import { Grid, Paper, Typography, withStyles, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import AddIcon from 'shared/assets/img/icons/candidateCreator/add.svg';
import { Translate } from 'shared/newComponents';
import { AssetService } from 'shared/services';
import { getAssetUrl } from 'shared/services';
import CandidateAssets from '../../../../../../../../../../../components/CandidateAssets';
import { styles } from './AddPics.styles';

const AddPics = ({ t, classes, formik, assetPath = 'assets' }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (formik.values[assetPath] && formik.values[assetPath].length) {
      setFiles(formik.values[assetPath]);
    } else {
      setFiles([1]);
    }
  }, [formik.values[assetPath]]);

  useEffect(() => {
    if (formik.values[assetPath] && formik.values[assetPath].length) {
      setFiles(formik.values[assetPath]);
    }
  }, [formik]);

  const addEmptyFile = () => {
    setFiles([...files, []]);
  };

  const callbackUpload = assets => {
    const filterFiles = files.map(item =>
      !item.id
        ? {
            ...assets[0],
            description: item.description ? item.description : null,
          }
        : item
    );
    formik.setFieldValue(assetPath, filterFiles);
    setFiles(filterFiles);
  };

  const removeFile = file => {
    AssetService.deleteAsset(file.id)
      .then(() => {
        const filterFiles = files.filter(
          item => item.id && item.id !== file.id
        );
        formik.setFieldValue(assetPath, filterFiles);
        setFiles(filterFiles.length ? filterFiles : [1]);
      })
      .catch(error => {
        console.log(error);
        alert(t`adminCitySeo:error`);
      });
  };

  if (!files) {
    return null;
  }

  const lastIndexFileWithoutAvatar = () => {
    let lastIndex = 0;
    files.forEach((file, index) => {
      if (file.type !== 'avatar') {
        lastIndex = index;
      }
    });
    return lastIndex;
  };

  return (
    <div className={classes.fileSection}>
      {files.map((item, index) => {
        if (item && item.id) {
          return (
            <Paper className={classes.paper} key={`files_${index}`}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item md={2}>
                  <Translate
                    text={`${t`employmentManagementDialog:dialog2.photo`} ${
                      index + 1
                    }`}
                    className={classes.fileDesc}
                    bold
                    disableTranslate={true}
                  />
                </Grid>
                <Grid item md={5}>
                  <a href={getAssetUrl(item.id)} className={classes.link}>
                    <Translate
                      bold
                      text={item.filename}
                      disableTranslate={true}
                      style={{
                        fontSize: '1rem',
                        wordBreak: 'break-all',
                        color: '#359CFF',
                      }}
                    />
                  </a>
                </Grid>
                <Grid item md={index === lastIndexFileWithoutAvatar() ? 3 : 5}>
                  <Button
                    onClick={() => removeFile(item)}
                    variant="outlined"
                    className={classes.delBtn}
                  >{t`employmentManagementDialog:dialog2.deleteFile`}</Button>
                </Grid>
                {index === lastIndexFileWithoutAvatar() && (
                  <Grid item md={2} onClick={addEmptyFile}>
                    <img
                      alt="addIcon"
                      src={AddIcon}
                      height={50}
                      className={classes.addIcon}
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
          );
        } else {
          return (
            <Paper className={classes.paper} key={`files_${index}`}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item md={9}>
                  <Typography variant="body1" className={classes.bold}>
                    {t`employmentManagementDialog:dialog2.addPics`}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <CandidateAssets
                    clearLoad={true}
                    callback={callbackUpload}
                    removeIcon={false}
                    filesLimit={1}
                    sendManual={true}
                    candidate={{}}
                    acceptedFiles={['.jpg', '.jpeg', '.png']}
                  >
                    <Button
                      variant="outlined"
                      className={classes.chooseFileBtn}
                      onClick={() => {}}
                    >{t`employmentManagementDialog:dialog2.chooseFile`}</Button>
                  </CandidateAssets>
                </Grid>
              </Grid>
            </Paper>
          );
        }
      })}
    </div>
  );
};

export default withStyles(styles)(withTranslation()(AddPics));
