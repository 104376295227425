import {
  Avatar,
  Typography,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { LocationOn as LocationOnIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getUserAvatarSrc } from 'shared/utils/assets';
import { getTimeLeft } from 'shared/utils/date';

const styles = theme => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 3,
    paddingBottom: 3,
    display: 'flex',
    transition: 'all .4s',
    justifyContent: 'space-between',
    '&:hover': {
      borderRadius: '20px',
      backgroundColor: 'rgba(82, 163, 252, 0.1)',
    },
    '& > div': {
      fontSize: 12,
    },
  },
  primary: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.palette.jobllegroGrey,
  },
  secondary: {
    padding: 0,
  },
  textBottom: {
    color: theme.palette.jobllegroLightGrey,
    fontSize: 10,
  },
  avatar: {
    width: 28,
    height: 28,
    marginLeft: 12,
  },
  rootListText: {
    padding: '0 12px',
  },
  icon: {
    fontSize: 9,
    marginLeft: 3,
    marginRight: 1,
  },
});

export const DashboardRightDrawerListItem = props => {
  const { data, type, classes, t } = props;

  let avatarSrc = '';
  let dataTextOne = '';
  let showCity = '';
  let showIndustry = '';
  let showProjectTime = '';

  if (type === 'recruiters') {
    avatarSrc = getUserAvatarSrc(data, false, 'avatarAsset');

    if (data.industry !== null) {
      showIndustry = data.industry;
    }
    if (data.city !== null) {
      showCity = data.city;
    }
  } else if (type === 'candidates') {
    avatarSrc = getUserAvatarSrc(data, false, 'avatarAsset');
    if (data.position !== null) {
      showIndustry = data.position;
    } else {
      showIndustry = t('core:general.noInformation');
    }
    if (data.city !== null) {
      showCity = data.city;
    }
  } else if (type === 'projects') {
    dataTextOne = data.client;
    showProjectTime = getTimeLeft(data, t);
  }

  const secondaryText = (
    <Typography className={classes.textBottom} component="div">
      {dataTextOne}
      {showProjectTime !== '' && ' (' + showProjectTime + ')'}
      {type !== 'candidates' ? showIndustry : ''}
      {showCity !== '' && <LocationOnIcon className={classes.icon} />}
      {showCity}
    </Typography>
  );

  const primaryText = (
    <Typography className={classes.primary} component="div">
      {type === 'projects' && data.position}
      {type === 'candidates' && showIndustry}
      {type === 'recruiters' && data.firstName + ' ' + data.lastName}
    </Typography>
  );

  return (
    <ListItem
      key={data.id}
      role={undefined}
      dense
      button
      className={classes.listItem}
      component={Link}
      to={
        type === 'projects'
          ? PATHS.PROJECT.SHOW.replace(':id', data.id)
          : type === 'recruiters'
          ? PATHS.RECRUITER.SHOW.replace(':id', data.id)
          : PATHS.CANDIDATE.SHOW.replace(':id', data.id)
      }
    >
      <Avatar className={classes.avatar} src={avatarSrc}>
        JB
      </Avatar>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        classes={{
          root: classes.rootListText,
          primary: classes.primary,
          secondary: classes.secondary,
        }}
      />
    </ListItem>
  );
};

DashboardRightDrawerListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(
  withTranslation()(DashboardRightDrawerListItem)
);
