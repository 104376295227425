import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { editProject, getProject } from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog/';

export class ProjectClose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationOpened: false,
      confirmationMessage: '',
      confirmationClosedStatus: null,
    };
  }

  closeProject = async confirmationClosedStatus => {
    try {
      const {
        t,
        projectDetails: { closedStatus },
      } = this.props;
      let snackBarText = '';

      if (confirmationClosedStatus === 0) {
        snackBarText = t('project:form.yourProjectWasRejectedFromClosed');
      } else if (
        confirmationClosedStatus === 1 ||
        confirmationClosedStatus === 2
      ) {
        snackBarText = t('project:form.yourProjectWasClosed');
      }
      if (closedStatus === 0 || closedStatus === 1) {
        const closeProjectData = {
          id: this.props.projectDetails.id,
          closedStatus: confirmationClosedStatus,
        };
        await this.props.editProject(closeProjectData);
        await this.props.getProject(this.props.projectDetails.id);
        this.props.showSnackbarMessage(snackBarText);
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleConfirmationOpen = id => () => {
    const { t } = this.props;
    let confirmationText = '';
    if (id === 0) {
      confirmationText = t('project:form.confirmToRejectProjectByHm');
    } else if (id === 1) {
      confirmationText = t('project:form.confirmToCloseProject');
    } else if (id === 2) {
      confirmationText = t('project:form.confirmToCloseProjectByHm');
    }
    this.setState({
      confirmationOpened: true,
      confirmationClosedStatus: id,
      confirmationMessage: confirmationText,
    });
  };

  handleConfirmationClose = isOk => {
    const { confirmationClosedStatus } = this.state;
    if (isOk) {
      this.closeProject(confirmationClosedStatus);
    }
    this.setState({
      confirmationOpened: false,
    });
  };

  render() {
    const { projectDetails, t } = this.props;
    const { permissions = {} } = projectDetails;
    const { isOwner = {}, isHiringManager = {} } = permissions;
    const { confirmationMessage, confirmationClosedStatus } = this.state;

    let showButton = false;
    if (!projectDetails.closedStatus && isOwner) {
      showButton = true;
    }

    return (
      (isOwner || isHiringManager) && (
        <React.Fragment>
          <ConfirmationDialog
            open={this.state.confirmationOpened}
            titleText={t('project:form.closeProject')}
            onClose={this.handleConfirmationClose}
            okButtonText={
              confirmationClosedStatus === 0
                ? t('action.rejectClose')
                : t('action.close')
            }
            cancelButtonText={t('action.cancel')}
          >
            {confirmationMessage}
          </ConfirmationDialog>
          {isHiringManager && projectDetails.closedStatus === 1 && (
            <React.Fragment>
              <ActionButtonJobllegroRounded
                onClick={this.handleConfirmationOpen(2)}
              >
                {t('project:form.projectClosedConfirm')}
              </ActionButtonJobllegroRounded>
              <ActionButtonJobllegroRounded
                onClick={this.handleConfirmationOpen(0)}
              >
                {t('project:form.projectClosedReject')}
              </ActionButtonJobllegroRounded>
            </React.Fragment>
          )}
          {isOwner &&
            (projectDetails.closedStatus === 0 ||
              projectDetails.closedStatus === 2) && (
              <ActionButtonJobllegroRounded
                onClick={this.handleConfirmationOpen(1)}
                disabled={
                  !showButton ||
                  projectDetails.closedStatus ||
                  projectDetails.isDraft
                }
              >
                {showButton && !projectDetails.closedStatus
                  ? t('project:form.closeProject')
                  : t('project:form.projectClosed')}
              </ActionButtonJobllegroRounded>
            )}
        </React.Fragment>
      )
    );
  }
}

const mapDispatchToProps = {
  editProject,
  getProject,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectClose.propTypes = {
  projectDetails: PropTypes.object.isRequired,
  editProject: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProjectClose));
