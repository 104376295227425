import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getHelpdeskMessages } from 'shared/services/helpdeskService';
import PageHeader from '../../components/PageHeader';

const columnData = [
  { id: 'id', numeric: true, label: 'lp.' },
  { id: 'date', numeric: false, label: 'date' },
  { id: 'name', numeric: false, label: 'name' },
  { id: 'email', numeric: false, label: 'email' },
  { id: 'title', numeric: false, label: 'title' },
  { id: 'message', numeric: false, label: 'message' },
];

export class AdminHelpdeskViewHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

AdminHelpdeskViewHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export class AdminHelpdeskView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'id',
      page: 0,
      rowsPerPage: 25,
      data: [],
    };
  }

  loadData = async () => {
    const { data } = await this.props.getHelpdeskMessages();
    this.setState({
      data,
      order: 'asc',
      orderBy: 'id',
      page: 0,
    });
  };

  componentWillMount() {
    this.loadData();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, page: 0 });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { order, orderBy, rowsPerPage, page } = this.state;
    const { t } = this.props;

    let data = this.state.data ? _.sortBy(this.state.data, orderBy) : [];
    //działa odwrotnie bo material-ui/Table ma przy asc strzałkę w górę
    data = order === 'asc' ? _.reverse(data) : data;

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
        <PageHeader title="HelpDesk" />
        <Button onClick={this.loadData} variant="contained" color="primary">
          Odśwież dane
        </Button>
        <Table>
          <AdminHelpdeskViewHead
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(n => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell align="right">{n.id}</TableCell>
                    <TableCell padding="none">{n.createdAt}</TableCell>
                    <TableCell padding="none">
                      {n.owner.firstName} {n.owner.lastName}
                    </TableCell>
                    <TableCell padding="none">{n.owner.email}</TableCell>
                    <TableCell padding="none">{n.title}</TableCell>
                    <TableCell padding="none">
                      {n.message.split('\n').map((item, k) => (
                        <p key={k}>{item}</p>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columnData.length + 1} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t('pagination.elementsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  t('pagination.pagesFromTo', { from, to, count })
                }
                colSpan={columnData.length + 1}
                count={data.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

const mapDispatchToProps = {
  getHelpdeskMessages,
};

AdminHelpdeskView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  getHelpdeskMessages: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(AdminHelpdeskView));
