import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import InternetIcon from 'shared/assets/img/icons/project-show/internet.png';
import { MissingOrganizationDetailsDialog } from '..';
import CustomDialog from '../../../components/CustomDialog';
import { FinicalPlanContainer } from '../../../modules/project/NewProjectCreateView/components/ProjectCreateWizardStep8/components';
import { ActionIconButton } from '../../../modules/project/NewProjectShowView/components/GeneralInformations/components/SideView/components';
import {
  editProjectNonDispatch,
  OrganisationService,
  ProjectV2Service,
  UserService,
} from '../../services';
import { showSnackbarMessage } from '../../store/messages/actions';
import { isOrganizationDetailsSet } from '../MissingOrganizationDetailsDialog/MissingOrganizationDetailsDialog.utils';
import { Translate } from '../index';

const MakePublicButton = ({
  projectDetails,
  t,
  showSnackbarMessage,
  ...props
}) => {
  const [isFirstPerPublicDialogOpen, setIsFirstPerPublicDialogOpen] =
    useState(false);
  const [isSecondPublicDialogOpen, setIsSecondPublicDialogOpen] =
    useState(false);

  const [projectAfterUpdate, setProjectAfterUpdate] = useState(projectDetails);
  const [isPublicBtnDisabled, setIsPublicBtnDisabled] = useState(false);

  const [isOpenOrganizationDialog, setIsOpenOrganizationDialog] =
    useState(false);

  const [finicalPlan, setFinicalPlan] = useState({});

  useEffect(() => {
    const id = projectDetails.id;
    if (!!id) {
      OrganisationService.getFinicalPlan(id)
        .then(({ data }) => setFinicalPlan(data))
        .catch(console.log);
    }
  }, [get(projectDetails, 'organizationToBe')]);

  const warrantyOrderValue =
    !!get(projectDetails, 'warrantyOrderValue') || false;

  const mentorOrderValue = !!get(projectDetails, 'mentorOrderValue') || false;
  const isDraft = get(projectDetails, 'isDraft');
  const organization = get(projectDetails, 'organizationToBe');

  const makePublic = () => {
    if (isPublicBtnDisabled) {
      return;
    }
    const body = {
      id: projectDetails.id,
      isDraft: false,
    };
    setIsFirstPerPublicDialogOpen(false);
    setIsPublicBtnDisabled(true);
    ProjectV2Service.editProjectV2(projectDetails.id, body, { stage: 'public' })
      .then(({ data }) => {
        setProjectAfterUpdate(data);
        return UserService.getMentorForProjectFromList();
      })
      .then(({ data }) => {
        setIsPublicBtnDisabled(false);
        setIsSecondPublicDialogOpen(true);
        if (data !== null) {
          return editProjectNonDispatch({
            id: projectDetails.id,
            mentorId: data.id,
          });
        }
      })
      .catch(error => {
        setIsPublicBtnDisabled(false);
        const { status } = error.response;
        if (status === 403) {
          showSnackbarMessage(t('projectDetails:error.stageIsIncomplete'));
        } else if (status === 401) {
          showSnackbarMessage(t('projectDetails:error.organizationIsNotSet'));
        } else {
          showSnackbarMessage(t('form.error.error'));
        }
        console.error(error);
      });
  };

  const handleOnCloseDialog = () => {
    setIsOpenOrganizationDialog(false);
  };

  return (projectDetails.permissions.isOwner ||
    projectDetails.permissions.isSuperUser) &&
    projectDetails.isDraft ? (
    <React.Fragment>
      {(!projectDetails.organization &&
        !projectDetails.organizationInformationSendStatus) ||
      projectDetails.organization ? (
        <ActionIconButton
          pulsarBtn={true}
          style={{ maxWidth: 46, maxHeight: 46 }}
          name={t('projectDetails:content.makePublic')}
          onClick={() => {
            if (organization && !isOrganizationDetailsSet(organization)) {
              return setIsOpenOrganizationDialog(true);
            }
            setIsFirstPerPublicDialogOpen(true);
          }}
          icon={InternetIcon}
        />
      ) : (
        <ActionIconButton
          style={{ maxWidth: 46, maxHeight: 46 }}
          name={t('projectDetails:content.waitingToApprove')}
          icon={InternetIcon}
        />
      )}

      <CustomDialog
        maxWidth={'md'}
        title={
          projectDetails.organization
            ? t`projectDetails:content.financialSum`
            : t`projectDetails:content.note`
        }
        isOpen={isFirstPerPublicDialogOpen}
        onCloseDialog={() => {
          setIsFirstPerPublicDialogOpen(false);
        }}
        body={
          <Grid>
            {projectDetails.organization || projectDetails.organizationToBe ? (
              <Grid>
                {' '}
                <FinicalPlanContainer
                  onlyView={true}
                  selectWarrantyOrderValue={warrantyOrderValue}
                  selectMentorOrderValue={mentorOrderValue}
                  finicalPlan={finicalPlan}
                  finicalPlanFetchStatus={false}
                  isDraft={isDraft}
                  organization={organization}
                />
                <ActionIconButton
                  disabled={isPublicBtnDisabled}
                  pulsarBtn={true}
                  style={{ maxWidth: 46, maxHeight: 46 }}
                  name={t`projectDetails:content.order`}
                  onClick={makePublic}
                  textFullWith={true}
                  icon={InternetIcon}
                />
              </Grid>
            ) : (
              <Grid>
                <Translate
                  text={t`projectDetails:content.chooseOrg`}
                  variant="body1"
                  style={{ paddingBottom: 8, fontSize: 18 }}
                />
              </Grid>
            )}
          </Grid>
        }
      />
      <MissingOrganizationDetailsDialog
        organizationOwner={
          organization && organization.owner.id === props.currentUser.id
        }
        handleOnCloseDialog={handleOnCloseDialog}
        isOpenOrganizationDialog={isOpenOrganizationDialog}
      />
      <CustomDialog
        maxWidth={'md'}
        title={
          !projectAfterUpdate.organization
            ? t`projectDetails:content.awaitingApproval`
            : t`projectDetails:content.published`
        }
        isOpen={isSecondPublicDialogOpen}
        onCloseDialog={() => {
          window.location.reload();
        }}
        body={
          <Grid>
            <Translate
              text={
                !projectAfterUpdate.organization
                  ? t`projectDetails:content.willBePublished`
                  : t`projectDetails:content.publishedOnJobllegro`
              }
              variant="body1"
              style={{ paddingBottom: 8, fontSize: 18 }}
            />
            <Translate
              text={t`projectDetails:content.infoSent`}
              variant="body1"
              style={{ paddingBottom: 8, fontSize: 18 }}
            />
            <Translate
              text={t`projectDetails:content.emailSent`}
              variant="body1"
              style={{ paddingBottom: 8, fontSize: 18 }}
            />
          </Grid>
        }
      />
    </React.Fragment>
  ) : null;
};

const mapDispatchToProps = {
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MakePublicButton));
