export const getLocalizationFromProjectOrder = localizations => {
  return localizations.map(localization => {
    return {
      name: `${localization.client} / ${localization.address} / ${localization.city} / ${localization.postCode}`,
      id: localization.id,
    };
  });
};

export const getSelectedLocalization = (localization, localizations) => {
  const findLocalization = localizations.find(
    item => item.id === localization.id
  );

  if (!findLocalization) {
    return '';
  }
  return findLocalization.id;
};
