import { Typography, withStyles, Grid, Button } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { enums } from 'shared/enums';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import { diffBetweenDate } from '../../../../../ProjectAts/utils/utils';
import { styles } from './Resignation.styles';

const Resignation = ({
  classes,
  t,
  dataAdded,
  selectedFullObject,
  showSaveBtn,
}) => {
  const [dayDiffDate, setDayDiffDate] = useState(0);

  const formData = {
    resignationStartDate: selectedFullObject.employmentStartDate
      ? selectedFullObject.employmentStartDate
      : moment().format('YYYY-MM-DD'),
    resignationEndDate: selectedFullObject.resignationEndDate
      ? selectedFullObject.resignationEndDate
      : '',
  };

  const minBreakDate = selectedFullObject.employmentStartDate
    ? selectedFullObject.employmentStartDate
    : moment().format('YYYY-MM-DD');

  const maxBreakDate = selectedFullObject.employmentEndDate
    ? selectedFullObject.employmentEndDate
    : moment().format('YYYY-MM-DD');

  const validationSchema = yup.object({
    resignationStartDate: yup
      .date()
      .required(t`ats2ats3:validation.isRequired`),
    resignationEndDate: yup
      .date()
      .min(
        minBreakDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minBreakDate}`
      )
      .max(
        maxBreakDate,
        `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${maxBreakDate}`
      )
      .required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({ resignationStartDate, resignationEndDate }) => {
      if (
        moment(resignationStartDate).isAfter(moment(resignationEndDate), 'day')
      ) {
        formik.setFieldError('resignationEndDate', t`ats2ats3:validation.date`);
        return;
      }
      const body = {
        resignationStartDate,
        resignationEndDate,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };

      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_RESIGNATION
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => console.log(error));
    },
  });

  useEffect(() => {
    setDayDiffDate(
      diffBetweenDate(
        formik.values.resignationStartDate,
        formik.values.resignationEndDate
      )
    );
  }, [formik.values.resignationStartDate, formik.values.resignationEndDate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.bgBlue}>
        <div className={classes.container}>
          <Typography
            variant="body1"
            className={classes.employmentEndDate}
          >{t`employmentManagementDialog:dialog8.employmentEndDate`}</Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <PowerSettingsNew className={classes.powerOnBtn} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.date}>
                    {formik.values.resignationStartDate || t`assign:noData`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                </Grid>
                <Grid item>
                  <TextField
                    type="date"
                    formik={formik}
                    name="resignationEndDate"
                    variant="transparent"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.noticeOfPeriod}
            alignItems="center"
            spacing={40}
          >
            <Grid item>
              <Typography variant="body1">{t`employmentManagementDialog:dialog8.noticePeriod`}</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                className={`${classes.date} ${
                  !isNaN(dayDiffDate) && dayDiffDate < 21 && classes.warning
                }`}
              >
                {!isNaN(dayDiffDate) ? dayDiffDate : ''}
              </Typography>
            </Grid>
          </Grid>
          {!isNaN(dayDiffDate) && dayDiffDate < 21 && (
            <Typography
              variant="h6"
              className={classes.warningMsg}
            >{t`employmentManagementDialog:dialog8.noticePeriodWarning`}</Typography>
          )}
        </div>
      </div>
      {showSaveBtn && (
        <div className={classes.saveBtnDiv}>
          <Button type="submit" className={classes.saveBtn}>
            {t`employmentManagementDialog:saveBtn`}
          </Button>
        </div>
      )}
    </form>
  );
};

export default withStyles(styles)(withTranslation()(Resignation));
