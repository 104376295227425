export const styles = () => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#2D333B',
  },
  dialogTitle: {
    '& h6': { fontWeight: '500' },
  },
  content: { background: '#E5F0FF' },
  date: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  header: {
    margin: '20px 0',
    fontWeight: 600,
  },
  employmentRequest: { marginTop: 10 },
  employmentDate: { marginTop: 10 },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  saveBtn: {
    display: 'flex',
    margin: '40px auto',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    color: '#fff',
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  txtField: {
    background: '#B7BEC8',
    borderRadius: 30,
    pointerEvents: 'none',
    fontWeight: 'bold',
  },
});
