import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateExpectedSalary = ({ candidate, t }) => {
  const maxMonthlyWadges = candidate.maxMonthlyWadges;
  const minMonthlyWadges = candidate.minMonthlyWadges;
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Translate
        style={{
          fontSize: 20,
        }}
        bold
        text={'candidateCreator:reviewStep2.expectedSalary'}
      />
      {minMonthlyWadges !== null && maxMonthlyWadges !== null ? (
        <Grid item>
          <Translate
            style={{
              fontSize: 16,
            }}
            text={`${minMonthlyWadges}-${maxMonthlyWadges} ${t(
              'candidateCreator:reviewStep2.netto'
            )}`}
          />
        </Grid>
      ) : (
        <Grid item>
          <Translate
            style={{
              fontSize: 16,
            }}
            text={'core:general.noInformation'}
            disableTranslate={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateExpectedSalary))));
