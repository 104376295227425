import { object, bool, string } from 'yup';

export const MODEL = {
  isRequireAttachmentCv: 'isRequireAttachmentCv',
  isRequireAttachmentRecruiterApproved: 'isRequireAttachmentRecruiterApproved',
  isRequireAttachmentPassportStampScan: 'isRequireAttachmentPassportStampScan',
  isRequireAttachmentPassportVisaScan: 'isRequireAttachmentPassportVisaScan',
  isRequireAttachmentPassportPersonalDataScan:
    'isRequireAttachmentPassportPersonalDataScan',
  isRequireAttachmentWorkPermitScan: 'isRequireAttachmentWorkPermitScan',
  isRequireAttachmentWorkQualificationScan:
    'isRequireAttachmentWorkQualificationScan',
  isRequireAttachmentCertificateAndDiplomaScan:
    'isRequireAttachmentCertificateAndDiplomaScan',
  isRequireAttachmentOtherScan: 'isRequireAttachmentOtherScan',
  isRequireAttachmentOtherDesc: 'isRequireAttachmentOtherDesc',
};

export const validationSchema = t =>
  object().shape(
    {
      isRequireAttachmentCv: bool(),
      isRequireAttachmentRecruiterApproved: bool(),
      isRequireAttachmentPassportStampScan: bool(),
      isRequireAttachmentPassportVisaScan: bool(),
      isRequireAttachmentPassportPersonalDataScan: bool(),
      isRequireAttachmentWorkPermitScan: bool(),
      isRequireAttachmentWorkQualificationScan: bool(),
      isRequireAttachmentCertificateAndDiplomaScan: bool(),
      isRequireAttachmentOtherScan: bool(),
      isRequireAttachmentOtherDesc: string().when(
        'isRequireAttachmentOtherScan',
        {
          is: isRequireAttachmentOtherScan => isRequireAttachmentOtherScan,
          then: string()
            .nullable()
            .strict(false)
            .trim()
            .required(t('projectCreator:core.requiredField')),
          otherwise: string().nullable(),
        }
      ),
    },
    ['isRequireAttachmentOtherScan', 'isRequireAttachmentOtherDesc']
  );
