export const enums = {
  RECRUITERS: {
    CONFIRMATION_STATUS: {
      INVITED_TO_PROJECT_BY_MEMBER: 1,
      INVITED_TO_PROJECT_BY_SELF: 4,
    },
  },
  PROJECT_CLOSED_STATUS: {
    PROJECT_CLOSED: 1,
  },
  EMPLOYMENT_MANAGEMENT_HISTORY_STATUS: {
    EMPLOYMENT_STATUS_WAIT_FOR_ARRIVAL: 0,
    EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY: 1,
    EMPLOYMENT_STATUS_QUARANTINE: 2,
    EMPLOYMENT_STATUS_CONFIRM_EMPLOY: 3,
    EMPLOYMENT_STATUS_EMPLOY: 4,
    EMPLOYMENT_STATUS_BREAK: 5,
    EMPLOYMENT_STATUS_RESIGNATION: 6,
    EMPLOYMENT_STATUS_ILLNESS: 7,
    EMPLOYMENT_STATUS_EMPLOY_END: 8,
    EMPLOYMENT_STATUS_DEREGISTERING: 9,
  },
  EMPLOYMENT_MANAGEMENT_HISTORY_STATUS_CONFIRM_COMEBACK: {
    WORK_BREAK: 400,
    ILLNESS: 401,
  },
  EMPLOYMENT_MANAGEMENT_HISTORY_STATUS_CONFIRM_RESIGNATION: {
    CONFIRM_RESIGNATION: 500,
  },
  EMPLOYMENT_MANAGEMENT_HISTORY_NAME_STATUS_FOR_TIMELINE: {
    0: 'employmentManagementDialog:status.waitingForArrival',
    1: 'employmentManagementDialog:status.awaitingEmployment',
    2: 'employmentManagementDialog:status.quarantine',
    3: 'employmentManagementDialog:status.confirmationOfEmployment',
    4: 'employmentManagementDialog:status.employed',
    5: 'employmentManagementDialog:status.breakAtWork',
    6: 'employmentManagementDialog:status.resignation',
    7: 'employmentManagementDialog:status.illness',
    8: 'employmentManagementDialog:status.endOfEmployment',
    100: 'employmentManagementDialog:status.registration',
    101: 'employmentManagementDialog:status.recruitment',
    200: 'employmentManagementDialog:status.legalization',
  },
  EMPLOYMENT_MANAGEMENT_HISTORY_LOCALIZATION: 300,
  EMPLOYMENT_MANAGEMENT_HISTORY_ACCOMMODATION: 301,
  ACCOMMODATION_TYPES: {
    ACCOMMODATION: 0,
    QUARANTINE_ACCOMMODATION: 1,
  },
  MENTOR_STATUS: {
    NOT_SET: 0,
    SEND_REQUEST: 1,
    ACCEPTED: 2,
  },
  ORGANIZATION_TYPES: {
    ORGANIZATION_BUSINESS_TYPE: 0,
    ORGANIZATION_PERSONAL_TYPE: 1,
  },
  SUBSCRIPT_TYPE: { 0: 'Brak', 1: 'Bronze', 2: 'Silver', 3: 'GOLD' },
  LANGUAGE_OLD_LEVELS: {
    0: 'Brak informacji',
    1: 'A1 ',
    2: 'A2',
    3: 'B1',
    4: 'B2',
    5: 'C1',
    6: 'C2',
    7: 'Język Ojczysty',
  },
  EXPORT_REPORTS_TYPE: {
    0: 'Projekty ogólne',
    1: 'Rekruterzy ogólne',
    2: 'Rekruterzy szczegółowe',
    3: 'Kandydaci ogólne',
    4: 'Kandydaci szczegółowe',
    5: 'Zamówienia zbiorczo',
    6: 'Zakwaterowanie zbiorczo',
    7: 'Zarządzanie projektami',
    8: 'Zakwaterowanie szczegółowe',
    9: 'Zamówienia szczegółowe',
    10: 'Raport Pomoc dla Ukrainy',
  },

  EXPORT_REPORTS_TYPE_RECRUITER: {
    5: 'Zamówienia zbiorczo',
    6: 'Zakwaterowanie zbiorczo',
    7: 'Zarządzanie projektami',
    8: 'Zakwaterowanie szczegółowe',
    9: 'Zamówienia szczegółowe',
  },
  VISIBILITY: {
    1: 'Prywatny',
    2: 'Tylko w organizacji',
    3: 'Publiczny',
  },
  COMPANY_SIZE: {
    0: '0-9',
    1: '10-49',
    2: '50-249',
    3: '250+',
  },
  RESIDENCE_TITLE: {
    0: 'candidateCreator:step1.nonContent',
    1: 'candidateCreator:step1.visaFree',
    2: 'candidateCreator:step1.visaNationalWork',
    3: 'candidateCreator:step1.visaSeasonalWork',
    4: 'candidateCreator:step1.visaStudent',
    5: 'candidateCreator:step1.visaOther',
    6: 'ats1ats2:employmentTypeRequest.residenceCard',
    7: 'ats1ats2:employmentTypeRequest.polesCard',
    8: 'ats1ats2:employmentTypeRequest.visaFreeBiometricPassport',
    9: 'ats1ats2:specialAct',
  },
  BIOMETRIC_PASSPORT_VALID_DAYS: 87,
  VISIBILITY_PROJECT_TYPE: {
    3: 'projectCreator:step1.projectPublic',
    2: 'projectCreator:step1.projectOrganization',
    1: 'projectCreator:step1.projectPrivate',
  },
  CANDIDATE_DISABLED_DEGREE: {
    0: 'candidateCreator:step5.firstDegreeOfDisability',
    1: 'candidateCreator:step5.secondDegreeOfDisability',
    2: 'candidateCreator:step5.thirdDegreeOfDisability',
  },
  CANDIDATE_DISABLED_REASON: {
    0: 'candidateCreator:step5.noneDegreeReason',
    1: 'candidateCreator:step5.UDegreeReason',
    2: 'candidateCreator:step5.PDegreeReason',
    3: 'candidateCreator:step5.LDegreeReason',
    4: 'candidateCreator:step5.ODegreeReason',
    5: 'candidateCreator:step5.RDegreeReason',
    6: 'candidateCreator:step5.EDegreeReason',
    7: 'candidateCreator:step5.SDegreeReason',
    8: 'candidateCreator:step5.TDegreeReason',
    9: 'candidateCreator:step5.MDegreeReason',
    10: 'candidateCreator:step5.NDegreeReason',
    11: 'candidateCreator:step5.IDegreeReason',
    12: 'candidateCreator:step5.CDegreeReason',
  },
  ATTACHMENT_TYPES: {
    OTHER_SCAN: 'otherScan',
  },
  ATTACHMENT_TYPE_ARRAY: [
    'cv',
    'recruiterApproved',
    'passportStampScan',
    'passportVisaScan',
    'passportPersonalDataScan',
    'workPermitScan',
    'workQualificationScan',
    'certificateAndDiplomaScan',
    'otherScan',
  ],

  POSITION_DEFAULT_ARRAY: ['Pracownik produkcji', 'Pracownik magazynu'],

  ATTACHMENT_TYPE: {
    cv: 'C.V',
    recruiterApproved:
      'projectCreator:step3.isRequireAttachmentRecruiterApproved',
    passportStampScan:
      'projectCreator:step3.isRequireAttachmentPassportStampScan',
    passportVisaScan:
      'projectCreator:step3.isRequireAttachmentPassportVisaScan',
    passportPersonalDataScan:
      'projectCreator:step3.isRequireAttachmentPassportPersonalDataScan',
    workPermitScan: 'projectCreator:step3.isRequireAttachmentWorkPermitScan',
    workQualificationScan:
      'projectCreator:step3.isRequireAttachmentWorkQualificationScan',
    certificateAndDiplomaScan:
      'projectCreator:step3.isRequireAttachmentCertificateAndDiplomaScan',
    otherScan: 'projectCreator:step3.isRequireAttachmentOtherScan',
  },
  ATTACHMENT_PASSPORT_TYPE: {
    passportStampScan:
      'projectCreator:step3.isRequireAttachmentPassportStampScan',
    passportVisaScan:
      'projectCreator:step3.isRequireAttachmentPassportVisaScan',
    passportPersonalDataScan:
      'projectCreator:step3.isRequireAttachmentPassportPersonalDataScan',
  },
  ATTACHMENT_AWAITING_EMPLOYMENT: {
    passportStampScan: 'employmentManagementDialog:dialog2.crossingBorderPage',
    passportVisaScan: 'employmentManagementDialog:dialog2.personalDataVisa',
    passportPersonalDataScan: 'employmentManagementDialog:dialog2.otherPages',
  },
  ATTACHMENT_ATS2: {
    visa: 'ats2ats3:addDoc',
  },
  ATTACHMENT_ATS4: {
    visa: 'ats4ats5:addVisa',
  },
  ATTACHMENT_EMPLOY_TYPE: {
    assignContractScan: 'projectCreator:step3.assignContractScan',
    accountNumberScan: 'projectCreator:step3.accountNumberScan',
    personNumberScan: 'projectCreator:step3.personNumberScan',
  },
  AVAILABILITY_CANDIDATE: {
    0: '30 dni od końca miesiąca',
  },
  CURRENCY: {
    PLN: 'PLN',
    EUR: 'EUR',
    HUA: 'HUA',
  },
  TERMINATION_TYPE: {
    0: 'employmentManagementDialog:dialog2.terminationTypeResignationSubmit',
    1: 'employmentManagementDialog:dialog2.terminationTypeEndOfMonth',
    2: 'employmentManagementDialog:dialog2.terminationTypeAgreement',
  },
  ACCOMMODATION_TYPE: {
    0: 'employmentManagementDialog:dialog2.accommodationTypeStandard',
    1: 'employmentManagementDialog:dialog2.accommodationTypeQuarantine',
  },
  TYPE_OF_PAY: {
    0: 'core:financial.gross',
    1: 'core:financial.net',
  },
  TYPE_OF_PAY_KEY: {
    GROSS: 0,
    NET: 1,
  },
  WORKING_HOURS_KEY: {
    MONTHLY: 0,
    HOURS: 5,
  },
  WORKING_HOURS: {
    0: 'projectCreator:workHoursSelect.monthly',
    5: 'projectCreator:workHoursSelect.hours',
  },
  EMPLOYMENT_TYPE: {
    'Umowa o pracę': 'Umowa o pracę',
    'Umowa zlecenie': 'Umowa zlecenie',
    'Umowa o dzieło': 'Umowa o dzieło',
    'Kontrakt B2B': 'Kontrakt B2B',
    'Umowa agencyjna': 'Umowa agencyjna',
    'Umowa zastępstwa': 'Umowa zastępstwa',
    'Umowa prowizyjna': 'Umowa prowizyjna',
    'Umowa student poniżej 26 roku': 'Umowa student poniżej 26 roku',
  },
  CALC_CANDIDATE_EXPERIENCE: {
    WITHOUT_EXPERIENCE: 'projectCreator:recruiterViewOnly.WITHOUT_EXPERIENCE',
    BEGINNER: 'projectCreator:recruiterViewOnly.BEGINNER',
    ADVANCED: 'projectCreator:recruiterViewOnly.ADVANCED',
    EXPERT: 'projectCreator:recruiterViewOnly.EXPERT',
  },
  FEEDBACK_EMPLOYER_DECISION: {
    CANDIDATE_EMPLOY: 'newAts:feedback.candidateEmploy',
    CANDIDATE_MOVE_TO_NEXT_STAGE: 'newAts:feedback.candidateMoveToNextStage',
    CANDIDATE_REJECTED: 'newAts:feedback.candidateRejected',
  },
  URL_SEO_TYPES: {
    URL_FOR_ALL: 'Dla strony kandydatów/ofert pracy',
    URL_FOR_PROJECTS: 'Dla ofert pracy',
    URL_FOR_CANDIDATES: 'Dla kandydatów',
  },
  CALC_SERVICE_COMPLETION_DATE: {
    AFTER_ACCEPTENCE_OF_WINNING_CANDIDATE:
      'projectCreator:recruiterViewOnly.AFTER_ACCEPTENCE_OF_WINNING_CANDIDATE',
    AFTER_HIRING_OF_WINNING_CANDIDATE:
      'projectCreator:recruiterViewOnly.AFTER_HIRING_OF_WINNING_CANDIDATE',
  },
  CALC_PAYMENT_DEADLINE: {
    PERIOD_7_DAYS: 'projectCreator:recruiterViewOnly.PERIOD_7_DAYS',
    PERIOD_14_DAYS: 'projectCreator:recruiterViewOnly.PERIOD_14_DAYS',
    PERIOD_21_DAYS: 'projectCreator:recruiterViewOnly.PERIOD_21_DAYS',
    PERIOD_30_DAYS: 'projectCreator:recruiterViewOnly.PERIOD_30_DAYS',
  },
  CALC_CANDIDATE_WARRANTY: {
    NO_WARRANTY: 'projectCreator:recruiterViewOnly.NO_WARRANTY',
    STANDARD_WARRANTY: 'projectCreator:recruiterViewOnly.STANDARD_WARRANTY',
    EXTENDED_WARRANTY_3_MONTHS:
      'projectCreator:recruiterViewOnly.EXTENDED_WARRANTY_3_MONTHS',
  },
  EXPERIENCE_LEVEL: {
    0: 'projectCreator:competenceExperienceLevel.none',
    2: 'projectCreator:competenceExperienceLevel.junior',
    5: 'projectCreator:competenceExperienceLevel.regular',
    10: 'projectCreator:competenceExperienceLevel.senior',
  },
  EMPLOYMENT_TYPE_REQUEST: {
    0: 'ats1ats2:employmentTypeRequest.access-type-oopp',
    1: 'ats1ats2:employmentTypeRequest.access-type-a',
    2: 'ats1ats2:employmentTypeRequest.access-type-residenceCardApplicationSubmitted',
    3: 'ats1ats2:employmentTypeRequest.access-type-c',
    4: 'ats1ats2:employmentTypeRequest.access-type-d',
    5: 'ats1ats2:employmentTypeRequest.access-type-e',
    6: 'ats1ats2:employmentTypeRequest.access-type-s',
    7: 'ats1ats2:employmentTypeRequest.access-type-residenceCardNoJobMarket',
    8: 'ats1ats2:employmentTypeRequest.residenceCardLaborMarket',
    9: 'ats1ats2:employmentTypeRequest.permanentResidenceCardLaborMarket',
    10: 'ats1ats2:employmentTypeRequest.polesCard',
    11: 'ats1ats2:employmentTypeRequest.no-content',
    12: 'ats1ats2:employmentTypeRequest.notification',
  },
  EMPLOYMENT_TYPE_REQUEST_SHORT: {
    0: 'ats1ats2:employmentTypeRequestShort.access-type-oopp',
    1: 'ats1ats2:employmentTypeRequestShort.access-type-a',
    2: 'ats1ats2:employmentTypeRequestShort.access-type-residenceCardApplicationSubmitted',
    3: 'ats1ats2:employmentTypeRequestShort.access-type-c',
    4: 'ats1ats2:employmentTypeRequestShort.access-type-d',
    5: 'ats1ats2:employmentTypeRequestShort.access-type-e',
    6: 'ats1ats2:employmentTypeRequestShort.access-type-s',
    7: 'ats1ats2:employmentTypeRequestShort.access-type-residenceCardNoJobMarket',
    8: 'ats1ats2:employmentTypeRequestShort.residenceCardLaborMarket',
    9: 'ats1ats2:employmentTypeRequestShort.permanentResidenceCardLaborMarket',
    10: 'ats1ats2:employmentTypeRequestShort.polesCard',
    11: 'ats1ats2:employmentTypeRequestShort.no-content',
    12: 'ats1ats2:employmentTypeRequestShort.notification',
  },
  EMPLOYMENT_TYPE_REQUEST_SHORT_FORM: [8, 9, 10, 11],
  VISA_REQUEST_SHORT_FORM: [0, 1, 8, 6, 7],
  VISA_REQUEST_BIOMETRIC_PASSPORT: [8],
  PROJECT_ORDER_STATUS: {
    2: 'projectOrderViewContent:projectOrderStatus.active',
    1: 'projectOrderViewContent:projectOrderStatus.cosed',
    0: 'projectOrderViewContent:projectOrderStatus.waitToApprove',
  },
  EMPLOYMENT_STATUS: {
    0: 'employmentManagementDialog:status.waitingForArrival',
    1: 'employmentManagementDialog:status.awaitingEmployment',
    2: 'employmentManagementDialog:status.quarantine',
    3: 'employmentManagementDialog:status.confirmationOfEmployment',
    4: 'employmentManagementDialog:status.employed',
    5: 'employmentManagementDialog:status.breakAtWork',
    6: 'employmentManagementDialog:status.resignation',
    7: 'employmentManagementDialog:status.illness',
    8: 'employmentManagementDialog:status.endOfEmployment',
    9: 'employmentManagementDialog:status.deregistering',
  },
  ACCESS_FILE_TYPES: {
    1: 'access-type-oopp',
    2: 'access-type-a',
    3: 'access-type-residenceCardApplicationSubmitted',
    4: 'access-type-c',
    5: 'access-type-d',
    6: 'access-type-e',
    7: 'access-type-s',
    8: 'access-type-residenceCardNoJobMarket',
    9: 'access-type-non-content',
    10: 'access-type-non-content',
    11: 'access-type-non-content',
  },
  ACCESS_FILE_TYPES_FOR_CANDIDATE_PAGE: {
    visa: 'candidateDetails:employmentStatus.residenceTitle',

    assignContractScan: '',
    accountNumberScan: '',

    personNumberScan: 'candidateDetails:employmentStatus.registrationNumber',

    passportStampScan: 'candidateDetails:employmentStatus.passport',
    passportVisaScan: 'candidateDetails:employmentStatus.passport',
    passportPersonalDataScan: 'candidateDetails:employmentStatus.passport',

    'access-type-oopp': 'ats1ats2:employmentTypeRequestShort.access-type-oopp',
    'access-type-a': 'ats1ats2:employmentTypeRequestShort.access-type-a',
    'access-type-residenceCardApplicationSubmitted':
      'ats1ats2:employmentTypeRequestShort.access-type-residenceCardApplicationSubmitted',
    'access-type-c': 'ats1ats2:employmentTypeRequestShort.access-type-c',
    'access-type-d': 'ats1ats2:employmentTypeRequestShort.access-type-d',
    'access-type-e': 'ats1ats2:employmentTypeRequestShort.access-type-e',
    'access-type-s': 'ats1ats2:employmentTypeRequestShort.access-type-s',
    'access-type-residenceCardNoJobMarket':
      'ats1ats2:employmentTypeRequestShort.access-type-residenceCardNoJobMarket',
    'access-type-non-content': 'ats1ats2:employmentTypeRequestShort.no-content',
  },
  ACCESS_FILE_TYPES_FOR_CANDIDATE_PAGE_SUBTITLE: {
    visa: 'candidateDetails:employmentStatus.residenceTitleVisa',

    assignContractScan: 'candidateDetails:employmentStatus.formOfEmployment',
    accountNumberScan: 'candidateDetails:employmentStatus.bankAccount',

    personNumberScan:
      'candidateDetails:employmentStatus.registrationNumberPesel',

    passportStampScan: 'candidateDetails:employmentStatus.inCountrySince',
    passportVisaScan: 'candidateDetails:employmentStatus.inCountrySince',
    passportPersonalDataScan:
      'candidateDetails:employmentStatus.inCountrySince',

    'access-type-oopp': 'candidateDetails:employmentStatus.workPermit',
    'access-type-a': 'candidateDetails:employmentStatus.workPermit',
    'access-type-residenceCardApplicationSubmitted':
      'candidateDetails:employmentStatus.workPermit',
    'access-type-c': 'candidateDetails:employmentStatus.workPermit',
    'access-type-d': 'candidateDetails:employmentStatus.workPermit',
    'access-type-e': 'candidateDetails:employmentStatus.workPermit',
    'access-type-s': 'candidateDetails:employmentStatus.workPermit',
    'access-type-residenceCardNoJobMarket':
      'candidateDetails:employmentStatus.workPermit',
    'access-type-non-content': 'candidateDetails:employmentStatus.workPermit',
  },
  STANDARD_OF_LOCATION: {
    0: 'employmentManagementDialog:dialog2.veryLow',
    1: 'employmentManagementDialog:dialog2.low',
    2: 'employmentManagementDialog:dialog2.mediocre',
    3: 'employmentManagementDialog:dialog2.good',
    4: 'employmentManagementDialog:dialog2.excellent',
  },
  ACCOMMODATION_PRICE_PERIOD: {
    0: 'employmentManagementDialog:dialog2.priceForOnePerson',
    1: 'employmentManagementDialog:dialog2.priceForRoom',
    2: 'employmentManagementDialog:dialog2.monthlyPrice',
  },
  TYPE_OF_LOCATION: {
    0: 'employmentManagementDialog:dialog2.privateRental',
    1: 'employmentManagementDialog:dialog2.house',
    2: 'employmentManagementDialog:dialog2.flat',
    3: 'employmentManagementDialog:dialog2.hostel',
    4: 'employmentManagementDialog:dialog2.hotel',
  },
  END_OF_EMPLOYMENT_REASON: {
    0: 'employmentManagementDialog:dialog9.endOfContract',
    1: 'employmentManagementDialog:dialog9.transfer',
    2: 'employmentManagementDialog:dialog9.dismissal',
    3: 'employmentManagementDialog:dialog9.lossOfOrder',
    4: 'employmentManagementDialog:dialog9.lowEfficiency',
    5: 'employmentManagementDialog:dialog9.inconvenientWorkingHours',
    6: 'employmentManagementDialog:dialog9.notEnoughWorkingHours',
    7: 'employmentManagementDialog:dialog9.poorWorkingConditions',
    8: 'employmentManagementDialog:dialog9.tooLowSalary',
    9: 'employmentManagementDialog:dialog9.other',
  },
  END_OF_EMPLOYMENT_REASON_VALUES: {
    END_OF_CONTRACT: 0,
    TRANSFER: 1,
    DISMISSAL: 2,
    LOSS_OF_ORDER: 3,
    LOW_EFFICIENCY: 4,
    INCONVENIENT_WORKING_HOURS: 5,
    NOT_ENOUGH_WORKING_HOURS: 6,
    POOR_WORKING_CONDITIONS: 7,
    TOO_LOW_SALARY: 8,
    OTHER: 9,
  },
  EMPLOYMENT_MANAGEMENT_HISTORY_ERROR_DATE_STATUS: {
    RED_ERROR: 2,
    YELLOW_ERROR: 1,
  },
  ATS_EMPLOYMENT_PROCESS_STAGES: {
    STAGE_CANDIDATE_SEND_FOR_DOCUMENTS: 2,
    STAGE_CANDIDATE_GET_DOCUMENTS: 3,
    STAGE_CANDIDATE_WAIT_FOR_ENTER_DOCUMENTS: 4,
    STAGE_CANDIDATE_READY_TO_EMPLOYMENT: 4,
  },
  MENTOR_TYPE: {
    0: 'userList:mentorTypeSelect.basic',
    1: 'userList:mentorTypeSelect.cooridinator',
    2: 'userList:mentorTypeSelect.legalizer',
  },
  TYPE_OF_ORDER: {
    0: 'modifyOrderDialog:increaseLvlOfEmployment',
    1: 'modifyOrderDialog:decreaseLvlOfEmployment',
  },
  CAST_TRANSLATE: {
    miesiąc: 'employmentManagementDialog:dialog2.monthly',
    rok: 'employmentManagementDialog:dialog2.year',
  },
  YES_NO: {
    true: 'general.yes',
    false: 'general.no',
  },
  SEX: {
    man: 'general.man',
    woman: 'general.woman',
  },
};
