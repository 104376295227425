import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { authUser, setTokenDispatch } from 'shared/services/authService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import LoginFromLandingPage from './LoginFromLandingPage.style';

const mapStateToProps = store => {
  return {
    isFetching: store.auth.isFetching,
    isAuthorized: store.auth.isAuthorized,
  };
};

const mapDispatchToProps = {
  authUser,
  showSnackbarMessage,
  setTokenDispatch,
};

const LoginFromLandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(LoginFromLandingPage)));

LoginFromLandingPageContainer.displayName = 'LoginFromLandingPageContainer';

export default LoginFromLandingPageContainer;
