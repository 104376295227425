import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { RecruiterV2Service, getCurrentUser } from 'shared/services';
import RecruiterPersonalDetailsContent from './components/RecruiterPersonalDetailsContent';

const RecruiterPersonalDetailsView = ({ match, history, t, ...props }) => {
  const [recruiter, setRecruiter] = useState({});
  const [fetchDone, setFetchDone] = useState(false);

  const getRecruiterById = id => {
    RecruiterV2Service.getRecruiterV2(id)
      .then(({ data }) => {
        setRecruiter(data);
        if (!data.email) {
          history.push(PATHS.CANDIDATE.SHOW.replace(':id', data.id));
        }
        setFetchDone(true);
      })
      .catch(() => {
        //TODO: redirect to 404
        alert(t`candidate:dataError`);
      });
  };

  useEffect(() => {
    shouldUpdateRecruiter();
  }, []);

  const shouldUpdateRecruiter = () => {
    props.getCurrentUser();
    const { id } = match.params;

    if (!id) {
      getRecruiterById(props.currentUser.id);
    } else {
      getRecruiterById(id);
    }
  };
  const isSelfEdit =
    (recruiter && props.currentUser.id === recruiter.id) ||
    props.currentUser.isSuperUser;

  if (!isSelfEdit && fetchDone) {
    history.push(PATHS.DASHBOARD.INDEX);
  }
  return fetchDone ? (
    <RecruiterPersonalDetailsContent
      isSelfEdit={isSelfEdit}
      recruiterEdited={shouldUpdateRecruiter}
      recruiterDetails={recruiter}
      currentUser={props.currentUser}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getCurrentUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(RecruiterPersonalDetailsView)));
