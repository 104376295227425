import {
  Typography,
  withStyles,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from '@material-ui/core';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import AddIcon from 'shared/assets/img/icons/addBlack.svg';
import ArrowUp from 'shared/assets/img/icons/project-show/arrowUp.png';
import ExclamationIcon from 'shared/assets/img/icons/project-show/exclamation.png';
import NumIcon from 'shared/assets/img/icons/project-show/num.png';
import RedAlert from 'shared/assets/img/icons/project-show/redAlert.png';
import YellowAlert from 'shared/assets/img/icons/project-show/yellowAlert.png';
import { enums } from 'shared/enums';
import {
  getCandidateInProject,
  clearCurrentCandidate,
  getProjectCandidates,
} from 'shared/services';
import { getLocalizationData } from 'shared/utils/functions';
import { getAccommodations } from 'shared/utils/functions';
import ProjectAtsCandidateInProject from '../../../../../components/ProjectAtsCandidateInProject';
import EmploymentManagementAddAccommodation from '../EmploymentManagementAddAccommodation';
import EmploymentManagementAddLocation from '../EmploymentManagementAddLocation';
import EmploymentManagementDialog from '../EmploymentManagementDialog';
import { styles } from './EmploymentManagementTable.styles';

const EmploymentManagementTable = ({
  t,
  classes,
  variant,
  projectCandidatesEmploymentManagement,
  selectedOrders,
  setSelectedOrders,
  isCheckAll,
  setIsCheckAll,
  project,
  loadProjectCandidatesEmploymentManagement,
  clearSelected,
  accommodations,
  projectAvailableToMove,
  shouldLoadAccommodations,
  selectedFullObjects,
  disableAllClick = false,
  history,
  ...props
}) => {
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const headersConfig = [
    {
      name: 'candidateFullName',
      label: t`employmentStatusTable:personalData`,
    },
    {
      name: 'employmentStatus',
      label: t`employmentStatusTable:status`,
    },
    {
      name: 'localization.id',
      label: t`employmentStatusTable:workplace`,
    },
    {
      name: 'accommodation.id',
      label: t`employmentStatusTable:accommodation`,
    },
    {
      name: 'alert',
      label: <img src={ExclamationIcon} alt="exclamation" height="20" />,
    },
    {
      name: 'tableStartDate',
      label: t`employmentStatusTable:startDate`,
    },
    {
      name: 'tableEndDate',
      label: t`employmentStatusTable:endDate`,
    },
    {
      name: 'tableWeekNumber',
      label: <img src={NumIcon} alt="num" height="15" />,
    },
  ];

  const [rows, setRows] = useState([]);

  const [headerSort, setHeaderSort] = useState(headersConfig.map(() => 'off'));

  useEffect(() => {
    setRows(
      projectCandidatesEmploymentManagement.map(candidate => ({
        ...candidate,
        showStatusModal: false,
        showLocationModal: false,
        showAccommodationModal: false,
      }))
    );
  }, [projectCandidatesEmploymentManagement]);

  useEffect(() => {
    sort();
  }, [headerSort]);

  const getNextSortType = currentSortType =>
    ({
      off: 'asc',
      asc: 'desc',
    }[currentSortType] || 'off');

  const changeSortType = index => {
    const tmpHeaderSort = [...headerSort];
    const currentSortType = tmpHeaderSort[index];
    tmpHeaderSort[index] = getNextSortType(currentSortType);
    setHeaderSort(tmpHeaderSort);
  };

  const getCurrentSortIcon = index => {
    const currentSortType = headerSort[index];
    return (
      {
        off: <Typography className={classes.typographySort}>-</Typography>,
        asc: <img alt={'sortIcon'} src={ArrowUp} className={classes.arrow} />,
      }[currentSortType] || (
        <img
          alt={'sortIcon'}
          src={ArrowUp}
          className={`${classes.arrowDown} ${classes.arrow}`}
        />
      )
    );
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedOrders(rows.map(li => li.id));
    if (isCheckAll) {
      setSelectedOrders([]);
    }
  };

  const sort = () => {
    const sortedFieldsArray = [];
    const sortedOrderArray = [];
    headerSort.forEach((item, index) => {
      if (item !== 'off') {
        sortedFieldsArray.push(headersConfig[index].name);
        sortedOrderArray.push(item);
      }
    });
    if (!sortedFieldsArray.length) {
      return setRows(projectCandidatesEmploymentManagement);
    }
    const sortedRows = _.orderBy(rows, sortedFieldsArray, sortedOrderArray);
    setRows(sortedRows);
  };

  const getEmploymentStatus = projectCandidateEmploymentManagement =>
    t(
      enums.EMPLOYMENT_STATUS[
        projectCandidateEmploymentManagement.employmentStatus
      ]
    );

  const getAlertIcon = ({ errorType }) => {
    if (
      errorType ===
      enums.EMPLOYMENT_MANAGEMENT_HISTORY_ERROR_DATE_STATUS.RED_ERROR
    ) {
      return RedAlert;
    }
    if (
      errorType ===
      enums.EMPLOYMENT_MANAGEMENT_HISTORY_ERROR_DATE_STATUS.YELLOW_ERROR
    ) {
      return YellowAlert;
    }
    return null;
  };

  const editStatus = projectCandidateEmploymentManagement => {
    const { isHiringManager } = project.permissions;
    if (variant !== 'disabled' && !isHiringManager) {
      setSelectedOrders(
        _.uniq([
          ...selectedOrders,
          parseInt(projectCandidateEmploymentManagement.id),
        ])
      );
      const elementIndex = rows.findIndex(
        row => row.id === projectCandidateEmploymentManagement.id
      );
      const newRows = [...rows];
      newRows[elementIndex] = {
        ...newRows[elementIndex],
        showStatusModal: true,
      };
      setRows(newRows);
    }
  };

  const closeEditStatus = projectCandidateEmploymentManagement => {
    if (variant !== 'disabled') {
      const filterSelectedOrders = selectedOrders.filter(
        order => order !== parseInt(projectCandidateEmploymentManagement)
      );
      setSelectedOrders(_.uniq([...filterSelectedOrders]));
      const elementIndex = rows.findIndex(
        row => row.id === projectCandidateEmploymentManagement.id
      );
      const newRows = [...rows];
      newRows[elementIndex] = {
        ...newRows[elementIndex],
        showStatusModal: false,
      };
      setRows(newRows);
      clearSelected();
    }
  };

  const onClickWrapper = (nextFnc, ...args) => {
    if (!disableAllClick) {
      return nextFnc(...args);
    }
  };

  const { isMentor, isOwner, isHiringManager } = project.permissions;

  const handleClickFullName = ({ candidate }) => {
    history.push(PATHS.CANDIDATE.SHOW.replace(':id', candidate.id));
  };

  const handleClickFullNameHiringManager = ({ candidate }) => {
    props
      .clearCurrentCandidate()
      .then(() => props.getProjectCandidates(project.id))
      .then(() => props.getCandidateInProject(project.id, candidate.id))
      .then(({ data }) => {
        setSelectedCandidate(data);
      });
  };

  return (
    <div className={classes.container}>
      <Table stickyheader="true" className={classes.table}>
        <TableHead
          className={`${
            variant === 'active'
              ? classes.tableHeadActive
              : classes.tableHeadDisabled
          } ${classes.tableHead}`}
        >
          <TableRow>
            <TableCell padding="checkbox" className={classes.checkboxCell}>
              {variant !== 'disabled' && (
                <input
                  name="selectAll"
                  type="checkbox"
                  className={classes.checkbox}
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
              )}
            </TableCell>
            {headersConfig.map(({ label }, index) => (
              <TableCell
                size="small"
                className={`${classes.thItem} ${
                  index > 3 && classes.tableHeadGray
                }`}
                key={index}
                onClick={() => onClickWrapper(changeSortType, index)}
              >
                <div className={classes.thRow}>
                  <Typography className={classes.typography}>
                    {label}
                  </Typography>
                  {getCurrentSortIcon(index)}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((projectCandidateEmploymentManagement, index) => (
            <>
              <TableRow
                key={projectCandidateEmploymentManagement.id}
                className={index % 2 ? classes.even : classes.odd}
              >
                <TableCell padding="checkbox" className={classes.contentCell}>
                  <input
                    disabled={variant === 'disabled'}
                    id={projectCandidateEmploymentManagement.id}
                    type="checkbox"
                    checked={
                      variant !== 'disabled' &&
                      selectedOrders.includes(
                        projectCandidateEmploymentManagement.id
                      )
                    }
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedOrders(
                          _.uniq([...selectedOrders, parseInt(e.target.id)])
                        );
                      } else {
                        const filterSelectedOrders = selectedOrders.filter(
                          order => order !== parseInt(e.target.id)
                        );
                        setSelectedOrders(_.uniq([...filterSelectedOrders]));
                      }
                    }}
                  />
                </TableCell>
                <TableCell
                  size="small"
                  className={`${classes.contentCell} ${classes.pointer}`}
                  onClick={() => {
                    if (isOwner || isMentor) {
                      handleClickFullName(projectCandidateEmploymentManagement);
                    } else if (isHiringManager) {
                      handleClickFullNameHiringManager(
                        projectCandidateEmploymentManagement
                      );
                    }
                  }}
                >
                  <Typography>
                    {projectCandidateEmploymentManagement.candidateFullName}
                  </Typography>
                </TableCell>
                <TableCell
                  size="small"
                  className={`${classes.contentCell} ${classes.pointer}`}
                  onClick={() =>
                    onClickWrapper(
                      editStatus,
                      projectCandidateEmploymentManagement
                    )
                  }
                >
                  <Typography>
                    {getEmploymentStatus(projectCandidateEmploymentManagement)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.contentCell} size="small">
                  <Typography>
                    {getLocalizationData(
                      projectCandidateEmploymentManagement.localization
                    )}
                  </Typography>
                  <img
                    alt="addIcon"
                    src={AddIcon}
                    className={classes.addIcon}
                    onClick={() =>
                      onClickWrapper(() => {
                        const { isHiringManager, isMentorLegalization } =
                          project.permissions;
                        if (isHiringManager || isMentorLegalization) {
                          return;
                        }
                        const elementIndex = rows.findIndex(
                          row =>
                            row.id === projectCandidateEmploymentManagement.id
                        );
                        const newRows = [...rows];
                        newRows[elementIndex] = {
                          ...newRows[elementIndex],
                          showLocationModal: true,
                        };
                        setRows(newRows);
                      })
                    }
                  />
                </TableCell>
                <TableCell className={classes.contentCell} size="small">
                  <Typography>
                    {getLocalizationData(
                      projectCandidateEmploymentManagement.accommodation
                    )}
                  </Typography>
                  {}
                  <img
                    alt="addIcon"
                    src={AddIcon}
                    className={classes.addIcon}
                    onClick={() => {
                      onClickWrapper(() => {
                        const { isHiringManager, isMentorLegalization } =
                          project.permissions;
                        if (isHiringManager || isMentorLegalization) {
                          return;
                        }
                        const elementIndex = rows.findIndex(
                          row =>
                            row.id === projectCandidateEmploymentManagement.id
                        );
                        const newRows = [...rows];
                        newRows[elementIndex] = {
                          ...newRows[elementIndex],
                          showAccommodationModal: true,
                        };
                        setRows(newRows);
                      });
                    }}
                  />
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  {getAlertIcon(projectCandidateEmploymentManagement) !==
                    null && (
                    <img
                      src={getAlertIcon(projectCandidateEmploymentManagement)}
                      alt="alert"
                      height="20"
                      className={classes.alert}
                    />
                  )}
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>
                    {projectCandidateEmploymentManagement.tableStartDate}
                  </Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>
                    {projectCandidateEmploymentManagement.tableEndDate}
                  </Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>
                    {projectCandidateEmploymentManagement.tableWeekNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              {projectCandidateEmploymentManagement.showLocationModal && (
                <EmploymentManagementAddLocation
                  onClose={() => {
                    const elementIndex = rows.findIndex(
                      row => row.id === projectCandidateEmploymentManagement.id
                    );
                    const newRows = [...rows];
                    newRows[elementIndex] = {
                      ...newRows[elementIndex],
                      showLocationModal: false,
                    };
                    setRows(newRows);
                  }}
                  isOpen={
                    projectCandidateEmploymentManagement.showLocationModal
                  }
                  projectOrder={project.projectOrders[0]}
                  project={project}
                  clearSelected={clearSelected}
                  projectCandidateEmploymentManagement={
                    projectCandidateEmploymentManagement
                  }
                  loadProjectCandidatesEmploymentManagement={
                    loadProjectCandidatesEmploymentManagement
                  }
                />
              )}
              {projectCandidateEmploymentManagement.showAccommodationModal && (
                <EmploymentManagementAddAccommodation
                  projectCandidateEmploymentManagement={
                    projectCandidateEmploymentManagement
                  }
                  onClose={() => {
                    const elementIndex = rows.findIndex(
                      row => row.id === projectCandidateEmploymentManagement.id
                    );
                    const newRows = [...rows];
                    newRows[elementIndex] = {
                      ...newRows[elementIndex],
                      showAccommodationModal: false,
                    };
                    setRows(newRows);
                  }}
                  isOpen={
                    projectCandidateEmploymentManagement.showAccommodationModal
                  }
                  accommodations={getAccommodations(accommodations)}
                  project={project}
                  loadProjectCandidatesEmploymentManagement={
                    loadProjectCandidatesEmploymentManagement
                  }
                  shouldLoadAccommodations={shouldLoadAccommodations}
                  clearSelected={clearSelected}
                />
              )}
              {selectedCandidate && (
                <ProjectAtsCandidateInProject
                  readOnly={true}
                  projectId={project.id}
                  industryCategories={[]}
                  selectedCandidateStageNumber={5}
                  reloadAtsHandler={() => {}}
                  open={selectedCandidate}
                  ownerInProject={selectedCandidate.owner}
                  handleClose={() => setSelectedCandidate(null)}
                />
              )}

              {projectCandidateEmploymentManagement.showStatusModal && (
                <EmploymentManagementDialog
                  projectAvailableToMove={projectAvailableToMove}
                  project={project}
                  clearSelected={clearSelected}
                  shouldLoadAccommodations={shouldLoadAccommodations}
                  accommodations={accommodations}
                  variant="active"
                  loadProjectCandidatesEmploymentManagement={
                    loadProjectCandidatesEmploymentManagement
                  }
                  isOpen={projectCandidateEmploymentManagement.showStatusModal}
                  selectedFullObjects={selectedFullObjects}
                  onClose={() => {
                    closeEditStatus(projectCandidateEmploymentManagement);
                  }}
                />
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const mapDispatchToProps = {
  clearCurrentCandidate,
  getCandidateInProject,
  getProjectCandidates,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(EmploymentManagementTable))));
