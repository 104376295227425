import {
  Avatar,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import {
  CandidateV2Service,
  getRecruitersWithLimit,
  getRecruiters,
  getNotifications,
  setNotificationRead,
} from '../../services';
import { showSnackbarMessage } from '../../store/messages/actions';
import { getUserAvatarSrc } from '../../utils/assets';
import { getTableRowPerPageFromStorage } from '../../utils/localStorage';
import { Translate } from '../index';
import { styles } from './AssignCandidateToRecruiterButton.styles';

const AssignCandidateToRecruiterButton = ({
  candidate,
  isOpen,
  onClose,
  t,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [choiceButtonStatus, setChoiceButtonStatus] = useState(false);

  const loadData = async (limit = 12, offset = 0, filters = {}) => {
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(props.match.path) || 12
      : limit;

    let params = {
      filters: { ...filters },
    };

    await props.getRecruitersWithLimit(limitToSearch, offset, params);
  };

  const handleReloadElements = ({ limit, offset, filters }) => {
    return loadData(limit, offset, filters);
  };

  useEffect(() => {
    setIsLoading(true);
    loadData().then(() => {
      setIsLoading(false);
    });
  }, [isOpen]);

  const handleSelect = async recruiterId => {
    const body = {
      ownerToBe: recruiterId,
    };

    CandidateV2Service.editCandidate(candidate.id, body, {
      stage: 'ownerToBe',
    })
      .then(() => {
        setChoiceButtonStatus(false);
        props.showSnackbarMessage(t`candidate:show.reqSent`);
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        props.showSnackbarMessage(t`candidate:show.unableToAssign`);
      });
  };

  const handleRemoveOwner = async () => {
    const body = {
      ownerToBe: null,
    };

    CandidateV2Service.editCandidate(candidate.id, body, {
      stage: 'ownerToBe',
    })
      .then(() => {
        setChoiceButtonStatus(false);
        props.showSnackbarMessage(t`candidate:show.mentorDel`);
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        props.showSnackbarMessage(t`candidate:show.unableToDel`);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  const getContent = recruiter => {
    if (!recruiter) {
      return null;
    }
    const {
      currentUser: { id, owner, ownerToBe },
    } = props;

    const avatar = getUserAvatarSrc(recruiter);

    const currentOwner = owner ? owner : ownerToBe;
    const isCandidateOwner = currentOwner && recruiter.id === currentOwner.id;

    let secondaryText = {};

    secondaryText =
      recruiter && recruiter.aboutMe ? ReactHtmlParser(recruiter.aboutMe) : '';

    return (
      recruiter &&
      recruiter.id !== id && (
        <ListItem key={recruiter.id}>
          <Grid container>
            <Grid item xs={2} sm={2} md={2}>
              <Avatar
                src={avatar}
                aria-label={recruiter.firstName + ' ' + recruiter.lastName}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <ListItemText
                primary={recruiter.firstName + ' ' + recruiter.lastName}
                secondary={secondaryText}
              />
            </Grid>
            {isCandidateOwner ? null : (
              <Grid item xs={2} sm={2} md={2}>
                <Button
                  disabled={choiceButtonStatus}
                  onClick={() => handleSelect(recruiter.id)}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  {t('action.choose')}
                </Button>
              </Grid>
            )}
            <Grid item xs={6} sm={6} md={6}>
              {ownerToBe && ownerToBe.id === recruiter.id && (
                <Translate text={t`candidate:show.awaitingApproval`} />
              )}
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              {isCandidateOwner && candidate.id === props.currentUser.id && (
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={handleRemoveOwner}
                    aria-label={t`candidate:show.delMentor`}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </Grid>
          </Grid>
        </ListItem>
      )
    );
  };

  const getRecruiterContent = recruiter => {
    return getContent(recruiter);
  };

  const getWrapper = options => {
    let { content, pagination, filtering } = options;

    const candidateOwner = candidate.owner
      ? candidate.owner
      : candidate.ownerToBe;
    const owner = getContent(candidateOwner);

    return (
      <React.Fragment>
        <ExpansionComponent
          title={t('filters.name')}
          textOnTop={t('filters.description')}
        >
          {filtering}
        </ExpansionComponent>
        {owner !== null && <List component={'ul'}>{owner}</List>}
        <Divider />
        <DialogContent>
          <List component={'nav'}>{content}</List>
        </DialogContent>
        {pagination}
      </React.Fragment>
    );
  };

  const { currentRecruitersLimitedList, currentRecruitersLimitedListCounter } =
    props;

  const filteringOptions = [
    {
      key: 'firstName',
      type: 'text',
      name: t('recruiter:field.firstName'),
    },
    {
      key: 'lastName',
      type: 'text',
      name: t('recruiter:field.lastName'),
    },
  ];

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="mentor-dialog"
      >
        <DialogTitle id="mentor-dialog">{t`candidate:show.chooseRecruiter`}</DialogTitle>
        {isLoading ? (
          <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
        ) : (
          <ElementsListAsync
            storageKey={props.match.path}
            rowsPerPage={getTableRowPerPageFromStorage(props.match.path)}
            handleReloadElements={handleReloadElements}
            elements={currentRecruitersLimitedList}
            filteringOptions={filteringOptions}
            elementsCount={currentRecruitersLimitedListCounter}
            load={isLoading}
            getContent={getRecruiterContent}
            getWrapper={getWrapper}
            scrollUp={false}
          />
        )}
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    notifications: store.notifications.list,
    currentRecruitersLimitedList: store.recruiters.currentRecruitersLimitedList,
    currentRecruitersLimitedListCounter:
      store.recruiters.currentRecruitersLimitedListCounter,
  };
};

const mapDispatchToProps = {
  getNotifications,
  setNotificationRead,
  getRecruitersWithLimit,
  getRecruiters,
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withStyles(styles)(withTranslation()(AssignCandidateToRecruiterButton))
  )
);
