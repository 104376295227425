import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pl',
    ns: [
      'adminOrganization',
      'candidate',
      'project',
      'core',
      'dashboard',
      'landing',
      'login',
      'menu',
      'notification',
      'organization',
      'recruiter',
      'summary',
      'footer',
      'register',
      'projectCreator',
      'projectDetails',
      'candidateCreator',
      'candidateDetails',
      'newAts',
      'ats1ats2',
      'ats2ats3',
      'ats3ats4',
      'ats4ats5',
      'projectOrdersTable',
      'projectOrderViewContent',
      'modifyOrderDialog',
      'cancelOrderDialog',
      'employmentStatusTable',
      'employmentManagementDialog',
      'userList',
      'citySeoTable',
      'configCmsTable',
      'filterAts',
      'adminCitySeo',
      'addCmsForm',
      'addCompetenceForm',
      'addIndustryCategoryForm',
      'addOrganizationForm',
      'fileInput',
      'isAuthorized',
      'skillsInputField',
      'organizationsTable',
      'projectForm',
      'recruiterOrganizationTable',
      'topAppBar',
      'assign',
      'helpDesk',
      'atsSubMenu',
      'atsEmploymentProcess',
      'export',
      'employmentType',
      'workStyles',
      'workStylesDescriptions',
      'help',
    ],
    defaultNS: 'core',
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupQuerystring: 'lang',
      lookupCookie: 'i18nextLng',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage', 'cookie'],
      htmlTag: document.documentElement,
    },
    load: 'languageOnly',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
