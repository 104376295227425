import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RecruiterOrganizationPanel from '../../components/RecruiterOrganizationPanel';

export class RecruiterOrganizationView extends Component {
  render() {
    const {
      currentUser: { isRecruiter = false },
    } = this.props;

    return (
      <React.Fragment>
        {isRecruiter && <RecruiterOrganizationPanel />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

RecruiterOrganizationView.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(RecruiterOrganizationView);
