import {
  Button,
  Dialog,
  DialogActions,
  withStyles,
  Slider,
} from '@material-ui/core';
import React from 'react';
import Cropper from 'react-easy-crop';
import { withTranslation } from 'react-i18next';
import { getCroppedImg } from 'shared/utils/imageCropper';

const styles = theme => ({
  mainWindow: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '25rem',
    height: '25rem',
    overflow: 'hidden',
  },
  cropContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 80,
  },
  controls: {
    position: 'absolute',
    left: '50%',
    width: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
  },
});

class ImageCropper extends React.Component {
  state = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
    croppedAreaPixels: null,
    cropShape: 'rect',
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  handleSaveImage = async () => {
    const { image, setOneFile, index, files } = this.props;
    const { croppedAreaPixels } = this.state;
    try {
      const modifiedImage = await getCroppedImg(image, croppedAreaPixels);
      const filedToEdit = [...files];
      filedToEdit[index] = modifiedImage;
      await setOneFile({ files: [...filedToEdit], cropperOpen: false });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { image, classes, isOpen, handleClose, t } = this.props;
    const { crop, zoom } = this.state;
    return (
      <Dialog open={isOpen} onClose={handleClose}>
        <div className={classes.mainWindow}>
          <div className={classes.mainWindow}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              cropShape={this.props.cropShape || this.state.cropShape}
              aspect={this.props.aspect || this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              restrictPosition={false}
            />
          </div>
        </div>
        <DialogActions>
          <div className={classes.controls}>
            <Slider
              value={zoom}
              min={0.1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => this.onZoomChange(zoom)}
              classes={{ container: 'slider' }}
            />
          </div>
        </DialogActions>
        <DialogActions>
          <div>
            <Button onClick={handleClose}>{t`fileInput:close`}</Button>
            <Button onClick={this.handleSaveImage} variant="contained">
              {t('action.choose')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation()(ImageCropper));
