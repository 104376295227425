import { object } from 'yup';

export const MODEL = {
  candidateRatingFromRecruiter: 'candidateRatingFromRecruiter',
  candidateRatingFromRecruiterDescription:
    'candidateRatingFromRecruiterDescription',
};

export const initialValues = {};

export const validationSchema = t => object().shape({});
