import { createReducer } from '@reduxjs/toolkit';
import { showRightDrawer, hideRightDrawer } from './actions';

const initialState = {
  component: null,
  open: false,
};

export default createReducer(initialState, builder =>
  builder
    .addCase(showRightDrawer, (state, action) => {
      state.component = action.payload;
      state.open = true;
    })
    .addCase(hideRightDrawer, state => {
      state.component = initialState.component;
      state.open = initialState.open;
    })
);
