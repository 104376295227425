import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Button,
  withStyles,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close, PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { enums } from '../../enums';
import { ProjectEmploymentProcessService } from '../../services';
import { getSelectItemsTranslate, sortItemsBy } from '../../utils/assets';
import { dateAddBusiness, dateSubtract } from '../../utils/date';
import { isShorForm } from '../../utils/functions';
import Select from '../Select';
import TextField from '../TextField';
import { styles } from './PopUpATS1ATS2.styles';
import InfoAboutCandidate from './components';
import {
  getLocalizationFromProjectOrder,
  getSelectedLocalization,
} from './utils';

const PopUpATS1ATS2 = ({
  isOpen,
  setIsOpen,
  onClose,
  classes,
  project,
  checkedCandidate = [],
  loadProjectCandidate,
  projectOrderLocalizations,
  projectEmploymentProcess: { id, candidate },
  t,
}) => {
  const { userEmploymentProcess } = candidate;

  const isMultiEdit = !!checkedCandidate.length;

  const localizations = getLocalizationFromProjectOrder(
    projectOrderLocalizations
  );

  const getInitialResidenceTitle = (userEmploymentProcess, candidate) => {
    if (candidate.candidateResidenceTitle === null) {
      return null;
    }

    if (
      userEmploymentProcess &&
      userEmploymentProcess.residenceTitle !== null
    ) {
      return userEmploymentProcess.residenceTitle;
    }

    return candidate.candidateResidenceTitle;
  };

  const showResidenceTitle = candidate.candidateResidenceTitle !== null;

  const formData = {
    residenceTitle: getInitialResidenceTitle(userEmploymentProcess, candidate),
    employmentTypeRequest:
      userEmploymentProcess &&
      userEmploymentProcess.employmentTypeRequest !== null &&
      !isMultiEdit
        ? userEmploymentProcess.employmentTypeRequest
        : '',
    submittingEmploymentRequestToOfficeDate:
      userEmploymentProcess &&
      userEmploymentProcess.submittingEmploymentRequestToOfficeDate !== null &&
      !isMultiEdit
        ? userEmploymentProcess.submittingEmploymentRequestToOfficeDate
        : '',
    requestedEmploymentStartDate:
      userEmploymentProcess &&
      userEmploymentProcess.requestedEmploymentStartDate !== null &&
      !isMultiEdit
        ? userEmploymentProcess.requestedEmploymentStartDate
        : '',
    requestedEmploymentEndDate:
      userEmploymentProcess &&
      userEmploymentProcess.requestedEmploymentEndDate !== null &&
      !isMultiEdit
        ? userEmploymentProcess.requestedEmploymentEndDate
        : '',
    estimatedStartEmploymentDate:
      userEmploymentProcess &&
      userEmploymentProcess.estimatedStartEmploymentDate !== null &&
      !isMultiEdit
        ? userEmploymentProcess.estimatedStartEmploymentDate
        : '',
    estimatedEndEmploymentDate:
      userEmploymentProcess &&
      userEmploymentProcess.estimatedEndEmploymentDate !== null &&
      !isMultiEdit
        ? userEmploymentProcess.estimatedEndEmploymentDate
        : '',
    localization:
      userEmploymentProcess && userEmploymentProcess.localization !== null
        ? getSelectedLocalization(
            userEmploymentProcess.localization,
            localizations
          )
        : '',
  };

  const validationSchema = yup.object(
    {
      employmentTypeRequest: yup
        .number()
        .required(t('ats1ats2:validation.employmentTypeRequest')),

      residenceTitle: showResidenceTitle
        ? yup.number().required(t('ats1ats2:validation.employmentTypeRequest'))
        : yup.number().nullable(),

      submittingEmploymentRequestToOfficeDate: yup
        .string()
        .when('employmentTypeRequest', {
          is: employmentTypeRequest => !isShorForm(employmentTypeRequest),
          then: yup
            .string()
            .required(
              t('ats1ats2:validation.submittingEmploymentRequestToOfficeDate')
            ),
          otherwise: yup.string(),
        }),
      requestedEmploymentStartDate: yup.date().when('employmentTypeRequest', {
        is: employmentTypeRequest => !isShorForm(employmentTypeRequest),
        then: yup
          .date()
          .min(
            dateSubtract(new Date(), 1),
            t`employmentManagementDialog:dialog2:validation.crossingBorderDate`
          )
          .required(
            t('ats1ats2:validation.submittingEmploymentRequestToOfficeDate')
          ),
        otherwise: yup.date(),
      }),
      requestedEmploymentEndDate: yup.date().when('employmentTypeRequest', {
        is: employmentTypeRequest => !isShorForm(employmentTypeRequest),
        then: yup
          .date()
          .min(
            yup.ref('requestedEmploymentStartDate'),
            t`ats2ats3:validation.date`
          )
          .required(t`ats2ats3:validation.isRequired`),
        otherwise: yup.date(),
      }),
      estimatedStartEmploymentDate: yup.date().when('employmentTypeRequest', {
        is: employmentTypeRequest => isShorForm(employmentTypeRequest),
        then: yup
          .date()
          .min(
            dateSubtract(new Date(), 1),
            t`employmentManagementDialog:dialog2:validation.crossingBorderDate`
          )
          .required(t`ats2ats3:validation.isRequired`),
        otherwise: yup.date(),
      }),
      estimatedEndEmploymentDate: yup.date().when('employmentTypeRequest', {
        is: employmentTypeRequest => isShorForm(employmentTypeRequest),
        then: yup
          .date()
          .min(
            yup.ref('estimatedStartEmploymentDate'),
            t`ats2ats3:validation.date`
          )
          .required(t`ats2ats3:validation.isRequired`),
        otherwise: yup.date(),
      }),
      localization: yup.string().when('employmentTypeRequest', {
        is: employmentTypeRequest => isShorForm(employmentTypeRequest),
        then: yup.string().required(t`ats2ats3:validation.isRequired`),
        otherwise: yup.string(),
      }),
    },
    [
      'employmentTypeRequest',
      'submittingEmploymentRequestToOfficeDate',
      'requestedEmploymentStartDate',
      'requestedEmploymentEndDate',
      'estimatedStartEmploymentDate',
      'estimatedEndEmploymentDate',
      'localization',
    ]
  );

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({
      employmentTypeRequest,
      submittingEmploymentRequestToOfficeDate,
      requestedEmploymentStartDate,
      requestedEmploymentEndDate,
      estimatedStartEmploymentDate,
      estimatedEndEmploymentDate,
      localization,
      residenceTitle,
    }) => {
      const body = {
        employmentTypeRequest,
        residenceTitle,
        submittingEmploymentRequestToOfficeDate: !isShorForm(
          employmentTypeRequest
        )
          ? submittingEmploymentRequestToOfficeDate
          : null,
        requestedEmploymentStartDate: !isShorForm(employmentTypeRequest)
          ? requestedEmploymentStartDate
          : null,
        requestedEmploymentEndDate: !isShorForm(employmentTypeRequest)
          ? requestedEmploymentEndDate
          : null,
        localization: isShorForm(employmentTypeRequest)
          ? { id: localization }
          : null,
        estimatedStartEmploymentDate: isShorForm(employmentTypeRequest)
          ? estimatedStartEmploymentDate
          : null,
        estimatedEndEmploymentDate: isShorForm(employmentTypeRequest)
          ? estimatedEndEmploymentDate
          : null,
        projectCandidateEmploymentProcesses: isMultiEdit
          ? _.uniq([
              ...checkedCandidate.map(
                projectEmploymentProcess => projectEmploymentProcess.id
              ),
              id,
            ])
          : _.uniq([...checkedCandidate, id]),
      };

      ProjectEmploymentProcessService.updateProjectEmploymentProcess(body, 2)
        .then(() => {
          loadProjectCandidate();
          setIsOpen(false);
        })
        .catch(error => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    if (
      formik.values.submittingEmploymentRequestToOfficeDate === null ||
      formik.values.submittingEmploymentRequestToOfficeDate === ''
    ) {
      formik.setFieldValue('requestedEmploymentStartDate', '');
      return;
    }
    formik.setFieldValue(
      'requestedEmploymentStartDate',
      dateAddBusiness(
        formik.values.submittingEmploymentRequestToOfficeDate,
        7
      ).format('YYYY-MM-DD')
    );
  }, [formik.values.submittingEmploymentRequestToOfficeDate]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component="div"
          variant="h6"
        >{t`ats1ats2:dialogTitle`}</Typography>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.content}>
          {showResidenceTitle && (
            <Select
              label={t`ats3ats4:residenceTitle`}
              variant="transparent"
              formik={formik}
              name="residenceTitle"
              items={sortItemsBy(
                getSelectItemsTranslate(enums.RESIDENCE_TITLE, t),
                'name'
              )}
              fontBold={true}
            />
          )}

          <Select
            label={t`ats1ats2:employmentTypeRequest.label`}
            variant="transparent"
            formik={formik}
            name="employmentTypeRequest"
            items={getSelectItemsTranslate(enums.EMPLOYMENT_TYPE_REQUEST, t)}
            fontBold={true}
          />
          {isShorForm(formik.values.employmentTypeRequest) && (
            <>
              <Typography
                variant="body1"
                className={classes.header}
              >{t`ats1ats2:estimatedEmploymentDate`}</Typography>
              <Grid container className={classes.employmentDate}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Grid item xs={1} sm={1} md={1}>
                          <PowerSettingsNew className={classes.powerOnBtn} />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                          <TextField
                            type="date"
                            name="estimatedStartEmploymentDate"
                            variant="transparent"
                            formik={formik}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Grid item xs={1} sm={1} md={1}>
                          <PowerSettingsNew className={classes.powerOffBtn} />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                          <TextField
                            type="date"
                            name="estimatedEndEmploymentDate"
                            variant="transparent"
                            formik={formik}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {!isShorForm(formik.values.employmentTypeRequest) && (
            <>
              <Grid container className={classes.employmentRequest}>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography>{t`ats1ats2:datePicker`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={1} sm={1} md={1} />
                      <Grid item xs={9} sm={9} md={9}>
                        <TextField
                          type="date"
                          name="submittingEmploymentRequestToOfficeDate"
                          variant="transparent"
                          formik={formik}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className={classes.employmentDate}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography>{t`ats1ats2:employmentDate`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid
                          container
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Grid item xs={1} sm={1} md={1}>
                            <PowerSettingsNew className={classes.powerOnBtn} />
                          </Grid>
                          <Grid item xs={9} sm={9} md={9}>
                            <TextField
                              type="date"
                              name="requestedEmploymentStartDate"
                              variant="transparent"
                              formik={formik}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid
                          container
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Grid item xs={1} sm={1} md={1}>
                            <PowerSettingsNew className={classes.powerOffBtn} />
                          </Grid>
                          <Grid item xs={9} sm={9} md={9}>
                            <TextField
                              type="date"
                              name="requestedEmploymentEndDate"
                              variant="transparent"
                              formik={formik}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <InfoAboutCandidate
                project={project}
                candidates={isMultiEdit ? checkedCandidate : [{ candidate }]}
              />
            </>
          )}
        </DialogContent>
        {isShorForm(formik.values.employmentTypeRequest) && (
          <DialogContent>
            <Typography
              variant="body1"
              className={classes.header}
            >{t`ats1ats2:localization`}</Typography>
            <Select
              formik={formik}
              label={t`ats1ats2:selectLab`}
              name="localization"
              items={localizations}
            />
          </DialogContent>
        )}
        <Button type="submit" className={classes.saveBtn}>
          {t`ats1ats2:saveBtn`}
        </Button>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(PopUpATS1ATS2);
