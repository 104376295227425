export const styles = theme => ({
  atsIcons: {
    transform: 'scale(1.3)',
    margin: 0,
    padding: 0,
  },
  paperAtsStage: {
    display: 'flex',
    alignItems: 'center',
    background: '#35bfe4',
    height: '100%',
    margin: 0,
    textTransform: 'capitalize',
    borderRadius: 0,
    borderRight: '1px solid #fff',
    [theme.breakpoints.down('md')]: {
      borderLeft: '1px solid #fff',
    },
  },
  AtsHeaderTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    fontFamily: 'Barlow Semi Condensed, sans-serif',
    margin: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  iconsAtsHeader: {
    color: 'white',
    cursor: 'pointer',
  },
});
