import { withStyles } from '@material-ui/core/styles';
import MenuBar from './MenuBar';

const styles = theme => ({
  MenuBar: {
    paddingTop: 20,
    paddingBottom: 20,
    background: 'transparent',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    maxWidth: 1280,
    margin: '0px auto',
  },
  toolBar: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  toolBarContainer: {
    alignItems: 'center',
  },
  languageContainer: {
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -15%)',
    position: 'absolute',
  },
});

export default withStyles(styles)(MenuBar);
