const commonAutosuggestInput = {
  width: 'calc(100% - 40px)',
  height: 30,
  padding: '14px 20px',
  transition: 'border 0.2s',
  borderRadius: 30,
};

const commonContainer = theme => ({
  padding: 10,
  '& .react-autosuggest__container': { position: 'relative', width: '100%' },
  '& .react-autosuggest__input': {
    ...commonAutosuggestInput,
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  '& .react-autosuggest__input:hover': {
    border: '1px solid #000',
  },
  '& .react-autosuggest__input--focused': { outline: 'none' },
  '& .react-autosuggest__suggestions-container': { display: 'none' },
  '& .react-autosuggest__suggestions-container--open': {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: '100%',
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    zIndex: 2,
    maxHeight: 300,
    overflowY: 'scroll',
  },
  '& .react-autosuggest__suggestions-list': {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

export const styles = theme => ({
  container: { ...commonContainer(theme) },
  containerError: {
    ...commonContainer(theme),
    '& .react-autosuggest__input': {
      ...commonAutosuggestInput,
      border: `1px solid ${theme.palette.error.main}`,
    },
    '& .react-autosuggest__input::placeholder': {
      color: theme.palette.error.main,
    },
    '& .react-autosuggest__input:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  floatingLabel: {
    position: 'absolute',
    zIndex: 2,
    padding: '0 5px',
    backgroundColor: '#fff',
    top: -8,
    left: 16,
    '& span': { color: theme.palette.text.secondary },
  },
});
