import axios from 'axios';

const getStorage = name => {
  return window.localStorage.getItem(name);
};
const API_URL = process.env.REACT_APP_API_URL;
const SERVER_ERROR_CODES = [
  500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511,
];

axios.interceptors.response.use(
  response => response,
  error => {
    const isNetworkError = !error.response;

    if (isNetworkError) {
      console.log('Network error');
    } else {
      const responseStatusCode = error.response.status;
      const isServerError = SERVER_ERROR_CODES.includes(responseStatusCode);
      console.log('Network ok', isServerError);
    }

    return Promise.reject(error);
  }
);

const getToken = () => {
  return getStorage('token') ? 'Bearer ' + getStorage('token') : null;
};

const getConfig = () => ({
  headers: {
    Authorization: getToken(),
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});

export const ApiService = {
  get(path, params = {}) {
    const url = API_URL + path;
    const paramsConfig = { ...params };
    return axios.get(url, { ...getConfig(), params: paramsConfig });
  },
  post(path, data, params = {}) {
    const url = API_URL + path;
    const paramsConfig = { ...params };
    return axios.post(url, data, { ...getConfig(), params: paramsConfig });
  },

  put(path, data, params = {}) {
    const url = API_URL + path;
    const paramsConfig = { ...params };
    return axios.put(url, data, { ...getConfig(), params: paramsConfig });
  },

  delete(path) {
    const url = API_URL + path;

    return axios.delete(url, getConfig());
  },
};
