import {
  Grid,
  IconButton,
  Avatar,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Tooltip,
  ListItemSecondaryAction,
  withStyles,
} from '@material-ui/core';
import {
  Error as ErrorIcon,
  Alarm as AlarmIcon,
  // CompareArrows as CompareArrowsIcon,
  AccountBox as AccountBoxIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getUserAvatarSrc } from 'shared/utils/assets';
import ElementList from '../../../components/list/ElementsList';

const styles = theme => ({
  container: {
    marginTop: 16,
  },
  actions: {
    borderTop: '1px solid #e9e9e9',
  },
  buttonShow: {
    background: '#F5F5F5',
    color: '#52a3fc',
    borderRadius: '0 0 10px 10px',
    width: '100%',
  },
  avatarRecruiter: {
    width: 80,
    height: 80,
  },
  rightIcon: {
    marginLeft: theme.spacing(),
    width: 15,
  },
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: -16,
  },
  alertList: {
    color: theme.palette.jobllegroRedAlert,
  },
  waitingList: {
    color: theme.palette.jobllegroBlue,
  },
});

export class RecruiterInProjectList extends Component {
  getContentRecruiter = recruiter => {
    const avatar = getUserAvatarSrc(recruiter.recruiter);
    const { candidateId, classes, t } = this.props;
    const { project, confirmationStatus } = recruiter;
    const { permissions = {} } = project;
    return (
      <React.Fragment>
        {permissions.isOwner && (
          <React.Fragment>
            {confirmationStatus === 2 ||
            confirmationStatus === 5 ||
            confirmationStatus === undefined ? (
              <Tooltip title={t('action.change')} placement="top">
                <ListItemIcon>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={this.props.onChangeRecruiterHandle.bind(
                      this,
                      project.id,
                      recruiter.recruiter.id,
                      candidateId
                    )}
                    aria-label="Change Recruiter"
                  >
                    {/*<CompareArrowsIcon/>*/}
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
            ) : confirmationStatus === 3 || confirmationStatus === 6 ? (
              <Tooltip
                title={t('recruiter:form.recruiterRejectedCooperation')}
                placement="bottom"
              >
                <ListItemIcon>
                  <IconButton
                    style={{ padding: 0 }}
                    aria-label="Canceled Recruiter"
                  >
                    <ErrorIcon className={classes.alertList} />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
            ) : (
              <Tooltip
                title={t('recruiter:form.recruiterDoesNotConfirmCooperation')}
                placement="bottom"
              >
                <ListItemIcon>
                  <IconButton
                    style={{ padding: 0 }}
                    aria-label="Pending Recruiter"
                  >
                    <AlarmIcon className={classes.waitingList} />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
            )}
          </React.Fragment>
        )}
        <Avatar
          src={avatar}
          component={Link}
          to={PATHS.RECRUITER.SHOW.replace(':id', recruiter.recruiter.id)}
        />
        <ListItemText
          primary={
            recruiter.recruiter.firstName + ' ' + recruiter.recruiter.lastName
          }
          secondary={recruiter.recruiter.email}
        />
        <ListItemSecondaryAction>
          <IconButton
            style={{ padding: 0 }}
            component={Link}
            to={PATHS.RECRUITER.SHOW.replace(':id', recruiter.recruiter.id)}
            aria-label="View Recruiter"
          >
            <AccountBoxIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </React.Fragment>
    );
  };

  getContent = recruiter => {
    const content = this.getContentRecruiter(recruiter);
    return <ListItem key={recruiter.recruiter.id || 0}>{content}</ListItem>;
  };

  getWrapper = options => {
    const { t } = this.props;
    const { content, pagination } = options;

    return (
      <Grid container alignItems="flex-start" spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List
                  subheader={
                    <ListSubheader component="div">
                      {t('recruiter:list.recruitersInProject')}
                    </ListSubheader>
                  }
                >
                  {content}
                </List>
              </Grid>
            </Grid>
            {pagination}
          </Paper>
        </Grid>
      </Grid>
    );
  };

  getElements = () => {
    return this.props.recruiters;
  };

  render() {
    return (
      <ElementList
        getElements={this.getElements}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
      />
    );
  }
}

RecruiterInProjectList.propTypes = {
  onChangeRecruiterHandle: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(RecruiterInProjectList));
