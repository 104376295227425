import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  withStyles,
  DialogTitle,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Close, PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import * as yup from 'yup';
import Attachments from '../../../modules/project/EmploymentManagementView/components/EmploymentManagementDialog/components/Attachments';
import { enums } from '../../enums';
import { ProjectEmploymentProcessService } from '../../services';
import { getEnumItemName } from '../../utils/assets';
import { isShorForm } from '../../utils/functions';
import TextField from '../TextField';
import { styles } from './PopUpATS2ATS3.styles';

const PopUpATS2ATS3 = ({
  isOpen,
  setIsOpen,
  onClose,
  classes,
  loadProjectCandidate,
  projectEmploymentProcess: { id, candidate },
  project,
  t,
}) => {
  const {
    userEmploymentProcess: {
      employmentTypeRequest,
      handedDocumentFromCandidateDate,
      documentNumber,
      documentStartDate,
      documentEndDate,
      decisionFromOfficeDate,
      requestedEmploymentStartDate,
      requestedEmploymentEndDate,
    },
    employmentProcessAsset,
  } = candidate;

  const formData = {
    documentNumber: documentNumber !== null ? documentNumber : '',
    documentStartDate: documentStartDate !== null ? documentStartDate : '',
    documentEndDate: documentEndDate !== null ? documentEndDate : '',
    decisionFromOfficeDate:
      decisionFromOfficeDate !== null
        ? decisionFromOfficeDate
        : moment(new Date()).format('YYYY-MM-DD'),
    handedDocumentFromCandidateDate:
      handedDocumentFromCandidateDate !== null
        ? handedDocumentFromCandidateDate
        : moment(new Date()).format('YYYY-MM-DD'),
  };

  const validationSchema = yup.object({
    documentNumber:
      !isShorForm(employmentTypeRequest) &&
      yup.string().required(t`ats2ats3:validation.isRequired`),
    documentStartDate:
      !isShorForm(employmentTypeRequest) &&
      yup.date().required(t`ats2ats3:validation.isRequired`),
    documentEndDate:
      !isShorForm(employmentTypeRequest) &&
      yup
        .date()
        .min(yup.ref('documentStartDate'), t`ats2ats3:validation.date`)
        .required(t`ats2ats3:validation.isRequired`),
    decisionFromOfficeDate:
      !isShorForm(employmentTypeRequest) &&
      yup.string().required(t`ats2ats3:validation.isRequired`),
    handedDocumentFromCandidateDate:
      !isShorForm(employmentTypeRequest) &&
      yup.string().required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: (
      {
        documentNumber,
        documentStartDate,
        documentEndDate,
        decisionFromOfficeDate,
        handedDocumentFromCandidateDate,
      },
      actions
    ) => {
      if (
        !isShorForm(employmentTypeRequest) &&
        moment().isAfter(moment(documentEndDate), 'day')
      ) {
        formik.setFieldError(
          'documentEndDate',
          t`employmentManagementDialog:dialog2.validation.crossingBorderDate`
        );
        return;
      }

      ProjectEmploymentProcessService.updateProjectEmploymentProcess(
        {
          documentNumber,
          documentStartDate,
          documentEndDate,
          decisionFromOfficeDate,
          handedDocumentFromCandidateDate,
          projectCandidateEmploymentProcesses: [id],
        },
        3
      )
        .then(() => {
          loadProjectCandidate();
          setIsOpen(false);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 409) {
              return actions.setFieldError(
                'documentNumber',
                t`ats2ats3:documentIsUsed`
              );
            }
          }
          console.log(error);
        });
    },
  });

  const accessFileType = getEnumItemName(
    enums.ACCESS_FILE_TYPES,
    employmentTypeRequest
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography component="div" variant="h6">
          {t`ats2ats3:dialogTitle`}
        </Typography>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.content}>
          <TextField
            formik={formik}
            value={t([
              getEnumItemName(
                enums.EMPLOYMENT_TYPE_REQUEST,
                employmentTypeRequest
              ),
            ])}
            className={classes.txtField}
          />
          {!isShorForm(employmentTypeRequest) && (
            <>
              <TextField
                variant="transparent"
                label={t`ats2ats3:documentNumber`}
                name="documentNumber"
                formik={formik}
              />
              <Typography
                className={classes.header}
              >{t`ats2ats3:header`}</Typography>
              <div className={classes.date}>
                <div className={classes.date}>
                  <div>
                    <PowerSettingsNew className={classes.powerOnBtn} />
                  </div>
                  <div className={classes.datePicker}>
                    <TextField
                      type="date"
                      name="documentStartDate"
                      variant="transparent"
                      formik={formik}
                    />
                  </div>
                  <div className={classes.date}>
                    <div>
                      <PowerSettingsNew className={classes.powerOffBtn} />
                    </div>
                    <div>
                      <TextField
                        type="date"
                        name="documentEndDate"
                        variant="transparent"
                        formik={formik}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.grid}
              >
                <Grid item xs={12} sm={3} md={3}>
                  <Typography>{t`ats2ats3:requestedDates`}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Grid item xs={1} sm={1} md={1}>
                      <PowerSettingsNew className={classes.powerOnBtn} />
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Typography
                        className={classes.fontRequestData}
                        onClick={() =>
                          formik.setFieldValue(
                            'documentStartDate',
                            requestedEmploymentStartDate
                          )
                        }
                      >
                        {requestedEmploymentStartDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Grid item xs={1} sm={1} md={1}>
                      <PowerSettingsNew className={classes.powerOffBtn} />
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Typography
                        className={classes.fontRequestData}
                        onClick={() =>
                          formik.setFieldValue(
                            'documentEndDate',
                            requestedEmploymentEndDate
                          )
                        }
                      >
                        {requestedEmploymentEndDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.date}>
                <div>
                  <Typography>{t`ats2ats3:decisionFromOfficeDate`}</Typography>
                </div>
                <div>
                  <TextField
                    type="date"
                    name="decisionFromOfficeDate"
                    variant="transparent"
                    formik={formik}
                  />
                </div>
              </div>
              <div className={classes.date}>
                <div>
                  <Typography>{t`ats2ats3:handedDocumentFromCandidateDate`}</Typography>
                </div>
                <div>
                  <TextField
                    type="date"
                    name="handedDocumentFromCandidateDate"
                    variant="transparent"
                    formik={formik}
                  />
                </div>
              </div>
            </>
          )}
        </DialogContent>
        {!isShorForm(employmentTypeRequest) && (
          <div className={classes.attachments}>
            <Attachments
              project={project}
              formik={formik}
              attachmentList={{
                [accessFileType]: enums.ATTACHMENT_ATS2.visa,
              }}
              initialCandidateValues={candidate}
              employmentProcessAsset={employmentProcessAsset}
            />
          </div>
        )}
        <Button type="submit" className={classes.saveBtn}>
          {t('ats1ats2:saveBtn')}
        </Button>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(PopUpATS2ATS3);
