import { Grid, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowRight as ArrowForwardIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getDashboardProjectsCounters } from 'shared/services/projectService';
import PieChartv1 from '../../../components/charts/PieChartv1';

const styles = theme => ({
  boxText1: {
    color: '#c3c3c3',
    fontSize: '12px',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.jobllegroBlue,
    },
    display: 'flex',
    alignItems: 'center',
  },
  chartContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: '#c3c3c3',
    fontSize: 14,
    padding: 0,
    margin: 0,
    width: '14px',
    height: 'auto',
    marginLeft: 4,
  },
});

export class ChartsProject extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      poProjects: 0,
      coProjects: 0,
      endingProjects: 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const newCounters = await this.props.getDashboardProjectsCounters();
    if (this._isMounted) {
      this.setState(newCounters);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, t } = this.props;
    const { poProjects, coProjects, endingProjects } = this.state;

    return (
      (poProjects !== 0 || coProjects !== 0 || endingProjects !== 0) && (
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            className={classes.chartContainer}
          >
            <Link to={PATHS.PROJECT.INDEX_SHOW.replace(':show', 'my-projects')}>
              <PieChartv1
                data={[
                  { name: 'PO', value: poProjects },
                  { name: 'CO', value: coProjects },
                ]}
                counter={poProjects}
              />
            </Link>
            <Typography
              component={Link}
              to={PATHS.PROJECT.INDEX_SHOW.replace(':show', 'my-projects')}
              align="center"
              className={classes.boxText1}
            >
              {t('dashboard:projectsTable.chartMy')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            className={classes.chartContainer}
          >
            <Link
              to={PATHS.PROJECT.INDEX_SHOW.replace(':show', 'cooperation')}
              style={{ cursor: 'pointer' }}
            >
              <PieChartv1
                data={[
                  { name: 'CO', value: coProjects },
                  { name: 'PO', value: poProjects },
                ]}
                counter={coProjects}
              />
            </Link>
            <Typography
              component={Link}
              to={PATHS.PROJECT.INDEX_SHOW.replace(':show', 'cooperation')}
              align="center"
              className={classes.boxText1}
            >
              {t('dashboard:projectsTable.chartParticipate')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            className={classes.chartContainer}
          >
            <Link to={PATHS.PROJECT.INDEX} style={{ cursor: 'pointer' }}>
              <PieChartv1
                data={[
                  { name: 'Ending', value: endingProjects },
                  {
                    name: `PO ${t`dashboard:projectsTable.or`} CO`,
                    value: coProjects + poProjects - endingProjects,
                  },
                ]}
                counter={endingProjects}
              />
            </Link>
            <Typography
              component={Link}
              to={PATHS.PROJECT.INDEX}
              align="center"
              className={classes.boxText1}
            >
              {t('dashboard:projectsTable.chartExpiring')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            className={classes.chartContainer}
          >
            <Typography
              component={Link}
              to={PATHS.PROJECT.INDEX}
              align="center"
              className={classes.boxText1}
            >
              {t('dashboard:projectsTable.more')}
              <ArrowForwardIcon className={classes.arrow} />
            </Typography>
          </Grid>
        </Grid>
      )
    );
  }
}

const mapDispatchToProps = {
  getDashboardProjectsCounters,
};

ChartsProject.propTypes = {
  classes: PropTypes.object.isRequired,
  getDashboardProjectsCounters: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ChartsProject)));
