const validate = (values, props) => {
  const { config = {}, t } = props;
  const errors = {};

  ['password', 'passwordConfirmation'].forEach(element => {
    let value = values[element];
    if (
      value === undefined ||
      typeof value !== 'string' ||
      value.trim() === ''
    ) {
      errors[element] = t`register:error.emptyField`;
    }
  });

  if (config['password_regex_frontend']) {
    let regexp = new RegExp(config['password_regex_frontend']);
    if (!regexp.test(values.password)) {
      errors.password = config['password_regex_message'];
    }
  }

  if (
    values.password &&
    values.passwordConfirmation &&
    values.password !== values.passwordConfirmation
  ) {
    errors.passwordConfirmation = t`register:error.passwordsNotEqual`;
  }

  return errors;
};

export default validate;
