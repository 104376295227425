export const styles = theme => ({
  header: { margin: '20px 0', padding: '0 25px' },
  atsSubtext: {
    display: 'inline-flex',
    color: '#2D333B',
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
    },
  },
  editBtn: {
    color: '#1E90FF',
    borderRadius: 30,
    borderColor: '#1E90FF',
    pointerEvents: 'none',
  },
  editBtnActive: {
    pointerEvents: 'auto',
    color: '#fff',
    background: '#1E90FF',
    '&:hover': { background: '#1E90FF', opacity: 0.8 },
  },
  candidatesLength: { marginLeft: 5 },
});
