import { Paper } from '@material-ui/core';
import Filtering from 'components/list/Filtering';
import { useTranslation } from 'react-i18next';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import OrganizationsTable from '../../../components/OrganizationsTable';
import PageHeader from '../../../components/PageHeader';
import { useAdminOrganizationFilters } from './AdminOrganizationView.utils';

function AdminOrganizationView({ classes }) {
  const { t } = useTranslation('adminOrganization');
  const {
    filterBy,
    filters,
    filteringOptions,
    filteredOrganizations,
    getOrganizations,
  } = useAdminOrganizationFilters();

  return (
    <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
      <PageHeader title="Organizacje" />
      <div className={classes.root}>
        <ExpansionComponent
          title={t('filters.name')}
          textOnTop={t('filters.description')}
        >
          <Filtering
            filterByHandler={filterBy}
            filters={filters}
            filteringOptions={filteringOptions}
          />
        </ExpansionComponent>
        <OrganizationsTable
          organizations={filteredOrganizations}
          shouldUpdateOrganizations={getOrganizations}
        />
      </div>
    </Paper>
  );
}

export default AdminOrganizationView;
