import {
  Paper,
  Grid,
  Tooltip,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  DeleteForever as DeleteForeverIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CandidateCard from 'shared/newComponents/CandidateCard/CandidateCard';
import Box from '../../../components/BoxContainer';
import ElementsListAsync from '../../../components/list/ElementsListAsync';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingBottom: 16,
    marginLeft: 0,
    paddingLeft: '64px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '16px !important',
    },
  }),
  toolTip: {
    background: theme.palette.jobllegroBlue,
    color: '#fff',
    fontSize: 14,
  },
});

export const ProjectCandidates = props => {
  const {
    classes,
    projectDetails,
    projectDetails: { permissions = {} },
    candidatesInProject,
    handleReloadElements,
    t,
  } = props;
  const { isMentor, isOwner } = permissions;

  const getWrapperProjectCandidates = options => {
    let { content } = options;
    return <Grid container>{content}</Grid>;
  };

  const getContentProjectCandidates = row => {
    let { candidate } = row;
    let isDisabledDelete = false;

    if (isOwner) {
      isDisabledDelete = isOwner || candidate.permissions.isOwner;
    } else if (isMentor) {
      isDisabledDelete = candidate.permissions.isOwner;
    }

    return (
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={row.id}>
        <Box>
          <CandidateCard
            candidate={candidate}
            action={
              !projectDetails.closedStatus &&
              isOwner && (
                <Tooltip
                  title={t`project:show.removeCandidateFromProject`}
                  placement="top"
                  classes={{
                    tooltip: classes.toolTip,
                  }}
                >
                  <div>
                    <IconButton
                      disabled={!isDisabledDelete}
                      onClick={props.onDeleteHandle.bind(this, candidate.id)}
                    >
                      <DeleteForeverIcon />
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )
            }
          />
        </Box>
      </Grid>
    );
  };

  return (
    <Paper className={classes.rootPaper} elevation={0}>
      <Box
        title={t('project:show.candidatesChosenList')}
        elevation={0}
        variant="backgroundNone"
      >
        <ElementsListAsync
          elements={candidatesInProject.data || []}
          getContent={getContentProjectCandidates}
          getWrapper={getWrapperProjectCandidates}
          handleReloadElements={handleReloadElements}
        />
      </Box>
    </Paper>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
    candidatesInProject: store.candidates.candidatesInProject,
  };
};

ProjectCandidates.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  onDeleteHandle: PropTypes.func.isRequired,
  dataList: PropTypes.object,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectCandidates)));
