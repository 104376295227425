import {
  Avatar,
  Paper,
  Card,
  CardHeader,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Public as PublicIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import CandidateEditButton from './CandidateEditButton';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 64,
    paddingBottom: 16,
    marginLeft: 0,
    paddingLeft: '64px !important',
    paddingRight: '64px !important',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: '32px !important',
      paddingRight: '32px !important',
      paddingTop: 32,
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 16,
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  }),
  cardHeader: {
    padding: 0,
  },
  titlePrimary: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 4,
    lineHeight: '1.3em',
    borderBottom: '1px solid #e6e6e6',
  },
  nameText: {
    fontSize: 35,
    color: '#52a3fc',
    fontWeight: '600',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 20,
      paddingBottom: '0px !important',
    },
  },
  titleSecondary: {
    paddingTop: 16,
    paddingLeft: 0,
    paddingRight: 0,
    color: '#52a3fc',
    fontWeight: '400',
    lineHeight: '1.3em',
    [theme.breakpoints.down('xs', 'sm')]: {
      paddingTop: 0,
    },
  },
  textBlue: {
    color: '#52a3fc',
    paddingLeft: 5,
  },
  avatar: {
    width: 100,
    height: 100,
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 50,
      height: 50,
    },
  },
  avatarIsPublic: {
    background: 'transparent',
    color: '#b1b1b1',
    marginTop: '-4px',
    marginLeft: 40,
    width: 'auto',
    fontSize: 14,
  },
  rootTop: {
    textAlign: 'right',
  },
  nameContainer: {
    display: 'flex',
  },
  textSubHeading: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#000',
    fontSize: 18,
  },
});

export const RecruiterOrganizationPanelTopCard = props => {
  const { classes, t, currentUser, organization } = props;
  const { owner = {} } = organization;
  const avatar = getProjectAssetSrcByType(organization, 'logo');

  return (
    <Paper className={classes.rootPaper} elevation={0}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rootTop}>
          <CandidateEditButton />
        </Grid>
      </Grid>
      <Card className={classes.Card} elevation={0} key={currentUser.id}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar
              src={avatar}
              aria-label={organization.name}
              className={classes.avatar}
            ></Avatar>
          }
          title={
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="div" className={classes.nameContainer}>
                  <div className={classes.nameText}>{organization.name}</div>
                  <Avatar className={classes.avatarIsPublic}>
                    {organization.isPublic ? (
                      <React.Fragment>
                        <PublicIcon />
                        {t('organization:show.public')}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <PublicIcon />
                        {t('organization:show.private')}
                      </React.Fragment>
                    )}
                  </Avatar>
                </Typography>
              </Grid>
            </Grid>
          }
          subheader={
            <Grid container spacing={0}>
              <Typography component="div" className={classes.textSubHeading}>
                {t('organization:show.owner')}:{' '}
                <span style={{ fontWeight: '700' }}>
                  {owner.firstName + ' ' + owner.lastName}
                </span>
              </Typography>
            </Grid>
          }
          classes={{
            title: classes.titlePrimary,
            subheader: classes.titleSecondary,
          }}
        />
      </Card>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    organization: state.organizations.currentOrganization,
  };
};

RecruiterOrganizationPanelTopCard.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(RecruiterOrganizationPanelTopCard)));
