export const styles = theme => ({
  candidateCardWrapper: {
    padding: '30px 15px',
  },
  candidateCard: {
    width: '100%',
    height: 420,
    borderRadius: 10,
    boxShadow: '-1px -1px 30px 0px rgba(0, 0, 0, 0.1)',
  },
  candidateCardHeader: {
    textTransform: 'uppercase',
    padding: 20,
    paddingBottom: 0,
  },
  candidateCardContent: {
    padding: '0 20px',
  },
  candidateCardWrapperFullWith: {
    width: '100%',
  },
  candidateCardContentNameUrl: {
    textDecoration: 'none',
  },
  candidateCardDetails: {
    minHeight: 60,
  },
  candidateCardHeaderText: {
    fontWeight: 'bold',
  },
  candidateCardContentName: {
    cursor: 'pointer',
    paddingTop: 8,
    marginBottom: 24,
    minHeight: 120,
    position: 'relative',
  },
  candidateCardContentNameShadow: {
    position: 'absolute',
    width: '100%',
    height: '30%',
    background: 'linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff)',
    bottom: 0,
  },
  candidateCardSkills: {
    height: '100%',
    padding: '0 20px',
  },
  candidateCardSkillsSkill: {
    padding: '7px 0',
  },
  actions: {
    borderTop: '1px solid #e9e9e9',
    padding: 0,
    justifyContent: 'space-between',
    display: 'flex',
  },
  buttonAccept: {
    color: theme.palette.jobllegroBlue,
    fontSize: 12,
    width: 'auto',
    '&:hover': {
      background: 'transparent',
    },
  },
  thumbUp: {
    color: theme.palette.jobllegroSuccess,
    fontSize: 15,
    marginRight: theme.spacing(1),
  },
  thumbDown: {
    color: theme.palette.jobllegroRedAlert,
    fontSize: 15,
    marginRight: theme.spacing(1),
  },
  buttonReject: {
    color: theme.palette.jobllegroBlue,
    fontSize: 12,
    width: 'auto',
    '&:hover': {
      background: 'transparent',
    },
  },
  listMenu: {
    fontSize: 18,
    color: theme.palette.jobllegroBlue,
  },
  buttonShow: {
    color: '#52a3fc',
    float: 'right',
    width: 'auto',
    fontSize: 12,
    textTransform: 'none',
    alignItems: 'center',
    '&:hover': {
      background: '#fff',
    },
  },
  rightIcon: {
    fontSize: 13,
  },
});
