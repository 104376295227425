import { API } from '../constants';
import { ApiService } from './api.service';

export const IndustryCategoryService = {
  getIndustryCategories() {
    return ApiService.get(API.GET.INDUSTRY_CATEGORY.GET);
  },
  addIndustryCategories(data) {
    return ApiService.post(API.POST.INDUSTRY_CATEGORY.POST, data);
  },
  replaceIndustryCategories(id, data) {
    return ApiService.put(
      API.PUT.INDUSTRY_CATEGORY.PUT.replace(':id', id),
      data
    );
  },
  deleteIndustryCategories(id) {
    return ApiService.delete(
      API.DELETE.INDUSTRY_CATEGORY.DELETE.replace(':id', id)
    );
  },
};

export default IndustryCategoryService;
