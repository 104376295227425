export const styles = () => ({
  wrapper: {
    padding: 12,
  },
  subWrapper: {
    justifyContent: 'end',
  },
  fontSize: {
    fontSize: 26,
  },
  btnAddRecruiter: {
    background: '#27D39B',
  },
  imageWrapper: {
    maxWidth: 80,
  },
  imageMargin: {
    margin: '10px 10px',
  },
});
