import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import {
  CountryService,
  RecruiterV2Service,
  IndustryCategoryService,
  CompetenceService,
} from 'shared/services';
import DepartmentService from 'shared/services/department.service';
import {
  getLanguageSkill,
  prepareLanguage,
} from '../../candidate/NewCandidateCreateView/components/CandidateCreateWizard/CandidateCreateWizard.utils';
import RecruiterAnonymousDetailsContent from './components/RecruiterAnonymousDetailsContent';

const RecruiterAnonymousDetailsView = ({ match, history, t, ...props }) => {
  const [recruiter, setRecruiter] = useState({});
  const [fetchDone, setFetchDone] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [languageCompetence, setLanguageCompetence] = useState([]);
  const [countries, setCountries] = useState([]);
  const [industryCategories, setIndustryCategories] = useState([]);

  const getRecruiterById = id => {
    RecruiterV2Service.getRecruiterV2(id)
      .then(({ data }) => {
        if (!data.email) {
          history.push(PATHS.RECRUITER.SHOW.replace(':id', data.id));
        }
        setRecruiter({
          ...data,
          ...getLanguageSkill(data.userSkills),
        });
        setFetchDone(true);
      })
      .catch(error => {
        console.log(error);
        //TODO: redirect to 404
        alert(t`recruiter:dataError`);
      });
  };

  useEffect(() => {
    shouldUpdateRecruiter();
    getDepartments();
    getIndustryCategories();
    getCountries();
    getCompetences();
  }, []);

  const getDepartments = () => {
    DepartmentService.getDepartments()
      .then(({ data }) => {
        setDepartments(data);
      })
      .catch(error => {
        console.log(error);
        //TODO: redirect to 404
        alert(t`recruiter:dataError`);
      });
  };

  const getCountries = () => {
    CountryService.getCountries()
      .then(({ data }) => {
        setCountries(data);
      })
      .catch(error => {
        console.log(error);
        //TODO: redirect to 404
        alert(t`recruiter:dataError`);
      });
  };

  const getCompetences = id => {
    const userId = id ? id : 0;
    CompetenceService.getCompetences({ project: 0, user: userId })
      .then(({ data }) => {
        setLanguageCompetence(prepareLanguage(data));
        setFetchDone(true);
      })
      .catch(error => console.log(error));
  };

  const getIndustryCategories = () => {
    IndustryCategoryService.getIndustryCategories()
      .then(({ data }) => {
        setIndustryCategories(data);
      })
      .catch(error => {
        console.log(error);
        //TODO: redirect to 404
        alert(t`recruiter:dataError`);
      });
  };
  const shouldUpdateRecruiter = () => {
    const { id } = match.params;

    if (!id) {
      getRecruiterById(props.currentUser.id);
    } else {
      getRecruiterById(id);
    }
  };

  const isSelfEdit =
    props.currentUser.id === recruiter.id || props.currentUser.isSuperUser;

  return fetchDone && recruiter?.id ? (
    <RecruiterAnonymousDetailsContent
      isSelfEdit={isSelfEdit}
      recruiterEdited={shouldUpdateRecruiter}
      recruiterDetails={recruiter}
      departments={departments}
      industryCategories={industryCategories}
      languageCompetence={languageCompetence}
      countries={countries}
      currentUser={props.currentUser}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(mapStateToProps)(
  withRouter(withTranslation()(RecruiterAnonymousDetailsView))
);
