import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { deleteCmsConfigs } from 'shared/services/cmsService';
import AddCmsConfigFormAction from '../AddCmsConfigForm/AddCmsConfigForm';
import CustomDialog from '../CustomDialog';
import CustomIconButton from '../CustomIconButton';

export class ConfigCmsTable extends React.Component {
  state = {
    cmsConfigData: null,
    openAddDialog: false,
    openDeleteDialog: false,
    selectedCmsConfig: '',
  };

  getCmsConfigById = id =>
    this.props.cmsConfigs.find(cmsConfig => cmsConfig.id === id);

  handleOpenEdit = id => {
    const cmsConfig = this.getCmsConfigById(id);
    if (cmsConfig) {
      this.setState({
        cmsConfigData: cmsConfig,
        openAddDialog: true,
      });
    }
  };

  handleOpenDelete = id => {
    this.setState({
      selectedCmsConfig: id,
      openDeleteDialog: true,
    });
  };

  handleCloseDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
    });
  };

  getCmsName = id => {
    const cmsConfig = this.getCmsConfigById(id);
    return cmsConfig ? cmsConfig.fieldName : '';
  };

  handleDelete = () => {
    deleteCmsConfigs(this.state.selectedCmsConfig).then(() => {
      this.setState({
        openDeleteDialog: false,
      });
      this.props.shouldUpdateCmsConfig();
    });
  };
  handleCloseAddDialog = () => {
    this.setState({
      openAddDialog: false,
      cmsConfigData: null,
    });
  };

  handleAdded = () => {
    this.handleCloseAddDialog();
    this.props.shouldUpdateCmsConfig();
  };

  handleAdd = () => {
    this.setState({
      openAddDialog: true,
      cmsConfigData: null,
    });
  };
  render() {
    const { cmsConfigs = [], classes, t } = this.props;
    const {
      selectedCmsConfig,
      openDeleteDialog,
      openAddDialog,
      cmsConfigData,
    } = this.state;
    return (
      <div>
        <Table stickyheader="true" className={classes.tableContainer}>
          <TableHead>
            <TableRow>
              <TableCell>{t`configCmsTable:field`}</TableCell>
              <TableCell>{t`configCmsTable:value`}</TableCell>
              <TableCell className={classes.buttonsTableCell} align={'right'}>
                <CustomIconButton onClick={this.handleAdd} type={'add'} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cmsConfigs.map(cmsConfig => (
              <TableRow key={cmsConfig.id}>
                <TableCell align={'center'}>{cmsConfig.fieldName}</TableCell>
                <TableCell align={'center'}>{cmsConfig.value}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton
                    onClick={() => this.handleOpenEdit(cmsConfig.id)}
                    type={'edit'}
                  />
                  <CustomIconButton
                    onClick={() => this.handleOpenDelete(cmsConfig.id)}
                    type={'delete'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <AddCmsConfigFormAction
          isOpen={openAddDialog}
          cmsConfig={cmsConfigData}
          onClose={this.handleCloseAddDialog}
          cmsConfigAdded={this.handleAdded}
        />
        <CustomDialog
          title={t`configCmsTable:delField`}
          onCloseDialog={this.handleCloseDeleteDialog}
          body={
            <>
              {t`configCmsTable:confirm`}

              {this.getCmsName(selectedCmsConfig)}
            </>
          }
          isOpen={openDeleteDialog}
          dialogActions={
            <>
              <Button color={'primary'} onClick={this.handleDelete}>
                {t`configCmsTable:yes`}
              </Button>
              <Button
                color={'primary'}
                autoFocus
                onClick={this.handleCloseDeleteDialog}
              >
                {t`configCmsTable:no`}
              </Button>
            </>
          }
        />
      </div>
    );
  }
}

ConfigCmsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(ConfigCmsTable);
