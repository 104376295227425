import { withStyles, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { styles } from './ListWithTicks.styles';

const ListWithTicks = ({ items, t, classes }) => {
  return (
    <ul>
      {items.map(({ title, description, boldTitle }) => (
        <li key={title} className={classes.listItem}>
          <CheckCircle color="secondary"></CheckCircle>
          <div>
            <Typography
              className={classes.title}
              style={boldTitle ? { fontWeight: 600 } : {}}
            >
              {t(title)}
            </Typography>
            <Typography>{t(description)}</Typography>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default withStyles(styles)(withTranslation()(ListWithTicks));
