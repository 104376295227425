import { Grid, TextField, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { normalizeMax200 } from 'shared/utils/normalizers';
import FieldWysiwyg from '../FieldWysiwyg';
import LanguagesInputField from '../inputs/LanguagesInputField';
import SkillsInputField from '../inputs/SkillsInputField';
import SliderInputField from '../inputs/SliderInputField';

const styles = () => ({
  textHeading: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#53a3fc',
    fontSize: '24px',
  },
});

export const ProjectFormStepThree = props => {
  let { valid, classes, initialValues, DictionariesLanguages, t } = props;

  initialValues = initialValues || {};

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FieldWysiwyg
          valid={valid}
          label={t('project:field.requirements') + ' *'}
          name="requirements"
          value={initialValues.requirements || ''}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          {t('project:field.projectSkillsHardLong')}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <SkillsInputField
          form={'projectCreatorForm'}
          fieldName="projectSkills"
          fieldLabel={t('project:field.projectSkills') + ' *'}
          currentFieldValue={initialValues.projectLanguages || []}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          {t('project:field.projectSkillsSoftLong')}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Field
          label={t('project:field.personalityCurious')}
          name="personalityCurious"
          defaultValue={initialValues.personalityCurious || 50}
          component={SliderInputField}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.personalityOrganized')}
          name="personalityOrganized"
          defaultValue={initialValues.personalityOrganized || 50}
          component={SliderInputField}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.personalityExtraversion')}
          name="personalityExtraversion"
          defaultValue={initialValues.personalityExtraversion || 50}
          component={SliderInputField}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.personalityCompassionate')}
          name="personalityCompassionate"
          defaultValue={initialValues.personalityCompassionate || 50}
          component={SliderInputField}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.personalitySecure')}
          name="personalitySecure"
          defaultValue={initialValues.personalitySecure || 50}
          component={SliderInputField}
          type="number"
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          {t('project:field.projectLanguagesLong')}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <LanguagesInputField
          suggestionsList={DictionariesLanguages}
          fieldName="projectLanguages"
          fieldLabel={t('project:field.projectLanguages')}
          currentFieldValue={initialValues.projectLanguages || []}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.talentManagement') + ' *'}
          name="talentManagement"
          inputProps={{ maxLength: 200 }}
          multiline={true}
          component={TextField}
          fullWidth
          normalize={normalizeMax200}
        />
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.auth.currentUser,
    initialValues: props.isEdited ? state.projects.currentProject : null,
    DictionariesLanguages: state.dictionaries.languages.list,
  };
};

ProjectFormStepThree.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectFormStepThree)));
