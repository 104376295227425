import {
  Avatar,
  Button,
  Card,
  CardHeader,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import {
  getRecruitersWithLimit,
  ProjectV2Service,
  getRecruiters,
  getNotifications,
  setNotificationRead,
} from '../../services';
import { showSnackbarMessage } from '../../store/messages/actions';
import { getUserAvatarSrc } from '../../utils/assets';
import {
  getMentorInProject,
  getMentorStatusInProject,
} from '../../utils/functions';
import { getTableRowPerPageFromStorage } from '../../utils/localStorage';
import ButtonAddMentorrOrHiriginManager from '../ButtonAddMentorOrHiringManager';
import { styles } from './AssignMentorToProjectButton.styles';

const AssignMentorToProjectButton = ({ projectDetails, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [choiceButtonStatus, setChoiceButtonStatus] = useState(false);

  const loadData = async (limit = 12, offset = 0, filters = {}) => {
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(props.match.path) || 12
      : limit;

    let params = {
      filters: { ...filters, mentorStatus: 2 },
    };

    await props.getRecruitersWithLimit(limitToSearch, offset, params);
  };

  const handleReloadElements = ({ limit, offset, filters }) => {
    return loadData(limit, offset, filters);
  };

  const handleClickOpen = async () => {
    setIsLoading(true);
    await loadData();
    setIsLoading(false);
    setIsOpen(true);
  };

  const handleSelect = async recruiterId => {
    await setChoiceButtonStatus(true);
    const { t } = props;

    const body = {
      mentorId: recruiterId,
      mentorStatus: 1,
    };

    ProjectV2Service.editProjectV2Mentor(projectDetails.id, body)
      .then(() => {
        setChoiceButtonStatus(false);
        setIsOpen(false);

        props.showSnackbarMessage(t('project:form.addMentorInfoSuccess'));
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        props.showSnackbarMessage(t`candidate:show.unableToAssign`);
      });
  };

  const handleRemoveMentor = async mentorId => {
    const { t } = props;
    const body = {
      mentorId: mentorId,
      mentorStatus: 0,
    };
    ProjectV2Service.editProjectV2Mentor(projectDetails.id, body)
      .then(() => {
        setIsOpen(false);
        props.showSnackbarMessage(t('project:form.removeMentorFromProject'));
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        props.showSnackbarMessage(t`candidate:show.unableToAssign`);
      });
  };

  const handleChooseOption = (idOption, mentor) => async () => {
    const { t, setNotificationRead, getNotifications, notifications } = props;
    const body = {
      mentorId: mentor.id,
      mentorStatus: idOption,
    };

    try {
      await ProjectV2Service.editProjectV2Mentor(projectDetails.id, body);
      const projectDetailsId = projectDetails.id;
      const findNotification = find(notifications, function (n) {
        const { project = {} } = n.data;
        return project.id === projectDetailsId;
      });
      await setNotificationRead(findNotification.id);
      await getNotifications();
      props.showSnackbarMessage(
        t(
          idOption === 2
            ? 'project:form.acceptByMentor'
            : 'project:form.rejectByMentor'
        )
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const getContent = recruiter => {
    const {
      t,
      currentUser: { id },
    } = props;

    const avatar = getUserAvatarSrc(recruiter);
    const {
      projectMentors,
      permissions: { isOwner },
    } = projectDetails;

    const mentor = getMentorInProject(projectMentors, recruiter);

    let secondaryText = {};

    if (mentor && recruiter.id === mentor.id) {
      secondaryText = t(getMentorStatusInProject(mentor.mentorStatus));
    } else {
      secondaryText =
        recruiter &&
        recruiter.industry &&
        t('project:field.industry') + ': ' + recruiter.industry;
    }

    return (
      recruiter &&
      recruiter.id !== id && (
        <ListItem key={recruiter.id}>
          <Avatar
            src={avatar}
            aria-label={recruiter.firstName + ' ' + recruiter.lastName}
          />
          <ListItemText
            primary={recruiter.firstName + ' ' + recruiter.lastName}
            secondary={secondaryText}
          />
          {mentor ? null : (
            <Button
              disabled={choiceButtonStatus}
              onClick={handleSelect.bind(this, recruiter.id)}
              size="small"
              variant="contained"
              color="primary"
            >
              {t('action.choose')}
            </Button>
          )}
          {mentor !== null && recruiter.id === mentor.id && isOwner && (
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => handleRemoveMentor(mentor.id)}
                aria-label="Remove mentor"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      )
    );
  };

  const getRecruiterContent = recruiter => {
    return getMentorInProject(projectDetails.projectMentors, recruiter)
      ? null
      : getContent(recruiter);
  };

  const getWrapper = options => {
    let { content, pagination, filtering } = options;
    const { t } = props;
    let mentors = projectDetails.projectMentors.map(mentor =>
      getContent(mentor.recruiter)
    );

    return (
      <React.Fragment>
        <ExpansionComponent
          title={t('filters.name')}
          textOnTop={t('filters.description')}
        >
          {filtering}
        </ExpansionComponent>
        <div className={props.classes.hiringManagerSelected}>
          {mentors !== null &&
            mentors.map(item => {
              <List key={item.id} component={'ul'}>
                {item}
              </List>;
            })}
        </div>
        <Divider />
        <DialogContent>
          <List component={'nav'}>{content}</List>
        </DialogContent>
        {pagination}
      </React.Fragment>
    );
  };

  const handleClickRedirect = (mentorID, projectID, position) => {
    props.history.push({
      pathname: PATHS.RECRUITER.SHOW.replace(':id', mentorID),
      state: {
        subject: `${t`candidate:show.project`}: ${projectID} ${position}`,
      },
    });
  };

  const getMentorActionButton1 = (
    mentorStatus,
    isMentorAssignedToProject,
    mentor
  ) => {
    const { classes, t, currentUser } = props;
    const {
      position,
      id,
      permissions: { isCooperator, isHiringManager, isOwner },
    } = projectDetails;
    let showStatus = 0;
    let avatar = null;
    let showButton = true;
    if (
      ((isCooperator && isMentorAssignedToProject) ||
        (mentor && mentor.id === currentUser.id)) &&
      !isOwner
    ) {
      showButton = false;
    }

    if (
      mentorStatus === 1 &&
      isMentorAssignedToProject &&
      mentor.id === currentUser.id
    ) {
      showStatus = 1;
    } else if (mentorStatus === 1 && isMentorAssignedToProject) {
      showStatus = 3;
      avatar = getUserAvatarSrc(mentor);
    } else if (mentorStatus === 2 && isMentorAssignedToProject) {
      showStatus = 2;
      avatar = getUserAvatarSrc(mentor);
    }
    return (
      <React.Fragment>
        {(showStatus === 2 || showStatus === 3) && (
          <React.Fragment>
            <Typography className={classes.cardTopSectionTitle}>
              {t('project:field.mentor')}
            </Typography>
            {showButton && (
              <Typography
                onClick={!isHiringManager ? handleClickOpen : undefined}
                className={classes.cardTopChange}
              >
                {t`candidate:show.change`}
              </Typography>
            )}
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  title: classes.titleCardHeader,
                  subheader: classes.titleCardSubHeader,
                  avatar: classes.avatarTitleCardHeader,
                }}
                onClick={() => handleClickRedirect(mentor.id, id, position)}
                avatar={
                  <Avatar className={classes.cardHeaderAvatar} src={avatar} />
                }
                title={mentor.firstName + ' ' + mentor.lastName}
                subheader={t(getMentorStatusInProject(mentorStatus))}
              />
            </Card>
          </React.Fragment>
        )}

        {showStatus === 1 && (
          <React.Fragment>
            <Typography
              className={classes.infoText}
              variant="subtitle1"
              gutterBottom
            >
              {t`candidate:show.mentorInvitation`}
              <br />
              {t`candidate:show.mentorQuestion`} {position} ?
            </Typography>
            <div className={classes.toggleContainer}>
              <ToggleButtonGroup exclusive>
                <ToggleButton
                  className={classes.buttonOptionsAccept}
                  value={2}
                  onClick={handleChooseOption(2, mentor)}
                >
                  {t('core:action.yesImApproving')}
                </ToggleButton>
                <ToggleButton
                  className={classes.buttonOptionsReject}
                  value={0}
                  onClick={handleChooseOption(0, mentor)}
                >
                  {t('core:action.noImRejecting')}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </React.Fragment>
        )}

        {showStatus === 0 && isOwner && (
          <ButtonAddMentorrOrHiriginManager handleClickOpen={handleClickOpen} />
        )}
      </React.Fragment>
    );
  };

  const mentor = getMentorInProject(
    projectDetails.projectMentors,
    props.currentUser
  );

  const mentorStatus = mentor ? mentor.mentorStatus : null;

  const {
    currentUser: { id },
    t,
    currentRecruitersLimitedList,
    currentRecruitersLimitedListCounter,
  } = props;

  const filteringOptions = [
    {
      key: 'firstName',
      type: 'text',
      name: t('recruiter:field.firstName'),
    },
    {
      key: 'lastName',
      type: 'text',
      name: t('recruiter:field.lastName'),
    },
  ];

  const {
    isOwner = false,
    isCooperator = false,
    isSuperUser = false,
  } = projectDetails.permissions;
  let isMentorAssignedToProject = false;
  if (mentor && (id === mentor.id || isOwner || isCooperator || isSuperUser)) {
    isMentorAssignedToProject = true;
  }

  const getMentorActionButton = getMentorActionButton1(
    mentorStatus,
    isMentorAssignedToProject,
    mentor
  );

  return (
    (isOwner || isMentorAssignedToProject) &&
    !projectDetails.closedStatus && (
      <React.Fragment>
        {getMentorActionButton}
        <Dialog
          open={isOpen}
          onClose={handleCancel}
          aria-labelledby="mentor-dialog"
        >
          <DialogTitle id="mentor-dialog">
            {t('project:show.selectMentor')}
          </DialogTitle>
          {isLoading ? (
            <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
          ) : !isEmpty(currentRecruitersLimitedList) ? (
            <ElementsListAsync
              storageKey={props.match.path}
              rowsPerPage={getTableRowPerPageFromStorage(props.match.path)}
              handleReloadElements={handleReloadElements}
              elements={currentRecruitersLimitedList}
              filteringOptions={filteringOptions}
              elementsCount={currentRecruitersLimitedListCounter}
              load={isLoading}
              getContent={getRecruiterContent}
              getWrapper={getWrapper}
              scrollUp={false}
            />
          ) : (
            <DialogContent>
              <Typography variant="subtitle1">
                {t('project:field.thereIsNoMentorInSystem')}
              </Typography>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {t('action.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    notifications: store.notifications.list,
    currentRecruitersLimitedList: store.recruiters.currentRecruitersLimitedList,
    currentRecruitersLimitedListCounter:
      store.recruiters.currentRecruitersLimitedListCounter,
  };
};

const mapDispatchToProps = {
  getNotifications,
  setNotificationRead,
  getRecruitersWithLimit,
  getRecruiters,
  showSnackbarMessage,
};

AssignMentorToProjectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(withStyles(styles)(withTranslation()(AssignMentorToProjectButton)))
);
