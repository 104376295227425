import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import SettlementDataForm from '../SettlementData/SettlementDataForm/SettlementDataForm';
import { styles } from './SettlementData.styles';

const SettlementData = ({ recruiterData, recruiterEdited, classes, t }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const openFormModal = () => {
    setIsOpen(true);
  };

  const settlementDataType = recruiterData
    ? recruiterData.invoiceTypeBusiness
    : null;

  const settlementDataTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t`addOrganizationForm:company`
        : t`addOrganizationForm:person`
      : '';

  const settlementDataInvoiceTypeTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t`project:field.companyName`
        : t`dashboard:rightDrawer.firstAndLastName`
      : t`project:field.companyName`;

  const settlementDataInvoiceNumberTypeTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t`project:field.nipAndVat`
        : t`organization:field.invoicePersonalNumber`
      : t`project:field.nipAndVat`;

  const invoiceCompanyName =
    recruiterData && recruiterData.invoiceCompanyName !== null
      ? recruiterData.invoiceCompanyName
      : t('core:general.noInformation');
  const invoiceAddress = recruiterData
    ? recruiterData.invoiceAddress
    : t('core:general.noInformation');
  const invoicePostCode = recruiterData
    ? recruiterData.invoicePostCode
    : t('core:general.noInformation');
  const invoiceCity = recruiterData
    ? recruiterData.invoiceCity
    : t('core:general.noInformation');

  const accountNumber =
    recruiterData && recruiterData.accountNumber !== null
      ? recruiterData.accountNumber
      : t('core:general.noInformation');

  const invoicePersonalNumber =
    recruiterData && recruiterData.invoicePersonalNumber !== null
      ? recruiterData.invoicePersonalNumber
      : t('core:general.noInformation');
  const invoiceIdentificationNumber =
    recruiterData && recruiterData.invoiceIdentificationNumber !== null
      ? recruiterData.invoiceIdentificationNumber
      : t('core:general.noInformation');

  const settlementDataNumber =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? invoiceIdentificationNumber
        : invoicePersonalNumber
      : invoiceIdentificationNumber;

  let concatAddress = t('core:general.noInformation');
  if (
    invoiceAddress !== null ||
    invoicePostCode !== null ||
    invoiceCity !== null
  ) {
    concatAddress = `${invoiceAddress !== null ? invoiceAddress : ''} ${
      invoicePostCode !== null ? invoicePostCode : ''
    } ${invoiceCity !== null ? invoiceCity : ''}`;
  }

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.topPadding}>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.gridWrapper}>
          <Grid container className={classes.gridPadding}>
            <Grid item xs={10} sm={10} md={10}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={'organization:settlementData'}
                    bold
                    className={classes.bigFont}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={settlementDataTitle}
                    className={classes.mediumFontSpecial}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              className={classes.imageWrapper}
              onClick={openFormModal}
            >
              <Grid container>
                <img alt="editIcon" src={EditIcon} height={50} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${settlementDataInvoiceTypeTitle}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={invoiceCompanyName}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
          <hr className={classes.separator} />
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t`modifyOrderDialog:paper.address`}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={concatAddress}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
          <hr className={classes.separator} />
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${settlementDataInvoiceNumberTypeTitle}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={settlementDataNumber}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
          <hr className={classes.separator} />
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t`recruiter:field.accountNumber`}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={accountNumber}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <SettlementDataForm
        recruiterData={recruiterData}
        isOpen={isOpen}
        onClose={onClose}
        recruiterEdited={recruiterEdited}
      />
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(SettlementData))
);
