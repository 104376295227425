import {
  Grid,
  Typography,
  Paper,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const styles = theme => ({
  rootPaper: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 0,
    paddingRight: 64,
    marginTop: 0,
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
  },
  textDescription: {
    fontSize: '16px',
    color: '#000',
    paddingBottom: 0,
  },
  textHeading: {
    paddingTop: 0,
    paddingBottom: 8,
    color: '#53a3fc',
    fontSize: '20px',
  },
});

export const ProjectDetailsJobDescription = props => {
  const { classes, projectDetails, isLoading, t } = props;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Paper className={classes.rootPaper} elevation={0}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              className={classes.textHeading}
            >
              {t('project:show.positionTitle')}
            </Typography>
            <Typography component="div" className={classes.textDescription}>
              <div
                dangerouslySetInnerHTML={{
                  __html: projectDetails.positionDescription,
                }}
              ></div>
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectDetailsJobDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsJobDescription)));
