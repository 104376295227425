export const styles = theme => ({
  cardTopSectionTitle: {
    margin: '10px 0',
    fontSize: 11,
    background: theme.palette.jobllegroSuccessActive,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
  },
  cardTopChange: {
    marginLeft: 10,
    marginTop: 10,
    fontSize: 11,
    background: theme.palette.jobllegroBlue,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.jobllegroSuccessActive,
    },
  },
  card: {
    marginBottom: 5,
    maxWidth: 320,
    boxShadow: 'none',
  },
  cardHeader: {
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeaderAvatar: {
    width: 20,
    height: 20,
  },
  titleCardHeader: {
    fontSize: 12,
  },
  titleCardSubHeader: {
    fontSize: 12,
  },
  avatarTitleCardHeader: {
    marginRight: 8,
    cursor: 'pointer',
  },
  container: { marginBottom: 20 },
  mentor: {
    background: theme.palette.jobllegroOrange,
  },
});
