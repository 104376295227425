import axios from 'axios';
import { API, API_URL } from '../constants';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getHelpdeskMessages = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.HELPDESK.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response;
          return { data: {}, status };
        }
        return { data: {}, status: 500 };
      });
  };
};

export const sendHelpdeskMessages = values => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.HELPDESK.ADD,
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response;
          return { data: {}, status };
        }
        return { data: {}, status: 500 };
      });
  };
};
