export const styles = () => ({
  bgBlue: { background: '#E5F0FF' },
  container: { padding: 25 },
  editBtn: { cursor: 'pointer' },
  row: { margin: '25px 0' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  date: { opacity: '70%', fontWeight: 600 },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  confirmBtn: {
    background: '#F41313',
    borderRadius: 30,
    color: '#fff',
    width: 250,
    height: 45,
    marginTop: 25,
    '&:hover': {
      background: '#F41313',
      opacity: 0.8,
    },
  },
  btnDisabled: { background: '#C4C4C4', color: '#fff !important' },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  warning: { color: '#F41313' },
  warningMsg: {
    color: '#F41313',
    display: 'flex',
    textAlign: 'center',
    margin: '25px 0',
  },
  resignationEndDate: { position: 'relative' },
  error: { color: '#f41010', position: 'absolute', top: '30px' },
  edited: { paddingTop: 20 },
  transferContainer: { padding: '25px 25px 0' },
});
