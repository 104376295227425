import { Typography, withStyles } from '@material-ui/core';
import { Money as MoneyIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const styles = theme => ({
  money: {
    color: theme.palette.jobllegroSuccess,
    marginRight: theme.spacing(1),
  },
  countSume: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: '700',
    color: '#52a3fc',
    fontSize: '22px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  textTop: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#7f7f7f',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
});

function getSuggestedPrice(values) {
  //kwota do wyliczeń wyciągana ze średniej widełek
  const salary =
    (values.minSalary + (values.maxSalary || values.minSalary)) * 0.5;
  let value = 0;

  //typ produktu
  switch (values.scope) {
    case 1:
      value += salary * 0.35 > 1800 ? salary * 0.35 : 1800;
      break;
    case 2:
      value += salary * 0.7 > 3600 ? salary * 0.7 : 3600;
      break;
    default:
      break;
  }

  //czas poszukiwania kandydata
  switch (values.calcSearchTime) {
    case 2:
      value += salary * 0.05;
      break;
    case 3:
      value += salary * 0.1;
      break;
    case 4:
      value += salary * 0.25;
      break;
    default:
      break;
  }

  //pula rekomendacji
  switch (values.calcNumberOfRecomendations) {
    case 1:
      //minValue += 1080;
      break;
    case 2:
      value += salary * 0.1 > 1440 ? salary * 0.1 : 1440;
      break;
    case 3:
      value += salary * 0.2 > 2160 ? salary * 0.2 : 2160;
      break;
    case 4:
      value += salary * 0.3 > 2880 ? salary * 0.3 : 2880;
      break;
    default:
      break;
  }

  //doświadczenie kandydata
  switch (values.calcCandidateExperience) {
    case 2:
      value += salary * 0.05;
      break;
    case 3:
      value += salary * 0.1;
      break;
    case 4:
      value += salary * 0.15;
      break;
    default:
      break;
  }

  //moment zakończenia usługi
  switch (values.calcServiceCompletionDate) {
    case 2:
      value += salary * 0.05;
      break;
    case 3:
      value += salary * 0.1;
      break;
    case 4:
      value += salary * 0.25;
      break;
    default:
      break;
  }

  //gwarancja na kandydata
  switch (values.calcCandidateWarranty) {
    case 2:
      value += salary * 0.05;
      break;
    case 3:
      value += salary * 0.1;
      break;
    case 4:
      value += salary * 0.15;
      break;
    default:
      break;
  }

  //1 transza platna...
  switch (values.calcUpfrontPayment) {
    case 2:
      value += salary * 0.1 > 1000 ? salary * 0.1 : 1000;
      break;
    case 3:
      value += salary * 0.2 > 1000 ? salary * 0.2 : 1000;
      break;
    case 4:
      value += salary * 0.3 > 2000 ? salary * 0.3 : 2000;
      break;
    default:
      break;
  }

  //termin płatności
  switch (values.calcPaymentDeadline) {
    case 2:
      value += salary * 0.02;
      break;
    case 3:
      value += salary * 0.05;
      break;
    case 4:
      value += salary * 0.08;
      break;
    default:
      break;
  }

  //znizka za więcej poszukiwanych osób
  let discount = 1;
  if (values.candidateCount <= 11) {
    discount = (values.candidateCount - 1) * 0.05;
  } else {
    discount = 0.5;
  }

  //uwzględnienie zniżki za wielu kandydatów
  value = value - salary * discount;
  //sprawdzenie wartości maksymalnej
  value = value > salary * 2 ? salary * 2 : value;

  return value;
}

export const ProjectFormCalculator = props => {
  let {
    classes,
    formValues,
    label = '',
    multiplier = 1,
    largeVersion = false,
    singleCandidate = false,
    value = false,
  } = props;

  const suggestedPrice = Math.round(
    getSuggestedPrice(formValues) *
      multiplier *
      (singleCandidate ? 1 : formValues.candidateCount)
  );

  return largeVersion ? (
    <React.Fragment>
      <Typography component="p" className={classes.textTop}>
        {label}
      </Typography>
      <Typography component="p" gutterBottom className={classes.text}>
        {suggestedPrice || 0} PLN.
      </Typography>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="h5" className={classes.countSume}>
        <MoneyIcon className={classes.money} /> {value || suggestedPrice || 0}{' '}
        PLN.
      </Typography>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.isEdited ? state.projects.currentProject : null,
  };
};

ProjectFormCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ProjectFormCalculator));
