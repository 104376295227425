import axios from 'axios';
import { API, API_URL } from '../constants';
import { getAllSkillsAction } from '../store/skills/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getSkills = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.SKILL.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllSkillsAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
export const getSkillsWithLimit = (limit, offset, params) => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.SKILL.GET_SEARCH,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      limit: limit,
      offset: offset,
      ...params,
    },
  });
};

export const getProjectsSkill = skillId => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.SKILL.GET_PROJECTS_SKILL.replace(':id', skillId),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const getUsersSkill = skillId => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.SKILL.GET_USERS_SKILL.replace(':id', skillId),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const deleteSkill = id => {
  return axios({
    method: 'DELETE',
    url: API_URL + API.DELETE.SKILL.DELETE.replace(':id', id),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const editSkill = (skillId, values) => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.SKILL.EDIT.replace(':id', skillId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const approveSkill = values => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.SKILL.APPROVE_SKILL,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const concatSkill = (skillId, values) => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.SKILL.CONCAT_SKILL.replace(':id', skillId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const addSkill = name => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.SKILL.ADD,
      data: { name },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllSkillsAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
