import {
  Avatar,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  LocationOn as LocationOnIcon,
  Money as MoneyIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { getImageFromSource } from 'shared/utils/assets';
import { checkCandidateFirstNameLastName } from 'shared/utils/functions';

const styles = theme => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  avatar: {
    width: 34,
    height: 34,
    marginLeft: 0,
    backgroundColor: theme.palette.jobllegroBlue,
  },
  rootListText: {
    padding: '0 16x 0 16px',
  },
  primary: {
    fontSize: 14,
    textTransform: 'none',
    color: theme.palette.jobllegroGrey,
  },
  secondary: {
    padding: 0,
    fontSize: 12,
    color: theme.palette.jobllegroGrey,
  },
  textBottom: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.jobllegroLightGrey,
    fontSize: 11,
  },
  icon: {
    fontSize: 9,
    marginLeft: 5,
    marginRight: 1,
  },
});

export const DataItemListItemCandidate = props => {
  const { classes, data, t } = props;
  const avatarSrc = getImageFromSource(data.logo);

  const getSimilaritiesProject = (projectId, data) => {
    const similaritiesProjectArray = data.similaritiesProjectArray;

    const itemSimilaritiesProject = similaritiesProjectArray.find(
      item => item.projectId === projectId
    );
    return itemSimilaritiesProject
      ? `- ${itemSimilaritiesProject.similarities} %`
      : '';
  };

  const recommendText = data.recommendFromProject
    ? data.recommendFromProject.map((item, index) => {
        let header = null;
        if (index === 0) {
          header = (
            <Typography
              key={item.id}
              className={classes.textBottom}
              component="div"
            >
              {t`candidateDetails:reko`}
            </Typography>
          );
        }
        return (
          <Typography key={item.id} component="div">
            {header}
            <Typography className={classes.textBottom} component="div">
              <Link
                to={PATHS.PROJECT.SHOW.replace(':id', item.id)}
                className={classes.link}
              >
                {item.position} {getSimilaritiesProject(item.id, data)}
              </Link>
            </Typography>
          </Typography>
        );
      })
    : null;

  const redirectAfterClick = () => {
    props.history.push(PATHS.CANDIDATE.SHOW.replace(':id', data.id));
  };

  const secondaryText = (
    <Typography component="div">
      <Typography
        className={classes.textBottom}
        component="div"
        onClick={redirectAfterClick}
      >
        {data.industry}
        {data.city !== null && <LocationOnIcon className={classes.icon} />}
        {data.city !== null && data.city}
        {data.minMonthlyWadges !== null && (
          <MoneyIcon className={classes.icon} />
        )}
        {data.minMonthlyWadges !== null &&
          `${data.minMonthlyWadges}pln - ${data.maxMonthlyWadges}pln`}
      </Typography>
      {recommendText}
    </Typography>
  );

  const primaryText = (
    <Typography className={classes.primary} component="div">
      {checkCandidateFirstNameLastName(
        data.firstName,
        data.lastName,
        data.id,
        t
      )}
    </Typography>
  );

  return (
    <ListItem key={data.id} role={undefined} dense className={classes.listItem}>
      <Avatar
        onClick={redirectAfterClick}
        className={classes.avatar}
        src={avatarSrc}
      />
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        classes={{
          root: classes.rootListText,
          primary: classes.primary,
          secondary: classes.secondary,
        }}
      />
    </ListItem>
  );
};

DataItemListItemCandidate.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(withTranslation()(DataItemListItemCandidate))
);
