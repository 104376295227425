import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LinkIcon from 'shared/assets/img/icons/project-show/link.svg';
import CustomDialog from '../../../components/CustomDialog';
import { ActionIconButton } from '../../../modules/project/NewProjectShowView/components/GeneralInformations/components/SideView/components';
import { LANDING_PAGE_URL } from '../../constants';
import { ProjectV2Service } from '../../services';

const GetProjectPublicUrlButton = ({ projectDetails, currentUser, t }) => {
  const { permissions = {} } = projectDetails;
  const [projectPublicUrl, setProjectPublicUrl] = useState(null);
  const [isOpenProjectPublicUrlDialog, setOpenProjectPublicUrlDialog] =
    useState(false);

  const {
    isOwner = {},
    isSuperUser = {},
    isCooperator = {},
    isMentor = {},
    statusInProject = 0,
  } = permissions;

  const getProjectPublicUrl = () => {
    ProjectV2Service.postProjectPublicUrl(projectDetails.id)
      .then(({ data }) => {
        setProjectPublicUrl(data.url);
        setOpenProjectPublicUrlDialog(true);
      })
      .catch(error => {
        setProjectPublicUrl(null);
        setOpenProjectPublicUrlDialog(false);
        console.log(error);
        alert(t`fileInput:unableToGenLink`);
      });
  };

  const handleCloseDialog = () => {
    setOpenProjectPublicUrlDialog(false);
  };
  return (
    (isOwner ||
      isMentor ||
      isSuperUser ||
      (isCooperator && (statusInProject === 5 || statusInProject === 2))) &&
    !projectDetails.closedStatus &&
    !projectDetails.isDraft &&
    currentUser.isRecruiter &&
    projectDetails.visibility === 3 && (
      <React.Fragment>
        <CustomDialog
          maxWidth="md"
          title={t`fileInput:public`}
          isOpen={isOpenProjectPublicUrlDialog}
          onCloseDialog={handleCloseDialog}
          body={
            <Typography variant="body1" color="primary">
              <a
                href={`${LANDING_PAGE_URL}${projectPublicUrl}`}
              >{`${LANDING_PAGE_URL}${projectPublicUrl}`}</a>
            </Typography>
          }
        />
        <ActionIconButton
          name={t('projectDetails:content.getProjectPublicUrl')}
          onClick={getProjectPublicUrl}
          icon={LinkIcon}
        />
      </React.Fragment>
    )
  );
};
export default withTranslation()(GetProjectPublicUrlButton);
