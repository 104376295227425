import { API } from '../constants';
import { ApiService } from './api.service';

export const OrganisationService = {
  getOrganisations() {
    return ApiService.get(API.GET.ORGANIZATION.GET);
  },
  getFinicalPlan(id) {
    return ApiService.get(API.GET.FINICAL_PLAN.GET.replace(':id', id));
  },
  getFinicalPlanOrganization(projectId, organizationId) {
    return ApiService.get(
      API.GET.FINICAL_PLAN.GET_BY_ORGANIZATION.replace(
        ':project',
        projectId
      ).replace(':organization', organizationId)
    );
  },
};

export default OrganisationService;
