export const PATHS = {
  LANDING_PAGE: {
    AUTH: {
      LOGIN: '/auth/sign-in',
      REGISTER: '/auth/registration',
      FORGOT_PASSWORD: '/auth/forgot-password',
      RESET_PASSWORD: '/auth/reset-password?token=:token',
    },
    STATIC_FILES: {
      UNIVERSITIES_COOPERATION: '/universities-cooperation',
      RULES: '/documents/Regulamin_01082023.pdf',
      PRIVACY_POLICY: '/documents/Polityka_prywatnosci_01082023',
      EXAMPLE_CSV: '/documents/exampleCandidateToImport.csv',
    },
  },
  AUTH: {
    LOGIN_LINKEDIN: '/auth/login/linkedin',
    LOGIN: '/auth/login',
    LOGIN_FROM_LANDING_PAGE: '/auth/login/:token/:isFirstLogin',
    LOGIN_FROM_LANDING_PAGE_AND_CANDIDATE:
      '/auth/login/:token/:isFirstLogin/:candidateId',
    LOGIN_FROM_LANDING_PAGE_AND_PROJECT_APPLY:
      '/auth/login/:token/:isFirstLogin/apply-project-from-candidate/:hash',
    LOGIN_TO_APPLY_PROJECT_FROM_CANDIDATE_PUBLIC:
      '/auth/login/:token/:isFirstLogin/apply-project-from-candidate-public/:projectId',
    LOGOUT: '/auth/logout',
    REGISTER: '/auth/authorize/register/:type',
    FORGOT_PASSWORD: '/auth/reset-password',
    RESET_PASSWORD: '/auth/reset-password/:token',
    LANDING: '/home',
  },
  ADMIN: {
    INDUSTRY_CATEGORY: '/admin/industryCategory/',
    CITY_SEO: '/admin/citySeo/',
    ORGANIZATION: '/admin/organization/',
    CONFIG: '/admin/config/',
    USER: '/admin/user/',
    SUPER_USER: '/admin/superUser/',
    SKILLS: '/admin/skills/',
    COMPETENCE_CATEGORIES: '/admin/competenceCategories/',
    COMPETENCE_TYPE: '/admin/competenceTypes/',
    COMPETENCE_KINDS: '/admin/competenceKinds/',
    COMPETENCE_TEMP: '/admin/competences/',
    SKILLS_TO_APPROVED: '/admin/skills-to-approved/',
    CANDIDATE: '/admin/candidate/',
    LOGS: '/admin/logs/',
    HELPDESK: '/admin/helpdesk/',
    EXPORTS: '/admin/exports/',
    ML_STATUS: '/admin/ml-status/',
    CMS_CONFIG: '/admin/cms-config/',
  },
  MAIN: '/',
  DASHBOARD: { INDEX: '/dashboard' },
  PROJECT: {
    INDEX: '/project',
    INDEX_SHOW: '/project/list/:show',
    CREATE: '/project/create',
    CREATE_FROM: '/project/create/:id',
    NEW_CREATE_FROM: '/project/create/:id',
    NEW_CREATE: '/project/newCreate',
    EDIT: '/project/edit/:id',
    NEW_EDIT: '/project/v2/edit/:id',
    SHOW: '/project/v2/:id',
    NEW_SHOW: '/project/v2/:id',
    CANDIDATES: '/project/:id/candidates',
    RECRUITERS: '/project/:id/recruiters',
    PROJECT_ORDERS: '/project/:id/project-orders',
    ATS: '/project/:id/ats',
    ATS_EMPLOYMENT_PROCESS: '/project/:id/ats/employment-process',
    ATS_EMPLOYMENT_MANAGEMENT: '/project/:id/ats/employment-management',
    PAYMENTS: '/project/:id/payments',
    IMPORT_CANDIDATES: '/project/:projectId/import-candidates',
  },
  RECRUITER: {
    INDEX: '/recruiter',
    INDEX_SHOW: '/recruiter/list/:show',
    SHOW: '/recruiter/:id',
    PERSONAL_DATA: '/recruiter/:id/personal-data',
    ORGANIZATIONS: '/recruiter/:id/organizations',
    ALL_PROJECTS: '/recruiter/:id/all-projects',
    SETTELMENTS: '/recruiter/:id/settelments',
    HELP: '/recruiter/:id/help',

    PROJECTS: {
      PO: '/recruiter/:id/open-projects',
      CO: '/recruiter/:id/cooperates-projects',
      CLOSED: '/recruiter/:id/closed-projects',
    },
    ORGANIZATION: {
      INDEX: '/recruiter/organization',
      RECRUITERS: '/recruiter/organization/recruiters',
      EDIT: '/recruiter/organization/edit',
    },
  },
  ORGANIZATION: {
    INDEX: '/organization',
    SHOW: '/organization/:id',
    RECRUITERS: '/organization/:id/recruiters',
  },
  CANDIDATE_SUMMARY: { INDEX: '/candidate-summary' },
  SUMMARY: {
    INDEX: '/summary/:type/:filter',
    ORGANIZATION_ALL: '/summary/organization/organizationAll',
  },
  SUMMARY_FOR_CANDIDATE: { INDEX: '/summary-for-candidate/:filter' },
  CANDIDATE: {
    INDEX: '/candidate',
    INDEX_SHOW: '/candidate/list/:show',
    CREATE: '/candidate/create',
    CREATE_IN_PROJECT: '/candidate/create/project/:projectId',
    EDIT: '/candidate/edit/:id',
    NEW_EDIT: '/candidate/v2/edit/:id',
    SHOW: '/candidate/:id',
    PROJECTS: '/candidate/:id/projects',
    PERSONAL_DATA: '/candidate/:id/personal-data',
    EMPLOYMENT_STATUS: '/candidate/:id/employment-status',
    HELP: '/candidate/:id/help',
  },
  RATING: { INDEX: '/rating' },
  REPORT: { INDEX: '/report' },
  ACTIVITY: { INDEX: '/activity' },
  USER: { INDEX: '/user' },
  SEARCH: { INDEX: '/search' },
  STATUS: { S404: '/404' },
  DEBUG: { INDEX: '/debug' },
};
export const NEW_LAYOUT_PATHS = {
  PROJECT_CREATE: `${PATHS.PROJECT.CREATE}/v2`,
};
