import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from 'shared/services/authService';
import { NewCandidateAnonymousDetailsView } from '../candidate';
import { NewRecruiterDetailsView } from '../recruiter';

export class UserPanelView extends Component {
  render() {
    const {
      currentUser = {},
      currentUser: { isRecruiter = false },
    } = this.props;
    const panel = isRecruiter ? (
      <NewRecruiterDetailsView id={currentUser.id} />
    ) : (
      <NewCandidateAnonymousDetailsView id={currentUser.id} />
    );

    return <React.Fragment>{panel}</React.Fragment>;
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getCurrentUser,
};

UserPanelView.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPanelView);
