import { withStyles } from '@material-ui/core/styles';
import AdminCitySeoView from './AdminCitySeoView';

const styles = () => ({
  root: {
    padding: '0 20px',
    margin: '0 auto',
  },
});

export default withStyles(styles)(AdminCitySeoView);
