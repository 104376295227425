import { bool, number, object } from 'yup';

export const MODEL = {
  linkedinCandidateLink: 'linkedinCandidateLink',
  facebookCandidateLink: 'facebookCandidateLink',
  isSecondStageInfoPublic: 'isSecondStageInfoPublic',
  isCandidateDisabled: 'isCandidateDisabled',
  degreeOfDisability: 'degreeOfDisability',
  candidateAssets: 'candidateAssets',

  disabilityReason: 'disabilityReason',
  disabilityIsConfirm: 'disabilityIsConfirm',
  disabilityWorkPlaceDesc: 'disabilityWorkPlaceDesc',
};

export const initialValues = {};

export const validationSchema = t =>
  object().shape({
    isCandidateDisabled: bool(),
    degreeOfDisability: number().when('isCandidateDisabled', {
      is: isCandidateDisabled => {
        return isCandidateDisabled === true;
      },
      then: number()
        .typeError(t('projectCreator:core.requiredField'))
        .required(t('projectCreator:core.requiredField')),
      otherwise: number().nullable(),
    }),
  });
