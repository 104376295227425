import { withStyles } from '@material-ui/core/styles';
import ForgotPassword from './ForgotPassword';

const styles = () => ({
  formContainer: {
    height: 'auto',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default withStyles(styles)(ForgotPassword);
