import { Grid, Paper, withStyles } from '@material-ui/core';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { setLastPage } from 'shared/services/authService';
import {
  getCandidates,
  getCandidatesWithLimit,
  clearCurrentCandidatesLimitedList,
  clearCurrentCandidatesLimitedListCounter,
  deleteCandidate,
  getCandidatesBelongsToRecruiter,
  getCandidatesPendingToRecruiter,
  acceptConfirmationStatusToCandidate,
  rejectConfirmationStatusToCandidate,
  getCandidatesCount,
} from 'shared/services/candidateService';
//import CandidateIndexMenu from './candidateMenu/CandidateIndexMenu';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import CandidateAdd from '../../components/CandidateAdd';
import ConfirmationDialog from '../../components/LayoutConfirmationDialog/';
import PageHeader from '../../components/PageHeader';
import TopMenu from '../../components/TopMenu';
import CandidateList from './CandidateList';

const styles = theme => ({
  containerCandidates: {
    marginTop: 20,
  },
  dialog: {
    width: '80%',
    maxHeight: 435,
  },
  root: {
    marginTop: 0,
  },
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
});

export class CandidateIndexView extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      candidates: [],
      selectedCandidate: null,
      confirmationOpened: false,
      confirmationInvitationOpened: false,
      load: false,
      lockLoadScreen: false,
      isLoadingEdit: false,
      anchorEl: null,
      menuCounters: [],
    };
  }

  menuRecruiterElements = [
    {
      name: t('candidate:menu.forAccept'),
      route_url: 'invitation',
      url: PATHS.CANDIDATE.INDEX_SHOW.replace(':show', 'invitation'),
    },
    {
      name: t('candidate:menu.candidateFromStash'),
      route_url: 'candidateFromStash',
      url: PATHS.CANDIDATE.INDEX_SHOW.replace(':show', 'candidateFromStash'),
    },
    {
      name: t('candidate:menu.myCandidates'),
      route_url: 'cooperation',
      url: PATHS.CANDIDATE.INDEX_SHOW.replace(':show', 'cooperation'),
    },
    {
      name: t('candidate:menu.all'),
      route_url: undefined,
      url: PATHS.CANDIDATE.INDEX,
    },

    {
      name: t('candidate:menu.candidatesInOrganization'),
      route_url: 'organization',
      url: PATHS.CANDIDATE.INDEX_SHOW.replace(':show', 'organization'),
      accessOrganization: true,
    },
  ];

  getFilterParameters(filterName) {
    const { currentUser } = this.props;
    if (filterName === 'cooperation') {
      return { owner: currentUser.id, withPublic: false };
    } else if (filterName === 'invitation') {
      return { ownerToBe: currentUser.id };
    } else if (filterName === 'organization') {
      return { inOrganization: true };
    } else if (filterName === 'candidateFromStash') {
      return { candidateFromStash: true, withPublic: true };
    } else {
      return { withPublic: true };
    }
  }

  async getMenuCoutners() {
    let menuCounters = {};
    await Promise.all(
      this.menuRecruiterElements.map(async element => {
        const { count = 0 } = await this.getCounter(element.route_url);
        menuCounters[element.route_url] = count || 0;
      })
    );
    if (this._isMounted) {
      this.setState({ menuCounters });
    }
  }

  getCounter = async route => {
    const params = this.getFilterParameters(route);
    return await this.props.getCandidatesCount(params);
  };

  loadCandidates = async ({
    show,
    limit,
    offset = 0,
    filters = null,
    orderBy = null,
  }) => {
    let { currentUser } = this.props;
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.url) || 12
      : limit;
    const paramShow = show === undefined ? this.props.match.params.show : show;
    let filter = undefined;
    if (paramShow === 'cooperation') {
      filter = { owner: currentUser.id, withPublic: false };
    } else if (paramShow === 'invitation') {
      filter = { ownerToBe: currentUser.id };
    } else if (paramShow === 'organization') {
      filter = { inOrganization: true };
    } else if (paramShow === 'candidateFromStash') {
      filter = { candidateFromStash: true, withPublic: true };
    } else {
      filter = { withPublic: true, skipIncompleteUsers: true };
    }
    let params = {
      ...filter,
      filters,
      orderBy,
    };
    if (this._isMounted) {
      this.setState({ lockLoadScreen: true });
    }
    this.getMenuCoutners();
    await this.props.getCandidatesWithLimit(limitToSearch, offset, params);
    this.props.setLastPage(this.props.location.pathname);
    if (this._isMounted) {
      this.setState({ load: false, lockLoadScreen: false });
    }
  };

  handleReloadElements = ({ limit, offset, filters, orderBy }) => {
    return this.loadCandidates({
      show: undefined,
      limit,
      offset,
      filters,
      orderBy,
    });
  };

  handleDelete = async id => {
    try {
      await this.props.deleteCandidate(id);
      this.setState({
        selectedCandidate: null,
        confirmationOpened: false,
      });
      this.loadCandidates();
    } catch (err) {
      console.error(err);
      this.setState({
        selectedCandidate: null,
        confirmationOpened: false,
      });
    }
  };

  handleAccept = async id => {
    try {
      this.setState({
        isLoading: true,
      });
      await this.props.acceptConfirmationStatusToCandidate(id);
      await this.props.getCandidatesPendingToRecruiter(id);
      await this.defaultLoad();
      this.setState({ isLoading: false });
      this.props.showSnackbarMessage(
        t('candidate:list.candidateHasBeenAccepted')
      );
    } catch (err) {
      console.error(err);
    }
  };

  handleReject = async id => {
    try {
      this.setState({
        isLoading: true,
        confirmationInvitationOpened: false,
      });
      await this.props.rejectConfirmationStatusToCandidate(id);
      await this.props.getCandidatesPendingToRecruiter(id);
      await this.defaultLoad();
      this.setState({ isLoading: false });
      this.props.showSnackbarMessage(
        t('candidate:list.candidateHasBeenRejected')
      );
    } catch (err) {
      console.error(err);
    }
  };

  handleConfirmationOpen = id => {
    this.setState({
      selectedCandidate: id,
      confirmationOpened: true,
    });
  };

  handleConfirmationInvitationOpen = id => {
    this.setState({
      selectedCandidate: id,
      confirmationInvitationOpened: true,
    });
  };

  handleConfirmationClose = isOk => {
    if (isOk) {
      this.handleDelete(this.state.selectedCandidate);
      this.props.showSnackbarMessage(
        t('candidate:list.candidateHasBeenRemoved')
      );
    } else {
      this.setState({
        selectedCandidate: null,
        confirmationOpened: false,
      });
    }
  };

  handleConfirmationInvitationClose = isOk => {
    if (isOk) {
      this.handleReject(this.state.selectedCandidate);
    } else {
      this.setState({
        selectedCandidate: null,
        confirmationInvitationOpened: false,
      });
    }
  };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.show !== this.props.match.params.show) {
      await this.props.clearCurrentCandidatesLimitedList();
      await this.props.clearCurrentCandidatesLimitedListCounter();
      this.setState({ load: true });
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  defaultLoad = () => {
    return this.loadCandidates({
      show: undefined,
      limit: null,
      offset: null,
      filters: null,
      orderBy: { id: 'desc', updatedAt: 'desc' },
    });
  };

  componentDidMount = async () => {
    this._isMounted = true;
    await this.props.clearCurrentCandidatesLimitedList();
    await this.props.clearCurrentCandidatesLimitedListCounter();
    await this.defaultLoad();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, t } = this.props;
    const { anchorEl, load, menuCounters, lockLoadScreen } = this.state;

    return (
      <React.Fragment>
        <PageHeader
          title={t('candidate:list.pageTitle')}
          component={<CandidateAdd handleRefresh={this.loadCandidates} />}
        />
        <ConfirmationDialog
          classes={{
            paper: classes.dialog,
          }}
          open={this.state.confirmationOpened}
          titleText={t('candidate:list.removeCandidate')}
          onClose={this.handleConfirmationClose}
          okButtonText={t('action.remove')}
          cancelButtonText={t('action.cancel')}
        >
          {t('candidate:list.removeCandidateConfirmation')}
        </ConfirmationDialog>
        <ConfirmationDialog
          classes={{
            paper: classes.dialog,
          }}
          open={this.state.confirmationInvitationOpened}
          titleText={t('candidate:list.rejectCandidate')}
          onClose={this.handleConfirmationInvitationClose}
          okButtonText={t('candidate:list.rejectCandidate')}
          cancelButtonText={t('action.cancel')}
        >
          {t('candidate:list.rejectCandidateConfirmation')}
        </ConfirmationDialog>
        <Grid
          container
          alignItems="flex-start"
          spacing={2}
          className={classes.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TopMenu
              handleClick={this.handleClick}
              handleClose={this.handleClose}
              counters={menuCounters}
              menuElements={this.menuRecruiterElements}
              anchorEl={anchorEl}
              itemSelected={this.props.match.params.show || undefined}
            />
            <Paper className={classes.rootPaper} elevation={0}>
              <CandidateList
                storageKey={this.props.match.url}
                handleReloadElements={this.handleReloadElements}
                handleDelete={this.handleConfirmationOpen}
                handleAccept={this.handleAccept}
                handleReject={this.handleConfirmationInvitationOpen}
                load={load}
                lockLoadScreen={lockLoadScreen}
              />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getCandidates,
  getCandidatesWithLimit,
  clearCurrentCandidatesLimitedList,
  clearCurrentCandidatesLimitedListCounter,
  deleteCandidate,
  getCandidatesBelongsToRecruiter,
  getCandidatesPendingToRecruiter,
  acceptConfirmationStatusToCandidate,
  rejectConfirmationStatusToCandidate,
  getCandidatesCount,
  showSnackbarMessage,
  setLastPage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    candidates: store.candidates.currentCandidatesLimitedList,
    candidatesCount: store.candidates.currentCandidatesLimitedListCounter,
  };
};

CandidateIndexView.propTypes = {
  classes: PropTypes.object.isRequired,
  setLastPage: PropTypes.func.isRequired,
  getCandidatesWithLimit: PropTypes.func.isRequired,
  clearCurrentCandidatesLimitedList: PropTypes.func.isRequired,
  clearCurrentCandidatesLimitedListCounter: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(CandidateIndexView))));
