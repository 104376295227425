export const styles = theme => ({
  wrapper: {
    padding: '16px 150px',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
    marginBottom: 40,
  },
  leftSectionProject: {
    boxSizing: 'border-box',
    display: 'flex',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    height: 'auto',
    flexDirection: 'column',
    placeContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  rightSectionProject: {
    boxSizing: 'border-box',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  companyLogotype: {
    width: '100%',
    height: 'auto',
  },
  editProjectButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    width: 30,
    height: 30,
    background: '#fff',
    padding: 0,
    [theme.breakpoints.only('xs')]: {
      left: 10,
      position: 'fixed',
      zIndex: '9999',
      width: 50,
      height: 50,
      background: theme.palette.jobllegroOrange,
      color: '#ffffff',
      top: '80vh',
    },
  },
  sectionBottom: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  projectPosition: {
    color: theme.palette.jobllegroBlue,
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  projectClient: {
    color: theme.palette.jobllegroGrey,
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  projectWww: {
    color: theme.palette.jobllegroBlue,
    '& > a': {
      color: theme.palette.jobllegroBlue,
      fontSize: 13,
      textDecoration: 'none',
    },
  },
  chip: {
    border: '0px',
    color: theme.palette.jobllegroLightGrey,
  },
  projectInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2.5),
  },
  chipIcon: {
    width: 14,
    color: theme.palette.jobllegroLightGrey,
  },
  avatarChip: {
    background: 'none',
    width: 'auto',
    height: 'auto',
  },
  containerLeft: {
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      position: 'static',
    },
  },
  imagesWrapper: {
    position: 'relative',
  },
  header: {
    maxHeight: 270,
    width: '100%',
    borderRadius: 10,
  },
  logoWrapper: {
    position: 'absolute',
    height: '100%',
    paddingLeft: 50,
  },
  logo: {
    borderRadius: '50%',
  },
  changeEmploymentLocBtn: {
    float: 'right',
    color: '#fff',
    background: '#01D38B',
    borderRadius: 30,
    margin: '25px 0',
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
});
