import { withStyles } from '@material-ui/core/styles';
import IsAuthorized from './IsAuthorized';

const styles = theme => ({
  progress: {
    margin: `0 ${theme.spacing(2)}px`,
    marginTop: 150,
  },
  progressContainer: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    textAlign: 'center',
  },
  dialog: {
    width: '80%',
    maxHeight: 435,
  },
});

export default withStyles(styles)(IsAuthorized);
