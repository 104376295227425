import {
  FormControl,
  FormHelperText,
  Grid,
  withStyles,
} from '@material-ui/core';
import { get, has } from 'lodash';
import React from 'react';
import SelectContainer, { components } from 'react-select';
import { styles } from './MultiSelect.styles';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const MultiSelect = ({
  classes,
  label,
  name,
  formik,
  options,
  disabled = false,
  isMultiOption = false,
}) => {
  const handleSelectChange = e => {
    formik.setFieldValue(name, e);
  };

  const error =
    has(formik, `touched.${name}`) && Boolean(get(formik.errors, name));

  return (
    <Grid container className={classes.root}>
      <FormControl
        fullWidth
        error={error}
        variant="outlined"
        disabled={disabled}
      >
        <SelectContainer
          maxMenuHeight={175}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          styles={{
            multiValue: provided => ({
              ...provided,
              borderRadius: 30,
            }),
            control: provided => ({
              ...provided,
              '&:hover': {
                borderColor: error ? 'red' : '#B3B3B3',
              },
              borderColor: error ? 'red' : '#B3B3B3',
              minHeight: 58,
              borderRadius: 30,
              color: 'rgba(0, 0, 0, 0.87)',
              cursor: 'text',
              fontSize: '1rem',
              fontFamily: 'Barlow Semi Condensed, sans-serif',
              lineHeight: '1.1875em',
              alignItems: 'center',
            }),
            menu: provided => ({
              ...provided,
              fontSize: '1rem',
              fontFamily: 'Barlow Semi Condensed, sans-serif',
              lineHeight: '1.1875em',
              alignItems: 'center',
              zIndex: 9999,
            }),
            menuList: base => ({
              ...base,
              zIndex: 9999,
            }),
            valueContainer: provided => ({
              ...provided,
              overflow: 'visible',
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: error ? 'red' : '#B3B3B3',
              position: 'absolute',
              top: state.hasValue || state.selectProps.inputValue ? -15 : '50%',
              background: 'white',
              transition: 'top 0.1s, font-size 0.1s',
              fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
            }),
          }}
          value={
            isMultiOption
              ? get(formik.values, name)
              : options
              ? options.find(
                  option =>
                    get(formik.values, name) &&
                    option.value === get(formik.values, name).id
                )
              : ''
          }
          placeholder={label}
          options={options}
          isMulti={isMultiOption}
          onChange={e => handleSelectChange(e)}
          name={name}
        />
        {error && (
          <FormHelperText>
            {has(formik, `touched.${name}`) &&
              (get(formik.errors, name).id || get(formik.errors, name))}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(MultiSelect);
