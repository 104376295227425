import { Grid, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import publicIcon from 'shared/assets/img/icons/project-show/internet.png';
import unPublicIcon from 'shared/assets/img/icons/project-show/unPublic.svg';
import { Translate } from 'shared/newComponents';
import AssignCandidateToStashButton from 'shared/newComponents/AssignCandidateToStashButton/AssignCandidateToStashButton';
import { deleteUser, logoutUser } from 'shared/services';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';
import AssignCandidateToProjectsButton from '../../../../../components/AssignCandidateToProjectsButton';
import CandidateDetailsGetMore from '../../../../../components/CandidateDetailsGetMore';
import CustomDialog from '../../../../../components/CustomDialog';
import ConfirmationDialog from '../../../../../components/LayoutConfirmationDialog/LayoutConfirmationDialog';
import { styles } from './CandidateDetailsHeaderCard.styles';

const CandidateAnonymousCard = ({
  candidate,
  classes,
  currentUser,
  itemSelected,
  title,
  t,
  history,
  ...props
}) => {
  const [deleteConfirmationOpened, setDeleteConfirmationOpened] =
    useState(false);

  const [openGetMoreDialog, setOpenGetMoreDialog] = useState(false);

  const handleGetMoreInformation = () => {
    setOpenGetMoreDialog(true);
  };

  const handleCloseGetMoreInformationDialog = () => {
    setOpenGetMoreDialog(false);
  };
  const isCandidate = !currentUser.isRecruiter;

  const handleClickChangePassword = () => {
    history.push(PATHS.AUTH.FORGOT_PASSWORD);
  };

  const handleClickRemoveButton = () => {
    setDeleteConfirmationOpened(true);
  };

  const handleRemove = async isOk => {
    if (isOk) {
      props.deleteUser(currentUser.id).then(() => {
        props.logoutUser();
        history.push(PATHS.AUTH.LOGIN);
      });
    }
    setDeleteConfirmationOpened(false);
  };
  return (
    <Grid container className={classes.wrapper} style={{ width: '100%' }}>
      <Grid
        container
        style={{
          alignItems: 'center',
        }}
      >
        <Grid item xs={10} sm={10} md={6}>
          <Grid
            container
            style={{
              alignItems: 'center',
            }}
          >
            <Grid item xs={5} sm={5} md={5}>
              <Translate text={title} bold style={{ fontSize: 20 }} />
            </Grid>
            {currentUser.id === candidate.id &&
            itemSelected !== 'all-projects' ? (
              <Grid item xs={5} sm={5} md={5}>
                <div
                  onClick={handleGetMoreInformation}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    border: '2px solid #868889',
                    boxSizing: 'border-box',
                    borderRadius: '6px',
                    minWidth: 153,
                  }}
                >
                  <Translate
                    text={'candidateDetails:candidateMenu.getMore'}
                    style={{ fontSize: 14 }}
                  />
                </div>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  alt="icon"
                  src={candidate.isPublic ? publicIcon : unPublicIcon}
                  style={{ margin: '10px 10px' }}
                />
                <Translate
                  text={
                    candidate.isPublic
                      ? 'projectDetails:content.public'
                      : 'projectDetails:content.isNotPublic'
                  }
                  variant="body1"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          style={{
            padding: '30px 30px 30px 30px',
          }}
        >
          {isCandidate ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <ActionButtonJobllegroRounded
                onClick={handleClickChangePassword}
                style={{ background: '#27D38B' }}
              >
                {t('recruiter:form.changePassword')}
              </ActionButtonJobllegroRounded>

              <ActionButtonJobllegroRounded
                onClick={handleClickRemoveButton}
                style={{ background: 'red' }}
              >
                {t('recruiter:form.removeProfileRecruiter')}
              </ActionButtonJobllegroRounded>

              <ConfirmationDialog
                open={deleteConfirmationOpened}
                titleText={t('recruiter:form.removeProfileRecruiterText')}
                onClose={handleRemove}
                okButtonText={t('action.remove')}
                cancelButtonText={t('action.cancel')}
              >
                {t('form.info.removeAccount')}
              </ConfirmationDialog>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <AssignCandidateToProjectsButton
                option="button"
                candidate={candidate}
                btnColor={'#27D38B'}
              />
              <AssignCandidateToStashButton
                currentUser={currentUser}
                option="button"
                candidate={candidate}
                btnColor={'#27D38B'}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <CustomDialog
        onCloseDialog={handleCloseGetMoreInformationDialog}
        body={<CandidateDetailsGetMore itemSelected={itemSelected} />}
        isOpen={openGetMoreDialog}
      />
    </Grid>
  );
};

const mapDispatchToProps = {
  deleteUser,
  logoutUser,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(CandidateAnonymousCard))));
