import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import CustomDialog from '../../../components/CustomDialog';
import { ButtonJobllegrov1 } from '../../utils/styledComponents';
import { Translate } from '../index';

const MissingOrganizationDetailsDialog = ({
  t,
  handleOnCloseDialog,
  history,
  isOpenOrganizationDialog,
  organizationOwner = false,
}) => {
  const handleRedirectToOrganizationInfo = () => {
    history.push(PATHS.RECRUITER.ORGANIZATION.EDIT);
    handleOnCloseDialog();
  };

  return (
    <CustomDialog
      title={t('notification:general.missingInvoiceInfo')}
      isOpen={isOpenOrganizationDialog}
      onCloseDialog={handleOnCloseDialog}
      body={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <Translate
              text={
                organizationOwner
                  ? t`notification:general.missingInvoiceInfoText`
                  : t`notification:general.missingInvoiceInfoTextMembers`
              }
              variant="body1"
              style={{ paddingBottom: 8, fontSize: 18 }}
            />
          </div>
          {organizationOwner ? (
            <div>
              <ButtonJobllegrov1
                type="submit"
                color="primary"
                onClick={handleRedirectToOrganizationInfo}
              >
                {t('notification:general.missingInvoiceInfoTextRedirect')}
              </ButtonJobllegrov1>
            </div>
          ) : null}
        </div>
      }
    />
  );
};

export default withRouter(withTranslation()(MissingOrganizationDetailsDialog));
