import moment from 'moment';
import { diffBetweenDate } from '../../../../../ProjectAts/utils/utils';

export const getConfirmComeBackShowBtnStatus = breakEndDate => {
  if (breakEndDate === null || breakEndDate === '') {
    return false;
  }
  const showConfirmComebackBtn = diffBetweenDate(breakEndDate, moment());

  return showConfirmComebackBtn >= 0;
};
