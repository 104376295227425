import {
  Grid,
  LinearProgress,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import CandidateCard from 'shared/newComponents/CandidateCard';
import { getLanguages } from 'shared/services/languageService';
import { getSkills } from 'shared/services/skillService';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import AssignCandidateToProjectsButton from '../../../components/AssignCandidateToProjectsButton';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import LayoutPopperMenu from '../../../components/LayoutPopperMenu';
import ElementsListAsync from '../../../components/list/ElementsListAsync';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: -16,
  },
  menuItem: {
    display: 'flex',
    fontSize: 12,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    color: theme.palette.jobllegroGrey,
    textTransform: 'none',
    borderBottom: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    height: 16,
    '& svg': {
      fontSize: 12,
      color: theme.palette.jobllegroLightGrey,
      marginRight: 10,
    },
  },
});

export class CandidateList extends Component {
  state = {
    anchorEl: null,
    candidateSelected: {},
  };

  componentDidMount() {
    this.props.getLanguages();
    this.props.getSkills();
  }

  handleToggleMenuCardOpen = candidate => event => {
    this.setState({
      anchorEl: event.currentTarget,
      candidateSelected: candidate,
    });
  };

  handleToggleMenuCardClose = () => {
    this.setState({
      anchorEl: null,
      candidateSelected: {},
    });
  };

  triggerDeleteButton = id => () => {
    this.props.handleDelete(id);
    this.handleToggleMenuCardClose();
  };

  getActionButtonsForCandidate = candidate => {
    const { permissions = {} } = candidate;
    const { isOwner } = permissions;
    const { classes, t } = this.props;
    return (
      <div>
        {/*<MenuItem*/}
        {/*    className={classes.menuItem}*/}
        {/*    aria-label='Delete'*/}
        {/*    disabled={isOwner ? false : true}*/}
        {/*    onClick={this.triggerDeleteButton(candidate.id)}*/}
        {/*>*/}
        {/*    <DeleteSweepIcon/>*/}
        {/*    {t('candidate:list.delete')}*/}
        {/*</MenuItem>*/}
        <MenuItem
          className={classes.menuItem}
          aria-label="Edit"
          disabled={isOwner ? false : true}
          component={Link}
          to={PATHS.CANDIDATE.NEW_EDIT.replace(':id', candidate.id)}
        >
          <EditIcon />
          {t('candidate:list.edit')}
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <AssignCandidateToProjectsButton option="box" candidate={candidate} />
        </MenuItem>
      </div>
    );
  };

  getContentCandidate = candidate => {
    return (
      <CandidateCard
        listType={'projectCandidate'}
        candidate={candidate}
        handleDelete={this.props.handleDelete}
        handleAccept={this.props.handleAccept}
        handleReject={this.props.handleReject}
        handleToggleMenuCardOpen={this.handleToggleMenuCardOpen}
      />
    );
  };

  getContent = candidate => {
    const content = this.getContentCandidate(candidate);
    const actionButtonsCandidate = this.getActionButtonsForCandidate(
      this.state.candidateSelected
    );

    return (
      <React.Fragment key={candidate.id}>
        <Grow in={true}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={candidate.id}>
            {content}
          </Grid>
        </Grow>
        <LayoutPopperMenu
          style={{ boxShadow: 0 }}
          anchorEl={this.state.anchorEl}
          handleToggleMenuCardClose={this.handleToggleMenuCardClose}
        >
          {actionButtonsCandidate}
        </LayoutPopperMenu>
      </React.Fragment>
    );
  };

  getWrapper = options => {
    let { content, pagination, sorting, filtering } = options;
    const { t } = this.props;

    return (
      <div>
        <ExpansionComponent
          title={t('candidate:list.filteringTitle')}
          textOnTop={t('candidate:list.filteringDescription')}
        >
          {filtering}
        </ExpansionComponent>
        {sorting}
        {this.props.lockLoadScreen ? (
          <LinearProgress />
        ) : (
          <Grid container className={this.props.classes.container}>
            {content.length !== 0 ? (
              content
            ) : (
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h1"
                  align="center"
                  style={{ paddingTop: '15%', height: '50vh' }}
                >
                  {t('candidate:list.noCandidatesFound')}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {pagination}
      </div>
    );
  };

  render() {
    const { languagesDictionary, skillsDictionary, t } = this.props;

    const skills = skillsDictionary.map(skill => ({
      key: skill.id,
      name: skill.name,
    }));
    const languages = languagesDictionary.map(language => ({
      key: language.id,
      name: language.name,
    }));

    let sortingOptions = [
      {
        name: 'id',
        label: t('candidate:field.id'),
      },
      {
        name: 'firstName',
        label: t('candidate:field.firstName'),
      },
      {
        name: 'lastName',
        label: t('candidate:field.lastName'),
      },
      {
        name: 'city',
        label: t('candidate:field.city'),
      },
      {
        name: 'createdAt',
        label: t('candidate:field.createdAt'),
      },
      {
        name: 'updatedAt',
        label: t('candidate:field.updatedAt'),
      },
    ];
    const defaultSorting = {
      id: 'desc',
      updatedAt: 'desc',
    };
    const filteringOptions = [
      {
        key: 'isPublic',
        type: 'boolean',
        name: t('candidate:field.isPublic'),
      },
      {
        key: 'firstName',
        type: 'text',
        name: t('candidate:field.firstName'),
      },
      {
        key: 'lastName',
        type: 'text',
        name: t('candidate:field.lastName'),
      },
      {
        key: 'city',
        type: 'text',
        name: t('candidate:field.city'),
      },
      {
        key: 'position',
        type: 'text',
        name: t('candidate:field.position'),
      },
      {
        key: 'birthDate',
        type: 'text',
        name: t('candidate:field.birthDate'),
        placeholder: 'YYYY-MM-DD',
      },
      {
        key: 'industry',
        type: 'text',
        name: t('candidate:field.industry'),
      },
      {
        key: 'minMonthlyWadges',
        type: 'number',
        name: t('candidate:field.minMonthlyWadges'),
      },
      {
        key: 'maxMonthlyWadges',
        type: 'number',
        name: t('candidate:field.maxMonthlyWadges'),
      },
      {
        key: 'availabilityDate',
        type: 'text',
        name: t('candidate:field.availabilityDate'),
        placeholder: 'YYYY-MM-DD',
      },
      {
        key: 'experience',
        type: 'number',
        name: t('candidate:field.experience'),
      },
      {
        key: 'contractType',
        type: 'text',
        name: t('candidate:field.contractType'),
      },
      {
        key: 'rating',
        type: 'number',
        name: t('candidate:field.rating'),
      },
      {
        key: 'havingLanguages',
        type: 'multiselect',
        name: t('project:field.projectLanguages'),
        values: languages,
      },
      {
        key: 'havingSkills',
        type: 'multiselect',
        name: t('project:field.projectSkills'),
        values: skills,
      },
    ];

    return (
      <ElementsListAsync
        storageKey={this.props.storageKey}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        handleReloadElements={this.props.handleReloadElements}
        load={this.props.load}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        sortingOptions={sortingOptions}
        defaultSorting={defaultSorting}
        filteringOptions={filteringOptions}
        elements={this.props.candidates}
        elementsCount={this.props.candidatesCount}
        scrollUp={true}
      />
    );
  }
}

CandidateList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  getLanguages,
  getSkills,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    candidates: store.candidates.currentCandidatesLimitedList,
    candidatesCount: store.candidates.currentCandidatesLimitedListCounter,
    languagesDictionary: store.dictionaries.languages.list,
    skillsDictionary: store.dictionaries.skills.list,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(CandidateList)));
