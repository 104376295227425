export const styles = () => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#2D333B',
  },
  dialogTitle: {
    '& h6': { fontWeight: '500' },
  },
  content: { background: '#E5F0FF' },
  txtField: {
    background: '#B7BEC8',
    borderRadius: 30,
    pointerEvents: 'none',
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 10,
  },
  date: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  datePicker: { marginRight: 40 },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  fontRequestData: {
    color: '#FFA412',
    fontSize: 24,
    cursor: 'pointer',
  },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 40px',
    padding: 20,
  },
  delBtn: {
    display: 'flex',
    margin: '0 auto',
    borderRadius: 30,
    color: '#B7BEC8',
    '&:hover': { borderColor: '#F41313', color: '#F41313', background: '#fff' },
  },
  saveBtn: {
    display: 'flex',
    color: '#fff',
    margin: '40px auto',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  fileSection: { marginTop: 10, display: 'flex', justifyContent: 'center' },
  filePaper: {
    margin: '10px 20px',
    borderRadius: 10,
    padding: 15,
    width: '100%',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileIsAttach: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    textAlign: 'center',
  },
  fileName: {
    color: '#359CFF',
    fontSize: '1rem',
    wordBreak: 'break-all',
    textAlign: 'center',
  },
  addIcon: { textAlign: 'center' },
  attachments: { margin: 25 },
  grid: { marginTop: 20 },
});
