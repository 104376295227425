import { withStyles } from '@material-ui/core/styles';
import Footer from './Footer';

const styles = theme => ({
  sectionRootContainer: {
    zIndex: '1000',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom, #1A212B, #1A212B)',
    height: 'auto',
  },
  sectionRoot: {
    maxWidth: 1280,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& h6': {
      color: theme.palette.jobllegroLightGrey,
      textTransform: 'uppercase',
      [theme.breakpoints.only('xs')]: {
        paddingTop: 20,
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  footerBottomContainer: {
    color: theme.palette.jobllegroGrey,
  },
  button: {
    color: '#fff',
    textTransform: 'none',
    fontWeight: 100,
    fontSize: 16,
    minHeight: '28px',
    '&:hover': {
      background: 'transparent',
    },
    '& span': {
      justifyContent: 'flex-start',
    },
  },
  buttonLogotype: {
    padding: 0,
    maxWidth: 200,
    height: 'auto',
    '&:hover': {
      background: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 200,
      display: 'flex',
      margin: '0 auto',
    },
  },
  linkContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > button': {
      minWidth: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 20,
    },
  },
  linkSectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '& > a': {
      minWidth: '50%',
      margin: 0,
      padding: 0,
      fontSize: 14,
    },
  },
  linkSectionColumn: {
    flexDirection: 'column',
  },
  footerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
});

export default withStyles(styles)(Footer);
