import { Divider, MenuList } from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  Pages as ListIcon,
  Settings as SettingsIcon,
  PersonAdd as PersonAddIcon,
  Assignment as AssigmentIcon,
  SupervisorAccount as SupervisorAccountIcon,
  LocationCity as OrganizationIcon,
  Poll as SummaryIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from 'config';
import LeftDrawerMenuListItem from '../LeftDrawerMenuListItem';

const Roles = {
  RECRUITER: 'RECRUITER',
  CANDIDATE: 'CANDIDATE',
};
const RoleProperties = {
  ORGANIZATION_MEMBER: 'ORGANIZATION_MEMBER',
  ORGANIZATION_OWNER: 'ORGANIZATION_OWNER',
  ADMIN_ACCESS: 'ADMIN_ACCESS',
  ADMIN_ACCESS_CONFIG: 'ADMIN_ACCESS_CONFIG',
};

const getUserRole = currentUser => {
  if (currentUser.isRecruiter) {
    return Roles.RECRUITER;
  }
  return Roles.CANDIDATE;
};

const getUserPropertiesRole = (currentUser, config) => {
  const rolePropertiesArray = [];
  const { isSuperUser = false, organization = false } = currentUser;
  const isOrganizationOwner =
    organization && currentUser.id === organization.owner;
  const adminMails = config.admin_emails.split(',');

  if (isSuperUser) {
    rolePropertiesArray.push(RoleProperties.ADMIN_ACCESS);
  }
  if (organization) {
    rolePropertiesArray.push(RoleProperties.ORGANIZATION_MEMBER);
  }
  if (isOrganizationOwner) {
    rolePropertiesArray.push(RoleProperties.ORGANIZATION_OWNER);
  }
  if (isSuperUser && adminMails.includes(currentUser.email)) {
    rolePropertiesArray.push(RoleProperties.ADMIN_ACCESS_CONFIG);
  }
  return rolePropertiesArray;
};

const LayoutLeftMenu = props => {
  const {
    currentUser = {},
    classes,
    isOpened,
    handleDrawerClose,
    t,
    config,
  } = props;
  if (!config) {
    return null;
  }
  const menuElements = [
    {
      name: t('menu:location.dashboard'),
      url: PATHS.DASHBOARD.INDEX,
      icon: DashboardIcon,
    },
    {
      type: 'divider',
    },
    {
      name: t('menu:location.projects'),
      url: PATHS.PROJECT.INDEX,
      icon: AssigmentIcon,
    },
    {
      name: t('menu:location.candidates'),
      url: PATHS.CANDIDATE.INDEX_SHOW.replace(':show', 'invitation'),
      icon: PersonAddIcon,
      roleAccess: [Roles.RECRUITER],
    },
    {
      name: t('menu:location.recruiters'),
      url: PATHS.RECRUITER.INDEX,
      icon: SupervisorAccountIcon,
    },
    {
      name: t('menu:location.organizations'),
      url: PATHS.ORGANIZATION.INDEX,
      icon: OrganizationIcon,
      roleAccess: [Roles.RECRUITER],
    },
    {
      name: t('menu:location.summaryRecruiter'),
      url: PATHS.SUMMARY.INDEX.replace(':type', 'recruiter').replace(
        ':filter',
        'recruiterAll'
      ),
      icon: SummaryIcon,
      roleAccess: [Roles.RECRUITER],
    },
    {
      name: t('menu:location.summaryOrganization'),
      url: PATHS.SUMMARY.INDEX.replace(':type', 'organization').replace(
        ':filter',
        'organizationAll'
      ),
      icon: SummaryIcon,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ORGANIZATION_MEMBER],
    },
    {
      name: t('menu:location.candidateSummaryRecruiter'),
      url: PATHS.CANDIDATE_SUMMARY.INDEX,
      icon: SummaryIcon,
      roleAccess: [Roles.RECRUITER],
    },
    {
      name: t('menu:location.summaryCandidate'),
      url: PATHS.SUMMARY_FOR_CANDIDATE.INDEX.replace(':filter', 'candidateAll'),
      icon: SummaryIcon,
      roleAccess: [Roles.CANDIDATE],
    },
    {
      type: 'divider',
    },
    {
      name: t('menu:adminLocation.organization'),
      icon: ListIcon,
      url: PATHS.ADMIN.ORGANIZATION,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.industryCategory'),
      icon: ListIcon,
      url: PATHS.ADMIN.INDUSTRY_CATEGORY,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.citySeo'),
      icon: ListIcon,
      url: PATHS.ADMIN.CITY_SEO,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.users'),
      icon: ListIcon,
      url: PATHS.ADMIN.USER,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.superUsers'),
      icon: ListIcon,
      url: PATHS.ADMIN.SUPER_USER,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [
        RoleProperties.ADMIN_ACCESS,
        RoleProperties.ADMIN_ACCESS_CONFIG,
      ],
    },
    {
      name: t('menu:adminLocation.skills'),
      icon: ListIcon,
      url: PATHS.ADMIN.SKILLS,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.skillsToApproved'),
      icon: ListIcon,
      url: PATHS.ADMIN.SKILLS_TO_APPROVED,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.competence'),
      icon: ListIcon,
      url: PATHS.ADMIN.COMPETENCE_TEMP,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.competenceCategories'),
      icon: ListIcon,
      url: PATHS.ADMIN.COMPETENCE_CATEGORIES,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.competenceKinds'),
      icon: ListIcon,
      url: PATHS.ADMIN.COMPETENCE_KINDS,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.competenceTypes'),
      icon: ListIcon,
      url: PATHS.ADMIN.COMPETENCE_TYPE,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.config'),
      icon: SettingsIcon,
      url: PATHS.ADMIN.CONFIG,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.logs'),
      icon: AssigmentIcon,
      url: PATHS.ADMIN.LOGS,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.helpdesk'),
      icon: AssigmentIcon,
      url: PATHS.ADMIN.HELPDESK,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.mlStatus'),
      icon: AssigmentIcon,
      url: PATHS.ADMIN.ML_STATUS,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      name: t('menu:adminLocation.cmsConfig'),
      icon: AssigmentIcon,
      url: PATHS.ADMIN.CMS_CONFIG,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [RoleProperties.ADMIN_ACCESS],
    },
    {
      type: 'divider',
    },
    {
      name: t('menu:adminLocation.exports'),
      icon: AssigmentIcon,
      url: PATHS.ADMIN.EXPORTS,
      roleAccess: [Roles.RECRUITER],
      roleProperties: [
        RoleProperties.ADMIN_ACCESS,
        RoleProperties.ORGANIZATION_OWNER,
      ],
    },
  ];

  const menu = menuElements.map((element, key) => {
    if (element.type === 'divider') {
      return (
        <Divider
          className={!isOpened ? classes.invisibleDivider : ''}
          key={key}
        />
      );
    } else {
      const userRole = getUserRole(currentUser);
      const userPropertiesRole = getUserPropertiesRole(currentUser, config);

      const roleAccess = element.roleAccess
        ? element.roleAccess.includes(userRole)
        : true;
      const rolePropertiesAccess = element.roleProperties
        ? element.roleProperties.some(item => userPropertiesRole.includes(item))
        : true;

      let icon = React.createElement(element.icon);
      return (
        roleAccess &&
        rolePropertiesAccess && (
          <LeftDrawerMenuListItem
            key={key}
            to={element.url}
            name={element.name}
            icon={icon}
            url={element.url}
            badge={false}
            isOpened={isOpened}
            handleDrawerClose={handleDrawerClose}
          />
        )
      );
    }
  });

  return <MenuList className={classes.menuConntent}>{menu}</MenuList>;
};

LayoutLeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

export default LayoutLeftMenu;
