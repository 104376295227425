export const validateCmsConfig = (values, { t }) => {
  let errors = {};
  ['fieldName', 'value'].forEach(element => {
    let value = values[element];
    if (
      value === undefined ||
      value === null ||
      value === {} ||
      (typeof value === 'string' && value.trim() === '')
    ) {
      errors[element] = t`candidate:form.validation.nonEmpty`;
    }
  });

  return errors;
};

export default validateCmsConfig;
