import { Divider, Grid, Paper, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ExpansionComponent from '../../../../../components/LayoutExpansionComponent';
import ProjectMenu from '../../../projectMenu/ProjectMenu';
import FilterOrders from '../FilterOrders';
import ProjectOrderInfo from '../ProjectOrderInfo';
import ProjectOrdersTableView from '../ProjectOrdersTableView';
import { styles } from './ProjectOrderViewContent.styles';

const ProjectOrderViewContent = ({
  project,
  loadProjectOrders,
  projectOrders,
  t,
  classes,
  projectAvailableToMove,
}) => {
  const [filteredProjectOrders, setFilteredProjectOrders] =
    useState(projectOrders);
  const [filters, setFilters] = useState({});

  const filterProjectOrders = () => {
    let filterProjectOrder = [...projectOrders];
    if (filters['orderNumber']) {
      filterProjectOrder = filterProjectOrder.filter(projectOrder =>
        projectOrder.orderNumber
          .toLowerCase()
          .includes(filters['orderNumber'].toLowerCase())
      );
    }
    if (filters['localizationString']) {
      filterProjectOrder = filterProjectOrder.filter(projectOrder =>
        projectOrder.localizationString
          .toLowerCase()
          .includes(filters['localizationString'].toLowerCase())
      );
    }
    if (filters['createdAtStart']) {
      filterProjectOrder = filterProjectOrder.filter(projectOrder => {
        return moment(projectOrder.createdAt).isSameOrAfter(
          moment(filters['createdAtStart']),
          'day'
        );
      });
    }
    if (filters['createdAtEnd']) {
      filterProjectOrder = filterProjectOrder.filter(projectOrder =>
        moment(projectOrder.createdAt).isSameOrBefore(
          moment(filters['createdAtEnd']),
          'day'
        )
      );
    }
    setFilteredProjectOrders(filterProjectOrder);
  };

  useEffect(() => {
    filterProjectOrders();
  }, [filters, projectOrders]);

  return (
    <Grid container>
      <Grid
        container
        alignItems="flex-start"
        spacing={0}
        className={classes.wrapperForPadding}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
          <ProjectMenu title={t`projectOrderViewContent:listOfOrders`} />
          <ProjectOrderInfo project={project} />
          <Paper className={classes.rootPaper} elevation={0}>
            <ExpansionComponent
              title={t`core:filters.name`}
              textOnTop={t`core:filters.description`}
            >
              <FilterOrders
                projectOrders={projectOrders}
                t={t}
                setFilters={setFilters}
              />
            </ExpansionComponent>
            <Divider />
            <ProjectOrdersTableView
              projectAvailableToMove={projectAvailableToMove}
              loadProjectOrders={loadProjectOrders}
              project={project}
              projectOrders={filteredProjectOrders}
            />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(ProjectOrderViewContent))
);
