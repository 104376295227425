import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import publicIcon from 'shared/assets/img/icons/project-show/public.svg';
import unPublicIcon from 'shared/assets/img/icons/project-show/unPublic.svg';
import { Translate } from 'shared/newComponents';
import AssignRecruiterToProjectsDialog from '../../../../../../../components/AssignRecruiterToProjectsDialog';
import { styles } from './RecruiterAnonymousDetailsContentTop.styles';

const RecruiterAnonymousDetailsContentTop = ({
  history,
  recruiterData,
  recruiterEdited,
  currentUser,
  classes,
  t,
  ...props
}) => {
  const isPublic = recruiterData && recruiterData.recruiterVisibility === 0;

  const currentUserIsRecruiter = currentUser.isRecruiter;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} className={classes.wrapper}>
        <Grid container>
          <Grid item xs={8} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={t`recruiter:card`}
                  bold
                  className={classes.fontSize}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container alignItems={'center'}>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        className={classes.imageWrapper}
                      >
                        <Grid container justifyContent={'center'}>
                          <img
                            alt="publicIcon"
                            src={isPublic ? publicIcon : unPublicIcon}
                            className={classes.imageMargin}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Translate
                          text={
                            isPublic
                              ? t`recruiter:public`
                              : t`recruiter:private`
                          }
                          className={classes.fontSize}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {currentUserIsRecruiter && (
            <Grid item xs={4} sm={4} md={4}>
              <Grid container justifyContent={'center'}>
                <AssignRecruiterToProjectsDialog
                  btnColor={'#01D38B'}
                  option="button"
                  recruiter={recruiterData}
                  recruiterData={recruiterData}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(RecruiterAnonymousDetailsContentTop))
);
