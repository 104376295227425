import { number, object } from 'yup';

export const MODEL = {
  calcCandidateExperience: 'calcCandidateExperience',
  calcServiceCompletionDate: 'calcServiceCompletionDate',
  calcPaymentDeadline: 'calcPaymentDeadline',
  calcCandidateWarranty: 'calcCandidateWarranty',
  hintForRecruiter: 'hintForRecruiter',
  projectStartDate: 'projectStartDate',
  projectEndDate: 'projectEndDate',
  revenueWinner: 'revenueWinner',
  revenueSubmitted: 'revenueSubmitted',
  mentorFee: 'mentorFee',
  recruitmentAreas: 'recruitmentAreas',
};

export const validationSchema = (
  t,
  initialProjectValues,
  user,
  recruitmentPayment
) => {
  const maxRevenueWinner = Math.round(
    recruitmentPayment.hiring + recruitmentPayment.hiring * 0.15
  );

  const maxMentorFee = Math.round(650 + 650 * 0.15);

  const maxRevenueSubmitted = Math.round(
    recruitmentPayment.meetings + recruitmentPayment.meetings * 0.15
  );

  if (user.isSuperUser) {
    return object().shape({
      mentorFee: initialProjectValues.isDraft
        ? number()
            .min(0, t('projectCreator:core.requiredFieldMinNumber'))
            .required(t('projectCreator:core.requiredField'))
        : number()
            .typeError(t('projectCreator:core.requiredFieldNumber'))
            .required(t('projectCreator:core.requiredField')),
      revenueWinner: initialProjectValues.isDraft
        ? number()
            .typeError(t('projectCreator:core.requiredFieldNumber'))
            .min(0, t('projectCreator:core.requiredFieldMinNumber'))
            .required(t('projectCreator:core.requiredField'))
        : number()
            .typeError(t('projectCreator:core.requiredFieldNumber'))
            .required(t('projectCreator:core.requiredField')),
      revenueSubmitted: initialProjectValues.isDraft
        ? number()
            .min(0, t('projectCreator:core.requiredFieldMinNumber'))
            .required(t('projectCreator:core.requiredField'))
        : number()
            .typeError(t('projectCreator:core.requiredFieldNumber'))
            .required(t('projectCreator:core.requiredField')),
    });
  }
  return object().shape({
    mentorFee: initialProjectValues.isDraft
      ? number()
          .min(0, t('projectCreator:core.requiredFieldMinNumber'))
          .max(
            maxMentorFee,
            `${t('projectCreator:core.requiredFieldMaxNumber')} ${maxMentorFee}`
          )
          .required(t('projectCreator:core.requiredField'))
      : number()
          .typeError(t('projectCreator:core.requiredFieldNumber'))
          .min(
            initialProjectValues.mentorFee,
            t('projectCreator:core.requiredFieldHigherThanRevenue')
          )
          .max(
            maxMentorFee,
            `${t('projectCreator:core.requiredFieldMaxNumber')} ${maxMentorFee}`
          )
          .required(t('projectCreator:core.requiredField')),
    revenueWinner: initialProjectValues.isDraft
      ? number()
          .typeError(t('projectCreator:core.requiredFieldNumber'))
          .min(0, t('projectCreator:core.requiredFieldMinNumber'))
          .max(
            maxRevenueWinner,
            `${t(
              'projectCreator:core.requiredFieldMaxNumber'
            )} ${maxRevenueWinner}`
          )
          .required(t('projectCreator:core.requiredField'))
      : number()
          .typeError(t('projectCreator:core.requiredFieldNumber'))
          .min(
            initialProjectValues.revenueWinner,
            t('projectCreator:core.requiredFieldHigherThanRevenue')
          )
          .max(
            maxRevenueWinner,
            `${t(
              'projectCreator:core.requiredFieldMaxNumber'
            )} ${maxRevenueWinner}`
          )
          .required(t('projectCreator:core.requiredField')),
    revenueSubmitted: initialProjectValues.isDraft
      ? number()
          .min(0, t('projectCreator:core.requiredFieldMinNumber'))
          .max(
            maxRevenueSubmitted,
            `${t(
              'projectCreator:core.requiredFieldMaxNumber'
            )} ${maxRevenueSubmitted}`
          )
          .required(t('projectCreator:core.requiredField'))
      : number()
          .typeError(t('projectCreator:core.requiredFieldNumber'))
          .min(
            initialProjectValues.revenueSubmitted,
            t('projectCreator:core.requiredFieldHigherThanRevenue')
          )
          .max(
            maxRevenueSubmitted,
            `${t(
              'projectCreator:core.requiredFieldMaxNumber'
            )} ${maxRevenueSubmitted}`
          )
          .required(t('projectCreator:core.requiredField')),
  });
};
