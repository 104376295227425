export const getLogoAsset = assets => {
  if (!assets) {
    return undefined;
  }
  const assetLogo = [];
  const filterAsset = assets.find(asset => asset.type === 'logo');
  if (filterAsset !== undefined) {
    assetLogo.push(filterAsset);
  }
  return assetLogo.length ? assetLogo : undefined;
};
export const getHeaderAsset = assets => {
  if (!assets) {
    return undefined;
  }
  const assetHeader = [];
  const filterAsset = assets.find(asset => asset.type === 'header');
  if (filterAsset !== undefined) {
    assetHeader.push(filterAsset);
  }
  return assetHeader.length ? assetHeader : undefined;
};
export const getSliderAsset = assets => {
  if (!assets) {
    return undefined;
  }
  const filterAsset = assets.filter(asset => asset.type === 'slider');

  return filterAsset.length ? filterAsset : undefined;
};
