import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadAssets } from 'shared/services/assetService';
import { getProject, editProject } from 'shared/services/projectService';
import AssetsUpload from './asset/AssetsUpload';

export class ProjectAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
    };
  }

  uploadAssets = async (files, assetType) => {
    const { project, editProject, uploadAssets } = this.props;
    let { data } = await uploadAssets(files, assetType);
    let projectAssets = [];
    Array.isArray(data) &&
      data.forEach(file => {
        projectAssets.push(file.id);
      });
    await editProject({ id: project.id, assets: projectAssets });
  };

  getAssets = async () => {
    const { project, getProject, assetType } = this.props;
    await getProject(project.id);
    return _.filter(project.assets, { type: assetType });
  };

  render() {
    const {
      currentUser = {},
      assetType,
      filesLimit = 4,
      acceptedFiles,
      project,
      cropShape,
      aspect,
    } = this.props;
    const { isRecruiter = false } = currentUser;

    return project.id && isRecruiter ? (
      <AssetsUpload
        cropShape={cropShape}
        aspect={aspect}
        uploadAssetsHandler={this.uploadAssets}
        getAssetsHandler={this.getAssets}
        assets={project.assets}
        filesLimit={filesLimit}
        assetType={assetType}
        acceptedFiles={acceptedFiles}
      >
        {this.props.children}
      </AssetsUpload>
    ) : null;
  }
}

const mapDispatchToProps = {
  uploadAssets,
  editProject,
  getProject,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    project: store.projects.currentProject,
  };
};

ProjectAssets.propTypes = {
  currentUser: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired,
  uploadAssets: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAssets);
