import { Tab, Tabs, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';

const styles = theme => ({
  button: {
    minWidth: 150,
    margin: 0,
    background: '#e6e6e6',
    color: '#b3b3b3',
    textTransform: 'capitalize',
    fontSize: '0.940rem',
    justifyContent: 'left',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
    minHeight: 40,
    '&:hover': {
      background: '#fff',
      color: '#53a3fc',
    },
  },
  buttonInActive: {
    minWidth: 150,
    margin: 0,
    background: '#f2f2f2',
    color: '#d3d3d3',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
  },
  active: {
    background: '#fff',
    color: '#53a3fc',
  },
  tabsRoot: {
    minHeight: 40,
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  scrollButtons: {
    color: theme.palette.jobllegroGrey,
    flex: '0 0 20px',
  },
});

export const SummaryIndexMenu = props => {
  const {
    classes,
    t,
    itemSelected,
    menuType = 'recruiter',
    currentUser: { organization = false },
  } = props;

  const menuRecruiterElements = [
    {
      name: t('summary:menu.recruiterAll'),
      route_url: 'poAndCoAndMentorAndHm',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'recruiter').replace(
        ':filter',
        'poAndCoAndMentorAndHm'
      ),
    },
    {
      name: t('summary:menu.recruiterPo'),
      route_url: 'recruiterPo',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'recruiter').replace(
        ':filter',
        'recruiterPo'
      ),
    },
    {
      name: t('summary:menu.recruiterCo'),
      route_url: 'recruiterCo',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'recruiter').replace(
        ':filter',
        'recruiterCo'
      ),
    },
    {
      name: t('summary:menu.organizationProjectClosed'),
      route_url: 'closedStatus',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'recruiter').replace(
        ':filter',
        'closedStatus'
      ),
    },
  ];

  const menuOrganizationElements = [
    {
      name: t('summary:menu.organizationAll'),
      route_url: 'organizationAll',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'organization').replace(
        ':filter',
        'organizationAll'
      ),
    },
    {
      name: t('summary:menu.organizationPo'),
      route_url: 'organizationPo',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'organization').replace(
        ':filter',
        'organizationPo'
      ),
      accessOrganization: true,
    },
    {
      name: t('summary:menu.organizationCo'),
      route_url: 'organizationCo',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'organization').replace(
        ':filter',
        'organizationCo'
      ),
      accessOrganization: true,
    },
    {
      name: t('summary:menu.organizationProjectClosed'),
      route_url: 'closedStatus',
      url: PATHS.SUMMARY.INDEX.replace(':type', 'organization').replace(
        ':filter',
        'closedStatus'
      ),
      accessOrganization: true,
    },
  ];

  const menuElements =
    menuType === 'organization'
      ? menuOrganizationElements
      : menuRecruiterElements;

  const subMenu = menuElements.map((element, key) => {
    let getAccess = true;
    let setActive = false;

    if (itemSelected === element.route_url) {
      setActive = true;
    }

    if (element.accessOrganization && !organization) {
      getAccess = false;
    }

    return (
      getAccess && (
        <Tab
          key={key}
          component={Link}
          to={element.url}
          className={setActive ? classes.active : ''}
          label={element.name}
          classes={{
            root: classes.button,
          }}
        />
      )
    );
  });

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="on"
      value={0}
      classes={{
        root: classes.tabsRoot,
        indicator: classes.indicator,
        scrollButtons: classes.scrollButtons,
      }}
    >
      {subMenu}
    </Tabs>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(SummaryIndexMenu)));
