export const styles = () => ({
  container: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  table: {
    maxHeight: 'calc(100vh - 170px)',
  },
  typography: { color: '#fff' },
  tableHead: {
    overflowX: 'auto',
    whiteSpace: 'noWrap',
    textAlign: 'center !important',
    maxWidth: 125,
    minWidth: 125,
  },
  tableHeadActive: { background: '#01D38B' },
  tableHeadDisabled: { background: '#B7BEC8' },
  checkboxCell: { borderRight: '1px solid #D5E5FB', textAlign: 'center' },
  checkbox: { width: 16, height: 16 },
  arrowDown: { transform: 'rotate(180deg)' },
  thItem: {
    color: '#fff',
    borderRight: '1px solid #D5E5FB',
    cursor: 'pointer',
  },
  thRow: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  arrow: { marginLeft: 10 },
  odd: { background: '#D5E5FB' },
  even: { background: '#fff' },
  contentCell: { borderRight: '1px solid #D5E5FB', textAlign: 'center' },
  typographySort: { color: '#fff', fontSize: 30, width: 17, marginLeft: 10 },
});
