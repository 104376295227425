import { Grid, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { enums } from 'shared/enums';
import { Select, TextEditor, Translate } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import { ProjectCandidateV2Service } from 'shared/services';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import { castObjectForSelect } from 'shared/utils/functions';
import CustomDialog from '../CustomDialog';
import { FeedbackGrade } from '../FeedbackGrade/FeedbackGrade';
import { styles } from './AddFeedbackPanelForm.style';

export const MODEL = {
  feedback: 'feedback',
  feedbackReviewStage1: 'feedbackReviewStage1',
  feedbackReviewStage2: 'feedbackReviewStage2',
  feedbackReviewStage3: 'feedbackReviewStage3',
  feedbackReviewStage4: 'feedbackReviewStage4',
  feedbackReviewStage5: 'feedbackReviewStage5',
  feedbackReviewStage6: 'feedbackReviewStage6',
  feedbackEmployerDecision: 'feedbackEmployerDecision',
  feedbackRejectReasons: 'feedbackRejectReasons',

  currentGradePreActive1: 'currentGradePreActive1',
  currentGradePreActive2: 'currentGradePreActive2',
  currentGradePreActive3: 'currentGradePreActive3',
  currentGradePreActive4: 'currentGradePreActive4',
  currentGradePreActive5: 'currentGradePreActive5',
  currentGradePreActive6: 'currentGradePreActive6',
};

const AddFeedbackPanelForm = ({
  isOpen,
  onClose,
  projectCandidate,
  feedbackAdded,
  feedbackRejectReason,
  t,
}) => {
  const formData = {
    feedback: projectCandidate ? projectCandidate.feedback : null,
    feedbackEmployerDecision: projectCandidate
      ? projectCandidate.feedbackEmployerDecision
      : null,
    feedbackRejectReasons: projectCandidate
      ? castObjectForSelect(projectCandidate.feedbackRejectReasons)
      : [],
    feedbackReviewStage1: projectCandidate
      ? projectCandidate.feedbackReviewStage1
      : 5,
    feedbackReviewStage2: projectCandidate
      ? projectCandidate.feedbackReviewStage2
      : 5,
    feedbackReviewStage3: projectCandidate
      ? projectCandidate.feedbackReviewStage3
      : 5,
    feedbackReviewStage4: projectCandidate
      ? projectCandidate.feedbackReviewStage4
      : 5,
    feedbackReviewStage5: projectCandidate
      ? projectCandidate.feedbackReviewStage5
      : 5,
    feedbackReviewStage6: projectCandidate
      ? projectCandidate.feedbackReviewStage6
      : 5,

    currentGradePreActive1: 0,
    currentGradePreActive2: 0,
    currentGradePreActive3: 0,
    currentGradePreActive4: 0,
    currentGradePreActive5: 0,
    currentGradePreActive6: 0,
  };

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      const feedbackRejectReasons = values.feedbackRejectReasons.length
        ? values.feedbackRejectReasons
        : [];
      const body = {
        feedback: values.feedback,
        feedbackEmployerDecision: values.feedbackEmployerDecision,
        feedbackRejectReasons:
          values.feedbackEmployerDecision === 2 ? feedbackRejectReasons : [],
        feedbackReviewStage1: values.feedbackReviewStage1,
        feedbackReviewStage2: values.feedbackReviewStage2,
        feedbackReviewStage3: values.feedbackReviewStage3,
        feedbackReviewStage4: values.feedbackReviewStage4,
        feedbackReviewStage5: values.feedbackReviewStage5,
        feedbackReviewStage6: values.feedbackReviewStage6,
      };

      ProjectCandidateV2Service.editProjectCandidateFeedback(
        projectCandidate.project.id,
        projectCandidate.candidate.id,
        body
      )
        .then(() => {
          feedbackAdded();
        })
        .catch(error => {
          console.log(error);
          alert(t('newAts:core.error'));
        });
    },
  });

  const onChangeGrade = (filedName, grade) => {
    formik.setFieldValue(filedName, grade);
  };

  const onChangeGradePreActive = (filedName, grade) => {
    formik.setFieldValue(filedName, grade);
  };

  return (
    <CustomDialog
      maxWidth={'lg'}
      title={t('newAts:feedback.employerGrade')}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <FeedbackGrade
                  currentGrade={formik.values[MODEL.feedbackReviewStage1]}
                  currentGradePreActive={
                    formik.values[MODEL.currentGradePreActive1]
                  }
                  filedNamePreActive={MODEL.currentGradePreActive1}
                  filedName={MODEL.feedbackReviewStage1}
                  onChangeGrade={onChangeGrade}
                  onChangeGradePreActive={onChangeGradePreActive}
                  title={t('newAts:feedback.feedbackReviewStage1')}
                />
                <FeedbackGrade
                  currentGrade={formik.values[MODEL.feedbackReviewStage2]}
                  filedName={MODEL.feedbackReviewStage2}
                  currentGradePreActive={
                    formik.values[MODEL.currentGradePreActive2]
                  }
                  filedNamePreActive={MODEL.currentGradePreActive2}
                  onChangeGrade={onChangeGrade}
                  onChangeGradePreActive={onChangeGradePreActive}
                  title={t('newAts:feedback.feedbackReviewStage2')}
                />
                <FeedbackGrade
                  currentGrade={formik.values[MODEL.feedbackReviewStage3]}
                  filedName={MODEL.feedbackReviewStage3}
                  currentGradePreActive={
                    formik.values[MODEL.currentGradePreActive3]
                  }
                  filedNamePreActive={MODEL.currentGradePreActive3}
                  onChangeGrade={onChangeGrade}
                  onChangeGradePreActive={onChangeGradePreActive}
                  title={t('newAts:feedback.feedbackReviewStage3')}
                />
                <FeedbackGrade
                  currentGrade={formik.values[MODEL.feedbackReviewStage4]}
                  filedName={MODEL.feedbackReviewStage4}
                  currentGradePreActive={
                    formik.values[MODEL.currentGradePreActive4]
                  }
                  filedNamePreActive={MODEL.currentGradePreActive4}
                  title={t('newAts:feedback.feedbackReviewStage4')}
                  onChangeGrade={onChangeGrade}
                  onChangeGradePreActive={onChangeGradePreActive}
                />
                <FeedbackGrade
                  currentGrade={formik.values[MODEL.feedbackReviewStage5]}
                  filedName={MODEL.feedbackReviewStage5}
                  currentGradePreActive={
                    formik.values[MODEL.currentGradePreActive5]
                  }
                  filedNamePreActive={MODEL.currentGradePreActive5}
                  onChangeGrade={onChangeGrade}
                  onChangeGradePreActive={onChangeGradePreActive}
                  title={t('newAts:feedback.feedbackReviewStage5')}
                />
                <FeedbackGrade
                  currentGrade={formik.values[MODEL.feedbackReviewStage6]}
                  filedName={MODEL.feedbackReviewStage6}
                  currentGradePreActive={
                    formik.values[MODEL.currentGradePreActive6]
                  }
                  filedNamePreActive={MODEL.currentGradePreActive6}
                  onChangeGrade={onChangeGrade}
                  onChangeGradePreActive={onChangeGradePreActive}
                  title={t('newAts:feedback.feedbackReviewStage6')}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={'newAts:feedback.employerGradeFormTitle'}
                    bold
                    style={{ fontSize: 20 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextEditor name={MODEL.feedback} formik={formik} />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'newAts:feedback.feedbackEmployerDecisionTitle'}
                  bold
                  style={{ fontSize: 20 }}
                />
              </Grid>

              <Grid container>
                <Select
                  formik={formik}
                  name={MODEL.feedbackEmployerDecision}
                  label={t('newAts:feedback.feedbackEmployerDecision')}
                  items={getSelectItemsTranslate(
                    enums.FEEDBACK_EMPLOYER_DECISION,
                    t
                  )}
                />
              </Grid>
              {formik.values[MODEL.feedbackEmployerDecision] === 2 ? (
                <MultiSelect
                  isMultiOption={true}
                  options={castObjectForSelect(feedbackRejectReason)}
                  label={t('newAts:feedback.feedbackEmployerDecisionReason')}
                  name={MODEL.feedbackRejectReasons}
                  formik={formik}
                />
              ) : null}

              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t('newAts:core.save')} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
};

export default withStyles(styles)(withTranslation()(AddFeedbackPanelForm));
