import { withStyles } from '@material-ui/core';
import React from 'react';
import StarRatings from 'react-star-ratings';

const styles = () => ({
  activeStar: {
    color: '#24d1d7',
  },
  star: {
    color: '#868686',
  },
  status: {
    fontSize: '11px',
    color: '#afafaf',
    float: 'right',
    marginTop: 5,
  },
  root: {
    justifyContent: 'right',
    textAlign: 'right',
  },
});

export const StarRating = props => {
  const { numberOfStars, changeRating, rating = 0, name } = props;

  return (
    <StarRatings
      name={name}
      rating={rating}
      starRatedColor="#f1f100"
      starHoverColor="#f1f100"
      starDimension="40px"
      starSpacing="15px"
      changeRating={changeRating}
      numberOfStars={numberOfStars}
    />
  );
};

StarRating.propTypes = {};

export default withStyles(styles)(StarRating);
