import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AssignCandidateToProjectButton from '../../../components/AssignCandidateToProjectButton';
import AssignRecruiterToProjectButton from '../../../components/AssignRecruiterToProjectButton';
import ProjectStepper from '../../../components/ProjectStepper';
import RemoveSelfFromProjectButton from '../../../components/RemoveSelfFromProjectButton';

const styles = theme => ({
  rootPaper: {
    paddingTop: 64,
    paddingBottom: 64,
    marginTop: 0,
    paddingLeft: 64,
    paddingRight: 64,
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  textSubHeading: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#000',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  textSubHeadingIcons: {
    paddingTop: 0,
    color: '#000',
    fontSize: '22px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    paddingBottom: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  iconsPosition: {
    marginTop: 5,
    display: 'inline-block',
    marginLeft: 5,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
});

export const ProjectDetailsJobInformation = props => {
  const { classes, projectDetails, t } = props;
  const { permissions = {} } = projectDetails;
  const { isOwner = {} } = permissions;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.rootPaper} elevation={0}>
          <ProjectStepper
            startDate={projectDetails.projectStartDate}
            endDate={projectDetails.projectEndDate}
          />
          {!isOwner && (
            <React.Fragment>
              <div className={classes.buttonContainer}>
                <AssignCandidateToProjectButton />
                <AssignRecruiterToProjectButton />
                <RemoveSelfFromProjectButton />
              </div>
            </React.Fragment>
          )}
          {projectDetails.minSalary !== null && (
            <Typography
              component="p"
              gutterBottom
              className={classes.textSubHeading}
            >
              {t('project:show.employeeRevenue')}:{' '}
              <span style={{ fontWeight: '700' }}>
                {projectDetails.minSalary}
                {projectDetails.maxSalary !== null
                  ? ' - ' + projectDetails.maxSalary
                  : null}
                {t('financial.currencySign')}
                <span style={{ fontSize: 12 }}>({t('financial.gross')})</span>
              </span>
            </Typography>
          )}
          {permissions.canReadRevenue && (
            <Typography
              component="p"
              gutterBottom
              className={classes.textSubHeading}
            >
              {t('project:show.priceForRecrutation')}:{' '}
              <span style={{ fontWeight: '700' }}>
                {projectDetails.revenue} {}{' '}
                <span style={{ fontSize: 12 }}>({t('financial.net')})</span>
              </span>
            </Typography>
          )}
          <Typography
            component="p"
            gutterBottom
            className={classes.textSubHeading}
          >
            {t('project:show.employmentType')}:{' '}
            <span style={{ fontWeight: '700' }}>
              {projectDetails.contractType}
            </span>
          </Typography>
          <Typography
            component="p"
            gutterBottom
            className={classes.textSubHeadingIcons}
          >
            {t('project:show.benefits')}:{projectDetails.benefits}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectDetailsJobInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsJobInformation)));
