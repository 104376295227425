import { createAction } from '@reduxjs/toolkit';
import {
  SET_CURRENT_RECRUITER,
  CLEAR_CURRENT_RECRUITER,
  SET_CURRENT_RECRUITERS_LIST,
  CLEAR_CURRENT_RECRUITERS_LIST,
  SET_RECRUITERS_IN_PROJECT,
  CLEAR_RECRUITERS_IN_PROJECT,
  SET_RECRUITERS_IN_ORGANIZATION,
  CLEAR_RECRUITERS_IN_ORGANIZATION,
  SET_MY_RECRUITERS,
  CLEAR_MY_RECRUITERS,
  SET_RECRUITERS_PENDING_TO_ORGANIZATION_LIST,
  SET_CURRENT_RECRUITERS_LIMITED_LIST,
  SET_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER,
  CLEAR_CURRENT_RECRUITERS_LIMITED_LIST,
  CLEAR_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER,
} from '../actionConstants';

export const setCurrentRecruiterAction = createAction(SET_CURRENT_RECRUITER);
export const clearCurrentRecruiterAction = createAction(
  CLEAR_CURRENT_RECRUITER
);
export const setCurrentRecruitersListAction = createAction(
  SET_CURRENT_RECRUITERS_LIST
);
export const clearCurrentRecruitersListAction = createAction(
  CLEAR_CURRENT_RECRUITERS_LIST
);
export const setRecruitersInProjectAction = createAction(
  SET_RECRUITERS_IN_PROJECT
);
export const clearRecruitersInProjectAction = createAction(
  CLEAR_RECRUITERS_IN_PROJECT
);
export const setRecruitersInOrganizationAction = createAction(
  SET_RECRUITERS_IN_ORGANIZATION
);
export const clearRecruitersInOrganizationAction = createAction(
  CLEAR_RECRUITERS_IN_ORGANIZATION
);
export const setMyRecruitersAction = createAction(SET_MY_RECRUITERS);
export const clearMyRecruitersAction = createAction(CLEAR_MY_RECRUITERS);
export const setRecruitersPendingToOrganizationListAction = createAction(
  SET_RECRUITERS_PENDING_TO_ORGANIZATION_LIST
);

export const setCurrentRecruitersLimitedListAction = createAction(
  SET_CURRENT_RECRUITERS_LIMITED_LIST
);
export const setCurrentRecruitersLimitedListCounterAction = createAction(
  SET_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER
);
export const clearCurrentRecruitersLimitedListAction = createAction(
  CLEAR_CURRENT_RECRUITERS_LIMITED_LIST
);
export const clearCurrentRecruitersLimitedListCounterAction = createAction(
  CLEAR_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER
);
