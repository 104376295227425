import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Translate } from 'shared/newComponents';
import { styles } from './BillingCard.styles';

const BillingCard = ({
  classes,
  companyName,
  address,
  taxId,
  organizationEmail,
  shortInformation = false,
  t,
}) => {
  const allInvoiceInfoIsSet = companyName && address && taxId;

  return (
    <Grid container className={classes.wrapper}>
      <Paper className={classes.paper}>
        <Grid container className={classes.container}>
          <Grid container style={{ paddingBottom: 16 }}>
            <Translate
              text={t`addOrganizationForm:invoiceData`}
              bold
              variant="h6"
            />
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3} container justifyContent="flex-end">
              <Translate text={`addOrganizationForm:company`} variant="body1" />
            </Grid>
            <Grid item xs={9}>
              <Translate text={companyName} variant="body1" bold />
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3} container justifyContent="flex-end">
              <Translate
                text={`modifyOrderDialog:paper.address`}
                variant="body1"
              />
            </Grid>
            <Grid item xs={9}>
              <Translate text={address} variant="body1" />
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            style={{ paddingBottom: 16 }}
          >
            <Grid item xs={3} container justifyContent="flex-end">
              <Translate text="NIP" variant="body1" />
            </Grid>
            <Grid item xs={9}>
              <Translate text={taxId} variant="body1" bold />
            </Grid>
          </Grid>
          <Grid container>
            <Translate
              text="projectCreator:step8.organizationInvoiceInfo"
              variant="body1"
            />
          </Grid>
          {allInvoiceInfoIsSet || shortInformation ? null : (
            <Grid container>
              <Grid item>
                <Grid container>
                  <Translate
                    style={{ color: 'red' }}
                    text="projectCreator:step8.organizationInvoiceInfoNotSet"
                    variant="body1"
                  />
                  <Translate text="&nbsp;" variant="body1" />
                  {organizationEmail ? (
                    <Translate
                      text={` - ${organizationEmail}`}
                      variant="body1"
                    />
                  ) : null}
                </Grid>
                <Translate
                  text="projectCreator:step8.organizationInvoiceInfoNotSetSubLabel"
                  variant="body1"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(BillingCard));
