import {
  List,
  Grid,
  Button,
  Fab,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  withStyles,
  withWidth,
} from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';
import {
  KeyboardArrowRight as ArrowForwardIcon,
  KeyboardArrowLeft as ArrowBackwardIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { PATHS } from 'config';
import { getCandidates } from 'shared/services/candidateService';
import { getProjects } from 'shared/services/projectService';
import { getRecruiters } from 'shared/services/recruiterService';
import DashboardRightDrawerListItem from './DashboardRightDrawerListItem';
import DashboardRightDrawerListItemHeader from './DashboardRightDrawerListItemHeader';
import 'shared/assets/css/scrollBarRightDrawer.css';

const styles = theme => ({
  RightSideDashboard: {
    height: '100%',
    background: '#fff',
    paddingTop: '80px',
    transition: 'all .4s',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      height: '100%',
      zIndex: 1200,
      right: 0,
    },
  },
  RightSideDashboardClosed: {
    height: '100vh',
    background: '#fff',
    paddingTop: '80px',
    transition: 'all .4s',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      zIndex: 1200,
      right: '-100%',
    },
  },
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
  }),
  sectionTitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.jobllegroBlue,
    paddingBottom: '4px',
    paddingTop: '4px',
    paddingLeft: '10px',
    paddingRight: '5px',
    fontSize: 15,
    width: 'auto',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgba(82, 163, 252, 0.1)',
    },
  },
  star: {
    width: 16,
    color: '#37bce7',
    marginTop: -3,
  },
  arrowSection: {
    color: '#03adeb',
    fontSize: 14,
  },
  iconButton: {
    width: '20px',
    height: '20px',
    padding: 0,
  },
  buttonDrawer: {
    zIndex: 12002,
    top: 400,
    right: -30,
    position: 'fixed',
    width: 50,
    height: 50,
    visibility: 'hidden',
    justifyContent: 'left',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
    },
  },
  list: {
    paddingTop: '5px',
    paddingBottom: '5px',
    height: 'auto',
    transition: 'all .4s',
    borderBottom: '1px solid #E7E7E7',
    borderTop: '1px solid #E7E7E7',
  },
  rightDrawerContent: {
    position: 'fixed',
    height: '100vh',
    background: '#fff',
    overflowY: 'scroll',
    overflowX: 'none',
    [theme.breakpoints.only('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: 0,
    fontSize: 10,
    color: theme.palette.jobllegroLightGrey,
    marginTop: 10,
    '&:hover': {
      background: 'none',
      color: theme.palette.jobllegroBlue,
    },
  },
  extendedIcon: {
    fontSize: 12,
  },
  panelExpandedContainer: {
    height: '60vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    borderBottom: '1px solid #E7E7E7',
  },
  showSection: {
    display: 'block',
  },
  hideSection: {
    display: 'none',
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
    width: 10,
    height: 10,
  },
  circle: {
    color: theme.palette.jobllegroLightGrey,
  },
});

export class DashboardRightDrawer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      openRightDrawer: false,
      projects: [],
      candidates: [],
      recruiters: [],
      isLoading: false,
      panelExpanded: '',
      panelWidth: 0,
      projectsOverAll: 0,
      candidatesOverAll: 0,
      recruitersOverAll: 0,
    };
    this.domRef = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    await this.loadData();
    await this.setState({
      isLoading: false,
      panelWidth: this.domRef.current ? this.domRef.current.clientWidth : null,
    });
    window.addEventListener('resize', this.getWindowResizeWidth);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.getWindowResizeWidth);
    clearInterval(this.interval);
  }

  getWindowResizeWidth = async () => {
    if (this.domRef && this.domRef.current) {
      const cWidth = await this.domRef.current.clientWidth;
      await this.setState({ panelWidth: cWidth });
    }
  };

  loadData = async (variable, panel) => {
    try {
      this.setState({ isLoading: true });
      let state = {};
      const dataProject = await this.props.getProjects({
        withPublic: true,
        orderBy: { updatedAt: 'desc' },
        dashView: true,
        limit: variable !== undefined && panel === 'projects' ? variable : 3,
      });

      const dataProjectCount = await this.props.getProjects({
        withPublic: true,
        count: true,
      });

      const dataCandidates = await this.props.getCandidates({
        isPublic: true,
        orderBy: { createdAt: 'desc' },
        dashView: true,
        limit: variable !== undefined && panel === 'candidates' ? variable : 3,
      });

      const dataCandidatesCount = 300;

      const dataRecruiters = await this.props.getRecruiters({
        isPublic: true,
        dashView: true,
        limit: variable !== undefined && panel === 'recruiters' ? variable : 3,
      });
      const dataRecruitersCount = 300;

      if (this._isMounted) {
        state.projectsOverAll = dataProjectCount.data.count;
        state.projects =
          variable !== undefined && panel === 'projects'
            ? dataProject.data
            : dataProject.data.slice(0, 3);

        state.candidates =
          variable !== undefined && panel === 'candidates'
            ? dataCandidates.data
            : dataCandidates.data.slice(0, 3);
        state.candidatesOverAll = dataCandidatesCount;

        state.recruiters =
          variable !== undefined && panel === 'recruiters'
            ? dataRecruiters.data
            : dataRecruiters.data.slice(0, 3);
        state.recruitersOverAll = dataRecruitersCount;

        Object.keys(state).length && this.setState(state);
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleRightDrawer = () => {
    this.setState({
      openRightDrawer: !this.state.openRightDrawer,
    });
  };

  expandPanel = (variable, panel) => async () => {
    try {
      await this.loadData(variable, panel);
      this.setState({
        panelExpanded: panel,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  ExpandButton(category) {
    const { classes, t } = this.props;
    const { panelExpanded } = this.state;
    return (
      <div align="right">
        <Button
          aria-label="more"
          className={classes.button}
          onClick={
            panelExpanded === category
              ? this.expandPanel(5, '')
              : this.expandPanel(100, category)
          }
        >
          {panelExpanded === 'projects' ? (
            <KeyboardArrowUpIcon className={classes.extendedIcon} />
          ) : (
            <KeyboardArrowDownIcon className={classes.extendedIcon} />
          )}
          {panelExpanded === category
            ? t('dashboard:rightDrawer.fold')
            : t('dashboard:rightDrawer.expandMore')}
        </Button>
      </div>
    );
  }

  getDataList = (type, dataLists) => {
    const { classes } = this.props;
    const { panelExpanded, isLoading } = this.state;
    const rightDrawerListItem = dataLists.map(dataList => (
      <DashboardRightDrawerListItem
        key={dataList.id}
        data={dataList}
        type={type}
      />
    ));
    return (
      <div
        className={
          panelExpanded !== type && panelExpanded !== ''
            ? classes.hideSection
            : classes.showSection
        }
      >
        <DashboardRightDrawerListItemHeader type={type} />
        <List
          className={classNames(
            classes.list,
            panelExpanded === type && classes.panelExpandedContainer
          )}
        >
          {isLoading ? (
            <div align="center">
              <CircularProgress
                classes={{
                  root: classes.progress,
                  circle: classes.circle,
                }}
              />
            </div>
          ) : (
            rightDrawerListItem
          )}
        </List>
        {this.ExpandButton(type)}
      </div>
    );
  };

  render() {
    const { classes, t } = this.props;
    const {
      openRightDrawer,
      isLoading,
      panelWidth,
      projects,
      recruiters,
      candidates,
      candidatesOverAll,
      projectsOverAll,
      recruitersOverAll,
    } = this.state;

    const getProjectData = this.getDataList('projects', projects);
    const getCandidatesData = this.getDataList('candidates', candidates);
    const getRecruitersData = this.getDataList('recruiters', recruiters);
    return (
      <RootRef rootRef={this.domRef}>
        <React.Fragment>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            className={
              openRightDrawer
                ? classes.RightSideDashboard
                : classes.RightSideDashboardClosed
            }
          >
            {isLoading && (
              <div align="center">
                <CircularProgress
                  classes={{
                    root: classes.progress,
                    circle: classes.circle,
                  }}
                />
              </div>
            )}
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ width: panelWidth }}
                className={classes.rightDrawerContent}
              >
                <Paper className={classes.root} elevation={0}>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h5"
                    component={Link}
                    to={PATHS.PROJECT.INDEX}
                  >
                    {t('dashboard:rightDrawer.projectsOnMarket', {
                      count: projectsOverAll,
                    })}
                    <IconButton className={classes.iconButton}>
                      <ArrowForwardIcon className={classes.arrowSection} />
                    </IconButton>
                  </Typography>
                  <Typography component="div">{getProjectData}</Typography>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h5"
                    component={Link}
                    to={PATHS.CANDIDATE.INDEX}
                  >
                    {t('dashboard:rightDrawer.candidatesOnMarket', {
                      count: candidatesOverAll,
                    })}
                    <IconButton className={classes.iconButton}>
                      <ArrowForwardIcon className={classes.arrowSection} />
                    </IconButton>
                  </Typography>
                  <Typography component="div">{getCandidatesData}</Typography>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h5"
                    component={Link}
                    to={PATHS.RECRUITER.INDEX}
                  >
                    {t('dashboard:rightDrawer.recomendedRecruiters', {
                      count: recruitersOverAll,
                    })}
                    <IconButton className={classes.iconButton}>
                      <ArrowForwardIcon className={classes.arrowSection} />
                    </IconButton>
                  </Typography>
                  <Typography component="div">{getRecruitersData}</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.buttonDrawer}
            onClick={this.handleRightDrawer}
          >
            {openRightDrawer ? <ArrowForwardIcon /> : <ArrowBackwardIcon />}
            dates can
          </Fab>
        </React.Fragment>
      </RootRef>
    );
  }
}

const mapDispatchToProps = {
  getProjects,
  getCandidates,
  getRecruiters,
};

DashboardRightDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(
  compose(withWidth())(
    withStyles(styles, { withTheme: true })(
      withTranslation()(DashboardRightDrawer)
    )
  )
);
