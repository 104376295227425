import axios from 'axios';
import { API, API_URL } from '../constants';
import {
  setSummaryListAction,
  clearSummaryListAction,
  setSummaryListClosedAction,
} from '../store/summary/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const clearSummary = () => {
  return async (dispatch, getState) => {
    dispatch(clearSummaryListAction());
  };
};

export const getSummary = filters => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.SUMMARY.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: filters,
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setSummaryListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getSummaryClosed = filters => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.SUMMARY.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: filters,
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setSummaryListClosedAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getCandidateSummary = filters => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.CANDIDATE_SUMMARY.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: filters,
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setSummaryListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
