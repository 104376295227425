export const styles = theme => ({
  image: {
    // border: `1px solid ${palette.success.main}`,
    // borderRadius: "50%",
    // margin: 4,
    // padding: 7,
  },
  disableImage: {
    // border: `1px solid grey`,
    // borderRadius: "50%",
    // margin: 4,
    // padding: 7,
  },
  btnRoot: {
    minWidth: 46,
  },

  rootButton: {
    height: 46,
    borderRadius: 30,
    border: `2px solid ${theme.palette.success.main}`,
  },
  rootDisabledButton: {
    height: 46,
    borderRadius: 30,
    border: `2px solid ${theme.palette.text.secondary}`,
  },
  rootContainedButton: {
    backgroundColor: '#27D38B',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.8,
    },
  },
  fullContainedButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.8,
    },
  },
  text: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    padding: '0 25px',
  },
  fullText: {
    color: 'white',
    fontWeight: 'bold',
    padding: '0 25px',
  },
  disabledText: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    padding: '0 25px',
  },
  containedText: { color: '#fff' },
  small: { height: 40 },
});
