import { LinearProgress } from '@material-ui/core';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PATHS } from 'config';
import { LANDING_PAGE_URL } from 'shared/constants';
import ConfirmationDialog from '../../LayoutConfirmationDialog/';
import validateCandidateProfile from './validateCandidateProfile';

export class IsAuthorized extends Component {
  state = {
    isLoading: true,
    confirmationOpened: false,
  };

  async shouldComponentUpdate(nextProps) {
    if (
      nextProps.currentUser &&
      nextProps.currentUser !== this.props.currentUser
    ) {
      const { currentUser } = nextProps;
      if (!currentUser.isRecruiter && !validateCandidateProfile(currentUser)) {
        this.setState({
          confirmationOpened: true,
        });
      }
    }
  }

  handleConfirmationClose = isOk => {
    if (isOk) {
      this.props.history.push(PATHS.USER.INDEX);
    }
    this.setState({
      confirmationOpened: false,
    });
  };

  acceptRodo = isOk => {
    this.props.editUserRodo(true);
  };

  async componentDidMount() {
    const { language } = this.props;
    if (language) {
      i18n.changeLanguage(language);
    }
    try {
      await this.props.getConfigs();
      await this.props.getCurrentUser();
      if (!this.props.currentUser.id) {
        this.props.history.push(PATHS.AUTH.LOGOUT);
      }
      this.setState({
        isLoading: false,
      });
    } catch (err) {
      console.error(err);
      this.props.history.push(PATHS.AUTH.LOGOUT);
    }

    this.props.getNotifications();
    this.interval = setInterval(async () => {
      await this.props.getNotifications();
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getRodoDialog = () => {
    const { classes, t } = this.props;
    const { isLoading } = this.state;

    return (
      <ConfirmationDialog
        classes={{ paper: classes.dialog }}
        open={!this.props.currentUser.isRodoAccepted && !isLoading}
        onClose={this.acceptRodo}
        titleText={t`isAuthorized:rodo`}
        okButtonText={t`isAuthorized:accept`}
        cancelButtonText=""
      >
        {t`isAuthorized:text.header`}
        <br />
        <br />
        {t`isAuthorized:text.p1`}
        <br />
        <br />
        {t`isAuthorized:text.p2`}
        <br />
        <br />
        {t`isAuthorized:text.p3`}
        <br />
        <br />
        {t`isAuthorized:text.footer`}
        <br />
        <br />
        <a
          href={`${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.STATIC_FILES.RULES}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t`isAuthorized:text.statue`}
        </a>
        <br />
        <a
          href={`${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.STATIC_FILES.PRIVACY_POLICY}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t`isAuthorized:privacy`}
        </a>
      </ConfirmationDialog>
    );
  };
  getProfileDialog = () => {
    const { classes, t } = this.props;
    const { confirmationOpened } = this.state;
    return (
      <ConfirmationDialog
        classes={{
          paper: classes.dialog,
        }}
        open={confirmationOpened}
        onClose={this.handleConfirmationClose}
        titleText={t`isAuthorized:getProfileDialog.profile`}
        okButtonText={t`isAuthorized:getProfileDialog.goToProfile`}
        cancelButtonText={t`isAuthorized:getProfileDialog.cancel`}
      >
        {t`isAuthorized:getProfileDialog.data`}
        <br />
        {t`isAuthorized:getProfileDialog.fillData`}
      </ConfirmationDialog>
    );
  };
  render() {
    const { children, classes, currentUser = {} } = this.props;
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        {isLoading || !currentUser.id ? (
          <div className={classes.progressContainer}>
            <LinearProgress className={classes.progress} />
          </div>
        ) : (
          children
        )}
        {this.props.location.pathname === '/user' ? '' : null}
        {/*// : this.getProfileDialog()}*/}
        {this.getRodoDialog()}
      </React.Fragment>
    );
  }
}

IsAuthorized.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  editUserRodo: PropTypes.func.isRequired,
};

export default IsAuthorized;
