import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'shared/assets/css/react-grid-layout.css';

const ReactGridLayout = WidthProvider(RGL);

export class Kanban extends Component {
  static defaultProps = {
    rowHeight: 60,
    minWidth: 200,
    elementRows: 1,
    margin: [1, 1],
    cols: 6,
    isResizable: false,
    isLocked: false,
    saveColumnOrder: () => {},
    getHeaders: () => [],
    getHeaderContent: () => {},
    onDragStartHandler: () => {},
    onDragStopHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      layout: [],
      stages: [],
      content: null,
    };
  }

  async componentDidMount() {
    this.loadElementsToLayout();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.elements !== this.props.elements) {
      this.loadElementsToLayout(nextProps);
    }
  }

  loadElementsToLayout = async nextProps => {
    let props = nextProps || this.props;
    let layout = [];
    let stages = [];
    let headers = props.headers || (await props.getHeaders());
    headers.forEach((item, index) => {
      layout.push({
        w: 1,
        h: 1,
        x: index,
        y: 0,
        i: (-index - 1).toString(),
        static: true,
        obj: item,
      });
      stages[item.number] = {
        column: item.number - 1,
        item,
      };
    });
    let elements = props.elements || (await props.getElements());
    elements.forEach((item, index) => {
      layout.push({
        w: 1,
        h: props.elementRows,
        x:
          item.stage && stages[item.stage.number]
            ? stages[item.stage.number].column
            : item.column,
        y: index + 1,
        i: item.candidate.id.toString(),
        isDraggable: !this.props.isLocked && item.isDraggable,
        obj: item,
      });
    });

    let content = layout.map(item => {
      return item.y === 0
        ? props.getHeaderContent(item)
        : props.getContent(item);
    });

    this.setState({
      content,
      layout,
      stages,
    });
  };

  onDragStart = (layout, oldItem, newItem) => {
    this.props.onDragStartHandler(layout, oldItem, newItem);
  };

  onDragStop = async (layout, oldItem, newItem) => {
    this.props.onDragStopHandler(layout, oldItem, newItem);
    if (oldItem.x === newItem.x && oldItem.y === newItem.y) {
      return;
    }

    let order = [];
    let stageId;
    let cancel = false;

    layout.some(item => {
      if (item.x === newItem.x && parseInt(item.i, 10) < 0) {
        stageId = -parseInt(item.i, 10);
        if (
          !this.state.stages[stageId].item.isDraggable &&
          !this.state.stages[stageId].item.draggableOnModal
        ) {
          cancel = true;
          newItem.x = oldItem.x;
          newItem.y = oldItem.y;
          return true;
        } else if (this.state.stages[stageId].item.draggableOnModal) {
          if (
            oldItem.x - 1 === 0 ||
            oldItem.x - 1 !== newItem.x ||
            oldItem.x + 1 === newItem.x
          ) {
            order.push({
              sorting: item.y,
              stageNumber: stageId,
              candidateId: parseInt(item.i, 10),
            });

            newItem.x = oldItem.x;
            newItem.y = oldItem.y;
          }
        }
      } else if (item.x === newItem.x && parseInt(item.i, 10) > 0) {
        order.push({
          sorting: item.y,
          stageNumber: stageId,
          candidateId: parseInt(item.i, 10),
        });
      }
      return false;
    });
    !cancel && (await this.props.saveColumnOrder(order, oldItem.i));
  };

  render() {
    return (
      <ReactGridLayout
        layout={this.state.layout}
        onDragStop={this.onDragStop}
        onDragStart={this.onDragStart}
        {...this.props}
      >
        {this.state.content}
      </ReactGridLayout>
    );
  }
}

Kanban.propTypes = {
  elements: PropTypes.array.isRequired,
  saveColumnOrder: PropTypes.func.isRequired,
};

export default Kanban;
