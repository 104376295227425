import {
  Typography,
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { string } from 'yup';
import Select from 'shared/newComponents/Select';
import { ProjectOrderService } from 'shared/services';
import {
  getAvailableProject,
  getAvailableProjectOrders,
} from 'shared/utils/functions';
import { styles } from './CancelOrderDialog.styles';

const CancelOrderDialog = ({
  classes,
  t,
  isOpen,
  onClose,
  selectedOrders,
  projectOrderRemoved,
  projectAvailableToMove,
}) => {
  const [selectOptions, setDynamicSelectOptions] = useState([]);

  const validationSchema = yup.object({
    project: string(),
    projectOrder: string().when('project', {
      is: project => project,
      then: string()
        .strict(false)
        .required(t('projectCreator:core.requiredField')),
      otherwise: string().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      project: '',
      projectOrder: '',
    },
    validationSchema,
    onSubmit: ({ project, projectOrder }, actions) => {
      const body = {
        projectOrders: selectedOrders,
        projectToMove: project !== '' ? { id: project } : null,
        projectOrderToMove: projectOrder !== '' ? { id: projectOrder } : null,
      };

      ProjectOrderService.closeProjectOrder(body)
        .then(() => {
          projectOrderRemoved();
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.status === 409) {
              return actions.setFieldError(
                'projectOrder',
                t`modifyOrderDialog:sameOrder`
              );
            }
            return actions.setFieldError(
              'projectOrder',
              t`newAts:careerPanelView.saveError`
            );
          }
        });
    },
  });

  useEffect(() => {
    setDynamicSelectOptions(
      getAvailableProjectOrders(formik.values.project, projectAvailableToMove)
    );
  }, [formik.values.project]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component="div"
          variant="h6"
        >{t`cancelOrderDialog:header`}</Typography>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography
            variant="body1"
            className={classes.question}
          >{t`cancelOrderDialog:question`}</Typography>
          <Typography
            className={classes.info}
          >{t`cancelOrderDialog:info`}</Typography>
          <Select
            items={getAvailableProject(projectAvailableToMove)}
            formik={formik}
            name="project"
            label={t`cancelOrderDialog:chooseProject`}
          />
          <Select
            items={selectOptions}
            formik={formik}
            name="projectOrder"
            label={t`cancelOrderDialog:chooseLocation`}
          />
        </DialogContent>
        <Button
          className={classes.closeOrderBtn}
          type="submit"
        >{t`cancelOrderDialog:closeOrderBtn`}</Button>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(withTranslation()(CancelOrderDialog));
