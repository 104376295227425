import { FormHelperText, Grid, Paper, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { get, has, isNil } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { array, number, object, string } from 'yup';
import { Checkbox, Hint, TextEditor, Translate } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import { ProjectCandidateV2Service, CandidateV2Service } from 'shared/services';
import ExperienceSelectCard from '../../modules/candidate/NewCandidateCreateView/components/ExperienceSelectCard/ExperienceSelectCard';
import CustomDialog from '../CustomDialog';
import { styles } from './AddCareerPanelForm.style';

export const MODEL = {
  userCareerMotivateToChange: 'userCareerMotivateToChange',
  userCareerProfessionalDescription: 'userCareerProfessionalDescription',
  userCareerProfessionalAspirations: 'userCareerProfessionalAspirations',
  userExperience: 'userExperiences',
  falseCandidateAppliedLastSixMonth: 'falseCandidateAppliedLastSixMonth',
  trueCandidateAppliedLastSixMonth: 'trueCandidateAppliedLastSixMonth',
};

const AddCareerPanelForm = ({
  candidate,
  isOpen,
  onClose,
  careerPanelAdded,
  t,
  classes,
  industryCategories,
  projectCandidate = null,
}) => {
  const formData = {
    userExperiences: candidate.userExperiences,
    userCareerMotivateToChange: projectCandidate
      ? projectCandidate.userCareerMotivateToChange
      : null,
    userCareerProfessionalDescription: projectCandidate
      ? projectCandidate.userCareerProfessionalDescription
      : null,
    userCareerProfessionalAspirations: projectCandidate
      ? projectCandidate.userCareerProfessionalAspirations
      : null,
    trueCandidateAppliedLastSixMonth: projectCandidate
      ? projectCandidate.isCandidateAppliedLastSixMonth
      : null,
    falseCandidateAppliedLastSixMonth: projectCandidate
      ? !projectCandidate.isCandidateAppliedLastSixMonth
      : null,
  };

  const validationSchema = Yup.object().shape({
    userExperiences: array().of(
      object().shape({
        position: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField')),
        companyName: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField')),
        experienceLevel: number().required(
          t('projectCreator:core.requiredField')
        ),
        startWorkYear: number().required(
          t('projectCreator:core.requiredField')
        ),
        companySize: number().required(t('projectCreator:core.requiredField')),
        industryCategory: object().shape({
          id: number().required(t('projectCreator:core.requiredField')),
        }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      const promiseArray = [];
      const valuesBrief = {
        userCareerMotivateToChange:
          values.userCareerMotivateToChange &&
          values.userCareerMotivateToChange.trim() !== ''
            ? values.userCareerMotivateToChange.trim()
            : null,
        userCareerProfessionalDescription:
          values.userCareerProfessionalDescription &&
          values.userCareerProfessionalDescription.trim() !== ''
            ? values.userCareerProfessionalDescription.trim()
            : null,
        userCareerProfessionalAspirations:
          values.userCareerProfessionalAspirations &&
          values.userCareerProfessionalAspirations.trim() !== ''
            ? values.userCareerProfessionalAspirations.trim()
            : null,
        isCandidateAppliedLastSixMonth: values.trueCandidateAppliedLastSixMonth,
      };
      const valuesUseExperiences = {
        userExperiences: values.userExperiences,
        projectId: projectCandidate.project.id,
      };

      promiseArray.push(
        CandidateV2Service.editCandidate(candidate.id, valuesUseExperiences, {
          stage: 'userExperiences',
        })
      );
      promiseArray.push(
        ProjectCandidateV2Service.editProjectCandidateCareer(
          projectCandidate.project.id,
          projectCandidate.candidate.id,
          valuesBrief
        )
      );
      Promise.all(promiseArray)
        .then(() => {
          careerPanelAdded();
        })
        .catch(error => {
          console.log(error);
          alert(t`newAts:core.error`);
        });
    },
  });

  const error =
    has(formik.touched, MODEL.userExperience) &&
    Boolean(get(formik.errors, MODEL.userExperience));

  const onChangeTrueIsCandidateAppliedLastSixMonth = event => {
    formik.setFieldValue(MODEL.trueCandidateAppliedLastSixMonth, true);
    formik.setFieldValue(MODEL.falseCandidateAppliedLastSixMonth, false);
  };

  const onChangeFalseIsCandidateAppliedLastSixMonth = event => {
    formik.setFieldValue(MODEL.falseCandidateAppliedLastSixMonth, true);
    formik.setFieldValue(MODEL.trueCandidateAppliedLastSixMonth, false);
  };
  return (
    <CustomDialog
      maxWidth={'lg'}
      title={t('newAts:core.careerDesc')}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Hint
                      style={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                      content={
                        <div>
                          {t('candidateCreator:step2.experienceHintLabel')}
                        </div>
                      }
                      hintLabel={t(
                        'candidateCreator:step2.experienceHintTitle'
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Translate
                      text={'candidateCreator:step2.experienceRequirements'}
                    />
                  </Grid>
                  <ExperienceSelectCard
                    formik={formik}
                    industryCategories={industryCategories}
                    initialCandidateValues={candidate}
                    t={t}
                  />
                </Grid>
                {error && (
                  <FormHelperText error>
                    {has(formik.touched, MODEL.userExperience) &&
                      !isNil(get(formik.errors, MODEL.userExperience)) &&
                      t('projectCreator:core.requiredField')}
                  </FormHelperText>
                )}
              </Paper>
              <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={'newAts:core.userCareerProfessionalDescription'}
                    bold
                    style={{ fontSize: 20 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextEditor
                    name={MODEL.userCareerProfessionalDescription}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={'newAts:core.userCareerProfessionalAspirations'}
                    bold
                    style={{ fontSize: 20 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextEditor
                    name={MODEL.userCareerProfessionalAspirations}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={'newAts:core.userCareerMotivateToChange'}
                    bold
                    style={{ fontSize: 20 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextEditor
                    name={MODEL.userCareerMotivateToChange}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={'newAts:core.isCandidateAppliedLastSixMonth'}
                    bold
                    style={{ fontSize: 20 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Checkbox
                    label={t('newAts:core.yes')}
                    onChange={event =>
                      onChangeTrueIsCandidateAppliedLastSixMonth(event)
                    }
                    name={MODEL.trueCandidateAppliedLastSixMonth}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Checkbox
                    onChange={event =>
                      onChangeFalseIsCandidateAppliedLastSixMonth(event)
                    }
                    label={t('newAts:core.no')}
                    name={MODEL.falseCandidateAppliedLastSixMonth}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t('newAts:core.save')} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
};

export default withStyles(styles)(withTranslation()(AddCareerPanelForm));
