import { Button, CircularProgress, withStyles } from '@material-ui/core';
import {
  WatchLater as WatchLaterIcon,
  Add as AddIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getNotifications,
  setNotificationRead,
} from 'shared/services/notificationService';
import {
  addProjectCandidate,
  getProjectCandidateConfirmationStatus,
  clearProjectCandidateConfirmationStatus,
  acceptInvitationProjectCandidate,
  rejectInvitationProjectCandidate,
  getProjectsPendingToCandidate,
} from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';

const styles = theme => ({
  button: {
    float: 'right',
    background: '#52a3fc',
    color: '#fff',
    '&:hover': {
      background: '#70b3ff',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonAccept: {
    background: '#3bbf13',
    color: '#fff !important',
    '&:hover': {
      background: '#3dd60e',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonCancel: {
    background: '#f7544d',
    color: '#fff !important',
    '&:hover': {
      background: '#ed635e',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
      marginTop: 0,
    },
  },
  iconClass: {
    float: 'right',
    marginRight: 10,
  },
});

export class AssignCandidateToProjectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  addProjectCandidate = async () => {
    const {
      addProjectCandidate,
      getProjectCandidateConfirmationStatus,
      showSnackbarMessage,
      projectDetails = {},
      candidateDetails = {},
      t,
    } = this.props;
    await addProjectCandidate(projectDetails.id, candidateDetails.id);
    await getProjectCandidateConfirmationStatus(
      projectDetails.id,
      candidateDetails.id
    );
    showSnackbarMessage(t`assign:addProjectCandidate`);
  };

  acceptInvitation = async () => {
    const {
      acceptInvitationProjectCandidate,
      showSnackbarMessage,
      projectDetails = {},
      candidateDetails = {},
      notifications,
      setNotificationRead,
      getNotifications,
      t,
    } = this.props;
    const findNotification = find(notifications, function (n) {
      const { project = {} } = n.data;
      return project.id === projectDetails.id;
    });
    await acceptInvitationProjectCandidate(
      projectDetails.id,
      candidateDetails.id
    );
    this.getConfirmationStatus();
    await setNotificationRead(findNotification.id);
    await getNotifications();
    showSnackbarMessage(t`assign:acceptanceApproved`);
  };

  rejectInvitation = async () => {
    const {
      rejectInvitationProjectCandidate,
      showSnackbarMessage,
      projectDetails = {},
      candidateDetails = {},
      notifications,
      setNotificationRead,
      getNotifications,
      t,
    } = this.props;
    const findNotification = find(notifications, function (n) {
      const { project = {} } = n.data;
      return project.id === projectDetails.id;
    });
    await rejectInvitationProjectCandidate(
      projectDetails.id,
      candidateDetails.id
    );
    this.getConfirmationStatus();
    await setNotificationRead(findNotification.id);
    await getNotifications();
    showSnackbarMessage(t`assign:rejectInvitation`);
  };

  getConfirmationStatus = async () => {
    const {
      getProjectCandidateConfirmationStatus,
      clearProjectCandidateConfirmationStatus,
      getProjectsPendingToCandidate,
      projectDetails = {},
      candidateDetails = {},
    } = this.props;
    if (projectDetails.id) {
      await this.setState({ isLoading: true });
      await clearProjectCandidateConfirmationStatus();
      await getProjectCandidateConfirmationStatus(
        projectDetails.id,
        candidateDetails.id
      );
      await getProjectsPendingToCandidate({
        coOnly: true,
        confirmationStatus: 1,
      });
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    const { isRecruiter } = this.props.candidateDetails;
    if (!isRecruiter) {
      this.getConfirmationStatus();
    }
  }

  render() {
    const {
      classes,
      projectDetails,
      candidateDetails,
      projectCandidateConfirmationStatus,
      t,
    } = this.props;

    const {
      stageNumber = {},
      isRejected = {},
      confirmationStatus = {},
    } = projectCandidateConfirmationStatus;

    const { permissions = {} } = projectDetails;
    const { isCooperator = false } = permissions;
    const { isLoading } = this.state;

    return (
      !candidateDetails.isRecruiter &&
      !projectDetails.closedStatus &&
      (isLoading ? (
        <Button
          disabled={true}
          className={classes.button}
          style={{ textAlign: 'center', background: '#f9f9f9', width: 250 }}
        >
          <CircularProgress
            style={{ width: 25, height: 25 }}
            className={classes.progress}
          />
        </Button>
      ) : (
        <React.Fragment>
          <React.Fragment>
            {stageNumber === 2 && !isRejected && (
              <React.Fragment>
                <ActionButtonJobllegroRounded
                  disabled={true}
                  className={classes.buttonAccept}
                >
                  {t`assign:inProject`}
                </ActionButtonJobllegroRounded>
              </React.Fragment>
            )}

            {stageNumber === 1 && isRejected && confirmationStatus === 3 && (
              <ActionButtonJobllegroRounded
                className={classes.buttonCancel}
                disabled={true}
              >
                {t`assign:rejectProject`}
              </ActionButtonJobllegroRounded>
            )}

            {stageNumber === 1 && isRejected && confirmationStatus === 6 && (
              <ActionButtonJobllegroRounded
                className={classes.buttonCancel}
                disabled={true}
              >
                {t`assign:youWereRejected`}
              </ActionButtonJobllegroRounded>
            )}

            {!isCooperator && confirmationStatus !== 4 && (
              <React.Fragment>
                <ActionButtonJobllegroRounded
                  onClick={this.addProjectCandidate}
                >
                  {t`assign:addToProject`}
                  <AddIcon className={classes.iconClass} />
                </ActionButtonJobllegroRounded>
              </React.Fragment>
            )}

            {stageNumber === 1 && !isRejected && confirmationStatus === 1 && (
              <React.Fragment>
                <ActionButtonJobllegroRounded
                  onClick={this.acceptInvitation}
                  className={classes.buttonAccept}
                  disabled={projectDetails.closedStatus}
                >
                  {t`assign:acceptInvitation`}
                  <DoneIcon className={classes.iconClass} />
                </ActionButtonJobllegroRounded>
                <ActionButtonJobllegroRounded
                  onClick={this.rejectInvitation}
                  className={classes.buttonCancel}
                  disabled={projectDetails.closedStatus}
                >
                  {t`assign:declineInvitation`}
                  <CloseIcon className={classes.iconClass} />
                </ActionButtonJobllegroRounded>
              </React.Fragment>
            )}

            {confirmationStatus === 4 && (
              <React.Fragment>
                <ActionButtonJobllegroRounded
                  aria-label="Oczekuję na akceptację"
                  disabled={projectDetails.closedStatus}
                >
                  {t`assign:waitingForApproval`}
                  <WatchLaterIcon className={classes.iconClass} />
                </ActionButtonJobllegroRounded>
              </React.Fragment>
            )}
          </React.Fragment>
        </React.Fragment>
      ))
    );
  }
}

const mapDispatchToProps = {
  addProjectCandidate,
  getProjectCandidateConfirmationStatus,
  clearProjectCandidateConfirmationStatus,
  acceptInvitationProjectCandidate,
  rejectInvitationProjectCandidate,
  getProjectsPendingToCandidate,
  getNotifications,
  setNotificationRead,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
    candidateDetails: store.auth.currentUser,
    projectCandidateConfirmationStatus:
      store.projects.projectCandidateConfirmationStatus,
    notifications: store.notifications.list,
  };
};

AssignCandidateToProjectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object,
  candidateDetails: PropTypes.object,
  projectCandidateConfirmationStatus: PropTypes.object.isRequired,
  addProjectCandidate: PropTypes.func.isRequired,
  getProjectCandidateConfirmationStatus: PropTypes.func.isRequired,
  acceptInvitationProjectCandidate: PropTypes.func.isRequired,
  rejectInvitationProjectCandidate: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(AssignCandidateToProjectButton)));
