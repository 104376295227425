export const styles = theme => ({
  container: {
    padding: 10,
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  paper: {
    borderRadius: 20,
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: 25,
  },
  title: { padding: 25, paddingBottom: 10, justifyContent: 'flex-end' },
});
