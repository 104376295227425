import { Icon, withStyles, Tooltip, Grid } from '@material-ui/core';
import { HelpCircle } from 'mdi-material-ui';
import React from 'react';
import { Translate } from '..';
import { styles } from './Hint.styles';

const Hint = ({ classes, content, hintLabel, style = {} }) => (
  <div>
    <Grid container alignItems={'center'}>
      {hintLabel && <Translate style={style} text={hintLabel} />}
      <Tooltip title={content} className={classes.tooltip}>
        <Icon className={classes.iconWrapper}>
          <HelpCircle fontSize="small" className={classes.root} />
        </Icon>
      </Tooltip>
    </Grid>
  </div>
);

export default withStyles(styles)(Hint);
