import { withStyles } from '@material-ui/core/styles';
import CandidateDetailsGetMore from './CandidateDetailsGetMore';

const styles = () => ({
  titleDialogPart: {
    marginBottom: 10,
  },
});

export default withStyles(styles)(CandidateDetailsGetMore);
