export const styles = ({ palette }) => ({
  wrapper: { padding: 8 },
  paper: {
    width: '100%',
    borderRadius: 20,
    minHeight: 165,
    position: 'relative',
  },
  container: { padding: 16 },
  primaryText: { color: palette.primary.main },
  secondaryText: { color: palette.secondary.main },
  disabledCard: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    opacity: 0.7,
    borderRadius: '20px',
    backgroundColor: '#fff',
  },
});
