import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { Translate } from 'shared/newComponents';
import NonContent from '../../../NonContent';
import DepartmentsExperienceItem from './DepartmentsExperienceItem';
import { styles } from './DepartmentsExperiences.styles';
import DepartmentsExperiencesForm from './DepartmentsExperiencesForm';

const DepartmentsExperiences = ({
  history,
  recruiterData,
  recruiterEdited,
  departments,
  isSelfEdit,
  classes,
  t,
  ...props
}) => {
  const [openForm, setOpenForm] = useState(false);

  const userDepartmentExperiences = recruiterData.userDepartmentExperiences;

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const getYearOfExperiences = experienceLevel => {
    return ` > ${experienceLevel} ${t`recruiter:yrs`}`;
  };

  if (!isSelfEdit && !userDepartmentExperiences.length) {
    return null;
  }
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Grid item xs={12} sm={12} md={12} className={classes.itemWrapper}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.containerWrapper}>
            <Grid container className={classes.headerWrapper}>
              <Grid item xs={10} sm={10} md={10}>
                <Translate
                  text={t`recruiter:departments`}
                  bold
                  className={classes.fontStyleBig}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} className={classes.editWrapper}>
                <div className={classes.imgWrapper} onClick={handleOpenForm}>
                  {isSelfEdit ? (
                    <img
                      alt="editIcon"
                      onClick={handleOpenForm}
                      src={EditIcon}
                      height={50}
                    />
                  ) : null}
                </div>
              </Grid>
            </Grid>
            {userDepartmentExperiences.length ? (
              <Grid container>
                {userDepartmentExperiences.map(item => {
                  return (
                    <DepartmentsExperienceItem
                      key={item.id}
                      title={item.department.name}
                      yearOfExperiences={item.experienceLevel}
                      yearOfExperiencesText={getYearOfExperiences(
                        item.experienceLevel
                      )}
                    />
                  );
                })}
              </Grid>
            ) : (
              <NonContent />
            )}
          </Grid>
        </Paper>
        {openForm ? (
          <DepartmentsExperiencesForm
            recruiterData={recruiterData}
            isOpen={openForm}
            onClose={handleCloseForm}
            recruiterEdited={recruiterEdited}
            departments={departments}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(DepartmentsExperiences))
);
