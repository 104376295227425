import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import {
  Button,
  Checkbox,
  Select,
  TextEditor,
  TextField,
  Translate,
  WizardStep,
} from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import {
  getSelectItemsFullObjectTranslate,
  getUserAvatarSrc,
} from 'shared/utils/assets';
import CandidateAssets from '../../../../../components/CandidateAssets';
import {
  useFormStep5,
  stage6NotSetFields,
} from '../CandidateCreateWizard/CandidateCreateWizard.utils';
import { styles } from './CandidateCreateWizardStep5.styles';
import { MODEL } from './CandidateCreateWizardStep5.utils';

const CandidateCreateWizardStep5 = ({
  t,
  initialCandidateValues,
  isFormStepError,
  isFormStepSaveButtonDisabled,
  closeAll,
  isCandidateSave,
  history,
  setCurrentStage,
  setIsSnackbarErrorOpen,
  isStep5Open,
  setIsStep5Open,
  step6FormValues,
  setIsStep6Open,
  isOneStageSaved,
  currentUser,
  classes,
  showSaveButton,
}) => {
  const [currentAvatar, setCurrentAvatar] = useState(null);

  const selfEdit = currentUser.id === initialCandidateValues.id;

  const myRef = useRef(null);

  const formik = useFormStep5({
    t,
    initialCandidateValues,
    history,
    setCurrentStage,
  });

  useEffect(() => {
    if (isStep5Open) {
      executeScroll(myRef);
    }
  }, [isStep5Open]);

  useEffect(() => {
    setCurrentAvatar(
      getUserAvatarSrc(
        {
          assets: formik.values[MODEL.candidateAssets],
        },
        true
      )
    );
  }, [formik.values[MODEL.candidateAssets]]);

  const onClickApproveDisabilityIsConfirm = () => {
    formik.setFieldValue(MODEL.disabilityIsConfirm, true);
  };

  const onClickRejectDisabilityIsConfirm = () => {
    formik.setFieldValue(MODEL.disabilityIsConfirm, false);
  };

  const clearDisabledStage = () => {
    formik.setFieldValue(MODEL.degreeOfDisability, null);
    formik.setFieldValue(MODEL.isSecondStageInfoPublic, false);
    formik.setFieldValue(MODEL.disabilityReason, null);
    formik.setFieldValue(MODEL.disabilityIsConfirm, false);
    formik.setFieldValue(MODEL.isCandidateDisabled, false);
  };
  const onChangeIsCandidateDisabled = event => {
    if (!event.target.checked) {
      clearDisabledStage();
    }
    formik.setFieldValue(MODEL.isCandidateDisabled, event.target.checked);
  };

  const updateAvatar = assets => {
    setCurrentAvatar(
      getUserAvatarSrc(
        {
          assets: assets,
        },
        true
      )
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />
      <WizardStep
        withSaveButton={showSaveButton}
        title="candidateCreator:step5.title"
        myRef={myRef}
        isOpen={isStep5Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            if (!formik.values[MODEL.disabilityIsConfirm]) {
              clearDisabledStage();
            }
            setIsStep5Open(false);
            const openSecondStep = stage6NotSetFields(
              initialCandidateValues,
              step6FormValues
            );
            setIsStep6Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep5Open(true);
          executeScroll();
        }}
        disableArrowButton={
          !(isCandidateSave === true && isOneStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isCandidateSave === true && isOneStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => {
          setIsStep5Open(false);
        }}
      >
        <Grid container>
          <Grid container>
            <Grid item>
              <Translate
                style={{ fontSize: '1rem', color: '#233862' }}
                text={'candidateCreator:step5.titleLabel'}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ marginTop: 42 }}>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                bold
                variant="h6"
                text={'candidateCreator:step5.moreInformationAboutCandidate'}
              />
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 32 }}>
              <Grid item xs={12} sm={12} md={2} style={{}}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <CandidateAssets
                      onClose={() => {}}
                      clearLoad={true}
                      callback={updateAvatar}
                      disabled={!selfEdit}
                      removeIcon={false}
                      filesLimit={1}
                      assetType={'avatar'}
                      acceptedFiles={['image/*']}
                      candidate={initialCandidateValues}
                    >
                      <img
                        alt="currentAvatar"
                        src={currentAvatar}
                        height={60}
                        style={{ borderRadius: 30 }}
                      />
                    </CandidateAssets>
                  </div>
                  {selfEdit ? (
                    <div>
                      <Translate text={'candidate:form.changeAvatar'} />
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  label={t('candidateCreator:step5.linkToLinkedin')}
                  name={MODEL.linkedinCandidateLink}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  label={t('candidateCreator:step5.linkToFacebook')}
                  name={MODEL.facebookCandidateLink}
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Paper className={classes.paper} style={{ marginTop: 10 }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Checkbox
                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                    onChange={event => onChangeIsCandidateDisabled(event)}
                    label={t('candidateCreator:step5.isCandidateDisabled')}
                    name={MODEL.isCandidateDisabled}
                    formik={formik}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    display: formik.values[MODEL.isCandidateDisabled]
                      ? ''
                      : 'none',
                    marginTop: 10,
                  }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      items={getSelectItemsFullObjectTranslate(
                        enums.CANDIDATE_DISABLED_DEGREE,
                        t
                      )}
                      label={t('candidateCreator:step5.setDegreeOfDisability')}
                      name={MODEL.degreeOfDisability}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      items={getSelectItemsFullObjectTranslate(
                        enums.CANDIDATE_DISABLED_REASON,
                        t
                      )}
                      label={t('candidateCreator:step5.disabilityReason')}
                      name={MODEL.disabilityReason}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item style={{ marginTop: 10 }}>
                      <Translate
                        style={{ color: '#868889' }}
                        text={
                          'candidateCreator:step5.disabilityWorkPlaceDescPart1'
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Translate
                        style={{ color: '#868889' }}
                        text={
                          'candidateCreator:step5.disabilityWorkPlaceDescPart2'
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextEditor
                        name={MODEL.disabilityWorkPlaceDesc}
                        formik={formik}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Checkbox
                      style={{
                        fontSize: '1rem',
                        color: '#818589',
                      }}
                      label={t(
                        'candidateCreator:step5.isSecondStageInfoPublic'
                      )}
                      name={MODEL.isSecondStageInfoPublic}
                      formik={formik}
                      type="switch"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Translate
                      style={{
                        fontSize: '1rem',
                        color: '#818589',
                      }}
                      text={
                        'candidateCreator:step5.isSecondStageInfoPublicTextInfo'
                      }
                    />
                  </Grid>
                  {showSaveButton ? (
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        {!formik.values[MODEL.disabilityIsConfirm] ? (
                          <Button
                            onClick={onClickApproveDisabilityIsConfirm}
                            label={t('candidateCreator:step5.approve')}
                            variant="outlined"
                          />
                        ) : (
                          <Button
                            onClick={onClickRejectDisabilityIsConfirm}
                            label={t('candidateCreator:step5.reject')}
                            variant="outlined"
                          />
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default withStyles(styles)(
  withTranslation()(CandidateCreateWizardStep5)
);
