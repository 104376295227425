import {
  Grid,
  Paper,
  LinearProgress,
  Typography,
  withStyles,
  Divider,
  Button,
} from '@material-ui/core';
import _, { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import {
  IndustryCategoryService,
  ProjectOrderService,
  ProjectEmploymentProcessService,
} from 'shared/services';
import {
  clearCurrentCandidate,
  getCandidate,
} from 'shared/services/candidateService';
import {
  getProject,
  getProjectCandidates,
  editProjectStageCandidates,
} from 'shared/services/projectService';
import { getCandidateInProject } from 'shared/services/projectService';
import { getStages } from 'shared/services/stageService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import AssignCandidateToProjectsButton from '../../../components/AssignCandidateToProjectsButton';
import FilterATS from '../../../components/FilterATS';
import Kanban from '../../../components/Kanban';
import ProjectAtsCandidateInProject from '../../../components/ProjectAtsCandidateInProject';
import ProjectDetailsViewLeftDescription from '../../../components/ProjectDetailsViewLeftDescription';
import ProjectStepper from '../../../components/ProjectStepper';
import ProjectMenu from '../projectMenu/ProjectMenu';
import AtsCardView from './components/AtsCardView';
import AtsHeader from './components/AtsHeader';
import AtsSubMenu from './components/AtsSubMenu';
import { handleAtsFilter } from './utils/utils';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 8,
  },
  paperContainer: {
    paddingTop: 56,
    paddingLeft: 64,
    paddingRight: 64,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  paperAtsStage: {
    display: 'flex',
    alignItems: 'center',
    background: '#35bfe4',
    margin: 0,
    textTransform: 'capitalize',
    borderRadius: 0,
    borderRight: '1px solid #fff',
    [theme.breakpoints.down('md')]: {
      borderLeft: '1px solid #fff',
    },
  },
  AtsHeaderTitle: {
    color: '#fff',
    fontSize: 16,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    fontFamily: 'Barlow Semi Condensed, sans-serif',
    margin: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  atsIcons: {
    color: 'black !important',
    margin: 0,
    padding: 0,
  },
  AtsFunctionalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  atsSubtext: {
    display: 'inline-flex',
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 64,
    color: '#808080',
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
    },
  },
  divider: {
    backgroundColor: theme.palette.jobllegroBorderGrey,
  },
  iconsAtsHeader: {
    color: 'white',
    cursor: 'pointer',
  },
  wrapperForPadding: {
    padding: '16px 150px',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },
  addCandidateBtn: {
    marginTop: 4,
    marginBottom: 20,
    color: '#fff',
    width: 200,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
});

export class ProjectAtsView extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      candidates: {
        active: [],
        rejected: [],
      },
      checkedCandidate: {
        actualStage: null,
        checkedIndex: [],
      },
      currentSelectAllColumn: null,
      industryCategories: [],
      projectOrders: [],
      selectedCandidateStageNumber: null,
      candidatesPerStage: [],
      candidateInProjectOpened: false,
      candidateSetInProjectOpened: false,
      filters: [],
      sortHeader: [],
      interval: {},
    };
  }
  handleOpenSetCandidateInProject = async () => {
    if (this._isMounted) {
      await this.setState({
        candidateSetInProjectOpened: true,
      });
    }
  };
  handleCloseSetCandidateInProject = async () => {
    if (this._isMounted) {
      await this.setState({
        candidateSetInProjectOpened: false,
      });
    }
  };
  handleOpenCandidateInProject = async (
    candidate,
    owner,
    stageNumber = null
  ) => {
    const {
      currentUser: { isRecruiter },
      project,
    } = this.props;
    const { permissions = {} } = project;

    if (!this.isDragged && isRecruiter) {
      await this.props.clearCurrentCandidate();
      //await this.props.getCandidate(candidate.id);
      await this.props.getCandidateInProject(project.id, candidate.id);
      if (this._isMounted) {
        let availableToOpenTab = true;
        if (permissions.isHiringManager && stageNumber < 4) {
          availableToOpenTab = false;
        }
        this.setState({
          selectedCandidateStageNumber: stageNumber,
          candidateInProjectOpened: availableToOpenTab,
          candidateOwnerInProject: owner,
        });
      }
    }
  };

  handleCloseCandidateInProject = () => {
    if (this._isMounted) {
      this.setState({
        candidateInProjectOpened: false,
        selectedCandidate: null,
      });
    }
  };

  onDragStart = () => {
    setTimeout(() => {
      this.isDragged = true;
    }, 300);
  };

  onDragStop = () => {
    setTimeout(() => {
      this.isDragged = false;
    }, 300);
  };

  getContent = item => {
    const { project = {} } = this.props;
    const { obj } = item;
    const { candidate = {} } = obj;

    return (
      <div key={item.i}>
        <AtsCardView
          item={item}
          candidate={candidate}
          obj={obj}
          project={project}
          handleChangeCheckbox={this.handleChangeCheckbox}
          handleOpenCandidateInProject={this.handleOpenCandidateInProject}
          handleOpenSetCandidateInProject={this.handleOpenSetCandidateInProject}
          parseCandidatesList={this.parseCandidatesList}
        />
      </div>
    );
  };

  getHeaders = () => {
    const { stages, project } = this.props;

    let headers = stages.map(stage => {
      return {
        ...stage,
        isDraggable:
          stage.isDraggable ||
          (project.permissions &&
            (project.permissions.isOwner ||
              project.permissions.isMentor ||
              project.permissions.isHiringManager) &&
            stage.number > 1),
      };
    });
    if (!this.state.sortHeader.length) {
      const defaultOrder = stages.map(() => 'off');
      if (this._isMounted) {
        this.setState({
          sortHeader: defaultOrder,
        });
      }
    }
    return headers;
  };

  sortCell = async (headerIndex, type) => {
    const { sortHeader } = this.state;
    const updateState = [...sortHeader];
    updateState[headerIndex - 1] = type;
    if (this._isMounted) {
      await this.setState({
        sortHeader: updateState,
      });
    }
    await this.parseCandidatesList();
  };

  selectCheckboxColumn = async () => {
    const {
      checkedCandidate,
      currentSelectAllColumn,
      candidates: { active },
    } = this.state;
    const checkedIndexTemp = [];
    const stageCandidate = _.filter(active, [
      'stageNumber',
      currentSelectAllColumn,
    ]);
    if (stageCandidate.length) {
      checkedCandidate.checkedIndex.forEach(item => {
        const candidateToSelect = _.filter(stageCandidate, obj => {
          return obj.candidate.id === item.index;
        });
        if (candidateToSelect.length) {
          checkedIndexTemp.push({
            index: candidateToSelect[0].candidate.id,
            checked: true,
          });
        } else {
          checkedIndexTemp.push({
            index: item.index,
            checked: false,
          });
        }
      });
      if (this._isMounted) {
        await this.setState({
          checkedCandidate: {
            actualStage: currentSelectAllColumn,
            checkedIndex: checkedIndexTemp,
          },
        });
      }
    }
  };

  handleChangeSelectAllCheckbox = async column => {
    if (this._isMounted) {
      await this.setState({
        currentSelectAllColumn:
          column === this.state.currentSelectAllColumn ? null : column,
      });
    }
    const { currentSelectAllColumn } = this.state;
    await this.resetCheckbox();
    if (currentSelectAllColumn) {
      await this.selectCheckboxColumn();
    }
    await this.parseCandidatesList();
  };

  getProjectCandidate = userId => {
    const {
      candidates: { active },
    } = this.state;
    return active.find(item => item.candidate.id === userId);
  };
  handleMoveCandidateToNextStage = () => {
    const { project = {} } = this.props;

    const { checkedCandidate } = this.state;

    const isMentorCoordinator = project.permissions.isMentorCoordinator;

    if (isMentorCoordinator) {
      return null;
    }

    if (checkedCandidate.actualStage === 6) {
      const filterCheckedCandidates = [];
      checkedCandidate.checkedIndex.forEach(item => {
        if (item.checked) {
          filterCheckedCandidates.push({
            id: this.getProjectCandidate(item.index).id,
          });
        }
      });
      if (filterCheckedCandidates.length) {
        this.setState({ isLoading: true });
        const body = {
          projectCandidates: filterCheckedCandidates,
        };
        ProjectEmploymentProcessService.addProjectEmploymentProcess(body)
          .then(() => {
            this.loadData().then(() => {
              this.setState({ isLoading: false });
            });
            this.resetCheckbox();
          })
          .catch(error => {
            console.log(error);
            alert('Błąd przetwarzania danych');
          });
      }
    }
  };

  translateObject = headerName => {
    switch (headerName) {
      case 'zaproszeni':
        return 'summary:field.stage1Title';
      case 'long lista':
        return 'summary:field.stage2Title';
      case 'interview':
        return 'summary:field.stage3Title';
      case 'short lista':
        return 'summary:field.stage4Title';
      case 'submitted':
        return 'summary:field.stage5Title';
      case 'winner':
        return 'summary:field.stage6Title';
      default:
        return 'summary:field.stage6Title';
    }
  };
  getHeaderContent = item => {
    const { candidatesPerStage, sortHeader, currentSelectAllColumn } =
      this.state;

    return (
      <div key={item.i}>
        <AtsHeader
          candidatesPerStage={candidatesPerStage}
          sortHeader={sortHeader}
          currentSelectAllColumn={currentSelectAllColumn}
          sortCell={this.sortCell}
          handleChangeSelectAllCheckbox={this.handleChangeSelectAllCheckbox}
          headerObject={item}
          translateObject={this.translateObject}
          handleMoveCandidateToNextStage={this.handleMoveCandidateToNextStage}
          stageToMoveCandidateToNextStage={6}
        />
      </div>
    );
  };

  setFilters = async filters => {
    if (this._isMounted) {
      await this.setState({
        filters,
      });
      this.resetCheckbox();
    }
  };

  resetCheckbox = async () => {
    const { checkedCandidate } = this.state;
    const checkedIndexTemp = [];
    checkedCandidate.checkedIndex.forEach(item => {
      checkedIndexTemp.push({
        index: item.index,
        checked: false,
      });
    });
    if (this._isMounted) {
      await this.setState({
        checkedCandidate: {
          actualStage: checkedCandidate.actualStage,
          checkedIndex: checkedIndexTemp,
        },
      });
    }
  };

  checkStage = async stage => {
    const { checkedCandidate } = this.state;
    if (this._isMounted) {
      await this.setState({
        checkedCandidate: {
          checkedIndex: checkedCandidate.checkedIndex,
          actualStage: stage,
        },
      });
    }
    if (checkedCandidate.actualStage !== stage) {
      await this.resetCheckbox();
    }
  };
  handleChangeCheckbox = async (
    indexCandidate,
    stage,
    statusCheckbox = null
  ) => {
    if (!statusCheckbox) {
      await this.checkStage(stage);
    }
    const { checkedCandidate } = this.state;
    const checkedIndexTemp = [];
    checkedCandidate.checkedIndex.forEach(item => {
      if (item.index === indexCandidate) {
        checkedIndexTemp.push({
          index: item.index,
          checked: statusCheckbox || !item.checked,
        });
      } else {
        checkedIndexTemp.push({
          index: item.index,
          checked: item.checked,
        });
      }
    });
    if (this._isMounted) {
      await this.setState({
        checkedCandidate: {
          checkedIndex: checkedIndexTemp,
          actualStage: stage,
        },
        currentSelectAllColumn: null,
      });
    }
    await this.parseCandidatesList();
  };

  readCheckboxCandidate = async (hardReset = false) => {
    const { candidates } = this.props;
    const { checkedCandidate } = this.state;
    const checkedIndexTemp = [];
    if (
      candidates.length !== checkedCandidate.checkedIndex.length ||
      hardReset
    ) {
      candidates.forEach(item => {
        if (!item.candidate) {
          console.log('none Candidate');
        } else {
          const filterIndex = _.filter(checkedCandidate.checkedIndex, [
            'index',
            item.candidate.id,
          ]);
          if (filterIndex.length) {
            checkedIndexTemp.push({
              index: item.candidate.id,
              checked: filterIndex.checked,
            });
          } else {
            checkedIndexTemp.push({
              index: item.candidate.id,
              checked: item.checked || false,
            });
          }
        }
      });
      if (this._isMounted) {
        await this.setState({
          checkedCandidate: {
            checkedIndex: checkedIndexTemp,
            actualStage: checkedCandidate.actualStage,
          },
        });
      }
    }
  };

  parseCandidatesList = async () => {
    await this.readCheckboxCandidate();
    const { checkedCandidate } = this.state;
    let candidates = {
      active: [],
      rejected: [],
    };
    let candidatesPerStage = [];
    const { filters, sortHeader } = this.state;
    const { project = {}, stages = {} } = this.props;
    const filteredData = handleAtsFilter(filters, this.props.candidates);
    const sortedArray = [];
    sortHeader.forEach((item, index) => {
      const partitionArray = _.partition(filteredData, [
        'stageNumber',
        index + 1,
      ]);
      const toSort =
        item !== 'off'
          ? _.orderBy(partitionArray[0], ['candidate.lastName'], item)
          : partitionArray[0];
      sortedArray.push(toSort);
    });
    const toMapArray = sortedArray.length
      ? _.flatten(sortedArray)
      : filteredData;

    toMapArray.forEach((element, index) => {
      if (!element.candidate || !element.candidate.id) {
        console.log('none Candidate');
      } else {
        const filterIndex = _.filter(checkedCandidate.checkedIndex, [
          'index',
          element.candidate.id,
        ]);
        const { candidate = {} } = element;

        let stage = find(stages, { number: element.stageNumber });

        if (stage && project.permissions) {
          const isDraggable =
            ((stage.isDraggable ||
              ((project.permissions.isOwner ||
                project.permissions.isMentor ||
                project.permissions.isProjectOrganizationOwner ||
                project.permissions.isSuperUser) &&
                stage.number > 1)) &&
              !project.closedStatus &&
              candidate.permissions.isCandidateInProjectOwner) ||
            ((project.permissions.isOwner ||
              (project.permissions.isMentor &&
                !project.permissions.isMentorLegalization &&
                !project.permissions.isMentorCoordinator) ||
              project.permissions.isProjectOrganizationOwner ||
              project.permissions.isSuperUser) &&
              stage.number > 1) ||
            (project.permissions.isHiringManager &&
              stage.number >= 4 &&
              stage.number !== 6 &&
              !project.closedStatus);
          if (!element.isRejected) {
            candidatesPerStage[stage.number]
              ? candidatesPerStage[stage.number]++
              : (candidatesPerStage[stage.number] = 1);
          }

          if (filterIndex[0]) {
            return candidates[element.isRejected ? 'rejected' : 'active'].push({
              ...element,
              isChecked: filterIndex[0].checked,
              column: stage.number - 1,
              stage: stage,
              isDraggable: isDraggable,
            });
          }
        }
      }
    });
    if (this._isMounted) {
      await this.setState({
        candidatesPerStage,
        candidates,
        isLoading: false,
      });
    }
  };

  saveColumnOrder = async (order, selectedElement = null) => {
    const {
      checkedCandidate: { checkedIndex },
    } = this.state;
    const motherObject = _.filter(order, [
      'candidateId',
      parseInt(selectedElement),
    ]);
    const mulitOrder = _.filter(checkedIndex, [
      'index',
      motherObject[0].candidateId,
    ]);
    if (mulitOrder.length && mulitOrder[0].checked) {
      checkedIndex.forEach(item => {
        if (item.checked && item.index !== motherObject[0].candidateId) {
          order.push({
            ...motherObject[0],
            candidateId: item.index,
          });
        }
      });
      if (this._isMounted) {
        await this.setState({
          checkedCandidate: {
            checkedIndex: checkedIndex,
            actualStage: order[0].stageNumber,
          },
        });
      }
    }
    const { sortHeader } = this.state;
    const updateState = [...sortHeader];
    updateState[order[0].stageNumber - 1] = 'off';
    if (this._isMounted) {
      await this.setState({
        currentSelectAllColumn: null,
        sortHeader: updateState,
        isLoading: true,
      });
    }

    const response = await this.props.editProjectStageCandidates(
      this.props.project.id,
      order
    );
    const { status } = response;
    if (status !== 200) {
      this.props.showSnackbarMessage('Operacja niedozwolona');
    }
    await this.parseCandidatesList();
    if (this._isMounted) {
      await this.setState({
        isLoading: false,
      });
    }
  };

  loadData = async (isFirstLoad = false) => {
    await this.props.getProject(this.props.match.params.id);
    await this.props.getStages(this.props.project.id);
    await this.props.getProjectCandidates(this.props.project.id);
    if (this._isMounted) {
      await this.parseCandidatesList();
    }
  };

  loadIndustryCategories = () => {
    return IndustryCategoryService.getIndustryCategories().then(({ data }) => {
      this.setState({
        industryCategories: data,
      });
    });
  };

  loadProjectOrders = () => {
    return ProjectOrderService.getProjectOrderFromProject(
      this.props.project.id
    ).then(({ data }) => {
      this.setState({
        projectOrders: data,
      });
    });
  };
  async componentDidMount() {
    this._isMounted = true;
    await this.loadData(true);
    await this.loadIndustryCategories();
    await this.loadProjectOrders();
    const interval = setInterval(() => {
      if (!this.isDragged) {
        // this.loadData();
      }
    }, 45000);
    if (this._isMounted) {
      await this.setState({ interval });
    }
  }

  componentWillUnmount() {
    this._isMounted = true;
    clearInterval(this.state.interval);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      await this.loadData();
    }
  }

  render() {
    const {
      classes,
      currentUser: { isRecruiter },
      project,
      stages,
      t,
    } = this.props;
    const {
      candidateInProjectOpened,
      candidates: { active },
      selectedCandidateStageNumber,
      projectOrders,
    } = this.state;
    const { permissions = {} } = project;
    const { isMentor, isOwner, isCooperator } = permissions;

    const activeCandidatesContent = this.state.isLoading ? (
      <div style={{ minHeight: 100 }}>
        <LinearProgress />
      </div>
    ) : (
      <Kanban
        elements={this.state.candidates.active}
        getContent={this.getContent}
        getHeaders={this.getHeaders}
        getHeaderContent={this.getHeaderContent}
        saveColumnOrder={this.saveColumnOrder}
        onDragStartHandler={this.onDragStart}
        onDragStopHandler={this.onDragStop}
        elementRows={2}
        rowHeight={70}
        cols={stages.length}
      />
    );

    const rejectedCandidatesContent = this.state.isLoading ? (
      <div style={{ minHeight: 100 }}>
        <LinearProgress />
      </div>
    ) : (
      <Kanban
        elements={this.state.candidates.rejected}
        getContent={this.getContent}
        saveColumnOrder={this.saveColumnOrder}
        elementRows={2}
        rowHeight={70}
        cols={stages.length}
        isLocked={true}
      />
    );

    const selectedCandidates = active.filter(item => item.isChecked);

    return (
      <React.Fragment>
        <Grid
          container
          alignItems="flex-start"
          spacing={0}
          className={classes.wrapperForPadding}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
            <ProjectMenu title={'Status Projektu'} />
            <AtsSubMenu />
            {(isMentor || isOwner || isCooperator) &&
              isRecruiter &&
              !project.closedStatus && (
                <Grid container md={12} justifyContent="flex-end">
                  <Button
                    className={classes.addCandidateBtn}
                    onClick={() =>
                      this.props.history.push(
                        PATHS.CANDIDATE.CREATE_IN_PROJECT.replace(
                          ':projectId',
                          project.id
                        )
                      )
                    }
                  >
                    {t('project:menu.details.candidates')}
                  </Button>
                </Grid>
              )}
            <ProjectAtsCandidateInProject
              projectId={project.id}
              industryCategories={this.state.industryCategories}
              selectedCandidateStageNumber={selectedCandidateStageNumber}
              reloadAtsHandler={this.loadData}
              open={candidateInProjectOpened}
              ownerInProject={this.state.candidateOwnerInProject}
              handleClose={this.handleCloseCandidateInProject}
            />
            {this.state.candidateSetInProjectOpened ? (
              <AssignCandidateToProjectsButton
                option="box"
                selectedCandidates={selectedCandidates}
                open={this.state.candidateSetInProjectOpened}
                handleOpenSetCandidateInProject={
                  this.handleOpenSetCandidateInProject
                }
                handleCloseSetCandidateInProject={
                  this.handleCloseSetCandidateInProject
                }
              />
            ) : null}

            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ProjectDetailsViewLeftDescription
                    title={t('project:show.candidatesInProject')}
                    project={project}
                    stepperComponent={
                      <ProjectStepper
                        startDate={project.projectStartDate}
                        endDate={project.projectEndDate}
                        marginTop={120}
                        variantMini={true}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <FilterATS
                parseCandidatesList={this.parseCandidatesList}
                setFilters={this.setFilters}
                projectOrders={projectOrders}
              />
              <Grid container alignItems="flex-start" spacing={0}>
                {isRecruiter && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography component="p" className={classes.atsSubtext}>
                      {t('project:show.candidatesInProjectATS1')}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {activeCandidatesContent}
                </Grid>
                {isRecruiter && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography component="p" className={classes.atsSubtext}>
                      {t('project:show.candidatesInProjectRejectedATS1')}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {rejectedCandidatesContent}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getCandidate,
  getProject,
  getStages,
  getProjectCandidates,
  clearCurrentCandidate,
  editProjectStageCandidates,
  getCandidateInProject,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    project: store.projects.currentProject,
    candidates: store.candidates.currentCandidatesList,
    stages: store.stages.list,
    currentUser: store.auth.currentUser,
    lastPage: store.auth.lastPage,
  };
};

ProjectAtsView.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  stages: PropTypes.array.isRequired,
  candidates: PropTypes.array.isRequired,
  getCandidate: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  getStages: PropTypes.func.isRequired,
  getProjectCandidates: PropTypes.func.isRequired,
  editProjectStageCandidates: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectAtsView)));
