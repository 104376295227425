import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FilterATS from './FilterATS';

const mapStateToProps = state => ({});

const mapDispatchToProps = null;

const FilterATSContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FilterATS));

FilterATSContainer.displayName = 'FilterATSContainer';

export default FilterATSContainer;
