import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { string } from 'yup';
import { TextEditor } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import { RecruiterV2Service } from 'shared/services';
import CustomDialog from '../../../../../../../../components/CustomDialog';

function OtherExperiencesForm({
  recruiterData,
  isOpen,
  onClose,
  recruiterEdited,
  t,
}) {
  const formData = {
    aboutMe: recruiterData ? recruiterData.aboutMe : '',
  };

  const validationShema = Yup.object().shape({
    aboutMe: string()
      .nullable()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationShema,
    onSubmit: (values, actions) => {
      const body = {
        aboutMe: values.aboutMe,
      };

      RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
        stage: 'about_me_data',
      })
        .then(() => {
          recruiterEdited();
          onClose();
        })
        .catch(error => {
          console.log(error);
          alert(t`recruiter:saveError`);
        });
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`recruiter:recruiterDesc`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <TextEditor
                formik={formik}
                name={'aboutMe'}
                label={t`recruiter:aboutMe`}
                subLabel={''}
              />
              <Grid
                container
                justifyContent="center"
                onClick={formik.handleSubmit}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Button label={t`adminCitySeo:save`} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
}

export default connect()(withTranslation()(OtherExperiencesForm));
