import { CurrentUser, Roles } from 'shared/types';

export const getUserRole = (currentUser: CurrentUser) => {
  if (currentUser.isSuperUser) {
    return Roles.ADMIN;
  }
  if (currentUser.isRecruiter) {
    return Roles.RECRUITER;
  }
  return Roles.CANDIDATE;
};
