import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import RegisterForm from './RegisterForm.style';
import validate from './validate';

const registerForm = reduxForm({
  form: 'registerRecruiter',
  validate,
})(RegisterForm);

export default withTranslation()(registerForm);
