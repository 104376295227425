import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import React from 'react';

export function CustomIconButton({ type, onClick, title, classes }) {
  return (
    <IconButton
      onClick={onClick}
      className={clsx({
        [classes.deleteIcon]: type === 'delete',
        [classes.editIcon]: type === 'edit',
      })}
      title={title}
    >
      {type === 'add' && <AddIcon fontSize={'small'} />}
      {type === 'delete' && <DeleteIcon fontSize={'small'} />}
      {type === 'edit' && <EditIcon fontSize={'small'} />}
      {type === 'preview' && <VisibilityIcon fontSize={'small'} />}
    </IconButton>
  );
}
