import {
  Button,
  Avatar,
  Chip,
  Grid,
  Typography,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  Tooltip,
  withStyles,
  MenuItem,
} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Delete as DeleteSweepIcon,
  Edit as EditIcon,
  LocationOn as LocalIcon,
  List as ListIcon,
  PeopleOutline as CoIcon,
  SupervisorAccount as SuperVisorIcon,
  Notifications as NotificationIcon,
  Timer as ClockIcon,
  Visibility as VisibilityIcon,
  VpnKey as VpnKeyIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { CONST_CONFIG } from 'shared/constants';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import Box from '../../../components/BoxContainer';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import LayoutPopperMenu from '../../../components/LayoutPopperMenu';
import ElementsListAsync from '../../../components/list/ElementsListAsync';

const styles = theme => ({
  container: {
    marginTop: 0,
  },
  card: {
    minWidth: '320px',
  },
  chipActive: {
    color: '#9eff93',
    width: 16,
  },
  actions: {
    borderTop: '1px solid #e9e9e9',
    padding: 0,
    justifyContent: 'space-between',
    display: 'flex',
  },
  jobTitle: {
    color: theme.palette.jobllegroBlue,
    fontSize: 16,
    paddingTop: 2,
    paddingBottom: 2,
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'inline-block',
    transition: 'all .4s',
    '&:hover': {
      borderRadius: '20px',
      backgroundColor: 'rgba(82, 163, 252, 0.1)',
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
  companyTitle: {
    color: theme.palette.jobllegroGrey,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  companyTitleCity: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.jobllegroLightGrey,
    marginLeft: 10,
  },
  companyTitleAvatar: {
    color: theme.palette.jobllegroLightGrey,
    background: 'none',
    width: 10,
    height: 10,
    marginRight: 2,
  },
  buttonShow: {
    color: '#52a3fc',
    float: 'right',
    width: 'auto',
    fontSize: 12,
    textTransform: 'none',
    alignItems: 'center',
    '&:hover': {
      background: '#fff',
    },
  },
  chip: {
    background: 'transparent',
    width: 14,
  },
  chipLabel: {
    fontSize: 12,
    background: 'transparent',
    color: theme.palette.jobllegroLightGrey,
  },
  chipAvatar: {
    width: 'auto',
    height: 'auto',
    background: 'transparent',
  },
  supervisorIcon: {
    width: 20,
  },
  avatar: {
    background: 'transparent',
    width: 20,
  },
  rightIcon: {
    fontSize: 13,
  },
  listMenu: {
    fontSize: 18,
    color: theme.palette.jobllegroBlue,
  },
  menuItem: {
    display: 'flex',
    fontSize: 12,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    color: theme.palette.jobllegroGrey,
    textTransform: 'none',
    borderBottom: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    height: 16,
    '& svg': {
      fontSize: 12,
      color: theme.palette.jobllegroLightGrey,
      marginRight: 10,
    },
  },
  cardContent: {
    paddingBottom: 8,
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
  chipIcon: {
    width: 15,
    color: theme.palette.jobllegroLightGrey,
  },
  chipIconGreen: {
    color: theme.palette.jobllegroSuccess,
  },
  chipIconRed: {
    color: theme.palette.jobllegroRedAlert,
  },
  statusInfo: {
    paddingTop: 4,
  },
  statusDownInfo: {
    top: 130,
    right: 10,
    position: 'absolute',
  },
});

export class ProjectList extends Component {
  state = {
    anchorEl: null,
    projectSelected: {},
  };

  handleToggleMenuCardOpen = project => event => {
    this.setState({
      anchorEl: event.currentTarget,
      projectSelected: project,
    });
  };

  handleToggleMenuCardClose = () => {
    this.setState({
      anchorEl: null,
      projectSelected: {},
    });
  };

  triggerDeleteButton = id => () => {
    this.props.handleDelete(id);
    this.handleToggleMenuCardClose();
  };

  getActionButtonsForProject = project => {
    const { classes, t } = this.props;
    const isOwner =
      project && project.permissions && project.permissions.isOwner;

    return (
      <div>
        <MenuItem
          className={classes.menuItem}
          aria-label="Edit"
          disabled={!isOwner}
          component={Link}
          to={PATHS.PROJECT.EDIT.replace(':id', project.id)}
        >
          <EditIcon />
          {t('project:list.edit')}
        </MenuItem>
        {project && project.isDraft ? (
          <MenuItem
            className={classes.menuItem}
            aria-label="Edit"
            disabled={!isOwner}
            onClick={this.triggerDeleteButton(project.id)}
          >
            <DeleteSweepIcon />
            {t('project:list.delete')}
          </MenuItem>
        ) : null}
      </div>
    );
  };

  getContentProject = project => {
    const { currentUser = {}, classes, t } = this.props;
    const { isRecruiter } = currentUser;
    const isOwner =
      project && project.permissions && project.permissions.isOwner;
    const isHiringManager =
      project && project.permissions && project.permissions.isHiringManager;
    const hiringManagerClosePending =
      isHiringManager && 1 === project.closedStatus;
    const ownerClosePending = isOwner && 1 === project.closedStatus;
    const { visibility } = project;
    const {
      permissions: { isMentor },
    } = project;

    let visibilityStatus = '';
    if (visibility === 3) {
      visibilityStatus = t('project:list.statusPublic');
    } else if (visibility === 2) {
      visibilityStatus = t('project:list.statusOrganization');
    } else if (visibility === 1) {
      visibilityStatus = t('project:list.statusPrivate');
    }

    let coStatus = '';
    if (isRecruiter) {
      if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 1
      ) {
        coStatus = (
          <Tooltip title={t('project:list.recruiterStatusInProject_1')}>
            <ClockIcon style={{ color: '#ffcf3f', width: 16 }} />
          </Tooltip>
        );
      } else if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 2
      ) {
        coStatus = (
          <Tooltip title={t('project:list.recruiterStatusInProject_2')}>
            <SuperVisorIcon className={classes.chipActive} />
          </Tooltip>
        );
      } else if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 4
      ) {
        coStatus = (
          <Tooltip title={t('project:list.recruiterStatusInProject_4')}>
            <ClockIcon />
          </Tooltip>
        );
      } else if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 5
      ) {
        coStatus = (
          <Tooltip title={t('project:list.recruiterStatusInProject_5')}>
            <SuperVisorIcon className={classes.chipActive} />
          </Tooltip>
        );
      }
    } else {
      if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 1
      ) {
        coStatus = (
          <Tooltip title={t('project:list.candidateStatusInProject_1')}>
            <NotificationIcon style={{ color: '#F50057', width: 16 }} />
          </Tooltip>
        );
      } else if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 2
      ) {
        coStatus = (
          <Tooltip title={t('project:list.candidateStatusInProject_2')}>
            <SuperVisorIcon className={classes.chipActive} />
          </Tooltip>
        );
      } else if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 4
      ) {
        coStatus = (
          <Tooltip title={t('project:list.candidateStatusInProject_4')}>
            <ClockIcon />
          </Tooltip>
        );
      } else if (
        project.permissions.isCooperator &&
        project.permissions.statusInProject === 5
      ) {
        coStatus = (
          <Tooltip title={t('project:list.candidateStatusInProject_5')}>
            <SuperVisorIcon className={classes.chipActive} />
          </Tooltip>
        );
      }
    }
    const isClientPublic = get(project, 'isClientPublic');

    const companyName = isClientPublic
      ? get(project, 'client')
      : t('projectCreator:step1.privateCompanyName');

    return (
      <React.Fragment>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h5"
            className={classes.jobTitle}
            component={Link}
            to={PATHS.PROJECT.SHOW.replace(':id', project.id)}
          >
            {project.position}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            className={classes.companyTitle}
          >
            {companyName}
            <Typography component="div" className={classes.companyTitleCity}>
              <Avatar className={classes.companyTitleAvatar}>
                <LocalIcon style={{ width: 10 }} />
              </Avatar>
              {project.city}
            </Typography>
          </Typography>
          {isRecruiter && (
            <div className={classes.statusInfo}>
              <Chip
                avatar={
                  <Avatar className={classes.chipAvatar}>
                    <VisibilityIcon
                      className={classNames(
                        classes.chip,
                        visibility === 3
                          ? classes.chipIconGreen
                          : classes.chipIconRed
                      )}
                    />
                  </Avatar>
                }
                label={visibilityStatus}
                className={classes.chipLabel}
              />

              <Chip
                avatar={
                  <Avatar className={classes.chipAvatar}>
                    <VpnKeyIcon
                      className={classNames(
                        classes.chip,
                        project.permissions.isOwner
                          ? classes.chipIconGreen
                          : classes.chipIconRed
                      )}
                    />
                  </Avatar>
                }
                label={
                  project.permissions.isOwner
                    ? t('project:list.owner')
                    : t('project:list.notOwner')
                }
                className={classes.chipLabel}
              />

              {project.permissions.isCooperator &&
                project.permissions.statusInProject === 5 && (
                  <Chip
                    avatar={
                      <Avatar className={classes.chipAvatar}>
                        <CoIcon
                          className={classNames(
                            classes.chip,
                            project.permissions.isCooperator
                              ? classes.chipIconGreen
                              : classes.chipIconRed
                          )}
                        />
                      </Avatar>
                    }
                    label={coStatus}
                    className={classes.chipLabel}
                  />
                )}
            </div>
          )}
          <div className={classes.statusDownInfo}>
            {isMentor && (
              <Chip
                style={{ height: 'auto' }}
                avatar={
                  <Avatar className={classes.chipAvatar}>
                    <CoIcon
                      className={classNames(
                        classes.chip,
                        classes.chipIconGreen
                      )}
                    />
                  </Avatar>
                }
                label="Mentor"
                className={classes.chipLabel}
              />
            )}

            {(hiringManagerClosePending || ownerClosePending) && (
              <Chip
                style={{ height: 'auto' }}
                avatar={
                  <Avatar className={classes.chipAvatar}>
                    <CoIcon
                      className={classNames(classes.chip, classes.chipIconRed)}
                    />
                  </Avatar>
                }
                label={
                  hiringManagerClosePending
                    ? t`project:closeProject`
                    : t`project:show.projectClosed`
                }
                className={classes.chipLabel}
              />
            )}
            {coStatus !== '' && (
              <Chip
                style={{ height: 'auto' }}
                label={coStatus}
                className={classes.chipLabel}
              />
            )}
          </div>
        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
          {isRecruiter && !project.closedStatus && (
            <IconButton
              aria-haspopup="true"
              onClick={this.handleToggleMenuCardOpen(project)}
            >
              <ListIcon className={classes.listMenu} />
            </IconButton>
          )}
          {!isRecruiter && (
            <Chip
              label={
                `${t`project:candidates`}: ` + project.candidateInProjectCount
                // +
                // " / " +
                // project.candidateCount
              }
              className={classes.chipLabel}
            />
          )}
          <Button
            className={classes.buttonShow}
            component={Link}
            to={PATHS.PROJECT.SHOW.replace(':id', project.id)}
          >
            {t('project:list.readMore')}
            <KeyboardArrowRightIcon className={classes.rightIcon} />
          </Button>
        </CardActions>
      </React.Fragment>
    );
  };

  getContent = project => {
    const content = this.getContentProject(project);
    const projectBanner = getProjectAssetSrcByType(project, 'header');
    const projectLogotype = getProjectAssetSrcByType(project, 'logo');
    const actionButtonsProject = this.getActionButtonsForProject(
      this.state.projectSelected
    );
    const showRecommend = project.recommendSort === 1;

    return (
      <React.Fragment key={project.id}>
        <Grow in={true}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Box
              showRecommend={showRecommend}
              imageHeight={115}
              logoType={projectLogotype}
              image={projectBanner}
            >
              <div>{content}</div>
            </Box>
          </Grid>
        </Grow>
        <LayoutPopperMenu
          anchorEl={this.state.anchorEl}
          handleToggleMenuCardClose={this.handleToggleMenuCardClose}
        >
          {actionButtonsProject}
        </LayoutPopperMenu>
      </React.Fragment>
    );
  };

  getWrapper = options => {
    let { content, pagination, sorting, filtering } = options;
    const { t } = this.props;

    return (
      <div>
        <ExpansionComponent
          title={t('filters.name')}
          textOnTop={t('filters.description')}
        >
          {filtering}
        </ExpansionComponent>
        {sorting}
        {this.props.load ? (
          <LinearProgress />
        ) : (
          <Grid container className={this.props.classes.container}>
            {content.length !== 0 ? (
              content
            ) : (
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h1"
                  align="center"
                  style={{ paddingTop: '15%', height: '50vh' }}
                >
                  {t('project:list.projectNoResult')}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {pagination}
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const sortingOptions = [
      {
        name: 'id',
        label: t('project:field.id'),
      },
      {
        name: 'client',
        label: t('project:field.client'),
      },
      {
        name: 'position',
        label: t('project:field.positionSort'),
      },
      {
        name: 'city',
        label: t('project:field.citySort'),
      },
      {
        name: 'createdAt',
        label: t('project:field.createdAt'),
      },
      {
        name: 'updatedAt',
        label: t('project:field.updatedAt'),
      },
    ];

    const filteringOptions = [
      {
        key: 'visibility',
        type: 'select',
        name: t('project:field.isPublicLong'),
        values: [
          {
            key: 1,
            name: t('project:field.isPrivate'),
          },
          {
            key: 2,
            name: t('project:field.isOrganization'),
          },
          {
            key: 3,
            name: t('project:field.isPublic'),
          },
        ],
      },
      {
        key: 'client',
        type: 'text',
        name: t('project:field.companyName'),
      },
      {
        key: 'companySize',
        type: 'select',
        name: t('project:field.companySize'),
        values: [
          {
            key: 1,
            name: '0-9',
          },
          {
            key: 2,
            name: '10-49',
          },
          {
            key: 3,
            name: '50-249',
          },
          {
            key: 4,
            name: '250+',
          },
        ],
      },
      {
        key: 'industry',
        type: 'text',
        name: t('project:field.industry'),
      },
      {
        key: 'city',
        type: 'text',
        name: t('project:field.city'),
      },
      {
        key: 'employmentStartDate',
        type: 'text',
        name: t('project:field.employmentStartDate'),
        placeholder: 'YYYY-MM-DD',
      },
      {
        key: 'position',
        type: 'text',
        name: t('project:field.position'),
      },
      {
        key: 'salary',
        type: 'number',
        name: t('project:field.salary'),
      },
      {
        key: 'contractType',
        type: 'text',
        name: t('project:field.contractType'),
      },
      {
        key: 'benefits',
        type: 'text',
        name: t('project:field.benefits'),
      },
      {
        key: 'projectStartDate',
        type: 'text',
        name: t('project:field.projectStartDate'),
        placeholder: 'YYYY-MM-DD',
      },
      {
        key: 'projectEndDate',
        type: 'text',
        name: t('project:field.projectEndDate'),
        placeholder: 'YYYY-MM-DD',
      },
      {
        key: 'candidateCount',
        type: 'number',
        name: t('project:field.candidateCount'),
      },
      {
        key: 'revenue',
        type: 'number',
        name: t('project:field.priceForRecrutation'),
      },
      {
        key: 'revenueSplit',
        type: 'text',
        name: t('project:field.priceForRecrutation'),
      },
    ];

    return (
      <ElementsListAsync
        storageKey={this.props.storageKey}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        handleReloadElements={this.props.handleReloadElements}
        load={this.props.load}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        sortingOptions={sortingOptions}
        defaultSorting={CONST_CONFIG.PROJECT.DEFAULT_SORT}
        filteringOptions={filteringOptions}
        elements={this.props.projects}
        elementsCount={this.props.projectsCount}
        scrollUp={true}
      />
    );
  }
}

ProjectList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    projects: store.projects.currentProjectsLimitedList,
    projectsCount: store.projects.currentProjectsLimitedListCounter,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectList)));
