import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCurrentUser, editUserRodo } from 'shared/services/authService';
import { getConfigs } from 'shared/services/configService';
import { getNotifications } from 'shared/services/notificationService';
import IsAuthorized from './IsAuthorized.style';

const mapDispatchToProps = {
  getNotifications,
  getCurrentUser,
  getConfigs,
  editUserRodo,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    language: store.auth.language,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(IsAuthorized)));
