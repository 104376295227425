import {
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import { getTimeLeft } from 'shared/utils/date';

const styles = theme => ({
  listItem: {
    paddingLeft: 18,
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    transition: 'all .4s',
    justifyContent: 'space-between',
    '& > div': {
      fontSize: 12,
    },
    '&::hover': {
      background: 'transparent',
    },
  },
  listWrapper: {
    padding: '0px',
  },
  avatar: {
    width: 34,
    height: 34,
    marginLeft: 0,
    backgroundColor: theme.palette.jobllegroBlue,
  },
  rootListText: {
    padding: '0 0px 0 24px',
  },
  primary: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: theme.palette.jobllegroBlue,
  },
  secondary: {
    padding: 0,
    fontSize: 12,
    color: theme.palette.jobllegroGrey,
  },
  textBottom: {
    color: theme.palette.jobllegroGrey,
    fontSize: 11,
  },
  userStatus: {
    background: theme.palette.jobllegroRedAlert,
    paddingRight: 2,
    paddingLeft: 2,
    top: '24px',
    borderRadius: 0,
    color: '#fff',
    width: 'auto',
    right: 0,
    fontSize: 8,
    height: 'auto',
  },
  userStatusDisabled: {
    display: 'none',
  },
});

export const DataItemListItemProject = props => {
  const { classes, data, t } = props;
  const avatarSrc = getProjectAssetSrcByType(data, 'logo');
  const dataTextOne = data.client;
  const showProjectTime = getTimeLeft(data, t);
  const { permissions = {} } = data;
  let isMentor = false;
  if (permissions === undefined) {
    isMentor = false;
  } else {
    isMentor = permissions.isMentor;
  }

  const secondaryText = (
    <Typography className={classes.textBottom} component="div">
      {dataTextOne}
      {showProjectTime !== '' && ' (' + showProjectTime + ')'}
    </Typography>
  );

  const primaryText = (
    <Typography className={classes.primary} component="div">
      {data.position}
    </Typography>
  );

  return (
    <List className={classes.listWrapper}>
      <ListItem
        key={data.id}
        dense
        className={classes.listItem}
        component={Link}
        to={PATHS.PROJECT.SHOW.replace(':id', data.id)}
      >
        <Badge
          badgeContent="Mentor"
          classes={{
            badge:
              isMentor === true
                ? classes.userStatus
                : classes.userStatusDisabled,
          }}
        >
          <Avatar className={classes.avatar} src={avatarSrc}>
            JB
          </Avatar>
        </Badge>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          classes={{
            root: classes.rootListText,
            primary: classes.primary,
            secondary: classes.secondary,
          }}
        />
      </ListItem>
    </List>
  );
};

DataItemListItemProject.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(DataItemListItemProject));
