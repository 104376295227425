import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import RecruiterMenu from '../../../recruiterMenu/RecruiterMenu';
import { styles } from './RecruiterAnonymousDetailsContent.styles';
import RecruiterAnonymousDetailsContentBottom from './components/Bottom';
import RecruiterAnonymousDetailsContentCenter from './components/Center';
import RecruiterAnonymousDetailsContentLowerTop from './components/LowerTop';
import RecruiterAnonymousDetailsContentTop from './components/Top';

const RecruiterAnonymousDetailsContent = ({
  recruiterDetails,
  recruiterEdited,
  departments,
  industryCategories,
  languageCompetence,
  countries,
  isSelfEdit,
  classes,
  currentUser,
}) => {
  return (
    <Grid container className={classes.wrapper}>
      <RecruiterMenu
        itemSelected="show"
        title={'candidateDetails:anonymousRecruiterDetailsCard.menuTitle'}
        subtitle={'candidateDetails:anonymousRecruiterDetailsCard.subTitle'}
        currentRecruiter={recruiterDetails}
        currentUser={currentUser}
      />
      <Grid container alignContent={'center'}>
        <RecruiterAnonymousDetailsContentTop
          recruiterData={recruiterDetails}
          currentUser={currentUser}
        />
        <RecruiterAnonymousDetailsContentLowerTop
          recruiterData={recruiterDetails}
        />
        <RecruiterAnonymousDetailsContentCenter
          isSelfEdit={isSelfEdit}
          recruiterData={recruiterDetails}
          recruiterEdited={recruiterEdited}
          departments={departments}
          industryCategories={industryCategories}
          countries={countries}
          languageCompetence={languageCompetence}
        />
        <RecruiterAnonymousDetailsContentBottom
          isSelfEdit={isSelfEdit}
          recruiterData={recruiterDetails}
          recruiterEdited={recruiterEdited}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(RecruiterAnonymousDetailsContent));
