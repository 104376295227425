import { Popover } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'shared/assets/css/scrollBar.css';

const styles = () => ({
  subHeader: {
    background: '#fcfcfc',
    paddingBottom: 15,
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
  },
});
export class LayoutMenuToggleAction extends Component {
  render() {
    const {
      classes,
      anchorElTrigger,
      anchorElCloseTrigger,
      getContent,
      forwardedRef,
    } = this.props;

    return (
      <>
        <Popover
          elevation={2}
          open={Boolean(anchorElTrigger)}
          anchorEl={anchorElTrigger}
          onClose={anchorElCloseTrigger}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          ref={forwardedRef}
        >
          <Slide
            ref={forwardedRef}
            direction="down"
            in={!!anchorElTrigger}
            mountOnEnter
            unmountOnExit
          >
            <div className={classes.subHeader}>{getContent()}</div>
          </Slide>
        </Popover>
      </>
    );
  }
}

LayoutMenuToggleAction.propTypes = {
  getContent: PropTypes.func,
};

export default connect(null, null)(withStyles(styles)(LayoutMenuToggleAction));
