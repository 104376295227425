import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CandidateV2Service, UserService } from '../../services';
import { ActionButtonJobllegroRounded } from '../../utils/styledComponents';
import { styles } from './AssignCandidateToStashButton.styles';

const AssignCandidateToStashButton = ({
  candidate,
  t,
  currentUser,
  btnColor = '#52A3FC',
  ...props
}) => {
  const [candidateStash, setCandidateStash] = useState([]);
  const [candidateInStash, setCandidateInStash] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    CandidateV2Service.getCandidateV2Stash()
      .then(({ data }) => {
        setCandidateStash(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, [candidate]);

  useEffect(() => {
    if (currentUser) {
      const findCandidateInStash = !!candidateStash.find(
        item => item.id === candidate.id
      );
      setCandidateInStash(findCandidateInStash);
      return;
    }
    setCandidateInStash(false);
  }, [candidateStash]);

  const handleSaveCandidateToStash = () => {
    UserService.postCandidateToStash({ candidate: candidate })
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDeleteCandidateToStash = () => {
    UserService.deleteCandidateFromStash(candidate.id)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  if (isLoading) {
    return null;
  }
  return candidateInStash ? (
    <ActionButtonJobllegroRounded
      onClick={handleDeleteCandidateToStash}
      style={{ background: btnColor }}
    >
      {t`candidate:show.delFromClipboard`}
    </ActionButtonJobllegroRounded>
  ) : (
    <ActionButtonJobllegroRounded
      style={{ background: btnColor }}
      onClick={handleSaveCandidateToStash}
    >
      {t`candidate:show.saveToClipboard`}
    </ActionButtonJobllegroRounded>
  );
};

export default withStyles(styles)(
  withTranslation()(AssignCandidateToStashButton)
);
