import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  uploadAssets,
  deleteAsset,
  getAssets,
} from '../../services/assetService';
import { showSnackbarMessage } from '../../store/messages/actions';
import FileInput from './FileInput';

const AssetsUpload = ({
  opened,
  closeHandler,
  t,
  uploadAssetsHandler,
  assetType,
  uploadAssets,
  showSnackbarMessage,
  deleteAsset,
  filesLimit = 4,
  maxSize = 5000000,
  acceptedFiles,
  cropShape,
  aspect,
  removeIcon = true,
  children,
  imageName,
  setResponse,
  assets,
  onDeleteAsset,
}) => {
  const [files, setFiles] = useState([]);
  const [uploadIsOpened, setUploadIsOpened] = useState(opened || false);

  useEffect(() => {
    loadAssets();
  }, []);

  const closeUploadModal = () => {
    closeHandler && closeHandler();
    setUploadIsOpened(false);
  };

  const saveAssets = async files => {
    try {
      let assetsOnServer = false;
      if (uploadAssetsHandler) {
        assetsOnServer = await uploadAssetsHandler(files, assetType);
      } else {
        const uploadResponse = await uploadAssets(files, assetType);
        setResponse(uploadResponse);
      }
      setUploadIsOpened(false);
      await loadAssets();

      if (assetsOnServer) {
        showSnackbarMessage(t('core:form:info.filesUploadAgain'));
      } else {
        showSnackbarMessage(t('core:form:info.filesUploadSuccess'));
      }
    } catch (error) {
      showSnackbarMessage(t('core:form:info.failedUploadSuccess'));
    }
  };

  const loadAssets = async newAssets => {
    let files = [];

    assetType &&
      (assets = filter(newAssets || assets, file => {
        if (file !== undefined) {
          const filterFiles = file.filter(test => test.type === assetType);
          if (filterFiles.length) {
            return file;
          }
        }
      }));

    if (assets) {
      assets.forEach(item => {
        item.forEach(file => {
          let fileObject = new File([], file.filename, {
            type: file.filetype || 'image/png',
          });
          fileObject.preview = '/api/resource/' + file.id;
          fileObject.id = file.id;
          fileObject.assetType = assetType || null;
          files.push(fileObject);
          setFiles(files);
        });
      });
    }
  };

  return (
    <>
      <FileInput
        removeIcon={removeIcon}
        cropShape={cropShape}
        isOpen={uploadIsOpened}
        setIsOpen={setUploadIsOpened}
        files={files}
        setFiles={setFiles}
        closeDialog={closeUploadModal}
        saveFiles={saveAssets}
        deleteFile={deleteAsset}
        onDeleteAsset={onDeleteAsset}
        filesLimit={filesLimit}
        maxSize={maxSize}
        acceptedFiles={acceptedFiles}
        aspect={aspect}
        imageName={imageName}
      />
      <div onClick={() => setUploadIsOpened(true)}>{children}</div>
    </>
  );
};

const mapDispatchToProps = {
  uploadAssets,
  deleteAsset,
  getAssets,
  showSnackbarMessage,
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(AssetsUpload));
