import { withStyles } from '@material-ui/core/styles';
import LoginForm from './LoginForm';

const styles = theme => ({
  card: {
    width: 320,
    borderRadius: '20px',
    paddingTop: 40,
    paddingBottom: 30,
    paddingLeft: 36,
    paddingRight: 36,
    [theme.breakpoints.only('xs')]: {
      width: 220,
    },
  },
  root: {
    zIndex: '1',
  },
  field: {
    marginTop: 10,
    padding: 0,
    display: 'flex',
    '& > label': {
      color: theme.palette.jobllegroBlue,
    },
  },
  boxTitle: {
    fontWeight: 800,
    fontSize: 22,
  },
  boxText: {
    fontSize: 13,
    color: theme.palette.jobllegroLightGrey,
    paddingBottom: 30,
    '& a': {
      color: theme.palette.jobllegroBlue,
      textDecoration: 'none',
    },
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    padding: '36px 36px',
    [theme.breakpoints.only('xs')]: {
      padding: '36px 0',
    },
  },
  cardContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  link: {
    color: theme.palette.jobllegroLightGrey,
    textDecoration: 'none',
    fontSize: 14,
    '&:hover': {
      color: theme.palette.jobllegroBlue,
    },
  },
  sectionDown: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(LoginForm);
