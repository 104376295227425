import { Grid, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { bool, string } from 'yup';
import { enums } from 'shared/enums';
import { Checkbox, Translate } from 'shared/newComponents';
import Button from 'shared/newComponents/Button';
import PostCode from 'shared/newComponents/PostCode';
import TextField from 'shared/newComponents/TextField';
import { OrganizationService } from 'shared/services';
import { MODEL } from '../modules/project/NewProjectCreateView/components/ProjectCreateWizardStep8/ProjectCreateWizardStep8.utils';

const OrganizationForm = props => {
  let { t, organization = null, organizationAdded = null } = props;

  const isBusinessType = organization
    ? organization.organizationType ===
      enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
    : false;

  const isPersonType = organization
    ? organization.organizationType !==
      enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
    : false;

  const disableEditOrganization = !!organization;

  const formData = {
    name: organization ? organization.name : '',
    description: organization ? organization.description : '',
    percent: organization ? organization.percent : 0,
    isPublic: organization ? organization.isPublic : false,
    invoiceAddress: organization ? organization.invoiceAddress : '',
    invoiceCompanyName: organization ? organization.invoiceCompanyName : '',
    invoiceCity: organization ? organization.invoiceCity : '',
    accountNumber: organization ? organization.accountNumber : '',
    invoicePostCode: organization ? organization.invoicePostCode : '',
    invoiceTypeBusiness:
      !isPersonType && !isBusinessType ? true : isBusinessType,
    invoiceTypeNaturalPerson: isPersonType,
    invoicePersonalNumber: organization
      ? organization.invoicePersonalNumber
      : '',
    invoiceIdentificationNumber: organization
      ? organization.invoiceIdentificationNumber
      : '',
  };
  const validationShema = Yup.object().shape(
    {
      invoiceTypeBusiness: bool(),
      name: Yup.string()
        .strict(false)
        .trim()
        .required(t`organization:validation.name`),
      description: Yup.string()
        .strict(false)
        .trim()
        .required(t`organization:validation.desc`),
      invoiceAddress: Yup.string()
        .strict(false)
        .trim()
        .required(t`organization:validation.invoiceAddress`),
      invoiceCompanyName: Yup.string()
        .strict(false)
        .trim()
        .required(t`organization:validation.invoiceCompanyName`),
      accountNumber: Yup.string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      invoiceIdentificationNumber: string().when('invoiceTypeBusiness', {
        is: invoiceTypeBusiness => {
          return invoiceTypeBusiness === true;
        },

        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoicePersonalNumber: string().when('invoiceTypeBusiness', {
        is: invoiceTypeBusiness => {
          return invoiceTypeBusiness === false;
        },
        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoicePostCode: string().when('organizationToBe', {
        is: organizationToBe => !organizationToBe || !organizationToBe.id,
        then: string()
          .length(
            6,
            t`employmentManagementDialog:dialog2.validation.postalCode.length`
          )
          .matches(
            /\d{2}-\d{3}/,
            t`employmentManagementDialog:dialog2.validation.postalCode.badFormat`
          )
          .required(t`ats2ats3:validation.isRequired`)
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoiceCity: string().when('organizationToBe', {
        is: organizationToBe => !organizationToBe || !organizationToBe.id,
        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      percent: Yup.number()
        .min(0, 'Min 0')
        .max(100, 'Max 100')
        .required(t`organization:validation.percent`),
    },
    [
      ['invoiceTypeBusiness', 'invoiceIdentificationNumber'],
      ['invoiceTypeBusiness', 'invoicePersonalNumber'],
      'invoiceTypeBusiness',
    ]
  );

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationShema,
    onSubmit: (values, actions) => {
      const extraFields = organization
        ? {
            currentSubscriptionType: organization.currentSubscriptionType,
            settlementCurrency: organization.settlementCurrency,
            publicationValue: organization.publicationValue,
            meetingsValue: organization.meetingsValue,
            hiringProc: organization.hiringProc,
            mentorValue: organization.mentorValue,
            warrantyValue: organization.warrantyValue,
            dateStart: organization.dateStart,
            dateStop: organization.dateStop,
            currentSubscriptionTypeDisabled:
              organization.currentSubscriptionTypeDisabled,
            settlementCurrencyDisabled: organization.settlementCurrencyDisabled,
            publicationValueDisabled: organization.publicationValueDisabled,
            meetingsValueDisabled: organization.meetingsValueDisabled,
            hiringProcDisabled: organization.hiringProcDisabled,
            mentorValueDisabled: organization.mentorValueDisabled,
            warrantyValueDisabled: organization.warrantyValueDisabled,
            currentSubscriptionValue: organization.currentSubscriptionValue,
            currentSubscriptionValueDisabled:
              organization.currentSubscriptionValueDisabled,
            individualFinicalPlan: organization.individualFinicalPlan,
          }
        : {};
      const dataRequest = {
        name: values.name,
        description: values.description,
        accountNumber: values.accountNumber,
        percent: values.percent,
        isPublic: values.isPublic,
        invoiceAddress: values.invoiceAddress,
        invoiceCompanyName: values.invoiceCompanyName,
        invoiceCity: values.invoiceCity,
        invoicePostCode: values.invoicePostCode,
        invoicePersonalNumber: values.invoicePersonalNumber,
        invoiceIdentificationNumber: values.invoiceIdentificationNumber,
        organizationType: values.invoiceTypeBusiness
          ? enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
          : enums.ORGANIZATION_TYPES.ORGANIZATION_PERSONAL_TYPE,
      };
      if (!organization) {
        OrganizationService.addOrganization(dataRequest)
          .then(({ data }) => {
            if (organizationAdded) {
              organizationAdded(data);
            } else {
              window.location.reload();
            }
          })
          .catch(error => {
            console.log(error.response);
            if (error.response && error.response.status === 409) {
              return actions.setFieldError(
                'name',
                t('organization:form.nameUsed')
              );
            }
            return actions.setFieldError('name', t('organization:form.error'));
          });
      } else {
        OrganizationService.editOrganization(organization.id, {
          ...dataRequest,
          ...extraFields,
        })
          .then(() => {
            window.location.reload();
          })
          .catch(error => {
            console.log(error.response);
            if (error.response && error.response.status === 409) {
              return actions.setFieldError(
                'name',
                t('organization:form.nameUsed')
              );
            }
            return actions.setFieldError('name', t('organization:form.error'));
          });
      }
    },
  });

  return (
    <Grid container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" component="h2">
              {organization
                ? t('organization:form.editOrganizationData')
                : t('organization:form.createNewOrganization')}
            </Typography>
          </Grid>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate text={t('organization:field.name')} bold variant="h6" />
          </Grid>
          <Grid container>
            <TextField
              label={t('organization:field.name')}
              name={'name'}
              formik={formik}
            />
          </Grid>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate
              text={t('organization:field.description')}
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <TextField
              label={t('organization:field.description')}
              name={'description'}
              formik={formik}
            />
          </Grid>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate
              text={t('organization:field.percent')}
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <TextField
              type={'number'}
              label={t('organization:field.percent')}
              name={'percent'}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Checkbox
              label={t('organization:field.isPublic')}
              name={'isPublic'}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" component="h2">
              {t`organization:invoiceData`}
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={6} sm={6} md={3}>
              <Checkbox
                onChange={event => {
                  if (!event.target.checked || disableEditOrganization) {
                    return;
                  }
                  formik.setFieldValue(MODEL.invoiceTypeBusiness, true);
                  formik.setFieldValue(MODEL.invoiceTypeNaturalPerson, false);
                }}
                label={t('projectCreator:step8.organizationTypeBusiness')}
                name={MODEL.invoiceTypeBusiness}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={5}>
              <Checkbox
                onChange={event => {
                  if (!event.target.checked || disableEditOrganization) {
                    return;
                  }
                  formik.setFieldValue(MODEL.invoiceTypeBusiness, false);
                  formik.setFieldValue(MODEL.invoiceTypeNaturalPerson, true);
                }}
                label={t('projectCreator:step8.organizationTypeNaturalPerson')}
                name={MODEL.invoiceTypeNaturalPerson}
                formik={formik}
              />
            </Grid>
          </Grid>

          {formik.values.invoiceTypeBusiness ? (
            <Grid container>
              <Grid item xs={12} style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.invoiceIdentificationNumber')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('organization:field.invoiceIdentificationNumber')}
                  name={'invoiceIdentificationNumber'}
                  formik={formik}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.invoicePersonalNumber')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('organization:field.invoicePersonalNumber')}
                  name={'invoicePersonalNumber'}
                  formik={formik}
                />
              </Grid>
            </Grid>
          )}

          <Grid container style={{ padding: '0 16px' }}>
            <Translate
              text={t('organization:field.invoiceCompanyName')}
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <TextField
              label={t('organization:field.invoiceCompanyName')}
              name={'invoiceCompanyName'}
              formik={formik}
            />
          </Grid>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate
              text={t('organization:field.invoiceAddress')}
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <TextField
              label={t('organization:field.invoiceAddress')}
              name={'invoiceAddress'}
              formik={formik}
            />
          </Grid>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate
              text={t('organization:field.invoiceCity')}
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <TextField
              label={t('organization:field.invoiceCity')}
              name={'invoiceCity'}
              formik={formik}
            />
          </Grid>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate
              text={t('organization:field.invoicePostCode')}
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <PostCode
              label={t('organization:field.invoicePostCode')}
              name={'invoicePostCode'}
              formik={formik}
            />
          </Grid>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate text={t`organization:bankAcc`} bold variant="h6" />
          </Grid>
          <Grid container>
            <TextField
              label={t`organization:bankAcc`}
              name={'accountNumber'}
              formik={formik}
            />
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={4}>
              <Button
                label={
                  organization
                    ? t('organization:form.editData')
                    : t('organization:form.createOrganization')
                }
                type="submit"
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default connect()(withRouter(withTranslation()(OrganizationForm)));
