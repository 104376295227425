import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { enums } from 'shared/enums';
import {
  TextField,
  Translate,
  Select,
  CheckboxGroup,
  Checkbox,
  WizardStep,
  Hint,
} from 'shared/newComponents';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import {
  getSelectItems,
  getSelectItemsFullObject,
  getSelectItemsFullObjectTranslate,
  getSelectItemsTranslate,
} from 'shared/utils/assets';
import { castObjectForSelect, isProjectClosed } from 'shared/utils/functions';
import {
  stage2NotSetFields,
  useFormStep1,
} from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { MODEL } from './ProjectCreateWizardStep1.utils';

const ProjectCreateWizardStep1 = ({
  countries,
  employmentTypes,
  industryCategories,
  initialProjectValues,
  isStep1Open,
  isProjectSave,
  setIsStep1Open,
  setIsStep2Open,
  isFormStepError,
  isFormStepSaveButtonDisabled,
  closeAll,
  departments,
  t,
  history,
  setStep1FormValues,
  assets,
  step2FormValues,
  setStep7FormValues,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [calcHourSalaryType, setCalcHourSalaryType] = useState(null);
  const [disableTypeOfPay, setDisableTypeOfPay] = useState(false);

  const permissions = get(initialProjectValues, 'permissions') || {};

  const formik = useFormStep1({
    t,
    initialProjectValues,
    history,
    setStep7FormValues,
  });
  const myRef = useRef(null);

  useEffect(() => {
    if (formik.values.salaryType === enums.WORKING_HOURS_KEY.HOURS) {
      setCalcHourSalaryType(
        parseFloat(
          parseFloat(formik.values.maxSalary) *
            parseFloat(formik.values.expectedWorkTime)
        )
      );
    } else {
      setCalcHourSalaryType(null);
    }
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep1FormValues({ ...formik.values, assets });
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values, assets]);

  useEffect(() => {
    if (
      formik.values.employmentTypes.length === 1 &&
      formik.values.employmentTypes[0].name ===
        enums.EMPLOYMENT_TYPE.BUSINESS_TO_BUSINESS
    ) {
      setDisableTypeOfPay(true);
      formik.setFieldValue(MODEL.typeOfPay, enums.TYPE_OF_PAY_KEY.NET);
    } else {
      setDisableTypeOfPay(false);
    }
  }, [formik.values]);

  useEffect(() => {
    if (isStep1Open) {
      executeScroll(myRef);
    }
  }, [isStep1Open]);

  const disableSalaryStage = !initialProjectValues.id
    ? false
    : !(permissions.isOwner || permissions.isSuperUser);

  const translateEmploymentTypes = employmentTypes.map(item => {
    return { ...item, name: item.name };
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <ScrollPoint refForward={myRef} />

      <WizardStep
        title="projectCreator:step1.title"
        withSaveButton={!isProjectClosed(initialProjectValues)}
        required
        isOpen={isStep1Open}
        onClose={() => {
          if (!isFormStepError(formik) && isProjectSave) {
            setIsStep1Open(false);
            const openSecondStep = stage2NotSetFields(
              initialProjectValues,
              step2FormValues
            );
            setIsStep2Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep1Open(true);
        }}
        disableArrowButton={false}
        onCloseSelf={() => {}}
      >
        <Grid container>
          <Grid container style={{ padding: '0 16px' }}>
            <Translate text="projectCreator:step1.position" bold variant="h6" />
          </Grid>
          <Grid container>
            <TextField
              label={t('projectCreator:step1.positionInput')}
              name={MODEL.position}
              formik={formik}
            />
          </Grid>
          <Grid container>
            <Select
              formik={formik}
              name={MODEL.calcCandidateExperience}
              label={t('projectCreator:step7.calcCandidateExperience')}
              items={getSelectItemsTranslate(
                enums.CALC_CANDIDATE_EXPERIENCE,
                t
              )}
              projectWizard
            />
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label={t('projectCreator:step1.city')}
                name={MODEL.city}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Checkbox
                label={t('projectCreator:step1.remoteWork')}
                name={MODEL.isRemoteWork}
                formik={formik}
                hintContent={
                  <div style={{ fontSize: 18 }}>
                    {t('projectCreator:step1.remoteWorkHint')}
                  </div>
                }
              />
            </Grid>
          </Grid>
          <Grid container style={{ padding: '32px 16px 0 16px' }}>
            <Translate text="projectCreator:step1.company" bold variant="h6" />
          </Grid>
          <Grid container>
            <TextField
              label={t('projectCreator:step1.companyInput')}
              name={MODEL.client}
              formik={formik}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <MultiSelect
                options={castObjectForSelect(countries)}
                label={t('projectCreator:step1.country')}
                name={MODEL.country}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MultiSelect
                options={castObjectForSelect(industryCategories)}
                label={t('projectCreator:step1.industry')}
                name={MODEL.industryCategory}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MultiSelect
                options={castObjectForSelect(departments)}
                label={t('candidateCreator:step1.department')}
                name={MODEL.department}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Select
                items={getSelectItemsFullObject(enums.COMPANY_SIZE)}
                label={t('projectCreator:step1.companySize')}
                name={MODEL.companySize}
                formik={formik}
                projectWizard
              />
            </Grid>
          </Grid>
          <Grid container>
            <TextField
              label={t('projectCreator:step1.websiteAddress')}
              name={MODEL.clientUrl}
              formik={formik}
            />
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={6}>
              <Checkbox
                label={t('projectCreator:step1.companyDatePublic')}
                name={MODEL.isClientPublic}
                formik={formik}
                type="switch"
                hintContent={
                  <div style={{ fontSize: 18 }}>
                    {t('projectCreator:step1.remoteWorkHintcompanyDatePublic')}
                  </div>
                }
              />
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: 32 }}>
            <CheckboxGroup
              items={translateEmploymentTypes}
              name={MODEL.employmentTypes}
              label={t('projectCreator:step1.employmentType')}
              formik={formik}
              variant="button"
              iterateFrom={1}
            />
          </Grid>
          <Grid container style={{ padding: '42px 16px 0 16px' }}>
            <Translate
              text="projectCreator:step1.workHourType"
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                disabled={true}
                items={[
                  { id: 0, name: t('projectCreator:workHoursSelect.monthly') },
                  // { id: 1, name: t("projectCreator:workHoursSelect.weekly") },
                  // { id: 2, name: t("projectCreator:workHoursSelect.daily") },
                  // { id: 3, name: t("projectCreator:workHoursSelect.fullTime") },
                  // { id: 4, name: t("projectCreator:workHoursSelect.partTime") },
                ]}
                onChange={e => {
                  formik.setFieldValue(MODEL.workHourType, e.target.value);
                  if (e.target.value > 2) {
                    formik.setFieldValue(MODEL.expectedWorkTime, '');
                  }
                }}
                name={MODEL.workHourType}
                label="Wymiar"
                formik={formik}
                projectWizard
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                type={'number'}
                disabled={formik.values.workHourType > 2}
                name={MODEL.expectedWorkTime}
                formik={formik}
                label={t('projectCreator:step1.expectedWorkTime')}
              />
            </Grid>
          </Grid>
          <Grid container style={{ padding: '32px 16px 0 16px' }}>
            <Translate text="projectCreator:step1.salary" variant="h6" bold />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item style={{ width: '100%' }}>
                  <TextField
                    disabled={disableSalaryStage}
                    label={t('projectCreator:step1.minSalary')}
                    name={MODEL.minSalary}
                    formik={formik}
                    type="number"
                  />
                </Grid>
                <Grid item>
                  <Translate text="-" variant="h4" color="textSecondary" />
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <TextField
                    disabled={disableSalaryStage}
                    name={MODEL.maxSalary}
                    formik={formik}
                    label={t('projectCreator:step1.maxSalary')}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Select
                disabled={disableTypeOfPay}
                label={t('projectCreator:step1.typeOfPay')}
                name={MODEL.typeOfPay}
                formik={formik}
                items={getSelectItemsFullObjectTranslate(enums.TYPE_OF_PAY, t)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Select
                disabled={disableSalaryStage}
                label={t('projectCreator:step1.currency')}
                name={MODEL.currency}
                formik={formik}
                items={getSelectItems(enums.CURRENCY)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                disabled={disableSalaryStage}
                items={getSelectItemsFullObjectTranslate(
                  enums.WORKING_HOURS,
                  t
                )}
                name={MODEL.salaryType}
                label={t('projectCreator:step1.salaryType')}
                formik={formik}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                padding: '32px 16px 0px',
              }}
            >
              <Grid item xs={6} sm={6} md={6}>
                <Checkbox
                  label={t('projectCreator:step1.isSalaryPublic')}
                  name={MODEL.isSalaryPublic}
                  formik={formik}
                  type="switch"
                  hintContent={
                    <div style={{ fontSize: 18 }}>
                      {t('projectCreator:step1.isSalaryPublicHint')}
                    </div>
                  }
                />
              </Grid>
              {calcHourSalaryType ? (
                <Grid item xs={6} sm={6} md={6}>
                  <div>
                    <Translate
                      style={{ fontSize: 16 }}
                      disableTranslate={true}
                      text={`${t(
                        'projectCreator:step1.expectSalaryCalc'
                      )} - ${calcHourSalaryType}`}
                      variant="body1"
                    />
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid container style={{ padding: '32px 16px 0 16px' }}>
            <Translate
              text="projectCreator:step1.employmentStartDate"
              variant="h6"
              bold
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name={MODEL.employmentStartDate}
                type="date"
                formik={formik}
              />
            </Grid>
          </Grid>
          <Grid container style={{ padding: '32px 16px 0 16px' }}>
            <Hint
              content={
                <div style={{ fontSize: 18 }}>
                  <p>{t('projectCreator:step1.projectTypeHint1')}</p>
                  <p>{t('projectCreator:step1.projectTypeHint2')}</p>
                  <p>{t('projectCreator:step1.projectTypeHint3')}</p>
                </div>
              }
              style={{ fontSize: '1.25rem', fontWeight: 'bold' }}
              hintLabel={t('projectCreator:step1.projectType')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Select
              items={getSelectItemsFullObjectTranslate(
                enums.VISIBILITY_PROJECT_TYPE,
                t
              )}
              label={t('project:field.isPublicLong')}
              name={MODEL.visibility}
              formik={formik}
            />
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default ProjectCreateWizardStep1;
