export const styles = ({ breakpoints }) => ({
  wrapper: {
    padding: '16px 150px',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  sideWrapper: {
    padding: 12,
  },
});
