import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getConfigs } from 'shared/services/configService';
import Landing from './Landing';

const mapDispatchToProps = {
  getConfigs,
};

export default connect(null, mapDispatchToProps)(withRouter(Landing));
