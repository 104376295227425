import {
  Avatar,
  Grid,
  Card,
  CardHeader,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Add as AddIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getUserAvatarSrc } from 'shared/utils/assets';

const styles = theme => ({
  card: {
    width: '100%',
    height: 'auto',
    margin: 0,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  },
  cardHeader: {
    paddingTop: '0px',
  },
  avatar: {
    width: 70,
    height: 70,
  },
  cardContent: {
    paddingBottom: '0px !important',
  },
  scelletonName: {
    width: '100%',
    height: 15,
    background: '#eee',
    marginTop: 10,
    marginBottom: 10,
  },
  addIcon: {
    color: theme.palette.jobllegroBlue,
    fontSize: 30,
  },
  avatarEmpty: {
    width: 70,
    height: 70,
    background: '#eee',
    transition: 'all .4s',
    '&:hover': {
      background: '#f2f2f2',
    },
  },
  rootBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 290,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: 100,
    },
  },
});

export class CurrentCandidateBox extends Component {
  render() {
    const { classes, t, currentUser = {} } = this.props;
    const { owner = {} } = currentUser;
    const avatar = getUserAvatarSrc(currentUser);
    const avatarOwner = getUserAvatarSrc(owner);

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rootBox}>
          <Typography
            align="center"
            style={{
              fontSize: 18,
              fontWeight: 400,
              padding: '20px 20px 0px 20px',
            }}
          >
            {t('dashboard:candidateProfileTable.welcomeMessage', currentUser)}
          </Typography>
          <Card className={classes.card}>
            <Typography color="textSecondary" align="center">
              {t('dashboard:candidateProfileTable.yourProfile')}
            </Typography>
            <CardHeader
              className={classes.cardHeader}
              avatar={
                <Avatar
                  aria-label="Recipe"
                  className={classes.avatar}
                  src={avatar}
                />
              }
              action={
                <IconButton component={Link} to={PATHS.USER.INDEX}>
                  <MoreVertIcon />
                </IconButton>
              }
              title={currentUser.firstName + ' ' + currentUser.lastName}
              subheader={currentUser.position}
            />
          </Card>
          {owner ? (
            <Card className={classes.card}>
              <Typography color="textSecondary" align="center">
                {t('dashboard:candidateProfileTable.yourRecruiter')}
              </Typography>
              <CardHeader
                className={classes.cardHeader}
                avatar={
                  <Avatar
                    aria-label="Recipe"
                    className={classes.avatar}
                    src={avatarOwner}
                  />
                }
                action={
                  <IconButton
                    component={Link}
                    to={PATHS.RECRUITER.SHOW.replace(':id', owner.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                title={owner.firstName + ' ' + owner.lastName}
                subheader={currentUser.industry}
              />
            </Card>
          ) : (
            <Card className={classes.card}>
              <Typography color="textSecondary" align="center">
                {t('dashboard:candidateProfileTable.noOwner')}
              </Typography>
              <CardHeader
                className={classes.cardHeader}
                avatar={
                  <Avatar
                    className={classes.avatarEmpty}
                    component={Link}
                    to={PATHS.RECRUITER.INDEX}
                  >
                    <AddIcon className={classes.addIcon} />
                  </Avatar>
                }
                title={<div className={classes.scelletonName} />}
                subheader={
                  <div
                    style={{ width: '50%' }}
                    className={classes.scelletonName}
                  />
                }
              />
            </Card>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

CurrentCandidateBox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(CurrentCandidateBox)));
