import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { filter, find, get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MODEL } from '../../modules/project/NewProjectCreateView/components/ProjectCreateWizardStep2/ProjectCreateWizardStep2.utils';
import { getAvailabilityDiffDayToDay } from '../../modules/project/ProjectAts/utils/utils';
import { API_URL_RESOURCE } from '../constants';
import { enums } from '../enums';
import { getEnumItemNameFullObject } from './assets';

export const castObjectPhoneCodesForSelect = items => {
  return items.map(suggestion => ({
    id: suggestion.dialCode,
    value: suggestion.dialCode,
    label: suggestion.dialCode,
  }));
};

export const getHiringManagerInProject = (projectHiringManagers, recruiter) => {
  let hiringManagerResult = null;
  if (!recruiter) {
    return null;
  }
  projectHiringManagers.forEach(hiringManager => {
    if (hiringManager.recruiter.id === recruiter.id) {
      hiringManagerResult = {
        ...hiringManager.recruiter,
        hiringManagerStatus: hiringManager.confirmationStatus,
      };
    }
  });
  return hiringManagerResult;
};

export const getMentorInProject = (projectMentors, recruiter) => {
  let mentorResult = null;
  if (!recruiter) {
    return null;
  }
  projectMentors.forEach(mentor => {
    if (mentor.recruiter.id === recruiter.id) {
      mentorResult = {
        ...mentor.recruiter,
        mentorStatus: mentor.confirmationStatus,
      };
    }
  });
  return mentorResult;
};
export const castObjectForSelect = items => {
  return items.map(suggestion => ({
    id: suggestion.id,
    value: suggestion.id,
    label: suggestion.name,
  }));
};

export const castLocalizationObjectForSelect = items => {
  return items.map(suggestion => ({
    id: suggestion.id,
    value: suggestion.id,
    label: `${suggestion.client} / ${suggestion.city} / ${suggestion.address} / ${suggestion.postCode} `,
  }));
};

export const birthDayObjectForSelect = () => {
  const birthDayArray = [];
  const currentYear = parseInt(moment().format('YYYY'));
  for (let i = currentYear; i >= currentYear - 70; i--) {
    birthDayArray.push({ id: i, name: i.toString() });
  }
  return birthDayArray;
};

export const getCompetenceHeaderInfo = (
  projectDetails,
  index,
  t,
  fieldName = MODEL.projectSkills,
  experienceFieldName = MODEL.experienceLevel
) => {
  const skillName = get(
    projectDetails,
    `${fieldName}[${index}].${MODEL.skill}.name`
  );

  const competenceTypeName = get(
    projectDetails,
    `${fieldName}[${index}].${MODEL.competenceType}.name`
  );

  const experience = get(
    projectDetails,
    `${fieldName}[${index}]${experienceFieldName}`
  );

  const secondHeaderInfo = getEnumItemNameFullObject(
    enums.EXPERIENCE_LEVEL,
    experience
  );

  return [competenceTypeName, skillName, t(secondHeaderInfo)].join(' | ');
};

export const isProjectClosed = projectDetails => {
  return get(projectDetails, 'closedStatus') === 1;
};

export const styledMui = Component => {
  return (style, options) => {
    function StyledComponent(props) {
      const { classes, className, ...other } = props;
      return (
        <Component className={classNames(classes.root, className)} {...other} />
      );
    }

    StyledComponent.propTypes = {
      classes: PropTypes.object.isRequired,
      className: PropTypes.string,
    };
    const styles =
      typeof style === 'function'
        ? theme => ({ root: style(theme) })
        : { root: style };
    return withStyles(styles, options)(StyledComponent);
  };
};

export const getNotificationsByCategory = (notifications, category) => {
  const notificationsResult = filter(notifications, {
    category: category,
  });
  return notificationsResult;
};

export const getNotificationsByCategories = notifications => {
  const notificationsResult = filter(notifications, record => {
    return (
      record.category === 'project' ||
      record.category === 'projectOrder' ||
      record.category === 'candidate' ||
      record.category === 'mentor'
    );
  });
  return notificationsResult;
};

export const getLanguagesLevel = idLevel => {
  const languagesLevel = [
    { id: 1, name: 'A1' },
    { id: 2, name: 'A2' },
    { id: 3, name: 'B1' },
    { id: 4, name: 'B2' },
    { id: 5, name: 'C1' },
    { id: 6, name: 'C2' },
    { id: 7, name: 'Język ojczysty' },
  ];

  const languagesResult = find(languagesLevel, { id: idLevel });
  return languagesResult.name;
};

export const getRecrutationServicesSelectedOption = (type, value) => {
  const data = {
    calcCandidateExperience: 'project:field.calcCandidateExperienceOpt',
    calcCandidateWarranty: 'project:field.calcCandidateWarrantyOpt',
    calcNumberOfRecomendations: 'project:field.calcNumberOfRecommendationsOpt',
    calcPaymentDeadline: 'project:field.calcPaymentDeadlineOpt',
    calcSearchTime: 'project:field.calcSearchTimeOpt',
    calcServiceCompletionDate: 'project:field.calcServiceCompletionDateOpt',
    calcUpfrontPayment: 'project:field.calcUpfrontPaymentOpt',
  };
  return data[type] + value;
};

export const getMentorStatusInProject = value => {
  const data = {
    0: 'project:field.projectMentorStatus0',
    1: 'project:field.projectMentorStatus1',
    2: 'project:field.projectMentorStatus2',
  };
  return data[value];
};

export const getHiringManagerStatusInProject = value => {
  const data = {
    0: 'project:field.projectHmStatus0',
    1: 'project:field.projectHmStatus1',
    2: 'project:field.projectHmStatus2',
  };
  return data[value];
};

export const checkIfRecruitersCoAreInProject = recruitersList => {
  return filter(recruitersList, function (o) {
    return (
      o.confirmationStatus === 1 ||
      o.confirmationStatus === 2 ||
      o.confirmationStatus === 4 ||
      o.confirmationStatus === 5
    );
  });
};

export const checkCandidateFirstNameLastName = (firstName, lastName, id, t) => {
  return firstName === null || lastName === null
    ? `${t('candidate:core.candidate')} #` + id
    : firstName + ' ' + lastName;
};

export const getSimilaritiesScore = similarities => {
  return similarities === null ? '' : `${similarities} %`;
};

export const checkProjectEditPermissions = ({ permissions }) => {
  return !(
    permissions.isOwner ||
    permissions.isMentor ||
    permissions.isSuperUser
  );
};

export const checkCandidateEditPermissions = ({ permissions }) => {
  return !(permissions.isOwner || permissions.isSuperUser);
};

export const preventEnterKey = keyEvent => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

export const hasAssets = assets => {
  const asset = assets.find(asset => asset.type === 'industryCategoryLogo');
  return !!asset;
};

export const getAssetUrl = assets => {
  const asset = assets.find(asset => asset.type === 'industryCategoryLogo');
  if (!asset) {
    return null;
  }
  return `${API_URL_RESOURCE}${asset.id}`;
};

export const getLevelProc = (candidateProject, t) => {
  const candidateIsRejected = candidateProject.rejected === 1;
  const candidateStageLevel = (candidateProject.stageNumber * 100) / 6;
  switch (candidateProject.stageNumber) {
    case 1:
      return {
        levelProc: candidateStageLevel,
        desc: !candidateIsRejected
          ? t('candidateDetails:allProjects.stage1Approved')
          : t('candidateDetails:allProjects.stage1Rejected'),
      };
    case 2:
      return {
        levelProc: candidateStageLevel,
        desc: !candidateIsRejected
          ? t('candidateDetails:allProjects.stage2Approved')
          : t('candidateDetails:allProjects.stage2Rejected'),
      };
    case 3:
      return {
        levelProc: candidateStageLevel,
        desc: !candidateIsRejected
          ? t('candidateDetails:allProjects.stage3Approved')
          : t('candidateDetails:allProjects.stage3Rejected'),
      };
    case 4:
      return {
        levelProc: candidateStageLevel,
        desc: !candidateIsRejected
          ? t('candidateDetails:allProjects.stage4Approved')
          : t('candidateDetails:allProjects.stage4Rejected'),
      };
    case 5:
      return {
        levelProc: candidateStageLevel,
        desc: !candidateIsRejected
          ? t('candidateDetails:allProjects.stage5Approved')
          : t('candidateDetails:allProjects.stage5Rejected'),
      };
    case 6:
      return {
        levelProc: candidateStageLevel,
        desc: !candidateIsRejected
          ? t('candidateDetails:allProjects.stage6Approved')
          : t('candidateDetails:allProjects.stage6Rejected'),
      };
  }
};

export const getLocalizationData = localization => {
  if (!localization) {
    return '';
  }
  return `${localization.city} ${localization.address} ${localization.postCode}`;
};

export const getAccommodationDataSelect = accommodations => {
  return accommodations.map(item => {
    return {
      id: item.id,
      name: `${item.name || ''} ${item.city} ${item.address} ${item.postCode}`,
    };
  });
};

export const isShorForm = employmentTypeRequest =>
  enums.EMPLOYMENT_TYPE_REQUEST_SHORT_FORM.includes(
    parseInt(employmentTypeRequest)
  );

export const isShorVisaForm = visaType =>
  enums.VISA_REQUEST_SHORT_FORM.includes(parseInt(visaType));

export const isBiometricPassport = visaType =>
  enums.VISA_REQUEST_BIOMETRIC_PASSPORT.includes(parseInt(visaType));

export const getSelectedAccommodation = (accommodationId, accommodations) => {
  if (!accommodationId) {
    return null;
  }
  return accommodations.find(item => item.id === accommodationId);
};

export const getTargetDate = candidate => {
  if (!candidate.userEmploymentProcess) {
    return {
      targetStartDate: moment(),
      targetEndDate: moment(),
    };
  }
  const BdocumentStartDate = candidate.userEmploymentProcess.documentStartDate;
  const BdocumentEndDate = candidate.userEmploymentProcess.documentEndDate;

  const ArequestedEmploymentStartDate =
    candidate.userEmploymentProcess.requestedEmploymentStartDate;
  const ArequestedEmploymentEndDate =
    candidate.userEmploymentProcess.requestedEmploymentEndDate;

  const CestimatedStartEmploymentDate =
    candidate.userEmploymentProcess.estimatedStartEmploymentDate;
  const CestimatedEndEmploymentDate =
    candidate.userEmploymentProcess.estimatedEndEmploymentDate;

  if (CestimatedStartEmploymentDate && CestimatedEndEmploymentDate) {
    return {
      targetStartDate: CestimatedStartEmploymentDate,
      targetEndDate: CestimatedEndEmploymentDate,
    };
  }
  if (BdocumentStartDate && BdocumentEndDate) {
    return {
      targetStartDate: BdocumentStartDate,
      targetEndDate: BdocumentEndDate,
    };
  }
  return {
    targetStartDate: ArequestedEmploymentStartDate,
    targetEndDate: ArequestedEmploymentEndDate,
  };
};

export const getAccommodations = accommodations => {
  return accommodations.filter(
    accommodation =>
      accommodation.type === enums.ACCOMMODATION_TYPES.ACCOMMODATION
  );
};

export const getDocumentInfo = (
  fileType,
  candidateDetails,
  candidateEmploymentHistory
) => {
  if (
    [
      'access-type-oopp',
      'access-type-a',
      'access-type-residenceCardApplicationSubmitted',
      'access-type-c',
      'access-type-d',
      'access-type-e',
      'access-type-s',
      'access-type-residenceCardNoJobMarket',
      'access-type-non-content',
    ].includes(fileType)
  ) {
    if (candidateDetails.userEmploymentProcess) {
      return {
        title: `${
          candidateDetails.userEmploymentProcess.documentStartDate || ''
        } - ${candidateDetails.userEmploymentProcess.documentEndDate || ''}`,
        diff: candidateDetails.userEmploymentProcess.documentEndDate
          ? getAvailabilityDiffDayToDay(
              candidateDetails.userEmploymentProcess.documentEndDate
            )
          : '',
      };
    }
  }
  if (fileType === 'visa') {
    if (candidateDetails.userEmploymentProcess) {
      return {
        title: `${candidateDetails.userEmploymentProcess.visaDate || ''}`,
        diff: candidateDetails.userEmploymentProcess.visaDate
          ? getAvailabilityDiffDayToDay(
              candidateDetails.userEmploymentProcess.visaDate
            )
          : '',
      };
    }
  }

  if (fileType === 'assignContractScan') {
    if (candidateEmploymentHistory) {
      return {
        title: `${candidateEmploymentHistory.employmentStartDate || ''} - ${
          candidateEmploymentHistory.employmentEndDate || ''
        }`,
        diff: candidateEmploymentHistory.employmentEndDate
          ? getAvailabilityDiffDayToDay(
              candidateEmploymentHistory.employmentEndDate
            )
          : '',
      };
    }
  }

  if (
    [
      'passportStampScan',
      'passportVisaScan',
      'passportPersonalDataScan',
    ].includes(fileType)
  ) {
    if (candidateEmploymentHistory) {
      return {
        title: `${candidateEmploymentHistory.corsBorderDate || ''}`,
        diff: candidateEmploymentHistory.corsBorderDate
          ? getAvailabilityDiffDayToDay(
              candidateEmploymentHistory.corsBorderDate
            )
          : '',
      };
    }
  }
  return {
    title: '',
    diff: '',
  };
};

export const getOptionsDisabled = value => {
  const {
    EMPLOYMENT_STATUS_WAIT_FOR_ARRIVAL,
    EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY,
    EMPLOYMENT_STATUS_QUARANTINE,
    EMPLOYMENT_STATUS_CONFIRM_EMPLOY,
    EMPLOYMENT_STATUS_EMPLOY,
    EMPLOYMENT_STATUS_BREAK,
    EMPLOYMENT_STATUS_RESIGNATION,
    EMPLOYMENT_STATUS_ILLNESS,
    EMPLOYMENT_STATUS_EMPLOY_END,
    EMPLOYMENT_STATUS_DEREGISTERING,
  } = enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS;

  const statuses = [
    EMPLOYMENT_STATUS_WAIT_FOR_ARRIVAL,
    EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY,
    EMPLOYMENT_STATUS_QUARANTINE,
    EMPLOYMENT_STATUS_CONFIRM_EMPLOY,
    EMPLOYMENT_STATUS_EMPLOY,
    EMPLOYMENT_STATUS_BREAK,
    EMPLOYMENT_STATUS_RESIGNATION,
    EMPLOYMENT_STATUS_ILLNESS,
    EMPLOYMENT_STATUS_EMPLOY_END,
    EMPLOYMENT_STATUS_DEREGISTERING,
  ];

  const obj = {
    EMPLOYMENT_STATUS_WAIT_FOR_ARRIVAL: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY_END,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY_END,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_QUARANTINE,
          EMPLOYMENT_STATUS_CONFIRM_EMPLOY,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_QUARANTINE: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY_END,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_CONFIRM_EMPLOY,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_CONFIRM_EMPLOY: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY_END,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_EMPLOY,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_EMPLOY: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY,
          EMPLOYMENT_STATUS_BREAK,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_ILLNESS,
          EMPLOYMENT_STATUS_EMPLOY_END,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_BREAK: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY,
          EMPLOYMENT_STATUS_BREAK,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_ILLNESS,
          EMPLOYMENT_STATUS_EMPLOY_END,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_RESIGNATION: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY,
          EMPLOYMENT_STATUS_BREAK,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_ILLNESS,
          EMPLOYMENT_STATUS_EMPLOY_END,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_ILLNESS: statuses.filter(
      i =>
        ![
          EMPLOYMENT_STATUS_EMPLOY,
          EMPLOYMENT_STATUS_BREAK,
          EMPLOYMENT_STATUS_RESIGNATION,
          EMPLOYMENT_STATUS_ILLNESS,
          EMPLOYMENT_STATUS_EMPLOY_END,
        ].includes(i)
    ),
    EMPLOYMENT_STATUS_EMPLOY_END: statuses.filter(
      i => ![EMPLOYMENT_STATUS_EMPLOY, EMPLOYMENT_STATUS_EMPLOY_END].includes(i)
    ),
    EMPLOYMENT_STATUS_DEREGISTERING: [],
  };
  return obj[Object.keys(enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS)[value]];
};

export const isPermissionToSaveEmploymentManagements = (
  currentEmploymentStatus,
  project
) => {
  const { permissions = {} } = project;
  const {
    isOwner = false,
    isMentor = false,
    isSuperUser = false,
    isProjectOrganizationOwner = false,
    isMentorCoordinator = false,
  } = permissions;

  if (isOwner || isProjectOrganizationOwner || isSuperUser) {
    return true;
  }

  const permissionObj = {
    EMPLOYMENT_STATUS_WAIT_FOR_ARRIVAL: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_QUARANTINE: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_CONFIRM_EMPLOY: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_EMPLOY: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_BREAK: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_RESIGNATION: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_ILLNESS: {
      permission: isMentor && isMentorCoordinator,
    },
    EMPLOYMENT_STATUS_EMPLOY_END: {
      permission: isMentor && isMentorCoordinator,
    },
  };
  if (!permissionObj[Object.keys(permissionObj)[currentEmploymentStatus]]) {
    return false;
  }
  return permissionObj[Object.keys(permissionObj)[currentEmploymentStatus]]
    .permission;
};

export const getAvailableProject = projectAvailableToMove => {
  return projectAvailableToMove.map(item => {
    return {
      id: item.id,
      name: `${item.position} / ${item.id}`,
    };
  });
};

export const getAvailableProjectOrders = (
  projectId,
  projectAvailableToMove
) => {
  const project = projectAvailableToMove.find(
    project => project.id === projectId
  );
  if (!project || !project.projectOrders) {
    return [];
  }

  return project.projectOrders.map(projectOrder => {
    return {
      id: projectOrder.id,
      name: `#${project.id}/${projectOrder.id} ${projectOrder.createdAt} ${projectOrder.owner.firstName} ${projectOrder.owner.lastName}`,
    };
  });
};

export const getClockDate = (candidate, stageNumber) => {
  const { userEmploymentProcess } = candidate;
  switch (stageNumber) {
    case 2: {
      if (isShorForm(userEmploymentProcess.employmentTypeRequest)) {
        return userEmploymentProcess.estimatedStartEmploymentDate;
      }
      return userEmploymentProcess.requestedEmploymentStartDate;
    }
    case 3:
      return userEmploymentProcess.documentStartDate;
    case 4:
      return userEmploymentProcess.residenceTitleEstimatedDate;
    case 5: {
      if (isShorVisaForm(userEmploymentProcess.residenceTitle)) {
        return userEmploymentProcess.requestedEmploymentStartDate;
      }
      return userEmploymentProcess.estimatedStartEmploymentDate;
    }
    default: {
      return candidate.availabilityDate;
    }
  }
};
