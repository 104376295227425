export const styles = () => ({
  list: {},
  title: {
    fontSize: '1em',
    paddingBottom: '5px',
  },
  listItem: {
    display: 'flex',
    gap: '15px',
    paddingBottom: '10px',
  },
});
