import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  deleteAsset,
  getAssets,
  uploadCsv,
} from 'shared/services/assetService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import FileInput from '../../components/asset/fileinput/FileInput';

class CsvUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploadIsOpened: props.opened || false,
    };
  }

  openUploadModal = () => {
    this.setState({
      uploadIsOpened: true,
    });
  };

  closeUploadModal = () => {
    this.props.closeHandler && this.props.closeHandler();
    this.setState({
      uploadIsOpened: false,
    });
  };

  saveCsv = async files => {
    const { t, uploadCsv, reloadData } = this.props;
    try {
      await uploadCsv(files, 'RECRUITER_RATING');
      this.setState({
        uploadIsOpened: false,
      });
      this.props.showSnackbarMessage(t('core:form:info.filesUploadSuccess'));
      reloadData();
    } catch (error) {
      this.setState({
        uploadIsOpened: false,
      });
      this.props.showSnackbarMessage('Error');
    }
  };

  render() {
    const {
      children,
      filesLimit = 4,
      maxSize = 5000000,
      acceptedFiles,
    } = this.props;
    const { uploadIsOpened, files } = this.state;

    return (
      <React.Fragment>
        <FileInput
          open={uploadIsOpened}
          files={files}
          closeDialog={this.closeUploadModal}
          saveFiles={this.saveCsv}
          deleteFile={() => {}}
          filesLimit={filesLimit}
          maxSize={maxSize}
          acceptedFiles={acceptedFiles}
        />
        <div onClick={this.openUploadModal}>{children}</div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  deleteAsset,
  getAssets,
  uploadCsv,
  showSnackbarMessage,
};

CsvUpload.propTypes = {
  assetType: PropTypes.string,
  filesLimit: PropTypes.number,
  maxSize: PropTypes.number,
  getAssetsHandler: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(withTranslation()(CsvUpload));
