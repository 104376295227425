import PropTypes from 'prop-types';
import React from 'react';
import FacebookLoginAPI from 'react-facebook-login/dist/facebook-login-render-props';
import { withTranslation } from 'react-i18next';
import { CONST_CONFIG } from 'shared/constants';
import SocialLoginButton from '../SocialLoginButton';

const FacebookLogin = ({ text, onSuccess, t }) => {
  return (
    <FacebookLoginAPI
      appId={CONST_CONFIG.FACEBOOK.FACEBOOK_CLIENT_ID}
      language="pl_PL"
      isMobile={false}
      callback={response => {
        const data = {
          accessToken: response.accessToken,
          type: CONST_CONFIG.FACEBOOK.LOGIN_FACEBOOK_API,
        };
        return response.name ? onSuccess(data) : null;
      }}
      render={({ onClick, disabled }) => (
        <SocialLoginButton
          brand="facebook"
          onClick={onClick}
          disabled={disabled}
          label={text ? text : t`core:facebook`}
        />
      )}
    />
  );
};

FacebookLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  text: PropTypes.string,
};

FacebookLogin.displayName = 'FacebookLogin';

export default withTranslation()(FacebookLogin);
