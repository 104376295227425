import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateHomeCountry = ({ candidate, t }) => {
  const isNewCreatorCandidate = candidate && candidate.currentStage > 1;
  const homeCountry = candidate.homeCountry ? candidate.homeCountry.name : null;

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Translate
        bold
        style={{
          fontSize: 20,
        }}
        text={'candidateCreator:reviewStep2.homeCountry'}
      />
      <Grid item>
        {isNewCreatorCandidate ? (
          <Translate
            style={{
              fontSize: 16,
            }}
            text={homeCountry ? homeCountry : 'core:general.noInformation'}
            disableTranslate={homeCountry}
          />
        ) : (
          <Translate
            style={{
              fontSize: 16,
            }}
            text={
              candidate.nationality
                ? candidate.nationality
                : 'core:general.noInformation'
            }
            disableTranslate={candidate.nationality}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateHomeCountry))));
