import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { CandidateV2Service } from 'shared/services';
import { getLanguageSkill } from '../NewCandidateCreateView/components/CandidateCreateWizard/CandidateCreateWizard.utils';
import CandidatePersonalDetailsContent from './components/CandidatePersonalDetailsContent';

const CandidateAnonymousDetailsView = ({ match, history, t, ...props }) => {
  const [candidate, setCandidate] = useState({});
  const [fetchDone, setFetchDone] = useState(false);

  const getCandidateById = id => {
    CandidateV2Service.getCandidateV2(id)
      .then(({ data }) => {
        setCandidate({
          ...data,
          ...getLanguageSkill(data.userSkills),
        });
        if (!data.email) {
          history.push(PATHS.CANDIDATE.SHOW.replace(':id', data.id));
        }
        setFetchDone(true);
      })
      .catch(() => {
        //TODO: redirect to 404
        alert(t`candidate:dataError`);
      });
  };

  useEffect(() => {
    shouldUpdateCandidate();
  }, []);

  const shouldUpdateCandidate = () => {
    const { id } = match.params;

    if (!id) {
      getCandidateById(props.currentUser.id);
    } else {
      getCandidateById(id);
    }
  };

  return fetchDone ? (
    <CandidatePersonalDetailsContent
      candidateDetails={candidate}
      currentUser={props.currentUser}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(mapStateToProps)(
  withRouter(withTranslation()(CandidateAnonymousDetailsView))
);
