import {
  Avatar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getProjectRecruitersFilteredForAdding,
  changeCandidateOwnerInProject,
} from 'shared/services/projectService';
import { clearCurrentRecruitersList } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getUserAvatarSrc } from 'shared/utils/assets';
import RecruiterInProjectList from '../modules/recruiter/RecruiterList/RecruiterInProjectList';

const styles = () => ({
  label: {
    textTransform: 'capitalize',
    fontSize: '0.940rem',
    justifyContent: 'left',
  },
  card: {
    width: '100%',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  },
  title: {
    marginBottom: 16,
    fontSize: 18,
  },
  titleTop: {
    marginBottom: 16,
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
});

export class ProjectAtsCandidateInProjectSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectCandidateOwner: {},
    };
  }

  loadData = async id => {
    this.props.clearCurrentRecruitersList();
    this.props.getProjectRecruitersFilteredForAdding(id, true);
  };

  changeCandidateOwner = async (projectId, ownerId, candidateId) => {
    const { t } = this.props;
    try {
      const changeOwnerAction = await this.props.changeCandidateOwnerInProject(
        projectId,
        ownerId,
        candidateId
      );
      const { owner = {} } = changeOwnerAction.data;
      this.setState({ projectCandidateOwner: owner });
      this.props.reloadAtsHandler();
      this.props.showSnackbarMessage(
        t`project:ownerIs` + owner.firstName + ' ' + owner.lastName
      );
    } catch (err) {
      console.error(err);
    }
  };

  async componentDidMount() {
    const { ownerCandidate } = this.props;
    this.loadData(this.props.projectId);
    this.setState({ projectCandidateOwner: ownerCandidate });
  }

  render() {
    const {
      classes,
      candidate,
      recruitersInProject,
      currentUser,
      currentProject,
      t,
    } = this.props;

    const { projectCandidateOwner } = this.state;
    const { firstName = {}, lastName = {} } = projectCandidateOwner;

    const { owner } = candidate;
    const avatar = getUserAvatarSrc(candidate);
    const avatarOwner = getUserAvatarSrc(owner);
    const avatarOwnerCandidate = getUserAvatarSrc(projectCandidateOwner);
    let recruiterListProps = '';
    if (currentProject.permissions.isOwner === false) {
      recruiterListProps = recruitersInProject;
    } else {
      recruiterListProps = recruitersInProject.concat({
        recruiter: currentUser || {},
        project: currentProject || {},
      });
    }
    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Card className={classes.card}>
              <CardContent style={{ borderBottom: '1px solid #eaeaea' }}>
                <Typography className={classes.title} color="textSecondary">
                  {t`project:candidate`}
                </Typography>
                <List>
                  <ListItem>
                    <Avatar src={avatar} />
                    <ListItemText
                      primary={candidate.firstName + ' ' + candidate.lastName}
                      secondary={candidate.email}
                    />
                  </ListItem>
                </List>
              </CardContent>
              <CardContent
                style={{
                  borderBottom: '1px solid #eaeaea',
                  background: '#f9f9f9',
                }}
              >
                <Typography className={classes.title} color="textSecondary">
                  {t`project:owner`}
                </Typography>
                <List>
                  <ListItem>
                    <Avatar src={avatarOwnerCandidate} />
                    <ListItemText
                      primary={firstName + ' ' + lastName}
                      secondary={projectCandidateOwner.email}
                    />
                  </ListItem>
                </List>
                {/*<Button size='small'>Odepnij rekrutera</Button>*/}
              </CardContent>
              {owner && (
                <CardContent style={{ borderBottom: '1px solid #eaeaea' }}>
                  <Typography className={classes.title} color="textSecondary">
                    {t`project:patronShort`}
                  </Typography>
                  <List>
                    <ListItem>
                      <Avatar src={avatarOwner} />
                      <ListItemText
                        primary={owner.firstName + ' ' + owner.lastName}
                        secondary={owner.email}
                      />
                    </ListItem>
                  </List>
                </CardContent>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <RecruiterInProjectList
              onChangeRecruiterHandle={this.changeCandidateOwner}
              candidateId={candidate.id}
              recruiters={recruiterListProps}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    recruitersInProject: store.recruiters.recruitersInProject,
    currentProject: store.projects.currentProject,
    candidate: store.candidates.currentCandidate,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  clearCurrentRecruitersList,
  getProjectRecruitersFilteredForAdding,
  changeCandidateOwnerInProject,
  showSnackbarMessage,
};

ProjectAtsCandidateInProjectSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  changeCandidateOwnerInProject: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  recruitersInProject: PropTypes.array,
  getProjectRecruitersFilteredForAdding: PropTypes.func.isRequired,
  clearCurrentRecruitersList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectAtsCandidateInProjectSettings)));
