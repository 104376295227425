import {
  Paper,
  Grid,
  IconButton,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  DeleteForever as DeleteForeverIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  acceptInvitationProjectRecruiter,
  rejectInvitationProjectRecruiter,
} from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import Box from '../../../components/BoxContainer';
import ProjectRecruiterAcceptRejectButton from '../../../components/ProjectRecruiterAcceptRejectButton';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import RecruiterCardList from '../../recruiter/RecruiterList/RecruiterCardList';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingBottom: 16,
    marginLeft: 0,
    paddingLeft: '64px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '16px !important',
    },
  }),
  buttonDelete: {
    background: '#52a3fc',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    borderRadius: 0,
    padding: '5px 1px',
    minHeight: '20px',
    fontSize: 12,
  },
  toolTip: {
    background: theme.palette.jobllegroBlue,
    color: '#fff',
    fontSize: 14,
  },
});

export const ProjectRecruiters = props => {
  const {
    classes,
    recruitersInProject,
    projectDetails,
    projectDetails: { permissions = {} },
    handleReloadElements,
    isLoading,
    t,
  } = props;
  const { isOwner } = permissions;

  const getWrapperProjectRecruiters = options => {
    let { content } = options;
    return <Grid container>{content}</Grid>;
  };

  const getContentProjectRecruiters = row => {
    const { recruiter, confirmationStatus, owner } = row;
    return (
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={row.id}>
        <Box>
          <RecruiterCardList
            recruiter={row.recruiter}
            action={
              !projectDetails.closedStatus &&
              isOwner && (
                <Tooltip
                  title={t('project:show.removeRecruiterFromProject')}
                  placement="top"
                  classes={{
                    tooltip: classes.toolTip,
                  }}
                >
                  <div>
                    <IconButton
                      disabled={!isOwner}
                      onClick={props.onDeleteHandle.bind(this, recruiter.id)}
                    >
                      <DeleteForeverIcon />
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )
            }
            cardContent={
              <ProjectRecruiterAcceptRejectButton
                isLoading={isLoading}
                ownerInProject={owner}
                confirmationStatus={confirmationStatus}
                project={projectDetails}
                recruiter={recruiter}
                handleReloadElements={handleReloadElements}
              />
            }
          />
        </Box>
      </Grid>
    );
  };

  return (
    <Paper className={classes.rootPaper} elevation={0}>
      <Box
        title={t('project:show.recruitersChosenList')}
        elevation={0}
        variant="backgroundNone"
      >
        <ElementsListAsync
          elements={recruitersInProject.data || []}
          getContent={getContentProjectRecruiters}
          getWrapper={getWrapperProjectRecruiters}
          handleReloadElements={handleReloadElements}
        />
      </Box>
    </Paper>
  );
};

const mapDispatchToProps = {
  acceptInvitationProjectRecruiter,
  rejectInvitationProjectRecruiter,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
    recruitersInProject: store.recruiters.recruitersInProject,
  };
};

ProjectRecruiters.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  acceptInvitationProjectRecruiter: PropTypes.func.isRequired,
  rejectInvitationProjectRecruiter: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  onDeleteHandle: PropTypes.func.isRequired,
  dataList: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectRecruiters)));
