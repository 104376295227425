import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Translate } from 'shared/newComponents';
import { styles } from './NonContent.styles';

const NonContent = ({ classes, showContent, t }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container justifyContent={'center'}>
          <Translate
            text={`recruiter:fillData`}
            className={classes.fontHeader}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Translate text={'recruiter:fillInfo'} className={classes.fontBody} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(NonContent));
