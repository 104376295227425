import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ProjectV2Service } from 'shared/services';
import { ProjectCreateForm } from './components';

export const ProjectCreateView = withRouter(({ match }) => {
  const [project, setProject] = useState({});
  const [fetchDone, setFetchDone] = useState(false);

  const getProjectById = id => {
    ProjectV2Service.getProjectV2(id)
      .then(({ data }) => {
        setProject(data);
        setFetchDone(true);
      })
      .catch(() => {
        //TODO: redirect to 404
        alert('Błąd wczytywania danych');
      });
  };

  useEffect(() => {
    shouldUpdateProject();
  }, []);

  const shouldUpdateProject = () => {
    const { id } = match.params;
    if (!id) {
      setFetchDone(true);
    } else {
      getProjectById(id);
    }
  };

  return fetchDone ? (
    <ProjectCreateForm
      project={project}
      shouldUpdateProject={shouldUpdateProject}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
});
