import { Button, withStyles } from '@material-ui/core';
import {
  ArrowForward as FolderShowIcon,
  HourglassEmpty as HourglassEmptyIcon,
  LocationCity as LocationCityIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  editRecruiter,
  editRecruiterOrganization,
} from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import ConfirmationDialog from './LayoutConfirmationDialog/';

const styles = theme => ({
  button: {
    boxShadow: 'none',
    background: 'transparent',
    minWidth: 'auto',
    padding: 0,
    textTransform: 'none',
    color: theme.palette.jobllegroLightGrey,
    '&:hover': {
      background: 'transparent',
    },
    '&:active': {
      color: theme.palette.jobllegroBlue,
    },
    fontSize: 11,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  iconSmall: {
    fontSize: 11,
  },
});

export class AssignRecruiterToOrganizationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationOpened: false,
      selectedRecruiter: null,
      organizationConfirmation: 0,
    };
  }

  addOrganizationRecruiter = async recruiterId => {
    const { t } = this.props;
    try {
      const {
        currentUser: { organization },
      } = this.props;
      const { status } = await this.props.editRecruiterOrganization({
        id: recruiterId,
        organizationId: organization.id,
      });
      if (status === 200) {
        this.setState({
          organizationConfirmation: 1,
        });
        this.props.showSnackbarMessage(t`assign:recruiterAdded`);
      } else {
        this.props.showSnackbarMessage(t`assign:addRecruiterError`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleConfirmationOpen = id => () => {
    this.setState({
      confirmationOpened: true,
      selectedRecruiter: id,
    });
  };

  handleConfirmationClose = isOk => {
    if (isOk) {
      const { selectedRecruiter } = this.state;
      this.addOrganizationRecruiter(selectedRecruiter);
    }
    this.setState({
      confirmationOpened: false,
      selectedRecruiter: null,
    });
  };

  buttonAddToOrganization = () => {
    const { classes, recruiter, t } = this.props;

    const { organizationConfirmation } = this.state;

    let icon = '';
    let message = '';
    if (organizationConfirmation === 0) {
      icon = (
        <FolderShowIcon
          className={classNames(classes.leftIcon, classes.iconSmall)}
        />
      );
      message = t('organization:form.addToOrganization');
    } else if (organizationConfirmation === 1) {
      icon = (
        <HourglassEmptyIcon
          className={classNames(classes.leftIcon, classes.iconSmall)}
        />
      );
      message = t('organization:form.waitingForAcceptance');
    } else if (organizationConfirmation === 2) {
      icon = (
        <LocationCityIcon
          className={classNames(classes.leftIcon, classes.iconSmall)}
        />
      );
      message = t('organization:form.inOrganization');
    }

    return (
      <Button
        disableRipple
        className={classes.button}
        onClick={
          organizationConfirmation === 0
            ? this.handleConfirmationOpen(recruiter.id)
            : undefined
        }
      >
        {icon}
        {message}
      </Button>
    );
  };

  componentDidMount() {
    const {
      recruiter: { organizationConfirmation },
    } = this.props;
    this.setState({
      organizationConfirmation,
    });
  }

  render() {
    const { t } = this.props;

    const { confirmationOpened } = this.state;

    const buttonAddToOrganizationShow = this.buttonAddToOrganization();

    return (
      <React.Fragment>
        <ConfirmationDialog
          open={confirmationOpened}
          titleText={t('organization:form.addToOrganization')}
          onClose={this.handleConfirmationClose}
          okButtonText={t('core:action.imConfirm')}
          cancelButtonText={t('core:action.cancel')}
        >
          {t('organization:form.addToOrganizationConfirmation') + '?'}
        </ConfirmationDialog>
        {buttonAddToOrganizationShow}
      </React.Fragment>
    );
  }
}

AssignRecruiterToOrganizationButton.propTypes = {
  classes: PropTypes.object.isRequired,
  recruiter: PropTypes.object.isRequired,
  editRecruiter: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  editRecruiter,
  editRecruiterOrganization,
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(AssignRecruiterToOrganizationButton)));
