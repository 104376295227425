import { API } from '../constants';
import { ApiService } from './api.service';

export const AccommodationService = {
  getAccommodations() {
    return ApiService.get(API.GET.ACCOMMODATION.GET);
  },
  getAccommodation(accommodationId) {
    return ApiService.get(
      API.GET.ACCOMMODATION.GET_ONE.replace(':accommodation', accommodationId)
    );
  },
  addAccommodation(body) {
    return ApiService.post(API.POST.ACCOMMODATION.POST_ACCOMMODATION, body);
  },
  updateAccommodation(accommodationId, body) {
    return ApiService.put(
      API.PUT.ACCOMMODATION.PUT_ACCOMMODATION.replace(
        ':accommodation',
        accommodationId
      ),
      body
    );
  },
  updateAccommodationAsset(accommodationId, body) {
    return ApiService.put(
      API.PUT.ACCOMMODATION.PUT_ACCOMMODATION_ASSET.replace(
        ':accommodation',
        accommodationId
      ),
      body
    );
  },
};

export default AccommodationService;
