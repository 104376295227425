import { concat, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { getProjectAssetsSrcByType } from 'shared/utils/assets';
import ProjectAssets from '../../../components/ProjectAssets';
import 'shared/assets/css/CarouselMainPage.css';
import { withStyles } from '@material-ui/core';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';

const styles = theme => ({
  divBg: theme.mixins.gutters({
    width: '100% !important',
    height: 500,
    margin: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  }),
  assetCanvas: {
    position: 'absolute',
    zIndex: 1000,
    left: 10,
  },
});

export class ProjectSlider extends Component {
  async componentWillReceiveProps(nextProps) {
    if (nextProps.projectDetails !== this.props.projectDetails) {
      this.forceUpdate();
    }
  }

  getProjectVideo(video) {
    const parsedLink = 'https://www.youtube.com/embed/' + video;
    const { classes } = this.props;
    return !isEmpty(video) ? (
      <div key={video}>
        <iframe
          title="Jobllegro"
          className={classes.divBg}
          src={parsedLink}
          allowfullscreen
        />
      </div>
    ) : (
      []
    );
  }

  render() {
    const { projectDetails, classes, t } = this.props;

    const projectImages = getProjectAssetsSrcByType(
      projectDetails,
      'slider'
    ).map((imageSrc, index) => {
      return (
        <div
          key={index}
          className={classes.divBg}
          style={{
            background: 'url(' + imageSrc + ') no-repeat center center',
            backgroundSize: 'cover',
          }}
        />
      );
    });

    const projectVideo = this.getProjectVideo(projectDetails.videoUrl);

    let imageList = [];
    let showIndicators = false;
    if (!isEmpty(projectVideo)) {
      imageList = projectVideo;
    } else {
      showIndicators = projectImages.length > 1 ? true : false;
      imageList = concat(projectVideo, projectImages);
    }

    return (
      <div style={{ maxHeight: 500, overflow: 'hidden', position: 'relative' }}>
        {!projectDetails.closedStatus && projectDetails.permissions.isOwner && (
          <div className={classes.assetCanvas}>
            <ProjectAssets
              aspect={1 / 1}
              filesLimit={1}
              assetType="logo"
              acceptedFiles={['image/*']}
            >
              <ActionButtonJobllegroRounded>{t`project:logotype`}</ActionButtonJobllegroRounded>
            </ProjectAssets>
            <ProjectAssets
              aspect={4 / 3}
              filesLimit={1}
              assetType="header"
              acceptedFiles={['image/*']}
            >
              <ActionButtonJobllegroRounded>
                {t('project:show.cardHeaderBackground')}
              </ActionButtonJobllegroRounded>
            </ProjectAssets>
            <ProjectAssets
              aspect={4 / 3}
              filesLimit={10}
              assetType="slider"
              acceptedFiles={['image/*']}
            >
              <ActionButtonJobllegroRounded>{t`project:slider`}</ActionButtonJobllegroRounded>
            </ProjectAssets>
          </div>
        )}
        <Carousel
          showArrows={false}
          showThumbs={false}
          showIndicators={showIndicators}
          showStatus={false}
          dynamicHeight={false}
          interval={4000}
          autoPlay
          infiniteLoop
        >
          {imageList}
        </Carousel>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectSlider.propTypes = {
  projectDetails: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectSlider)));
