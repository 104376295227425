export const styles = () => ({
  container: { padding: 10 },
  paper: {
    borderRadius: 20,
    padding: 15,
    paddingTop: 15,
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
  },
  blueBackground: { backgroundColor: '#D0E6FF' },
  icon: { fontSize: 50 },
  iconWrapper: { fontSize: 50, padding: 12 },
  saveButton: { paddingTop: 15 },
  children: { position: 'relative' },
});
