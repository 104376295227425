import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  withStyles,
  DialogTitle,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Close, PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import * as yup from 'yup';
import { diffBetweenDate } from '../../../modules/project/ProjectAts/utils/utils';
import { enums } from '../../enums';
import { ProjectEmploymentProcessService } from '../../services';
import { getSelectItemsTranslate, sortItemsBy } from '../../utils/assets';
import { dateAdd } from '../../utils/date';
import { isBiometricPassport, isShorVisaForm } from '../../utils/functions';
import {
  getLocalizationFromProjectOrder,
  getSelectedLocalization,
} from '../PopUpATS1ATS2/utils';
import Select from '../Select';
import TextField from '../TextField';
import { styles } from './PopUpATS3ATS4.styles';

const PopUpATS3ATS4 = ({
  isOpen,
  setIsOpen,
  onClose,
  classes,
  loadProjectCandidate,
  checkedCandidate = [],
  projectOrderLocalizations,
  projectEmploymentProcess: { id, candidate },
  t,
  project,
}) => {
  const isMultiEdit = !!checkedCandidate.length;

  const { country } = project;
  const {
    userEmploymentProcess: {
      residenceTitle,
      residenceTitleEstimatedDate,
      localization,
      requestedEmploymentStartDate,
      requestedEmploymentEndDate,
      documentStartDate,
      documentEndDate,
    },
  } = candidate;

  const localizations = getLocalizationFromProjectOrder(
    projectOrderLocalizations
  );

  const formData = {
    country: country.name,
    residenceTitle:
      residenceTitle !== null && !isMultiEdit ? residenceTitle : '',
    residenceTitleEstimatedDate:
      residenceTitleEstimatedDate !== null && !isMultiEdit
        ? residenceTitleEstimatedDate
        : '',

    localization:
      localization !== null
        ? getSelectedLocalization(localization, localizations)
        : '',
    requestedEmploymentStartDate:
      requestedEmploymentStartDate !== null && !isMultiEdit
        ? requestedEmploymentStartDate
        : '',
    requestedEmploymentEndDate:
      requestedEmploymentEndDate !== null && !isMultiEdit
        ? requestedEmploymentEndDate
        : '',
  };

  const validationSchema = yup.object(
    {
      country: yup.string().required(t`ats3ats4:validation.country`),
      residenceTitle: yup
        .number()
        .required(t`ats3ats4:validation.residenceTitle`),
      residenceTitleEstimatedDate: yup.string().when('residenceTitle', {
        is: residenceTitle => !isShorVisaForm(residenceTitle),
        then: yup.string().required(t`ats2ats3:validation.isRequired`),
        otherwise: yup.string(),
      }),
      requestedEmploymentStartDate: yup.date().when('residenceTitle', {
        is: residenceTitle => isShorVisaForm(residenceTitle),
        then: yup
          .date()
          .min(
            documentStartDate,
            `${t`employmentManagementDialog:dialog6.minBreakDate`} ${documentStartDate}`
          )
          .max(
            documentEndDate,
            `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${documentEndDate}`
          )
          .required(
            t('ats1ats2:validation.submittingEmploymentRequestToOfficeDate')
          ),
        otherwise: yup.date(),
      }),
      requestedEmploymentEndDate: yup.date().when('residenceTitle', {
        is: residenceTitle => isShorVisaForm(residenceTitle),
        then: yup
          .date()
          .max(
            documentEndDate,
            `${t`employmentManagementDialog:dialog6.minBreakDate`} ${documentEndDate}`
          )
          .when(
            'requestedEmploymentStartDate',
            (requestedEmploymentStartDate, schema) =>
              requestedEmploymentStartDate &&
              schema.min(
                requestedEmploymentStartDate,
                t`ats2ats3:validation.date`
              )
          )
          .required(t`ats2ats3:validation.isRequired`),
        otherwise: yup.date(),
      }),
      localization: yup.string().when('residenceTitle', {
        is: residenceTitle => isShorVisaForm(residenceTitle),
        then: yup.string().required(t`ats2ats3:validation.isRequired`),
        otherwise: yup.string(),
      }),
    },
    ['residenceTitle', 'residenceTitleEstimatedDate']
  );

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({
      residenceTitle,
      residenceTitleEstimatedDate,
      localization,
      requestedEmploymentStartDate,
      requestedEmploymentEndDate,
    }) => {
      if (isShorVisaForm(residenceTitle)) {
        if (moment().isSameOrAfter(moment(requestedEmploymentEndDate), 'day')) {
          formik.setFieldError(
            'requestedEmploymentEndDate',
            t`employmentManagementDialog:dialog2.validation.crossingBorderDate`
          );
          return;
        }
        if (moment().isAfter(moment(requestedEmploymentStartDate), 'day')) {
          formik.setFieldError(
            'requestedEmploymentStartDate',
            t`employmentManagementDialog:dialog2.validation.crossingBorderDate`
          );
          return;
        }

        const dayDiffDate = diffBetweenDate(
          requestedEmploymentStartDate,
          requestedEmploymentEndDate
        );
        if (
          isBiometricPassport(residenceTitle) &&
          dayDiffDate > enums.BIOMETRIC_PASSPORT_VALID_DAYS
        ) {
          formik.setFieldError(
            'requestedEmploymentEndDate',
            t('employmentManagementDialog:dialog2.validation.87days')
          );
          return;
        }
      }

      const body = {
        residenceTitle,
        residenceTitleEstimatedDate: !isShorVisaForm(residenceTitle)
          ? residenceTitleEstimatedDate
          : null,
        localization: isShorVisaForm(residenceTitle)
          ? { id: localization }
          : null,
        requestedEmploymentStartDate: isShorVisaForm(residenceTitle)
          ? requestedEmploymentStartDate
          : null,
        requestedEmploymentEndDate: isShorVisaForm(residenceTitle)
          ? requestedEmploymentEndDate
          : null,

        projectCandidateEmploymentProcesses: _.uniq([...checkedCandidate, id]),
      };
      ProjectEmploymentProcessService.updateProjectEmploymentProcess(body, 4)
        .then(() => {
          loadProjectCandidate();
          setIsOpen(false);
        })
        .catch(err => console.log(err));
    },
  });

  const handleChangeSelect = event => {
    const value = event.target.value;

    if (!isShorVisaForm(value)) {
      formik.setFieldValue(
        'residenceTitleEstimatedDate',
        dateAdd(moment(), 45).format('YYYY-MM-DD')
      );
    }
    formik.setFieldValue('residenceTitle', value);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle
        className={classes.dialogTitle}
      >{t`ats3ats4:dialogTitle`}</DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.content}>
          <TextField
            name="country"
            formik={formik}
            className={classes.txtField}
          />
          <Select
            label={t`ats3ats4:residenceTitle`}
            variant="transparent"
            formik={formik}
            name="residenceTitle"
            onChange={handleChangeSelect}
            items={sortItemsBy(
              getSelectItemsTranslate(enums.RESIDENCE_TITLE, t),
              'name'
            )}
            fontBold={true}
          />
          {!isShorVisaForm(formik.values.residenceTitle) && (
            <div className={classes.date}>
              <div>
                <Typography variant="body1">{t`ats3ats4:residenceTitleEstimatedDate`}</Typography>
              </div>
              <div>
                <TextField
                  type="date"
                  name="residenceTitleEstimatedDate"
                  variant="transparent"
                  formik={formik}
                />
              </div>
            </div>
          )}
          {isShorVisaForm(formik.values.residenceTitle) && (
            <>
              <Grid container className={classes.employmentRequest}>
                <Grid container className={classes.employmentDate}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography>{t`ats1ats2:employmentDate`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid
                          container
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Grid item xs={1} sm={1} md={1}>
                            <PowerSettingsNew className={classes.powerOnBtn} />
                          </Grid>
                          <Grid item xs={9} sm={9} md={9}>
                            <TextField
                              type="date"
                              name="requestedEmploymentStartDate"
                              variant="transparent"
                              formik={formik}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid
                          container
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Grid item xs={1} sm={1} md={1}>
                            <PowerSettingsNew className={classes.powerOffBtn} />
                          </Grid>
                          <Grid item xs={9} sm={9} md={9}>
                            <TextField
                              type="date"
                              name="requestedEmploymentEndDate"
                              variant="transparent"
                              formik={formik}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <DialogContent>
                <Typography
                  variant="body1"
                  className={classes.header}
                >{t`ats1ats2:localization`}</Typography>
                <Select
                  formik={formik}
                  label={t`ats1ats2:selectLab`}
                  name="localization"
                  items={localizations}
                  variant="transparent"
                />
              </DialogContent>
            </>
          )}
        </DialogContent>
        <Button type="submit" className={classes.saveBtn}>
          {t`ats3ats4:saveBtn`}
        </Button>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(PopUpATS3ATS4);
