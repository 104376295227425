import { LinearProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { CONST_CONFIG, LANDING_PAGE_URL } from 'shared/constants';
import LandingLayout from '../../../components/LandingLayout/';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog/';
import RegisterForm from './../components/RegisterForm/';

export class Register extends Component {
  state = {
    openConflictDialog: false,
    error: null,
    fetch: 'idle',
  };

  componentDidMount() {
    document.body.style.display = 'none';
    window.location = `${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.AUTH.REGISTER}`;
    // this.props.getConfigs();
  }

  handleSubmit = async values => {
    if (
      [
        CONST_CONFIG.FACEBOOK.LOGIN_FACEBOOK_API,
        CONST_CONFIG.GMAIL.LOGIN_GOOGLE_API,
        CONST_CONFIG.LINKEDIN.LOGIN_LINKEDIN_API,
      ].includes(values.type)
    ) {
      try {
        await this.setState({
          error: null,
          fetch: 'fetching',
        });
        const { data } = await this.props.authUser(values);
        await this.setState({
          isFirstLogin: data.isFirstLogin,
          fetch: 'idle',
        });
      } catch (error) {
        console.log(error);
        this.props.showSnackbarMessage('error');
        this.setState({ error: error.toString() });
      }
    } else {
      const userType = this.props.match.params.type;
      const isRecruiter = userType !== 'candidate';
      values.employerStatus = !isRecruiter ? '0' : values.employerStatus;
      const { status } = await this.props.registerRecruiter(
        Object.assign({}, values, { isRecruiter })
      );
      if (status === 201) {
        this.props.history.push(PATHS.AUTH.LOGIN);
      } else if (status === 409) {
        this.setState({
          openConflictDialog: true,
        });
      } else {
        this.props.showSnackbarMessage(
          this.props.t`register:error.creatingAccount`
        );
      }
    }
  };

  handleConfirmationClose = () => {
    this.setState({
      openConflictDialog: false,
    });
  };

  getConflictDialog = () => {
    const { openConflictDialog } = this.state;
    const { classes, t } = this.props;

    return (
      <ConfirmationDialog
        open={openConflictDialog}
        titleText={t`register:error.takenEmail`}
        onClose={this.handleConfirmationClose}
        cancelButtonText={t`register:gotIt`}
      >
        <div className={classes.boxText}>
          <p>{t`register:error.userAlreadyExist`}</p>
          <Trans i18nKey={'login:form.descriptionConflict'}>
            x<Link to={PATHS.AUTH.FORGOT_PASSWORD}>x</Link>x
          </Trans>
          <p>{t`register:p1`}</p>
          <p>{t`register:p2`}</p>
        </div>
      </ConfirmationDialog>
    );
  };
  render() {
    const { isFetching, isAuthorized, config, classes } = this.props;
    const userType = this.props.match.params.type;
    return isAuthorized ? (
      <Redirect to={PATHS.DASHBOARD.INDEX} />
    ) : (
      <LandingLayout>
        <Grid item xs={12} className={classes.formContainer}>
          <RegisterForm
            userType={userType}
            onSubmit={this.handleSubmit}
            config={config}
          />
          {isFetching && <LinearProgress className={classes.progress} />}
        </Grid>
        {this.getConflictDialog()}
      </LandingLayout>
    );
  }
}

Register.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  getConfigs: PropTypes.func.isRequired,
  registerRecruiter: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default withTranslation()(Register);
