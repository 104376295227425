import { bool, object, string } from 'yup';

export const MODEL = {
  organizationMentor: 'organizationMentor',
  organizationToBe: 'organizationToBe',
  organization: 'organization',
  invoiceCompanyName: 'invoiceCompanyName',
  invoiceAddress: 'invoiceAddress',
  invoicePostCode: 'invoicePostCode',
  accountNumber: 'accountNumber',
  invoiceCity: 'invoiceCity',
  invoiceIdentificationNumber: 'invoiceIdentificationNumber',
  invoicePersonalNumber: 'invoicePersonalNumber',
  invoiceTypeBusiness: 'invoiceTypeBusiness',
  invoiceTypeNaturalPerson: 'invoiceTypeNaturalPerson',
};

export const validationSchema = t => {
  return object().shape(
    {
      invoiceTypeBusiness: bool(),
      organizationToBe: object().required(
        t('projectCreator:core.requiredField')
      ),
      invoiceAddress: string().when('organizationToBe', {
        is: organizationToBe => !organizationToBe || !organizationToBe.id,
        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoicePostCode: string().when('organizationToBe', {
        is: organizationToBe => !organizationToBe || !organizationToBe.id,
        then: string()
          .length(
            6,
            t`employmentManagementDialog:dialog2.validation.postalCode.length`
          )
          .matches(
            /\d{2}-\d{3}/,
            t`employmentManagementDialog:dialog2.validation.postalCode.badFormat`
          )
          .required(t`ats2ats3:validation.isRequired`),
        otherwise: string().nullable(),
      }),
      accountNumber: string().when('organizationToBe', {
        is: organizationToBe => !organizationToBe || !organizationToBe.id,
        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoiceCity: string().when('organizationToBe', {
        is: organizationToBe => !organizationToBe || !organizationToBe.id,
        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoiceIdentificationNumber: string().when(
        ['organizationToBe', 'invoiceTypeBusiness'],
        {
          is: (organizationToBe, invoiceTypeBusiness) => {
            return (
              invoiceTypeBusiness === true &&
              (!organizationToBe || !organizationToBe.id)
            );
          },

          then: string()
            .strict(false)
            .trim()
            .required(t('projectCreator:core.requiredField'))
            .nullable(),
          otherwise: string().nullable(),
        }
      ),
      invoicePersonalNumber: string().when(
        ['organizationToBe', 'invoiceTypeBusiness'],
        {
          is: (organizationToBe, invoiceTypeBusiness) =>
            invoiceTypeBusiness === false &&
            (!organizationToBe || !organizationToBe.id),
          then: string()
            .strict(false)
            .trim()
            .required(t('projectCreator:core.requiredField'))
            .nullable(),
          otherwise: string().nullable(),
        }
      ),
    },
    [
      ['invoicePersonalNumber', 'organizationToBe', 'invoiceTypeBusiness'],
      [
        'invoiceIdentificationNumber',
        'organizationToBe',
        'invoiceTypeBusiness',
      ],
      ['invoiceCity', 'organizationToBe'],
      ['invoicePostCode', 'organizationToBe'],
      ['invoiceAddress', 'organizationToBe'],
      'organizationToBe',
      'invoiceTypeBusiness',
    ]
  );
};
