import { API } from '../constants';
import { ApiService } from './api.service';

export const UserService = {
  getMentorForProjectFromList: () => {
    return ApiService.get(API.POST.USER.GET_MENTOR_FOR_PROJECT_FROM_LIST);
  },
  postCandidateToStash: data => {
    return ApiService.post(API.POST.USER.CANDIDATE_STASH, data);
  },
  deleteCandidateFromStash: candidateId => {
    return ApiService.delete(
      API.DELETE.USER.DELETE_CANDIDATE_FROM_STASH.replace(':id', candidateId)
    );
  },
};
