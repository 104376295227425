import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select as BaseSelect,
  withStyles,
  OutlinedInput,
} from '@material-ui/core';
import { map, get, has, isNil } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Translate } from '..';
import { styles } from './Select.styles';

const Select = ({
  items,
  classes,
  label,
  name,
  formik,
  disabled = false,
  variant = 'default',
  fontBold = false,
  optionsDisabled = null,
  touched = false,
  projectWizard = false,
  ...props
}) => {
  const [value, setValue] = useState('0');

  useEffect(() => {
    setValue(isNil(get(formik.values, name)) ? '' : get(formik.values, name));
  }, [get(formik.values, name)]);

  const handleSelectChange = e => {
    setValue(e.target.value.toString());
    formik.setFieldValue(name, e.target.value);
  };

  const error = !touched
    ? has(formik.touched, name) && Boolean(get(formik.errors, name))
    : Boolean(get(formik.errors, name));

  const optionsDisabledHandler = item => {
    if (optionsDisabled) {
      return optionsDisabled.includes(item.id);
    }
    return false;
  };

  return (
    <Grid container className={classes.root}>
      <FormControl
        fullWidth
        error={error}
        variant="outlined"
        disabled={disabled}
        className={projectWizard ? '' : classes.formControl}
      >
        <InputLabel error={error} classes={{ root: classes.rootLabel }}>
          {label}
        </InputLabel>
        <BaseSelect
          style={
            variant === 'transparent'
              ? { backgroundColor: 'white', borderRadius: 30 }
              : {}
          }
          value={value.toString()}
          onChange={handleSelectChange}
          fullWidth
          name={name}
          error={error}
          input={
            <OutlinedInput
              labelWidth={0}
              classes={{ notchedOutline: classes.input }}
            />
          }
          classes={{ select: classes.baseSelect, icon: classes.dropdownIcon }}
          {...props}
        >
          {map(items, item => (
            <MenuItem
              value={item.id}
              key={item.id}
              disabled={optionsDisabledHandler(item)}
            >
              <Translate
                text={item.name}
                bold={fontBold}
                style={item.color ? { color: item.color } : null}
              />
            </MenuItem>
          ))}
        </BaseSelect>
        {error && (
          <FormHelperText
            className={projectWizard ? '' : classes.formHelperText}
          >
            {!touched
              ? has(formik.touched, name) && get(formik.errors, name)
              : get(formik.errors, name)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(Select);
