import { Typography, ListItem, List, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  listTop: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  listItemTop: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      fontSize: 11,
      color: theme.palette.jobllegroLightGrey,
      marginLeft: theme.spacing(7),
    },
  },
});

export const DashboardRightDrawerListItemHeader = props => {
  const { classes, type, t } = props;

  const headerTopText = (
    <Typography component="div">
      {type === 'projects' &&
        t('dashboard:rightDrawer.position') +
          ' / ' +
          t('dashboard:rightDrawer.client') +
          ' / ' +
          t('dashboard:rightDrawer.timeLeft')}
      {type === 'candidates' && t('dashboard:rightDrawer.firstAndLastName')}
      {type === 'recruiters' && t('dashboard:rightDrawer.firstAndLastName')}
    </Typography>
  );

  return (
    <List className={classes.listTop}>
      <ListItem key={1} role={undefined} className={classes.listItemTop}>
        {headerTopText}
      </ListItem>
    </List>
  );
};

DashboardRightDrawerListItemHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withTranslation()(DashboardRightDrawerListItemHeader)
);
