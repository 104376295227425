import {
  Button,
  CircularProgress,
  Avatar,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getImageFromSource } from 'shared/utils/assets';

const styles = theme => ({
  avatar: {
    width: 30,
    height: 30,
  },
  icon: {
    color: '#afafaf',
  },
  listItem: {
    paddingLeft: '12px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.3s',
    '&:hover': {
      background: '#f9f9f9',
    },
  },
  listItemText: {
    maxWidth: 270,
  },
  iconButtoniconButtonActtion: {
    width: 30,
    height: 30,
    padding: 0,
    transition: 'transform 300ms ease-in-out',
    '&:hover': {
      transform: 'rotate(20deg)',
      background: 'transparent',
    },
  },
  thumbUp: {
    color: theme.palette.jobllegroSuccess,
    width: 20,
  },
  thumbDown: {
    color: theme.palette.jobllegroRedAlert,
    width: 20,
  },
  primaryListItem: {
    color: '#000',
    lineHeight: '1rem',
  },
  progress: {
    margin: theme.spacing(2),
  },
  progressAwait: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#fff',
    opacity: '0.9',
    zIndex: '8000',
    textAlign: 'center',
  },
  button: {
    background: theme.palette.jobllegroSuccess,
    color: '#fff',
    padding: '2px 4px',
    fontSize: 11,
    '&:hover': {
      background: theme.palette.jobllegroSuccessActive,
    },
  },
  listSubHeader: {
    paddingLeft: 12,
    paddingRight: 12,
    lineHeight: '24px',
  },
  listItemSecondaryAction: {
    top: '50%',
    right: 12,
  },
});

export const NotificationsOrganizationListItem = props => {
  const {
    acceptInvitation,
    rejectInvitation,
    setNotificationRead,
    classes,
    item,
    isLoading,
    currentUser,
    type,
    t,
  } = props;

  const {
    data: {
      recruiter = {},
      organization = {},
      project = {},
      projectOwner = {},
    },
  } = item;

  let primaryText = '';
  let secondaryText = '';
  let informationText = '';
  let confirmationAcceptId = null;
  let confirmationRejectId = null;
  let userId = null;
  let avatar = null;
  let link = null;

  if (type === 'organization_recruiter_co_6') {
    primaryText = organization.name;
    avatar = getImageFromSource(organization.logo);
    informationText = t(
      'notification:organization.organizationInvitationRejected'
    );
    link = PATHS.ORGANIZATION.SHOW.replace(':id', organization.id);
  } else if (type === 'organization_recruiter_co_5') {
    primaryText = organization.name;
    avatar = getImageFromSource(organization.logo);
    informationText = t(
      'notification:organization.recruiterAcceptedYourOrganizationRequest',
      recruiter
    );
    link = PATHS.ORGANIZATION.SHOW.replace(':id', organization.id);
  } else if (type === 'organization_recruiter_co_4') {
    confirmationAcceptId = 5;
    confirmationRejectId = 6;
    userId = recruiter.id;
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    avatar = getImageFromSource(recruiter.avatar);
    informationText = t(
      'notification:organization.recruiterWantsToJoinYourOrganization',
      recruiter
    );
  } else if (type === 'organization_recruiter_co_3') {
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    avatar = getImageFromSource(recruiter.avatar);
    informationText = t(
      'notification:organization.recruiterRejectedYourOrganizationInvitation',
      recruiter
    );
  } else if (type === 'organization_recruiter_co_2') {
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    avatar = getImageFromSource(recruiter.avatar);
    informationText = t(
      'notification:organization.recruiterAcceptedYourOrganizationInvitation',
      recruiter
    );
  } else if (type === 'organization_recruiter_co_1') {
    confirmationAcceptId = 2;
    confirmationRejectId = 3;
    userId = currentUser.id;
    primaryText = organization.name;
    avatar = getImageFromSource(organization.logo);
    informationText = t(
      'notification:organization.recruiterInvitedYouToHisOrganization',
      recruiter
    );
    link = PATHS.ORGANIZATION.SHOW.replace(':id', organization.id);
  } else if (type === 'organization_project_to_approve') {
    confirmationAcceptId = 1;
    confirmationRejectId = 2;
    userId = currentUser.id;
    primaryText = `${t`notification:organization.recruiter`} ${
      projectOwner.lastName
    } 
    ${projectOwner.firstName} ${t`notification:organization.ask`}
     ${project.position}`;
    avatar = getImageFromSource(organization.logo);
    informationText = t(
      'notification:organization.waitingToApproveProjectOrganization'
    );
    link = PATHS.PROJECT.SHOW.replace(':id', project.id);
  } else if (type === 'remove_from_organization') {
    primaryText = organization.name;
    avatar = getImageFromSource(organization.logo);
    informationText = t(
      'notification:organization.recruiterRemoveYouFromOrganization'
    );
    link = PATHS.ORGANIZATION.SHOW.replace(':id', organization.id);
  }

  return (
    <React.Fragment>
      <ListSubheader
        disableSticky={true}
        component="div"
        className={classes.listSubHeader}
      >
        {informationText}
        <ArrowDropDownIcon style={{ position: 'absolute', top: '1px' }} />
      </ListSubheader>
      <ListItem key={item.id} className={classes.listItem}>
        {isLoading && (
          <div className={classes.progressAwait}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
        <Avatar
          src={avatar}
          component={Link}
          to={link || PATHS.RECRUITER.SHOW.replace(':id', recruiter.id)}
          className={classes.avatar}
        />
        <ListItemText
          className={classes.listItemText}
          primary={primaryText}
          secondary={secondaryText}
          classes={{
            primary: classes.primaryListItem,
          }}
        />
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          {type === 'organization_recruiter_co_5' ||
          type === 'organization_recruiter_co_6' ||
          type === 'organization_recruiter_co_2' ||
          type === 'organization_recruiter_co_3' ||
          type === 'remove_from_organization' ? (
            <Button
              size="small"
              color="primary"
              className={classes.button}
              onClick={setNotificationRead.bind(this, item.id)}
            >
              {t('notification:general.markAsRead')}
            </Button>
          ) : (
            <React.Fragment>
              <IconButton
                onClick={acceptInvitation.bind(
                  this,
                  confirmationAcceptId,
                  userId,
                  item.id,
                  type,
                  project.id
                )}
                aria-label="Acceptance"
                className={classes.iconButtonActtion}
              >
                <ThumbUpIcon className={classes.thumbUp} />
              </IconButton>
              <IconButton
                onClick={rejectInvitation.bind(
                  this,
                  confirmationRejectId,
                  userId,
                  item.id,
                  type,
                  project.id
                )}
                aria-label="Reject"
                className={classes.iconButtonActtion}
              >
                <ThumbDownIcon className={classes.thumbDown} />
              </IconButton>
            </React.Fragment>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
};

NotificationsOrganizationListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(NotificationsOrganizationListItem)));
