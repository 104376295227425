import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getCmsConfigs } from 'shared/services/cmsService';
import ConfigCmsTable from '../../../components/ConfigCmsTable';
import PageHeader from '../../../components/PageHeader';

export class AdminConfigCmsView extends React.Component {
  state = {
    cmsConfigs: [],
  };

  componentDidMount() {
    this.getCmsConfigs();
  }

  getCmsConfigs = () => {
    getCmsConfigs().then(response => {
      const { data } = response;
      this.setState({
        cmsConfigs: data,
      });
    });
  };

  render() {
    const { cmsConfigs } = this.state;
    return (
      <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
        <PageHeader title="Konfiguracja CMS" />
        <div className={this.props.classes.root}>
          <ConfigCmsTable
            cmsConfigs={cmsConfigs}
            shouldUpdateCmsConfig={this.getCmsConfigs}
          />
        </div>
      </Paper>
    );
  }
}

AdminConfigCmsView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AdminConfigCmsView;
