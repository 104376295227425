import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const columnData = [
  { id: 'id', numeric: true, label: 'id' },
  { id: 'email', numeric: false, label: 'email' },
  { id: 'firstName', numeric: false, label: 'imię' },
  { id: 'lastName', numeric: false, label: 'nazwisko' },
  { id: 'createdAt', numeric: false, label: 'data utworzenia' },
  { id: 'recruiterRating', numeric: false, label: 'kolejność rekrtuera' },
  { id: 'isRecruiter', numeric: false, label: 'rekruter' },
  { id: 'employerStatus', numeric: false, label: 'Pracodawca' },
  {
    id: 'isNotificationNewProjectEnabled',
    numeric: false,
    label: 'Powiadomienia o nowym projekcie',
  },
  { id: 'mentorType', numeric: false, label: 'Typ mentora' },
  {
    id: 'isRandomMentorFormProject',
    numeric: false,
    label: 'Mentor losowany do nowego projektu',
  },
  { id: 'isSuperUser', numeric: false, label: 'superuser' },
  { id: 'mentorStatus', numeric: false, label: 'Mentor' },
  { id: 'confirmationToken', numeric: false, label: 'token' },
  { id: 'enabled', numeric: false, label: 'enabled' },
  { id: '', numeric: false, label: 'Akcje' },
];

export class AdminUserViewHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableRow>
        {columnData.map(column => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            sortDirection={orderBy === column.id ? order : false}
            size="small"
            style={{ textAlign: 'center' }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={order}
              onClick={this.createSortHandler(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    );
  }
}

AdminUserViewHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default AdminUserViewHead;
