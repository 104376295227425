import { createReducer } from '@reduxjs/toolkit';
import {
  showSnackbarMessage,
  hideSnackbarMessage,
  showSnackbarInformationMessage,
  hideSnackbarInformationMessage,
} from './actions';

const initialState = {
  snackbarMessage: '',
  open: false,
  variant: '',
};

export default createReducer(initialState, builder =>
  builder
    .addCase(showSnackbarMessage, (state, action) => {
      state.snackbarMessage = action.payload;
      state.open = true;
    })
    .addCase(hideSnackbarMessage, (state, action) => {
      state.snackbarMessage = '';
      state.open = false;
    })
    .addCase(showSnackbarInformationMessage, (state, action) => {
      state.snackbarMessage = action.payload;
      state.open = true;
      state.variant = 'information';
    })
    .addCase(hideSnackbarInformationMessage, (state, action) => {
      state.snackbarMessage = '';
      state.open = false;
      state.variant = '';
    })
);
