import { Paper, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withTranslation } from 'react-i18next';
import { CandidateV2Service, ProjectV2Service } from 'shared/services';
import CitySeoService from 'shared/services/citySeo.service';
import CitySeoTable from '../../../components/CitySeoTable';
import PageHeader from '../../../components/PageHeader';

function AdminCitySeoView({ classes, t }) {
  const [citiesSeo, setCitiesSeo] = useState([]);
  const [
    mostPopularCandidateCitiesCsvData,
    setMostPopularCandidateCitiesCsvData,
  ] = useState([]);
  const [
    mostPopularProjectsCitiesCsvData,
    setMostPopularProjectsCitiesCsvData,
  ] = useState([]);

  const currentDate = moment(new Date()).format('YYYY-MM-DD_HH-mm-ss');

  const generalProjectReportHeader = [
    { label: t`adminCitySeo:cityLabel`, key: 'city' },
    { label: t`adminCitySeo:numOfObjects`, key: 'popularCount' },
  ];

  useEffect(() => {
    getCitiesSeo();
    getMostPopularCandidateCities();
    getMostPopularProjectCities();
  }, []);

  const getCitiesSeo = () => {
    CitySeoService.getCitiesSeo()
      .then(({ data }) => {
        setCitiesSeo(data);
      })
      .catch(error => {
        alert(t`adminCitySeo:dataError`);
        console.log(error);
      });
  };
  const getMostPopularCandidateCities = () => {
    CandidateV2Service.getMostPopularCities()
      .then(({ data }) => {
        setMostPopularCandidateCitiesCsvData(data);
      })
      .catch(error => {
        alert(t`adminCitySeo:dataError`);
        console.log(error);
      });
  };

  const getMostPopularProjectCities = () => {
    ProjectV2Service.getMostPopularCities()
      .then(({ data }) => {
        setMostPopularProjectsCitiesCsvData(data);
      })
      .catch(error => {
        alert(t`adminCitySeo:dataError`);
        console.log(error);
      });
  };
  return (
    <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
      <PageHeader title={t`adminCitySeo:seoCity`} />
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item>
          <CSVLink
            style={{ textDecoration: 'none' }}
            data={mostPopularCandidateCitiesCsvData}
            headers={generalProjectReportHeader}
            filename={`most_popular_cities_candidates_${currentDate}.csv`}
          >
            <p>{t`adminCitySeo:recentPlan`}</p>
          </CSVLink>
          <CSVLink
            style={{ textDecoration: 'none' }}
            data={mostPopularProjectsCitiesCsvData}
            headers={generalProjectReportHeader}
            filename={`most_popular_cities_projects_${currentDate}.csv`}
          >
            <p>{t`adminCitySeo:recentPlan2`}</p>
          </CSVLink>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <CitySeoTable
          citiesSeo={citiesSeo}
          shouldUpdateCitiesSeo={getCitiesSeo}
        />
      </div>
    </Paper>
  );
}

export default withTranslation()(AdminCitySeoView);
