import { Grid, Paper, withStyles } from '@material-ui/core';
import { isNil } from 'lodash';
import React from 'react';
import { Translate } from 'shared/newComponents';
import { styles } from './FinicalPlanCard.styles';

const FinicalPlanCard = ({
  classes,
  iconSrc,
  subscriptionVariantText,
  price,
  name,
  description,
  currency = 'PLN',
  onClick = () => {},
  selected = false,
  isPointer = false,
  greyStyle = false,
}) => {
  return (
    <Grid
      container
      className={classes.wrapper}
      onClick={onClick}
      style={isPointer ? { cursor: 'pointer' } : null}
    >
      <Paper
        className={classes.paper}
        style={
          selected
            ? {
                backgroundColor: 'rgb(215 239 243)',
              }
            : null
        }
      >
        <Grid container className={classes.container}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingBottom: 10 }}
          >
            <Grid item>
              {iconSrc && <img alt="iconSrc" src={iconSrc} height={40} />}
              {subscriptionVariantText && (
                <Translate text={subscriptionVariantText} bold variant="h4" />
              )}
            </Grid>
            <Grid item>
              <Translate
                text={
                  price === 'Free'
                    ? 'Free'
                    : price === ''
                    ? ''
                    : `${price} ${currency}`
                }
                bold
                variant={price === 'Free' ? 'h4' : 'h6'}
                className={
                  price === 'Free' ? classes.primaryText : classes.secondaryText
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Translate text={name} bold variant="body1" />
          </Grid>
          <Grid container>
            <Translate text={description} variant="body1" />
          </Grid>
        </Grid>
        {(isNil(price) || price === '' || greyStyle) && (
          <Grid className={classes.disabledCard} />
        )}
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(FinicalPlanCard);
