import { Avatar, Button, Grid, Typography, styled } from '@material-ui/core';
import { themeOptions } from 'config';

export const GridContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  background: themeOptions.palette.jobllegroSuperLightGrey,
  height: ' 100vh',
});

export const Wrapper = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const Content = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: themeOptions.spacing(1),
  marginBottom: themeOptions.spacing(1),
});

export const Logo = styled(Avatar)({
  width: '123px',
  height: '30px',
  borderRadius: 0,
});

export const Icon = styled(Avatar)({
  width: '24px',
  height: '24px',
  borderRadius: 0,
  cursor: 'pointer',
});

export const BottomBar = styled(Grid)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  background: '#fff',
  boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.15)',
  padding: themeOptions.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: themeOptions.spacing(5),
});

export const StyledButton = styled(Button)({
  background: '#148EFF',
  color: '#fff',
  border: 'none',
  borderRadius: '30px',
  padding: themeOptions.spacing(2, 6),
  cursor: 'pointer',
});

export const ButtonText = styled(Typography)({
  fontSize: '16px',
  weight: '600',
});
