import {
  Paper,
  Typography,
  withStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
} from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import Box from '../../../components/BoxContainer';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import ProjectRightListItem from './ProjectRightListItem';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingBottom: 16,
    paddingLeft: '0px !important',
    paddingRight: '64px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: '16px !important',
      paddingLeft: '16px !important',
    },
  }),
});

export const ProjectRightListRecruiters = props => {
  const { classes, isLoading, projectDetails, t, myRecruiters } = props;
  const { permissions = {} } = projectDetails;

  const getRecruiterContent = recruiter => {
    return (
      (permissions.isOwner ||
        permissions.isMentor ||
        permissions.isHiringManager) && (
        <ProjectRightListItem
          key={recruiter.id}
          isLoadingRight={isLoading}
          data={recruiter}
          projectDetails={projectDetails}
          onAddHandle={props.onAddRecruiterHandle}
          option="recruiter"
        />
      )
    );
  };

  const getWrapper = ({ content, pagination, filtering }) => (
    <React.Fragment>
      <ExpansionComponent
        title={t('menu:filter')}
        textOnTop={t`project:searchRecruiter`}
      >
        {filtering}
      </ExpansionComponent>
      {content.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('project:show.nameAndSurname')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>{pagination}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <Typography variant="caption">
          {t('project:show.chooserRecruitersToProject')}
        </Typography>
      )}
    </React.Fragment>
  );

  const sortingOptions = null;
  const defaultSorting = {};
  const filteringOptions = [
    {
      key: 'firstName',
      type: 'text',
      name: t('recruiter:field.firstName'),
    },
    {
      key: 'lastName',
      type: 'text',
      name: t('recruiter:field.lastName'),
    },
    {
      key: 'email',
      type: 'text',
      name: t('recruiter:field.email'),
    },
    {
      key: 'industry',
      type: 'text',
      name: t('recruiter:field.industry'),
    },
    {
      key: 'nationality',
      type: 'text',
      name: t('recruiter:field.nationality'),
    },
    {
      key: 'createdAt',
      type: 'text',
      name: t('candidate:field.createdAt'),
      placeholder: 'YYYY-MM-DD',
    },
  ];

  return (
    <Paper className={classes.rootPaper} elevation={0}>
      <Box
        title={t('project:show.list') + ' ' + t('project:show.recruiters')}
        elevation={0}
        variant="backgroundNone"
      >
        <div>
          {props.tabsSection && props.tabsSection}
          <ElementsListAsync
            storageKey={props.storageKey}
            rowsPerPage={getTableRowPerPageFromStorage(props.storageKey)}
            handleReloadElements={props.handleReloadElements}
            load={isLoading}
            getContent={getRecruiterContent}
            getWrapper={getWrapper}
            sortingOptions={sortingOptions}
            defaultSorting={defaultSorting}
            filteringOptions={filteringOptions}
            elements={myRecruiters.data || []}
            elementsCount={myRecruiters.count || 0}
            scrollUp={true}
          />
        </div>
      </Box>
    </Paper>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
    myRecruiters: store.recruiters.myRecruiters,
    recruitersCount: store.recruiters.currentRecruitersLimitedListCounter,
    recruitersInProject: store.recruiters.recruitersInProject,
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectRightListRecruiters)));
