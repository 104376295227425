import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const columnData = [
  { id: 'id', numeric: true, label: 'id' },
  { id: 'name', numeric: false, label: 'klucz' },
  { id: 'value', numeric: false, label: 'wartość' },
  { id: '', numeric: false, label: 'Akcje' },
];

export class AdminConfigViewHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

AdminConfigViewHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default AdminConfigViewHead;
