import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { ButtonJobllegrovLandingButton } from 'shared/utils/styledComponents';

const MenuBarRight = props => {
  const menuHideStatus = path => path === '/auth/authorize/register/:type';

  const { classes, t } = props;
  const hideMenu = menuHideStatus(props.match.path);
  const hideMenuContent = (
    <div className={classes.menuContentLocation}>
      <ButtonJobllegrovLandingButton component={Link} to={PATHS.AUTH.LOGIN}>
        {t('menu:action.login')}
      </ButtonJobllegrovLandingButton>
    </div>
  );
  const menuContent = (
    <div className={classes.menuContentLocation}>
      <div className={classes.menuContentBlockInline}>
        <ButtonJobllegrovLandingButton
          component={Link}
          to={PATHS.AUTH.REGISTER.replace(':type', 'candidate')}
        >
          {t('menu:action.registerCandidateAccount')}
        </ButtonJobllegrovLandingButton>
      </div>
      <div className={classes.menuContentBlockInline}>
        <ButtonJobllegrovLandingButton
          component={Link}
          to={PATHS.AUTH.REGISTER.replace(':type', 'recruiter')}
        >
          {t('menu:action.registerRecruiterAccount')}
        </ButtonJobllegrovLandingButton>
      </div>
      <ButtonJobllegrovLandingButton component={Link} to={PATHS.AUTH.LOGIN}>
        {t('menu:action.login')}
      </ButtonJobllegrovLandingButton>
    </div>
  );

  return hideMenu ? hideMenuContent : menuContent;
};

export default MenuBarRight;
