import { API } from '../constants';
import { ApiService } from './api.service';

export const ProjectCandidateV2Service = {
  editProjectCandidateCareer: (projectId, candidateId, data) => {
    return ApiService.put(
      API.PUT.PROJECT_CANDIDATE_V2.PUT_CAREER.replace(
        ':projectId',
        projectId
      ).replace(':candidateId', candidateId),
      data
    );
  },
  editProjectCandidateRecommendRate: (projectId, candidateId, data) => {
    return ApiService.put(
      API.PUT.PROJECT_CANDIDATE_V2.PUT_RECOMMEND_RATE.replace(
        ':projectId',
        projectId
      ).replace(':candidateId', candidateId),
      data
    );
  },
  editProjectCandidateFeedback: (projectId, candidateId, data) => {
    return ApiService.put(
      API.PUT.PROJECT_CANDIDATE_V2.PUT_FEEDBACK.replace(
        ':projectId',
        projectId
      ).replace(':candidateId', candidateId),
      data
    );
  },
  getProjectCandidateEmploymentProcess: projectId => {
    return ApiService.get(
      API.GET.PROJECT_CANDIDATE_EMPLOYMENT_PROCESS.PROJECT_CANDIDATE_EMPLOYMENT_PROCESS.replace(
        ':projectId',
        projectId
      )
    );
  },
};
