import { FormControlLabel, Grid, Paper, withStyles } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { styles } from './AtsHeader.styles';

export const AtsHeader = ({
  classes,
  t,
  candidatesPerStage,
  sortHeader,
  currentSelectAllColumn,
  sortCell,
  handleChangeSelectAllCheckbox,
  headerObject,
  translateObject,
  stageToMoveCandidateToNextStage,
  handleMoveCandidateToNextStage,
  projectDetails,
}) => {
  const sortComponent = (sortHeader, index) => {
    if (sortHeader[index - 1] === 'off') {
      return <RemoveIcon onClick={() => sortCell(index, 'asc')} />;
    } else if (sortHeader[index - 1] === 'asc') {
      return <ArrowUpwardIcon onClick={() => sortCell(index, 'desc')} />;
    }

    return <ArrowDownwardIcon onClick={() => sortCell(index, 'off')} />;
  };

  const selectAllInColumn = (
    <FormControlLabel
      className={classes.atsIcons}
      control={
        <input
          type="checkbox"
          checked={currentSelectAllColumn === headerObject.obj.number}
          onChange={() =>
            handleChangeSelectAllCheckbox(headerObject.obj.number)
          }
        />
      }
    />
  );

  const { permissions = {} } = projectDetails;
  const {
    isOwner = false,
    isMentor = false,
    isSuperUser = false,
    isProjectOrganizationOwner = false,
    isMentorCoordinator = false,
    isMentorLegalization = false,
  } = permissions;

  const isPermissionToMoveToLegalizationAts =
    isSuperUser ||
    isOwner ||
    (isMentor && (isMentorCoordinator || isMentorLegalization)) ||
    isProjectOrganizationOwner;

  return (
    <Paper
      key={headerObject.i}
      className={classes.paperAtsStage}
      style={{ background: headerObject.obj.color }}
      elevation={0}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.AtsHeaderTitle}
        >
          {t(translateObject(headerObject.obj.name))} (
          {candidatesPerStage[headerObject.obj.number] || 0})
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Grid container justifyContent={'space-evenly'}>
                {headerObject.obj.selectAll && (
                  <div className={classes.iconsAtsHeader}>
                    {selectAllInColumn}
                  </div>
                )}
                <div className={classes.iconsAtsHeader}>
                  {sortComponent(sortHeader, headerObject.obj.number)}
                </div>
              </Grid>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3}>
              {isPermissionToMoveToLegalizationAts &&
              headerObject.obj.number === stageToMoveCandidateToNextStage &&
              projectDetails.projectVersion === 3 ? (
                <Grid container justifyContent={'center'}>
                  <ArrowForwardIcon
                    className={classes.iconsAtsHeader}
                    onClick={handleMoveCandidateToNextStage}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(AtsHeader))
);
