import {
  Paper,
  Typography,
  withStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
} from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import Box from '../../../components/BoxContainer';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import ProjectRightListItem from './ProjectRightListItem';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingBottom: 16,
    paddingLeft: '0px !important',
    paddingRight: '64px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: '16px !important',
      paddingLeft: '16px !important',
    },
  }),
});

export const ProjectRightListCandidates = props => {
  const { classes, isLoading, projectDetails, t, myCandidates } = props;

  const getCandidateContent = candidate => (
    <ProjectRightListItem
      key={candidate.id}
      isLoadingRight={isLoading}
      data={candidate}
      projectDetails={projectDetails}
      onAddHandle={props.onAddCandidateHandle}
      option="candidate"
    />
  );

  const getWrapper = ({ content, pagination, filtering }) => (
    <React.Fragment>
      <ExpansionComponent title={t`menu:filter`} textOnTop={t`menu:searchCan`}>
        {filtering}
      </ExpansionComponent>
      {content.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('project:show.nameAndSurname')}</TableCell>
              <TableCell>{t('project:show.city')}</TableCell>
              <TableCell>{t('project:show.position')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>{pagination}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <Typography variant="caption">
          {t('project:show.chooserCandidatesToProject')}
        </Typography>
      )}
    </React.Fragment>
  );

  const sortingOptions = null;
  const defaultSorting = {};
  const filteringOptions = [
    {
      key: 'firstName',
      type: 'text',
      name: t('candidate:field.firstName'),
    },
    {
      key: 'lastName',
      type: 'text',
      name: t('candidate:field.lastName'),
    },
    {
      key: 'city',
      type: 'text',
      name: t('candidate:field.city'),
    },
    {
      key: 'createdAt',
      type: 'text',
      name: t('candidate:field.createdAt'),
      placeholder: 'YYYY-MM-DD',
    },
    {
      key: 'industry',
      type: 'text',
      name: t('candidate:field.industry'),
    },
    // {
    //     key: 'languages',
    //     type: 'text',
    //     name: t('candidate:field.languages'),
    // },
    // {
    //     key: 'skills',
    //     type: 'text',
    //     name: t('project:field.projectSkills'),
    // },
    {
      key: 'minMonthlyWadges',
      type: 'number',
      name: t('candidate:field.minMonthlyWadges'),
    },
    {
      key: 'maxMonthlyWadges',
      type: 'number',
      name: t('candidate:field.maxMonthlyWadges'),
    },
    {
      key: 'availabilityDate',
      type: 'text',
      name: t('candidate:field.availabilityDate'),
      placeholder: 'YYYY-MM-DD',
    },
  ];

  return (
    <Paper className={classes.rootPaper} elevation={0}>
      <Box
        link={null}
        title={t('project:show.list') + ' ' + t('project:show.candidates')}
        elevation={0}
        variant="backgroundNone"
      >
        <div style={{ overflow: 'scroll' }}>
          {props.tabsSection && props.tabsSection}
          <ElementsListAsync
            storageKey={props.storageKey}
            rowsPerPage={getTableRowPerPageFromStorage(props.storageKey)}
            handleReloadElements={props.handleReloadElements}
            getContent={getCandidateContent}
            getWrapper={getWrapper}
            sortingOptions={sortingOptions}
            defaultSorting={defaultSorting}
            filteringOptions={filteringOptions}
            elements={myCandidates.data || []}
            elementsCount={myCandidates.count || 0}
            scrollUp={true}
          />
        </div>
      </Box>
    </Paper>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectRightListCandidates)));
