import { Snackbar, SnackbarContent } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { Info as InfoIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

export const LayoutSnackBar = props => {
  const {
    open,
    hideSnackbarMessage,
    snackbarMessage,
    variant,
    classes,
    hideSnackbarInformationMessage,
  } = props;

  const transitionLeft = () => {
    return <Slide {...props} direction="left" />;
  };

  return variant === 'information' ? (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      onClose={hideSnackbarInformationMessage}
      TransitionComponent={transitionLeft}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
    >
      <SnackbarContent
        className={classes.content}
        message={
          <span id="client-snackbar" className={classes.message}>
            <InfoIcon className={classes.icon} />
            {snackbarMessage}
          </span>
        }
      />
    </Snackbar>
  ) : (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={hideSnackbarMessage}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{snackbarMessage}</span>}
    />
  );
};

LayoutSnackBar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  hideSnackbarMessage: PropTypes.func.isRequired,
  hideSnackbarInformationMessage: PropTypes.func.isRequired,
  snackbarMessage: PropTypes.string.isRequired,
};

export default LayoutSnackBar;
