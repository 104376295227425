import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CareerItemPanel from './CareerItemPanel';
import { styles } from './PersonalData.style';

const CareerItemsPanel = ({ candidate, projectCandidate, t, classes }) => {
  return (
    <Grid container style={{ padding: '30px 30px 30px 30px' }}>
      <Grid container>
        {candidate.userExperiences.map(userExperience => {
          return (
            <Grid
              key={userExperience.id}
              item
              xs={12}
              sm={4}
              md={4}
              style={{ width: '100%', padding: 10 }}
            >
              <CareerItemPanel
                userExperience={userExperience}
                projectCandidate={projectCandidate}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(CareerItemsPanel));
