export const validateCandidateProfile = values => {
  let validated = true;
  ['email', 'firstName', 'lastName', 'birthDate'].forEach(element => {
    if (!values[element]) {
      validated = false;
    }
  });
  return validated;
};

export default validateCandidateProfile;
