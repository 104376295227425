import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import DataItemListItemCandidate from '../../../components/DataItemListItemCandidate';

const styles = theme => ({
  rowList: {
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    transition: 'all .4s',
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    textDecoration: 'none',
    '&:hover': {
      background: theme.palette.jobllegroUltraLightGrey,
    },
  },
});

export const DataItemCandidate = props => {
  const { data, classes } = props;

  return (
    <div key={data.id} className={classes.rowList}>
      <DataItemListItemCandidate data={data} />
    </div>
  );
};

DataItemCandidate.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataItemCandidate);
