import {
  FormControl,
  FormHelperText,
  Grid,
  withStyles,
} from '@material-ui/core';
import {
  convertFromHTML,
  EditorState,
  ContentState,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { has, get } from 'lodash';
import { Translate } from '..';
import { theme } from '../../../app/App';
import { styles } from './TextEditor.styles';

const TextEditor = ({
  name,
  formik,
  label,
  classes,
  subLabel,
  exampleText,
  disabled,
  variant = 'default',
  ...props
}) => {
  const contentHtml = convertFromHTML(`${formik.initialValues[name]}`);

  const [editorState, setEditorState] = useState(
    contentHtml.contentBlocks !== null &&
      !!formik.initialValues[name] &&
      formik.initialValues[name].trim().length
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(`${formik.initialValues[name]}`)
          )
        )
      : EditorState.createEmpty()
  );
  const backgroundColor = disabled ? 'rgb(243 243 243)' : '';

  useEffect(() => {
    if (!!exampleText) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(exampleText))
        )
      );
    }
  }, [exampleText]);

  const onEditorChange = editorState => {
    const contentHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ).replaceAll(/[\ue000-\uF8FF]/g, '');
    if (contentHtml === '<p></p>\n') {
      formik.setFieldValue(name, '');
    } else {
      formik.setFieldValue(name, contentHtml);
    }
    setEditorState(editorState);
  };

  const isError =
    has(formik, `touched.${name}`) && Boolean(get(formik.errors, name));

  return (
    <Grid container className={classes.container}>
      {label && (
        <Grid container className={classes.title}>
          <Translate text={label} variant="h6" bold />
        </Grid>
      )}
      {subLabel && (
        <Grid container className={classes.title}>
          <Translate text={subLabel} variant="body1" />
        </Grid>
      )}
      <FormControl
        style={
          variant === 'candidateCreatorStage6'
            ? { backgroundColor: 'white', borderRadius: 30 }
            : {}
        }
      >
        <Editor
          handlePastedText={() => false}
          readOnly={disabled}
          onEditorStateChange={onEditorChange}
          name={name}
          editorState={editorState}
          wrapperStyle={{
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${
              isError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)'
            }`,
            borderRadius: 30,
            padding: 10,
            background: backgroundColor,
          }}
          editorStyle={{
            height: 300,
            overflowY: 'scroll',
            background: backgroundColor,
          }}
          toolbarStyle={{ border: 0, background: backgroundColor }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'history',
            ],
          }}
          {...props}
        />
        {isError && (
          <FormHelperText error={isError}>
            {has(formik, `touched.${name}`) && get(formik.errors, name)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(TextEditor);
