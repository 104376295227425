import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { styles } from './OtherExperiencesItem.styles';

const OtherExperiencesItem = ({ history, text, classes, t, ...props }) => {
  return (
    <div className={classes.borderWrapper}>
      <Translate text={text} className={classes.fontStyleSmall} />
    </div>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(OtherExperiencesItem))
);
