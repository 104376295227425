import axios from 'axios';
import { API, API_URL } from '../constants';
import { getAllCompetenceTypes } from '../store/competenceTypes/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getCompetenceTypesWithLimit = (limit, offset, params) => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.COMPETENCE_TYPE.GET_SEARCH,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      limit: limit,
      offset: offset,
      ...params,
    },
  });
};

export const getCompetenceTypes = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.COMPETENCE_TYPE.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllCompetenceTypes(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getCompetenceTypesWithoutDispatch = () => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.COMPETENCE_TYPE.GET,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const deleteCompetenceType = id => {
  return axios({
    method: 'DELETE',
    url: API_URL + API.DELETE.COMPETENCE_TYPE.DELETE.replace(':id', id),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const editCompetenceType = (competenceTypeId, values) => {
  return axios({
    method: 'PUT',
    url:
      API_URL + API.PUT.COMPETENCE_TYPE.EDIT.replace(':id', competenceTypeId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const addCompetenceType = values => {
  return axios({
    method: 'POST',
    url: API_URL + API.POST.COMPETENCE_TYPE.PUT,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};
