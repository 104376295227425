import {
  Grid,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  Hidden,
  withStyles,
  Divider,
} from '@material-ui/core';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getProject,
  getProjectCandidates,
} from 'shared/services/projectService';
import { getUserAvatarSrc } from 'shared/utils/assets';
import ProjectDetailsViewLeftDescription from '../../components/ProjectDetailsViewLeftDescription';
import ProjectStepper from '../../components/ProjectStepper';
import ProjectClose from './ProjectDetails/ProjectClose';
import ProjectMenu from './projectMenu/ProjectMenu';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 8,
  },
  paperContainer: {
    paddingTop: 56,
    paddingLeft: 64,
    paddingRight: 64,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  listItemSecondary: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  primaryListItem: {
    fontSize: '14px !important',
  },
  listItemSecondaryText: {
    fontSize: '12px !important',
    color: theme.palette.jobllegroGrey,
  },
  textHeading: {
    paddingTop: 0,
    paddingBottom: 8,
    color: '#53a3fc',
    fontSize: '24px',
  },
  divider: {
    backgroundColor: theme.palette.jobllegroBorderGrey,
  },
  wrapperForPadding: {
    padding: '16px 150px',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },
});

const PaymentsTableCell = withStyles(theme => ({
  head: {
    fontSize: 16,
    backgroundColor: theme.palette.jobllegroBlue,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  root: {
    padding: '4px 12px 4px 12px',
  },
}))(TableCell);

export class ProjectPaymentsView extends Component {
  _isMounted = false;

  state = {
    fetch: 'IDLE',
  };

  loadData = async id => {
    await this.props.getProject(id);
    await this.props.getProjectCandidates(this.props.project.id);
  };

  async componentDidMount() {
    this._isMounted = true;
    const { id } = this.props.match.params;
    if (this._isMounted) {
      this.setState({
        fetch: 'FETCHING',
      });
    }
    await this.loadData(id);
    if (this._isMounted) {
      this.setState({
        fetch: 'IDLE',
      });
    }
  }

  async componentWillUnmount() {
    this._isMounted = false;
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { id } = nextProps.match.params;
      await this.loadData(id);
    }
  }

  columnCandidate = data => {
    const avatar = getUserAvatarSrc(data);
    const { classes } = this.props;
    return (
      <List>
        <ListItem className={classes.listItem} dense button>
          <Hidden only={['xs']}>
            <Avatar alt={data.firstName + ' ' + data.lastName} src={avatar} />
          </Hidden>
          <ListItemText
            classes={{
              primary: classes.primaryListItem,
            }}
            primary={data.firstName + ' ' + data.lastName}
          />
        </ListItem>
      </List>
    );
  };

  columnRecruiter = data => {
    const { t } = this.props;
    const avatar = getUserAvatarSrc(data);
    const { classes } = this.props;
    return (
      <List className={classes.list}>
        <ListItem
          component={Link}
          to={PATHS.RECRUITER.SHOW.replace(':id', data.id)}
          className={classes.listItem}
          dense
          button
        >
          <Hidden only={['xs']}>
            <Avatar alt={data.firstName + ' ' + data.lastName} src={avatar} />
          </Hidden>
          <ListItemText
            classes={{
              primary: classes.primaryListItem,
            }}
            primary={data.firstName + ' ' + data.lastName}
          />
        </ListItem>
        <ListItem className={classes.listItemSecondary} dense button>
          <ListItemText
            classes={{
              primary: classes.listItemSecondaryText,
              secondary: classes.listItemSecondaryText,
            }}
            primary={
              data.accountNumber &&
              `${t`projectCreator:account`}: ${data.accountNumber}`
            }
            secondary={
              data.phonePrimary &&
              `${t`projectCreator:phone`}: ${data.phonePrimary}`
            }
          />
        </ListItem>
      </List>
    );
  };

  getPayments = () => {
    const { currentUser, project, candidates, classes, t } = this.props;

    const submitted = filter(candidates, candidate => {
      if (
        candidate.project &&
        candidate.project.owner &&
        candidate.project.owner.id === currentUser.id
      ) {
        if (
          candidate.stageNumber === 5 &&
          candidate.owner.id !== currentUser.id
        ) {
          return true;
        }
      } else if (
        candidate.stageNumber === 5 &&
        candidate.owner.id === currentUser.id
      ) {
        return true;
      }
    });

    const winners = filter(candidates, candidate => {
      if (
        candidate.project &&
        candidate.project.owner &&
        candidate.project.owner.id === currentUser.id
      ) {
        if (
          candidate.stageNumber === 6 &&
          candidate.owner.id !== currentUser.id
        ) {
          return true;
        }
      } else if (
        candidate.stageNumber === 6 &&
        candidate.owner.id === currentUser.id
      ) {
        return true;
      }
    });

    const submittedSum = project.revenueSubmitted * submitted.length;
    const winnersSum = project.revenueWinner * winners.length;
    const sum = (submittedSum + winnersSum).toFixed(2);
    const projectRevenueSubmitted = project.revenueSubmitted
      ? project.revenueSubmitted.toFixed(2)
      : project.revenueSubmitted;
    const projectRevenueWinner = project.revenueWinner
      ? project.revenueWinner.toFixed(2)
      : project.revenueWinner;

    return submitted
      .map(({ candidate, owner }) => (
        <TableRow key={candidate.id} className={classes.row}>
          <PaymentsTableCell>{t('project:show.submitted')}</PaymentsTableCell>
          <Hidden only={['xs', 'sm']}>
            <PaymentsTableCell>{this.columnRecruiter(owner)}</PaymentsTableCell>
          </Hidden>
          <PaymentsTableCell>
            {this.columnCandidate(candidate)}
          </PaymentsTableCell>
          <PaymentsTableCell>
            {projectRevenueSubmitted} {t('financial.currencySign')}
          </PaymentsTableCell>
        </TableRow>
      ))
      .concat(
        winners.map(({ candidate, owner }) => (
          <TableRow key={candidate.id} className={classes.row}>
            <PaymentsTableCell>{t('project:show.winner')}</PaymentsTableCell>
            <Hidden only={['xs', 'sm']}>
              <PaymentsTableCell>
                {this.columnRecruiter(owner)}
              </PaymentsTableCell>
            </Hidden>
            <PaymentsTableCell>
              {this.columnCandidate(candidate)}
            </PaymentsTableCell>
            <PaymentsTableCell>
              {projectRevenueWinner} {t('financial.currencySign')}
            </PaymentsTableCell>
          </TableRow>
        ))
      )
      .concat([
        <TableRow key={0}>
          <PaymentsTableCell></PaymentsTableCell>
          <Hidden only={['xs', 'sm']}>
            <PaymentsTableCell></PaymentsTableCell>
          </Hidden>
          <PaymentsTableCell style={{ textAlign: 'right' }}>
            {t('financial.together')} ({t('financial.net')}):
          </PaymentsTableCell>
          <PaymentsTableCell style={{ fontWeight: '900' }}>
            {sum} {t('financial.currencySign')}
          </PaymentsTableCell>
        </TableRow>,
      ]);
  };

  render() {
    const {
      classes,
      project: { permissions = {} },
      project,
      t,
    } = this.props;

    return permissions.isSuperUser ||
      permissions.isOwner ||
      permissions.isCooperator ? (
      <React.Fragment>
        {/*<PageHeader*/}
        {/*    title='Rozliczenia'*/}
        {/*    showBackButton={true}*/}
        {/*    backButtonTarget={lastPage}*/}
        {/*/>*/}
        <Grid
          container
          alignItems="flex-start"
          spacing={0}
          className={classes.wrapperForPadding}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
            <ProjectMenu title={'Rozliczenia'} />
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ProjectDetailsViewLeftDescription
                    title={t('project:show.candidatesInProject')}
                    project={project}
                    buttonComponents={<ProjectClose />}
                    stepperComponent={
                      <ProjectStepper
                        startDate={project.projectStartDate}
                        endDate={project.projectEndDate}
                        marginTop={120}
                        type="wide"
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paperContainer} elevation={0}>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      className={classes.textHeading}
                    >
                      {t('project:show.payments')}
                    </Typography>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <PaymentsTableCell component="th">
                            {t('project:show.atsPosition')}
                          </PaymentsTableCell>
                          <Hidden only={['xs', 'sm']}>
                            <PaymentsTableCell component="th">
                              {t('recruiter:core.recruiter')}
                            </PaymentsTableCell>
                          </Hidden>
                          <PaymentsTableCell component="th">
                            {t('candidate:core.candidate')}
                          </PaymentsTableCell>
                          <PaymentsTableCell component="th">
                            {t('financial.amount')} ({t('financial.net')})
                          </PaymentsTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.fetch === 'FETCHING'
                          ? null
                          : this.getPayments()}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    ) : null;
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    lastPage: store.auth.lastPage,
    candidates: store.candidates.currentCandidatesList,
    project: store.projects.currentProject,
  };
};

const mapDispatchToProps = {
  getProject,
  getProjectCandidates,
};

ProjectPaymentsView.propTypes = {
  project: PropTypes.object.isRequired,
  getProject: PropTypes.func.isRequired,
  getProjectCandidates: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectPaymentsView)));
