import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import NotVisibleIcon from 'shared/assets/img/icons/candidate-show/notVisible.svg';
import VisibilityIcon from 'shared/assets/img/icons/candidate-show/visible.svg';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { getEnumItemNameFullObject } from 'shared/utils/assets';
import { styles } from './DisabledCandidate.styles';

const DisabledCandidate = ({ candidate, t, classes }) => {
  const candidateDisabledStatus = candidate.isCandidateDisabled
    ? t('candidateDetails:core.yes')
    : t('candidateDetails:core.no');
  const isSecondStageInfoPublic = candidate.isSecondStageInfoPublic;
  const disabilityIsConfirm = candidate.disabilityIsConfirm;

  const disabilityWorkPlaceDesc = candidate.disabilityWorkPlaceDesc;

  const disabilityReason = getEnumItemNameFullObject(
    enums.CANDIDATE_DISABLED_REASON,
    candidate.disabilityReason
  );
  const degreeOfDisability = getEnumItemNameFullObject(
    enums.CANDIDATE_DISABLED_DEGREE,
    candidate.degreeOfDisability
  );

  return (
    <Grid container>
      <Grid container alignItems={'center'}>
        <Grid item xs={12} sm={6} md={6}>
          <Translate
            text={`${t('candidateCreator:step5.isCandidateDisabledTitle')}:`}
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container alignItems={'center'}>
            <Grid item xs={3} sm={3} md={3}>
              <Translate
                text={candidateDisabledStatus}
                bold
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <Grid
                container
                alignItems={'center'}
                style={{ padding: '15px 0px' }}
                justifyContent={'space-evenly'}
              >
                <Grid item xs={3} sm={3} md={3} style={{ minWidth: 50 }}>
                  {isSecondStageInfoPublic && disabilityIsConfirm ? (
                    <img alt="VisibilityIcon" src={VisibilityIcon} />
                  ) : (
                    <img
                      alt="isConfirm"
                      src={
                        isSecondStageInfoPublic && disabilityIsConfirm
                          ? VisibilityIcon
                          : NotVisibleIcon
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <Translate
                    text={
                      isSecondStageInfoPublic && disabilityIsConfirm
                        ? 'candidateDetails:personalDetailsContent.visible'
                        : 'candidateDetails:personalDetailsContent.invisible'
                    }
                    style={{ fontSize: 20 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isSecondStageInfoPublic && disabilityIsConfirm ? (
        <Grid container>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} />
          <Grid item xs={12} sm={12} md={9}>
            <Grid container style={{ padding: '15px 0px' }}>
              <Grid container>
                <Grid item xs={12} sm={7} md={7}>
                  <Translate
                    text={
                      'candidateDetails:personalDetailsContent.degreeOfDisability'
                    }
                    style={{ fontSize: 20 }}
                  />
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <Translate
                    text={degreeOfDisability}
                    style={{ fontSize: 20 }}
                  />
                </Grid>
              </Grid>
              {disabilityReason ? (
                <Grid container>
                  <hr className={classes.separator} />
                </Grid>
              ) : null}
              {disabilityReason ? (
                <Grid container>
                  <Grid item xs={12} sm={7} md={7}>
                    <Translate
                      text={'candidateCreator:step5.disabilityReason'}
                      style={{ fontSize: 20 }}
                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5}>
                    <Translate
                      text={disabilityReason}
                      style={{ fontSize: 20 }}
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container>
                <hr className={classes.separator} />
              </Grid>
              {disabilityWorkPlaceDesc ? (
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Translate
                      text={
                        'candidateDetails:personalDetailsContent.disabilityWorkPlaceDesc'
                      }
                      style={{ fontSize: 20 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Translate
                      style={{
                        fontSize: 20,
                      }}
                      disableTranslate={true}
                      text={ReactHtmlParser(disabilityWorkPlaceDesc)}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(DisabledCandidate));
