import {
  DialogContent,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { editProject } from 'shared/services/projectService';
import { intNormalization } from 'shared/utils/normalizers';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';
import validate from './validate/validateMentorFee';

const styles = theme => ({
  form: {
    display: 'flex',
  },
});

class MentorFeeDialog extends Component {
  handleSubmitTrigger = async values => {
    try {
      await this.props.editProject(values);
      window.location.reload();
    } catch (error) {}
  };

  render() {
    const { handleSubmit, t, classes, valid, submitting } = this.props;
    return (
      <DialogContent>
        <Typography variant="subtitle1">{t`project.mentorTxt`}</Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(this.handleSubmitTrigger)}
        >
          <Field
            label={t('project:field.mentorFee')}
            name="mentorFee"
            inputProps={{ min: 0, maxLength: 255 }}
            component={TextField}
            normalize={intNormalization}
            fullWidth
          />
          <ButtonJobllegrov1 type="submit" disabled={submitting || !valid}>
            {t`project.save`}
          </ButtonJobllegrov1>
        </form>
      </DialogContent>
    );
  }
}

const afterSubmit = (result, dispatch) => {
  if (result && result.status === 200) {
    dispatch(reset('projectCreatorForm'));
  }
};

const mapStateToProps = (state, props) => {
  return {
    projectDetails: state.projects.currentProject,
    initialValues: { scope: 2, id: state.projects.currentProject.id },
  };
};

const MentorFeeDialogForm = reduxForm({
  form: 'mentorFeeForm',
  validate,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(MentorFeeDialog);

const mapDispatchToProps = {
  editProject,
};

MentorFeeDialog.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(MentorFeeDialogForm)));
