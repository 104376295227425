import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { authUser, registerRecruiter } from 'shared/services/authService';
import { getConfigs } from 'shared/services/configService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import Register from './Register.style';

const mapStateToProps = store => {
  return {
    isFetching: store.auth.isFetching,
    isAuthorized: store.auth.isAuthorized,
    config: store.auth.config,
  };
};

const mapDispatchToProps = {
  authUser,
  getConfigs,
  registerRecruiter,
  showSnackbarMessage,
};

const RegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Register)));

RegisterContainer.displayName = 'RegisterContainer';

export default RegisterContainer;
