import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';

export class ProjectMakeCopyButton extends Component {
  render() {
    const {
      project: { id, permissions = {} },
      t,
    } = this.props;
    return permissions.isOwner ? (
      <ActionButtonJobllegroRounded
        component={Link}
        to={PATHS.PROJECT.CREATE_FROM.replace(':id', id)}
      >
        {t('project:form.copyProject')}
      </ActionButtonJobllegroRounded>
    ) : null;
  }
}

const mapStateToProps = store => {
  return {
    project: store.projects.currentProject,
  };
};

ProjectMakeCopyButton.propTypes = {
  project: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(ProjectMakeCopyButton));
