export const styles = theme => ({
  paper: {
    marginBottom: 25,
  },
  imgWrapper: { padding: '20px 20px' },
  rightSectionProject: {
    boxSizing: 'border-box',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  companyLogotype: {
    width: '100%',
    height: 'auto',
  },
  projectPosition: {
    color: theme.palette.jobllegroBlue,
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  projectClient: {
    color: theme.palette.jobllegroGrey,
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  projectWww: {
    color: theme.palette.jobllegroBlue,
    '& > a': {
      color: theme.palette.jobllegroBlue,
      fontSize: 13,
      textDecoration: 'none',
    },
  },
  projectInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2.5),
  },
  gridItem: { padding: '4px 0' },
  icon: { width: 30, height: 30, paddingRight: 8 },
  iconWrapper: { display: 'flex', alignItems: 'center' },
});
