import { withStyles, IconButton, withWidth } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { PATHS } from 'config';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';

const styles = theme => ({
  addCandidateButton: {
    [theme.breakpoints.between('xs', 'sm')]: {
      right: 10,
      bottom: 10,
      position: 'fixed',
      width: 50,
      height: 50,
      background: '#fcc93c',
      color: '#ffffff',
      zIndex: '99999',
    },
  },
});

export class CandidateAdd extends Component {
  render() {
    const { classes, t, width } = this.props;

    return width === 'xs' || width === 'sm' ? (
      <IconButton
        className={classes.addCandidateButton}
        component={Link}
        to={PATHS.CANDIDATE.CREATE}
      >
        <AddIcon style={{ width: 100 }} />
      </IconButton>
    ) : (
      <ButtonJobllegrov1 component={Link} to={PATHS.CANDIDATE.CREATE}>
        {t('candidate:form.candidateCreator')}
      </ButtonJobllegrov1>
    );
  }
}

CandidateAdd.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withWidth())(
  withStyles(styles)(withTranslation()(CandidateAdd))
);
