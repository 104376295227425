import moment from 'moment';
import { object, string, bool, number, date } from 'yup';

export const MODEL = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phonePrimary: 'phonePrimary',
  phonePrimaryCode: 'phonePrimaryCode',
  country: 'country',
  position: 'position',
  city: 'city',
  isRemoteWorkOnly: 'isRemoteWorkOnly',
  industryCategory: 'industryCategory',
  department: 'department',
  homeCountry: 'homeCountry',
  companySize: 'companySize',
  availabilityDate: 'availabilityDate',
  isPublic: 'isPublic',
  availabilityTypeCandidate: 'availabilityTypeCandidate',
  isForeigner: 'isForeigner',
  isFistStageInfoPublic: 'isFistStageInfoPublic',
  citizenship: 'citizenship',
  passportNumber: 'passportNumber',
  passportSerialNumber: 'passportSerialNumber',
  birthYear: 'birthYear',
  isForeignerInEmploymentArea: 'isForeignerInEmploymentArea',
  residenceTitle: 'residenceTitle',
  plannedResidenceTitle: 'plannedResidenceTitle',
  arrivalDateEmploymentCountry: 'arrivalDateEmploymentCountry',
  isMen: 'isMen',
  isWomen: 'isWomen',
};

export const initialValues = {
  phonePrimaryCode: { id: '+48', value: '+48', label: '+48' },
  isFistStageInfoPublic: false,
  isForeigner: false,
  isMen: false,
  isWomen: false,
  citizenship: undefined,
  plannedResidenceTitle: null,
  firstName: '',
  lastName: '',
  email: '',
  phonePrimary: '',
  country: null,
  homeCountry: null,
  department: null,
  position: null,
  city: null,
  isRemoteWorkOnly: false,
  industryCategory: null,
  companySize: null,
  availabilityDate: moment().format('YYYY-MM-DD'),
  isPublic: true,
  availabilityTypeCandidate: '',
  passportNumber: '',
  passportSerialNumber: '',
  birthYear: null,
  isForeignerInEmploymentArea: false,
  arrivalDateEmploymentCountry: '',
  residenceTitle: null,
};

const phoneRegex = RegExp(
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
);
export const validationSchema = (t, isEdit, initialCandidateValues) => {
  const minDate =
    isEdit &&
    initialCandidateValues.availabilityDate &&
    moment().isAfter(initialCandidateValues.availabilityDate, 'day')
      ? moment(initialCandidateValues.availabilityDate).startOf('day')
      : moment().startOf('day');

  return object().shape(
    {
      firstName: string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      lastName: string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      phonePrimaryCode: object()
        .shape({
          id: number()
            .nullable()
            .required(t('projectCreator:core.requiredField')),
        })
        .nullable()
        .required(t('projectCreator:core.requiredField')),

      email: string().when(['phonePrimary', 'isForeigner'], {
        is: (phonePrimary, isForeigner) =>
          (!phonePrimary || phonePrimary.length === 0) && isForeigner === false,
        then: string()
          .email(t('candidateCreator:step1.invalidEmail'))
          .required(t('projectCreator:core.requiredField')),
        otherwise: isEdit
          ? string()
              .email(t('candidateCreator:step1.invalidEmail'))
              .required(t('projectCreator:core.requiredField'))
          : string().email(t('candidateCreator:step1.invalidEmail')),
      }),
      phonePrimary: string().when(['email', 'isForeigner'], {
        is: (email, isForeigner) =>
          !email || email.length === 0 || isForeigner === true,
        then: string()
          .matches(phoneRegex, t('candidateCreator:step1.invalidPhoneNumber'))
          .nullable()
          .trim()
          .min(9, t('candidateCreator:step1.invalidPhoneNumber'))
          .max(10, t('candidateCreator:step1.invalidPhoneNumber'))
          .required(t('projectCreator:core.requiredField')),
        otherwise: string()
          .matches(phoneRegex, t('candidateCreator:step1.invalidPhoneNumber'))
          .min(9, t('candidateCreator:step1.invalidPhoneNumber'))
          .max(10, t('candidateCreator:step1.invalidPhoneNumber'))
          .nullable(),
      }),
      birthYear: string().nullable(),
      position: string().strict(false).nullable().trim(),
      city: string().strict(false).nullable().trim(),
      country: object()
        .shape({
          id: number().required(t('projectCreator:core.requiredField')),
        })
        .nullable(),
      industryCategory: object()
        .shape({
          id: number().required(t('projectCreator:core.requiredField')),
        })
        .nullable(),
      department: object()
        .shape({
          id: number().required(t('projectCreator:core.requiredField')),
        })
        .nullable(),
      isForeigner: bool(),
      isForeignerInEmploymentArea: bool(),
      isMen: bool(),
      isWomen: bool(),
      isFistStageInfoPublic: bool(),
      citizenship: object().when('isForeigner', {
        is: isForeigner => isForeigner === true,
        then: object()
          .shape({
            id: number().required(t('projectCreator:core.requiredField')),
          })
          .nullable()
          .required(t('projectCreator:core.requiredField')),
        otherwise: object().nullable(),
      }),

      passportSerialNumber: string().when('isForeigner', {
        is: isForeigner => isForeigner === true,
        then: string()
          .strict(false)
          .trim()
          .nullable()
          .required(t('projectCreator:core.requiredField')),
        otherwise: string().nullable(),
      }),

      passportNumber: string().when('isForeigner', {
        is: isForeigner => isForeigner === true,
        then: string()
          .strict(false)
          .trim()
          .nullable()
          .required(t('projectCreator:core.requiredField')),
        otherwise: string().nullable(),
      }),

      plannedResidenceTitle: string().when('isForeigner', {
        is: isForeigner => isForeigner === true,
        then: string()
          .nullable()
          .required(t('projectCreator:core.requiredField')),
        otherwise: string().nullable(),
      }),
      residenceTitle: string().when('isForeignerInEmploymentArea', {
        is: isForeignerInEmploymentArea => isForeignerInEmploymentArea === true,
        then: string()
          .nullable()
          .required(t('projectCreator:core.requiredField')),
        otherwise: string().nullable(),
      }),

      arrivalDateEmploymentCountry: string().when(
        'isForeignerInEmploymentArea',
        {
          is: isForeignerInEmploymentArea =>
            isForeignerInEmploymentArea === true,
          then: string()
            .strict(false)
            .trim()
            .nullable()
            .required(t('projectCreator:core.requiredField')),
          otherwise: string().nullable(),
        }
      ),

      homeCountry: object()
        .shape({
          id: number(),
        })
        .nullable(),
      companySize: string().nullable(),

      availabilityTypeCandidate: string(),
      availabilityDate: date()
        .min(minDate, `${t`candidate:dateVal`} ${minDate.format('YYYY-MM-DD')}`)
        .required(t('projectCreator:core.requiredField')),
    },
    [
      ['phonePrimary', 'email'],
      ['availabilityDate', 'availabilityTypeCandidate'],
      ['passportNumber', 'passportSerialNumber', 'isForeigner', 'citizenship'],
      [
        'isForeignerInEmploymentArea',
        'residenceTitle',
        'arrivalDateEmploymentCountry',
      ],
      'email',
    ]
  );
};
