export const styles = () => ({
  mainWindow: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '25rem',
    height: '25rem',
    overflow: 'hidden',
  },
  cropContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 80,
  },
  controls: {
    position: 'absolute',
    left: '50%',
    width: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
  },
  paper: {
    overflowX: 'hidden',
  },
});
