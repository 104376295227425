import {
  SwipeableDrawer,
  Grid,
  AppBar,
  Hidden,
  Toolbar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';
import LanguagesChange from '../../../../components/LanguagesChange/';
import MenuBarLeft from './../MenuBarLeft/';
import MenuBarRight from './../MenuBarRight/';
import MenuRightDrawerRight from './../MenuRightDrawerRight/';

export class MenuBar extends Component {
  state = {
    right: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="static" className={classes.MenuBar}>
        <div className={classes.languageContainer}>
          <LanguagesChange version="dots" />
        </div>
        <Toolbar className={classes.toolBar}>
          <Grid
            container
            justifyContent="flex-start"
            className={classes.toolBarContainer}
          >
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <MenuBarLeft />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={8} align="right">
              <Hidden only={['xs', 'sm']}>
                <MenuBarRight />
              </Hidden>
              <Hidden only={['md', 'xl', 'lg']}>
                <ButtonJobllegrov1 onClick={this.toggleDrawer('right', true)}>
                  Menu
                </ButtonJobllegrov1>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
        <SwipeableDrawer
          className={classes.list}
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('right', false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            <MenuRightDrawerRight />
          </div>
        </SwipeableDrawer>
      </AppBar>
    );
  }
}

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MenuBar;
