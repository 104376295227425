import { API } from '../constants';
import { ApiService } from './api.service';

export const CompetenceService = {
  getCompetences(params = {}) {
    return ApiService.get(API.GET.COMPETENCE_TEMP.GET, params);
  },
};

export default CompetenceService;
