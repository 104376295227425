import { CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import NotVisibleIcon from 'shared/assets/img/icons/candidate-show/notVisible.svg';
import { StarRating } from '../../../../components/TopAppBar/StarRating';
import CandidateVerifyBy from '../../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateVerifyBy';
import {
  getCandidateInProject,
  getProjectCandidates,
  ProjectCandidateV2Service,
} from '../../../services';
import { Translate } from '../../index';
import { styles } from '../ProjectAtsCandidateInProjectRecommend.styles';

const ProjectAtsCandidateInProjectRecommendHeader = ({
  projectCandidate,
  candidate,
  currentProject,
  t,
  ...props
}) => {
  const [currentRate, setCurrentRate] = useState(0);

  useEffect(() => {
    if (
      projectCandidate &&
      projectCandidate.recommendRate !== 0 &&
      projectCandidate.recommendRate !== null
    ) {
      setCurrentRate(projectCandidate.recommendRate);
    }
  }, projectCandidate);

  const changeRating = (newRating, name) => {
    const data = {
      recommendRate: newRating,
    };
    ProjectCandidateV2Service.editProjectCandidateRecommendRate(
      projectCandidate.project.id,
      projectCandidate.candidate.id,
      data
    )
      .then(() => {
        setCurrentRate(newRating);
        props.getCandidateInProject(projectCandidate.project.id, candidate.id);
        props.getProjectCandidates(projectCandidate.project.id);
      })
      .catch(error => {
        console.log(error);
        alert(t`newAts:careerPanelView.careerHeader`);
      });
  };

  if (!currentProject || !projectCandidate) {
    return null;
  }
  const { permissions } = currentProject;

  return (
    <Grid container>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <img
          alt="NotVisibleIcon"
          src={NotVisibleIcon}
          style={{ paddingRight: 10 }}
        />
        <Translate
          style={{
            fontSize: 18,
          }}
          text={t`newAts:careerPanelView.careerHeader`}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Grid container>
          <CandidateVerifyBy candidate={candidate} />
        </Grid>
      </Grid>
      {(permissions.isOwner ||
        permissions.isSuperUser ||
        projectCandidate.owner.id === props.currentUser.id) &&
      projectCandidate.fromRecommend ? (
        <Grid item xs={6} sm={6} md={6}>
          <Grid container>
            <CardContent>
              <Typography variant="h6">{t`newAts:careerPanelView.rate`}</Typography>
              <StarRating
                name="recommendRate"
                numberOfStars={5}
                rating={currentRate}
                changeRating={changeRating}
              />
            </CardContent>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapDispatchToProps = {
  getCandidateInProject,
  getProjectCandidates,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    withTranslation()(ProjectAtsCandidateInProjectRecommendHeader)
  )
);
