import React, { Component } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import './css/style.css';

export class PieChartv1 extends Component {
  fakeData = [
    { name: '', value: 100 },
    { name: '', value: 100 },
  ];

  COLORS = ['#23d0d4', '#eaeaea'];

  renderCustomizedLabel = data => {
    const { cx, cy } = data;
    return (
      <text
        x={cx}
        y={cy}
        dy={6}
        textAnchor={'middle'}
        fill={'#575757'}
        style={{
          fontFamily: 'Barlow Semi Condensed, sans-serif',
          fontSize: 13,
        }}
      >
        {this.props.counter}
      </text>
    );
  };

  render() {
    const data =
      this.props.data === undefined ? this.fakeData : this.props.data;
    const content = data.map((entry, index) => (
      <Cell key={index} fill={this.COLORS[index % this.COLORS.length]} />
    ));

    return (
      <PieChart width={60} height={60} onMouseEnter={this.onPieEnter}>
        <Pie
          isAnimationActive={false}
          data={data}
          dataKey="value"
          cx={25}
          cy={25}
          innerRadius={20}
          outerRadius={25}
          fill="#8884d8"
          paddingAngle={5}
          labelLine={false}
          label={this.renderCustomizedLabel}
        >
          {content}
        </Pie>
      </PieChart>
    );
  }
}

export default PieChartv1;
