import { Grid, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import {
  getProject,
  clearCurrentProject,
} from 'shared/services/projectService';
import PageHeader from '../../components/PageHeader';
import ProjectDetails from './ProjectDetails';
import ProjectMenu from './projectMenu/ProjectMenu';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 8,
  },
});

export class ProjectShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingProject: false,
    };
  }

  loadProject = async id => {
    try {
      this.setState({ isLoadingProject: true });
      await this.props.clearCurrentProject();
      await this.props.getProject(id);
      this.setState({
        isLoadingProject: false,
      });
    } catch (err) {
      this.setState({ isLoadingProject: false });
      console.error(err);
    }
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    this.props.history.push(PATHS.PROJECT.NEW_SHOW.replace(':id', id));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { id } = nextProps.match.params;
      this.loadProject(id);
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentProject();
  }

  render() {
    const { classes, lastPage, t } = this.props;
    const { isLoadingProject } = this.state;
    return (
      <React.Fragment>
        <PageHeader
          title={t('project:show.pageTitle')}
          showBackButton={true}
          backButtonTarget={lastPage}
        />
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
            <ProjectMenu />
            <Paper className={classes.rootPaper} elevation={0}>
              <ProjectDetails isLoading={isLoadingProject} />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    lastPage: store.auth.lastPage,
  };
};

const mapDispatchToProps = {
  getProject,
  clearCurrentProject,
};

ProjectShow.propTypes = {
  classes: PropTypes.object.isRequired,
  getProject: PropTypes.func.isRequired,
  clearCurrentProject: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectShow)));
