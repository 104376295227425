import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import RecruiterMenu from '../../../recruiterMenu/RecruiterMenu';
import { styles } from './RecruiterPersonalDetailsContent.styles';
import RecruiterPresonalDetailsCenter from './components/Center';
import PublicSwitch from './components/PublicSwitch';
import RecruiterPresonalDetailsTop from './components/Top';

const RecruiterPersonalDetailsContent = ({
  recruiterDetails,
  recruiterEdited,
  classes,
  currentUser,
  t,
}) => {
  return (
    <Grid container className={classes.wrapper}>
      <RecruiterMenu
        recruiterId={recruiterDetails.id}
        itemSelected="personal-data"
        title={'candidateDetails:anonymousRecruiterDetailsCard:menuTitle'}
        subtitle={'candidateDetails:anonymousRecruiterDetailsCard:subTitle'}
        currentRecruiter={recruiterDetails}
        currentUser={currentUser}
      />
      <RecruiterPresonalDetailsTop
        recruiterData={recruiterDetails}
        recruiterEdited={recruiterEdited}
      />
      <PublicSwitch
        recruiterData={recruiterDetails}
        recruiterEdited={recruiterEdited}
      />
      <RecruiterPresonalDetailsCenter
        recruiterData={recruiterDetails}
        recruiterEdited={recruiterEdited}
      />
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(RecruiterPersonalDetailsContent))
);
