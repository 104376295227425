import { Grid, Paper, withStyles } from '@material-ui/core';
import { get, isNil } from 'lodash';
import React from 'react';
import { Translate } from 'shared/newComponents';
import { MODEL } from '../ProjectCreateWizardStep7/ProjectCreateWizardStep7.utils';
import { styles } from './ProjectReview.style';
import {
  ProjectReviewStep1,
  ProjectReviewStep2,
  ProjectReviewStep3,
  ProjectReviewStep4,
  ProjectReviewStep5,
  ProjectReviewStep6,
  ProjectReviewStep7,
} from './components';

const ProjectReview = ({
  classes,
  step1FormValues,
  step2FormValues,
  step3FormValues,
  step4FormValues,
  step5FormValues,
  step6FormValues,
  step7FormValues,
  assetPreview,
}) => {
  const calcCandidateExperience = get(
    step7FormValues,
    MODEL.calcCandidateExperience
  );
  const calcServiceCompletionDate = get(
    step7FormValues,
    MODEL.calcServiceCompletionDate
  );
  const calcPaymentDeadline = get(step7FormValues, MODEL.calcPaymentDeadline);
  const calcCandidateWarranty = get(
    step7FormValues,
    MODEL.calcCandidateWarranty
  );
  const hintForRecruiter = get(step7FormValues, MODEL.hintForRecruiter);

  const showStep7 =
    !isNil(calcCandidateExperience) ||
    !isNil(calcServiceCompletionDate) ||
    !isNil(calcPaymentDeadline) ||
    !isNil(calcCandidateWarranty) ||
    hintForRecruiter;

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container className={classes.title}>
          <Translate
            style={{ color: '#27D38B', fontSize: 26 }}
            text="projectCreator:core.reviewTitle"
            bold
            variant="h5"
          />
        </Grid>
        <ProjectReviewStep1
          step1FormValues={step1FormValues}
          assetPreview={assetPreview}
        />
        <ProjectReviewStep2 step2FormValues={step2FormValues} />
        <ProjectReviewStep4 step4FormValues={step4FormValues} />
        <ProjectReviewStep3 step3FormValues={step3FormValues} />
        <ProjectReviewStep5 step5FormValues={step5FormValues} />
        <ProjectReviewStep6 step6FormValues={step6FormValues} />
      </Paper>
      {showStep7 && (
        <Paper className={classes.paper}>
          <ProjectReviewStep7 step7FormValues={step7FormValues} />
        </Paper>
      )}
    </Grid>
  );
};

export default withStyles(styles)(ProjectReview);
