import {
  Avatar,
  Badge,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Lock as LockIcon, Widgets as WidgetsIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getProjectsPoAndCoWithLimit,
  getProjectsWithLimit,
  addProjectCandidate,
} from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import {
  getProjectAssetSrcByType,
  getUserAvatarSrc,
} from 'shared/utils/assets';
import {
  ButtonJobllegrov5,
  ActionButtonJobllegroRounded,
} from 'shared/utils/styledComponents';
import ElementList from './list/ElementsList';

const styles = theme => ({
  button: {
    marginTop: 15,
    color: '#fff',
    float: 'right',
    background: '#52a3fc',
    borderRadius: '3px',
    '&:hover': {
      background: '#70b3ff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  avatar: {
    width: 60,
    height: 60,
  },
  avatarIcon: {
    background: theme.palette.jobllegroBlue,
  },
  dialogContent: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  widgetsIcon: {
    color: theme.palette.jobllegroBlue,
  },
  badgeLocked: {
    top: 'calc(60%)',
    left: '0',
    background: theme.palette.jobllegroRedAlert,
  },
  chip: {
    background: 'none',
    fontSize: 16,
    '&:hover': {
      background: 'transparent',
      color: theme.palette.jobllegroBlue,
    },
  },
  iconButtonBox: {
    fontSize: 9,
    color: theme.palette.jobllegroBlue,
    padding: 0,
    textTransform: 'uppercase',
    '&:hover': {
      background: 'transparent',
    },
  },
});

export class AssignCandidateToProjectsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
    };
  }
  async componentDidMount() {
    if (this.props.open) {
      await this.handleClickOpen();
    }
  }

  loadProjects = async (
    limit = null,
    offset = null,
    filters = null,
    orderBy = null
  ) => {
    const { candidate, selectedCandidates } = this.props;
    let params = {
      filters,
      orderBy,
    };
    const candidatesToSearch = selectedCandidates
      ? selectedCandidates.map(item => item.candidate.id)
      : null;
    candidate
      ? await this.props.getProjectsPoAndCoWithLimit(
          limit,
          offset,
          params,
          candidate.id
        )
      : await this.props.getProjectsPoAndCoWithLimit(
          limit,
          offset,
          params,
          candidatesToSearch
        );
  };

  handleClickOpen = async () => {
    await this.loadProjects();
    this.setState({ open: true });
  };

  handleClose = async () => {
    this.setState({ open: false });
    if (this.props.selectedCandidates) {
      await this.props.handleCloseSetCandidateInProject(false);
    }
  };

  addProjectCandidates = async projectId => {
    try {
      this.setState({ isLoading: true });
      const { t, selectedCandidates } = this.props;
      let promiseArray = [];
      selectedCandidates.forEach(item => {
        promiseArray.push(
          this.props.addProjectCandidate(projectId, item.candidate.id)
        );
      });
      await Promise.all(promiseArray);
      await this.loadProjects();
      this.setState({ isLoading: false });
      this.props.showSnackbarMessage(
        t('candidate:form.candidateWasAddedToProject')
      );
    } catch (err) {
      console.error(err);
    }
  };

  addProjectCandidate = async (projectId, candidateId) => {
    try {
      this.setState({ isLoading: true });
      const { t } = this.props;
      await this.props.addProjectCandidate(projectId, candidateId);
      await this.loadProjects();
      this.setState({ isLoading: false });
      this.props.showSnackbarMessage(
        t('candidate:form.candidateWasAddedToProject')
      );
    } catch (err) {
      console.error(err);
    }
  };

  getContent = project => {
    const logo = getProjectAssetSrcByType(project, 'logo');
    const { t, classes, candidate } = this.props;
    const { isLoading } = this.state;

    return (
      project && (
        <ListItem
          key={project.id}
          style={
            project.permissions.isOwner ||
            project.isMentor ||
            project.isCooperator
              ? { backgroundColor: '#c6fbd4' }
              : null
          }
        >
          {project.visibility === 1 ? (
            <Badge
              badgeContent={<LockIcon style={{ width: 15 }} />}
              color="primary"
              classes={{ badge: classes.badgeLocked }}
            >
              <Avatar
                component={Link}
                to={PATHS.PROJECT.SHOW.replace(':id', project.id)}
                className={classes.avatar}
                src={logo}
                aria-label={project.position}
              />
            </Badge>
          ) : (
            <Avatar
              component={Link}
              to={PATHS.PROJECT.SHOW.replace(':id', project.id)}
              className={classes.avatar}
              src={logo}
              aria-label={project.position}
            />
          )}
          <ListItemText
            primary={project.position}
            secondary={project.industry}
          />
          <ButtonJobllegrov5
            disabled={isLoading}
            onClick={() => {
              if (candidate) {
                return this.addProjectCandidate(project.id, candidate.id);
              } else {
                return this.addProjectCandidates(project.id);
              }
            }}
          >
            {isLoading ? (
              <CircularProgress
                style={{ width: 20, height: 20, color: '#fff' }}
              />
            ) : (
              t('core:action.add')
            )}
          </ButtonJobllegrov5>
        </ListItem>
      )
    );
  };

  handleClickOpenProjectCreator = () => {
    this.props.history.push({
      pathname: PATHS.PROJECT.NEW_CREATE,
    });
  };
  getWrapper = options => {
    let { content, pagination } = options;
    const { candidate, classes, selectedCandidates, t } = this.props;

    const avatar = getUserAvatarSrc(candidate);
    let candidatesInfo = '';
    if (selectedCandidates) {
      selectedCandidates.forEach((item, index) => {
        if (item.candidate.firstName && item.candidate.lastName) {
          candidatesInfo += `${item.candidate.firstName} ${item.candidate.lastName}`;
        } else {
          candidatesInfo += t`assign:noData`;
        }

        if (index !== selectedCandidates.length - 1) {
          candidatesInfo += ', ';
        }
      });
    } else {
      candidatesInfo = null;
    }
    const candidateDetails =
      candidate && candidate.firstName && candidate.lastName
        ? `${candidate.firstName} ${candidate.lastName}`
        : t`assign:noData`;
    return (
      <React.Fragment>
        {candidate && (
          <List>
            <ListItem>
              <Grid container justifyContent={'space-between'}>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Avatar
                    className={classes.avatar}
                    src={avatar}
                    aria-label={candidateDetails}
                  />
                  <ListItemText
                    primary={candidateDetails}
                    secondary={candidate.email}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ButtonJobllegrov5
                    style={{ padding: 10 }}
                    onClick={this.handleClickOpenProjectCreator}
                  >
                    {t('project:form.projectCreator')}
                  </ButtonJobllegrov5>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        )}
        {selectedCandidates && (
          <List
            style={{
              wordBreak: 'breakWord',
              maxWidth: '100%',
            }}
          >
            <ListItem>
              <ListItemText primary={candidatesInfo} />
            </ListItem>
          </List>
        )}
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <List component={'nav'}>
            {isEmpty(content) ? (
              <ListItem>
                <Typography variant="body1">
                  {t('project:list.noProjectsForCandidate', candidate)}
                </Typography>
              </ListItem>
            ) : (
              content
            )}
          </List>
        </DialogContent>
        {!isEmpty(content) && pagination}
      </React.Fragment>
    );
  };

  render() {
    const {
      currentProjectsLimitedList,
      classes,
      option,
      t,
      btnColor = '#52A3FC',
    } = this.props;
    return (
      <React.Fragment>
        {option === 'box' && (
          <IconButton
            className={classes.iconButtonBox}
            style={{ width: 'auto', height: 'auto' }}
            aria-label={t('candidate:show.addCandidateToProjects')}
            onClick={this.handleClickOpen}
          >
            <WidgetsIcon className={classes.widgetsIcon} />
            {t('candidate:list.projectAssign')}
          </IconButton>
        )}
        {option === 'list' && (
          <Chip
            className={classes.chip}
            avatar={
              <Avatar className={classes.avatarIcon}>
                <WidgetsIcon style={{ color: '#fff' }} />
              </Avatar>
            }
            label={t('candidate:show.addCandidateToProjects')}
            onClick={this.handleClickOpen}
          />
        )}
        {option === 'button' && (
          <ActionButtonJobllegroRounded
            style={{ background: btnColor }}
            onClick={this.handleClickOpen}
          >
            {t('candidate:show.addCandidateToProjects')}
          </ActionButtonJobllegroRounded>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('candidate:list.addToProject')}
          </DialogTitle>
          <ElementList
            elements={currentProjectsLimitedList}
            getContent={this.getContent}
            getWrapper={this.getWrapper}
          />
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t('core:action.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentProjectsLimitedList: store.projects.currentProjectsLimitedList,
  };
};

const mapDispatchToProps = {
  getProjectsPoAndCoWithLimit,
  getProjectsWithLimit,
  addProjectCandidate,
  showSnackbarMessage,
};

AssignCandidateToProjectsButton.propTypes = {
  classes: PropTypes.object.isRequired,
  getProjectsPoAndCoWithLimit: PropTypes.func.isRequired,
  currentProjectsLimitedList: PropTypes.any,
  addProjectCandidate: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    withTranslation()(withRouter(AssignCandidateToProjectsButton))
  )
);
