const regex = {
  phone: new RegExp(/^\d+$/),
  email: new RegExp(/^\S+@\S+$/),
};
export default class validationRegexTest {
  static validPhone = value => regex.phone.test(value);
  static validMail = value => regex.email.test(value);
}

export const required = value => {
  if (value && typeof value !== 'number') {
    return value.trim().length ? undefined : 'Pole wymagane';
  }
  return value || typeof value === 'number' ? undefined : 'Pole wymagane';
};
