import axios from 'axios';
import { push } from 'react-router-redux';
import { PATHS } from 'config';
import { API, API_URL } from '../constants';
import { setNotificationsListAction } from '../store/notifications/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getNotifications = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.NOTIFICATION.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setNotificationsListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 403) {
          dispatch(push(PATHS.AUTH.LOGOUT));
        }
        return { data: {}, status };
      });
  };
};

export const setNotificationRead = notificationId => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.NOTIFICATION.EDIT.replace(':id', notificationId),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      data: { isRead: true },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editNotification = (notificationId, values) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.NOTIFICATION.EDIT.replace(':id', notificationId),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      data: values,
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
