import { Menu, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  paper: {
    width: 160,
    boxShadow:
      '0px 0px 1px 0px rgba(142, 142, 142, 0.1), ' +
      '0px 0px 0px 0px rgba(142, 142, 142, 0.1), ' +
      '0px 0px 0px -2px rgba(142, 142, 142, 0.1)',
    paddingTop: 0,
    paddingBottom: 0,
    '& > ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

export class LayoutPopperMenu extends React.Component {
  render() {
    const { handleToggleMenuCardClose, anchorEl, children, classes } =
      this.props;
    return (
      <Menu
        id="candidate-card-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleToggleMenuCardClose}
        elevation={0}
        classes={{
          paper: classes.paper,
        }}
        keepMounted
      >
        {children}
      </Menu>
    );
  }
}

LayoutPopperMenu.propTypes = {
  anchorEl: PropTypes.object,
  children: PropTypes.node.isRequired,
  handleToggleMenuCardClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(LayoutPopperMenu);
