export const styles = () => ({
  periodOfStay: { marginBottom: 20 },
  datesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 35,
  },
  dates: { display: 'flex', alignItems: 'center' },
  date: { display: 'flex', alignItems: 'center' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
    marginRight: 10,
  },
  containerPadding: {
    paddingTop: 20,
  },
  powerOffBtn: {
    background: '#F41313',
    borderRadius: '50%',
    color: '#fff',
    marginRight: 10,
    marginLeft: 30,
  },
  hidden: { visibility: 'hidden' },
  editBtn: { cursor: 'pointer' },
  selectLoc: { margin: '20px 0' },
  pinIcon: {
    height: 50,
  },
  bold: { fontWeight: 600 },
  switch: { padding: '25px 25px 0' },
  mailDiv: { display: 'flex', alignItems: 'center', marginBottom: 10 },
  mail: { marginRight: 10 },
  blue: { color: '#148EFF', fontWeight: 600 },
  mobileDiv: { display: 'flex', alignItems: 'center' },
  mobile: { marginRight: 10 },
});
