import axios from 'axios';
import { API, API_URL } from '../constants';
import { getAllCompetenceTemp } from '../store/competenceTemp/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getCompetenceTempWithLimit = (limit, offset, params) => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.COMPETENCE_TEMP.GET_SEARCH,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      limit: limit,
      offset: offset,
      ...params,
    },
  });
};

export const getCompetenceTemp = (projectId = 0, userId = 0) => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.COMPETENCE_TEMP.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        project: projectId,
        user: userId,
      },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllCompetenceTemp(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const deleteCompetenceTemp = id => {
  return axios({
    method: 'DELETE',
    url: API_URL + API.DELETE.COMPETENCE_TEMP.DELETE.replace(':id', id),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const editCompetenceTemp = (competenceTempId, values) => {
  return axios({
    method: 'PUT',
    url:
      API_URL + API.PUT.COMPETENCE_TEMP.EDIT.replace(':id', competenceTempId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const addCompetenceTemp = values => {
  return axios({
    method: 'POST',
    url: API_URL + API.POST.COMPETENCE_TEMP.PUT,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};
