import { withStyles } from '@material-ui/core/styles';
import RecruiterOrganizationTable from './RecruiterOrganizationTable';

const styles = () => ({
  tableContainer: {
    margin: '0 20px',
    maxHeight: 'calc(100vh - 170px)',
  },
  buttonsTableCell: {
    padding: 5,
    width: 100,
    minWidth: 100,
  },
  listItem: {
    padding: 0,
  },
  wptCell: {
    maxWidth: 120,
  },
  actionsTypeCell: {
    maxWidth: 90,
  },
  bold: {
    fontWeight: 'bold',
  },
  colorCell: {
    width: 30,
    minWidth: 30,
  },
});

export default withStyles(styles)(RecruiterOrganizationTable);
