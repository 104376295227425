import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteUser, logoutUser } from 'shared/services';
import OrganizationData from '../OrganizationData';
import OrganizationFinicialPlan from '../OrganizationFinicialPlan';
import { styles } from './RightSide.styles';

const RightSide = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  return (
    <Grid container>
      <OrganizationData recruiterData={recruiterData} />
      <OrganizationFinicialPlan recruiterData={recruiterData} />
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  deleteUser,
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(RightSide))));
