import { Grid, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { setLastPage } from 'shared/services/authService';
import {
  getRecruiters,
  getRecruitersWithLimit,
  clearCurrentRecruitersLimitedList,
  clearCurrentRecruitersLimitedListCounter,
  getRecruitersCount,
} from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import PageHeader from '../../components/PageHeader';
import TopMenu from '../../components/TopMenu';
import RecruiterList from './RecruiterList';

const styles = theme => ({
  root: {
    marginTop: 0,
  },
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
});

export class RecruiterIndexView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      anchorEl: null,
      menuCounters: [],
    };
  }

  menuRecruiterElements = [
    {
      name: this.props.t`menu:all`,
      route_url: undefined,
      url: PATHS.RECRUITER.INDEX,
    },
    {
      name: this.props.t`menu:mentors`,
      route_url: 'mentor',
      url: PATHS.RECRUITER.INDEX_SHOW.replace(':show', 'mentor'),
    },
  ];

  getFilterParameters(filterName) {
    if (filterName === 'mentor') {
      return { filters: { mentorStatus: 2 } };
    } else {
      return { type: 'recruiter' };
    }
  }

  async getMenuCoutners() {
    let menuCounters = {};
    await Promise.all(
      this.menuRecruiterElements.map(async element => {
        const { count = 0 } = await this.getCounter(element.route_url);
        menuCounters[element.route_url] = count || 0;
      })
    );
    if (this._isMounted) {
      this.setState({ menuCounters });
    }
  }

  getCounter = async route => {
    const params = this.getFilterParameters(route);
    return await this.props.getRecruitersCount(params);
  };

  loadRecruiters = async (
    show,
    limit,
    offset = 0,
    filters = null,
    orderBy = null
  ) => {
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.url) || 12
      : limit;
    const paramShow = show === undefined ? this.props.match.params.show : show;
    let filter = undefined;
    if (paramShow === 'mentor') {
      filters = { ...filters, mentorStatus: 2 };
    } else {
      filter = { type: 'recruiter' };
    }

    let params = {
      ...filter,
      filters,
      orderBy,
    };

    this.getMenuCoutners();

    await this.props.getRecruitersWithLimit(limitToSearch, offset, params);
    this.props.setLastPage(this.props.location.pathname);
    if (this._isMounted) {
      this.setState({ load: false });
    }
  };

  handleReloadElements = ({ limit, offset, filters, orderBy }) => {
    return this.loadRecruiters(undefined, limit, offset, filters, orderBy);
  };

  async componentDidUpdate(nextProps) {
    if (nextProps.match.params.show !== this.props.match.params.show) {
      await this.props.clearCurrentRecruitersLimitedList();
      await this.props.clearCurrentRecruitersLimitedListCounter();
      this.setState({ load: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearCurrentRecruitersLimitedList();
    this.props.clearCurrentRecruitersLimitedListCounter();
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadRecruiters();
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, t } = this.props;
    const { anchorEl, load, menuCounters } = this.state;

    return (
      <React.Fragment>
        <PageHeader title={t('recruiter:list.pageTitle')} />
        <Grid
          container
          alignItems="flex-start"
          spacing={2}
          className={classes.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TopMenu
              handleClick={this.handleClick}
              handleClose={this.handleClose}
              counters={menuCounters}
              menuElements={this.menuRecruiterElements}
              anchorEl={anchorEl}
              itemSelected={this.props.match.params.show || undefined}
            />
            <Paper className={classes.rootPaper} elevation={0}>
              <RecruiterList
                storageKey={this.props.match.url}
                handleReloadElements={this.handleReloadElements}
                handleDelete={this.handleConfirmationOpen}
                load={load}
              />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getRecruiters,
  getRecruitersWithLimit,
  clearCurrentRecruitersLimitedList,
  clearCurrentRecruitersLimitedListCounter,
  getRecruitersCount,
  showSnackbarMessage,
  setLastPage,
};

RecruiterIndexView.propTypes = {
  classes: PropTypes.object.isRequired,
  setLastPage: PropTypes.func.isRequired,
  getRecruitersWithLimit: PropTypes.func.isRequired,
  clearCurrentRecruitersLimitedList: PropTypes.func.isRequired,
  clearCurrentRecruitersLimitedListCounter: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(RecruiterIndexView))));
