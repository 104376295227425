import { Avatar, Chip, Paper, withStyles } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1 / 2),
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  },
  rootContents: {
    display: 'contents',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1 / 2),
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  },
  chip: {
    margin: theme.spacing(1 / 2),
    background: '#f7f7f7',
    '&:hover': {
      background: '#fff',
    },
  },
  avatar: {
    background: theme.palette.jobllegroBlue,
  },
});

export class Sorting extends Component {
  static defaultProps = {
    orderByHandler: () => false,
    orderBy: {},
  };

  getContent = ({ name, label }) => {
    const { orderBy, orderByHandler, classes } = this.props;

    const order =
      orderBy[name] === undefined ? (
        false
      ) : orderBy[name] === 'asc' ? (
        <ExpandMoreIcon style={{ fontSize: 20, color: '#fff' }} />
      ) : (
        <ExpandLessIcon style={{ fontSize: 20, color: '#fff' }} />
      );

    let avatar = null;
    avatar = <Avatar className={classes.avatar}>{order}</Avatar>;

    return (
      <Chip
        key={name}
        avatar={avatar}
        label={label}
        onClick={orderByHandler.bind(this, name, orderBy[name])}
        className={classes.chip}
      />
    );
  };

  render() {
    const { sortingOptions, classes, flexCotents } = this.props;

    const content = sortingOptions.map(sorting => this.getContent(sorting));

    return (
      <React.Fragment>
        <Paper className={!flexCotents ? classes.root : classes.rootContents}>
          {content}
        </Paper>
      </React.Fragment>
    );
  }
}

Sorting.propTypes = {
  orderBy: PropTypes.object,
  orderByHandler: PropTypes.func.isRequired,
  sortingOptions: PropTypes.array,
  flexCotents: PropTypes.bool,
};

export default withStyles(styles)(Sorting);
