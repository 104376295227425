import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CandidateV2Service } from 'shared/services';
import { getLanguageSkill } from '../NewCandidateCreateView/components/CandidateCreateWizard/CandidateCreateWizard.utils';
import CandidateEmploymentStatusContent from './components/CandidateEmploymentStatusContent';

const CandidateEmploymentStatusView = ({ match, t, ...props }) => {
  const [candidate, setCandidate] = useState({});
  const [candidateEmploymentHistories, setCandidateEmploymentHistories] =
    useState([]);
  const [fetchDone, setFetchDone] = useState(false);

  const getCandidateById = id => {
    CandidateV2Service.getCandidateV2(id)
      .then(({ data }) => {
        setCandidate({
          ...data,
          ...getLanguageSkill(data.userSkills),
        });
        getCandidateEmploymentHistories(id).then(() => {
          setFetchDone(true);
        });
      })
      .catch(() => {
        //TODO: redirect to 404
        alert(t`candidate:dataError`);
      });
  };

  useEffect(() => {
    shouldUpdateCandidate();
  }, []);

  const getCandidateEmploymentHistories = candidateId => {
    return CandidateV2Service.getCandidateEmploymentHistoriesV2(candidateId)
      .then(({ data }) => {
        setCandidateEmploymentHistories(data);
      })
      .catch(() => {
        //TODO: redirect to 404
        alert(t`candidate:dataError`);
      });
  };
  const shouldUpdateCandidate = () => {
    const { id } = match.params;
    if (!id) {
      getCandidateById(props.currentUser.id);
    } else {
      getCandidateById(id);
    }
  };

  return fetchDone ? (
    <CandidateEmploymentStatusContent
      candidateDetails={candidate}
      candidateEmploymentHistories={candidateEmploymentHistories}
      currentUser={props.currentUser}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(mapStateToProps)(
  withRouter(withTranslation()(CandidateEmploymentStatusView))
);
