import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckBox as EnabledIcon,
  CheckBoxOutlineBlank as DisabledIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getTempSkills,
  deleteTempSkill,
  editTempSkill,
  editTempSkills,
  concatTempSkill,
  getTempSkillsWithLimit,
  approveTempSkill,
  getTempUsersSkills,
  getTempUserSkill,
  getTempProjectSkill,
  getTempProjectsSkills,
  getTempSkillsIds,
  getCompetenceTemp,
} from 'shared/services/tempSkillService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import AddSkillForm from '../../../components/AddSkillForm/AddSkillForm';
import CustomDialog from '../../../components/CustomDialog/index';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog/';
import PageHeader from '../../../components/PageHeader';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import AdminSkillToApprovedHead from './AdminSkillToApprovedHead';

export class AdminSkillToApprovedView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dictionariesSkills: [],
      skills: [],
      concatName: '',
      concatId: 0,
      skillsCount: 0,
      order: 'asc',
      orderBy: 'id',
      deleteConfirmationOpened: false,
      checkedAll: false,
      openEditDialog: false,
      openConcatDialog: false,
      withMultiEdit: false,
      selectedToApprovedSkillId: [],
      selectedToApprovedSkill: [],
      competencesTemp: [],
      selectedSkill: {},
      projectsSkill: [],
      usersSkill: [],
      options: {
        limit: 12,
        offset: 0,
      },
      isClicked: false,
      recruiterRating: [],
      isSubmitting: false,
    };
  }

  handleSorting = async (event, property) => {
    const orderBy = property;
    let order = 'asc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'asc') {
      order = 'desc';
    }

    await this.setState({ order, orderBy });
    this.handleReloadElements();
  };

  openRemoveDialog = user => {
    this.setState({
      selectedSkill: user,
      deleteConfirmationOpened: true,
    });
  };

  handleRemove = isOk => {
    if (isOk) {
      deleteTempSkill(this.state.selectedSkill.id).then(response => {
        this.setState({
          checkedAll: false,
          selectedSkill: null,
          selectedToApprovedSkillId: [],
        });
        this.handleReloadElements();
      });
    }
    this.setState({
      deleteConfirmationOpened: false,
    });
  };

  handleConcatSkill = async isOk => {
    if (isOk) {
      this.setState({
        isSubmitting: true,
      });
      const body = {
        name: this.state.concatName,
      };
      concatTempSkill(this.state.selectedSkill.id, body)
        .then(() => {
          this.props.showSnackbarMessage('Zapisano');
          this.setState({
            isSubmitting: false,
            concatName: '',
            openConcatDialog: false,
          });
          this.handleReloadElements();
        })
        .catch(error => {
          console.log(error);
          this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
        });
    }
    this.setState({
      concatName: '',
      openConcatDialog: false,
    });
  };

  onCloseEditDialog = () => {
    this.setState({
      selectedSkill: null,
      openEditDialog: false,
      withMultiEdit: false,
      usersSkill: [],
      projectsSkill: [],
      competencesTemp: [],
    });
  };

  onOpenEditDialog = skill => {
    this.setState({
      selectedSkill: skill,
      openEditDialog: true,
      withMultiEdit: false,
    });
    this.getUserSkill(skill.id);
    this.getProjectSkill(skill.id);
    this.getCompetencesTemp(skill.id);
  };

  getSkill = selectedSkillIds => {
    getTempSkillsIds({ id: selectedSkillIds }).then(response => {
      const { data } = response;
      this.setState({
        selectedToApprovedSkill: data,
      });
    });
  };

  getUserSkill = skillId => {
    getTempUserSkill(skillId).then(response => {
      const { data } = response;
      if (data.length) {
        this.setState({
          usersSkill: data,
        });
      }
    });
  };

  getCompetencesTemp = skillId => {
    getCompetenceTemp(skillId).then(response => {
      const { data } = response;
      if (data.length) {
        this.setState({
          competencesTemp: data,
        });
      }
    });
  };

  getUsersSkills = () => {
    const { selectedToApprovedSkillId } = this.state;
    getTempUsersSkills({ id: selectedToApprovedSkillId }).then(response => {
      const { data } = response;
      this.setState({
        usersSkill: data,
      });
    });
  };

  getProjectSkill = skillId => {
    getTempProjectSkill(skillId).then(response => {
      const { data } = response;
      if (data.length) {
        this.setState({
          projectsSkill: data,
        });
      }
    });
  };

  getProjectsSkills = () => {
    const { selectedToApprovedSkillId } = this.state;

    getTempProjectsSkills({ id: selectedToApprovedSkillId }).then(response => {
      const { data } = response;
      this.setState({
        projectsSkill: data,
      });
    });
  };

  handleUpdateSkill = async values => {
    this.setState({
      isSubmitting: true,
    });
    const body = {
      name: values.name,
      deName: values.deName,
      enName: values.enName,
      uaName: values.uaName,
    };
    editTempSkill(this.state.selectedSkill.id, body)
      .then(() => {
        this.onCloseEditDialog();
        this.handleReloadElements();
        this.props.showSnackbarMessage('Zapisano');
        this.setState({
          isSubmitting: false,
        });
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 409) {
          this.setState({
            openConcatDialog: true,
            isSubmitting: false,
            openEditDialog: false,
            concatName: values.name,
          });
        } else {
          this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
        }
        this.setState({
          isSubmitting: false,
        });
      });
  };

  handleUpdateSkills = async values => {
    const { selectedToApprovedSkillId } = this.state;
    this.setState({
      isSubmitting: true,
    });
    const body = {
      name: values.name,
      ids: selectedToApprovedSkillId,
    };
    editTempSkills(body)
      .then(() => {
        this.onCloseEditDialog();
        this.handleReloadElements();
        this.props.showSnackbarMessage('Zapisano');
        this.setState({
          selectedSkill: null,
          selectedToApprovedSkillId: [],
          isSubmitting: false,
          checkedAll: false,
        });
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 409) {
          this.setState({
            openConcatDialog: true,
            isSubmitting: false,
            openEditDialog: false,
            concatName: values.name,
          });
        } else {
          this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
        }
        this.setState({
          isSubmitting: false,
        });
      });
  };

  loadData = async (options = {}) => {
    const { limit, offset, filters } = options || this.state.options;
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.url) || 12
      : limit;
    const { order, orderBy } = this.state;
    this.setState({
      isLoading: true,
    });
    const params = {
      filters: { ...filters, isApproved: false },
      orderBy: {
        [orderBy]: order,
      },
    };
    getTempSkills().then(response => {
      const { data } = response;
      this.setState({
        dictionariesSkills: data,
      });
    });
    getTempSkillsWithLimit(limitToSearch, offset, params).then(response => {
      const { data } = response;
      this.setState({
        skills: data.data,
        skillsCount: data.count,
      });
    });
    this.setState({
      isLoading: false,
    });
  };

  handleReloadElements = (options = false) => {
    if (options) {
      this.setState({
        checkedAll: false,
        options,
      });
    }
    if (!this.props.isLoading) {
      return this.loadData(options || this.state.options);
    } else {
      return null;
    }
  };

  componentWillMount() {
    this.handleReloadElements();
  }

  onOpenEditMultiDialog = () => {
    this.setState({
      openEditDialog: true,
      withMultiEdit: true,
    });
    this.getSkill(this.state.selectedToApprovedSkillId);
    this.getUsersSkills();
    this.getProjectsSkills();
  };

  approveSelectedSkillId = () => {
    this.setState({
      withMultiEdit: false,
      isSubmitting: true,
    });
    const body = this.state.selectedToApprovedSkillId.map(item => {
      return {
        id: item,
      };
    });
    approveTempSkill(body)
      .then(() => {
        this.setState({
          isSubmitting: false,
          selectedToApprovedSkillId: [],
        });
        this.handleReloadElements();
        this.props.showSnackbarMessage('Zapisano zmiany');
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 418) {
          this.props.showSnackbarMessage(
            'Wszystkie wersje językowe muszą zostać uzupełnione'
          );
        } else {
          this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
        }
        this.setState({
          isSubmitting: false,
        });
      });
  };

  getWrapper = ({ content, filtering, pagination }) => {
    const { order, orderBy, withMultiEdit } = this.state;
    return (
      <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
        <PageHeader title="Lista umiejętności oczekujących na akceptację" />
        <Paper style={{ padding: 20 }} elevation={0}>
          {filtering}
        </Paper>
        <Button
          disabled={!this.state.selectedToApprovedSkillId.length}
          style={{ margin: '17px' }}
          onClick={() => this.approveSelectedSkillId()}
          variant="contained"
          color="primary"
        >
          Zaakceptuj umiejętności
        </Button>
        <Button
          disabled={!this.state.selectedToApprovedSkillId.length}
          style={{ margin: '17px' }}
          onClick={() => this.onOpenEditMultiDialog()}
          variant="contained"
          color="primary"
        >
          Edytuj wszystkie
        </Button>
        <Table>
          <TableHead>
            <AdminSkillToApprovedHead
              handleCheckbox={this.handleCheckbox}
              checkedAll={this.state.checkedAll}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <AdminSkillToApprovedHead
              handleCheckbox={this.handleCheckbox}
              checkedAll={this.state.checkedAll}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableFooter>
        </Table>
        {pagination}
        <CustomDialog
          title={
            this.state.withMultiEdit
              ? 'Edycja wielu umiejętności'
              : 'Edycja umiejętności'
          }
          maxWidth={'lg'}
          isOpen={this.state.openEditDialog}
          onCloseDialog={this.onCloseEditDialog}
          body={
            <div
              style={{
                width: '600px',
              }}
            >
              <AddSkillForm
                multiEdit={this.state.withMultiEdit}
                dictionariesSkills={this.state.dictionariesSkills}
                skill={this.state.selectedSkill}
                handleUpdateSkill={
                  withMultiEdit
                    ? this.handleUpdateSkills
                    : this.handleUpdateSkill
                }
                isSubmitting={this.state.isSubmitting}
              />
              {this.state.withMultiEdit && (
                <div>
                  <Typography>Zaznaczone umiejętności:</Typography>
                  <div
                    style={{
                      height: '200px',
                      overflow: 'auto',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Umiejętność</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.selectedToApprovedSkill.map(skill => {
                          return (
                            <TableRow key={skill.id}>
                              <TableCell>{skill.name}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              )}
              <Typography>Kompetencje:</Typography>
              <div
                style={{
                  height: '200px',
                  overflow: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Kategoria</TableCell>
                      <TableCell>Typ</TableCell>
                      <TableCell>Rodzaj</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.competencesTemp.map(competencesTemp => {
                      return (
                        <TableRow key={competencesTemp.id}>
                          <TableCell>
                            {competencesTemp.competenceCategory.name}
                          </TableCell>
                          <TableCell>
                            {competencesTemp.competenceType.name}
                          </TableCell>
                          <TableCell>
                            {competencesTemp.competenceKind.name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Typography>Wykorzystany w Projektach:</Typography>
              <div
                style={{
                  height: '200px',
                  overflow: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Projekt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.projectsSkill.map(projectSkill => {
                      return (
                        <TableRow key={projectSkill.id}>
                          <TableCell>
                            <Link
                              to={PATHS.PROJECT.SHOW.replace(
                                ':id',
                                projectSkill.project.id
                              )}
                            >
                              Projekt: {projectSkill.project.id}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Typography>Wykorzystany przez użytkowników:</Typography>
              <div
                style={{
                  height: '200px',
                  overflow: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Użytkownik</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.usersSkill.map(userSkill => {
                      return (
                        <TableRow key={userSkill.id}>
                          <TableCell>
                            <Link
                              to={PATHS.CANDIDATE.SHOW.replace(
                                ':id',
                                userSkill.user.id
                              )}
                            >
                              Użytkownik: {userSkill.user.id}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Button onClick={this.onCloseEditDialog} color="primary">
                Zamknij
              </Button>
            </div>
          }
        >
          Czy na pewno usunąć?
        </CustomDialog>
        <ConfirmationDialog
          open={this.state.openConcatDialog}
          titleText="Połączenie umiejętności"
          onClose={this.handleConcatSkill}
          okButtonText="Połącz"
          cancelButtonText="Anuluj"
        >
          Podana umiejętność &quot;{this.state.concatName}&quot; już istnieje w
          systemie, czy chcesz ją połączyć z instniejącą umiejętnością ?
        </ConfirmationDialog>
        <ConfirmationDialog
          open={this.state.deleteConfirmationOpened}
          titleText="Usuń umiejętność?"
          onClose={this.handleRemove}
          okButtonText="Usuń"
          cancelButtonText="Anuluj"
        >
          Czy na pewno usunąć?
        </ConfirmationDialog>
      </Paper>
    );
  };

  handleCheckbox = () => {
    const { skills, checkedAll } = this.state;
    if (checkedAll) {
      this.setState({
        checkedAll: false,
        selectedToApprovedSkillId: [],
      });
    } else {
      this.setState({
        checkedAll: true,
      });
      skills.forEach(skill => {
        this.handleSelectSkillToApproved(skill.id);
      });
    }
  };

  handleSelectSkillToApproved = skillId => {
    let selectedToApprovedSkillId = this.state.selectedToApprovedSkillId;
    if (selectedToApprovedSkillId.includes(skillId)) {
      selectedToApprovedSkillId = selectedToApprovedSkillId.filter(
        item => item !== skillId
      );
    } else {
      selectedToApprovedSkillId.push(skillId);
    }
    this.setState({
      selectedToApprovedSkillId: selectedToApprovedSkillId,
    });
  };
  getContent = skill => {
    const selectedToApprovedSkillId = this.state.selectedToApprovedSkillId;
    return (
      <TableRow hover key={skill.id}>
        <TableCell
          padding="checkbox"
          style={{ textAlign: 'right', width: '1px' }}
        >
          {selectedToApprovedSkillId.includes(skill.id) ? (
            <IconButton
              style={{ padding: 0 }}
              onClick={() => this.handleSelectSkillToApproved(skill.id)}
            >
              <EnabledIcon />
            </IconButton>
          ) : (
            <IconButton
              style={{ padding: 0 }}
              onClick={() => this.handleSelectSkillToApproved(skill.id)}
            >
              <DisabledIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>{skill.id}</TableCell>
        <TableCell padding="none">{skill.name}</TableCell>
        <TableCell padding="none">{skill.enName}</TableCell>
        <TableCell padding="none">{skill.deName}</TableCell>
        <TableCell padding="none">{skill.uaName}</TableCell>
        <TableCell>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => this.onOpenEditDialog(skill)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => this.openRemoveDialog(skill)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { skills, skillsCount } = this.state;

    const filteringOptions = [
      {
        key: 'name',
        type: 'text',
        name: 'Nazwa',
      },
    ];

    return (
      <ElementsListAsync
        storageKey={this.props.match.url}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        handleReloadElements={this.handleReloadElements}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        filteringOptions={filteringOptions}
        elements={skills || []}
        elementsCount={skillsCount || 0}
        scrollUp={true}
      />
    );
  }
}

const mapDispatchToProps = {
  showSnackbarMessage,
};

AdminSkillToApprovedView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  showSnackbarMessage: PropTypes.func.isRequired,
};
export default connect(null, mapDispatchToProps)(AdminSkillToApprovedView);
