import { Grid, Typography, withStyles } from '@material-ui/core';
import { Place, People } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { get, map, find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { getAssetUrl } from 'shared/services';
import { getProjectAssets } from 'shared/services/assetService';
import {
  getEnumItemName,
  getEnumItemNameFullObject,
} from 'shared/utils/assets';
import { styles } from './ProjectReviewStep1.styles';

const ProjectReviewStep1 = ({
  step1FormValues,
  classes,
  getProjectAssets,
  t,
  assetPreview,
}) => {
  const [assets, setAssets] = useState([]);

  const newAssets = get(step1FormValues, 'assets');
  const newLogoUrl = get(
    find(newAssets, o => o.filename === 'logo.jpg'),
    'id'
  );
  const newHeader = get(
    find(newAssets, o => o.filename === 'header.jpg'),
    'id'
  );

  const getClearAssetArray = assetToClear => {
    return assetToClear.filter(item => item !== undefined);
  };
  useEffect(() => {
    if (!step1FormValues.id) {
      return;
    }

    setAssets(getClearAssetArray(assetPreview));
  }, [assetPreview]);

  const getAssest = async () => {
    if (!step1FormValues.id) {
      return;
    }
    const newAssets = await getProjectAssets(step1FormValues.id);

    setAssets(get(newAssets, 'data'));
  };

  useEffect(() => {
    setTimeout(() => {
      getAssest();
    }, 1000);
  }, [newAssets, assetPreview]);

  const logoUrl =
    newLogoUrl ||
    get(
      find(assets, o => o.filename === 'logo.jpg'),
      'id'
    );
  const logo = getAssetUrl(logoUrl);
  const headerUrl =
    newHeader ||
    get(
      find(assets, o => o.filename === 'header.jpg'),
      'id'
    );
  const header = getAssetUrl(headerUrl);

  const position = step1FormValues.position;
  const workHourTypeIndex = step1FormValues.workHourType;
  const workHourType = getEnumItemNameFullObject(
    enums.WORKING_HOURS,
    workHourTypeIndex
  );

  const expectedWorkTime = step1FormValues.expectedWorkTime;
  const clientFormik = step1FormValues.client;
  const client = clientFormik
    ? step1FormValues.isClientPublic
      ? step1FormValues.client
      : t('projectCreator:step1.privateCompanyName')
    : clientFormik;
  const city = step1FormValues.city;
  const isRemoteWork = step1FormValues.isRemoteWork;
  const isSalaryPublic = step1FormValues.isSalaryPublic;
  const projectVisibilityType = step1FormValues.visibility;

  const industryCategory = step1FormValues.industryCategory;
  const industryCategoryName = get(industryCategory, 'name');
  const industryCategoryAssets = get(industryCategory, 'assets');
  const industryCategoryLogo = industryCategoryAssets
    ? industryCategoryAssets.find(item => item.type === 'industryCategoryLogo')
    : null;

  const industryCategoryBackgroundAsset = industryCategoryAssets
    ? industryCategoryAssets.find(
        item => item.type === 'industryCategoryBackground'
      )
    : null;

  const industryCategoryIcon = industryCategoryLogo
    ? getAssetUrl(industryCategoryLogo.id)
    : null;

  const industryCategoryBackground = industryCategoryBackgroundAsset
    ? getAssetUrl(industryCategoryBackgroundAsset.id)
    : null;
  const companySizeIndex = step1FormValues.companySize;
  const companySize = getEnumItemName(enums.COMPANY_SIZE, companySizeIndex);
  const clientUrl = step1FormValues.clientUrl;
  const isClientUrlCorrect = clientUrl ? clientUrl.includes('://') : '';

  const creatorIsEmpty = !position;
  const minSalary = step1FormValues.minSalary;
  const maxSalary = step1FormValues.maxSalary;
  const currencyIndex = step1FormValues.currency;
  const currency = getEnumItemName(enums.CURRENCY, currencyIndex);

  const typeOfPayIndex = step1FormValues.typeOfPay;
  const typeOfPay = t(getEnumItemName(enums.TYPE_OF_PAY, typeOfPayIndex));

  const salaryTypeIndex = step1FormValues.salaryType;
  const salaryType = getEnumItemNameFullObject(
    enums.WORKING_HOURS,
    salaryTypeIndex
  );
  const employmentTypeIndexArray = map(
    step1FormValues.employmentTypes,
    o => o.id - 1
  );
  const employmentType = getEnumItemName(
    enums.EMPLOYMENT_TYPE,
    employmentTypeIndexArray
  );
  const employmentStartDate = step1FormValues.employmentStartDate;

  return (
    <>
      {get(industryCategory, 'assets[1].id') && (
        <Grid container className={classes.imagesContainer}>
          <img
            alt="industryCategoryBackground"
            src={header || industryCategoryBackground}
            style={{ width: '100%' }}
          />
          {logoUrl && (
            <Grid container className={classes.logoWrapper} alignItems="center">
              <img alt="logo" src={logo} className={classes.logo} />
            </Grid>
          )}
        </Grid>
      )}
      {creatorIsEmpty ? (
        <Grid container justifyContent="center" style={{ padding: '3px 25px' }}>
          <Translate
            style={{ color: '#afafaf', fontSize: 18 }}
            text="projectCreator:core.creatorIsEmpty"
          />
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="flex-end"
          style={{ padding: '3px 25px' }}
        >
          {projectVisibilityType === 3 ? (
            <Grid container justifyContent="flex-end">
              <VisibilityIcon
                style={{ color: '#29d298', padding: '0px 14px' }}
              />
              <Translate
                style={{ color: '#afafaf', fontSize: 18 }}
                text="projectCreator:reviewStep1.isPublic"
              />
            </Grid>
          ) : projectVisibilityType === 2 ? (
            <Grid container justifyContent="flex-end">
              <VisibilityOffIcon
                style={{ color: '#008ffa', padding: '0px 14px' }}
              />
              <Translate
                style={{ color: '#afafaf', fontSize: 18 }}
                text="projectCreator:reviewStep1.isOrganizationProject"
              />
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <VisibilityOffIcon
                style={{ color: '#fd0012', padding: '0px 14px' }}
              />
              <Translate
                style={{ color: '#afafaf', fontSize: 18 }}
                text="projectCreator:reviewStep1.isNotPublic"
              />
            </Grid>
          )}
        </Grid>
      )}

      <Grid container className={classes.contentWrapper}>
        <Grid container style={{ paddingBottom: 12 }}>
          {position && (
            <Grid container>
              <Translate
                style={{ color: '#148EFF', fontSize: 42, lineHeight: 1.2 }}
                text={position}
                disableTranslate={true}
                bold
                variant="h6"
              />
            </Grid>
          )}
          {client && (
            <Grid container>
              <Translate
                disableTranslate={true}
                text={client}
                style={{ fontSize: 26 }}
                bold
                variant="h6"
              />
            </Grid>
          )}
        </Grid>
        {city && (
          <Grid container alignItems="center" style={{ padding: '4px 0' }}>
            <Place style={{ width: 40, height: 40, paddingRight: 8 }} />
            <Translate
              style={{ fontSize: 24 }}
              text={city}
              disableTranslate={true}
              variant="body1"
            />
            {isRemoteWork && (
              <>
                <Translate text="&nbsp;" variant="body1" />
                <Translate
                  style={{ fontSize: 24 }}
                  text="projectCreator:reviewStep1.isRemoteWork"
                  variant="body1"
                />
              </>
            )}
          </Grid>
        )}
        {industryCategoryName && (
          <Grid container alignItems="center" style={{ padding: '4px 0' }}>
            <img
              alt="industryCategoryIcon"
              src={industryCategoryIcon}
              style={{ paddingRight: 8 }}
            />
            <Translate
              style={{ fontSize: 16 }}
              text={industryCategoryName}
              variant="body1"
            />
          </Grid>
        )}
        {companySize && (
          <Grid container alignItems="center" style={{ padding: '4px 0' }}>
            <People style={{ width: 40, height: 40, paddingRight: 8 }} />
            <Translate
              style={{ fontSize: 16 }}
              text={companySize}
              variant="body1"
            />
            <Translate text="&nbsp;" variant="body1" />
            <Translate
              style={{ fontSize: 16 }}
              text="projectCreator:reviewStep1.companySize"
              variant="body1"
            />
          </Grid>
        )}
        {clientUrl && step1FormValues.isClientPublic && (
          <Grid
            container
            style={{
              padding: 5,
            }}
          >
            <a
              href={isClientUrlCorrect ? clientUrl : `https://${clientUrl}`}
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <Typography
                style={{ fontSize: 24, fontWeight: 'bold' }}
                variant="body1"
                color="primary"
              >
                {clientUrl}
              </Typography>
            </a>
          </Grid>
        )}
      </Grid>
      {((minSalary && maxSalary && currency && salaryType) ||
        employmentType.length > 0 ||
        employmentStartDate) && (
        <Grid
          container
          className={classes.bluePrimaryBackground}
          style={{
            paddingBottom: 25,
            paddingTop: 25,
          }}
        >
          <Grid container className={classes.contentWrapper}>
            {isSalaryPublic ? (
              <Grid container>
                {minSalary && maxSalary && currency && salaryType && (
                  <>
                    <Grid container>
                      <Translate
                        style={{ fontSize: 26 }}
                        text="projectCreator:reviewStep1.salaryType"
                        variant="body1"
                        bold
                      />
                    </Grid>
                    <Grid container>
                      <Translate
                        style={{ fontSize: 24 }}
                        text={`${minSalary} - ${maxSalary} ${currency} ${typeOfPay} `}
                        variant="body1"
                        disableTranslate
                      />
                      <Translate text="&nbsp;" variant="body1" />

                      <Translate
                        style={{ fontSize: 24 }}
                        text={salaryType}
                        variant="body1"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ) : (
              <Grid container>
                {minSalary && maxSalary && currency && salaryType && (
                  <>
                    <Grid container>
                      <Translate
                        style={{ fontSize: 26 }}
                        text="projectCreator:reviewStep1.salaryType"
                        variant="body1"
                        bold
                      />
                    </Grid>
                    <Grid container>
                      <Translate
                        style={{ fontSize: 24 }}
                        text="projectCreator:reviewStep1.salaryIsHide"
                        variant="body1"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            <Grid container>
              {employmentType.length > 0 && (
                <>
                  <Grid container>
                    <Translate
                      style={{ fontSize: 26 }}
                      text="projectCreator:step1.employmentType"
                      variant="body1"
                      bold
                    />
                  </Grid>
                  {employmentType.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Translate
                          style={{ fontSize: 24 }}
                          text={item}
                          variant="body1"
                        />
                        {index < employmentType.length - 1 && (
                          <Translate text=",&nbsp;" variant="body1" />
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </Grid>
            <Grid container>
              {expectedWorkTime !== undefined && workHourType ? (
                <>
                  <Translate
                    style={{ fontSize: 26 }}
                    text="projectCreator:step1.workHourType"
                    variant="body1"
                    bold
                  />
                  <Grid container>
                    <Translate
                      style={{ fontSize: 24 }}
                      text={workHourType}
                      variant="body1"
                    />
                    <Translate text="&nbsp;" variant="body1" />
                    {expectedWorkTime ? (
                      <Translate
                        style={{ fontSize: 24 }}
                        text={` - ${expectedWorkTime}`}
                        variant="body1"
                      />
                    ) : null}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default connect(null, { getProjectAssets })(
  withStyles(styles)(withTranslation()(ProjectReviewStep1))
);
