export const styles = theme => ({
  imagesWrapper: {
    position: 'relative',
  },
  header: {
    width: '100%',
  },
  logoWrapper: {
    position: 'absolute',
    height: '100%',
    paddingLeft: 24,
  },
  logo: {
    borderRadius: '50%',
  },
  blueBackground: { backgroundColor: '#f1f7ff' },
  separator: {
    borderColor: 'rgba(213, 214, 216, 0.5)',
    margin: '16px 0',
    width: '100%',
  },
  divBg: theme.mixins.gutters({
    width: '100% !important',
    height: 500,
    margin: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  }),
});
