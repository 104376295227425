export const styles = ({ palette, breakpoints }) => ({
  wrapper: {
    padding: '16px 150px',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  tabsWrapper: { borderBottom: '1px solid #b0b4b9' },
  link: { color: palette.primary.main },
  scroll: { overflow: 'auto' },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: '50',
    [breakpoints.only('xs')]: {
      width: 50,
      height: 50,
    },
  },
  separator: {
    borderColor: 'rgba(213, 214, 216, 0.5)',
    margin: '0px 0',
    width: '100%',
  },
  paperWrapper: {
    width: '100%',
    borderRadius: 20,
    height: '100%',
  },
  gridWrapper: {
    alignItems: 'center',
    padding: '30px 30px 30px 30px',
    wordBreak: 'break-word',
  },
  gridPadding: {
    padding: '15px 0px',
  },
  bigFont: {
    fontSize: 26,
  },
  mediumFont: {
    fontSize: 20,
  },
  settlementDataTitleFont: {
    fontSize: 16,
    color: '#148EFF',
  },
});
