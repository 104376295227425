import axios from 'axios';
import { push } from 'react-router-redux';
import { PATHS } from 'config';
import { API, CONST_CONFIG, API_URL } from '../constants';
import {
  authAction,
  isFetchingAction,
  setCurrentUserAction,
  logoutAction,
  setLastPageAction,
} from '../store/auth/actions';
import {
  clearLocalStorageByKey,
  getLocalStorageByKey,
  setLocalStorage,
} from '../utils/localStorage';

export const setLastPage = lastPage => {
  return async (dispatch, getState) => {
    dispatch(setLastPageAction(lastPage));
  };
};

const setAuthURLRequest = credentials => {
  if (credentials.type) {
    if (credentials.type === CONST_CONFIG.FACEBOOK.LOGIN_FACEBOOK_API) {
      return API.POST.AUTHORIZE.FACEBOOK_LOGIN;
    }
    if (credentials.type === CONST_CONFIG.GMAIL.LOGIN_GOOGLE_API) {
      return API.POST.AUTHORIZE.GMAIL_LOGIN;
    }
    if (credentials.type === CONST_CONFIG.LINKEDIN.LOGIN_LINKEDIN_API) {
      return API.POST.AUTHORIZE.LINKEDIN_LOGIN;
    }
  }
  return API.POST.AUTHORIZE.LOGIN;
};

export const authUser = credentials => {
  return async dispatch => {
    try {
      dispatch(isFetchingAction(true));
      const response = await axios({
        method: 'POST',
        url: API_URL + setAuthURLRequest(credentials),
        data: credentials,
      });

      const {
        data: { token },
      } = response;
      dispatch(isFetchingAction(false));
      dispatch(authAction(token));
      await dispatch(getCurrentUser());
      setLocalStorage('token', token);
      return response;
    } catch (err) {
      dispatch(isFetchingAction(false));
      throw err;
    }
  };
};

export const setTokenDispatch = token => {
  return async dispatch => {
    dispatch(isFetchingAction(false));
    dispatch(authAction(token));
    setLocalStorage('token', token);
    await dispatch(getCurrentUser());
  };
};
export const registerRecruiter = values => {
  return async (dispatch, getState) => {
    if (values.passwordConfirmation) {
      delete values.passwordConfirmation;
    }
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.AUTHORIZE.REGISTER.RECRUITER,
      data: values,
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const validateResetPasswordToken = token => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.AUTHORIZE.RESET_PASSWORD.replace(':token', token),
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: false, status };
      });
  };
};

export const resetPassword = (token, values) => {
  return async (dispatch, getState) => {
    if (values.passwordConfirmation) {
      delete values.passwordConfirmation;
    }
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.AUTHORIZE.RESET_PASSWORD.replace(':token', token),
      data: values,
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: false, status };
      });
  };
};

export const forgotPassword = values => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.AUTHORIZE.FORGOT_PASSWORD,
      data: values,
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: false, status };
      });
  };
};

export const refreshToken = userId => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.AUTHORIZE.REFRESH_TOKEN.replace(':userId', userId),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: false, status };
      });
  };
};

export const logoutUser = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.AUTHORIZE.LOGOUT,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        clearLocalStorageByKey('token');
        dispatch(logoutAction(null));
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        clearLocalStorageByKey('token');
        dispatch(logoutAction(null));
        dispatch(isFetchingAction(false));
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const validToken = token => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.AUTHORIZE.CURRENT_USER,
    headers: { Authorization: 'Bearer ' + token },
  });
};

export const getCurrentUser = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.AUTHORIZE.CURRENT_USER,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentUserAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 403) {
          dispatch(push(PATHS.AUTH.LOGOUT));
        }
        return { data: {}, status };
      });
  };
};

/*API version*/
export const getApiVersion = name => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.VERSION.GET,
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getAdminLogs = name => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.LOGS.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getMLTaskList = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ML.TASK_LIST,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const fetchMLData = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ML.FETCH_DATA,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const checkMLTask = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ML.CHECK_LIST,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editUserRodo = isRodoAccepted => {
  return async (dispatch, getState) => {
    const {
      auth: {
        currentUser: { id },
      },
    } = getState();

    const body = getState().auth.currentUser.isRecruiter
      ? {
          ...getState().auth.currentUser,
          isRodoAccepted: isRodoAccepted,
        }
      : { isRodoAccepted: isRodoAccepted };
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.USER.EDIT.replace(':id', id),
      data: body,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentUserAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
