import { withStyles, Switch as BaseSwitch, Grid } from '@material-ui/core';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Translate } from '..';
import { styles } from './Switch.style';

const Switch = ({ classes, name, label, formik }) => {
  const [isChecked, setIsChecked] = useState(get(formik.initialValues, name));

  const handleSwichChange = () => {
    setIsChecked(!isChecked);
    formik.setFieldValue(name, !isChecked);
  };

  return (
    <div className={classes.container}>
      <Grid container justifyContent="flex-end">
        <Translate text={label} color="textSecondary" />
      </Grid>
      <Grid
        container
        className={classes.switchWrapper}
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <BaseSwitch
          checked={isChecked}
          name={name}
          onChange={handleSwichChange}
          color="primary"
          classes={{
            root: classes.root,
            bar: classes.bar,
            icon: classes.icon,
            switchBase: classes.switchBase,
            iconChecked: classes.checked,
          }}
        />
        <Grid
          container
          onClick={handleSwichChange}
          className={classes.textContainer}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Grid container justifyContent="center">
              <Translate
                text="candidateDetails:core.no"
                className={classes.nonActiveText}
                bold={!isChecked}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="center">
              <Translate
                text="candidateDetails:core.yes"
                className={classes.activeText}
                bold={isChecked}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Switch);
