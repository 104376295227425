export const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_TYPE = process.env.REACT_APP_TYPE;
export const API_URL_RESOURCE = `${process.env.REACT_APP_API_URL}/api/resource/`;

export const defaultPaddingPage = { padding: '16px 150px' };

export const LANGUAGE_CATEGORY_TYPE_ID = APP_TYPE === 'LOCAL' ? 16 : 21;
export const LANGUAGE_KIND_ID = APP_TYPE === 'LOCAL' ? 13 : 17;
export const LANGUAGE_TYPE_ID = APP_TYPE === 'LOCAL' ? 5 : 2;

export const CONST_CONFIG = {
  PROJECT: {
    DEFAULT_SORT: {
      updatedAt: 'desc',
    },
  },
  FACEBOOK: {
    FACEBOOK_CLIENT_ID: 686110692156756,
    LOGIN_FACEBOOK_API: 'LOGIN_FACEBOOK_API',
  },
  GMAIL: {
    GOOGLE_CLIENT_ID:
      '779341479580-98equm08f9g99ln54u4hlp9d5jalr81i.apps.googleusercontent.com',
    LOGIN_GOOGLE_API: 'LOGIN_GOOGLE_API',
  },
  LINKEDIN: {
    LINKEDIN_CLIENT_ID: '77prybs6rojacg',
    LOGIN_LINKEDIN_API: 'LOGIN_LINKEDIN_API',
  },
  GTM: {
    GTM_ID: 'GTM-5M5QDGT',
  },
};

export const IdCONSTANTS = [];
export const API = {
  GET: {
    HELP: {
      GET_OFFERING_HELP: '/api/recruiter/v2/:recruiterId/offering_help_info',
      GET_LOOKING_HELP: '/api/candidate/v2/:candidateId/looking_help_info',
      GET_ACCOMMODATION: '/api/looking_help_info/{{lookingHelpInfoId}}?stage=2',
      GET_TRANSPORT: '/api/looking_help_info/{{lookingHelpInfoId}}?stage=2',
    },
    ACCOMMODATION: {
      GET: '/api/accommodations',
      GET_ONE: '/api/accommodations/:accommodation',
    },
    PROJECT_CANDIDATE_EMPLOYMENT_PROCESS: {
      PROJECT_CANDIDATE_EMPLOYMENT_PROCESS:
        '/api/project/:projectId/candidate/employment-process',
    },
    PROJECT_PUBLIC_URL: {
      GET_PROJECT_PUBLIC_URL: '/api/project_public_url/:hash',
    },
    PROJECT_ORDER: {
      PROJECT_ORDER_FROM_PROJECT: '/api/project-orders/project/:projectId',
      PROJECT_ORDER: '/api/project-orders/:projectOrderId',
    },
    EMPLOYMENT_MANAGEMENT_HISTORY: {
      GET_PROJECT_EMPLOYMENT_MANAGEMENT_HISTORY:
        '/api/project/:projectId/candidate/employment-management-history',
    },
    CANDIDATE_V2: {
      GET: '/api/candidate/v2/:id',
      GET_EMPLOYMENT_HISTORIES: '/api/candidate/v2/:id/employmentHistory',
      GET_CANDIDATE_STASH: '/api/candidate/stash',
    },
    POSITION: {
      GET: '/api/position',
    },
    RECRUITER_V2: {
      GET: '/api/recruiter/v2/:id',
      GET_PROJECT_CANDIDATE: '/api/recruiter/v2/:id/projectCandidate',
    },
    DEPARTMENT: {
      GET: '/api/departments',
    },
    PROJECT_V2: {
      GET: '/api/project/v2/:id',
      GET_RECRUITEMNT_PAYMENT: '/api/project/v2/:id/recruiterPayment',
      GET_PROJECT_LOCALIZATIONS: '/api/project/v2/:id/localizations',
      GET_PROJECT_AVAILABLE_TO_MOVE: '/api/project/v2/available-to-move',
      GET_PROJECT_ACCOMMODATIONS: '/api/project/v2/:id/accommodations',
      GET_MOST_POPULAR_CITIES: '/api/project/v2/most_popular_cities',
    },
    EMPLOYMENT_TYPE: {
      GET: '/api/employment_type',
    },
    COUNTRY: {
      GET: '/api/country',
    },
    WORK_STYLE: {
      GET: '/api/work_style',
    },
    CMS_CONFIG: {
      GET: '/api/cms_config',
    },
    ML: {
      TASK_LIST: '/api/ml/taskList',
      FETCH_DATA: '/api/ml/dataFetchFromML',
      CHECK_LIST: '/api/ml/checkList',
      CHECK_PREDICT_TASK: '/api/ml/predict/:taskId',
    },
    COMPETENCE_TYPE: {
      GET_SEARCH: '/api/competence_type/search',
      GET: '/api/competence_type',
    },
    COMPETENCE_CATEGORY: {
      GET_SEARCH: '/api/competence_category/search',
      GET: '/api/competence_category',
    },
    COMPETENCE_KIND: {
      GET_SEARCH: '/api/competence_kind/search',
      GET: '/api/competence_kind',
    },
    COMPETENCE_TEMP: {
      GET_SEARCH: '/api/competence/search',
      GET: '/api/competence/filter',
    },
    AUTHORIZE: {
      CURRENT_USER: '/api/authorize/user',
      LOGOUT: '/api/authorize/logout',
      RESET_PASSWORD: '/api/authorize/reset/:token',
      REFRESH_TOKEN: '/api/authorize/refresh-token/:userId',
      GET_TOKEN: '/api/authorize/:userId',
    },
    PROJECT: {
      GET: '/api/project',
      CANDIDATE: {
        GET: '/api/project/:id/candidate',
        CONFIRMATION: '/api/project/:id/candidate/:candidateId',
      },
      RECRUITER: {
        GET: '/api/project/:id/recruiter',
        CONFIRMATION: '/api/project/:id/recruiter/:recruiterId',
      },
      HASHES: {
        GET: '/api/project/:id/hashes',
      },
    },
    USER: {
      GET: '/api/user',
      SHOW: '/api/user/:id',
      CHECK_MAIL: '/api/user/checkMail/:mail',
      MOST_POPULAR_CITIES: '/api/user/most_popular_cities',
    },
    EXPORT: {
      GENERAL_PROJECTS: '/api/export/v2/general/projects',
      GENERAL_RECRUITERS: '/api/export/v2/general/recruiters',
      GENERAL_CANDIDATES: '/api/export/v2/general/candidates',
      DETAIL_RECRUITERS: '/api/export/v2/detail/recruiters',
      DETAIL_CANDIDATES: '/api/export/v2/detail/candidates',
      PROJECT_ORDERS: '/api/export/v2/operational/projectOrders',
      PROJECTS: '/api/export/projects',
      CANDIDATES: '/api/export/candidates',
      RECRUITERS: '/api/export/recruiters',
      ACCOMMODATIONS: '/api/export/v2/operational/accommodations',
      DETAIL_ACCOMMODATIONS: '/api/export/v2/operational/accommodations/detail',
      DETAIL_PROJECT_ORDERS: '/api/export/v2/operational/projectOrders/detail',
      ALL_HELP_INFO: '/api/export/v2/help_info/all',
      PROJECT_MANAGEMENT: '/api/export/v2/operational/projects',
    },
    CONFIG: {
      GET: '/api/config',
    },
    ASSET: {
      GET: '/api/asset',
      GET_PROJECT_ASSETS: '/api/project/:id/asset',
    },
    ORGANIZATION: {
      GET: '/api/organization',
      GET_ALL: '/api/organizations',
      RECRUITER: {
        GET: '/api/organization/:id/recruiter',
      },
    },
    FINICAL_PLAN: {
      GET: '/api/project/v2/:id/finicalPlan',
      GET_BY_ORGANIZATION: '/api/project/v2/:project/finicalPlan/:organization',
    },
    CITY: {
      GET: '/api/city',
    },
    INDUSTRY: {
      GET: '/api/industry',
    },
    INDUSTRY_CATEGORY: {
      GET: '/api/industry_category',
    },
    CITY_SEO: {
      GET: '/api/cities_seo',
    },
    FEEDBACK_REASON: {
      GET: '/api/feedback_reject_reason',
    },
    NATIONALITY: {
      GET: '/api/nationality',
    },
    LANGUAGE: {
      GET: '/api/language',
    },
    HELPDESK: {
      GET: '/api/helpdesk',
    },
    NOTIFICATION: {
      GET: '/api/notification',
    },
    TEMP_SKILL: {
      GET: '/api/skill',
      GET_SEARCH: '/api/skill/search',
      GET_USERS_SKILLS: '/api/skill/usersSkills/array',
      GET_PROJECTS_SKILLS: '/api/skill/projectsSkills/array',
      GET_USER_SKILL: '/api/skill/:id/usersSkill',
      GET_PROJECT_SKILL: '/api/skill/:id/projectsSkill',
      GET_COMPETENCE: '/api/skill/:id/competence',
    },
    SKILL: {
      GET: '/api/skill',
      GET_SEARCH: '/api/skill/search',
    },
    STAGE: {
      GET: '/api/stage',
    },
    SUMMARY: {
      GET: '/api/summary',
    },
    CANDIDATE_SUMMARY: {
      GET: '/api/candidate_summary',
    },
    SEARCH: {
      GET: '/api/search',
    },
    VERSION: {
      GET: '/api/debug/version',
    },
    LOGS: {
      GET: '/api/debug/logs',
    },
  },
  POST: {
    HELP: {
      OFFERING_ACCOMMODATION_INFO:
        '/api/offering_accommodation_info/offering_help_info/:offeringHelpInfoId',
      OFFERING_TRANSPORT_INFO:
        '/api/offering_transport_info/offering_help_info/:offeringHelpInfoId',
    },
    ACCOMMODATION: {
      POST_ACCOMMODATION: '/api/accommodations',
    },
    PROJECT_PUBLIC_URL: {
      POST_PROJECT_PUBLIC_URL: '/api/project_public_url/:id',
    },
    INDUSTRY_CATEGORY: {
      POST: '/api/industry_category',
    },
    LOCALIZATION: {
      POST: '/api/localizations',
    },
    PROJECT_ORDER: {
      POST: '/api/project-orders',
    },
    PROJECT_CANDIDATE_EMPLOYMENT_PROCESS: {
      POST: '/api/project-candidate-employment-processes',
    },
    PROJECT_CANDIDATE_EMPLOYMENT_MANAGEMENT_HISTORY: {
      POST: '/api/employment-management-histories',
    },
    CITY_SEO: {
      POST: '/api/cities_seo',
    },
    PROJECT_V2: {
      POST: '/api/project/v2',
      COPY: '/api/project/v2/copy/:id',
    },
    CMS_CONFIG: {
      POST: '/api/cms_config',
    },
    COMPETENCE_CATEGORY: {
      PUT: '/api/competence_category',
    },
    COMPETENCE_KIND: {
      PUT: '/api/competence_kind',
    },
    COMPETENCE_TYPE: {
      PUT: '/api/competence_type',
    },
    COMPETENCE_TEMP: {
      PUT: '/api/competence',
    },
    ML: {
      PREDICT_USERS_PROJECTS: '/api/ml/predict/users_project',
      PREDICT_PROJECTS_USER: '/api/ml/predict/projects_user',
    },
    AUTHORIZE: {
      LOGIN: '/api/authorize',
      FACEBOOK_LOGIN: '/api/authorize/facebook',
      GMAIL_LOGIN: '/api/authorize/gmail',
      LINKEDIN_LOGIN: '/api/authorize/linkedIn',
      REGISTER: {
        RECRUITER: '/api/authorize/register',
      },
      RESET_PASSWORD: '/api/authorize/reset/:token',
      FORGOT_PASSWORD: '/api/authorize/reset',
    },
    PROJECT: {
      ADD: '/api/project',
      CANDIDATE: {
        ADD: '/api/project/:id/candidate',
      },
      RECRUITER: {
        ADD: '/api/project/:id/recruiter',
      },
      WORK_STYLE_POINTS: {
        POST: '/api/project/v2/workStylePoints',
      },
    },
    USER: {
      CREATE: '/api/user',
      GET_MENTOR_FOR_PROJECT_FROM_LIST:
        '/api/user/mentor/for_project_from_list',
      CANDIDATE_STASH: '/api/user/stash',
    },
    CANDIDATE_V2: {
      CREATE: '/api/candidate/v2',
    },
    ORGANIZATION: {
      ADD: '/api/organization',
      RECRUITER: {
        ADD: '/api/organization/:id/recruiter',
      },
    },
    CONFIG: {
      ADD: '/api/new_config',
    },
    ASSET: {
      UPLOAD: '/api/asset',
      CHECK_CANDIDATE: '/api/checkCandidateFromFile',
      DATA_FROM_IMPORT: '/api/candidate/v2/dataFromImport',
    },
    CITY: {
      ADD: '/api/city',
    },
    INDUSTRY: {
      ADD: '/api/industry',
    },
    NATIONALITY: {
      ADD: '/api/nationality',
    },
    LANGUAGE: {
      ADD: '/api/language',
    },
    HELPDESK: {
      ADD: '/api/helpdesk',
    },
    SKILL: {
      ADD: '/api/skill',
    },
    RECRUITER: {
      RATING: {
        ADD: '/api/recruiter/rating',
      },
    },
  },
  DELETE: {
    INDUSTRY_CATEGORY: {
      DELETE: '/api/industry_category/:id',
    },
    CITY_SEO: {
      DELETE: '/api/cities_seo/:id',
    },
    CMS_CONFIG: {
      DELETE: '/api/cms_config/:id',
    },
    COMPETENCE_CATEGORY: {
      DELETE: '/api/competence_category/:id',
    },
    COMPETENCE_KIND: {
      DELETE: '/api/competence_kind/:id',
    },
    COMPETENCE_TYPE: {
      DELETE: '/api/competence_type/:id',
    },
    COMPETENCE_TEMP: {
      DELETE: '/api/competence/:id',
    },
    CONFIG: {
      DELETE: '/api/config/:id',
    },
    TEMP_SKILL: {
      DELETE_TEMP: '/api/skill/:id',
    },
    SKILL: {
      DELETE: '/api/skill/:id',
    },
    USER: {
      DELETE: '/api/user/:id',
      DELETE_CANDIDATE_FROM_STASH: '/api/user/stash/:id',
    },
    PROJECT: {
      DELETE: '/api/project/:id',
      CANDIDATE: {
        DELETE: '/api/project/:id/candidate/:candidateID',
      },
      RECRUITER: {
        DELETE: '/api/project/:id/recruiter/:recruiterID',
      },
    },
    ORGANIZATION: {
      DELETE: '/api/organization/:id',
      RECRUITER: {
        DELETE: '/api/organization/:id/recruiter/:recruiterID',
      },
    },
    ASSET: {
      DELETE: '/api/asset',
      DELETE_ID: '/api/asset/:id',
    },
  },
  PUT: {
    HELP: {
      PUT_LOOKING_HELP_INFO: '/api/looking_help_info/:lookingHelpInfoId',
      PUT_OFFERING_TRANSPORT_INFO:
        '/api/offering_transport_info/:offeringTransportInfoId',
      PUT_OFFERING_ACCOMMODATION_INFO:
        '/api/offering_accommodation_info/:offeringAccommodationInfoId',
    },
    ACCOMMODATION: {
      PUT_ACCOMMODATION: '/api/accommodations/:accommodation',
      PUT_ACCOMMODATION_ASSET: '/api/accommodations/:accommodation/asset',
    },
    EMPLOYMENT_MANAGEMENT_HISTORY: {
      PUT_STAGE: '/api/employment-management-histories',
    },
    PROJECT_CANDIDATE_EMPLOYMENT_PROCESS: {
      PUT_STAGE: '/api/project-candidate-employment-processes',
      PUT_STAGE_ONLY: '/api/project-candidate-employment-processes/stage-only',
      REJECT: '/api/project-candidate-employment-processes/:id/reject',
      RESTORE: '/api/project-candidate-employment-processes/:id/restore',
    },
    PROJECT_CANDIDATE_V2: {
      PUT_CAREER: '/api/project/:projectId/candidate/:candidateId/career',
      PUT_RECOMMEND_RATE:
        '/api/project/:projectId/candidate/:candidateId/recommendRate',
      PUT_FEEDBACK: '/api/project/:projectId/candidate/:candidateId/feedback',
    },
    CANDIDATE_V2: {
      PUT: '/api/candidate/v2/:id',
    },
    RECRUITER_V2: {
      PUT: '/api/recruiter/v2/:id',
      PUT_MENTOR_TYPE: '/api/recruiter/:mentorId/mentor_type',
    },
    INDUSTRY_CATEGORY: {
      PUT: '/api/industry_category/:id',
    },
    CITY_SEO: {
      PUT: '/api/cities_seo/:id',
    },
    PROJECT_V2: {
      PUT: '/api/project/v2/:id',
      PUT_ASSETS: '/api/project/v2/:id/assets',
      PUT_HIRING_MANAGER: '/api/project/v2/:id/hiring-manager',
      PUT_MENTOR: '/api/project/v2/:id/mentor',
      PUT_ORGANIZATION_STATUS: '/api/project/v2/:id/organization',
    },
    CMS_CONFIG: {
      PUT: '/api/cms_config/:id',
    },
    COMPETENCE_CATEGORY: {
      EDIT: '/api/competence_category/:id',
    },
    COMPETENCE_KIND: {
      EDIT: '/api/competence_kind/:id',
    },
    COMPETENCE_TYPE: {
      EDIT: '/api/competence_type/:id',
    },
    COMPETENCE_TEMP: {
      EDIT: '/api/competence/:id',
    },
    TEMP_SKILL: {
      APPROVE_TEMP_SKILL: '/api/skill/approveSkill',
      EDIT_TEMP: '/api/skill/:id',
      CONCAT_AND_APPROVE: '/api/skill/concatAndApprove',
      CONCAT_SKILL: '/api/skill/concatSkill/:id',
    },
    SKILL: {
      EDIT: '/api/skill/:id',
      EDIT_SKILLS: '/api/skill',
      APPROVE_SKILL: '/api/skill/approveSkill',
      APPROVE_TEMP_SKILL: '/api/skill/approveSkill',
      CONCAT_SKILL: '/api/skill/concatSkill/:id',
    },
    PROJECT: {
      EDIT: '/api/project',
      CANDIDATE: {
        EDIT: '/api/project/:id/candidate',
        CONFIRMATION: '/api/project/:id/candidate',
        CHANGE_CANDIDATE_IN_PROJECT_OWNER:
          '/api/project/:id/candidate/:candidateID',
      },
      RECRUITER: {
        CONFIRMATION: '/api/project/:id/recruiter/:recruiterId',
      },
      LANGUAGE: {
        EDIT: '/api/project/:id/language',
      },
      SKILL: {
        EDIT: '/api/project/:id/skill',
      },
    },
    USER: {
      EDIT: '/api/user/:id',
      LANGUAGE: {
        EDIT: '/api/user/:id/language',
      },
      SKILL: {
        EDIT: '/api/user/:id/skill',
      },
    },
    ORGANIZATION: {
      EDIT: '/api/organization/:id',
      EDIT_ASSETS: '/api/organization/:id/asset',
      RECRUITER: {
        CONFIRMATION: '/api/organization/:id/recruiter/:recruiterId',
      },
    },
    RECRUITER: {
      SHOW: '/recruiter/:id',
      EDIT: '/api/recruiter/:id',
      EDIT_MENTOR_STATUS: '/api/recruiter/:id/mentor_status',
      EDIT_ORGANIZATION_STATUS: '/api/recruiter/:id/organization_status',
      EDIT_ASSETS: '/api/recruiter/:id/assets',
    },
    CONFIG: {
      EDIT: '/api/config/:id',
    },
    NOTIFICATION: {
      EDIT: '/api/notification/:id',
    },
    PROJECT_ORDER: {
      EDIT: '/api/project-orders/:orderId',
      CLOSE: '/api/project-orders/close-orders',
      CONFIRM_PROJECT_ORDER: '/api/project-orders/:orderId/confirmStatus',
    },
  },
};
