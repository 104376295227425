import { Avatar, Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import {
  getProjectAssetSrcByType,
  getUserAvatarSrc,
} from 'shared/utils/assets';
import { styles } from './RecruiterAnonymousDetailsContentLowerTop.styles';

const RecruiterAnonymousDetailsContentLowerTop = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  const avatar = getUserAvatarSrc(recruiterData);

  const candidateCount = recruiterData.candidateCount;
  const numberOfProject = recruiterData.numberOfProject;

  const userOrganization = recruiterData.organization;
  const organizationAvatar = getProjectAssetSrcByType(userOrganization, 'logo');

  const firstName = recruiterData.firstName;
  const lastName = recruiterData.lastName;
  const email = recruiterData.email;
  const currentMentorStatus = recruiterData.mentorStatus;

  const isMentor = currentMentorStatus === enums.MENTOR_STATUS.ACCEPTED;

  return (
    <Grid container alignContent={'center'}>
      <Grid item xs={12} sm={12} md={6} className={classes.itemWrapper}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.avatarWrapper}>
            <Grid item xs={3} sm={3} md={3}>
              <Avatar
                src={avatar}
                aria-label="Jobllegro"
                className={classes.avatar}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={`${firstName} ${lastName}`}
                    bold
                    className={classes.fontStylesBig}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={`${email}`}
                    className={classes.fontStylesBig}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={
                      isMentor
                        ? t`recruiter:mentor`
                        : t`recruiter:core:recruiter`
                    }
                    className={classes.fontStylesBig}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.itemWrapper}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.avatarWrapper}>
            <Grid container>
              <Grid item xs={10} sm={10} md={10}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container>
                    <Translate
                      text={t`menu:reko`}
                      className={classes.fontStylesSmall}
                      disableTranslate={true}
                    />
                    <Translate text="&nbsp;" variant="body1" />
                    <Translate
                      text={candidateCount}
                      disableTranslate={true}
                      className={classes.fontStylesSmall}
                      bold
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container>
                    <Translate
                      text={t`menu:activity`}
                      className={classes.fontStylesSmall}
                      disableTranslate={true}
                    />
                    <Translate text="&nbsp;" variant="body1" />
                    <Translate
                      text={numberOfProject}
                      disableTranslate={true}
                      className={classes.fontStylesSmall}
                      bold
                    />
                  </Grid>

                  {userOrganization ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container>
                        <Translate
                          text={t`menu:recruiterIn`}
                          className={classes.fontStylesSmall}
                          disableTranslate={true}
                        />
                        <Translate text="&nbsp;" variant="body1" />
                        <Translate
                          text={userOrganization.name}
                          disableTranslate={true}
                          className={classes.fontStylesSmall}
                          bold
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {userOrganization ? (
                <Grid item xs={2} sm={2} md={2}>
                  <Avatar
                    src={organizationAvatar}
                    aria-label="Jobllegro"
                    className={classes.avatar}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(RecruiterAnonymousDetailsContentLowerTop))
);
