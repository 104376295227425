import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: '64px !important',
    paddingRight: '64px !important',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  }),
  textDescription: {
    fontSize: '16px',
    color: '#000',
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
  textHeading: {
    paddingTop: 0,
    paddingBottom: 8,
    color: '#52a3fc',
    fontSize: '20px',
  },
});

export const ProjectDetailsBenefits = props => {
  const { classes, projectDetails, t } = props;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.rootPaper} elevation={0}>
          {projectDetails.profits && (
            <React.Fragment>
              <Typography
                component="h3"
                gutterBottom
                className={classes.textHeading}
              >
                {t('project:show.profitsTitle')}
              </Typography>
              <Typography component="p" className={classes.textDescription}>
                {projectDetails.profits}
              </Typography>
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectDetailsBenefits.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsBenefits)));
