import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { styledMui } from './functions';

export const ButtonJobllegrov1 = styledMui(Button)(theme => ({
  background: '#20d4d5',
  borderRadius: 5,
  color: '#fff',
  height: 'auto',
  padding: '12px',
  fontWeight: '600',
  fontSize: '14px',
  marginRight: '0px !important',
  transition: 'all 0.3s',
  '&:hover': {
    background: theme.palette.jobllegroBlue,
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}));

export const ButtonJobllegrov2 = styledMui(Button)({
  background: '#fff',
  borderRadius: 5,
  color: '#22d2d2',
  height: 'auto',
  padding: '10px',
  fontWeight: '700',
  fontSize: '14px',
  marginRight: 0,
  border: '3px solid #20d4d5',
  '&:hover': {
    background: '#20d4d5',
    color: '#fff',
  },
});

export const ButtonJobllegrov3 = styledMui(Button)({
  background: 'linear-gradient(60deg, #e86958 30%, #f26957 60%)',
  borderRadius: 5,
  color: '#fff',
  height: 'auto',
  padding: '12px',
  fontWeight: '700',
  fontSize: '14px',
  marginRight: 0,
  '&:hover': {
    background: 'linear-gradient(60deg, #f26957 30%, #e86958 60%)',
  },
});

export const ButtonJobllegrov4 = styledMui(Button)({
  background: 'transparent',
  borderRadius: 0,
  color: '#e86958',
  height: 'auto',
  padding: '0px',
  fontWeight: '700',
  fontSize: '14px',
  marginRight: 0,
  boxShadow: 0,
  '&:hover': {
    background: 'transparent',
  },
});

export const ButtonJobllegrov5 = styledMui(Button)(theme => ({
  background: theme.palette.jobllegroBlue,
  borderRadius: 0,
  color: '#fff',
  height: 'auto',
  padding: '0px',
  fontWeight: '700',
  fontSize: '14px',
  marginRight: 0,
  boxShadow: 0,
  transition: 'all 0.4s',
  '&:hover': {
    background: theme.palette.jobllegroLightGrey,
  },
  '&:disabled': {
    background: theme.palette.jobllegroLightGrey,
  },
}));

export const ButtonJobllegrov6 = styledMui(Button)(theme => ({
  background: theme.palette.jobllegroBlue,
  borderRadius: 5,
  color: '#fff',
  height: 'auto',
  padding: '12px',
  fontWeight: '600',
  fontSize: '14px',
  marginRight: '0px !important',
  transition: 'all 0.3s',
  '&:hover': {
    background: '#20d4d5',
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}));

export const ButtonJobllegrov7 = styledMui(Button)(theme => ({
  background: '#d5241a',
  borderRadius: 5,
  color: '#fff',
  height: 'auto',
  padding: '12px',
  fontWeight: '600',
  fontSize: '14px',
  marginRight: '0px !important',
  transition: 'all 0.3s',
  '&:hover': {
    background: theme.palette.jobllegroBlue,
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}));

export const ButtonJobllegrovLandingButton = styledMui(Button)(theme => ({
  background: 'transparent',
  borderRadius: 25,
  color: '#fff',
  height: 'auto',
  padding: '4px 12px 4px 12px',
  fontWeight: '600',
  fontSize: '13px',
  marginRight: '0px !important',
  transition: 'all 0.3s',
  border: '1px solid #fff',
  '&:hover': {
    background: '#20d4d5',
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}));

export const ActionButtonJobllegroRounded = styledMui(Button)(theme => ({
  marginTop: 10,
  height: 'auto',
  padding: '6px 4px 6px 4px',
  fontWeight: '600',
  fontSize: '12px',
  marginRight: '0px !important',
  transition: 'all 0.3s',
  color: '#fff',
  background: '#52a3fc',
  borderRadius: '20px',
  width: '100%',
  maxWidth: 320,
  display: 'block',
  textAlign: 'center',
  lineHeight: '22px',
  '&:hover': {
    background: theme.palette.jobllegroGrey,
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}));

export const ActionButtonJobllegroRoundedInline = styledMui(Button)(theme => ({
  marginTop: 0,
  height: 'auto',
  minHeight: 'auto',
  padding: '0px 6px 0px 6px',
  fontWeight: '600',
  fontSize: '10px',
  marginRight: '0px !important',
  transition: 'all 0.3s',
  color: '#fff',
  background: '#52a3fc',
  borderRadius: '20px',
  width: 'auto',
  display: 'block',
  textAlign: 'center',
  lineHeight: '22px',
  '&:hover': {
    background: theme.palette.jobllegroGrey,
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  '& a': {
    color: '#fff',
    textDecoration: 'none',
  },
}));

export const ActionButtonJobllegroRoundedBig = styledMui(Button)(theme => ({
  height: 'auto',
  padding: '16px 4px 16px 4px',
  fontWeight: '200',
  fontSize: '16px',
  marginRight: '0px !important',
  transition: 'all 0.3s',
  color: '#fff',
  background: '#01D38A',
  borderRadius: '25px',
  width: '100%',
  maxWidth: 320,
  display: 'block',
  textAlign: 'center',
  lineHeight: '22px',
  boxShadow:
    '0px 6px 6px -3px rgba(117, 117, 117, 0.1), 0px 10px 14px 1px rgba(117, 117, 117, 0.12), 0px 4px 18px 3px rgba(117, 117, 117, 0.1)',
  '&:hover': {
    background: theme.palette.jobllegroGrey,
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}));

export const JButtonPrimary = styled(
  ({ color, mentorStatus, isLoading, ...other }) => (
    <Button
      {...other}
      disabled={mentorStatus === 1 ? true : null}
      classes={{ label: 'label', disabled: 'disabled' }}
    />
  )
)`
  && {
    background: transparent;
    border: 0;
    color: #000;
    height: auto;
    padding: 0px;
    font-weight: 600;
    box-shadow: 0px rgba(255, 255, 255, 0);
    transition: all 0.3s;
    :hover {
      font-weight: 600;
      color: #52a3fc;
      background: transparent;
    }
  }
`;
