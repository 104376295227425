import { Grid, withStyles } from '@material-ui/core';
import { FieldArray, FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Select } from 'shared/newComponents';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import { MODEL } from '../CandidateCreateWizardStep2/CandidateCreateWizardStep2.utils';
import { styles } from './LanguageSelectCard.styles';

const LanguageSelectCard = ({
  formik,
  initialCandidateValues,
  languageCompetence,
  t,
}) => {
  const [languageSelectCard, setLanguageSelectCard] = useState([]);

  const defaultLanguageCard = {
    competence: {
      competenceCategory: { id: 16, name: t`candidate:field.languages` },
      competenceType: { id: 5, name: t`candidate:field.skill` },
      competenceKind: { id: 13, name: t`candidate:field.lang` },
    },
    experience: '',
    skill: {},
  };

  useEffect(() => {
    if (
      initialCandidateValues.userLanguageSkills &&
      initialCandidateValues.userLanguageSkills.length
    ) {
      onAddLanguageExist(initialCandidateValues.userLanguageSkills);
    } else {
      onAddLanguage();
    }
  }, [initialCandidateValues]);

  const onAddLanguageExist = userLanguage => {
    setLanguageSelectCard(userLanguage);
  };
  const onAddLanguage = () => {
    setLanguageSelectCard([...languageSelectCard, defaultLanguageCard]);
    formik.setFieldValue(`${MODEL.userLanguageSkills}`, [
      ...formik.values[MODEL.userLanguageSkills],
      defaultLanguageCard,
    ]);
  };

  const onRemove = indexToRemove => {
    const filterLanguageTable = languageSelectCard.filter(
      (item, index) => index !== indexToRemove
    );
    const filterLanguageTableFromFormik = formik.values[
      MODEL.userLanguageSkills
    ].filter((item, index) => index !== indexToRemove);

    setLanguageSelectCard(filterLanguageTable);
    formik.setFieldValue(
      `${MODEL.userLanguageSkills}`,
      filterLanguageTableFromFormik
    );
  };

  const isLastIndex = index => {
    return languageSelectCard.length === index + 1;
  };

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name={MODEL.userLanguageSkills}
        render={() => {
          return languageSelectCard.map((item, index) => {
            return (
              <Grid container key={index}>
                <Grid item xs={12} sm={12} md={6}>
                  <MultiSelect
                    options={
                      languageCompetence
                        ? languageCompetence.map(item => {
                            return {
                              id: item.skill.id,
                              value: item.skill.id,
                              label: item.skill.name,
                              name: item.skill.name,
                            };
                          })
                        : []
                    }
                    label={t('candidateCreator:step2.language')}
                    name={`${MODEL.userLanguageSkills}[${index}].${MODEL.skill}`}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Select
                    formik={formik}
                    items={[
                      {
                        id: 0,
                        name: t(
                          'projectCreator:competenceExperienceLevel.none'
                        ),
                      },
                      {
                        id: 2,
                        name: t(
                          'projectCreator:competenceExperienceLevel.junior'
                        ),
                      },
                      {
                        id: 5,
                        name: t(
                          'projectCreator:competenceExperienceLevel.regular'
                        ),
                      },
                      {
                        id: 10,
                        name: t(
                          'projectCreator:competenceExperienceLevel.senior'
                        ),
                      },
                    ]}
                    name={`${MODEL.userLanguageSkills}[${index}].${MODEL.experience}`}
                    label={t('projectCreator:step2.experienceLevel')}
                  />
                </Grid>
                <Grid
                  container
                  style={{ padding: 10 }}
                  spacing={2}
                  alignItems="center"
                >
                  {isLastIndex(index) ? (
                    <Grid item xs={12} sm={12} md={4}>
                      {index !== 0 ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <Button
                            style={{ borderColor: 'red', marginBottom: 4 }}
                            fontColor={'red'}
                            label={t('candidateCreator:step2.removeExperience')}
                            onClick={() => onRemove(index)}
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={12} md={12}>
                        <Button
                          label={t('candidateCreator:step2.addExperience')}
                          onClick={onAddLanguage}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={4}>
                      <Button
                        style={{ borderColor: 'red' }}
                        fontColor={'red'}
                        label={t('candidateCreator:step2.removeExperience')}
                        onClick={() => onRemove(index)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          });
        }}
      />
    </FormikProvider>
  );
};

export default withStyles(styles)(withTranslation()(LanguageSelectCard));
