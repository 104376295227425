import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import HappyFace from 'shared/assets/img/icons/faces/active/happyFace.svg';
import HappyFaceV2 from 'shared/assets/img/icons/faces/active/happyFaceV2.svg';
import NeutralFace from 'shared/assets/img/icons/faces/active/neutralFace.svg';
import SadFace from 'shared/assets/img/icons/faces/active/sadFace.svg';
import SadFaceV2 from 'shared/assets/img/icons/faces/active/sadFaceV2.svg';
import HappyFaceNonSelect from 'shared/assets/img/icons/faces/grey/happyFace.svg';
import HappyFaceV2NonSelect from 'shared/assets/img/icons/faces/grey/happyFaceV2.svg';
import NeutralFaceNonSelect from 'shared/assets/img/icons/faces/grey/neutralFace.svg';
import SadFaceNonSelect from 'shared/assets/img/icons/faces/grey/sadFace.svg';
import SadFaceV2NonSelect from 'shared/assets/img/icons/faces/grey/sadFaceV2.svg';
import { Translate } from 'shared/newComponents';
import { styles } from './FeedbackGrade.style';

export function FeedbackGrade({
  title,
  onChangeGrade,
  onChangeGradePreActive,
  currentGrade = 5,
  currentGradePreActive = 0,
  filedName,
  filedNamePreActive,
}) {
  const onClickGrade = (event, grade) => {
    onChangeGrade(filedName, grade);
  };
  const onHoverGradePreActive = (event, grade) => {
    onChangeGradePreActive(filedNamePreActive, grade);
  };
  const onOverGradePreActive = () => {
    onChangeGradePreActive(filedNamePreActive, 0);
  };
  const selectedStyle = {
    display: 'flex',
    boxShadow: ' 0px 3px 0px 1px rgb(0 0 0 / 25%)',
    borderRadius: 18,
    padding: '10px 10px',
  };
  const nonSelectedStyle = {
    display: 'flex',
    padding: '10px 10px',
  };
  return (
    <Grid container style={{ marginTop: 30 }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Translate
            text={title}
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <div
            style={currentGrade === 10 ? selectedStyle : nonSelectedStyle}
            onMouseOver={event => onHoverGradePreActive(event, 10)}
            onMouseOut={onOverGradePreActive}
          >
            <img
              alt="HappyFace"
              onClick={event => onClickGrade(event, 10)}
              src={
                currentGrade === 10 || currentGradePreActive === 10
                  ? HappyFace
                  : HappyFaceNonSelect
              }
              height={50}
            />
          </div>
          <div
            style={currentGrade === 8 ? selectedStyle : nonSelectedStyle}
            onMouseOver={event => onHoverGradePreActive(event, 8)}
            onMouseOut={onOverGradePreActive}
          >
            <img
              alt="HappyFaceV2"
              onClick={event => onClickGrade(event, 8)}
              src={
                currentGrade === 8 || currentGradePreActive === 8
                  ? HappyFaceV2
                  : HappyFaceV2NonSelect
              }
              height={50}
            />
          </div>
          <div
            style={currentGrade === 5 ? selectedStyle : nonSelectedStyle}
            onMouseOver={event => onHoverGradePreActive(event, 5)}
            onMouseOut={onOverGradePreActive}
          >
            <img
              alt="NeutralFace"
              onClick={event => onClickGrade(event, 5)}
              src={
                currentGrade === 5 || currentGradePreActive === 5
                  ? NeutralFace
                  : NeutralFaceNonSelect
              }
              height={50}
            />
          </div>
          <div
            style={currentGrade === 2 ? selectedStyle : nonSelectedStyle}
            onMouseOver={event => onHoverGradePreActive(event, 2)}
            onMouseOut={onOverGradePreActive}
          >
            <img
              alt="SadFace"
              onClick={event => onClickGrade(event, 2)}
              src={
                currentGrade === 2 || currentGradePreActive === 2
                  ? SadFace
                  : SadFaceNonSelect
              }
              height={50}
            />
          </div>
          <div
            style={currentGrade === 1 ? selectedStyle : nonSelectedStyle}
            onMouseOver={event => onHoverGradePreActive(event, 1)}
            onMouseOut={onOverGradePreActive}
          >
            <img
              alt="SadFaceV2"
              onClick={event => onClickGrade(event, 1)}
              src={
                currentGrade === 1 || currentGradePreActive === 1
                  ? SadFaceV2
                  : SadFaceV2NonSelect
              }
              height={50}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withTranslation()(FeedbackGrade));
