import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export class FieldWysiwyg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  removeBackgroundColorFromDraft = value => {
    value.blocks.forEach(item => {
      const inlineStyleArray = [];
      item.inlineStyleRanges.forEach(inlineStyle => {
        if (!inlineStyle.style.includes('color-rgb')) {
          inlineStyleArray.push(inlineStyle);
        }
      });
      item.inlineStyleRanges = inlineStyleArray;
    });
    return value;
  };

  onChange = (onChangeHandler, value) => {
    const htmlContent = draftToHtml(
      this.removeBackgroundColorFromDraft(value)
    ).replaceAll(/[\ue000-\uF8FF]/g, '');
    onChangeHandler(htmlContent);
  };

  getLanguage(language) {
    const languages = [
      'da',
      'de',
      'en',
      'es',
      'fr',
      'it',
      'ja',
      'ko',
      'nl',
      'pl',
      'pt',
      'ru',
      'zh',
      'zh_tw',
    ];

    if (languages.indexOf(language) >= 0) {
      return language;
    } else {
      return 'en';
    }
  }

  getEditor = props => {
    const {
      label,
      input,
      meta,
      language,
      valid = true,
      readOnly = false,
    } = props;
    const invalid =
      (meta.touched && meta.invalid && meta.error.length > 0) ||
      (meta.initial !== null && !valid);
    if (!_.isObject(input.value)) {
      const contentBlock = htmlToDraft(input.value);
      if (contentBlock) {
        input.value = convertToRaw(
          ContentState.createFromBlockArray(contentBlock.contentBlocks)
        );
      }
    }

    return (
      <FormControl error={invalid}>
        <InputLabel
          htmlFor={input.name}
          margin={'dense'}
          shrink={true}
          style={{ display: 'block' }}
        >
          {label}
        </InputLabel>
        <div
          style={{
            marginTop: '4%',
          }}
        />
        <Editor
          readOnly={readOnly}
          defaultContentState={input.value}
          wrapperClassName={input.name + '-wrapper'}
          editorClassName={input.name + '-editor'}
          onChange={this.onChange.bind(this, input.onChange)}
          editorStyle={{
            wordBreak: 'break-all',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            height: 280,
          }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'history',
            ],
          }}
          localization={{
            locale: this.getLanguage(language),
          }}
        />
        {invalid && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    );
  };

  render() {
    return <Field component={this.getEditor} {...this.props} />;
  }
}

const mapStateToProps = store => {
  return {
    language: store.auth.language,
  };
};

export default connect(mapStateToProps, null)(FieldWysiwyg);
