import {
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  CloudUpload as FileIcon,
  Close as DeleteIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import 'shared/assets/css/FileInput.css';
import { withTranslation } from 'react-i18next';
import ImageCropper from '../../Cropper/index';

export class FileInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      cropperOpen: false,
      disabledSaveButton: false,
      files: props.files || [],
      acceptedFiles: this.props.acceptedFiles || [
        'image/*',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.pdf',
      ],
    };
  }

  acceptedFilesDictionary = {
    'image/*': '.jpg, .png, .gif, .jpeg',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      '.xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      '.pptx',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      '.docx',
  };

  acceptedFilesDropZone = {
    'image/*': 'image/jpg, image/png, image/gif, image/jpeg',
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open || false,
      files: nextProps.files || [],
    });
  }

  handleClose = () => {
    this.props.closeDialog();
    this.setState({ open: false, cropperOpen: false });
  };

  onDrop = async files => {
    if (files.length === 0) {
      return 0;
    }
    const oldFiles = this.state.files;
    const filesLimit = this.props.filesLimit || '4';
    const { t } = this.props;
    if (filesLimit === 1) {
      if (oldFiles[0]) {
        await this.props.deleteFile(oldFiles[0].id);
      }
      this.setState({
        files: [files[0]],
        cropperOpen: true,
      });
    } else if (oldFiles.length > filesLimit) {
      this.props.showSnackbarMessage(t`fileInput:noMoreFiles`);
    } else {
      this.setState({
        files: oldFiles.concat(files),
        cropperOpen: true,
      });
    }
  };

  handleRemove = (file, fileIndex) => {
    const files = this.state.files;
    window.URL.revokeObjectURL(file.preview);

    files.splice(fileIndex, 1);
    this.setState({ files });

    if (file.id) {
      this.props.deleteFile(file.id);
    }
  };

  checkFilesSize = files => {
    const fileStatus = {
      overSize: false,
    };
    files.forEach(file => {
      if (file.size > this.props.maxSize) {
        fileStatus.overSize = true;
      }
    });
    return fileStatus.overSize;
  };

  setOneFile = async files => {
    await this.setState(files);
  };

  saveFiles = async () => {
    this.setState({ disabledSaveButton: true });
    const filesLimit = this.props.filesLimit || '4';
    const fileOverSize = this.checkFilesSize(this.state.files);
    const { t } = this.props;
    if (fileOverSize) {
      this.props.showSnackbarMessage(t`fileInput:tooLarge`);
    } else if (this.state.files.length > filesLimit) {
      this.props.showSnackbarMessage(t`fileInput:tooManyFiles`);
    } else if (this.state.files.length <= 0) {
      this.props.showSnackbarMessage(t`fileInput:noFile`);
    } else {
      await this.props.saveFiles(this.state.files);
    }
    this.setState({ disabledSaveButton: false });
  };

  onDropRejected = files => {
    const { t } = this.props;
    if (files[0].size > this.props.maxSize) {
      this.props.showSnackbarMessage(t`fileInput:tooLarge`);
    } else if (
      this.acceptedFilesDropZone[this.props.acceptedFiles] !== undefined &&
      !this.acceptedFilesDropZone[this.props.acceptedFiles]
        .split(',')
        .includes(files[0].type)
    ) {
      this.props.showSnackbarMessage(t`fileInput:badFormat`);
    } else {
      this.props.showSnackbarMessage(t`fileInput:rejected`);
    }
  };

  render() {
    const { maxSize, filesLimit, cropShape, aspect, removeIcon, t } =
      this.props;
    const { open, files, acceptedFiles, cropperOpen, disabledSaveButton } =
      this.state;
    let img;
    let previews = '';
    const fileSizeLimit = maxSize || 5000000;

    previews = files.map((file, i) => {
      const path = file.preview || '/pic' + file.path;

      if (file.type.startsWith('image')) {
        img = (
          <img className="smallPreviewImg" alt={file.filename} src={path} />
        );
      } else {
        img = <FileIcon className="smallPreviewImg" alt={file.filename} />;
      }

      return (
        <div className={'imageContainer col fileIconImg'} key={i}>
          <Tooltip title={file.name}>
            <div>
              {img}
              <div className="middle">
                {removeIcon && (
                  <IconButton onClick={this.handleRemove.bind(this, file, i)}>
                    <DeleteIcon className="removeBtn" />
                  </IconButton>
                )}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    });

    const allowedFiletypes = _.join(
      acceptedFiles.map(type => {
        return this.acceptedFilesDictionary[type] || type;
      }),
      ', '
    );
    if (files.length && cropperOpen && acceptedFiles[0] === 'image/*') {
      return (
        <ImageCropper
          image={files[files.length - 1].preview}
          files={files}
          index={files.length - 1}
          cropShape={cropShape}
          isOpen={this.state.open}
          handleClose={this.handleClose}
          setOneFile={this.setOneFile}
          aspect={aspect}
        />
      );
    }
    return (
      <Dialog title={'Wyślij pliki'} open={open} onClose={this.handleClose}>
        <DialogContent title={''}>
          <Paper>
            <Dropzone
              accept={
                this.acceptedFilesDropZone[acceptedFiles] ||
                acceptedFiles.join(',')
              }
              onDrop={this.onDrop}
              className={'dropZone'}
              acceptClassName={'stripes'}
              rejectClassName={'rejectStripes'}
              onDropRejected={this.onDropRejected}
              maxSize={fileSizeLimit}
            >
              <div className={'dropzoneTextStyle'}>
                <Typography variant="subtitle1" component="h1">
                  {t`fileInput:dnd`}
                </Typography>
                <CloudUploadIcon className={'uploadIconSize'} />
              </div>
            </Dropzone>
          </Paper>
          <br />
          <div className="row">
            {files.length ? (
              <Typography variant="subtitle1" component="h2">
                {t`fileInput:preview`}
              </Typography>
            ) : null}
          </div>
          <div className="row">{previews}</div>
          <Typography className="row" variant="subtitle1" component="h2">
            {t`fileInput:fileTypes`}: {allowedFiletypes}
          </Typography>
          <Typography className="row" variant="subtitle1" component="h2">
            {t`fileInput:maxFileSize`} : {maxSize / 1000000}MB
          </Typography>
          <Typography className="row" variant="subtitle1" component="h2">
            {t`fileInput:maxFiles`}: {filesLimit}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleClose()}
          >{t`fileInput:close`}</Button>
          <Button
            disabled={disabledSaveButton}
            onClick={() => this.saveFiles()}
            variant="contained"
          >
            {t`fileInput:save`}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = {
  showSnackbarMessage,
};

FileInput.propTypes = {
  open: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  closeDialog: PropTypes.func.isRequired,
  saveFiles: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  filesLimit: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired,
};

export default connect(null, mapDispatchToProps)(withTranslation()(FileInput));
