import {
  Grid,
  IconButton,
  Icon,
  Paper,
  withStyles,
  Collapse,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Translate, Button } from '..';
import { styles } from './WizardStep.style';

const WizardStep = ({
  classes,
  title,
  subInfo = null,
  required = false,
  isOpen,
  onClose,
  onOpen,
  children,
  isError,
  forceDisabled = false,
  disableArrowButton,
  withBlueBackground = false,
  withSaveButton = true,
  onCloseSelf,
  onClickArrow = () => {},
  style = {},
  alternativeSavedHeader = false,
  color,
  t,
}) => (
  <Grid container className={classes.container}>
    <Paper
      className={classNames(
        classes.paper,
        withBlueBackground && classes.blueBackground
      )}
    >
      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        alignItems="center"
        style={{ paddingBottom: 16 }}
      >
        <Grid container>
          <Grid item xs={8} sm={8} md={8} style={{ display: 'flex' }}>
            <Grid container justifyContent={subInfo ? 'space-between' : ''}>
              <Translate variant="h5" text={title} bold style={style} />
              {required && (
                <Translate
                  variant="h5"
                  text="*"
                  bold
                  style={{ color: 'red' }}
                />
              )}
              {subInfo && (
                <Translate
                  variant="h5"
                  text={subInfo}
                  style={{
                    color: '#52a3fc',
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            {alternativeSavedHeader && (
              <Translate
                variant="h5"
                text={t`isSaved`}
                bold
                style={{
                  color: '#1bff5c',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item onClick={onClickArrow}>
          <Grid container alignItems="center">
            {isOpen ? (
              <Icon className={classes.iconWrapper} onClick={onCloseSelf}>
                <KeyboardArrowUp className={classes.icon} />
              </Icon>
            ) : disableArrowButton ? (
              <Icon className={classes.iconWrapper}>
                <KeyboardArrowDown className={classes.icon} />
              </Icon>
            ) : (
              <IconButton onClick={onOpen}>
                <KeyboardArrowDown className={classes.icon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen}>
        <Grid container className={classes.children}>
          {children}
        </Grid>
        {withSaveButton && (
          <Grid
            container
            justifyContent="center"
            className={classes.saveButton}
          >
            <Grid item xs={12} sm={12} md={4}>
              <Button
                full={true}
                label="projectCreator:core.saveSection"
                type="submit"
                disabled={isError || forceDisabled}
                onClick={onClose}
              />
            </Grid>
          </Grid>
        )}
      </Collapse>
    </Paper>
  </Grid>
);

export default withStyles(styles)(withTranslation()(WizardStep));
