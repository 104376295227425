import { Card, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { getAvailabilityDiffDay } from './utils/utils';

const styles = theme => ({
  cardCandidateBox: {
    height: '100%',
    width: '100%',
    background: '#fafafa',
    boxShadow: '0px 0px 0px 5px #cccccc inset',
    margin: 0,
    borderRadius: 0,
    // borderLeft: '0px solid #fff',
    // borderRight: '1px solid #fff',
    // borderBottom: '1px solid #fff',
    // [theme.breakpoints.down('md')]: {
    //     borderLeft: '1px solid #fff',
    //     boxShadow: '0px 0px 0px 5px #e6e6e6 inset',
    // },
  },
  cardCandidateBoxRedBorder: {
    height: '100%',
    width: '100%',
    background: '#fafafa',
    boxShadow: '0px 0px 0px 5px red inset',
    margin: 0,
    borderRadius: 0,
    // borderLeft: '0px solid #fff',
    // borderRight: '1px solid #fff',
    // borderBottom: '1px solid #fff',
    // [theme.breakpoints.down('md')]: {
    //     borderLeft: '1px solid #fff',
    //     boxShadow: '0px 0px 0px 5px #e6e6e6 inset',
    // },
  },
  cardCandidateBoxLocked: {
    background: 'linear-gradient(to top, #ffe8e8, #fff7f7)',
  },
  cardCandidateBoxOwner: {
    background: 'linear-gradient(to top, #d4ffb7, #fff7f7)',
  },
  cardCandidateRejectFromEmploymentProcess: {
    background: 'linear-gradient(to top, #b9b9b9, #fff7f7)',
  },
  cardCandidateBoxPermissionsIsNotOwner: {
    background: 'linear-gradient(to top, #ffe8e8, #fff7f7)',
  },
  cardCandidateOwnerInProjectBox: {
    background: 'linear-gradient(to top, #fffcdd, #fff37a)',
  },
  cardCandidateGivenToRecruiterBox: {
    background: 'linear-gradient(to top, #e5f1ff, #cce4ff)',
  },
});

export const AtsCard = props => {
  const { classes, children, candidate, obj } = props;

  let boxClass = '';
  if (
    candidate.permissions.isCandidateInProjectOwner &&
    !candidate.permissions.isOwner
  ) {
    boxClass = classes.cardCandidateOwnerInProjectBox;
  } else if (
    !candidate.permissions.isCandidateInProjectOwner &&
    candidate.permissions.isOwner
  ) {
    boxClass = classes.cardCandidateGivenToRecruiterBox;
  } else if (
    candidate.permissions.isCandidateInProjectOwner &&
    candidate.permissions.isOwner
  ) {
    boxClass = classes.cardCandidateBoxOwner;
  } else if (
    !candidate.permissions.isOwner &&
    !candidate.permissions.isCandidateInProjectOwner
  ) {
    boxClass = classes.cardCandidateBoxPermissionsIsNotOwner;
  } else if (obj.locked) {
    boxClass = classes.cardCandidateBoxLocked;
  }
  if (obj.rejectedFromEmploymentProcess) {
    boxClass = classes.cardCandidateRejectFromEmploymentProcess;
  }

  const candidateDateStage = obj.isRejected
    ? obj[`stage${obj.stageNumber}DateReject`]
    : obj[`stage${obj.stageNumber}Date`];

  if (getAvailabilityDiffDay(candidateDateStage) >= 14) {
    return (
      <Card
        className={classNames(classes.cardCandidateBoxRedBorder, boxClass)}
        elevation={0}
      >
        {children}
      </Card>
    );
  }
  return (
    <Card
      className={classNames(classes.cardCandidateBox, boxClass)}
      elevation={0}
    >
      {children}
    </Card>
  );
};

AtsCard.propTypes = {
  children: PropTypes.array.isRequired,
};

export default withStyles(styles)(AtsCard);
