import { FormHelperText, Grid, Paper, withStyles } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { isNil, map, get, find, remove, isEmpty, has } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Hint, Translate, WizardStep } from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { preventEnterKey } from 'shared/utils/functions';
import CompetenceSelectCard from '../../../../project/NewProjectCreateView/components/ProjectCreateWizardStep2/components/CompetenceSelectCard';
import {
  useFormStep2,
  stage3NotSetFields,
} from '../CandidateCreateWizard/CandidateCreateWizard.utils';
import ExperienceSelectCard from '../ExperienceSelectCard';
import LanguageSelectCard from '../LanguageSelectCard';
import { styles } from './CandidateCreateWizardStep2.styles';
import { MODEL } from './CandidateCreateWizardStep2.utils';

const CandidateCreateWizardStep2 = ({
  t,
  initialCandidateValues,
  isStep2Open,
  isFormStepError,
  setIsStep3Open,
  isFormStepSaveButtonDisabled,
  closeAll,
  setIsStep2Open,
  isCandidateSave,
  history,
  setCurrentStage,
  step3FormValues,
  setIsSnackbarErrorOpen,
  industryCategories,
  setStep2FormValues,
  competences,
  languageCompetence,
  classes,
  showSaveButton,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const myRef = useRef(null);

  const formik = useFormStep2({
    t,
    initialCandidateValues,
    history,
    setCurrentStage,
  });

  const competenceInitialValues = get(formik.values, MODEL.userSkills)
    ? get(formik.values, MODEL.userSkills).filter(
        item => item !== undefined && item !== null
      )
    : [];

  const competenceCardsInitialValues = map(
    competenceInitialValues,
    (_, index) => ({
      index,
      isOpen: false,
      isSave: true,
    })
  );

  const [competenceCards, setCompetenceCards] = useState(
    isEmpty(competenceCardsInitialValues)
      ? [{ index: 0, isOpen: true, isSave: false }]
      : competenceCardsInitialValues
  );

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep2FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  const isEdit = !!find(
    competenceCards,
    card => !isNil(card) && !get(card, 'isSave')
  );

  const competencesCount = remove(
    map(competenceCards, o => o),
    o => o !== undefined && o.isSave === true
  ).length;

  const error =
    has(formik.touched, MODEL.userExperience) &&
    Boolean(get(formik.errors, MODEL.userExperience));

  useEffect(() => {
    if (isStep2Open) {
      executeScroll(myRef);
    }
  }, [isStep2Open]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classes.form}
      onKeyDown={preventEnterKey}
    >
      <ScrollPoint refForward={myRef} />
      <WizardStep
        withSaveButton={showSaveButton}
        title="candidateCreator:step2.title"
        myRef={myRef}
        isOpen={isStep2Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep2Open(false);
            const openSecondStep = stage3NotSetFields(
              initialCandidateValues,
              step3FormValues
            );

            setIsStep3Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep2Open(true);
          executeScroll();
        }}
        disableArrowButton={!isCandidateSave}
        onClickArrow={() => {
          if (!isCandidateSave) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => {
          setIsStep2Open(false);
        }}
      >
        <Grid container>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Hint
                  style={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                  content={
                    <div>{t('candidateCreator:step2.experienceHintLabel')}</div>
                  }
                  hintLabel={t('candidateCreator:step2.experienceHintTitle')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'candidateCreator:step2.experienceRequirements'}
                />
              </Grid>
              <ExperienceSelectCard
                formik={formik}
                industryCategories={industryCategories}
                initialCandidateValues={initialCandidateValues}
                t={t}
              />
            </Grid>
            {error && (
              <FormHelperText error>
                {has(formik.touched, MODEL.userExperience) &&
                  !isNil(get(formik.errors, MODEL.userExperience)) &&
                  t('projectCreator:core.requiredField')}
              </FormHelperText>
            )}
          </Paper>

          <Paper className={classes.paper} style={{ marginTop: 10 }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Hint
                  style={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                  content={
                    <div>{t('candidateCreator:step2.experienceHintLabel')}</div>
                  }
                  hintLabel={t(
                    'candidateCreator:step2.skillsAndCompetenceTitle'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'candidateCreator:step2.competenceRequirements'}
                />
              </Grid>
            </Grid>
            <Grid container>
              {map(
                competenceCards,
                card =>
                  !isNil(card) &&
                  card !== undefined &&
                  has(card, 'index') &&
                  card.index !== undefined && (
                    <CompetenceSelectCard
                      formik={formik}
                      experienceFieldName={MODEL.experience}
                      fieldName={MODEL.userSkills}
                      index={card.index}
                      onSave={() =>
                        setCompetenceCards(
                          map(competenceCards, card =>
                            isNil(card)
                              ? undefined
                              : {
                                  index: card.index,
                                  isOpen: false,
                                  isSave: true,
                                }
                          )
                        )
                      }
                      isOpen={card.isOpen}
                      isSave={card.isSave}
                      key={card.index}
                      competences={competences}
                      onEdit={() =>
                        setCompetenceCards(
                          map(competenceCards, item =>
                            isNil(card)
                              ? undefined
                              : {
                                  ...item,
                                  isOpen: item
                                    ? card.index === item.index
                                    : false,
                                }
                          )
                        )
                      }
                      onDelete={() => {
                        const cardFilter = competenceCards.filter(
                          (item, index) => index !== card.index
                        );
                        const cardMappedIndex = cardFilter.map(
                          (item, index) => {
                            return { ...item, index };
                          }
                        );

                        setCompetenceCards(cardMappedIndex);
                        const formikFieldArray =
                          formik.values[MODEL.userSkills];
                        const formikFieldArrayFilter = formikFieldArray.filter(
                          (item, index) => index !== card.index
                        );
                        formik.setFieldValue(
                          `${MODEL.userSkills}`,
                          formikFieldArrayFilter.length
                            ? formikFieldArrayFilter
                            : []
                        );
                      }}
                      isEdit={isEdit}
                    />
                  )
              )}
              {error && (
                <FormHelperText error>
                  {has(formik.touched, MODEL.userSkills) &&
                    !isNil(get(formik.errors, MODEL.userSkills)) &&
                    t('projectCreator:core.requiredField')}
                </FormHelperText>
              )}
            </Grid>
            <Grid
              container
              style={{ padding: 10 }}
              spacing={2}
              alignItems="center"
            >
              {!isEdit && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Button
                      label={t('projectCreator:step2.addCompetence')}
                      onClick={() => {
                        setCompetenceCards([
                          ...map(competenceCards, card =>
                            isNil(card)
                              ? undefined
                              : {
                                  index: card.index,
                                  isOpen: false,
                                  isSave: true,
                                }
                          ),
                          {
                            index: competenceCards.length,
                            isOpen: true,
                            isSave: false,
                          },
                        ]);
                        formik.setFieldValue(
                          `${MODEL.userSkills}[${competenceCards.length}].${MODEL.competenceType}`,
                          get(
                            formik.values,
                            `${MODEL.userSkills}[${
                              competenceCards.length - 1
                            }].${MODEL.competenceType}`
                          )
                        );

                        formik.setFieldTouched(
                          `${MODEL.userSkills}[${competenceCards.length}].${MODEL.skill}`
                        );

                        formik.setFieldTouched(
                          `${MODEL.userSkills}[${competenceCards.length}].${MODEL.experience}`
                        );
                      }}
                    />
                  </Grid>
                  {competencesCount < 1 && (
                    <Grid xs={12} sm={6} md={6}>
                      <Paper className={classes.warningMessage} elevation={0}>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>
                            <WarningRounded />
                          </Grid>
                          <Grid item>
                            <Translate
                              text="projectCreator:step2.addCompetence"
                              bold
                              variant="body1"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: 10 }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Hint
                  style={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                  content={
                    <div>{t('candidateCreator:step2.experienceHintLabel')}</div>
                  }
                  hintLabel={t('candidateCreator:step2.languageHintTitle')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'candidateCreator:step2.languageRequirements'}
                />
              </Grid>
              <LanguageSelectCard
                formik={formik}
                industryCategories={industryCategories}
                languageCompetence={languageCompetence}
                initialCandidateValues={initialCandidateValues}
                t={t}
              />
            </Grid>
            {error && (
              <FormHelperText error>
                {has(formik.touched, MODEL.userLanguageSkills) &&
                  !isNil(get(formik.errors, MODEL.userLanguageSkills)) &&
                  t('projectCreator:core.requiredField')}
              </FormHelperText>
            )}
          </Paper>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default withStyles(styles)(
  withTranslation()(CandidateCreateWizardStep2)
);
