import { AppBar, Toolbar, IconButton, withStyles } from '@material-ui/core';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import IconBar from './IconBar';
import Logotype from './Logotype';
import SearchBar from './SearchBar';
import UserAccount from './UserAccount';

const styles = theme => ({
  menuButton: {
    marginLeft: 0,
    marginRight: 36,
    [theme.breakpoints.only('xs')]: {
      marginLeft: 24,
      width: 'auto',
      marginRight: 0,
    },
  },
  hide: {
    display: 'none',
  },
  toolBar: {
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  appBar: {
    background: '#575757',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

export class TopAppBar extends Component {
  render() {
    const { classes, handleDrawerOpen, handleDrawerClose, isOpened } =
      this.props;

    return (
      <AppBar className={classNames(classes.appBar)}>
        <Toolbar className={classes.toolBar}>
          {isOpened ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              className={classNames(classes.menuButton)}
            >
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Logotype />
          <SearchBar />
          <IconBar />
          <UserAccount />
        </Toolbar>
      </AppBar>
    );
  }
}

TopAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
};

export default withStyles(styles)(TopAppBar);
