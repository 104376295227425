import { withStyles } from '@material-ui/core/styles';
import Register from './Register';

const styles = () => ({
  formContainer: {
    height: 'auto',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default withStyles(styles)(Register);
