import axios from 'axios';
import _ from 'lodash';
import { push } from 'react-router-redux';
import { PATHS } from 'config';
import { API, API_URL } from '../constants';
import {
  setCurrentCandidateAction,
  setCurrentCandidatesListAction,
  setCandidatesBelongsToRecruiterListAction,
  setCandidatesPendingToRecruiterListAction,
  clearCurrentCandidateAction,
  clearMyCandidatesAction,
  clearCandidatesInProjectAction,
  clearCurrentCandidatesListAction,
  setCurrentCandidatesLimitedListAction,
  clearCurrentCandidatesLimitedListAction,
  setCurrentCandidatesLimitedListCounterAction,
  clearCurrentCandidatesLimitedListCounterAction,
} from '../store/candidates/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const clearMyCandidates = () => {
  return async (dispatch, getState) => {
    dispatch(clearMyCandidatesAction());
  };
};

export const clearCandidatesInProject = () => {
  return async (dispatch, getState) => {
    dispatch(clearCandidatesInProjectAction());
  };
};

export const clearCurrentCandidate = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentCandidateAction());
  };
};

export const clearCurrentCandidatesList = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentCandidatesListAction());
  };
};

export const clearCurrentCandidatesLimitedList = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentCandidatesLimitedListAction());
  };
};

export const clearCurrentCandidatesLimitedListCounter = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentCandidatesLimitedListCounterAction());
  };
};

export const getCandidate = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.SHOW.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidateAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const getCandidates = params => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: { type: 'candidate', ...params },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidatesListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const countCandidateWithLimits = params => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.USER.GET,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      type: 'candidate',
      count: true,
      ...params,
    },
  });
};
export const getCandidatesWithLimit = (limit, offset, params) => {
  return async dispatch => {
    const candidatesCount = countCandidateWithLimits(params)
      .then(response => {
        const {
          data: { count },
        } = response;
        dispatch(setCurrentCandidatesLimitedListCounterAction(count));
        return { count };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
    const candidatesData = axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        type: 'candidate',
        limit: limit,
        offset: offset,
        ...params,
      },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidatesLimitedListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
    return await Promise.all([candidatesCount, candidatesData]).then(
      apiData => {
        return {
          count: apiData[0]['count'],
          data: apiData[1]['data'],
          status: apiData[1]['status'],
        };
      }
    );
  };
};

export const getCandidatesCount = params => {
  return async dispatch => {
    return axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        type: 'candidate',
        count: true,
        ...params,
      },
    })
      .then(response => {
        const {
          data,
          data: { count },
          status,
        } = response;
        return { data, status, count };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getCandidatesBelongsToRecruiter = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: { owner: id, withPublic: false, type: 'candidate' },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCandidatesBelongsToRecruiterListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const getCandidatesPendingToRecruiter = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: { ownerToBe: id, type: 'candidate' },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCandidatesPendingToRecruiterListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const addCandidate = values => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.USER.CREATE,
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidateAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const editCandidate = values => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.USER.EDIT.replace(':id', values.id),
      data: _.pick(values, [
        'isPublic',
        'ownerId',
        'enabled',
        'email',
        'firstName',
        'lastName',
        'description',
        'birthDate',
        'city',
        'languages',
        'position',
        'industry',
        'industryCategory',
        'phonePrimary',
        'phoneSecondary',
        'minMonthlyWadges',
        'maxMonthlyWadges',
        'availabilityDate',
        'experience',
        'contractType',
        'rating',
        'ratingDescription',
        'assets',
        'nationality',
        'personalityCurious',
        'personalityOrganized',
        'personalityExtraversion',
        'personalityCompassionate',
        'personalitySecure',
      ]),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidateAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const deleteCandidate = id => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url: API_URL + API.DELETE.USER.DELETE.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const acceptConfirmationStatusToCandidate = id => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.USER.EDIT.replace(':id', id),
      data: {
        confirmationStatusByRecruiter: 2,
      },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCandidatesPendingToRecruiterListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const rejectConfirmationStatusToCandidate = id => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.USER.EDIT.replace(':id', id),
      data: {
        confirmationStatusByRecruiter: 3,
      },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCandidatesPendingToRecruiterListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
