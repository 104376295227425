import { createAction } from '@reduxjs/toolkit';
import {
  SET_SELECTED_USER,
  CLEAR_SELECTED_USER,
  SET_SELECTED_USERS_LIST,
  SET_SELECTED_USERS_LIST_COUNT,
  CLEAR_SELECTED_USERS_LIST,
  SET_CURRENT_USERS_LIMITED_LIST,
  CLEAR_CURRENT_USERS_LIMITED_LIST,
  SET_CURRENT_USERS_LIMITED_LIST_COUNTER,
  CLEAR_CURRENT_USERS_LIMITED_LIST_COUNTER,
} from '../actionConstants';

export const setSelectedUserAction = createAction(SET_SELECTED_USER);
export const clearSelectedUserAction = createAction(CLEAR_SELECTED_USER);
export const setSelectedUsersListAction = createAction(SET_SELECTED_USERS_LIST);
export const setSelectedUsersListCountAction = createAction(
  SET_SELECTED_USERS_LIST_COUNT
);
export const clearSelectedUsersListAction = createAction(
  CLEAR_SELECTED_USERS_LIST
);
export const setCurrentUsersLimitedListAction = createAction(
  SET_CURRENT_USERS_LIMITED_LIST
);
export const clearCurrentUsersLimitedListAction = createAction(
  CLEAR_CURRENT_USERS_LIMITED_LIST
);
export const setCurrentUsersLimitedListCounterAction = createAction(
  SET_CURRENT_USERS_LIMITED_LIST_COUNTER
);
export const clearCurrentUsersLimitedListCounterAction = createAction(
  CLEAR_CURRENT_USERS_LIMITED_LIST_COUNTER
);
