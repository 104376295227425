import {
  Avatar,
  IconButton,
  CircularProgress,
  TableRow,
  TableCell,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  // ListItemSecondaryAction,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  LocationCity as OrganizationIcon,
  CompareArrows as CompareArrowsIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getUserAvatarSrc } from 'shared/utils/assets';

const styles = theme => ({
  buttonAdd: {
    width: 20,
    height: 20,
  },
  avatar: {
    width: 32,
    height: 32,
  },
  Link: {
    textDecoration: 'none',
    color: '#000',
  },
  ul: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  toolTip: {
    background: theme.palette.jobllegroBlue,
    color: '#fff',
    fontSize: 14,
  },
  toolTipBlocked: {
    background: theme.palette.jobllegroRedAlert,
  },
  progressCircular: {
    marginRight: 16,
  },
  iconDisabled: {
    color: theme.palette.jobllegroRedAlert,
  },
  industryOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '10rem',
  },
});

export const OrganizationRightListItem = props => {
  const { data, isLoading, onAddHandle, classes, currentUser, t } = props;
  const { organization = {} } = data;
  const avatar = getUserAvatarSrc(data);

  const titleCard = data => {
    const link = PATHS.RECRUITER.SHOW;
    return (
      <Link className={classes.Link} to={link.replace(':id', data.id)}>
        {data.firstName + ' ' + data.lastName}
      </Link>
    );
  };

  return (
    currentUser.id !== data.id && (
      <TableRow key={data.id}>
        <TableCell style={{ paddingLeft: 0 }}>
          <List className={classes.ul}>
            <ListItem className={classes.ul}>
              {isLoading ? (
                <CircularProgress
                  className={classes.progressCircular}
                  size={20}
                />
              ) : (
                <Tooltip
                  title={
                    organization
                      ? t('project:form.sendInvitationToProjectBlocked')
                      : t('project:form.sendInvitationToProject')
                  }
                  placement="top"
                  classes={{
                    tooltip: classNames(
                      classes.toolTip,
                      organization && classes.toolTipBlocked
                    ),
                  }}
                >
                  <ListItemIcon>
                    {organization ? (
                      <OrganizationIcon className={classes.iconDisabled} />
                    ) : (
                      <IconButton
                        className={classes.buttonAdd}
                        onClick={onAddHandle.bind(this, data.id)}
                        color="primary"
                      >
                        <CompareArrowsIcon />
                      </IconButton>
                    )}
                  </ListItemIcon>
                </Tooltip>
              )}
              <Avatar
                src={avatar}
                aria-label="Jobllegro"
                component={Link}
                to={PATHS.RECRUITER.SHOW.replace(':id', data.id)}
                className={classes.avatar}
              ></Avatar>
              <ListItemText
                primary={titleCard(data)}
                secondary={
                  data.availabilityDate !== null &&
                  t('project:show.availableFrom') + data.availabilityDate
                }
              />
            </ListItem>
          </List>
        </TableCell>
        <TableCell>
          <div className={classes.industryOverflow}>{data.industry}</div>
        </TableCell>
        <TableCell>{data.city}</TableCell>
        <TableCell>{data.position}</TableCell>
      </TableRow>
    )
  );
};

const mapStateToProps = store => {
  return {
    open: store.messages.open,
    currentUser: store.auth.currentUser,
  };
};

OrganizationRightListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onAddHandle: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(OrganizationRightListItem)));
