import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { enums } from 'shared/enums';
import {
  TextField,
  Translate,
  Select,
  Checkbox,
  WizardStep,
  Button,
  AutocompleteFreeSolo,
} from 'shared/newComponents';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import {
  getSelectItemsFullObject,
  getSelectItemsFullObjectTranslate,
  sortItemsBy,
} from 'shared/utils/assets';
import { countriesPhoneCode } from 'shared/utils/countriesPhoneCodes';
import {
  birthDayObjectForSelect,
  castObjectForSelect,
  castObjectPhoneCodesForSelect,
} from 'shared/utils/functions';
import CustomDialog from '../../../../../components/CustomDialog/index';
import {
  useFormStep1,
  stage2NotSetFields,
} from '../CandidateCreateWizard/CandidateCreateWizard.utils';
import { MODEL } from './CandidateCreateWizardStep1.utils';

const CandidateCreateWizardStep1 = ({
  countries,
  positions,
  industryCategories,
  departments,
  initialCandidateValues,
  isStep1Open,
  setIsStep1Open,
  isFormStepSaveButtonDisabled,
  closeAll,
  t,
  history,
  isFormStepError,
  isCandidateSave,
  step2FormValues,
  setIsStep2Open,
  setStep1FormValues,
  project,
  showSaveButton,
  title = null,
  alternativeSavedHeader = false,
  callBackSave = null,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [isFirstStageApproveOpen, setIsFirstStageApproveOpen] = useState(false);

  const initialValues = {
    ...initialCandidateValues,
    availabilityTypeCandidate:
      initialCandidateValues.availabilityTypeCandidate !== null
        ? initialCandidateValues.availabilityTypeCandidate
        : '',
    availabilityDate: initialCandidateValues.availabilityDate
      ? initialCandidateValues.availabilityDate
      : moment().format('YYYY-MM-DD'),
  };

  const onClose = () => {
    if (!isFormStepError(formik) && isCandidateSave) {
      setIsStep1Open(false);
      const openSecondStep = stage2NotSetFields(
        initialCandidateValues,
        step2FormValues
      );
      setIsStep2Open(openSecondStep);
      if (!openSecondStep) {
        executeScrollToTop();
      }
    }
  };

  const formik = useFormStep1({
    t,
    initialCandidateValues: initialValues,
    history,
    project,
    onClose,
    callBackSave,
  });

  const myRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep1FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  useEffect(() => {
    if (isStep1Open) {
      executeScroll(myRef);
    }
  }, [isStep1Open]);

  const onChangeIsForeigner = event => {
    if (!showSaveButton) {
      return;
    }
    if (!event.target.checked) {
      formik.setFieldValue(MODEL.citizenship, undefined);
      formik.setFieldValue(MODEL.isFistStageInfoPublic, false);
      formik.setFieldValue(MODEL.passportNumber, null);
      formik.setFieldValue(MODEL.passportSerialNumber, null);
      formik.setFieldValue(MODEL.plannedResidenceTitle, null);

      formik.setFieldValue(MODEL.residenceTitle, null);
      formik.setFieldValue(MODEL.isForeignerInEmploymentArea, false);
      formik.setFieldValue(MODEL.arrivalDateEmploymentCountry, null);
    }
    formik.setFieldValue(MODEL.isForeigner, event.target.checked);
  };

  const onChangeIsForeignerInEmploymentArea = event => {
    if (!showSaveButton) {
      return;
    }
    if (!event.target.checked) {
      formik.setFieldValue(MODEL.residenceTitle, null);
      formik.setFieldValue(MODEL.arrivalDateEmploymentCountry, null);
    }
    formik.setFieldValue(
      MODEL.isForeignerInEmploymentArea,
      event.target.checked
    );
  };

  const onChangeFirstStageInfo = event => {
    if (event.target.checked) {
      setIsFirstStageApproveOpen(event.target.checked);
    }
    if (!event.target.checked) {
      formik.setFieldValue(MODEL.isFistStageInfoPublic, false);
    }
  };

  const onCloseFirstDialog = () => {
    setIsFirstStageApproveOpen(false);
    formik.setFieldValue(MODEL.isFistStageInfoPublic, false);
  };

  const onClickApproveFirstStage = () => {
    onCloseFirstDialog();
    formik.setFieldValue(MODEL.isFistStageInfoPublic, true);
  };

  const onChangeSexMen = event => {
    if (event.target.checked) {
      formik.setFieldValue(MODEL.isWomen, false);
    }
    formik.setFieldValue(MODEL.isMen, event.target.checked);
  };
  const onChangeSexWomen = event => {
    if (event.target.checked) {
      formik.setFieldValue(MODEL.isMen, false);
    }
    formik.setFieldValue(MODEL.isWomen, event.target.checked);
  };

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <ScrollPoint refForward={myRef} />
      <WizardStep
        alternativeSavedHeader={alternativeSavedHeader}
        withSaveButton={showSaveButton}
        title={title ? title : 'projectCreator:step1.title'}
        required
        isOpen={isStep1Open}
        onClose={null}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep1Open(true);
        }}
        disableArrowButton={false}
        onCloseSelf={() => {
          setIsStep1Open(false);
        }}
      >
        <Grid container>
          <Grid container style={{ padding: '0 16px' }} variant="h6">
            <Translate
              text="candidateCreator:step1.personalData"
              bold
              variant="h6"
            />
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Grid container>
                <Grid item xs={3} sm={3} md={3}>
                  <Checkbox
                    onChange={event => onChangeSexMen(event)}
                    label={t('candidateCreator:step1.men')}
                    name={MODEL.isMen}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Checkbox
                    onChange={event => onChangeSexWomen(event)}
                    label={t('candidateCreator:step1.women')}
                    name={MODEL.isWomen}
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label={t('candidateCreator:step1.firstName')}
                name={MODEL.firstName}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label={t('candidateCreator:step1.lastName')}
                name={MODEL.lastName}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label={t('candidateCreator:step1.email')}
                name={MODEL.email}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <MultiSelect
                    label={t('candidateCreator:step1.phoneCode')}
                    name={MODEL.phonePrimaryCode}
                    formik={formik}
                    options={castObjectPhoneCodesForSelect(countriesPhoneCode)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    label={t('candidateCreator:step1.phone')}
                    name={MODEL.phonePrimary}
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Grid container>
                <MultiSelect
                  label={t('candidateCreator:step1.homec')}
                  name={MODEL.homeCountry}
                  formik={formik}
                  options={castObjectForSelect(countries)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container>
                <Select
                  items={birthDayObjectForSelect()}
                  label={t('candidateCreator:step1.birthDate')}
                  name={MODEL.birthYear}
                  formik={formik}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Checkbox
                onChange={event => onChangeIsForeigner(event)}
                label={t('candidateCreator:step5.isForeigner')}
                name={MODEL.isForeigner}
                formik={formik}
              />
            </Grid>
            <Grid
              container
              style={{
                display: formik.values[MODEL.isForeigner] ? 'flex' : 'none',
                marginTop: 10,
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Grid container>
                  <MultiSelect
                    label={t('candidateCreator:step5.choseCitizenship')}
                    name={MODEL.citizenship}
                    formik={formik}
                    options={castObjectForSelect(countries)}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label={t('candidateCreator:step1.passportSerialNumber')}
                    name={MODEL.passportSerialNumber}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label={t('candidateCreator:step1.passportNumber')}
                    name={MODEL.passportNumber}
                    formik={formik}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={8}>
                  <Select
                    items={sortItemsBy(
                      getSelectItemsFullObjectTranslate(
                        enums.RESIDENCE_TITLE,
                        t
                      ),
                      'name'
                    )}
                    label={t('candidateCreator:step1.plannedResidenceTitle')}
                    name={MODEL.plannedResidenceTitle}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Checkbox
                  onChange={event => onChangeIsForeignerInEmploymentArea(event)}
                  label={t(
                    'candidateCreator:step5.isForeignerInEmploymentArea'
                  )}
                  name={MODEL.isForeignerInEmploymentArea}
                  formik={formik}
                />
              </Grid>
              <Grid
                container
                style={{
                  display: formik.values[MODEL.isForeignerInEmploymentArea]
                    ? 'flex'
                    : 'none',
                }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <Select
                    items={sortItemsBy(
                      getSelectItemsFullObjectTranslate(
                        enums.RESIDENCE_TITLE,
                        t
                      )
                    )}
                    label={t('candidateCreator:step5.residenceTitle')}
                    name={MODEL.residenceTitle}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label={t(
                      'candidateCreator:step5.arrivalDateEmploymentCountry'
                    )}
                    name={MODEL.arrivalDateEmploymentCountry}
                    type="date"
                    formik={formik}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Checkbox
                  onChange={event => onChangeFirstStageInfo(event)}
                  style={{
                    color: '#818589',
                  }}
                  label={t('candidateCreator:step5.isFirstStageInfoPublic')}
                  name={MODEL.isFistStageInfoPublic}
                  formik={formik}
                  type="switch"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container style={{ padding: '32px 16px 0 16px' }}>
              <Translate
                text="candidateCreator:step1.preferencesTitle"
                bold
                variant="h6"
              />
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12} md={12}>
                <AutocompleteFreeSolo
                  formik={formik}
                  limit={20}
                  items={[...enums.POSITION_DEFAULT_ARRAY, ...positions]}
                  label={t('candidateCreator:step1.position')}
                  name={MODEL.position}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label={t('candidateCreator:step1.city')}
                  name={MODEL.city}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  label={t('candidateCreator:step1.remoteWorkOnly')}
                  name={MODEL.isRemoteWorkOnly}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MultiSelect
                  options={castObjectForSelect(countries)}
                  label={t('candidateCreator:step1.country')}
                  name={MODEL.country}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MultiSelect
                  options={castObjectForSelect(industryCategories)}
                  label={t('candidateCreator:step1.industryCategory')}
                  name={MODEL.industryCategory}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MultiSelect
                  options={castObjectForSelect(departments)}
                  label={t('candidateCreator:step1.department')}
                  name={MODEL.department}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Select
                  items={getSelectItemsFullObject(enums.COMPANY_SIZE)}
                  label={t('candidateCreator:step1.companySize')}
                  name={MODEL.companySize}
                  formik={formik}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container style={{ padding: '32px 16px 0 16px' }}>
              <Translate
                text="candidateCreator:step1.availabilityTitle"
                bold
                variant="h6"
              />
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  name={MODEL.availabilityDate}
                  type="date"
                  formik={formik}
                />
              </Grid>
              <Grid container alignItems="center">
                <Checkbox
                  style={{
                    color: '#818589',
                  }}
                  label={t('candidateCreator:step1.isPrivate')}
                  name={MODEL.isPublic}
                  formik={formik}
                  type="switch"
                  hintContent={
                    <div>
                      {/*{t("projectCreator:step1.remoteWorkHintcompanyDatePublic")}*/}
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CustomDialog
          title={t('candidateCreator:step5.isFirstStageInfoPublic')}
          maxWidth={'lg'}
          isOpen={isFirstStageApproveOpen}
          onCloseDialog={onCloseFirstDialog}
          body={
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'candidateCreator:step5.isFirstStageInfoPublicTextInfo'}
                />
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item onClick={onClickApproveFirstStage}>
                  <Button
                    label={t('candidateCreator:step5.approve')}
                    variant="outlined"
                    onClick={() => {}}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </WizardStep>
    </form>
  );
};

export default CandidateCreateWizardStep1;
