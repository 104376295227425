import { TableRow, TableCell, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DataItemListItemProject from '../../../components/DataItemListItemProject';
import ProjectStepper from '../../../components/ProjectStepper';

const styles = theme => ({
  TableCell: {
    color: theme.palette.black,
    fontSize: 12,
    fontWeight: 300,
    textAlign: 'center',
  },
  avatar: {
    width: 50,
    height: 50,
  },
  hiddenSm: {
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
  },
  hiddenXs: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  zeroField: {
    color: theme.palette.jobllegroLightGrey,
  },
  projectClosedRow: {
    opacity: '0.4',
  },
  stepperWrapper: {
    display: 'flex',
    height: '100%',
    paddingRight: 24,
  },
});

export const SummaryRowComponent = props => {
  const {
    classes: c,
    classes,
    data,
    key,
    projectIsClosed = false,
    dashboardView = false,
  } = props;

  return (
    <TableRow
      classes={{
        root: projectIsClosed && classes.projectClosedRow,
      }}
      hover
      key={key}
    >
      <TableCell padding="none">
        <DataItemListItemProject data={data} />
      </TableCell>
      <TableCell
        padding="none"
        style={{ textAlign: 'center' }}
        className={classNames(c.hiddenSm, c.hiddenXs)}
      >
        <div className={classes.stepperWrapper}>
          <ProjectStepper
            startDate={data.projectStartDate}
            endDate={data.projectEndDate}
            variantMini={true}
          />
        </div>
      </TableCell>
      <TableCell
        classes={{
          root: data.stage1 === 0 && classes.zeroField,
        }}
        align="right"
        padding="none"
        className={classNames(c.TableCell, c.hiddenSm, c.hiddenXs)}
      >
        {data.stage1}
      </TableCell>
      <TableCell
        classes={{
          root: data.stage2 === 0 && classes.zeroField,
        }}
        align="right"
        padding="none"
        className={classNames(c.TableCell, c.hiddenSm, c.hiddenXs)}
      >
        {data.stage2}
      </TableCell>
      <TableCell
        classes={{
          root: data.stage3 === 0 && classes.zeroField,
        }}
        align="right"
        padding="none"
        className={classNames(c.TableCell, c.hiddenSm, c.hiddenXs)}
      >
        {data.stage3}
      </TableCell>
      {!dashboardView && (
        <React.Fragment>
          <TableCell
            classes={{
              root: data.stage4 === 0 && classes.zeroField,
            }}
            align="right"
            padding="none"
            className={classNames(c.TableCell, c.hiddenXs)}
          >
            {data.stage4}
          </TableCell>
          <TableCell
            classes={{
              root: data.stage5 === 0 && classes.zeroField,
            }}
            align="right"
            padding="none"
            className={classNames(c.TableCell, c.hiddenXs)}
          >
            {data.stage5}
          </TableCell>
          <TableCell
            classes={{
              root: data.stage6 === 0 && classes.zeroField,
            }}
            align="right"
            padding="none"
            className={c.TableCell}
          >
            {data.stage6}
          </TableCell>
          <TableCell
            classes={{
              root: data.rejected === 0 && classes.zeroField,
            }}
            align="right"
            padding="none"
            className={c.TableCell}
          >
            {data.rejected}
          </TableCell>
          <TableCell
            classes={{
              root: data.coCount === 0 && classes.zeroField,
            }}
            align="right"
            padding="none"
            className={classNames(c.TableCell, c.hiddenXs)}
          >
            {data.coCount}
          </TableCell>
        </React.Fragment>
      )}
    </TableRow>
  );
};

SummaryRowComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(SummaryRowComponent);
