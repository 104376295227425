import { FormHelperText, Grid, Paper, withStyles } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { isNil, map, get, find, remove, isEmpty, has } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Hint,
  TextEditor,
  Translate,
  WizardStep,
} from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { CompetenceService } from 'shared/services';
import { isProjectClosed, preventEnterKey } from 'shared/utils/functions';
import {
  stage3NotSetFields,
  useFormStep2,
} from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { styles } from './ProjectCreateWizardStep2.styles';
import { MODEL } from './ProjectCreateWizardStep2.utils';
import { CompetenceSelectCard, WorkStyleComponent } from './components';

const ProjectCreateWizardStep2 = ({
  classes,
  t,
  initialProjectValues,
  isStep2Open,
  isFormStepError,
  setIsStep3Open,
  isFormStepSaveButtonDisabled,
  closeAll,
  setIsStep2Open,
  isProjectSave,
  history,
  setStep2FormValues,
  setCurrentStage,
  step3FormValues,
  setIsSnackbarErrorOpen,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const myRef = useRef(null);

  const formik = useFormStep2({
    t,
    initialProjectValues,
    history,
    setCurrentStage,
  });
  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep2FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  const competenceInitialValues = get(formik.values, MODEL.projectSkills)
    ? get(formik.values, MODEL.projectSkills).filter(
        item => item !== undefined && item !== null
      )
    : [];

  const competenceCardsInitialValues = map(
    competenceInitialValues,
    (_, index) => ({
      index,
      isOpen: false,
      isSave: true,
    })
  );
  const [competences, setCompetences] = useState([]);
  const [competenceCards, setCompetenceCards] = useState(
    isEmpty(competenceCardsInitialValues)
      ? [{ index: 0, isOpen: true, isSave: false }]
      : competenceCardsInitialValues
  );

  useEffect(() => {
    const projectId = formik.values.id ? formik.values.id : 0;
    CompetenceService.getCompetences({ project: projectId, user: 0 })
      .then(({ data }) => {
        setCompetences(data);
      })
      .catch(error => console.log(error));
  }, []);

  const isEdit = !!find(
    competenceCards,
    card => !isNil(card) && !get(card, 'isSave')
  );

  const competencesCount = remove(
    map(competenceCards, o => o),
    o => o !== undefined
  ).length;

  const error =
    has(formik.touched, MODEL.projectSkills) &&
    Boolean(get(formik.errors, MODEL.projectSkills));

  useEffect(() => {
    if (isStep2Open) {
      executeScroll(myRef);
    }
  }, [isStep2Open]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classes.form}
      onKeyDown={preventEnterKey}
    >
      <ScrollPoint refForward={myRef} />
      <WizardStep
        withSaveButton={!isProjectClosed(initialProjectValues)}
        forceDisabled={
          formik.values.projectSkills
            ? formik.values.projectSkills.filter(
                item => item !== null && item !== undefined
              ).length < 3
            : false
        }
        title="projectCreator:step2.title"
        myRef={myRef}
        required
        isOpen={isStep2Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep2Open(false);
            const openSecondStep = stage3NotSetFields(
              initialProjectValues,
              step3FormValues
            );

            setIsStep3Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep2Open(true);
          executeScroll();
        }}
        disableArrowButton={!isProjectSave}
        onClickArrow={() => {
          if (!isProjectSave) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => {}}
      >
        <Grid container>
          <Grid container>
            <Hint
              content={
                <div style={{ fontSize: 18 }}>
                  {t('projectCreator:step2.competence1Hint')}
                </div>
              }
              hintLabel={t('projectCreator:step2.competence2Hint')}
            />
          </Grid>
          <Grid container>
            {map(
              competenceCards,
              card =>
                !isNil(card) &&
                card !== undefined &&
                has(card, 'index') &&
                card.index !== undefined && (
                  <CompetenceSelectCard
                    formik={formik}
                    index={card.index}
                    onSave={() =>
                      setCompetenceCards(
                        map(competenceCards, card =>
                          isNil(card)
                            ? undefined
                            : {
                                index: card.index,
                                isOpen: false,
                                isSave: true,
                              }
                        )
                      )
                    }
                    isOpen={card.isOpen}
                    isSave={card.isSave}
                    key={card.index}
                    competences={competences}
                    onEdit={() =>
                      setCompetenceCards(
                        map(competenceCards, item =>
                          isNil(card)
                            ? undefined
                            : {
                                ...item,
                                isOpen: item
                                  ? card.index === item.index
                                  : false,
                              }
                        )
                      )
                    }
                    onDelete={() => {
                      const cardFilter = competenceCards.filter(
                        (item, index) => index !== card.index
                      );
                      const cardMappedIndex = cardFilter.map((item, index) => {
                        return { ...item, index };
                      });

                      setCompetenceCards(cardMappedIndex);

                      const formikFieldArray =
                        formik.values[MODEL.projectSkills];
                      const formikFieldArrayFilter = formikFieldArray.filter(
                        (item, index) => index !== card.index
                      );
                      formik.setFieldValue(
                        `${MODEL.projectSkills}`,
                        formikFieldArrayFilter.length
                          ? formikFieldArrayFilter
                          : []
                      );
                    }}
                    isEdit={isEdit}
                  />
                )
            )}
            {error && (
              <FormHelperText error>
                {has(formik.touched, MODEL.projectSkills) &&
                  !isNil(get(formik.errors, MODEL.projectSkills)) &&
                  t('projectCreator:core.requiredField')}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            container
            style={{ padding: 10 }}
            spacing={2}
            alignItems="center"
          >
            {!isEdit && (
              <>
                <Grid item xs={5}>
                  <Button
                    label={t('projectCreator:step2.addCompetence')}
                    onClick={() => {
                      setCompetenceCards([
                        ...map(competenceCards, card =>
                          isNil(card)
                            ? undefined
                            : {
                                index: card.index,
                                isOpen: false,
                                isSave: true,
                              }
                        ),
                        {
                          index: competenceCards.length,
                          isOpen: true,
                          isSave: false,
                        },
                      ]);

                      formik
                        .setFieldValue(
                          `${MODEL.projectSkills}[${competenceCards.length}].${MODEL.competenceType}`,
                          get(
                            formik.values,
                            `${MODEL.projectSkills}[${
                              competenceCards.length - 1
                            }].${MODEL.competenceType}`
                          )
                        )
                        .then(() =>
                          formik.setFieldTouched(
                            `${MODEL.projectSkills}[${competenceCards.length}].${MODEL.competenceType}`
                          )
                        );

                      formik.setFieldTouched(
                        `${MODEL.projectSkills}[${competenceCards.length}].${MODEL.skill}`
                      );

                      formik.setFieldTouched(
                        `${MODEL.projectSkills}[${competenceCards.length}].${MODEL.experienceLevel}`
                      );
                    }}
                  />
                </Grid>
                {competencesCount < 3 && (
                  <Grid item xs={7}>
                    <Paper className={classes.warningMessage} elevation={0}>
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                          <WarningRounded />
                        </Grid>
                        <Grid item>
                          <Translate
                            text="projectCreator:step2.addCompetence"
                            bold
                            variant="body1"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid container>
            <TextEditor
              name={MODEL.requirements}
              formik={formik}
              label={t('projectCreator:step2.requirements')}
              subLabel={t('projectCreator:step2.requirementsSubLabel')}
            />
          </Grid>
          <Grid container>
            <WorkStyleComponent formik={formik} />
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default withStyles(styles)(withTranslation()(ProjectCreateWizardStep2));
