export const styles = ({ palette }) => ({
  wrapper: {
    padding: '4px 0',
  },
  container: {
    width: '100%',
    backgroundColor: '#c1dfff',
    height: 8,
    borderRadius: 4,
    margin: '4px 0',
  },
  bar: { height: 8, borderRadius: 4, backgroundColor: palette.primary.main },
});
