import { Button } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getCurrentUser } from 'shared/services/authService';
import {
  clearCurrentOrganization,
  deleteOrganization,
} from 'shared/services/organizationService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import ConfirmationDialog from './LayoutConfirmationDialog/';

export class OrganizationCloseButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationOpened: false,
    };
  }

  deleteOrganization = async () => {
    const { t, organization } = this.props;
    try {
      await this.props.deleteOrganization(organization.id);
      this.props.clearCurrentOrganization();
      this.props.getCurrentUser();
      this.props.showSnackbarMessage(
        t('organization:form.organizationClosedInfo')
      );
    } catch (err) {
      console.error(err);
    }
  };

  handleConfirmationOpen = () => {
    this.setState({
      confirmationOpened: true,
    });
  };

  handleConfirmationClose = isOk => {
    if (isOk) {
      this.deleteOrganization();
    }
    this.setState({
      confirmationOpened: false,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ConfirmationDialog
          open={this.state.confirmationOpened}
          titleText={t('organization:form.closeOrganization')}
          onClose={this.handleConfirmationClose}
          okButtonText={t`organization:del`}
          cancelButtonText={t`organization:cancel`}
        >
          {t('organization:form.closeOrganizationConfirmation')}
        </ConfirmationDialog>
        <Button
          onClick={this.handleConfirmationOpen}
          variant="contained"
          color="primary"
        >
          {t('organization:form.closeOrganization')}
          <CloseIcon />
        </Button>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  deleteOrganization,
  clearCurrentOrganization,
  getCurrentUser,
  showSnackbarMessage,
};

OrganizationCloseButton.propTypes = {
  organization: PropTypes.object.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  clearCurrentOrganization: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(OrganizationCloseButton));
