import { Grid } from '@material-ui/core';
import { get, isNil } from 'lodash';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { getEnumItemName } from 'shared/utils/assets';
import ProjectStepper from '../../../../../../../components/ProjectStepper/index';
import { MODEL } from '../../../ProjectCreateWizardStep7/ProjectCreateWizardStep7.utils';

const ProjectReviewStep7 = ({ step7FormValues }) => {
  const projectStartDate = get(step7FormValues, 'projectStartDate');
  const projectEndDate = get(step7FormValues, 'projectEndDate');

  const calcCandidateExperience = get(
    step7FormValues,
    MODEL.calcCandidateExperience
  );
  const calcServiceCompletionDate = get(
    step7FormValues,
    MODEL.calcServiceCompletionDate
  );
  const calcPaymentDeadline = get(step7FormValues, MODEL.calcPaymentDeadline);
  const calcCandidateWarranty = get(
    step7FormValues,
    MODEL.calcCandidateWarranty
  );
  const hintForRecruiter = get(step7FormValues, MODEL.hintForRecruiter);

  const revenueSubmitted = get(step7FormValues, MODEL.revenueSubmitted);
  const revenueWinner = get(step7FormValues, MODEL.revenueWinner);
  const mentorFee = get(step7FormValues, MODEL.mentorFee);

  const showProjectRules =
    !isNil(calcCandidateExperience) ||
    !isNil(calcServiceCompletionDate) ||
    !isNil(calcPaymentDeadline) ||
    !isNil(calcCandidateWarranty);

  return (
    <Grid container style={{ padding: 25 }}>
      <Grid container style={{ paddingBottom: 8 }}>
        <Translate
          text="projectCreator:step7.title"
          style={{ fontSize: 26 }}
          bold
          variant="h6"
        />
      </Grid>
      {showProjectRules && (
        <>
          <Grid container>
            <Translate
              text="projectCreator:reviewStep7.showProjectRules"
              bold
              variant="body1"
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Grid container style={{ paddingBottom: 24 }}>
            {!isNil(calcCandidateExperience) && (
              <Grid container>
                <Translate
                  text="projectCreator:reviewStep7.calcCandidateExperience"
                  variant="body1"
                />
                <Translate text="&nbsp;-&nbsp;" variant="body1" />
                <Translate
                  text={getEnumItemName(
                    enums.CALC_CANDIDATE_EXPERIENCE,
                    calcCandidateExperience
                  )}
                  variant="body1"
                />
              </Grid>
            )}
            {!isNil(calcServiceCompletionDate) && (
              <Grid container>
                <Translate
                  text="projectCreator:reviewStep7.calcServiceCompletionDate"
                  variant="body1"
                />
                <Translate text="&nbsp;" variant="body1" />

                <Translate
                  text={getEnumItemName(
                    enums.CALC_SERVICE_COMPLETION_DATE,
                    calcServiceCompletionDate
                  )}
                  variant="body1"
                />
              </Grid>
            )}
            {!isNil(calcPaymentDeadline) && (
              <Grid container>
                <Translate
                  text="projectCreator:reviewStep7.calcPaymentDeadline"
                  variant="body1"
                />
                <Translate text="&nbsp;-&nbsp;" variant="body1" />

                <Translate
                  text={getEnumItemName(
                    enums.CALC_PAYMENT_DEADLINE,
                    calcPaymentDeadline
                  )}
                  variant="body1"
                />
              </Grid>
            )}
            {!isNil(calcCandidateWarranty) && (
              <Grid container>
                <Translate
                  text="projectCreator:reviewStep7.calcCandidateWarranty"
                  variant="body1"
                />
                <Translate text="&nbsp;-&nbsp;" variant="body1" />

                <Translate
                  text={getEnumItemName(
                    enums.CALC_CANDIDATE_WARRANTY,
                    calcCandidateWarranty
                  )}
                  variant="body1"
                />
              </Grid>
            )}
          </Grid>
        </>
      )}

      {hintForRecruiter && (
        <>
          <Grid container>
            <Translate
              text="projectCreator:reviewStep7.hintForRecruiter"
              style={{ fontSize: 20 }}
              variant="body1"
              bold
            />
          </Grid>
          <Grid container>{ReactHtmlParser(hintForRecruiter)}</Grid>
        </>
      )}
      {projectStartDate && projectEndDate && (
        <Grid container>
          <Grid container>
            <Translate
              text="projectDetails:recruiterInformation.projectSchedule"
              style={{ fontSize: 20 }}
              variant="body1"
              bold
            />
          </Grid>
          <Grid container style={{ marginTop: '1%', alignItems: 'center' }}>
            <Translate bold text={projectStartDate} variant="body1" />
            <Grid
              container
              style={{
                marginRight: '-57%',
                marginLeft: '10%',
              }}
            >
              <ProjectStepper
                startDate={projectStartDate}
                endDate={projectEndDate}
                variantMini={true}
              />
            </Grid>
            <Translate bold text={projectEndDate} variant="body1" />
          </Grid>
        </Grid>
      )}
      <>
        <Grid container>
          <Translate
            text="projectCreator:step7.recruitersSalary"
            style={{ fontSize: 20 }}
            variant="body1"
            bold
          />
        </Grid>
        <Grid container>
          {revenueSubmitted ? (
            <Grid item xs={12} sm={12} md={12}>
              <Translate text="projectCreator:step7.revenueSubmitted" />
              <Translate
                bold
                disableTranslate={true}
                text={`${revenueSubmitted} PLN`}
                variant="body1"
              />
            </Grid>
          ) : null}
          {revenueWinner ? (
            <Grid item xs={12} sm={12} md={12}>
              <Translate text="projectCreator:step7.revenueWinner" />
              <Translate
                bold
                disableTranslate={true}
                text={`${revenueWinner} PLN`}
                variant="body1"
              />
            </Grid>
          ) : null}
          {mentorFee ? (
            <Grid item xs={12} sm={12} md={12}>
              <Translate text="projectCreator:step7.mentorFee" />
              <Translate
                bold
                disableTranslate={true}
                text={`${mentorFee} PLN`}
                variant="body1"
              />
            </Grid>
          ) : null}
        </Grid>
      </>
    </Grid>
  );
};

export default ProjectReviewStep7;
