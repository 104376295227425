import {
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const styles = theme => ({
  rootPaper: {
    paddingTop: 0,
    paddingBottom: 64,
    marginTop: 0,
    paddingLeft: 64,
    paddingRight: 64,
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  textHeading: {
    paddingTop: 32,
    // paddingBottom: 32,
    color: '#52a3fc',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
    },
  },
  text: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#7f7f7f',
    fontSize: '22px',
  },
  gridStyle: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  price: {
    fontWeight: '700',
    color: '#52a3fc',
    fontSize: '32px',
  },
  textDown: {
    color: theme.palette.jobllegroLightGrey,
  },
});

export const ProjectDetailsRecruiterPayments = props => {
  const { classes, projectDetails, t } = props;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.rootPaper} elevation={0}>
          <Divider />
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            className={classes.textHeading}
          >
            {t('project:show.salaryForCo')}
          </Typography>

          <Typography
            component="p"
            gutterBottom
            className={classes.textDown}
            style={{ maxWidth: '400px' }}
          >
            <Trans i18nKey={'project:show.infoAboutSalaryPart1'}>
              x<strong style={{ fontSize: 12 }}>x</strong>
            </Trans>
          </Typography>
          <Typography
            component="p"
            gutterBottom
            className={classes.textDown}
            style={{ maxWidth: '400px' }}
          >
            <Trans i18nKey={'project:show.infoAboutSalaryPart2'}>
              x<strong style={{ fontSize: 12 }}>x</strong>
            </Trans>
          </Typography>
          <Grid container spacing={0}>
            {(projectDetails.isRevenueSubmittedPublic ||
              projectDetails.permissions.isOwner ||
              projectDetails.permissions.isMentor ||
              (projectDetails.permissions.isCooperator &&
                projectDetails.isRevenueSubmittedPublic)) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.gridStyle}
              >
                <div>
                  <Typography
                    component="p"
                    gutterBottom
                    className={classes.text}
                  >
                    {t('project:show.candidateIsSubmitted') + ': '}
                    <span className={classes.price}>
                      {projectDetails.revenueSubmitted}{' '}
                      {t('financial.currencySign') + '.'}
                      {/*<span style={{fontSize: 12}}>({t('financial.net')})</span>*/}
                    </span>
                  </Typography>
                  <Typography
                    component="p"
                    gutterBottom
                    className={classes.textDown}
                  >
                    {t('project:show.deliveryToCandidateIsNotEmployed') + '.'}
                  </Typography>
                </div>
              </Grid>
            )}
            {(projectDetails.isRevenueWinnerPublic ||
              projectDetails.permissions.isOwner ||
              projectDetails.permissions.isMentor ||
              (projectDetails.permissions.isCooperator &&
                projectDetails.isRevenueWinnerPublic)) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.gridStyle}
              >
                <div>
                  <Typography
                    component="p"
                    gutterBottom
                    className={classes.text}
                  >
                    {t('project:show.candidateIsEmployed') + ': '}
                    <span className={classes.price}>
                      {projectDetails.revenueWinner}{' '}
                      {t('financial.currencySign') + '.'}
                      {/*<span style={{fontSize: 12}}>({t('financial.net')})</span>*/}
                    </span>
                  </Typography>
                  <Typography
                    component="p"
                    gutterBottom
                    className={classes.textDown}
                  >
                    {t('project:show.deliveryToCandidateIsEmployed') + '.'}
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectDetailsRecruiterPayments.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsRecruiterPayments)));
