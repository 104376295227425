import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import LanguagesChange from './LanguagesChange.style';

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LanguagesChange));
