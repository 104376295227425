import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { Translate } from 'shared/newComponents';
import { styles } from './Card.styles';

const Card = ({ name, icon, price, subtext, classes }) => (
  <Grid container style={{ height: '100%' }}>
    <Paper className={classes.paperRoot}>
      <Grid container className={classes.containerRoot}>
        <Grid container>
          <img
            alt="icon"
            src={icon}
            className={classes.icon}
            style={{ width: 40, height: 40 }}
          />
        </Grid>
        <Grid container>
          <Translate text={name} bold variant="body1" />
        </Grid>
        <Grid container>
          <Typography variant="body1" className={classes.price}>
            {price ? `${price} PLN` : ''}
          </Typography>
        </Grid>
        <Grid container>
          <Translate text={subtext} variant="body1" />
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

export default withStyles(styles)(Card);
