import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { styles } from './CandidateAnonymousCard.styles';

const CandidatePreferenceWorkArea = ({ candidate, t }) => {
  const preferenceWorkArea = candidate.isRemoteWorkOnly
    ? t('candidateCreator:step1.remoteWorkOnly')
    : candidate.city;
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Translate
        style={{
          fontSize: 20,
        }}
        bold
        text={'candidateCreator:reviewStep2.preferenceWorkArea'}
      />
      <Grid item>
        <Translate
          style={{
            fontSize: 16,
          }}
          text={
            preferenceWorkArea
              ? preferenceWorkArea
              : 'core:general.noInformation'
          }
          disableTranslate={preferenceWorkArea}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(
  withStyles(styles)(withRouter(withTranslation()(CandidatePreferenceWorkArea)))
);
