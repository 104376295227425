import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { PATHS } from 'config';
import { ActionButtonJobllegroRoundedBig } from 'shared/utils/styledComponents';
import FacebookLogin from '../../../../components/FacebookLogin';
import GmailLogin from '../../../../components/GoogleLogin/GoogleLogin';

export class LoginForm extends Component {
  componentDidMount() {
    this.props.getConfigs();
  }

  getLoginDisabledWarning = () => {
    const { config = {}, t } = this.props;

    if (
      config['candidate_can_login'] !== '1' &&
      config['recruiter_can_login'] !== '1'
    ) {
      return (
        <Typography component="p">
          {t('login:error.recruiterAndCandidateLoginDisabled')}
        </Typography>
      );
    } else if (config['candidate_can_login'] !== '1') {
      return (
        <Typography component="p">
          {t('login:error.candidateLoginDisabled')}
        </Typography>
      );
    } else if (config['recruiter_can_login'] !== '1') {
      return (
        <Typography component="p">
          {t('login:error.recruiterLoginDisabled')}
        </Typography>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes, onSubmit, handleSubmit, valid, t } = this.props;
    const loginDisabledWarning = this.getLoginDisabledWarning();

    return (
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className={classes.card} elevation={10}>
            <CardContent className={classes.cardContent}>
              <Typography
                className={classes.boxTitle}
                gutterBottom
                component="h2"
              >
                {t('login:form.title')}
              </Typography>
              <Typography className={classes.boxText} component="p">
                <Trans i18nKey={'login:form.description'}>
                  x
                  <Link to={PATHS.AUTH.REGISTER.replace(':type', 'candidate')}>
                    x
                  </Link>
                  x
                </Trans>
              </Typography>

              <Field
                className={classes.field}
                label={t('login:form.field.email')}
                name="email"
                component={TextField}
              />
              <Field
                className={classes.field}
                label={t('login:form.field.password')}
                name="password"
                type="password"
                component={TextField}
              />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <ActionButtonJobllegroRoundedBig
                type="submit"
                name="login"
                disabled={!valid}
              >
                {t('login:form.submitButton')}
              </ActionButtonJobllegroRoundedBig>
              <div>
                <FacebookLogin
                  onSuccess={responseData => onSubmit(responseData)}
                />
                <GmailLogin
                  onSuccess={responseData => onSubmit(responseData)}
                />
              </div>
              <Typography
                component="div"
                align="left"
                className={classes.sectionDown}
              >
                <Link className={classes.link} to={PATHS.AUTH.FORGOT_PASSWORD}>
                  {t('login:form.forgotPassword')}
                </Link>
                <a
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer"
                  href="https://jobllegro.com/regulamin/"
                >
                  {t('login:form.rules')}
                </a>
              </Typography>
            </CardActions>
            {loginDisabledWarning}
          </Card>
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default LoginForm;
