import { PopoverOrigin } from '@material-ui/core';
import { Location } from '../types';

export const getCurrentPath = ({
  pathname,
  state,
}: Location<{ background?: Location }>) =>
  state?.background?.pathname || pathname;

const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;
const CUSTOM_CLASS_PREFIX = 'jss';
let classCounter = 0;

/**
 * Heavily inspired with Material UI:
 * @see https://github.com/mui-org/material-ui/blob/9cf73828e523451de456ba3dbf2ab15f87cf8504/src/styles/createGenerateClassName.js
 * The issue with the MUI function is that is create a new index for each
 * new `withStyles`, so we handle have to write our own counter
 */
export const generateClassName = (rule: any, styleSheet: any) => {
  classCounter += 1;
  if (!styleSheet?.options?.classNamePrefix) {
    return `${rule.key}-${classCounter}`;
  }
  // Sanitize the string as will be used to prefix the generated class name.
  const prefix = styleSheet.options.classNamePrefix.replace(escapeRegex, '-');
  if (prefix.match(/^MuiBox/)) return `${prefix}-${rule.key}-${classCounter}`;
  if (prefix.match(/^Mui/)) return `${prefix}-${rule.key}`;
  return `${CUSTOM_CLASS_PREFIX}-${rule.key}-${classCounter}`;
};

export const DEFAULT_POPOVER_ORIGINS = [
  {
    horizontal: 'right',
    vertical: 'bottom',
  },
  {
    horizontal: 'right',
    vertical: 'top',
  },
] as PopoverOrigin[];
