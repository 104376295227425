import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import AtsCard from '../../AtsCard';
import AtsCardContent from '../../AtsCardContent';
import AtsCardContentEmploymentProcess from '../../AtsCardContentEmploymentProcess';
import AtsCardHeader from '../../AtsCardHeader';
import { styles } from './AtsCardView.styles';

export const AtsCardView = ({
  item,
  obj,
  candidate,
  project,
  handleChangeCheckbox,
  handleOpenCandidateInProject,
  handleOpenSetCandidateInProject,
  parseCandidatesList,
  handleOpenAtsModal,
  variant = '',
}) => {
  return (
    <AtsCard item={item} candidate={candidate} obj={obj}>
      <AtsCardHeader
        variant={variant}
        project={project}
        handleChangeCheckbox={handleChangeCheckbox}
        candidate={candidate}
        obj={obj}
        handleOpenCandidateInProject={handleOpenCandidateInProject}
      />
      {variant === 'employmentProcess' ? (
        <AtsCardContentEmploymentProcess
          handleOpenAtsModal={handleOpenAtsModal}
          handleChangeCheckbox={handleChangeCheckbox}
          obj={obj}
          project={project}
          handleOpenCandidateInProject={handleOpenCandidateInProject}
          parseCandidatesList={parseCandidatesList}
        />
      ) : (
        <AtsCardContent
          handleOpenSetCandidateInProject={handleOpenSetCandidateInProject}
          handleChangeCheckbox={handleChangeCheckbox}
          obj={obj}
          project={project}
          handleOpenCandidateInProject={handleOpenCandidateInProject}
          parseCandidatesList={parseCandidatesList}
        />
      )}
    </AtsCard>
  );
};

export default withStyles(styles)(withTranslation()(AtsCardView));
