import { Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  TextEditor,
  TextField,
  Translate,
  UploadFileCard,
  WizardStep,
} from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { isProjectClosed } from 'shared/utils/functions';
import {
  stage6NotSetFields,
  useFormStep5,
} from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { MODEL } from './ProjectCreateWizardStep5.utils';

const ProjectCreateWizardStep5 = ({
  assets,
  setAssets,
  setStep5FormValues,
  initialProjectValues,
  t,
  classes,
  isStep5Open,
  isFormStepError,
  setIsStep6Open,
  setIsStep5Open,
  closeAll,
  isProjectSave,
  step5Assets,
  isThreeStageSaved,
  step6FormValues,
  setIsSnackbarErrorOpen,
}) => {
  const formik = useFormStep5({
    t,
    initialProjectValues,
    step5Assets,
    setAssets,
  });

  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep5FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  const setResponse = (res, index) => {
    let newAssets = assets;
    newAssets[index] = res.data;
    setAssets([...newAssets]);
  };

  const onDeleteAsset = fileId => {
    const newAssets = assets.map(item => {
      if (item !== undefined && item.id === fileId) {
        item = undefined;
      }
      return item;
    });
    setAssets(newAssets);
  };
  const myRef = useRef(null);

  useEffect(() => {
    if (isStep5Open) {
      executeScroll(myRef);
    }
  }, [isStep5Open]);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />

      <WizardStep
        withSaveButton={!isProjectClosed(initialProjectValues)}
        title="projectCreator:step5.title"
        isOpen={isStep5Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep5Open(false);
            const openSecondStep = stage6NotSetFields(
              initialProjectValues,
              step6FormValues
            );
            setIsStep6Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        onOpen={() => {
          closeAll();
          setIsStep5Open(true);
        }}
        disableArrowButton={
          !(isProjectSave === true && isThreeStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isProjectSave === true && isThreeStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => setIsStep5Open(false)}
      >
        <Grid container>
          <Grid container>
            <TextEditor
              formik={formik}
              name={MODEL.companyDescription}
              label={t('projectCreator:step5.companyDescription')}
              subLabel={t('projectCreator:step5.companyDescriptionSubLabel')}
            />
          </Grid>
          <Grid container>
            <UploadFileCard
              onDeleteAsset={onDeleteAsset}
              label={t('projectCreator:step5.logo')}
              imageName="logo"
              aspect={1 / 1}
              assetType="logo"
              setResponse={res => setResponse(res, 0)}
              isAssetAssigned={!!assets[0]}
              assets={assets}
            />
          </Grid>
          <Grid container>
            <UploadFileCard
              onDeleteAsset={onDeleteAsset}
              label={t('projectCreator:step5.header')}
              imageName="header"
              aspect={884 / 230}
              assetType="header"
              setResponse={res => setResponse(res, 1)}
              isAssetAssigned={!!assets[1]}
              assets={assets}
            />
          </Grid>
          <Grid container>
            <UploadFileCard
              onDeleteAsset={onDeleteAsset}
              label={t('projectCreator:step5.slider')}
              imageName="slider"
              fileLimit={10}
              aspect={1.55}
              assetType="slider"
              setResponse={res => setResponse(res, 2)}
              isAssetAssigned={!!assets[2]}
              assets={assets}
            />
          </Grid>
          <Grid container style={{ padding: '10px 10px 0 10px' }}>
            <Grid item xs={12}>
              <Translate
                text="projectCreator:step5.videoUrl"
                variant="body1"
                bold
              />
            </Grid>
            <Grid item xs={12}>
              <Translate
                text="projectCreator:step5.videoUrlBody"
                variant="body1"
              />
            </Grid>
          </Grid>
          <Grid container>
            <TextField
              formik={formik}
              name={MODEL.videoUrl}
              label={t('projectCreator:step5.videoUrl')}
            />
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default ProjectCreateWizardStep5;
