import { Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { enums } from 'shared/enums';
import {
  Select,
  TextEditor,
  TextField,
  Translate,
  WizardStep,
} from 'shared/newComponents';
import MultiSelect from 'shared/newComponents/MultiSelect';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { ProjectV2Service } from 'shared/services/projectV2.service';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import { castObjectForSelect, isProjectClosed } from 'shared/utils/functions';
import {
  castToMultiSelect,
  stage8NotSetFields,
  useFormStep7,
} from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { MODEL } from './ProjectCreateWizardStep7.utils';

const ProjectCreateWizardStep7 = ({
  t,
  classes,
  setStep7FormValues,
  isStep7Open,
  isFormStepError,
  setIsStep7Open,
  setIsStep8Open,
  closeAll,
  isProjectSave,
  isThreeStageSaved,
  step8FormValues,
  currentUser,
  countries,
  recruitmentPayment,
  projectId,
  setIsSnackbarErrorOpen,
  projectValues,
}) => {
  const [initialProjectValues, setInitialProjectValues] = useState({
    hintForRecruiter: projectValues.hintForRecruiter,
  });

  const [timeoutId, setTimeoutId] = useState(null);
  const [formIsValid, setFormIsValid] = useState(true);
  const formik = useFormStep7({
    t,
    recruitmentPayment,
    initialProjectValues,
    setStep7FormValues,
    currentUser,
  });
  const myRef = useRef(null);
  const disabledRecruiterValues = false;

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep7FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
    setFormIsValid(isFormStepError(formik));
  }, [formik.values]);

  useEffect(() => {
    if (isStep7Open) {
      executeScroll(myRef);
    }
    ProjectV2Service.getProjectV2(projectId).then(({ data }) => {
      setInitialProjectValues({
        ...data,
        recruitmentAreas: castToMultiSelect(
          data.recruitmentAreas,
          'name',
          'id'
        ),
      });
    });
  }, [isStep7Open]);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />

      <WizardStep
        withSaveButton={!isProjectClosed(initialProjectValues)}
        title="projectCreator:step7.title"
        isOpen={isStep7Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep7Open(false);
            setIsStep8Open(true);
            const openSecondStep = stage8NotSetFields(
              initialProjectValues,
              step8FormValues
            );
            setIsStep8Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        onOpen={() => {
          closeAll();
          setIsStep7Open(true);
        }}
        disableArrowButton={
          !(isProjectSave === true && isThreeStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isProjectSave === true && isThreeStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => setIsStep7Open(false)}
      >
        <Grid container>
          <Grid container style={{ padding: 10 }}>
            <Translate
              text="projectCreator:step7.recruiterViewOnly"
              variant="h6"
            />
          </Grid>

          <Grid container>
            <Select
              formik={formik}
              name={MODEL.calcServiceCompletionDate}
              label={t('projectCreator:step7.calcServiceCompletionDate')}
              items={getSelectItemsTranslate(
                enums.CALC_SERVICE_COMPLETION_DATE,
                t
              )}
            />
          </Grid>
          <Grid container>
            <Select
              formik={formik}
              name={MODEL.calcPaymentDeadline}
              label={t('projectCreator:step7.calcPaymentDeadline')}
              items={getSelectItemsTranslate(enums.CALC_PAYMENT_DEADLINE, t)}
            />
          </Grid>
          <Grid container>
            <Select
              formik={formik}
              name={MODEL.calcCandidateWarranty}
              label={t('projectCreator:step7.calcCandidateWarranty')}
              items={getSelectItemsTranslate(enums.CALC_CANDIDATE_WARRANTY, t)}
            />
          </Grid>
          <Grid container>
            <MultiSelect
              isMultiOption={true}
              formik={formik}
              name={MODEL.recruitmentAreas}
              label={t('projectCreator:step7.recruitmentAreas')}
              options={castObjectForSelect(countries)}
            />
          </Grid>
          <Grid container>
            <TextEditor
              formik={formik}
              subLabel={t('projectCreator:step7.hintForRecruiter')}
              name={MODEL.hintForRecruiter}
            />
          </Grid>
          <Grid container style={{ padding: '32px 16px 0 16px' }}>
            <Translate text="projectCreator:step7.projectDates" variant="h6" />
          </Grid>
          <Grid container>
            <Grid container style={{ padding: '32px 16px 0 16px' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate text="projectCreator:step7.projectStartDate" />
                <TextField
                  name={MODEL.projectStartDate}
                  type="date"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate text="projectCreator:step7.projectEndDate" />
                <TextField
                  name={MODEL.projectEndDate}
                  type="date"
                  formik={formik}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ padding: '32px 16px 0 16px' }}>
            <Translate
              text="projectCreator:step7.recruitersSalary"
              variant="h6"
            />
          </Grid>
          <Grid container>
            <Grid container style={{ padding: '32px 16px 0 16px' }}>
              <Grid item xs={12} sm={12} md={12}>
                <Translate text="projectCreator:step7.revenueSubmitted" />
                <TextField
                  disabled={disabledRecruiterValues}
                  name={MODEL.revenueSubmitted}
                  type="number"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate text="projectCreator:step7.revenueWinner" />
                <TextField
                  disabled={disabledRecruiterValues}
                  name={MODEL.revenueWinner}
                  type="number"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate text="projectCreator:step7.mentorFee" />
                <TextField
                  disabled={disabledRecruiterValues}
                  name={MODEL.mentorFee}
                  type="number"
                  formik={formik}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};
export default connect(mapStateToProps)(ProjectCreateWizardStep7);
