import { enums } from 'shared/enums';
import {
  getEnumItemName,
  getEnumItemNameFullObject,
} from 'shared/utils/assets';
import {
  prepareCompetence,
  prepareLanguageCompetence,
} from '../candidate/NewCandidateCreateView/components/CandidateCreateWizard/CandidateCreateWizard.utils';

export const detailCandidatesReportHeader = [
  { label: 'Id kandydata', key: 'id' },
  { label: 'Imię', key: 'firstName' },
  { label: 'Nazwisko', key: 'lastName' },
  { label: 'ID opiekuna kandydata', key: 'owner' },
  { label: 'ID projektu', key: 'project' },
  { label: 'Stanowisko', key: 'projectPosition' },
  { label: 'Nazwa firmy', key: 'projectClient' },
  { label: 'Data publikacji projektu', key: 'projectPublicationDate' },
  { label: 'Data wprowadzenia stage #1', key: 'stage1Date' },
  { label: 'Data wprowadzenia stage #2', key: 'stage2Date' },
  { label: 'Data wprowadzenia stage #3', key: 'stage3Date' },
  { label: 'Data wprowadzenia stage #4', key: 'stage4Date' },
  { label: 'Data wprowadzenia stage #5', key: 'stage5Date' },
  { label: 'Data wprowadzenia stage #6', key: 'stage6Date' },
  { label: 'Data odrzucenia stage #1', key: 'stage1DateReject' },
  { label: 'Data odrzucenia stage #2', key: 'stage2DateReject' },
  { label: 'Data odrzucenia stage #3', key: 'stage3DateReject' },
  { label: 'Data odrzucenia stage #4', key: 'stage4DateReject' },
  { label: 'Data odrzucenia stage #5', key: 'stage5DateReject' },
  { label: 'Data odrzucenia stage #6', key: 'stage6DateReject' },
];
export const generalCandidatesReportHeader = [
  { label: 'Id kandydata', key: 'id' },
  { label: 'Imię', key: 'firstName' },
  { label: 'Nazwisko', key: 'lastName' },
  { label: 'Numer telefonu', key: 'phonePrimary' },
  { label: 'Adres email', key: 'email' },
  { label: 'Kraj zamieszkania', key: 'homeCountry' },
  { label: 'Narodowość', key: 'citizenship' },
  { label: 'Preferowane stanowisko', key: 'position' },
  { label: 'Branża', key: 'industryCategory' },
  { label: 'Wielkość firmy', key: 'companySize' },
  { label: 'Praca za granicą', key: 'workInForeign' },
  { label: 'Miejscowość', key: 'city' },
  { label: 'Doświadczenie (nazwa)', key: 'userExperiencesName' },
  { label: 'Doświadczenie (branża)', key: 'userExperiencesIndustry' },
  {
    label: 'Doświadczenie (wielkość firmy)',
    key: 'userExperiencesCompanySize',
  },
  { label: 'Doświadczenie (lata)', key: 'userExperiencesExperience' },
  { label: 'Obszar kompetencji #1', key: 'userSkillsCompetenceCategory1' },
  { label: 'Poziom doświadczenia #1', key: 'userSkillsExperienceLevel1' },
  { label: 'Obszar kompetencji #2', key: 'userSkillsCompetenceCategory2' },
  { label: 'Poziom doświadczenia #2', key: 'userSkillsExperienceLevel2' },
  { label: 'Obszar kompetencji #3', key: 'userSkillsCompetenceCategory3' },
  { label: 'Poziom doświadczenia #3', key: 'userSkillsExperienceLevel3' },
  { label: 'Język obcy #1', key: 'userSkillsLanguageName1' },
  {
    label: 'Poziom doświadczenia  #1',
    key: 'userSkillsLanguageExperienceLevel1',
  },
  { label: 'Język obcy #2', key: 'userSkillsLanguageName2' },
  {
    label: 'Poziom doświadczenia  #2',
    key: 'userSkillsLanguageExperienceLevel2',
  },
  { label: 'Język obcy #3', key: 'userSkillsLanguageName3' },
  {
    label: 'Poziom doświadczenia  #3',
    key: 'userSkillsLanguageExperienceLevel3',
  },
  { label: 'Wyn minimum', key: 'minMonthlyWadges' },
  { label: 'Wyn maksimum', key: 'maxMonthlyWadges' },
  { label: 'Forma zatrudnienia', key: 'employmentTypes' },
  { label: 'Ilość projektów', key: 'candidateInProject' },
  { label: 'Ilość projektów na Stage #4', key: 'candidatesInStage4' },
  { label: 'Ilość projektów na Stage #5', key: 'candidatesInStage5' },
  { label: 'Ilość projektów na Stage #6', key: 'candidatesInStage6' },
  { label: 'Data utworzenia profilu', key: 'createdAt' },
  { label: 'Data ostatniej modyfikacji', key: 'updatedAt' },
  { label: 'ID opiekuna kandydata', key: 'owner' },
  { label: 'Imię Opiekuna kandydata', key: 'ownerName' },
  { label: 'Nazwisko Opiekuna kandydta', key: 'ownerLastName' },
];
export const detailRecruitersReportHeader = [
  { label: 'Rekruter id', key: 'id' },
  { label: 'Imię', key: 'firstName' },
  { label: 'Nazwisko', key: 'lastName' },

  { label: 'Data utworzenia konta', key: 'createdAt' },
  { label: 'Czas utworzenia konta', key: 'createdAtTime' },
  { label: "Data ostatniego update'u", key: 'updatedAt' },
  { label: 'Typ konta', key: 'employerStatus' },
  { label: 'Id organizacji', key: 'organization' },
  { label: 'Nazwa organizacji', key: 'organizationName' },
  { label: 'Czy właściciel organizacji', key: 'isOrganizationOwner' },

  { label: 'ID Opiekun rekrutera ', key: 'owner' },
  { label: 'ID projektu', key: 'project' },
  { label: 'Stanowisko', key: 'position' },
  { label: 'Nazwa firmy', key: 'client' },
  { label: 'Data publikacji projektu', key: 'publicationDate' },
  { label: 'Ilość kandydatów stage1', key: 'candidatesInStage1' },
  { label: 'Ilość kandydatów stage4', key: 'candidatesInStage4' },
  { label: 'Ilość kandydatów stage5', key: 'candidatesInStage5' },
  { label: 'Ilość kandydatów stage6', key: 'candidatesInStage6' },
  {
    label: 'Ilość kandydatów rejected stage1',
    key: 'candidatesInStage1Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage2',
    key: 'candidatesInStage2Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage3',
    key: 'candidatesInStage3Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage4',
    key: 'candidatesInStage4Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage5',
    key: 'candidatesInStage5Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage6',
    key: 'candidatesInStage6Rejected',
  },
];

export const generalRecruitersReportHeader = [
  { label: 'Rekruter id', key: 'id' },
  { label: 'Imię', key: 'firstName' },
  { label: 'Nazwisko', key: 'lastName' },
  { label: 'Kraj zamieszkania', key: 'country' },
  { label: 'Narodowość', key: 'citizen' },
  { label: 'Czy jest Mentorem', key: 'mentorStatus' },
  { label: 'ID Opiekun rekrutera ', key: 'owner' },
  { label: 'Specjalizacja w branży #1', key: 'industry' },
  { label: 'Data utworzenia konta', key: 'createdAt' },
  { label: 'Czas utworzenia konta', key: 'createdAtTime' },
  { label: "Data ostatniego update'u", key: 'updatedAt' },
  { label: 'Typ konta', key: 'employerStatus' },
  { label: 'Id organizacji', key: 'organization' },
  { label: 'Nazwa organizacji', key: 'organizationName' },
  { label: 'Czy właściciel organizacji', key: 'isOrganizationOwner' },
  { label: 'Rodzaj umowy rozliczenia', key: 'employmentType' },
  { label: 'Numer telefonu', key: 'primaryPhone' },
  { label: 'Email', key: 'email' },
  {
    label: 'Ilość Kandydatów na platofrmie (jest Opiekunem)',
    key: 'candidateOwner',
  },
  {
    label: 'Ilość projektów w których brał udział',
    key: 'recruiterInProjects',
  },
  { label: 'Ilość kadnydatów na projektach', key: 'candidateInProjects' },
  { label: 'Ilość kandydatów na Stage #5', key: 'candidatesInStage5' },
  { label: 'Ilość kandydatów na Stage #6', key: 'candidatesInStage6' },
];
export const generalProjectReportHeader = [
  { label: 'Projekt id', key: 'id' },
  { label: 'Opublikowany', key: 'isDraft' },
  { label: 'Subskrypcja klient', key: 'subscriptionValue' },
  { label: 'Publikacja klient', key: 'publicationValue' },
  { label: 'Spotkania klient', key: 'meetingsValue' },
  { label: 'Zatrudnienie klient', key: 'hiringValue' },
  { label: 'Gwarancja klient', key: 'warrantyValue' },
  { label: 'Termin płatności', key: 'paymentDeadline' },
  { label: 'Spotkania rekruter', key: 'revenueSubmitted' },
  { label: 'Zatrudnienie rekruter', key: 'revenueWinner' },
  { label: 'Mentor Fee', key: 'mentorFee' },
  { label: 'Nazwa firmy', key: 'client' },
  { label: 'Kraj', key: 'country' },
  { label: 'Branża', key: 'industryCategory' },
  { label: 'Wielkość firmy', key: 'companySize' },
  { label: 'Miejscowość', key: 'city' },
  { label: 'Praca zdalna', key: 'isRemoteWork' },
  { label: 'Stanowisko', key: 'position' },
  { label: 'Ogólny poziom doświadczenia', key: 'calcCandidateExperience' },
  { label: 'Wyn minimum', key: 'minSalary' },
  { label: 'Wyn maksimum', key: 'maxSalary' },
  { label: 'Forma zatrudnienia', key: 'employmentTypes' },
  { label: 'Ilość kandydatów stage1', key: 'candidatesInStage1' },
  { label: 'Ilość kandydatów stage4', key: 'candidatesInStage4' },
  { label: 'Ilość kandydatów stage5', key: 'candidatesInStage5' },
  { label: 'Ilość kandydatów stage6', key: 'candidatesInStage6' },
  {
    label: 'Ilość kandydatów rejected stage1',
    key: 'candidatesInStage1Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage2',
    key: 'candidatesInStage2Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage3',
    key: 'candidatesInStage3Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage4',
    key: 'candidatesInStage4Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage5',
    key: 'candidatesInStage5Rejected',
  },
  {
    label: 'Ilość kandydatów rejected stage6',
    key: 'candidatesInStage6Rejected',
  },
  { label: 'Ilość rekruterów', key: 'recruitersInProject' },
  { label: 'Data publikacji', key: 'publicationDate' },
  { label: 'Data last update', key: 'updatedAt' },
  { label: 'Data zamknięcia', key: 'closedDate' },
  {
    label: 'Data pierwsza rekomendacja (stage4)',
    key: 'firstDateRecommendationCandidate',
  },
  {
    label: 'Data druga rekomendacja (stage4)',
    key: 'secondDateRecommendationCandidate',
  },
  {
    label: 'Data trzecia rekomendacja (stage4)',
    key: 'thirdDateRecommendationCandidate',
  },
  { label: 'Owner Id', key: 'owner' },
  { label: 'Owner Imię', key: 'ownerFirstName' },
  { label: 'Owner Nazwisko', key: 'ownerLastName' },
  { label: 'Mentor Id', key: 'mentor' },
  { label: 'Mentor Imię', key: 'mentorFirstName' },
  { label: 'Mentor Nazwisko', key: 'mentorLastName' },
  { label: 'Hiring Manager Id', key: 'hiringManager' },
  { label: 'Hiring Manager Imię', key: 'hiringManagerFirstName' },
  { label: 'Hiring Manager Nazwisko', key: 'hiringManagerLastName' },
];

export const projectOrdersReportHeader = [
  {
    label: '# id projektu',
    key: 'projectNumber',
  },
  { label: 'Data zamówienia', key: 'projectOrderCreatedAt' },
  { label: '# id zamówienia', key: 'projectOrderNumber' },
  { label: 'Firma', key: 'projectOrganizationName' },
  { label: 'Klient / Firma', key: 'projectOrderClient' },
  { label: 'Lokalizacja miejscowość', key: 'projectOrderLocalizationCity' },
  { label: 'Lokalizacja ulica', key: 'projectOrderLocalizationAddress' },
  { label: 'Status zamówienia', key: 'projectOrderStatus' },
  { label: 'Data zamówienia', key: 'projectOrderEmploymentStartDate' },
  { label: 'Data zatrudnienia', key: 'projectOrderEmploymentStartDate' },
  { label: 'Stanowisko', key: 'projectPosition' },
  { label: 'Ilość Mężczyzn', key: 'projectOrderNumberOfManCandidate' },
  { label: 'Ilość Kobiet', key: 'projectOrderNumberOfWomanCandidate' },
  { label: 'Ilość Par', key: 'projectOrderNumberOfPairCandidate' },
  { label: 'Ilość Suma', key: 'projectOrderSumOfCandidates' },
  { label: 'Średnia liczba kandydatów', key: 'projectOrderAVGOfCandidates' },
  {
    label: 'Liczba kandydatów w procesie',
    key: 'projectOrderAllCandidateInProcess',
  },
];

export const accommodationsReportHeader = [
  {
    label: 'id Lokalizacji zakwaterowania',
    key: 'accommodationNumber',
  },
  { label: 'Nazwa właściciela lokalizacji', key: 'accommodationOwner' },
  { label: 'Email', key: 'accommodationEmail' },
  { label: 'Numer kierunkowy', key: 'accommodationPrefixPhone' },
  { label: 'telefon', key: 'accommodationPhone' },
  { label: 'Adres lokalizacji ulica', key: 'accommodationAddress' },
  { label: 'Adres lokalizacji miejscowość', key: 'accommodationCity' },
  { label: 'Adres lokalizacji kod', key: 'accommodationPostCode' },
  { label: 'Typ lokalizacji', key: 'accommodationLocationType' },
  { label: 'Standard lokalizacji', key: 'accommodationLocationStandard' },
  {
    label: 'Dostęp do komunikacji miejskiej',
    key: 'accommodationAccessToPublicTransport',
  },
  {
    label: 'Dostęp do sklepu spożywczego poniżej 1 km',
    key: 'accommodationAccessToStore',
  },
  { label: 'Liczba pokoi 2 os', key: 'accommodationTwoPersonRoom' },
  { label: 'Liczba pokoi 3 os', key: 'accommodationTriplePersonRoom' },
  { label: 'Liczba pokoi 4 os', key: 'accommodationFourPersonRoom' },
  { label: 'Liczba pokoi 5 os', key: 'accommodationFivePersonRoom' },
  { label: 'Ilość kuchnia', key: 'accommodationRoomKitchen' },
  { label: 'Ilość łazienka', key: 'accommodationRoomLivingRoom' },
  { label: 'Ilość salon', key: 'accommodationRoomBathroom' },
  { label: 'Ilość pralnia', key: 'accommodationRoomLaundry' },
  { label: 'Umowa zawarta OD', key: 'accommodationContractStartDate' },
  { label: 'Umowa zawarta DO', key: 'accommodationContractEndDate' },
  { label: 'Moment wypowiedzenia', key: 'accommodationTerminationType' },
  { label: 'Okres wypowiedzenia', key: 'accommodationTerminationDayNumber' },
  { label: 'Cena', key: 'accommodationPrice' },
  { label: 'Waluta', key: 'accommodationCurrency' },
  { label: 'Typ zakwaterowania', key: 'accommodationType' },
  { label: 'Ilość załączonych zdjęć', key: 'accommodationAssetCount' },
  {
    label: 'ILOŚĆ MIEJSC NOCLEGOWYCH',
    key: 'accommodationAllAvailableRoomsPerPerson',
  },
  {
    label:
      'ŚREDNIA ILOŚĆ OSÓB ZAKWATEROWNYCH (zakres dat sporządzenia raportu)',
    key: 'accommodationAVGAvailableRoomsPerPerson',
  },
];

export const detailAccommodationsReportHeader = [
  { label: 'Data', key: 'date' },
  { label: 'Płeć', key: 'candidateSex' },
  { label: 'Imię', key: 'candidateFirstName' },
  { label: 'Nazwisko', key: 'candidateLastName' },
  { label: 'Rok urodzenia', key: 'candidateBirthYear' },
  { label: 'Obywatelstwo', key: 'candidateCitizenship' },
  { label: 'Firma / Klient', key: 'projectOrderClientName' },
  { label: 'Firma / Płatnik', key: 'projectOrganizationName' },
  { label: 'id Lokalizacji zakwaterowania', key: 'accommodationNumber' },
  { label: 'Nazwa właściciela lokalizacji', key: 'accommodationOwner' },
  { label: 'email', key: 'accommodationEmail' },
  { label: 'numer kierunkowy', key: 'accommodationPrefixPhone' },
  { label: 'telefon', key: 'accommodationPhone' },
  { label: 'Adres lokalizacji ulica', key: 'accommodationAddress' },
  { label: 'Adres lokalizacji miejscowość', key: 'accommodationCity' },
  { label: 'Adres lokalizacji kod', key: 'accommodationPostCode' },
  { label: 'Typ zakwaterowania', key: 'accommodationLocationType' },
  { label: 'Standard zakwaterowania', key: 'accommodationLocationStandard' },
  {
    label: 'Dostęp do komunikacji miejskiej',
    key: 'accommodationAccessToPublicTransport',
  },
  {
    label: 'Dostęp do sklepu spożywczego poniżej 1 km',
    key: 'accommodationAccessToStore',
  },
  { label: 'Liczba pokoi 2 os', key: 'accommodationTwoPersonRoom' },
  { label: 'Liczba pokoi 3 os', key: 'accommodationTriplePersonRoom' },
  { label: 'Liczba pokoi 4 os', key: 'accommodationFourPersonRoom' },
  { label: 'Liczba pokoi 5 os', key: 'accommodationFivePersonRoom' },
  { label: 'Ilość kuchnia', key: 'accommodationRoomKitchen' },
  { label: 'Ilość łazienka', key: 'accommodationRoomLivingRoom' },
  { label: 'Ilość salon', key: 'accommodationRoomBathroom' },
  { label: 'Ilość pralnia', key: 'accommodationRoomLaundry' },
  { label: 'Umowa zawarta OD', key: 'accommodationContractStartDate' },
  { label: 'Umowa zawarta DO', key: 'accommodationContractEndDate' },
  { label: 'Moment wypowiedzenia', key: 'accommodationTerminationType' },
  { label: 'Okres wypowiedzenia', key: 'accommodationTerminationDayNumber' },
  { label: 'Cena', key: 'accommodationPrice' },
  { label: 'Waluta', key: 'accommodationCurrency' },
  { label: 'Typ zakwaterowania', key: 'accommodationType' },
  { label: 'Ilość załączonych zdjęć', key: 'accommodationAssetCount' },
  {
    label: 'ILOŚĆ MIEJSC NOCLEGOWYCH',
    key: 'accommodationAllAvailableRoomsPerPerson',
  },
];

export const allHelpInfoReportHeader = [
  { label: 'id', key: 'id' },
  { label: 'Rodzaj konta', key: 'employerStatus' },
  { label: 'Nazwisko', key: 'lastName' },
  { label: 'Imię', key: 'firstName' },
  { label: 'prefix', key: 'phonePrimaryCode' },
  { label: 'telefon', key: 'phonePrimary' },
  { label: 'email adres', key: 'email' },
  { label: 'T_Transport', key: 'transport' },
  { label: 'Z_Zakwaterowanie', key: 'accommodation' },
  { label: 'P_Praca', key: 'work' },
  { label: 'T_Ilość osób', key: 'numberOfSeats' },
  { label: 'T_Odbiór', key: 'pickUpPlace' },
  { label: 'T_Dojazd', key: 'destinationPlace' },
  { label: 'T_data odbioru', key: 'periodPickUpDate' },
  { label: 'T_godzina odbioru', key: 'periodPickUpTime' },
  { label: 'T_marka pojazu', key: 'vehicleBrand' },
  { label: 'T_numer rejestr.', key: 'vehicleNumber' },
  { label: 'Z_miejscowość', key: 'city' },
  { label: 'Z_ilość osób', key: 'numberOfPeople' },
  { label: 'Z_ilość_M', key: 'numberOfMen' },
  { label: 'Z_ilość_K', key: 'numberOfWomen' },
  { label: 'Z_ilość_D', key: 'numberOfPair' },
  { label: 'Z_data_OD', key: 'periodStayStartDate' },
  { label: 'Z_data_DO', key: 'periodStayEndDate' },
  { label: 'Z_adres_ulica', key: 'address' },
  { label: 'Z_adres_kod_pocztowy', key: 'postCode' },
  { label: 'Z_Typ_lokalizacji', key: 'locationType' },
  { label: 'Z_standard_lokalizacji', key: 'locationStandard' },
  { label: 'Z_ilość pokoi', key: 'roomNumber' },
];
export const detailProjectOrdersReportHeader = [
  { label: 'Data', key: 'date' },
  { label: 'Płeć', key: 'candidateSex' },
  { label: 'Narodowość', key: 'candidateCitizenship' },
  { label: 'Rok urodzenia', key: 'candidateBirthYear' },
  { label: 'Nazwisko', key: 'candidateLastName' },
  { label: 'Imię', key: 'candidateFirstName' },
  { label: 'Nazwa Procesu', key: 'employmentManagementProcessStatus' },
  { label: 'Etap procesu', key: 'employmentManagementProcessSecondStatus' },
  { label: 'Data dodania', key: 'employmentManagementProcessDate' },

  {
    label: '# id projektu',
    key: 'projectNumber',
  },
  { label: 'Data zamówienia', key: 'projectOrderCreatedAt' },
  { label: '# id zamówienia', key: 'projectOrderNumber' },
  { label: 'Firma', key: 'projectOrganizationName' },
  { label: 'Klient / Firma', key: 'projectOrderClient' },
  { label: 'Lokalizacja miejscowość', key: 'projectOrderLocalizationCity' },
  { label: 'Lokalizacja ulica', key: 'projectOrderLocalizationAddress' },
  { label: 'Status zamówienia', key: 'projectOrderStatus' },
  { label: 'Data zamówienia', key: 'projectOrderEmploymentStartDate' },
  { label: 'Data zatrudnienia', key: 'projectOrderEmploymentStartDate' },
  { label: 'Stanowisko', key: 'projectPosition' },
  { label: 'Ilość Mężczyzn', key: 'projectOrderNumberOfManCandidate' },
  { label: 'Ilość Kobiet', key: 'projectOrderNumberOfWomanCandidate' },
  { label: 'Ilość Par', key: 'projectOrderNumberOfPairCandidate' },
  { label: 'Ilość Suma', key: 'projectOrderSumOfCandidates' },
];

export const generateDataDetailAccommodations = (data, t) =>
  data.map(
    ({
      date,
      candidateSex,
      candidateFirstName,
      candidateLastName,
      candidateBirthYear,
      candidateCitizenship,
      projectOrderClientName,
      projectOrganizationName,
      accommodationNumber,
      accommodationOwner,
      accommodationEmail,
      accommodationPrefixPhone,
      accommodationPhone,
      accommodationAddress,
      accommodationCity,
      accommodationPostCode,
      accommodationLocationType,
      accommodationLocationStandard,
      accommodationAccessToPublicTransport,
      accommodationAccessToStore,
      accommodationTwoPersonRoom,
      accommodationTriplePersonRoom,
      accommodationFourPersonRoom,
      accommodationFivePersonRoom,
      accommodationRoomKitchen,
      accommodationRoomLivingRoom,
      accommodationRoomBathroom,
      accommodationRoomLaundry,
      accommodationContractStartDate,
      accommodationContractEndDate,
      accommodationTerminationType,
      accommodationTerminationDayNumber,
      accommodationPrice,
      accommodationCurrency,
      accommodationType,
      accommodationAssetCount,
      accommodationAllAvailableRoomsPerPerson,
    }) => ({
      date,
      candidateSex: t(enums.SEX[candidateSex]),
      candidateFirstName,
      candidateLastName,
      candidateBirthYear,
      candidateCitizenship,
      projectOrderClientName,
      projectOrganizationName,
      accommodationNumber,
      accommodationOwner,
      accommodationEmail,
      accommodationPrefixPhone,
      accommodationPhone,
      accommodationAddress,
      accommodationCity,
      accommodationPostCode,
      accommodationLocationType: t(
        getEnumItemName(enums.TYPE_OF_LOCATION, accommodationLocationType)
      ),
      accommodationLocationStandard: t(
        getEnumItemName(
          enums.STANDARD_OF_LOCATION,
          accommodationLocationStandard
        )
      ),
      accommodationAccessToPublicTransport: t(
        enums.YES_NO[accommodationAccessToPublicTransport]
      ),
      accommodationAccessToStore: t(enums.YES_NO[accommodationAccessToStore]),
      accommodationTwoPersonRoom,
      accommodationTriplePersonRoom,
      accommodationFourPersonRoom,
      accommodationFivePersonRoom,
      accommodationRoomKitchen,
      accommodationRoomLivingRoom,
      accommodationRoomBathroom,
      accommodationRoomLaundry,
      accommodationContractStartDate,
      accommodationContractEndDate,
      accommodationTerminationType: t(
        getEnumItemName(enums.TERMINATION_TYPE, accommodationTerminationType)
      ),
      accommodationTerminationDayNumber,
      accommodationPrice,
      accommodationCurrency: getEnumItemName(
        enums.CURRENCY,
        accommodationCurrency
      ),
      accommodationType: t(
        getEnumItemName(enums.ACCOMMODATION_TYPE, accommodationType)
      ),
      accommodationAssetCount,
      accommodationAllAvailableRoomsPerPerson,
    })
  );

export const generateDataAccommodations = (data, t) =>
  data.map(
    ({
      accommodationNumber,
      accommodationOwner,
      accommodationEmail,
      accommodationPrefixPhone,
      accommodationPhone,
      accommodationAddress,
      accommodationCity,
      accommodationPostCode,
      accommodationLocationType,
      accommodationLocationStandard,
      accommodationAccessToPublicTransport,
      accommodationAccessToStore,
      accommodationTwoPersonRoom,
      accommodationTriplePersonRoom,
      accommodationFourPersonRoom,
      accommodationFivePersonRoom,
      accommodationRoomKitchen,
      accommodationRoomLivingRoom,
      accommodationRoomBathroom,
      accommodationRoomLaundry,
      accommodationContractStartDate,
      accommodationContractEndDate,
      accommodationTerminationType,
      accommodationTerminationDayNumber,
      accommodationPrice,
      accommodationCurrency,
      accommodationType,
      accommodationAssetCount,
      accommodationAllAvailableRoomsPerPerson,
      accommodationAVGAvailableRoomsPerPerson,
    }) => ({
      accommodationNumber,
      accommodationOwner,
      accommodationEmail,
      accommodationPrefixPhone,
      accommodationPhone,
      accommodationAddress,
      accommodationCity,
      accommodationPostCode,
      accommodationLocationType: t(
        getEnumItemName(enums.TYPE_OF_LOCATION, accommodationLocationType)
      ),
      accommodationLocationStandard: t(
        getEnumItemName(
          enums.STANDARD_OF_LOCATION,
          accommodationLocationStandard
        )
      ),
      accommodationAccessToPublicTransport: t(
        enums.YES_NO[accommodationAccessToPublicTransport]
      ),
      accommodationAccessToStore: t(enums.YES_NO[accommodationAccessToStore]),
      accommodationTwoPersonRoom,
      accommodationTriplePersonRoom,
      accommodationFourPersonRoom,
      accommodationFivePersonRoom,
      accommodationRoomKitchen,
      accommodationRoomLivingRoom,
      accommodationRoomBathroom,
      accommodationRoomLaundry,
      accommodationContractStartDate,
      accommodationContractEndDate,
      accommodationTerminationType: t(
        getEnumItemName(enums.TERMINATION_TYPE, accommodationTerminationType)
      ),
      accommodationTerminationDayNumber,
      accommodationPrice,
      accommodationCurrency: getEnumItemName(
        enums.CURRENCY,
        accommodationCurrency
      ),
      accommodationType: t(
        getEnumItemName(enums.ACCOMMODATION_TYPE, accommodationType)
      ),
      accommodationAssetCount,
      accommodationAllAvailableRoomsPerPerson,
      accommodationAVGAvailableRoomsPerPerson,
    })
  );

export const projectManagementReportHeader = [
  {
    label: 'id Kandydata',
    key: 'candidateId',
  },

  { label: 'Nazwisko', key: 'candidateLastName' },
  {
    label: 'Imię',
    key: 'candidateFirstName',
  },
  { label: 'Rekruter / Opiekun', key: 'candidateOwner' },
  { label: 'Email', key: 'candidateEmail' },
  { label: 'Prefix telefon', key: 'candidatePhonePrimaryCode' },
  { label: 'Telefonu', key: 'candidatePhonePrimary' },
  { label: 'Płeć', key: 'candidateSex' },
  { label: 'Rok urodzenia', key: 'candidateBirthYear' },
  { label: 'Obywatelstwo', key: 'candidateCitizenship' },
  { label: 'Seria paszportu', key: 'candidatePassportSerialNumber' },
  { label: 'Numer paszportu', key: 'candidatePassportNumber' },
  { label: 'Planowany tytuł pobytowy', key: 'candidateResidenceTitle' },
  {
    label: 'Czy jest w kraju zatrudnienia',
    key: 'candidateIsForeignerInEmploymentArea',
  },
  { label: 'Data planowanej dostępności', key: 'candidateAvailabilityDate' },
  { label: 'Język #1 poziom', key: 'candidateLanguageSkillOne' },
  { label: 'Język #2 poziom', key: 'candidateLanguageSkillTwo' },
  { label: 'Numer zamówienia', key: 'projectOrderNumber' },
  { label: 'Data zamówienia', key: 'projectOrderCreatedAt' },
  { label: 'Firma / Płatnik', key: 'projectOrderOrganizationName' },
  { label: 'Firma / Klient', key: 'projectOrderClientName' },
  { label: 'Miejscowość', key: 'projectOrderLocalizationCity' },
  { label: 'Ulica', key: 'projectOrderLocalizationAddress' },
  { label: 'ID projektu', key: 'projectNumber' },
  { label: 'Projekt / Firma', key: 'projectClient' },
  { label: 'Branża', key: 'projectIndustryCategory' },
  { label: 'Stanowisko', key: 'projectPosition' },
  { label: 'Wynagrodzenie netto OD', key: 'projectSalaryMin' },
  { label: 'Wynagrodzenie netto DO', key: 'projectSalaryMax' },

  {
    label: 'Planowany rodzaj dokumentu wjazdowego',
    key: 'employmentProcessPlannedResidenceTitle',
  },
  {
    label: 'Planowany typ wniosku o pozwolenie na pracę',
    key: 'employmentProcessTypeRequest',
  },
  {
    label: 'Data złożenia wniosku o pozwolenie na pracę',
    key: 'employmentProcessResidenceRequestToOfficeDate',
  },
  {
    label: 'Wnioskowane zatrudnienie OD',
    key: 'employmentProcessRequestEmploymentStartDate',
  },
  {
    label: 'Wnioskowane zatrudnienie DO',
    key: 'employmentProcessRequestEmploymentEndDate',
  },
  {
    label: 'Typ wniosku o pozwolenie na pracę',
    key: 'employmentProcessTypeRequest',
  },

  {
    label: 'Numer dokumentu pozwolenia na pracę',
    key: 'employmentProcessDocumentNumber',
  },
  {
    label: 'Datę otrzymania decyzji z urzędu',
    key: 'employmentProcessDecisionFromOffice',
  },
  {
    label: 'Data przekazania dokumentu od kandydata',
    key: 'employmentProcessHandedDocumentFromCandidateDate',
  },
  { label: 'Pozwolenie ważne OD', key: 'employmentProcessDocumentStartDate' },
  { label: 'Pozwolenie ważne DO', key: 'employmentProcessDocumentEndDate' },
  { label: 'Kraj zatrudnienia', key: 'projectCountry' },
  {
    label: 'Rodzaj dokumentu wjazdowego',
    key: 'employmentProcessResidenceTitle',
  },

  {
    label: 'Wnioskowane daty zatrudnienia OD',
    key: 'employmentProcessRequestEmploymentStartDate',
  },
  {
    label: 'Wnioskowane daty zatrudnienia DO',
    key: 'employmentProcessRequestEmploymentEndDate',
  },

  {
    label: 'Planowana lokalizacja zatrudnienia kandydata',
    key: 'employmentProcessLocalization',
  },
  {
    label: 'Rodzaj dokumentu wjazdowego',
    key: 'employmentProcessResidenceTitle',
  },

  {
    label: 'Data ważności dokumentu wzjazdowego',
    key: 'employmentProcessVisaDate',
  },
  {
    label: 'Spodziewana data przyjazdu kandydata do kraju pracy',
    key: 'employmentProcessArrivalEstimatedDate',
  },

  {
    label: 'Podaj planowany okres zatrudnieniaOD',
    key: 'employmentProcessEstimatedStartEmploymentDate',
  },
  {
    label: 'Podaj planowany okres zatrudnieniaDO',
    key: 'employmentProcessEstimatedEndEmploymentDate',
  },

  {
    label: 'Lokalizacja zatrudnienia kandydata',
    key: 'employmentProcessLocalization',
  },

  {
    label: 'Ostatnia planowana data przyjazdu',
    key: 'employmentProcessArrivalEstimatedDate',
  },
  { label: 'Ubezpieczenie', key: 'employmentManagementIsEmployeeInsured' },

  {
    label: 'Data przekroczenia granicy',
    key: 'employmentManagementCrossBorderDate',
  },
  { label: 'Miejsce zakwaterowania', key: 'employmentManagementAccommodation' },

  {
    label: 'Planowane zakaterowanie OD',
    key: 'employmentManagementPeriodStayStartDate',
  },
  {
    label: 'Planowane zakaterowanie DO',
    key: 'employmentManagementPeriodStayEndDate',
  },
  { label: 'Kwarantanna', key: 'employmentManagementIsQuarantineRequired' },
  {
    label: 'Okres zatrudnienia OD',
    key: 'employmentManagementEmploymentStartDate',
  },
  {
    label: 'Okres zatrudnienia DO',
    key: 'employmentManagementEmploymentEndDate',
  },
  {
    label: 'Miejsce wykonywania pracy',
    key: 'employmentManagementLocalization',
  },
  { label: 'Miejsce zakwaterowania', key: 'employmentManagementAccommodation' },

  { label: 'Zatrudniony OD', key: 'employmentManagementEmploymentStartDate' },
  { label: 'Zatrudniony DO', key: 'employmentManagementEmploymentEndDate' },

  {
    label: 'Skan podpisanej umowy zatrudnienia',
    key: 'employmentManagementAssignContractScanDate',
  },
  {
    label: 'Skan numeru konta bankowego',
    key: 'employmentManagementAccountNumberScanDate',
  },
  {
    label: 'Skan dokumentu nadania numeru ewidencyjnego',
    key: 'employmentManagementPersonNumberScanDate',
  },

  { label: 'Przerwa w pracy OD', key: 'employmentManagementWorkBreak' },
  { label: 'Choroba OD', key: 'employmentManagementIllness' },
  {
    label: 'Datę planowanego końca pracy',
    key: 'employmentManagementResignationStartDate',
  },
  {
    label: 'Rezygancja Data końca pracy',
    key: 'employmentManagementResignationEndDate',
  },
  {
    label: 'Rezygnacja Termin wypowiedzenia w dniach',
    key: 'employmentManagementResignationEndDate',
  },
  {
    label: 'Data końca zatrudnienia',
    key: 'employmentManagementResignationEndDate',
  },

  {
    label: 'Przyczyna końca zatrudnienia',
    key: 'employmentManagementReasonOfTermination',
  },
  { label: 'Wyrejestrowanie', key: 'employmentManagementDeregisteringDate' },
];

export const generateDataProjectManagement = (data, t) =>
  data.map(
    ({
      candidateId,
      candidateFirstName,
      candidateLastName,
      candidateOwner,
      candidateEmail,
      candidatePhonePrimaryCode,
      candidatePhonePrimary,
      candidateSex,
      candidateBirthYear,
      candidateCitizenship,
      candidatePassportNumber,
      candidatePassportSerialNumber,
      candidateResidenceTitle,
      candidateIsForeignerInEmploymentArea,
      candidateAvailabilityDate,
      candidateLanguageSkillOne,
      candidateLanguageSkillTwo,
      projectOrderLocalizationAddress,
      projectOrderNumber,
      projectOrderCreatedAt,
      projectOrderOrganizationName,
      projectOrderClientName,
      projectOrderLocalizationCity,
      projectNumber,
      projectClient,
      projectIndustryCategory,
      projectPosition,
      projectSalaryMin,
      projectSalaryMax,
      employmentProcessPlannedResidenceTitle,
      employmentProcessTypeRequest,
      employmentProcessResidenceRequestToOfficeDate,
      employmentProcessRequestEmploymentStartDate,
      employmentProcessRequestEmploymentEndDate,
      employmentProcessDocumentNumber,
      employmentProcessDecisionFromOffice,
      employmentProcessHandedDocumentFromCandidateDate,
      employmentProcessDocumentStartDate,
      employmentProcessDocumentEndDate,
      projectCountry,
      employmentProcessResidenceTitle,
      employmentProcessLocalization,
      employmentProcessVisaDate,
      employmentProcessArrivalEstimatedDate,
      employmentProcessEstimatedStartEmploymentDate,
      employmentProcessEstimatedEndEmploymentDate,
      employmentManagementCrossBorderDate,
      employmentManagementIsEmployeeInsured,
      employmentManagementAccommodation,
      employmentManagementPeriodStayStartDate,
      employmentManagementPeriodStayEndDate,
      employmentManagementIsQuarantineRequired,
      employmentManagementEmploymentStartDate,
      employmentManagementEmploymentEndDate,
      employmentManagementLocalization,
      employmentManagementAssignContractScanDate,
      employmentManagementAccountNumberScanDate,
      employmentManagementPersonNumberScanDate,
      employmentManagementWorkBreak,
      employmentManagementDeregisteringDate,
      employmentManagementIllness,
      employmentManagementResignationStartDate,
      employmentManagementResignationEndDate,
      employmentManagementReasonOfTermination,
    }) => ({
      candidateId,
      candidateFirstName,
      projectOrderLocalizationAddress,
      candidateLastName,
      candidateOwner,
      candidateEmail,
      candidatePhonePrimaryCode,
      candidatePhonePrimary,
      candidateSex: t(enums.SEX[candidateSex]),
      candidateBirthYear,
      candidateCitizenship,
      candidatePassportNumber,
      candidatePassportSerialNumber,
      candidateResidenceTitle: t(
        getEnumItemName(enums.RESIDENCE_TITLE, candidateResidenceTitle)
      ),
      candidateIsForeignerInEmploymentArea: t(
        enums.YES_NO[candidateIsForeignerInEmploymentArea]
      ),
      candidateAvailabilityDate,
      candidateLanguageSkillOne,
      candidateLanguageSkillTwo,
      projectOrderNumber,
      projectOrderCreatedAt,
      projectOrderOrganizationName,
      projectOrderClientName,
      projectOrderLocalizationCity,
      projectNumber,
      projectClient,
      projectIndustryCategory: projectIndustryCategory,
      projectPosition,
      projectSalaryMin,
      projectSalaryMax,
      employmentProcessPlannedResidenceTitle: t(
        getEnumItemName(
          enums.RESIDENCE_TITLE,
          employmentProcessPlannedResidenceTitle
        )
      ),
      employmentProcessTypeRequest: t(
        getEnumItemName(
          enums.EMPLOYMENT_TYPE_REQUEST,
          employmentProcessTypeRequest
        )
      ),
      employmentProcessResidenceRequestToOfficeDate,
      employmentProcessRequestEmploymentStartDate,
      employmentProcessRequestEmploymentEndDate,
      employmentProcessDocumentNumber,
      employmentProcessDecisionFromOffice,
      employmentProcessHandedDocumentFromCandidateDate,
      employmentProcessDocumentStartDate,
      employmentProcessDocumentEndDate,
      projectCountry,
      employmentProcessResidenceTitle: t(
        getEnumItemName(enums.RESIDENCE_TITLE, employmentProcessResidenceTitle)
      ),
      employmentProcessLocalization,
      employmentProcessVisaDate,
      employmentProcessArrivalEstimatedDate,
      employmentProcessEstimatedStartEmploymentDate,
      employmentProcessEstimatedEndEmploymentDate,
      employmentManagementCrossBorderDate,
      employmentManagementIsEmployeeInsured: t(
        enums.YES_NO[employmentManagementIsEmployeeInsured]
      ),
      employmentManagementAccommodation,
      employmentManagementPeriodStayStartDate,
      employmentManagementPeriodStayEndDate,
      employmentManagementIsQuarantineRequired: t(
        enums.YES_NO[employmentManagementIsQuarantineRequired]
      ),
      employmentManagementEmploymentStartDate,
      employmentManagementEmploymentEndDate,
      employmentManagementLocalization,
      employmentManagementAssignContractScanDate,
      employmentManagementAccountNumberScanDate,
      employmentManagementPersonNumberScanDate,
      employmentManagementDeregisteringDate,
      employmentManagementWorkBreak:
        employmentManagementWorkBreak !== null
          ? employmentManagementWorkBreak
              .map(item => {
                return `${item.dateStart} - ${item.dateEnd}`;
              })
              .join('| ')
          : '',
      employmentManagementIllness:
        employmentManagementIllness !== null
          ? employmentManagementIllness
              .map(item => {
                return `${item.dateStart} - ${item.dateEnd}`;
              })
              .join('| ')
          : '',
      employmentManagementResignationStartDate,
      employmentManagementResignationEndDate,
      employmentManagementReasonOfTermination: t(
        getEnumItemName(
          enums.END_OF_EMPLOYMENT_REASON,
          employmentManagementReasonOfTermination
        )
      ),
    })
  );

const getEmploymentGeneralStatus = employmentManagementProcessStatus => {
  if ([100, 101].includes(employmentManagementProcessStatus)) {
    return `atsSubMenu:recruitment`;
  }
  if ([200].includes(employmentManagementProcessStatus)) {
    return `atsSubMenu:employment`;
  }
  if (employmentManagementProcessStatus) {
    return `atsSubMenu:management`;
  }
  return '';
};

export const generateDataAllHelpInfo = (data, t) =>
  data.map(
    ({
      id,
      employerStatus,
      isRecruiter,
      firstName,
      lastName,
      phonePrimaryCode,
      phonePrimary,
      email,
      transport,
      accommodation,
      work,
      numberOfSeats,
      pickUpPlace,
      destinationPlace,
      periodPickUpDate,
      periodPickUpTime,
      vehicleNumber,
      vehicleBrand,
      city,
      numberOfPeople,
      numberOfMen,
      numberOfWomen,
      numberOfPair,
      periodStayStartDate,
      periodStayEndDate,
      address,
      postCode,
      locationType,
      locationStandard,
      roomNumber,
    }) => {
      return {
        id,
        employerStatus: isRecruiter
          ? getUserAccountType(employerStatus)
          : 'Kandydat',
        firstName,
        lastName,
        phonePrimaryCode,
        phonePrimary,
        email,
        transport: t(enums.YES_NO[transport]),
        accommodation: t(enums.YES_NO[accommodation]),
        work: t(enums.YES_NO[work]),
        numberOfSeats,
        pickUpPlace,
        destinationPlace,
        periodPickUpDate,
        periodPickUpTime,
        vehicleNumber,
        vehicleBrand,
        city,
        numberOfPeople,
        numberOfMen,
        numberOfWomen,
        numberOfPair,
        periodStayStartDate,
        periodStayEndDate,
        address,
        postCode,
        locationType: t(getEnumItemName(enums.TYPE_OF_LOCATION, locationType)),
        locationStandard: t(
          getEnumItemName(enums.STANDARD_OF_LOCATION, locationStandard)
        ),
        roomNumber,
      };
    }
  );

export const generateDataDetailsProjectOrders = (data, t) =>
  data.map(
    ({
      employmentManagementProcessStatus,
      employmentManagementProcessSecondStatus,
      employmentManagementProcessDate,
      date,
      candidateSex,
      candidateFirstName,
      candidateLastName,
      candidateBirthYear,
      candidateCitizenship,
      projectNumber,
      projectOrderCreatedAt,
      projectOrderNumber,
      projectOrganizationName,
      projectOrderLocalizationCity,
      projectOrderLocalizationAddress,
      projectOrderClient,
      projectOrderStatus,
      projectOrderEmploymentStartDate,
      projectPosition,
      projectOrderNumberOfManCandidate,
      projectOrderNumberOfWomanCandidate,
      projectOrderNumberOfPairCandidate,
      projectOrderSumOfCandidates,
    }) => ({
      employmentManagementProcessStatus: t(
        getEmploymentGeneralStatus(employmentManagementProcessStatus)
      ),
      employmentManagementProcessSecondStatus,
      employmentManagementProcessDate,
      date,
      candidateSex,
      candidateFirstName,
      candidateLastName,
      candidateBirthYear,
      candidateCitizenship,
      projectNumber,
      projectOrderCreatedAt,
      projectOrderNumber,
      projectOrganizationName,
      projectOrderClient,
      projectOrderLocalizationCity,
      projectOrderLocalizationAddress,
      projectOrderStatus: t(
        getEnumItemName(enums.PROJECT_ORDER_STATUS, projectOrderStatus)
      ),
      projectOrderEmploymentStartDate,
      projectPosition,
      projectOrderNumberOfManCandidate,
      projectOrderNumberOfWomanCandidate,
      projectOrderNumberOfPairCandidate,
      projectOrderSumOfCandidates,
    })
  );

export const generateDataProjectOrders = (data, t) =>
  data.map(
    ({
      projectNumber,
      projectOrderCreatedAt,
      projectOrderNumber,
      projectOrganizationName,
      projectOrderLocalizationCity,
      projectOrderLocalizationAddress,
      projectOrderClient,
      projectOrderStatus,
      projectOrderEmploymentStartDate,
      projectPosition,
      projectOrderNumberOfManCandidate,
      projectOrderNumberOfWomanCandidate,
      projectOrderNumberOfPairCandidate,
      projectOrderSumOfCandidates,
      projectOrderAVGOfCandidates,
      projectOrderAllCandidateInProcess,
    }) => ({
      projectNumber,
      projectOrderCreatedAt,
      projectOrderNumber,
      projectOrganizationName,
      projectOrderClient,
      projectOrderLocalizationCity,
      projectOrderLocalizationAddress,
      projectOrderStatus: t(
        getEnumItemName(enums.PROJECT_ORDER_STATUS, projectOrderStatus)
      ),
      projectOrderEmploymentStartDate,
      projectPosition,
      projectOrderNumberOfManCandidate,
      projectOrderNumberOfWomanCandidate,
      projectOrderNumberOfPairCandidate,
      projectOrderSumOfCandidates,
      projectOrderAVGOfCandidates,
      projectOrderAllCandidateInProcess,
    })
  );

export const generateDataDetailCandidate = (data, t) => {
  return data.map(item => {
    return {
      id: item.candidate.id,
      firstName: item.candidate.firstName,
      lastName: item.candidate.lastName,
      owner: item.candidate.owner ? item.candidate.owner.id : null,
      project: item.project.id,
      projectPosition: item.project.position,
      projectClient: item.project.client,
      projectPublicationDate: item.project.publicationDate,
      stage1Date: item.stage1Date,
      stage2Date: item.stage2Date,
      stage3Date: item.stage3Date,
      stage4Date: item.stage4Date,
      stage5Date: item.stage5Date,
      stage6Date: item.stage6Date,
      stage1DateReject: item.stage1DateReject,
      stage2DateReject: item.stage2DateReject,
      stage3DateReject: item.stage3DateReject,
      stage4DateReject: item.stage4DateReject,
      stage5DateReject: item.stage5DateReject,
      stage6DateReject: item.stage6DateReject,
    };
  });
};
export const generalDataGeneralCandidate = (data, t) => {
  return data.map(item => {
    const filterUserLanguages = item.userSkills
      ? prepareLanguageCompetence(item.userSkills)
      : [];

    const filterUserSkills = item.userSkills
      ? prepareCompetence(item.userSkills)
      : [];

    return {
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      phonePrimary: item.phonePrimary
        ? `${item.phonePrimaryCode ? item.phonePrimaryCode : ''}${
            item.phonePrimary
          }`
        : null,
      email: item.email,
      homeCountry: item.homeCountry ? item.homeCountry.name : null,
      citizenship: item.citizenship ? item.citizenship.name : null,
      position: item.position,
      industryCategory: item.industryCategory
        ? item.industryCategory.name
        : null,
      companySize: getEnumItemName(enums.COMPANY_SIZE, item.companySize),
      workInForeign: null,
      city: item.city,
      userExperiencesName:
        item.userExperiences && item.userExperiences.length
          ? item.userExperiences[0].position
          : null,
      userExperiencesIndustry:
        item.userExperiences && item.userExperiences.length
          ? item.userExperiences[0].industryCategory.name
          : null,
      userExperiencesCompanySize:
        item.userExperiences && item.userExperiences.length
          ? getEnumItemName(
              enums.COMPANY_SIZE,
              item.userExperiences[0].companySize
            )
          : null,
      userExperiencesExperience:
        item.userExperiences && item.userExperiences.length
          ? item.userExperiences[0].experienceLevel
          : null,
      userSkillsCompetenceCategory1:
        filterUserSkills.length && filterUserSkills[0]
          ? filterUserSkills[0].competence.skill.name
          : null,
      userSkillsExperienceLevel1:
        filterUserSkills.length && filterUserSkills[0]
          ? t(
              getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                filterUserSkills[0].experience
              )
            )
          : null,
      userSkillsCompetenceCategory2:
        filterUserSkills.length && filterUserSkills[1]
          ? filterUserSkills[1].competence.skill.name
          : null,
      userSkillsExperienceLevel2:
        filterUserSkills.length && filterUserSkills[1]
          ? t(
              getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                filterUserSkills[1].experience
              )
            )
          : null,
      userSkillsCompetenceCategory3:
        filterUserSkills.length && filterUserSkills[2]
          ? filterUserSkills[2].competence.skill.name
          : null,
      userSkillsExperienceLevel3:
        filterUserSkills.length && filterUserSkills[2]
          ? t(
              getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                filterUserSkills[2].experience
              )
            )
          : null,

      userSkillsLanguageName1:
        filterUserLanguages.length && filterUserLanguages[0]
          ? filterUserLanguages[0].competence.skill.name
          : null,
      userSkillsLanguageExperienceLevel1:
        filterUserLanguages.length && filterUserLanguages[0]
          ? t(
              getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                filterUserLanguages[0].experience
              )
            )
          : null,

      userSkillsLanguageName2:
        filterUserLanguages.length && filterUserLanguages[1]
          ? filterUserLanguages[1].competence.skill.name
          : null,
      userSkillsLanguageExperienceLevel2:
        filterUserLanguages.length && filterUserLanguages[1]
          ? t(
              getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                filterUserLanguages[1].experience
              )
            )
          : null,

      userSkillsLanguageName3:
        filterUserLanguages.length && filterUserLanguages[2]
          ? filterUserLanguages[2].competence.skill.name
          : null,
      userSkillsLanguageExperienceLevel3:
        filterUserLanguages.length && filterUserLanguages[2]
          ? t(
              getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                filterUserLanguages[2].experience
              )
            )
          : null,
      minMonthlyWadges: item.minMonthlyWadges,
      maxMonthlyWadges: item.maxMonthlyWadges,
      employmentTypes:
        item.employmentTypes !== null
          ? item.employmentTypes.map(item => item.name).join(', ')
          : '',
      candidateInProject: item.generalCandidateReport.candidateInProject,
      candidatesInStage4: item.generalCandidateReport.candidatesInStage4,
      candidatesInStage5: item.generalCandidateReport.candidatesInStage5,
      candidatesInStage6: item.generalCandidateReport.candidatesInStage6,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      owner: item.owner ? item.owner.id : null,
      ownerName: item.owner ? item.owner.firstName : null,
      ownerLastName: item.owner ? item.owner.lastName : null,
    };
  });
};
export const detailDataGeneralRecruiter = (data, translate) => {
  return data.map(item => {
    return {
      id: item.recruiter.id,
      firstName: item.recruiter.firstName,
      lastName: item.recruiter.lastName,

      createdAt: item.recruiter.createdAt,
      createdAtTime: item.recruiter.createdAtTime,
      updatedAt: item.recruiter.updatedAt,
      employerStatus: getUserAccountType(item.recruiter.employerStatus),
      organization: item.recruiter.organization
        ? item.recruiter.organization.id
        : '',
      organizationName: item.recruiter.organization
        ? item.recruiter.organization.name
        : '',
      isOrganizationOwner:
        item.recruiter.organization &&
        item.recruiter.organization.owner &&
        item.recruiter.organization.owner.id === item.id
          ? 'TAK'
          : 'NIE',

      owner: item.recruiter.owner ? item.recruiter.owner.id : null,
      project: item.project.id,
      position: item.project.position,
      client: item.project.client,
      publicationDate: item.project.publicationDate,
      candidatesInStage1: item.detailRecruiterReport.candidatesInStage1,
      candidatesInStage4: item.detailRecruiterReport.candidatesInStage4,
      candidatesInStage5: item.detailRecruiterReport.candidatesInStage5,
      candidatesInStage6: item.detailRecruiterReport.candidatesInStage6,
      candidatesInStage1Rejected:
        item.detailRecruiterReport.candidatesInStage1Rejected,
      candidatesInStage2Rejected:
        item.detailRecruiterReport.candidatesInStage2Rejected,
      candidatesInStage3Rejected:
        item.detailRecruiterReport.candidatesInStage3Rejected,
      candidatesInStage4Rejected:
        item.detailRecruiterReport.candidatesInStage4Rejected,
      candidatesInStage5Rejected:
        item.detailRecruiterReport.candidatesInStage5Rejected,
      candidatesInStage6Rejected:
        item.detailRecruiterReport.candidatesInStage6Rejected,
    };
  });
};

const getUserAccountType = employerStatus => {
  switch (employerStatus) {
    case 0:
      return 'Rekruter';
    case 1:
      return 'Pracodawca';
    case 2:
      return 'Partner';
    default:
      return 'Rekruter';
  }
};
export const generateDataGeneralRecruiter = (data, translate) => {
  return data.map(item => {
    return {
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      country: item.country,
      email: item.email,
      citizen: null,
      mentorStatus: item.mentorStatus === 0 ? 'Nie' : 'Tak',
      owner: item.owner ? item.owner.id : null,
      industry: item.industry,
      createdAt: item.createdAt,
      createdAtTime: item.createdAtTime,
      updatedAt: item.updatedAt,
      employerStatus: getUserAccountType(item.employerStatus),
      organization: item.organization ? item.organization.id : null,
      isOrganizationOwner:
        item.organization &&
        item.organization.owner &&
        item.organization.owner === item.id
          ? 'TAK'
          : 'NIE',
      organizationName: item.organization ? item.organization.name : null,
      employmentType: null,
      primaryPhone: item.phonePrimary,
      candidateOwner: item.generalRecruiterReport.candidateOwner,
      recruiterInProjects: item.generalRecruiterReport.recruiterInProjects,
      candidateInProjects: item.generalRecruiterReport.candidateInProjects,
      candidatesInStage5: item.generalRecruiterReport.candidatesInStage5,
      candidatesInStage6: item.generalRecruiterReport.candidatesInStage6,
    };
  });
};

export const generateDataGeneralProject = (data, translate) => {
  return data.map(item => {
    return {
      id: item.id,
      isDraft: item.isDraft ? 'Nie' : 'Tak',
      subscriptionValue: item.subscriptionValue,
      publicationValue: item.publicationValue,
      meetingsValue: item.meetingsValue,
      hiringValue: item.hiringValue,
      warrantyValue: item.warrantyValue,
      paymentDeadline: '',
      revenueSubmitted: item.revenueSubmitted,
      revenueWinner: item.revenueWinner,
      mentorFee: item.mentorFee,
      client: item.client,
      country: item.country !== null ? item.country.name : '',
      industryCategory:
        item.industryCategory !== null ? item.industryCategory.name : '',
      companySize: getEnumItemName(enums.COMPANY_SIZE, item.companySize),
      city: item.city,
      isRemoteWork: item.isRemoteWork ? 'Tak' : 'Nie',
      position: item.position,
      calcCandidateExperience:
        item.calcCandidateExperience !== null
          ? translate(
              getEnumItemName(
                enums.CALC_CANDIDATE_EXPERIENCE,
                item.calcCandidateExperience
              )
            )
          : '',
      minSalary: item.minSalary,
      maxSalary: item.maxSalary,
      employmentTypes:
        item.employmentTypes !== null
          ? item.employmentTypes.map(item => item.name).join(', ')
          : '',
      candidatesInStage1:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage1
          : '',
      candidatesInStage4:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage4
          : '',
      candidatesInStage5:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage5
          : '',
      candidatesInStage6:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage6
          : '',
      candidatesInStage1Rejected:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage1Rejected
          : '',
      candidatesInStage2Rejected:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage2Rejected
          : '',
      candidatesInStage3Rejected:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage3Rejected
          : '',
      candidatesInStage4Rejected:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage4Rejected
          : '',
      candidatesInStage5Rejected:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage5Rejected
          : '',
      candidatesInStage6Rejected:
        item.generalProjectReport !== null
          ? item.generalProjectReport.candidatesInStage6Rejected
          : '',
      recruitersInProject:
        item.generalProjectReport !== null
          ? item.generalProjectReport.recruitersInProject
          : '',
      publicationDate: item.publicationDate,
      updatedAt: item.updatedAt,
      closedDate: item.closedDate,
      firstDateRecommendationCandidate:
        item.generalProjectReport !== null
          ? item.generalProjectReport.firstDateRecommendationCandidate
          : '',
      secondDateRecommendationCandidate:
        item.generalProjectReport !== null
          ? item.generalProjectReport.secondDateRecommendationCandidate
          : '',
      thirdDateRecommendationCandidate:
        item.generalProjectReport !== null
          ? item.generalProjectReport.thirdDateRecommendationCandidate
          : '',
      owner: item.owner ? item.owner.id : '',
      ownerFirstName: item.owner ? item.owner.firstName : '',
      ownerLastName: item.owner ? item.owner.lastName : '',
      mentor: item.mentor ? item.mentor.id : '',
      mentorFirstName: item.mentor ? item.mentor.firstName : '',
      mentorLastName: item.mentor ? item.mentor.lastName : '',
      hiringManager: item.hiringManager ? item.hiringManager.id : '',
      hiringManagerFirstName: item.hiringManager
        ? item.hiringManager.firstName
        : '',
      hiringManagerLastName: item.hiringManager
        ? item.hiringManager.lastName
        : '',
    };
  });
};
