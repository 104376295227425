import { Grid, Paper, withStyles } from '@material-ui/core';
import { Check } from 'mdi-material-ui';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { AssetsUpload, Button, Translate } from '..';
import { styles } from './UploadFileCard.styles';

const UploadFileCard = ({
  classes,
  label,
  imageName,
  aspect,
  assetType,
  setResponse,
  isAssetAssigned,
  assets,
  onDeleteAsset,
  t,
  fileLimit = 1,
}) => {
  return (
    <Grid container className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Translate text={label} variant="body1" />
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              {isAssetAssigned && (
                <Grid item>
                  <Check color="primary" />
                </Grid>
              )}
              <Grid item>
                <AssetsUpload
                  onDeleteAsset={onDeleteAsset}
                  acceptedFiles={['image/*']}
                  assets={assets}
                  filesLimit={fileLimit}
                  aspect={aspect}
                  imageName={imageName}
                  assetType={assetType}
                  setResponse={setResponse}
                >
                  <Button
                    label={t('projectCreator:step5.choiceFile')}
                    variant="outlined"
                    onClick={() => {}}
                  />
                </AssetsUpload>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(UploadFileCard));
