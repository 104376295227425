import { API } from '../constants';
import { ApiService } from './api.service';

export const LocalizationService = {
  addLocalization(data) {
    return ApiService.post(API.POST.LOCALIZATION.POST, data);
  },
};

export default LocalizationService;
