import {
  CardActions,
  CardContent,
  Typography,
  withStyles,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import Button from 'shared/newComponents/Button';
import RecruiterCard from 'shared/newComponents/RecruiterCard';
import AssignRecruiterToOrganizationButton from '../../../components/AssignRecruiterToOrganizationButton';

const styles = theme => ({
  actions: {
    borderTop: '1px solid #e9e9e9',
  },
  buttonShow: {
    background: '#F5F5F5',
    color: '#52a3fc',
    borderRadius: '0 0 10px 10px',
    width: '100%',
  },
  rightIcon: {
    marginLeft: theme.spacing(),
    width: 15,
  },
  avatarRecruiter: {
    width: 80,
    height: 80,
  },
  userStatus: {
    background: theme.palette.jobllegroRedAlert,
    paddingRight: 5,
    paddingLeft: 5,
    top: '58px',
    borderRadius: 0,
    color: '#fff',
    width: 'auto',
    right: 0,
  },
  userStatusDisabled: {
    display: 'none',
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
  },
  content: {
    overflow: 'hidden',
  },
  subHeaderContent: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

export const RecruiterCardList = props => {
  const {
    classes,
    t,
    recruiter,
    action = null,
    cardContent = null,
    cardActions = null,
    currentUser: { isOrganizationOwner },
  } = props;

  const handleClickReturnToProject = () => {
    props.history.push(PATHS.RECRUITER.SHOW.replace(':id', recruiter.id));
  };

  return (
    <Grid
      container
      style={{
        boxShadow: '-1px -1px 30px 0px rgb(0 0 0 / 10%)',
        borderRadius: 10,
        wordBreak: 'break-all',
      }}
    >
      <RecruiterCard recruiter={recruiter} showBtn={false} action={action} />
      <CardContent className={classes.cardContent} style={{ paddingTop: 0 }}>
        {cardContent !== null && (
          <Typography component="div">{cardContent}</Typography>
        )}
        {isOrganizationOwner && (
          <AssignRecruiterToOrganizationButton recruiter={recruiter} />
        )}
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        {cardActions ? (
          cardActions
        ) : (
          <Grid
            container
            justifyContent="center"
            className={classes.saveButton}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Button
                label={t`recruiter:see`}
                onClick={handleClickReturnToProject}
              />
            </Grid>
          </Grid>
        )}
      </CardActions>
    </Grid>
  );
};

RecruiterCardList.propTypes = {
  classes: PropTypes.object.isRequired,
  recruiter: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(withRouter(RecruiterCardList))));
