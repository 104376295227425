export const styles = () => ({
  contentWrapper: { padding: '0px 25px' },
  bluePrimaryBackground: { backgroundColor: '#e5f0ff' },
  imagesContainer: { position: 'relative' },
  logoWrapper: { position: 'absolute', height: '100%' },
  logo: { height: '70%', marginLeft: '40px', borderRadius: '50%' },
  separator: {
    borderColor: 'rgba(213, 214, 216, 0.5)',
    margin: '16px 0',
    width: '100%',
  },
});
