export const styles = () => ({
  contentWrapper: {
    backgroundColor: '#f1f7ff',
    margin: '0 -15px 25px',
    width: 'calc(100% + 30px)',
    padding: '25px 20px 25px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  header: {
    width: '100%',
    paddingLeft: '80px',
  },
  link: {
    width: '50%',
    textDecoration: 'none',
  },
  divider: {
    width: '150px',
    height: '2px',
    marginTop: '15px',
    backgroundColor: '#2E9CFF',
  },
});
