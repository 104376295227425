import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const columnData = [
  { id: 'id', numeric: true, label: 'id' },
  { id: 'name', numeric: false, label: 'nazwa' },
  { id: 'en_name', numeric: false, label: 'nazwa (en)' },
  { id: 'de_name', numeric: false, label: 'nazwa (de)' },
  { id: 'ua_name', numeric: false, label: 'nazwa (ua)' },
  { id: 'isApproved', numeric: false, label: 'Status' },
  { id: '', numeric: false, label: 'Akcje' },
];

export class AdminCompetenceTypeViewHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableRow>
        {columnData.map(column => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={order}
              onClick={this.createSortHandler(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    );
  }
}

AdminCompetenceTypeViewHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default AdminCompetenceTypeViewHead;
