import { Grid, withStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { styles } from './Workwear.styles';

const Workwear = ({ classes, t, candidateEmploymentHistories }) => {
  const candidateEmploymentHistory = candidateEmploymentHistories.length
    ? candidateEmploymentHistories[0]
    : null;

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={classes.insurance}
      >
        <Grid item>
          <Typography variant="body1">{t`candidateDetails:employmentStatus.healthInsurance`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.bold}>
            {candidateEmploymentHistory &&
            candidateEmploymentHistory.isEmployeeInsured
              ? t`candidateDetails:employmentStatus.yes`
              : t`candidateDetails:employmentStatus.no`}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(withTranslation()(Workwear));
