import { enums } from '../../enums';

export const isOrganizationDetailsSet = organization => {
  if (
    !organization.invoiceAddress ||
    !organization.invoiceCity ||
    !organization.invoiceCompanyName ||
    !organization.invoicePostCode ||
    (organization.organizationType ===
    enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
      ? !organization.invoiceIdentificationNumber
      : !organization.invoicePersonalNumber)
  ) {
    return false;
  }
  return (
    organization.invoiceAddress.trim().length !== 0 ||
    organization.invoiceCity.trim().length !== 0 ||
    organization.invoiceCompanyName.trim().length !== 0 ||
    organization.invoicePostCode.trim().length !== 0 ||
    (organization.organizationType ===
    enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
      ? organization.invoiceIdentificationNumber.trim().length !== 0
      : organization.invoicePersonalNumber.trim().length !== 0)
  );
};
