import {
  Avatar,
  IconButton,
  CircularProgress,
  TableRow,
  TableCell,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { CompareArrows as CompareArrowsIcon } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getUserAvatarSrc } from 'shared/utils/assets';
import {
  checkCandidateFirstNameLastName,
  getSimilaritiesScore,
} from 'shared/utils/functions';

const styles = theme => ({
  RowActive: {
    background: '#ebffe0',
  },
  buttonAdd: {
    width: 20,
    height: 20,
    padding: 0,
  },
  avatar: {
    width: 32,
    height: 32,
  },
  Link: {
    textDecoration: 'none',
    color: '#000',
  },
  ul: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  toolTip: {
    background: theme.palette.jobllegroBlue,
    color: '#fff',
    fontSize: 14,
  },
  progressCircular: {
    marginRight: 16,
  },
});

export const ProjectRightListItem = props => {
  const {
    data,
    isLoadingRight,
    projectDetails,
    onAddHandle,
    classes,
    option,
    t,
    currentUser: { id: userId },
  } = props;

  const link =
    option === 'candidate' ? PATHS.CANDIDATE.SHOW : PATHS.RECRUITER.SHOW;

  const titleCard = (data, option) => {
    return (
      <Link className={classes.Link} to={link.replace(':id', data.id)}>
        {checkCandidateFirstNameLastName(
          data.firstName,
          data.lastName,
          data.id,
          t
        )}{' '}
        {getSimilaritiesScore(data.similarities)}
      </Link>
    );
  };

  const avatar = getUserAvatarSrc(data);
  const checkIfCandidateisYours = data.permissions.isOwner;
  const { permissions = {} } = projectDetails;
  const { isHiringManager } = permissions;

  return (
    userId !== data.id && (
      <TableRow
        key={data.id}
        className={classNames(checkIfCandidateisYours && classes.RowActive)}
      >
        <TableCell style={{ paddingLeft: 0 }}>
          <List className={classes.ul}>
            <ListItem className={classes.ul}>
              {isLoadingRight ? (
                <CircularProgress
                  className={classes.progressCircular}
                  size={20}
                />
              ) : (
                !projectDetails.closedStatus &&
                !isHiringManager && (
                  <Tooltip
                    title={t('project:form.sendInvitationToProject')}
                    placement="top"
                    classes={{
                      tooltip: classes.toolTip,
                    }}
                  >
                    <ListItemIcon>
                      <IconButton
                        className={classes.buttonAdd}
                        onClick={onAddHandle.bind(this, data.id)}
                        color="primary"
                      >
                        <CompareArrowsIcon />
                      </IconButton>
                    </ListItemIcon>
                  </Tooltip>
                )
              )}
              <Avatar
                src={avatar}
                aria-label="Jobllegro"
                component={Link}
                to={link.replace(':id', data.id)}
                className={classes.avatar}
              ></Avatar>
              <ListItemText
                primary={titleCard(data, option)}
                // secondary={data.availabilityDate !== null && t('project:show.availableFrom') + data.availabilityDate}
              />
            </ListItem>
          </List>
        </TableCell>
        <TableCell>{data.city}</TableCell>
        <TableCell>{data.position}</TableCell>
      </TableRow>
    )
  );
};

const mapStateToProps = store => {
  return {
    open: store.messages.open,
    currentUser: store.auth.currentUser,
  };
};

ProjectRightListItem.propTypes = {
  open: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectRightListItem)));
