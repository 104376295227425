export const styles = () => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#2D333B',
  },
  dialogTitle: {
    '& h6': { fontWeight: 500 },
  },
  cancelBtn: {
    marginRight: 10,
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 30,
    color: '#fff',
    background: '#F41313',
    '&:hover': { background: '#F41313', opacity: 0.8 },
  },
  saveBtn: {
    width: 76,
    color: '#fff',
    marginTop: 10,
    marginRight: 20,
    marginBottom: 10,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  alert: { borderRadius: '50%' },
  chooseFileBtn: {
    borderRadius: 30,
    color: '#fff',
    background: '#148EFF',
    '&:hover': { background: '#148EFF', opacity: 0.8 },
  },
  chooseFileDiv: {
    marginLeft: 20,
  },
  info: { display: 'flex', alignItems: 'center' },
});
