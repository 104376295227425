import { Grid, Typography, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { WizardStep } from '../../../../newComponents';
import TextField from '../../../../newComponents/TextField';
import { HelpService } from '../../../../services';
import { styles } from './NeedTransport.styles';

const NeedTransport = ({ classes, t, lookingHelpData, sectionSaved }) => {
  const [isTransportOpen, setIsTransportOpen] = useState(true);
  const [saved, setIsSaved] = useState(false);

  const formData = {
    pickUpPlace:
      lookingHelpData && lookingHelpData.pickUpPlace
        ? lookingHelpData.pickUpPlace
        : '',
    destinationPlace:
      lookingHelpData && lookingHelpData.destinationPlace
        ? lookingHelpData.destinationPlace
        : '',
    numberOfSeats:
      lookingHelpData && lookingHelpData.numberOfSeats
        ? lookingHelpData.numberOfSeats
        : 0,
    periodPickUpDate:
      lookingHelpData && lookingHelpData.periodPickUpDate
        ? lookingHelpData.periodPickUpDate
        : '',
    periodPickUpTime:
      lookingHelpData && lookingHelpData.periodPickUpTime
        ? lookingHelpData.periodPickUpTime
        : '',
  };

  const lookingHelpInfoAdded = () => {
    setIsSaved(true);
    sectionSaved();
  };

  const validationSchema = yup.object({
    pickUpPlace: yup.string().required(t`ats2ats3:validation.isRequired`),
    destinationPlace: yup.string().required(t`ats2ats3:validation.isRequired`),
    numberOfSeats: yup
      .number()
      .min(0, t('projectCreator:core.minValue', { value: 0 }))
      .required(t`ats2ats3:validation.isRequired`),
    periodPickUpDate: yup
      .date()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
    periodPickUpTime: yup.string().required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: values => {
      const body = {
        pickUpPlace: values.pickUpPlace,
        destinationPlace: values.destinationPlace,
        periodPickUpDate: values.periodPickUpDate,
        periodPickUpTime: values.periodPickUpTime,
        numberOfSeats: parseInt(values.numberOfSeats),
      };

      HelpService.updateLookingHelpInfoId(lookingHelpData.id, body, 2)
        .then(() => {
          lookingHelpInfoAdded();
        })
        .catch(error => {
          console.log(error);
        });
    },
  });

  return (
    <>
      <WizardStep
        subInfo={
          (lookingHelpData && lookingHelpData.periodPickUpTime) || saved
            ? 'help:help.sectionSaved'
            : null
        }
        withSaveButton
        title={t('help:help.transport')}
        isOpen={isTransportOpen}
        onCloseSelf={() => {
          setIsTransportOpen(false);
        }}
        onOpen={() => {
          setIsTransportOpen(true);
        }}
        onClose={() => {
          formik.submitForm();
        }}
      >
        <div className={classes.container}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.padding}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">
                    {t('help:help.pickupPlace')}
                  </Typography>
                  <TextField
                    placeholder={t('help:help.enterPickupPlace')}
                    formik={formik}
                    name="pickUpPlace"
                    variant="transparent"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">
                    {t('help:help.destinationPlace')}
                  </Typography>
                  <TextField
                    placeholder={t('help:help.enterDestinationPlace')}
                    formik={formik}
                    name="destinationPlace"
                    variant="transparent"
                  />
                </Grid>
              </Grid>

              <div className={classes.transportDetail}>
                <Typography className={classes.transportLabel} variant="h6">
                  {t('help:help.seatsPlace')}
                </Typography>
                <TextField
                  type="number"
                  formik={formik}
                  name="numberOfSeats"
                  variant="transparent"
                  className={classes.numberField}
                />
              </div>

              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {t('help:help.plannedPickupDate')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="date"
                    formik={formik}
                    name="periodPickUpDate"
                    variant="transparent"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    type="time"
                    formik={formik}
                    name="periodPickUpTime"
                    variant="transparent"
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </WizardStep>
    </>
  );
};

export default withStyles(styles)(withTranslation()(NeedTransport));
