import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateAbilityDate = ({ candidate, t }) => {
  const isNewCreatorCandidate = candidate && candidate.currentStage > 1;

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Grid item>
        <Translate
          style={{
            fontSize: 20,
          }}
          bold
          text={'candidateCreator:step1.availabilityTitle'}
        />
      </Grid>
      <Grid item>
        {isNewCreatorCandidate ? (
          <Translate
            style={{
              fontSize: 16,
            }}
            text={
              candidate.availabilityDate
                ? candidate.availabilityDate
                : candidate.availabilityTypeCandidate !== null
                ? 'candidateCreator:step1.availabilityDayLeftTitle'
                : 'core:general.noInformation'
            }
            disableTranslate={candidate.availabilityDate}
          />
        ) : (
          <Translate
            style={{
              fontSize: 16,
            }}
            text={
              candidate.availability
                ? candidate.availability
                : 'core:general.noInformation'
            }
            disableTranslate={candidate.availability}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateAbilityDate))));
