import { createReducer } from '@reduxjs/toolkit';
import {
  setCurrentRecruiterAction,
  clearCurrentRecruiterAction,
  setCurrentRecruitersListAction,
  clearCurrentRecruitersListAction,
  setRecruitersInProjectAction,
  clearRecruitersInProjectAction,
  setRecruitersInOrganizationAction,
  clearRecruitersInOrganizationAction,
  setMyRecruitersAction,
  clearMyRecruitersAction,
  setRecruitersPendingToOrganizationListAction,
  setCurrentRecruitersLimitedListAction,
  setCurrentRecruitersLimitedListCounterAction,
  clearCurrentRecruitersLimitedListAction,
  clearCurrentRecruitersLimitedListCounterAction,
} from './actions';

const initialState = {
  currentRecruiter: {},
  currentRecruitersList: [],
  currentRecruitersLimitedList: [],
  currentRecruitersLimitedListCounter: 0,
  recruitersInProject: [],
  recruitersInOrganization: {},
  myRecruiters: {},
  recruitersPendingToOrganizationList: [],
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setCurrentRecruiterAction, (state, action) => {
      state.currentRecruiter = action.payload;
    })
    .addCase(clearCurrentRecruiterAction, state => {
      state.currentRecruiter = initialState.currentRecruiter;
    })
    .addCase(setCurrentRecruitersListAction, (state, action) => {
      state.currentRecruitersList = action.payload;
    })
    .addCase(clearCurrentRecruitersListAction, state => {
      state.currentRecruitersList = initialState.currentRecruitersList;
    })
    .addCase(setRecruitersInProjectAction, (state, action) => {
      state.recruitersInProject = action.payload;
    })
    .addCase(clearRecruitersInProjectAction, state => {
      state.recruitersInProject = initialState.recruitersInProject;
    })
    .addCase(setRecruitersInOrganizationAction, (state, action) => {
      state.recruitersInOrganization = action.payload;
    })
    .addCase(clearRecruitersInOrganizationAction, state => {
      state.recruitersInOrganization = initialState.recruitersInOrganization;
    })
    .addCase(setMyRecruitersAction, (state, action) => {
      state.myRecruiters = action.payload;
    })
    .addCase(clearMyRecruitersAction, state => {
      state.myRecruiters = initialState.myRecruiters;
    })
    .addCase(setRecruitersPendingToOrganizationListAction, (state, action) => {
      state.recruitersPendingToOrganizationList = action.payload;
    })
    .addCase(setCurrentRecruitersLimitedListAction, (state, action) => {
      state.currentRecruitersLimitedList = action.payload;
    })
    .addCase(clearCurrentRecruitersLimitedListAction, state => {
      state.currentRecruitersLimitedList =
        initialState.currentRecruitersLimitedList;
    })
    .addCase(setCurrentRecruitersLimitedListCounterAction, (state, action) => {
      state.currentRecruitersLimitedListCounter = action.payload;
    })
    .addCase(clearCurrentRecruitersLimitedListCounterAction, state => {
      state.currentRecruitersLimitedListCounter =
        initialState.currentRecruitersLimitedListCounter;
    })
);
