import { Tabs, Tab, withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { Translate } from 'shared/newComponents';
import { getProjectRecruiterConfirmationStatus } from 'shared/services';
import { styles } from '../NewProjectShowView/components/ProjectShowViewContent/ProjectShowViewContent.styles';

export const ProjectMenu = ({
  match,
  getProjectRecruiterConfirmationStatus,
  currentUser = {},
  projectDetails,
  projectDetailsOutStore,
  classes,
  t,
  history,
  title,
  subtitle = null,
}) => {
  const [index, setIndex] = useState(0);

  const getProjectRecruiterConfirmationStatusFunc = async () => {
    try {
      if (match.params.id !== undefined) {
        await getProjectRecruiterConfirmationStatus(
          match.params.id,
          currentUser.id
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getIndexMenu = () => {
    switch (match.path) {
      case PATHS.PROJECT.NEW_SHOW:
        return 0;
      case PATHS.PROJECT.ATS:
        return 1;
      case PATHS.PROJECT.ATS_EMPLOYMENT_PROCESS:
        return 1;
      case PATHS.PROJECT.ATS_EMPLOYMENT_MANAGEMENT:
        return 1;
      case PATHS.PROJECT.RECRUITERS:
        return 2;
      case PATHS.PROJECT.CANDIDATES:
        return 3;
      case PATHS.PROJECT.PROJECT_ORDERS:
        return 4;
      case PATHS.PROJECT.PAYMENTS:
        return 5;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const index = getIndexMenu();
    setIndex(index);
    getProjectRecruiterConfirmationStatusFunc();
  }, []);
  const projectSource = projectDetailsOutStore
    ? projectDetailsOutStore
    : projectDetails;
  const { permissions = {} } = projectSource;
  const {
    isOwner = false,
    isCooperator = false,
    isMentor = false,
    isSuperUser = false,
    isHiringManager = false,
    isProjectOrganizationOwner = false,
    statusInProject = 0,
  } = permissions;

  const projectId = match.params.id;
  const isConnectedWithProject =
    isOwner ||
    (isCooperator && (statusInProject === 5 || statusInProject === 2)) ||
    isMentor ||
    isSuperUser ||
    isHiringManager;

  const menuProjectElements = [
    {
      name: t('project:menu.details.info'),
      url: PATHS.PROJECT.NEW_SHOW.replace(':id', projectId),
      accessCandidate: true,
      accessRecruiter: true,
    },
    {
      name: t('project:menu.details.ats'),
      url: PATHS.PROJECT.ATS.replace(':id', projectId),
      accessCandidate: isCooperator,
      accessRecruiter: isConnectedWithProject || isProjectOrganizationOwner,
    },
    {
      name: t('project:menu.details.recruiters'),
      url: PATHS.PROJECT.RECRUITERS.replace(':id', projectId),
      accessCandidate: false,
      accessRecruiter: isOwner || isMentor || isHiringManager || isSuperUser,
    },
    {
      name: t('project:menu.details.candidates'),
      url: PATHS.PROJECT.CANDIDATES.replace(':id', projectId),
      accessCandidate: false,
      accessRecruiter: isConnectedWithProject,
    },
    {
      name: t('project:menu.details.orders'),
      url: PATHS.PROJECT.PROJECT_ORDERS.replace(':id', projectId),
      accessCandidate: false,
      accessRecruiter: isOwner || isMentor || isHiringManager || isSuperUser,
    },
    {
      name: t('project:menu.details.payments'),
      url: PATHS.PROJECT.PAYMENTS.replace(':id', projectId),
      accessCandidate: false,
      accessRecruiter:
        isOwner || (isConnectedWithProject && !isMentor && !isHiringManager),
    },
  ];

  const subMenu = menuProjectElements.map((element, key) => {
    const projectSource = projectDetailsOutStore
      ? projectDetailsOutStore
      : projectDetails;

    /*Candidate access*/
    let getAccess = true;
    const { isRecruiter } = currentUser;
    if (!isRecruiter) {
      if (!element.accessCandidate) {
        getAccess = false;
      }
    }

    let disabled = false;
    if (projectSource.id === undefined) {
      disabled = true;
    }

    const { accessRecruiter } = element;
    let controlButtonDisabled = false;
    !accessRecruiter && !disabled
      ? (controlButtonDisabled = true)
      : (controlButtonDisabled = false);

    return (
      getAccess && (
        <Tab
          key={key}
          component={Link}
          to={element.url}
          disabled={controlButtonDisabled}
          style={controlButtonDisabled ? { display: 'none' } : null}
          label={element.name}
          classes={{
            root: !accessRecruiter ? classes.buttonInActive : classes.button,
            label: classes.label,
          }}
        />
      )
    );
  });

  const handleTabChange = (_, index) => {
    const projectSource = projectDetailsOutStore
      ? projectDetailsOutStore
      : projectDetails;

    switch (index) {
      case 0: {
        history.push(PATHS.PROJECT.NEW_SHOW.replace(':id', projectSource.id));
        break;
      }
      case 1: {
        history.push(PATHS.PROJECT.ATS.replace(':id', projectSource.id));
        break;
      }
      case 2: {
        history.push(PATHS.PROJECT.RECRUITERS.replace(':id', projectSource.id));
        break;
      }
      case 3: {
        history.push(PATHS.PROJECT.CANDIDATES.replace(':id', projectSource.id));
        break;
      }
      case 4: {
        history.push(
          PATHS.PROJECT.PROJECT_ORDERS.replace(':id', projectSource.id)
        );
        break;
      }
      case 5: {
        history.push(PATHS.PROJECT.PAYMENTS.replace(':id', projectSource.id));
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Grid container className={classes.wrapper}>
      <Grid
        container
        style={{ cursor: 'pointer' }}
        onClick={() => {
          history.goBack();
        }}
      >
        <Translate
          text="projectDetails:content.back"
          bold
          underline
          variant="body1"
          className={classes.link}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: '8px 0 16px 0' }}
      >
        <Grid item>
          <Translate style={{ fontSize: 42 }} text={title} variant="h4" bold />
          {subtitle ? (
            <Translate
              style={{ fontSize: 20, color: '#6A6F76' }}
              text={subtitle}
              disableTranslate={true}
              variant="h6"
              bold
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid container>
        <Grid container className={classes.tabsWrapper}>
          <Tabs
            value={index}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator,
              scrollButtons: classes.scrollButtons,
              flexContainer: classes.scroll,
            }}
          >
            {subMenu}
          </Tabs>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    projectDetails: store.projects.currentProject,
  };
};

const mapDispatchToProps = {
  getProjectRecruiterConfirmationStatus,
};

ProjectMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  getProjectRecruiterConfirmationStatus: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(ProjectMenu))));
