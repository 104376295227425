import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AddFeedbackPanelForm from '../../../components/AddFeedbackPanelForm/AddFeedbackPanelForm';
import {
  FeedbackRejectReasonService,
  getCandidateInProject,
  getProjectCandidates,
} from '../../services';
import { styles } from './ProjectAtsCandidateInProjectFeedbackFromEmployer.styles';
import EmployerFeedback from './components/EmployerFeedback';
import EmployerReview from './components/EmployerReview';
import ProjectAtsCandidateInProjectFeedbackFromEmployerHeader from './components/ProjectAtsCandidateInProjectFeedbackFromEmployerHeader';

const ProjectAtsCandidateInProjectFeedbackFromEmployer = ({
  candidate,
  readOnly = false,
  classes,
  t,
  ...props
}) => {
  const [openFeedbackPanel, setOpenFeedbackPanel] = useState(false);
  const [projectCandidate, setProjectCandidate] = useState(null);
  const [feedbackRejectReason, setFeedbackRejectReason] = useState([]);

  useEffect(() => {
    const projectCandidate = props.currentCandidatesList.find(
      item =>
        item.project.id === props.currentProject.id &&
        item.candidate.id === candidate.id
    );
    setProjectCandidate(projectCandidate);
    FeedbackRejectReasonService.getFeedbackRejectReasons()
      .then(({ data }) => {
        setFeedbackRejectReason(data);
      })
      .catch(error => {
        console.log(error);
        alert(t`addOrganizationForm:error`);
      });
  }, [candidate]);

  const onClickEditFeedback = () => {
    setOpenFeedbackPanel(true);
  };

  const onCloseFeedbackPanel = () => {
    setOpenFeedbackPanel(false);
  };

  if (!projectCandidate) {
    return null;
  }

  const feedbackAdded = () => {
    onCloseFeedbackPanel();
    props.getCandidateInProject(projectCandidate.project.id, candidate.id);
    props.getProjectCandidates(projectCandidate.project.id);
  };

  const { permissions = {} } = props.currentProject;

  return (
    <Grid container className={classes.wrapper} style={{ width: '100%' }}>
      <Paper style={{ width: '100%', borderRadius: 20 }}>
        <Grid container>
          <Grid
            container
            style={{
              padding: '30px 30px 30px 30px',
              gridArea: 'left',
            }}
          >
            <ProjectAtsCandidateInProjectFeedbackFromEmployerHeader
              showEditBtn={
                (permissions.isHiringManager ||
                  permissions.isOwner ||
                  permissions.isSuperUser) &&
                !readOnly
              }
              onClickEditFeedback={onClickEditFeedback}
            />
          </Grid>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={classes.bluePrimaryBackground}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                style={{
                  padding: '30px 30px 30px 30px',
                  height: '100%',
                }}
              >
                <EmployerReview projectCandidate={projectCandidate} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} style={{ padding: '0px 25px' }}>
            <Grid
              container
              style={{
                padding: '30px 30px 30px 30px',
              }}
            >
              <EmployerFeedback projectCandidate={projectCandidate} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <AddFeedbackPanelForm
        feedbackRejectReason={feedbackRejectReason}
        projectCandidate={projectCandidate}
        feedbackAdded={feedbackAdded}
        isOpen={openFeedbackPanel}
        onClose={onCloseFeedbackPanel}
      />
    </Grid>
  );
};

const mapDispatchToProps = {
  getCandidateInProject,
  getProjectCandidates,
};
const mapStateToProps = store => {
  return {
    currentCandidatesList: store.candidates.currentCandidatesList,
    currentProject: store.projects.currentProject,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    withTranslation()(ProjectAtsCandidateInProjectFeedbackFromEmployer)
  )
);
