import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { get, toLower, find, has, take } from 'lodash';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { Translate } from '..';
import { styles } from './AutocompleteFreeSolo.style';

const Autocomplete = ({
  items,
  classes,
  label,
  formik,
  name,
  onComponentChange,
  limit = null,
  disabled = false,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(get(formik.values, name));
  }, [get(formik.values, name)]);

  const getSuggestions = value => {
    const inputValue = toLower(value.value);
    const inputLength = inputValue.length;
    return inputLength === 0
      ? items
      : items.filter(item => {
          return toLower(item).indexOf(inputValue) !== -1;
        });
  };
  const onChange = (_, { newValue }) => {
    const newValueObj = newValue.trim().length ? newValue : undefined;

    const selectedItem = find(items, item => item === newValue);
    if (selectedItem) {
      formik.setFieldValue(name, selectedItem);
    } else {
      formik.setFieldValue(name, newValueObj);
    }
    if (onComponentChange) {
      onComponentChange();
    }
    setValue(newValueObj);
  };
  const onSuggestionsFetchRequested = value => {
    const filteredSuggestions = limit
      ? take(getSuggestions(value), limit)
      : getSuggestions(value);

    setSuggestions(filteredSuggestions);
  };
  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion}
      </MenuItem>
    );
  };
  const error = has(formik.touched, name) && Boolean(get(formik.errors, name));

  return (
    <Grid
      container
      className={error ? classes.containerError : classes.container}
    >
      <FormControl fullWidth error={error} variant="outlined">
        {value && (
          <div className={classes.floatingLabel}>
            <Translate text={label} variant="caption" />
          </div>
        )}
        <Autosuggest
          freeSolo
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={suggestion => suggestion}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={() => true}
          inputProps={{
            placeholder: label,
            value: value || '',
            onChange,
            disabled: disabled,
          }}
        />
        {error && (
          <FormHelperText>
            {has(formik.touched, name) && get(formik.errors, name)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(Autocomplete);
