import { Card, CardHeader, Avatar, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getProjectAssetSrcByType } from 'shared/utils/assets';

const styles = () => ({
  avatarOrganization: {
    width: 100,
    height: 100,
    borderRadius: 0,
  },
  avatarRecruiter: {
    width: 70,
    height: 70,
  },
  titleCompany: {
    color: '#000',
    fontSize: '22px',
    fontWeight: '600',
  },
});

export const SummaryLeftDescription = props => {
  const { classes, params, currentUser } = props;
  const { organization = {} } = currentUser;

  let objectDescription = null;
  let logotypeType = '';
  if (params === 'organization') {
    objectDescription = organization;
    logotypeType = 'logo';
  } else {
    objectDescription = currentUser;
    logotypeType = 'avatar';
  }

  const AtsLogo = getProjectAssetSrcByType(objectDescription, logotypeType);

  return (
    <Card elevation={0}>
      <CardHeader
        style={{ padding: 0 }}
        avatar={
          <Avatar
            src={AtsLogo}
            className={
              params === 'organization'
                ? classes.avatarOrganization
                : classes.avatarRecruiter
            }
          />
        }
        title={
          params === 'organization'
            ? objectDescription.name
            : objectDescription.firstName + ' ' + objectDescription.lastName
        }
        subheader={
          (params !== 'organization' && organization && organization.name) ||
          objectDescription.email
        }
        classes={{
          title: classes.titleCompany,
        }}
      />
    </Card>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

SummaryLeftDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(SummaryLeftDescription)));
