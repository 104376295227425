import { Grid, withStyles } from '@material-ui/core';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import AddCareerPanelForm from '../../../../components/AddCareerPanelForm/AddCareerPanelForm';
import { getCandidateInProject, getProjectCandidates } from '../../../services';
import Translate from '../../Translate';
import CareerItemsPanel from './CareerItemsPanel';
import CareerTextContent from './CareerTextContent';
import { styles } from './PersonalData.style';

const CareerPanel = ({
  candidate,
  industryCategories,
  t,
  classes,
  projectCandidate,
  ...props
}) => {
  const [openCareerPanel, setOpenCareerPanel] = useState(false);

  const handleOpenEditCareerPanel = () => {
    setOpenCareerPanel(true);
  };
  const careerPanelAdded = () => {
    setOpenCareerPanel(false);
    props.getCandidateInProject(projectCandidate.project.id, candidate.id);
    props.getProjectCandidates(projectCandidate.project.id);
  };

  const onClose = () => {
    setOpenCareerPanel(false);
  };

  if (!projectCandidate) {
    return null;
  }

  const { permissions } = props.currentProject;
  return (
    <Grid container style={{ padding: '15px 0px' }}>
      <Grid container>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            style={{
              padding: '30px 30px 30px 30px',
              gridArea: 'left',
            }}
          >
            <Translate
              text={'newAts:careerPanelView.careerComponentTitle'}
              bold
              style={{ fontSize: 26 }}
            />
          </Grid>
          {permissions.isOwner ||
          permissions.isMentor ||
          permissions.isSuperUser ||
          projectCandidate.owner.id === props.currentUser.id ? (
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              onClick={handleOpenEditCareerPanel}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img alt="EditIcon" src={EditIcon} height={50} />
            </Grid>
          ) : null}
        </Grid>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            style={{
              padding: '30px 30px 30px 30px',
              gridArea: 'left',
            }}
          >
            <Translate
              text={'newAts:careerPanelView.careerTitle'}
              bold
              style={{ fontSize: 26 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CareerItemsPanel
            candidate={candidate}
            projectCandidate={projectCandidate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CareerTextContent projectCandidate={projectCandidate} />
        </Grid>
      </Grid>
      <AddCareerPanelForm
        isOpen={openCareerPanel}
        onClose={onClose}
        candidate={candidate}
        careerPanelAdded={careerPanelAdded}
        industryCategories={industryCategories}
        projectCandidate={projectCandidate}
      />
    </Grid>
  );
};

const mapDispatchToProps = {
  getCandidateInProject,
  getProjectCandidates,
};
const mapStateToProps = store => {
  return {
    currentCandidatesList: store.candidates.currentCandidatesList,
    currentProject: store.projects.currentProject,
    currentUser: store.auth.currentUser,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(CareerPanel)));
