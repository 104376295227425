import axios from 'axios';
import { API, API_URL, API_URL_RESOURCE } from '../constants';
import { getLocalStorageByKey } from '../utils/localStorage';

export const uploadAssets = (files, type) => {
  return async dispatch => {
    let data = new FormData();
    const oldFiles = [];
    const newFiles = [];
    files.forEach((file, i) => {
      if (!file.id) {
        data.append('file[' + i + ']', files[i]);
        newFiles.push(file);
      } else {
        oldFiles.push(file);
      }
    });
    if (!newFiles.length) {
      return { data: oldFiles };
    }
    return await axios({
      method: 'POST',
      url: type
        ? API_URL + API.POST.ASSET.UPLOAD + '/' + type
        : API_URL + API.POST.ASSET.UPLOAD,
      data: data,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return {
          data: [...data, ...oldFiles],
          status,
        };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: undefined, status };
      });
  };
};

export const uploadCsv = (files, type) => {
  return async dispatch => {
    const formData = new FormData();
    files.forEach((file, i) => {
      if (!file.id) {
        formData.append('file[' + i + ']', files[i]);
      }
    });
    const response = await axios({
      method: 'POST',
      url: type
        ? API_URL + API.POST.ASSET.UPLOAD + '/' + type
        : API_URL + API.POST.ASSET.UPLOAD,
      data: formData,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    });
    const { data, status } = response;
    return { data, status };
  };
};

export const checkCandidates = async files => {
  const formData = new FormData();
  files.forEach((file, i) => {
    if (!file.id) {
      formData.append('file[' + i + ']', files[i]);
    }
  });
  const { data } = await axios({
    method: 'POST',
    url: API_URL + API.POST.ASSET.CHECK_CANDIDATE,
    data: formData,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });

  return data;
};

export const uploadCandidatesFromImport = async files => {
  const { data } = await axios({
    method: 'POST',
    url: API_URL + API.POST.ASSET.DATA_FROM_IMPORT,
    data: files,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });

  return data;
};

export const getAssets = () => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ASSET.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getProjectAssets = id => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ASSET.GET_PROJECT_ASSETS.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getFile = asset => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'GET',
      url: API_URL + '/api/resource/' + asset.id,
      responseType: 'arraybuffer',
    })
      .then(response => {
        const { data, status } = response;
        let file = new File([Blob([data])], asset.filename, {
          type: asset.filetype,
        });
        file.preview = API_URL_RESOURCE + asset.id;
        return { file, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { file: null, status };
      });
  };
};

export const getAsset = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ASSET.GET + '/' + id,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getAssetUrl = asset => {
  if (asset === undefined) {
    return null;
  }
  return `${API_URL}/api/resource/${asset}`;
};

export const deleteAsset = id => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url: API_URL + API.DELETE.ASSET.DELETE + '/' + id,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
