import axios from 'axios';
import { API, API_URL } from '../constants';
import { getAllLanguagesAction } from '../store/languages/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getLanguages = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.LANGUAGE.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllLanguagesAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const addLanguage = name => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.LANGUAGE.ADD,
      data: { name },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllLanguagesAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
