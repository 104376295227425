import { MenuItem, Paper, TextField, withStyles } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { changeFieldValue } from 'shared/services/formService';

const styles = theme => ({
  suggestionsContainerOpen: {
    overflow: 'scroll',
    maxHeight: '300px',
    position: 'absolute',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: '9999 !important',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    width: 'fit-content',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    background: '#fff',
  },
  suggestionsContainer: {
    flexGrow: 1,
    position: 'relative',
  },
});

export class AutoSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      timer: null,
      value: null,
    };
  }

  componentDidMount() {
    this.setState({
      suggestions: this.props.suggestionsList ? this.props.suggestionsList : [],
    });
  }

  onChangeHandler = (e, { newValue }) => {
    const { formName, fieldName } = this.props;
    this.props.changeFieldValue(formName, fieldName, newValue);
    if (!this.props.onChange) {
      return;
    }
    this.props.onChange(formName, fieldName, newValue, this.props.index);
  };

  timeoutHandle = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const result =
      inputLength === 0
        ? this.props.suggestionsList
        : this.props.suggestionsList.filter(
            lang => lang.name.toLowerCase().indexOf(inputValue) > -1
          );
    this.setState({
      suggestions: result.slice(0, 100),
    });
  };
  getSuggestions = value => {
    this.setState({
      value,
    });
    this.timeoutHandle(value);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.name}
      </MenuItem>
    );
  };

  shouldRenderSuggestions = (value, reason) => {
    if (value.trim().length > 0) {
      return true;
    }
    return !!(value.trim().length === 0 && this.props.showListOnClick);
  };

  renderInput = ({
    input,
    suggestions,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    fieldLabel,
  }) => (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{ ...input, onChange: this.onChangeHandler }}
      renderInputComponent={inputProps => (
        <TextField {...inputProps} label={fieldLabel} fullWidth />
      )}
    />
  );

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  getSuggestionValue = suggestion => suggestion.name;

  render() {
    const { suggestions } = this.state;
    const {
      classes,
      form: { values: formValues = [] },
      fieldName,
      fieldLabel,
    } = this.props;

    return (
      <Field
        name={fieldName}
        component={this.renderInput}
        suggestions={suggestions}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        fieldLabel={fieldLabel}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    form:
      _.find(
        state.form,
        (obj, key) =>
          obj.registeredFields && obj.registeredFields[props.fieldName]
      ) || {},
    formName: _.findKey(
      state.form,
      (obj, key) =>
        obj.registeredFields && obj.registeredFields[props.fieldName]
    ),
  };
};

const mapDispatchToProps = {
  changeFieldValue,
};

AutoSuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestionsList: PropTypes.array.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AutoSuggest));
