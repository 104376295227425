export const styles = () => ({
  paper: { padding: 25, marginBottom: 25 },
  header: { marginBottom: 25 },
  doc: { borderBottom: '1px solid #D5D6D8', padding: '20px 0' },
  lastDoc: { padding: '20px 0' },
  docName: { opacity: '70%' },
  bold: { fontWeight: 'bold' },
  left: { color: '#F41313', paddingLeft: 25 },
  cloud: { cursor: 'pointer' },
});
