import axios from 'axios';
import _ from 'lodash';
import moment from 'moment/moment';
import { push } from 'react-router-redux';
import { PATHS } from 'config';
import { API, API_URL } from '../constants';
import {
  setMyCandidatesAction,
  setCandidatesInProjectAction,
  setCurrentCandidatesListAction,
  setCurrentCandidateAction,
} from '../store/candidates/actions';
import {
  setCurrentProjectAction,
  clearCurrentProjectAction,
  setCurrentProjectsListAction,
  setCurrentProjectsLimitedListAction,
  clearCurrentProjectsLimitedListAction,
  setCurrentProjectsLimitedListCounterAction,
  clearCurrentProjectsLimitedListCounterAction,
  clearCurrentProjectsListAction,
  setProjectRecruiterConfirmationStatusAction,
  setProjectCandidateConfirmationStatusAction,
  clearProjectRecruiterConfirmationStatusAction,
  clearProjectCandidateConfirmationStatusAction,
  setProjectsPendingToRecruiterListAction,
  clearProjectsPendingToRecruiterListAction,
  setProjectsPendingToCandidateListAction,
} from '../store/projects/actions';
import {
  setCurrentRecruitersListAction,
  setMyRecruitersAction,
  setRecruitersInProjectAction,
} from '../store/recruiters/actions';
import { getLocalStorageByKey } from '../utils/localStorage';
import { getCandidatesWithLimit } from './candidateService';
import { getRecruitersWithLimit } from './recruiterService';

export const clearCurrentProject = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentProjectAction());
  };
};

export const clearCurrentProjectsList = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentProjectsListAction());
  };
};

export const clearProjectRecruiterConfirmationStatus = () => {
  return async (dispatch, getState) => {
    dispatch(clearProjectRecruiterConfirmationStatusAction());
  };
};

export const clearProjectCandidateConfirmationStatus = () => {
  return async (dispatch, getState) => {
    dispatch(clearProjectCandidateConfirmationStatusAction());
  };
};

export const clearProjectsPendingToRecruiter = () => {
  return async (dispatch, getState) => {
    dispatch(clearProjectsPendingToRecruiterListAction());
  };
};

export const clearCurrentProjectsLimitedList = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentProjectsLimitedListAction());
  };
};

export const clearCurrentProjectsLimitedListCounter = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentProjectsLimitedListCounterAction());
  };
};

export const getProject = (id, params = { fields: 'owner' }) => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET + '/' + id,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: params,
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentProjectAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const getProjects = params => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: params ? params : { withPublic: true },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentProjectsListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
export const getProjectsPoWithLimit = (limit, offset, params, recruiterId) => {
  return async dispatch => {
    await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        limit: limit,
        offset: offset,
        poOnly: true,
        recruiter: true,
        forUser: recruiterId,
        ...params,
      },
    })
      .then(response => {
        const { data } = response;
        dispatch(setCurrentProjectsLimitedListAction(data));
      })
      .catch(error => {
        return {};
      });
  };
};

export const getProjectsPoAndCoWithLimit = (
  limit,
  offset,
  params,
  candidateId
) => {
  return async dispatch => {
    let projectsPo = [];
    let projectsCo = [];
    let projectsPoCO = [];
    const getProjectsPo = async () => {
      projectsPo = await axios({
        method: 'GET',
        url: API_URL + API.GET.PROJECT.GET,
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
        params: {
          limit: limit,
          offset: offset,
          poOnly: true,
          forUser: candidateId,

          newCreator: true,
          ...params,
        },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    const getProjectsCo = async () => {
      projectsCo = await axios({
        method: 'GET',
        url: API_URL + API.GET.PROJECT.GET,
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
        params: {
          limit: limit,
          offset: offset,
          coOnly: true,
          forUser: candidateId,

          newCreator: true,
          ...params,
        },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    await Promise.all([getProjectsPo(), getProjectsCo()]);
    projectsPoCO = _.concat(projectsPo, projectsCo);
    dispatch(setCurrentProjectsLimitedListAction(projectsPoCO));
  };
};

export const getProjectsWithLimit = (limit, offset, params) => {
  return async dispatch => {
    axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        count: true,
        ...params,
      },
    })
      .then(response => {
        const {
          data,
          data: { count },
          status,
        } = response;
        dispatch(setCurrentProjectsLimitedListCounterAction(count));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        limit: limit,
        offset: offset,
        ...params,
      },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentProjectsLimitedListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getProjectsCount = params => {
  return async dispatch => {
    return axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        count: true,
        ...params,
      },
    })
      .then(response => {
        const {
          data,
          data: { count },
          status,
        } = response;
        return { data, status, count };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getProjectsPendingToRecruiter = params => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: { ...params },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectsPendingToRecruiterListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getProjectsPendingToCandidate = params => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: { ...params },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectsPendingToCandidateListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const addProject = values => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.PROJECT.ADD,
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentProjectAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};
export const editProjectNonDispatch = values => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.PROJECT.EDIT + '/' + values.id,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  })
    .then(response => {
      const { data, status } = response;
      return { data, status };
    })
    .catch(error => {
      const { status } = error.response;
      return { status };
    });
};

export const editProject = values => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.PROJECT.EDIT + '/' + values.id,
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        // dispatch(clearCurrentProjectAction());
        // dispatch(setCurrentProjectAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const deleteProject = id => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url: API_URL + API.DELETE.PROJECT.DELETE.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getCandidateInProject = (id, candidateId) => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url:
        API_URL +
        API.GET.PROJECT.CANDIDATE.CONFIRMATION.replace(':id', id).replace(
          ':candidateId',
          candidateId
        ),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidateAction(data.candidate));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

/*Project candidates*/
export const getProjectCandidates = projectId => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.CANDIDATE.GET.replace(':id', projectId),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidatesListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const getProjectCandidatesFilteredForAdding = (
  projectId,
  candidatesParams = {}
) => {
  return async dispatch => {
    let allCandidates = [];
    let myCandidates = [];
    let projectCandidates = [];

    const getProjectCandidates = async () => {
      projectCandidates = await axios({
        method: 'GET',
        url: API_URL + API.GET.PROJECT.CANDIDATE.GET.replace(':id', projectId),
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    const getAllCandidates = async () => {
      allCandidates = await axios({
        method: 'GET',
        url: API_URL + API.GET.USER.GET,
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
        params: { type: 'candidate', ...candidatesParams },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    await Promise.all([getAllCandidates(), getProjectCandidates()]);

    myCandidates = _.differenceBy(
      allCandidates,
      projectCandidates.map(ca => {
        return { id: ca.candidate.id };
      }),
      'id'
    );

    dispatch(setMyCandidatesAction(myCandidates));
    dispatch(setCandidatesInProjectAction(projectCandidates));
    return [myCandidates, projectCandidates];
  };
};

export const getProjectCandidatesFilteredForAddingAsyncList = (
  projectId,
  limit,
  offset,
  params,
  tabPage
) => {
  return async (dispatch, getState) => {
    const myCandidates = await getCandidatesWithLimit(limit, offset, {
      withPublic: true,
      ...params,
      filters: { notInProject: projectId, ...params.filters },
    })(dispatch, getState).then(data => data);
    const projectCandidates = await getProjectCandidates(projectId)(
      dispatch,
      getState
    ).then(data => data);

    await Promise.all([myCandidates, projectCandidates]);

    dispatch(setMyCandidatesAction(myCandidates));
    dispatch(setCandidatesInProjectAction(projectCandidates));

    return {
      myCandidates,
      projectCandidates,
      tabPage,
    };
  };
};

export const getProjectRecruitersFilteredForAddingAsyncList = (
  projectId,
  limit,
  offset,
  params
) => {
  return async (dispatch, getState) => {
    const myRecruiters = await getRecruitersWithLimit(limit, offset, {
      withPublic: true,
      ...params,
      filters: { notInProject: projectId, ...params.filters },
    })(dispatch, getState).then(data => data);
    const projectRecruiters = await getProjectRecruiters(projectId)(
      dispatch,
      getState
    ).then(data => data);

    await Promise.all([myRecruiters, projectRecruiters]);

    dispatch(setMyRecruitersAction(myRecruiters));
    dispatch(setRecruitersInProjectAction(projectRecruiters));

    return {
      myRecruiters,
      projectRecruiters,
    };
  };
};

export const getProjectRecruitersFilteredForAdding = (
  projectId,
  recruitersParams = {}
) => {
  return async (dispatch, getState) => {
    let allRecruiters = [];
    let myRecruiters = [];
    let projectRecruiters = [];

    const getProjectRecruiters = async () => {
      projectRecruiters = await axios({
        method: 'GET',
        url: API_URL + API.GET.PROJECT.RECRUITER.GET.replace(':id', projectId),
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    const getRecruiters = async () => {
      allRecruiters = await axios({
        method: 'GET',
        url: API_URL + API.GET.USER.GET,
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
        params: { type: 'recruiter', ...recruitersParams },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    await Promise.all([getRecruiters(), getProjectRecruiters()]);

    myRecruiters = updateMyRecruitersFilteredForAdding(
      dispatch,
      getState,
      allRecruiters,
      projectRecruiters
    );
    dispatch(setRecruitersInProjectAction(projectRecruiters));
    dispatch(setCurrentRecruitersListAction(allRecruiters));
    return [myRecruiters, projectRecruiters];
  };
};

const updateMyRecruitersFilteredForAdding = (
  dispatch,
  getState,
  allRecruiters,
  projectRecruiters
) => {
  const {
    auth: { currentUser },
  } = getState();
  let filteredRecruiters = projectRecruiters.map(pr => {
    return { id: pr.recruiter.id };
  });
  filteredRecruiters.push({ id: currentUser.id });
  const myRecruiters = _.differenceBy(allRecruiters, filteredRecruiters, 'id');
  dispatch(setMyRecruitersAction(myRecruiters));
  return myRecruiters;
};

/*Add candidate to project*/
export const addProjectCandidate = (
  projectId,
  candidateId,
  fromRecommend = false,
  selectedProjectOrder = null
) => {
  const data = { candidateId: candidateId };
  if (fromRecommend) {
    data['fromRecommend'] = true;
  }
  if (selectedProjectOrder) {
    data['projectOrderId'] = selectedProjectOrder.id;
  }
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.PROJECT.CANDIDATE.ADD.replace(':id', projectId),
      data: data,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Add recruiter to project*/
export const addProjectRecruiter = (projectId, recruiterId) => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.PROJECT.RECRUITER.ADD.replace(':id', projectId),
      data: { recruiterId: recruiterId },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Delete candidate from project*/
export const deleteProjectCandidate = (projectId, candidateId) => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url:
        API_URL +
        API.DELETE.PROJECT.CANDIDATE.DELETE.replace(':id', projectId).replace(
          ':candidateID',
          candidateId
        ),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Project recruiters*/
export const getProjectRecruiters = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.RECRUITER.GET.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentRecruitersListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Project cv hashes*/
export const getProjectCvHashes = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.HASHES.GET.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editProjectStageCandidates = (projectId, values) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.PROJECT.CANDIDATE.EDIT.replace(':id', projectId),
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentCandidatesListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Delete recruiter from project*/
export const deleteProjectRecruiter = (projectId, recruiterId) => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url:
        API_URL +
        API.DELETE.PROJECT.RECRUITER.DELETE.replace(':id', projectId).replace(
          ':recruiterID',
          recruiterId
        ),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Project recruiter confirmation status*/
export const getProjectRecruiterConfirmationStatus = (
  projectId,
  recruiterId
) => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url:
        API_URL +
        API.GET.PROJECT.RECRUITER.CONFIRMATION.replace(
          ':id',
          projectId
        ).replace(':recruiterId', recruiterId),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectRecruiterConfirmationStatusAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Project candidate confirmation status*/
export const getProjectCandidateConfirmationStatus = (
  projectId,
  candidateId
) => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url:
        API_URL +
        API.GET.PROJECT.CANDIDATE.CONFIRMATION.replace(
          ':id',
          projectId
        ).replace(':candidateId', candidateId),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectCandidateConfirmationStatusAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Accept invitation*/
export const acceptInvitationProjectRecruiter = (
  projectId,
  recruiterId,
  acceptationId
) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.RECRUITER.CONFIRMATION.replace(
          ':id',
          projectId
        ).replace(':recruiterId', recruiterId),
      data: { confirmationStatus: acceptationId },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectRecruiterConfirmationStatusAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Reject invitation*/
export const rejectInvitationProjectRecruiter = (
  projectId,
  recruiterId,
  rejectionId
) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.RECRUITER.CONFIRMATION.replace(
          ':id',
          projectId
        ).replace(':recruiterId', recruiterId),
      data: { confirmationStatus: rejectionId },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Accept invitation Candidate*/
export const acceptInvitationProjectCandidate = (projectId, candidateId) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.CANDIDATE.CONFIRMATION.replace(':id', projectId),
      data: [
        {
          stageNumber: 2,
          candidateId: candidateId,
        },
      ],
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectCandidateConfirmationStatusAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Accept invitation Candidate as Recruiter*/
export const acceptInvitationProjectCandidateAsRecruiter = (
  projectId,
  candidateId
) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.CANDIDATE.CONFIRMATION.replace(':id', projectId),
      data: [
        {
          candidateId: candidateId,
        },
      ],
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectCandidateConfirmationStatusAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Reject invitation Candidate as Recruiter*/
export const rejectInvitationProjectCandidateAsRecruiter = (
  projectId,
  candidateId
) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.CANDIDATE.CONFIRMATION.replace(':id', projectId),
      data: [
        {
          isRejected: true,
          candidateId: candidateId,
        },
      ],
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setProjectCandidateConfirmationStatusAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Reject invitation Candidate*/
export const rejectInvitationProjectCandidate = (projectId, candidateId) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.CANDIDATE.CONFIRMATION.replace(':id', projectId),
      data: [
        {
          isRejected: true,
          candidateId: candidateId,
        },
      ],
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Change candidate owner in project*/
export const changeCandidateOwnerInProject = (
  projectId,
  ownerId,
  candidateId
) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.CANDIDATE.CHANGE_CANDIDATE_IN_PROJECT_OWNER.replace(
          ':id',
          projectId
        ).replace(':candidateID', candidateId),
      data: {
        ownerId: ownerId,
      },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Modify brief by recruiter*/
export const modifyCandidateInProject = (projectId, candidateId, data) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.PROJECT.CANDIDATE.CHANGE_CANDIDATE_IN_PROJECT_OWNER.replace(
          ':id',
          projectId
        ).replace(':candidateID', candidateId),
      data: data,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editProjectLanguages = (projectId, values) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.PROJECT.LANGUAGE.EDIT.replace(':id', projectId),
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editProjectSkills = (projectId, values) => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.PROJECT.SKILL.EDIT.replace(':id', projectId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const getDashboardProjectsCounters = () => {
  return async dispatch => {
    const poProjectsFn = axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        count: true,
        poOnly: true,
      },
    })
      .then(response => {
        const {
          data,
          data: { count },
          status,
        } = response;
        dispatch(setCurrentProjectsLimitedListCounterAction(count));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });

    const coAndMentorProjectsFn = axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        count: true,
        coAndMentorOnly: true,
        confirmationStatus: '2,5',
      },
    })
      .then(response => {
        const {
          data,
          data: { count },
          status,
        } = response;
        dispatch(setCurrentProjectsLimitedListCounterAction(count));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });

    const in3days = moment(new Date().setDate(new Date().getDate() + 3)).format(
      'YYYY-MM-DD'
    );
    const endingProjectsFn = axios({
      method: 'GET',
      url: API_URL + API.GET.PROJECT.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        count: true,
        coAndPoOnly: true,
        // confirmationStatus: '2,5',
        filters: {
          'projectEndDate-to': in3days,
        },
      },
    })
      .then(response => {
        const {
          data,
          data: { count },
          status,
        } = response;
        dispatch(setCurrentProjectsLimitedListCounterAction(count));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });

    const data = await Promise.all([
      poProjectsFn,
      coAndMentorProjectsFn,
      endingProjectsFn,
    ]);
    const {
      data: { count: poProjects = 0 },
    } = data[0];
    const {
      data: { count: coProjects = 0 },
    } = data[1];
    const {
      data: { count: endingProjects = 0 },
    } = data[2];

    return { poProjects, coProjects, endingProjects };
  };
};
