import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import ForgotPasswordForm from './ForgotPasswordForm.style';
import validate from './validate';

const forgotPasswordForm = reduxForm({
  form: 'registerRecruiter',
  validate,
})(ForgotPasswordForm);

export default withTranslation()(forgotPasswordForm);
