import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import DownloadIcon from 'shared/assets/img/icons/candidate-show/download.svg';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import {
  getAllCandidateAssetsDocuments,
  getDocumentFromSource,
  getEnumItemNameFullObjectByName,
  getUserAvatarSrc,
} from 'shared/utils/assets';
import CandidateAssets from '../../../../../components/CandidateAssets';
import { styles } from './CandidatePersonalCard.styles';

const CandidatePersonalCardRight = ({
  candidate,
  currentUser,
  readOnly = false,
  classes,
  t,
  history,
}) => {
  const redirectToEditPage = () => {
    prepareOpenStage4();
    history.push(PATHS.CANDIDATE.NEW_EDIT.replace(':id', candidate.id));
    window.location.reload();
  };
  const prepareOpenStage4 = () => {
    localStorage.setItem('openStage1', 'false');
    localStorage.setItem('openStage4', 'true');
  };
  const concatNameDate =
    candidate.firstName && candidate.lastName
      ? `${candidate.firstName} ${candidate.lastName}`
      : t('candidateDetails:personalDetailsContent.noInfo');

  const lastUpdateData = candidate.updatedAt
    ? candidate.updatedAt
    : t('candidateDetails:personalDetailsContent.noInfo');

  const candidateAssetsDocuments = getAllCandidateAssetsDocuments(candidate);

  const selfEdit = currentUser.id === candidate.id;

  return (
    <Grid item xs={12} sm={12} md={6} style={{ padding: 12 }}>
      <Paper style={{ width: '100%', borderRadius: 20 }}>
        <Grid
          container
          style={{ alignItems: 'center', padding: '30px 30px 30px 30px' }}
        >
          <Grid item xs={2} sm={2} md={2} style={{ minWidth: 50 }}>
            <div style={{ display: 'flex', width: '10%' }}>
              <img
                alt="candidate"
                src={getUserAvatarSrc(candidate)}
                height={50}
                style={{ borderRadius: 30 }}
              />
            </div>
          </Grid>
          <Grid item xs={8} sm={8} md={8}>
            <Grid container />
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={concatNameDate}
                bold
                disableTranslate={true}
                style={{ fontSize: 26 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={`${t(
                  'candidateDetails:personalDetailsContent.lastModifyDate'
                )}: ${lastUpdateData}`}
                disableTranslate={true}
                style={{ fontSize: 14 }}
              />
            </Grid>
          </Grid>
          {selfEdit ? (
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!readOnly && (
                <div style={{ display: 'flex' }}>
                  <CandidateAssets
                    onClose={() => {}}
                    clearLoad={true}
                    callback={() => {
                      window.location.reload();
                    }}
                    disabled={!selfEdit}
                    removeIcon={false}
                    filesLimit={1}
                    assetType={'avatar'}
                    acceptedFiles={['image/*']}
                    candidate={candidate}
                  >
                    <img alt="EditIcon" src={EditIcon} height={50} />
                  </CandidateAssets>
                </div>
              )}
            </Grid>
          ) : null}
        </Grid>
      </Paper>
      {!candidateAssetsDocuments.length ? null : (
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
          <Paper style={{ width: '100%', borderRadius: 20 }}>
            <Grid
              container
              style={{ alignItems: 'center', padding: '30px 30px 30px 30px' }}
            >
              <Grid item xs={10} sm={10} md={10}>
                <Translate
                  text={'candidateDetails:personalDetailsContent.myDocuments'}
                  bold
                  style={{ fontSize: 26 }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!readOnly && (
                  <div style={{ display: 'flex' }} onClick={redirectToEditPage}>
                    <img alt="EditIcon" src={EditIcon} height={50} />
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              style={{ alignItems: 'center', padding: '30px 30px 30px 30px' }}
            >
              {candidateAssetsDocuments.map(item => {
                return (
                  <Grid
                    container
                    style={{ padding: '15px 0px' }}
                    key={item.hash}
                  >
                    <Grid item xs={10} sm={10} md={10}>
                      <Grid container />
                      <Grid item xs={12} sm={12} md={12}>
                        <Translate
                          text={getEnumItemNameFullObjectByName(
                            enums.ATTACHMENT_TYPE,
                            item.type
                          )}
                          bold
                          style={{ fontSize: 26 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Translate
                          text={`${t(
                            'candidateDetails:personalDetailsContent.lastModifyDate'
                          )}: ${
                            item.updatedAt
                              ? item.updatedAt
                              : t(
                                  'candidateDetails:personalDetailsContent.noInfo'
                                )
                          }`}
                          disableTranslate={true}
                          style={{ fontSize: 14 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                      <div style={{ display: 'flex', width: '10%' }}>
                        <a
                          href={getDocumentFromSource(item.id)}
                          download={item.filename}
                        >
                          <img
                            alt="downloadIcon"
                            src={DownloadIcon}
                            height={40}
                          />
                        </a>
                      </div>
                    </Grid>
                    <Grid container>
                      <hr className={classes.separator} />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(CandidatePersonalCardRight))
);
