import { Grid, LinearProgress, Paper } from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { enums } from 'shared/enums';
import { Select, Translate } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import TextField from 'shared/newComponents/TextField/TextField';
import { getSelectItemsFullObject } from 'shared/utils/assets';
import { getConfigById } from './utils';

export const ExportView = ({
  t,
  currentUser: { isRecruiter, isSuperUser },
}) => {
  const [dateTitle, setDateTitle] = useState(t`export:projectPublication`);
  const [fetch, setFetch] = useState(false);
  const [dataStatus, setDataStatus] = useState(0);

  const currentDate = moment(new Date()).format('YYYY-MM-DD_HH-mm-ss');

  const [currentHeaders, setCurrentHeaders] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [currentFileName, setCurrentFileName] = useState('');

  const formData = {
    dateStart: moment()
      .subtract(30 * 3, 'd')
      .format('YYYY-MM-DD'),
    dateStop: '',
    reportType: 0,
  };

  const formik = useFormik({
    initialValues: formData,
    onSubmit: ({ dateStart, dateStop, reportType }) => {
      if (!dateStart) {
        formik.setFieldError('dateStart', t`projectCreator:core.requiredField`);
        return;
      }

      const params = {
        dateStart: dateStart === '' ? null : dateStart,
        dateStop: dateStop === '' ? null : dateStop,
      };
      setFetch(true);
      setDataStatus(0);

      const config = getConfigById(reportType);

      config
        .action(params)
        .then(({ data }) => {
          setCurrentHeaders(config.header);
          setCurrentData(config.dataConvert(data, t));
          setCurrentFileName(`${config.fileName}${currentDate}.csv`);
          setFetch(false);
          setDataStatus(1);
        })
        .catch(error => {
          console.log(error);
          setFetch(false);
        });
    },
  });

  return (
    <React.Fragment>
      <Paper>
        <form onSubmit={formik.handleSubmit}>
          <Grid container style={{ padding: 40 }} justifyContent="center">
            <Grid item xs={9}>
              <Select
                items={
                  isSuperUser
                    ? getSelectItemsFullObject(enums.EXPORT_REPORTS_TYPE)
                    : isRecruiter
                    ? getSelectItemsFullObject(
                        enums.EXPORT_REPORTS_TYPE_RECRUITER
                      )
                    : []
                }
                onChange={event => {
                  switch (event.target.value) {
                    case 0:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 1:
                      setDateTitle(t`export:createAccount`);
                      break;
                    case 2:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 3:
                      setDateTitle(t`export:createAccount`);
                      break;
                    case 4:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 5:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 6:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 7:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 8:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 9:
                      setDateTitle(t`export:createProject`);
                      break;
                    case 10:
                      setDateTitle(t`export:allHelpInfo`);
                      break;
                  }
                  formik.setFieldValue('reportType', event.target.value);
                }}
                label={t`export:reportType`}
                name={'reportType'}
                formik={formik}
              />
              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={`${t`export:startDate`} (${dateTitle})`}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField type="date" name={'dateStart'} formik={formik} />
              </Grid>

              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={`${t`export:endDate`} (${dateTitle})`}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField type="date" name={'dateStop'} formik={formik} />
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{ marginTop: 10 }}
                spacing={4}
              >
                <Grid item xs={4} sm={4} md={4}>
                  {!fetch ? (
                    <Button label={t`export:genReport`} type="submit" />
                  ) : (
                    <LinearProgress style={{ width: '100%' }} />
                  )}
                </Grid>
                {dataStatus === 1 ? (
                  <Grid item xs={4} sm={4} md={4}>
                    <CSVLink
                      style={{ textDecoration: 'none' }}
                      data={currentData}
                      headers={currentHeaders}
                      filename={currentFileName}
                    >
                      <Button label={t`export:downReport`} />
                    </CSVLink>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, null)(withTranslation()(ExportView));
