import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
// import { TextField, Select, Checkbox } from 'redux-form-material-ui';
import { checkIfLinkIsFromYouTube } from 'shared/utils/normalizers';
import FieldWysiwyg from '../FieldWysiwyg';

const styles = () => ({
  formControl: {
    minWidth: '50%',
  },
});

export const ProjectFormStepOne = props => {
  let {
    valid,
    classes,
    DictionariesIndustryCategories,
    initialValues,
    t,
    isEdited,
  } = props;

  initialValues = initialValues || {};
  initialValues['isEdited'] = isEdited;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormVisibility'
          )}
        >
          <InputLabel htmlFor="visibility">
            {t('project:field.isPublicLong') + ' *'}
          </InputLabel>
          <Field name="visibility" component={Select} type="number">
            <MenuItem value={1}>{t('project:field.isPrivate')}</MenuItem>
            <MenuItem value={2}>{t('project:field.isOrganization')}</MenuItem>
            <MenuItem value={3}>{t('project:field.isPublic')}</MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Field
          label={t('project:field.companyName') + ' *'}
          name="client"
          inputProps={{ maxLength: 255 }}
          component={TextField}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Field name="isClientPublic" component={Checkbox} />}
          label={t('project:field.isClientPublic')}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.clientUrl') + ' *'}
          name="clientUrl"
          inputProps={{ maxLength: 255 }}
          component={TextField}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.videoUrl')}
          name="videoUrl"
          component={TextField}
          fullWidth
          normalize={checkIfLinkIsFromYouTube}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCompanySize'
          )}
        >
          <InputLabel htmlFor="companySize">
            {t('project:field.companySize') + ' *'}
          </InputLabel>
          <Field name="companySize" component={Select} type="number">
            <MenuItem value={1}>0-9</MenuItem>
            <MenuItem value={2}>10-49</MenuItem>
            <MenuItem value={3}>50-249</MenuItem>
            <MenuItem value={4}>250+</MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel htmlFor="industryCategory">
            {t('candidate:field.industryLong')}
          </InputLabel>
          <Field name="industryCategory.id" component={Select} type="number">
            <MenuItem value={null}>Brak</MenuItem>
            {DictionariesIndustryCategories.map(industryCategory => (
              <MenuItem value={industryCategory.id} key={industryCategory.id}>
                {industryCategory.name}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FieldWysiwyg
          valid={valid}
          label={t('project:field.companyDescription') + ' *'}
          name="companyDescription"
          value={initialValues.companyDescription || ''}
        />
      </Grid>
      <Field type="hidden" name="isEdited" component={TextField} />
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.isEdited ? state.projects.currentProject : null,
    DictionariesIndustries: state.dictionaries.industries.list,
    DictionariesIndustryCategories: state.dictionaries.industryCategories.list,
  };
};

ProjectFormStepOne.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectFormStepOne)));
