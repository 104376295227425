import { withStyles, Typography, DialogContent } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import Checkbox from 'shared/newComponents/Checkbox';
import Select from 'shared/newComponents/Select';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import { styles } from './AdditionalInfo.styles';

const AdditionalInfo = ({ classes, t, formik }) => {
  return (
    <div className={classes.bgBlue}>
      <DialogContent>
        <Typography
          variant="body1"
          className={classes.bold}
        >{t`employmentManagementDialog:dialog2.additionalInfo`}</Typography>
        <div className={classes.location}>
          <div>
            <Typography variant="body1">{t`employmentManagementDialog:dialog2.typeOfLocation`}</Typography>
          </div>
          <div className={classes.locationSelect}>
            <Select
              formik={formik}
              name="locationType"
              items={getSelectItemsTranslate(enums.TYPE_OF_LOCATION, t)}
              label={t`employmentManagementDialog:dialog2.typeOfLocation`}
              fontBold
              variant="transparent"
            />
          </div>
        </div>
        <div className={classes.location}>
          <div>
            <Typography variant="body1">{t`employmentManagementDialog:dialog2.locationStandard`}</Typography>
          </div>
          <div className={classes.locationSelect}>
            <Select
              formik={formik}
              name="locationStandard"
              items={getSelectItemsTranslate(enums.STANDARD_OF_LOCATION, t)}
              label={t`employmentManagementDialog:dialog2.locationStandard`}
              fontBold
              variant="transparent"
            />
          </div>
        </div>
        <Checkbox
          formik={formik}
          label={t`employmentManagementDialog:dialog2.publicTransport`}
          name="isAccessToPublicTransport"
        />
        <Checkbox
          formik={formik}
          label={t`employmentManagementDialog:dialog2.groceryStore`}
          name="isAccessToStore"
        />
      </DialogContent>
    </div>
  );
};

export default withStyles(styles)(withTranslation()(AdditionalInfo));
