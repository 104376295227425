export const validateMentorFee = (values, { t }) => {
  let errors = {};
  ['mentorFee'].forEach(element => {
    let value = values[element];
    if (
      value === undefined ||
      value === null ||
      value === {} ||
      (typeof value === 'string' && value.trim() === '')
    ) {
      errors[element] = t`candidate:form.validation.nonEmpty`;
    }
    if (value <= 0) {
      errors[element] = t`candidate:form.validation.wages3`;
    }
  });

  return errors;
};

export default validateMentorFee;
