import { API } from '../constants';
import { ApiService } from './api.service';

export const ExportService = {
  getExportGeneralProject: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.GENERAL_PROJECTS, params);
  },
  getExportGeneralRecruiter: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.GENERAL_RECRUITERS, params);
  },
  getExportGeneralCandidate: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.GENERAL_CANDIDATES, params);
  },
  getExportDetailRecruiter: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.DETAIL_RECRUITERS, params);
  },
  getExportDetailCandidate: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.DETAIL_CANDIDATES, params);
  },
  getExportAccommodation: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.ACCOMMODATIONS, params);
  },
  getExportProjectOrders: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.PROJECT_ORDERS, params);
  },
  getExportProjectManagement: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.PROJECT_MANAGEMENT, params);
  },
  getExportDetailAccommodation: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.DETAIL_ACCOMMODATIONS, params);
  },
  getExportDetailProjectOrders: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.DETAIL_PROJECT_ORDERS, params);
  },
  getExportAllHelpInfo: (params = {}) => {
    return ApiService.get(API.GET.EXPORT.ALL_HELP_INFO, params);
  },
};
