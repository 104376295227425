export const getLocalStorageByKey = key => {
  return localStorage[key];
};

export const setLocalStorage = (key, value) => {
  if (key && value) {
    localStorage[key] = value;
  }
};

export const clearLocalStorageByKey = key => localStorage.removeItem(key);

export const clearLocalStorage = () => localStorage.clear();

export const getTableRowPerPageFromStorage = key => {
  return getLocalStorageByKey(key)
    ? parseInt(getLocalStorageByKey(key))
    : getLocalStorageByKey(key);
};

export const setTableRowPerPageToStorage = (key, value) => {
  if (!isNaN(value)) {
    setLocalStorage(key, value);
  }
};
