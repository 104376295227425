import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { Translate } from '..';
import { styles } from './ProgressBar.styles';

const ProgressBar = ({
  percentage,
  title,
  secondTitle = false,
  classes,
  customStyle = null,
  customStyleSecondHeader = null,
}) => (
  <Grid container className={classes.wrapper}>
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Translate
        text={title}
        variant="body1"
        style={customStyle ? customStyle : null}
      />
      {secondTitle ? (
        <Translate
          text={secondTitle}
          variant="body1"
          style={customStyleSecondHeader ? customStyleSecondHeader : null}
        />
      ) : null}
    </Grid>
    <div className={classes.container}>
      <div className={classes.bar} style={{ width: `${percentage}%` }} />
    </div>
  </Grid>
);

export default withStyles(styles)(ProgressBar);
