import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { PATHS } from 'config';
import { LANDING_PAGE_URL, API } from 'shared/constants';
import { ProjectV2Service, UserService } from 'shared/services';
import { ApiService } from 'shared/services/api.service';
import { validToken } from 'shared/services/authService';

export class LoginFromLandingPage extends Component {
  state = {
    tokenUser: null,
    candidateIdToMeet: null,
  };

  async componentDidMount() {
    await this.validateToken(
      this.props.match.params.token,
      this.props.match.params.isFirstLogin,
      this.props.match.params.candidateId,
      this.props.match.params.hash,
      this.props.match.params.projectId
    );
  }

  async componentDidUpdate(nextProps) {
    if (nextProps.match.params.token !== this.props.match.params.token) {
      await this.validateToken(
        nextProps.match.params.token,
        nextProps.match.params.isFirstLogin,
        nextProps.match.params.candidateId,
        nextProps.match.params.hash,
        nextProps.match.params.projectId
      );
    }
  }
  validateToken = async (
    token,
    isFirstLogin = 'n',
    candidateId = null,
    hash = null,
    projectId = null
  ) => {
    let urlToRedirect = PATHS.DASHBOARD.INDEX;
    try {
      const { data } = await validToken(token);
      await this.props.setTokenDispatch(token);

      if (isFirstLogin === 'y') {
        urlToRedirect = PATHS.PROJECT.CREATE;
      }
      if (candidateId) {
        const candidateResponse = await ApiService.get(
          API.GET.CANDIDATE_V2.GET.replace(':id', candidateId.toString())
        );

        await UserService.postCandidateToStash({
          candidate: candidateResponse.data,
        });
      }
      if (hash) {
        ProjectV2Service.getProjectPublicUrl(hash)
          .then(({ data }) => {
            this.props.history.push(
              PATHS.PROJECT.SHOW.replace(':id', data.project.id)
            );
          })
          .catch(error => {
            console.log(error);
          });
      }

      if (projectId) {
        ProjectV2Service.getProjectV2(projectId)
          .then(({ data }) => {
            this.props.history.push(PATHS.PROJECT.SHOW.replace(':id', data.id));
          })
          .catch(error => {
            console.log(error);
          });
      }

      if (data.isFromHelpForm) {
        if (data.isRecruiter) {
          urlToRedirect = PATHS.RECRUITER.HELP.replace(':id', data.id);
        } else {
          urlToRedirect = PATHS.CANDIDATE.HELP.replace(':id', data.id);
        }
      }

      const query = new URLSearchParams(this.props.location.search);
      const language = query.get('language');
      if (language === 'ua') {
        i18n.changeLanguage('uk-UK');
      }

      this.props.history.push(urlToRedirect);
    } catch (error) {
      this.props.showSnackbarMessage(this.props.t`login:error.badToken`);
      this.props.history.push(
        `${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.AUTH.LOGIN}`
      );
    }
  };
  render() {
    return null;
  }
}

LoginFromLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
};

export default withTranslation()(LoginFromLandingPage);
