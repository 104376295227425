import { Grid, LinearProgress } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import Auhorized from 'shared/newComponents/Auhorized/Auhorized';
import Snackbar from 'shared/newComponents/Snackbar';
import { getProject, ProjectV2Service } from 'shared/services';
import ProjectAtsEmploymentProcessViewContent from './components/ProjectAtsEmploymentProcessViewContent';

const ProjectAtsEmploymentProcess = ({ match, t, history, getProject }) => {
  const [projectDetails, setProjectDetails] = useState({});
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);

  const id = get(match, 'params.id');

  useEffect(() => {
    if (!!id) {
      ProjectV2Service.getProjectV2(id)
        .then(({ data }) => {
          getProject(id);
          setProjectDetails(data);
        })
        .catch(error => {
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              setIsSnackbarErrorOpen(true);
              setTimeout(redirectToDashboard, 2000);
            }
          }
          console.log(error);
        });
    }
  }, [id]);

  const redirectToDashboard = () => {
    history.push(PATHS.DASHBOARD.INDEX);
  };

  const { permissions = {} } = projectDetails;

  return isEmpty(projectDetails) ? (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
      <Snackbar
        type={'error'}
        open={isSnackbarErrorOpen}
        onClose={() => setIsSnackbarErrorOpen(false)}
        label={t('projectCreator:core.projectRejected')}
      />
    </Grid>
  ) : (
    <>
      <Auhorized
        permissions={permissions}
        roles={[
          'isOwner',
          'isMentor',
          'isSuperUser',
          'isHiringManager',
          'isProjectOrganizationOwner',
        ]}
      />
      <ProjectAtsEmploymentProcessViewContent project={projectDetails} />
    </>
  );
};

const mapDispatchToProps = {
  getProject,
};
export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectAtsEmploymentProcess)));
