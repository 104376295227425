import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { PATHS } from 'config';

export class Logout extends Component {
  state = {
    redirect: false,
  };

  async componentDidMount() {
    try {
      await this.props.logoutUser();
      this.setState({ redirect: true });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>{this.state.redirect && <Redirect to={PATHS.AUTH.LOGIN} />}</div>
    );
  }
}

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

export default Logout;
