import { API } from '../constants';
import { ApiService } from './api.service';

export const WorkStyleService = {
  getWorkStyles() {
    return ApiService.get(API.GET.WORK_STYLE.GET);
  },
};

export default WorkStyleService;
