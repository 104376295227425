import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import logotypeTop from 'shared/assets/img/LOGO-JOBLLEGRO-TOP.png';

const MenuBarLeft = props => {
  const { classes } = props;
  return (
    <a href="https://jobllegro.com">
      <Avatar className={classes.Avatar} alt="Jobllegro" src={logotypeTop} />
    </a>
  );
};

MenuBarLeft.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MenuBarLeft;
