import { withStyles } from '@material-ui/core/styles';
import MenuBarLeft from './MenuBarLeft';

const styles = theme => ({
  Avatar: {
    borderRadius: 0,
    width: 324,
    height: 84,
    marginRight: 0,
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
    },
    '& > img': {
      [theme.breakpoints.only('xs')]: {
        maxWidth: 140,
        height: 'auto',
      },
    },
  },
});

export default withStyles(styles)(MenuBarLeft);
