import { ExportService } from 'shared/services';
import {
  accommodationsReportHeader,
  detailCandidatesReportHeader,
  detailDataGeneralRecruiter,
  detailRecruitersReportHeader,
  generalCandidatesReportHeader,
  generalDataGeneralCandidate,
  generalProjectReportHeader,
  generalRecruitersReportHeader,
  generateDataAccommodations,
  generateDataDetailCandidate,
  generateDataGeneralProject,
  generateDataGeneralRecruiter,
  generateDataProjectManagement,
  generateDataProjectOrders,
  projectManagementReportHeader,
  projectOrdersReportHeader,
  generateDataDetailAccommodations,
  detailAccommodationsReportHeader,
  generateDataDetailsProjectOrders,
  detailProjectOrdersReportHeader,
  allHelpInfoReportHeader,
  generateDataAllHelpInfo,
} from './headersCsv';

export const getConfigById = configId => {
  return config.find(item => item.id === configId);
};

const Roles = {
  ADMIN: 'ADMIN',
  RECRUITER: 'RECRUITER',
  CANDIDATE: 'CANDIDATE',
};

const config = [
  {
    id: 0,
    fileName: 'general_projects_',
    header: generalProjectReportHeader,
    dataConvert: (data, t) => generateDataGeneralProject(data, t),
    action: params => ExportService.getExportGeneralProject(params),
  },
  {
    id: 1,
    fileName: 'general_recruiters_',
    header: generalRecruitersReportHeader,
    dataConvert: (data, t) => generateDataGeneralRecruiter(data, t),
    action: params => ExportService.getExportGeneralRecruiter(params),
  },
  {
    id: 2,
    fileName: 'detail_recruiters_',
    header: detailRecruitersReportHeader,
    dataConvert: (data, t) => detailDataGeneralRecruiter(data, t),
    action: params => ExportService.getExportDetailRecruiter(params),
  },
  {
    id: 3,
    fileName: 'general_candidates_',
    header: generalCandidatesReportHeader,
    dataConvert: (data, t) => generalDataGeneralCandidate(data, t),
    action: params => ExportService.getExportGeneralCandidate(params),
  },
  {
    id: 4,
    fileName: 'details_candidates_',
    header: detailCandidatesReportHeader,
    dataConvert: (data, t) => generateDataDetailCandidate(data, t),
    action: params => ExportService.getExportDetailCandidate(params),
  },
  {
    id: 5,
    fileName: 'project_orders_',
    header: projectOrdersReportHeader,
    dataConvert: (data, t) => generateDataProjectOrders(data, t),
    action: params => ExportService.getExportProjectOrders(params),
  },
  {
    id: 6,
    fileName: 'accommodations_',
    header: accommodationsReportHeader,
    dataConvert: (data, t) => generateDataAccommodations(data, t),
    action: params => ExportService.getExportAccommodation(params),
  },
  {
    id: 7,
    fileName: 'project_management_',
    header: projectManagementReportHeader,
    dataConvert: (data, t) => generateDataProjectManagement(data, t),
    action: params => ExportService.getExportProjectManagement(params),
  },
  {
    id: 8,
    fileName: 'details_accommodations_',
    header: detailAccommodationsReportHeader,
    dataConvert: (data, t) => generateDataDetailAccommodations(data, t),
    action: params => ExportService.getExportDetailAccommodation(params),
  },
  {
    id: 9,
    fileName: 'details_project_orders_',
    header: detailProjectOrdersReportHeader,
    dataConvert: (data, t) => generateDataDetailsProjectOrders(data, t),
    action: params => ExportService.getExportDetailProjectOrders(params),
  },
  {
    id: 10,
    fileName: 'ukraina_help_infos_',
    header: allHelpInfoReportHeader,
    dataConvert: (data, t) => generateDataAllHelpInfo(data, t),
    action: params => ExportService.getExportAllHelpInfo(params),
  },
];
