import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';

const styles = theme => ({
  rootHeaderPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: 'transparent',
  },
  backPage: {
    color: theme.palette.jobllegroBlue,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.jobllegroSuccess,
    },
  },
});

export const PageHeader = props => {
  const {
    classes,
    title,
    component,
    showBackButton = false,
    backButtonTarget = '',
    t,
  } = props;

  return (
    <React.Fragment>
      <Paper className={classes.rootHeaderPaper} elevation={0}>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h5" component="h3">
              {title}
              {showBackButton && (
                <Typography component="div">
                  <Link
                    to={backButtonTarget || PATHS.PROJECT.INDEX}
                    className={classes.backPage}
                  >
                    <BackIcon style={{ width: 12, height: 12 }} />
                    {t('core:general.back')}
                  </Link>
                </Typography>
              )}
            </Typography>
            {component && <Typography component="div">{component}</Typography>}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(PageHeader));
