import { Grid, Paper, LinearProgress, withStyles } from '@material-ui/core';
import PageHeaderTitleComponent from 'components/PageHeaderTitleComponent';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { addCity, getCities } from 'shared/services/cityService';
import { getCompetenceCategories } from 'shared/services/competenceCategoryService';
import { getCompetenceKinds } from 'shared/services/competenceKindService';
import { getCompetenceTemp } from 'shared/services/competenceTempService';
import { getCompetenceTypes } from 'shared/services/competenceTypeService';
import { getIndustryCategories } from 'shared/services/industryCategoryService';
import { addIndustry, getIndustries } from 'shared/services/industryService';
import { addLanguage, getLanguages } from 'shared/services/languageService';
import {
  addProject,
  editProjectLanguages,
  editProjectSkills,
  getProject,
  clearCurrentProject,
} from 'shared/services/projectService';
import { addSkill, getSkills } from 'shared/services/skillService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { checkIfExsistInObject } from 'shared/utils/normalizers';
import ProjectForm from '../../components/projectForm/ProjectForm';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  }),
  bottomNavigation: {
    marginTop: 20,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export class ProjectCreateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingProject: false,
      copiedProject: {},
    };
  }

  loadProject = async () => {
    const { id } = this.props.match.params;
    if (id) {
      try {
        this.setState({ isLoadingProject: true });
        await this.props.clearCurrentProject();
        await this.props.getProject(id);
        const project = _(this.props.project)
          .omitBy(_.isNil)
          .omit('isDraft')
          .omit('closedStatus')
          .omit('hiringManager')
          .omit('createdAt')
          .omit('updatedAt')
          .omit('id')
          .value();
        this.setState({
          isLoadingProject: false,
          copiedProject: project,
        });
      } catch (err) {
        this.setState({ isLoadingProject: false });
        console.error(err);
      }
    }
  };

  handleSubmitForm = async values => {
    const { t } = this.props;
    values['industry'] = null;

    const projectLanguages = values['projectLanguages'] || [];
    delete values['projectLanguages'];
    // TODO na projectSkill
    const projectSkills = values['projectSkills'] || [];
    delete values['projectSkills'];
    // TODO na projectSkill
    delete values['tempProjectSkills'];
    delete values['isEdited'];
    delete values['poLastActivity'];
    delete values['hmLastActivity'];
    delete values['mentorLastActivity'];
    delete values['publicationDate'];
    const { id } = this.props.match.params;
    if (id) {
      delete values['mentor'];
      values['hiringManagerStatus'] = 0;
      values['mentorStatus'] = 0;
      values['copyID'] = id;
    }
    try {
      let result = await this.props.addProject(values);
      if (result && result.status === 201) {
        if (
          checkIfExsistInObject(
            this.props.DictionariesCities,
            values.city,
            'name'
          ) === false
        ) {
          await this.props.addCity(values.city);
        }
        if (
          checkIfExsistInObject(
            this.props.DictionariesIndustries,
            values.industry,
            'name'
          ) === false
        ) {
          await this.props.addIndustry(values.industry);
        }
        //Languages
        await Promise.all(
          projectLanguages.map(async language => {
            if (
              checkIfExsistInObject(
                this.props.DictionariesLanguages,
                language.language.name,
                'name'
              ) === false
            ) {
              await this.props.addLanguage(language.language.name);
            }
          })
        );
        const languages = projectLanguages.map(language => {
          const dictLanguage =
            _.find(this.props.DictionariesLanguages, [
              'name',
              language.language.name,
            ]) || {};
          return {
            languageId: dictLanguage.id,
            level: language.level,
          };
        });
        await this.props.editProjectLanguages(result.data.id, languages);
        const skills = projectSkills.map(skill => {
          return {
            skill: {
              name: skill.skill.name,
              level: skill.level,
              industry: skill.industry,
              experience: parseInt(skill.experience, 10),
              industryCategory: skill.industryCategory.id,
            },
            competence: {
              competenceCategory: skill.competence.competenceCategory.name,
              competenceKind: skill.competence.competenceKind.name,
              competenceType: skill.competence.competenceType.name,
            },
          };
        });
        await editProjectSkills(result.data.id, skills);

        this.props.showSnackbarMessage(t`projectCreator:newProjAdded`);
        this.props.history.push(
          PATHS.PROJECT.SHOW.replace(':id', result.data.id)
        );
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    await this.loadProject();
    await this.props.getCities();
    await this.props.getIndustries();
    await this.props.getIndustryCategories();
    await this.props.getLanguages();
    await this.props.getSkills();
    await this.props.getCompetenceCategories();
    await this.props.getCompetenceKinds();
    await this.props.getCompetenceTypes();
    await this.props.getCompetenceTemp();
  }

  render() {
    const { classes, t } = this.props;
    const { isLoadingProject } = this.state;

    return (
      <Grid
        container
        alignItems="flex-start"
        spacing={2}
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} xl={8} lg={8}>
          <PageHeaderTitleComponent title={t`projectCreator:projectCreator`} />
          <Paper className={classes.rootPaper} elevation={4}>
            {isLoadingProject ? (
              <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
            ) : (
              <ProjectForm
                isEdited={false}
                handleSubmitTrigger={this.handleSubmitForm}
                copiedProject={this.state.copiedProject}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.auth.currentUser,
    DictionariesCities: state.dictionaries.cities.list,
    DictionariesIndustries: state.dictionaries.industries.list,
    DictionariesLanguages: state.dictionaries.languages.list,
    DictionariesSkills: state.dictionaries.skills.list,
    project: state.projects.currentProject,
  };
};

const mapDispatchToProps = {
  getCompetenceCategories,
  getCompetenceKinds,
  getCompetenceTemp,
  getCompetenceTypes,
  addProject,
  editProjectLanguages,
  getProject,
  clearCurrentProject,
  addCity,
  getCities,
  addIndustry,
  getIndustryCategories,
  getIndustries,
  addLanguage,
  getLanguages,
  addSkill,
  getSkills,
  showSnackbarMessage,
};

ProjectCreateView.propTypes = {
  classes: PropTypes.object.isRequired,
  addProject: PropTypes.func.isRequired,
  editProjectLanguages: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  clearCurrentProject: PropTypes.func.isRequired,
  addCity: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  addIndustry: PropTypes.func.isRequired,
  getIndustries: PropTypes.func.isRequired,
  addLanguage: PropTypes.func.isRequired,
  getLanguages: PropTypes.func.isRequired,
  addSkill: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(ProjectCreateView))));
