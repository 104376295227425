import { Typography, withStyles, Grid } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import { TextEditor } from 'shared/newComponents';
import Select from 'shared/newComponents/Select';
import TextField from 'shared/newComponents/TextField';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import {
  getAvailableProject,
  getAvailableProjectOrders,
} from 'shared/utils/functions';
import { styles } from './Deregistering.styles';

const Deregistering = ({
  classes,
  t,
  selectedFullObject,
  projectAvailableToMove,
}) => {
  const [selectOptions, setDynamicSelectOptions] = useState([]);

  const isResignationConfirm = selectedFullObject.isResignationConfirm;

  const formData = {
    resignationStartDate: selectedFullObject.employmentStartDate
      ? selectedFullObject.employmentStartDate
      : '',
    resignationEndDate: selectedFullObject.resignationEndDate
      ? selectedFullObject.resignationEndDate
      : '',
    reasonOfTerminationDescription:
      selectedFullObject.reasonOfTerminationDescription
        ? selectedFullObject.reasonOfTerminationDescription
        : '',
    reasonOfTermination:
      selectedFullObject.reasonOfTermination !== null
        ? selectedFullObject.reasonOfTermination
        : '',
    project: '',
    projectOrder: '',
  };

  const formik = useFormik({
    initialValues: formData,
    onSubmit: () => {},
  });

  useEffect(() => {
    setDynamicSelectOptions(
      getAvailableProjectOrders(formik.values.project, projectAvailableToMove)
    );
  }, [formik.values.project]);

  return (
    <form>
      <div className={classes.bgBlue}>
        <div className={classes.container}>
          <Grid
            container
            alignItems="center"
            spacing={4}
            className={classes.edited}
          >
            <Grid item md={5}>
              <Typography variant="body1">{t`employmentManagementDialog:dialog9.plannedEndWorkDate`}</Typography>
            </Grid>
            <Grid item md={5}>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.date}>
                    {formik.values.resignationEndDate || t`assign:noData`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item md={5}>
              <Typography variant="body1">{t`employmentManagementDialog:dialog9.endWorkDate`}</Typography>
            </Grid>
            <Grid item md={6}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                </Grid>
                <Grid item>
                  <TextField
                    disabled={isResignationConfirm}
                    type="date"
                    formik={formik}
                    name="resignationEndDate"
                    variant="transparent"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Select
            disabled={isResignationConfirm}
            formik={formik}
            name="reasonOfTermination"
            items={getSelectItemsTranslate(enums.END_OF_EMPLOYMENT_REASON, t)}
            label={t`employmentManagementDialog:dialog9.reasonLabel`}
            variant="transparent"
            fontBold
          />
        </div>
      </div>

      {formik.values.reasonOfTermination ===
        enums.END_OF_EMPLOYMENT_REASON_VALUES.OTHER && (
        <div className={classes.transferContainer}>
          <TextEditor
            disabled={isResignationConfirm}
            formik={formik}
            name={'reasonOfTerminationDescription'}
            label={''}
            subLabel={''}
          />
        </div>
      )}
      {formik.values.reasonOfTermination ===
        enums.END_OF_EMPLOYMENT_REASON_VALUES.TRANSFER && (
        <div className={classes.transferContainer}>
          <Select
            disabled={isResignationConfirm}
            items={getAvailableProject(projectAvailableToMove)}
            formik={formik}
            name="project"
            label={t`cancelOrderDialog:chooseProject`}
          />
          <Select
            disabled={isResignationConfirm}
            items={selectOptions}
            formik={formik}
            name="projectOrder"
            label={t`cancelOrderDialog:chooseLocation`}
          />
        </div>
      )}
    </form>
  );
};

export default withStyles(styles)(withTranslation()(Deregistering));
