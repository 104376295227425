export const styles = ({ palette }) => ({
  link: {
    color: palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  paperWrapper: {
    alignItems: 'center',
    height: '6rem',
  },
  paper: {
    borderRadius: 20,
    padding: 15,
    paddingTop: 15,
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
  },
});
