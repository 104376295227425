import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  withStyles,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close, PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { date } from 'yup';
import Attachments from '../../../modules/project/EmploymentManagementView/components/EmploymentManagementDialog/components/Attachments';
import { enums } from '../../enums';
import { ProjectEmploymentProcessService } from '../../services';
import { getEnumItemName } from '../../utils/assets';
import { isShorVisaForm } from '../../utils/functions';
import { getLocalizationFromProjectOrder } from '../PopUpATS1ATS2/utils';
import Select from '../Select';
import TextField from '../TextField';
import { styles } from './PopUpATS4ATS5.styles';

const PopUpATS4ATS5 = ({
  isOpen,
  setIsOpen,
  onClose,
  classes,
  loadProjectCandidate,
  projectOrderLocalizations,
  projectEmploymentProcess: { id, candidate },
  t,
  project,
}) => {
  const { country } = project;

  const {
    userEmploymentProcess: {
      arrivalEstimatedDate,
      estimatedStartEmploymentDate,
      estimatedEndEmploymentDate,
      visaDate,
      localization,
      residenceTitle,
      employmentTypeRequest,
      documentStartDate,
      documentEndDate,
    },
    employmentProcessVisaAsset,
  } = candidate;

  const localizations = getLocalizationFromProjectOrder(
    projectOrderLocalizations
  );

  const getSelectedLocalization = localization => {
    const findLocalization = localizations.find(
      item => item.id === localization.id
    );

    if (!findLocalization) {
      return '';
    }
    return findLocalization.id;
  };

  const formData = {
    visaDate: visaDate !== null ? visaDate : '',
    arrivalEstimatedDate:
      arrivalEstimatedDate !== null ? arrivalEstimatedDate : '',
    estimatedStartEmploymentDate:
      estimatedStartEmploymentDate !== null ? estimatedStartEmploymentDate : '',
    estimatedEndEmploymentDate:
      estimatedEndEmploymentDate !== null ? estimatedEndEmploymentDate : '',
    localization:
      localization !== null ? getSelectedLocalization(localization) : '',
  };

  const validationSchema = yup.object({
    visaDate:
      !isShorVisaForm(residenceTitle) &&
      yup.string().required(t`ats2ats3:validation.isRequired`),
    arrivalEstimatedDate:
      !isShorVisaForm(residenceTitle) &&
      yup.string().required(t`ats2ats3:validation.isRequired`),
    estimatedStartEmploymentDate:
      !isShorVisaForm(residenceTitle) &&
      date()
        .min(
          documentStartDate,
          `${t`employmentManagementDialog:dialog6.minBreakDate`} ${documentStartDate}`
        )
        .max(
          documentEndDate,
          `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${documentEndDate}`
        )
        .required(t('projectCreator:core.requiredField')),
    estimatedEndEmploymentDate:
      !isShorVisaForm(residenceTitle) &&
      yup
        .date()
        .min(
          documentStartDate,
          `${t`employmentManagementDialog:dialog6.minBreakDate`} ${documentStartDate}`
        )
        .max(
          documentEndDate,
          `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${documentEndDate}`
        )
        .when(
          'estimatedStartEmploymentDate',
          (estimatedStartEmploymentDate, schema) =>
            estimatedStartEmploymentDate &&
            schema.min(
              estimatedStartEmploymentDate,
              t`ats2ats3:validation.date`
            )
        )
        .required(t`ats2ats3:validation.isRequired`),
    localization:
      !isShorVisaForm(residenceTitle) &&
      yup.string().required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({
      arrivalEstimatedDate,
      estimatedStartEmploymentDate,
      estimatedEndEmploymentDate,
      visaDate,
      localization,
    }) => {
      ProjectEmploymentProcessService.updateProjectEmploymentProcess(
        {
          visaDate,
          arrivalEstimatedDate,
          estimatedStartEmploymentDate,
          estimatedEndEmploymentDate,
          localization: { id: localization },
          projectCandidateEmploymentProcesses: [id],
        },
        5
      )
        .then(() => {
          loadProjectCandidate();
          setIsOpen(false);
        })
        .catch(err => console.log(err));
    },
  });

  if (isShorVisaForm(residenceTitle)) {
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={isOpen}
      >
        <DialogTitle
          className={classes.dialogTitle}
        >{t`ats4ats5:dialogTitle`}</DialogTitle>
        <IconButton className={classes.closeBtn} onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className={classes.content}>
            <Typography className={classes.country} variant="subtitle1">
              {country.name}
            </Typography>
            <TextField
              variant="transparent"
              formik={formik}
              value={t([
                getEnumItemName(enums.RESIDENCE_TITLE, residenceTitle),
              ])}
              InputProps={{
                className: classes.textField,
              }}
            />
          </DialogContent>
          <Button type="submit" className={classes.saveBtn}>
            {t`ats4ats5:saveBtn`}
          </Button>
        </form>
      </Dialog>
    );
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle
        className={classes.dialogTitle}
      >{t`ats4ats5:dialogTitle`}</DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.content}>
          <Typography className={classes.country} variant="subtitle1">
            {country.name}
          </Typography>
          <TextField
            variant="transparent"
            formik={formik}
            value={t([getEnumItemName(enums.RESIDENCE_TITLE, residenceTitle)])}
            InputProps={{
              className: classes.textField,
            }}
          />
          <Typography
            className={classes.header}
          >{t`ats4ats5:header1`}</Typography>
          <div className={classes.date}>
            <div>
              <Typography>{t`ats4ats5:docVisaExpireDate`}</Typography>
            </div>
            <div>
              <TextField
                type="date"
                name="visaDate"
                variant="transparent"
                formik={formik}
              />
            </div>
          </div>
          <div className={classes.date}>
            <div className={classes.txtRight}>
              <Typography>{t`ats4ats5:arrivalEstimatedDate`}</Typography>
            </div>
            <div>
              <TextField
                type="date"
                name="arrivalEstimatedDate"
                variant="transparent"
                formik={formik}
              />
            </div>
          </div>
          <Typography
            className={classes.header}
          >{t`ats4ats5:header2`}</Typography>
          <div className={classes.date}>
            <div className={classes.date}>
              <div>
                <PowerSettingsNew className={classes.powerOnBtn} />
              </div>
              <div className={classes.datePicker}>
                <TextField
                  formik={formik}
                  type="date"
                  name="estimatedStartEmploymentDate"
                  variant="transparent"
                />
              </div>
            </div>
            <div className={classes.date}>
              <div>
                <PowerSettingsNew className={classes.powerOffBtn} />
              </div>
              <div>
                <TextField
                  formik={formik}
                  type="date"
                  name="estimatedEndEmploymentDate"
                  variant="transparent"
                />
              </div>
            </div>
          </div>
          <Typography
            className={classes.localization}
          >{t`ats4ats5:header3`}</Typography>
          <Select
            label={t`ats4ats5:localization`}
            variant="transparent"
            formik={formik}
            name="localization"
            items={localizations}
            fontBold={true}
          />
        </DialogContent>
        {employmentTypeRequest !== 0 && (
          <div className={classes.attachments}>
            <Attachments
              project={project}
              formik={formik}
              attachmentList={enums.ATTACHMENT_ATS4}
              initialCandidateValues={candidate}
              employmentProcessAsset={employmentProcessVisaAsset}
            />
          </div>
        )}
        <Button type="submit" className={classes.saveBtn}>
          {t`ats4ats5:saveBtn`}
        </Button>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(PopUpATS4ATS5);
