import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';

const ProjectAtsEmploymentProcess = ({
  permissions = {},
  roles = [],
  history,
}) => {
  const redirectToDashboard = () => {
    history.push(PATHS.DASHBOARD.INDEX);
  };

  const isAuthorized = roles.find(role => permissions[role]);

  if (!isAuthorized) {
    redirectToDashboard();
  }

  return null;
};

export default withRouter(ProjectAtsEmploymentProcess);
