import {
  IconButton,
  Snackbar as BaseSnackbar,
  withStyles,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import React from 'react';
import { styles } from './Snackbar.styles';

const Snackbar = ({
  open,
  onClose,
  classes,
  label,
  type = 'default',
  ...props
}) => (
  <BaseSnackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    open={open}
    autoHideDuration={6000}
    onClose={onClose}
    message={label}
    action={
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <Close fontSize="small" />
      </IconButton>
    }
    className={
      type === 'default'
        ? classes.baseSnackbarDefault
        : classes.baseSnackbarError
    }
    style={{}}
    {...props}
  />
);

export default withStyles(styles)(Snackbar);
