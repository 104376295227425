import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
/*Api version*/
import { getApiVersion } from 'shared/services/authService';
import HelpDesk from './HelpDesk';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(3),
    zIndex: 1,
    overflow: 'hidden',
    fontSize: 12,
    fontFamily: 'Arial',
  },
  version: {
    display: 'none',
  },
});

export class LayoutFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiVersion: '',
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      const { data: apiVersion } = await this.props.getApiVersion();
      this._isMounted && this.setState({ apiVersion });
    } catch (error) {
      this._isMounted && this.setState({ error: error.toString() });
    }
  }

  render() {
    const { classes } = this.props;
    const { apiVersion } = this.state;

    return (
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12} align="center">
          <HelpDesk />
          <Typography className={classes.version}>
            {'Version: ' + apiVersion.text}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  getApiVersion,
};

LayoutFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  getApiVersion: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(LayoutFooter));
