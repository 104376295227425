const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.src = url;
  });

const dataURLtoFileConverter = (dataUrl, fileName) => {
  const arrayOfDataUrlSplitted = dataUrl.split(','),
    mime = arrayOfDataUrlSplitted[0].match(/:(.*?);/)[1],
    decodedData = atob(arrayOfDataUrlSplitted[1]); //decode data coded in base-64
  let decodedDataLength = decodedData.length;
  const uint8Array = new Uint8Array(decodedData.length); //The Uint8Array typed array represents an array of 8-bit unsigned integers.

  while (decodedDataLength--) {
    uint8Array[decodedDataLength] = decodedData.charCodeAt(decodedDataLength); //return Unicode number of current sign
  }
  const file = new File([uint8Array], fileName, { type: mime });

  const blobUrl = URL.createObjectURL(file);
  file.preview = blobUrl;
  return file;
};

export const getCroppedImg = async (imageSrc, pixelCrop, name) => {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image, // Specifies the image, canvas, or video element to use
    pixelCrop.x, // Optional. The x coordinate where to start clipping
    pixelCrop.y, // Optional. The y coordinate where to start clipping
    pixelCrop.width, // Optional. The width of the clipped image
    pixelCrop.height, // Optional. The height of the clipped image
    0, // pixelCrop.x, // The x coordinate where to place the image on the canvas
    0, //pixelCrop.y, // The y coordinate where to place the image on the canvas
    pixelCrop.width, // Optional. The width of the image to use (stretch or reduce the image)
    pixelCrop.height // Optional. The height of the image to use (stretch or reduce the image)
  );

  // As Base64 string
  const imageInBase64 = canvas.toDataURL('image/jpg', 1);
  const file = dataURLtoFileConverter(
    imageInBase64,
    name ? `${name}.jpg` : 'image.jpg'
  );
  return file;
};
