export const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  containerWithoutArrow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  iconButton: {
    alignSelf: 'center',
    padding: 6,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  children: {
    margin: '0 0.5rem',
  },
  svg: {
    color: '#B3B3B3',
    fill: 'currentColor',
    height: '3rem',
    width: '3rem',
    [theme.breakpoints.down('xs')]: {
      height: '1rem',
      width: '1rem',
    },
  },
});
