import {
  Button,
  CardActions,
  Grid,
  IconButton,
  Paper,
  withStyles,
} from '@material-ui/core';
import {
  KeyboardArrowRight as FolderShowIcon,
  List as ListIcon,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
} from '@material-ui/icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import PinIcon from 'shared/assets/img/icons/project-show/pin.svg';
import { enums } from '../../enums';
import {
  checkCandidateFirstNameLastName,
  getAssetUrl,
  hasAssets,
} from '../../utils/functions';
import { truncateToThreeDots } from '../../utils/normalizers';
import { Translate } from '../index';
import { styles } from './CandidateCard.styles';

const CandidateCard = ({
  candidate,
  handleAccept,
  handleReject,
  handleDelete = null,
  handleToggleMenuCardOpen,
  t,
  classes,
  action = null,
  listType,
}) => {
  const getExperienceInYears = candidate => {
    if (candidate.experience) {
      return candidate.experience.toString();
    }
    if (candidate.userExperiences && candidate.userExperiences.length) {
      let experienceLevel = 0;
      candidate.userExperiences.forEach(item => {
        experienceLevel += item.experienceLevel;
      });
      return experienceLevel;
    }
    return t('core:general.noInformation');
  };

  const candidateNameDetails = checkCandidateFirstNameLastName(
    candidate.firstName,
    candidate.lastName,
    candidate.id,
    t
  );

  const { isOwnerToBe } = candidate.permissions;

  const candidateUrl = `#${PATHS.CANDIDATE.SHOW.replace(':id', candidate.id)}`;

  const candidateMlSimilarities =
    candidate.similarities && candidate.similarities > 0
      ? candidate.similarities
      : null;

  const salaryType = enums.TYPE_OF_PAY[1];

  const preferenceWorkArea = candidate.isRemoteWorkOnly
    ? t('candidateCreator:step1.remoteWorkOnly')
    : candidate.city;

  const position = candidate.position
    ? truncateToThreeDots(candidate.position, 30)
    : null;

  const preferenceWorkAreaAfterTruncate = preferenceWorkArea
    ? truncateToThreeDots(preferenceWorkArea, 30)
    : null;

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid
        className={
          listType
            ? classes.candidateCardWrapper
            : classes.candidateCardWrapperFullWith
        }
      >
        <Paper className={classes.candidateCard}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            className={classes.candidateCard}
            wrap="nowrap"
          >
            <Grid
              container
              item
              className={classes.candidateCardHeader}
              alignItems="center"
              justifyContent={'space-between'}
            >
              <Grid item>
                {candidate.industryCategory &&
                hasAssets(candidate.industryCategory.assets) ? (
                  <img
                    src={getAssetUrl(candidate.industryCategory.assets)}
                    width={40}
                    height={40}
                    alt={'industryLogo'}
                  />
                ) : null}
                {candidate.industryCategory ? (
                  <Translate
                    text={candidate.industryCategory.name}
                    style={{ fontSize: '0.875rem' }}
                  />
                ) : (
                  <Translate
                    text={'core:general.noInformation'}
                    style={{ fontSize: '0.875rem' }}
                  />
                )}
              </Grid>
              {action ? <Grid item>{action}</Grid> : null}
            </Grid>
            <Grid container item className={classes.candidateCardContent}>
              <Grid
                item
                container
                alignContent="center"
                className={classes.candidateCardContentName}
              >
                <a
                  href={candidateUrl}
                  className={classes.candidateCardContentNameUrl}
                >
                  <Translate
                    text={position ? position : 'core:general.noInformation'}
                    style={{ fontSize: '1.5rem', color: '#348EFF' }}
                    bold
                  />
                  <Translate
                    text={candidateNameDetails}
                    style={{ fontSize: '14px' }}
                  />
                </a>
              </Grid>
              <Grid
                item
                container
                alignContent="center"
                className={classes.candidateCardDetails}
              >
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  {preferenceWorkAreaAfterTruncate ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={PinIcon}
                        alt={'city'}
                        style={{ paddingRight: 8 }}
                      />
                      <Translate
                        text={`${preferenceWorkAreaAfterTruncate}`}
                        style={{ fontSize: '16px', display: 'inline' }}
                      />
                    </div>
                  ) : null}
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  {candidate.minMonthlyWadges && candidate.maxMonthlyWadges ? (
                    <>
                      <Translate
                        text={`${candidate.minMonthlyWadges}-${candidate.maxMonthlyWadges} `}
                        bold
                        style={{ fontSize: '16px', display: 'inline' }}
                      />
                      <Translate
                        text={salaryType}
                        style={{ fontSize: '16px', display: 'inline' }}
                      />
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <Translate
                    text={'candidateCreator:reviewStep2.experience'}
                    style={{ fontSize: '16px', display: 'inline' }}
                  />{' '}
                  <Translate
                    text={getExperienceInYears(candidate)}
                    bold
                    style={{ fontSize: '16px', display: 'inline' }}
                  />
                </Grid>
                {candidateMlSimilarities ? (
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Translate
                      text={'candidate:fiels:mlSimilarities'}
                      style={{ fontSize: '16px', display: 'inline' }}
                    />{' '}
                    <Translate
                      text={candidateMlSimilarities}
                      bold
                      style={{ fontSize: '16px', display: 'inline' }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <CardActions className={classes.actions} disableSpacing>
              {isOwnerToBe ? (
                <React.Fragment>
                  <Button
                    onClick={() => handleAccept(candidate.id)}
                    color="primary"
                    className={classes.buttonAccept}
                  >
                    <ThumbUpIcon className={classes.thumbUp} />{' '}
                    {t('project:menu.main.acceptance')}
                  </Button>
                  <Button
                    onClick={() => handleReject(candidate.id)}
                    color="primary"
                    className={classes.buttonReject}
                  >
                    <ThumbDownIcon className={classes.thumbDown} />{' '}
                    {t('action.reject')}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {handleDelete !== null && (
                    <React.Fragment>
                      <IconButton
                        aria-haspopup="true"
                        onClick={handleToggleMenuCardOpen(candidate)}
                      >
                        <ListIcon className={classes.listMenu} />
                      </IconButton>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              <Button
                component={Link}
                to={PATHS.CANDIDATE.SHOW.replace(':id', candidate.id)}
                className={classes.buttonShow}
              >
                {t('candidate:list.readMore')}
                <FolderShowIcon className={classes.rightIcon} />
              </Button>
            </CardActions>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(CandidateCard));
