import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  withStyles,
  Grid,
} from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getProjectsWithLimit,
  addProjectRecruiter,
  getProjectsPoWithLimit,
} from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import {
  getProjectAssetSrcByType,
  getUserAvatarSrc,
} from 'shared/utils/assets';
import {
  ButtonJobllegrov5,
  ActionButtonJobllegroRounded,
} from 'shared/utils/styledComponents';
import ElementList from './list/ElementsList';

const styles = theme => ({
  button: {
    marginTop: 15,
    color: '#fff',
    float: 'right',
    background: '#52a3fc',
    borderRadius: '3px',
    '&:hover': {
      background: '#70b3ff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  avatar: {
    width: 60,
    height: 60,
  },
  avatarIcon: {
    background: theme.palette.jobllegroBlue,
  },
  dialogContent: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  widgetsIcon: {
    color: theme.palette.jobllegroBlue,
  },
  badgeLocked: {
    top: 'calc(60%)',
    left: '0',
    background: theme.palette.jobllegroRedAlert,
  },
  chip: {
    background: 'none',
    fontSize: 16,
    '&:hover': {
      background: 'transparent',
      color: theme.palette.jobllegroBlue,
    },
  },
  iconButtonBox: {
    fontSize: 9,
    color: theme.palette.jobllegroBlue,
    padding: 0,
    textTransform: 'uppercase',
    '&:hover': {
      background: 'transparent',
    },
  },
});

class AssignRecruiterToProjectsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
    };
  }
  async componentDidMount() {
    if (this.props.open) {
      await this.handleClickOpen();
    }
  }

  loadProjects = async (
    limit = null,
    offset = null,
    filters = null,
    orderBy = null
  ) => {
    const { recruiter } = this.props;
    let params = {
      filters,
      orderBy,
    };
    await this.props.getProjectsPoWithLimit(
      limit,
      offset,
      params,
      recruiter.id
    );
  };

  handleClickOpen = async () => {
    await this.loadProjects();
    this.setState({ open: true });
  };

  addProjectRecruiter = async (projectId, recruiterId) => {
    try {
      this.setState({ isLoading: true });
      const { t } = this.props;
      await this.props.addProjectRecruiter(projectId, recruiterId);
      await this.loadProjects();
      this.setState({ isLoading: false });
      this.props.showSnackbarMessage(t`assign:requestSent`);
    } catch (err) {
      console.error(err);
    }
  };

  getContent = project => {
    const logo = getProjectAssetSrcByType(project, 'logo');
    const { t, classes, recruiter } = this.props;
    const { isLoading } = this.state;

    return (
      project && (
        <ListItem key={project.id}>
          {project.visibility === 1 ? (
            <Badge
              badgeContent={<LockIcon style={{ width: 15 }} />}
              color="primary"
              classes={{ badge: classes.badgeLocked }}
            >
              <Avatar
                component={Link}
                to={PATHS.PROJECT.SHOW.replace(':id', project.id)}
                className={classes.avatar}
                src={logo}
                aria-label={project.position}
              />
            </Badge>
          ) : (
            <Avatar
              component={Link}
              to={PATHS.PROJECT.SHOW.replace(':id', project.id)}
              className={classes.avatar}
              src={logo}
              aria-label={project.position}
            />
          )}
          <ListItemText
            primary={project.position}
            secondary={project.industry}
          />
          <ButtonJobllegrov5
            disabled={isLoading}
            onClick={() => {
              return this.addProjectRecruiter(project.id, recruiter.id);
            }}
          >
            {isLoading ? (
              <CircularProgress
                style={{ width: 20, height: 20, color: '#fff' }}
              />
            ) : (
              t('core:action.add')
            )}
          </ButtonJobllegrov5>
        </ListItem>
      )
    );
  };

  handleClickOpenProjectCreator = () => {
    this.props.history.push({
      pathname: PATHS.PROJECT.NEW_CREATE,
    });
  };

  getWrapper = options => {
    let { content, pagination } = options;
    const { recruiter, classes, t } = this.props;

    const avatar = getUserAvatarSrc(recruiter);

    const recruiterDetails =
      recruiter.firstName && recruiter.lastName
        ? `${recruiter.firstName} ${recruiter.lastName}`
        : t`assign:noData`;
    return (
      <React.Fragment>
        {recruiter && (
          <List>
            <ListItem>
              <Grid container justifyContent={'space-between'}>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Avatar
                    className={classes.avatar}
                    src={avatar}
                    aria-label={recruiterDetails}
                  />
                  <ListItemText
                    primary={recruiterDetails}
                    secondary={recruiter.email}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ButtonJobllegrov5
                    style={{ padding: 10 }}
                    onClick={this.handleClickOpenProjectCreator}
                  >
                    {t('project:form.projectCreator')}
                  </ButtonJobllegrov5>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        )}
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <List component={'nav'}>
            {isEmpty(content) ? (
              <ListItem>
                <Typography variant="body1">{t`assign:noProjects`}</Typography>
              </ListItem>
            ) : (
              content
            )}
          </List>
        </DialogContent>
        {!isEmpty(content) && pagination}
      </React.Fragment>
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  render() {
    const {
      currentProjectsLimitedList,
      option,
      t,
      btnColor = '#52A3FC',
    } = this.props;
    return (
      <React.Fragment>
        {option === 'button' && (
          <ActionButtonJobllegroRounded
            style={{ background: btnColor }}
            onClick={this.handleClickOpen}
          >
            {t`assign:addRecruiter`}
          </ActionButtonJobllegroRounded>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t`assign:addRecruiter`}</DialogTitle>
          <ElementList
            elements={currentProjectsLimitedList}
            getContent={this.getContent}
            getWrapper={this.getWrapper}
          />
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t('core:action.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentProjectsLimitedList: store.projects.currentProjectsLimitedList,
  };
};

const mapDispatchToProps = {
  getProjectsPoWithLimit,
  getProjectsWithLimit,
  addProjectRecruiter,
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    withTranslation()(withRouter(AssignRecruiterToProjectsDialog))
  )
);
