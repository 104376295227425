import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart as Chart,
} from 'recharts';
import { styles } from './RadarChart.style';

const RadarChart = ({ data, classes, t }) => {
  const initialData = {
    creator: 0,
    star: 0,
    supporter: 0,
    dealMaker: 0,
    trader: 0,
    accumulator: 0,
    lord: 0,
    mechanic: 0,
  };

  const parseData = data => {
    return [
      {
        name: t`candidateDetails:radarChart.innovator`,
        value: data.workStyleCreatorGraphPoints,
        fullMark: 100,
      },
      {
        name: t`candidateDetails:radarChart.promoter`,
        value: data.workStyleStarGraphPoints,
        fullMark: 100,
      },
      {
        name: t`candidateDetails:radarChart.integrator`,
        value: data.workStyleSupporterGraphPoints,
        fullMark: 100,
      },
      {
        name: t`candidateDetails:radarChart.connector`,
        value: data.workStyleDealMakerGraphPoints,
        fullMark: 100,
      },
      {
        name: t`candidateDetails:radarChart.negotiator`,
        value: data.workStyleTraderGraphPoints,
        fullMark: 100,
      },
      {
        name: t`candidateDetails:radarChart.guardian`,
        value: data.workStyleAccumulatorGraphPoints,
        fullMark: 100,
      },
      {
        name: t`candidateDetails:radarChart.creator`,
        value: data.workStyleLordGraphPoints,
        fullMark: 100,
      },
      {
        name: t`candidateDetails:radarChart.architect`,
        value: data.workStyleMechanicGraphPoints,
        fullMark: 100,
      },
    ];
  };

  return (
    <Typography
      component="div"
      variant="caption"
      className={classes.radarChart}
    >
      <Chart
        outerRadius={90}
        width={550}
        height={250}
        data={parseData(data || initialData)}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis angle={90} domain={[0, 100]} />
        <Radar
          name="RadarChart"
          dataKey="value"
          stroke="#52a3fc"
          fill="#20d4d5"
          fillOpacity={0.6}
          fontSize={10}
        />
      </Chart>
    </Typography>
  );
};

export default withStyles(styles)(withTranslation()(RadarChart));
