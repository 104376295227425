import {
  Avatar,
  Card,
  CardMedia,
  withStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import BoxTopTitle from './BoxTopTitle';

const styles = theme => ({
  card: {
    maxWidth: 'auto',
    borderRadius: '5px',
    margin: theme.spacing(1),
  },
  avatar: {
    backgroundColor: '#fff',
    position: 'absolute !important',
    width: 70,
    height: 70,
    margin: '20px',
    borderRadius: '50px',
    border: '1px solid #ededed',
    boxShadow: '0 0 0 0.2rem rgba(237,237,237,0.25)',
  },
  recommend: {
    color: 'red',
    backgroundColor: '#fff',
    position: 'absolute !important',
    left: '206px',
    top: '30px',
  },
  avatarImage: {
    width: 70,
    height: 70,
    objectFit: 'cover',
  },
  emptyHeaderMock: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundImage:
      'linear-gradient(to right bottom, rgba(217, 225, 241, 0.5), rgba(201, 209, 228, 0.7), rgba(198, 206, 225, 0.7)) !important',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    borderRadius: '5px 5px 0 0 !important',
  },
});

export class Box extends Component {
  render() {
    let showHeader = true;
    const {
      classes,
      title,
      children,
      image,
      imageHeight,
      icons,
      elevation,
      link,
      variant = '',
      logoType,
      secondTitle = null,
      secondLink = null,
      showRecommend = false,
      t,
    } = this.props;
    const setRadius = title ? '0px' : '10px 10px 0 0';
    if (image === 'emptyCardHeader' || image === undefined) {
      showHeader = false;
    }
    return (
      <Card className={classes.card} elevation={elevation}>
        {logoType && (
          <Avatar alt="Jobllegro project" className={classes.avatar}>
            <img
              alt="Jobllegro"
              src={logoType}
              className={classes.avatarImage}
            />
          </Avatar>
        )}
        {showRecommend && (
          <Typography variant="h6" className={classes.recommend}>
            {t('core:projectList.chosenForYou')}
          </Typography>
        )}
        {title && (
          <BoxTopTitle
            title={title}
            link={link}
            secondTitle={secondTitle}
            secondLink={secondLink}
            icons={icons}
            variant={variant}
          />
        )}
        {!showHeader ? (
          <div
            style={{ height: imageHeight, borderRadius: setRadius }}
            className={classes.emptyHeaderMock}
          />
        ) : (
          <CardMedia
            style={{ height: imageHeight, borderRadius: setRadius }}
            image={image}
          />
        )}
        {children}
      </Card>
    );
  }
}

Box.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  image: PropTypes.string,
  imageHeight: PropTypes.number,
  icons: PropTypes.string,
  elevation: PropTypes.number,
};

export default withStyles(styles, { withTheme: true })(withTranslation()(Box));
