import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';

// import { TextField } from 'redux-form-material-ui';

export const ResetPasswordForm = props => {
  const { classes, user = {}, onSubmit, handleSubmit, valid, t } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography component="p">
              {t`register:enterNewPassword`} {user.email}:
            </Typography>
            <Field
              className={classes.field}
              label={t`register:form.password`}
              name="password"
              type="password"
              component={TextField}
            />
            <Field
              className={classes.field}
              label={t`register:form.repeatPassword`}
              name="passwordConfirmation"
              type="password"
              component={TextField}
            />
          </CardContent>
          <CardActions>
            <Button
              type="submit"
              name="login"
              variant="contained"
              color="primary"
              disabled={!valid}
            >
              {t`register:changePassword`}
            </Button>
          </CardActions>
        </Card>
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default withTranslation()(ResetPasswordForm);
