import { Grid, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Translate } from 'shared/newComponents';
import { MODEL } from '../../../ProjectCreateWizardStep5/ProjectCreateWizardStep5.utils';
import { styles } from './ProjectReviewStep5.style';

const ProjectReviewStep5 = ({ step5FormValues, classes }) => {
  const companyDescription = get(step5FormValues, MODEL.companyDescription);
  return (
    !!companyDescription && (
      <Grid container className={classes.contentWrapper}>
        <Grid container>
          <Translate
            text="projectCreator:reviewStep5.companyDescription"
            style={{ fontSize: 26 }}
            bold
            variant="h6"
          />
        </Grid>
        <Grid container>{ReactHtmlParser(companyDescription)}</Grid>
      </Grid>
    )
  );
};

export default withStyles(styles)(ProjectReviewStep5);
