import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { styles } from './CompanyData.styles';

const CompanyData = ({ project, classes, t }) => {
  const projectOrder = project.projectOrders[0];
  const projectOrderCompany = projectOrder.company;

  const settlementDataType = projectOrderCompany
    ? projectOrderCompany.organizationType
    : null;

  const settlementDataInvoiceTypeTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t`dashboard:rightDrawer:client`
        : t`dashboard:rightDrawer:firstAndLastName`
      : t`dashboard:rightDrawer:client`;

  const settlementDataInvoiceNumberTypeTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t('organization:field.invoiceIdentificationNumber')
        : t`organization:field.invoicePersonalNumber`
      : t('organization:field.invoiceIdentificationNumber');

  const companyName =
    projectOrderCompany && projectOrderCompany.invoiceCompanyName;
  const invoiceAddress =
    projectOrderCompany && projectOrderCompany.invoiceAddress;
  const invoicePostCode =
    projectOrderCompany && projectOrderCompany.invoicePostCode;
  const invoiceCity = projectOrderCompany && projectOrderCompany.invoiceCity;
  const invoicePersonalNumber =
    projectOrderCompany && projectOrderCompany.invoicePersonalNumber;
  const invoiceIdentificationNumber =
    projectOrderCompany && projectOrderCompany.invoiceIdentificationNumber;

  const settlementDataNumber =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? invoiceIdentificationNumber
        : invoicePersonalNumber
      : invoiceIdentificationNumber;

  let concatAddress = t`general.noInformation`;
  if (
    invoiceAddress !== null ||
    invoicePostCode !== null ||
    invoiceCity !== null
  ) {
    concatAddress = `${invoiceAddress !== null ? invoiceAddress : ''} ${
      invoicePostCode !== null ? invoicePostCode : ''
    } ${invoiceCity !== null ? invoiceCity : ''}`;
  }

  return (
    <Grid container className={classes.marginGridWrapper}>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.containerWrapper}>
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={'modifyOrderDialog:paper.header'}
              bold
              className={classes.bigFont}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.marginGridWrapper}
          >
            <Grid container>
              <Grid item xs={3} sm={2} md={2}>
                <Translate
                  className={classes.mediumFont}
                  text={settlementDataInvoiceTypeTitle}
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Translate
                  className={classes.mediumFont}
                  text={companyName}
                  disableTranslate={true}
                  bold
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.marginGridWrapper}
          >
            <Grid container>
              <Grid item xs={3} sm={2} md={2}>
                <Translate
                  className={classes.mediumFont}
                  text={'modifyOrderDialog:paper.address'}
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Translate
                  className={classes.mediumFont}
                  text={concatAddress}
                  disableTranslate={true}
                  bold
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.marginGridWrapper}
          >
            <Grid container>
              <Grid item xs={3} sm={2} md={2}>
                <Translate
                  className={classes.mediumFont}
                  text={settlementDataInvoiceNumberTypeTitle}
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Translate
                  className={classes.mediumFont}
                  text={settlementDataNumber}
                  disableTranslate={true}
                  bold
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.marginGridWrapper}
          >
            <Translate
              className={classes.mediumFont}
              text={'modifyOrderDialog:editData'}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(CompanyData));
