import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { Translate } from 'shared/newComponents';
import { styles } from './CandidateReview.style';
import { CandidateReviewStep1, CandidateReviewStep2 } from './componets';

const CandidateReview = ({
  classes,
  step1FormValues,
  step2FormValues,
  step3FormValues,
  step6FormValues,
  currentUser,
}) => {
  return (
    <Grid container className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container className={classes.title}>
          <Translate
            style={{ color: '#27D38B', fontSize: 24 }}
            text="candidateCreator:core.reviewTitle"
            bold
            variant="h5"
          />
        </Grid>
        <CandidateReviewStep1 step1FormValues={step1FormValues} />
        <CandidateReviewStep2
          step1FormValues={step1FormValues}
          step2FormValues={step2FormValues}
          step3FormValues={step3FormValues}
          step6FormValues={step6FormValues}
          currentUser={currentUser}
        />
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(CandidateReview);
