import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CandidateMenu from '../../../modules/candidate/candidateMenu/CandidateMenu';
import { NeedHelpModal } from '../HelpModal';
import { styles } from './HelpCandidateContent.styles';

const HelpCandidateContent = ({
  currentCandidate,
  recruiterProjectCandidate,
  history,
  t,
  classes,
  currentUser,
}) => {
  return (
    <Grid container className={classes.wrapper}>
      <CandidateMenu
        itemSelected="help"
        title={'candidateDetails:anonymousDetailsCard.menuTitle'}
        subtitle={'candidateDetails:anonymousDetailsCard.subTitle'}
        currentCandidate={currentCandidate}
        currentUser={currentCandidate}
      />
      <NeedHelpModal id={currentCandidate.id} />
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(HelpCandidateContent))
);
