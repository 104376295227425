import axios from 'axios';
import { API, API_URL } from '../constants';
import { getAllCompetenceCategories } from '../store/competenceCategories/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getCompetenceCategoriesWithLimit = (limit, offset, params) => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.COMPETENCE_CATEGORY.GET_SEARCH,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      limit: limit,
      offset: offset,
      ...params,
    },
  });
};

export const getCompetenceCategories = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.COMPETENCE_CATEGORY.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllCompetenceCategories(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getCompetenceCategoriesWithoutDispatch = () => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.COMPETENCE_CATEGORY.GET,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const deleteCompetenceCategory = id => {
  return axios({
    method: 'DELETE',
    url: API_URL + API.DELETE.COMPETENCE_CATEGORY.DELETE.replace(':id', id),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const editCompetenceCategory = (competenceCategoryId, values) => {
  return axios({
    method: 'PUT',
    url:
      API_URL +
      API.PUT.COMPETENCE_CATEGORY.EDIT.replace(':id', competenceCategoryId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const addCompetenceCategory = values => {
  return axios({
    method: 'POST',
    url: API_URL + API.POST.COMPETENCE_CATEGORY.PUT,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};
