import { Avatar, withWidth, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import logoJobllegro from 'shared/assets/img/Jobleggro_logo.png';
import logoJobllegroXs from 'shared/assets/img/Jobleggro_logo_xs.png';
import { LANDING_PAGE_URL } from 'shared/constants';

const styles = theme => ({
  Avatar: {
    borderRadius: 0,
    width: 103,
    height: 41,
    marginRight: 80,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: 41,
    },
  },
});

export const Logotype = props => {
  const { classes, width } = props;

  return (
    <a href={LANDING_PAGE_URL} rel="noopener noreferrer">
      <Avatar
        className={classNames(classes.Avatar)}
        alt="Jobllegro"
        src={width === 'xs' ? logoJobllegroXs : logoJobllegro}
      />
    </a>
  );
};

Logotype.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withWidth())(Logotype);
