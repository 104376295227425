import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Translate } from 'shared/newComponents';
import { MODEL } from '../../../ProjectCreateWizardStep4/ProjectCreateWizardStep4.utils';

const ProjectReviewStep4 = ({ step4FormValues }) => {
  const positionDescription = get(step4FormValues, MODEL.positionDescription);
  const benefits = get(step4FormValues, MODEL.benefits);

  return (
    (!!positionDescription || !!benefits) && (
      <Grid container style={{ padding: 25, paddingBottom: 0 }}>
        {!!positionDescription && (
          <>
            <Grid container>
              <Translate
                style={{ fontSize: 26 }}
                text="projectCreator:step4.positionDescription"
                variant="h6"
                bold
              />
            </Grid>
            <Grid container>{ReactHtmlParser(positionDescription)}</Grid>
          </>
        )}
        {!!benefits && (
          <>
            <Grid container>
              <Translate
                style={{ fontSize: 26 }}
                text="projectCreator:step4.benefits"
                variant="h6"
                bold
              />
            </Grid>
            <Grid container>{ReactHtmlParser(benefits)}</Grid>
          </>
        )}
      </Grid>
    )
  );
};

export default ProjectReviewStep4;
