export const styles = ({ breakpoints }) => ({
  itemWrapper: {
    padding: 12,
  },
  paperWrapper: {
    width: '100%',
    borderRadius: 20,
    height: '100%',
  },
  avatarWrapper: {
    alignItems: 'center',
    padding: '30px 30px 30px 30px',
    wordBreak: 'break-word',
  },
  fontStylesBig: {
    fontSize: 26,
  },
  fontStylesSmall: {
    fontSize: 24,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: '50',
    [breakpoints.only('xs')]: {
      width: 50,
      height: 50,
    },
  },
});
