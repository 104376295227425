import { API } from '../constants';
import { ApiService } from './api.service';

export const RecruiterV2Service = {
  getRecruiterV2: recruiterId => {
    return ApiService.get(API.GET.RECRUITER_V2.GET.replace(':id', recruiterId));
  },
  getRecruiterV2ProjectCandidates: recruiterId => {
    return ApiService.get(
      API.GET.RECRUITER_V2.GET_PROJECT_CANDIDATE.replace(':id', recruiterId)
    );
  },
  editRecruiterV2: (recruiterId, data, params) => {
    return ApiService.put(
      API.PUT.RECRUITER_V2.PUT.replace(':id', recruiterId),
      data,
      params
    );
  },
  updateMentorType: (recruiterId, data) => {
    return ApiService.put(
      API.PUT.RECRUITER_V2.PUT_MENTOR_TYPE.replace(':mentorId', recruiterId),
      data
    );
  },
};
