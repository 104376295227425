import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IndustryCategoryService } from 'shared/services';
import IndustryCategoryTable from '../../../components/IndustryCategoryTable/index';
import PageHeader from '../../../components/PageHeader';

function AdminIndustryCategoryView({ classes }) {
  const [industryCategories, setIndustryCategories] = useState([]);
  const { t } = useTranslation('adminOrganization');

  useEffect(() => {
    getIndustryCategories();
  }, []);

  const getIndustryCategories = () => {
    IndustryCategoryService.getIndustryCategories()
      .then(({ data }) => {
        setIndustryCategories(data);
      })
      .catch(error => {
        alert(t('dataError'));
        console.log(error);
      });
  };
  return (
    <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
      <PageHeader title="Branże" />
      <div className={classes.root}>
        <IndustryCategoryTable
          industryCategories={industryCategories}
          shouldUpdateIndustryCategory={getIndustryCategories}
        />
      </div>
    </Paper>
  );
}

export default AdminIndustryCategoryView;
