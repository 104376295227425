import { Button, Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Translate } from 'shared/newComponents';
import { styles } from './ActionIconButton.styles';
import 'shared/assets/css/pulsarBtn.css';

const ActionIconButton = ({
  name,
  onClick,
  icon,
  classes,
  disabled = false,
  variant = 'outlined',
  size = 'large',
  textFullWith = false,
  fullWidth = true,
  full = false,
  pulsarBtn = false,
}) => (
  <div className={pulsarBtn ? 'pulsarBtn' : ''}>
    <Button
      variant={variant}
      className={classNames(
        disabled ? classes.rootDisabledButton : classes.rootButton,
        variant === 'contained' ? classes.rootContainedButton : '',
        full ? classes.rootContainedButton : '',
        size === 'small' ? classes.small : ''
      )}
      disabled={disabled}
      fullWidth={fullWidth}
      classes={{ root: classes.btnRoot }}
    >
      <Grid
        container
        style={{ width: textFullWith ? '' : 246, justifyContent: 'center' }}
        onClick={onClick}
      >
        <Grid item style={{ width: 26, height: 26 }}>
          {' '}
          <img alt="icon" src={icon} style={{ width: 26, height: 26 }} />
        </Grid>
        {name ? (
          <Grid
            item
            style={{
              alignSelf: 'center',
              marginLeft: 10,
            }}
          >
            <Translate text={name} />
          </Grid>
        ) : null}
      </Grid>
    </Button>
  </div>
);

export default withStyles(styles)(ActionIconButton);
