import { Grid, withStyles } from '@material-ui/core';
import { Translate } from 'shared/newComponents';
import AssignOrganizationToProjectButton from 'shared/newComponents/AssignOrganizationToProjectButton/AssingOrganizationToProjectButton';
import { styles } from './OrganizationJoinContent.style';

const OrganizationJoinContent = ({
  notificationToAccept,
  projectDetails,
  ...props
}) => {
  return (
    <>
      <Grid container style={{ padding: 16 }}>
        <Grid
          container
          style={{
            justifyContent: 'center',
          }}
        >
          <Translate
            variant="h6"
            text={
              'projectDetails:assignCandidateToProjectButton.requestJoinProjectToOrganization'
            }
            bold
          />
        </Grid>
        <Grid
          container
          style={{
            justifyContent: 'center',
          }}
        >
          <AssignOrganizationToProjectButton
            notificationToAccept={notificationToAccept}
            projectDetails={projectDetails}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(OrganizationJoinContent);
