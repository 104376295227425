import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIndustryCategoryForm } from '../AddIndustryCategoryForm/AddIndustryCategoryForm';
import CustomIconButton from '../CustomIconButton';

export function IndustryCategoryTable({
  industryCategories,
  shouldUpdateIndustryCategory,
  classes,
}) {
  const { t } = useTranslation('addIndustryCategoryForm');
  const [industryCategoryData, setIndustryCategoryData] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleOpenEdit = id => {
    const industryCategory = getIndustryCategoryById(id);
    if (industryCategory) {
      setIndustryCategoryData(industryCategory);
      setOpenAddDialog(true);
    }
  };

  const getIndustryCategoryById = id =>
    industryCategories.find(industryCategory => industryCategory.id === id);

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setIndustryCategoryData(null);
  };

  const handleAdded = () => {
    handleCloseAddDialog();
    shouldUpdateIndustryCategory();
  };

  const handleAddAction = () => {
    setIndustryCategoryData(null);
    setOpenAddDialog(true);
  };

  return (
    <div>
      <Table stickyheader="true" className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>{t('name')}</TableCell>
            <TableCell>{t('nameEn')}</TableCell>
            <TableCell>{t('nameDe')}</TableCell>
            <TableCell>{t('nameUa')}</TableCell>
            <TableCell>{t('visible')}</TableCell>
            <TableCell>{t('factor')}</TableCell>
            <TableCell>{t('sortingFactor')}</TableCell>
            <TableCell>{t('priceType2')}</TableCell>
            <TableCell>
              <CustomIconButton onClick={handleAddAction} type={'add'} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {industryCategories.map(industryCategory => (
            <TableRow key={industryCategory.id}>
              <TableCell align={'center'}>{industryCategory.id}</TableCell>
              <TableCell align={'center'}>{industryCategory.name}</TableCell>
              <TableCell align={'center'}>{industryCategory.enName}</TableCell>
              <TableCell align={'center'}>{industryCategory.deName}</TableCell>
              <TableCell align={'center'}>{industryCategory.uaName}</TableCell>
              <TableCell align={'center'}>
                {industryCategory.isCmsButton
                  ? t('`citySeoTable:yes')
                  : t('`citySeoTable:no')}
              </TableCell>
              <TableCell align={'center'}>{industryCategory.ratio}</TableCell>
              <TableCell align={'center'}>
                {industryCategory.orderNumber}
              </TableCell>
              <TableCell align={'center'}>
                {industryCategory.priceTypeList}
              </TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell}>
                <CustomIconButton
                  onClick={() => handleOpenEdit(industryCategory.id)}
                  type={'edit'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {openAddDialog && (
        <AddIndustryCategoryForm
          isOpen={openAddDialog}
          industryCategoryData={industryCategoryData}
          onClose={handleCloseAddDialog}
          industryCategoryAdded={handleAdded}
        />
      )}
    </div>
  );
}

export default IndustryCategoryTable;
