import React from 'react';
import TextField from '../TextField';

const PostCode = ({ name, formik, label, disabled = false }) => {
  const onKeyDown = e => {
    const key = e.keyCode || e.charCode;
    const value = formik.values[name];
    if (!value) {
      return;
    }
    if (key === 8 || key === 46) {
      if (value.length === 4) {
        formik
          .setFieldValue(name, value.slice(0, -1))
          .then(() => formik.setFieldTouched(name));
      }
    } else {
      if (value.length === 2 && key !== 189) {
        formik
          .setFieldValue(name, value + '-')
          .then(() => formik.setFieldTouched(name));
      }
    }
  };

  return (
    <TextField
      formik={formik}
      label={label}
      name={name}
      onKeyDown={onKeyDown}
      disabled={disabled}
    />
  );
};

export default PostCode;
