import { API } from '../constants';
import { ApiService } from './api.service';

export const CountryService = {
  getCountries() {
    return ApiService.get(API.GET.COUNTRY.GET);
  },
};

export default CountryService;
