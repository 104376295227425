import { Dialog, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  dialogPaper: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
});

export class LayoutContentDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(false);
  };

  render() {
    const { children, classes, ...other } = this.props;

    return (
      <Dialog
        fullScreen
        onClose={this.handleClose}
        {...other}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        {children}
      </Dialog>
    );
  }
}

LayoutContentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(LayoutContentDialog);
