import { Grid, withStyles } from '@material-ui/core';
import { get, map } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { enums } from 'shared/enums';
import { ProgressBar, Translate } from 'shared/newComponents';
import { getEnumItemNameFullObject } from 'shared/utils/assets';
import { getCompetenceHeaderInfo } from 'shared/utils/functions';
import { MODEL } from '../../../NewCandidateCreateView/components/CandidateCreateWizardStep2/CandidateCreateWizardStep2.utils';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateKeyCompetences = ({ candidate, t, classes }) => {
  const userSkills = candidate.userSkills;

  const isUserSkillsSet = !!(userSkills && userSkills.length);
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Translate
        bold
        style={{
          fontSize: 20,
        }}
        text={'candidateCreator:reviewStep2.keyCompetences'}
      />
      {isUserSkillsSet ? (
        <Grid
          container
          style={{
            marginTop: 20,
          }}
        >
          <Grid container style={{ paddingBottom: 16 }}>
            {map(userSkills, (skill, index) => {
              if (!skill) {
                return null;
              }
              const experienceLevel = get(skill, MODEL.experience) + 1;
              const level =
                experienceLevel * 10 < 100 ? experienceLevel * 10 : 100;
              const headerInfo = getCompetenceHeaderInfo(
                candidate,
                index,
                t,
                MODEL.userSkills,
                MODEL.experience
              );

              const secondHeaderInfo = getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                get(skill, MODEL.experience)
              );
              return (
                <ProgressBar
                  key={skill.id}
                  percentage={level}
                  title={headerInfo}
                  customStyle={{
                    background:
                      skill.skill && skill.skill.isApproved ? '' : '#d8d8d8',
                  }}
                  secondTitle={secondHeaderInfo}
                  customStyleSecondHeader={{
                    color: '#6CB8FF',
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateKeyCompetences))));
