import { PATHS } from 'config';
import { ModuleRoute } from 'shared/types';
import ForgotPasswordIndexView from '../../modules/auth/ForgotPassword';
import LoginIndexView from '../../modules/auth/Login';
import LoginFromLandingPage from '../../modules/auth/LoginFromLandingPage';
import LogoutIndexView from '../../modules/auth/Logout';
import RegisterIndexView from '../../modules/auth/Register';
import ResetPasswordIndexView from '../../modules/auth/ResetPassword';
import LandingIndexView from '../../modules/landing/Landing';

const authRoutes: ModuleRoute[] = [
  {
    path: PATHS.AUTH.LANDING,
    exact: true,
    component: LandingIndexView,
  },
  {
    path: PATHS.AUTH.LOGIN,
    exact: true,
    component: LoginIndexView,
  },
  {
    path: PATHS.AUTH.LOGIN_FROM_LANDING_PAGE,
    exact: true,
    component: LoginFromLandingPage,
  },
  {
    path: PATHS.AUTH.LOGIN_FROM_LANDING_PAGE_AND_CANDIDATE,
    exact: true,
    component: LoginFromLandingPage,
  },
  {
    path: PATHS.AUTH.LOGIN_FROM_LANDING_PAGE_AND_PROJECT_APPLY,
    exact: true,
    component: LoginFromLandingPage,
  },
  {
    path: PATHS.AUTH.LOGIN_TO_APPLY_PROJECT_FROM_CANDIDATE_PUBLIC,
    exact: true,
    component: LoginFromLandingPage,
  },
  {
    path: PATHS.AUTH.LOGOUT,
    exact: true,
    component: LogoutIndexView,
  },
  {
    path: PATHS.AUTH.REGISTER,
    exact: true,
    component: RegisterIndexView,
  },
  {
    path: PATHS.AUTH.RESET_PASSWORD,
    exact: true,
    component: ResetPasswordIndexView,
  },
  {
    path: PATHS.AUTH.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPasswordIndexView,
  },
];

export default authRoutes;
