export const styles = theme => ({
  root: {
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
    border: 0,
  },
  iconWrapper: { width: 20, height: 20, margin: '0 10px' },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
});
