import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { Translate } from 'shared/newComponents';
import { styles } from './CandidatePersonalCard.styles';
import DisabledCandidate from './components/DisabledCandidate';

const CandidatePersonalCardLeft = ({
  candidate,
  classes,
  history,
  t,
  ...props
}) => {
  const redirectToEditPage = () => {
    prepareOpenStage1();
    history.push(PATHS.CANDIDATE.NEW_EDIT.replace(':id', candidate.id));
    window.location.reload();
  };

  const prepareOpenStage1 = () => {
    localStorage.setItem('openStage1', 'true');
    localStorage.setItem('openStage4', 'false');
  };

  const splitPhone = phone => {
    return [phone.slice(0, 3), phone.slice(3, 6), phone.slice(6)].join(' ');
  };
  const getPhone = candidate => {
    if (candidate.phonePrimaryCode) {
      return `(${candidate.phonePrimaryCode}) ${splitPhone(
        candidate.phonePrimary
      )}`;
    }
  };
  const firstName = candidate.firstName
    ? candidate.firstName
    : t('candidateDetails:personalDetailsContent.noInfo');

  const lastName = candidate.lastName
    ? candidate.lastName
    : t('candidateDetails:personalDetailsContent.noInfo');
  const homeCountry = candidate.homeCountry
    ? candidate.homeCountry.name
    : t('candidateDetails:personalDetailsContent.noInfo');
  const citizenship = candidate.citizenship
    ? candidate.citizenship.name
    : t('candidateDetails:personalDetailsContent.noInfo');

  const email = candidate.email
    ? candidate.email
    : t('candidateDetails:personalDetailsContent.noInfo');
  const phone = candidate.phonePrimary
    ? getPhone(candidate)
    : t('candidateDetails:personalDetailsContent.noInfo');
  const linkedinLink = candidate.linkedinCandidateLink
    ? candidate.linkedinCandidateLink
    : t('candidateDetails:personalDetailsContent.noInfo');
  const facebookLink = candidate.facebookCandidateLink
    ? candidate.facebookCandidateLink
    : t('candidateDetails:personalDetailsContent.noInfo');
  const sex = candidate.isMen
    ? t('candidateCreator:step1.men')
    : candidate.isWomen
    ? t('candidateCreator:step1.women')
    : '';
  const birthYear = candidate.birthYear
    ? candidate.birthYear
    : t('candidateDetails:personalDetailsContent.noInfo');

  const enableToEdit =
    (candidate.owner && props.currentUser.id === candidate.owner.id) ||
    props.currentUser.isSuperUser ||
    props.currentUser.id === candidate.id;

  return (
    <Grid item xs={12} sm={12} md={6} style={{ padding: 12 }}>
      <Paper style={{ width: '100%', borderRadius: 20 }}>
        <Grid
          container
          style={{
            alignItems: 'center',
            padding: '30px 30px 30px 30px',
            wordBreak: 'break-word',
          }}
        >
          <Grid container style={{ padding: '15px 0px' }}>
            <Grid item xs={10} sm={10} md={10}>
              <Translate
                text={'candidateDetails:personalDetailsContent.personalData'}
                bold
                style={{ fontSize: 26 }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {enableToEdit ? (
                <div style={{ display: 'flex' }} onClick={redirectToEditPage}>
                  <img alt="EditIcon" src={EditIcon} height={50} />
                </div>
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Translate
              text={sex}
              bold
              disableTranslate={true}
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Grid container style={{ padding: '15px 0px' }}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.firstName')}:`}
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={firstName}
                bold
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid container style={{ padding: '15px 0px', minWidth: 220 }}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.lastName')}:`}
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={lastName}
                bold
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid container style={{ padding: '15px 0px' }}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.birthDate')}:`}
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={birthYear}
                disableTranslate={true}
                style={
                  candidate.birthYear
                    ? { fontSize: 20, fontWeight: 'bold' }
                    : { fontSize: 20 }
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid container style={{ padding: '15px 0px' }}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:reviewStep2.homeCountry')}:`}
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={homeCountry}
                style={
                  candidate.homeCountry
                    ? { fontSize: 20, fontWeight: 'bold' }
                    : { fontSize: 20 }
                }
                disableTranslate={true}
              />
            </Grid>
          </Grid>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid container style={{ padding: '15px 0px' }}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.citezenship')}:`}
                disableTranslate={true}
                style={{ fontSize: 20 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={citizenship}
                disableTranslate={true}
                style={
                  candidate.citizenship
                    ? { fontSize: 20, fontWeight: 'bold' }
                    : { fontSize: 20 }
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <DisabledCandidate candidate={candidate} />
        </Grid>
      </Paper>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{ marginTop: 20, wordBreak: 'break-word' }}
      >
        <Paper style={{ width: '100%', borderRadius: 20 }}>
          <Grid
            container
            style={{ alignItems: 'center', padding: '30px 30px 30px 30px' }}
          >
            <Grid container style={{ padding: '15px 0px' }}>
              <Grid item xs={10} sm={10} md={10}>
                <Translate
                  text={'candidateDetails:personalDetailsContent.contact'}
                  bold
                  style={{ fontSize: 20 }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {enableToEdit ? (
                  <div style={{ display: 'flex' }} onClick={redirectToEditPage}>
                    <img alt="EditIcon" src={EditIcon} height={50} />
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid container style={{ padding: '15px 0px' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`${t(
                    'candidateDetails:personalDetailsContent.email'
                  )}:`}
                  disableTranslate={true}
                  style={{ fontSize: 20 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={email}
                  bold
                  disableTranslate={true}
                  style={{ fontSize: 20 }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
            <Grid container style={{ padding: '15px 0px' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`${t('candidateCreator:step1.phone')}:`}
                  disableTranslate={true}
                  style={{ fontSize: 20 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={phone}
                  disableTranslate={true}
                  style={
                    candidate.phonePrimary
                      ? { fontSize: 20, fontWeight: 'bold' }
                      : { fontSize: 20 }
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
            <Grid container style={{ padding: '15px 0px' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`${t(
                    'candidateDetails:personalDetailsContent.linkedin'
                  )}:`}
                  disableTranslate={true}
                  style={{ fontSize: 20 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={linkedinLink}
                  disableTranslate={true}
                  style={
                    candidate.linkedinLink
                      ? { fontSize: 20, fontWeight: 'bold' }
                      : { fontSize: 20 }
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
            <Grid container style={{ padding: '15px 0px' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`${t(
                    'candidateDetails:personalDetailsContent.facebook'
                  )}:`}
                  disableTranslate={true}
                  style={{ fontSize: 20 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={facebookLink}
                  disableTranslate={true}
                  style={
                    candidate.facebookLink
                      ? { fontSize: 20, fontWeight: 'bold' }
                      : { fontSize: 20 }
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidatePersonalCardLeft))));
