import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';

export const columnData = [
  {
    id: 'select',
    selectAll: true,
    numeric: false,
    label: '',
    style: { width: '10px' },
  },
  {
    id: 'id',
    selectAll: false,
    numeric: true,
    label: 'id',
    style: { width: '30px' },
  },
  { id: 'name', selectAll: false, numeric: false, label: 'nazwa' },
  { id: 'en_name', numeric: false, label: 'nazwa (en)' },
  { id: 'de_name', numeric: false, label: 'nazwa (de)' },
  { id: 'ua_name', numeric: false, label: 'nazwa (ua)' },
  { id: '', selectAll: false, numeric: false, label: 'Akcje' },
];

export class AdminSkillToApprovedHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, handleCheckbox, checkedAll } = this.props;

    return (
      <TableRow>
        {columnData.map(column => {
          if (column.selectAll) {
            return (
              <TableCell
                style={column.style}
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding="checkbox"
              >
                <Checkbox
                  checked={checkedAll}
                  onChange={() => handleCheckbox()}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
            );
          }
          return (
            <TableCell
              style={column.style}
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
}

AdminSkillToApprovedHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default AdminSkillToApprovedHead;
