import {
  Paper,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  withStyles,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setLastPage } from 'shared/services/authService';
import { getSummary } from 'shared/services/summaryService';
import AtsEmptyContainer from '../../components/AtsEmptyContainer';
import DefaultSearchField from '../../components/DefaultSearchField';
import PageHeader from '../../components/PageHeader';
import SummaryLeftDescription from '../../components/SummaryLeftDescription';
import SummaryCellComponent from './components/SummaryCellComponent';
import SummaryIndexMenu from './components/SummaryIndexMenu';
import SummaryRowComponent from './components/SummaryRowComponent';

let columnData = [
  {
    id: 'client',
    numeric: false,
    label: 'summary:field.clientAndPosition',
    hiddenSm: 'false',
    hiddenXs: 'false',
  },
  {
    id: 'date',
    numeric: false,
    label: 'summary:field.timeLeft',
    hiddenSm: 'true',
    hiddenXs: 'true',
  },
  {
    id: 'stage1',
    numeric: true,
    label: 'summary:field.stage1Title',
    hiddenSm: 'true',
    hiddenXs: 'true',
  },
  {
    id: 'stage2',
    numeric: true,
    label: 'summary:field.stage2Title',
    hiddenSm: 'true',
    hiddenXs: 'true',
  },
  {
    id: 'stage3',
    numeric: true,
    label: 'summary:field.stage3Title',
    hiddenSm: 'true',
    hiddenXs: 'true',
  },
  {
    id: 'stage4',
    numeric: true,
    label: 'summary:field.stage4Title',
    hiddenSm: 'false',
    hiddenXs: 'true',
  },
  {
    id: 'stage5',
    numeric: true,
    label: 'summary:field.stage5Title',
    hiddenSm: 'false',
    hiddenXs: 'true',
  },
  {
    id: 'stage6',
    numeric: true,
    label: 'summary:field.stage6Title',
    hiddenSm: 'false',
    hiddenXs: 'false',
  },
  {
    id: 'rejected',
    numeric: true,
    label: 'summary:field.rejected',
    hiddenSm: 'false',
    hiddenXs: 'false',
  },
];

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 8,
  },
  paperContainer: {
    paddingTop: 56,
    paddingLeft: 64,
    paddingRight: 64,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  paperContainerSection: {
    display: 'flex',
    height: 50,
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  tableRow: {
    borderTop: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    paddingTop: 0,
    paddingBottom: 0,
    height: '40px',
  },
  sectionTitle: {
    color: '#52a3fc',
    fontSize: 18,
    fontWeight: '500',
  },
});

export class SummaryCandidateViewHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { t, order, orderBy, classes } = this.props;

    columnData = columnData.map((column, index) => {
      return { ...column, label: t(column.label) };
    });

    return (
      <TableHead>
        <TableRow className={classes.tableRow}>
          {columnData.map((column, index) => (
            <SummaryCellComponent
              index={index}
              key={column.id}
              column={column}
              orderBy={orderBy}
              order={order}
              onClickTrigger={this.createSortHandler(column.id)}
            />
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

SummaryCandidateViewHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export class SummaryForCandidateIndexView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'desc',
      orderBy: 'id',
      page: 0,
      rowsPerPage: 10,
      filterString: '',
      isLoading: true,
      anchorEl: null,
      paramType: null,
    };
  }

  async componentWillMount() {
    this.setState({ isLoading: true });
    let paramType = await this.props.match.params.type;
    this.setState({
      paramType: paramType,
    });
    this.reloadSummary();
    this.interval = setInterval(() => {
      this.reloadSummary();
    }, 30000);
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params !== prevProps.match.params) {
      this.setState({
        paramType: this.props.match.params.type,
      });
      this.reloadSummary();
    }
  }

  reloadSummary = async () => {
    let paramFilter = null;
    if (this.props.match.params.filter === 'closedStatus') {
      paramFilter = {
        [this.props.match.params.filter]: true,
        [this.state.paramType + 'All']: true,
      };
    } else {
      paramFilter = { [this.props.match.params.filter]: true };
    }
    await this.props.getSummary(paramFilter);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, page: 0 });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  setFilterString = value => {
    this.setState({
      filterString: value,
    });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { t, summaryList = [], classes } = this.props;
    const {
      order,
      orderBy,
      rowsPerPage,
      page,
      filterString,
      anchorEl,
      isLoading,
    } = this.state;

    let data = _.filter(summaryList, candidate => {
      let filter = false;
      _.forOwn(candidate, field => {
        filter =
          filter ||
          (field &&
            _.includes(
              field.toString().toLowerCase(),
              filterString.toLowerCase()
            ));
      });
      return filter;
    });

    data = _.sortBy(data, orderBy);
    //działa odwrotnie bo material-ui/Table ma przy asc strzałkę w górę
    data = order === 'asc' ? _.reverse(data) : data;

    return (
      <React.Fragment>
        <PageHeader title={t('summary:menu.summaryShortTitle')} />
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
            <SummaryIndexMenu
              itemSelected={this.props.match.params.filter}
              handleClick={this.handleClick}
              handleClose={this.handleClose}
              anchorEl={anchorEl}
            />
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <Paper className={classes.paperContainer} elevation={0}>
                    <SummaryLeftDescription
                      params={this.props.match.params.type}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Paper
                    className={classes.paperContainerSection}
                    elevation={0}
                  >
                    <Typography variant="h5" className={classes.sectionTitle}>
                      {t('summary:menu.summaryTitle')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} align="flex-end">
                  <Paper
                    className={classes.paperContainerSection}
                    elevation={0}
                  >
                    <DefaultSearchField
                      placeholder={t('summary:searchProjectBarTitle')}
                      onChangeHandler={this.setFilterString}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {isLoading ? (
                    <LinearProgress />
                  ) : data.length === 0 ? (
                    <AtsEmptyContainer text={t('summary:projectListEmpty')} />
                  ) : (
                    <Table>
                      <SummaryCandidateViewHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        classes={classes}
                        t={t}
                      />
                      <TableBody>
                        {data
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map(n => {
                            return <SummaryRowComponent key={n.id} data={n} />;
                          })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            labelRowsPerPage={t('pagination.elementsPerPage')}
                            labelDisplayedRows={({ from, to, count }) =>
                              t('pagination.pagesFromTo', { from, to, count })
                            }
                            colSpan={columnData.length + 1}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            page={page}
                            backIconButtonProps={{
                              'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                              'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    summaryList: store.summary.list,
  };
};

const mapDispatchToProps = {
  getSummary,
  setLastPage,
};

SummaryForCandidateIndexView.propTypes = {
  summaryList: PropTypes.array.isRequired,
  getSummary: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(SummaryForCandidateIndexView)));
