import { withStyles } from '@material-ui/core/styles';
import MenuBarRight from './MenuBarRight';

const styles = theme => ({
  menuContentLocation: {
    textAlign: 'right',
  },
  menuContentBlockInline: {
    marginRight: 10,
    display: 'inline-block',
  },
});

export default withStyles(styles)(MenuBarRight);
