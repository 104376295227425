import { Grid, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { enums } from 'shared/enums';
import PopUpATS1ATS2 from 'shared/newComponents/PopUpATS1ATS2';
import PopUpATS2ATS3 from 'shared/newComponents/PopUpATS2ATS3';
import PopUpATS3ATS4 from 'shared/newComponents/PopUpATS3ATS4';
import PopUpATS4ATS5 from 'shared/newComponents/PopUpATS4ATS5';
import {
  ProjectCandidateV2Service,
  ProjectOrderService,
  ProjectEmploymentProcessService,
  ProjectV2Service,
} from 'shared/services';
import FilterATS from '../../../../../components/FilterATS';
import Kanban from '../../../../../components/Kanban';
import AtsCardView from '../../../ProjectAts/components/AtsCardView';
import AtsHeader from '../../../ProjectAts/components/AtsHeader';
import { handleAtsEmploymentProcessFilter } from '../../../ProjectAts/utils/utils';
import { styles } from './AtsEmploymentProcessView.styles';

const AtsEmploymentProcessView = ({ project, t, classes }) => {
  const [projectOrders, setProjectOrders] = useState([]);
  const [projectEmploymentProcess, setProjectEmploymentProcess] =
    useState(null);
  const [projectOrderLocalizations, setProjectOrderLocalizations] = useState([
    [],
  ]);

  const stages = [
    {
      number: 1,
      name: t`atsEmploymentProcess:selectedCandidates`,
      isDraggable: false,
      draggableOnModal: true,
      color: '#D86713',
      selectAll: true,
    },
    {
      number: 2,
      name: t`atsEmploymentProcess:docs`,
      isDraggable: false,
      draggableOnModal: true,
      color: '#D86713',
      selectAll: false,
    },
    {
      number: 3,
      name: t`atsEmploymentProcess:receiveDocs`,
      isDraggable: false,
      draggableOnModal: true,
      color: '#D86713',
      selectAll: true,
    },
    {
      number: 4,
      name: t`atsEmploymentProcess:candidatesAwaitingDocs`,
      isDraggable: false,
      draggableOnModal: true,
      color: '#4DD8F0',
      selectAll: false,
    },
    {
      number: 5,
      name: t`atsEmploymentProcess:ready`,
      isDraggable: false,
      draggableOnModal: true,
      color: '#D86713',
      selectAll: true,
    },
  ];

  const [candidates, setCandidates] = useState([]);
  const [candidatesActive, setCandidatesActive] = useState([]);
  const [candidatesRejected, setCandidatesRejected] = useState([]);
  const [currentSelectAllColumn, setCurrentSelectAllColumn] = useState(null);
  const [checkedCandidate, setCheckedCandidate] = useState([]);
  const [currentCheckedCandidateStage, setCurrentCheckedCandidateStage] =
    useState(null);

  const [filters, setFilters] = useState({});
  const [sortHeader, setSortHeader] = useState(stages.map(() => 'off'));

  const loadProjectOrders = () => {
    ProjectOrderService.getProjectOrderFromProject(project.id)
      .then(({ data }) => {
        setProjectOrders(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const loadProjectCandidate = () => {
    ProjectCandidateV2Service.getProjectCandidateEmploymentProcess(project.id)
      .then(({ data }) => {
        const filteredData = handleAtsEmploymentProcessFilter(filters, data);
        setCandidates(filteredData);
        resetCheckedCandidates();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const setStageAttribute = projectCandidates => {
    return projectCandidates.map(projectCandidate => {
      const stage = stages.find(
        stage => stage.number === projectCandidate.stageNumber
      );

      const isCandidateChecked = checkedCandidate.includes(
        projectCandidate.candidate.id
      );

      const isMentorCoordinator = project.permissions.isMentorCoordinator;

      return {
        ...projectCandidate,
        isChecked: !!isCandidateChecked,
        column: stage.number - 1,
        stage: stage,
        isDraggable: !isMentorCoordinator,
      };
    });
  };

  const sortCandidates = filteredData => {
    const sortedArray = [];
    sortHeader.forEach((item, index) => {
      const partitionArray = _.partition(filteredData, [
        'stageNumber',
        index + 1,
      ]);
      const toSort =
        item !== 'off'
          ? _.orderBy(partitionArray[0], ['candidate.lastName'], item)
          : partitionArray[0];
      sortedArray.push(toSort);
    });
    return sortedArray.length ? _.flatten(sortedArray) : filteredData;
  };
  const filterCandidates = () => {
    const candidatesActiveFilter = candidates.filter(
      candidate => !candidate.isRejected
    );
    const candidatesRejectedFilter = candidates.filter(
      candidate => candidate.isRejected
    );

    const finalResultCandidateActive = setStageAttribute(
      candidatesActiveFilter
    );
    const finalResultCandidateRejected = setStageAttribute(
      candidatesRejectedFilter
    );

    setCandidatesActive(sortCandidates(finalResultCandidateActive));
    setCandidatesRejected(sortCandidates(finalResultCandidateRejected));
  };

  useEffect(() => {
    filterCandidates();
  }, [candidates]);

  useEffect(() => {
    loadProjectOrders();
    loadProjectCandidate();
    getProjectOrderLocations();
  }, []);

  const getProjectOrderLocations = () => {
    ProjectV2Service.getProjectLocalizations(project.id)
      .then(({ data }) => {
        setProjectOrderLocalizations(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleOverwriteCandidate = (
    stageNumber,
    projectCandidateEmploymentProcess
  ) => {
    const candidatesToOverwrite = candidates.map(item => {
      if (item.id === projectCandidateEmploymentProcess.id) {
        return { ...item, stageNumber: stageNumber };
      }
      return item;
    });
    setCandidates(candidatesToOverwrite);
  };

  const updateStageOnly = (setStage, projectCandidateEmploymentProcess) => {
    handleOverwriteCandidate(setStage, projectCandidateEmploymentProcess);
    ProjectEmploymentProcessService.updateProjectEmploymentProcessStageOnly(
      {
        projectCandidateEmploymentProcesses: [
          projectCandidateEmploymentProcess.id,
        ],
      },
      setStage
    )
      .then(() => {
        loadProjectCandidate();
      })
      .catch(error => {
        console.log(error);
        loadProjectCandidate();
      });
  };

  const validatePermissionToOpenModals = stageNumber => {
    const { permissions = {} } = project;
    const {
      isOwner = false,
      isMentor = false,
      isSuperUser = false,
      isProjectOrganizationOwner = false,
      isMentorCoordinator = false,
      isMentorLegalization = false,
    } = permissions;
    if (
      isMentor &&
      isMentorCoordinator &&
      stageNumber <=
        enums.ATS_EMPLOYMENT_PROCESS_STAGES.STAGE_CANDIDATE_SEND_FOR_DOCUMENTS
    ) {
      return true;
    }
    return (
      isOwner ||
      isSuperUser ||
      isProjectOrganizationOwner ||
      (isMentor && isMentorLegalization)
    );
  };
  const handleOpenAtsModalByStageNumber = stageNumber => {
    if (!validatePermissionToOpenModals(stageNumber)) {
      return;
    }
    if (project.closedStatus === enums.PROJECT_CLOSED_STATUS.PROJECT_CLOSED) {
      alert(t`atsEmploymentProcess:closed`);
      return;
    }
    stageNumber === 1 && setIsOpenATS1ATS2(true);
    stageNumber === 2 && setIsOpenATS2ATS3(true);
    stageNumber === 3 && setIsOpenATS3ATS4(true);
    stageNumber === 4 && setIsOpenATS4ATS5(true);
  };

  const resetCheckedCandidates = () => {
    setCurrentSelectAllColumn(null);
    setCurrentCheckedCandidateStage(null);
    setCheckedCandidate([]);
  };

  const handleOpenAtsModal = (
    projectCandidateEmploymentProcess,
    stageNumberFromKanban,
    editByBtn = false
  ) => {
    if (editByBtn) {
      resetCheckedCandidates();
    }
    const { stageNumber } = projectCandidateEmploymentProcess;
    setProjectEmploymentProcess(projectCandidateEmploymentProcess);
    if (!(stageNumber + 1 === stageNumberFromKanban) && !editByBtn) {
      if (stageNumber > 2) {
        updateStageOnly(
          stageNumberFromKanban,
          projectCandidateEmploymentProcess
        );
      }

      return null;
    }
    handleOpenAtsModalByStageNumber(editByBtn ? stageNumber - 1 : stageNumber);
  };

  useEffect(() => {
    filterCandidates();
  }, [checkedCandidate]);

  useEffect(() => {
    loadProjectCandidate();
  }, [filters]);

  const handleChangeCheckbox = (indexCandidate, stage, columnSelect = null) => {
    const checkedUsersArray = [];
    const isDifferentStage = stage !== currentCheckedCandidateStage;

    //Select all
    if (
      (!currentSelectAllColumn &&
        !indexCandidate &&
        currentSelectAllColumn !== columnSelect) ||
      (!indexCandidate && currentSelectAllColumn !== columnSelect)
    ) {
      const stageCandidates = _.filter(candidatesActive, [
        'stageNumber',
        columnSelect,
      ]);

      stageCandidates.forEach(item => {
        checkedUsersArray.push(item.candidate.id);
      });
    }
    const isCandidateInCheckedArray = checkedCandidate.includes(indexCandidate);

    // Klikniecie na kadydata i zaznaczony select all
    if (indexCandidate) {
      if (isCandidateInCheckedArray) {
        checkedCandidate.forEach(item => {
          if (item !== indexCandidate && !isDifferentStage) {
            checkedUsersArray.push(item);
          }
        });
      } else {
        checkedCandidate.forEach(item => {
          if (!isDifferentStage) {
            checkedUsersArray.push(item);
          }
        });
        checkedUsersArray.push(indexCandidate);
      }
    }

    setCurrentSelectAllColumn(
      currentSelectAllColumn === columnSelect ? null : columnSelect
    );
    setCurrentCheckedCandidateStage(stage ? stage : columnSelect);
    setCheckedCandidate(checkedUsersArray);
  };

  const getContent = item => {
    const { obj } = item;
    const { candidate = {} } = obj;
    return (
      <div key={item.i}>
        <AtsCardView
          variant={'employmentProcess'}
          handleOpenAtsModal={handleOpenAtsModal}
          item={item}
          candidate={candidate}
          obj={obj}
          project={project}
          handleChangeCheckbox={handleChangeCheckbox}
          handleOpenCandidateInProject={() => {}}
          handleOpenSetCandidateInProject={() => {}}
          parseCandidatesList={loadProjectCandidate}
        />
      </div>
    );
  };

  const getHeaders = () => {
    return stages;
  };

  const translateObject = headerName => {
    return headerName;
  };

  const handleChangeSelectAllCheckbox = async column => {
    handleChangeCheckbox(null, null, column);
  };

  const sortCell = (headerIndex, type) => {
    const updateState = [...sortHeader];
    updateState[headerIndex - 1] = type;
    setSortHeader(updateState);
    loadProjectCandidate();
  };

  const getCandidatesPerStage = () => {
    const stagesCounter = [];
    candidates.forEach(item => {
      if (!item.isRejected) {
        stagesCounter[item.stageNumber]
          ? stagesCounter[item.stageNumber]++
          : (stagesCounter[item.stageNumber] = 1);
      }
    });
    return stagesCounter;
  };

  const handleMoveCandidateToNextStage = () => {
    const isMentorCoordinator = project.permissions.isMentorCoordinator;

    if (currentCheckedCandidateStage !== 5) {
      return null;
    }

    if (isMentorCoordinator) {
      return null;
    }
    const selectedCandidates = filterCheckedCandidatesByStage(
      checkedCandidate,
      5
    );

    if (selectedCandidates.length) {
      const body = {
        projectCandidateEmploymentProcesses: selectedCandidates.map(
          item => item.id
        ),
      };
      ProjectEmploymentProcessService.addProjectEmploymentManagement(body)
        .then(() => {
          resetCheckedCandidates();
          loadProjectCandidate();
        })
        .catch(error => {
          console.log(error);
          alert(t`adminCitySeo:error`);
        });
    }
  };

  const getHeaderContent = item => {
    return (
      <div key={item.i}>
        <AtsHeader
          candidatesPerStage={getCandidatesPerStage()}
          sortHeader={sortHeader}
          currentSelectAllColumn={currentSelectAllColumn}
          sortCell={sortCell}
          handleChangeSelectAllCheckbox={handleChangeSelectAllCheckbox}
          headerObject={item}
          translateObject={translateObject}
          stageToMoveCandidateToNextStage={5}
          handleMoveCandidateToNextStage={handleMoveCandidateToNextStage}
        />
      </div>
    );
  };
  const saveColumnOrder = async (order, selectedElement = null) => {
    const motherObject = _.filter(order, [
      'candidateId',
      parseInt(selectedElement),
    ]);
    if (motherObject) {
      const projectCandidateEmploymentProcess = candidates.find(
        candidate => candidate.candidate.id === motherObject[0].candidateId
      );
      handleOpenAtsModal(
        projectCandidateEmploymentProcess,
        motherObject[0].stageNumber
      );
    }
  };
  const activeCandidatesContent = (
    <Kanban
      elements={candidatesActive}
      getContent={getContent}
      getHeaders={getHeaders}
      getHeaderContent={getHeaderContent}
      saveColumnOrder={saveColumnOrder}
      onDragStartHandler={() => {}}
      onDragStopHandler={() => {}}
      elementRows={2}
      rowHeight={70}
      cols={5}
    />
  );

  const rejectedCandidatesContent = (
    <Kanban
      isLocked={true}
      elements={candidatesRejected}
      getContent={getContent}
      saveColumnOrder={() => {}}
      onDragStartHandler={() => {}}
      onDragStopHandler={() => {}}
      elementRows={2}
      rowHeight={70}
      cols={5}
    />
  );

  const [isOpenATS1ATS2, setIsOpenATS1ATS2] = useState(false);
  const [isOpenATS2ATS3, setIsOpenATS2ATS3] = useState(false);
  const [isOpenATS3ATS4, setIsOpenATS3ATS4] = useState(false);
  const [isOpenATS4ATS5, setIsOpenATS4ATS5] = useState(false);

  const filterCheckedCandidatesByStage = (checkedCandidate, stepToFilter) => {
    return candidates.filter(
      item =>
        checkedCandidate.includes(item.candidate.id) &&
        item.stageNumber === stepToFilter
    );
  };
  const filterCandidatesForMultiEdit = (stepToFilter, fullObject = false) => {
    const selectedCandidates = filterCheckedCandidatesByStage(
      checkedCandidate,
      stepToFilter
    );

    return fullObject
      ? selectedCandidates
      : selectedCandidates.map(item => item.id);
  };
  return (
    <>
      {isOpenATS1ATS2 && (
        <PopUpATS1ATS2
          project={project}
          projectOrderLocalizations={projectOrderLocalizations}
          projectEmploymentProcess={projectEmploymentProcess}
          checkedCandidate={filterCandidatesForMultiEdit(1, true)}
          isOpen={isOpenATS1ATS2}
          setIsOpen={setIsOpenATS1ATS2}
          onClose={() => setIsOpenATS1ATS2(false)}
          loadProjectCandidate={loadProjectCandidate}
          projectOrders={projectOrders}
          t={t}
        />
      )}
      {isOpenATS2ATS3 && (
        <PopUpATS2ATS3
          projectEmploymentProcess={projectEmploymentProcess}
          project={project}
          isOpen={isOpenATS2ATS3}
          setIsOpen={setIsOpenATS2ATS3}
          onClose={() => {
            setIsOpenATS2ATS3(false);
            loadProjectCandidate();
          }}
          loadProjectCandidate={loadProjectCandidate}
          t={t}
        />
      )}
      {isOpenATS3ATS4 && (
        <PopUpATS3ATS4
          projectOrderLocalizations={projectOrderLocalizations}
          projectEmploymentProcess={projectEmploymentProcess}
          checkedCandidate={filterCandidatesForMultiEdit(3)}
          isOpen={isOpenATS3ATS4}
          setIsOpen={setIsOpenATS3ATS4}
          onClose={() => setIsOpenATS3ATS4(false)}
          projectOrders={projectOrders}
          loadProjectCandidate={loadProjectCandidate}
          t={t}
          project={project}
        />
      )}
      {isOpenATS4ATS5 && (
        <PopUpATS4ATS5
          projectOrderLocalizations={projectOrderLocalizations}
          projectEmploymentProcess={projectEmploymentProcess}
          isOpen={isOpenATS4ATS5}
          setIsOpen={setIsOpenATS4ATS5}
          projectOrders={projectOrders}
          onClose={() => {
            setIsOpenATS4ATS5(false);
            loadProjectCandidate();
          }}
          loadProjectCandidate={loadProjectCandidate}
          t={t}
          project={project}
        />
      )}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FilterATS
            variant={'employmentProcess'}
            parseCandidatesList={loadProjectCandidate}
            setFilters={setFilters}
            projectOrders={projectOrders}
          />
        </Grid>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="p" className={classes.atsSubtext}>
              {t`menu:candidatesInAts`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {activeCandidatesContent}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="p" className={classes.atsSubtext}>
              {t`menu:rejected`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {rejectedCandidatesContent}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(AtsEmploymentProcessView))
);
