import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadAssets } from 'shared/services/assetService';
import { editCandidate } from 'shared/services/candidateService';
import {
  editRecruiter,
  editRecruiterAssets,
} from 'shared/services/recruiterService';
import AssetsUpload from './asset/AssetsUpload';

export class UserAvatar extends Component {
  uploadAssets = async (files, assetType) => {
    const {
      user,
      updateUser,
      editCandidate,
      editRecruiterAssets,
      uploadAssets,
    } = this.props;
    let { data } = await uploadAssets(files, assetType);
    let assets = [];
    Array.isArray(data) &&
      data.forEach(file => {
        assets.push(file.id);
      });

    if (this.props.updateRecruiterV2) {
      await updateUser();
    } else {
      user.isRecruiter
        ? await editRecruiterAssets({ id: user.id, assets })
        : await editCandidate({ id: user.id, assets });

      await updateUser();
    }
  };

  getAssets = async () => {
    const { user, updateUser } = this.props;
    await updateUser();
    return _.filter(user.assets, { type: 'avatar' });
  };

  render() {
    const { user } = this.props;

    return (
      user && (
        <AssetsUpload
          uploadAssetsHandler={this.uploadAssets}
          getAssetsHandler={this.getAssets}
          acceptedFiles={['image/*']}
          assets={user.assets}
          filesLimit={1}
          assetType={'avatar'}
        >
          {this.props.children}
        </AssetsUpload>
      )
    );
  }
}

const mapDispatchToProps = {
  uploadAssets,
  editRecruiter,
  editCandidate,
  editRecruiterAssets,
};

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  uploadAssets: PropTypes.func.isRequired,
  editRecruiter: PropTypes.func.isRequired,
  editCandidate: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(UserAvatar);
