import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PATHS } from 'config';
import { LANDING_PAGE_URL } from 'shared/constants';
import LandingLayout from '../../../components/LandingLayout/';
import ForgotPasswordForm from './../components/ForgotPasswordForm/';

export class ForgotPassword extends Component {
  componentDidMount() {
    document.body.style.display = 'none';
    window.location = `${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.AUTH.FORGOT_PASSWORD}`;
  }

  handleSubmit = async values => {
    const { t } = this.props;
    try {
      const { status } = await this.props.forgotPassword(values);
      if (status === 200) {
        this.props.showSnackbarMessage(
          t('login:form.emailToResetPasswordSend')
        );
        await this.props.logoutUser();
        this.props.history.push(PATHS.AUTH.LOGIN);
      } else {
        throw new Error('Unknown response status');
      }
    } catch (error) {
      this.props.showSnackbarMessage(t('form.error.errorOccurred'));
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <LandingLayout>
        <Grid item xs={11} className={classes.formContainer}>
          <ForgotPasswordForm onSubmit={this.handleSubmit} />
        </Grid>
      </LandingLayout>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default ForgotPassword;
