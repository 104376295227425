import {
  Checkbox as BaseCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  withStyles,
} from '@material-ui/core';
import { map, get, find, remove, has } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Translate } from '..';
import { styles } from './CheckboxGroup.styles';

const ButtonCheckbox = ({ checked, onChange, name, classes, label }) => (
  <FormControlLabel
    control={
      <BaseCheckbox
        checked={checked}
        onChange={onChange}
        name={name}
        classes={{ root: classes.root }}
      />
    }
    label={<Translate text={label} variant="body1" />}
    classes={{
      label: checked ? classes.labelChecked : classes.label,
      root: classes.labelRoot,
    }}
  />
);

const CheckboxGroup = ({
  classes,
  items,
  name,
  label,
  formik,
  variant = 'checkbox',
  iterateFrom = 0,
  direction = 'row',
  labelStyle,
  touched = false,
  errorPath = 'name',
}) => {
  const [formState, setFormState] = useState([]);

  useEffect(() => {
    setFormState(
      map(get(formik.values, name), item => ({
        id: item.id,
        name: item.name,
      })) || []
    );
  }, [get(formik.values, name)]);

  const isRadio = variant === 'radio';

  const handleChange = event => {
    const formItems = get(formik.values, name);
    const isItemExist = !!find(
      formItems,
      o =>
        get(items[parseInt(o.id - iterateFrom)], 'name') === event.target.name
    );
    const selectedItem = find(
      items,
      item => get(item, 'name') === event.target.name
    );
    const reducedSelectedItem = {
      id: selectedItem.id,
      name: selectedItem.name,
    };
    const newFormState = isRadio
      ? reducedSelectedItem
      : isItemExist
      ? remove(
          formState,
          o => get(items[o.id - iterateFrom], 'name') !== event.target.name
        )
      : [...formState, reducedSelectedItem];
    formik.setFieldValue(name, newFormState);
  };

  const isError = !touched
    ? has(formik, `touched.${name}`) && Boolean(get(formik.errors, errorPath))
    : Boolean(get(formik.errors, name));

  return (
    <Grid container className={classes.container}>
      <Grid container>
        <Translate
          style={labelStyle ? labelStyle : null}
          text={label}
          variant="h6"
        />
      </Grid>
      <FormControl component="fieldset">
        {variant === 'button' && (
          <FormGroup row={direction === 'row'} name={name}>
            {map(items, item => (
              <ButtonCheckbox
                checked={
                  !!find(get(formik.values, name), o => o.id === item.id)
                }
                onChange={handleChange}
                name={item.name}
                classes={classes}
                label={item.name}
                key={item.id}
              />
            ))}
          </FormGroup>
        )}
        {variant === 'radio' && (
          <FormGroup row={direction === 'row'} name={name}>
            {map(items, item => (
              <FormControlLabel
                key={item.name}
                control={
                  <Radio
                    checked={
                      !!find(get(formik.values, name), o => o === item.id)
                    }
                    onChange={handleChange}
                    name={item.name}
                    color="primary"
                  />
                }
                label={<Translate text={item.name} variant="body1" />}
              />
            ))}
          </FormGroup>
        )}
        {variant === 'checkbox' && (
          <FormGroup row={direction === 'row'} name={name}>
            {map(items, item => (
              <FormControlLabel
                key={item.name}
                control={
                  <BaseCheckbox
                    checked={
                      !!find(get(formik.values, name), o => o.id === item.id)
                    }
                    onChange={handleChange}
                    name={item.name}
                    color="primary"
                  />
                }
                label={<Translate text={item.name} variant="body1" />}
              />
            ))}
          </FormGroup>
        )}
        {isError && (
          <FormHelperText error={isError}>
            {!touched
              ? has(formik.touched, name) && get(formik.errors, errorPath)
              : get(formik.errors, errorPath)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(CheckboxGroup);
