import { API } from '../constants';
import { ApiService } from './api.service';

export const DepartmentService = {
  getDepartments() {
    return ApiService.get(API.GET.DEPARTMENT.GET);
  },
};

export default DepartmentService;
