import { Grid, Paper, withStyles } from '@material-ui/core';
import { get, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { styles } from './GeneralInformations.styles';
import { MainContent, SideView, RecruiterInformations } from './components';
import CandidateContent from './components/CandidateContent';
import OrganizationJoinContent from './components/OrganizationJoinContent';
import RecruiterContent from './components/RecruiterContent';

const GeneralInformations = ({
  fetchProject,
  projectDetails,
  classes,
  ...props
}) => {
  const calcCandidateExperience = get(
    projectDetails,
    'calcCandidateExperience'
  );
  const calcServiceCompletionDate = get(
    projectDetails,
    'calcServiceCompletionDate'
  );
  const calcPaymentDeadline = get(projectDetails, 'calcPaymentDeadline');
  const calcCandidateWarranty = get(projectDetails, 'calcCandidateWarranty');
  const hintForRecruiter = get(projectDetails, 'hintForRecruiter');

  const [showOrganizationJoinBtn, setShowOrganizationJoinBtn] = useState(false);
  const [notificationToAccept, setNotificationToAccept] = useState(null);

  const showComponent =
    !isNil(calcCandidateExperience) ||
    !isNil(calcServiceCompletionDate) ||
    !isNil(calcPaymentDeadline) ||
    !isNil(calcCandidateWarranty) ||
    hintForRecruiter;

  const isRequestToJointToOrganization = () => {
    props.notifications.forEach(notifiaction => {
      if (
        notifiaction.category === 'organization' &&
        notifiaction.type === 'organization_project_to_approve' &&
        notifiaction.data.project.id === projectDetails.id
      ) {
        setShowOrganizationJoinBtn(true);
        setNotificationToAccept(notifiaction);
      }
    });
  };

  useEffect(() => {
    isRequestToJointToOrganization();
  }, props.notifications);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container>
          <Paper className={classes.paperRoot}>
            <SideView
              fetchProject={fetchProject}
              projectDetails={projectDetails}
              currentUser={props.currentUser}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Paper className={classes.paperRoot}>
            <MainContent
              projectDetails={projectDetails}
              currentUser={props.currentUser}
            />
          </Paper>
        </Grid>
      </Grid>
      {showComponent && (
        <Grid item xs={12}>
          <Grid container>
            <Paper className={classes.paperRoot}>
              <RecruiterInformations
                currentUser={props.currentUser}
                projectDetails={projectDetails}
                calcCandidateExperience={calcCandidateExperience}
                calcServiceCompletionDate={calcServiceCompletionDate}
                calcPaymentDeadline={calcPaymentDeadline}
                calcCandidateWarranty={calcCandidateWarranty}
                hintForRecruiter={hintForRecruiter}
              />
              {props.currentUser.isRecruiter &&
              props.currentUser.id !== projectDetails.owner.id ? (
                <Grid item xs={12}>
                  <Grid container>
                    <Paper className={classes.paperRoot}>
                      <RecruiterContent projectDetails={projectDetails} />
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
            </Paper>
          </Grid>
        </Grid>
      )}
      {!props.currentUser.isRecruiter ? (
        <Grid item xs={12}>
          <Grid container>
            <Paper className={classes.paperRoot}>
              <CandidateContent projectDetails={projectDetails} />
            </Paper>
          </Grid>
        </Grid>
      ) : null}
      {showOrganizationJoinBtn ? (
        <Grid item xs={12}>
          <Grid container>
            <Paper className={classes.paperRoot}>
              <OrganizationJoinContent
                notificationToAccept={notificationToAccept}
                projectDetails={projectDetails}
              />
            </Paper>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    notifications: store.notifications.list,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(GeneralInformations));
