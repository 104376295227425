import { object, string } from 'yup';

export const MODEL = {
  positionDescription: 'positionDescription',
  benefits: 'benefits',
};

export const validationSchema = t =>
  object().shape({
    positionDescription: string(),
    benefits: string(),
  });
