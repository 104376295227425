import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  addCompetenceKind,
  deleteCompetenceKind,
  editCompetenceKind,
  getCompetenceKindsWithLimit,
  getCompetenceKindsWithoutDispatch,
} from 'shared/services/competenceKindService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import AddSkillForm from '../../../components/AddSkillForm/AddSkillForm';
import CustomDialog from '../../../components/CustomDialog/index';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog/LayoutConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import AdminSkillViewHead from '../AdminSkillView/AdminSkillViewHead';
import AdminCompetenceKindViewHead from './AdminCompetenceKindViewHead';

export class AdminCompetenceKindView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dictionariesKinds: [],
      competenceKinds: [],
      competenceKindsCount: 0,
      order: 'asc',
      orderBy: 'id',
      deleteConfirmationOpened: false,
      openEditDialog: false,
      selectedCompetenceKind: {},
      options: {
        limit: 12,
        offset: 0,
      },
      isClicked: false,
      isSubmitting: false,
    };
  }

  handleSorting = async (event, property) => {
    const orderBy = property;
    let order = 'asc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'asc') {
      order = 'desc';
    }

    await this.setState({ order, orderBy });
    this.handleReloadElements();
  };

  openRemoveDialog = competenceKind => {
    this.setState({
      selectedCompetenceKind: competenceKind,
      deleteConfirmationOpened: true,
    });
  };

  handleRemove = async isOk => {
    if (isOk) {
      await deleteCompetenceKind(this.state.selectedCompetenceKind.id);
      this.handleReloadElements();
    }
    this.setState({
      deleteConfirmationOpened: false,
    });
  };

  onCloseEditDialog = () => {
    this.setState({
      selectedCompetenceKind: null,
      openEditDialog: false,
    });
  };

  onOpenEditDialog = competenceKind => {
    this.setState({
      selectedCompetenceKind: competenceKind,
      openEditDialog: true,
    });
  };

  handleUpdateCompetenceKind = async values => {
    this.setState({
      isSubmitting: true,
    });
    const body = {
      name: values.name,
      deName: values.deName,
      enName: values.enName,
      uaName: values.uaName,
      isApproved: values.isApproved,
    };
    if (this.state.selectedCompetenceKind) {
      editCompetenceKind(this.state.selectedCompetenceKind.id, body)
        .then(() => {
          this.onCloseEditDialog();
          this.handleReloadElements();
          this.props.showSnackbarMessage('Zapisano zmiany');
          this.setState({
            isSubmitting: false,
          });
        })
        .catch(error => {
          const { status } = error.response;
          if (status === 409) {
            this.props.showSnackbarMessage(
              'Podant rodzaj kompetencji istnieje w systemie'
            );
          } else {
            this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
          }
          this.setState({
            isSubmitting: false,
          });
        });
    } else {
      addCompetenceKind(body)
        .then(() => {
          this.onCloseEditDialog();
          this.handleReloadElements();
          this.props.showSnackbarMessage('Zapisano zmiany');
          this.setState({
            isSubmitting: false,
          });
        })
        .catch(error => {
          const { status } = error.response;
          if (status === 409) {
            this.props.showSnackbarMessage(
              'Podant rodzaj kompetencji istnieje w systemie'
            );
          } else {
            this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
          }
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  loadData = async (options = {}) => {
    const { limit, offset, filters } = options || this.state.options;
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.url) || 12
      : limit;
    const { order, orderBy } = this.state;
    this.setState({
      isLoading: true,
    });
    if (filters && filters.isApproved) {
      delete filters['isApproved'];
    }
    const params = {
      filters,
      orderBy: {
        [orderBy]: order,
      },
    };

    getCompetenceKindsWithoutDispatch().then(response => {
      const { data } = response;
      this.setState({
        dictionariesKinds: data,
      });
    });

    getCompetenceKindsWithLimit(limitToSearch, offset, params).then(
      response => {
        const { data } = response;
        this.setState({
          competenceKinds: data.data,
          competenceKindsCount: data.count,
        });
      }
    );
    this.setState({
      isLoading: false,
    });
  };

  handleReloadElements = (options = false) => {
    if (options) {
      this.setState({
        options,
      });
    }
    if (!this.props.isLoading) {
      return this.loadData(options || this.state.options);
    } else {
      return null;
    }
  };

  componentWillMount() {
    this.handleReloadElements();
  }

  onOpenAddCompetenceKindDialog = () => {
    this.setState({
      selectedCompetenceKind: null,
      openEditDialog: true,
    });
  };

  getWrapper = ({ content, filtering, pagination }) => {
    const { order, orderBy } = this.state;
    return (
      <Paper>
        <PageHeader title="Lista dostępnych rodzajów kompetencji" />
        <Paper style={{ padding: 20 }} elevation={0}>
          {filtering}
        </Paper>
        <Button
          style={{ margin: '17px' }}
          onClick={() => this.onOpenAddCompetenceKindDialog()}
          variant="contained"
          color="primary"
        >
          Dodaj rodzaj kompetencji
        </Button>
        <Table>
          <TableHead>
            <AdminCompetenceKindViewHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <AdminSkillViewHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableFooter>
        </Table>
        {pagination}
        <CustomDialog
          title={
            this.state.selectedCompetenceKind
              ? 'Edycja rodzaju kompetencji'
              : 'Dodaj rodzaj kompetencji'
          }
          maxWidth={'lg'}
          isOpen={this.state.openEditDialog}
          onCloseDialog={this.onCloseEditDialog}
          body={
            <div
              style={{
                width: '600px',
              }}
            >
              <AddSkillForm
                dictionariesSkills={this.state.dictionariesKinds}
                skill={this.state.selectedCompetenceKind}
                handleUpdateSkill={this.handleUpdateCompetenceKind}
                isSubmitting={this.state.isSubmitting}
                withApproved={true}
              />
              <Button onClick={this.onCloseEditDialog} color="primary">
                Zamknij
              </Button>
            </div>
          }
        />
        <ConfirmationDialog
          open={this.state.deleteConfirmationOpened}
          titleText="Usuń umiejętność?"
          onClose={this.handleRemove}
          okButtonText="Usuń"
          cancelButtonText="Anuluj"
        >
          Czy na pewno usunąć?
        </ConfirmationDialog>
      </Paper>
    );
  };

  getContent = competenceKind => {
    return (
      <TableRow hover key={competenceKind.id}>
        <TableCell align="right">{competenceKind.id}</TableCell>
        <TableCell padding="none">{competenceKind.name}</TableCell>
        <TableCell padding="none">{competenceKind.enName}</TableCell>
        <TableCell padding="none">{competenceKind.deName}</TableCell>
        <TableCell padding="none">{competenceKind.uaName}</TableCell>
        <TableCell padding="none">
          {competenceKind.isApproved
            ? 'Zaakceptowany'
            : 'Oczekujący na akceptację'}
        </TableCell>
        <TableCell>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => this.onOpenEditDialog(competenceKind)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => this.openRemoveDialog(competenceKind)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { competenceKinds, competenceKindsCount } = this.state;
    const filteringOptions = [
      {
        key: 'name',
        type: 'text',
        name: 'Nazwa',
      },
    ];

    return (
      <ElementsListAsync
        storageKey={this.props.match.url}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        handleReloadElements={this.handleReloadElements}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        filteringOptions={filteringOptions}
        elements={competenceKinds || []}
        elementsCount={competenceKindsCount || 0}
        scrollUp={true}
      />
    );
  }
}

const mapDispatchToProps = {
  showSnackbarMessage,
};

AdminCompetenceKindView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  showSnackbarMessage: PropTypes.func.isRequired,
};
export default connect(null, mapDispatchToProps)(AdminCompetenceKindView);
