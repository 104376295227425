import { Grid, Paper, withStyles } from '@material-ui/core';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { CONST_CONFIG } from 'shared/constants';
import { setLastPage } from 'shared/services/authService';
import {
  sendPredictProjectsUser,
  checkPredictMLTask,
} from 'shared/services/mlService';
import {
  getProjectsCount,
  clearCurrentProjectsLimitedList,
  clearCurrentProjectsLimitedListCounter,
  getProjectsWithLimit,
  deleteProject,
} from 'shared/services/projectService';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import ConfirmationDialog from '../../components/LayoutConfirmationDialog/';
import PageHeader from '../../components/PageHeader';
import ProjectAdd from '../../components/ProjectAdd';
import TopMenu from '../../components/TopMenu';
import ProjectList from './ProjectList';

const styles = theme => ({
  dialog: {
    width: '80%',
    maxHeight: 435,
  },
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 0,
  },
});

export class ProjectIndexView extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mlTaskId: null,
      projects: [],
      selectedProject: null,
      confirmationOpened: false,
      load: false,
      isLoadingEdit: false,
      anchorEl: null,
      menuCounters: null,
      interval: null,
    };
  }

  menuRecruiterElements = [
    {
      name: t('project:menu.main.all'),
      route_url: 'all-projects',
      url: PATHS.PROJECT.INDEX,
      accessCandidate: true,
    },
    {
      name: t('project:menu.main.myprojects'),
      route_url: 'my-projects',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'my-projects'),
      accessCandidate: false,
    },
    {
      name: t('project:menu.main.mydrafts'),
      route_url: 'my-drafts',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'my-drafts'),
      accessCandidate: false,
    },
    {
      name: t('project:menu.main.involvement'),
      route_url: 'cooperation',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'cooperation'),
      accessCandidate: true,
    },
    {
      name: t('project:menu.main.acceptance'),
      route_url: 'acceptance',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'acceptance'),
      accessCandidate: true,
    },
    {
      name: t('project:menu.main.waitingToAcceptance'),
      route_url: 'waitingtoacceptance',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'waitingtoacceptance'),
      accessCandidate: true,
    },
    {
      name: t('project:menu.main.closed'),
      route_url: 'closed',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'closed'),
      accessCandidate: true,
    },
    {
      name: t('project:menu.main.hiringManager'),
      route_url: 'hiringmanageropened',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'hiringmanageropened'),
      accessCandidate: true,
      hideEmpty: true,
    },
    {
      name:
        t('project:menu.main.hiringManager') +
        ' ' +
        t('project:menu.main.closed'),
      route_url: 'hiringmanagerclosed',
      url: PATHS.PROJECT.INDEX_SHOW.replace(':show', 'hiringmanagerclosed'),
      accessCandidate: true,
      hideEmpty: true,
    },
  ];

  getFilterParameters(filterName) {
    const {
      currentUser: { isRecruiter },
    } = this.props;
    if (filterName === 'my-projects') {
      return { poOnly: true };
    } else if (filterName === 'recommend') {
      return { recommendOnly: true };
    } else if (filterName === 'my-drafts') {
      return { withDraft: true };
    } else if (filterName === 'cooperation') {
      let filteredData = null;
      if (isRecruiter) {
        filteredData = { coAndMentorOnly: true, confirmationStatus: '2,5' };
      } else {
        filteredData = { coOnly: true, confirmationStatus: '2,5' };
      }
      return filteredData;
    } else if (filterName === 'acceptance') {
      return { coAndPoAcceptance: true };
    } else if (filterName === 'waitingtoacceptance') {
      return { coOnly: true, confirmationStatus: 4 };
    } else if (filterName === 'closed') {
      return { closedStatus: '1,2', coAndPoOnly: true };
    } else if (filterName === 'hiringmanageropened') {
      return { hmOnly: true };
    } else if (filterName === 'hiringmanagerclosed') {
      return { hmOnly: true, closedStatus: '1,2' };
    } else {
      return { withPublic: true };
    }
  }

  async getMenuCounters() {
    let menuCounters = {};

    if (this.state.menuCounters) {
      return;
    }
    await Promise.all(
      this.menuRecruiterElements.map(async element => {
        const { count = 0 } = await this.getCounter(element.route_url);
        menuCounters[element.route_url] = count || 0;
      })
    );
    if (this._isMounted) {
      this.setState({ menuCounters });
    }
  }

  getTaskId = async (show, limit, offset, filters, orderBy) => {
    if (this.props.currentUser.isRecruiter) {
      return;
    }

    clearInterval(this.state.interval);
    this.setState({
      interval: null,
    });
    if (!this.state.mlTaskId) {
      try {
        const predictResponse = await this.props.sendPredictProjectsUser();
        this.setState({
          mlTaskId: predictResponse.data.taskId,
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (this.state.mlTaskId) {
      try {
        const response = await this.props.checkPredictMLTask(
          this.state.mlTaskId
        );
        if (response.status !== 200) {
          this.setState({
            interval: setInterval(() => {
              this.loadProjects(show, limit, offset, filters, orderBy);
            }, 3000),
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  loadProjects = async (
    show,
    limit,
    offset = 0,
    filters = null,
    orderBy = CONST_CONFIG.PROJECT.DEFAULT_SORT
  ) => {
    await this.getTaskId(show, limit, offset, filters, orderBy);
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.url) || 12
      : limit;
    const paramShow = show === undefined ? this.props.match.params.show : show;
    const filter = this.getFilterParameters(paramShow);

    let params = {
      ...filter,
      filters,
      orderBy,
    };

    if (filter.recommendOnly || filter.withPublic) {
      params.taskId = this.state.mlTaskId;
    }

    this.getMenuCounters();
    await this.props.getProjectsWithLimit(limitToSearch, offset, params);

    this.props.setLastPage(this.props.location.pathname);
    if (this._isMounted) {
      this.setState({ load: false });
    }
  };

  getCounter = async route => {
    const params = this.getFilterParameters(route);
    if (params.recommendOnly) {
      params.taskId = this.state.mlTaskId;
    }
    return await this.props.getProjectsCount(params);
  };

  handleReloadElements = ({ limit, offset, filters, orderBy }) => {
    return this.loadProjects(undefined, limit, offset, filters, orderBy);
  };

  handleDelete = async id => {
    try {
      await this.props.deleteProject(id);
      if (this._isMounted) {
        this.setState({
          selectedProject: null,
          confirmationOpened: false,
        });
      }
      this.loadProjects();
    } catch (err) {
      console.error(err);
      if (this._isMounted) {
        this.setState({
          selectedProject: null,
          confirmationOpened: false,
        });
      }
    }
  };

  handleConfirmationOpen = id => {
    if (this._isMounted) {
      this.setState({
        selectedProject: id,
        confirmationOpened: true,
      });
    }
  };

  handleConfirmationClose = isOk => {
    if (isOk) {
      this.handleDelete(this.state.selectedProject);
    } else {
      if (this._isMounted) {
        this.setState({
          selectedProject: null,
          confirmationOpened: false,
        });
      }
    }
  };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.show !== this.props.match.params.show) {
      await this.props.clearCurrentProjectsLimitedList();
      await this.props.clearCurrentProjectsLimitedListCounter();
      this.setState({ load: true });
    }
  }

  handleClick = event => {
    if (this._isMounted) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  handleClose = () => {
    if (this._isMounted) {
      this.setState({ anchorEl: null });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      await this.props.clearCurrentProjectsLimitedList();
      await this.props.clearCurrentProjectsLimitedListCounter();
      this.loadProjects();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, load, confirmationOpened, menuCounters } = this.state;
    return (
      <React.Fragment>
        <PageHeader
          title={t('project:list.pageTitle')}
          component={<ProjectAdd />}
        />
        <ConfirmationDialog
          classes={{
            paper: classes.dialog,
          }}
          open={confirmationOpened}
          titleText={t('project:list.removeProject')}
          onClose={this.handleConfirmationClose}
          okButtonText={t('action.remove')}
          cancelButtonText={t('action.cancel')}
        >
          {t('project:list.removeProjectConfirmation')}
        </ConfirmationDialog>
        <Grid
          container
          alignItems="flex-start"
          spacing={2}
          className={classes.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TopMenu
              handleClick={this.handleClick}
              handleClose={this.handleClose}
              counters={menuCounters}
              menuElements={this.menuRecruiterElements}
              anchorEl={anchorEl}
              itemSelected={this.props.match.params.show || 'all-projects'}
            />
            <Paper className={classes.rootPaper} elevation={0}>
              <ProjectList
                storageKey={this.props.match.url}
                handleReloadElements={this.handleReloadElements}
                handleDelete={this.handleConfirmationOpen}
                load={load}
              />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    projects: store.projects.currentProjectsLimitedList,
    projectsCount: store.projects.currentProjectsLimitedListCounter,
  };
};

const mapDispatchToProps = {
  sendPredictProjectsUser,
  checkPredictMLTask,
  clearCurrentProjectsLimitedList,
  clearCurrentProjectsLimitedListCounter,
  getProjectsWithLimit,
  deleteProject,
  getProjectsCount,
  setLastPage,
};

ProjectIndexView.propTypes = {
  classes: PropTypes.object.isRequired,
  getProjectsWithLimit: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  setLastPage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(ProjectIndexView))));
