import { connect } from 'react-redux';
import {
  hideSnackbarInformationMessage,
  hideSnackbarMessage,
} from 'shared/store/messages/actions';
import LayoutSnackBar from './LayoutSnackBar.style';

const mapStateToProps = store => {
  return {
    snackbarMessage: store.messages.snackbarMessage,
    open: store.messages.open,
    variant: store.messages.variant,
  };
};

const mapDispatchToProps = {
  hideSnackbarMessage,
  hideSnackbarInformationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSnackBar);
