import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { Translate } from 'shared/newComponents';
import NonContent from '../NonContent';
import AboutMeForm from './AboutMeForm/AboutMeForm';
import { styles } from './RecruiterAnonymousDetailsContentBottom.styles';

const RecruiterAnonymousDetailsContentBottom = ({
  history,
  recruiterData,
  recruiterEdited,
  isSelfEdit,
  classes,
  t,
  ...props
}) => {
  const [openForm, setOpenForm] = useState(false);

  const aboutMe = recruiterData.aboutMe;

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  if (!isSelfEdit && !aboutMe) {
    return null;
  }

  return (
    <Grid container>
      <Grid container alignContent={'center'}>
        <Grid item xs={12} sm={12} md={12} className={classes.itemWrapper}>
          <Paper className={classes.paperWrapper}>
            <Grid container className={classes.containerWrapper}>
              <Grid container className={classes.headerWrapper}>
                <Grid container>
                  <Grid item xs={10} sm={10} md={10}>
                    <Translate
                      text={t`recruiter:aboutMe`}
                      bold
                      className={classes.fontStyleBig}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    className={classes.editWrapper}
                  >
                    <div className={classes.imgWrapper}>
                      {isSelfEdit ? (
                        <img
                          alt="editIcon"
                          onClick={handleOpenForm}
                          src={EditIcon}
                          height={50}
                        />
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
                {aboutMe ? (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      {ReactHtmlParser(aboutMe)}
                    </Grid>
                  </Grid>
                ) : (
                  <NonContent showContent={isSelfEdit} />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {openForm ? (
        <AboutMeForm
          recruiterData={recruiterData}
          isOpen={openForm}
          onClose={handleCloseForm}
          recruiterEdited={recruiterEdited}
        />
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(RecruiterAnonymousDetailsContentBottom))
);
