import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import EmploymentManagementDialog from '../EmploymentManagementDialog';
import EmploymentManagementTable from '../EmploymentManagementTable';
import { styles } from './EmploymentManagementTableView.styles';

const EmploymentManagementTableView = ({
  t,
  classes,
  project,
  projectCandidatesEmploymentManagement,
  loadProjectCandidatesEmploymentManagement,
  shouldLoadAccommodations,
  accommodations,
  setSelectedOrders,
  selectedOrders,
  isCheckAll,
  setIsCheckAll,
  clearSelected,
  projectAvailableToMove,
  isLoading,
}) => {
  const [employmentDialog, setEmploymentDialog] = useState(false);

  const selectedFullObjects = projectCandidatesEmploymentManagement.filter(
    item => selectedOrders.includes(item.id)
  );

  const { isHiringManager } = project.permissions;

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}
        spacing={2}
      >
        <Grid item>
          <Typography component="p" className={classes.atsSubtext}>
            {t`projectOrderViewContent:employmentStatus`}
            <span className={classes.candidatesLength}>
              ({projectCandidatesEmploymentManagement.length})
            </span>
          </Typography>
        </Grid>
        <Grid item>
          {!isHiringManager && (
            <Button
              onClick={() => setEmploymentDialog(true)}
              variant="outlined"
              className={`${
                selectedOrders.length === 1 && classes.editBtnActive
              } ${classes.editBtn}`}
            >{t`employmentStatusTable:editStatusBtn`}</Button>
          )}
        </Grid>
      </Grid>
      <EmploymentManagementTable
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        variant="active"
        project={project}
        projectCandidatesEmploymentManagement={
          projectCandidatesEmploymentManagement
        }
        isCheckAll={isCheckAll}
        setIsCheckAll={setIsCheckAll}
        loadProjectCandidatesEmploymentManagement={
          loadProjectCandidatesEmploymentManagement
        }
        clearSelected={clearSelected}
        accommodations={accommodations}
        shouldLoadAccommodations={shouldLoadAccommodations}
        projectAvailableToMove={projectAvailableToMove}
        selectedFullObjects={selectedFullObjects}
        disableAllClick={isLoading}
      />
      {employmentDialog && (
        <EmploymentManagementDialog
          projectAvailableToMove={projectAvailableToMove}
          project={project}
          clearSelected={clearSelected}
          shouldLoadAccommodations={shouldLoadAccommodations}
          accommodations={accommodations}
          variant="active"
          loadProjectCandidatesEmploymentManagement={
            loadProjectCandidatesEmploymentManagement
          }
          isOpen={employmentDialog}
          selectedFullObjects={selectedFullObjects}
          onClose={() => {
            loadProjectCandidatesEmploymentManagement();
            setEmploymentDialog(false);
          }}
        />
      )}
    </>
  );
};

export default withStyles(styles)(
  withTranslation()(EmploymentManagementTableView)
);
