import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Translate } from 'shared/newComponents';
import { deleteUser, logoutUser } from 'shared/services';
import CustomDialog from '../../../../../../../components/CustomDialog';
import RecruiterOrganizationRecruitersShowView from '../../../../../RecruiterOrganizationRecruitersShowView';
import { styles } from './RecruiterInOrganization.styles';

const RecruiterInOrganization = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const userOrganization =
    recruiterData.organization &&
    [2, 5, 9].includes(recruiterData.organizationConfirmation)
      ? recruiterData.organization
      : null;
  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };
  if (!userOrganization) {
    return null;
  }
  return (
    <Grid container className={classes.gridTopMargin}>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.gridWrapper}>
          <Grid container className={classes.gridPadding} alignItems={'center'}>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={`organization:recruitersIn`}
                bold
                className={classes.bigFont}
              />
              <Translate
                text={'organization:browse&add'}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              onClick={onOpen}
              className={classes.gridTopMargin}
            >
              <Button label={t`organization:browseOrAdd`} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <CustomDialog
        maxWidth={'lx'}
        title={''}
        isOpen={isOpen}
        onCloseDialog={onClose}
        body={
          <Grid container>
            <RecruiterOrganizationRecruitersShowView />
          </Grid>
        }
      />
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  deleteUser,
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(RecruiterInOrganization))));
