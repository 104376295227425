import {
  Button,
  CircularProgress,
  Avatar,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
} from '@material-ui/icons';
import { lowerCase, find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getImageFromSource } from 'shared/utils/assets';

const styles = theme => ({
  avatar: {
    width: 30,
    height: 30,
  },
  icon: {
    color: '#afafaf',
  },
  listItem: {
    paddingLeft: '12px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.3s',
    '&:hover': {
      background: '#f9f9f9',
    },
  },
  listItemText: {
    paddingLeft: '10px',
    maxWidth: 270,
  },
  iconButtonActtion: {
    width: 30,
    height: 30,
    padding: 0,
    transition: 'transform 300ms ease-in-out',
    '&:hover': {
      transform: 'rotate(20deg)',
      background: 'transparent',
    },
  },
  thumbUp: {
    color: theme.palette.jobllegroSuccess,
    width: 20,
  },
  thumbDown: {
    color: theme.palette.jobllegroRedAlert,
    width: 20,
  },
  primaryListItem: {
    color: '#000',
    lineHeight: '1rem',
  },
  secondaryListItem: {
    color: theme.palette.jobllegroBlue,
    lineHeight: '1rem',
  },
  progress: {
    margin: theme.spacing(2),
  },
  progressAwait: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#fff',
    opacity: '0.9',
    zIndex: '8000',
    textAlign: 'center',
  },
  button: {
    background: theme.palette.jobllegroSuccess,
    color: '#fff',
    padding: '0px',
    fontSize: 11,
    '&:hover': {
      background: theme.palette.jobllegroSuccessActive,
    },
  },
  listSubHeader: {
    paddingLeft: 12,
    paddingRight: 12,
    lineHeight: '24px',
    background: '#fcfcfc',
    borderTop: '1px solid #efefef',
  },
  listItemSecondaryAction: {
    top: '50%',
  },
});

const getActionControls = (
  projectId,
  userId,
  confirmationAcceptId,
  confirmationRejectId,
  itemId,
  isProjectRecruitersTrigger = null,
  typeOption,
  props
) => {
  const {
    acceptProjectInvitationCandidate,
    rejectProjectInvitationCandidate,
    acceptProjectInvitationRecruiter,
    rejectProjectInvitationRecruiter,
    acceptRejectMentorInvitationToProject,
    acceptRejectHiringManagerInvitationToProject,
    acceptProjectInvitationTriggeredByInactiveCandidate,
    rejectProjectInvitationTriggeredByInactiveCandidate,
    acceptRejectMentorRole,
    classes,
    notifications,
  } = props;

  let acceptTrigger = null;
  let rejectTrigger = null;
  if (
    typeOption === 'project_recruiter_co_1' ||
    typeOption === 'project_recruiter_co_4'
  ) {
    acceptTrigger = acceptProjectInvitationRecruiter.bind(
      this,
      projectId,
      userId,
      confirmationAcceptId,
      itemId,
      isProjectRecruitersTrigger
    );
    rejectTrigger = rejectProjectInvitationRecruiter.bind(
      this,
      projectId,
      userId,
      confirmationRejectId,
      itemId,
      isProjectRecruitersTrigger
    );
  } else if (typeOption === 'project_candidate_co_7') {
    acceptTrigger = acceptProjectInvitationTriggeredByInactiveCandidate.bind(
      this,
      projectId,
      userId,
      confirmationAcceptId,
      itemId,
      isProjectRecruitersTrigger
    );
    rejectTrigger = rejectProjectInvitationTriggeredByInactiveCandidate.bind(
      this,
      projectId,
      userId,
      confirmationRejectId,
      itemId,
      isProjectRecruitersTrigger
    );
  } else if (typeOption === 'mentor_in_project_request') {
    acceptTrigger = acceptRejectMentorInvitationToProject.bind(
      this,
      2,
      projectId,
      itemId
    );
    rejectTrigger = acceptRejectMentorInvitationToProject.bind(
      this,
      0,
      projectId,
      itemId
    );
  } else if (typeOption === 'hiring_manager_in_project_request') {
    acceptTrigger = acceptRejectHiringManagerInvitationToProject.bind(
      this,
      2,
      projectId,
      itemId
    );
    rejectTrigger = acceptRejectHiringManagerInvitationToProject.bind(
      this,
      0,
      projectId,
      itemId
    );
  } else if (typeOption === 'request_grant_mentor_role') {
    acceptTrigger = acceptRejectMentorRole.bind(this, 2, userId, itemId);
    rejectTrigger = acceptRejectMentorRole.bind(this, 0, userId, itemId);
  } else {
    acceptTrigger = acceptProjectInvitationCandidate.bind(
      this,
      projectId,
      userId,
      confirmationAcceptId,
      itemId
    );
    rejectTrigger = rejectProjectInvitationCandidate.bind(
      this,
      projectId,
      userId,
      confirmationRejectId,
      itemId
    );
  }

  if (typeOption === 'project_recruiter_co_1') {
    const findTheSameProject = find(notifications, function (item) {
      return (
        item.type === 'project_candidate_co_7' &&
        item.data.project.id === projectId
      );
    });
    if (!isEmpty(findTheSameProject)) return;
  }

  return (
    <React.Fragment>
      <IconButton
        onClick={acceptTrigger}
        aria-label="Acceptance"
        className={classes.iconButtonActtion}
      >
        <ThumbUpIcon className={classes.thumbUp} />
      </IconButton>
      <IconButton
        onClick={rejectTrigger}
        aria-label="Reject"
        className={classes.iconButtonActtion}
      >
        <ThumbDownIcon className={classes.thumbDown} />
      </IconButton>
    </React.Fragment>
  );
};

export const NotificationsListItem = props => {
  const {
    setNotificationRead,
    classes,
    item,
    isLoading,
    currentUser,
    type,
    t,
  } = props;
  const {
    data: { candidate = {}, recruiter = {}, project = {} },
    recipient = {},
  } = item;
  // @TODO Bedzie potrzebne przy organizacji
  //const {isRecruiter = false} = currentUser;
  let primaryText = '';
  let secondaryText = '';
  let informationText = '';
  let confirmationAcceptId = null;
  let confirmationRejectId = null;
  let userId = null;
  let avatar = null;
  let link = null;
  let isProjectRecruitersTrigger = false;

  if (type === 'project_recruiter_co_6') {
    primaryText = lowerCase(project.position);
    avatar = getImageFromSource(project.logo);
    informationText = t`topAppBar:rejected`;
  } else if (type === 'project_recruiter_co_5') {
    primaryText = lowerCase(project.position);
    avatar = getImageFromSource(project.logo);
    informationText = t`topAppBar:accepted`;
  } else if (type === 'project_order_created') {
    primaryText = lowerCase(project.position);
    avatar = getImageFromSource(project.logo);
    informationText = t`topAppBar:newOrder`;
    link = PATHS.PROJECT.PROJECT_ORDERS.replace(':id', project.id);
  } else if (type === 'project_order_updated') {
    primaryText = lowerCase(project.position);
    avatar = getImageFromSource(project.logo);
    informationText = t`topAppBar:mod`;
    link = PATHS.PROJECT.PROJECT_ORDERS.replace(':id', project.id);
  } else if (type === 'project_recruiter_co_4') {
    confirmationAcceptId = 5;
    confirmationRejectId = 6;
    userId = recruiter.id;
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    secondaryText = lowerCase(project.position);
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:wantJoin`;
    link = PATHS.RECRUITER.SHOW.replace(':id', recruiter.id);
    isProjectRecruitersTrigger = true;
  } else if (type === 'project_recruiter_co_3') {
    primaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:declined`;
  } else if (type === 'project_recruiter_co_2') {
    primaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:accept`;
  } else if (type === 'project_recruiter_co_1') {
    confirmationAcceptId = 2;
    confirmationRejectId = 3;
    userId = currentUser.id;
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:invitesYou`;
  } else if (type === 'project_candidate_co_6') {
    primaryText = lowerCase(project.position);
    avatar = getImageFromSource(project.logo);
    informationText = t`topAppBar:rejected`;
  } else if (type === 'project_candidate_co_5') {
    primaryText = lowerCase(project.position);
    avatar = getImageFromSource(project.logo);
    informationText = t`topAppBar:accepted`;
  } else if (type === 'project_recruiter_co_rejected') {
    primaryText = `${t`topAppBar:project`}${lowerCase(
      project.position
    )} Id: ${lowerCase(project.id)}`;
    avatar = getImageFromSource(project.logo);
    informationText = t`topAppBar:removed`;
  } else if (type === 'project_candidate_co_4') {
    confirmationAcceptId = 5;
    confirmationRejectId = 6;
    userId = candidate.id;
    primaryText = candidate.firstName + ' ' + candidate.lastName;
    secondaryText = t`topAppBar:project` + lowerCase(project.position);
    avatar = getImageFromSource(candidate.avatar);
    informationText =
      t`topAppBar:candidate` +
      (candidate.firstName + ' ' + candidate.lastName) +
      t`topAppBar:wantJoin`;
    link = PATHS.CANDIDATE.SHOW.replace(':id', candidate.id);
  } else if (type === 'project_candidate_co_3') {
    primaryText = project.position;
    secondaryText = t`topAppBar:project` + project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:candidate` +
      (candidate.firstName + ' ' + candidate.lastName) +
      t`topAppBar:declined`;
  } else if (type === 'project_candidate_co_2') {
    primaryText = candidate.firstName + ' ' + candidate.lastName;
    secondaryText = t`topAppBar:project` + project.position;
    avatar = getImageFromSource(candidate.avatar);
    informationText =
      t`topAppBar:candidate` +
      (candidate.firstName + ' ' + candidate.lastName) +
      t`topAppBar:rejected`;
  } else if (type === 'project_candidate_co_1') {
    confirmationAcceptId = 2;
    confirmationRejectId = 3;
    userId = currentUser.id;
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:invitesYou`;
  } else if (type === 'project_candidate_co_7') {
    confirmationAcceptId = 2;
    confirmationRejectId = 3;
    userId = candidate.id;
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:invitesYourCandidate` +
      (candidate.firstName + ' ' + candidate.lastName) +
      t`topAppBar:invitesYourCandidate2`;
  } else if (type === 'oo_project_owner') {
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:removedFromOrg`;
  } else if (type === 'oo_candidate_owner') {
    primaryText = candidate.firstName + ' ' + candidate.lastName;
    avatar = getImageFromSource(candidate.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:removedFromOrg2`;
    link = PATHS.CANDIDATE.SHOW.replace(':id', candidate.id);
  } else if (type === 'oo_candidate_in_project_owner') {
    primaryText = candidate.firstName + ' ' + candidate.lastName;
    secondaryText = t`topAppBar:project` + lowerCase(project.position);
    avatar = getImageFromSource(candidate.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:removedFromOrg3`;
    link = PATHS.CANDIDATE.SHOW.replace(':id', candidate.id);
  } else if (type === 'request_grant_mentor_role') {
    primaryText =
      t`topAppBar:mentorStatus` +
      (recruiter.firstName + ' ' + recruiter.lastName);
    avatar = getImageFromSource(recruiter.avatar);
    informationText = t`topAppBar:notification`;
    userId = recruiter.id;
    link = PATHS.RECRUITER.SHOW.replace(':id', recruiter.id);
  } else if (type === 'recipient_grant_mentor_role') {
    primaryText = t`topAppBar:mentorReqAccepted`;
    avatar = getImageFromSource(recipient.assets.avatar);
    informationText = t`topAppBar:notification`;
  } else if (type === 'recipient_revoke_mentor_role') {
    primaryText = t`topAppBar:mentorReqDeclined`;
    avatar = getImageFromSource(recipient.assets.avatar);
    informationText = t`topAppBar:notification`;
  } else if (type === 'mentor_in_project_request') {
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:inviteToMentor`;
  } else if (type === 'mentor_in_project_request_accepted') {
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    secondaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:mentorInvitationAccepted`;
  } else if (type === 'mentor_in_project_request_rejected') {
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    secondaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:mentorInvitationDeclined`;
  } else if (type === 'mentor_in_project_rejected_by_po') {
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:removedMentorStatus`;
  } else if (type === 'hiring_manager_in_project_request') {
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:hiringManagerInvitation`;
  } else if (type === 'hiring_manager_in_project_request_accepted') {
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    secondaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:hmInvitationAccepted`;
  } else if (type === 'hiring_manager_in_project_request_rejected') {
    primaryText = recruiter.firstName + ' ' + recruiter.lastName;
    secondaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:hmInvitationDeclined`;
  } else if (type === 'hiring_manager_in_project_rejected_by_po') {
    primaryText = project.position;
    avatar = getImageFromSource(project.logo);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:hmIStatusRemoved`;
  } else if (type === 'projec_owner_close_project') {
    primaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:recruiter` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:closeProject`;
  } else if (type === 'hm_reject_project_close') {
    primaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:hiringManager` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:declinedToCloseProject`;
  } else if (type === 'hm_accept_project_close') {
    primaryText = project.position;
    avatar = getImageFromSource(recruiter.avatar);
    informationText =
      t`topAppBar:hiringManager` +
      (recruiter.firstName + ' ' + recruiter.lastName) +
      t`topAppBar:confirmToCloseProject`;
  }

  const getActionControlsData = getActionControls(
    project.id,
    userId,
    confirmationAcceptId,
    confirmationRejectId,
    item.id,
    isProjectRecruitersTrigger,
    type,
    props
  );

  return (
    <React.Fragment>
      <ListSubheader
        disableSticky={true}
        component="div"
        className={classes.listSubHeader}
      >
        {informationText}
      </ListSubheader>
      <ListItem key={item.id} className={classes.listItem}>
        {isLoading && (
          <div className={classes.progressAwait}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
        <Avatar
          src={avatar}
          component={Link}
          to={
            link !== null ? link : PATHS.PROJECT.SHOW.replace(':id', project.id)
          }
          className={classes.avatar}
        />
        <ListItemText
          className={classes.listItemText}
          primary={primaryText}
          secondary={secondaryText}
          classes={{
            primary: classes.primaryListItem,
            secondary: classes.secondaryListItem,
          }}
        />
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          {type === 'project_order_updated' ||
          type === 'project_order_created' ||
          type === 'project_recruiter_co_5' ||
          type === 'project_recruiter_co_6' ||
          type === 'project_recruiter_co_2' ||
          type === 'project_recruiter_co_3' ||
          type === 'project_candidate_co_2' ||
          type === 'project_candidate_co_3' ||
          type === 'project_candidate_co_5' ||
          type === 'project_candidate_co_6' ||
          type === 'oo_project_owner' ||
          type === 'oo_candidate_owner' ||
          type === 'recipient_grant_mentor_role' ||
          type === 'recipient_revoke_mentor_role' ||
          type === 'oo_candidate_in_project_owner' ||
          type === 'mentor_in_project_request_accepted' ||
          type === 'mentor_in_project_request_rejected' ||
          type === 'mentor_in_project_rejected_by_po' ||
          type === 'hiring_manager_in_project_request_accepted' ||
          type === 'hiring_manager_in_project_rejected_by_po' ||
          type === 'hiring_manager_in_project_request_rejected' ||
          type === 'projec_owner_close_project' ||
          type === 'hm_reject_project_close' ||
          type === 'project_recruiter_co_rejected' ||
          type === 'hm_accept_project_close' ? (
            <Button
              size="small"
              className={classes.button}
              onClick={setNotificationRead.bind(this, item.id)}
            >
              {t('core:action.imUnderstand')}
            </Button>
          ) : (
            <div>{getActionControlsData}</div>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
};

NotificationsListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    notifications: store.notifications.list,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(NotificationsListItem)));
