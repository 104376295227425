import {
  Typography,
  Badge,
  IconButton,
  List,
  LinearProgress,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import {
  LocationCity as OrganizationIcon,
  Done as DoneIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { isOrganizationDetailsSet } from 'shared/newComponents/MissingOrganizationDetailsDialog/MissingOrganizationDetailsDialog.utils';
import { getCurrentUser } from 'shared/services';
import {
  editRecruiter,
  getRecruitersPendingToOrganization,
} from 'shared/services';
import { getOrganizationRecruitersFilteredForAdding } from 'shared/services';
import { getNotifications, setNotificationRead } from 'shared/services';
import { ProjectV2Service } from 'shared/services';
import { editRecruiterOrganization } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getNotificationsByCategory } from 'shared/utils/functions';
import MenuToggleAction from '../LayoutMenuToggleAction';
import NotificationsInformationListItem from './NotificationsInformationListItem';
import NotificationsOrganizationListItem from './NotificationsOrganizationListItem';

const styles = theme => ({
  iconButton: {
    color: '#afafaf',
    marginTop: 14,
    padding: 10,
  },
  icon: {
    color: '#afafaf',
  },
  subHeader: {
    background: '#52a3fc',
    color: '#fff',
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
  },
  rootList: {
    width: 420,
    maxHeight: 350,
    overflowY: 'auto',
    background: '#fff',
  },
  rootSubList: {
    width: '100%',
    maxWidth: 420,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  progressCircular: {
    color: '#fff',
  },
});

export class NotificationsOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isLoading: false,
      isOpenOrganizationDialog: false,
    };
  }

  setNotificationRead = async itemId => {
    const { setNotificationRead } = this.props;
    this.setState({ isLoading: true });
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
  };

  acceptInvitation = async (
    idStatus,
    idUser,
    itemId,
    type,
    projectId = null
  ) => {
    const { currentUser, t } = this.props;
    try {
      this.setState({ isLoading: true });
      let status = null;

      if (type !== 'organization_project_to_approve') {
        const response = await this.props.editRecruiterOrganization({
          id: idUser,
          organizationConfirmation: idStatus,
        });
        status = response.status;
      } else {
        const body = {
          organizationAcceptStatus: idStatus,
        };
        const response = await ProjectV2Service.editProjectV2OrganizationStatus(
          projectId,
          body
        );
        status = response.status;
      }
      if (status === 200) {
        if (currentUser.organization) {
          const { id } = this.props.currentUser.organization;
          this.loadData(id);
          this.props.getOrganizationRecruitersFilteredForAdding(id);
          this.handleCheckOrganizationInvoiceInfoStatus(
            currentUser.organization
          );
        }
        await this.props.setNotificationRead(itemId);
        await this.props.getNotifications();
        this.props.getCurrentUser();
        this.props.showSnackbarMessage(
          t('notification:organization.invitationAccepted')
        );
        window.location.reload();
      } else {
        this.props.showSnackbarMessage(
          t('notification:organization.errorOccurred')
        );
      }
      this.setState({ isLoading: false });
    } catch (err) {
      console.error(err);
    }
  };

  rejectInvitation = async (
    idStatus,
    idUser,
    itemId,
    type = null,
    projectId = null
  ) => {
    const { currentUser, t } = this.props;
    let snackbarText = t('notification:organization.yourRejectedInvitation');
    try {
      this.setState({ isLoading: true });

      let status = null;
      if (type !== 'organization_project_to_approve') {
        const response = await this.props.editRecruiterOrganization({
          id: idUser,
          organizationConfirmation: idStatus,
        });
        status = response.status;
      } else {
        const body = {
          organizationAcceptStatus: idStatus,
        };
        const response = await ProjectV2Service.editProjectV2OrganizationStatus(
          projectId,
          body
        );
        status = response.status;
        snackbarText = t('notification:organization.yourRejectedProject');
      }

      if (status === 200) {
        if (currentUser.organization) {
          const { id } = this.props.currentUser.organization;
          this.loadData(id);
          this.props.getOrganizationRecruitersFilteredForAdding(id);
        }
        await this.props.setNotificationRead(itemId);
        await this.props.getNotifications();
        this.props.getCurrentUser();
        this.props.showSnackbarMessage(snackbarText);
        window.location.reload();
      } else {
        this.props.showSnackbarMessage(
          t('notification:organization.errorOccurred')
        );
      }
      this.setState({ isLoading: false });
    } catch (err) {
      console.error(err);
    }
  };

  getOrganizationStatusContent = () => {
    const {
      classes,
      currentUser: { organizationConfirmation, organization = {} },
      notifications,
    } = this.props;
    const { isLoading } = this.state;
    const notificationsList = getNotificationsByCategory(
      notifications,
      'organization'
    );

    if (
      (organization !== null && organizationConfirmation === 1) ||
      (organization !== null && organizationConfirmation === 5) ||
      (organization === null && organizationConfirmation === 0) ||
      organizationConfirmation === 6
    ) {
      return (
        <React.Fragment>
          {notificationsList.map(value => (
            <NotificationsOrganizationListItem
              key={value.id}
              item={value}
              type={value.type}
              isLoading={isLoading}
              acceptInvitation={this.acceptInvitation}
              rejectInvitation={this.rejectInvitation}
              setNotificationRead={this.setNotificationRead}
            />
          ))}
        </React.Fragment>
      );
    } else if (
      organization !== null &&
      (organizationConfirmation === 2 || organizationConfirmation === 4)
    ) {
      const filterNotificationsList = notificationsList
        ? notificationsList.filter(
            notification =>
              notification.type === 'organization_project_to_approve'
          )
        : [];

      return (
        <React.Fragment>
          {filterNotificationsList.map(value => (
            <NotificationsOrganizationListItem
              key={value.id}
              item={value}
              type={value.type}
              isLoading={isLoading}
              acceptInvitation={this.acceptInvitation}
              rejectInvitation={this.rejectInvitation}
              setNotificationRead={this.setNotificationRead}
            />
          ))}
        </React.Fragment>
      );
    } else if (organization !== null && organizationConfirmation === 9) {
      return (
        <React.Fragment>
          {notificationsList.length > 0 && (
            <List className={classes.rootSubList} subheader={<li />}>
              <li key={1} className={classes.listSection}>
                <ul className={classes.ul}>
                  {notificationsList.map(value => (
                    <NotificationsOrganizationListItem
                      key={value.id}
                      item={value}
                      type={value.type}
                      isLoading={isLoading}
                      acceptInvitation={this.acceptInvitation}
                      rejectInvitation={this.rejectInvitation}
                      setNotificationRead={this.setNotificationRead}
                    />
                  ))}
                </ul>
              </li>
            </List>
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  getOrganizationTitle = status => {
    const {
      currentUser: { organization },
      t,
    } = this.props;

    const isInOrganization = status === 2 || status === 5 || status === 9;

    const isWaiting = status === 1 || status === 4;

    if (isInOrganization && organization) {
      return t('notification:organization.youAreOrganizationMember');
    } else if (isWaiting) {
      return status === 1
        ? t('notification:organization.organizationInvitation')
        : t(
            'notification:organization.youAreWaitingForOrganizationOwnerAcceptance'
          );
    } else {
      return t('notification:organization.youAreNotOrganizationMember');
    }
  };

  handleClickOpenToggleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickCloseToggleMenu = () => {
    this.setState({ anchorEl: null });
  };

  loadData = async id => {
    try {
      await this.props.getRecruitersPendingToOrganization(
        id,
        '?filter[confirmationStatus]=4'
      );
      this.setState({ isLoading: false });
    } catch (err) {
      console.error(err);
    }
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { organization } = this.props.currentUser;
    if (organization) {
      this.loadData(organization.id);
    } else {
      this.setState({ isLoading: false });
    }
  }

  getOrganizationInvitationContent = () => {
    const {
      classes,
      currentUser: { organization, organizationConfirmation, id },
      t,
    } = this.props;
    const { isLoading } = this.state;
    const organizationStatusContent = this.getOrganizationStatusContent();
    const organizationTitle = this.getOrganizationTitle(
      organizationConfirmation
    );

    return (
      <div>
        <Typography
          variant="caption"
          align="center"
          className={classes.subHeader}
        >
          {organizationTitle}
        </Typography>
        <List
          component="nav"
          classes={{
            root: classes.rootList,
          }}
        >
          {isLoading ? (
            <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
          ) : (
            <React.Fragment>
              {organization &&
                organizationConfirmation !== 4 &&
                organizationConfirmation !== 1 &&
                organizationConfirmation !== 2 && (
                  <NotificationsInformationListItem
                    item={organization}
                    currentUserId={id}
                    classes={classes}
                  />
                )}
              {(organizationConfirmation === 6 ||
                organizationConfirmation === 0 ||
                organizationConfirmation === 3 ||
                !organization) && (
                <NotificationsInformationListItem
                  classes={classes}
                  title={t(
                    'notification:organization.checkListOfAvailableOrganizations'
                  )}
                />
              )}
              {organizationStatusContent}
            </React.Fragment>
          )}
        </List>
      </div>
    );
  };

  handleRedirectToOrganizationInfo = () => {
    this.props.history.push(PATHS.RECRUITER.ORGANIZATION.EDIT);
    this.handleOnCloseDialog();
  };

  handleOnCloseDialog = () => {
    this.setState({
      isOpenOrganizationDialog: false,
    });
    window.location.reload();
  };

  handleCheckOrganizationInvoiceInfoStatus = organization => {
    if (isOrganizationDetailsSet(organization)) {
      this.setState({
        isOpenOrganizationDialog: true,
      });
    }
  };

  render() {
    const {
      classes,
      notifications,
      currentUser: { organizationConfirmation },
    } = this.props;
    const { anchorEl, isLoading } = this.state;
    const { organization } = this.props.currentUser;

    const notificationsOrganization = getNotificationsByCategory(
      notifications,
      'organization'
    );
    const countNotificationsOrganization = notificationsOrganization.length;

    let componentIcon = null;
    if (organizationConfirmation === 1 || organizationConfirmation === 4) {
      componentIcon = (
        <Badge badgeContent={'!'} color="secondary">
          <OrganizationIcon className={classes.icon} />
        </Badge>
      );
    } else if (
      organizationConfirmation === 6 ||
      organizationConfirmation === 0
    ) {
      if (countNotificationsOrganization > 0) {
        componentIcon = (
          <Badge
            badgeContent={countNotificationsOrganization}
            color="secondary"
          >
            <OrganizationIcon className={classes.icon} />
          </Badge>
        );
      } else {
        componentIcon = <OrganizationIcon className={classes.icon} />;
      }
    } else if (
      organization &&
      (organizationConfirmation === 9 ||
        organizationConfirmation === 5 ||
        organizationConfirmation === 6 ||
        organizationConfirmation === 2)
    ) {
      if (countNotificationsOrganization > 0) {
        componentIcon = (
          <Badge
            badgeContent={countNotificationsOrganization}
            color="secondary"
          >
            <OrganizationIcon className={classes.icon} />
          </Badge>
        );
      } else {
        componentIcon = (
          <Badge
            badgeContent={<DoneIcon style={{ fontSize: 13 }} />}
            color="primary"
          >
            <OrganizationIcon className={classes.icon} />
          </Badge>
        );
      }
    } else {
      componentIcon = <OrganizationIcon className={classes.icon} />;
    }

    return (
      <React.Fragment>
        <IconButton
          className={classes.iconButton}
          onClick={this.handleClickOpenToggleMenu}
        >
          {isLoading ? (
            <CircularProgress className={classes.progressCircular} size={24} />
          ) : (
            <React.Fragment>{componentIcon}</React.Fragment>
          )}
        </IconButton>
        <MenuToggleAction
          anchorElTrigger={anchorEl}
          anchorElCloseTrigger={this.handleClickCloseToggleMenu}
          getContent={this.getOrganizationInvitationContent.bind(this)}
        />
      </React.Fragment>
    );
  }
}

NotificationsOrganization.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  getCurrentUser,
  editRecruiter,
  editRecruiterOrganization,
  getOrganizationRecruitersFilteredForAdding,
  getRecruitersPendingToOrganization,
  showSnackbarMessage,
  getNotifications,
  setNotificationRead,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    notifications: store.notifications.list,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(NotificationsOrganization))));
