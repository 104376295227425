import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { getEnumItemNameFullObject } from 'shared/utils/assets';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateLanguages = ({ candidate, t }) => {
  const userLanguageSkills = candidate.userLanguageSkills;
  const userOldLanguageSkills = candidate.userLanguages;

  const isNewCreatorCandidate = candidate && candidate.currentStage > 1;

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Translate
        style={{
          fontSize: 20,
        }}
        bold
        text={'candidateCreator:reviewStep2.experienceLanguages'}
      />
      {isNewCreatorCandidate && !userLanguageSkills.length ? (
        <Translate
          style={{
            fontSize: 16,
          }}
          text={'core:general.noInformation'}
          disableTranslate={false}
        />
      ) : !isNewCreatorCandidate && !userOldLanguageSkills.length ? (
        <Translate
          style={{
            fontSize: 16,
          }}
          text={'core:general.noInformation'}
          disableTranslate={false}
        />
      ) : null}
      {isNewCreatorCandidate && userLanguageSkills
        ? userLanguageSkills.map(item => {
            const languageSkill = getEnumItemNameFullObject(
              enums.EXPERIENCE_LEVEL,
              item.level ? item.level : item.experience
            );

            if (!item.competence || !item.competence.skill) {
              if (item.skill && item.skill.name) {
                return (
                  <Grid item key={`experience_${item.id}`}>
                    <Translate
                      style={{
                        fontSize: 16,
                        background: item.skill.isApproved ? '' : '#d8d8d8',
                      }}
                      disableTranslate={true}
                      text={`${item.skill.name}: ${t(languageSkill)}`}
                    />
                  </Grid>
                );
              }
              return null;
            }

            return (
              <Grid item key={`experience_${item.id}`}>
                <Translate
                  style={{
                    fontSize: 16,
                    background: item.competence.skill.isApproved
                      ? ''
                      : '#d8d8d8',
                  }}
                  disableTranslate={true}
                  text={`${item.competence.skill.name}: ${t(languageSkill)}`}
                />
              </Grid>
            );
          })
        : userOldLanguageSkills.map(item => {
            if (!item.language) {
              return null;
            }
            return (
              <Grid item key={`experience_${item.language.id}`}>
                <Translate
                  style={{
                    fontSize: 16,
                  }}
                  disableTranslate={true}
                  text={`${item.language.name}: ${getEnumItemNameFullObject(
                    enums.LANGUAGE_OLD_LEVELS,
                    item.level ? item.level : item.experience
                  )}`}
                />
              </Grid>
            );
          })}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateLanguages))));
