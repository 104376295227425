import { Grid, withStyles } from '@material-ui/core';
import { useState } from 'react';
import CandidateCreateWizard from '../CandidateCreateWizard';
import CandidateReview from '../CandidateReview';
import { styles } from './CandidateCreateForm.style';

const CandidateCreateForm = ({
  classes,
  candidate,
  shouldUpdateCandidate,
  currentUser,
  competences,
  languageCompetence,
  project,
}) => {
  const [step1FormValues, setStep1FormValues] = useState({});
  const [step2FormValues, setStep2FormValues] = useState({});
  const [step3FormValues, setStep3FormValues] = useState({});
  const [step4FormValues, setStep4FormValues] = useState({});
  const [step5FormValues, setStep5FormValues] = useState({});
  const [step6FormValues, setStep6FormValues] = useState({});

  const [currentStage, setCurrentStage] = useState(
    candidate.id ? candidate.currentStage : 0
  );

  return (
    <Grid container alignItems="flex-start" className={classes.root}>
      <Grid item xs={12} sm={12} md={12} xl={7} lg={7}>
        <CandidateCreateWizard
          project={project}
          currentUser={currentUser}
          shouldUpdateCandidate={shouldUpdateCandidate}
          setCurrentStage={setCurrentStage}
          currentStage={currentStage}
          setStep1FormValues={setStep1FormValues}
          setStep2FormValues={setStep2FormValues}
          setStep3FormValues={setStep3FormValues}
          setStep4FormValues={setStep4FormValues}
          setStep5FormValues={setStep5FormValues}
          setStep6FormValues={setStep6FormValues}
          initialCandidateValues={candidate}
          step1FormValues={step1FormValues}
          step2FormValues={step2FormValues}
          step3FormValues={step3FormValues}
          step4FormValues={step4FormValues}
          step5FormValues={step5FormValues}
          step6FormValues={step6FormValues}
          competences={competences}
          languageCompetence={languageCompetence}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
        <CandidateReview
          step1FormValues={step1FormValues}
          step2FormValues={step2FormValues}
          step3FormValues={step3FormValues}
          step4FormValues={step4FormValues}
          step5FormValues={step5FormValues}
          step6FormValues={step6FormValues}
          currentUser={currentUser}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CandidateCreateForm);
