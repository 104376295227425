export const styles = ({ palette, breakpoints }) => ({
  wrapper: {
    padding: '16px 150px',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  tabsWrapper: { borderBottom: '1px solid #b0b4b9' },
  link: { color: palette.primary.main },
  scroll: { overflow: 'auto' },
});
