import { useFormik } from 'formik';
import { PATHS } from 'config';
import {
  LANGUAGE_CATEGORY_TYPE_ID,
  LANGUAGE_KIND_ID,
  LANGUAGE_TYPE_ID,
} from 'shared/constants';
import { CandidateV2Service } from 'shared/services';
import {
  validationSchema,
  initialValues,
} from '../CandidateCreateWizardStep1/CandidateCreateWizardStep1.utils';
import {
  validationSchema as validationSchemaStep2,
  initialValues as initialValuesStage2,
} from '../CandidateCreateWizardStep2/CandidateCreateWizardStep2.utils';
import { validationSchema as validationSchemaStep3 } from '../CandidateCreateWizardStep3/CandidateCreateWizardStep3.utils';
import { validationSchema as validationSchemaStep5 } from '../CandidateCreateWizardStep5/CandidateCreateWizardStep5.utils';
import { validationSchema as validationSchemaStep6 } from '../CandidateCreateWizardStep6/CandidateCreateWizardStep6.utils';

export const useFormStep1 = ({
  t,
  initialCandidateValues,
  history,
  project,
  onClose,
  callBackSave = null,
}) =>
  useFormik({
    initialValues: initialCandidateValues.id
      ? initialCandidateValues
      : initialValues,
    validationSchema: validationSchema(
      t,
      !!initialCandidateValues.id,
      initialCandidateValues
    ),
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email === '' ? null : values.email,
        phonePrimary: values.phonePrimary === '' ? null : values.phonePrimary,
        homeCountry: values.homeCountry,
        position: values.position,
        isMen: values.isMen,
        isWomen: values.isWomen,
        city: values.city,
        isRemoteWorkOnly: values.isRemoteWorkOnly,
        country: values.country,
        industryCategory: values.industryCategory,
        department: values.department,
        companySize: values.companySize,
        availabilityDate:
          values.availabilityDate === '' ? null : values.availabilityDate,
        availabilityTypeCandidate:
          values.availabilityTypeCandidate === ''
            ? null
            : parseInt(values.availabilityTypeCandidate),
        isPublic: values.isPublic,
        isForeigner: values.isForeigner,
        isFistStageInfoPublic: values.isFistStageInfoPublic,
        residenceTitle: values.residenceTitle,
        arrivalDateEmploymentCountry: values.arrivalDateEmploymentCountry,
        isForeignerInEmploymentArea: values.isForeignerInEmploymentArea,
        birthYear: values.birthYear,
        passportNumber: values.passportNumber,
        passportSerialNumber: values.passportSerialNumber,
        plannedResidenceTitle: values.plannedResidenceTitle,
        citizenship: values.isForeigner ? values.citizenship : null,
        phonePrimaryCode: values.phonePrimaryCode.value,
        projectId: project ? project.id : null,
      };

      if (!values.isMen && !values.isWomen) {
        return actions.setFieldError('isMen', t`candidateCreator:chooseGender`);
      }
      if (!initialCandidateValues.id) {
        CandidateV2Service.postCandidate(body)
          .then(({ data }) => {
            if (callBackSave) {
              return callBackSave(data);
            }
            localStorage.setItem('isDataSave', 'true');
            localStorage.setItem('isNewCandidate', 'true');
            history.push(PATHS.CANDIDATE.NEW_EDIT.replace(':id', data.id));
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              if (error.response.status === 409) {
                return actions.setFieldError(
                  'email',
                  t`candidateCreator:emailTaken`
                );
              } else if (error.response.status === 418) {
                return actions.setFieldError(
                  'phonePrimary',
                  t`candidateCreator:phoneTaken`
                );
              }
            } else {
              alert(t`newAts:core.error`);
            }
          });
      } else {
        CandidateV2Service.editCandidate(initialCandidateValues.id, body, {
          stage: 1,
        })
          .then(() => {
            onClose();
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              if (error.response.status === 409) {
                return actions.setFieldError(
                  'email',
                  t`candidateCreator:emailTaken`
                );
              } else if (error.response.status === 418) {
                return actions.setFieldError(
                  'phonePrimary',
                  t`candidateCreator:phoneTaken`
                );
              }
            } else {
              alert(t`newAts:core.error`);
            }
          });
      }
    },
  });

export const prepareLanguageCompetence = competences => {
  return competences.filter(item => {
    if (item.competence) {
      if (
        item.competence.competenceCategory &&
        item.competence.competenceCategory.id === LANGUAGE_CATEGORY_TYPE_ID &&
        item.competence.competenceKind &&
        item.competence.competenceKind.id === LANGUAGE_KIND_ID &&
        item.competence.competenceType &&
        item.competence.competenceType.id === LANGUAGE_TYPE_ID
      ) {
        return item;
      }
    }
  });
};

export const prepareLanguage = competences => {
  return competences.filter(item => {
    if (
      item.competenceCategory &&
      item.competenceCategory.id === LANGUAGE_CATEGORY_TYPE_ID &&
      item.competenceKind &&
      item.competenceKind.id === LANGUAGE_KIND_ID &&
      item.competenceType &&
      item.competenceType.id === LANGUAGE_TYPE_ID
    ) {
      return item;
    }
  });
};

export const prepareCompetence = competences => {
  return competences.filter(item => {
    if (item.competence) {
      if (
        !(
          item.competence.competenceCategory &&
          item.competence.competenceCategory.id === LANGUAGE_CATEGORY_TYPE_ID &&
          item.competence.competenceKind &&
          item.competence.competenceKind.id === LANGUAGE_KIND_ID &&
          item.competence.competenceType &&
          item.competence.competenceType.id === LANGUAGE_TYPE_ID
        )
      ) {
        return item;
      }
    }
  });
};
export const getPrimaryPhoneCode = candidate => {
  return {
    phonePrimaryCode: {
      value: candidate.phonePrimaryCode,
      id: candidate.phonePrimaryCode,
      label: candidate.phonePrimaryCode,
      name: candidate.phonePrimaryCode,
    },
  };
};
export const getLanguageSkill = userSkills => {
  if (!userSkills) {
    return {
      userLanguageSkills: [],
      userSkills: [],
    };
  }
  const filterUserLanguages = prepareLanguageCompetence(userSkills);
  const filterUserSkills = prepareCompetence(userSkills);

  return {
    userLanguageSkills: filterUserLanguages,
    userSkills: filterUserSkills,
  };
};
export const useFormStep2 = ({ t, initialCandidateValues, setCurrentStage }) =>
  useFormik({
    initialValues: initialCandidateValues.id
      ? initialCandidateValues
      : initialValuesStage2,
    validationSchema: validationSchemaStep2(t),
    enableReinitialize: true,
    onSubmit: values => {
      const filterUserSkills = values.userSkills.filter(
        item => item !== null && item !== undefined
      );

      const body = {
        userExperiences: values.userExperiences.map(item => {
          return {
            ...item,
            startWorkYear:
              item.startWorkYear === '' ? null : item.startWorkYear,
          };
        }),
        userSkills: [...filterUserSkills, ...values.userLanguageSkills],
      };

      CandidateV2Service.editCandidate(initialCandidateValues.id, body, {
        stage: 2,
      })
        .then(({ data }) => {
          if (data.currentStage) {
            setCurrentStage(data.currentStage);
          }
        })
        .catch(error => {
          console.log(error);
          alert(t`newAts:core.error`);
        });
    },
  });

export const useFormStep3 = ({ t, initialCandidateValues, setCurrentStage }) =>
  useFormik({
    initialValues: initialCandidateValues,
    validationSchema: validationSchemaStep3(t),
    enableReinitialize: true,
    onSubmit: values => {
      const body = {
        employmentTypes: values.employmentTypes,
        workHourType: values.workHourType,
        expectedWorkTime: values.expectedWorkTime,
        minMonthlyWadges: values.minMonthlyWadges,
        maxMonthlyWadges: values.maxMonthlyWadges,
        currency: values.currency,
        salaryType: values.salaryType,
        isWeekendsWorkPossible: values.isWeekendsWorkPossible,
        isShiftWorkPossible: values.isShiftWorkPossible,
      };
      CandidateV2Service.editCandidate(initialCandidateValues.id, body, {
        stage: 3,
      })
        .then(({ data }) => {
          if (data.currentStage) {
            setCurrentStage(data.currentStage);
          }
        })
        .catch(error => {
          console.log(error);
          alert(t`newAts:core.error`);
        });
    },
  });

export const useFormStep5 = ({ t, initialCandidateValues, setCurrentStage }) =>
  useFormik({
    initialValues: initialCandidateValues,
    validationSchema: validationSchemaStep5(t),
    enableReinitialize: true,
    onSubmit: values => {
      const body = {
        linkedinCandidateLink: values.linkedinCandidateLink,
        facebookCandidateLink: values.facebookCandidateLink,
        isSecondStageInfoPublic: values.disabilityIsConfirm
          ? values.isSecondStageInfoPublic
          : false,
        isCandidateDisabled: values.disabilityIsConfirm
          ? values.isCandidateDisabled
          : false,
        degreeOfDisability: values.disabilityIsConfirm
          ? values.degreeOfDisability
          : null,
        disabilityReason: values.disabilityIsConfirm
          ? values.disabilityReason
          : null,
        disabilityIsConfirm: values.disabilityIsConfirm,
        disabilityWorkPlaceDesc: values.disabilityIsConfirm
          ? values.disabilityWorkPlaceDesc
          : null,
      };

      CandidateV2Service.editCandidate(initialCandidateValues.id, body, {
        stage: 5,
      })
        .then(() => {})
        .catch(error => {
          console.log(error);
          alert(t`newAts:core.error`);
        });
    },
  });

export const useFormStep6 = ({ t, initialCandidateValues, setCurrentStage }) =>
  useFormik({
    initialValues: initialCandidateValues,
    validationSchema: validationSchemaStep6(t),
    enableReinitialize: true,
    onSubmit: values => {
      const body = {
        candidateRatingFromRecruiterDescription:
          values.candidateRatingFromRecruiterDescription,
        candidateRatingFromRecruiter: values.candidateRatingFromRecruiter,
      };

      CandidateV2Service.editCandidate(initialCandidateValues.id, body, {
        stage: 6,
      })
        .then(() => {})
        .catch(error => {
          console.log(error);
          alert(t`newAts:core.error`);
        });
    },
  });

export const stage2NotSetFields = (initialProjectValues, step2FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  return true;
  // const { userExperiences, userSkills } = step2FormValues;
  // return userExperiences.length === 0 && userSkills.length === 0;
};

export const stage3NotSetFields = (initialProjectValues, step3FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  return true;
  // const {
  //   employmentTypes,
  //   workHourType,
  //   expectedWorkTime,
  //   minMonthlyWadges,
  //   maxMonthlyWadges,
  //   currency,
  //   salaryType,
  //   isWeekendsWorkPossible,
  //   isShiftWorkPossible,
  // } = step3FormValues;
  // return (
  //   employmentTypes &&
  //   employmentTypes.length === 0 &&
  //   workHourType &&
  //   workHourType.length === 0 &&
  //   expectedWorkTime &&
  //   expectedWorkTime.length === 0 &&
  //   minMonthlyWadges &&
  //   minMonthlyWadges.length === 0 &&
  //   maxMonthlyWadges &&
  //   maxMonthlyWadges.length === 0 &&
  //   currency &&
  //   currency.length === 0 &&
  //   salaryType &&
  //   salaryType.length === 0 &&
  //   isWeekendsWorkPossible &&
  //   isWeekendsWorkPossible.length === 0 &&
  //   isShiftWorkPossible &&
  //   isShiftWorkPossible.length === 0
  // );
};

export const stage4NotSetFields = (initialProjectValues, step4FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  return true;
};

export const stage5NotSetFields = (initialProjectValues, step5FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  return true;
};

export const stage6NotSetFields = (initialProjectValues, step6FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  return true;
};
