import { Typography, withStyles, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { WizardStep } from '../../../../newComponents';
import TextField from '../../../../newComponents/TextField';
import { HelpService } from '../../../../services';
import { styles } from './Transport.styles';

const Transport = ({
  classes,
  t,
  offeringHelpInfo,
  sectionSaved,
  selectedOfferingTransportInfo,
}) => {
  const [isTransportOpen, setIsTransportOpen] = useState(true);
  const [savedTransport, setSavedTransport] = useState(
    selectedOfferingTransportInfo
  );

  const formData = {
    pickUpPlace: selectedOfferingTransportInfo
      ? selectedOfferingTransportInfo.pickUpPlace
      : '',
    destinationPlace: selectedOfferingTransportInfo
      ? selectedOfferingTransportInfo.destinationPlace
      : '',
    numberOfSeats: selectedOfferingTransportInfo
      ? selectedOfferingTransportInfo.numberOfSeats
      : 0,
    vehicleNumber: selectedOfferingTransportInfo
      ? selectedOfferingTransportInfo.vehicleNumber
      : '',
    vehicleBrand: selectedOfferingTransportInfo
      ? selectedOfferingTransportInfo.vehicleBrand
      : '',
    periodPickUpDate: selectedOfferingTransportInfo
      ? selectedOfferingTransportInfo.periodPickUpDate
      : '',
    periodPickUpTime: selectedOfferingTransportInfo
      ? selectedOfferingTransportInfo.periodPickUpTime
      : '',
  };

  const validationSchema = yup.object({
    pickUpPlace: yup.string().required(t`ats2ats3:validation.isRequired`),
    destinationPlace: yup.string().required(t`ats2ats3:validation.isRequired`),
    numberOfSeats: yup
      .number()
      .min(0)
      .required(t`ats2ats3:validation.isRequired`),
    vehicleNumber: yup.string().required(t`ats2ats3:validation.isRequired`),
    vehicleBrand: yup.string().required(t`ats2ats3:validation.isRequired`),
    periodPickUpDate: yup
      .date()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
    periodPickUpTime: yup.string().required(t`ats2ats3:validation.isRequired`),
  });

  const dataHelpInfoAdded = () => {
    sectionSaved();
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: value => {
      const body = {
        pickUpPlace: value.pickUpPlace,
        destinationPlace: value.destinationPlace,
        numberOfSeats: parseInt(value.numberOfSeats),
        vehicleNumber: value.vehicleNumber,
        vehicleBrand: value.vehicleBrand,
        periodPickUpDate: value.periodPickUpDate,
        periodPickUpTime: value.periodPickUpTime,
      };

      if (!savedTransport) {
        HelpService.postOfferingTransportInfo(offeringHelpInfo.id, body)
          .then(({ data }) => {
            setSavedTransport(data);
            dataHelpInfoAdded();
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        HelpService.putOfferingTransportInfo(savedTransport.id, body)
          .then(() => {
            dataHelpInfoAdded();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  });

  return (
    <>
      <WizardStep
        subInfo={savedTransport ? 'help:help.sectionSaved' : null}
        withSaveButton
        title={t('help:help.transport')}
        isOpen={isTransportOpen}
        onCloseSelf={() => {
          setIsTransportOpen(false);
        }}
        onOpen={() => {
          setIsTransportOpen(true);
        }}
        onClose={() => {
          formik.submitForm();
        }}
      >
        <div className={classes.container}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.padding}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">
                    {t('help:help.pickupPlace')}
                  </Typography>
                  <TextField
                    placeholder={t('help:help.enterPickupPlace')}
                    formik={formik}
                    name="pickUpPlace"
                    variant="transparent"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">
                    {t('help:help.destinationPlace')}
                  </Typography>
                  <TextField
                    placeholder={t('help:help.enterDestinationPlace')}
                    formik={formik}
                    name="destinationPlace"
                    variant="transparent"
                  />
                </Grid>
              </Grid>

              <Grid container alignItems={'center'}>
                <Grid item xs={12} md={2}>
                  <Typography className={classes.transportLabel} variant="h6">
                    {t('help:help.seatsPlace')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    type="number"
                    formik={formik}
                    name="numberOfSeats"
                    variant="transparent"
                  />
                </Grid>
              </Grid>

              <Grid container alignItems={'center'}>
                <Grid item xs={12} md={2}>
                  <Typography className={classes.transportLabel} variant="h6">
                    {t('help:help.car')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    formik={formik}
                    label={t('help:help.registrationNumber')}
                    name="vehicleNumber"
                    variant="transparent"
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    formik={formik}
                    label={t('help:help.carBrand')}
                    name="vehicleBrand"
                    variant="transparent"
                  />
                </Grid>
              </Grid>

              <Grid container alignItems={'center'}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {t('help:help.plannedPickupDate')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="date"
                    formik={formik}
                    name="periodPickUpDate"
                    variant="transparent"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    type="time"
                    formik={formik}
                    name="periodPickUpTime"
                    variant="transparent"
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </WizardStep>
    </>
  );
};

export default withStyles(styles)(withTranslation()(Transport));
