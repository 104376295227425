import { createAction } from '@reduxjs/toolkit';
import {
  AUTH,
  IS_FETCHING,
  SET_CURRENT_USER,
  LOGOUT,
  SET_CONFIG,
  SET_CONFIG_LIST,
  SET_LAST_PAGE,
} from '../actionConstants';

export const authAction = createAction(AUTH);
export const isFetchingAction = createAction(IS_FETCHING);
export const setCurrentUserAction = createAction(SET_CURRENT_USER);
export const logoutAction = createAction(LOGOUT);
export const setConfigAction = createAction(SET_CONFIG);
export const setConfigListAction = createAction(SET_CONFIG_LIST);
export const setLastPageAction = createAction(SET_LAST_PAGE);
