import { Grid, withStyles } from '@material-ui/core';
import { isEmpty, sortBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { Button, Snackbar } from 'shared/newComponents';
import {
  ProjectV2Service,
  CountryService,
  EmploymentType,
  IndustryCategoryService,
} from 'shared/services';
import DepartmentService from 'shared/services/department.service';
import {
  ProjectCreateWizardStep1,
  ProjectCreateWizardStep2,
  ProjectCreateWizardStep3,
  ProjectCreateWizardStep4,
  ProjectCreateWizardStep5,
  ProjectCreateWizardStep6,
  ProjectCreateWizardStep7,
  ProjectCreateWizardStep8,
} from '..';
import { styles } from './ProjectCreateWizard.styles';
import { isTrue } from './ProjectCreateWizard.utils';

const ProjectCreateWizard = ({
  t,
  setStep1FormValues,
  setStep2FormValues,
  setStep3FormValues,
  setStep4FormValues,
  setStep5FormValues,
  setStep6FormValues,
  setStep7FormValues,
  setStep8FormValues,
  setCurrentStage,
  classes,
  initialProjectValues,
  history,
  currentStage,
  step5AetAssets,
  step5Assets,
  step2FormValues,
  step3FormValues,
  step4FormValues,
  step5FormValues,
  step6FormValues,
  step7FormValues,
  step8FormValues,
}) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const [assets] = useState([]);
  const [isStep1Open, setIsStep1Open] = useState(!initialProjectValues.id);
  const [isStep2Open, setIsStep2Open] = useState(false);
  const [isStep3Open, setIsStep3Open] = useState(false);
  const [isStep4Open, setIsStep4Open] = useState(false);
  const [isStep5Open, setIsStep5Open] = useState(false);
  const [isStep6Open, setIsStep6Open] = useState(false);
  const [isStep7Open, setIsStep7Open] = useState(false);
  const [isStep8Open, setIsStep8Open] = useState(false);
  const [recruitmentPayment, setRecruitmentPayment] = useState([]);
  const [countries, setCountries] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [industryCategories, setIndustryCategories] = useState([]);
  const [departments, setDepartments] = useState([]);

  const getRecruitmentPayment = () => {
    if (initialProjectValues.id) {
      ProjectV2Service.getRecruitmentPayment(initialProjectValues.id).then(
        ({ data }) => {
          setRecruitmentPayment(data);
        }
      );
    }
  };

  const getCountries = () => {
    CountryService.getCountries().then(({ data }) => {
      setCountries(data);
    });
  };

  const getEmploymentTypes = () => {
    EmploymentType.getEmploymentTypes().then(({ data }) => {
      setEmploymentTypes(sortBy(data, o => o.id));
    });
  };

  const getIndustryCategories = () => {
    IndustryCategoryService.getIndustryCategories().then(({ data }) =>
      setIndustryCategories(data)
    );
  };

  const getDepartments = () => {
    DepartmentService.getDepartments().then(({ data }) => setDepartments(data));
  };

  const isProjectSave = !!initialProjectValues.id;

  const isThreeStageSaved = currentStage >= 3;

  useEffect(() => {
    setIsSnackbarOpen(isTrue(localStorage.getItem('isDataSave')));
    localStorage.setItem('isDataSave', 'false');

    if (isTrue(localStorage.getItem('isNewProject'))) {
      setIsStep1Open(false);
      setIsStep2Open(true);
      localStorage.setItem('isNewProject', 'false');
    }
    getCountries();
    getEmploymentTypes();
    getIndustryCategories();
    getDepartments();
  }, []);

  useEffect(() => {
    getRecruitmentPayment();
  }, [step7FormValues]);
  const closeAll = () => {
    setIsStep1Open(false);
    setIsStep2Open(false);
    setIsStep3Open(false);
    setIsStep4Open(false);
    setIsStep5Open(false);
    setIsStep6Open(false);
    setIsStep7Open(false);
    setIsStep8Open(false);
  };

  const formStepTouched = formStep => !isEmpty(formStep.touched);
  const formStepError = formStep => !isEmpty(formStep.errors);
  const formStepDirty = formStep => formStep.dirty;

  const isFormStepErrorFullCheck = formStep => {
    return (
      (!formStepTouched(formStep) && !formStepDirty(formStep)) ||
      formStepError(formStep)
    );
  };

  const isFormStepError = formStep => {
    return (
      (!formStepTouched(formStep) && !formStepDirty(formStep)) ||
      formStepError(formStep)
    );
  };

  const isFormStepSaveButtonDisabled = formStep =>
    (formStepTouched(formStep) && formStepError(formStep)) ||
    !formStepDirty(formStep);

  const handleClickReturnToProject = () => {
    history.push(PATHS.PROJECT.SHOW.replace(':id', initialProjectValues.id));
    window.location.reload();
  };

  return (
    <Grid container>
      <Snackbar
        open={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        label={t('projectCreator:core.sectionHasSaved')}
      />
      <Snackbar
        type={'error'}
        open={isSnackbarErrorOpen}
        onClose={() => setIsSnackbarErrorOpen(false)}
        label={t('projectCreator:core.sectionHasNotSaved')}
      />
      <ProjectCreateWizardStep1
        departments={departments}
        setStep7FormValues={setStep7FormValues}
        setStep1FormValues={setStep1FormValues}
        assets={assets}
        history={history}
        t={t}
        isStep1Open={isStep1Open}
        isProjectSave={isProjectSave}
        setIsStep1Open={setIsStep1Open}
        setIsStep2Open={setIsStep2Open}
        isFormStepError={isFormStepError}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={closeAll}
        initialProjectValues={initialProjectValues}
        countries={countries}
        industryCategories={industryCategories}
        employmentTypes={employmentTypes}
        step2FormValues={step2FormValues}
      />
      <ProjectCreateWizardStep2
        setCurrentStage={setCurrentStage}
        isFormStepError={isFormStepError}
        isStep2Open={isStep2Open}
        setIsStep2Open={setIsStep2Open}
        setIsStep3Open={setIsStep3Open}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={closeAll}
        isProjectSave={isProjectSave}
        initialProjectValues={initialProjectValues}
        history={history}
        t={t}
        setStep2FormValues={setStep2FormValues}
        step3FormValues={step3FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
      />

      <ProjectCreateWizardStep3
        setCurrentStage={setCurrentStage}
        history={history}
        t={t}
        initialProjectValues={initialProjectValues}
        setStep3FormValues={setStep3FormValues}
        isProjectSave={isProjectSave && currentStage > 1}
        isFormStepError={isFormStepError}
        closeAll={closeAll}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        isStep3Open={isStep3Open}
        setIsStep3Open={setIsStep3Open}
        setIsStep4Open={setIsStep4Open}
        classes={classes}
        step4FormValues={step4FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
      />

      <ProjectCreateWizardStep4
        history={history}
        t={t}
        initialProjectValues={initialProjectValues}
        setStep4FormValues={setStep4FormValues}
        isThreeStageSaved={isThreeStageSaved}
        isProjectSave={isProjectSave}
        isFormStepError={isFormStepError}
        closeAll={closeAll}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        classes={classes}
        setIsStep4Open={setIsStep4Open}
        setIsStep5Open={setIsStep5Open}
        isStep4Open={isStep4Open}
        step5FormValues={step5FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
      />

      <ProjectCreateWizardStep5
        history={history}
        t={t}
        initialProjectValues={initialProjectValues}
        setStep5FormValues={setStep5FormValues}
        isThreeStageSaved={isThreeStageSaved}
        isProjectSave={isProjectSave}
        isFormStepError={isFormStepError}
        closeAll={closeAll}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        classes={classes}
        isStep5Open={isStep5Open}
        setIsStep6Open={setIsStep6Open}
        setIsStep5Open={setIsStep5Open}
        step5Assets={step5Assets}
        assets={step5Assets}
        setAssets={step5AetAssets}
        step6FormValues={step6FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
      />
      <ProjectCreateWizardStep6
        history={history}
        t={t}
        initialProjectValues={initialProjectValues}
        setStep5FormValues={setStep5FormValues}
        isThreeStageSaved={isThreeStageSaved}
        isProjectSave={isProjectSave}
        isFormStepError={isFormStepError}
        closeAll={closeAll}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        setStep6FormValues={setStep6FormValues}
        isStep6Open={isStep6Open}
        setIsStep6Open={setIsStep6Open}
        setIsStep7Open={setIsStep7Open}
        step7FormValues={step7FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
      />

      <ProjectCreateWizardStep7
        projectId={initialProjectValues.id ? initialProjectValues.id : null}
        recruitmentPayment={recruitmentPayment}
        countries={countries}
        history={history}
        t={t}
        setStep5FormValues={setStep5FormValues}
        isThreeStageSaved={isThreeStageSaved}
        isProjectSave={isProjectSave}
        isFormStepError={isFormStepErrorFullCheck}
        closeAll={closeAll}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        classes={classes}
        setStep7FormValues={setStep7FormValues}
        isStep7Open={isStep7Open}
        setIsStep7Open={setIsStep7Open}
        setIsStep8Open={setIsStep8Open}
        step8FormValues={step8FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
        projectValues={initialProjectValues}
      />

      <ProjectCreateWizardStep8
        history={history}
        t={t}
        initialProjectValues={initialProjectValues}
        setStep5FormValues={setStep5FormValues}
        isThreeStageSaved={isThreeStageSaved}
        isProjectSave={isProjectSave}
        isFormStepError={isFormStepError}
        closeAll={closeAll}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        classes={classes}
        isStep8Open={isStep8Open}
        setIsStep8Open={setIsStep8Open}
        match={history.match}
        setStep8FormValues={setStep8FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
      />
      {initialProjectValues.id ? (
        <Grid container justifyContent="center" className={classes.saveButton}>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              label="projectCreator:core.returnToProjectDetails"
              onClick={handleClickReturnToProject}
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(
  withTranslation()(withRouter(ProjectCreateWizard))
);
