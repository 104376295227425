import { Grid, withStyles } from '@material-ui/core';
import { get, isNil } from 'lodash';
import React from 'react';
import EmploymentIcon from 'shared/assets/img/icons/project-creator/employment.png';
import GuaranteeIcon from 'shared/assets/img/icons/project-creator/guarantee.png';
import MeetingIcon from 'shared/assets/img/icons/project-creator/meeting.png';
import ProjectCoordinatorIcon from 'shared/assets/img/icons/project-creator/project-coordinator.png';
import ProjectPublicationIcon from 'shared/assets/img/icons/project-creator/project-publication.png';
import { Translate } from 'shared/newComponents';
import FinicalPlanCard from '../FinicalPlanCard';
import { styles } from './FinicalPlanContainer.styles';

const FinicalPlanContainer = ({
  finicalPlan,
  selectWarrantyOrderValue,
  finicalPlanFetchStatus,
  isDraft,
  setOpenWarrantyOrderDialog,
  selectMentorOrderValue,
  setOpenMentorOrderDialog,
  organization,
  onlyView = false,
}) => {
  const publicationValue =
    get(finicalPlan, 'publicationValue') !== null &&
    get(finicalPlan, 'publicationValue') !== undefined
      ? get(finicalPlan, 'publicationValue').toFixed(2)
      : '';
  const meetingsValue =
    get(finicalPlan, 'meetingsValue') !== null &&
    get(finicalPlan, 'meetingsValue') !== undefined
      ? get(finicalPlan, 'meetingsValue').toFixed(2)
      : '';

  const hiringValue =
    get(finicalPlan, 'hiringValue') !== null &&
    get(finicalPlan, 'hiringValue') !== undefined
      ? get(finicalPlan, 'hiringValue').toFixed(2)
      : '';

  const subscriptionValue =
    get(finicalPlan, 'subscriptionValue') !== null &&
    get(finicalPlan, 'subscriptionValue') !== undefined
      ? get(finicalPlan, 'subscriptionValue').toFixed(2)
      : '';

  const mentorValue =
    get(finicalPlan, 'mentorValue') !== null &&
    get(finicalPlan, 'mentorValue') !== undefined
      ? get(finicalPlan, 'mentorValue').toFixed(2)
      : '';
  const warrantyValue =
    get(finicalPlan, 'warrantyValue') !== null &&
    get(finicalPlan, 'warrantyValue') !== undefined
      ? get(finicalPlan, 'warrantyValue').toFixed(2)
      : '';

  return (
    <Grid container>
      <Grid container style={{ paddingBottom: 16 }}>
        <Translate
          text={'projectCreator:step8.finicalPlanHeader'}
          bold
          variant="h6"
        />
      </Grid>
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          price={publicationValue}
          iconSrc={ProjectPublicationIcon}
          name={'projectCreator:step8.publicationValue'}
          description={'projectCreator:step8.publicationValueDesc'}
        />
      </Grid>
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          price={meetingsValue}
          iconSrc={MeetingIcon}
          name={'projectCreator:step8.meetingsValue'}
          description={'projectCreator:step8.meetingsValueDesc'}
        />
      </Grid>
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          price={hiringValue}
          iconSrc={EmploymentIcon}
          name={'projectCreator:step8.hiringValue'}
          description={'projectCreator:step8.hiringValueDesc'}
        />
      </Grid>
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          price={warrantyValue}
          iconSrc={GuaranteeIcon}
          name={'projectCreator:step8.warrantyValue'}
          description={'projectCreator:step8.warrantyValueDesc'}
          selected={selectWarrantyOrderValue}
          greyStyle={!selectWarrantyOrderValue}
          fetching={finicalPlanFetchStatus}
          isPointer={
            !(isNil(warrantyValue) || warrantyValue === '') &&
            !finicalPlanFetchStatus &&
            isDraft
          }
          onClick={() => {
            if (isNil(warrantyValue) || warrantyValue === '' || onlyView) {
              return;
            }
            if (!selectWarrantyOrderValue) {
              setOpenWarrantyOrderDialog(true);
            }
          }}
        />
      </Grid>
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          greyStyle={!selectMentorOrderValue}
          price={mentorValue}
          iconSrc={ProjectCoordinatorIcon}
          name={'projectCreator:step8.mentorValue'}
          description={`${mentorValue} PLN/mc`}
          selected={selectMentorOrderValue}
          isPointer={
            !(isNil(mentorValue) || mentorValue === '') &&
            !finicalPlanFetchStatus &&
            isDraft
          }
          fetching={finicalPlanFetchStatus}
          onClick={() => {
            if (isNil(mentorValue) || mentorValue === '' || onlyView) {
              return;
            }
            if (!selectMentorOrderValue) {
              setOpenMentorOrderDialog(true);
            }
          }}
        />
      </Grid>
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }} />
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          price={
            organization && organization.currentSubscriptionType === 1
              ? subscriptionValue
              : ''
          }
          subscriptionVariantText="Economic"
          name={'projectCreator:step8.subscription'}
          description={'projectCreator:step8.subscriptionDesc'}
        />
      </Grid>
      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          price={
            organization && organization.currentSubscriptionType === 2
              ? subscriptionValue
              : ''
          }
          subscriptionVariantText="Comfort"
          name={'projectCreator:step8.subscription'}
          description={'projectCreator:step8.subscriptionDesc'}
        />
      </Grid>

      <Grid item xs={4} style={{ minWidth: 250, display: 'flex' }}>
        <FinicalPlanCard
          price={
            organization && organization.currentSubscriptionType === 3
              ? subscriptionValue
              : ''
          }
          subscriptionVariantText="Premium"
          name={'projectCreator:step8.subscription'}
          description={'projectCreator:step8.subscriptionDesc'}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FinicalPlanContainer);
