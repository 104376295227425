import { LinearProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { PATHS } from 'config';
import { LANDING_PAGE_URL } from 'shared/constants';
import LandingLayout from '../../../components/LandingLayout/';
import LoginForm from '../components/LoginForm/';

export class Login extends Component {
  state = {
    error: null,
    isFirstLogin: false,
    fetch: 'idle',
  };
  componentDidMount() {
    document.body.style.display = 'none';
    window.location = `${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.AUTH.LOGIN}`;
  }

  handleSubmit = async values => {
    const { t } = this.props;
    try {
      await this.setState({
        error: null,
        fetch: 'fetching',
      });
      const { data } = await this.props.authUser(values);
      await this.setState({
        isFirstLogin: data.isFirstLogin,
        fetch: 'idle',
      });
    } catch (error) {
      if (values.password === undefined) {
        this.props.showSnackbarMessage(t`login:error.noConnectedAccount`);
      } else {
        this.props.showSnackbarMessage(t('login:error.invalidData'));
      }
      this.setState({ error: error.toString() });
    }
  };

  getContentToRender = () => {
    const { classes, isFetching, isAuthorized } = this.props;
    if (this.state.fetch === 'idle' && isAuthorized) {
      if (isAuthorized && !this.state.isFirstLogin) {
        return <Redirect to={PATHS.DASHBOARD.INDEX} />;
      } else if (isAuthorized && this.state.isFirstLogin) {
        return <Redirect to={PATHS.PROJECT.CREATE} />;
      }
    } else {
      return (
        <LandingLayout>
          <Grid item xs={12} className={classes.formContainer}>
            <LoginForm onSubmit={this.handleSubmit} />
            {isFetching && <LinearProgress className={classes.progress} />}
          </Grid>
        </LandingLayout>
      );
    }
  };

  render() {
    return this.getContentToRender();
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
};

export default Login;
