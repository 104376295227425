import { filter, get } from 'lodash';

export const getWorkStyles = projectDetails => ({
  workStyleCreatorGraphPoints: get(
    projectDetails,
    'workStyleCreatorGraphPoints'
  ),
  workStyleStarGraphPoints: get(projectDetails, 'workStyleStarGraphPoints'),
  workStyleSupporterGraphPoints: get(
    projectDetails,
    'workStyleSupporterGraphPoints'
  ),
  workStyleDealMakerGraphPoints: get(
    projectDetails,
    'workStyleDealMakerGraphPoints'
  ),
  workStyleTraderGraphPoints: get(projectDetails, 'workStyleTraderGraphPoints'),
  workStyleAccumulatorGraphPoints: get(
    projectDetails,
    'workStyleAccumulatorGraphPoints'
  ),
  workStyleLordGraphPoints: get(projectDetails, 'workStyleLordGraphPoints'),
  workStyleMechanicGraphPoints: get(
    projectDetails,
    'workStyleMechanicGraphPoints'
  ),
});

export const getRequiredAttachements = (projectDetails, t) => {
  const isRequireAttachmentCv = get(projectDetails, 'isRequireAttachmentCv')
    ? 'C.V.'
    : null;
  const isRequireAttachmentRecruiterApproved = get(
    projectDetails,
    'isRequireAttachmentRecruiterApproved'
  )
    ? t`candidateCreator:step4.recruiterApproved`
    : null;
  const isRequireAttachmentPassportStampScan = get(
    projectDetails,
    'isRequireAttachmentPassportStampScan'
  )
    ? t`candidateCreator:step4.passportStampScan`
    : null;
  const isRequireAttachmentPassportVisaScan = get(
    projectDetails,
    'isRequireAttachmentPassportVisaScan'
  )
    ? t`candidateCreator:step4.passportVisaScan`
    : null;
  const isRequireAttachmentPassportPersonalDataScan = get(
    projectDetails,
    'isRequireAttachmentPassportPersonalDataScan'
  )
    ? t`candidateCreator:step4.passportPersonalDataScan`
    : null;
  const isRequireAttachmentWorkPermitScan = get(
    projectDetails,
    'isRequireAttachmentWorkPermitScan'
  )
    ? t`candidateCreator:step4.workPermitScan`
    : null;
  const isRequireAttachmentWorkQualificationScan = get(
    projectDetails,
    'isRequireAttachmentWorkQualificationScan'
  )
    ? t`candidateCreator:step4.workQualificationScan`
    : null;
  const isRequireAttachmentCertificateAndDiplomaScan = get(
    projectDetails,
    'isRequireAttachmentCertificateAndDiplomaScan'
  )
    ? t`candidateCreator:step4.certificateAndDiplomaScan`
    : null;
  const isRequireAttachmentOtherScan = get(
    projectDetails,
    'isRequireAttachmentOtherScan'
  );
  const isRequireAttachmentOtherDesc =
    isRequireAttachmentOtherScan &&
    get(projectDetails, 'isRequireAttachmentOtherDesc')
      ? get(projectDetails, 'isRequireAttachmentOtherDesc')
      : null;
  return filter(
    [
      isRequireAttachmentCv,
      isRequireAttachmentRecruiterApproved,
      isRequireAttachmentPassportStampScan,
      isRequireAttachmentPassportVisaScan,
      isRequireAttachmentPassportPersonalDataScan,
      isRequireAttachmentWorkPermitScan,
      isRequireAttachmentWorkQualificationScan,
      isRequireAttachmentCertificateAndDiplomaScan,
      isRequireAttachmentOtherDesc,
    ],
    o => !!o
  );
};
