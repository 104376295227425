export const styles = () => ({
  insurance: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  checkboxes: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 40,
  },
});
