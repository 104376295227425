import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tab,
  Tabs,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  Assignment as AssigmentIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProjectAtsCandidateInProjectFeedbackFromEmployer from 'shared/newComponents/ProjectAtsCandidateInProjectFeedbackFromEmployer';
import ProjectAtsCandidateInProjectRecommend from 'shared/newComponents/ProjectAtsCandidateInProjectRecommend';
import { getCandidate } from 'shared/services/candidateService';
import { getLanguageSkill } from '../modules/candidate/NewCandidateCreateView/components/CandidateCreateWizard/CandidateCreateWizard.utils';
import CandidateAnonymousCard from '../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateAnonymousCard';
import CandidatePersonalCard from '../modules/candidate/NewCandidateDetails/components/CandidatePersonalCard/CandidatePersonalCard';
import ContentDialog from './LayoutContentDialog';
import ProjectAtsCandidateInProjectSettings from './ProjectAtsCandidateInProjectSettings';

const styles = () => ({
  menuConntent: {
    width: '280px',
    margin: '0px auto',
  },
  appBar: {
    position: 'relative',
    background: '#35BFE4',
  },
  flex: {
    flex: 1,
  },
  dialogPaper: {
    width: '80%',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

function TabContainer(props) {
  return <div>{props.children}</div>;
}

const ProjectAtsCandidateInProject = ({
  handleClose,
  selectedCandidateStageNumber,
  projectId,
  reloadAtsHandler,
  open,
  ownerInProject,
  industryCategories,
  readOnly = false,
  t,
  ...props
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(0);
  }, [selectedCandidateStageNumber]);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const getContentCandidateInProject = () => {
    const { classes, currentUser, candidate = {}, project } = props;

    const { permissions = {} } = project;

    const showCandidateData = !(
      permissions.isHiringManager && selectedCandidateStageNumber < 5
    );

    return (
      <div>
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {t`atsSubMenu:candidateInProj`} / {candidate.firstName}{' '}
              {showCandidateData ? candidate.lastName : ''}
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              {t`atsSubMenu:exit`}
            </Button>
          </Toolbar>
        </AppBar>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            classes={{
              flexContainer: classes.tabs,
            }}
          >
            <Tab label={t`atsSubMenu:anonCard`} icon={<AccountCircleIcon />} />

            <Tab
              label={t`atsSubMenu:personalData`}
              icon={<AssigmentIcon />}
              style={showCandidateData ? null : { display: 'none' }}
            />
            <Tab label={t`atsSubMenu:recomendation`} icon={<AssigmentIcon />} />
            <Tab label={t`atsSubMenu:feedback`} icon={<AssigmentIcon />} />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            {!candidate.id ? (
              <LinearProgress
                style={{ marginTop: '20%', marginBottom: '20%' }}
              />
            ) : (
              <CandidateAnonymousCard
                candidate={{
                  ...candidate,
                  ...getLanguageSkill(candidate.userSkills),
                }}
              />
            )}
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            {!candidate.id ? (
              <LinearProgress
                style={{ marginTop: '20%', marginBottom: '20%' }}
              />
            ) : (
              <CandidatePersonalCard
                readOnly={readOnly}
                candidate={candidate}
                currentUser={currentUser}
              />
            )}
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            {!candidate.id ? (
              <LinearProgress
                style={{ marginTop: '20%', marginBottom: '20%' }}
              />
            ) : (
              <ProjectAtsCandidateInProjectRecommend
                showCandidateData={showCandidateData}
                candidate={{
                  ...candidate,
                  ...getLanguageSkill(candidate.userSkills),
                }}
                industryCategories={industryCategories}
              />
            )}
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            {!candidate.id ? (
              <LinearProgress
                style={{ marginTop: '20%', marginBottom: '20%' }}
              />
            ) : (
              <ProjectAtsCandidateInProjectFeedbackFromEmployer
                readOnly={readOnly}
                candidate={candidate}
              />
            )}
          </TabContainer>
        )}
        {value === 4 && (
          <TabContainer>
            {!candidate.id ? (
              <LinearProgress
                style={{ marginTop: '20%', marginBottom: '20%' }}
              />
            ) : (
              <ProjectAtsCandidateInProjectSettings
                projectId={projectId}
                ownerCandidate={ownerInProject}
                reloadAtsHandler={reloadAtsHandler}
              />
            )}
          </TabContainer>
        )}
      </div>
    );
  };

  const content = getContentCandidateInProject();

  return (
    <ContentDialog open={open} onClose={handleClose}>
      {content}
    </ContentDialog>
  );
};

ProjectAtsCandidateInProject.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number,
};

const mapStateToProps = store => {
  return {
    candidate: store.candidates.currentCandidate,
    currentUser: store.auth.currentUser,
    project: store.projects.currentProject,
  };
};

const mapDispatchToProps = {
  getCandidate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectAtsCandidateInProject)));
