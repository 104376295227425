import { createReducer } from '@reduxjs/toolkit';
import {
  setCurrentCandidateAction,
  clearCurrentCandidateAction,
  setCurrentCandidatesListAction,
  setCurrentCandidatesListCountAction,
  setCandidatesBelongsToRecruiterListAction,
  setCandidatesPendingToRecruiterListAction,
  clearCurrentCandidatesListAction,
  setCandidatesInProjectAction,
  clearCandidatesInProjectAction,
  setMyCandidatesAction,
  clearMyCandidatesAction,
  setCurrentCandidatesLimitedListAction,
  clearCurrentCandidatesLimitedListAction,
  setCurrentCandidatesLimitedListCounterAction,
  clearCurrentCandidatesLimitedListCounterAction,
} from './actions';

const initialState = {
  currentCandidate: {},
  currentCandidatesList: [],
  currentCandidatesListCount: 0,
  candidatesInProject: [],
  myCandidates: [],
  candidatesBelongsToRecruiterList: [],
  candidatesPendingToRecruiterList: [],
  currentCandidatesLimitedList: [],
  currentCandidatesLimitedListCounter: 0,
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setCurrentCandidateAction, (state, action) => {
      state.currentCandidate = action.payload;
    })
    .addCase(clearCurrentCandidateAction, (state, action) => {
      state.currentCandidate = initialState.currentCandidate;
    })
    .addCase(setCurrentCandidatesListAction, (state, action) => {
      state.currentCandidatesList = action.payload;
    })
    .addCase(setCurrentCandidatesListCountAction, (state, action) => {
      state.currentCandidatesListCount = action.payload;
    })
    .addCase(setCandidatesBelongsToRecruiterListAction, (state, action) => {
      state.candidatesBelongsToRecruiterList = action.payload;
    })
    .addCase(setCandidatesPendingToRecruiterListAction, (state, action) => {
      state.candidatesPendingToRecruiterList = action.payload;
    })
    .addCase(clearCurrentCandidatesListAction, (state, action) => {
      state.currentCandidatesList = [];
    })
    .addCase(setCandidatesInProjectAction, (state, action) => {
      state.candidatesInProject = action.payload;
    })
    .addCase(clearCandidatesInProjectAction, (state, action) => {
      state.candidatesInProject = [];
    })
    .addCase(setMyCandidatesAction, (state, action) => {
      state.myCandidates = action.payload;
    })
    .addCase(clearMyCandidatesAction, (state, action) => {
      state.myCandidates = [];
    })
    .addCase(setCurrentCandidatesLimitedListAction, (state, action) => {
      state.currentCandidatesLimitedList = action.payload;
    })
    .addCase(clearCurrentCandidatesLimitedListAction, (state, action) => {
      state.currentCandidatesLimitedList = [];
    })
    .addCase(setCurrentCandidatesLimitedListCounterAction, (state, action) => {
      state.currentCandidatesLimitedListCounter = action.payload;
    })
    .addCase(
      clearCurrentCandidatesLimitedListCounterAction,
      (state, action) => {
        state.currentCandidatesLimitedListCounter = 0;
      }
    )
);
