import {
  FormControlLabel,
  Checkbox as BaseCheckbox,
  Grid,
  withStyles,
  Switch,
  FormHelperText,
} from '@material-ui/core';
import { get, has } from 'lodash';
import React from 'react';
import { Hint, Translate } from '..';
import { styles } from './Checkbox.styles';

const Checkbox = ({
  formik,
  name,
  label,
  hintContent,
  type,
  classes,
  onChange,
  disabled = false,
  style = {},
}) => {
  const error =
    has(formik, `touched.${name}`) && Boolean(get(formik.errors, name));

  return (
    <Grid container alignItems="center" className={classes.container}>
      <FormControlLabel
        disabled={disabled}
        control={
          type === 'switch' ? (
            <Switch
              checked={get(formik.values, name)}
              onChange={onChange ? onChange : formik.handleChange}
              name={name}
              color="primary"
            />
          ) : (
            <BaseCheckbox
              checked={get(formik.values, name)}
              onChange={onChange ? onChange : formik.handleChange}
              name={name}
              color="primary"
            />
          )
        }
        label={<Translate style={style} text={label} variant="body1" />}
      />
      {hintContent && <Hint content={hintContent} />}
      {error && (
        <Grid container>
          <FormHelperText className={classes.errorLabel}>
            {has(formik, `touched.${name}`) && get(formik.errors, name)}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(Checkbox);
