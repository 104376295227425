import { TableRow, TableCell, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DataItemListItemCandidate from '../../../components/DataItemListItemCandidate';

const styles = theme => ({
  TableCell: {
    color: theme.palette.black,
    fontSize: 12,
    fontWeight: 300,
    textAlign: 'center',
  },
  avatar: {
    width: 50,
    height: 50,
  },
  hiddenSm: {
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
  },
  hiddenXs: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  zeroField: {
    color: theme.palette.jobllegroLightGrey,
  },
  primaryListItem: {
    color: theme.palette.jobllegroBlue,
  },
  listItem: {
    paddingLeft: '16px',
  },
});

export const SummaryRowComponent = props => {
  const { classes: c, classes, data, key } = props;

  return (
    <TableRow hover key={key}>
      <TableCell>
        <DataItemListItemCandidate key={data.id} data={data} />
      </TableCell>
      <TableCell
        classes={{
          root: data.stage1 === 0 && classes.zeroField,
        }}
        numeric
        padding="none"
        className={classNames(c.TableCell, c.hiddenSm, c.hiddenXs)}
      >
        {data.stage1}
      </TableCell>
      <TableCell
        classes={{
          root: data.stage2 === 0 && classes.zeroField,
        }}
        numeric
        padding="none"
        className={classNames(c.TableCell, c.hiddenSm, c.hiddenXs)}
      >
        {data.stage2}
      </TableCell>
      <TableCell
        classes={{
          root: data.stage3 === 0 && classes.zeroField,
        }}
        numeric
        padding="none"
        className={classNames(c.TableCell, c.hiddenSm, c.hiddenXs)}
      >
        {data.stage3}
      </TableCell>
      <TableCell
        classes={{
          root: data.stage4 === 0 && classes.zeroField,
        }}
        numeric
        padding="none"
        className={classNames(c.TableCell, c.hiddenXs)}
      >
        {data.stage4}
      </TableCell>
      <TableCell
        classes={{
          root: data.stage5 === 0 && classes.zeroField,
        }}
        numeric
        padding="none"
        className={classNames(c.TableCell, c.hiddenXs)}
      >
        {data.stage5}
      </TableCell>
      <TableCell
        classes={{
          root: data.stage6 === 0 && classes.zeroField,
        }}
        numeric
        padding="none"
        className={c.TableCell}
      >
        {data.stage6}
      </TableCell>
      <TableCell
        classes={{
          root: data.rejected === 0 && classes.zeroField,
        }}
        numeric
        padding="none"
        className={c.TableCell}
      >
        {data.rejected}
      </TableCell>
    </TableRow>
  );
};

SummaryRowComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(SummaryRowComponent);
