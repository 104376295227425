import { Divider, Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AtsSubMenu from '../../../ProjectAts/components/AtsSubMenu';
import ProjectMenu from '../../../projectMenu/ProjectMenu';
import AtsEmploymentProcessView from '../AtsEmploymentProcessView';
import ProjectDetails from '../ProjectDetails';
import { styles } from './ProjectAtsEmploymentProcessViewContent.styles';

const ProjectAtsEmploymentProcessViewContent = ({ project, t, classes }) => {
  return (
    <Grid container>
      <Grid
        container
        alignItems="flex-start"
        spacing={0}
        className={classes.wrapperForPadding}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
          <ProjectMenu title={'Ats'} />
          <AtsSubMenu />
          <Paper className={classes.rootPaper} elevation={0}>
            <ProjectDetails project={project} />
            <Divider className={classes.divider} />
            <AtsEmploymentProcessView project={project} />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(ProjectAtsEmploymentProcessViewContent))
);
