import { Grid, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { bool, string } from 'yup';
import { enums } from 'shared/enums';
import { Checkbox, Select, Translate } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import TextField from 'shared/newComponents/TextField';
import { OrganizationService } from 'shared/services';
import { getSelectItems } from 'shared/utils/assets';
import CustomDialog from '../CustomDialog';

export function AddOrganizationForm({
  organizationData,
  isOpen,
  onClose,
  organizationAdded,
  t,
}) {
  const isBusinessType = organizationData
    ? organizationData.organizationType ===
      enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
    : false;

  const formData = {
    name: organizationData ? organizationData.name : '',
    description: organizationData ? organizationData.description : '',
    percent: organizationData ? organizationData.percent : 10,
    isPublic: organizationData ? organizationData.isPublic : false,
    invoiceAddress: organizationData ? organizationData.invoiceAddress : '',
    invoiceCompanyName: organizationData
      ? organizationData.invoiceCompanyName
      : '',
    invoiceCity: organizationData ? organizationData.invoiceCity : '',
    invoicePostCode: organizationData ? organizationData.invoicePostCode : '',
    invoiceTypeBusiness: isBusinessType,
    accountNumber: organizationData ? organizationData.accountNumber : '',
    invoicePersonalNumber: organizationData
      ? organizationData.invoicePersonalNumber
      : '',
    invoiceIdentificationNumber: organizationData
      ? organizationData.invoiceIdentificationNumber
      : '',
    currentSubscriptionType: organizationData
      ? organizationData.currentSubscriptionType
      : 0,
    currentSubscriptionValue:
      organizationData && organizationData.currentSubscriptionValue !== null
        ? organizationData.currentSubscriptionValue.toString()
        : '',
    settlementCurrency:
      organizationData && organizationData.settlementCurrency !== null
        ? organizationData.settlementCurrency
        : 0,
    publicationValue:
      organizationData && organizationData.publicationValue !== null
        ? organizationData.publicationValue.toString()
        : '',
    meetingsValue:
      organizationData && organizationData.meetingsValue !== null
        ? organizationData.meetingsValue.toString()
        : '',
    hiringProc:
      organizationData && organizationData.hiringProc !== null
        ? organizationData.hiringProc.toString()
        : '',
    mentorValue:
      organizationData && organizationData.mentorValue !== null
        ? organizationData.mentorValue.toString()
        : '',
    warrantyValue:
      organizationData && organizationData.warrantyValue !== null
        ? organizationData.warrantyValue.toString()
        : '',
    dateStart:
      organizationData && organizationData.dateStart
        ? organizationData.dateStart.toString()
        : '',
    dateStop:
      organizationData && organizationData.dateStop
        ? organizationData.dateStop.toString()
        : '',
    individualFinicalPlan:
      organizationData && organizationData.individualFinicalPlan !== null
        ? organizationData.individualFinicalPlan
        : false,
    currentSubscriptionTypeDisabled:
      organizationData &&
      organizationData.currentSubscriptionTypeDisabled !== null
        ? organizationData.currentSubscriptionTypeDisabled
        : true,
    settlementCurrencyDisabled:
      organizationData && organizationData.settlementCurrencyDisabled !== null
        ? organizationData.settlementCurrencyDisabled
        : true,
    publicationValueDisabled:
      organizationData && organizationData.publicationValueDisabled !== null
        ? organizationData.publicationValueDisabled
        : true,
    meetingsValueDisabled:
      organizationData && organizationData.meetingsValueDisabled !== null
        ? organizationData.meetingsValueDisabled
        : true,
    hiringProcDisabled:
      organizationData && organizationData.hiringProcDisabled !== null
        ? organizationData.hiringProcDisabled
        : true,
    mentorValueDisabled:
      organizationData && organizationData.mentorValueDisabled !== null
        ? organizationData.mentorValueDisabled
        : true,
    warrantyValueDisabled:
      organizationData && organizationData.warrantyValueDisabled !== null
        ? organizationData.warrantyValueDisabled
        : true,
    currentSubscriptionValueDisabled:
      organizationData &&
      organizationData.currentSubscriptionValueDisabled !== null
        ? organizationData.currentSubscriptionValueDisabled
        : true,
  };

  const validationShema = Yup.object().shape(
    {
      name: Yup.string().required(t`addOrganizationForm:name`),
      description: Yup.string().required(t`addOrganizationForm:desc`),
      invoiceTypeBusiness: bool(),
      percent: Yup.number()
        .min(0, t`addOrganizationForm:min`)
        .max(100, t`addOrganizationForm:max`)
        .required(t`addOrganizationForm:percent`)
        .nullable(),
      invoiceAddress: Yup.string()
        .strict(false)
        .trim()
        .required(t`addOrganizationForm:name`)
        .nullable(),
      invoiceCity: Yup.string()
        .strict(false)
        .trim()
        .required(t`addOrganizationForm:name`)
        .nullable(),
      accountNumber: Yup.string()
        .strict(false)
        .trim()
        .required(t`addOrganizationForm:bankAcc`)
        .nullable(),
      invoicePostCode: Yup.string()
        .strict(false)
        .trim()
        .required(t`addOrganizationForm:name`)
        .nullable(),
      invoiceCompanyName: Yup.string()
        .strict(false)
        .trim()
        .required(t`addOrganizationForm:companyName`)
        .nullable(),
      invoiceIdentificationNumber: string().when('invoiceTypeBusiness', {
        is: invoiceTypeBusiness => {
          return invoiceTypeBusiness === true;
        },

        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoicePersonalNumber: string().when('invoiceTypeBusiness', {
        is: invoiceTypeBusiness => {
          return invoiceTypeBusiness === false;
        },
        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      individualFinicalPlan: Yup.bool(),
      currentSubscriptionTypeDisabled: Yup.bool(),
      settlementCurrencyDisabled: Yup.bool(),
      publicationValueDisabled: Yup.bool(),
      meetingsValueDisabled: Yup.bool(),
      hiringProcDisabled: Yup.bool(),
      mentorValueDisabled: Yup.bool(),
      warrantyValueDisabled: Yup.bool(),
      currentSubscriptionValueDisabled: Yup.bool(),
      dateStart: Yup.string().when('individualFinicalPlan', {
        is: individualFinicalPlan => individualFinicalPlan === true,
        then: Yup.string()
          .required(t`addOrganizationForm:isRequired`)
          .required(t`addOrganizationForm:isRequired`),
      }),
      dateStop: Yup.string().when('individualFinicalPlan', {
        is: individualFinicalPlan => individualFinicalPlan === true,
        then: Yup.string()
          .required(t`addOrganizationForm:isRequired`)
          .required(t`addOrganizationForm:isRequired`),
      }),
      settlementCurrency: Yup.number().when('settlementCurrencyDisabled', {
        is: settlementCurrencyDisabled => settlementCurrencyDisabled !== true,
        then: Yup.number()
          .required(t`addOrganizationForm:isRequired`)
          .required(t`addOrganizationForm:isRequired`),
      }),
      currentSubscriptionValue: Yup.number().when(
        'currentSubscriptionValueDisabled',
        {
          is: currentSubscriptionValueDisabled =>
            currentSubscriptionValueDisabled !== true,
          then: Yup.number()
            .min(0, t`addOrganizationForm:positiveVal`)
            .required(t`addOrganizationForm:isRequired`),
        }
      ),
      publicationValue: Yup.number().when('publicationValueDisabled', {
        is: publicationValueDisabled => publicationValueDisabled !== true,
        then: Yup.number()
          .min(0, t`addOrganizationForm:positiveVal`)
          .required(t`addOrganizationForm:isRequired`),
      }),
      meetingsValue: Yup.number().when('meetingsValueDisabled', {
        is: meetingsValueDisabled => meetingsValueDisabled !== true,
        then: Yup.number()
          .min(0, t`addOrganizationForm:positiveVal`)
          .required(t`addOrganizationForm:isRequired`),
      }),
      hiringProc: Yup.number().when('hiringProcDisabled', {
        is: hiringProcDisabled => hiringProcDisabled !== true,
        then: Yup.number()
          .min(0, t`addOrganizationForm:positiveVal`)
          .required(t`addOrganizationForm:isRequired`),
      }),
      mentorValue: Yup.number().when('mentorValueDisabled', {
        is: mentorValueDisabled => mentorValueDisabled !== true,
        then: Yup.number()
          .min(0, t`addOrganizationForm:positiveVal`)
          .required(t`addOrganizationForm:isRequired`),
      }),
      warrantyValue: Yup.number().when('warrantyValueDisabled', {
        is: warrantyValueDisabled => warrantyValueDisabled !== true,
        then: Yup.number()
          .min(0, t`addOrganizationForm:positiveVal`)
          .required(t`addOrganizationForm:isRequired`),
      }),
    },
    [
      ['invoiceTypeBusiness', 'invoiceIdentificationNumber'],
      ['invoiceTypeBusiness', 'invoicePersonalNumber'],
      'invoiceTypeBusiness',
    ]
  );

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationShema,
    onSubmit: (values, actions) => {
      const data = {
        name: values.name,
        description: values.description,
        accountNumber: values.accountNumber,
        percent: values.percent,
        isPublic: values.isPublic,
        invoiceAddress: values.invoiceAddress,
        invoiceCompanyName: values.invoiceCompanyName,
        invoiceCity: values.invoiceCity,
        invoicePostCode: values.invoicePostCode,
        invoicePersonalNumber: values.invoicePersonalNumber,
        invoiceIdentificationNumber: values.invoiceIdentificationNumber,
        currentSubscriptionType: !values.currentSubscriptionTypeDisabled
          ? values.currentSubscriptionType
          : 0,
        settlementCurrency: values.settlementCurrency,
        individualFinicalPlan: values.individualFinicalPlan,
        currentSubscriptionValue:
          !values.currentSubscriptionValueDisabled &&
          values.currentSubscriptionValue !== null
            ? parseInt(values.currentSubscriptionValue)
            : null,
        publicationValue:
          !values.publicationValueDisabled && values.publicationValue !== null
            ? parseFloat(values.publicationValue)
            : null,
        meetingsValue:
          !values.meetingsValueDisabled && values.meetingsValue !== null
            ? parseFloat(values.meetingsValue)
            : null,
        hiringProc:
          !values.hiringProcDisabled && values.hiringProc !== null
            ? parseFloat(values.hiringProc)
            : null,
        mentorValue:
          !values.mentorValueDisabled && values.mentorValue !== null
            ? parseFloat(values.mentorValue)
            : null,
        warrantyValue:
          !values.warrantyValueDisabled && values.warrantyValue !== null
            ? parseFloat(values.warrantyValue)
            : null,
        dateStart: values.dateStart ? values.dateStart : null,
        dateStop: values.dateStop ? values.dateStop : null,
        currentSubscriptionTypeDisabled: values.currentSubscriptionTypeDisabled,
        settlementCurrencyDisabled: values.settlementCurrencyDisabled,
        publicationValueDisabled: values.publicationValueDisabled,
        meetingsValueDisabled: values.meetingsValueDisabled,
        hiringProcDisabled: values.hiringProcDisabled,
        mentorValueDisabled: values.mentorValueDisabled,
        warrantyValueDisabled: values.warrantyValueDisabled,
        currentSubscriptionValueDisabled:
          values.currentSubscriptionValueDisabled,
      };
      if (values.individualFinicalPlan === false) {
        data.currentSubscriptionType = 0;
        data.settlementCurrency = null;
        data.publicationValue = null;
        data.hiringProc = null;
        data.mentorValue = null;
        data.warrantyValue = null;
        data.dateStart = null;
        data.dateStop = null;
        data.currentSubscriptionValue = null;
      }

      OrganizationService.editOrganization(organizationData.id, data)
        .then(() => {
          organizationAdded();
        })
        .catch(error => {
          console.log(error.response);
          if (error.response && error.response.status === 409) {
            return actions.setFieldError('name', t`addOrganizationForm:taken`);
          }
          actions.setFieldError('dateStop', t`addOrganizationForm:error`);
          return actions.setFieldError(
            'dateStart',
            t`addOrganizationForm:error`
          );
        });
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`addOrganizationForm:organizationEdit`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.name')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('organization:field.name')}
                  name={'name'}
                  formik={formik}
                />
              </Grid>
              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.description')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('organization:field.description')}
                  name={'description'}
                  formik={formik}
                />
              </Grid>
              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.percent')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  type={'number'}
                  label={t('organization:field.percent')}
                  name={'percent'}
                  onWheel={e => e.target.blur()}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  label={t('organization:field.isPublic')}
                  name={'isPublic'}
                  formik={formik}
                  type="switch"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  {`${t`addOrganizationForm:invoice`} (${
                    isBusinessType
                      ? t`addOrganizationForm:company`
                      : t`addOrganizationForm:person`
                  })`}
                </Typography>
              </Grid>

              {isBusinessType ? (
                <Grid container>
                  <Grid item xs={12} style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.invoiceIdentificationNumber')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t(
                        'organization:field.invoiceIdentificationNumber'
                      )}
                      name={'invoiceIdentificationNumber'}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.invoicePersonalNumber')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('organization:field.invoicePersonalNumber')}
                      name={'invoicePersonalNumber'}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.invoiceCompanyName')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('organization:field.invoiceCompanyName')}
                  name={'invoiceCompanyName'}
                  formik={formik}
                />
              </Grid>

              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.invoiceAddress')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('organization:field.invoiceAddress')}
                  name={'invoiceAddress'}
                  formik={formik}
                />
              </Grid>

              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.invoiceCity')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('organization:field.invoiceCity')}
                  name={'invoiceCity'}
                  formik={formik}
                />
              </Grid>
              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t('organization:field.invoicePostCode')}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('organization:field.invoicePostCode')}
                  name={'invoicePostCode'}
                  formik={formik}
                />
              </Grid>
              <Grid container style={{ padding: '0 16px' }}>
                <Translate
                  text={t`addOrganizationForm:bankAcc2`}
                  bold
                  variant="h6"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t`addOrganizationForm:bankAcc2`}
                  name={'accountNumber'}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  label={t`addOrganizationForm:plan`}
                  name={'individualFinicalPlan'}
                  formik={formik}
                  type="switch"
                />
              </Grid>
              {get(formik.values, 'individualFinicalPlan') ? (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {t`addOrganizationForm:planData`}
                    </Typography>
                  </Grid>

                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <Select
                        items={getSelectItems(enums.SUBSCRIPT_TYPE)}
                        label={t`addOrganizationForm:sub`}
                        name={'currentSubscriptionType'}
                        formik={formik}
                        disabled={get(
                          formik.values,
                          'currentSubscriptionTypeDisabled'
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'currentSubscriptionTypeDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <TextField
                        label={t`addOrganizationForm:subVal`}
                        type={'number'}
                        name={'currentSubscriptionValue'}
                        onWheel={e => e.target.blur()}
                        formik={formik}
                        disabled={get(
                          formik.values,
                          'currentSubscriptionValueDisabled'
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'currentSubscriptionValueDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.settlementCurrency')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <Select
                        items={getSelectItems(enums.CURRENCY)}
                        label={t('organization:field.settlementCurrency')}
                        name={'settlementCurrency'}
                        formik={formik}
                        disabled={get(
                          formik.values,
                          'settlementCurrencyDisabled'
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'settlementCurrencyDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.publicationValue')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <TextField
                        label={t('organization:field.publicationValue')}
                        type={'number'}
                        onWheel={e => e.target.blur()}
                        name={'publicationValue'}
                        formik={formik}
                        disabled={get(
                          formik.values,
                          'publicationValueDisabled'
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'publicationValueDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.meetingsValue')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <TextField
                        label={t('organization:field.meetingsValue')}
                        type={'number'}
                        name={'meetingsValue'}
                        onWheel={e => e.target.blur()}
                        formik={formik}
                        disabled={get(formik.values, 'meetingsValueDisabled')}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'meetingsValueDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.hiringProc')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <TextField
                        label={t('organization:field.hiringProc')}
                        type={'number'}
                        onWheel={e => e.target.blur()}
                        name={'hiringProc'}
                        formik={formik}
                        disabled={get(formik.values, 'hiringProcDisabled')}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'hiringProcDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.mentorValue')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <TextField
                        label={t('organization:field.mentorValue')}
                        type={'number'}
                        onWheel={e => e.target.blur()}
                        name={'mentorValue'}
                        formik={formik}
                        disabled={get(formik.values, 'mentorValueDisabled')}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'mentorValueDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.warrantyValue')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={9}>
                      <TextField
                        label={t('organization:field.warrantyValue')}
                        type={'number'}
                        onWheel={e => e.target.blur()}
                        name={'warrantyValue'}
                        formik={formik}
                        disabled={get(formik.values, 'warrantyValueDisabled')}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <Checkbox
                        label={t`addOrganizationForm:notApplicable`}
                        name={'warrantyValueDisabled'}
                        formik={formik}
                        type="switch"
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.dateStart')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container>
                    <TextField type="date" name={'dateStart'} formik={formik} />
                  </Grid>

                  <Grid container style={{ padding: '0 16px' }}>
                    <Translate
                      text={t('organization:field.dateStop')}
                      bold
                      variant="h6"
                    />
                  </Grid>
                  <Grid container>
                    <TextField type="date" name={'dateStop'} formik={formik} />
                  </Grid>
                </Grid>
              ) : null}
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t`addOrganizationForm:save`} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
}
export default connect()(withTranslation()(AddOrganizationForm));
