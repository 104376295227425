import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close as CloseIcon } from '@material-ui/icons';
import React from 'react';

export class CustomDialog extends React.Component {
  render() {
    const {
      title,
      body,
      isOpen,
      dialogActions,
      maxWidth = 'sm',
      hideCloseButton,
      onCloseDialog,
      classes,
    } = this.props;

    return (
      <Dialog
        maxWidth={maxWidth}
        fullWidth
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!hideCloseButton && (
          <IconButton className={classes.closeButton} onClick={onCloseDialog}>
            <CloseIcon fontSize={'small'} />
          </IconButton>
        )}
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
      </Dialog>
    );
  }
}
