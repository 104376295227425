import { createReducer } from '@reduxjs/toolkit';
import { getAllCompetenceKinds } from './actions';

//actions
const initialState = {
  list: [],
};

export default createReducer(initialState, builder =>
  builder.addCase(getAllCompetenceKinds, (state, action) => {
    state.list = action.payload;
  })
);
