export const styles = theme => ({
  atsSubtext: {
    display: 'inline-flex',
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 64,
    color: '#808080',
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
    },
  },
});
