import axios from 'axios';
import { API, API_URL } from '../constants';
import { setConfigAction, setConfigListAction } from '../store/auth/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getConfigs = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.CONFIG.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        let configs = {};
        data.forEach(config => {
          configs[config.name] = config.value;
        });
        dispatch(setConfigAction(configs));
        dispatch(setConfigListAction(data));
        return { data, status };
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response;
          return { data: {}, status };
        }
        return { data: {}, status: 500 };
      });
  };
};

export const editConfig = (id, values) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.CONFIG.EDIT.replace(':id', id),
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response;
          return { data: {}, status };
        }
        return { data: {}, status: 500 };
      });
  };
};
