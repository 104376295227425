import { withStyles, DialogContent, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ManIcon from 'shared/assets/img/icons/project-show/man.png';
import TextField from 'shared/newComponents/TextField';
import { styles } from './FloorPlan.styles';

const FloorPlan = ({ classes, t, formik }) => {
  return (
    <DialogContent>
      <Typography
        variant="body1"
        className={classes.floorPlan}
      >{t`employmentManagementDialog:dialog2.floorPlan`}</Typography>
      <Grid container>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField formik={formik} type="number" name="twoPersonRoom" />
            </Grid>
            <Grid item>
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField formik={formik} type="number" name="roomKitchen" />
            </Grid>
            <Grid item>
              <Typography variant="body1">{t`employmentManagementDialog:dialog2.kitchen`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField
                formik={formik}
                type="number"
                name="triplePersonRoom"
              />
            </Grid>
            <Grid item>
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField formik={formik} type="number" name="roomBathroom" />
            </Grid>
            <Grid item>
              <Typography variant="body1">{t`employmentManagementDialog:dialog2.bathroom`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField formik={formik} type="number" name="fourPersonRoom" />
            </Grid>
            <Grid item>
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField formik={formik} type="number" name="roomLivingRoom" />
            </Grid>
            <Grid item>
              <Typography variant="body1">{t`employmentManagementDialog:dialog2.livingRoom`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField
                formik={formik}
                type="number"
                name="fivePersonRoom"
                variant="transparent"
              />
            </Grid>
            <Grid item>
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
              <img alt="manIcon" src={ManIcon} height="30" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} lg={6}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <TextField formik={formik} type="number" name="roomLaundry" />
            </Grid>
            <Grid item>
              <Typography variant="body1">{t`employmentManagementDialog:dialog2.laundry`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default withStyles(styles)(withTranslation()(FloorPlan));
