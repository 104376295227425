import { Grid, withStyles } from '@material-ui/core';
import { FieldArray, FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react';
import { enums } from 'shared/enums';
import { Button, Select, TextField } from 'shared/newComponents';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import { getSelectItemsFullObject } from 'shared/utils/assets';
import {
  birthDayObjectForSelect,
  castObjectForSelect,
} from 'shared/utils/functions';
import { MODEL } from '../CandidateCreateWizardStep2/CandidateCreateWizardStep2.utils';
import { styles } from './ExperienceSelectCard.styles';

const ExperienceSelectCard = ({
  formik,
  industryCategories,
  initialCandidateValues,
  t,
}) => {
  const [experienceSelectCard, setExperienceSelectCard] = useState([]);

  const defaultExperienceCard = {
    position: '',
    industryCategory: '',
    companySize: '',
    experienceLevel: '',
    startWorkYear: '',
    companyName: '',
  };

  useEffect(() => {
    if (
      initialCandidateValues.userExperiences &&
      initialCandidateValues.userExperiences.length !== 0
    ) {
      onAddExperienceExist(initialCandidateValues.userExperiences);
    } else {
      onAddExperience();
    }
  }, [initialCandidateValues]);

  const onAddExperienceExist = userExperience => {
    setExperienceSelectCard(userExperience);
  };

  const onAddExperience = () => {
    setExperienceSelectCard([...experienceSelectCard, defaultExperienceCard]);
    formik.setFieldValue(`${MODEL.userExperience}`, [
      ...formik.values[MODEL.userExperience],
      defaultExperienceCard,
    ]);
  };

  const onRemove = indexToRemove => {
    const filterExperienceTable = experienceSelectCard.filter(
      (item, index) => index !== indexToRemove
    );
    const filterExperienceTableFromFormik = formik.values[
      MODEL.userExperience
    ].filter((item, index) => index !== indexToRemove);

    setExperienceSelectCard(filterExperienceTable);
    formik.setFieldValue(
      `${MODEL.userExperience}`,
      filterExperienceTableFromFormik
    );
  };

  const isLastIndex = index => {
    return experienceSelectCard.length === index + 1;
  };

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name={MODEL.userExperience}
        render={() => {
          return experienceSelectCard.map((item, index) => {
            return (
              <Grid container key={index}>
                <Grid item xs={12} sm={12} md={7}>
                  <TextField
                    label={t('candidateCreator:step1.position')}
                    name={`${MODEL.userExperience}[${index}].${MODEL.position}`}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <TextField
                    label={t('projectCreator:step1.companyInput')}
                    name={`${MODEL.userExperience}[${index}].${MODEL.companyName}`}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <MultiSelect
                    options={castObjectForSelect(industryCategories)}
                    label={t('candidateCreator:step1.industryCategory')}
                    name={`${MODEL.userExperience}[${index}].${MODEL.industryCategory}`}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Select
                    items={getSelectItemsFullObject(enums.COMPANY_SIZE)}
                    label={t('candidateCreator:step1.companySize')}
                    name={`${MODEL.userExperience}[${index}].${MODEL.companySize}`}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    type={'number'}
                    label={t('candidateCreator:step2.experienceLevel')}
                    name={`${MODEL.userExperience}[${index}].${MODEL.experienceLevel}`}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Select
                    items={birthDayObjectForSelect()}
                    label={t('projectCreator:step1.startWorkYear')}
                    name={`${MODEL.userExperience}[${index}].${MODEL.startWorkYear}`}
                    formik={formik}
                  />
                </Grid>
                <Grid
                  container
                  style={{ padding: 10 }}
                  spacing={2}
                  alignItems="center"
                >
                  {isLastIndex(index) ? (
                    <Grid item xs={12} sm={12} md={4}>
                      {index !== 0 ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <Button
                            style={{ borderColor: 'red', marginBottom: 4 }}
                            fontColor={'red'}
                            label={t('candidateCreator:step2.removeExperience')}
                            onClick={() => onRemove(index)}
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={12} md={12}>
                        <Button
                          label={t('candidateCreator:step2.addExperience')}
                          onClick={onAddExperience}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={4}>
                      <Button
                        style={{ borderColor: 'red' }}
                        fontColor={'red'}
                        label={t('candidateCreator:step2.removeExperience')}
                        onClick={() => onRemove(index)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          });
        }}
      />
    </FormikProvider>
  );
};

export default withStyles(styles)(ExperienceSelectCard);
