import { Paper, Grid, withStyles, Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CloudIcon from 'shared/assets/img/icons/candidate-show/cloud.png';
import { enums } from 'shared/enums';
import { getDocumentFromSource } from 'shared/utils/assets';
import { getDocumentInfo } from 'shared/utils/functions';
import { styles } from './DocsInfo.styles';

const DocsInfo = ({
  classes,
  t,
  candidateDetails,
  candidateEmploymentHistories,
}) => {
  const candidateEmploymentHistory = candidateEmploymentHistories.length
    ? candidateEmploymentHistories[0]
    : null;

  const { employmentProcessAssetCandidateSite } = candidateDetails;

  const assetView = employmentProcessAssetCandidateSite.map(asset => {
    return (
      <Grid
        container
        className={classes.doc}
        justifyContent="space-between"
        alignItems="center"
        key={asset.id}
      >
        <Grid item md={7}>
          <Grid container justifyContent="space-between">
            <Grid item md={7}>
              <Typography variant="body1" className={classes.docName}>
                {t(enums.ACCESS_FILE_TYPES_FOR_CANDIDATE_PAGE[asset.type])}
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography
                variant="body1"
                className={classes.bold}
                style={{ wordBreak: 'break-all' }}
              >
                {asset.filename}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={7}>
              <Typography variant="body1">
                {t(
                  enums.ACCESS_FILE_TYPES_FOR_CANDIDATE_PAGE_SUBTITLE[
                    asset.type
                  ]
                )}
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography variant="body1">
                {
                  getDocumentInfo(
                    asset.type,
                    candidateDetails,
                    candidateEmploymentHistory
                  ).title
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {getDocumentInfo(
                asset.type,
                candidateDetails,
                candidateEmploymentHistory
              ).diff !== '' ? (
                <Typography variant="body1" className={classes.left}>
                  {t`candidateDetails:employmentStatus.left`}{' '}
                  {
                    getDocumentInfo(
                      asset.type,
                      candidateDetails,
                      candidateEmploymentHistory
                    ).diff
                  }{' '}
                  {t`candidateDetails:employmentStatus.days`}
                </Typography>
              ) : null}
            </Grid>
            <Grid item>
              <a
                href={getDocumentFromSource(asset.id)}
                download={asset.filename}
              >
                <img
                  src={CloudIcon}
                  alt="cloud"
                  height="30"
                  className={classes.cloud}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });
  return (
    <Paper className={classes.paper}>
      <Typography
        variant="h6"
        className={classes.header}
      >{t`candidateDetails:employmentStatus.docsHeader`}</Typography>
      {assetView}
    </Paper>
  );
};

export default withStyles(styles)(withTranslation()(DocsInfo));
