import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  List,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';
import {
  Add as AddIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getCandidates } from 'shared/services/candidateService';
import DataItemCandidate from './DataItemCandidate';
import 'shared/assets/css/scrollBarRightDrawer.css';
import { sendPredictUsersProjects } from 'shared/services/mlService';
import { getProject } from 'shared/services/projectService';

const styles = theme => ({
  cardSkeleton: {
    width: 'auto',
    margin: 20,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  },
  cardHeader: {
    paddingTop: '0px',
  },
  avatar: {
    width: 70,
    height: 70,
  },
  addIcon: {
    color: theme.palette.jobllegroBlue,
    fontSize: 30,
  },
  avatarEmpty: {
    width: 70,
    height: 70,
    background: '#eee',
    transition: 'all .4s',
    '&:hover': {
      background: '#f2f2f2',
    },
  },
  rootTabs: {
    minHeight: 'auto',
    borderBottom: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    paddingTop: 0,
    paddingBottom: 0,
    '& > div > p': {
      fontSize: 12,
      color: theme.palette.jobllegroLightGrey,
      textAlign: 'center',
    },
  },
  rootTab: {
    fontSize: 12,
    minHeight: '40px',
    minWidth: 'auto',
    // '&$selected span': {
    //     color: theme.palette.jobllegroGrey,
    // },
  },
  labelContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.jobllegroLightGrey,
    textTransform: 'none',
  },
  label: {
    paddingBottom: 0,
    fontSize: 12,
  },
  indicator: {
    background: theme.palette.jobllegroBlue,
    height: 2,
  },
  rootBox: {
    height: 290,
  },
  listWrapper: {
    padding: '0px',
  },
  progress: {
    display: 'inherit',
    margin: '80px auto',
  },
  circle: {
    color: theme.palette.jobllegroLightGrey,
  },
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: 0,
    fontSize: 10,
    color: theme.palette.jobllegroLightGrey,
    '&:hover': {
      background: 'none',
      color: theme.palette.jobllegroBlue,
    },
  },
  extendedIcon: {
    fontSize: 12,
  },
});

function TabContainer(props) {
  return (
    <Grid container spacing={0}>
      <Grid
        className="boxContainer"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ height: 207, overflowY: 'scroll' }}
      >
        {props.children}
      </Grid>
      <Grid
        className="boxContainer"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          height: 43,
          display: 'flex',
          alignItems: 'center',
          placeContent: 'flex-end',
          marginRight: 20,
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            aria-label="more"
            className={props.classes.button}
            onClick={props.showAction}
          >
            {props.isShowMore ? (
              <KeyboardArrowUpIcon className={props.classes.extendedIcon} />
            ) : (
              <KeyboardArrowDownIcon className={props.classes.extendedIcon} />
            )}
            {props.isShowMore
              ? props.t('dashboard:candidatesTable.fold')
              : props.t('dashboard:candidatesTable.expandMore')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export class CandidatesTable extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      candidates: [],
      isLoading: false,
      isShowMore: false,
      myProjects: [
        793, 797, 785, 793, 797, 785, 793, 797, 785, 793, 797, 785, 793, 797,
        785, 793, 797, 785, 793, 797, 785, 793, 797, 785, 793, 797, 785, 793,
        797, 785,
      ],
      mlTaskIds: [],
    };
  }

  getFilterParameters(variable, panel) {
    const { currentUser } = this.props;
    const { isShowMore } = this.state;
    let showMore = null;
    isShowMore ? (showMore = 50) : (showMore = 3);
    if (panel === 0) {
      return {
        owner: currentUser.id,
        withPublic: false,
        limit:
          variable === 'cooperation' && panel === 0 && isShowMore
            ? 50
            : showMore,
      };
    } else if (panel === 1) {
      return {
        ownerToBe: currentUser.id,
        limit:
          variable === 'invitation' && panel === 1 && isShowMore
            ? 50
            : showMore,
      };
    } else if (panel === 2) {
      return {
        withPublic: true,
        limit: variable === 'all' && panel === 2 && isShowMore ? 50 : showMore,
      };
    } else if (panel === 3) {
      return {
        withPublic: true,
        recommend: true,
        limit: variable === 'all' && panel === 3 && isShowMore ? 50 : showMore,
      };
    }
  }

  loadData = async (variable, panel) => {
    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
    }
    const { getCandidates } = this.props;
    const filter = this.getFilterParameters(variable, panel);
    let params = {
      ...filter,
    };
    const { data } = await getCandidates(params);
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        candidates: data,
      });
    }
  };

  getMlTaskId = async project => {
    const predictResponse = await this.props.sendPredictUsersProjects(project);
    return predictResponse.data.taskId;
  };

  loadRecommendData = async (variable, panel) => {
    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
    }
    const myProjects = this.state.myProjects;
    const promiseArray = [];

    myProjects.forEach(project => {
      promiseArray.push(this.getMlTaskId(project));
    });

    const predictIds = await Promise.all(promiseArray);

    const { getCandidates } = this.props;
    const filter = this.getFilterParameters(variable, panel);
    let params = {
      ...filter,
      taskIds: predictIds,
    };
    const { data } = await getCandidates(params);
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        candidates: data,
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    this.loadData(null, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = async (event, value) => {
    await this.setState({ value, isShowMore: false });
    await this.loadData(null, value);
  };

  showAction = (panel, variable) => async () => {
    await this.setState({ isShowMore: !this.state.isShowMore });
    await this.loadData(panel, variable);
  };

  showRecommendAction = (panel, variable) => async () => {
    await this.setState({ isShowMore: !this.state.isShowMore });
    await this.loadRecommendData(panel, variable);
  };

  getCandidatesList = () => {
    const { candidates } = this.state;
    const { classes, t } = this.props;
    return candidates && candidates.length > 0 ? (
      <List className={classes.listWrapper}>
        {candidates.map(candidate => (
          <DataItemCandidate key={candidate.id} data={candidate} />
        ))}
      </List>
    ) : (
      <div style={{ marginTop: 40 }}>
        <Typography align="center" style={{ fontSize: 18, fontWeight: 400 }}>
          {t('dashboard:candidatesTable.noActiveCandidatesFound')}
        </Typography>
        <Card className={classes.cardSkeleton}>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Avatar
                className={classes.avatarEmpty}
                component={Link}
                to={PATHS.CANDIDATE.INDEX}
              >
                <AddIcon className={classes.addIcon} />
              </Avatar>
            }
            title={t('dashboard:candidatesTable.emptyProjectsTableCandidate')}
          />
        </Card>
      </div>
    );
  };

  render() {
    const {
      classes,
      t,
      countMyCandidates,
      countAllCandidates,
      countForAcceptanceCandidates,
      countRecommendCandidates,
    } = this.props;
    const { value, isLoading, isShowMore } = this.state;
    const getCandidatesList = this.getCandidatesList();
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rootBox}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: classes.rootTabs,
              indicator: classes.indicator,
            }}
          >
            <Tab
              classes={{
                root: classes.rootTab,
              }}
              label={`${t(
                'dashboard:candidatesTable.myCandidates'
              )} (${countMyCandidates})`}
            />
            <Tab
              classes={{
                root: classes.rootTab,
              }}
              label={`${t(
                'dashboard:candidatesTable.invitationCandidates'
              )} (${countForAcceptanceCandidates})`}
            />
            <Tab
              classes={{
                root: classes.rootTab,
              }}
              label={`${t(
                'dashboard:candidatesTable.allCandidates'
              )} (${countAllCandidates})`}
            />
            <Tab
              classes={{
                root: classes.rootTab,
              }}
              label={`${t(
                'dashboard:candidatesTable.recommendCandidates'
              )} (${countRecommendCandidates})`}
            />
          </Tabs>
          {isLoading ? (
            <CircularProgress
              classes={{
                root: classes.progress,
                circle: classes.circle,
              }}
            />
          ) : (
            (value === 0 && (
              <TabContainer
                classes={classes}
                t={t}
                isShowMore={isShowMore}
                showAction={this.showAction('cooperation', 0)}
              >
                {getCandidatesList}
              </TabContainer>
            )) ||
            (value === 1 && (
              <TabContainer
                classes={classes}
                t={t}
                isShowMore={isShowMore}
                showAction={this.showAction('invitation', 1)}
              >
                {getCandidatesList}
              </TabContainer>
            )) ||
            (value === 2 && (
              <TabContainer
                classes={classes}
                t={t}
                isShowMore={isShowMore}
                showAction={this.showAction('all', 2)}
              >
                {getCandidatesList}
              </TabContainer>
            )) ||
            (value === 3 && (
              <TabContainer
                classes={classes}
                t={t}
                isShowMore={isShowMore}
                showAction={this.showAction('all', 3)}
              >
                {getCandidatesList}
              </TabContainer>
            ))
          )}
        </Grid>
      </Grid>
    );
  }
}

CandidatesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  getProject,
  sendPredictUsersProjects,
  getCandidates,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(CandidatesTable)));
