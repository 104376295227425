import moment from 'moment';
import { object, string, bool, number, date, array, ref } from 'yup';
import { enums } from 'shared/enums';

export const MODEL = {
  position: 'position',
  city: 'city',
  isRemoteWork: 'isRemoteWork',
  client: 'client',
  country: 'country',
  industryCategory: 'industryCategory',
  department: 'department',
  companySize: 'companySize',
  clientUrl: 'clientUrl',
  isClientPublic: 'isClientPublic',
  visibility: 'visibility',
  isProjectPrivate: 'isProjectPrivate',
  employmentTypes: 'employmentTypes',
  workHourType: 'workHourType',
  expectedWorkTime: 'expectedWorkTime',
  minSalary: 'minSalary',
  maxSalary: 'maxSalary',
  currency: 'currency',
  typeOfPay: 'typeOfPay',
  salaryType: 'salaryType',
  isSalaryPublic: 'isSalaryPublic',
  employmentStartDate: 'employmentStartDate',
  calcCandidateExperience: 'calcCandidateExperience',
};

export const initialValues = {
  position: '',
  city: '',
  isRemoteWork: false,
  client: '',
  country: {},
  industryCategory: {},
  companySize: '',
  clientUrl: '',
  isClientPublic: true,
  isProjectPrivate: false,
  employmentTypes: [],
  typeOfPay: enums.TYPE_OF_PAY_KEY.GROSS,
  workHourType: 0,
  expectedWorkTime: '168',
  minSalary: '',
  maxSalary: '',
  currency: '',
  salaryType: '',
  isSalaryPublic: true,
  employmentStartDate: '',
  calcCandidateExperience: '',
  visibility: 3,
  department: '',
};

// export const initialValues = {
//   position: "Java Developer",
//   city: "Gliwice",
//   isRemoteWork: true,
//   client: "Euvic",
//   country: { id: 2, name: "Albania" },
//   industryCategory: { id: 4, name: "Call Center | Obsługa Klienta" },
//   companySize: 1,
//   clientUrl: "www.google.pl",
//   isClientPublic: true,
//   employmentTypes: [
//     {
//         id: 5,
//         name: "Umowa agencyjna",
//     },
//   ],
//   workHourType: 1,
//   expectedWorkTime: "134 (3/4)",
//   minSalary: 2000,
//   maxSalary: 9000,
//   currency: 1,
//   salaryType: 1,
//   isSalaryPublic: true,
//   employmentStartDate: nextMonthDate.toISOString().split("T")[0],
// };

export const validationSchema = (t, isEdit, initialCandidateValues) => {
  const minDate =
    isEdit &&
    !initialCandidateValues.isDraft &&
    initialCandidateValues.employmentStartDate &&
    moment().isAfter(initialCandidateValues.employmentStartDate, 'day')
      ? moment(initialCandidateValues.employmentStartDate).startOf('day')
      : moment().startOf('day');

  return object().shape({
    position: string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    city: string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    calcCandidateExperience: string().required(
      t('projectCreator:core.requiredField')
    ),
    isRemoteWork: bool(),
    client: string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    country: object().shape({
      id: number().required(t('projectCreator:core.requiredField')),
    }),
    industryCategory: object().shape({
      id: number().required(t('projectCreator:core.requiredField')),
    }),
    department: object().shape({
      id: number().required(t('projectCreator:core.requiredField')),
    }),
    companySize: string().required(t('projectCreator:core.requiredField')),
    clientUrl: string().strict(false).trim(),
    isClientPublic: bool(),
    employmentTypes: array()
      .min(1, t('projectCreator:core.requiredField'))
      .required(t('projectCreator:core.requiredField')),
    workHourType: number().required(t('projectCreator:core.requiredField')),
    typeOfPay: number().required(t('projectCreator:core.requiredField')),

    expectedWorkTime: number()
      .min(0, t('projectCreator:core.requiredFieldMinNumber'))
      .required(t('projectCreator:core.requiredField')),
    minSalary: number()
      .min(0, t('projectCreator:core.requiredFieldMinNumber'))
      .required(t('projectCreator:core.requiredField')),
    maxSalary: number()
      .min(ref('minSalary'), t('projectCreator:core.requiredFieldHigherThan'))
      .required(t('projectCreator:core.requiredField')),
    currency: number().required(t('projectCreator:core.requiredField')),
    salaryType: string().required(t('projectCreator:core.requiredField')),
    isSalaryPublic: bool(),
    employmentStartDate: date()
      .min(
        minDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minDate.format(
          'YYYY-MM-DD'
        )}`
      )
      .required(t('projectCreator:core.requiredField')),
  });
};
