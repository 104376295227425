import {
  // IconButton,
  CardHeader,
  // Typography,
  // Badge,
  withStyles,
} from '@material-ui/core';
import {
  // MoreVert as MoreVertIcon,
  // AddBox as AddBoxIcon,
  // Notifications as NotificationIcon,
  KeyboardArrowRight as ArrowForwardIcon,
} from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
  header: {
    height: 'auto',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    textDecoration: 'none !important',
    borderBottom: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundImage: `linear-gradient(0deg, rgba(235, 235, 239, 0.6) , #fff 20%)`,
  },
  headerWithNoBackground: {
    height: 'auto',
    background: 'transparent',
    borderRadius: '0px',
    textDecoration: 'none !important',
  },
  title: {
    color: theme.palette.jobllegroBlue,
    fontSize: 18,
  },
  titleWithNoBackground: {
    color: theme.palette.jobllegroBlue,
  },
  arrow: {
    color: theme.palette.jobllegroBlue,
    fontSize: 18,
    padding: 0,
    margin: 0,
    width: '15px',
    height: '15px',
  },
  sectionTitle: {
    display: 'flex',
    color: theme.palette.jobllegroBlue,
    fontSize: 18,
    alignItems: 'center',
  },
  addCircle: {
    color: theme.palette.jobllegroBlue,
    fontSize: 18,
    padding: 0,
    marginLeft: '5px',
    width: '15px',
    height: '15px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.jobllegroBlue,
  },
});

export const BoxTopTitle = props => {
  const { classes, icons, variant } = props;

  const TitleBox = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, textDecoration: 'none' }}>
        {props.link ? (
          <Link to={props.link ? props.link : ''} className={classes.link}>
            {props.title}
            <ArrowForwardIcon className={classes.arrow} />
          </Link>
        ) : (
          <span className={classes.link}>{props.title}</span>
        )}
      </div>
      {props.secondTitle && props.secondLink && (
        <div style={{ marginRight: '20px' }}>
          <Link to={props.secondLink} className={classes.link}>
            {props.secondTitle}
            <AddCircleOutlineIcon className={classes.addCircle} />
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <CardHeader
      className={
        variant === 'backgroundNone'
          ? classes.headerWithNoBackground
          : classes.header
      }
      title={TitleBox}
      // component={Link}
      // to={props.link ? props.link : ''}
      classes={{
        title:
          variant === 'backgroundNone'
            ? classes.titleWithNoBackground
            : classes.title,
      }}
      action={
        icons && (
          <div>
            {/*@TO DO*/}
            {/*<IconButton className={classes.iconbutton}>*/}
            {/*<Badge badgeContent={1} color='secondary'>*/}
            {/*<NotificationIcon className={classes.title}/>*/}
            {/*</Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton>*/}
            {/*<AddBoxIcon className={classes.title}/>*/}
            {/*</IconButton>*/}
            {/*<IconButton>*/}
            {/*<MoreVertIcon className={classes.title}/>*/}
            {/*</IconButton>*/}
          </div>
        )
      }
    />
  );
};

BoxTopTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  icons: PropTypes.string,
  title: PropTypes.string,
};

export default withStyles(styles)(BoxTopTitle);
