export const styles = ({ palette, breakpoints }) => ({
  wrapper: {
    padding: '16px 150px',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  tabsWrapper: { borderBottom: '1px solid #b0b4b9' },
  bluePrimaryBackground: { backgroundColor: '#e5f0ff' },

  link: { color: palette.primary.main },
  scroll: { overflow: 'auto' },
  separator: {
    borderColor: 'rgba(213, 214, 216, 0.5)',
    margin: '0px 0',
    width: '100%',
  },
});
