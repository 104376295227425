export const styles = ({ palette, breakpoints }) => ({
  wrapper: {
    padding: '16px 150px',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  tabsWrapper: { borderBottom: '1px solid #b0b4b9' },
  link: { color: palette.primary.main },
  scroll: { overflow: 'auto' },
  avatar: {
    cursor: 'pointer',
    width: 80,
    height: 80,
    borderRadius: '50',
    [breakpoints.only('xs')]: {
      width: 50,
      height: 50,
    },
  },
  paperWrapper: {
    width: '100%',
    borderRadius: 20,
    height: '100%',
  },
  organizationWrapper: {
    alignItems: 'center',
    padding: '30px 30px 30px 30px',
    wordBreak: 'break-word',
  },
  gridPadding: {
    padding: '15px 0px',
  },
  organizationStatusText: {
    fontSize: 26,
  },
  avatarCursor: {},
  publicStatusText: {
    fontSize: 22,
  },
  sectionMarginTop: {
    marginTop: 26,
  },
  sectionBigMarginTop: {
    marginTop: 60,
  },
  organizationFirstText: {
    fontSize: 16,
    color: '#2D333B',
  },
  organizationSecondText: {
    fontSize: 16,
    color: '#2D333B',
    marginTop: 54,
  },
  deleteOrganizationBtn: {
    border: '2px solid red',
  },
});
