import {
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { ProjectOrderService } from 'shared/services';
import {
  checkCandidates,
  uploadCandidatesFromImport,
} from 'shared/services/assetService';
import {
  clearMyCandidates,
  clearCandidatesInProject,
  clearCurrentCandidatesLimitedListCounter,
  clearCurrentCandidatesLimitedList,
  clearCurrentCandidatesList,
  countCandidateWithLimits,
} from 'shared/services/candidateService';
import { getCandidates } from 'shared/services/candidateService';
import { sendPredictUsersProjects } from 'shared/services/mlService';
import {
  getProject,
  addProjectCandidate,
  deleteProjectCandidate,
  getProjectCandidatesFilteredForAddingAsyncList,
} from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';
import ProjectDetailsViewLeftDescription from '../../components/ProjectDetailsViewLeftDescription';
import ProjectNextPrevButtons from '../../components/ProjectNextPrevButtons';
import ProjectOrdersDialog from './ProjectCandidateViewComponents/ProjectOrdersDialog';
import ProjectCandidatesCsvTable from './ProjectCandidatesCsvTable';
import { styles } from './ProjectCandidatesView.styles';
import ProjectCandidates from './ProjectDetails/ProjectCandidates';
import ProjectRightListCandidates from './ProjectDetails/ProjectRightListCandidates';
import ProjectMenu from './projectMenu/ProjectMenu';

export class ProjectCandidatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mlTaskId: null,
      isLoading: true,
      isLoadingRight: false,
      isLoadingCandidates: false,
      openProjectOrdersDialog: false,
      filter: '',
      selectedProjectOrder: null,
      tabPage: 0,
      candidateId: null,
      options: { limit: 12, offset: 0, filters: null, orderBy: null },
      myCandidates: [],
      projectOrders: [],
      tabDisplayStatus: [
        { tab0: false },
        { tab1: false },
        { tab2: false },
        { tab3: false },
        { tab4: false },
      ],
      interval: null,
      previewTable: false,
      checkedCandidates: [],
      validCandidates: [],
      lockSendCandidateBtn: false,
    };
  }

  loadProject = async id => {
    try {
      await this.props.getProject(id);
    } catch (err) {
      console.error(err);
    }
  };

  addProjectCandidateDialog = candidateId => {
    const { projectOrders } = this.state;
    if (projectOrders.length > 1) {
      this.setState({
        openProjectOrdersDialog: true,
        candidateId: candidateId,
      });
    } else {
      this.addProjectCandidate(candidateId);
    }
  };

  projectOrdersAdded = () => {
    this.addProjectCandidate(this.state.candidateId);
  };

  setProjectOrder = projectOrder => {
    this.setState({
      selectedProjectOrder: projectOrder,
    });
  };

  addProjectCandidate = async candidateId => {
    try {
      const { tabPage, selectedProjectOrder } = this.state;

      const { t } = this.props;
      const { id } = this.props.match.params;
      this.setState({
        isLoading: true,
      });
      const fromRecommend = tabPage === 2;
      const addResponse = await this.props.addProjectCandidate(
        id,
        candidateId,
        fromRecommend,
        selectedProjectOrder
      );
      const candidateInProjectResponse = this.props.candidatesInProject;
      await this.loadData(id);

      if (addResponse.status !== 201) {
        this.props.showSnackbarMessage('Brak możliwości przypisania');
        return;
      }
      if (addResponse.data.length === candidateInProjectResponse.data.length) {
        this.props.showSnackbarMessage(
          t('candidate:form.candidateWasSendMessageToAddedToProject')
        );
      } else {
        this.props.showSnackbarMessage(
          t('candidate:form.candidateWasAddedToProject')
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  deleteProjectCandidate = async candidateId => {
    try {
      const { t } = this.props;
      const { id } = this.props.match.params;
      this.setState({
        isLoading: true,
      });
      await this.props.deleteProjectCandidate(id, candidateId);
      this.loadData(id);
      this.props.showSnackbarMessage(
        t('candidate:form.candidateWasDeletedToProject')
      );
    } catch (err) {
      console.error(err);
    }
  };

  loadData = async (id, options = false) => {
    const { limit, offset, filters, orderBy } = options || this.state.options;
    const { currentUser } = this.props;
    const { tabPage } = this.state;
    this.setState({
      isLoading: true,
      selectedProjectOrder: null,
    });

    const params = this.getParamsRequest({
      tabPage,
      filters,
      currentUser,
      orderBy,
    });

    const candidateResponse =
      await this.props.getProjectCandidatesFilteredForAddingAsyncList(
        this.props.match.params.id,
        limit,
        offset,
        params,
        this.state.tabPage
      );
    if (candidateResponse.tabPage === this.state.tabPage) {
      this.setState({
        myCandidates: candidateResponse.myCandidates,
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  getParamsRequest = ({ tabPage, filters, currentUser, orderBy }) => {
    let candidatesParams = {};

    if (tabPage === 0) {
      candidatesParams = {
        candidateFromStash: true,
      };
    }
    if (tabPage === 1) {
      candidatesParams = { owner: currentUser.id, withPublic: false };
    } else if (tabPage === 2) {
      candidatesParams = {
        recommend: true,
        projectId: this.props.match.params.id,
      };
    } else if (tabPage === 4) {
      candidatesParams = { inOrganization: true };
    } else if (tabPage === 3) {
      candidatesParams = { withPublic: true, skipIncompleteUsers: true };
    }

    return {
      type: 'candidate',
      filters,
      orderBy,
      ...candidatesParams,
    };
  };

  handleChangeTab = async (event, tabPage) => {
    await this.setState({
      tabPage,
    });
    const { id } = this.props.match.params;
    this.loadData(id);
  };

  handleReloadElements = (options = false) => {
    const { id } = this.props.match.params;
    if (options) {
      this.setState({
        options,
        isLoading: false,
      });
    }
    if (!this.state.isLoading) {
      return this.loadData(id, options || this.state.options);
    } else {
      return null;
    }
  };

  getCountTabStash = tabPage => {
    const { currentUser } = this.props;

    const params = this.getParamsRequest({
      tabPage,
      filters: {},
      currentUser,
      orderBy: null,
    });
    return countCandidateWithLimits(params);
  };

  loadTabDisplayStatus = () => {
    const { currentUser } = this.props;
    const promiseArray = [];
    promiseArray.push(this.getCountTabStash(0));
    promiseArray.push(this.getCountTabStash(1));
    promiseArray.push(this.getCountTabStash(2));
    promiseArray.push(this.getCountTabStash(3));
    if (currentUser.organization) {
      promiseArray.push(this.getCountTabStash(4));
    }

    Promise.all(promiseArray).then(result => {
      const stashCount = result[0].data.count;
      const selfCandidateCount = result[1].data.count;
      const recommendCount = result[2].data.count;
      const allCandidateCount = result[3].data.count;
      let organizationCount = 0;
      if (currentUser.organization) {
        organizationCount = result[4].data.count;
      }
      const newSelectedTab =
        stashCount !== 0
          ? 0
          : selfCandidateCount !== 0
          ? 1
          : recommendCount !== 0
          ? 2
          : allCandidateCount !== 0
          ? 3
          : organizationCount !== 0
          ? 4
          : 3;

      this.setState({
        tabDisplayStatus: [
          { tab0: stashCount !== 0 },
          { tab1: selfCandidateCount !== 0 },
          { tab2: recommendCount !== 0 },
          { tab3: allCandidateCount !== 0 },
          { tab4: organizationCount !== 0 },
        ],
      });

      return this.handleChangeTab(null, newSelectedTab);
    });
  };

  loadProjectOrders = projectId => {
    ProjectOrderService.getProjectOrderFromProject(projectId)
      .then(({ data }) => {
        this.setState({
          projectOrders: data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.clearCurrentCandidatesList();
    this.loadProject(id);
    this.loadProjectOrders(id);
    this.loadTabDisplayStatus();
  }

  componentWillUnmount() {
    this.props.clearCandidatesInProject();
    this.props.clearMyCandidates();
    this.props.clearCurrentCandidatesList();
    this.props.clearCurrentCandidatesLimitedList();
    this.props.clearCurrentCandidatesLimitedListCounter();
    clearInterval(this.state.interval);
  }

  onCloseProjectOrdersDialog = () => {
    this.setState({
      openProjectOrdersDialog: false,
    });
  };

  handleFileUpload = async files => {
    const checkedCandidates = await checkCandidates(files);
    await this.setState({ checkedCandidates: checkedCandidates });
    await this.setState({
      verifiedCandidates: this.state.checkedCandidates
        .filter(candidate => candidate.isAccept === true)
        .map(
          ({ firstName, lastName, email, phonePrimary, phonePrimaryCode }) => ({
            firstName,
            lastName,
            email,
            phonePrimary,
            phonePrimaryCode,
            projectId: this.props.project.id,
          })
        ),
    });
    await this.setState({ previewTable: true });
  };

  saveCsvTable = async () => {
    try {
      await this.setState({
        lockSendCandidateBtn: true,
      });
      await uploadCandidatesFromImport(this.state.verifiedCandidates);
      await this.setState({
        previewTable: false,
        lockSendCandidateBtn: false,
      });
      this.props.history.push(
        PATHS.PROJECT.ATS.replace(':id', this.props.project.id)
      );
    } catch (error) {
      await this.setState({ checkedCandidates: [], verifiedCandidates: [] });
      await this.setState({
        lockSendCandidateBtn: false,
      });
      console.log(error);
    }
  };

  render() {
    const { classes, project, currentUser, t } = this.props;
    const { isLoading, tabPage, isLoadingRight, isLoadingCandidates } =
      this.state;
    const { permissions = {} } = project;

    const { isMentor, isOwner, isCooperator } = permissions;

    const MultiTab = (
      <Tabs
        onChange={this.handleChangeTab}
        value={tabPage}
        variant="scrollable"
        scrollButtons="on"
        classes={{
          indicator: classes.indicator,
        }}
      >
        <Tab //0
          label={t('project:show.candidateInStash')}
          classes={{
            root: classes.buttonTab,
            selected: classes.selected,
          }}
          style={
            !this.state.tabDisplayStatus[0].tab0 ? { display: 'none' } : null
          }
        />
        <Tab //1
          label={t('project:show.myCandidates')}
          classes={{
            root: classes.buttonTab,
            selected: classes.selected,
          }}
          style={
            !this.state.tabDisplayStatus[1].tab1 ? { display: 'none' } : null
          }
        />
        <Tab //2
          label={t('project:show.recommendCandidates')}
          classes={{
            root: classes.buttonTab,
            selected: classes.selected,
          }}
          style={
            !this.state.tabDisplayStatus[2].tab2 ? { display: 'none' } : null
          }
        />

        <Tab // 3
          label={t('project:show.allCandidates')}
          classes={{
            root: classes.buttonTab,
            selected: classes.selected,
          }}
        />
        {currentUser.organization ? (
          <Tab //4
            label={t('project:show.organizationCandidates')}
            classes={{
              root: classes.buttonTab,
              selected: classes.selected,
            }}
            style={
              !this.state.tabDisplayStatus[4].tab4 ? { display: 'none' } : null
            }
          />
        ) : null}
      </Tabs>
    );

    const isLoadingFetch = isLoading || isLoadingRight || isLoadingCandidates;

    return (
      <React.Fragment>
        <Grid
          container
          alignItems="flex-start"
          spacing={0}
          className={classes.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ProjectMenu title={t('candidate:core.candidates')} />
            {isLoadingFetch ? (
              <Grid
                container
                style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
              >
                <LinearProgress style={{ width: '100%' }} />
              </Grid>
            ) : null}
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ProjectDetailsViewLeftDescription
                    title={t('project:show.candidatesInProject')}
                    project={project}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper
                    className={classes.rightSectionProjectDetails}
                    elevation={0}
                  >
                    {(isMentor || isOwner || isCooperator) &&
                      !project.closedStatus && (
                        <Grid
                          container
                          justifyContent={'space-evenly'}
                          alignContent={'center'}
                        >
                          <ActionButtonJobllegroRounded
                            color="primary"
                            component={Link}
                            to={PATHS.CANDIDATE.CREATE_IN_PROJECT.replace(
                              ':projectId',
                              project.id
                            )}
                            className={classes.buttonRedirect}
                          >
                            {t('project:show.addNewCandidateToProject')}
                          </ActionButtonJobllegroRounded>
                          <ActionButtonJobllegroRounded
                            color="primary"
                            component={Link}
                            to={PATHS.PROJECT.IMPORT_CANDIDATES.replace(
                              ':projectId',
                              project.id
                            )}
                            className={classes.buttonRedirect}
                          >
                            {t('project:show.importCandidatesToProject')}
                          </ActionButtonJobllegroRounded>
                          <ActionButtonJobllegroRounded
                            color="primary"
                            className={classes.buttonRedirect}
                            onClick={() =>
                              this.setState({ previewTable: true })
                            }
                          >
                            {t('project:show.importCandidatesFromFile')}
                          </ActionButtonJobllegroRounded>
                        </Grid>
                      )}
                  </Paper>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ProjectNextPrevButtons />
                  <ProjectCandidates
                    isLoading={isLoading}
                    onShowListHandle={this.loadCandidates}
                    onDeleteHandle={this.deleteProjectCandidate}
                    handleReloadElements={this.handleReloadElements}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.rightList}
                >
                  <ProjectRightListCandidates
                    myCandidates={this.state.myCandidates}
                    storageKey={this.props.match.path}
                    handleReloadElements={this.handleReloadElements}
                    isLoading={isLoading}
                    userType="candidate"
                    onAddCandidateHandle={this.addProjectCandidateDialog}
                    tabsSection={MultiTab}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {this.state.previewTable && (
          <ProjectCandidatesCsvTable
            isOpen={this.state.previewTable}
            onClose={() =>
              this.setState({
                previewTable: false,
                checkedCandidates: [],
                verifiedCandidates: [],
              })
            }
            saveCsvTable={this.saveCsvTable}
            handleFileUpload={this.handleFileUpload}
            checkedCandidates={this.state.checkedCandidates}
            verifiedCandidates={this.state.verifiedCandidates}
            lockSendCandidateBtn={this.state.lockSendCandidateBtn}
          />
        )}
        <ProjectOrdersDialog
          projectOrders={this.state.projectOrders}
          openProjectOrdersDialog={this.state.openProjectOrdersDialog}
          onCloseProjectOrdersDialog={this.onCloseProjectOrdersDialog}
          setProjectOrder={this.setProjectOrder}
          projectOrdersAdded={this.projectOrdersAdded}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    candidatesInProject: store.candidates.candidatesInProject,
    project: store.projects.currentProject,
    lastPage: store.auth.lastPage,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getProject,
  sendPredictUsersProjects,
  getCandidates,
  clearMyCandidates,
  clearCurrentCandidatesLimitedList,
  clearCurrentCandidatesList,
  addProjectCandidate,
  deleteProjectCandidate,
  clearCandidatesInProject,
  clearCurrentCandidatesLimitedListCounter,
  getProjectCandidatesFilteredForAddingAsyncList,
  showSnackbarMessage,
};

ProjectCandidatesView.propTypes = {
  classes: PropTypes.object.isRequired,
  getProject: PropTypes.func.isRequired,
  getCandidates: PropTypes.func.isRequired,
  clearCurrentCandidatesList: PropTypes.func.isRequired,
  clearCurrentCandidatesLimitedList: PropTypes.func.isRequired,
  clearMyCandidates: PropTypes.func.isRequired,
  addProjectCandidate: PropTypes.func.isRequired,
  deleteProjectCandidate: PropTypes.func.isRequired,
  loadTabDisplayStatus: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectCandidatesView)));
