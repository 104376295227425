import { Typography, withStyles, Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import MailIcon from 'shared/assets/img/icons/project-show/mail.png';
import MobileIcon from 'shared/assets/img/icons/project-show/mobile.png';
import PinIcon from 'shared/assets/img/icons/project-show/pin.svg';
import { styles } from './Accommodation.styles';

const AccommodationData = ({
  classes,
  selectedAccommodation,
  onClickEditAccommodation,
  helpModal = false,
}) => {
  if (!selectedAccommodation) {
    return null;
  }
  return (
    <Grid container alignItems="center" className={classes.containerPadding}>
      {helpModal ? (
        <Grid item md={12}>
          <Grid container justifyContent={'flex-end'}>
            <img
              alt="editIcon"
              onClick={onClickEditAccommodation}
              src={EditIcon}
              height={50}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid item md={6}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <img src={PinIcon} alt="city" className={classes.pinIcon} />
          </Grid>
          <Grid item md={6}>
            <Typography variant="body1" className={classes.bold}>
              {`${selectedAccommodation.name} ${selectedAccommodation.address}  ${selectedAccommodation.postCode}  ${selectedAccommodation.city}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={5}>
        <div className={classes.mailDiv}>
          <img src={MailIcon} alt="mail" className={classes.mail} />
          <Typography variant="body1" className={classes.blue}>
            {selectedAccommodation.email}
          </Typography>
        </div>
        <div className={classes.mobileDiv}>
          <img src={MobileIcon} alt="mobile" className={classes.mobile} />
          <Typography variant="body1" className={classes.blue}>
            {`${selectedAccommodation.prefixPhone} ${selectedAccommodation.phone}`}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(AccommodationData));
