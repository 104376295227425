import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import Box from './BoxContainer';
import ExpansionComponent from './LayoutExpansionComponent';
import OrganizationRightListItem from './OrganizationRightListItem';
import ElementsListAsync from './list/ElementsListAsync';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingBottom: 16,
    paddingLeft: '0px !important',
    paddingRight: '64px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: '16px !important',
      paddingLeft: '16px !important',
    },
  }),
});

export const OrganizationRightList = props => {
  const { classes, isLoading, t, myRecruiters } = props;

  const getContent = recruiter => {
    return (
      <OrganizationRightListItem
        key={recruiter.id}
        isLoading={isLoading}
        data={recruiter}
        onAddHandle={props.onAddRecruiterHandle.bind(this, recruiter.id)}
      />
    );
  };

  const getWrapper = ({ content, pagination, filtering }) => (
    <React.Fragment>
      <ExpansionComponent
        title={t('menu:filter')}
        textOnTop={t`organization:findRecruiter`}
      >
        {filtering}
      </ExpansionComponent>
      {content.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('project:show.nameAndSurname')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>{pagination}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <Typography variant="caption">{t`organization:chooseRecruiters`}</Typography>
      )}
    </React.Fragment>
  );

  const sortingOptions = null;
  const defaultSorting = {};
  const filteringOptions = [
    {
      key: 'firstName',
      type: 'text',
      name: t('recruiter:field.firstName'),
    },
    {
      key: 'lastName',
      type: 'text',
      name: t('recruiter:field.lastName'),
    },
    {
      key: 'email',
      type: 'text',
      name: t('recruiter:field.email'),
    },
    {
      key: 'industry',
      type: 'text',
      name: t('recruiter:field.industry'),
    },
    {
      key: 'nationality',
      type: 'text',
      name: t('recruiter:field.nationality'),
    },
    {
      key: 'isInOrganization',
      type: 'boolean',
      name: t('recruiter:list.isInOrganization'),
    },
  ];

  return (
    <Paper className={classes.rootPaper} elevation={0}>
      <Box
        title={t('project:show.list') + ' ' + t('project:show.recruiters')}
        elevation={0}
        variant="backgroundNone"
      >
        <div>
          <ElementsListAsync
            storageKey={props.storageKey}
            rowsPerPage={getTableRowPerPageFromStorage(props.storageKey)}
            handleReloadElements={props.handleReloadElements}
            getContent={getContent}
            getWrapper={getWrapper}
            sortingOptions={sortingOptions}
            defaultSorting={defaultSorting}
            filteringOptions={filteringOptions}
            elements={myRecruiters.data || []}
            elementsCount={myRecruiters.count || 0}
            scrollUp={true}
            load={false}
          />
        </div>
      </Box>
    </Paper>
  );
};

const mapStateToProps = store => {
  return {
    myRecruiters: store.recruiters.myRecruiters,
    recruitersCount: store.recruiters.currentRecruitersLimitedListCounter,
  };
};

OrganizationRightList.propTypes = {
  classes: PropTypes.object.isRequired,
  myRecruiters: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(OrganizationRightList)));
