export const styles = () => ({
  bgBlue: { background: '#E5F0FF' },
  contractData: { marginBottom: 20, fontWeight: 'bold' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  accommodationType: { marginTop: 15 },
});
