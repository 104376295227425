export const styles = () => ({
  container: { padding: '10px 0', width: '100%' },
  locationWrapper: {
    display: 'flex',
    width: '100%',
    gap: '30px',
    paddingBottom: '20px',
  },
  location: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& h6': {
      marginLeft: '10px',
      fontWeight: 600,
      fontSize: '1.25rem',
    },
  },
  numberField: {
    width: '100px',
  },
  transportDetail: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    paddingLeft: '10px',
    '& h6': {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
  },
  transportLabel: {
    minWidth: '100px',
  },
  datesSection: {
    padding: '15px 5px',
    '& p': {
      marginLeft: '10px',
    },
  },
  datesWrapper: {
    display: 'flex',
    gap: '10px',
    maxWidth: '250px',
  },
});
