import { Grid, Button, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import React from 'react';
import * as yup from 'yup';
import { enums } from 'shared/enums';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import TextField from 'shared/newComponents/TextField';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import { getLocalizationData } from 'shared/utils/functions';
import { styles } from './EmploymentManagementTableFilter.styles';

const EmploymentManagementTableFilter = ({
  classes,
  setFilters,
  t,
  projectCandidatesEmploymentManagement,
}) => {
  const projectOrders = _.uniqBy(
    projectCandidatesEmploymentManagement
      .filter(item => item.projectCandidate.projectOrder !== null)
      .map(item => item.projectCandidate.projectOrder),
    'id'
  );
  const localizations = _.uniqBy(
    projectCandidatesEmploymentManagement
      .filter(item => item.localization !== null)
      .map(item => item.localization),
    'id'
  );
  const accommodations = _.uniqBy(
    projectCandidatesEmploymentManagement
      .filter(item => item.accommodation !== null)
      .map(item => item.accommodation),
    'id'
  );

  const employmentsStatus = getSelectItemsTranslate(enums.EMPLOYMENT_STATUS, t);

  const projectOrdersArray = projectOrders.map(projectOrder => {
    return {
      id: projectOrder.id,
      value: projectOrder.id,
      label: `#${projectOrder.id} ${projectOrder.createdAt} ${projectOrder.owner.firstName} ${projectOrder.owner.lastName}`,
    };
  });
  const localizationArray = localizations.map(localization => {
    return {
      id: localization.id,
      value: localization.id,
      label: getLocalizationData(localization),
    };
  });
  const accommodationArray = accommodations.map(accommodation => {
    return {
      id: accommodation.id,
      value: accommodation.id,
      label: getLocalizationData(accommodation),
    };
  });

  const employmentStatusArray = employmentsStatus.map(employmentStatus => {
    return {
      id: employmentStatus.id,
      value: employmentStatus.name,
      label: employmentStatus.name,
    };
  });

  const formData = {
    candidateLastName: '',
    tableDateStart: '',
    tableDateEnd: '',
    employmentStatusNumbers: [],
    projectOrderNumbers: [],
    localizationNumbers: [],
    accommodationNumbers: [],
  };

  const validationSchema = yup.object({
    candidateLastName: yup.string(),
    tableStartDate: yup.string(),
    tableEndDate: yup.string(),
    employmentStatusNumbers: yup.array(),
    projectOrderNumbers: yup.array(),
    localizationNumbers: yup.array(),
    accommodationNumbers: yup.array(),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({
      candidateLastName,
      tableStartDate,
      tableEndDate,
      employmentStatusNumbers,
      projectOrderNumbers,
      localizationNumbers,
      accommodationNumbers,
    }) => {
      const body = {
        candidateLastName: candidateLastName !== '' ? candidateLastName : null,
        tableStartDate: tableStartDate !== '' ? tableStartDate : null,
        tableEndDate: tableEndDate !== '' ? tableEndDate : null,
        employmentStatusNumbers: employmentStatusNumbers,
        localizationNumbers: localizationNumbers,
        accommodationNumbers: accommodationNumbers,
        projectOrderNumbers: projectOrderNumbers,
      };
      setFilters(body);
    },
  });

  return (
    <Grid container justifyContent="space-around">
      <Grid item xs={12} sm={12} md={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <Grid container>
                <MultiSelect
                  isMultiOption={true}
                  options={projectOrdersArray}
                  label={t`filterAts:orderId`}
                  name={'projectOrderNumbers'}
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Grid container>
                <MultiSelect
                  isMultiOption={true}
                  options={employmentStatusArray}
                  label={t`candidateDetails:menu.employmentStatus`}
                  name={'employmentStatusNumbers'}
                  formik={formik}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Grid container>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t`employmentStatusTable:dateStart`}
                  name="tableStartDate"
                  type="date"
                  formik={formik}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Grid container>
                <MultiSelect
                  isMultiOption={true}
                  options={localizationArray}
                  label={t`employmentStatusTable:workplace`}
                  name={'localizationNumbers'}
                  formik={formik}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Grid container>
                <TextField
                  label={t`projectOrderViewContent:employeeSurname`}
                  name="candidateLastName"
                  formik={formik}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Grid container>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t`employmentStatusTable:finalDate`}
                  name="tableEndDate"
                  type="date"
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'}>
              <Grid item xs={12} sm={12} md={5}>
                <Grid container>
                  <MultiSelect
                    isMultiOption={true}
                    options={accommodationArray}
                    label={t`employmentStatusTable:accommodation`}
                    name={'accommodationNumbers'}
                    formik={formik}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <Grid container>
                  <Button
                    className={classes.filterSubmitBtn}
                    type="submit"
                    onClick={formik.handleSubmit}
                    fullWidth={true}
                  >{t`projectOrderViewContent:showResultsBtn`}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(EmploymentManagementTableFilter);
