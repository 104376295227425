import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ProgressBar, Translate } from 'shared/newComponents';
import { styles } from './DepartmentsExperienceItem.styles';

const DepartmentsExperienceItem = ({
  title,
  yearOfExperiences,
  yearOfExperiencesText,
  classes,
  t,
}) => {
  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={10} sm={10} md={10} className={classes.progressBarWrapper}>
        <ProgressBar
          percentage={
            yearOfExperiences * 10 > 100 ? 100 : yearOfExperiences * 10
          }
          title={title}
          secondTitle={''}
          customStyleSecondHeader={{
            color: '#6CB8FF',
          }}
        />
      </Grid>
      <Grid item xs={2} sm={2} md={2}>
        <Grid container justifyContent={'center'}>
          <Translate
            text={yearOfExperiencesText}
            className={classes.fontStyleSmall}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(DepartmentsExperienceItem))
);
