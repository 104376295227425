import { Grid, withStyles } from '@material-ui/core';
import { useState } from 'react';
import { ProjectCreateWizard, ProjectReview } from '..';
import { allCompetenceIsSet } from '../ProjectCreateWizardStep2/ProjectCreateWizardStep2.utils';
import { styles } from './ProjectCreateForm.style';
import {
  getHeaderAsset,
  getLogoAsset,
  getSliderAsset,
} from './ProjectCreatorFormUtils';

const ProjectCreateForm = ({ classes, project, shouldUpdateProject }) => {
  const [step1FormValues, setStep1FormValues] = useState({});
  const [step2FormValues, setStep2FormValues] = useState({});
  const [step3FormValues, setStep3FormValues] = useState({});
  const [step4FormValues, setStep4FormValues] = useState({});
  const [step5FormValues, setStep5FormValues] = useState({});
  const [step6FormValues, setStep6FormValues] = useState({});
  const [step7FormValues, setStep7FormValues] = useState({});
  const [step8FormValues, setStep8FormValues] = useState({});
  const [currentStage, setCurrentStage] = useState(
    project.id ? project.currentStage : 0
  );
  const [step5Assets, step5AetAssets] = useState([
    getLogoAsset(project.assets),
    getHeaderAsset(project.assets),
    getSliderAsset(project.assets),
  ]);

  //To Clear uncolpete old competences
  if (project && project.projectSkills) {
    project.projectSkills = project.projectSkills.filter((item, index) => {
      if (allCompetenceIsSet(project, index)) {
        return item;
      }
    });
  }

  return (
    <Grid container alignItems="flex-start" className={classes.root}>
      <Grid item xs={12} sm={12} md={12} xl={7} lg={7}>
        <ProjectCreateWizard
          shouldUpdateProject={shouldUpdateProject}
          setCurrentStage={setCurrentStage}
          currentStage={currentStage}
          step5AetAssets={step5AetAssets}
          step5Assets={step5Assets}
          setStep1FormValues={setStep1FormValues}
          setStep2FormValues={setStep2FormValues}
          setStep3FormValues={setStep3FormValues}
          setStep4FormValues={setStep4FormValues}
          setStep5FormValues={setStep5FormValues}
          setStep6FormValues={setStep6FormValues}
          setStep7FormValues={setStep7FormValues}
          setStep8FormValues={setStep8FormValues}
          initialProjectValues={project}
          step1FormValues={step1FormValues}
          step2FormValues={step2FormValues}
          step3FormValues={step3FormValues}
          step4FormValues={step4FormValues}
          step5FormValues={step5FormValues}
          step6FormValues={step6FormValues}
          step7FormValues={step7FormValues}
          step8FormValues={step8FormValues}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
        <ProjectReview
          assetPreview={step5Assets}
          step1FormValues={step1FormValues}
          step2FormValues={step2FormValues}
          step3FormValues={step3FormValues}
          step4FormValues={step4FormValues}
          step5FormValues={step5FormValues}
          step6FormValues={step6FormValues}
          step7FormValues={step7FormValues}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ProjectCreateForm);
