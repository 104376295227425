import { Avatar, ListItem, ListItemText, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getProjectAssetSrcByType } from 'shared/utils/assets';

const styles = () => ({
  avatar: {
    width: 30,
    height: 30,
  },
  icon: {
    color: '#afafaf',
  },
  listItem: {
    paddingLeft: '12px',
    position: 'relative',
    overflow: 'hidden',
  },
  listItemText: {
    maxWidth: 150,
  },
  iconButtonActtion: {
    width: 40,
    height: 40,
    padding: 0,
  },
  thumbUp: {
    color: '#00D298',
    width: 20,
  },
  thumbDown: {
    color: '#E86958',
    width: 20,
  },
  primaryListItem: {
    color: '#000',
    lineHeight: '1rem',
  },
});

export const NotificationsInformationListItem = props => {
  const { classes, item, title = null, currentUserId } = props;
  const avatar = getProjectAssetSrcByType(item, 'logo');

  return item ? (
    <ListItem
      button
      key={item.id}
      className={classes.listItem}
      component={Link}
      to={PATHS.RECRUITER.ORGANIZATIONS.replace(':id', currentUserId)}
    >
      <Avatar src={avatar} className={classes.avatar}></Avatar>
      <ListItemText
        primary={item.name}
        secondary={title ? title : null}
        classes={{
          primary: classes.primaryListItem,
        }}
      />
    </ListItem>
  ) : (
    <ListItem
      key={0}
      button
      className={classes.listItem}
      component={Link}
      to={PATHS.ORGANIZATION.INDEX}
    >
      <ListItemText
        primary={title}
        classes={{
          primary: classes.primaryListItem,
        }}
      />
    </ListItem>
  );
};

NotificationsInformationListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationsInformationListItem);
