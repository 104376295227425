import { API } from '../constants';
import { ApiService } from './api.service';

export const WorkStylePointsService = {
  setWorkStylePoints(data) {
    return ApiService.post(API.POST.PROJECT.WORK_STYLE_POINTS.POST, data);
  },
};

export default WorkStylePointsService;
