import { Location } from 'history';
import { matchPath } from 'react-router-dom';
import { PATHS } from 'config';
import { routes } from 'shared/routes';
import { CurrentUser, Routes } from 'shared/types';
import { clearLocalStorageByKey } from 'shared/utils/localStorage';
import { getUserRole } from 'shared/utils/userRole';

export const getAllowRoutes = (currentUser: CurrentUser) => {
  const userRole = getUserRole(currentUser);
  return routes.filter(({ permission }) => {
    if (!permission) {
      return true;
    }
    return permission.includes(userRole);
  });
};

export const getCurrentRoute = (location: Location) => {
  return routes.find(route => matchPath(location.pathname, route));
};

export const manageProjectDataInLocalStorage = (location: Location) => {
  const currentRoute = getCurrentRoute(location);

  if (!currentRoute) return null;

  const isCreateInProjectPath = matchPath(
    PATHS.CANDIDATE.CREATE_IN_PROJECT,
    currentRoute
  );

  if (
    !matchPath(PATHS.CANDIDATE.NEW_EDIT, currentRoute) &&
    !isCreateInProjectPath
  ) {
    clearLocalStorageByKey('projectId');
  }
  if (!isCreateInProjectPath) {
    clearLocalStorageByKey('projectIdEmploymentProcess');
  }
};

export const getRoutesFromObject = (object: Routes) =>
  Object.keys(object).map(key => object[key]);
