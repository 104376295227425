import { CircularProgress, Paper, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Translate } from 'shared/newComponents';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import RecruiterCardList from '../modules/recruiter/RecruiterList/RecruiterCardList';
import OrganizationRecruiterAcceptRejectButton from './OrganizationRecruiterAcceptRejectButton';
import ElementsListAsync from './list/ElementsListAsync';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingBottom: 16,
    marginLeft: 0,
    paddingLeft: '64px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '16px !important',
    },
  }),
  progressCircular: {
    marginRight: 26,
    marginTop: 14,
  },
});

export const OrganizationRecruiters = props => {
  const {
    classes,
    t,
    isLoading,
    recruitersInOrganization,
    handleReloadElements,
    organization: { owner = {} },
    currentUser,
  } = props;

  const getWrapperOrganizationRecruiters = options => {
    let { content } = options;
    return <Grid container>{content}</Grid>;
  };

  const getContentOrganizationRecruiters = recruiter => {
    const isOwner = owner.id === currentUser.id;

    let statusConfirmed = null;
    if (
      recruiter.organizationConfirmation === 1 ||
      recruiter.organizationConfirmation === 4
    ) {
      statusConfirmed = t`organization:awaiting`;
    } else if (
      recruiter.organizationConfirmation === 2 ||
      recruiter.organizationConfirmation === 5
    ) {
      statusConfirmed = t`organization:approved`;
    } else if (
      recruiter.organizationConfirmation === 3 ||
      recruiter.organizationConfirmation === 6
    ) {
      statusConfirmed = t`organization:rejected`;
    }

    return (
      <React.Fragment key={recruiter.id}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={isOwner ? 6 : 3}
          xl={isOwner ? 6 : 3}
          key={recruiter.id}
        >
          <RecruiterCardList
            recruiter={recruiter}
            action={
              isOwner && (
                <div>
                  {isLoading ? (
                    <CircularProgress
                      className={classes.progressCircular}
                      size={20}
                    />
                  ) : (
                    <Grid container justifyContent={'center'}>
                      <Grid item>
                        {isOwner ? null : (
                          <Translate
                            onClick={props.onDeleteHandle.bind(
                              this,
                              recruiter.id
                            )}
                            text={t`organization:removeMember`}
                            style={{
                              cursor: 'pointer',
                              fontSize: 13,
                              color: 'red',
                              marginTop: 10,
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </div>
              )
            }
            cardContent={
              <React.Fragment>
                <div>{statusConfirmed}</div>
                {recruiter.organizationConfirmation === 9 && (
                  <div>{t`organization:orgOwner`}</div>
                )}
              </React.Fragment>
            }
            cardActions={
              <OrganizationRecruiterAcceptRejectButton
                confirmationStatus={recruiter.organizationConfirmation}
                organization={recruiter.organization}
                recruiter={recruiter}
              />
            }
          />
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Paper elevation={0}>
      <ElementsListAsync
        elements={recruitersInOrganization.data || []}
        elementsCount={recruitersInOrganization.count || 0}
        getContent={getContentOrganizationRecruiters}
        getWrapper={getWrapperOrganizationRecruiters}
        handleReloadElements={handleReloadElements}
        load={false}
      />
    </Paper>
  );
};

const mapDispatchToProps = {
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    organization: store.organizations.currentOrganization,
    recruitersInOrganization: store.recruiters.recruitersInOrganization,
  };
};

OrganizationRecruiters.propTypes = {
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  onDeleteHandle: PropTypes.func.isRequired,
  dataList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(OrganizationRecruiters)));
