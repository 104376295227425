import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { styles } from './RecruiterAnonymousDetailsContentCenter.styles';
import DepartmentsExperiences from './comonents/DepartmentsExperiences';
import IndustriesExperiences from './comonents/IndustriesExperiences';
import OtherExperiences from './comonents/OtherExperiences';
import PositionsLevel from './comonents/PositionsLevel';

const RecruiterAnonymousDetailsContentCenter = ({
  history,
  recruiterData,
  recruiterEdited,
  departments,
  industryCategories,
  languageCompetence,
  countries,
  isSelfEdit,
  classes,
  t,
  ...props
}) => {
  if (
    !isSelfEdit &&
    !recruiterData.userDepartmentExperiences.length &&
    !recruiterData.userIndustryCategoryExperiences.length &&
    !recruiterData.userPositionExperiences.length &&
    !recruiterData.userRecruitmentAreas.length &&
    !recruiterData.userRecruitmentCountries.length &&
    !recruiterData.userLanguageSkills.length &&
    !recruiterData.aboutMe
  ) {
    return null;
  }
  return (
    <Grid container>
      <Grid container alignContent={'center'}>
        <Grid item xs={12} sm={12} md={12}>
          <Translate
            text={`recruiter:recruitmentExp`}
            bold
            clas
            className={classes.fontStylesBig}
          />
        </Grid>
        <DepartmentsExperiences
          isSelfEdit={isSelfEdit}
          recruiterData={recruiterData}
          recruiterEdited={recruiterEdited}
          departments={departments}
        />
        <IndustriesExperiences
          isSelfEdit={isSelfEdit}
          recruiterData={recruiterData}
          recruiterEdited={recruiterEdited}
          industryCategories={industryCategories}
        />
        <PositionsLevel
          isSelfEdit={isSelfEdit}
          recruiterData={recruiterData}
          recruiterEdited={recruiterEdited}
        />
        <OtherExperiences
          isSelfEdit={isSelfEdit}
          recruiterData={recruiterData}
          recruiterEdited={recruiterEdited}
          languageCompetence={languageCompetence}
          countries={countries}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(RecruiterAnonymousDetailsContentCenter))
);
