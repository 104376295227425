export const styles = () => ({
  radarChart: {
    maxWidth: '100%',
    '& .recharts-wrapper': {
      maxWidth: '100%',
      '& .recharts-surface': {
        maxWidth: '100%',
      },
    },
  },
});
