export const styles = () => ({
  paper: { padding: 20 },
  workwear: { borderBottom: '1px solid #D5D6D8', paddingBottom: 10 },
  workwearBtn: {
    width: 70,
    borderRadius: 6,
    color: '#868889',
    border: '2px solid #868889',
    margin: '0 10px',
    whiteSpace: 'noWrap',
  },
  bold: { fontWeight: 'bold' },
  insurance: { paddingTop: 20 },
});
