import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getCurrentUser } from 'shared/services/authService';
import {
  editRecruiter,
  editRecruiterOrganization,
} from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';

export class OrganizationAssignRecruiterButton extends Component {
  acceptRequest = async () => {
    const { t } = this.props;
    try {
      const { organization, currentUser } = this.props;
      const { status } = await this.props.editRecruiterOrganization({
        id: currentUser.id,
        organizationId: organization.id,
      });
      if (status === 200) {
        this.props.getCurrentUser();
        this.props.showSnackbarMessage(t`organization:msg`);
      } else {
        this.props.showSnackbarMessage(t`organization:error`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  cancelRequest = async () => {
    // try {
    //     const {organization, currentUser} = this.props;
    //     const {status} = await this.props.editRecruiter({
    //         id: currentUser.id,
    //         organizationId: null,
    //     });
    //     if (status === 200) {
    //         this.props.getCurrentUser();
    //         this.props.showSnackbarMessage('Wysłano prośbę o dołączenie do organizacji.');
    //     } else {
    //         this.props.showSnackbarMessage('Wystąpił błąd.');
    //     }
    // } catch (err) {
    //     console.error(err);
    // }
  };

  render() {
    const {
      t,
      currentUser = {},
      currentUser: { organizationConfirmation },
      organization = {},
    } = this.props;

    const showStatusButton =
      organization.owner &&
      currentUser.id !== organization.owner.id &&
      !currentUser.organization;

    const showWaitingButton =
      currentUser.organization && organizationConfirmation === 4;

    return (
      <React.Fragment>
        {showStatusButton ? (
          <React.Fragment>
            <Button
              color="primary"
              variant="contained"
              onClick={this.acceptRequest}
            >
              {t('organization:form.addToOrganization')}
            </Button>
          </React.Fragment>
        ) : null}
        {showWaitingButton ? (
          <React.Fragment>
            <Button
              color="primary"
              variant="contained"
              onClick={this.cancelRequest}
              disabled={true}
            >
              {t('organization:form.waitingForAcceptance')}
            </Button>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  editRecruiter,
  editRecruiterOrganization,
  getCurrentUser,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    currentOrganization: store.organizations.currentOrganization,
  };
};

OrganizationAssignRecruiterButton.propTypes = {
  organization: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  editRecruiter: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OrganizationAssignRecruiterButton));
