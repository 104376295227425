import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import AssignRecruiterToProjectButton from 'shared/newComponents/AssingRecruiterToProjectButton/AssignRecruiterToProjectButton';
import { styles } from './RecruiterContent.style';

const RecruiterContent = ({ projectDetails }) => {
  return (
    <>
      <Grid container style={{ padding: 16 }}>
        <Grid
          container
          style={{
            justifyContent: 'center',
          }}
        >
          <AssignRecruiterToProjectButton projectDetails={projectDetails} />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(RecruiterContent);
