import { Typography, Grid, withStyles, Paper } from '@material-ui/core';
import { find, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PinIcon from 'shared/assets/img/icons/project-show/pin.svg';
import Translate from 'shared/newComponents/Translate';
import { getAssetUrl } from 'shared/services';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import { styles } from './CandidateDetailsViewLeftDescription.styles';

const CandidateDetailsViewLeftDescription = ({
  t,
  classes,
  candidateEmploymentHistories,
}) => {
  if (!candidateEmploymentHistories.length) {
    return null;
  }
  const project =
    candidateEmploymentHistories[candidateEmploymentHistories.length - 1]
      .projectCandidate.project;
  const { client, clientUrl, city, position, isRemoteWork } = project;
  const logoId = get(
    find(get(project, 'assets'), o => o.type === 'logo'),
    'id'
  );
  const projectLogo = getAssetUrl(logoId);
  const projectBanner = getProjectAssetSrcByType(project, 'header');
  return (
    <Grid container className={classes.wrapper}>
      <Grid container spacing={0} component={Paper}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ padding: '20px 20px' }}
        >
          <Grid container className={classes.imagesWrapper}>
            <img
              src={projectBanner}
              alt="imageHeader"
              className={classes.header}
            />
            <Grid container alignItems="center" className={classes.logoWrapper}>
              {projectLogo ? (
                <img
                  src={projectLogo}
                  alt="imageLogo"
                  className={classes.logo}
                  style={{ maxWidth: '15%' }}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.rightSectionProject}>
            <Typography
              variant="h5"
              component="h1"
              className={classes.projectPosition}
            >
              {position}
            </Typography>
            <Typography
              variant="h5"
              component="h4"
              className={classes.projectClient}
            >
              {client}
            </Typography>
            <Typography component="div" className={classes.projectWww}>
              <a href={clientUrl}>{clientUrl}</a>
            </Typography>
            <Typography component="div" className={classes.projectInfo}>
              <Grid container alignItems="center" justifyContent="flex-start">
                {city && (
                  <Grid item style={{ padding: '4px 0' }}>
                    <Grid container>
                      <img
                        src={PinIcon}
                        alt="city"
                        style={{ paddingRight: 8 }}
                      />
                      <Translate
                        text={city}
                        variant="body1"
                        disableTranslate={true}
                      />
                    </Grid>
                    {isRemoteWork && (
                      <>
                        <Translate text="&nbsp;" variant="body1" />
                        <Translate
                          text="projectDetails:mainContent.isRemoteWork"
                          variant="body1"
                        />
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Typography>
            {/*<Button*/}
            {/*  onClick={() => {}}*/}
            {/*  className={classes.changeEmploymentLocBtn}*/}
            {/*>{t`candidateDetails:employmentStatus.changeEmploymentLocBtn`}</Button>*/}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(CandidateDetailsViewLeftDescription)));
