import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  rootHeaderPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    background: 'transparent',
  },
  backPage: {
    color: theme.palette.jobllegroBlue,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.jobllegroSuccess,
    },
  },
  title: {
    fontSize: 16,
  },
});

export const SubHeader = props => {
  const { classes, title } = props;

  return (
    <React.Fragment>
      <Paper className={classes.rootHeaderPaper} elevation={0}>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h5" component="h4" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubHeader);
