import { Grid, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { styles } from './CustomSlider.styles';

const CSlider = ({
  handleClickLeft,
  handleClickRight,
  classes,
  children,
  showIndicators = false,
}) => {
  return (
    <Grid
      className={
        showIndicators ? classes.container : classes.containerWithoutArrow
      }
    >
      {showIndicators ? (
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={handleClickLeft}
        >
          <KeyboardArrowLeft size="normal" classes={{ root: classes.svg }} />
        </IconButton>
      ) : null}

      <Grid className={classes.children}>{children}</Grid>
      {showIndicators ? (
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={handleClickRight}
        >
          <KeyboardArrowRight classes={{ root: classes.svg }} />
        </IconButton>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(CSlider);
