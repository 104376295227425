import { Grid, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import AssignCandidateToRecruiterButton from 'shared/newComponents/AssignCandidateToRecruiterButton/AssignCandidateToRecruiterButton';
import Button from 'shared/newComponents/Button';
import { getUserAvatarSrc } from 'shared/utils/assets';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateVerifyBy = ({
  candidate,
  classes,
  variant,
  enableToEdit = false,
  t,
  ...props
}) => {
  const [openSetRecruiter, setOpenSetRecruiter] = useState(false);

  const ownerKey = variant === 'owner' ? 'owner' : 'ownerToBe';

  const candidateHasOwner = !!(candidate[ownerKey] && candidate[ownerKey].id);

  const ownerIsPublic =
    candidateHasOwner &&
    (candidate[ownerKey].recruiterVisibility === 0 ||
      candidate[ownerKey].id === props.currentUser.id);

  const currentAvatarOwner = candidateHasOwner
    ? getUserAvatarSrc(ownerIsPublic ? candidate[ownerKey] : null)
    : null;

  if (!candidate[ownerKey]) {
    return null;
  }

  const handleOpenSetCandidateRecruiter = () => {
    setOpenSetRecruiter(true);
  };

  const onClose = () => {
    setOpenSetRecruiter(false);
  };

  return (
    <Grid
      container
      style={{
        padding: '30px 30px 30px 30px',
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={
                  variant === 'owner'
                    ? 'candidateCreator:reviewStep2.candidateVerifyBy'
                    : 'candidate:awaitingVerification'
                }
                bold
                style={{
                  fontSize: 20,
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} style={{ marginTop: 16 }}>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <img
                alt="currentAvatarOwner"
                src={currentAvatarOwner}
                height={60}
                style={{ borderRadius: 30 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              {' '}
              <Translate
                style={{
                  fontSize: 16,
                }}
                disableTranslate={true}
                text={
                  ownerIsPublic
                    ? `${candidate[ownerKey].firstName} ${candidate[ownerKey].lastName}`
                    : `${t`dashboard:rightDrawer.recruiter`} #${
                        candidate[ownerKey].id
                      }`
                }
              />
            </Grid>
            {enableToEdit && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.unassignedCandidate}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  className={classes.unassignedCandidate}
                >
                  <Button
                    label={t('action.change')}
                    onClick={handleOpenSetCandidateRecruiter}
                  />
                  <AssignCandidateToRecruiterButton
                    candidate={candidate}
                    isOpen={openSetRecruiter}
                    onClose={onClose}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateVerifyBy))));
