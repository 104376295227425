import { PATHS } from 'config';
import { ModuleRoute, Roles } from 'shared/types';
import ActivityIndexView from '../../modules/activity/ActivityIndexView';
import AdminCitySeoViewView from '../../modules/admin/AdminCitySeoView';
import AdminCompetenceCategoryView from '../../modules/admin/AdminCompetenceCategoryView';
import AdminCompetenceKindView from '../../modules/admin/AdminCompetenceKindView';
import AdminCompetenceTempView from '../../modules/admin/AdminCompetenceTempView';
import AdminCompetenceTypeView from '../../modules/admin/AdminCompetenceTypeView';
import AdminConfigCmsView from '../../modules/admin/AdminConfigCmsView/AdminConfigCmsView.styles';
import AdminConfigView from '../../modules/admin/AdminConfigView';
import AdminHelpdeskView from '../../modules/admin/AdminHelpdeskView';
import AdminIndustryCategoryView from '../../modules/admin/AdminIndustryCategoryView';
import AdminLogView from '../../modules/admin/AdminLogView';
import AdminOrganizationView from '../../modules/admin/AdminOrganizationView/AdminOrganizationView.styles';
import AdminSkillToApprovedView from '../../modules/admin/AdminSkillToApprovedView';
import AdminSkillView from '../../modules/admin/AdminSkillView';
import AdminSuperUserView from '../../modules/admin/AdminSuperUserView';
import AdminUserView from '../../modules/admin/AdminUserView';
import {
  NewCandidateAllProjectsDetailsView,
  NewCandidateAnonymousDetailsView,
  NewCandidateCreateFromView,
  NewCandidateEditFromView,
  NewCandidatePersonalDetailsView,
  NewCandidateEmploymentStatusView,
} from '../../modules/candidate';
import CandidateIndexView from '../../modules/candidate/CandidateIndexView';
import CandidateSummaryIndexView from '../../modules/candidateSummary/CandidateSummaryIndexView';
import DebugIndexView from '../../modules/debug/DebugIndexView';
import ExportView from '../../modules/export/ExportView';
import MLStatusView from '../../modules/mlStatus/MLStatusView';
import OrganizationIndexView from '../../modules/organization/OrganizationIndexView';
import OrganizationRecruitersView from '../../modules/organization/OrganizationRecruitersView';
import OrganizationShowView from '../../modules/organization/OrganizationShowView';
import {
  NewProjectCreateFromView,
  NewProjectCreateView,
  NewProjectEditView,
  NewProjectShowView,
  ProjectAtsEmploymentProcess,
  ProjectAtsView,
  ProjectCandidatesView,
  ProjectImportCandidatesView,
  ProjectIndexView,
  ProjectPaymentsView,
  ProjectRecruitersView,
  ProjectOrdersView,
  EmploymentManagementView,
} from '../../modules/project';
import RatingIndexView from '../../modules/rating/RatingIndexView';
import {
  NewRecruiterDetailsView,
  NewRecruiterAnonymousDetailsView,
  NewRecruiterOrganizationDetailsView,
  NewCandidateProjectDetailsView,
} from '../../modules/recruiter';
import RecruiterIndexView from '../../modules/recruiter/RecruiterIndexView';
import RecruiterOrganizationEditView from '../../modules/recruiter/RecruiterOrganizationEditView';
import RecruiterOrganizationRecruitersShowView from '../../modules/recruiter/RecruiterOrganizationRecruitersShowView';
import RecruiterOrganizationView from '../../modules/recruiter/RecruiterOrganizationView';
import RecruiterProjectClosedView from '../../modules/recruiter/RecruiterProjectClosedView';
import RecruiterProjectCoView from '../../modules/recruiter/RecruiterProjectCoView';
import RecruiterProjectOpenView from '../../modules/recruiter/RecruiterProjectOpenView';
import ReportIndexView from '../../modules/report/ReportIndexView';
import SearchIndexView from '../../modules/search/SearchIndexView';
import Status404View from '../../modules/status/Status404View';
import SummaryIndexView from '../../modules/summary/SummaryIndexView';
import SummaryForCandidateIndexView from '../../modules/summaryForCandidate/SummaryForCandidateIndexView';
import UserPanelView from '../../modules/user/UserPanelView';
import { HelpRecruiter, HelpCandidate } from '../helpComponents';

const routes: ModuleRoute[] = [
  {
    path: PATHS.PROJECT.INDEX,
    component: ProjectIndexView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.INDEX_SHOW,
    component: ProjectIndexView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.CREATE,
    component: NewProjectCreateFromView,
    exact: true,
    permission: [Roles.RECRUITER, Roles.ADMIN],
  },
  {
    path: PATHS.PROJECT.CREATE_FROM,
    component: NewProjectCreateFromView,
    exact: true,
    permission: [Roles.RECRUITER, Roles.ADMIN],
  },
  {
    path: PATHS.PROJECT.NEW_CREATE,
    component: NewProjectCreateView,
    exact: true,
    permission: [Roles.RECRUITER, Roles.ADMIN],
  },
  {
    path: PATHS.PROJECT.NEW_EDIT,
    component: NewProjectEditView,
    exact: true,
    permission: [Roles.RECRUITER, Roles.ADMIN],
  },
  {
    path: PATHS.PROJECT.NEW_CREATE_FROM,
    component: NewProjectEditView,
    exact: true,
    permission: [Roles.RECRUITER, Roles.ADMIN],
  },
  {
    path: PATHS.PROJECT.EDIT,
    component: NewProjectEditView,
    exact: true,
    permission: [Roles.RECRUITER, Roles.ADMIN],
  },
  {
    path: PATHS.PROJECT.SHOW,
    component: NewProjectShowView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.NEW_SHOW,
    component: NewProjectShowView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.CANDIDATES,
    component: ProjectCandidatesView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.RECRUITERS,
    component: ProjectRecruitersView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.PROJECT_ORDERS,
    component: ProjectOrdersView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.ATS,
    component: ProjectAtsView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.ATS_EMPLOYMENT_PROCESS,
    component: ProjectAtsEmploymentProcess,
    exact: true,
  },
  {
    path: PATHS.PROJECT.PAYMENTS,
    component: ProjectPaymentsView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.IMPORT_CANDIDATES,
    component: ProjectImportCandidatesView,
    exact: true,
  },
  {
    path: PATHS.PROJECT.ATS_EMPLOYMENT_PROCESS,
    component: ProjectAtsEmploymentProcess,
    exact: true,
  },
  {
    path: PATHS.PROJECT.ATS_EMPLOYMENT_MANAGEMENT,
    component: EmploymentManagementView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.INDEX,
    component: RecruiterIndexView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.INDEX_SHOW,
    component: RecruiterIndexView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.ORGANIZATION.INDEX,
    component: RecruiterOrganizationView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.ORGANIZATION.EDIT,
    component: RecruiterOrganizationEditView,
    exact: true,
    permission: [Roles.ADMIN, Roles.RECRUITER],
  },
  {
    path: PATHS.RECRUITER.ORGANIZATION.RECRUITERS,
    component: RecruiterOrganizationRecruitersShowView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.PROJECTS.PO,
    component: RecruiterProjectOpenView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.PROJECTS.CO,
    component: RecruiterProjectCoView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.PROJECTS.CLOSED,
    component: RecruiterProjectClosedView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.INDEX,
    component: CandidateIndexView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.INDEX_SHOW,
    component: CandidateIndexView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.CREATE,
    component: NewCandidateCreateFromView,
    exact: true,
    permission: [Roles.ADMIN, Roles.RECRUITER],
  },
  {
    path: PATHS.CANDIDATE.CREATE_IN_PROJECT,
    component: NewCandidateCreateFromView,
    exact: true,
    permission: [Roles.ADMIN, Roles.RECRUITER],
  },
  {
    path: PATHS.CANDIDATE.NEW_EDIT,
    component: NewCandidateEditFromView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.SHOW,
    component: NewCandidateAnonymousDetailsView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.PERSONAL_DATA,
    component: NewCandidatePersonalDetailsView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.PROJECTS,
    component: NewCandidateAllProjectsDetailsView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.EMPLOYMENT_STATUS,
    component: NewCandidateEmploymentStatusView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.PERSONAL_DATA,
    component: NewRecruiterDetailsView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.SHOW,
    component: NewRecruiterAnonymousDetailsView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.HELP,
    component: HelpRecruiter,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE.HELP,
    component: HelpCandidate,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.ALL_PROJECTS,
    component: NewCandidateProjectDetailsView,
    exact: true,
  },
  {
    path: PATHS.RECRUITER.ORGANIZATIONS,
    component: NewRecruiterOrganizationDetailsView,
    exact: true,
  },
  {
    path: PATHS.ORGANIZATION.INDEX,
    component: OrganizationIndexView,
    exact: true,
  },
  {
    path: PATHS.ORGANIZATION.SHOW,
    component: OrganizationShowView,
    exact: true,
  },
  {
    path: PATHS.ORGANIZATION.RECRUITERS,
    component: OrganizationRecruitersView,
    exact: true,
  },
  {
    path: PATHS.SUMMARY.INDEX,
    component: SummaryIndexView,
    exact: true,
  },
  {
    path: PATHS.SUMMARY_FOR_CANDIDATE.INDEX,
    component: SummaryForCandidateIndexView,
    exact: true,
  },
  {
    path: PATHS.CANDIDATE_SUMMARY.INDEX,
    component: CandidateSummaryIndexView,
    exact: true,
    permission: [Roles.ADMIN, Roles.RECRUITER],
  },
  {
    path: PATHS.REPORT.INDEX,
    component: ReportIndexView,
    exact: true,
  },
  {
    path: PATHS.RATING.INDEX,
    component: RatingIndexView,
    exact: true,
  },
  {
    path: PATHS.ACTIVITY.INDEX,
    component: ActivityIndexView,
    exact: true,
  },
  {
    path: PATHS.USER.INDEX,
    component: UserPanelView,
    exact: true,
  },
  {
    path: PATHS.SEARCH.INDEX,
    component: SearchIndexView,
    exact: true,
  },
  {
    path: PATHS.DEBUG.INDEX,
    component: DebugIndexView,
    exact: true,
  },
  {
    path: PATHS.ADMIN.ORGANIZATION,
    component: AdminOrganizationView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.INDUSTRY_CATEGORY,
    component: AdminIndustryCategoryView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.CITY_SEO,
    component: AdminCitySeoViewView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.CONFIG,
    component: AdminConfigView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.SKILLS,
    component: AdminSkillView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.COMPETENCE_CATEGORIES,
    component: AdminCompetenceCategoryView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.COMPETENCE_KINDS,
    component: AdminCompetenceKindView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.COMPETENCE_TYPE,
    component: AdminCompetenceTypeView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.COMPETENCE_TEMP,
    component: AdminCompetenceTempView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.SKILLS_TO_APPROVED,
    component: AdminSkillToApprovedView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.USER,
    component: AdminUserView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.SUPER_USER,
    component: AdminSuperUserView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.LOGS,
    component: AdminLogView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.HELPDESK,
    component: AdminHelpdeskView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.EXPORTS,
    component: ExportView,
    exact: true,
    permission: [Roles.ADMIN, Roles.RECRUITER],
  },
  {
    path: PATHS.ADMIN.ML_STATUS,
    component: MLStatusView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.ADMIN.CMS_CONFIG,
    component: AdminConfigCmsView,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    path: PATHS.STATUS.S404,
    component: Status404View,
    exact: true,
  },
];

export default routes;
