import { Grid } from '@material-ui/core';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { enums } from '../../../enums';
import { getEnumItemName } from '../../../utils/assets';
import { Translate } from '../../index';

const EmployerFeedback = ({ projectCandidate, t }) => {
  const feedback = projectCandidate.feedback;

  const feedbackEmployerDecision = projectCandidate.feedbackEmployerDecision;

  const feedbackRejectReasonsText = projectCandidate.feedbackRejectReasons
    .map(item => item.name)
    .join(', ');

  const feedbackEmployerDecisionText = getEnumItemName(
    enums.FEEDBACK_EMPLOYER_DECISION,
    feedbackEmployerDecision
  );

  return (
    <Grid container>
      <Grid container>
        {feedbackEmployerDecisionText !== null &&
        feedbackEmployerDecisionText !== undefined ? (
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={'newAts:feedbackView.feedbackEmployerDecision'}
                bold
                style={{ fontSize: 20 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={feedbackEmployerDecisionText}
                style={{ fontSize: 16 }}
              />
              <Translate
                text={feedbackRejectReasonsText}
                style={{ fontSize: 16 }}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={12}>
          <Translate
            text={'newAts:feedbackView.feedbackTitle'}
            bold
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Translate
            text={ReactHtmlParser(feedback)}
            disableTranslate={true}
            style={{ fontSize: 16 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(EmployerFeedback);
