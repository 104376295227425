import { Dialog, Grid, Icon, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { get, map, find, isEmpty, concat } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import EmployersIcon from 'shared/assets/img/icons/project-show/employers.svg';
import PinIcon from 'shared/assets/img/icons/project-show/pin.svg';
import { enums } from 'shared/enums';
import { ProgressBar, RadarChart, Translate } from 'shared/newComponents';
import CustomSlider from 'shared/newComponents/CustomSlider/CustomSlider';
import { getAssetUrl } from 'shared/services';
import {
  getEnumItemName,
  getEnumItemNameFullObject,
  getProjectAssetsSrcByType,
} from 'shared/utils/assets';
import { getCompetenceHeaderInfo } from 'shared/utils/functions';
import { MODEL } from '../../../../../../candidate/NewCandidateCreateView/components/CandidateCreateWizardStep2/CandidateCreateWizardStep2.utils';
import { styles } from './MainContent.style';
import { getRequiredAttachements, getWorkStyles } from './MainContent.utils';

const MainContent = ({ projectDetails, classes, currentUser, t }) => {
  const [isOpenGDPR, setIsOpenGDPR] = useState(false);

  const [contentCountPerSlider, setContentCountPerSlider] = useState(4);

  const [currentOffset, setCurrentOffset] = useState(0);

  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

  let maxPage = 0;

  const [isOpenProcessingAgreement, setIsOpenProcessingAgreement] =
    useState(false);
  const headerId = get(
    find(get(projectDetails, 'assets'), o => o.type === 'header'),
    'id'
  );
  const logoId = get(
    find(get(projectDetails, 'assets'), o => o.type === 'logo'),
    'id'
  );
  const logo = getAssetUrl(logoId);
  const position = get(projectDetails, 'position');
  const city = get(projectDetails, 'city');
  const isRemoteWork = get(projectDetails, 'isRemoteWork');
  const industryCategory = get(projectDetails, 'industryCategory');
  const industryCategoryName = get(industryCategory, 'name');
  const industryCategoryAssets = get(industryCategory, 'assets');
  const industryCategoryLogo = industryCategoryAssets
    ? industryCategoryAssets.find(item => item.type === 'industryCategoryLogo')
    : null;

  const industryCategoryBackground = industryCategoryAssets
    ? industryCategoryAssets.find(
        item => item.type === 'industryCategoryBackground'
      )
    : null;
  const header = headerId
    ? getAssetUrl(headerId)
    : getAssetUrl(
        industryCategoryBackground ? industryCategoryBackground.id : undefined
      );

  const industryCategoryIcon = getAssetUrl(
    industryCategoryLogo ? industryCategoryLogo.id : null
  );
  const companySizeIndex = get(projectDetails, 'companySize');
  const companySize = getEnumItemName(enums.COMPANY_SIZE, companySizeIndex);
  const clientUrl = get(projectDetails, 'clientUrl');
  const videoUrl = get(projectDetails, 'videoUrl');
  const isClientUrlCorrect = clientUrl ? clientUrl.includes('://') : '';
  const minSalary = get(projectDetails, 'minSalary');
  const maxSalary = get(projectDetails, 'maxSalary');
  const currencyIndex = get(projectDetails, 'currency');
  const typeOfPayIndex = get(projectDetails, 'typeOfPay');
  const currency = getEnumItemName(enums.CURRENCY, currencyIndex);
  const typeOfPay = getEnumItemName(enums.TYPE_OF_PAY, typeOfPayIndex);
  const salaryTypeIndex = get(projectDetails, 'salaryType');
  const salaryType = getEnumItemName(enums.WORKING_HOURS, salaryTypeIndex);
  const employmentTypeIndexArray = map(
    get(projectDetails, 'employmentTypes'),
    o => o.id - 1
  );
  const employmentType = getEnumItemName(
    enums.EMPLOYMENT_TYPE,
    employmentTypeIndexArray
  );
  const expectedWorkTime = get(projectDetails, 'expectedWorkTime');
  const workHourTypeIndex = get(projectDetails, 'workHourType');
  const workHourType = getEnumItemName(enums.WORKING_HOURS, workHourTypeIndex);
  const employmentStartDate = get(projectDetails, 'employmentStartDate');
  const positionDescription = get(projectDetails, 'positionDescription');
  const projectSkills = get(projectDetails, 'projectSkills');
  const requirements = get(projectDetails, 'requirements');
  const workStyles = getWorkStyles(projectDetails);
  const requiredAttachment = getRequiredAttachements(projectDetails, t);
  const benefits = get(projectDetails, 'benefits');
  const companyDescription = get(projectDetails, 'companyDescription');

  const isClientPublic = get(projectDetails, 'isClientPublic');
  const isSalaryPublic = get(projectDetails, 'isSalaryPublic');
  const companyName = isClientPublic
    ? get(projectDetails, 'client')
    : t('projectCreator:step1.privateCompanyName');
  const dataProcessingAgreementText = get(
    projectDetails,
    'dataProcessingAgreement'
  );
  const clauseGDPRText = get(projectDetails, 'clauseGDPR');
  const clauseGDPRUrl = get(projectDetails, 'clauseGDPRUrl');
  const dataProcessingAgreementUrl = get(
    projectDetails,
    'dataProcessingAgreementUrl'
  );
  const handleResize = () => {
    if (window.innerWidth > 1566) {
      contentCountPerSlider !== 4 && setCurrentOffset(0);
      setContentCountPerSlider(4);
    }
    if (window.innerWidth > 1165 && window.innerWidth <= 1566) {
      contentCountPerSlider !== 3 && setCurrentOffset(0);
      setContentCountPerSlider(3);
    }
    if (window.innerWidth > 1085 && window.innerWidth <= 1330) {
      contentCountPerSlider !== 2 && setCurrentOffset(0);
      setContentCountPerSlider(2);
    }
    if (window.innerWidth <= 1085) {
      contentCountPerSlider !== 1 && setCurrentOffset(0);
      setContentCountPerSlider(1);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  const handleShowImageDialog = imageSrc => {
    setIsImageDialogOpen(true);
    setSelectedImage(imageSrc);
  };
  const handleShowVideoDialog = videoUrl => {
    setIsVideoDialogOpen(true);
    setSelectedVideo(videoUrl);
  };

  const handleCloseImageDialog = () => {
    setIsImageDialogOpen(false);
    setSelectedImage(null);
  };

  const handleCloseVideoDialog = () => {
    setIsVideoDialogOpen(false);
    setSelectedVideo(null);
  };

  const getImageContent = (imageSrc, index) => {
    return (
      <Grid container onClick={() => handleShowImageDialog(imageSrc)}>
        <Grid item className={classes.imgContainer}>
          <img
            src={imageSrc}
            alt={`imageSoruce${index}`}
            className={classes.picture}
          />
        </Grid>
      </Grid>
    );
  };
  const projectImagesToMap = getProjectAssetsSrcByType(
    projectDetails,
    'slider'
  );
  const projectImages = projectImagesToMap
    ? getProjectAssetsSrcByType(projectDetails, 'slider').map(
        (imageSrc, index) => {
          return <div key={index}>{getImageContent(imageSrc, index)}</div>;
        }
      )
    : null;

  const projectPermissions = projectDetails.permissions;

  const getProjectVideo = video => {
    const parsedLink = 'https://www.youtube.com/embed/' + video;
    return !isEmpty(video) ? (
      <Grid>
        <Grid key={video} onClick={() => handleShowVideoDialog(parsedLink)}>
          <iframe
            title="Jobllegro"
            src={parsedLink}
            className={classes.picture}
            style={{
              cursor: 'pointer',
              borderWidth: 0,
              pointerEvents: 'none',
            }}
          />
        </Grid>
      </Grid>
    ) : (
      []
    );
  };

  const imageList = videoUrl
    ? concat(getProjectVideo(videoUrl), projectImages)
    : projectImages;

  const imageToShow = () => {
    maxPage = Math.ceil(imageList.length / contentCountPerSlider);
    const page = currentOffset;
    return imageList.slice(
      page * contentCountPerSlider,
      page * contentCountPerSlider + contentCountPerSlider
    );
  };

  return (
    <>
      <Grid container style={{ padding: 30 }}>
        <Grid container className={classes.imagesWrapper}>
          <img src={header} alt={'imageHeader'} className={classes.header} />
          <Grid container alignItems="center" className={classes.logoWrapper}>
            {logo ? (
              <img
                src={logo}
                alt={'imageLogo'}
                className={classes.logo}
                style={{ maxWidth: '15%' }}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '2%' }}>
          <Translate
            style={{
              fontSize: 26,
            }}
            variant="h6"
            bold
            disableTranslate={true}
            text={position}
          />
        </Grid>
        <Grid container style={{ marginBottom: '1%' }}>
          <Translate
            variant="body1"
            text={companyName}
            disableTranslate={true}
          />
        </Grid>
        <Grid container style={{ padding: '8px 0' }}>
          {city && (
            <Grid container alignItems="center" style={{ padding: '4px 0' }}>
              <img src={PinIcon} alt={'city'} style={{ paddingRight: 8 }} />
              <Translate text={city} variant="body1" disableTranslate={true} />
              {isRemoteWork && (
                <>
                  <Translate text="&nbsp;" variant="body1" />
                  <Translate
                    text="projectDetails:mainContent.isRemoteWork"
                    variant="body1"
                  />
                </>
              )}
            </Grid>
          )}
          {industryCategoryName && (
            <Grid container alignItems="center" style={{ padding: '4px 0' }}>
              <img
                src={industryCategoryIcon}
                alt={'imageIndustry'}
                style={{ width: 30, height: 30, paddingRight: 8 }}
              />
              <Translate text={industryCategoryName} variant="body1" />
            </Grid>
          )}
          {companySize && (
            <Grid container alignItems="center" style={{ padding: '4px 0' }}>
              <img
                src={EmployersIcon}
                alt={'companySize'}
                style={{ width: 30, height: 30, paddingRight: 8 }}
              />

              <Translate text={companySize} variant="body1" />
              <Translate text="&nbsp;os." variant="body1" />
            </Grid>
          )}
          {clientUrl && isClientPublic && (
            <Grid container style={{ marginBottom: '2%' }}>
              <a
                href={isClientUrlCorrect ? clientUrl : `https://${clientUrl}`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                >
                  {clientUrl}
                </Typography>
              </a>
            </Grid>
          )}
          {imageList ? (
            <Grid container>
              <Grid
                item
                style={{
                  width: '100%',
                }}
              >
                <CustomSlider
                  showIndicators={imageList.length > contentCountPerSlider}
                  handleClickLeft={() => {
                    if (currentOffset - 1 < 0) {
                      setCurrentOffset(maxPage - 1);
                    } else {
                      setCurrentOffset(currentOffset - 1);
                    }
                  }}
                  handleClickRight={() => {
                    if (currentOffset + 1 > maxPage - 1) {
                      setCurrentOffset(0);
                    } else {
                      setCurrentOffset(currentOffset + 1);
                    }
                  }}
                >
                  <Grid item style={{ display: 'flex' }}>
                    {imageToShow()}
                  </Grid>
                </CustomSlider>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid container className={classes.blueBackground}>
        <Grid container style={{ padding: 30 }} spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Translate
                bold
                text="projectDetails:mainContent.salaryType"
                variant="body1"
                style={{ fontSize: 20 }}
              />
              {isSalaryPublic ? (
                <Grid container>
                  <Translate
                    text={`${minSalary} - ${maxSalary} ${currency} ${t(
                      typeOfPay
                    )} `}
                    disableTranslate={true}
                    variant="body1"
                  />
                  <Translate text="&nbsp;" variant="body1" />

                  <Translate text={salaryType} variant="body1" />
                </Grid>
              ) : (
                <>
                  <Grid container>
                    <Translate
                      text="projectCreator:reviewStep1.salaryIsHide"
                      variant="body1"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Translate
                bold
                text="projectDetails:mainContent.employmentType"
                variant="body1"
                style={{ fontSize: 20 }}
              />
              {employmentType.map((item, index) => (
                <React.Fragment key={index}>
                  <Translate
                    text={item}
                    variant="body1"
                    style={{ display: 'inline' }}
                  />
                  {index < employmentType.length - 1 && (
                    <Translate
                      text=",&nbsp;"
                      variant="body1"
                      style={{ display: 'inline' }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Translate
                bold
                text="projectDetails:mainContent.expectedWorkTime"
                variant="body1"
                style={{ fontSize: 20 }}
              />
              <Grid container>
                <Translate
                  text={`${expectedWorkTime} ${t`project:hours`} `}
                  variant="body1"
                />
                <Translate text="&nbsp;" variant="body1" />

                <Translate text={workHourType} variant="body1" />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Translate
                bold
                text="projectDetails:mainContent.employmentStartDate"
                variant="body1"
                style={{ fontSize: 20 }}
              />
              <Translate text={employmentStartDate} variant="body1" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ padding: 30 }}>
        <Grid container>
          <Translate
            text="projectDetails:mainContent.positionDesc"
            bold
            variant="body1"
            style={{ fontSize: 20 }}
          />
        </Grid>
        <div>{ReactHtmlParser(positionDescription)}</div>
        <Grid container>
          <hr className={classes.separator} />
        </Grid>
        <Grid container style={{ paddingBottom: 8 }}>
          <Translate
            bold
            text="projectDetails:mainContent.skillDesc"
            variant="body1"
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid container style={{ width: '65%' }}>
          {map(projectSkills, (skill, index) => {
            const experienceLevel = get(skill, 'experienceLevel') + 1;
            const level =
              experienceLevel * 10 < 100 ? experienceLevel * 10 : 100;
            const headerInfo = getCompetenceHeaderInfo(
              projectDetails,
              index,
              t
            );

            const secondHeaderInfo = getEnumItemNameFullObject(
              enums.EXPERIENCE_LEVEL,
              get(skill, MODEL.experienceLevel)
            );

            return (
              <ProgressBar
                key={skill.id}
                percentage={level}
                title={headerInfo}
                secondTitle={secondHeaderInfo}
                customStyleSecondHeader={{
                  color: '#6CB8FF',
                }}
              />
            );
          })}
        </Grid>
        <Grid container>
          <hr className={classes.separator} />
        </Grid>
        <Grid container style={{ paddingBottom: 8 }}>
          <Translate
            bold
            text="projectDetails:mainContent.requirementsDesc"
            variant="body1"
            style={{ fontSize: 20 }}
          />
        </Grid>
        <div>{ReactHtmlParser(requirements)}</div>
      </Grid>
      <Grid container className={classes.blueBackground}>
        <Grid container style={{ padding: 30 }}>
          <Grid container>
            <Translate
              text="projectDetails:mainContent.workStyles"
              bold
              variant="body1"
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Grid container justifyContent="center">
            <RadarChart data={workStyles} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ padding: 30 }}>
        <Grid container>
          <Translate
            text="projectDetails:mainContent.requiredAttachment"
            bold
            variant="body1"
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid container>
          <ul>
            {requiredAttachment.map((item, index) => (
              <React.Fragment key={index}>
                <li>
                  <p
                    style={{
                      fontFamily: 'Barlow Semi Condensed, sans-serif',
                    }}
                  >
                    {item}
                  </p>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </Grid>
        {benefits && (
          <>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
            <Grid container style={{ paddingBottom: 8 }}>
              <Translate
                bold
                text="projectDetails:mainContent.benefits"
                style={{ fontSize: 20 }}
                variant="body1"
              />
            </Grid>
            <div>{ReactHtmlParser(benefits)}</div>
          </>
        )}
        {companyDescription && (
          <>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
            <Grid container style={{ paddingBottom: 8 }}>
              <Translate
                bold
                text="projectDetails:mainContent.companyDescription"
                variant="body1"
                style={{ fontSize: 20 }}
              />
            </Grid>
            <div>{ReactHtmlParser(companyDescription)}</div>
          </>
        )}
        {(dataProcessingAgreementText ||
          clauseGDPRText ||
          clauseGDPRUrl ||
          dataProcessingAgreementUrl) && (
          <>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
            <Grid container style={{ paddingBottom: 16 }}>
              <Translate
                bold
                text="projectDetails:mainContent.agreement"
                variant="body1"
                style={{ fontSize: 20 }}
              />
            </Grid>
            {(dataProcessingAgreementText || dataProcessingAgreementUrl) && (
              <>
                <Grid container style={{ paddingBottom: 8 }}>
                  <Translate
                    bold
                    text="projectDetails:mainContent.dataProcessingAgreementText"
                    variant="body1"
                    style={{ fontSize: 18 }}
                  />
                  {dataProcessingAgreementText ? (
                    <Grid item>
                      <Grid container alignItems="center">
                        {isOpenProcessingAgreement ? (
                          <Icon
                            onClick={() => {
                              setIsOpenProcessingAgreement(false);
                            }}
                          >
                            <KeyboardArrowUp className={classes.icon} />
                          </Icon>
                        ) : (
                          <Icon
                            onClick={() => {
                              setIsOpenProcessingAgreement(true);
                            }}
                          >
                            <KeyboardArrowDown className={classes.icon} />
                          </Icon>
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                {dataProcessingAgreementText ? (
                  <div>
                    {isOpenProcessingAgreement
                      ? ReactHtmlParser(dataProcessingAgreementText)
                      : null}
                  </div>
                ) : (
                  <a
                    href={`https://${dataProcessingAgreementUrl}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant="body1" color="primary">
                      {dataProcessingAgreementUrl}
                    </Typography>
                  </a>
                )}
              </>
            )}
            {(clauseGDPRText || clauseGDPRUrl) && (
              <>
                <Grid container style={{ paddingBottom: 8 }}>
                  <Translate
                    bold
                    text="projectDetails:mainContent.clauseGDPRText"
                    variant="body1"
                    style={{ fontSize: 18 }}
                  />
                  {clauseGDPRText ? (
                    <Grid item>
                      <Grid container alignItems="center">
                        {isOpenGDPR ? (
                          <Icon
                            // className={classes.iconWrapper}
                            onClick={() => {
                              setIsOpenGDPR(false);
                            }}
                          >
                            <KeyboardArrowUp className={classes.icon} />
                          </Icon>
                        ) : (
                          <Icon
                            onClick={() => {
                              setIsOpenGDPR(true);
                            }}
                          >
                            <KeyboardArrowDown className={classes.icon} />
                          </Icon>
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                {clauseGDPRText ? (
                  <div>
                    {isOpenGDPR ? ReactHtmlParser(clauseGDPRText) : null}
                  </div>
                ) : (
                  <a
                    href={`https://${clauseGDPRUrl}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant="body1" color="primary">
                      {clauseGDPRUrl}
                    </Typography>
                  </a>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {isImageDialogOpen ? (
        <Dialog
          open={isImageDialogOpen}
          maxWidth={'md'}
          onClose={handleCloseImageDialog}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } }}
        >
          <Grid item className={classes.imgContainer}>
            <img
              width="854"
              height="480"
              src={selectedImage}
              alt={`selectedImage`}
            />
          </Grid>
        </Dialog>
      ) : null}
      {isVideoDialogOpen ? (
        <Dialog
          open={isVideoDialogOpen}
          maxWidth={'md'}
          onClose={handleCloseVideoDialog}
          transparent={true}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } }}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Grid item>
            <iframe
              allowFullScreen="allowFullScreen"
              width="854"
              height="480"
              className={classes.iframeVideoDialog}
              title="Jobllegro"
              src={selectedVideo}
            />
          </Grid>
        </Dialog>
      ) : null}
    </>
  );
};

export default withStyles(styles)(withTranslation()(MainContent));
