import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import 'shared/assets/css/ProjectStepper.css';
import moment from 'moment';
import 'moment/locale/pl';
import { withTranslation } from 'react-i18next';

moment.locale('pl');

const ProjectStepper = ({
  startDate,
  endDate,
  classes,
  variantMini = false,
  t,
  type = '',
}) => {
  const [percentage, setPercentage] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);
  const [alert, setAlert] = useState(false);

  const countDifferenceBetweenDates = () => {
    const now = moment(new Date());
    const startDateProps = moment(startDate).startOf('day');
    const endDateProps = moment(endDate).endOf('day');

    const secondsFromStart = now.diff(startDateProps);
    const secondsToEnd = -now.diff(endDateProps);

    let timeLeftProps = null;
    let alertTriggerProps = false;
    let resultPercentageProps = 0;

    if (
      startDateProps.isBefore(now, 'day') &&
      endDateProps.isAfter(now, 'day')
    ) {
      resultPercentageProps =
        (secondsFromStart / (secondsFromStart + secondsToEnd)) * 100;
      timeLeftProps = endDateProps.endOf('day').toNow(true);
    } else if (startDateProps.isBefore(now, 'day')) {
      timeLeftProps = endDateProps.endOf('day').toNow(true);
      alertTriggerProps = true;
    }
    setPercentage(resultPercentageProps);
    setTimeLeft(timeLeftProps);
    setAlert(alertTriggerProps);
  };

  useEffect(() => {
    countDifferenceBetweenDates();
  }, []);

  useEffect(() => {
    countDifferenceBetweenDates();
  }, [startDate, endDate]);

  return variantMini ? (
    <Grid container spacing={0} className={classes.rootMiniSecond}>
      <Grid item xs={12} sm={4} md={4} lg={4} align="center">
        {timeLeft && (
          <Typography
            component="p"
            gutterBottom
            className={classes.titleprimary}
          >
            {alert ? t('stepper.deadline') : t('stepper.remaining')}
          </Typography>
        )}
        <div className="meter" align="left">
          <div className={alert ? 'circleLeft circleAlert' : 'circleLeft'} />
          <div
            className={alert ? 'middleLine alert' : 'middleLine'}
            style={{ width: alert ? '100%' : percentage + '%' }}
          />
          <div className={alert ? 'circleRight circleAlert' : 'circleRight'} />
        </div>
        {timeLeft && (
          <Typography
            component="p"
            gutterBottom
            className={classNames(classes.number, alert && classes.numberAlert)}
          >
            {alert ? `-${timeLeft}` : timeLeft}
          </Typography>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12} sm={4} md={3} lg={4} align="center">
        <List>
          <ListItem style={{ padding: 0 }}>
            <ListItemText
              style={{ padding: 0 }}
              primary={t('stepper.beginning')}
              secondary={startDate}
              classes={{
                root: type === 'wide' && classes.rootWide,
                primary: classes.titleprimary,
                secondary: classes.titleseccondary,
              }}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} align="center">
        {timeLeft && (
          <Typography
            component="p"
            gutterBottom
            className={classes.titleprimary}
          >
            {alert ? t('stepper.deadline') : t('stepper.remaining')}
          </Typography>
        )}
        <div className="meter" align="left">
          <div className={alert ? 'circleLeft circleAlert' : 'circleLeft'} />
          <div
            className={alert ? 'middleLine alert' : 'middleLine'}
            style={{ width: alert ? '100%' : percentage + '%' }}
          />
          <div className={alert ? 'circleRight circleAlert' : 'circleRight'} />
        </div>
        {timeLeft && (
          <Typography
            component="p"
            gutterBottom
            className={classNames(classes.number, alert && classes.numberAlert)}
          >
            {alert ? `-${timeLeft}` : timeLeft}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={4} align="center">
        <List>
          <ListItem style={{ padding: 0 }}>
            <ListItemText
              style={{ padding: 0 }}
              primary={t('stepper.ending')}
              secondary={endDate}
              classes={{
                root: type === 'wide' && classes.rootWide,
                primary: classes.titleprimary,
                secondary: classes.titleseccondary,
              }}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ProjectStepper);
