import { API } from '../constants';
import { ApiService } from './api.service';

export const EmploymentType = {
  getEmploymentTypes() {
    return ApiService.get(API.GET.EMPLOYMENT_TYPE.GET);
  },
};

export default EmploymentType;
