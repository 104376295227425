export const styles = ({ breakpoints, palette }) => ({
  recruiterCardWrapper: {
    padding: '30px 15px',
  },
  headerWrapper: {
    minHeight: 150,
  },
  recruiterCard: {
    width: '100%',
    height: 420,
    borderRadius: 10,
    boxShadow: '-1px -1px 30px 0px rgba(0, 0, 0, 0.1)',
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: '50',
    [breakpoints.only('lg')]: {
      width: 50,
      height: 50,
    },
  },
  fontStylesBig: {
    fontSize: 22,
    color: '#148EFF',
  },
  fontStylesSmall: {
    fontSize: 16,
  },
  wrapperTextExp: {
    marginTop: 23,
  },
  wrapperDepartments: {
    marginTop: 23,
    minHeight: 96,
  },
  wrapperLogos: {
    marginTop: 23,
    minHeight: 40,
  },
  imgWrapper: {
    display: 'flex',
  },
  saveButton: {
    marginTop: 23,
  },
  personalDataWrapper: {
    cursor: 'pointer',
  },
  userStatus: {
    background: palette.jobllegroRedAlert,
    paddingRight: 5,
    paddingLeft: 5,
    top: '58px',
    [breakpoints.only('lg')]: {
      top: '40px',
    },
    borderRadius: 0,
    color: '#fff',
    width: 'auto',
    right: 0,
  },
  userStatusDisabled: {
    display: 'none',
  },
});
