import {
  Grid,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  showSnackbarInformationMessage,
  hideSnackbarInformationMessage,
} from 'shared/store/messages/actions';
import { getRecrutationServicesSelectedOption } from 'shared/utils/functions';

const styles = theme => ({
  rootPaper: {
    paddingTop: 0,
    paddingBottom: 64,
    marginTop: 0,
    paddingLeft: 64,
    paddingRight: 64,
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  textHeading: {
    paddingTop: 32,
    paddingBottom: 32,
    color: '#52a3fc',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
    },
  },
  text: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#7f7f7f',
    fontSize: '22px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  textList: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.jobllegroBlue,
    fontSize: '18px',
  },
  textTop: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#7f7f7f',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  gridStyle: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  price: {
    fontWeight: '700',
    color: '#52a3fc',
  },
  information: {
    color: theme.palette.jobllegroBlue,
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 12,
  },
  toolTipSpan: {
    fontSize: 14,
  },
});

export const ProjectDetailsRecruiterServices = props => {
  const { classes, projectDetails, t } = props;

  let scopeTitle = '';
  if (projectDetails.scope === 1) {
    scopeTitle = 'Search & selection';
  } else if (projectDetails.scope === 2) {
    scopeTitle = 'Validation';
  }

  const getTooltipSpan = text => {
    return <span className={classes.toolTipSpan}> {text} </span>;
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.rootPaper} elevation={0}>
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            className={classes.textHeading}
          >
            {t('project:show.recrutationServices')}
          </Typography>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.gridStyle}
            >
              <div>
                <Typography component="p" className={classes.textTop}>
                  {t('project:show.serviceScope') + ':'}
                </Typography>
                <Typography component="p" gutterBottom className={classes.text}>
                  <span className={classes.price}>{scopeTitle}</span>
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.gridStyle}
            >
              <div>
                <Typography component="p" className={classes.textTop}>
                  {t('project:field.calcCandidateExperience') + ':'}
                  <Tooltip
                    title={getTooltipSpan(
                      t('project:show.calcCandidateExperienceInfo')
                    )}
                  >
                    <span className={classes.information}>(?)</span>
                  </Tooltip>
                </Typography>
                <Typography
                  component="p"
                  gutterBottom
                  className={classes.textList}
                >
                  {t(
                    getRecrutationServicesSelectedOption(
                      'calcCandidateExperience',
                      projectDetails.calcCandidateExperience
                    )
                  )}
                </Typography>
                <Typography component="p" className={classes.textTop}>
                  {t('project:field.calcCandidateWarranty') + ':'}
                  <Tooltip
                    title={getTooltipSpan(
                      t('project:show.calcCandidateWarrantyInfo')
                    )}
                  >
                    <span className={classes.information}>(?)</span>
                  </Tooltip>
                </Typography>
                <Typography
                  component="p"
                  gutterBottom
                  className={classes.textList}
                >
                  {t(
                    getRecrutationServicesSelectedOption(
                      'calcCandidateWarranty',
                      projectDetails.calcCandidateWarranty
                    )
                  )}
                </Typography>
                <Typography component="p" className={classes.textTop}>
                  {t('project:field.calcNumberOfRecommendations') + ':'}
                  <Tooltip
                    title={getTooltipSpan(
                      t('project:show.calcNumberOfRecommendationsInfo')
                    )}
                  >
                    <span className={classes.information}>(?)</span>
                  </Tooltip>
                </Typography>
                <Typography
                  component="p"
                  gutterBottom
                  className={classes.textList}
                >
                  {t(
                    getRecrutationServicesSelectedOption(
                      'calcNumberOfRecomendations',
                      projectDetails.calcNumberOfRecomendations
                    )
                  )}
                </Typography>
                <Typography component="p" className={classes.textTop}>
                  {t('project:field.calcPaymentDeadline') + ':'}
                  <Tooltip
                    title={getTooltipSpan(
                      t('project:show.calcPaymentDeadlineInfo')
                    )}
                  >
                    <span className={classes.information}>(?)</span>
                  </Tooltip>
                </Typography>
                <Typography
                  component="p"
                  gutterBottom
                  className={classes.textList}
                >
                  {t(
                    getRecrutationServicesSelectedOption(
                      'calcPaymentDeadline',
                      projectDetails.calcPaymentDeadline
                    )
                  )}
                </Typography>
                <Typography component="p" className={classes.textTop}>
                  {t('project:field.calcSearchTime') + ':'}
                  <Tooltip
                    title={getTooltipSpan(t('project:show.calcSearchTimeInfo'))}
                  >
                    <span className={classes.information}>(?)</span>
                  </Tooltip>
                </Typography>
                <Typography
                  component="p"
                  gutterBottom
                  className={classes.textList}
                >
                  {t(
                    getRecrutationServicesSelectedOption(
                      'calcSearchTime',
                      projectDetails.calcSearchTime
                    )
                  )}
                </Typography>
                <Typography component="p" className={classes.textTop}>
                  {t('project:field.calcServiceCompletionDate') + ':'}
                  <Tooltip
                    title={getTooltipSpan(
                      t('project:show.calcServiceCompletionDateInfo')
                    )}
                  >
                    <span className={classes.information}>(?)</span>
                  </Tooltip>
                </Typography>
                <Typography
                  component="p"
                  gutterBottom
                  className={classes.textList}
                >
                  {t(
                    getRecrutationServicesSelectedOption(
                      'calcServiceCompletionDate',
                      projectDetails.calcServiceCompletionDate
                    )
                  )}
                </Typography>
                <Typography component="p" className={classes.textTop}>
                  {t('project:field.calcUpfrontPayment') + ':'}
                  <Tooltip
                    title={getTooltipSpan(
                      t('project:show.calcUpfrontPaymentInfo')
                    )}
                  >
                    <span className={classes.information}>(?)</span>
                  </Tooltip>
                </Typography>
                <Typography
                  component="p"
                  gutterBottom
                  className={classes.textList}
                >
                  {t(
                    getRecrutationServicesSelectedOption(
                      'calcUpfrontPayment',
                      projectDetails.calcUpfrontPayment
                    )
                  )}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

const mapDispatchToProps = {
  showSnackbarInformationMessage,
  hideSnackbarInformationMessage,
};

ProjectDetailsRecruiterServices.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectDetailsRecruiterServices)));
