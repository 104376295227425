import { FormControl, Slider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Component } from 'react';

export class SliderInputField extends Component {
  constructor(props) {
    super(props);
    let value = parseInt(
      this.props.input.value !== undefined
        ? this.props.input.value
        : this.props.defaultValue,
      10
    );
    value = isNaN(value) ? this.props.defaultValue : value;
    this.state = {
      value: value,
    };
  }

  onChange = async (event, value) => {
    await this.setState({ value: parseInt(value || 0, 10) });
    this.props.input.onChange(this.state.value);
  };

  render() {
    return (
      <FormControl fullWidth>
        <Typography htmlFor={this.props.input.name}>
          {this.props.label} ({this.state.value})
        </Typography>
        <Slider
          min={0}
          max={100}
          step={1}
          value={this.state.value}
          onChange={this.onChange}
          onDrop={this.onDrop}
        />
      </FormControl>
    );
  }
}

SliderInputField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default SliderInputField;
