import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Tooltip,
  ListItemSecondaryAction,
  IconButton,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
  link: {
    color: '#575755',
    fontWeight: 600,
    fontSize: 15,
    '&:hover': {
      color: theme.palette.jobllegroBlue,
    },
  },
  listItem: {
    paddingLeft: '0px',
    paddingTop: '16px',
    paddingBottom: '16px',
    '&:hover': {
      background: 'transparent',
      transform: 'translateX(5px)',
    },
    '&:hover > $listItemIcon': {
      color: '#fff',
    },
    transition: 'all .4s',
  },
  listItemActive: {
    paddingLeft: '0px',
    paddingTop: '16px',
    paddingBottom: '16px',
    '&:hover': {
      background: 'transparent',
      transform: 'translateX(5px)',
    },
    '&:hover > $listItemIcon': {
      color: theme.palette.jobllegroBlue,
    },
    transition: 'all .4s',
  },
  circle: {
    height: '20px',
    width: '20px',
    backgroundColor: '#ff4e44',
    borderRadius: '50%',
    display: 'inline-block',
    fontSize: 10,
    color: '#fff',
    textAlign: 'center',
    lineHeight: '22px',
  },
  iconButton: {
    justifyContent: 'flex-end',
  },
  arrowPopper: {
    '&[x-placement*="right"] $arrowArrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.grey[700]} transparent transparent`,
      },
    },
  },
  arrowArrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  rootTooltip: {
    borderRadius: 0,
    background: theme.palette.jobllegroGrey,
  },
  listItemIcon: {
    color: theme.palette.jobllegroLightGrey,
  },
});

export const LeftDrawerMenuListItem = props => {
  const { key, url, name, icon, badge, classes, isOpened, handleDrawerClose } =
    props;

  return (
    <MenuItem
      key={key}
      button
      component={Link}
      to={url}
      className={isOpened ? classes.listItemActive : classes.listItem}
      onClick={handleDrawerClose}
    >
      {isOpened ? (
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      ) : (
        <Tooltip
          placement="right"
          classes={{
            tooltip: classes.rootTooltip,
            popper: classes.arrowPopper,
          }}
          title={
            <React.Fragment>
              {name}
              <span className={classes.arrowArrow} />
            </React.Fragment>
          }
        >
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        </Tooltip>
      )}
      <ListItemText
        inset
        primary={
          <Typography align="left" component="span" className={classes.link}>
            {name}
          </Typography>
        }
      />
      {badge && (
        <ListItemSecondaryAction>
          <IconButton aria-label="Alerts" className={classes.iconButton}>
            <div className={classes.circle}>5</div>
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </MenuItem>
  );
};

LeftDrawerMenuListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  key: PropTypes.string,
  badge: PropTypes.bool.isRequired,
};

export default withStyles(styles)(LeftDrawerMenuListItem);
