import { Grid, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { getAssetUrl } from 'shared/services';
import { styles } from './IndustriesExperienceItem.styles';

const IndustriesExperienceItem = ({
  industryCategory,
  yearOfExperiences,
  classes,
  t,
}) => {
  const industryCategoryAssets = get(industryCategory, 'assets');
  const industryCategoryLogo = industryCategoryAssets
    ? industryCategoryAssets.find(item => item.type === 'industryCategoryLogo')
    : null;
  const industryCategoryIcon = industryCategoryLogo
    ? getAssetUrl(industryCategoryLogo.id)
    : null;

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.wrapperItem}>
      <Grid container justifyContent={'space-around'} alignItems={'center'}>
        <Grid item xs={4} sm={4} md={4}>
          <div className={classes.imgWrapper}>
            <img
              alt="industryCategoryIcon"
              src={industryCategoryIcon}
              height={40}
            />
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Translate
            text={industryCategory.name}
            className={classes.fontStyleSmall}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Translate
            text={yearOfExperiences}
            className={classes.fontStyleSmallWithColor}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(IndustriesExperienceItem))
);
