import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  authUser,
  resetPassword,
  validateResetPasswordToken,
} from 'shared/services/authService';
import { getConfigs } from 'shared/services/configService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import ResetPassword from './ResetPassword.style';

const mapStateToProps = store => {
  return {
    config: store.auth.config,
  };
};

const mapDispatchToProps = {
  authUser,
  getConfigs,
  validateResetPasswordToken,
  resetPassword,
  showSnackbarMessage,
};

const ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ResetPassword)));

ResetPasswordContainer.displayName = 'ResetPasswordContainer';

export default ResetPasswordContainer;
