import { Grid, withStyles } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Select,
  TextEditor,
  Translate,
  WizardStep,
} from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import { executeScroll } from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { useFormStep6 } from '../CandidateCreateWizard/CandidateCreateWizard.utils';
import { styles } from './CandidateCreateWizardStep6.styles';
import { MODEL } from './CandidateCreateWizardStep6.utils';

const CandidateCreateWizardStep6 = ({
  t,
  initialCandidateValues,
  isFormStepSaveButtonDisabled,
  closeAll,
  isCandidateSave,
  history,
  setCurrentStage,
  setIsSnackbarErrorOpen,
  isStep6Open,
  setIsStep6Open,
  setStep6FormValues,
  isOneStageSaved,
  classes,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const myRef = useRef(null);

  const formik = useFormStep6({
    t,
    initialCandidateValues,
    history,
    setCurrentStage,
  });

  useEffect(() => {
    if (isStep6Open) {
      executeScroll(myRef);
    }
  }, [isStep6Open]);

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep6FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />
      <WizardStep
        withBlueBackground={true}
        withSaveButton={true}
        title="candidateCreator:step6.title"
        myRef={myRef}
        isOpen={isStep6Open}
        onClose={() => {
          setIsStep6Open(false);
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep6Open(true);
          executeScroll();
        }}
        disableArrowButton={
          !(isCandidateSave === true && isOneStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isCandidateSave === true && isOneStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => {
          setIsStep6Open(false);
        }}
      >
        <Grid container>
          <Grid container>
            <Grid item>
              <Translate
                style={{ fontSize: '1rem' }}
                text={'candidateCreator:step6.label1'}
              />
            </Grid>
            <Grid item>
              <Translate
                bold
                style={{ fontSize: '1rem', marginTop: 10 }}
                text={'candidateCreator:step6.important'}
              />
            </Grid>
            <Grid item>
              <Translate
                style={{ fontSize: '1rem' }}
                text={'candidateCreator:step6.label2'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
              <Select
                variant={'candidateCreatorStage6'}
                items={[
                  {
                    id: 0,
                    name: t('projectCreator:competenceExperienceLevel.none'),
                  },
                  {
                    id: 2,
                    name: t('projectCreator:competenceExperienceLevel.junior'),
                  },
                  {
                    id: 5,
                    name: t('projectCreator:competenceExperienceLevel.regular'),
                  },
                  {
                    id: 10,
                    name: t('projectCreator:competenceExperienceLevel.senior'),
                  },
                ]}
                label={t('candidateCreator:step6.candidateRatingFromRecruiter')}
                name={MODEL.candidateRatingFromRecruiter}
                formik={formik}
              />
            </Grid>
            <Grid item style={{ marginTop: 10 }}>
              <Translate
                style={{ fontSize: '1rem' }}
                text={'candidateCreator:step6.label3'}
              />
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <TextEditor
                variant={'candidateCreatorStage6'}
                name={MODEL.candidateRatingFromRecruiterDescription}
                formik={formik}
              />
            </Grid>
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default withStyles(styles)(
  withTranslation()(CandidateCreateWizardStep6)
);
