import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { CandidateV2Service } from 'shared/services';
import { uploadAssets } from 'shared/services/assetService';
import { getCurrentUser } from 'shared/services/authService';
import { getCandidate, editCandidate } from 'shared/services/candidateService';
import AssetsUpload from './asset/AssetsUpload';

export class CandidateAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      backPage: PATHS.USER.INDEX,
    };
  }

  uploadAssets = async (files, assetType) => {
    const {
      candidate,
      editCandidate,
      uploadAssets,
      getCurrentUser,
      callback,
      projectId = null,
      sendManual = false,
    } = this.props;
    let { data } = await uploadAssets(files, assetType);
    let candidateAssets = [];
    const callbackAssets = [];
    Array.isArray(data) &&
      data.forEach(file => {
        candidateAssets.push({
          fileName: file.id,
          description: this.props.fileDescription,
        });
        callbackAssets.push(file);
      });
    if (sendManual && callback) {
      callback(callbackAssets);
      return;
    }
    if (this.props.newCandidateUpload) {
      await CandidateV2Service.editCandidate(
        candidate.id,
        {
          assets: candidateAssets,
          projectId: projectId ? projectId : undefined,
        },
        { stage: 'assets' }
      );
    } else {
      await editCandidate({ id: candidate.id, assets: candidateAssets });
    }
    getCurrentUser(candidate.id);
    if (callback) {
      callback(callbackAssets);
    }
  };

  getAssets = async () => {
    const {
      candidate,
      getCandidate,
      assetType,
      clearLoad = false,
    } = this.props;
    if (clearLoad) {
      return [];
    }
    await getCandidate(candidate.id);
    return filter(candidate.assets, { type: assetType });
  };

  backAction = () => {
    this.props.history.push(this.state.backPage);
  };

  render() {
    const {
      candidate: { candidateAssets },
      acceptedFiles,
      assetType,
      filesLimit = 4,
      removeIcon = true,
      onClose,
      disabled = false,
      clearLoad = false,
    } = this.props;
    let candidateAssetsTmp = candidateAssets;
    if (clearLoad) {
      candidateAssetsTmp = [];
    }
    const closeFn = onClose ? onClose : this.backAction;
    return (
      <AssetsUpload
        disabled={disabled}
        removeIcon={removeIcon}
        acceptedFiles={acceptedFiles}
        closeHandler={closeFn}
        uploadAssetsHandler={this.uploadAssets}
        getAssetsHandler={this.getAssets}
        assets={candidateAssetsTmp}
        filesLimit={filesLimit}
        assetType={assetType}
      >
        {this.props.children}
      </AssetsUpload>
    );
  }
}

const mapDispatchToProps = {
  uploadAssets,
  editCandidate,
  getCandidate,
  getCurrentUser,
};

CandidateAssets.propTypes = {
  candidate: PropTypes.object.isRequired,
  uploadAssets: PropTypes.func.isRequired,
  editCandidate: PropTypes.func.isRequired,
  getCandidate: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(withRouter(CandidateAssets));
