import { object } from 'yup';

export const MODEL = {
  userAttachments: 'userAttachments',
  asset: 'asset',
  assetType: 'type',
  fileDescription: 'description',
  candidateAssets: 'candidateAssets',
};

export const initialValues = {};

export const validationSchema = t => object().shape({});
