export const styles = theme => ({
  rootButton: {
    height: 60,
    borderRadius: 30,
    border: `2px solid ${theme.palette.success.main}`,
  },
  rootButtonChange: {
    height: 60,
    borderRadius: 30,
    border: `2px solid ${theme.palette.changeRecruiter.main}`,
  },
  rootDisabledButton: {
    height: 60,
    borderRadius: 30,
    border: `2px solid ${theme.palette.text.secondary}`,
  },
  rootContainedButton: {
    backgroundColor: '#27D38B',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.8,
    },
  },
  fullContainedButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.8,
    },
  },
  text: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    padding: '0 25px',
  },
  textRed: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    padding: '0 25px',
  },
  fullText: {
    color: 'white',
    fontWeight: 'bold',
    padding: '0 25px',
  },
  disabledText: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    padding: '0 25px',
  },
  containedText: { color: '#fff' },
  small: { height: 40 },
});
