import { withStyles } from '@material-ui/core/styles';
import LayoutLeftDrawer from './LayoutLeftDrawer';

const drawerWidth = 280;

const styles = theme => ({
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    background: theme.palette.common.white,
    transition: 'all 0.4s',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    background: theme.palette.jobllegroDarkGrey,
    width: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    marginTop: theme.spacing(9),
  },
  LanguagesChangeWrapper: {
    paddingBottom: '50px',
  },
});

export default withStyles(styles, { withTheme: true })(LayoutLeftDrawer);
