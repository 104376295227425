import { Avatar, Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { Button, Translate } from 'shared/newComponents';
import { editRecruiterOrganization } from 'shared/services/recruiterService';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import { styles } from './YourOrganizations.styles';

const YourOrganizations = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  const userOrganization = recruiterData.organization;
  const avatar = getProjectAssetSrcByType(userOrganization, 'logo');

  const organizationName = userOrganization && userOrganization.name;

  const publicStatus =
    userOrganization && userOrganization.isPublic
      ? t`recruiter:orgPublic`
      : t`recruiter:orgPrivate`;

  const isOwnerOrganization =
    recruiterData && recruiterData.isOrganizationOwner;

  const handleClickRedirectToOrganizationList = () => {
    history.push(PATHS.ORGANIZATION.INDEX);
  };

  const deleteOrganizationRecruiter = async () => {
    props
      .editRecruiterOrganization({
        id: recruiterData.id,
        organizationId: null,
      })
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };
  if (!userOrganization) {
    return (
      <Grid container className={classes.gridTopMargin}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.gridWrapper}>
            <Grid
              container
              className={classes.gridPadding}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'organization:orgYouIn'}
                  bold
                  className={classes.bigFont}
                />
                <Translate
                  text={'organization:joinOrg'}
                  className={classes.mediumFont}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  label={`+ ${t`organization:addToOrg`}`}
                  onClick={handleClickRedirectToOrganizationList}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
  return (
    <Grid container className={classes.gridTopMargin}>
      <Paper className={classes.paperSecondVariantWrapper}>
        <Grid container className={classes.gridWrapper}>
          <Grid container className={classes.gridPadding} alignItems={'center'}>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={`organization:orgYouIn`}
                bold
                className={classes.bigFont}
              />
            </Grid>
            <Grid container className={classes.gridTopMarginSecondVariant}>
              <Grid container className={classes.gridTopMarginSecondVariant}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid
                    container
                    className={classes.avatarWrapper}
                    alignItems={'center'}
                  >
                    <Grid item xs={3} sm={3} md={3}>
                      <Avatar
                        src={avatar}
                        aria-label="Jobllegro"
                        className={classes.avatar}
                      />
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Translate
                            className={classes.bigFont}
                            text={organizationName}
                            bold
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Translate
                            className={classes.mediumFont}
                            text={publicStatus}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {!isOwnerOrganization ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.gridTopMarginSecondVariant}
                  >
                    <Button
                      onClick={deleteOrganizationRecruiter}
                      style={{
                        border: '2px solid red',
                      }}
                      fontColor="red"
                      label={t`organization:leaveOrg`}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  editRecruiterOrganization,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(YourOrganizations))));
