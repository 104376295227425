import { Divider, MenuList, IconButton, Avatar } from '@material-ui/core';
import {
  VpnKey as VpnKeyIcon,
  Dashboard as DashboardIcon,
  PersonAdd as PersonAddIcon,
  SupervisorAccount as SupervisorAccountIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from 'config';
import logotype from 'shared/assets/img/jobllegro_logo_drawer.png';
import LeftDrawerMenuListItem from '../../../../components/LeftDrawerMenuListItem';

const MenuRightDrawerRight = props => {
  const { classes, t } = props;

  const menuElementsTop = [
    {
      name: t('menu:location.homePage'),
      url: PATHS.AUTH.LOGIN,
      icon: DashboardIcon,
    },
  ];

  const menuElements = [
    {
      name: t('menu:action.login'),
      url: PATHS.AUTH.LOGIN,
      icon: VpnKeyIcon,
    },
    {
      name: t('menu:action.registerRecruiterAccount'),
      url: PATHS.AUTH.REGISTER.replace(':type', 'recruiter'),
      icon: PersonAddIcon,
    },
    {
      name: t('menu:action.registerCandidateAccount'),
      url: PATHS.AUTH.REGISTER.replace(':type', 'candidate'),
      icon: SupervisorAccountIcon,
    },
  ];

  const menuTop = menuElementsTop.map((element, key) => {
    let icon = React.createElement(element.icon);
    return (
      <LeftDrawerMenuListItem
        key={key}
        to={element.url}
        name={element.name}
        icon={icon}
        url={element.url}
        badge={false}
      />
    );
  });

  const menu = menuElements.map((element, key) => {
    let icon = React.createElement(element.icon);
    return (
      <LeftDrawerMenuListItem
        key={key}
        to={element.url}
        name={element.name}
        icon={icon}
        url={element.url}
        badge={false}
      />
    );
  });

  return (
    <MenuList className={classes.menuConntent}>
      <div className={classes.drawerHeader}>
        <IconButton style={{ width: 'auto', padding: 0 }}>
          <ChevronRightIcon />
        </IconButton>
        <Avatar className={classes.logotype}>
          <img alt={t('page.title')} src={logotype} />
        </Avatar>
      </div>
      <Divider />
      {menuTop}
      <Divider />
      {menu}
    </MenuList>
  );
};

MenuRightDrawerRight.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MenuRightDrawerRight;
