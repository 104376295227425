import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth/reducer';
import candidates from './candidates/reducer';
import cities from './cities/reducer';
import competenceCategories from './competenceCategories/reducer';
import competenceKinds from './competenceKinds/reducer';
import competenceTemp from './competenceTemp/reducer';
import competenceTypes from './competenceTypes/reducer';
import industries from './industries/reducer';
import industryCategories from './industryCategories/reducer';
import languages from './languages/reducer';
import messages from './messages/reducer';
import nationalities from './nationalities/reducer';
import notifications from './notifications/reducer';
import organizations from './organizations/reducer';
import projects from './projects/reducer';
import recruiters from './recruiters/reducer';
import rightDrawer from './rightDrawer/reducer';
import skills from './skills/reducer';
import stages from './stages/reducer';
import summary from './summary/reducer';
import users from './users/reducer';

const dictionaries = combineReducers({
  cities,
  competenceTemp,
  industries,
  industryCategories,
  nationalities,
  languages,
  skills,
  competenceCategories,
  competenceKinds,
  competenceTypes,
});

export default combineReducers({
  dictionaries,
  summary,
  notifications,
  stages,
  messages,
  rightDrawer,
  auth,
  projects,
  candidates,
  recruiters,
  users,
  organizations,
  form: formReducer,
});
