import { withStyles, IconButton, withWidth } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { PATHS } from 'config';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';

const styles = theme => ({
  button: {},
  addProjectButton: {
    [theme.breakpoints.between('xs', 'sm')]: {
      right: 10,
      bottom: 10,
      position: 'fixed',
      width: 50,
      height: 50,
      background: '#fcc93c',
      color: '#ffffff',
      zIndex: '99999',
    },
  },
});

export class ProjectAdd extends Component {
  render() {
    const { classes, currentUser, width, t } = this.props;

    return (
      currentUser.isRecruiter &&
      (width === 'xs' || width === 'sm' ? (
        <IconButton
          className={classes.addProjectButton}
          component={Link}
          to={PATHS.PROJECT.CREATE}
        >
          <AddIcon style={{ width: 100 }} />
        </IconButton>
      ) : (
        <ButtonJobllegrov1
          className={classes.button}
          component={Link}
          to={PATHS.PROJECT.CREATE}
        >
          {t('project:form.projectCreator')}
        </ButtonJobllegrov1>
      ))
    );
  }
}

ProjectAdd.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default compose(withWidth())(
  connect(
    mapStateToProps,
    null
  )(withStyles(styles)(withTranslation()(ProjectAdd)))
);
