import { API } from '../constants';
import { ApiService } from './api.service';

export const ProjectV2Service = {
  getRecruitmentPayment(id) {
    return ApiService.get(
      API.GET.PROJECT_V2.GET_RECRUITEMNT_PAYMENT.replace(':id', id)
    );
  },
  getProjectLocalizations(id) {
    return ApiService.get(
      API.GET.PROJECT_V2.GET_PROJECT_LOCALIZATIONS.replace(':id', id)
    );
  },
  getProjectAvailableToMove() {
    return ApiService.get(API.GET.PROJECT_V2.GET_PROJECT_AVAILABLE_TO_MOVE);
  },
  getProjectAccommodations(id) {
    return ApiService.get(
      API.GET.PROJECT_V2.GET_PROJECT_ACCOMMODATIONS.replace(':id', id)
    );
  },
  addProjectV2(data, params = {}) {
    return ApiService.post(API.POST.PROJECT_V2.POST, data, params);
  },
  copyProjectV2(id, params = {}) {
    return ApiService.post(API.POST.PROJECT_V2.COPY.replace(':id', id));
  },
  editProjectV2(id, data, params = {}) {
    return ApiService.put(
      API.PUT.PROJECT_V2.PUT.replace(':id', id),
      data,
      params
    );
  },
  editProjectV2Assets(id, data, params = {}) {
    return ApiService.put(
      API.PUT.PROJECT_V2.PUT_ASSETS.replace(':id', id),
      data,
      params
    );
  },
  editProjectV2HiringManager(id, data) {
    return ApiService.put(
      API.PUT.PROJECT_V2.PUT_HIRING_MANAGER.replace(':id', id),
      data
    );
  },
  editProjectV2Mentor(id, data) {
    return ApiService.put(
      API.PUT.PROJECT_V2.PUT_MENTOR.replace(':id', id),
      data
    );
  },
  editProjectV2OrganizationStatus(id, data, params = {}) {
    return ApiService.put(
      API.PUT.PROJECT_V2.PUT_ORGANIZATION_STATUS.replace(':id', id),
      data,
      params
    );
  },
  getProjectV2(id) {
    return ApiService.get(API.GET.PROJECT_V2.GET.replace(':id', id));
  },
  postProjectPublicUrl(projectId) {
    return ApiService.post(
      API.POST.PROJECT_PUBLIC_URL.POST_PROJECT_PUBLIC_URL.replace(
        ':id',
        projectId
      )
    );
  },

  getProjectPublicUrl(projectPublicHash) {
    return ApiService.get(
      API.GET.PROJECT_PUBLIC_URL.GET_PROJECT_PUBLIC_URL.replace(
        ':hash',
        projectPublicHash
      )
    );
  },

  getMostPopularCities() {
    return ApiService.get(API.GET.PROJECT_V2.GET_MOST_POPULAR_CITIES);
  },
};

export default ProjectV2Service;
