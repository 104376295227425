export const styles = () => ({
  bgBlue: { background: '#E5F0FF' },
  container: { padding: '40px 25px' },
  crossingBorderDateDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 35,
  },
  datePicker: { marginLeft: 25 },
  crossingBorderDate: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  crossingBorderDateFilled: { opacity: '70%', fontWeight: 600 },
  editBtn: { cursor: 'pointer' },
  hidden: { visibility: 'hidden' },
  switch: { padding: '25px 25px 0' },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  error: { position: 'absolute', top: '45px', color: '#f41010' },
});
