export const styles = () => ({
  wrapper: {
    padding: '16px 0',
  },
  paper: {
    width: '100%',
  },
  container: {
    padding: 16,
  },
});
