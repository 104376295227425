export const styles = () => ({
  fileSection: { paddingTop: 10, paddingBottom: 5 },
  paper: {
    padding: 20,
    marginBottom: 10,
    borderRadius: 10,
  },
  noGap: { marginBottom: 0 },
  chooseFileBtn: {
    float: 'right',
    width: 100,
    fontWeight: 'bold',
    whiteSpace: 'noWrap',
    borderRadius: 30,
    color: '#01D38B',
    border: '2px solid #01D38B',
    '&:hover': {
      background: '#01D38B',
      color: '#fff',
    },
  },
  bold: { fontWeight: 'bold' },
  fileName: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    fontWeight: 'bold',
  },
  delBtn: {
    float: 'right',
    width: 100,
    border: '2px solid #B7BEC8',
    borderRadius: 30,
    fontWeight: 'bold',
    color: '#B7BEC8',
    whiteSpace: 'noWrap',
    '&:hover': { borderColor: '#F41313', color: '#F41313', background: '#fff' },
  },
  link: { textDecoration: 'none', color: '#359CFF' },
});
