import { createAction } from '@reduxjs/toolkit';
import {
  SET_CURRENT_ORGANIZATION,
  CLEAR_CURRENT_ORGANIZATION,
  SET_CURRENT_ORGANIZATIONS_LIST,
  CLEAR_CURRENT_ORGANIZATIONS_LIST,
} from '../actionConstants';

export const setCurrentOrganizationAction = createAction(
  SET_CURRENT_ORGANIZATION
);
export const clearCurrentOrganizationAction = createAction(
  CLEAR_CURRENT_ORGANIZATION
);
export const setCurrentOrganizationsListAction = createAction(
  SET_CURRENT_ORGANIZATIONS_LIST
);
export const clearCurrentOrganizationsListAction = createAction(
  CLEAR_CURRENT_ORGANIZATIONS_LIST
);
