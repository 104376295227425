import withStyles from '@material-ui/core/es/styles/withStyles';
import { CustomIconButton } from './CustomIconButton';

const styles = () => ({
  deleteIcon: {
    color: '#ee6d59',
  },
  editIcon: {
    color: '#636dde',
  },
});

export default withStyles(styles)(CustomIconButton);
