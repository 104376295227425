import { Typography, withStyles, Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import MailIcon from 'shared/assets/img/icons/project-show/mail.png';
import MobileIcon from 'shared/assets/img/icons/project-show/mobile.png';
import PinIcon from 'shared/assets/img/icons/project-show/pin.svg';
import { styles } from './LocalizationData.styles';

const LocalizationData = ({ classes, selectedLocalization, t }) => {
  if (!selectedLocalization) {
    return null;
  }

  return (
    <Grid container alignItems="center" className={classes.containerMargin}>
      <Grid item md={6}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <img src={PinIcon} alt="city" className={classes.pinIcon} />
          </Grid>
          <Grid item md={6}>
            <Typography variant="body1" className={classes.bold}>
              {t`assign:noData`}
            </Typography>
            <Typography variant="body1" className={classes.bold}>
              {`${selectedLocalization.address}  `}
            </Typography>
            <Typography variant="body1" className={classes.bold}>
              {`${selectedLocalization.postCode}  ${selectedLocalization.city}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={5}>
        <div className={classes.mailDiv}>
          <img src={MailIcon} alt="mail" className={classes.mail} />
          <Typography variant="body1" className={classes.blue}>
            {t`assign:noData`}
          </Typography>
        </div>
        <div className={classes.mobileDiv}>
          <img src={MobileIcon} alt="mobile" className={classes.mobile} />
          <Typography variant="body1" className={classes.blue}>
            {t`assign:noData`}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(LocalizationData));
