import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { setLastPage } from 'shared/services/authService';
import { getSummary, getSummaryClosed } from 'shared/services/summaryService';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';
import SummaryCellComponent from '../modules/summary/components/SummaryCellComponent';
import SummaryRowComponent from '../modules/summary/components/SummaryRowComponent';
import AtsEmptyContainer from './AtsEmptyContainer';
import SubHeader from './SubHeader';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 0,
  },
  paperContainer: {
    paddingTop: 0,
    paddingLeft: 18,
    paddingRight: 18,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  showMore: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 32,
  },
  tableRow: {
    borderTop: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    paddingTop: 0,
    paddingBottom: 0,
    height: '40px',
  },
  emptyAtsContainer: {
    height: '15vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export class SummaryRecruiterViewHead extends React.Component {
  columnDataSrc = [
    {
      id: 'client',
      numeric: false,
      label: 'summary:field.clientAndPosition',
      hiddenSm: 'false',
      hiddenXs: 'false',
    },
    {
      id: 'date',
      numeric: false,
      label: 'summary:field.timeLeft',
      hiddenSm: 'true',
      hiddenXs: 'true',
    },
    {
      id: 'stage1',
      numeric: true,
      label: 'summary:field.stage1Title',
      hiddenSm: 'true',
      hiddenXs: 'true',
    },
    {
      id: 'stage2',
      numeric: true,
      label: 'summary:field.stage2Title',
      hiddenSm: 'true',
      hiddenXs: 'true',
    },
    {
      id: 'stage3',
      numeric: true,
      label: 'summary:field.stage3Title',
      hiddenSm: 'true',
      hiddenXs: 'true',
    },
  ];

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { t, order, orderBy, classes } = this.props;

    let columnData = this.columnDataSrc.map((column, index) => {
      return { ...column, label: t(column.label) };
    });

    return (
      <TableHead>
        <TableRow className={classes.tableRow}>
          {columnData.map((column, index) => (
            <SummaryCellComponent
              index={index}
              key={column.id}
              column={column}
              orderBy={orderBy}
              order={order}
              onClickTrigger={this.createSortHandler(column.id)}
            />
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

SummaryRecruiterViewHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export class DashboardAts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'desc',
      orderBy: 'id',
      page: 0,
      rowsPerPage: 5,
      filterString: '',
      isLoading: true,
      anchorEl: null,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const {
      currentUser: { isOrganizationOwner },
    } = this.props;

    this.reloadSummary({
      [isOrganizationOwner ? 'organizationAll' : this.props.params]: true,
      limit: 5,
    });
    this.reloadSummaryClosed({
      closedStatus: '1,2',
      limit: 5,
      [isOrganizationOwner && 'organizationAll']: true,
    });

    this.interval = setInterval(() => {
      this.props.params
        ? this.reloadSummary({
            [isOrganizationOwner ? 'organizationAll' : this.props.params]: true,
            limit: 5,
          }) &&
          this.reloadSummaryClosed({
            closedStatus: '1,2',
            limit: 5,
            [isOrganizationOwner && 'organizationAll']: true,
          })
        : this.reloadSummary();
    }, 30000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
  }

  reloadSummary = async filter => {
    if (this._isMounted) {
      this.setState({ isLoading: true });
    }
    await this.props.getSummary(filter);
    if (this._isMounted) {
      this.setState({ isLoading: false });
    }
  };

  reloadSummaryClosed = async filter => {
    if (this._isMounted) {
      this.setState({ isLoading: true });
    }
    await this.props.getSummaryClosed(filter);
    if (this._isMounted) {
      this.setState({ isLoading: false });
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, page: 0 });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { t, summaryList = [], summaryListClosed = [], classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const {
      currentUser: { isOrganizationOwner },
    } = this.props;

    return (
      <React.Fragment>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paperContainer} elevation={0}>
                    <SubHeader title={t('dashboard:ats.openedProjects')} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {summaryList.length === 0 ? (
                    <AtsEmptyContainer
                      text={t('dashboard:ats.emptyListOfOpenedProjects')}
                    />
                  ) : (
                    <Table>
                      <SummaryRecruiterViewHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        classes={classes}
                        t={t}
                      />
                      <TableBody>
                        {summaryList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map(n => {
                            return (
                              <SummaryRowComponent
                                key={n.id}
                                data={n}
                                hideColumn={1}
                                dashboardView={true}
                              />
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                </Grid>
                {summaryList.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.showMore}
                  >
                    <ButtonJobllegrov1
                      component={Link}
                      to={
                        isOrganizationOwner
                          ? PATHS.SUMMARY.INDEX.replace(
                              ':type',
                              'organization'
                            ).replace(':filter', 'organizationAll')
                          : PATHS.SUMMARY.INDEX.replace(
                              ':type',
                              'recruiter'
                            ).replace(':filter', 'poAndCoAndMentorAndHm')
                      }
                    >
                      {t('core:general.showAll')}
                    </ButtonJobllegrov1>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paperContainer} elevation={0}>
                    <SubHeader title={t('dashboard:ats.closedProjects')} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {summaryListClosed.length === 0 ? (
                    <AtsEmptyContainer
                      text={t('dashboard:ats.emptyListOfClosedProjects')}
                    />
                  ) : (
                    <Table>
                      <SummaryRecruiterViewHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        classes={classes}
                        t={t}
                      />
                      <TableBody>
                        {summaryListClosed
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map(n => {
                            return (
                              <SummaryRowComponent
                                style={{ opacity: '0.7' }}
                                key={n.id}
                                data={n}
                                hideColumn={1}
                                projectIsClosed={true}
                                dashboardView={true}
                              />
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                </Grid>
                {summaryListClosed.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.showMore}
                  >
                    <ButtonJobllegrov1
                      component={Link}
                      to={
                        isOrganizationOwner
                          ? PATHS.SUMMARY.INDEX.replace(
                              ':type',
                              'organization'
                            ).replace(':filter', 'closedStatus')
                          : PATHS.SUMMARY.INDEX.replace(
                              ':type',
                              'recruiter'
                            ).replace(':filter', 'closedStatus')
                      }
                    >
                      {t('core:general.showAll')}
                    </ButtonJobllegrov1>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    summaryList: store.summary.list,
    summaryListClosed: store.summary.listClosed,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getSummary,
  getSummaryClosed,
  setLastPage,
};

DashboardAts.propTypes = {
  summaryList: PropTypes.array.isRequired,
  getSummary: PropTypes.func.isRequired,
  getSummaryClosed: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DashboardAts))
);
