import { Grid, LinearProgress } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import Auhorized from 'shared/newComponents/Auhorized/Auhorized';
import Snackbar from 'shared/newComponents/Snackbar';
import {
  EmploymentManagementHistory,
  getProject,
  ProjectV2Service,
} from 'shared/services';
import EmploymentManagementViewContent from './components/EmploymentManagementViewContent';

const EmploymentManagementView = ({ match, t, history, getProject }) => {
  const [projectDetails, setProjectDetails] = useState({});
  const [
    projectCandidatesEmploymentManagement,
    setProjectCandidatesEmploymentManagement,
  ] = useState([]);

  const [accommodations, setAccommodations] = useState([]);
  const [projectAvailableToMove, setProjectAvailableToMove] = useState([]);

  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const id = get(match, 'params.id');

  const redirectToDashboard = () => {
    history.push(PATHS.DASHBOARD.INDEX);
  };

  useEffect(() => {
    if (!!id) {
      ProjectV2Service.getProjectV2(id)
        .then(({ data }) => {
          getProject(id);
          setProjectDetails(data);
          loadAccommodations();
          loadProjectAvailableToMove();
          loadProjectCandidatesEmploymentManagement(data);
        })
        .catch(error => {
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              setIsSnackbarErrorOpen(true);
              setTimeout(redirectToDashboard, 2000);
            }
          }
          console.log(error);
        });
    }
  }, [id]);

  const loadProjectCandidatesEmploymentManagement = () => {
    setIsLoading(true);
    EmploymentManagementHistory.getEmploymentManagementHistory(id)
      .then(({ data }) => {
        setProjectCandidatesEmploymentManagement(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const loadAccommodations = () => {
    ProjectV2Service.getProjectAccommodations(id)
      .then(({ data }) => {
        setAccommodations(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const loadProjectAvailableToMove = () => {
    ProjectV2Service.getProjectAvailableToMove()
      .then(({ data }) => {
        setProjectAvailableToMove(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const shouldLoadAccommodations = () => {
    loadAccommodations();
  };

  const { permissions = {} } = projectDetails;

  return isEmpty(projectDetails) ? (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
      <Snackbar
        type={'error'}
        open={isSnackbarErrorOpen}
        onClose={() => setIsSnackbarErrorOpen(false)}
        label={t('projectCreator:core.projectRejected')}
      />
    </Grid>
  ) : (
    <>
      <Auhorized
        permissions={permissions}
        roles={[
          'isOwner',
          'isMentor',
          'isSuperUser',
          'isHiringManager',
          'isProjectOrganizationOwner',
        ]}
      />
      <EmploymentManagementViewContent
        projectAvailableToMove={projectAvailableToMove}
        project={projectDetails}
        projectCandidatesEmploymentManagement={
          projectCandidatesEmploymentManagement
        }
        loadProjectCandidatesEmploymentManagement={
          loadProjectCandidatesEmploymentManagement
        }
        shouldLoadAccommodations={shouldLoadAccommodations}
        accommodations={accommodations}
        isLoading={isLoading}
      />
    </>
  );
};

const mapDispatchToProps = {
  getProject,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(EmploymentManagementView)));
