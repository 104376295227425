import { withStyles } from '@material-ui/core/styles';
import AdminOrganizationView from './AdminOrganizationView';

const styles = theme => ({
  root: {
    padding: '0 20px',
    margin: '0 auto',
  },
  formControl: {
    flexDirection: 'row',
    marginRight: 15,
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
    },
  },
});

export default withStyles(styles)(AdminOrganizationView);
