import { AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  label: {
    textTransform: 'capitalize',
    fontSize: '0.940rem',
    justifyContent: 'left',
  },
  AppBar: {
    marginTop: 16,
    background: '#fff',
    borderRadius: '10px 10px 0px 0px',
    width: '50%',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
});

export const PageHeaderTitleComponent = ({ title, classes }) => (
  <AppBar position="static" color="default" className={classes.AppBar}>
    <Toolbar>
      <Typography variant="h5">{title}</Typography>
    </Toolbar>
  </AppBar>
);

PageHeaderTitleComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(PageHeaderTitleComponent);
