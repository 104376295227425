import {
  Grid,
  Paper,
  Typography,
  Button,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getCurrentUser } from 'shared/services/authService';
import {
  createOrganization,
  getOrganization,
  clearCurrentOrganization,
} from 'shared/services/organizationService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import {
  getOrganizationSubscriptionType,
  getSettlementCurrencyType,
} from 'shared/utils/date';
import OrganizationCloseButton from './OrganizationCloseButton';
import OrganizationForm from './OrganizationForm';
import PageHeader from './PageHeader';
import RecruiterOrganizationPanelTopCard from './RecruiterOrganizationPanelTopCard';
import RecruitersMenu from './RecruitersMenu';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  rootPaperDescription: {
    paddingTop: 32,
    paddingBottom: 64,
    paddingLeft: 64,
    paddingRight: 64,
    marginTop: 0,
    background: 'transparent',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  root: {
    marginTop: 8,
  },
  textDescription: {
    fontSize: '16px',
    color: '#000',
    paddingBottom: 32,
  },
  textHeading: {
    paddingTop: 0,
    paddingBottom: 8,
    color: '#53a3fc',
    fontSize: '24px',
  },
});

export class RecruiterOrganizationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const {
      currentUser: { organization = false },
      getOrganization,
      clearCurrentOrganization,
    } = this.props;
    clearCurrentOrganization();
    organization && (await getOrganization(organization.id));
    this.setState({ isLoading: false });
  }

  handleSubmit = async () => {
    getCurrentUser();
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, t, currentUser, organization = {} } = this.props;
    const { isLoading, anchorEl } = this.state;

    let checkIfUserIsOrganizationOwner = false;
    if (currentUser.organization) {
      const { owner = {} } = currentUser.organization;
      if (currentUser.id === owner) {
        checkIfUserIsOrganizationOwner = true;
      }
    }
    return (
      <React.Fragment>
        <PageHeader title={t`recruiter:menu:organization`} />
        <Grid
          container
          alignItems="flex-start"
          spacing={0}
          className={classes.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <React.Fragment>
                <RecruitersMenu
                  handleClick={this.handleClick}
                  handleClose={this.handleClose}
                  anchorEl={anchorEl}
                  itemSelected="organization"
                />
                <Paper className={classes.rootPaper} elevation={0}>
                  <Grid container alignItems="flex-start" spacing={0}>
                    {currentUser && currentUser.organization !== null ? (
                      <React.Fragment>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ padding: '0px' }}
                        >
                          <RecruiterOrganizationPanelTopCard />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ padding: '0px' }}
                        >
                          <Paper
                            className={classes.rootPaperDescription}
                            elevation={0}
                          >
                            <Typography
                              variant="h5"
                              component="h3"
                              gutterBottom
                              className={classes.textHeading}
                            >
                              {t('organization:field.descriptionLong')}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.textDescription}
                            >
                              {organization.description}
                            </Typography>
                            <Typography
                              variant="h5"
                              component="h3"
                              gutterBottom
                              className={classes.textHeading}
                            >
                              {t('organization:field.currentSubscription')}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.textDescription}
                            >
                              {getOrganizationSubscriptionType(
                                organization.currentSubscriptionType
                              )}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.textDescription}
                            >
                              {' '}
                              {t`recruiter:subValue`}{' '}
                              {organization.currentSubscriptionValueDisabled
                                ? t`recruiter:notApplicable`
                                : organization.currentSubscriptionValue}
                            </Typography>
                            {organization.individualFinicalPlan === true && (
                              <div>
                                <Typography
                                  variant="h5"
                                  component="h3"
                                  gutterBottom
                                  className={classes.textHeading}
                                >
                                  {t`recruiter:plan`}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.textDescription}
                                >
                                  {t('organization:field.settlementCurrency')}:{' '}
                                  {organization.settlementCurrencyDisabled
                                    ? t`recruiter:notApplicable`
                                    : getSettlementCurrencyType(
                                        organization.settlementCurrency
                                      )}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.textDescription}
                                >
                                  {t('organization:field.publicationValue')}:{' '}
                                  {organization.publicationValueDisabled
                                    ? t`recruiter:notApplicable`
                                    : organization.publicationValue}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.textDescription}
                                >
                                  {t('organization:field.meetingsValue')}:{' '}
                                  {organization.meetingsValueDisabled
                                    ? t`recruiter:notApplicable`
                                    : organization.meetingsValue}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.textDescription}
                                >
                                  {t('organization:field.hiringProc')}:{' '}
                                  {organization.hiringProcDisabled
                                    ? t`recruiter:notApplicable`
                                    : `${organization.hiringProc} %`}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.textDescription}
                                >
                                  {t('organization:field.mentorValue')}:{' '}
                                  {organization.mentorValueDisabled
                                    ? t`recruiter:notApplicable`
                                    : organization.mentorValue}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.textDescription}
                                >
                                  {t('organization:field.warrantyValue')}:{' '}
                                  {organization.warrantyValueDisabled
                                    ? t`recruiter:notApplicable`
                                    : organization.warrantyValue}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.textDescription}
                                >
                                  {t`recruiter:obligatoryDate`}:{' '}
                                  {organization.dateStart} -{' '}
                                  {organization.dateStart}
                                </Typography>
                              </div>
                            )}

                            <Typography
                              variant="h5"
                              component="h3"
                              gutterBottom
                              className={classes.textHeading}
                            >
                              {t('organization:form.youAreInOrganization')}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.textDescription}
                            >
                              {organization.name}
                            </Typography>
                            <Typography
                              component="div"
                              className={classes.textDescription}
                            >
                              {checkIfUserIsOrganizationOwner && (
                                <React.Fragment>
                                  <Button
                                    component={Link}
                                    to={PATHS.RECRUITER.ORGANIZATION.EDIT}
                                    color="primary"
                                    variant="contained"
                                  >
                                    {t(
                                      'organization:form.editOrganizationData'
                                    )}
                                  </Button>
                                  {/*<OrganizationAssets*/}
                                  {/*  filesLimit={1}*/}
                                  {/*  assetType="logo"*/}
                                  {/*  acceptedFiles={["image/*"]}*/}
                                  {/*>*/}
                                  {/*  <Button*/}
                                  {/*    style={{ marginTop: 20 }}*/}
                                  {/*    color="default"*/}
                                  {/*    variant="contained"*/}
                                  {/*  >*/}
                                  {/*    {t(*/}
                                  {/*      "organization:form.editOrganizationLogotype"*/}
                                  {/*    )}*/}
                                  {/*  </Button>*/}
                                  {/*</OrganizationAssets>*/}
                                  <br />
                                  <OrganizationCloseButton
                                    organization={organization}
                                  />
                                </React.Fragment>
                              )}
                            </Typography>
                          </Paper>
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ padding: '0px' }}
                      >
                        <Paper
                          className={classes.rootPaperDescription}
                          elevation={0}
                        >
                          <OrganizationForm
                            organization={
                              organization.name ? organization : null
                            }
                          />
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

RecruiterOrganizationPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getCurrentUser,
  createOrganization,
  getOrganization,
  clearCurrentOrganization,
  showSnackbarMessage,
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    organization: state.organizations.currentOrganization,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(RecruiterOrganizationPanel)));
