import {
  Typography,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { enums } from 'shared/enums';
import Checkbox from 'shared/newComponents/Checkbox';
import MultiSelect from 'shared/newComponents/MultiSelect';
import TextField from 'shared/newComponents/TextField';
import { ProjectV2Service } from 'shared/services';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import { castLocalizationObjectForSelect } from 'shared/utils/functions';
import CustomDialog from '../../../../../components/CustomDialog';
import ProjectOrderLocalization from '../../../../project/NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalization';
import ProjectOrderLocalizationForm from '../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalizationForm/ProjectOrderLocalizationForm';
import { styles } from './EmploymentManagementAddLocation.styles';

const EmploymentManagementAddLocation = ({
  isOpen,
  onClose,
  classes,
  t,
  project,
  projectOrder,
  projectCandidateEmploymentManagement,
  loadProjectCandidatesEmploymentManagement,
  clearSelected,
}) => {
  const MODEL = {
    projectOderLocalization: 'projectOderLocalization',
    address: 'address',
    postCode: 'postCode',
    city: 'city',
  };

  const formData = {
    projectOderLocalization: projectOrder ? projectOrder.localization : '',
    address:
      projectOrder && projectOrder.localization
        ? projectOrder.localization.address
        : '',
    postCode:
      projectOrder && projectOrder.localization
        ? projectOrder.localization.postCode
        : '',
    city:
      projectOrder && projectOrder.localization
        ? projectOrder.localization.city
        : '',
    isRemoteWork:
      projectOrder && projectOrder.localization
        ? projectOrder.localization.isRemoteWork
        : false,
    isHybridWork:
      projectOrder && projectOrder.localization
        ? projectOrder.localization.isHybridWork
        : false,
  };

  const [isOpenProjectOrderLocalization, setIsOpenProjectOrderLocalization] =
    useState(false);

  const [projectOrderLocalizations, setProjectOrderLocalizations] = useState([
    [],
  ]);

  const getProjectOrderLocations = () => {
    ProjectV2Service.getProjectLocalizations(project.id)
      .then(({ data }) => {
        setProjectOrderLocalizations(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const validationSchema = yup.object().shape({
    projectOderLocalization: yup.object().shape({
      id: yup
        .number()
        .required()
        .required(t('projectCreator:core.requiredField')),
    }),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({ projectOderLocalization }) => {
      const body = {
        localization: projectOderLocalization.id,
        employmentManagementHistories: [
          {
            candidate: {
              id: projectCandidateEmploymentManagement.candidate.id,
            },
            projectCandidate: {
              id: projectCandidateEmploymentManagement.projectCandidate.id,
            },
          },
        ],
      };

      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_LOCALIZATION
      )
        .then(() => {
          loadProjectCandidatesEmploymentManagement();
          clearSelected();
        })
        .catch(error => console.log(error));
    },
  });

  const resetLocationForm = () => {
    formik.setFieldValue(MODEL.address, '');
    formik.setFieldValue(MODEL.postCode, '');
    formik.setFieldValue(MODEL.city, '');
    formik.setFieldValue(MODEL.isRemoteWork, false);
    formik.setFieldValue(MODEL.isHybridWork, false);
  };

  useEffect(() => {
    const currentSelectValue = formik.values[MODEL.projectOderLocalization];
    const localization = projectOrderLocalizations.find(
      item => item.id === currentSelectValue.id
    );

    resetLocationForm();
    if (localization) {
      formik.setFieldValue(MODEL.address, localization.address);
      formik.setFieldValue(MODEL.postCode, localization.postCode);
      formik.setFieldValue(MODEL.city, localization.city);
      formik.setFieldValue(MODEL.isRemoteWork, !!localization.isRemoteWork);
      formik.setFieldValue(MODEL.isHybridWork, !!localization.isHybridWork);
    }
  }, [formik.values[MODEL.projectOderLocalization], projectOrderLocalizations]);

  useEffect(() => {
    getProjectOrderLocations();
  }, [projectOrder]);

  const handleOpenAddLocalization = () => {
    setIsOpenProjectOrderLocalization(true);
  };

  const handleCloseAddLocalization = () => {
    setIsOpenProjectOrderLocalization(false);
  };

  const locationAdded = () => {
    getProjectOrderLocations();
    handleCloseAddLocalization();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component="div"
          variant="h6"
        >{t`employmentStatusTable:addLocationDialogTitle`}</Typography>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <ProjectOrderLocalization
            project={project}
            handleOpenAddLocalization={handleOpenAddLocalization}
          />
          <Typography
            component="div"
            variant="h6"
            className={classes.location}
          >{t`modifyOrderDialog:employmentLocation`}</Typography>
          <MultiSelect
            formik={formik}
            name={MODEL.projectOderLocalization}
            label={t`modifyOrderDialog:employmentLocation`}
            options={castLocalizationObjectForSelect(projectOrderLocalizations)}
          />
          <div className={classes.txtFields}>
            <div className={classes.grow}>
              <TextField
                disabled={true}
                formik={formik}
                label={t`modifyOrderDialog:street`}
                name={MODEL.address}
              />
            </div>
          </div>
          <div className={classes.txtFields}>
            <div className={classes.homeNumber}>
              <TextField
                disabled={true}
                formik={formik}
                name={MODEL.postCode}
                label={t`modifyOrderDialog:postalCode`}
              />
            </div>
            <div className={classes.grow}>
              <TextField
                disabled={true}
                formik={formik}
                name={MODEL.city}
                label={t`modifyOrderDialog:city`}
              />
            </div>
          </div>
          <div className={classes.checkboxes}>
            <Checkbox
              formik={formik}
              disabled={true}
              name={MODEL.isRemoteWork}
              label={t`modifyOrderDialog:openToRemote`}
            />
            <Checkbox
              formik={formik}
              disabled={true}
              name={MODEL.isHybridWork}
              label={t`modifyOrderDialog:openToHybrid`}
            />
          </div>
        </DialogContent>
        <Button type="submit" className={classes.saveBtn}>
          {t`modifyOrderDialog:saveBtn`}
        </Button>
      </form>
      <CustomDialog
        maxWidth={'md'}
        title={''}
        onCloseDialog={handleCloseAddLocalization}
        body={
          <ProjectOrderLocalizationForm
            project={project}
            localizationAdded={locationAdded}
          />
        }
        isOpen={isOpenProjectOrderLocalization}
      />
    </Dialog>
  );
};

export default withStyles(styles)(
  withTranslation()(EmploymentManagementAddLocation)
);
