import { Grid } from '@material-ui/core';
import { isNil, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router-dom';
import EmploymentIcon from 'shared/assets/img/icons/project-creator/employment.png';
import MeetingIcon from 'shared/assets/img/icons/project-creator/meeting.png';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { getEnumItemName } from 'shared/utils/assets';
import ProjectStepper from '../../../../../../../components/ProjectStepper';
import {
  BillingCard,
  FinicalPlanContainer,
} from '../../../../../NewProjectCreateView/components/ProjectCreateWizardStep8/components';
import { Card } from './components';

const RecruiterInformations = ({
  projectDetails,
  calcCandidateExperience,
  calcServiceCompletionDate,
  calcPaymentDeadline,
  calcCandidateWarranty,
  hintForRecruiter,
  currentUser,
  match,
}) => {
  const [finicalPlan, setFinicalPlan] = useState({});

  useEffect(() => {
    const id = match.params.id;
    if (!!id) {
      setFinicalPlan(projectDetails);
    }
  }, [get(projectDetails, 'organizationToBe')]);

  const revenueSubmitted = get(projectDetails, 'revenueSubmitted');
  const revenueWinner = get(projectDetails, 'revenueWinner');
  const mentorFee = get(projectDetails, 'mentorFee');
  const projectStartDate = get(projectDetails, 'projectStartDate');
  const projectEndDate = get(projectDetails, 'projectEndDate');
  const warrantyOrderValue =
    !!get(projectDetails, 'warrantyOrderValue') || false;
  const mentorOrderValue = !!get(projectDetails, 'mentorOrderValue') || false;
  const isDraft = get(projectDetails, 'isDraft');
  const organization = get(projectDetails, 'organizationToBe');

  const organizationEmail = get(
    projectDetails,
    'organizationToBe.warrantyOrderValue'
  );
  const companyName = get(
    projectDetails,
    'organizationToBe.invoiceCompanyName'
  );
  const invoiceAddress = get(projectDetails, 'organizationToBe.invoiceAddress');
  const invoiceIdentificationNumber = get(
    projectDetails,
    'organizationToBe.invoiceIdentificationNumber'
  );

  const showProjectRules =
    !isNil(calcCandidateExperience) ||
    !isNil(calcServiceCompletionDate) ||
    !isNil(calcPaymentDeadline) ||
    !isNil(calcCandidateWarranty);

  const showInvoiceSection =
    projectDetails.permissions.isSuperUser ||
    projectDetails.permissions.isOwner ||
    projectDetails.permissions.isHiringManagerToBe ||
    projectDetails.permissions.isHiringManager;

  const showRecruitmentSection =
    (projectDetails.permissions.isSuperUser ||
      projectDetails.permissions.isOwner ||
      projectDetails.permissions.isMentor ||
      currentUser.isRecruiter ||
      projectDetails.permissions.isMentorToBe) &&
    !projectDetails.permissions.isHiringManagerToBe &&
    !projectDetails.permissions.isHiringManager;

  const showMentorSection =
    projectDetails.permissions.isSuperUser ||
    projectDetails.permissions.isOwner ||
    projectDetails.permissions.isMentor ||
    projectDetails.permissions.isMentorToBe;

  if (!currentUser.isRecruiter) {
    return null;
  }
  return (
    <Grid>
      <Grid container style={{ padding: 30 }}>
        <Grid container style={{ paddingBottom: 8 }}>
          <Translate
            text="projectDetails:recruiterInformation.title"
            bold
            variant="h6"
            style={{ fontSize: 26 }}
          />
        </Grid>
        {showProjectRules && (
          <>
            <Grid container>
              <Translate
                text="projectDetails:recruiterInformation.projectRule"
                bold
                variant="body1"
                style={{ paddingBottom: 8, fontSize: 18 }}
              />
            </Grid>
            <Grid container style={{ paddingBottom: 24 }}>
              {!isNil(calcCandidateExperience) && (
                <Grid container>
                  <Translate
                    text="projectDetails:recruiterInformation.calcCandidateExperience"
                    variant="body1"
                  />
                  <Translate text="&nbsp;-&nbsp;" variant="body1" />
                  <Translate
                    text={getEnumItemName(
                      enums.CALC_CANDIDATE_EXPERIENCE,
                      calcCandidateExperience
                    )}
                    variant="body1"
                  />
                </Grid>
              )}
              {!isNil(calcServiceCompletionDate) && (
                <Grid container>
                  <Translate
                    text="projectDetails:recruiterInformation.calcServiceCompletionDate"
                    variant="body1"
                  />
                  <Translate text="&nbsp;-&nbsp;" variant="body1" />

                  <Translate
                    text={getEnumItemName(
                      enums.CALC_SERVICE_COMPLETION_DATE,
                      calcServiceCompletionDate
                    )}
                    variant="body1"
                  />
                </Grid>
              )}
              {!isNil(calcPaymentDeadline) && (
                <Grid container>
                  <Translate
                    text="projectDetails:recruiterInformation.calcPaymentDeadline"
                    variant="body1"
                  />
                  <Translate text="&nbsp;-&nbsp;" variant="body1" />

                  <Translate
                    text={getEnumItemName(
                      enums.CALC_PAYMENT_DEADLINE,
                      calcPaymentDeadline
                    )}
                    variant="body1"
                  />
                </Grid>
              )}
              {!isNil(calcCandidateWarranty) && (
                <Grid container>
                  <Translate
                    text="projectDetails:recruiterInformation.calcCandidateWarranty"
                    variant="body1"
                  />
                  <Translate text="&nbsp;-&nbsp;" variant="body1" />

                  <Translate
                    text={getEnumItemName(
                      enums.CALC_CANDIDATE_WARRANTY,
                      calcCandidateWarranty
                    )}
                    variant="body1"
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
        {hintForRecruiter && (
          <>
            <Grid container>
              <Translate
                text="projectDetails:recruiterInformation.hintForRecruiter"
                variant="body1"
                bold
                style={{ fontSize: 18 }}
              />
            </Grid>
            <Grid container>{ReactHtmlParser(hintForRecruiter)}</Grid>
          </>
        )}
        {!isNil(calcCandidateWarranty) && calcCandidateWarranty !== 0 && (
          <>
            <Grid container>
              <Translate
                text="projectDetails:recruiterInformation.warrantyHeader"
                variant="body1"
                bold
                style={{ fontSize: 18 }}
              />
            </Grid>
            <Grid container>
              <Translate
                text={
                  calcCandidateWarranty === 1
                    ? 'projectDetails:recruiterInformation.warrantyDesc1Month'
                    : 'projectDetails:recruiterInformation.warrantyDesc3Month'
                }
                variant="body1"
                style={{ fontSize: 18 }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: '#f1f7ff',
        }}
      >
        {projectStartDate && projectEndDate ? (
          <Grid container style={{ padding: 30 }}>
            <Grid container>
              <Translate
                text="projectDetails:recruiterInformation.projectSchedule"
                bold
                variant="body1"
                style={{ fontSize: 18 }}
              />
            </Grid>
            <Grid container style={{ marginTop: '1%', alignItems: 'center' }}>
              <Translate
                text="projectDetails:recruiterInformation.projectStartDate"
                variant="body1"
              />
              <Translate text="&nbsp;-&nbsp;" variant="body1" />
              <Translate bold text={projectStartDate} variant="body1" />
              <Grid
                container
                style={{
                  marginRight: '-57%',
                  marginLeft: '10%',
                }}
              >
                <ProjectStepper
                  startDate={projectStartDate}
                  endDate={projectEndDate}
                  variantMini={true}
                />
              </Grid>
              <Translate
                text="projectDetails:recruiterInformation.projectEndDate"
                variant="body1"
              />
              <Translate text="&nbsp;-&nbsp;" variant="body1" />
              <Translate bold text={projectEndDate} variant="body1" />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {showRecruitmentSection ? (
        <Grid container style={{ padding: 30 }}>
          <Grid container style={{ paddingBottom: 16 }}>
            <Translate
              text="projectDetails:recruiterInformation.recruiterSalary"
              bold
              variant="body1"
              style={{ fontSize: 18 }}
            />
          </Grid>
          <Grid container spacing={2} style={{ paddingBottom: 16 }}>
            <Grid item xs={12} sm={6}>
              <Card
                name="projectDetails:recruiterInformation.meetingsValue"
                icon={MeetingIcon}
                price={revenueSubmitted ? revenueSubmitted.toString() : null}
                subtext="projectDetails:recruiterInformation.meetingsValueDesc"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                name="projectDetails:recruiterInformation.hiringValue"
                icon={EmploymentIcon}
                price={revenueWinner ? revenueWinner.toString() : null}
                subtext="projectDetails:recruiterInformation.hiringValueDesc"
              />
            </Grid>
          </Grid>
          {mentorFee && showMentorSection ? (
            <Grid item xs={12} sm={6}>
              <Card
                name="projectDetails:recruiterInformation.mentorValue"
                icon={MeetingIcon}
                price={mentorFee.toString()}
                subtext="projectDetails:recruiterInformation.mentorValueDesc"
              />
            </Grid>
          ) : null}

          <Grid container style={{ paddingBottom: 8 }}>
            <Translate
              text="projectDetails:recruiterInformation.footerOne"
              variant="body1"
            />
          </Grid>
          <Grid container>
            <Translate
              text="projectDetails:recruiterInformation.footerTwo"
              variant="body1"
            />
          </Grid>
        </Grid>
      ) : null}
      {organization && showInvoiceSection ? (
        <Grid container style={{ padding: 30 }}>
          <Grid container>
            <BillingCard
              shortInformation={true}
              organizationEmail={organizationEmail}
              companyName={companyName}
              address={invoiceAddress}
              taxId={invoiceIdentificationNumber}
            />
          </Grid>
          <FinicalPlanContainer
            onlyView={true}
            selectWarrantyOrderValue={warrantyOrderValue}
            selectMentorOrderValue={mentorOrderValue}
            finicalPlan={finicalPlan}
            finicalPlanFetchStatus={false}
            isDraft={isDraft}
            organization={organization}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withRouter(RecruiterInformations);
