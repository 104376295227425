import React from 'react';
import addIcon from 'shared/assets/img/icons/project-show/add.svg';
import { Translate } from '../index';

const ButtonAddMentorOrHiringManager = ({ handleClickOpen }) => {
  return (
    <div
      onClick={handleClickOpen}
      style={{
        display: 'flex',
        justifyContent: 'left',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: 'flex',
          marginRight: 10,
          alignItems: 'center',
        }}
      >
        <img alt="addIcon" src={addIcon} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Translate
          style={{ fontSize: 22, color: '#01D38B' }}
          text={'projectDetails:assignCandidateToProjectButton.assign'}
          variant="body1"
          bold
        />
      </div>
    </div>
  );
};
export default ButtonAddMentorOrHiringManager;
