import {
  Dialog,
  DialogTitle,
  withStyles,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { number, object } from 'yup';
import { AccommodationService } from 'shared/services';
import { styles } from './AwaitingEmploymentLocationForm.styles';
import AddPics from './components/AddPics';
import AdditionalInfo from './components/AdditionalInfo';
import ContractData from './components/ContactData';
import FloorPlan from './components/FloorPlan';
import Location from './components/Location';

const AwaitingEmploymentLocationForm = ({
  t,
  classes,
  isOpen,
  onClose,
  project,
  accommodation,
  emitValuesOnSubmit,
  hideContractData,
  hideAddPhoto,
}) => {
  const formData = {
    assets: accommodation ? accommodation.assets : [],
    name: accommodation ? accommodation.name : '',
    address: accommodation ? accommodation.address : '',
    postCode: accommodation ? accommodation.postCode : '',
    city: accommodation ? accommodation.city : '',
    email: accommodation ? accommodation.email : '',
    prefixPhone: accommodation
      ? {
          id: accommodation.prefixPhone,
          value: accommodation.prefixPhone,
          label: accommodation.prefixPhone,
        }
      : { id: '+48', value: '+48', label: '+48' },
    phone: accommodation ? accommodation.phone : '',
    locationType: accommodation ? accommodation.locationType : '',
    locationStandard: accommodation ? accommodation.locationStandard : '',
    isAccessToPublicTransport: accommodation
      ? accommodation.isAccessToPublicTransport
      : false,
    isAccessToStore: accommodation ? accommodation.isAccessToStore : false,
    twoPersonRoom: accommodation ? accommodation.twoPersonRoom : 0,
    triplePersonRoom: accommodation ? accommodation.triplePersonRoom : 0,
    fourPersonRoom: accommodation ? accommodation.fourPersonRoom : 0,
    fivePersonRoom: accommodation ? accommodation.fivePersonRoom : 0,
    roomKitchen: accommodation ? accommodation.roomKitchen : 0,
    roomBathroom: accommodation ? accommodation.roomBathroom : 0,
    roomLivingRoom: accommodation ? accommodation.roomLivingRoom : 0,
    roomLaundry: accommodation ? accommodation.roomLaundry : 0,
    contractStartDate: accommodation ? accommodation.contractStartDate : '',
    contractEndDate: accommodation ? accommodation.contractEndDate : '',
    terminationType: accommodation ? accommodation.terminationType : '',
    price: accommodation ? accommodation.price : '',
    currency: accommodation ? accommodation.currency : '',
    terminationDayNumber: accommodation
      ? accommodation.terminationDayNumber
      : '',
    type: accommodation ? accommodation.type : '',
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required(t`ats2ats3:validation.isRequired`),
    address: yup
      .string()
      .trim()
      .required(t`ats2ats3:validation.isRequired`),
    postCode: yup
      .string()
      .length(
        6,
        t`employmentManagementDialog:dialog2.validation.postalCode.length`
      )
      .matches(
        /\d{2}-\d{3}/,
        t`employmentManagementDialog:dialog2.validation.postalCode.badFormat`
      )
      .required(t`ats2ats3:validation.isRequired`),
    city: yup
      .string()
      .trim()
      .required(t`ats2ats3:validation.isRequired`),
    email: yup
      .string()
      .email(t`employmentManagementDialog:dialog2.validation.email`)
      .required(t`ats2ats3:validation.isRequired`),
    prefixPhone: object()
      .shape({
        id: number()
          .nullable()
          .required(t('projectCreator:core.requiredField')),
      })
      .nullable()
      .required(t('projectCreator:core.requiredField')),
    phone: yup
      .string()
      .matches(
        phoneRegExp,
        t`employmentManagementDialog:dialog2.validation.phone`
      )
      .required(t`ats2ats3:validation.isRequired`),
    locationType: yup.number().required(t`ats2ats3:validation.isRequired`),
    locationStandard: yup.number().required(t`ats2ats3:validation.isRequired`),
    isAccessToPublicTransport: yup
      .bool()
      .required(t`ats2ats3:validation.isRequired`),
    isAccessToStore: yup.bool().required(t`ats2ats3:validation.isRequired`),
    twoPersonRoom: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    triplePersonRoom: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    fourPersonRoom: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    fivePersonRoom: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    roomKitchen: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    roomBathroom: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    roomLivingRoom: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    roomLaundry: yup
      .number()
      .min(0, t`ats2ats3:validation.mustByPositive`)
      .required(t`ats2ats3:validation.isRequired`),
    ...(hideContractData
      ? {}
      : {
          contractStartDate: yup
            .string()
            .required(t`ats2ats3:validation.isRequired`),
          contractEndDate: yup
            .string()
            .required(t`ats2ats3:validation.isRequired`),
          terminationType: yup
            .number()
            .required(t`ats2ats3:validation.isRequired`),
          price: yup
            .number()
            .min(0, t`ats2ats3:validation.mustByPositive`)
            .required(t`ats2ats3:validation.isRequired`),
          currency: yup.number().required(t`ats2ats3:validation.isRequired`),
          terminationDayNumber: yup
            .number()
            .min(0, t`ats2ats3:validation.mustByPositive`)
            .required(t`ats2ats3:validation.isRequired`),
          type: yup.number().required(t`ats2ats3:validation.isRequired`),
        }),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({
      name,
      address,
      postCode,
      city,
      email,
      prefixPhone,
      phone,
      locationType,
      locationStandard,
      isAccessToPublicTransport,
      isAccessToStore,
      twoPersonRoom,
      triplePersonRoom,
      fourPersonRoom,
      fivePersonRoom,
      roomKitchen,
      roomBathroom,
      roomLivingRoom,
      roomLaundry,
      contractStartDate,
      contractEndDate,
      terminationType,
      price,
      currency,
      terminationDayNumber,
      type,
      assets,
    }) => {
      const body = {
        name,
        address,
        organization: project ? project.organization : '',
        postCode,
        city,
        email,
        prefixPhone: prefixPhone.value,
        phone,
        locationType,
        locationStandard,
        isAccessToPublicTransport,
        isAccessToStore,
        twoPersonRoom,
        triplePersonRoom,
        fourPersonRoom,
        fivePersonRoom,
        roomKitchen,
        roomBathroom,
        roomLivingRoom,
        roomLaundry,
        contractStartDate,
        contractEndDate,
        terminationType,
        price,
        currency,
        terminationDayNumber,
        type,
      };
      if (emitValuesOnSubmit) {
        emitValuesOnSubmit(body);
        onClose();

        return;
      }

      if (accommodation && accommodation.id) {
        if (assets.length) {
          const assetBody = { assets };
          AccommodationService.updateAccommodationAsset(
            accommodation.id,
            assetBody
          );
        }

        onClose();
      } else {
        AccommodationService.addAccommodation(body)
          .then(({ data }) => {
            if (assets.length) {
              const assetBody = { assets };
              AccommodationService.updateAccommodationAsset(data.id, assetBody);
            }

            onClose();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component="div"
          variant="h6"
        >{t`employmentManagementDialog:dialog2.fillLocation`}</Typography>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <Location formik={formik} />
        <AdditionalInfo formik={formik} />
        <FloorPlan formik={formik} />
        {hideContractData ? null : <ContractData formik={formik} />}
        {hideAddPhoto ? null : <AddPics formik={formik} />}
        <div className={classes.saveBtnDiv}>
          <Button type="submit" className={classes.saveBtn}>
            {t`employmentManagementDialog:saveBtn`}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(
  withTranslation()(AwaitingEmploymentLocationForm)
);
