import {
  Avatar,
  Chip,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  LocationOn as LocalIcon,
  SupervisorAccount as SuperVisorIcon,
  Label as LabelIcon,
  VpnKey as VpnKeyIcon,
  Visibility as VisibilityIcon,
  Lock as LockIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import AssignHiringManagerToProjectButton from '../../../components/AssignHiringManagerToProjectButton';
import AssignMentorToProjectButton from '../../../components/AssignMentorToProjectButton';
import ProjectAssets from '../../../components/ProjectAssets';
import ProjectMakeCopyButton from '../../../components/ProjectMakeCopyButton';
import ProjectMakePublicButton from '../../../components/ProjectMakePublicButton';
import NotificationsInformationListItem from '../../../components/TopAppBar/NotificationsInformationListItem';
import ProjectClose from './ProjectClose';

const styles = theme => ({
  leftSectionProject: {
    boxSizing: 'border-box',
    display: 'flex',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    height: '500px',
    flexDirection: 'column',
    placeContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 16,
      height: 'auto',
    },
  },
  rightSectionProject: {
    boxSizing: 'border-box',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 16,
    // height: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingRight: 32,
      height: 'auto',
    },
  },
  companyLogotype: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: 300,
    },
  },
  textDescription: {
    height: 215,
    overflowY: 'auto',
    fontSize: '14px',
    color: theme.palette.jobllegroGrey,
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  editProjectButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    width: 30,
    height: 30,
    background: '#fff',
    padding: 0,
    [theme.breakpoints.only('xs')]: {
      left: 10,
      position: 'fixed',
      zIndex: '9999',
      width: 50,
      height: 50,
      background: theme.palette.jobllegroOrange,
      color: '#ffffff',
      top: '80vh',
    },
  },
  sectionBottom: {
    paddingTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.jobllegroSuperLightGrey}`,
    [theme.breakpoints.only('xs')]: {
      paddingTop: 0,
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
    },
  },
  projectPosition: {
    color: theme.palette.jobllegroBlue,
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  projectClient: {
    color: theme.palette.jobllegroGrey,
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(1.5),
  },
  projectWww: {
    color: theme.palette.jobllegroBlue,
    '& > a': {
      color: theme.palette.jobllegroBlue,
      fontSize: 13,
      textDecoration: 'none',
    },
  },
  projectCreatedAt: {
    color: theme.palette.jobllegroLightGrey,
    fontSize: 11,
  },
  chip: {
    border: '0px',
    color: theme.palette.jobllegroLightGrey,
  },
  projectInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2.5),
  },
  chipIcon: {
    width: 14,
    color: theme.palette.jobllegroLightGrey,
  },
  chipIconGreen: {
    width: 14,
    color: theme.palette.jobllegroSuccess,
  },
  chipIconRed: {
    width: 14,
    color: theme.palette.jobllegroRedAlert,
  },
  avatarChip: {
    background: 'none',
    width: 'auto',
    height: 'auto',
  },
  containerLeft: {
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      position: 'static',
      textAlign: 'center',
    },
  },
});

export const ProjectDetailsLeftDescription = props => {
  const { classes, projectDetails, currentUser, t } = props;
  const {
    organization = {},
    owner = {},
    visibility,
    permissions: { isMentor, isSuperUser },
  } = projectDetails;
  const isOwner =
    projectDetails &&
    projectDetails.permissions &&
    projectDetails.permissions.isOwner;
  const { isRecruiter } = currentUser;
  const projectLogo = getProjectAssetSrcByType(projectDetails, 'logo');

  let companySize = '';
  if (projectDetails.companySize === 1) {
    companySize = '0-9';
  } else if (projectDetails.companySize === 2) {
    companySize = '10-49';
  } else if (projectDetails.companySize === 3) {
    companySize = '50-249';
  } else if (projectDetails.companySize === 4) {
    companySize = '250+';
  }
  let disableEdit = true;
  if (!isOwner || !isMentor) {
    disableEdit = false;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className={classes.leftSectionProject}>
          <div className={classes.containerLeft}>
            {projectDetails.permissions.isOwner ? (
              <ProjectAssets
                filesLimit={1}
                assetType="logo"
                acceptedFiles={['image/*']}
              >
                <img
                  src={projectLogo}
                  alt={projectDetails.client}
                  className={classes.companyLogotype}
                />
              </ProjectAssets>
            ) : (
              <img
                src={projectLogo}
                alt={projectDetails.client}
                className={classes.companyLogotype}
              />
            )}

            {((isRecruiter &&
              !projectDetails.closedStatus &&
              (isOwner || isMentor)) ||
              isSuperUser) && (
              <IconButton
                className={classes.editProjectButton}
                aria-label="Edit"
                disabled={disableEdit}
                component={Link}
                to={PATHS.PROJECT.EDIT.replace(':id', projectDetails.id)}
              >
                <EditIcon style={{ width: 20 }} />
              </IconButton>
            )}
          </div>
          <div className={classes.sectionBottom}>
            <AssignMentorToProjectButton />
            <AssignHiringManagerToProjectButton />
            ` <ProjectMakePublicButton />
            ` <ProjectMakeCopyButton />
            <ProjectClose />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <div className={classes.rightSectionProject}>
          <Typography
            variant="h5"
            component="h1"
            className={classes.projectPosition}
          >
            {projectDetails.position}
          </Typography>
          <Typography component="span" className={classes.projectCreatedAt}>
            {`${t('project:field.createdAt') + ': '} ${
              projectDetails.createdAt
            }`}
          </Typography>
          {projectDetails.publicationDate && (
            <Typography component="span" className={classes.projectCreatedAt}>
              {`${t('project:field.publicationDate') + ': '} ${
                projectDetails.publicationDate
              }`}
            </Typography>
          )}
          <Typography
            variant="h5"
            component="h4"
            className={classes.projectClient}
          >
            {projectDetails.client}
          </Typography>
          <Typography component="div" className={classes.projectWww}>
            <a href={projectDetails.clientUrl}>{projectDetails.clientUrl}</a>
          </Typography>
          <Typography component="div" className={classes.projectInfo}>
            <Chip
              avatar={
                <Avatar className={classes.avatarChip}>
                  <SuperVisorIcon className={classes.chipIcon} />
                </Avatar>
              }
              label={companySize + ' ' + t('project:field.people')}
              className={classes.chip}
              variant="outlined"
            />
            <Chip
              avatar={
                <Avatar className={classes.avatarChip}>
                  <LocalIcon className={classes.chipIcon} />
                </Avatar>
              }
              label={projectDetails.city}
              className={classes.chip}
              variant="outlined"
            />
            {projectDetails.industryCategory !== undefined &&
              projectDetails.industryCategory && (
                <Chip
                  avatar={
                    <Avatar className={classes.avatarChip}>
                      <LabelIcon className={classes.chipIcon} />
                    </Avatar>
                  }
                  label={projectDetails.industryCategory.name}
                  className={classes.chip}
                  variant="outlined"
                />
              )}
          </Typography>
          <Typography component="div" className={classes.textDescription}>
            <div
              dangerouslySetInnerHTML={{
                __html: projectDetails.companyDescription,
              }}
            ></div>
          </Typography>
          <Typography component="div" className={classes.projectInfo}>
            {isRecruiter && (
              <Chip
                avatar={
                  <Avatar className={classes.avatarChip}>
                    <VpnKeyIcon className={classes.chipIconGreen} />
                  </Avatar>
                }
                label={
                  organization ? (
                    <NotificationsInformationListItem
                      item={organization}
                      title={owner.firstName + ' ' + owner.lastName}
                    />
                  ) : (
                    t('project:field.owner') +
                    ': ' +
                    owner.firstName +
                    ' ' +
                    owner.lastName
                  )
                }
                className={classes.chip}
                variant="outlined"
              />
            )}
            <Chip
              avatar={
                <Avatar className={classes.avatarChip}>
                  <VisibilityIcon className={classes.chipIconGreen} />
                </Avatar>
              }
              label={
                <div>
                  {visibility === 3 && t('project:field.isPublic')}
                  {visibility === 2 && t('project:field.isOrganization')}
                  {visibility === 1 && t('project:field.isPrivate')}
                </div>
              }
              className={classes.chip}
              variant="outlined"
            />
            <Chip
              avatar={
                <Avatar className={classes.avatarChip}>
                  <LockIcon
                    className={
                      (projectDetails.hiringManager === undefined &&
                        projectDetails.closedStatus) === 1 ||
                      projectDetails.closedStatus === 2
                        ? classes.chipIconRed
                        : classes.chipIconGreen
                    }
                  />
                </Avatar>
              }
              label={
                2 === projectDetails.closedStatus ||
                (1 === projectDetails.closedStatus &&
                  projectDetails.hiringManager === null)
                  ? t('project:form.projectClose')
                  : (projectDetails.hiringManager !== undefined ||
                      projectDetails.hiringManager !== null) &&
                    projectDetails.closedStatus === 1
                  ? t('project:form.projectClosePendingHM')
                  : t('project:form.projectOpen')
              }
              className={classes.chip}
              variant="outlined"
            />
          </Typography>
          <Typography component="div" className={classes.projectInfo}>
            {projectDetails.hiringManager &&
              t('project:field.hiringManager') +
                ': ' +
                projectDetails.hiringManager.firstName +
                ' ' +
                projectDetails.hiringManager.lastName +
                ' '}
          </Typography>
          <Typography component="div" className={classes.projectInfo}>
            {projectDetails.mentor &&
              t('project:field.mentor') +
                ': ' +
                projectDetails.mentor.firstName +
                ' ' +
                projectDetails.mentor.lastName}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
    currentUser: store.auth.currentUser,
  };
};

ProjectDetailsLeftDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsLeftDescription)));
