import { Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import VisibleIcon from 'shared/assets/img/icons/candidate-show/visible.svg';
import { Translate } from '../../index';

const ProjectAtsCandidateInProjectFeedbackFromEmployerHeader = ({
  onClickEditFeedback,
  showEditBtn,
}) => {
  return (
    <Grid container>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid
          items
          xs={9}
          sm={9}
          md={9}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            alt="VisibleIcon"
            src={VisibleIcon}
            style={{ paddingRight: 10 }}
          />
          <Translate
            style={{
              fontSize: 18,
            }}
            text={'newAts:feedbackView.feedbackReviewHeader'}
          />
        </Grid>
        {showEditBtn ? (
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            onClick={onClickEditFeedback}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img alt="EditIcon" src={EditIcon} height={50} />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(
  ProjectAtsCandidateInProjectFeedbackFromEmployerHeader
);
