import axios from 'axios';
import { push } from 'react-router-redux';
import { PATHS } from 'config';
import { API, API_URL } from '../constants';
import {
  setSelectedUserAction,
  setSelectedUsersListAction,
  clearSelectedUserAction,
  clearSelectedUsersListAction,
  setCurrentUsersLimitedListAction,
  setCurrentUsersLimitedListCounterAction,
} from '../store/users/actions';
import { getLocalStorageByKey } from '../utils/localStorage';
import { ApiService } from './api.service';

export const clearSelectedUser = () => {
  return async (dispatch, getState) => {
    dispatch(clearSelectedUserAction());
  };
};

export const clearSelectedUsersList = () => {
  return async (dispatch, getState) => {
    dispatch(clearSelectedUsersListAction());
  };
};

export const getUser = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.SHOW.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setSelectedUserAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const getUsers = params => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: params,
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setSelectedUsersListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const getUsersWithLimit = (limit, offset, params) => {
  return async dispatch => {
    const usersCount = axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        count: true,
        ...params,
      },
    })
      .then(response => {
        const {
          data: { count },
        } = response;
        dispatch(setCurrentUsersLimitedListCounterAction(count));
        return { count };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
    const usersData = axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        limit: limit,
        offset: offset,
        ...params,
      },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentUsersLimitedListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
    return await Promise.all([usersCount, usersData]).then(apiData => {
      return {
        count: apiData[0]['count'],
        data: apiData[1]['data'],
        status: apiData[1]['status'],
      };
    });
  };
};

export const createUser = values => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.USER.CREATE,
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setSelectedUserAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const editUser = values => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.USER.EDIT.replace(':id', values.id),
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setSelectedUserAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const checkMail = mail => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.CHECK_MAIL.replace(':mail', mail),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const deleteUser = id => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url: API_URL + API.DELETE.USER.DELETE.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editUserLanguages = (userId, values) => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.USER.LANGUAGE.EDIT.replace(':id', userId),
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editUserSkills = (userId, values) => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.USER.SKILL.EDIT.replace(':id', userId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const UserService = {
  postCandidateToStash: data => {
    return ApiService.post(API.POST.USER.CANDIDATE_STASH, data);
  },
};
