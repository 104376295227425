import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { styles } from './CandidatePersonalCard.styles';
import CandidatePersonalCardLeft from './CandidatePersonalCardLeft';
import CandidatePersonalCardRight from './CandidatePersonalCardRight';

const CandidatePersonalCard = ({
  candidate,
  currentUser,
  readOnly = false,
  classes,
}) => {
  return (
    <Grid container className={classes.wrapper} style={{ width: '100%' }}>
      <CandidatePersonalCardLeft candidate={candidate} />
      <CandidatePersonalCardRight
        candidate={candidate}
        readOnly={readOnly}
        currentUser={currentUser}
      />
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(CandidatePersonalCard))
);
