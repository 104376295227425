import { Paper, TextField, Hidden, withStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { getSearchData } from 'shared/services/searchService';

const styles = theme => ({
  flex: {
    flex: 1,
  },
  searchBar: {
    flexGrow: 1,
    maxWidth: 650,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  inputUnderline: {
    '&:after': {
      backgroundColor: '#fff',
    },
  },
  textFieldRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  textFieldInput: {
    color: '#b1b1b1',
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    boxShadow: 'inset 0 0 8px #ced4da',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  textFieldFormLabel: {
    fontSize: 18,
  },
  searchIcon: {
    color: '#cccccc',
    position: 'absolute',
    top: 10,
    right: 10,
  },
  drawerPaper: {
    position: 'absolute',
    top: 65,
    width: 500,
  },
});

export class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      candidates: [],
      projects: [],
      recruiters: [],
    };
  }

  onChange = async (event, { newValue }) => {
    await this.setState({
      value: newValue,
    });
  };

  getSuggestions = async value => {
    const {
      data: { projects, candidates, recruiters },
    } = await this.props.getSearchData(value);

    let cSuggestions = candidates.map(candidate => {
      return {
        id: candidate.id,
        name: candidate.firstName + ' ' + candidate.lastName,
        type: 'candidate',
      };
    });

    let pSuggestions = projects.map(project => {
      return {
        id: project.id,
        name: project.client,
        type: 'project',
      };
    });

    let rSuggestions = recruiters.map(recruiter => {
      return {
        id: recruiter.id,
        name: recruiter.firstName + ' ' + recruiter.lastName,
        type: 'recruiter',
      };
    });

    return _.union(cSuggestions, pSuggestions, rSuggestions) || [];
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.setState({
      suggestions:
        inputLength === 0 ? [] : await this.getSuggestions(inputValue),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);
    let content = parts.map((part, index) =>
      part.highlight ? (
        <span key={String(index)} style={{ fontWeight: 300 }}>
          {part.text}
        </span>
      ) : (
        <strong key={String(index)} style={{ fontWeight: 500 }}>
          {part.text}
        </strong>
      )
    );

    if (suggestion.type === 'candidate') {
      return (
        <Link to={PATHS.CANDIDATE.SHOW.replace(':id', suggestion.id)}>
          CANDIDATE: {content}
        </Link>
      );
    } else if (suggestion.type === 'project') {
      return (
        <Link to={PATHS.PROJECT.SHOW.replace(':id', suggestion.id)}>
          PROJECT: {content}
        </Link>
      );
    } else if (suggestion.type === 'recruiter') {
      return (
        <Link to={PATHS.RECRUITER.SHOW.replace(':id', suggestion.id)}>
          RECRUITER: {content}
        </Link>
      );
    }
  };

  renderInput = inputProps => {
    const { classes, ref, name, ...other } = inputProps;

    return (
      <TextField
        className={classes.flex}
        fullWidth
        placeholder="Wyszukaj..."
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <SearchIcon
              position="end"
              className={classes.searchIcon}
            ></SearchIcon>
          ),
          classes: {
            root: classes.textFieldRoot,
            input: classes.textFieldInput,
          },
          ...other,
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldFormLabel,
        }}
        inputRef={ref}
      />
    );
  };

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;
    return (
      <Paper
        {...containerProps}
        square
        className={this.props.classes.drawerPaper}
      >
        {children}
      </Paper>
    );
  };

  getSuggestionValue = suggestion => {
    return '';
  };

  render() {
    const { value, suggestions } = this.state;
    const { classes } = this.props;

    return (
      <Hidden smDown>
        <div className={classes.searchBar}>
          <Autosuggest
            renderInputComponent={this.renderInput}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={{
              classes,
              value: value,
              onChange: this.onChange,
            }}
          />
        </div>
      </Hidden>
    );
  }
}

const mapDispatchToProps = {
  getSearchData,
};

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  getSearchData: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(SearchBar)));
