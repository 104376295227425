import {
  Hidden,
  IconButton,
  CardContent,
  Divider,
  Typography,
  Tooltip,
  Grid,
  withStyles,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  ArrowUpward as ArrowIcon,
  Input as InputIcon,
  AccessTime as AccessTimeicon,
  AccountCircle as AccountCircleIcon,
  GroupAdd as GroupAddIcon,
  Home as HomeIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { enums } from 'shared/enums';
import { editProjectStageCandidates } from 'shared/services/projectService';
import { getEnumItemName } from 'shared/utils/assets';
import { getAvailabilityFontStyle } from './utils/utils';

const styles = theme => ({
  cardContent: {
    paddingTop: 8,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: '8px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: '12px !important',
    },
  },
  textBottom: {
    color: '#808080',
    fontSize: 15,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    textAlign: 'left',
  },
  textMiddle: {
    color: '#52a3fc',
    fontSize: 15,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  textMiddleLocked: {
    color: '#808080',
  },
  acceptButton: {
    width: '20px',
    height: '20px',
    padding: 0,
  },
  bottomButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    height: 28,
  },
});

export const AtsCardContent = props => {
  const {
    classes,
    project = {},
    editProjectStageCandidates,
    handleOpenCandidateInProject,
    handleOpenSetCandidateInProject,
    parseCandidatesList,
    currentUser: { isRecruiter },
    obj,
    t,
  } = props;

  const rejectCandidate = async obj => {
    const { handleChangeCheckbox } = props;
    await editProjectStageCandidates(project.id, [
      {
        candidateId: obj.candidate.id,
        isRejected: true,
      },
    ]);
    await handleChangeCheckbox(obj.candidate.id, obj.stageNumber, false);

    await parseCandidatesList();
  };

  const restoreCandidate = async obj => {
    await editProjectStageCandidates(project.id, [
      {
        candidateId: obj.candidate.id,
        isRejected: false,
      },
    ]);
    await parseCandidatesList();
  };

  const acceptCandidate = async obj => {
    await editProjectStageCandidates(project.id, [
      {
        candidateId: obj.candidate.id,
        stageNumber: 2,
      },
    ]);
    await parseCandidatesList();
  };

  const { stage = {}, candidate = {}, owner = {} } = obj;

  const switchUser = obj.isRejected ? (
    <ArrowIcon style={{ fontSize: 18 }} onClick={() => restoreCandidate(obj)} />
  ) : (
    <CloseIcon style={{ fontSize: 18 }} onClick={() => rejectCandidate(obj)} />
  );
  const acceptUser = (
    <InputIcon style={{ width: 20 }} onClick={() => acceptCandidate(obj)} />
  );

  const candidateDateStage = obj.isRejected
    ? obj[`stage${obj.stageNumber}DateReject`]
    : obj[`stage${obj.stageNumber}Date`];

  const availability = candidate.availabilityDate
    ? candidate.availabilityDate
    : getEnumItemName(
        enums.AVAILABILITY_CANDIDATE,
        candidate.availabilityTypeCandidate
      );

  const showActiveBtn = () => {
    if (!project.closedStatus && !obj.isRejected && stage.number === 1) {
      if (candidate.permissions.isOwner) {
        return true;
      } else if (
        obj.confirmationStatus !== 1 &&
        (project.permissions.isOwner ||
          (project.permissions.isMentor &&
            !project.permissions.isMentorLegalization &&
            !project.permissions.isMentorCoordinator))
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <Hidden only="xs">
      <CardContent className={classes.cardContent}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Typography
              component="p"
              className={classes.textBottom}
              style={getAvailabilityFontStyle(candidateDateStage)}
            >
              {candidateDateStage}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Typography
              component="p"
              className={classNames(
                classes.textMiddle,
                stage.locked ? classes.textMiddleLocked : ''
              )}
            >
              {candidate.maxMonthlyWadges
                ? `${candidate.maxMonthlyWadges} pln`
                : null}
            </Typography>
          </Grid>
        </Grid>
        {!stage.locked && isRecruiter && (
          <React.Fragment>
            <Divider />
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.bottomButtons}
              >
                {candidate.isForeignerInEmploymentArea && (
                  <Tooltip
                    id="tooltip-top"
                    title={t`atsSubMenu:candidateIn`}
                    placement="top"
                  >
                    <IconButton
                      style={{ width: '20px', height: '20px', padding: 0 }}
                    >
                      <HomeIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  id="tooltip-top"
                  title={t`atsSubMenu:candidateInProj`}
                  placement="top"
                >
                  <IconButton
                    onClick={() =>
                      handleOpenCandidateInProject(
                        candidate,
                        owner,
                        obj.stageNumber
                      )
                    }
                    style={{ width: '20px', height: '20px', padding: 0 }}
                  >
                    <AccountCircleIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title={availability ? availability : t`candidate:lack`}
                  placement="top"
                >
                  <IconButton
                    onClick={() =>
                      handleOpenCandidateInProject(
                        candidate,
                        owner,
                        obj.stageNumber
                      )
                    }
                    style={{ width: '20px', height: '20px', padding: 0 }}
                  >
                    <AccessTimeicon style={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>

                {obj.isChecked && (
                  <Tooltip
                    id="tooltip-top"
                    title={t`assign:addToProjects`}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => handleOpenSetCandidateInProject(candidate)}
                      style={{ width: '20px', height: '20px', padding: 0 }}
                    >
                      <GroupAddIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                {showActiveBtn() && (
                  <Tooltip
                    title={t`candidate:show.activateCandidate`}
                    placement="top"
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      className={classes.acceptButton}
                    >
                      {acceptUser}
                    </IconButton>
                  </Tooltip>
                )}

                {!project.closedStatus &&
                  obj.confirmationStatus !== 1 &&
                  (candidate.permissions.isOwner ||
                    project.permissions.isOwner ||
                    project.permissions.isMentor) && (
                    <Tooltip
                      title={
                        obj.isRejected
                          ? t`candidate:show.bringBack`
                          : t`candidate:show.rejectCandidate`
                      }
                      placement="top"
                    >
                      <IconButton
                        style={{ padding: 0 }}
                        className={classes.acceptButton}
                      >
                        {switchUser}
                      </IconButton>
                    </Tooltip>
                  )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </CardContent>
    </Hidden>
  );
};

AtsCardContent.propTypes = {
  project: PropTypes.object.isRequired,
  obj: PropTypes.object.isRequired,
  handleOpenCandidateInProject: PropTypes.func.isRequired,
  parseCandidatesList: PropTypes.func.isRequired,
  editProjectStageCandidates: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  editProjectStageCandidates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(AtsCardContent)));
