export const styles = () => ({
  container: { padding: 10 },
  paper: {
    borderRadius: 10,
    // padding: 15,
    width: '100%',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
  },
});
