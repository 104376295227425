export const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 8,
  },
  wrapperForPadding: {
    padding: '16px 150px',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },
  backgroundColor: theme.palette.jobllegroBorderGrey,
});
