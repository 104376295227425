import { Typography, withStyles, Grid, Button } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { enums } from 'shared/enums';
import { TextEditor } from 'shared/newComponents';
import Select from 'shared/newComponents/Select';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import {
  getAvailableProject,
  getAvailableProjectOrders,
} from 'shared/utils/functions';
import { styles } from './EndOfEmployment.styles';

const EndOfEmployment = ({
  classes,
  t,
  dataAdded,
  selectedFullObject,
  project,
  showSaveBtn,
  projectAvailableToMove,
}) => {
  const [selectOptions, setDynamicSelectOptions] = useState([]);

  const isResignationConfirm = selectedFullObject.isResignationConfirm;

  const isMentorCoordinator = project.permissions.isMentorCoordinator;

  const resignationEndDate =
    !selectedFullObject.resignationEndDate &&
    !selectedFullObject.employmentEndDate
      ? moment().format('YYYY-MM-DD')
      : selectedFullObject.resignationEndDate
      ? selectedFullObject.resignationEndDate
      : selectedFullObject.employmentEndDate;

  const formData = {
    resignationStartDate: selectedFullObject.employmentStartDate
      ? selectedFullObject.employmentStartDate
      : moment().format('YYYY-MM-DD'),
    resignationEndDate: resignationEndDate,
    reasonOfTerminationDescription:
      selectedFullObject.reasonOfTerminationDescription
        ? selectedFullObject.reasonOfTerminationDescription
        : '',
    reasonOfTermination:
      selectedFullObject.reasonOfTermination !== null
        ? selectedFullObject.reasonOfTermination
        : '',
    project: '',
    projectOrder: '',
  };

  const minBreakDate = selectedFullObject.employmentStartDate
    ? selectedFullObject.employmentStartDate
    : moment().format('YYYY-MM-DD');

  const maxBreakDate = selectedFullObject.employmentEndDate
    ? selectedFullObject.employmentEndDate
    : moment().format('YYYY-MM-DD');

  const validationSchema = yup.object({
    resignationStartDate: yup
      .date()
      .min(
        minBreakDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minBreakDate}`
      )
      .max(
        maxBreakDate,
        `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${maxBreakDate}`
      )
      .required(t`ats2ats3:validation.isRequired`),
    resignationEndDate: yup
      .date()
      .min(
        minBreakDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minBreakDate}`
      )
      .max(
        maxBreakDate,
        `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${maxBreakDate}`
      )
      .required(t`ats2ats3:validation.isRequired`),
    reasonOfTermination: yup
      .number()
      .required(t`ats2ats3:validation.isRequired`),
    project: yup.string().when('reasonOfTermination', {
      is: reasonOfTermination =>
        reasonOfTermination === enums.END_OF_EMPLOYMENT_REASON_VALUES.TRANSFER,
      then: yup.string().required(t`ats2ats3:validation.isRequired`),
      otherwise: yup.string(),
    }),
    reasonOfTerminationDescription: yup.string().when('reasonOfTermination', {
      is: reasonOfTermination =>
        reasonOfTermination === enums.END_OF_EMPLOYMENT_REASON_VALUES.OTHER,
      then: yup.string().required(t`ats2ats3:validation.isRequired`),
      otherwise: yup.string(),
    }),
    projectOrder: yup.string().when('project', {
      is: project => project,
      then: yup
        .string()
        .strict(false)
        .required(t('projectCreator:core.requiredField')),
      otherwise: yup.string().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: (
      {
        resignationStartDate,
        resignationEndDate,
        reasonOfTermination,
        project,
        projectOrder,
        reasonOfTerminationDescription,
      },
      actions
    ) => {
      if (
        moment(resignationStartDate).isAfter(moment(resignationEndDate), 'day')
      ) {
        formik.setFieldError('resignationEndDate', t`ats2ats3:validation.date`);
        return;
      }

      const body = {
        resignationStartDate,
        reasonOfTerminationDescription,
        projectToMove: project !== '' ? { id: project } : null,
        projectOrderToMove: projectOrder !== '' ? { id: projectOrder } : null,
        reasonOfTermination:
          reasonOfTermination !== '' ? reasonOfTermination : null,
        resignationEndDate,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };

      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_EMPLOY_END
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.status === 409) {
              return actions.setFieldError(
                'projectOrder',
                t`employmentManagementDialog:dialog9.req`
              );
            }
            return actions.setFieldError(
              'projectOrder',
              t`newAts:careerPanelView.saveError`
            );
          }
        });
    },
  });

  useEffect(() => {
    setDynamicSelectOptions(
      getAvailableProjectOrders(formik.values.project, projectAvailableToMove)
    );
  }, [formik.values.project]);

  const handleConfirmEndOfWork = () => {
    const body = {
      employmentManagementHistories: [
        {
          candidate: selectedFullObject.candidate,
          projectCandidate: selectedFullObject.projectCandidate,
        },
      ],
      isResignationConfirm: true,
    };
    EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
      body,
      enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS_CONFIRM_RESIGNATION
        .CONFIRM_RESIGNATION
    )
      .then(() => {
        dataAdded();
      })
      .catch(error => console.log(error));
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.bgBlue}>
        <div className={classes.container}>
          <Grid
            container
            alignItems="center"
            spacing={4}
            className={classes.edited}
          >
            <Grid item md={5}>
              <Typography variant="body1">{t`employmentManagementDialog:dialog9.plannedEndWorkDate`}</Typography>
            </Grid>
            <Grid item md={5}>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.date}>
                    {formik.values.resignationEndDate || t`assign:noData`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item md={5}>
              <Typography variant="body1">{t`employmentManagementDialog:dialog9.endWorkDate`}</Typography>
            </Grid>
            <Grid item md={6}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                </Grid>
                <Grid item>
                  <TextField
                    disabled={isResignationConfirm}
                    type="date"
                    formik={formik}
                    name="resignationEndDate"
                    variant="transparent"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.reasonOfTermination}>
            <Select
              disabled={isResignationConfirm}
              formik={formik}
              name="reasonOfTermination"
              items={getSelectItemsTranslate(enums.END_OF_EMPLOYMENT_REASON, t)}
              label={t`employmentManagementDialog:dialog9.reasonLabel`}
              variant="transparent"
              fontBold
            />
          </div>
          {selectedFullObject.employmentStatus ===
            enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
              .EMPLOYMENT_STATUS_EMPLOY_END && (
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent={'center'}
                >
                  <div className={classes.saveBtnDiv}>
                    <Button
                      onClick={handleConfirmEndOfWork}
                      className={classes.confirmBtn}
                      disabled={isResignationConfirm || isMentorCoordinator}
                    >
                      {t`employmentManagementDialog:dialog9:confirmBtn`}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </div>

      {formik.values.reasonOfTermination ===
        enums.END_OF_EMPLOYMENT_REASON_VALUES.OTHER && (
        <div className={classes.transferContainer}>
          <TextEditor
            disabled={isResignationConfirm}
            formik={formik}
            name={'reasonOfTerminationDescription'}
            label={''}
            subLabel={''}
          />
        </div>
      )}
      {formik.values.reasonOfTermination ===
        enums.END_OF_EMPLOYMENT_REASON_VALUES.TRANSFER && (
        <div className={classes.transferContainer}>
          <Select
            disabled={isResignationConfirm}
            items={getAvailableProject(projectAvailableToMove)}
            formik={formik}
            name="project"
            label={t`cancelOrderDialog:chooseProject`}
          />
          <Select
            disabled={isResignationConfirm}
            items={selectOptions}
            formik={formik}
            name="projectOrder"
            label={t`cancelOrderDialog:chooseLocation`}
          />
        </div>
      )}
      {showSaveBtn && (
        <div className={classes.saveBtnDiv}>
          <Button
            type="submit"
            className={classes.saveBtn}
            disabled={isResignationConfirm}
          >
            {t`employmentManagementDialog:saveBtn`}
          </Button>
        </div>
      )}
    </form>
  );
};

export default withStyles(styles)(withTranslation()(EndOfEmployment));
