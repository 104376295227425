export const styles = () => ({
  root: {
    padding: 10,
    '& fieldset': { borderRadius: 30 },
    '& input': { padding: '18.5px 14px 23px 20px', borderRadius: 30 },
  },
  input: { '& > div > div > p': { width: 'max-content' } },
  formControl: { position: 'relative' },
  formHelperText: { position: 'absolute', top: '55px' },
});
