import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getLanguagesLevel } from 'shared/utils/functions';
import LevelDotsRating from '../../../components/LevelDotsRating';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: '0px !important',
    paddingRight: '64px !important',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  }),
  textHeading: {
    paddingTop: 0,
    paddingBottom: 8,
    color: '#52a3fc',
    fontSize: '20px',
  },
  gridContainer: {
    marginTop: 12,
    marginBottom: 12,
  },
  skillLine: {
    marginTop: 12,
    marginBottom: 12,
  },
  secondaryHeading: {
    color: theme.palette.jobllegroGrey,
    '& > span': {
      fontWeight: 900,
    },
  },
  primaryHeading: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
});

export const ProjectDetailsCompetencesLanguages = props => {
  const { classes, projectDetails, t } = props;
  const { projectLanguages = {}, projectSkills = {} } = projectDetails;
  const countProjectLanguages =
    projectLanguages === null ? 0 : projectLanguages.length;
  const countProjectSkills = projectSkills === null ? 0 : projectSkills.length;

  const LanguageListItem = element => {
    const item = getLanguagesLevel(element.level);
    return (
      <Grid
        key={element.id}
        container
        spacing={0}
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <Typography className={classes.primaryHeading}>
            {element.language.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={5} lg={5}>
          <LevelDotsRating
            variant="linear"
            dotsActive={element.level}
            points={7}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} align="center">
          <Typography className={classes.secondaryHeading}>
            {t('candidate:show.level')} <span>{item}</span>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={0}>
      {countProjectSkills > 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.rootPaper} elevation={0}>
            <Typography
              component="h3"
              gutterBottom
              className={classes.textHeading}
            >
              {t('candidate:show.competencesSubTitleHard')}
            </Typography>
            {projectDetails.projectSkills.map((element, key) => {
              if (!element.skill) {
                return '';
              }
              return (
                <Grid
                  key={key}
                  container
                  spacing={0}
                  className={classes.skillLine}
                  style={{
                    background:
                      element.competence &&
                      element.competence.skill &&
                      element.competence.skill.isApproved
                        ? ''
                        : '#d8d8d8',
                  }}
                >
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    <Typography className={classes.primaryHeading}>
                      {element.skill.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={5} lg={5}>
                    <LevelDotsRating
                      variant="linear"
                      dotsActive={element.level}
                      points={5}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} align="center">
                    <Typography className={classes.secondaryHeading}>
                      {element.industryCategory
                        ? element.industryCategory.name
                        : ''}
                      , Lata dośw.
                      <span>{element.experience}</span>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Paper>
        </Grid>
      )}
      {countProjectLanguages > 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            className={classes.rootPaper}
            elevation={0}
            style={{ paddingTop: 0 }}
          >
            <Typography
              component="h3"
              gutterBottom
              className={classes.textHeading}
            >
              {t('project:show.projectLanguagesTitle')}
            </Typography>
            {projectDetails.projectLanguages.map(element => {
              return LanguageListItem(element);
            })}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectDetailsCompetencesLanguages.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsCompetencesLanguages)));
