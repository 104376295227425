import {
  IconButton,
  Badge,
  Avatar,
  Divider,
  MenuItem,
  Menu,
  List,
  ListItem,
  ListItemText,
  Typography,
  Hidden,
  withStyles,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getUserAvatarSrc } from 'shared/utils/assets';

const styles = theme => ({
  flex: {
    flex: 1,
  },
  MvIcon: {
    color: '#afafaf',
  },
  hiddenText: {
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#f2f2f2',
    fontSize: '12px',
  },
  text: {
    color: '#f2f2f2',
    fontSize: '12px',
  },
  listItem: {
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
      display: 'inline-block',
    },
  },
  root: {
    flexGrow: 1,
    maxWidth: 280,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 60,
    },
  },
  mentor: {
    background: theme.palette.jobllegroRedAlert,
  },
  recruiter: {
    background: theme.palette.jobllegroBlue,
  },
  candidate: {
    background: theme.palette.jobllegroSuccess,
  },
});

export class UserAccount extends Component {
  state = {
    anchorEl: null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, t, userLogged = {} } = this.props;
    const { anchorEl } = this.state;
    const usermenuOpened = Boolean(anchorEl);
    const avatarSrc = getUserAvatarSrc(userLogged);

    let userStatus = '';
    let userStatusBackground = '';
    if (userLogged.mentorStatus === 2) {
      userStatus = 'M';
      userStatusBackground = classes.mentor;
    } else if (userLogged.isRecruiter === true) {
      userStatus = 'R';
      userStatusBackground = classes.recruiter;
    } else if (userLogged.isRecruiter === false) {
      userStatus = 'C';
      userStatusBackground = classes.candidate;
    }

    return (
      <div className={classes.root}>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              disableTypography
              primary={<></>}
              secondary={
                <Hidden xsDown>
                  <>
                    <Typography align="right" className={classes.hiddenText}>
                      {userLogged ? userLogged.email : ''}
                    </Typography>
                    {userLogged && !userLogged.isRecruiter && userLogged.owner && (
                      <Typography align="right" className={classes.hiddenText}>
                        ({userLogged.owner.email})
                      </Typography>
                    )}
                  </>
                </Hidden>
              }
            />
            <Badge
              badgeContent={userStatus}
              classes={{
                badge: userStatusBackground,
              }}
            >
              <Avatar
                onClick={this.handleMenu}
                alt={userLogged.email}
                src={avatarSrc}
              />
            </Badge>
            <Hidden xsDown>
              <IconButton
                aria-label="Menu"
                aria-haspopup="true"
                onClick={this.handleMenu}
                id="UserContextMenuButton"
              >
                <MoreVertIcon className={classes.MvIcon} />
              </IconButton>
            </Hidden>
          </ListItem>
        </List>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={usermenuOpened}
          onClose={this.handleClose}
        >
          <MenuItem
            onClick={this.handleClose}
            component={Link}
            to={PATHS.USER.INDEX}
          >
            {t('menu:location.userPanel')}
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to={PATHS.AUTH.LOGOUT}>
            {t('menu:action.logout')}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    userLogged: store.auth.currentUser,
  };
};

UserAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  userLogged: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(UserAccount)));
