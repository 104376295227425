import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PATHS } from 'config';
import LandingLayout from '../../../components/LandingLayout/';

export class LandingIndexView extends Component {
  componentDidMount() {
    this.props.getConfigs();
    this.props.history.push(PATHS.AUTH.LOGIN);
  }

  render() {
    return <LandingLayout />;
  }
}

LandingIndexView.propTypes = {
  getConfigs: PropTypes.func.isRequired,
};

export default LandingIndexView;
