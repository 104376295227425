import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getNotifications,
  setNotificationRead,
} from 'shared/services/notificationService';
import { editProject } from 'shared/services/projectService';
import { getRecruitersWithLimit } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getUserAvatarSrc } from 'shared/utils/assets';
import { getHiringManagerStatusInProject } from 'shared/utils/functions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';
import ExpansionComponent from './LayoutExpansionComponent';
import ElementsListAsync from './list/ElementsListAsync';

const styles = theme => ({
  buttonOptionsAccept: {
    color: '#fff',
    background: theme.palette.jobllegroSuccess,
    opacity: 0.7,
    height: 'auto',
    '&:hover': {
      background: theme.palette.jobllegroSuccess,
      opacity: 1,
    },
  },
  buttonOptionsReject: {
    color: '#fff',
    background: theme.palette.jobllegroRedAlert,
    opacity: 0.7,
    height: 'auto',
    '&:hover': {
      background: theme.palette.jobllegroRedAlert,
      opacity: 1,
    },
  },
  infoText: {
    color: theme.palette.jobllegroSuccess,
    fontSize: 12,
  },
  cardTopSectionTitle: {
    marginTop: 10,
    fontSize: 11,
    background: theme.palette.jobllegroSuccessActive,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
  },
  cardTopChange: {
    marginLeft: 10,
    marginTop: 10,
    fontSize: 11,
    background: theme.palette.jobllegroBlue,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.jobllegroSuccessActive,
    },
  },
  toggleContainer: {
    height: 'auto',
    padding: `${theme.spacing()}px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing()}px 0`,
    background: theme.palette.background.default,
  },
  card: {
    maxWidth: 320,
    boxShadow: 'none',
  },
  cardHeader: {
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeaderAvatar: {
    width: 20,
    height: 20,
  },
  titleCardHeader: {
    fontSize: 12,
  },
  titleCardSubHeader: {
    fontSize: 12,
  },
  avatarTitleCardHeader: {
    marginRight: 8,
    cursor: 'pointer',
  },
});

export class AssignHiringManagerToProjectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hiringManagerList: [],
      isLoading: false,
      choiceButtonStatus: false,
    };
  }

  loadRecruiters = async (show, limit = 12, offset = 0, filters = null) => {
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.path) || 12
      : limit;
    let params = {
      filters,
      orderBy: {
        createdAt: 'desc',
      },
    };
    await this.props.getRecruitersWithLimit(limitToSearch, offset, params);
  };

  handleClickOpen = async () => {
    this.setState({
      isLoading: true,
    });
    await this.loadRecruiters();
    this.setState({
      open: true,
      isLoading: false,
    });
  };

  handleSelect = async recruiterId => {
    await this.setState({ choiceButtonStatus: true });
    const hiringManagerData = {
      id: this.props.projectDetails.id,
      hiringManagerId: recruiterId,
    };
    const { t } = this.props;
    const { status } = await this.props.editProject(hiringManagerData);
    if (status !== 200) {
      this.props.showSnackbarMessage(t`assign:handleSelect`);
      return;
    }
    await this.setState({ open: false, choiceButtonStatus: false });
    this.props.showSnackbarMessage(
      t('project:form.addHiringManagerInfoSuccess')
    );
    window.location.reload();
  };

  handleRemoveHiringManager = async () => {
    const { t } = this.props;
    const hiringManagerData = {
      id: this.props.projectDetails.id,
      hiringManagerId: null,
      hmLastActivity: null,
    };
    await this.props.editProject(hiringManagerData);
    this.setState({ open: false });
    this.props.showSnackbarMessage(
      t('project:form.removeHiringManagerFromProject')
    );
    window.location.reload();
  };

  handleChooseOption = idOption => async () => {
    const { t, setNotificationRead, getNotifications, notifications } =
      this.props;
    const hiringManagerData = {
      id: this.props.projectDetails.id,
      hiringManagerStatus: idOption,
    };
    await this.props.editProject(hiringManagerData);
    const projectDetailsId = this.props.projectDetails.id;
    const findNotification = find(notifications, function (n) {
      const { project = {} } = n.data;
      return project.id === projectDetailsId;
    });
    await setNotificationRead(findNotification.id);
    await getNotifications();
    this.props.showSnackbarMessage(
      t(idOption === 2 ? 'project:form.acceptByHm' : 'project:form.rejectByHm')
    );
    window.location.reload();
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  isHiringManager = recruiter =>
    this.props.projectDetails.hiringManager &&
    recruiter.id === this.props.projectDetails.hiringManager.id;

  getContent(recruiter) {
    const {
      t,
      currentUser: { id },
    } = this.props;
    const { choiceButtonStatus } = this.state;
    const avatar = getUserAvatarSrc(recruiter);
    const {
      hiringManager = {},
      hiringManagerStatus,
      permissions: { isOwner },
    } = this.props.projectDetails;

    let secondaryText = {};
    if (hiringManager && recruiter.id === hiringManager.id) {
      secondaryText = t(getHiringManagerStatusInProject(hiringManagerStatus));
    } else {
      secondaryText =
        recruiter &&
        recruiter.industry &&
        t('project:field.industry') + ': ' + recruiter.industry;
    }

    return (
      recruiter &&
      recruiter.id !== id && (
        <ListItem key={recruiter.id}>
          <Avatar
            src={avatar}
            aria-label={recruiter.firstName + ' ' + recruiter.lastName}
          ></Avatar>
          <ListItemText
            primary={recruiter.firstName + ' ' + recruiter.lastName}
            secondary={secondaryText}
          />
          {this.isHiringManager(recruiter) ? null : (
            <Button
              disabled={choiceButtonStatus}
              onClick={this.handleSelect.bind(this, recruiter.id)}
              size="small"
              variant="contained"
              color="primary"
            >
              {t('action.choose')}
            </Button>
          )}
          {hiringManager !== null &&
            recruiter.id === hiringManager.id &&
            isOwner && (
              <ListItemSecondaryAction>
                <IconButton
                  onClick={this.handleRemoveHiringManager}
                  aria-label="Remove Hiring Manager"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
        </ListItem>
      )
    );
  }

  getRecruiterContent = recruiter => {
    return this.isHiringManager(recruiter) ? null : this.getContent(recruiter);
  };

  getWrapper = options => {
    let { content, pagination, filtering } = options;
    const { t } = this.props;
    let hiringManager = this.getContent(
      this.props.projectDetails.hiringManager
    );
    return (
      <React.Fragment>
        <ExpansionComponent
          title={t('filters.name')}
          textOnTop={t('filters.description')}
        >
          {filtering}
        </ExpansionComponent>
        {hiringManager !== null && (
          <List component={'ul'}>{hiringManager}</List>
        )}
        <Divider />
        <DialogContent>
          <List component={'nav'}>{content}</List>
        </DialogContent>
        {pagination}
      </React.Fragment>
    );
  };

  handleClickRedirect = (recruiterID, projectID, position) => {
    const { t } = this.props;
    this.props.history.push({
      pathname: PATHS.RECRUITER.SHOW.replace(':id', recruiterID),
      state: { subject: `${t`assign:project`} ${projectID} ${position}` },
    });
  };

  getHmActionButton = (hMStatus, ishMAssignedToProject) => {
    const { classes, t, currentUser } = this.props;
    const {
      position,
      hiringManager = {},
      permissions: { isHiringManager, isOwner, isMentor, isCooperator },
      id,
    } = this.props.projectDetails;
    let showStatus = 0;
    let avatar = null;
    let showButton = true;
    if ((isCooperator && ishMAssignedToProject) || isHiringManager) {
      showButton = false;
    }
    if (
      hMStatus === 1 &&
      ishMAssignedToProject &&
      hiringManager.id === currentUser.id
    ) {
      showStatus = 1;
    } else if (hMStatus === 1 && ishMAssignedToProject) {
      showStatus = 3;
      avatar = getUserAvatarSrc(hiringManager);
    } else if (hMStatus === 2 && ishMAssignedToProject) {
      showStatus = 2;
      avatar = getUserAvatarSrc(hiringManager);
    }
    return (
      <React.Fragment>
        {(showStatus === 2 || showStatus === 3) && (
          <React.Fragment>
            <Typography className={classes.cardTopSectionTitle}>
              Hiring manager
            </Typography>
            {showButton && (
              <Typography
                onClick={!isHiringManager ? this.handleClickOpen : undefined}
                className={classes.cardTopChange}
              >
                {t`assign:change`}
              </Typography>
            )}
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  title: classes.titleCardHeader,
                  subheader: classes.titleCardSubHeader,
                  avatar: classes.avatarTitleCardHeader,
                }}
                onClick={() =>
                  this.handleClickRedirect(hiringManager.id, id, position)
                }
                avatar={
                  <Avatar className={classes.cardHeaderAvatar} src={avatar} />
                }
                title={hiringManager.firstName + ' ' + hiringManager.lastName}
                subheader={t(getHiringManagerStatusInProject(hMStatus))}
              />
            </Card>
          </React.Fragment>
        )}

        {showStatus === 1 && (
          <React.Fragment>
            <Typography
              className={classes.infoText}
              variant="subtitle1"
              gutterBottom
            >
              {t`assign:askToBeHM`}
              <br />
              {t`assign:HMQuestion`} {position} ?
            </Typography>
            <div className={classes.toggleContainer}>
              <ToggleButtonGroup exclusive>
                <ToggleButton
                  className={classes.buttonOptionsAccept}
                  value={2}
                  onClick={this.handleChooseOption(2)}
                >
                  {t('core:action.yesImApproving')}
                </ToggleButton>
                <ToggleButton
                  className={classes.buttonOptionsReject}
                  value={0}
                  onClick={this.handleChooseOption(0)}
                >
                  {t('core:action.noImRejecting')}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </React.Fragment>
        )}

        {showStatus === 0 && (isOwner || isMentor) && (
          <ActionButtonJobllegroRounded
            onClick={this.handleClickOpen}
            // disabled={isDraft}
            disabled={false}
          >
            {t('project:form.addHiringManager')}
          </ActionButtonJobllegroRounded>
        )}
      </React.Fragment>
    );
  };

  handleReloadElements = ({ limit, offset, filters, orderBy }) => {
    return this.loadRecruiters(undefined, limit, offset, filters, orderBy);
  };

  render() {
    const { hiringManager = {}, hiringManagerStatus } =
      this.props.projectDetails;
    const {
      currentUser: { id },
      projectDetails: { permissions = {}, closedStatus = false },
      t,
      currentRecruitersLimitedList,
      currentRecruitersLimitedListCounter,
    } = this.props;
    const {
      isOwner = false,
      isMentor = false,
      isCooperator = false,
    } = permissions;
    const { isLoading } = this.state;

    let isHmAssignedToProject = false;
    if (
      hiringManager &&
      (id === hiringManager.id || isOwner || isMentor || isCooperator)
    ) {
      isHmAssignedToProject = true;
    }
    const getHmActionButton = this.getHmActionButton(
      hiringManagerStatus,
      isHmAssignedToProject
    );

    const sortingOptions = [
      {
        name: 'id',
        label: t('project:field.id'),
      },
    ];

    const defaultSorting = {};

    const filteringOptions = [
      {
        key: 'firstName',
        type: 'text',
        name: t('recruiter:field.firstName'),
      },
      {
        key: 'lastName',
        type: 'text',
        name: t('recruiter:field.lastName'),
      },
    ];
    return (
      !closedStatus && (
        <React.Fragment>
          {getHmActionButton}
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="hm-dialog"
          >
            <DialogTitle id="hm-dialog">
              {t('project:show.selectHiringManager')}
            </DialogTitle>
            {isLoading ? (
              <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
            ) : !isEmpty(currentRecruitersLimitedList) ? (
              <ElementsListAsync
                storageKey={this.props.match.path}
                rowsPerPage={getTableRowPerPageFromStorage(
                  this.props.match.path
                )}
                handleReloadElements={this.handleReloadElements}
                load={isLoading}
                getContent={this.getRecruiterContent}
                getWrapper={this.getWrapper}
                sortingOptions={sortingOptions}
                defaultSorting={defaultSorting}
                filteringOptions={filteringOptions}
                elements={currentRecruitersLimitedList}
                elementsCount={currentRecruitersLimitedListCounter}
                scrollUp={false}
              />
            ) : (
              <DialogContent>
                <Typography variant="subtitle1">
                  {t('project:field.thereIsNoHmInSystem')}
                </Typography>
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                {t('action.cancel')}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    projectDetails: store.projects.currentProject,
    notifications: store.notifications.list,
    currentRecruitersLimitedList: store.recruiters.currentRecruitersLimitedList,
    currentRecruitersLimitedListCounter:
      store.recruiters.currentRecruitersLimitedListCounter,
  };
};

const mapDispatchToProps = {
  getNotifications,
  setNotificationRead,
  editProject,
  getRecruitersWithLimit,
  showSnackbarMessage,
};

AssignHiringManagerToProjectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  editProject: PropTypes.func.isRequired,
  getRecruitersWithLimit: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  setNotificationRead: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withStyles(styles)(withTranslation()(AssignHiringManagerToProjectButton))
  )
);
