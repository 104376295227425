import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CandidateKeyCompetences from '../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateKeyCompetences';
import { styles } from './ProjectAtsCandidateInProjectRecommend.styles';
import CareerPanel from './components/CareerPanel';
import PersonalData from './components/PersonalData';
import ProjectAtsCandidateInProjectRecommendHeader from './components/ProjectAtsCandidateInProjectRecommendHeader';

const ProjectAtsCandidateInProjectRecommend = ({
  candidate,
  industryCategories,
  classes,
  showCandidateData,
  ...props
}) => {
  const [projectCandidate, setProjectCandidate] = useState(null);

  useEffect(() => {
    if (!props.currentCandidatesList) {
      return null;
    }
    const projectCandidate = props.currentCandidatesList.find(
      item =>
        item.project.id === props.currentProject.id &&
        item.candidate.id === candidate.id
    );
    setProjectCandidate(projectCandidate);
  }, [candidate]);

  if (!props.currentProject) {
    return null;
  }
  return (
    <Grid container className={classes.wrapper} style={{ width: '100%' }}>
      <Paper style={{ width: '100%', borderRadius: 20 }}>
        <Grid container>
          <ProjectAtsCandidateInProjectRecommendHeader
            candidate={candidate}
            projectCandidate={projectCandidate}
            currentProject={props.currentProject}
          />
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={classes.bluePrimaryBackground}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                style={{
                  padding: '30px 30px 30px 30px',
                  height: '100%',
                }}
              >
                <CandidateKeyCompetences candidate={candidate} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} style={{ padding: '0px 25px' }}>
            <Grid
              container
              style={{
                padding: '30px 30px 30px 30px',
              }}
            >
              <PersonalData
                candidate={candidate}
                showCandidateData={showCandidateData}
              />
            </Grid>
          </Grid>
        </Grid>
        <CareerPanel
          candidate={candidate}
          industryCategories={industryCategories}
          projectCandidate={projectCandidate}
        />
      </Paper>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentCandidatesList: store.candidates.currentCandidatesList,
    currentProject: store.projects.currentProject,
    currentUser: store.auth.currentUser,
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectAtsCandidateInProjectRecommend)));
