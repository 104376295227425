import { Grid, CardContent, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import { setLastPage } from 'shared/services/authService';
import Box from '../../components/BoxContainer';
import DashboardCandidateAts from '../../components/DashboardCandidateAts';
import CurrentCandidateBox from './components/CurrentCandidateBox';
import ProjectsTable from './components/ProjectsTable';
import RecommendProjectsTable from './components/RecommendProjectsTable';

const styles = () => ({
  content: {
    padding: '0px !important',
    margin: 0,
  },
});

export class DashboardCandidateIndexView extends Component {
  getProjectBoxContent = () => {
    const { classes } = this.props;

    return (
      <CardContent className={classes.content}>
        <ProjectsTable />
      </CardContent>
    );
  };

  getCurrentCandidateBoxContent = () => {
    const { classes } = this.props;

    return (
      <CardContent className={classes.content}>
        <CurrentCandidateBox />
      </CardContent>
    );
  };
  getRecommendProjectsBoxContent = () => {
    const { classes } = this.props;

    return (
      <CardContent className={classes.content}>
        <RecommendProjectsTable />
      </CardContent>
    );
  };

  componentDidMount() {
    this.props.setLastPage(PATHS.DASHBOARD.INDEX);
  }

  render() {
    const { t } = this.props;
    const projectBoxContent = this.getProjectBoxContent();
    const candidateRecommendProjectContent =
      this.getRecommendProjectsBoxContent();

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box
            title={t('dashboard:projectsTable.projectsTitle')}
            link={PATHS.PROJECT.INDEX}
            icons="true"
            elevation={6}
          >
            {projectBoxContent}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box
            title={t('dashboard:projectsTable.recommendProjectsTitle')}
            link={PATHS.PROJECT.INDEX}
            icons="true"
            elevation={6}
          >
            {candidateRecommendProjectContent}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            title={t('dashboard:ats.candidate')}
            link={PATHS.SUMMARY_FOR_CANDIDATE.INDEX.replace(
              ':filter',
              'candidateAll'
            )}
            icons="true"
            elevation={6}
          >
            <DashboardCandidateAts />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

DashboardCandidateIndexView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  setLastPage,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(DashboardCandidateIndexView)));
