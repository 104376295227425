import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import CitySeoService from 'shared/services/citySeo.service';
import { getEnumItemName } from 'shared/utils/assets';
import AddCitySeoForm from '../AddCitySeoForm/AddCitySeoForm';
import CustomDialog from '../CustomDialog';
import CustomIconButton from '../CustomIconButton';

export function CitySeoTable({ citiesSeo, shouldUpdateCitiesSeo, classes, t }) {
  const [citySeoData, setCitySeoData] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [citySeoSelected, setCitySeoSelected] = useState(null);

  const handleOpenEdit = id => {
    const citySeo = getCitySeoById(id);
    if (citySeo) {
      setCitySeoData(citySeo);
      setOpenAddDialog(true);
    }
  };

  const getCitySeoById = id => citiesSeo.find(citySeo => citySeo.id === id);

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setCitySeoData(null);
  };

  const handleAdded = () => {
    handleCloseAddDialog();
    shouldUpdateCitiesSeo();
  };

  const handleAddAction = () => {
    setCitySeoData(null);
    setOpenAddDialog(true);
  };

  const handleOpenDelete = id => {
    setCitySeoSelected(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setCitySeoSelected(null);
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    CitySeoService.deleteCitySeo(citySeoSelected)
      .then(() => {
        handleCloseDeleteDialog();
        shouldUpdateCitiesSeo();
      })
      .catch(error => {
        console.log(error);
        alert(t`citySeoTable:error`);
      });
  };

  const getCmsName = citySeoSelected => {
    const citySeo = getCitySeoById(citySeoSelected);
    if (citySeo) {
      return citySeo.name;
    }
    return '';
  };

  return (
    <div>
      <Table stickyheader="true" className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>{t`citySeoTable:name`}</TableCell>
            <TableCell>{t`citySeoTable:prop`}</TableCell>
            <TableCell>
              <CustomIconButton onClick={handleAddAction} type={'add'} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {citiesSeo.map(citySeo => (
            <TableRow key={citySeo.id}>
              <TableCell align={'center'}>{citySeo.id}</TableCell>
              <TableCell align={'center'}>{citySeo.name}</TableCell>
              <TableCell align={'center'}>
                {getEnumItemName(enums.URL_SEO_TYPES, citySeo.urlType)}
              </TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell}>
                <CustomIconButton
                  onClick={() => handleOpenEdit(citySeo.id)}
                  type={'edit'}
                />
                <CustomIconButton
                  onClick={() => handleOpenDelete(citySeo.id)}
                  type={'delete'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {openAddDialog && (
        <AddCitySeoForm
          isOpen={openAddDialog}
          citySeoData={citySeoData}
          onClose={handleCloseAddDialog}
          citSeoAdded={handleAdded}
        />
      )}
      <CustomDialog
        title={t`citySeoTable:delField`}
        onCloseDialog={handleCloseDeleteDialog}
        body={
          <>
            {t`citySeoTable:confirm`}

            {getCmsName(citySeoSelected)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDelete}>
              {t`citySeoTable:yes`}
            </Button>
            <Button
              color={'primary'}
              autoFocus
              onClick={handleCloseDeleteDialog}
            >
              {t`citySeoTable:no`}
            </Button>
          </>
        }
      />
    </div>
  );
}

export default withTranslation()(CitySeoTable);
