import { find } from 'lodash';

export const intNormalization = input => {
  if (!isNaN(parseInt(input, 10))) {
    return Math.round(parseInt(input, 10));
  } else {
    return null;
  }
};

export const int0to99Normalization = input => {
  if (!isNaN(parseInt(input, 10))) {
    if (parseInt(input, 10) < 0) {
      return 0;
    } else if (parseInt(input, 10) > 99) {
      return 99;
    } else {
      return Math.round(parseInt(input, 10));
    }
  } else {
    return null;
  }
};

export const int0UpNormalization = input => {
  if (!isNaN(parseInt(input, 10))) {
    if (parseInt(input, 10) < 0) {
      return 0;
    } else if (parseInt(input, 10) > 999999999) {
      return 999999999;
    } else {
      return Math.round(parseInt(input, 10));
    }
  } else {
    return null;
  }
};

export const normalizeMax200 = value => {
  const checkLenght = value;
  if (checkLenght.length <= 200) {
    return checkLenght;
  }
};

export const normalizeMax600 = value => {
  const checkLenght = value;
  if (checkLenght.length <= 600) {
    return checkLenght;
  }
};

export const checkIfExsistInObject = (object, word, fieldname) => {
  let isMatched = false;
  const findInObject = find(object, [fieldname, word]);
  if (findInObject) {
    isMatched = true;
  }
  return isMatched;
};

export const checkIfLinkIsFromYouTube = link => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = link.match(regExp);
  return match && match[7].length === 11 ? match[7] : link;
};

export const truncateToThreeDots = (input, maxLength) =>
  input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;
