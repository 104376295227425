import { LinearProgress, Grid, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { PATHS } from 'config';
import { LANDING_PAGE_URL } from 'shared/constants';
import LandingLayout from '../../../components/LandingLayout/';
import ResetPasswordForm from './../components/ResetPasswordForm/';

export class ResetPassword extends Component {
  state = {
    tokenUser: null,
  };

  async componentDidMount() {
    document.body.style.display = 'none';
    window.location = `${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.AUTH.RESET_PASSWORD.replace(
      ':token',
      this.props.match.params.token
    )}`;
    // this.props.getConfigs();
    // await this.validateToken(this.props.match.params.token);
  }

  async componentDidUpdate(nextProps) {
    if (nextProps.match.params.token !== this.props.match.params.token) {
      this.validateToken(nextProps.match.params.token);
    }
  }

  validateToken = async token => {
    const { data } = await this.props.validateResetPasswordToken(token);
    this.setState({
      tokenUser: data,
    });
  };

  handleSubmit = async values => {
    try {
      const { data } = await this.props.resetPassword(
        this.props.match.params.token,
        values
      );
      if (data) {
        this.props.showSnackbarMessage(this.props.t`register:passwordChanged`);
        this.props.history.push(PATHS.AUTH.LOGIN);
      }
    } catch (error) {
      this.props.showSnackbarMessage(
        this.props.t`register:error.changePassword`
      );
      console.log(error);
    }
  };

  render() {
    const { classes, config, t } = this.props;
    const { tokenUser } = this.state;

    return (
      <LandingLayout>
        <Grid item xs={11} md={8} xl={6} className={classes.vh80}>
          {tokenUser === false ? (
            <Paper style={{ padding: 30 }}>
              <Typography>{t`login:error.badToken`}</Typography>
            </Paper>
          ) : tokenUser ? (
            <ResetPasswordForm
              user={tokenUser}
              config={config}
              onSubmit={this.handleSubmit}
            />
          ) : (
            <LinearProgress className={classes.progress} />
          )}
        </Grid>
      </LandingLayout>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  authUser: PropTypes.func.isRequired,
  getConfigs: PropTypes.func.isRequired,
  validateResetPasswordToken: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default withTranslation()(ResetPassword);
