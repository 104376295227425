import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
// import { Select } from 'redux-form-material-ui';
import { changeFieldValue } from 'shared/services';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';
import AutosuggestComponent from '../inputs/AutoSuggest';
import validate from '../validate/validateHelpDesk';

class AddCompetenceForm extends React.Component {
  render() {
    const {
      handleSubmit,
      handleUpdateSkill,
      pristine,
      isSubmitting,
      dictionariesSkills,
      dictionariesCategories,
      dictionariesKinds,
      dictionariesTypes,
      t,
    } = this.props;

    const mapDictionariesToLanguage = (dictionary, language = 'name') => {
      return dictionary
        .filter(item => item[language] !== null)
        .map(item => {
          return { id: item.id, name: item[language] };
        });
    };

    const changeFieldLanguage = (formName, dictionaries, fieldName) => {
      this.props.changeFieldValue(
        formName,
        `${fieldName}.enName`,
        dictionaries['enName']
      );
      this.props.changeFieldValue(
        formName,
        `${fieldName}.deName`,
        dictionaries['deName']
      );
      this.props.changeFieldValue(
        formName,
        `${fieldName}.uaName`,
        dictionaries['uaName']
      );
    };

    const onChangeMainCompetenceCategory = (
      formName,
      fieldName,
      newValue,
      firstLoad = false
    ) => {
      const competenceCategory = dictionariesCategories.find(
        item => item.name === newValue
      );
      if (competenceCategory) {
        changeFieldLanguage(formName, competenceCategory, 'competenceCategory');
      }
    };

    const onChangeMainCompetenceKind = (
      formName,
      fieldName,
      newValue,
      firstLoad = false
    ) => {
      const competenceKind = dictionariesKinds.find(
        item => item.name === newValue
      );
      if (competenceKind) {
        changeFieldLanguage(formName, competenceKind, 'competenceKind');
      }
    };

    const onChangeMainCompetenceType = (
      formName,
      fieldName,
      newValue,
      firstLoad = false
    ) => {
      const competenceType = dictionariesTypes.find(
        item => item.name === newValue
      );
      if (competenceType) {
        changeFieldLanguage(formName, competenceType, 'competenceType');
      }
    };

    const onChangeMainSkill = (
      formName,
      fieldName,
      newValue,
      firstLoad = false
    ) => {
      const skill = dictionariesSkills.find(item => item.name === newValue);
      if (skill) {
        changeFieldLanguage(formName, skill, 'skill');
      }
    };

    return (
      <form onSubmit={handleSubmit(handleUpdateSkill)}>
        <div>
          <h5>{t`addCompetenceForm:competenceType`}</h5>
          <AutosuggestComponent
            onChange={onChangeMainCompetenceCategory}
            required={true}
            suggestionsList={dictionariesCategories}
            maxLength={255}
            fieldName={`competenceCategory.name`}
            fieldLabel={t`addCompetenceForm:category`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesCategories,
              'enName'
            )}
            maxLength={255}
            fieldName={`competenceCategory.enName`}
            fieldLabel={t`addCompetenceForm:categoryEn`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesCategories,
              'deName'
            )}
            maxLength={255}
            fieldName={`competenceCategory.deName`}
            fieldLabel={t`addCompetenceForm:categoryDe`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesCategories,
              'uaName'
            )}
            maxLength={255}
            fieldName={`competenceCategory.uaName`}
            fieldLabel={t`addCompetenceForm:categoryUa`}
          />
        </div>
        <div>
          <h5>{t`addCompetenceForm:competenceType`}</h5>
          <AutosuggestComponent
            onChange={onChangeMainCompetenceKind}
            required={true}
            suggestionsList={dictionariesKinds}
            maxLength={255}
            fieldName={`competenceKind.name`}
            fieldLabel={t`addCompetenceForm:type`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesCategories,
              'enName'
            )}
            maxLength={255}
            fieldName={`competenceKind.enName`}
            fieldLabel={t`addCompetenceForm:typeEn`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesCategories,
              'deName'
            )}
            maxLength={255}
            fieldName={`competenceKind.deName`}
            fieldLabel={t`addCompetenceForm:typeDe`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesCategories,
              'uaName'
            )}
            maxLength={255}
            fieldName={`competenceKind.uaName`}
            fieldLabel={t`addCompetenceForm:typeUa`}
          />
        </div>
        <div>
          <h5>{t`addCompetenceForm:competenceType2`}</h5>
          <AutosuggestComponent
            onChange={onChangeMainCompetenceType}
            required={true}
            suggestionsList={dictionariesTypes}
            maxLength={255}
            fieldName={`competenceType.name`}
            fieldLabel={t`addCompetenceForm:type2`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesTypes,
              'enName'
            )}
            maxLength={255}
            fieldName={`competenceType.enName`}
            fieldLabel={t`addCompetenceForm:type2En`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesTypes,
              'deName'
            )}
            maxLength={255}
            fieldName={`competenceType.deName`}
            fieldLabel={t`addCompetenceForm:type2De`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesTypes,
              'uaName'
            )}
            maxLength={255}
            fieldName={`competenceType.uaName`}
            fieldLabel={t`addCompetenceForm:type2Ua`}
          />
        </div>
        <div>
          <h5>{t`addCompetenceForm:skill`}</h5>
          <AutosuggestComponent
            onChange={onChangeMainSkill}
            required={true}
            suggestionsList={dictionariesSkills}
            maxLength={255}
            fieldName={`skill.name`}
            fieldLabel={t`addCompetenceForm:skill`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesSkills,
              'enName'
            )}
            maxLength={255}
            fieldName={`skill.enName`}
            fieldLabel={t`addCompetenceForm:skillEn`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesSkills,
              'deName'
            )}
            maxLength={255}
            fieldName={`skill.deName`}
            fieldLabel={t`addCompetenceForm:skillDe`}
          />
          <AutosuggestComponent
            required={true}
            suggestionsList={mapDictionariesToLanguage(
              dictionariesSkills,
              'uaName'
            )}
            maxLength={255}
            fieldName={`skill.uaName`}
            fieldLabel={t`addCompetenceForm:skillUa`}
          />
        </div>
        <Field
          name="isApproved"
          component={({ input }) => (
            <Select
              fullWidth
              {...input}
              onChange={event => input.onChange(event.target.value)}
            >
              <MenuItem
                value={false}
              >{t`addCompetenceForm:pendingApproval`}</MenuItem>
              <MenuItem value={true}>{t`addCompetenceForm:approved`}</MenuItem>
            </Select>
          )}
        ></Field>

        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
        >
          <ButtonJobllegrov1
            type="submit"
            color="primary"
            disabled={pristine || isSubmitting}
          >
            {t`addCompetenceForm:save`}
          </ButtonJobllegrov1>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      ...props.competence,
      isApproved: props.competence ? props.competence.isApproved : true,
    },
  };
};
const mapDispatchToProps = {
  changeFieldValue,
};
const AddCompetenceFormAction = reduxForm({
  form: 'addCompetenceForm',
  validate,
  enableReinitialize: true,
})(AddCompetenceForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddCompetenceFormAction));
