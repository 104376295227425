export const styles = () => ({
  bgBlue: { background: '#E5F0FF' },
  container: { padding: 25 },
  editBtn: { cursor: 'pointer' },
  hidden: { visibility: 'hidden' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  date: { opacity: '70%', fontWeight: 600 },
  icon: { margin: 0 },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  periodOfStay: { marginBottom: 20 },
  extendBtnDiv: { margin: '25px 0', display: 'flex', justifyContent: 'center' },
  extendBtn: {
    height: 50,
    width: 390,
    color: '#fff',
    background: '#2E9CFF',
    borderRadius: 30,
    '&:hover': {
      background: '#2E9CFF',
      opacity: 0.8,
    },
  },
  docsContainer: { padding: '25px 25px 0' },
  attachments: { fontWeight: 'bold', marginBottom: 20 },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  employmentStartDate: { position: 'relative' },
  error: { position: 'absolute', top: '25px', color: '#f41010' },
});
