import { API } from '../constants';
import { ApiService } from './api.service';

export const EmploymentManagementHistory = {
  getEmploymentManagementHistory(projectId) {
    return ApiService.get(
      API.GET.EMPLOYMENT_MANAGEMENT_HISTORY.GET_PROJECT_EMPLOYMENT_MANAGEMENT_HISTORY.replace(
        ':projectId',
        projectId
      )
    );
  },
  updateEmploymentManagementHistoryStage(body, employmentStatus) {
    return ApiService.put(
      API.PUT.EMPLOYMENT_MANAGEMENT_HISTORY.PUT_STAGE,
      body,
      {
        employmentStatus,
      }
    );
  },
};

export default EmploymentManagementHistory;
