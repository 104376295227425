import {
  Avatar,
  Button,
  CardActions,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import Box from './BoxContainer';
import ExpansionComponent from './LayoutExpansionComponent';
import ElementList from './list/ElementsList';

const styles = theme => ({
  container: {
    marginTop: 16,
  },
  jobTitle: {
    color: theme.palette.jobllegroBlue,
    fontSize: 18,
    paddingTop: 0,
    paddingBottom: 8,
    paddingLeft: 10,
  },
  actions: {
    borderTop: '1px solid #e9e9e9',
  },
  buttonShow: {
    background: '#F5F5F5',
    color: '#52a3fc',
    borderRadius: '0 0 10px 10px',
    width: '100%',
  },
  avatarOrganization: {
    width: 80,
    height: 80,
  },
});

export class OrganizationList extends Component {
  getContentOrganization = organization => {
    const { classes, t } = this.props;
    const avatar = getProjectAssetSrcByType(organization, 'logo');

    return (
      <React.Fragment>
        <List style={{ paddingBottom: 0 }}>
          <ListItem>
            <Avatar
              src={avatar}
              component={Link}
              to={PATHS.ORGANIZATION.SHOW.replace(':id', organization.id)}
              className={classes.avatarOrganization}
            ></Avatar>
            <ListItemText
              primary={organization.name}
              classes={{
                primary: classes.jobTitle,
              }}
            />
          </ListItem>
        </List>
        <CardActions className={classes.actions} disableSpacing>
          <Button
            component={Link}
            to={PATHS.ORGANIZATION.SHOW.replace(':id', organization.id)}
            color="primary"
            className={classes.buttonShow}
          >
            {t('general.details')}
          </Button>
        </CardActions>
      </React.Fragment>
    );
  };

  getContent = organization => {
    const contentOrganization = this.getContentOrganization(organization);

    return (
      <Grow in={true} key={organization.id}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Box>{contentOrganization}</Box>
        </Grid>
      </Grow>
    );
  };

  getWrapper = options => {
    const { t } = this.props;
    const { content, pagination, sorting, filtering } = options;
    return (
      <div>
        <ExpansionComponent
          title={t('filters.name')}
          textOnTop={t('filters.description')}
        >
          {filtering}
        </ExpansionComponent>
        {sorting}
        <Grid container>
          {content.length !== 0 ? (
            content
          ) : (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                component="h1"
                align="center"
                style={{ paddingTop: '15%', height: '50vh' }}
              >
                {t('organization:list.noDataFound')}
              </Typography>
            </Grid>
          )}
        </Grid>
        {pagination}
      </div>
    );
  };

  getElements = () => {
    return this.props.organizations;
  };
  render() {
    const { t } = this.props;

    let sortingOptions = [
      {
        name: 'id',
        label: t('organization:field.id'),
      },
      {
        name: 'name',
        label: t('organization:field.name'),
      },
    ];
    const defaultSorting = {
      id: 'asc',
    };
    const filteringOptions = [
      {
        key: 'name',
        type: 'text',
        name: t('organization:field.name'),
      },
      {
        key: 'description',
        type: 'text',
        name: t('organization:field.description'),
      },
    ];

    return (
      <ElementList
        storageKey={this.props.storageKey}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        getElements={this.getElements}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        sortingOptions={sortingOptions}
        defaultSorting={defaultSorting}
        filteringOptions={filteringOptions}
      />
    );
  }
}

OrganizationList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(OrganizationList));
