const labelStyles = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 20px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: 31,
  margin: 1,
});

export const styles = theme => ({
  root: { width: 0, height: 0, padding: 0, '& svg': { width: 0, height: 0 } },
  container: { padding: '0 16px' },
  labelRoot: { margin: 0, padding: '12px 8px 0 0' },
  label: { ...labelStyles() },
  labelChecked: {
    ...labelStyles(),
    border: `2px solid ${theme.palette.primary.main}`,
    margin: 0,
    fontWeight: 'bold',
  },
});
