import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getConfigs } from 'shared/services/configService';
import LoginForm from './LoginForm.style';
import { validate } from './validate';

const loginForm = reduxForm({
  form: 'loginForm',
  validate,
})(LoginForm);

const mapStateToProps = store => {
  return {
    config: store.auth.config,
  };
};

const mapDispatchToProps = {
  getConfigs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(loginForm));
