import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAssets, uploadAssets } from 'shared/services/assetService';
import AssetsUpload from '../../components/asset/AssetsUpload';

export class DebugIndexView extends Component {
  uploadAssets = async files => {
    return await this.props.uploadAssets(files);
  };

  getAssets = async () => {
    return await this.props.getAssets();
  };

  render() {
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          SearchIndex Page
        </Typography>
        <AssetsUpload
          uploadAssetsHandler={this.uploadAssets}
          getAssetsHandler={this.getAssets}
          filesLimit={99}
        >
          <Button>WYŚLIJ PLIKI</Button>
        </AssetsUpload>
      </div>
    );
  }
}

const mapDispatchToProps = {
  uploadAssets,
  getAssets,
};

DebugIndexView.propTypes = {
  classes: PropTypes.object.isRequired,
  uploadAssets: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(DebugIndexView);
