export const styles = ({ palette }) => ({
  container: { height: 80 },
  switchWrapper: { height: '100%' },
  textContainer: {
    width: 160,
    position: 'absolute',
    height: 45,
    '&:hover': { cursor: 'pointer' },
  },
  root: { width: 160 },
  bar: { height: 40, width: 160, borderRadius: 20, left: 17, top: 11 },
  icon: {
    width: 80,
    height: 36,
    borderRadius: 18,
    position: 'relative',
    left: -38,
  },
  switchBase: { width: 160 },
  checked: { transform: 'translateX(62px)' },
  activeText: { color: palette.primary.contrastText },
  nonActiveText: { color: palette.text.secondary },
});
