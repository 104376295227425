export const styles = () => ({
  date: { opacity: '70%', fontWeight: 500 },
  endOfEmployment: { color: '#F41313' },
  employment: { color: '#01D38B' },
  timeline: {
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '1fr 12px 1fr',
  },
  timelineComponent: {
    margin: '0 20px 65px',
  },
  timelineMiddle: {
    position: 'relative',
    background: '#c7e0f7',
  },
  timelineMiddleTop: { borderTopRightRadius: 50, borderTopLeftRadius: 50 },
  timelineMiddleBottom: {
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
  timelinePoint: {
    position: 'absolute',
    top: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 12,
    height: 12,
    background: '#148EFF',
    borderRadius: '50%',
  },
  timelinePointLong: {
    position: 'absolute',
    top: 7,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 12,
    height: 53,
    background: '#148EFF',
    borderTopRightRadius: 50,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
  timelinePointBottom: {
    top: 'initial',
    bottom: 0,
  },
  timelineDateRight: {
    textAlign: 'right',
  },
  timelineComponentBreakAtWork: {
    margin: '15px 20px 65px',
  },
  breakAtWork: { paddingTop: 15 },
});
