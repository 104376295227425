import { withStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import SocialLoginButton from './SocialLoginButton';

const GOOGLE_COLOR = '#f03b2c';
const FACEBOOK_COLOR = '#3b5998';
const LINKED_IN_COLOR = '#358198';

const styles = theme => ({
  leftIcon: {
    marginRight: 0,
  },
  root: {
    textTransform: 'none',
    color: 'white',
    width: '15rem',
    borderRadius: '0.5rem',
    margin: '0.5rem 1rem',
  },
  google: {
    backgroundColor: GOOGLE_COLOR,
    '&:hover': {
      backgroundColor: darken(GOOGLE_COLOR, 0.2),
    },
  },
  facebook: {
    backgroundColor: FACEBOOK_COLOR,
    '&:hover': {
      backgroundColor: darken(FACEBOOK_COLOR, 0.2),
    },
  },
  linkedIn: {
    backgroundColor: LINKED_IN_COLOR,
    '&:hover': {
      backgroundColor: darken(LINKED_IN_COLOR, 0.2),
    },
  },
});

export default withStyles(styles)(SocialLoginButton);
