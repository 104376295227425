import axios from 'axios';
import { API, API_URL } from '../constants';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getCmsConfigs = () => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.CMS_CONFIG.GET,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const deleteCmsConfigs = id => {
  return axios({
    method: 'DELETE',
    url: API_URL + API.DELETE.CMS_CONFIG.DELETE.replace(':id', id),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const addCmsConfigs = data => {
  return axios({
    method: 'POST',
    url: API_URL + API.POST.CMS_CONFIG.POST,
    data,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const updateCmsConfigs = (id, data) => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.CMS_CONFIG.PUT.replace(':id', id),
    data: data,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};
