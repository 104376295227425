import { Paper, LinearProgress, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getOrganizations } from 'shared/services/organizationService';
import OrganizationList from '../../components/OrganizationList';
import PageHeaderTitleComponent from '../../components/PageHeaderTitleComponent';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
});

export class OrganizationIndexView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.props.getOrganizations();
    if (this._isMounted) {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { isLoading } = this.state;

    const { currentOrganizationsList, classes, t } = this.props;
    return (
      <React.Fragment>
        <PageHeaderTitleComponent
          title={t('organization:list.organizationListTitle')}
        />
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Paper className={classes.rootPaper} elevation={0}>
            <OrganizationList
              organizations={currentOrganizationsList}
              storageKey={this.props.match.url}
            />
          </Paper>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentOrganizationsList: store.organizations.currentOrganizationsList,
  };
};

const mapDispatchToProps = {
  getOrganizations,
};

OrganizationIndexView.propTypes = {
  currentOrganizationsList: PropTypes.array.isRequired,
  getOrganizations: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(OrganizationIndexView)));
