import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import RadarChart from '../../../components/charts/RadarChart';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 64,
    paddingBottom: 64,
    marginTop: 0,
    paddingLeft: '0px !important',
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  }),
  textDescription: {
    fontSize: '16px',
    color: '#000',
    paddingBottom: 0,
    width: '100%',
  },
  textHeading: {
    paddingTop: 0,
    paddingBottom: 8,
    color: '#53a3fc',
    fontSize: '20px',
  },
  gridContainer: {
    marginBottom: 10,
  },
  chartContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const ProjectDetailsCompetences = props => {
  const { classes, projectDetails, t } = props;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.rootPaper} elevation={0}>
          <Typography
            component="h3"
            gutterBottom
            className={classes.textHeading}
          >
            {t('project:show.competencesSubTitleSoft')}
          </Typography>
          <div className={classes.chartContainer}>
            <RadarChart project={projectDetails} />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
  };
};

ProjectDetailsCompetences.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsCompetences)));
