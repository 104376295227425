import { Grid, withStyles } from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { array, number, object } from 'yup';
import Button from 'shared/newComponents/Button/Button';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import TextField from 'shared/newComponents/TextField';
import { RecruiterV2Service } from 'shared/services';
import { castObjectForSelect } from 'shared/utils/functions';
import CustomDialog from '../../../../../../../../../../components/CustomDialog';
import { styles } from './IndustriesExperiencesForm.styles';

function IndustriesExperiencesForm({
  recruiterData,
  isOpen,
  onClose,
  recruiterEdited,
  industryCategories,
  classes,
  t,
}) {
  const [
    experienceIndustryCategorySelectCard,
    setExperienceIndustryCategorySelectCard,
  ] = useState([]);

  const defaultExperienceIndustryCategoryCard = {
    industryCategory: '',
    experienceLevel: '',
  };

  const MODEL = {
    userIndustryCategoryExperiences: 'userIndustryCategoryExperiences',
  };

  useEffect(() => {
    if (
      recruiterData.userIndustryCategoryExperiences &&
      recruiterData.userIndustryCategoryExperiences.length !== 0
    ) {
      onAddExperienceIndustryCategoryExist(
        recruiterData.userIndustryCategoryExperiences
      );
    } else {
      onAddExperienceIndustryCategory();
    }
  }, [recruiterData]);

  const onAddExperienceIndustryCategoryExist =
    userExperienceIndustryCategory => {
      setExperienceIndustryCategorySelectCard(userExperienceIndustryCategory);
    };

  const onAddExperienceIndustryCategory = () => {
    setExperienceIndustryCategorySelectCard([
      ...experienceIndustryCategorySelectCard,
      defaultExperienceIndustryCategoryCard,
    ]);
    formik.setFieldValue(MODEL.userIndustryCategoryExperiences, [
      ...formik.values[MODEL.userIndustryCategoryExperiences],
      defaultExperienceIndustryCategoryCard,
    ]);
  };

  const onRemove = indexToRemove => {
    const filterDepartmentTable = experienceIndustryCategorySelectCard.filter(
      (item, index) => index !== indexToRemove
    );
    const filterDepartmentTableFromFormik = formik.values[
      MODEL.userIndustryCategoryExperiences
    ].filter((item, index) => index !== indexToRemove);

    setExperienceIndustryCategorySelectCard(filterDepartmentTable);
    formik.setFieldValue(
      `${MODEL.userIndustryCategoryExperiences}`,
      filterDepartmentTableFromFormik
    );
  };

  const isLastIndex = index => {
    return experienceIndustryCategorySelectCard.length === index + 1;
  };

  const formData = {
    userIndustryCategoryExperiences: recruiterData
      ? recruiterData.userIndustryCategoryExperiences
      : null,
  };

  const validationSchema = Yup.object().shape({
    userIndustryCategoryExperiences: array().of(
      object().shape({
        experienceLevel: number()
          .positive()
          .min(1, `${t`recruiter:departments`} 0`)
          .required(t('projectCreator:core.requiredField')),
        industryCategory: object().shape({
          id: number().required(t('projectCreator:core.requiredField')),
        }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: values => {
      const body = {
        userIndustryCategoryExperiences: values.userIndustryCategoryExperiences,
      };

      RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
        stage: 'industry_category_experiences_data',
      })
        .then(() => {
          recruiterEdited();
          onClose();
        })
        .catch(error => {
          console.log(error);
          alert(t`recruiter:saveError`);
        });
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`recruiter:industries`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <FormikProvider value={formik}>
            <FieldArray
              name={MODEL.userIndustryCategoryExperiences}
              render={() => {
                return experienceIndustryCategorySelectCard.map(
                  (item, index) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs={12} sm={12} md={6}>
                          <MultiSelect
                            options={castObjectForSelect(industryCategories)}
                            label={t`recruiter:industry`}
                            name={`${MODEL.userIndustryCategoryExperiences}[${index}].industryCategory`}
                            formik={formik}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <TextField
                            type={'number'}
                            label={t('candidateCreator:step2.experienceLevel')}
                            name={`${MODEL.userIndustryCategoryExperiences}[${index}].experienceLevel`}
                            formik={formik}
                          />
                        </Grid>

                        <Grid
                          container
                          className={classes.wrapLastIndex}
                          spacing={2}
                          alignItems="center"
                        >
                          {isLastIndex(index) ? (
                            <Grid item xs={12} sm={12} md={12}>
                              {index !== 0 ? (
                                <Grid item xs={12} sm={12} md={12}>
                                  <Button
                                    className={classes.wrapLastIndexBtn}
                                    fontColor={'red'}
                                    label={t(
                                      'candidateCreator:step2.removeExperience'
                                    )}
                                    onClick={() => onRemove(index)}
                                  />
                                </Grid>
                              ) : null}
                              <Grid item xs={12} sm={12} md={12}>
                                <Button
                                  label={t(
                                    'candidateCreator:step2.addExperience'
                                  )}
                                  onClick={onAddExperienceIndustryCategory}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={12} md={12}>
                              <Button
                                className={classes.wrapRemoveBtn}
                                fontColor={'red'}
                                label={t(
                                  'candidateCreator:step2.removeExperience'
                                )}
                                onClick={() => onRemove(index)}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    );
                  }
                );
              }}
            />
            <Grid
              container
              justifyContent="center"
              onClick={formik.handleSubmit}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Button label={t`recruiter:save`} type="submit" />
              </Grid>
            </Grid>
          </FormikProvider>
        </Grid>
      }
    />
  );
}

export default connect()(
  withStyles(styles)(withTranslation()(IndustriesExperiencesForm))
);
