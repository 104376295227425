import {
  Card,
  CardContent,
  CardActions,
  Typography,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { PATHS } from 'config';
import { ActionButtonJobllegroRoundedBig } from 'shared/utils/styledComponents';
import FacebookLogin from '../../../../components/FacebookLogin';
import GmailLogin from '../../../../components/GoogleLogin/GoogleLogin';

export default class RegisterForm extends Component {
  state = {
    employerStatus: 0,
  };
  render() {
    const {
      classes,
      config = {},
      onSubmit,
      handleSubmit,
      valid,
      userType,
      t,
    } = this.props;
    if (userType === 'candidate' && config['candidate_can_register'] !== '1') {
      return (
        <div className={classes.root}>
          <Typography component="h1">
            {t('register:error.registerBlockedCandidate')}
          </Typography>
        </div>
      );
    }
    if (userType === 'recruiter' && config['recruiter_can_register'] !== '1') {
      return (
        <div className={classes.root}>
          <Typography component="h1">
            {t('register:error.registerBlockedRecruiter')}
          </Typography>
        </div>
      );
    }

    const radioButton = ({ input, ...rest }) => (
      <FormControl>
        <RadioGroup {...input} {...rest}>
          <FormControlLabel
            value="0"
            control={<Radio color="default" />}
            label={t('register:form.employerStatusRecruiter')}
          />
          <FormControlLabel
            value="1"
            control={<Radio color="default" />}
            label={t('register:form.employerStatusEmployer')}
          />
          {/*<FormControlLabel value="2" control={<Radio color="default"/>} label={t('register:form.employerStatusRecruiterAndEmployer')} />*/}
        </RadioGroup>
      </FormControl>
    );

    return (
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className={classes.card} elevation={10}>
            <CardContent className={classes.cardContent}>
              <Typography
                className={classes.boxTitle}
                gutterBottom
                component="h2"
              >
                {userType === 'candidate'
                  ? t('register:form.registerCandidate')
                  : t('register:form.registerRecruiter')}
              </Typography>
              <Typography className={classes.boxText} component="p">
                <Trans i18nKey={'login:form.descriptionRegister'}>
                  x<Link to={PATHS.AUTH.LOGIN}>x</Link>x
                </Trans>
              </Typography>
              <Field
                className={classes.field}
                label={t('register:form.name')}
                name="firstName"
                component={TextField}
              />
              <Field
                className={classes.field}
                label={t('register:form.surname')}
                name="lastName"
                component={TextField}
              />
              <Field
                className={classes.field}
                label={t('register:form.email')}
                name="email"
                type="email"
                component={TextField}
              />
              <Field
                className={classes.field}
                label={t('register:form.password')}
                name="password"
                type="password"
                component={TextField}
              />
              <Field
                className={classes.field}
                label={t('register:form.repeatPassword')}
                name="passwordConfirmation"
                type="password"
                component={TextField}
              />

              {userType !== 'candidate' ? (
                <Field
                  name="employerStatus"
                  component={radioButton}
                  onChange={event =>
                    this.setState({
                      employerStatus: parseInt(event.target.value),
                    })
                  }
                />
              ) : null}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <ActionButtonJobllegroRoundedBig
                type="submit"
                name="login"
                disabled={!valid}
              >
                {t('login:form.submitButtonRegister')}
              </ActionButtonJobllegroRoundedBig>

              <div>
                <FacebookLogin
                  onSuccess={responseData =>
                    onSubmit({
                      ...responseData,
                      userType: userType,
                      employerStatus: this.state.employerStatus,
                    })
                  }
                  text={t`register:facebook`}
                />
                <GmailLogin
                  onSuccess={responseData =>
                    onSubmit({
                      ...responseData,
                      userType: userType,
                      employerStatus: this.state.employerStatus,
                    })
                  }
                  text={t`register:gmail`}
                />
                {/*<LinkedIn*/}
                {/*    onSuccess={(responseData) =>*/}
                {/*        onSubmit({...responseData, 'userType': userType, employerStatus: this.state.employerStatus})*/}
                {/*    }*/}
                {/*    text={'Zarejestruj się przez LinkedIn'}*/}
                {/*/>*/}
              </div>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

RegisterForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
};
