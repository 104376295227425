import { withStyles } from '@material-ui/core/styles';
import jobllegroBackground from 'shared/assets/img/jobllegro_background_subpage.jpg';
import LandingLayout from './LandingLayout';

const styles = theme => ({
  landingWrapper: {
    margin: 0,
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${jobllegroBackground})`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.only('xs')]: {
      height: 'auto',
    },
  },
  landingInnerWrapper: {
    marginTop: 0,
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh',
    },
    [theme.breakpoints.only('xs')]: {
      paddingBottom: '32px',
    },
  },
  icon: {
    maxWidth: 150,
    position: 'absolute',
    animationFillMode: 'backwards',
  },
  icon6: {
    top: '60vh',
    left: '10vw',
    animationName: 'moveInLeft',
    animationDuration: '2s',
    animationDelay: '1s',
  },
  icon5: {
    top: '71vh',
    right: '20vw',
    animationName: 'moveFromBottom',
    animationDuration: '1s',
    animationDelay: '0.4s',
  },
  icon1: {
    top: '35vh',
    right: '2vw',
    animationName: 'moveInRight',
    animationDuration: '1.7s',
    animationDelay: '1s',
  },
  icon2: {
    top: '38vh',
    right: '5vw',
    animationName: 'moveInRight',
    animationDuration: '1.5s',
    animationDelay: '1s',
  },
  icon3: {
    top: '50vh',
    right: '18vw',
    animationName: 'moveInRight',
    animationDuration: '1.2s',
    animationDelay: '1s',
  },
  icon4: {
    top: '25vh',
    right: '25vw',
    animationName: 'moveInRight',
    animationDuration: '2.5s',
    animationDelay: '1s',
  },
});

export default withStyles(styles, { withTheme: true })(LandingLayout);
