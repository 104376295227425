import { withStyles } from '@material-ui/core/styles';
import ResetPassword from './ResetPassword';

const styles = () => ({
  vh80: {
    height: '80vh',
  },
});

export default withStyles(styles)(ResetPassword);
