import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Checkbox } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import TextField from 'shared/newComponents/TextField';
import { IndustryCategoryService } from 'shared/services';
import CustomDialog from '../CustomDialog';

export function AddIndustryCategoryForm({
  industryCategoryData,
  isOpen,
  onClose,
  industryCategoryAdded,
}) {
  const { t } = useTranslation('addIndustryCategoryForm');
  const formData = {
    name: industryCategoryData ? industryCategoryData.name : '',
    enName: industryCategoryData ? industryCategoryData.enName : '',
    deName: industryCategoryData ? industryCategoryData.deName : '',
    uaName: industryCategoryData ? industryCategoryData.uaName : '',
    orderNumber: industryCategoryData
      ? industryCategoryData.orderNumber.toString()
      : '0',
    priceTypeList: industryCategoryData
      ? industryCategoryData.priceTypeList
      : '',
    ratio: industryCategoryData ? industryCategoryData.ratio.toString() : '',
    isCmsButton: industryCategoryData
      ? industryCategoryData.isCmsButton
      : false,
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().strict(false).trim().required(t('enterName')),
    enName: Yup.string().strict(false).trim().required(t('enterName')),
    deName: Yup.string().strict(false).trim().required(t('enterName')),
    uaName: Yup.string().strict(false).trim().required(t('enterName')),
    priceTypeList: Yup.string().strict(false).trim().required(t('priceType')),
    ratio: Yup.number()
      .min(0, 'Min 0')
      .max(100, 'Max 100')
      .required(t('factor')),
    isCmsButton: Yup.bool(),
    orderNumber: Yup.number().required(t('enterName')),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationShema,
    onSubmit: (values, actions) => {
      const data = {
        name: values.name,
        enName: values.enName,
        deName: values.deName,
        uaName: values.uaName,
        priceTypeList: values.priceTypeList,
        ratio: parseFloat(values.ratio),
        isCmsButton: values.isCmsButton,
        orderNumber: parseInt(values.orderNumber),
      };
      if (industryCategoryData) {
        IndustryCategoryService.replaceIndustryCategories(
          industryCategoryData.id,
          data
        )
          .then(() => {
            industryCategoryAdded();
          })
          .catch(error => {
            console.log(error);
            const response = error.response;
            const { status } = response;
            if (status === 409) {
              alert(t('taken'));
            } else {
              alert(t('error'));
            }
          });
      } else {
        IndustryCategoryService.addIndustryCategories(data)
          .then(() => {
            industryCategoryAdded();
          })
          .catch(error => {
            console.log(error);
            const response = error.response;
            const { status } = response;
            if (status === 409) {
              alert(t('taken'));
            } else {
              alert(t('error'));
            }
          });
      }
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t('industryEdition')}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid container>
                <TextField label={t('name')} name={'name'} formik={formik} />
              </Grid>
              <Grid container>
                <TextField
                  label={t('nameEn')}
                  name={'enName'}
                  formik={formik}
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('nameDe')}
                  name={'deName'}
                  formik={formik}
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('nameUa')}
                  name={'uaName'}
                  formik={formik}
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('priceType2')}
                  name={'priceTypeList'}
                  formik={formik}
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('factor2')}
                  name={'ratio'}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  label={t('visible')}
                  name={'isCmsButton'}
                  formik={formik}
                  type="switch"
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t('sortingFactor')}
                  name={'orderNumber'}
                  formik={formik}
                />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t('save')} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
}
export default connect()(AddIndustryCategoryForm);
