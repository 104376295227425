import { Grid, Typography, withStyles } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import AccommodationData from '../../../../../modules/project/EmploymentManagementView/components/EmploymentManagementDialog/components/AwaitingEmployment/components/AccommodationData/AccommodationData';
import AwaitingEmploymentLocationForm from '../../../../../modules/project/EmploymentManagementView/components/EmploymentManagementDialog/components/AwaitingEmployment/components/AwaitingEmploymentLocationForm/AwaitingEmploymentLocationForm';
import ProjectOrderLocalization from '../../../../../modules/project/NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalization';
import { WizardStep } from '../../../../newComponents';
import TextField from '../../../../newComponents/TextField';
import { HelpService } from '../../../../services';
import { styles } from './OfferAccommodation.styles';

const OfferAccommodation = ({
  classes,
  t,
  offeringHelpInfo,
  sectionSaved,
  selectedOfferingAccommodationInfo,
}) => {
  const [locationForm, setLocationForm] = useState(false);
  const [isAccommodationOpen, setIsAccommodationOpen] = useState(true);
  const [savedAccommodation, setSavedAccommodation] = useState(
    selectedOfferingAccommodationInfo
  );

  const formData = {
    periodStayStartDate: selectedOfferingAccommodationInfo
      ? selectedOfferingAccommodationInfo.periodStayStartDate
      : '',
    periodStayEndDate: selectedOfferingAccommodationInfo
      ? selectedOfferingAccommodationInfo.periodStayEndDate
      : '',
    accommodation: selectedOfferingAccommodationInfo
      ? {
          address: selectedOfferingAccommodationInfo.address,
          city: selectedOfferingAccommodationInfo.city,
          email: selectedOfferingAccommodationInfo.email,
          fivePersonRoom: selectedOfferingAccommodationInfo.fivePersonRoom,
          fourPersonRoom: selectedOfferingAccommodationInfo.fourPersonRoom,
          isAccessToPublicTransport:
            selectedOfferingAccommodationInfo.isAccessToPublicTransport,
          isAccessToStore: selectedOfferingAccommodationInfo.isAccessToStore,
          locationStandard: selectedOfferingAccommodationInfo.locationStandard,
          locationType: selectedOfferingAccommodationInfo.locationType,
          name: selectedOfferingAccommodationInfo.name,
          phone: selectedOfferingAccommodationInfo.phone,
          postCode: selectedOfferingAccommodationInfo.postCode,
          prefixPhone: selectedOfferingAccommodationInfo.prefixPhone,
          roomBathroom: selectedOfferingAccommodationInfo.roomBathroom,
          roomKitchen: selectedOfferingAccommodationInfo.roomKitchen,
          roomLaundry: selectedOfferingAccommodationInfo.roomLaundry,
          roomLivingRoom: selectedOfferingAccommodationInfo.roomLivingRoom,
          triplePersonRoom: selectedOfferingAccommodationInfo.triplePersonRoom,
          twoPersonRoom: selectedOfferingAccommodationInfo.twoPersonRoom,
        }
      : '',
  };

  const validationSchema = yup.object({
    periodStayStartDate: yup
      .date()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
    periodStayEndDate: yup.date().required(t`ats2ats3:validation.isRequired`),
    accommodation: yup
      .object()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
  });

  const dataHelpInfoAdded = () => {
    sectionSaved();
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({ periodStayStartDate, periodStayEndDate, accommodation }) => {
      if (
        moment(periodStayStartDate).isAfter(moment(periodStayEndDate), 'day')
      ) {
        formik.setFieldError(
          'periodStayStartDate',
          t`ats2ats3:validation.date`
        );
        return;
      }
      const {
        name,
        address,
        postCode,
        city,
        email,
        prefixPhone,
        phone,
        locationType,
        locationStandard,
        isAccessToPublicTransport,
        isAccessToStore,
        twoPersonRoom,
        triplePersonRoom,
        fourPersonRoom,
        fivePersonRoom,
        roomKitchen,
        roomBathroom,
        roomLivingRoom,
        roomLaundry,
      } = accommodation;

      const body = {
        periodStayStartDate,
        periodStayEndDate,
        name,
        address,
        postCode,
        city,
        email,
        prefixPhone,
        phone,
        locationType,
        locationStandard,
        isAccessToPublicTransport,
        isAccessToStore,
        twoPersonRoom,
        triplePersonRoom,
        fourPersonRoom,
        fivePersonRoom,
        roomKitchen,
        roomBathroom,
        roomLivingRoom,
        roomLaundry,
      };

      if (!savedAccommodation) {
        HelpService.postOfferingAccommodationInfo(offeringHelpInfo.id, body)
          .then(({ data }) => {
            setSavedAccommodation(data);
            dataHelpInfoAdded();
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        HelpService.putOfferingAccommodationInfo(savedAccommodation.id, body)
          .then(() => {
            dataHelpInfoAdded();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  });

  return (
    <>
      <WizardStep
        withSaveButton
        subInfo={savedAccommodation ? 'help:help.sectionSaved' : null}
        title={t('help:help.accommodationTitle')}
        isOpen={isAccommodationOpen}
        onCloseSelf={() => {
          setIsAccommodationOpen(false);
        }}
        onOpen={() => {
          setIsAccommodationOpen(true);
        }}
        onClose={() => {
          formik.submitForm();
        }}
      >
        <div className={classes.bg}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.container}>
              <div className={classes.padding}>
                <div className={classes.quarantinePeriod}>
                  <Typography variant="body1">
                    {t('help:help.accommodationDateRange')}
                  </Typography>
                </div>

                <Grid container className={classes.employmentDate}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid
                          container
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Grid item xs={1} sm={1} md={1}>
                            <PowerSettingsNew className={classes.powerOnBtn} />
                          </Grid>
                          <Grid item xs={9} sm={9} md={9}>
                            <TextField
                              type="date"
                              formik={formik}
                              name="periodStayStartDate"
                              variant="transparent"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid
                          container
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Grid item xs={1} sm={1} md={1}>
                            <PowerSettingsNew className={classes.powerOffBtn} />
                          </Grid>
                          <Grid item xs={9} sm={9} md={9}>
                            <TextField
                              type="date"
                              formik={formik}
                              name="periodStayEndDate"
                              variant="transparent"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {formik.values.accommodation ? (
                  <div
                    onClick={() => {
                      setLocationForm(true);
                    }}
                    className={classes.accommodationWrapper}
                  >
                    <AccommodationData
                      helpModal={true}
                      onClickEditAccommodation={() => {
                        setLocationForm(true);
                      }}
                      selectedAccommodation={formik.values.accommodation}
                    />
                  </div>
                ) : (
                  <Grid container className={classes.containerMargin}>
                    <Grid item xs={12}>
                      <ProjectOrderLocalization
                        helpModal={true}
                        handleOpenAddLocalization={() => setLocationForm(true)}
                      />
                    </Grid>
                  </Grid>
                )}
                {
                  <Typography style={{ color: 'red' }}>
                    {formik.submitCount > 0 && formik.errors.accommodation}
                  </Typography>
                }
              </div>
            </div>
          </form>
        </div>
      </WizardStep>
      {locationForm && (
        <AwaitingEmploymentLocationForm
          hideContractData
          hideAddPhoto
          project={{}}
          isOpen={locationForm}
          emitValuesOnSubmit={accommodationData => {
            formik.setFieldValue('accommodation', accommodationData);
            setLocationForm(false);
          }}
          onClose={() => {
            setLocationForm(false);
          }}
          accommodation={formik.values.accommodation}
        />
      )}
    </>
  );
};

export default withStyles(styles)(withTranslation()(OfferAccommodation));
