import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';

const styles = () => ({
  formControl: {
    minWidth: '50%',
  },
});

export const ProjectFormFooter = props => {
  const {
    activeStep,
    classes,
    submitting,
    valid,
    isEdited,
    handleBack,
    handleNext,
    t,
    isMentorEdited,
  } = props;

  return (
    <Grid
      item
      xs={12}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <ButtonJobllegrov1
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        {t('project:form.stepBack')}
      </ButtonJobllegrov1>
      <ButtonJobllegrov1 type="submit" disabled={submitting || !valid}>
        {isEdited
          ? t('project:form.saveProject')
          : t('project:form.addNewProject')}
      </ButtonJobllegrov1>
      {activeStep !== (isMentorEdited ? 2 : 3) && (
        <ButtonJobllegrov1
          disabled={activeStep === 3 || !valid}
          onClick={handleNext}
        >
          {t('project:form.stepForward')}
        </ButtonJobllegrov1>
      )}
    </Grid>
  );
};

ProjectFormFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(ProjectFormFooter));
