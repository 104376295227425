import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { get, toLower, find, has } from 'lodash';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { Translate } from '..';
import { styles } from './Autocomplete.style';

const Autocomplete = ({
  items,
  classes,
  label,
  formik,
  name,
  onComponentChange,
  disabled = false,
  touched = false,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState({ id: 0, name: '' });

  useEffect(() => {
    const newValue = {
      id: get(formik.values, `${name}.id`),
      name: get(formik.values, `${name}.name`),
      assets: get(formik.values, `${name}.assets`),
    };
    setValue(newValue);
  }, [get(formik.values, `${name}.name`)]);

  const getSuggestions = value => {
    const inputValue = toLower(get(value, 'value'));
    const inputLength = inputValue.length;
    return inputLength === 0
      ? items
      : items.filter(item => {
          return toLower(get(item, 'name')).indexOf(inputValue) !== -1;
        });
  };
  const onChange = (_, { newValue }) => {
    const newValueObj = newValue.trim().length ? { name: newValue } : undefined;

    const selectedItem = find(items, item => item.name === newValue);
    if (selectedItem) {
      formik.setFieldValue(name, {
        id: selectedItem.id,
        name: selectedItem.name,
        assets: selectedItem.assets,
      });
    } else {
      formik.setFieldValue(name, newValueObj);
    }
    if (onComponentChange) {
      onComponentChange();
    }
    setValue(newValueObj);
  };
  const onSuggestionsFetchRequested = value => {
    const filteredSuggestions = getSuggestions(value);
    setSuggestions(filteredSuggestions);
  };
  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.name}
      </MenuItem>
    );
  };
  const error = !touched
    ? has(formik.touched, name) && Boolean(get(formik.errors, name))
    : Boolean(get(formik.errors, name));

  return (
    <Grid
      container
      className={error ? classes.containerError : classes.container}
    >
      <FormControl fullWidth error={error} variant="outlined">
        {get(value, 'name') && (
          <div className={classes.floatingLabel}>
            <Translate text={label} variant="caption" />
          </div>
        )}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={suggestion => get(suggestion, 'name')}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={() => true}
          inputProps={{
            placeholder: label,
            value: get(value, 'name') || '',
            onChange,
            disabled: disabled,
          }}
        />
        {error && (
          <FormHelperText>
            {!touched
              ? has(formik.touched, name) && get(formik.errors, `${name}.name`)
              : get(formik.errors, `${name}.name`)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(Autocomplete);
