import { withStyles } from '@material-ui/core/styles';
import MenuRightDrawerRight from './MenuRightDrawerRight';

const styles = theme => ({
  menuConntent: {
    width: '280px',
    padding: '0 16px 0 16px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0px 20px 0px',
    ...theme.mixins.toolbar,
    width: 'auto',
  },
  logotype: {
    marginRight: 105,
    width: 55,
    height: 55,
  },
});
export default withStyles(styles)(MenuRightDrawerRight);
