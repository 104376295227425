import { Typography } from '@material-ui/core';
import React, { Component } from 'react';

export class ReportIndexView extends Component {
  render() {
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          ReportIndex Page
        </Typography>
      </div>
    );
  }
}

export default ReportIndexView;
