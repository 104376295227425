import { Grid, withStyles } from '@material-ui/core';
import { get, map } from 'lodash';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import { ProgressBar, Translate } from 'shared/newComponents';
import {
  getEnumItemName,
  getEnumItemNameFullObject,
  getUserAvatarSrc,
} from 'shared/utils/assets';
import { getCompetenceHeaderInfo } from 'shared/utils/functions';
import { MODEL } from '../../../CandidateCreateWizardStep2/CandidateCreateWizardStep2.utils';
import { styles } from './CandidateReviewStep2.styles';

const CandidateReviewStep2 = ({
  step1FormValues,
  step2FormValues,
  step3FormValues,
  step6FormValues,
  currentUser,
  classes,
  t,
}) => {
  const userExperiences = step2FormValues.userExperiences;
  const maxMonthlyWadges = step3FormValues.maxMonthlyWadges;
  const minMonthlyWadges = step3FormValues.minMonthlyWadges;
  const userSkills = step2FormValues.userSkills;
  const userLanguageSkills = step2FormValues.userLanguageSkills;
  const preferenceWorkArea = step1FormValues.isRemoteWorkOnly
    ? t('candidateCreator:step1.remoteWorkOnly')
    : step1FormValues.city;
  const availability = step1FormValues.availabilityDate
    ? step1FormValues.availabilityDate
    : getEnumItemName(
        enums.AVAILABILITY_CANDIDATE,
        step1FormValues.availabilityTypeCandidate
      );
  const homeCountry = step1FormValues.homeCountry
    ? step1FormValues.homeCountry.name
    : null;
  const isUserExperiencesSet =
    userExperiences &&
    userExperiences.length &&
    userExperiences[0].position !== '';

  const isUserSkillsSet = userSkills && userSkills.length;

  const selfEdit = currentUser.id === step1FormValues.id;

  const stage6Source = selfEdit ? step1FormValues : step6FormValues;

  const candidateRatingFromRecruiterDescription =
    stage6Source.candidateRatingFromRecruiterDescription;

  const candidateHasOwner = !!(stage6Source.owner && stage6Source.owner.id);

  const currentAvatar = candidateHasOwner
    ? getUserAvatarSrc(stage6Source.owner)
    : null;

  const isUserLanguageSkillsSet = !!(
    userLanguageSkills &&
    userLanguageSkills.length &&
    userLanguageSkills[0].experience !== ''
  );

  const isSalarySet =
    minMonthlyWadges !== null &&
    minMonthlyWadges !== undefined &&
    maxMonthlyWadges !== undefined &&
    maxMonthlyWadges !== null;

  const getSumUserExperiencesYears = userExperiencesArray => {
    let experiencesYears = 0;
    userExperiencesArray.forEach(
      item => (experiencesYears += item.experienceLevel)
    );
    return experiencesYears;
  };

  return (
    <>
      <Grid container className={classes.contentWrapper}>
        <Grid container style={{ paddingBottom: 12 }}>
          {isUserExperiencesSet ? (
            <Grid container>
              <Translate
                style={{
                  fontSize: 24,
                }}
                text={'candidateCreator:reviewStep2.experience'}
              />
              <Translate text="&nbsp;" variant="body1" />
              <Translate
                bold
                style={{
                  fontSize: 26,
                }}
                disableTranslate={true}
                text={` ${getSumUserExperiencesYears(userExperiences)}`}
              />
            </Grid>
          ) : null}
          {isSalarySet ? (
            <Grid container>
              <Translate
                bold
                style={{
                  fontSize: 24,
                }}
                disableTranslate={true}
                text={`${minMonthlyWadges}-${maxMonthlyWadges}`}
              />
              <Translate text="&nbsp;" variant="body1" />
              <Translate
                style={{
                  fontSize: 24,
                }}
                text={'candidateCreator:reviewStep2.netto'}
              />
            </Grid>
          ) : null}
          {isUserSkillsSet ? (
            <Grid
              container
              style={{
                marginTop: 26,
              }}
            >
              <Translate
                bold
                style={{
                  fontSize: 20,
                }}
                text={'candidateCreator:reviewStep2.keyCompetences'}
              />
              <Grid container style={{ paddingBottom: 16 }}>
                {map(userSkills, (skill, index) => {
                  if (!skill) {
                    return null;
                  }
                  const experienceLevel = get(skill, MODEL.experience) + 1;
                  const level =
                    experienceLevel * 10 < 100 ? experienceLevel * 10 : 100;
                  const headerInfo = getCompetenceHeaderInfo(
                    step2FormValues,
                    index,
                    t,
                    MODEL.userSkills,
                    'experience'
                  );

                  const secondHeaderInfo = getEnumItemNameFullObject(
                    enums.EXPERIENCE_LEVEL,
                    get(skill, MODEL.experience)
                  );

                  return (
                    <ProgressBar
                      key={index}
                      percentage={level}
                      title={headerInfo}
                      secondTitle={secondHeaderInfo}
                      customStyleSecondHeader={{
                        color: '#6CB8FF',
                      }}
                    />
                  );
                })}
              </Grid>
            </Grid>
          ) : null}
          {userExperiences && isUserLanguageSkillsSet ? (
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  style={{
                    fontSize: 20,
                  }}
                  bold
                  text={'candidateCreator:reviewStep2.experienceInIndustries'}
                />
                {userExperiences &&
                  userExperiences.map(item => {
                    if (!item.industryCategory) {
                      return null;
                    }
                    return (
                      <Grid
                        item
                        key={`experience_${item.id}`}
                        style={{ display: 'flex' }}
                      >
                        <Translate
                          style={{
                            fontSize: 16,
                          }}
                          disableTranslate={true}
                          text={`${
                            item.industryCategory.name
                              ? item.industryCategory.name
                              : item.industryCategory.label
                          }:`}
                        />
                        <Translate text="&nbsp;" variant="body1" />
                        <Translate
                          style={{
                            fontSize: 16,
                          }}
                          bold
                          disableTranslate={true}
                          text={item.experienceLevel}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  style={{
                    fontSize: 20,
                  }}
                  bold
                  text={'candidateCreator:reviewStep2.experienceLanguages'}
                />
                {userLanguageSkills &&
                  userLanguageSkills.map(item => {
                    if (item.skill.id === undefined) {
                      return [];
                    }

                    const languageSkill = getEnumItemNameFullObject(
                      enums.EXPERIENCE_LEVEL,
                      item.experience
                    );

                    if (item.skill && item.skill.name) {
                      return (
                        <Grid
                          item
                          key={`experience_${item.skill.id}`}
                          style={{ display: 'flex' }}
                        >
                          <Translate
                            style={{
                              fontSize: 16,
                            }}
                            disableTranslate={true}
                            text={`${item.skill.name}:`}
                          />
                          <Translate text="&nbsp;" variant="body1" />
                          <Translate
                            style={{
                              fontSize: 16,
                            }}
                            bold
                            disableTranslate={true}
                            text={t(languageSkill)}
                          />
                        </Grid>
                      );
                    }

                    if (item.competence && item.competence.skill) {
                      return (
                        <Grid
                          item
                          key={`experience_${item.competence.skill.id}`}
                          style={{ display: 'flex' }}
                        >
                          <Translate
                            style={{
                              fontSize: 16,
                            }}
                            disableTranslate={true}
                            text={`${
                              item.competence.skill.name
                                ? item.competence.skill.name
                                : ''
                            }:`}
                          />
                          <Translate text="&nbsp;" variant="body1" />
                          <Translate
                            style={{
                              fontSize: 16,
                            }}
                            bold
                            disableTranslate={true}
                            text={`${t(languageSkill)}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
              </Grid>
            </Grid>
          ) : null}
          {userExperiences && isUserLanguageSkillsSet ? (
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
          ) : null}
          <Grid container>
            {isSalarySet ? (
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  style={{
                    fontSize: 20,
                  }}
                  bold
                  text={'candidateCreator:reviewStep2.expectedSalary'}
                />
                <Grid item>
                  <Translate
                    style={{
                      fontSize: 16,
                    }}
                    text={`${minMonthlyWadges}-${maxMonthlyWadges} ${t(
                      'candidateCreator:reviewStep2.netto'
                    )}`}
                  />
                </Grid>
              </Grid>
            ) : null}
            {preferenceWorkArea ? (
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  style={{
                    fontSize: 20,
                  }}
                  bold
                  text={'candidateCreator:reviewStep2.preferenceWorkArea'}
                />
                <Grid item>
                  <Translate
                    style={{
                      fontSize: 16,
                    }}
                    text={preferenceWorkArea}
                    disableTranslate={true}
                  />
                </Grid>
              </Grid>
            ) : null}

            {availability ? (
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  style={{
                    fontSize: 20,
                  }}
                  bold
                  text={'candidateCreator:step1.availabilityTitle'}
                />
                <Grid item>
                  <Translate
                    style={{
                      fontSize: 16,
                    }}
                    text={availability}
                    disableTranslate={true}
                  />
                </Grid>
              </Grid>
            ) : null}

            {homeCountry ? (
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  bold
                  style={{
                    fontSize: 20,
                  }}
                  text={'candidateCreator:reviewStep2.homeCountry'}
                />
                <Grid item>
                  <Translate
                    style={{
                      fontSize: 16,
                    }}
                    text={homeCountry}
                    disableTranslate={true}
                  />
                </Grid>
              </Grid>
            ) : null}
            {candidateRatingFromRecruiterDescription ? (
              <Grid container>
                <hr className={classes.separator} />
              </Grid>
            ) : null}
            {candidateRatingFromRecruiterDescription ? (
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  style={{
                    fontSize: 20,
                  }}
                  bold
                  text={
                    'candidateCreator:reviewStep2.recruiterRatingDescription'
                  }
                />
                <Grid item>
                  <Translate
                    style={{
                      fontSize: 16,
                    }}
                    disableTranslate={true}
                    text={ReactHtmlParser(
                      candidateRatingFromRecruiterDescription
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            {candidateHasOwner ? (
              <Grid container>
                <hr className={classes.separator} />
              </Grid>
            ) : null}
            {candidateHasOwner ? (
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        style={{
                          fontSize: 20,
                        }}
                        bold
                        text={'candidateCreator:reviewStep2.candidateVerifyBy'}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems={'center'}
                    style={{ marginTop: 16 }}
                  >
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <img
                        alt="currentAvatar"
                        src={currentAvatar}
                        height={60}
                        style={{ borderRadius: 30 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {' '}
                      <Translate
                        style={{
                          fontSize: 16,
                        }}
                        disableTranslate={true}
                        text={`${stage6Source.owner.firstName} ${stage6Source.owner.lastName}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(withTranslation()(CandidateReviewStep2));
