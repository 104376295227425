import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import React from 'react';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const LayoutConfirmationDialog = props => {
  const handleCancel = () => {
    props.onClose(false);
  };

  const handleOk = () => {
    props.onClose(true);
  };

  const {
    titleText,
    children,
    cancelButtonText,
    okButtonText = '',
    ...other
  } = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      // onEntering={this.handleEntering}
      aria-labelledby="confirmation-dialog-title"
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelButtonText.length > 0 && (
          <Button onClick={handleCancel} color="primary">
            {cancelButtonText}
          </Button>
        )}
        {okButtonText !== '' && (
          <Button onClick={handleOk} color="primary">
            {okButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

LayoutConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  children: PropTypes.node,
  cancelButtonText: PropTypes.string,
  okButtonText: PropTypes.string,
};

export default LayoutConfirmationDialog;
