import { createAction } from '@reduxjs/toolkit';
import {
  SET_CURRENT_CANDIDATE,
  CLEAR_CURRENT_CANDIDATE,
  SET_CURRENT_CANDIDATES_LIST,
  SET_CURRENT_CANDIDATES_LIST_COUNT,
  CLEAR_CURRENT_CANDIDATES_LIST,
  SET_CANDIDATES_BELONGS_TO_RECRUITER_LIST,
  SET_CANDIDATES_PENDING_TO_RECRUITER_LIST,
  SET_CANDIDATES_IN_PROJECT,
  CLEAR_CANDIDATES_IN_PROJECT,
  SET_MY_CANDIDATES,
  CLEAR_MY_CANDIDATES,
  SET_CURRENT_CANDIDATES_LIMITED_LIST,
  CLEAR_CURRENT_CANDIDATES_LIMITED_LIST,
  SET_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER,
  CLEAR_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER,
} from '../actionConstants';

export const setCurrentCandidateAction = createAction(SET_CURRENT_CANDIDATE);
export const clearCurrentCandidateAction = createAction(
  CLEAR_CURRENT_CANDIDATE
);
export const setCurrentCandidatesListAction = createAction(
  SET_CURRENT_CANDIDATES_LIST
);
export const setCurrentCandidatesListCountAction = createAction(
  SET_CURRENT_CANDIDATES_LIST_COUNT
);
export const setCandidatesBelongsToRecruiterListAction = createAction(
  SET_CANDIDATES_BELONGS_TO_RECRUITER_LIST
);
export const setCandidatesPendingToRecruiterListAction = createAction(
  SET_CANDIDATES_PENDING_TO_RECRUITER_LIST
);
export const clearCurrentCandidatesListAction = createAction(
  CLEAR_CURRENT_CANDIDATES_LIST
);
export const setCandidatesInProjectAction = createAction(
  SET_CANDIDATES_IN_PROJECT
);
export const clearCandidatesInProjectAction = createAction(
  CLEAR_CANDIDATES_IN_PROJECT
);
export const setMyCandidatesAction = createAction(SET_MY_CANDIDATES);
export const clearMyCandidatesAction = createAction(CLEAR_MY_CANDIDATES);
export const setCurrentCandidatesLimitedListAction = createAction(
  SET_CURRENT_CANDIDATES_LIMITED_LIST
);
export const clearCurrentCandidatesLimitedListAction = createAction(
  CLEAR_CURRENT_CANDIDATES_LIMITED_LIST
);
export const setCurrentCandidatesLimitedListCounterAction = createAction(
  SET_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER
);
export const clearCurrentCandidatesLimitedListCounterAction = createAction(
  CLEAR_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER
);
