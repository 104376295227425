export const styles = ({ palette }) => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#2D333B',
  },
  dialogTitle: {
    '& h6': { fontWeight: '500' },
  },
  content: { background: '#E5F0FF' },
  country: { float: 'right', fontWeight: 600, paddingRight: 10 },
  textField: {
    color: '#000000',
    background: '#B7BEC8',
    borderRadius: 30,
    fontWeight: 600,
    fontSize: 17,
    pointerEvents: 'none',
  },
  header: { fontWeight: 'bold', padding: 10 },
  powerOnBtn: { background: '#01D38B', borderRadius: '50%', color: '#fff' },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  txtRight: { textAlign: 'right' },
  date: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  datePicker: { marginRight: 40 },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 40px',
    padding: 20,
  },
  fileSection: { marginTop: 10, display: 'flex', justifyContent: 'center' },
  warningMessage: {
    backgroundColor: palette.warning.main,
    padding: 16,
  },
  filePaper: {
    margin: '10px 20px',
    borderRadius: 10,
    padding: 15,
    width: '100%',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileIsAttach: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    textAlign: 'center',
  },
  fileName: {
    color: '#359CFF',
    fontSize: '1rem',
    wordBreak: 'break-all',
    textAlign: 'center',
  },
  addIcon: { textAlign: 'center' },
  delBtn: {
    display: 'flex',
    margin: '0 auto',
    borderRadius: 30,
    color: '#B7BEC8',
    '&:hover': { borderColor: '#F41313', color: '#F41313', background: '#fff' },
  },
  saveBtn: {
    display: 'flex',
    margin: '40px auto',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    color: '#fff',
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  localization: { fontWeight: 'bold', padding: 10, marginTop: 10 },
  attachments: { margin: 25 },
});
