import {
  Typography,
  Grid,
  MenuItem,
  withStyles,
  Select,
} from '@material-ui/core';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Field, FieldArray } from 'redux-form';
// import { Select } from 'redux-form-material-ui';
import {
  ButtonJobllegrov1,
  ButtonJobllegrov4,
} from 'shared/utils/styledComponents';
import { required } from 'shared/utils/validationRegexTest';
import AutosuggestComponent from './AutoSuggest';

const styles = () => ({
  card: {
    width: '100%',
  },
  selectField: {
    paddingTop: 16,
    width: 'calc(100% - 10px)',
    marginLeft: '10px',
  },
});

export class LanguagesInputField extends Component {
  getFieldsContent = ({ fields, meta: { touched, error, submitFailed } }) => {
    return (
      <React.Fragment>
        {fields.length > 0 && (
          <Grid container>
            <Grid item xs={8} sm={5} lg={5}>
              <Typography>{t('project:field.chooseLanguage')}</Typography>
            </Grid>
            <Grid item xs={4} sm={5} lg={5} style={{ paddingLeft: '10px' }}>
              <Typography>{t('project:field.chooseLanguageLevel')}</Typography>
            </Grid>
            <Grid item xs={2} sm={2} lg={2}></Grid>
          </Grid>
        )}
        {(touched || submitFailed) && error && <span>{error}</span>}
        {fields.map((fieldName, index) => (
          <Grid container key={index}>
            <Grid item xs={8} sm={5} lg={5}>
              <AutosuggestComponent
                suggestionsList={this.props.suggestionsList}
                maxLength={255}
                fieldName={`${fieldName}.language.name`}
                fieldLabel={this.props.fieldLabel}
                currentFieldValue={
                  fields.get(index).language && fields.get(index).language.name
                }
              />
            </Grid>
            <Grid item xs={4} sm={5} lg={5} style={{ display: 'flex' }}>
              <Field
                validate={[required]}
                name={`${fieldName}.level`}
                component={Select}
                type="number"
                className={this.props.classes.selectField}
              >
                <MenuItem value={1}>A1</MenuItem>
                <MenuItem value={2}>A2</MenuItem>
                <MenuItem value={3}>B1</MenuItem>
                <MenuItem value={4}>B2</MenuItem>
                <MenuItem value={5}>C1</MenuItem>
                <MenuItem value={6}>C2</MenuItem>
                <MenuItem value={7}>
                  {t('project:field.languageLevelNative')}
                </MenuItem>
              </Field>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              lg={2}
              style={{
                display: 'flex',
                alignContent: 'end',
                justifyContent: 'center',
              }}
            >
              <ButtonJobllegrov4
                style={{ paddingTop: 16 }}
                onClick={() => fields.remove(index)}
              >
                {t('action.remove')}
              </ButtonJobllegrov4>
            </Grid>
          </Grid>
        ))}
        <ButtonJobllegrov1
          style={{ marginTop: 10 }}
          variant="contained"
          onClick={() => fields.push({})}
        >
          {fields.length
            ? t('project:form.addAnotherLanguge')
            : t('project:form.addNewLanguge')}
        </ButtonJobllegrov1>
      </React.Fragment>
    );
  };

  render() {
    return (
      <FieldArray
        name={this.props.fieldName}
        component={this.getFieldsContent}
        label={this.props.fieldLabel}
      />
    );
  }
}

LanguagesInputField.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestionsList: PropTypes.array.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  currentFieldValue: PropTypes.array.isRequired,
};

export default withStyles(styles)(withTranslation()(LanguagesInputField));
