export const styles = theme => ({
  atsSubtext: {
    display: 'inline-flex',
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 64,
    color: '#2D333B',
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modifyBtn: {
    color: '#01D38B',
    borderRadius: 30,
    borderColor: '#01D38B',
    pointerEvents: 'none',
  },
  modifyBtnActive: {
    pointerEvents: 'auto',
    color: '#fff',
    background: '#01D38B',
    '&:hover': { background: '#01D38B', opacity: 0.8 },
  },
  cancelBtn: {
    color: '#F41313',
    marginLeft: 20,
    marginRight: 34,
    borderRadius: 30,
    borderColor: '#F41313',
    pointerEvents: 'none',
  },
  cancelBtnActive: {
    pointerEvents: 'auto',
    color: '#fff',
    background: '#F41313',
    '&:hover': { background: '#F41313', opacity: 0.8 },
  },
  newOrderBtn: {
    marginRight: 20,
    borderRadius: 30,
    color: '#fff',
    background: '#148EFF',
    '&:hover': { background: '#148EFF', opacity: 0.8 },
  },
  margin: { marginBottom: 40 },
});
