import { useFormik } from 'formik';
import { filter, get } from 'lodash';
import { PATHS } from 'config';
import { enums } from 'shared/enums';
import { OrganizationService, ProjectV2Service } from 'shared/services';
import { checkIfLinkIsFromYouTube } from 'shared/utils/normalizers';
import {
  initialValues as step1InitialValues,
  validationSchema as step1ValidationSchema,
} from '../ProjectCreateWizardStep1/ProjectCreateWizardStep1.utils';
import { validationSchema as step2ValidationSchema } from '../ProjectCreateWizardStep2/ProjectCreateWizardStep2.utils';
import { validationSchema as step3ValidationSchema } from '../ProjectCreateWizardStep3/ProjectCreateWizardStep3.utils';
import { validationSchema as step4ValidationSchema } from '../ProjectCreateWizardStep4/ProjectCreateWizardStep4.utils';
import { validationSchema as step5ValidationSchema } from '../ProjectCreateWizardStep5/ProjectCreateWizardStep5.utils';
import { validationSchema as step6ValidationSchema } from '../ProjectCreateWizardStep6/ProjectCreateWizardStep6.utils';
import { validationSchema as step7ValidationSchema } from '../ProjectCreateWizardStep7/ProjectCreateWizardStep7.utils';
import { validationSchema as step8ValidationSchema } from '../ProjectCreateWizardStep8/ProjectCreateWizardStep8.utils';

export const isTrue = value => value === 'true';

export const stage2NotSetFields = (initialProjectValues, step2FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  const { requirements, workStyles, projectSkills } = step2FormValues;
  return (
    requirements.length === 0 &&
    workStyles.length === 0 &&
    projectSkills.length === 0
  );
};

export const stage3NotSetFields = (initialProjectValues, step3FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  const {
    isRequireAttachmentCv,
    isRequireAttachmentRecruiterApproved,
    isRequireAttachmentPassportStampScan,
    isRequireAttachmentPassportPersonalDataScan,
    isRequireAttachmentWorkPermitScan,
    isRequireAttachmentPassportVisaScan,
    isRequireAttachmentWorkQualificationScan,
    isRequireAttachmentCertificateAndDiplomaScan,
    isRequireAttachmentOtherScan,
    isRequireAttachmentOtherDesc,
  } = step3FormValues;
  return (
    !isRequireAttachmentCv &&
    !isRequireAttachmentRecruiterApproved &&
    !isRequireAttachmentPassportStampScan &&
    !isRequireAttachmentPassportPersonalDataScan &&
    !isRequireAttachmentWorkPermitScan &&
    !isRequireAttachmentPassportVisaScan &&
    !isRequireAttachmentWorkQualificationScan &&
    !isRequireAttachmentCertificateAndDiplomaScan &&
    !isRequireAttachmentOtherScan &&
    !isRequireAttachmentOtherDesc
  );
};

export const stage4NotSetFields = (initialProjectValues, step5FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  const { positionDescription, benefits } = step5FormValues;
  return positionDescription.length === 0 && benefits.length === 0;
};

export const stage5NotSetFields = (initialProjectValues, step5FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  const { companyDescription, videoUrl } = step5FormValues;
  return companyDescription.length === 0 && videoUrl.length === 0;
};

export const stage6NotSetFields = (initialProjectValues, step6FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }

  const {
    dataProcessingAgreement,
    dataProcessingAgreementUrl,
    clauseGDPRUrl,
    clauseGDPR,
  } = step6FormValues;
  if (
    !dataProcessingAgreement &&
    !dataProcessingAgreementUrl &&
    !clauseGDPRUrl &&
    !clauseGDPR
  ) {
    return true;
  }
  return (
    dataProcessingAgreement &&
    dataProcessingAgreement.length === 0 &&
    dataProcessingAgreementUrl &&
    dataProcessingAgreementUrl.length === 0 &&
    clauseGDPRUrl &&
    clauseGDPRUrl.length === 0 &&
    clauseGDPR &&
    clauseGDPR.length === 0
  );
};

export const stage7NotSetFields = (initialProjectValues, step7FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  const { hintForRecruiter, projectEndDate, projectStartDate, mentorFee } =
    step7FormValues;
  if (!hintForRecruiter && !projectEndDate && !mentorFee && !projectStartDate) {
    return true;
  }
  return (
    hintForRecruiter &&
    hintForRecruiter.length === 0 &&
    projectEndDate &&
    projectEndDate.length === 0 &&
    projectStartDate &&
    projectStartDate.length === 0 &&
    mentorFee &&
    mentorFee.length === 0
  );
};

export const stage8NotSetFields = (initialProjectValues, step8FormValues) => {
  if (!initialProjectValues.id) {
    return true;
  }
  return step8FormValues.organizationToBe === null;
};

export const useFormStep1 = ({
  t,
  initialProjectValues,
  history,
  setStep7FormValues,
}) =>
  useFormik({
    //TODO: Remove after debug
    initialValues: initialProjectValues.id
      ? initialProjectValues
      : step1InitialValues,
    validationSchema: step1ValidationSchema(
      t,
      !!initialProjectValues.id,
      initialProjectValues
    ),
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const projectV2Data = {
        city: values.city.trim(),
        client: values.client.trim(),
        clientUrl: values.clientUrl.trim(),
        companySize: parseInt(values.companySize),
        country: values.country,
        currency: values.currency,
        employmentStartDate: values.employmentStartDate,
        employmentTypes: values.employmentTypes,
        expectedWorkTime: values.expectedWorkTime.toString(),
        industryCategory: values.industryCategory,
        department: values.department,
        isClientPublic: values.isClientPublic,
        isRemoteWork: values.isRemoteWork,
        isSalaryPublic: values.isSalaryPublic,
        maxSalary: values.maxSalary,
        minSalary: values.minSalary,
        position: values.position.trim(),
        salaryType: parseInt(values.salaryType),
        typeOfPay: parseInt(values.typeOfPay),
        workHourType: values.workHourType,
        visibility: parseInt(values.visibility),
        calcCandidateExperience: values.calcCandidateExperience,
      };
      if (!initialProjectValues.id) {
        ProjectV2Service.addProjectV2(projectV2Data)
          .then(({ data }) => {
            localStorage.setItem('isDataSave', 'true');
            localStorage.setItem('isNewProject', 'true');
            history.push(PATHS.PROJECT.NEW_EDIT.replace(':id', data.id));
            window.location.reload();
          })
          .catch(error => {
            console.log(error);
            alert(t`projectCreator:loadDataError`);
          });
      } else {
        ProjectV2Service.editProjectV2(initialProjectValues.id, projectV2Data, {
          stage: 1,
        })
          .then(({ data }) => {
            setStep7FormValues({
              ...data,
              recruitmentAreas: castToMultiSelect(
                data.recruitmentAreas,
                'name',
                'id'
              ),
            });
          })
          .catch(error => {
            console.log(error);
            alert(t`projectCreator:loadDataError`);
          });
      }
    },
  });

export const useFormStep2 = ({ t, initialProjectValues, setCurrentStage }) =>
  useFormik({
    initialValues: initialProjectValues,
    validationSchema: step2ValidationSchema(t),
    enableReinitialize: true,
    onSubmit: values => {
      const projectV2Data = {
        projectSkills: values.projectSkills.filter(item => item !== undefined),
        requirements: values.requirements.trim(),
        workStyles: values.workStyles,
      };
      ProjectV2Service.editProjectV2(initialProjectValues.id, projectV2Data, {
        stage: 2,
      })
        .then(({ data }) => {
          if (data.currentStage) {
            setCurrentStage(data.currentStage);
          }
        })
        .catch(error => {
          console.log(error);
          alert(t`projectCreator:loadDataError`);
        });
    },
  });

export const useFormStep3 = ({ t, initialProjectValues, setCurrentStage }) =>
  useFormik({
    initialValues: initialProjectValues,
    validationSchema: step3ValidationSchema(t),
    enableReinitialize: true,
    onSubmit: values => {
      const projectV2Data = {
        isRequireAttachmentCv: values.isRequireAttachmentCv,
        isRequireAttachmentRecruiterApproved:
          values.isRequireAttachmentRecruiterApproved,
        isRequireAttachmentPassportStampScan:
          values.isRequireAttachmentPassportStampScan,
        isRequireAttachmentPassportPersonalDataScan:
          values.isRequireAttachmentPassportPersonalDataScan,
        isRequireAttachmentWorkPermitScan:
          values.isRequireAttachmentWorkPermitScan,
        isRequireAttachmentPassportVisaScan:
          values.isRequireAttachmentPassportVisaScan,
        isRequireAttachmentWorkQualificationScan:
          values.isRequireAttachmentWorkQualificationScan,
        isRequireAttachmentCertificateAndDiplomaScan:
          values.isRequireAttachmentCertificateAndDiplomaScan,
        isRequireAttachmentOtherScan: values.isRequireAttachmentOtherScan,
        isRequireAttachmentOtherDesc: values.isRequireAttachmentOtherScan
          ? values.isRequireAttachmentOtherDesc
          : null,
      };
      ProjectV2Service.editProjectV2(initialProjectValues.id, projectV2Data, {
        stage: 3,
      })
        .then(({ data }) => {
          if (data.currentStage) {
            setCurrentStage(data.currentStage);
          }
        })
        .catch(error => {
          console.log(error);
          alert(t`projectCreator:loadDataError`);
        });
    },
  });

export const useFormStep4 = ({ t, initialProjectValues }) =>
  useFormik({
    initialValues: initialProjectValues,
    validationSchema: step4ValidationSchema(t),
    enableReinitialize: true,
    onSubmit: values => {
      const projectV2Data = {
        positionDescription: values.positionDescription,
        benefits: values.benefits,
      };
      ProjectV2Service.editProjectV2(initialProjectValues.id, projectV2Data, {
        stage: 4,
      })
        .then(({ data }) => {})
        .catch(error => {
          console.log(error);
          alert(t`projectCreator:loadDataError`);
        });
    },
  });

export const useFormStep5 = ({
  t,
  initialProjectValues,
  step5Assets,
  setAssets,
}) =>
  useFormik({
    initialValues: initialProjectValues,
    validationSchema: step5ValidationSchema(t),
    enableReinitialize: true,
    onSubmit: values => {
      const assets = filter(step5Assets, o => o !== undefined);
      const assetsIds = [];
      const oldAssetsId = initialProjectValues.assets.map(asset => asset.id);

      assets.forEach(asset => {
        asset.forEach(item => {
          if (!oldAssetsId.includes(item.id)) {
            assetsIds.push(item.id);
          }
        });
      });

      const projectV2DataAssets = {
        assets: assetsIds,
      };
      const videoUrl = checkIfLinkIsFromYouTube(values.videoUrl);
      const projectV2Data = {
        companyDescription: values.companyDescription,
        videoUrl: videoUrl,
      };
      ProjectV2Service.editProjectV2(initialProjectValues.id, projectV2Data, {
        stage: 5,
      })
        .then(() => {
          ProjectV2Service.editProjectV2Assets(
            initialProjectValues.id,
            projectV2DataAssets
          );
          setAssets(assets);
        })
        .catch(error => {
          console.log(error);
          alert(t`projectCreator:loadDataError`);
        });
    },
  });

export const useFormStep6 = ({ t, initialProjectValues }) =>
  useFormik({
    initialValues: initialProjectValues,
    validationSchema: step6ValidationSchema(t),
    enableReinitialize: true,
    onSubmit: values => {
      {
        const projectV2Data = {
          dataProcessingAgreement: values.dataProcessingAgreement,
          dataProcessingAgreementUrl: values.dataProcessingAgreementUrl,
          clauseGDPRUrl: values.clauseGDPRUrl,
          clauseGDPR: values.clauseGDPR,
        };
        ProjectV2Service.editProjectV2(initialProjectValues.id, projectV2Data, {
          stage: 6,
        })
          .then(({ data }) => {})
          .catch(error => {
            console.log(error);
            alert(t`projectCreator:loadDataError`);
          });
      }
    },
  });

export const castToMultiSelect = (values, labelName, valueName) => {
  if (values === null || values === undefined) {
    return [];
  }
  return values.map(item => {
    return {
      label: item[labelName],
      value: item[valueName],
      id: item[valueName],
    };
  });
};

export const castFromMultiSelectToApi = values => {
  if (values === null || values === undefined) {
    return [];
  }
  return values.map(item => {
    return {
      id: item.value,
    };
  });
};

export const useFormStep7 = ({
  t,
  initialProjectValues,
  setStep7FormValues,
  currentUser,
  recruitmentPayment,
}) =>
  useFormik({
    initialValues: {
      ...initialProjectValues,
    },
    validationSchema: step7ValidationSchema(
      t,
      initialProjectValues,
      currentUser,
      recruitmentPayment
    ),
    enableReinitialize: true,
    onSubmit: values => {
      const projectV2Data = {
        calcServiceCompletionDate: values.calcServiceCompletionDate,
        calcPaymentDeadline: values.calcPaymentDeadline,
        calcCandidateWarranty: values.calcCandidateWarranty,
        hintForRecruiter: values.hintForRecruiter,
        projectEndDate: values.projectEndDate,
        projectStartDate: values.projectStartDate,
        revenueSubmitted: values.revenueSubmitted,
        revenueWinner: values.revenueWinner,
        mentorFee: values.mentorFee,
        recruitmentAreas: castFromMultiSelectToApi(values.recruitmentAreas),
      };
      ProjectV2Service.editProjectV2(initialProjectValues.id, projectV2Data, {
        stage: 7,
      })
        .then(({ data }) => {
          setStep7FormValues({
            ...data,
            recruitmentAreas: castToMultiSelect(
              data.recruitmentAreas,
              'name',
              'id'
            ),
          });
        })
        .catch(error => {
          console.log(error);
          alert(t`projectCreator:loadDataError`);
        });
    },
  });

export const useFormStep8 = ({
  t,
  initialProjectValues,
  finicalOrderPlanBody,
  setDateSaved,
  selectedOrganizationMentor,
}) =>
  useFormik({
    initialValues: initialProjectValues,
    validationSchema: step8ValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const savesStatus = { error: false };
      const forceReload = { status: false };
      if (!(values.organizationToBe && values.organizationToBe.id)) {
        const dataRequest = {
          name: values.organizationToBe.name,
          description: '',
          percent: 0,
          isPublic: true,
          invoiceAddress: values.invoiceAddress,
          invoiceCompanyName: values.organizationToBe.name,
          invoicePostCode: values.invoicePostCode,
          accountNumber: values.accountNumber,
          invoiceCity: values.invoiceCity,
          invoiceIdentificationNumber: values.invoiceTypeBusiness
            ? values.invoiceIdentificationNumber
            : null,
          invoicePersonalNumber: values.invoiceTypeBusiness
            ? null
            : values.invoicePersonalNumber,
          organizationType: values.invoiceTypeBusiness
            ? enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
            : enums.ORGANIZATION_TYPES.ORGANIZATION_PERSONAL_TYPE,
        };
        try {
          forceReload.status = true;
          const organizationResponse =
            await OrganizationService.addOrganization(dataRequest);
          await updateStage8(
            initialProjectValues,
            {
              organizationToBe: { id: organizationResponse.data.id },
            },
            t
          );
        } catch (error) {
          savesStatus.error = true;
          const { status } = error.response;
          if (status === 400) {
            alert(t`projectCreator:gotOrg`);
            return actions.setFieldError(
              'organizationToBe',
              t`projectCreator:gotOrg`
            );
          }
          alert(t`projectCreator:loadDataError`);
        }
      }

      if (values.organizationToBe && values.organizationToBe.id) {
        if (
          !(
            initialProjectValues.organizationToBe &&
            values.organizationToBe.id ===
              initialProjectValues.organizationToBe.id
          )
        ) {
          try {
            await updateStage8(initialProjectValues, values, t);
          } catch (error) {
            savesStatus.error = true;
            console.log(error);
            alert(t`projectCreator:loadDataError`);
          }
        }
      }

      if (finicalOrderPlanBody) {
        try {
          await updateStage8FinicalValues(
            initialProjectValues,
            finicalOrderPlanBody,
            t
          );
        } catch (error) {
          savesStatus.error = true;
          console.log(error);
          alert(t`projectCreator:loadDataError`);
        }
      }

      if (selectedOrganizationMentor && initialProjectValues.isDraft) {
        try {
          const projectV2Data = {
            mentorId: selectedOrganizationMentor.id,
          };
          await ProjectV2Service.editProjectV2(
            initialProjectValues.id,
            projectV2Data,
            {
              stage: 'organizationMentor',
            }
          );
        } catch (error) {
          savesStatus.error = true;
          console.log(error);
          alert(t`projectCreator:loadDataError`);
        }
      }

      if (!savesStatus.error) {
        setDateSaved(true);
      }

      if (forceReload.status) {
        window.location.reload();
      }
    },
  });

const updateStage8 = (initialProjectValues, values, t) => {
  const projectV2Data = {
    organizationToBe: { id: get(values, 'organizationToBe.id') },
  };

  return ProjectV2Service.editProjectV2(
    initialProjectValues.id,
    projectV2Data,
    {
      stage: 8,
    }
  ).catch(error => {
    console.log(error);
    alert(t`projectCreator:loadDataError`);
  });
};

const updateStage8FinicalValues = (
  initialProjectValues,
  finicalOrderPlanBody,
  t
) => {
  return ProjectV2Service.editProjectV2(
    initialProjectValues.id,
    finicalOrderPlanBody,
    {
      stage: 'finicalValues',
    }
  ).catch(error => {
    console.log(error);
    alert(t`projectCreator:loadDataError`);
  });
};
