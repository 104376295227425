import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import ProjectDetailsViewLeftDescription from '../../../../../components/ProjectDetailsViewLeftDescription';
import { styles } from './ProjectOrderInfo.styles';

const ProjectOrderInfo = ({ project }) => {
  return (
    <Grid container alignItems="flex-start" spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ProjectDetailsViewLeftDescription project={project} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ProjectOrderInfo);
