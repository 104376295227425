import {
  Typography,
  Card,
  CardHeader,
  Avatar,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { getUserAvatarSrc } from 'shared/utils/assets';
import { getHiringManagerStatusInProject } from 'shared/utils/functions';
import { styles } from './List.styles';

const List = ({ t, classes, projectDetails, history, hm = false }) => {
  const { projectHiringManagers, position, id, projectMentors } =
    projectDetails;

  const handleClickRedirect = (recruiterID, projectID, position) => {
    history.push({
      pathname: PATHS.RECRUITER.SHOW.replace(':id', recruiterID),
      state: {
        subject: `${t`candidate:show.project`}: ${projectID} ${position}`,
      },
    });
  };

  return (
    <div className={classes.container}>
      <Typography
        className={`${classes.cardTopSectionTitle} ${!hm && classes.mentor}`}
      >
        {hm
          ? t`project:menu.main.hiringManager`
          : t`organization:field.mentorValue`}
      </Typography>
      {(hm ? projectHiringManagers : projectMentors).map(manager => (
        <Card className={classes.card} key={manager.id}>
          <CardHeader
            className={classes.cardHeader}
            classes={{
              title: classes.titleCardHeader,
              subheader: classes.titleCardSubHeader,
              avatar: classes.avatarTitleCardHeader,
            }}
            onClick={() =>
              handleClickRedirect(manager.recruiter.id, id, position)
            }
            avatar={
              <Avatar
                className={classes.cardHeaderAvatar}
                src={getUserAvatarSrc(manager.recruiter)}
              />
            }
            subheader={t(
              getHiringManagerStatusInProject(manager.confirmationStatus)
            )}
            title={
              manager.recruiter.firstName + ' ' + manager.recruiter.lastName
            }
          />
        </Card>
      ))}
    </div>
  );
};

export default withRouter(withStyles(styles)(withTranslation()(List)));
