import axios from 'axios';
import { push } from 'react-router-redux';
import { PATHS } from 'config';
import { API, API_URL } from '../constants';
import {
  clearCurrentRecruiterAction,
  clearCurrentRecruitersListAction,
  setCurrentRecruiterAction,
  setCurrentRecruitersListAction,
  clearMyRecruitersAction,
  setRecruitersPendingToOrganizationListAction,
  setCurrentRecruitersLimitedListAction,
  setCurrentRecruitersLimitedListCounterAction,
  clearCurrentRecruitersLimitedListAction,
  clearCurrentRecruitersLimitedListCounterAction,
  clearRecruitersInProjectAction,
} from '../store/recruiters/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const clearRecruitersInProject = () => {
  return async (dispatch, getState) => {
    dispatch(clearRecruitersInProjectAction());
  };
};

export const clearCurrentRecruiter = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentRecruiterAction());
  };
};

export const clearCurrentRecruitersList = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentRecruitersListAction());
  };
};

export const clearMyRecruiters = () => {
  return async (dispatch, getState) => {
    dispatch(clearMyRecruitersAction());
  };
};

export const clearCurrentRecruitersLimitedList = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentRecruitersLimitedListAction());
  };
};

export const clearCurrentRecruitersLimitedListCounter = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentRecruitersLimitedListCounterAction());
  };
};

export const getRecruitersCount = params => {
  return async dispatch => {
    return axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        count: true,
        ...params,
      },
    })
      .then(response => {
        const {
          data,
          data: { count },
          status,
        } = response;
        return { data, status, count };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getRecruiter = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.SHOW.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentRecruiterAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const getRecruiters = params => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: { type: 'recruiter', ...params },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentRecruitersListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getRecruitersWithLimit = (limit, offset, params) => {
  return async dispatch => {
    const recruitersCount = axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        type: 'recruiter',
        count: true,
        ...params,
      },
    })
      .then(response => {
        const {
          data: { count },
        } = response;
        dispatch(setCurrentRecruitersLimitedListCounterAction(count));
        return { count };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
    const recruitersData = axios({
      method: 'GET',
      url: API_URL + API.GET.USER.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: {
        type: 'recruiter',
        limit: limit,
        offset: offset,
        ...params,
      },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentRecruitersLimitedListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
    return await Promise.all([recruitersCount, recruitersData]).then(
      apiData => {
        return {
          count: apiData[0]['count'],
          data: apiData[1]['data'],
          status: apiData[1]['status'],
        };
      }
    );
  };
};

export const getRecruitersPendingToOrganization = (organizationId, filters) => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url:
        API_URL +
        API.GET.ORGANIZATION.RECRUITER.GET.replace(':id', organizationId) +
        filters,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setRecruitersPendingToOrganizationListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { status };
      });
  };
};

export const editRecruiter = values => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.USER.EDIT.replace(':id', values.id),
      data: { ...values },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentRecruiterAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const editRecruiterOrganization = values => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.RECRUITER.EDIT_ORGANIZATION_STATUS.replace(':id', values.id),
      data: { ...values },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentRecruiterAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const editRecruiterAssets = values => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.RECRUITER.EDIT_ASSETS.replace(':id', values.id),
      data: { ...values },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentRecruiterAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const deleteRecruiter = id => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url: API_URL + API.DELETE.USER.DELETE.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editRecruiterMentorStatus = values => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url:
        API_URL +
        API.PUT.RECRUITER.EDIT_MENTOR_STATUS.replace(':id', values.id),
      data: {
        mentorStatus: values.mentorStatus,
      },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};
