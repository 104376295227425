import { Grid, Typography, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ArrowUp from 'shared/assets/img/icons/project-show/arrowUp.png';
import FunnelIcon from 'shared/assets/img/icons/project-show/lejek.png';
import ManIcon from 'shared/assets/img/icons/project-show/man_white.png';
import PairIcon from 'shared/assets/img/icons/project-show/pair_white.png';
import SumIcon from 'shared/assets/img/icons/project-show/sum.png';
import WomanIcon from 'shared/assets/img/icons/project-show/woman_white.png';
import { styles } from './ProjectOrdersTable.styles';

const ProjectOrdersTable = ({
  projectOrders,
  t,
  classes,
  variant,
  selectedOrders,
  setSelectedOrders,
  isCheckAll,
  setIsCheckAll,
  confirmationTable = false,
}) => {
  const headersConfig = [
    {
      name: 'orderNumber',
      label: t`projectOrdersTable:orderNum`,
    },
    {
      name: 'createdAt',
      label: t`projectOrdersTable:orderDate`,
    },
    {
      name: 'employmentStartDate',
      label: t`projectOrdersTable:hireDate`,
    },
    {
      name: 'localizationString',
      label: t`projectOrdersTable:location`,
    },
    {
      name: 'position',
      label: t`projectOrdersTable:position`,
    },
    {
      name: 'numberOfManCandidate',
      label: <img alt="ManIcon" src={ManIcon} height={30} />,
    },
    {
      name: 'numberOfWomanCandidate',
      label: <img alt="WomanIcon" src={WomanIcon} height={30} />,
    },
    {
      name: 'numberOfPairCandidate',
      label: <img alt="PairIcon" src={PairIcon} height={30} />,
    },
    {
      name: 'sumOfCandidates',
      label: <img alt="sumIcon" src={SumIcon} height={20} />,
    },
    {
      name: 'allCandidateInProcess',
      label: <img alt="FunnelIcon" src={FunnelIcon} height={20} />,
    },
  ];

  const [rows, setRows] = React.useState([]);

  const [headerSort, setHeaderSort] = useState(headersConfig.map(() => 'off'));

  React.useEffect(() => {
    setRows(projectOrders);
  }, [projectOrders]);

  React.useEffect(() => {
    sort();
  }, [headerSort]);

  const getNextSortType = currentSortType => {
    switch (currentSortType) {
      case 'off':
        return 'asc';
      case 'asc':
        return 'desc';
      default:
        return 'off';
    }
  };
  const changeSortType = index => {
    const tmpHeaderSort = [...headerSort];
    const currentSortType = tmpHeaderSort[index];
    tmpHeaderSort[index] = getNextSortType(currentSortType);
    setHeaderSort(tmpHeaderSort);
  };

  const getCurrentSortIcon = index => {
    const currentSortType = headerSort[index];
    switch (currentSortType) {
      case 'off':
        return <Typography className={classes.typographySort}>-</Typography>;
      case 'asc':
        return <img alt={'sortIcon'} src={ArrowUp} className={classes.arrow} />;
      default:
        return (
          <img
            alt={'sortIcon'}
            src={ArrowUp}
            className={`${classes.arrowDown} ${classes.arrow}`}
          />
        );
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedOrders(rows.map(li => li.id));
    if (isCheckAll) {
      setSelectedOrders([]);
    }
  };

  const sort = () => {
    const sortedFieldsArray = [];
    const sortedOrderArray = [];
    headerSort.forEach((item, index) => {
      if (item !== 'off') {
        sortedFieldsArray.push(headersConfig[index].name);
        sortedOrderArray.push(item);
      }
    });
    if (!sortedFieldsArray.length) {
      return setRows(projectOrders);
    }
    const sortedRows = _.orderBy(rows, sortedFieldsArray, sortedOrderArray);
    setRows(sortedRows);
  };

  return (
    <Grid>
      <div className={classes.container}>
        <Table stickyheader="true" className={classes.table}>
          <TableHead
            className={`${
              variant === 'active'
                ? classes.tableHeadActive
                : classes.tableHeadDisabled
            } ${classes.tableHead}`}
            style={confirmationTable ? { background: '#B7BEC8' } : null}
          >
            <TableRow>
              <TableCell padding="checkbox" className={classes.checkboxCell}>
                {variant !== 'disabled' && (
                  <input
                    name="selectAll"
                    type="checkbox"
                    className={classes.checkbox}
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />
                )}
              </TableCell>
              {headersConfig.map((header, index) => {
                return (
                  <TableCell
                    key={index}
                    padding={index > 7 ? 'checkbox' : 'dense'}
                    className={classes.thItem}
                    onClick={() => {
                      changeSortType(index);
                    }}
                  >
                    <div className={classes.thRow}>
                      <Typography className={classes.typography}>
                        {header.label}
                      </Typography>
                      {getCurrentSortIcon(index)}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((projectOrder, index) => (
              <TableRow
                key={projectOrder.id}
                className={index % 2 ? classes.even : classes.odd}
              >
                <TableCell padding="checkbox" className={classes.contentCell}>
                  <input
                    disabled={variant === 'disabled'}
                    id={projectOrder.id}
                    type="checkbox"
                    checked={
                      variant !== 'disabled' &&
                      selectedOrders.includes(projectOrder.id)
                    }
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedOrders(
                          _.uniq([...selectedOrders, parseInt(e.target.id)])
                        );
                      } else {
                        const filterSelectedOrders = selectedOrders.filter(
                          order => order !== parseInt(e.target.id)
                        );
                        setSelectedOrders(_.uniq([...filterSelectedOrders]));
                      }
                    }}
                  />
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>{projectOrder.orderNumber}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>{projectOrder.createdAt}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>{projectOrder.employmentStartDate}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>{projectOrder.localizationString}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography noWrap>{projectOrder.position}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>{projectOrder.numberOfManCandidate}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>{projectOrder.numberOfWomanCandidate}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography>{projectOrder.numberOfPairCandidate}</Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography variant="h6">
                    {projectOrder.sumOfCandidates}
                  </Typography>
                </TableCell>
                <TableCell size="small" className={classes.contentCell}>
                  <Typography variant="h6">
                    {projectOrder.allCandidateInProcess}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(ProjectOrdersTable))
);
