import { Grid, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { array, number, object } from 'yup';
import { Translate } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import { RecruiterV2Service } from 'shared/services';
import { castObjectForSelect } from 'shared/utils/functions';
import CustomDialog from '../../../../../../../../../../components/CustomDialog';
import LanguageSelectCard from '../../../../../../../../../candidate/NewCandidateCreateView/components/LanguageSelectCard/LanguageSelectCard';
import { castToMultiSelect } from '../../../../../../../../../project/NewProjectCreateView/components/ProjectCreateWizard/ProjectCreateWizard.utils';
import { styles } from './OtherExperiencesForm.styles';

function OtherExperiencesForm({
  recruiterData,
  isOpen,
  onClose,
  recruiterEdited,
  countries,
  languageCompetence,
  t,
  classes,
}) {
  const MODEL = {
    userRecruitmentAreas: 'userRecruitmentAreas',
    userRecruitmentCountries: 'userRecruitmentCountries',
    userLanguageSkills: 'userLanguageSkills',
  };
  const formData = {
    userRecruitmentAreas: recruiterData
      ? castToMultiSelect(
          recruiterData.userRecruitmentAreas.map(item => item.country),
          'name',
          'id'
        )
      : [],
    userRecruitmentCountries: recruiterData
      ? castToMultiSelect(
          recruiterData.userRecruitmentCountries.map(item => item.country),
          'name',
          'id'
        )
      : [],
    userLanguageSkills: recruiterData ? recruiterData.userLanguageSkills : [],
  };

  const validationSchema = Yup.object().shape({
    userLanguageSkills: array()
      .of(
        object().shape({
          skill: object().shape({
            id: number().required(t('projectCreator:core.requiredField')),
          }),
          experience: number().required(t('projectCreator:core.requiredField')),
        })
      )
      .required(t('projectCreator:core.requiredField')),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      const body = {
        userRecruitmentAreas: values.userRecruitmentAreas,
        userCountries: values.userRecruitmentCountries,
        userSkills: values.userLanguageSkills,
      };

      RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
        stage: 'other_data',
      })
        .then(() => {
          recruiterEdited();
          onClose();
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 409) {
              return actions.setFieldError(
                'email',
                t`candidateCreator:emailTaken`
              );
            } else if (error.response.status === 418) {
              return actions.setFieldError(
                'phonePrimary',
                t`candidateCreator:phoneTaken`
              );
            }
          } else {
            console.log(error);
            alert(t`recruiter:saveError`);
          }
        });
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`candidateCreator:general`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'candidateCreator:area'}
                  bold
                  className={classes.fontStyleSmall}
                />
              </Grid>
              <Grid container>
                <MultiSelect
                  isMultiOption={true}
                  formik={formik}
                  name={MODEL.userRecruitmentAreas}
                  label={t`candidateCreator:area`}
                  options={castObjectForSelect(countries)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'candidateCreator:langs'}
                  bold
                  className={classes.fontStyleSmall}
                />
              </Grid>
              <Grid container>
                <LanguageSelectCard
                  formik={formik}
                  languageCompetence={languageCompetence}
                  initialCandidateValues={recruiterData}
                  t={t}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'candidateCreator:country'}
                  bold
                  className={classes.fontStyleSmall}
                />
              </Grid>
              <Grid container>
                <MultiSelect
                  isMultiOption={true}
                  formik={formik}
                  name={MODEL.userRecruitmentCountries}
                  label={t`candidateCreator:country`}
                  options={castObjectForSelect(countries)}
                />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t`recruiter:save`} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
}

export default connect()(
  withStyles(styles)(withTranslation()(OtherExperiencesForm))
);
