export const styles = ({ palette, breakpoints }) => ({
  itemWrapper: {
    padding: 12,
  },
  paperWrapper: {
    width: '100%',
    borderRadius: 20,
    height: '100%',
  },
  containerWrapper: {
    alignItems: 'center',
    padding: '30px 30px 30px 30px',
    wordBreak: 'break-word',
  },
  headerWrapper: {
    padding: '15px 0px',
  },
  fontStyleBig: {
    fontSize: 26,
  },
  fontStyleSmall: {
    fontSize: 16,
  },
  fontStyleSmallWithColor: {
    fontSize: 14,
    color: '#148EFF',
  },
  editWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgWrapper: {
    display: 'flex',
  },
  progressBarWrapper: {
    minHeight: 65,
  },
  wrapperItem: {
    padding: '15px 15px',
  },
});
