export const executeScroll = ref => {
  if (
    ref !== undefined &&
    ref !== null &&
    ref.current &&
    ref.current !== null
  ) {
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 500);
  }
};

export const executeScrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, 500);
};
