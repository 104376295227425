import {
  CircularProgress,
  Typography,
  Badge,
  IconButton,
  ListItem,
  List,
  LinearProgress,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Notifications as NotificationIcon } from '@material-ui/icons';
import { filter, forEach, find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ProjectV2Service } from 'shared/services';
import {
  getNotifications,
  setNotificationRead,
} from 'shared/services/notificationService';
import {
  editProject,
  acceptInvitationProjectCandidate,
  acceptInvitationProjectCandidateAsRecruiter,
  rejectInvitationProjectCandidateAsRecruiter,
  acceptInvitationProjectRecruiter,
  rejectInvitationProjectRecruiter,
  rejectInvitationProjectCandidate,
  getProjectCandidateConfirmationStatus,
  getProjectRecruiterConfirmationStatus,
  getProjectRecruitersFilteredForAdding,
} from 'shared/services/projectService';
import { editRecruiterMentorStatus } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getNotificationsByCategories } from 'shared/utils/functions';
import MenuToggleAction from '../LayoutMenuToggleAction';
import NotificationsBottomNavigation from './NotificationsBottomNavigation';
import NotificationsListItem from './NotificationsListItem';

const styles = theme => ({
  iconButton: {
    color: '#afafaf',
    marginTop: 14,
    padding: 10,
  },
  icon: {
    color: '#afafaf',
  },
  root: {
    textAlign: 'right',
    flexGrow: 1,
  },
  subHeader: {
    background: '#52a3fc',
    color: '#fff',
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 14,
  },
  rootList: {
    width: 420,
    maxHeight: 350,
    overflowY: 'auto',
    background: '#fff',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    paddingLeft: '12px',
    position: 'relative',
    overflow: 'hidden',
  },
  listItemText: {
    maxWidth: 150,
  },
  progress: {
    margin: theme.spacing(2),
  },
  progressAwait: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#fff',
    opacity: '0.9',
    zIndex: '8000',
    textAlign: 'center',
  },
  progressCircular: {
    color: '#fff',
  },
});

export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isLoading: false,
    };
  }

  setNotificationRead = async itemId => {
    const { setNotificationRead } = this.props;
    this.setState({ isLoading: true });
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
  };

  acceptProjectInvitationCandidate = async (
    projectId,
    candidateId,
    confirmationAcceptId,
    itemId
  ) => {
    const {
      acceptInvitationProjectCandidate,
      getProjectCandidateConfirmationStatus,
      showSnackbarMessage,
      setNotificationRead,
      t,
    } = this.props;
    this.setState({ isLoading: true });
    await acceptInvitationProjectCandidate(
      projectId,
      candidateId,
      confirmationAcceptId
    );
    await getProjectCandidateConfirmationStatus(projectId, candidateId);
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youAcceptedProjectInvitation'));
  };

  acceptProjectInvitationCandidateAsRecruiter = async (
    projectId,
    candidateId,
    confirmationAcceptId,
    itemId
  ) => {
    const {
      acceptInvitationProjectCandidateAsRecruiter,
      getProjectCandidateConfirmationStatus,
      showSnackbarMessage,
      setNotificationRead,
      t,
    } = this.props;
    this.setState({ isLoading: true });
    await acceptInvitationProjectCandidateAsRecruiter(
      projectId,
      candidateId,
      confirmationAcceptId
    );
    await getProjectCandidateConfirmationStatus(projectId, candidateId);
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youAcceptedProjectInvitation'));
  };

  rejectProjectInvitationCandidate = async (
    projectId,
    candidateId,
    confirmationRejectId,
    itemId
  ) => {
    const {
      rejectInvitationProjectCandidate,
      getProjectCandidateConfirmationStatus,
      showSnackbarMessage,
      setNotificationRead,
      t,
    } = this.props;
    this.setState({ isLoading: true });
    await rejectInvitationProjectCandidate(
      projectId,
      candidateId,
      confirmationRejectId
    );
    await getProjectCandidateConfirmationStatus(projectId, candidateId);
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youRejectedProjectInvitation'));
  };

  rejectProjectInvitationCandidateAsRecruiter = async (
    projectId,
    candidateId,
    confirmationRejectId,
    itemId
  ) => {
    const {
      rejectInvitationProjectCandidateAsRecruiter,
      getProjectCandidateConfirmationStatus,
      showSnackbarMessage,
      setNotificationRead,
      t,
    } = this.props;
    this.setState({ isLoading: true });
    const response = await rejectInvitationProjectCandidateAsRecruiter(
      projectId,
      candidateId,
      confirmationRejectId
    );
    await getProjectCandidateConfirmationStatus(projectId, candidateId);
    if (response.status === 200) {
      await setNotificationRead(itemId);
      await this.props.getNotifications();
    }
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youRejectedProjectInvitation'));
  };

  acceptProjectInvitationRecruiter = async (
    projectId,
    recruiterId,
    confirmationAcceptId,
    itemId,
    isProjectRecruitersTrigger
  ) => {
    const {
      acceptInvitationProjectRecruiter,
      getProjectRecruiterConfirmationStatus,
      showSnackbarMessage,
      setNotificationRead,
      getProjectRecruitersFilteredForAdding,
      t,
    } = this.props;
    this.setState({ isLoading: true });
    await acceptInvitationProjectRecruiter(
      projectId,
      recruiterId,
      confirmationAcceptId
    );
    if (isProjectRecruitersTrigger) {
      await getProjectRecruitersFilteredForAdding(projectId);
    } else {
      await getProjectRecruiterConfirmationStatus(projectId, recruiterId);
    }
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youAcceptedProjectInvitation'));
  };

  acceptProjectInvitationTriggeredByInactiveCandidate = async (
    projectId,
    candidateId,
    confirmationAcceptId,
    itemId,
    isProjectRecruitersTrigger
  ) => {
    const {
      showSnackbarMessage,
      setNotificationRead,
      t,
      notifications,
      currentUser: { id },
    } = this.props;
    const findRecruiter_R1 = filter(notifications, function (item) {
      return (
        item.type === 'project_recruiter_co_1' &&
        item.data.project.id === projectId
      );
    });
    this.setState({ isLoading: true });
    forEach(
      findRecruiter_R1,
      function (value) {
        this.acceptProjectInvitationRecruiter(
          projectId,
          id,
          confirmationAcceptId,
          value.id,
          isProjectRecruitersTrigger
        );
        setNotificationRead(value.id);
      }.bind(this)
    );
    await this.acceptProjectInvitationCandidateAsRecruiter(
      projectId,
      candidateId,
      confirmationAcceptId,
      itemId
    );
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youAcceptedProjectInvitation'));
  };

  rejectProjectInvitationTriggeredByInactiveCandidate = async (
    projectId,
    candidateId,
    confirmationRejectId,
    itemId,
    isProjectRecruitersTrigger
  ) => {
    const {
      showSnackbarMessage,
      setNotificationRead,
      t,
      notifications,
      currentUser: { id },
    } = this.props;
    this.setState({ isLoading: true });
    await this.rejectProjectInvitationCandidateAsRecruiter(
      projectId,
      candidateId,
      confirmationRejectId,
      itemId
    );
    const findCandidate_CO7 = filter(notifications, function (item) {
      return (
        item.type === 'project_candidate_co_7' &&
        item.data.project.id === projectId
      );
    });
    if (findCandidate_CO7.length - 1 === 0) {
      const findRecruiter = find(notifications, function (recruiter) {
        return (
          recruiter.type === 'project_recruiter_co_1' &&
          recruiter.data.project.id === projectId
        );
      });
      this.rejectProjectInvitationRecruiter(
        projectId,
        id,
        confirmationRejectId,
        itemId,
        isProjectRecruitersTrigger
      );
      if (!isEmpty(findRecruiter)) {
        setNotificationRead(findRecruiter.id);
      }
    }
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youRejectedProjectInvitation'));
  };

  rejectProjectInvitationRecruiter = async (
    projectId,
    recruiterId,
    confirmationRejectId,
    itemId,
    isProjectRecruitersTrigger
  ) => {
    const {
      rejectInvitationProjectRecruiter,
      getProjectRecruiterConfirmationStatus,
      showSnackbarMessage,
      setNotificationRead,
      getProjectRecruitersFilteredForAdding,
      t,
    } = this.props;
    this.setState({ isLoading: true });
    await rejectInvitationProjectRecruiter(
      projectId,
      recruiterId,
      confirmationRejectId
    );
    if (isProjectRecruitersTrigger) {
      await getProjectRecruitersFilteredForAdding(projectId);
    } else {
      await getProjectRecruiterConfirmationStatus(projectId, recruiterId);
    }
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(t('notification:project.youRejectedProjectInvitation'));
  };

  acceptRejectMentorInvitationToProject = async (
    idOption,
    projectId,
    itemId
  ) => {
    const { showSnackbarMessage, setNotificationRead, t, currentUser } =
      this.props;
    this.setState({ isLoading: true });
    const body = {
      mentorId: currentUser.id,
      mentorStatus: idOption,
    };
    await ProjectV2Service.editProjectV2Mentor(projectId, body);
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(
      t(
        idOption === 2
          ? 'project:form.acceptByMentor'
          : 'project:form.rejectByMentor'
      )
    );
    window.location.reload();
  };
  acceptRejectMentorRole = async (idOption, userId, itemId) => {
    const { showSnackbarMessage, setNotificationRead, t } = this.props;
    this.setState({ isLoading: true });
    const mentorData = {
      id: userId,
      mentorStatus: idOption,
    };
    await this.props.editRecruiterMentorStatus(mentorData);
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(
      t(
        idOption === 2
          ? 'project:form.acceptMentor'
          : 'project:form.rejectMentor'
      )
    );
  };
  acceptRejectHiringManagerInvitationToProject = async (
    idOption,
    projectId,
    itemId
  ) => {
    const { showSnackbarMessage, setNotificationRead, currentUser, t } =
      this.props;
    this.setState({ isLoading: true });
    const body = {
      hiringManagerId: currentUser.id,
      hiringManagerStatus: idOption,
    };
    await ProjectV2Service.editProjectV2HiringManager(projectId, body);
    await setNotificationRead(itemId);
    await this.props.getNotifications();
    this.setState({ isLoading: false });
    showSnackbarMessage(
      t(idOption === 2 ? 'project:form.acceptByHm' : 'project:form.rejectByHm')
    );
    window.location.reload();
  };

  handleClickOpenToggleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickCloseToggleMenu = () => {
    this.setState({ anchorEl: null });
  };

  getProjectInvitationContent = () => {
    const { isLoading } = this.state;
    const { classes, t } = this.props;
    const notifications = getNotificationsByCategories(
      this.props.notifications
    );

    return (
      <React.Fragment>
        <Typography
          variant="caption"
          align="center"
          className={classes.subHeader}
        >
          {t('notification:general.notificationsTitle')}
        </Typography>
        <List
          component="nav"
          classes={{
            root: classes.rootList,
          }}
        >
          {isLoading ? (
            <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
          ) : (
            <React.Fragment>
              {notifications.length === 0 ? (
                <ListItem className={classes.listItem}>
                  <ListItemText
                    primary={t('notification:general.noNotifications')}
                  />
                </ListItem>
              ) : (
                ''
              )}

              {notifications.map(value => (
                <NotificationsListItem
                  key={value.id}
                  item={value}
                  type={value.type}
                  isLoading={isLoading}
                  acceptProjectInvitationCandidate={
                    this.acceptProjectInvitationCandidate
                  }
                  acceptProjectInvitationRecruiter={
                    this.acceptProjectInvitationRecruiter
                  }
                  rejectProjectInvitationCandidate={
                    this.rejectProjectInvitationCandidate
                  }
                  rejectProjectInvitationRecruiter={
                    this.rejectProjectInvitationRecruiter
                  }
                  acceptRejectMentorInvitationToProject={
                    this.acceptRejectMentorInvitationToProject
                  }
                  acceptProjectInvitationTriggeredByInactiveCandidate={
                    this.acceptProjectInvitationTriggeredByInactiveCandidate
                  }
                  rejectProjectInvitationTriggeredByInactiveCandidate={
                    this.rejectProjectInvitationTriggeredByInactiveCandidate
                  }
                  acceptRejectHiringManagerInvitationToProject={
                    this.acceptRejectHiringManagerInvitationToProject
                  }
                  setNotificationRead={this.setNotificationRead}
                  acceptRejectMentorRole={this.acceptRejectMentorRole}
                />
              ))}
            </React.Fragment>
          )}
        </List>
        <NotificationsBottomNavigation
          handleClickCloseToggleMenu={this.handleClickCloseToggleMenu}
        />
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    const { anchorEl, isLoading } = this.state;
    const notifications = getNotificationsByCategories(
      this.props.notifications
    );
    const countPendingNotifications = notifications.length;

    return (
      <React.Fragment>
        <IconButton
          className={classes.iconButton}
          onClick={this.handleClickOpenToggleMenu}
        >
          {isLoading ? (
            <CircularProgress className={classes.progressCircular} size={24} />
          ) : countPendingNotifications ? (
            countPendingNotifications !== 0 ? (
              <Badge
                badgeContent={
                  countPendingNotifications >= 20
                    ? '20+'
                    : countPendingNotifications
                }
                color="secondary"
              >
                <NotificationIcon className={classes.icon} />
              </Badge>
            ) : (
              <NotificationIcon className={classes.icon} />
            )
          ) : (
            <NotificationIcon className={classes.icon} />
          )}
        </IconButton>
        <MenuToggleAction
          anchorElTrigger={anchorEl}
          anchorElCloseTrigger={this.handleClickCloseToggleMenu}
          getContent={this.getProjectInvitationContent.bind(this)}
        />
      </React.Fragment>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    notifications: store.notifications.list,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  editRecruiterMentorStatus,
  editProject,
  showSnackbarMessage,
  setNotificationRead,
  getProjectRecruitersFilteredForAdding,
  getProjectRecruiterConfirmationStatus,
  getProjectCandidateConfirmationStatus,
  acceptInvitationProjectRecruiter,
  acceptInvitationProjectCandidate,
  acceptInvitationProjectCandidateAsRecruiter,
  rejectInvitationProjectCandidateAsRecruiter,
  rejectInvitationProjectRecruiter,
  rejectInvitationProjectCandidate,
  getNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(Notifications))));
