import { Grid, withStyles } from '@material-ui/core';
import { isEmpty, sortBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { Button, Snackbar } from 'shared/newComponents';
import {
  CountryService,
  EmploymentType,
  IndustryCategoryService,
  PositionService,
} from 'shared/services';
import DepartmentService from 'shared/services/department.service';
import {
  setLocalStorage,
  getLocalStorageByKey,
} from 'shared/utils/localStorage';
import {
  CandidateCreateWizardStep1,
  CandidateCreateWizardStep2,
  CandidateCreateWizardStep3,
  CandidateCreateWizardStep4,
  CandidateCreateWizardStep5,
  CandidateCreateWizardStep6,
} from '..';
import { isTrue } from '../../../../project/NewProjectCreateView/components/ProjectCreateWizard/ProjectCreateWizard.utils';
import { styles } from './CandidateCreateWizard.styles';

const CandidateCreateWizard = ({
  initialCandidateValues,
  t,
  setStep1FormValues,
  setStep2FormValues,
  setStep3FormValues,
  setStep6FormValues,
  currentStage,
  step3FormValues,
  setCurrentStage,
  classes,
  history,
  step2FormValues,
  currentUser,
  competences,
  languageCompetence,
  project,
}) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const [isSnackbarSuccessOpen, setIsSnackbarSuccessOpen] = useState(false);
  const [isStep1Open, setIsStep1Open] = useState(!initialCandidateValues.id);
  const [isStep2Open, setIsStep2Open] = useState(false);
  const [isStep3Open, setIsStep3Open] = useState(false);
  const [isStep4Open, setIsStep4Open] = useState(false);
  const [isStep5Open, setIsStep5Open] = useState(false);
  const [isStep6Open, setIsStep6Open] = useState(false);

  const [countries, setCountries] = useState([]);
  const [positions, setPositions] = useState([]);

  const [industryCategories, setIndustryCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const getEmploymentTypes = () => {
    EmploymentType.getEmploymentTypes().then(({ data }) => {
      setEmploymentTypes(sortBy(data, o => o.id));
    });
  };

  const getCountries = () => {
    CountryService.getCountries().then(({ data }) => {
      setCountries(data);
    });
  };

  const getPositions = () => {
    PositionService.getPositions().then(({ data }) => {
      setPositions(data.map(position => position.position));
    });
  };

  const getIndustryCategories = () => {
    IndustryCategoryService.getIndustryCategories().then(({ data }) =>
      setIndustryCategories(data)
    );
  };

  const getDepartments = () => {
    DepartmentService.getDepartments().then(({ data }) => setDepartments(data));
  };

  const isCandidateSave = !!initialCandidateValues.id;

  const isOneStageSaved = currentStage >= 1;

  const currentUserIsOwner = () => {
    if (initialCandidateValues && initialCandidateValues.owner) {
      return currentUser.id === initialCandidateValues.owner.id;
    }
    return false;
  };

  const selfEdit = () => {
    return initialCandidateValues
      ? currentUser.id === initialCandidateValues.id
      : false;
  };

  const currentUserIsOwnerOrSuperAdmin = isCandidateSave
    ? !!(currentUser.isSuperUser || currentUserIsOwner())
    : false;

  const showSaveButton = isCandidateSave
    ? !!(
        currentUser.isSuperUser ||
        currentUserIsOwner() ||
        currentUser.id === initialCandidateValues.id
      )
    : true;

  useEffect(() => {
    setIsSnackbarOpen(isTrue(localStorage.getItem('isDataSave')));
    localStorage.setItem('isDataSave', 'false');

    if (isTrue(localStorage.getItem('isNewCandidate'))) {
      setIsStep1Open(false);
      setIsStep2Open(true);
      localStorage.removeItem('isNewCandidate');
    }
    if (isTrue(localStorage.getItem('openStage1'))) {
      setIsStep1Open(true);
      localStorage.setItem('openStage1', 'false');
    }
    if (isTrue(localStorage.getItem('openStage4'))) {
      setIsStep4Open(true);
      localStorage.setItem('openStage4', 'false');
    }
    getCountries();
    getPositions();
    getIndustryCategories();
    getEmploymentTypes();
    getDepartments();
  }, []);

  useEffect(() => {
    if (project) {
      setLocalStorage('projectId', project.id.toString());
    }
  }, []);

  const closeAll = () => {
    setIsStep1Open(false);
    setIsStep2Open(false);
    setIsStep3Open(false);
    setIsStep4Open(false);
    setIsStep5Open(false);
    setIsStep6Open(false);
  };

  const formStepTouched = formStep => !isEmpty(formStep.touched);
  const formStepError = formStep => !isEmpty(formStep.errors);
  const formStepDirty = formStep => formStep.dirty;

  const isFormStepError = formStep => {
    return (
      (!formStepTouched(formStep) && !formStepDirty(formStep)) ||
      formStepError(formStep)
    );
  };

  const isFormStepSaveButtonDisabled = formStep =>
    (formStepTouched(formStep) && formStepError(formStep)) ||
    !formStepDirty(formStep);

  const handleClickReturnToCandidate = () => {
    setBtnDisabled(true);
    if (getLocalStorageByKey('projectId')) {
      setIsSnackbarSuccessOpen(true);
      setTimeout(() => {
        history.push(
          PATHS.PROJECT.ATS.replace(':id', getLocalStorageByKey('projectId'))
        );
        window.location.reload();
      }, 1000);

      return;
    } else if (getLocalStorageByKey('projectIdEmploymentProcess')) {
      history.push(
        PATHS.PROJECT.ATS_EMPLOYMENT_PROCESS.replace(
          ':id',
          getLocalStorageByKey('projectIdEmploymentProcess')
        )
      );
    } else if (selfEdit()) {
      history.push(PATHS.USER.INDEX);
    } else {
      history.push(
        PATHS.CANDIDATE.SHOW.replace(':id', initialCandidateValues.id)
      );
    }

    window.location.reload();
  };

  return (
    <Grid container>
      <Snackbar
        open={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        label={t('projectCreator:core.sectionHasSaved')}
      />
      <Snackbar
        type={'error'}
        open={isSnackbarErrorOpen}
        onClose={() => setIsSnackbarErrorOpen(false)}
        label={t('projectCreator:core.sectionHasNotSaved')}
      />
      <Snackbar
        open={isSnackbarSuccessOpen}
        onClose={() => setIsSnackbarSuccessOpen(false)}
        label={t`projectCreator:core.candidateAdded`}
      />
      <CandidateCreateWizardStep1
        project={project}
        countries={countries}
        positions={positions}
        industryCategories={industryCategories}
        departments={departments}
        initialCandidateValues={initialCandidateValues}
        isStep1Open={isStep1Open}
        setIsStep1Open={setIsStep1Open}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={closeAll}
        t={t}
        history={history}
        isFormStepError={isFormStepError}
        isCandidateSave={isCandidateSave}
        step2FormValues={step2FormValues}
        setIsStep2Open={setIsStep2Open}
        setStep1FormValues={setStep1FormValues}
        showSaveButton={showSaveButton}
      />
      <CandidateCreateWizardStep2
        t={t}
        initialCandidateValues={initialCandidateValues}
        isStep2Open={isStep2Open}
        isFormStepError={isFormStepError}
        setIsStep3Open={setIsStep3Open}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={closeAll}
        setIsStep2Open={setIsStep2Open}
        isCandidateSave={isCandidateSave}
        history={history}
        setCurrentStage={setCurrentStage}
        step3FormValues={step3FormValues}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
        industryCategories={industryCategories}
        setStep2FormValues={setStep2FormValues}
        competences={competences}
        languageCompetence={languageCompetence}
        showSaveButton={showSaveButton}
      />
      <CandidateCreateWizardStep3
        t={t}
        initialCandidateValues={initialCandidateValues}
        setIsStep2Open={setIsStep2Open}
        employmentTypes={employmentTypes}
        isFormStepError={isFormStepError}
        isStep3Open={isStep3Open}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={closeAll}
        isCandidateSave={isCandidateSave}
        history={history}
        setCurrentStage={setCurrentStage}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
        setIsStep3Open={setIsStep3Open}
        setIsStep4Open={setIsStep4Open}
        setStep3FormValues={setStep3FormValues}
        showSaveButton={showSaveButton}
      />
      <CandidateCreateWizardStep4
        t={t}
        initialCandidateValues={initialCandidateValues}
        isFormStepError={isFormStepError}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={closeAll}
        isCandidateSave={isCandidateSave}
        history={history}
        setCurrentStage={setCurrentStage}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
        isStep4Open={isStep4Open}
        setIsStep4Open={setIsStep4Open}
        setIsStep5Open={setIsStep5Open}
        isOneStageSaved={isOneStageSaved}
        showSaveButton={showSaveButton}
      />
      <CandidateCreateWizardStep5
        t={t}
        initialCandidateValues={initialCandidateValues}
        isFormStepError={isFormStepError}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={closeAll}
        isCandidateSave={isCandidateSave}
        history={history}
        setCurrentStage={setCurrentStage}
        setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
        isStep5Open={isStep5Open}
        countries={countries}
        setIsStep5Open={setIsStep5Open}
        setIsStep6Open={setIsStep6Open}
        isOneStageSaved={isOneStageSaved}
        currentUser={currentUser}
        showSaveButton={showSaveButton}
      />
      {currentUserIsOwnerOrSuperAdmin ? (
        <CandidateCreateWizardStep6
          t={t}
          initialCandidateValues={initialCandidateValues}
          isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
          closeAll={closeAll}
          isCandidateSave={isCandidateSave}
          history={history}
          setCurrentStage={setCurrentStage}
          setIsSnackbarErrorOpen={setIsSnackbarErrorOpen}
          isStep6Open={isStep6Open}
          setIsStep6Open={setIsStep6Open}
          setStep6FormValues={setStep6FormValues}
          isOneStageSaved={isOneStageSaved}
        />
      ) : null}

      {initialCandidateValues.id ? (
        <Grid container justifyContent="center" className={classes.saveButton}>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              label="candidateCreator:core.returnToCandidateDetails"
              onClick={handleClickReturnToCandidate}
              disabled={btnDisabled}
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(
  withTranslation()(withRouter(CandidateCreateWizard))
);
