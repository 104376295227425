import { withStyles } from '@material-ui/core/styles';
import LayoutLeftMenu from './LayoutLeftMenu';

const styles = () => ({
  menuConntent: {
    width: '260px',
    margin: '0px auto',
  },
  invisibleDivider: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
});

export default withStyles(styles, { withTheme: true })(LayoutLeftMenu);
