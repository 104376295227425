import { Grid, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getCurrentUser } from 'shared/services/authService';
import { editOrganization } from 'shared/services/organizationService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import OrganizationForm from '../../components/OrganizationForm';
import RecruitersMenu from '../../components/RecruitersMenu';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: '64px !important',
    paddingRight: '64px !important',
    marginTop: 0,
  }),
  root: {
    marginTop: 16,
  },
});

export class RecruiterOrganizationEditView extends Component {
  handleSubmit = async () => {
    this.props.getCurrentUser();
    this.props.showSnackbarMessage('test');
  };

  render() {
    const {
      currentUser: { isRecruiter = false, organization },
      classes,
    } = this.props;

    return (
      <React.Fragment>
        {isRecruiter && (
          <div className={classes.root}>
            <RecruitersMenu />
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} md={12}>
                  <OrganizationForm organization={organization} />
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getCurrentUser,
  editOrganization,
  showSnackbarMessage,
};

RecruiterOrganizationEditView.propTypes = {
  currentUser: PropTypes.object.isRequired,
  editOrganization: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(RecruiterOrganizationEditView)));
