import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/es/styles/withStyles';
import { useFormik } from 'formik';
import React from 'react';
import { array, object, string } from 'yup';
import Button from 'shared/newComponents/Button';
import MultiSelect from 'shared/newComponents/MultiSelect';
import TextField from 'shared/newComponents/TextField';
import ExpansionComponent from '../LayoutExpansionComponent';
import { styles } from './FilterATS.style';

const FilterATS = ({
  t,
  projectOrders,
  setFilters,
  parseCandidatesList,
  variant,
}) => {
  const MODEL = {
    candidateLastName: 'candidateLastName',
    stage1DateStart: 'stage1DateStart',
    stage1DateStop: 'stage1DateStop',
    projectOwnerRecruiterLastName: 'projectOwnerRecruiterLastName',
    projectOrderNumbers: 'projectOrderNumbers',
  };
  const FilterComponent = () => {
    const formData = {
      candidateLastName: '',
      stage1DateStart: '',
      stage1DateStop: '',
      projectOwnerRecruiterLastName: '',
      projectOrderNumbers: [],
    };

    const validationSchema = object().shape({
      candidateLastName: string(),
      stage1DateStart: string(),
      stage1DateStop: string(),
      projectOwnerRecruiterLastName: string(),
      projectOrderNumbers: array(),
    });

    const formik = useFormik({
      initialValues: formData,
      validationSchema: validationSchema,
      onSubmit: values => {
        const body = {
          candidateLastName:
            values.candidateLastName !== '' ? values.candidateLastName : null,
          stage1DateStart:
            values.stage1DateStart !== '' ? values.stage1DateStart : null,
          stage1DateStop:
            values.stage1DateStop !== '' ? values.stage1DateStop : null,
          projectOwnerRecruiterLastName:
            values.projectOwnerRecruiterLastName !== ''
              ? values.projectOwnerRecruiterLastName
              : null,
          projectOrderNumbers: values.projectOrderNumbers,
        };

        setFilters(body);
        if (variant !== 'employmentProcess') {
          parseCandidatesList();
        }
      },
    });

    const projectOrdersArray = projectOrders.map(projectOrder => {
      return {
        id: projectOrder.id,
        value: projectOrder.id,
        label: `#${projectOrder.id} ${projectOrder.createdAt} ${projectOrder.owner.firstName} ${projectOrder.owner.lastName}`,
      };
    });

    return (
      <Grid container justifyContent={'space-around'}>
        <Grid item xs={12} sm={12} md={8}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t`filterAts:dateFrom`}
                  name={MODEL.stage1DateStart}
                  type="date"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t`filterAts:dateTo`}
                  name={MODEL.stage1DateStop}
                  type="date"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MultiSelect
                  isMultiOption={true}
                  options={projectOrdersArray}
                  label={t`filterAts:orderId`}
                  name={MODEL.projectOrderNumbers}
                  formik={formik}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Grid container>
                  <TextField
                    label={t`filterAts:candidateLastName`}
                    name={MODEL.candidateLastName}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container>
                  <TextField
                    label={t`filterAts:recruiterLastName`}
                    name={MODEL.projectOwnerRecruiterLastName}
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={3} style={{ alignSelf: 'center' }}>
          <Button
            label={t`filterAts:showResults`}
            type="submit"
            onClick={formik.handleSubmit}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <ExpansionComponent
        title={t('filters.name')}
        textOnTop={t('filters.description')}
      >
        {FilterComponent()}
      </ExpansionComponent>
    </div>
  );
};
export default withStyles(styles)(FilterATS);
