import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import AddIcon from 'shared/assets/img/icons/candidateCreator/add.svg';
import { enums } from 'shared/enums';
import { Button, Select, TextField, Translate } from 'shared/newComponents';
import { AssetService } from 'shared/services';
import { getSelectItemsFullObjectTranslate } from 'shared/utils/assets';
import CandidateAssets from '../../../../../../../components/CandidateAssets';
import { styles } from '../../CandidateCreateWizardStep4.styles';
import { MODEL } from '../../CandidateCreateWizardStep4.utils';

const FileChooser = ({
  t,
  initialCandidateValues,
  classes,
  showSaveButton,
  formik,
  attachmentList = enums.ATTACHMENT_TYPE,
  project,
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (
      formik.values[MODEL.candidateAssets] &&
      formik.values[MODEL.candidateAssets].length
    ) {
      setFiles(formik.values[MODEL.candidateAssets]);
    } else {
      setFiles([1]);
    }
  }, [formik.values[MODEL.candidateAssets]]);

  const isAttachmentTypeSet = index => {
    return (
      formik.values &&
      formik.values[MODEL.candidateAssets] &&
      formik.values[MODEL.candidateAssets][index]
    );
  };

  const getAssetType = index => {
    return isAttachmentTypeSet(index)
      ? formik.values[MODEL.candidateAssets][index][MODEL.assetType]
      : 'cv';
  };

  const getAssetDescription = index => {
    return isAttachmentTypeSet(index)
      ? formik.values[MODEL.candidateAssets][index][MODEL.fileDescription]
      : 'cv';
  };

  const addEmptyFile = () => {
    setFiles([...files, []]);
  };

  const callbackUpload = assets => {
    const filterFiles = files.map(item =>
      !item.id
        ? {
            ...assets[0],
            description: item.description ? item.description : null,
          }
        : item
    );
    formik.setFieldValue(MODEL.candidateAssets, filterFiles);
    setFiles(filterFiles);
  };

  const removeFile = file => {
    AssetService.deleteAsset(file.id)
      .then(() => {
        const filterFiles = files.filter(
          item => item.id && item.id !== file.id
        );
        formik.setFieldValue(MODEL.candidateAssets, filterFiles);
        setFiles(filterFiles.length ? filterFiles : [1]);
      })
      .catch(error => {
        console.log(error);
        alert(t`adminCitySeo:error`);
      });
  };

  if (!files) {
    return null;
  }

  const lastIndexFileWithoutAvatar = () => {
    let lastIndex = 0;
    files.forEach((file, index) => {
      if (file.type !== 'avatar') {
        lastIndex = index;
      }
    });
    return lastIndex;
  };

  return (
    <Grid container style={{ marginTop: 36 }}>
      {files.map((item, index) => {
        if (item && item.id && item.type !== 'avatar') {
          return (
            <Paper
              className={classes.paper}
              key={`files_${index}`}
              style={{ marginBottom: 16 }}
            >
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={6} sm={4} md={4}>
                  <Translate
                    text={`${t('candidateCreator:step4.fileIsAttach')} ${t(
                      `candidateCreator:step4.${item.type}`
                    )} ${item.description ? ': ' + item.description : ''}`}
                    style={{ fontSize: '1rem', wordBreak: 'break-all' }}
                    bold
                    disableTranslate={true}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <Translate
                    bold
                    style={{
                      color: '#359CFF',
                      fontSize: '1rem',
                      wordBreak: 'break-all',
                    }}
                    text={item.filename}
                    disableTranslate={true}
                  />
                </Grid>
                {showSaveButton ? (
                  <Grid
                    item
                    xs={10}
                    sm={3}
                    md={3}
                    onClick={() => removeFile(item)}
                  >
                    <Button label={t('candidateCreator:step4.removeFile')} />
                  </Grid>
                ) : null}
                {showSaveButton ? (
                  <Grid item xs={2} sm={2} md={2} onClick={addEmptyFile}>
                    {index === lastIndexFileWithoutAvatar() ? (
                      <img alt="AddIcon" src={AddIcon} height={50} />
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
          );
        } else {
          if (item.type === 'avatar' && files.length !== 1) {
            return null;
          }
          return (
            <Paper
              className={classes.paper}
              key={`files_${index}`}
              style={{ marginBottom: 16 }}
            >
              <Grid container alignItems={'center'}>
                <Grid item xs={12} sm={12} md={8}>
                  <Select
                    items={getSelectItemsFullObjectTranslate(
                      attachmentList,
                      t,
                      false
                    )}
                    label={t('candidateCreator:step4.choseFileType')}
                    name={`${MODEL.candidateAssets}[${index}].${MODEL.assetType}`}
                    formik={formik}
                    fullWidth={true}
                  />
                </Grid>
                {getAssetType(index) === enums.ATTACHMENT_TYPES.OTHER_SCAN ? (
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      label={t('candidateCreator:step4.attachmentDescription')}
                      name={`${MODEL.candidateAssets}[${index}].${MODEL.fileDescription}`}
                      formik={formik}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} md={4}>
                  <CandidateAssets
                    projectId={project ? project.id : null}
                    fileDescription={getAssetDescription(index)}
                    newCandidateUpload={true}
                    onClose={() => {}}
                    clearLoad={true}
                    callback={callbackUpload}
                    disabled={!isAttachmentTypeSet(index)}
                    removeIcon={false}
                    filesLimit={1}
                    assetType={getAssetType(index)}
                    acceptedFiles={['.pdf', '.docx', '.doc', '.odt']}
                    candidate={initialCandidateValues}
                  >
                    <Button
                      disabled={!isAttachmentTypeSet(index)}
                      label={t('projectCreator:step5.choiceFile')}
                      variant="outlined"
                      onClick={() => {}}
                    />
                  </CandidateAssets>
                </Grid>
              </Grid>
            </Paper>
          );
        }
      })}
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(FileChooser));
