import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getTempSkillsWithLimit,
  deleteTempSkill,
  editTempSkill,
  getTempSkills,
  getTempProjectSkill,
  getTempUserSkill,
  getCompetenceTemp,
} from 'shared/services/tempSkillService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import AddSkillForm from '../../../components/AddSkillForm/AddSkillForm';
import CustomDialog from '../../../components/CustomDialog/index';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog/';
import PageHeader from '../../../components/PageHeader';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import AdminSkillViewHead from './AdminSkillViewHead';

export class AdminSkillView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dictionariesSkills: [],
      skills: [],
      skillsCount: 0,
      order: 'asc',
      orderBy: 'id',
      deleteConfirmationOpened: false,
      openEditDialog: false,
      selectedSkill: {},
      projectsSkill: [],
      usersSkill: [],
      competencesTemp: [],
      options: {
        limit: 12,
        offset: 0,
      },
      isClicked: false,
      recruiterRating: [],
      isSubmitting: false,
    };
  }

  handleSorting = async (event, property) => {
    const orderBy = property;
    let order = 'asc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'asc') {
      order = 'desc';
    }

    await this.setState({ order, orderBy });
    this.handleReloadElements();
  };

  openRemoveDialog = user => {
    this.setState({
      selectedSkill: user,
      deleteConfirmationOpened: true,
    });
  };

  handleRemove = async isOk => {
    if (isOk) {
      await deleteTempSkill(this.state.selectedSkill.id);
      this.handleReloadElements();
    }
    this.setState({
      deleteConfirmationOpened: false,
    });
  };

  onCloseEditDialog = () => {
    this.setState({
      selectedSkill: null,
      openEditDialog: false,
      usersSkill: [],
      projectsSkill: [],
      competencesTemp: [],
    });
  };

  onOpenEditDialog = skill => {
    this.setState({
      selectedSkill: skill,
      openEditDialog: true,
    });
    this.getUserSkill(skill.id);
    this.getProjectsSKill(skill.id);
    this.getCompetencesTemp(skill.id);
  };

  getUserSkill = skillId => {
    getTempUserSkill(skillId).then(response => {
      const { data } = response;
      if (data.length) {
        this.setState({
          usersSkill: data,
        });
      }
    });
  };

  getProjectsSKill = skillId => {
    getTempProjectSkill(skillId).then(response => {
      const { data } = response;
      if (data.length) {
        this.setState({
          projectsSkill: data,
        });
      }
    });
  };

  getCompetencesTemp = skillId => {
    getCompetenceTemp(skillId).then(response => {
      const { data } = response;
      if (data.length) {
        this.setState({
          competencesTemp: data,
        });
      }
    });
  };

  handleUpdateSkill = async values => {
    this.setState({
      isSubmitting: true,
    });
    const body = {
      name: values.name,
      deName: values.deName,
      enName: values.enName,
      uaName: values.uaName,
    };
    editTempSkill(this.state.selectedSkill.id, body)
      .then(() => {
        this.onCloseEditDialog();
        this.handleReloadElements();
        this.props.showSnackbarMessage('Zapisano zmiany');
        this.setState({
          isSubmitting: false,
        });
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 409) {
          this.props.showSnackbarMessage(
            'Podana umiejętność już istnieje w systemie'
          );
        } else {
          this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
        }
        this.setState({
          isSubmitting: false,
        });
      });
  };

  loadData = async (options = {}) => {
    const { limit, offset, filters } = options || this.state.options;
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.url) || 12
      : limit;
    const { order, orderBy } = this.state;
    this.setState({
      isLoading: true,
    });
    if (filters) {
      filters['isApproved'] = true;
    }
    const params = {
      filters,
      orderBy: {
        [orderBy]: order,
      },
    };

    getTempSkills().then(response => {
      const { data } = response;

      this.setState({
        dictionariesSkills: data,
      });
    });

    getTempSkillsWithLimit(limitToSearch, offset, params).then(response => {
      const { data } = response;
      this.setState({
        skills: data.data,
        skillsCount: data.count,
      });
    });
    this.setState({
      isLoading: false,
    });
  };

  handleReloadElements = (options = false) => {
    if (options) {
      this.setState({
        options,
      });
    }
    if (!this.props.isLoading) {
      return this.loadData(options || this.state.options);
    } else {
      return null;
    }
  };

  componentWillMount() {
    this.handleReloadElements();
  }

  getWrapper = ({ content, filtering, pagination }) => {
    const { order, orderBy } = this.state;
    return (
      <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
        <PageHeader title="Lista dostępnych umiejętności (zaakceptowane)" />
        <Paper style={{ padding: 20 }} elevation={0}>
          {filtering}
        </Paper>
        <Table>
          <TableHead>
            <AdminSkillViewHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <AdminSkillViewHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableFooter>
        </Table>
        {pagination}
        <CustomDialog
          title={'Edycja umiejętności'}
          maxWidth={'lg'}
          isOpen={this.state.openEditDialog}
          onCloseDialog={this.onCloseEditDialog}
          body={
            <div
              style={{
                width: '600px',
              }}
            >
              <AddSkillForm
                dictionariesSkills={this.state.dictionariesSkills}
                skill={this.state.selectedSkill}
                handleUpdateSkill={this.handleUpdateSkill}
                isSubmitting={this.state.isSubmitting}
              />
              <Typography>Kompetencje:</Typography>
              <div
                style={{
                  height: '200px',
                  overflow: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Kategoria</TableCell>
                      <TableCell>Typ</TableCell>
                      <TableCell>Rodzaj</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.competencesTemp.map(competencesTemp => {
                      return (
                        <TableRow key={competencesTemp.id}>
                          <TableCell>
                            {competencesTemp.competenceCategory.name}
                          </TableCell>
                          <TableCell>
                            {competencesTemp.competenceType.name}
                          </TableCell>
                          <TableCell>
                            {competencesTemp.competenceKind.name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Typography>Wykorzystany w Projektach:</Typography>
              <div
                style={{
                  height: '200px',
                  overflow: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Projekt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.projectsSkill.map(projectSkill => {
                      return (
                        <TableRow key={projectSkill.id}>
                          <TableCell>
                            <Link
                              to={PATHS.PROJECT.SHOW.replace(
                                ':id',
                                projectSkill.project.id
                              )}
                            >
                              Projekt: {projectSkill.project.id}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Typography>Wykorzystany przez użytkowników:</Typography>
              <div
                style={{
                  height: '200px',
                  overflow: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Użytkownik</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.usersSkill.map(userSkill => {
                      return (
                        <TableRow key={userSkill.id}>
                          <TableCell>
                            <Link
                              to={PATHS.CANDIDATE.SHOW.replace(
                                ':id',
                                userSkill.user.id
                              )}
                            >
                              Użytkownik: {userSkill.user.id}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Button onClick={this.onCloseEditDialog} color="primary">
                Zamknij
              </Button>
            </div>
          }
        >
          Czy na pewno usunąć?
        </CustomDialog>
        <ConfirmationDialog
          open={this.state.deleteConfirmationOpened}
          titleText="Usuń umiejętność?"
          onClose={this.handleRemove}
          okButtonText="Usuń"
          cancelButtonText="Anuluj"
        >
          Czy na pewno usunąć?
        </ConfirmationDialog>
      </Paper>
    );
  };

  getContent = skill => {
    return (
      <TableRow hover key={skill.id}>
        <TableCell align="right">{skill.id}</TableCell>
        <TableCell padding="none">{skill.name}</TableCell>
        <TableCell padding="none">{skill.enName}</TableCell>
        <TableCell padding="none">{skill.deName}</TableCell>
        <TableCell padding="none">{skill.uaName}</TableCell>
        <TableCell>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => this.onOpenEditDialog(skill)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => this.openRemoveDialog(skill)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { skills, skillsCount } = this.state;

    const filteringOptions = [
      {
        key: 'name',
        type: 'text',
        name: 'Nazwa',
      },
    ];

    return (
      <ElementsListAsync
        storageKey={this.props.match.url}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        handleReloadElements={this.handleReloadElements}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        filteringOptions={filteringOptions}
        elements={skills || []}
        elementsCount={skillsCount || 0}
        scrollUp={true}
      />
    );
  }
}

const mapDispatchToProps = {
  showSnackbarMessage,
};

AdminSkillView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  showSnackbarMessage: PropTypes.func.isRequired,
};
export default connect(null, mapDispatchToProps)(AdminSkillView);
