import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ProjectV2Service } from 'shared/services';
import { CompetenceService } from 'shared/services/competence.service';
import { CandidateCreateForm } from './components';
import { prepareLanguage } from './components/CandidateCreateWizard/CandidateCreateWizard.utils';

const CandidateCreateFromView = ({ match, t, ...props }) => {
  const [candidate, setCandidate] = useState({});
  const [project, setProject] = useState(null);
  const [competences, setCompetences] = useState([]);
  const [languageCompetence, setLanguageCompetence] = useState([]);

  const [fetchDone, setFetchDone] = useState(false);

  useEffect(() => {
    shouldUpdateCandidate();
  }, []);

  const shouldUpdateCandidate = () => {
    const { id, projectId } = match.params;
    if (!id) {
      getCompetences(0);
    }
    if (projectId) {
      getProject(projectId);
    }
  };
  const getProject = id => {
    ProjectV2Service.getProjectV2(id)
      .then(({ data }) => {
        setProject(data);
      })
      .catch(error => {
        alert(t`project:projectError`);
      });
  };
  const getCompetences = id => {
    const userId = id ? id : 0;
    CompetenceService.getCompetences({ project: 0, user: userId })
      .then(({ data }) => {
        setCompetences(data);
        setLanguageCompetence(prepareLanguage(data));
        setFetchDone(true);
      })
      .catch(error => console.log(error));
  };

  return fetchDone ? (
    <CandidateCreateForm
      candidate={candidate}
      project={project}
      competences={competences}
      languageCompetence={languageCompetence}
      shouldUpdateCandidate={shouldUpdateCandidate}
      currentUser={props.currentUser}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(mapStateToProps)(withRouter(CandidateCreateFromView));
