import {
  Typography,
  withStyles,
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { enums } from 'shared/enums';
import Select from 'shared/newComponents/Select';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import {
  getAccommodationDataSelect,
  getSelectedAccommodation,
} from 'shared/utils/functions';
import ProjectOrderLocalization from '../../../../project/NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalization';
import AccommodationData from '../../components/EmploymentManagementDialog/components/AwaitingEmployment/components/AccommodationData';
import AwaitingEmploymentLocationForm from '../../components/EmploymentManagementDialog/components/AwaitingEmployment/components/AwaitingEmploymentLocationForm';
import { styles } from './EmploymentManagementAddAccommodation.styles';

const EmploymentManagementAddAccommodation = ({
  isOpen,
  onClose,
  classes,
  t,
  project,
  projectCandidateEmploymentManagement,
  loadProjectCandidatesEmploymentManagement,
  accommodations,
  shouldLoadAccommodations,
  clearSelected,
}) => {
  const formData = {
    accommodation: projectCandidateEmploymentManagement.accommodation
      ? projectCandidateEmploymentManagement.accommodation.id
      : null,
  };

  const [selectedAccommodation, setSelectedAccommodation] = useState(null);

  const [locationForm, setLocationForm] = useState(false);

  const validationSchema = yup.object({
    accommodation: yup
      .number()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({ accommodation }) => {
      const body = {
        accommodation,
        employmentManagementHistories: [
          {
            candidate: {
              id: projectCandidateEmploymentManagement.candidate.id,
            },
            projectCandidate: {
              id: projectCandidateEmploymentManagement.projectCandidate.id,
            },
          },
        ],
      };

      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_ACCOMMODATION
      )
        .then(() => {
          loadProjectCandidatesEmploymentManagement();
          clearSelected();
        })
        .catch(error => console.log(error));
    },
  });

  useEffect(() => {
    setSelectedAccommodation(
      getSelectedAccommodation(formik.values.accommodation, accommodations)
    );
  }, [formik.values.accommodation]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={isOpen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography
            component="div"
            variant="h6"
          >{t`employmentStatusTable:addAccommodationDialogTitle`}</Typography>
        </DialogTitle>
        <IconButton className={classes.closeBtn} onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="body1">{t`employmentManagementDialog:dialog2.accommodation`}</Typography>
            <ProjectOrderLocalization
              handleOpenAddLocalization={() => setLocationForm(true)}
              accommodation={true}
            />
            <div className={classes.selectLoc}>
              <Select
                formik={formik}
                name="accommodation"
                label={t`employmentManagementDialog:dialog2.chooseAccommodation`}
                variant="transparent"
                items={getAccommodationDataSelect(accommodations)}
                fontBold={true}
              />
            </div>
            <AccommodationData selectedAccommodation={selectedAccommodation} />
            <div className={classes.saveBtnDiv}>
              <Button type="submit" className={classes.saveBtn}>
                {t`employmentManagementDialog:saveBtn`}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {locationForm && (
        <AwaitingEmploymentLocationForm
          accommodation={null}
          project={project}
          isOpen={locationForm}
          onClose={() => {
            shouldLoadAccommodations();
            setLocationForm(false);
          }}
        />
      )}
    </>
  );
};

export default withStyles(styles)(
  withTranslation()(EmploymentManagementAddAccommodation)
);
