import {
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  CloudUpload as FileIcon,
  Close as DeleteIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { showSnackbarMessage } from '../../store/messages/actions';
import 'shared/assets/css/FileInput.css';
import { API_URL } from '../../constants';
import ImageCropper from './ImageCropper';

const FileInput = ({
  closeDialog,
  setIsOpen,
  isOpen,
  filesLimit = '4',
  deleteFile,
  onDeleteAsset,
  setFiles,
  showSnackbarMessage,
  maxSize,
  saveFiles,
  acceptedFiles,
  cropShape,
  aspect,
  removeIcon,
  imageName,
  t,
  ...props
}) => {
  const [cropperOpen, setCropperOpen] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const acceptedFilesDictionary = {
    'image/*': '.jpg, .png, .gif, .jpeg',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      '.xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      '.pptx',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      '.docx',
  };

  const acceptedFilesDropZone = {
    'image/*': 'image/jpg, image/png, image/gif, image/jpeg',
  };

  const handleClose = () => {
    closeDialog();
    setIsOpen(false);
    setCropperOpen(false);
  };

  const onDrop = async files => {
    const oldFiles = props.files;

    if (filesLimit === 1) {
      if (oldFiles[0]) {
        await deleteFile(oldFiles[0].id);
      }
      setFiles(files);
      setCropperOpen(true);
    } else if (oldFiles.length > filesLimit) {
      showSnackbarMessage(t`fileInput:noMoreFiles`);
    } else {
      setFiles(oldFiles.concat(files));
      setCropperOpen(true);
    }
  };

  const handleRemove = (file, fileIndex) => {
    const fileBeforeRemove = props.files.filter(
      (file, index) => index !== fileIndex
    );

    setFiles(fileBeforeRemove);

    if (file.id) {
      deleteFile(file.id);
      onDeleteAsset(file.id);
    }
  };

  const checkFilesSize = files => {
    const fileStatus = {
      overSize: false,
    };
    props.files.forEach(file => {
      if (file.size > maxSize) {
        fileStatus.overSize = true;
      }
    });
    return fileStatus.overSize;
  };

  const setOneFile = files => {
    setCropperOpen(files.cropperOpen);
    setFiles(files.files);
  };

  const saveNewFiles = async () => {
    setDisabledSaveButton(true);
    const fileOverSize = checkFilesSize(props.files);

    if (fileOverSize) {
      showSnackbarMessage(t`fileInput:tooLarge`);
    } else if (props.files.length > filesLimit) {
      showSnackbarMessage(t`fileInput:tooManyFiles`);
    } else if (props.files.length <= 0) {
      showSnackbarMessage(t`fileInput:noFile`);
    } else {
      await saveFiles(props.files);
    }
    setDisabledSaveButton(false);
  };

  const onDropRejected = files => {
    if (files[0].size > maxSize) {
      showSnackbarMessage(t`fileInput:tooLarge`);
    } else if (
      !acceptedFilesDropZone[acceptedFiles].split(',').includes(files[0].type)
    ) {
      showSnackbarMessage(t`fileInput:badFormat`);
    } else {
      showSnackbarMessage(t`fileInput:rejected`);
    }
  };

  let img;
  let previews = '';
  const fileSizeLimit = maxSize || 5000000;

  previews = props.files.map((file, i) => {
    const path = _.get(file, 'preview') || '/pic' + file.path;

    if (file.type.startsWith('image')) {
      img = (
        <img
          className="smallPreviewImg"
          alt={file.filename}
          src={file.id ? API_URL + path : path}
        />
      );
    } else {
      img = <FileIcon className="smallPreviewImg" alt={file.filename} />;
    }

    return (
      <div className={'imageContainer col fileIconImg'} key={i}>
        <Tooltip title={file.name}>
          <div>
            {img}
            <div className="middle">
              {removeIcon && (
                <IconButton onClick={handleRemove.bind(this, file, i)}>
                  <DeleteIcon className="removeBtn" />
                </IconButton>
              )}
            </div>
          </div>
        </Tooltip>
      </div>
    );
  });

  const allowedFiletypes = _.join(
    acceptedFiles.map(type => {
      return acceptedFilesDictionary[type] || type;
    }),
    ', '
  );
  if (props.files.length && cropperOpen && acceptedFiles[0] === 'image/*') {
    return (
      <ImageCropper
        image={props.files[props.files.length - 1].preview}
        files={props.files}
        index={props.files.length - 1}
        cropShape={cropShape}
        isOpen={isOpen}
        handleClose={handleClose}
        setOneFile={setOneFile}
        aspect={aspect}
        imageName={imageName}
      />
    );
  }
  return (
    <Dialog title={t`fileInput:sendFiles`} open={isOpen} onClose={handleClose}>
      <DialogContent title={''}>
        <Paper>
          <Dropzone
            accept={
              acceptedFilesDropZone[acceptedFiles] || acceptedFiles.join(',')
            }
            onDrop={onDrop}
            className={'dropZone'}
            acceptClassName={'stripes'}
            rejectClassName={'rejectStripes'}
            onDropRejected={onDropRejected}
            maxSize={fileSizeLimit}
          >
            <div className={'dropzoneTextStyle'}>
              <Typography variant="subtitle1" component="h1">
                {t`fileInput:dnd`}
              </Typography>
              <CloudUploadIcon className={'uploadIconSize'} />
            </div>
          </Dropzone>
        </Paper>
        <br />
        <div className="row">
          {props.files.length ? (
            <Typography variant="subtitle1" component="h2">
              {t`fileInput:preview`}
            </Typography>
          ) : null}
        </div>
        <div className="row">{previews}</div>
        <Typography className="row" variant="subtitle1" component="h2">
          {t`fileInput:fileTypes`} {allowedFiletypes}
        </Typography>
        <Typography className="row" variant="subtitle1" component="h2">
          {t`fileInput:maxFileSize`} {maxSize / 1000000}MB
        </Typography>
        <Typography className="row" variant="subtitle1" component="h2">
          {t`fileInput:maxFiles`} {filesLimit}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>{t`fileInput:close`}</Button>
        <Button
          disabled={disabledSaveButton}
          onClick={() => saveNewFiles()}
          variant="contained"
        >
          {t`fileInput:save`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  showSnackbarMessage,
};

export default connect(null, mapDispatchToProps)(withTranslation()(FileInput));
