import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getProject,
  deleteProjectCandidate,
} from 'shared/services/projectService';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';

const styles = {
  iconClass: {
    float: 'right',
    marginRight: 10,
  },
};

export class RemoveSelfFromProjectButton extends Component {
  state = {
    open: false,
  };

  handleClickOpen = async () => {
    this.setState({ open: true });
  };

  handleAccept = async () => {
    const { currentUser, projectDetails } = this.props;
    await this.props.deleteProjectCandidate(projectDetails.id, currentUser.id);
    this.props.getProject(projectDetails.id);
    this.setState({ open: false });
  };

  handleReject = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      currentUser,
      projectDetails,
      projectDetails: { permissions = {} },
      t,
    } = this.props;
    const { stageNumber } = permissions;

    return (
      !currentUser.isRecruiter &&
      !projectDetails.closedStatus &&
      stageNumber > 0 && (
        <React.Fragment>
          <ActionButtonJobllegroRounded onClick={this.handleClickOpen}>
            {t`project:removeFromProject`}
            <DeleteIcon className={classes.iconClass} />
          </ActionButtonJobllegroRounded>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t`core:action.approve`}?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleReject} color="primary">
                {t`core:action.noImRejecting`}
              </Button>
              <Button onClick={this.handleAccept} color="primary" autoFocus>
                {t`core:action.yesImApproving`}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = store => {
  return {
    projectDetails: store.projects.currentProject,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  deleteProjectCandidate,
  getProject,
};

RemoveSelfFromProjectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  deleteProjectCandidate: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(RemoveSelfFromProjectButton)));
