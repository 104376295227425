import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  CheckBox as EnabledIcon,
  Refresh as RefreshIcon,
  CheckBoxOutlineBlank as DisabledIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { PATHS } from 'config';
import { refreshToken } from 'shared/services/authService';
import { editRecruiterMentorStatus } from 'shared/services/recruiterService';
import {
  getUsersWithLimit,
  clearSelectedUsersList,
  editUser,
  deleteUser,
} from 'shared/services/userService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog/';
import PageHeader from '../../../components/PageHeader';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import AdminUserHead from './AdminSuperUserHead';

export class AdminUserView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'id',
      deleteConfirmationOpened: false,
      selectedUser: {},
      options: {
        limit: 12,
        offset: 0,
      },
      isClicked: false,
      recruiterRating: [],
    };
  }

  handleSorting = async (event, property) => {
    const orderBy = property;
    let order = 'asc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'asc') {
      order = 'desc';
    }

    await this.setState({ order, orderBy });
    this.handleReloadElements();
  };

  openRemoveDialog = user => {
    this.setState({
      selectedUser: user,
      deleteConfirmationOpened: true,
    });
  };

  updateUser = async (user, index) => {
    const filtredRecruiterRating = this.state.recruiterRating.filter(
      item => item.index === index
    );
    if (
      filtredRecruiterRating.length &&
      !isNaN(filtredRecruiterRating[0].value)
    ) {
      try {
        const body = user.isRecruiter
          ? {
              ...user,
              recruiterRating: filtredRecruiterRating[0].value,
            }
          : {
              id: user.id,
              recruiterRating: filtredRecruiterRating[0].value,
            };

        await this.props.editUser(body);
        const removeUpdatedRating = this.state.recruiterRating.filter(
          item => item.index !== index
        );
        const recruiterRatingArray = [...removeUpdatedRating];
        this.setState({
          recruiterRating: recruiterRatingArray,
        });
        this.props.showSnackbarMessage('Zapisano zmiany');
        this.handleReloadElements();
      } catch (error) {
        this.props.showSnackbarMessage('Błąd podczas zapisu informacji');
      }
    }
  };

  handleRemove = async isOk => {
    if (isOk) {
      await this.props.deleteUser(this.state.selectedUser.id);
      this.handleReloadElements();
    }
    this.setState({
      deleteConfirmationOpened: false,
    });
  };

  handleRefreshToken = async user => {
    await this.props.refreshToken(user.id);
    this.handleReloadElements();
  };

  handleToggle = async user => {
    await this.props.editUser({ id: user.id, enabled: !user.enabled });
    this.handleReloadElements();
  };

  handleToggleSuperUser = async user => {
    const body = user.isRecruiter
      ? {
          ...user,
          isSuperUser: !user.isSuperUser,
        }
      : {
          id: user.id,
          isSuperUser: !user.isSuperUser,
        };

    await this.props.editUser(body);
    this.handleReloadElements();
  };

  handleSetMentorStatus = async (user, idStatus) => {
    const { editRecruiterMentorStatus } = this.props;
    await editRecruiterMentorStatus({
      id: user.id,
      mentorStatus: idStatus,
    });
    this.handleReloadElements();
  };

  loadData = async (options = {}) => {
    const { limit, offset, filters } = options || this.state.options;
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(this.props.match.url) || 12
      : limit;
    const { order, orderBy } = this.state;
    this.setState({
      isLoading: true,
    });
    const params = {
      filters,
      orderBy: {
        [orderBy]: order,
      },
      withAdmins: true,
    };
    await this.props.getUsersWithLimit(limitToSearch, offset, params);
    this.setState({
      isLoading: false,
    });
  };

  handleReloadElements = (options = false) => {
    if (options) {
      this.setState({
        options,
      });
    }
    if (!this.props.isLoading) {
      return this.loadData(options || this.state.options);
    } else {
      return null;
    }
  };

  componentWillMount() {
    this.handleReloadElements();
  }

  getWrapper = ({ content, filtering, sorting, pagination }) => {
    const { order, orderBy } = this.state;
    return (
      <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
        <PageHeader title="Lista administratorów" />
        <Paper style={{ padding: 20 }} elevation={0}>
          {filtering}
        </Paper>
        <Table>
          <TableHead>
            <AdminUserHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <AdminUserHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleSorting}
            />
          </TableFooter>
        </Table>
        {pagination}
        <ConfirmationDialog
          open={this.state.deleteConfirmationOpened}
          titleText="Usuń użytkownika"
          onClose={this.handleRemove}
          okButtonText="Usuń"
          cancelButtonText="Anuluj"
        >
          Czy na pewno usunąć?
        </ConfirmationDialog>
      </Paper>
    );
  };
  handleChange = (event, index) => {
    const filtredRecruiterRating = this.state.recruiterRating.filter(
      item => item.index !== index
    );
    const recruiterRatingArray = [
      ...filtredRecruiterRating,
      { value: event.target.value, index: index },
    ];
    this.setState({
      recruiterRating: recruiterRatingArray,
    });
  };

  getContent = (user, index = null) => {
    return (
      <TableRow hover key={user.id}>
        <TableCell align="right">{user.id}</TableCell>
        <TableCell padding="none">{user.email}</TableCell>
        <TableCell padding="none">{user.firstName}</TableCell>
        <TableCell padding="none">{user.lastName}</TableCell>
        <TableCell padding="none">{user.createdAt}</TableCell>
        <TableCell style={{ textAlign: 'center' }} padding="none">
          {user.isSuperUser ? (
            <IconButton
              style={{ padding: 0 }}
              onClick={this.handleToggleSuperUser.bind(this, user)}
            >
              <EnabledIcon />
            </IconButton>
          ) : (
            <IconButton
              style={{ padding: 0 }}
              onClick={this.handleToggleSuperUser.bind(this, user)}
            >
              <DisabledIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell padding="none" style={{ textAlign: 'center' }}>
          {user.confirmationToken && (
            <a
              href={
                '#' +
                PATHS.AUTH.RESET_PASSWORD.replace(
                  ':token',
                  user.confirmationToken
                )
              }
            >
              LINK
            </a>
          )}
        </TableCell>
        <TableCell padding="none" style={{ textAlign: 'center' }}>
          {user.enabled ? (
            <IconButton
              style={{ padding: 0 }}
              onClick={this.handleToggle.bind(this, user)}
            >
              <EnabledIcon />
            </IconButton>
          ) : (
            <IconButton
              style={{ padding: 0 }}
              onClick={this.handleToggle.bind(this, user)}
            >
              <DisabledIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <IconButton
            style={{ padding: 0 }}
            onClick={this.openRemoveDialog.bind(this, user)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            style={{ padding: 0 }}
            onClick={this.handleRefreshToken.bind(this, user)}
          >
            <RefreshIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { elements, elementsCount } = this.props;

    const filteringOptions = [
      {
        key: 'firstName',
        type: 'text',
        name: 'Imię',
      },
      {
        key: 'lastName',
        type: 'text',
        name: 'Nazwisko',
      },
      {
        key: 'email',
        type: 'text',
        name: 'Email',
      },
      {
        key: 'isAdmin',
        type: 'checkbox',
        name: 'Tylko administratorzy',
        value: '1',
        default: false,
      },
    ];

    return (
      <ElementsListAsync
        storageKey={this.props.match.url}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        handleReloadElements={this.handleReloadElements}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        filteringOptions={filteringOptions}
        elements={elements || []}
        elementsCount={elementsCount || 0}
        scrollUp={true}
      />
    );
  }
}

const mapStateToProps = store => {
  return {
    elements: store.users.currentUsersLimitedList,
    elementsCount: store.users.currentUsersLimitedListCounter,
    initialValues: { user: store.users.currentUsersLimitedList },
  };
};

const mapDispatchToProps = {
  getUsersWithLimit,
  clearSelectedUsersList,
  editRecruiterMentorStatus,
  editUser,
  deleteUser,
  refreshToken,
  showSnackbarMessage,
};

const AdminUserViewFormAction = reduxForm({
  form: 'adminuserviewformaction',
  enableReinitialize: true,
})(AdminUserView);

AdminUserView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  getUsersWithLimit: PropTypes.func.isRequired,
  clearSelectedUsersList: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserViewFormAction);
