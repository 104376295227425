import { IconButton, withStyles } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';

const styles = {
  iconButton: {
    marginTop: 0,
    color: '#52a3fc',
    width: 'auto',
    height: 'auto',
  },
};

export const CandidateEditButton = props => {
  const { currentCandidate, currentUser, classes } = props;
  const { permissions = {}, owner = {} } = currentCandidate;
  return (
    <IconButton
      className={classes.iconButton}
      aria-label="Edit"
      component={Link}
      disabled={!permissions.isOwner && owner && owner.id !== currentUser.id}
      to={PATHS.CANDIDATE.NEW_EDIT.replace(':id', currentCandidate.id)}
    >
      {(permissions.isOwner || (owner && owner.id === currentUser.id)) && (
        <EditIcon style={{ fontSize: 18 }} />
      )}
    </IconButton>
  );
};

const mapStateToProps = store => {
  return {
    currentCandidate: store.candidates.currentCandidate,
    currentUser: store.auth.currentUser,
  };
};

CandidateEditButton.propTypes = {
  classes: PropTypes.object.isRequired,
  currentCandidate: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(CandidateEditButton));
