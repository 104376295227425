import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import CandidateMenu from '../../../candidateMenu/CandidateMenu';
import { styles } from './CandidateEmploymentStatusContent.styles';
import CandidateDetailsViewLeftDescription from './components/CandidateDetailsViewLeftDescription';
import TimelineSection from './components/TimelineSection';

const CandidateEmploymentStatusContent = ({
  candidateEmploymentHistories,
  candidateDetails,
  classes,
  currentUser,
}) => {
  return (
    <Grid container className={classes.wrapper}>
      <CandidateMenu
        itemSelected="employment-status"
        title={'candidateDetails:anonymousDetailsCard:menuTitle'}
        subtitle={'candidateDetails:anonymousDetailsCard:subTitle'}
        currentCandidate={candidateDetails}
        currentUser={currentUser}
      />
      <CandidateDetailsViewLeftDescription
        candidateDetails={candidateDetails}
        candidateEmploymentHistories={candidateEmploymentHistories}
      />
      <TimelineSection
        candidateDetails={candidateDetails}
        candidateEmploymentHistories={candidateEmploymentHistories}
      />
    </Grid>
  );
};

export default withStyles(styles)(withRouter(CandidateEmploymentStatusContent));
