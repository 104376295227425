import { Grid, Tab, Tabs, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { Translate } from 'shared/newComponents';

const styles = ({ breakpoints, palette }) => ({
  wrapper: {
    padding: '16px 150px',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  tabsWrapper: { borderBottom: '1px solid #b0b4b9' },
  link: { color: palette.primary.main },
  scroll: { overflow: 'auto' },
});

export const CandidateMenu = ({
  title,
  subtitle,
  itemSelected,
  currentCandidate,
  t,
  classes,
  ...props
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    switch (itemSelected) {
      case 'show':
        setIndex(0);
        break;
      case 'personal-data':
        setIndex(1);
        break;
      case 'all-projects':
        setIndex(2);
        break;
      case 'employment-status':
        setIndex(3);
        break;
      case 'help':
        setIndex(4);
        break;
      default:
        setIndex(0);
    }
  });
  const menuCandidateElements = [
    {
      name: t('candidateDetails:menu.anonymousDetailsCard'),
      route_url: 'show',
      url: PATHS.CANDIDATE.SHOW.replace(':id', currentCandidate.id),
    },
    {
      name: t('candidateDetails:menu.personalDetails'),
      route_url: 'personal-data',
      url: PATHS.CANDIDATE.PERSONAL_DATA.replace(':id', currentCandidate.id),
    },
    {
      name: t('candidateDetails:menu.allProjects'),
      route_url: 'all-projects',
      url: PATHS.CANDIDATE.PROJECTS.replace(':id', currentCandidate.id),
    },
    {
      name: t`candidateDetails:menu.employmentStatus`,
      route_url: 'employment-status',
      url: PATHS.CANDIDATE.EMPLOYMENT_STATUS.replace(
        ':id',
        currentCandidate.id
      ),
    },
    ...(currentCandidate.isFromHelpForm
      ? [
          {
            name: t('help:help.tabTitle'),
            route_url: 'help',
            url: PATHS.CANDIDATE.HELP.replace(':id', currentCandidate.id),
          },
        ]
      : []),
  ];

  let disabled = false;
  if (currentCandidate.id === undefined) {
    disabled = true;
  }

  const handleTabChange = (_, index) => {};

  const subMenu = menuCandidateElements.map((element, key) => {
    let setActive = false;
    if (itemSelected === element.route_url) {
      setActive = true;
    }
    if (currentCandidate.email === null && key !== 0) {
      return [];
    }
    return (
      <Tab
        key={key}
        component={Link}
        to={element.url}
        className={setActive ? classes.active : ''}
        label={element.name}
        disabled={currentCandidate.email === null}
        classes={{
          root: classes.button,
          label: classes.label,
        }}
      />
    );
  });

  return (
    <Grid container className={classes.wrapper}>
      <Grid
        container
        style={{ cursor: 'pointer' }}
        onClick={() => {
          props.history.goBack();
        }}
      >
        <Translate
          text="projectDetails:content.back"
          bold
          underline
          variant="body1"
          className={classes.link}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: '8px 0 16px 0' }}
      >
        <Grid item>
          <Translate style={{ fontSize: 38 }} text={title} variant="h4" bold />
          {subtitle ? (
            <Translate
              style={{ fontSize: 20, color: '#6A6F76' }}
              text={subtitle}
              variant="h6"
              bold
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid container>
        <Grid container className={classes.tabsWrapper}>
          <Tabs
            value={index}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator,
              scrollButtons: classes.scrollButtons,
              flexContainer: classes.scroll,
            }}
          >
            {subMenu}
          </Tabs>
        </Grid>
      </Grid>
    </Grid>
  );
};

CandidateMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  null,
  null
)(withRouter(withStyles(styles)(withTranslation()(CandidateMenu))));
