import { Badge, IconButton, withStyles } from '@material-ui/core';
import {
  // @TODO
  // Mail as MailIcon,
  // Chat as QuestionAnswerIcon,
  Group as SupervisorAccountIcon,
} from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import {
  CandidateV2Service,
  getCandidatesPendingToRecruiter,
} from 'shared/services';
import { getProjects } from 'shared/services';
import Notifications from './Notifications';
import NotificationsOrganization from './NotificationsOrganization';

const styles = () => ({
  container: {
    marginRight: 0,
  },
  iconbutton: {
    color: '#afafaf',
    marginTop: 14,
    padding: 10,
  },
  icon: {
    color: '#afafaf',
  },
  root: {
    textAlign: 'right',
    flexGrow: 1,
  },
});

export class IconBar extends Component {
  state = {
    candidatesStash: [],
  };

  loadCandidates = async () => {
    try {
      const { id } = this.props.currentUser;
      CandidateV2Service.getCandidateV2Stash().then(({ data }) => {
        this.setState({
          candidatesStash: data,
        });
      });
      await this.props.getCandidatesPendingToRecruiter(id);
    } catch (err) {
      console.error(err);
    }
  };

  async componentDidMount() {
    this.loadCandidates();
  }

  render() {
    const { classes, candidatesPendingToRecruiterList, currentUser } =
      this.props;
    const candidateStash = this.state.candidatesStash;

    return (
      <React.Fragment>
        {/*<Hidden xsDown>*/}
        <div className={classes.root}>
          <div className={classes.container}>
            {currentUser.isRecruiter && (
              <React.Fragment>
                <IconButton
                  component={Link}
                  to={PATHS.CANDIDATE.INDEX_SHOW.replace(':show', 'invitation')}
                  className={classes.iconbutton}
                >
                  {candidatesPendingToRecruiterList.length ? (
                    <Badge
                      badgeContent={candidatesPendingToRecruiterList.length}
                      color="secondary"
                    >
                      <SupervisorAccountIcon className={classes.icon} />
                    </Badge>
                  ) : (
                    <SupervisorAccountIcon className={classes.icon} />
                  )}
                </IconButton>
                <IconButton
                  component={Link}
                  to={PATHS.CANDIDATE.INDEX_SHOW.replace(
                    ':show',
                    'candidateFromStash'
                  )}
                  className={classes.iconbutton}
                >
                  {candidateStash.length ? (
                    <Badge
                      badgeContent={candidateStash.length}
                      color="secondary"
                    >
                      <SaveIcon className={classes.icon} />
                    </Badge>
                  ) : (
                    <SaveIcon className={classes.icon} />
                  )}
                </IconButton>
                <NotificationsOrganization />
              </React.Fragment>
            )}
            {/*@TODO*/}
            {/*<IconButton className={classes.iconbutton}>*/}
            {/*<Badge badgeContent={12} color='secondary'>*/}
            {/*<QuestionAnswerIcon className={classes.icon}/>*/}
            {/*</Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton className={classes.iconbutton}>*/}
            {/*<Badge badgeContent={4} color='secondary'>*/}
            {/*<MailIcon className={classes.icon}/>*/}
            {/*</Badge>*/}
            {/*</IconButton>*/}
            <Notifications />
          </div>
        </div>
        {/*</Hidden>*/}
      </React.Fragment>
    );
  }
}

IconBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  getCandidatesPendingToRecruiter,
  getProjects,
};

const mapStateToProps = store => {
  return {
    candidatesPendingToRecruiterList:
      store.candidates.candidatesPendingToRecruiterList,
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(IconBar));
