import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { bool, string } from 'yup';
import { enums } from 'shared/enums';
import { Checkbox } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import TextField from 'shared/newComponents/TextField';
import { RecruiterV2Service } from 'shared/services';
import CustomDialog from '../../../../../../../../../../components/CustomDialog';
import { MODEL } from '../../../../../../../../../project/NewProjectCreateView/components/ProjectCreateWizardStep8/ProjectCreateWizardStep8.utils';

function SettlementDataForm({
  recruiterData,
  isOpen,
  onClose,
  recruiterEdited,
  t,
}) {
  const formData = {
    invoiceCompanyName:
      recruiterData && recruiterData.invoiceCompanyName
        ? recruiterData.invoiceCompanyName
        : '',
    accountNumber:
      recruiterData && recruiterData.accountNumber
        ? recruiterData.accountNumber
        : '',
    invoiceAddress:
      recruiterData && recruiterData.invoiceAddress
        ? recruiterData.invoiceAddress
        : '',
    invoicePostCode:
      recruiterData && recruiterData.invoicePostCode
        ? recruiterData.invoicePostCode
        : '',
    invoiceCity:
      recruiterData && recruiterData.invoiceCity
        ? recruiterData.invoiceCity
        : '',
    invoicePersonalNumber:
      recruiterData && recruiterData.invoicePersonalNumber
        ? recruiterData.invoicePersonalNumber
        : '',
    invoiceIdentificationNumber:
      recruiterData && recruiterData.invoiceIdentificationNumber
        ? recruiterData.invoiceIdentificationNumber
        : '',
    invoiceTypeNaturalPerson:
      recruiterData && recruiterData.invoiceTypeBusiness
        ? recruiterData.invoiceTypeBusiness ===
          enums.ORGANIZATION_TYPES.ORGANIZATION_PERSONAL_TYPE
        : false,
    invoiceTypeBusiness:
      recruiterData && recruiterData.invoiceTypeBusiness
        ? recruiterData.invoiceTypeBusiness ===
          enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        : true,
  };

  const validationShema = Yup.object().shape(
    {
      invoiceTypeBusiness: bool(),
      invoiceCompanyName: Yup.string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      accountNumber: Yup.string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      invoiceAddress: Yup.string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      invoicePostCode: Yup.string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      invoiceCity: Yup.string()
        .strict(false)
        .trim()
        .required(t('projectCreator:core.requiredField')),
      invoiceIdentificationNumber: string().when(['invoiceTypeBusiness'], {
        is: invoiceTypeBusiness => {
          return invoiceTypeBusiness === true;
        },

        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
      invoicePersonalNumber: string().when(['invoiceTypeBusiness'], {
        is: invoiceTypeBusiness => invoiceTypeBusiness === false,
        then: string()
          .strict(false)
          .trim()
          .required(t('projectCreator:core.requiredField'))
          .nullable(),
        otherwise: string().nullable(),
      }),
    },
    ['invoiceTypeBusiness']
  );

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationShema,
    onSubmit: (values, actions) => {
      const body = {
        invoiceCompanyName: values.invoiceCompanyName,
        accountNumber: values.accountNumber,
        invoiceAddress: values.invoiceAddress,
        invoicePostCode: values.invoicePostCode,
        invoiceCity: values.invoiceCity,
        invoiceIdentificationNumber: values.invoiceTypeBusiness
          ? values.invoiceIdentificationNumber
          : null,
        invoicePersonalNumber: values.invoiceTypeBusiness
          ? null
          : values.invoicePersonalNumber,
        invoiceTypeBusiness: values.invoiceTypeBusiness
          ? enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
          : enums.ORGANIZATION_TYPES.ORGANIZATION_PERSONAL_TYPE,
      };

      RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
        stage: 'settlement_data',
      })
        .then(() => {
          recruiterEdited();
          onClose();
        })
        .catch(error => {
          console.log(error);
          alert(t`recruiter:saveError`);
        });
    },
  });

  const isInvoiceTypeBusiness = !!get(formik.values, 'invoiceTypeBusiness');

  const clearOrganizationForm = () => {
    formik.setFieldValue(MODEL.invoiceCompanyName, '');
    formik.setFieldValue(MODEL.invoiceIdentificationNumber, '');
    formik.setFieldValue(MODEL.invoicePersonalNumber, '');
  };

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`organization:settlementData`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid container>
                <Grid item xs={6} sm={6} md={3}>
                  <Checkbox
                    onChange={event => {
                      if (!event.target.checked) {
                        return;
                      }
                      if (event.target.checked !== formik.invoiceTypeBusiness) {
                        clearOrganizationForm();
                      }
                      formik.setFieldValue('invoiceTypeBusiness', true);
                      formik.setFieldValue('invoiceTypeNaturalPerson', false);
                    }}
                    label={t('projectCreator:step8.organizationTypeBusiness')}
                    name={'invoiceTypeBusiness'}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={5}>
                  <Checkbox
                    onChange={event => {
                      if (!event.target.checked) {
                        return;
                      }
                      if (
                        event.target.checked !== formik.invoiceTypeNaturalPerson
                      ) {
                        clearOrganizationForm();
                      }
                      formik.setFieldValue('invoiceTypeBusiness', false);
                      formik.setFieldValue('invoiceTypeNaturalPerson', true);
                    }}
                    label={t(
                      'projectCreator:step8.organizationTypeNaturalPerson'
                    )}
                    name={'invoiceTypeNaturalPerson'}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label={
                        isInvoiceTypeBusiness
                          ? t('organization:enterClientName')
                          : t('organization:enterNames')
                      }
                      name={'invoiceCompanyName'}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label={t('Adres')}
                      name={'invoiceAddress'}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label={t('projectCreator:step8.organizationPostCode')}
                      name={'invoicePostCode'}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label={t('projectCreator:step8.organizationCity')}
                      name={'invoiceCity'}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    {isInvoiceTypeBusiness ? (
                      <TextField
                        label={t(
                          'projectCreator:step8.invoiceIdentificationNumber'
                        )}
                        name={'invoiceIdentificationNumber'}
                        formik={formik}
                      />
                    ) : (
                      <TextField
                        label={t('projectCreator:step8.invoicePersonalNumber')}
                        name={'invoicePersonalNumber'}
                        formik={formik}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label={t('recruiter:field.accountNumber')}
                      name={'accountNumber'}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t`recruiter:save`} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
}

export default connect()(withTranslation()(SettlementDataForm));
