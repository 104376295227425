import {
  Button,
  Typography,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import {
  WatchLater as WatchLaterIcon,
  Add as AddIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { find, isEmpty, filter } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  addProjectRecruiter,
  getProjectRecruiterConfirmationStatus,
  clearProjectRecruiterConfirmationStatus,
  acceptInvitationProjectRecruiter,
  rejectInvitationProjectRecruiter,
  getProjectsPendingToRecruiter,
  clearProjectsPendingToRecruiter,
} from '../../services';
import { getNotifications, setNotificationRead } from '../../services';
import { showSnackbarMessage } from '../../store/messages/actions';
import { ActionButtonJobllegroRounded } from '../../utils/styledComponents';

const styles = theme => ({
  button: {
    background: '#52a3fc',
    color: '#fff',
    '&:hover': {
      background: '#70b3ff',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonAccept: {
    background: '#3bbf13',
    color: '#fff !important',
    '&:hover': {
      background: '#3dd60e',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonCancel: {
    background: '#f7544d',
    color: '#fff !important',
    '&:hover': {
      background: '#ed635e',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  iconClass: {
    float: 'right',
    marginRight: 10,
  },
});

class AssignRecruiterToProjectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  addProjectRecruiter = async () => {
    const {
      addProjectRecruiter,
      getProjectRecruiterConfirmationStatus,
      showSnackbarMessage,
      projectDetails = {},
      currentUser = {},
      t,
    } = this.props;
    await addProjectRecruiter(projectDetails.id, currentUser.id);
    await getProjectRecruiterConfirmationStatus(
      projectDetails.id,
      currentUser.id
    );
    showSnackbarMessage(t('recruiter:form.recruiterPendingToProject'));
  };

  acceptInvitation = async () => {
    const {
      acceptInvitationProjectRecruiter,
      showSnackbarMessage,
      projectDetails = {},
      currentUser = {},
      notifications,
      setNotificationRead,
      getNotifications,
      t,
    } = this.props;
    const findNotification = find(notifications, function (n) {
      const { project = {} } = n.data;
      return project.id === projectDetails.id;
    });
    await acceptInvitationProjectRecruiter(
      projectDetails.id,
      currentUser.id,
      2
    );
    this.getConfirmationStatus();
    await setNotificationRead(findNotification.id);
    await getNotifications();
    showSnackbarMessage(t('recruiter:form.recruiterAcceptanceIsApproved'));
    window.location.reload();
  };

  rejectInvitation = async () => {
    const {
      rejectInvitationProjectRecruiter,
      showSnackbarMessage,
      projectDetails = {},
      currentUser = {},
      notifications,
      setNotificationRead,
      getNotifications,
      t,
    } = this.props;
    const findNotification = find(notifications, function (n) {
      const { project = {} } = n.data;
      return project.id === projectDetails.id;
    });
    await rejectInvitationProjectRecruiter(
      projectDetails.id,
      currentUser.id,
      3
    );
    this.getConfirmationStatus();
    await setNotificationRead(findNotification.id);
    await getNotifications();
    showSnackbarMessage(t('recruiter:form.invitationRejected'));
    window.location.reload();
  };

  getConfirmationStatus = async () => {
    const {
      getProjectRecruiterConfirmationStatus,
      clearProjectRecruiterConfirmationStatus,
      projectDetails = {},
      currentUser = {},
    } = this.props;
    if (projectDetails.id) {
      this.setState({ isLoading: true });
      this.props.clearProjectsPendingToRecruiter();
      await this.props.getProjectsPendingToRecruiter({
        coOnly: true,
        confirmationStatus: 1,
      });
      await clearProjectRecruiterConfirmationStatus();
      await getProjectRecruiterConfirmationStatus(
        projectDetails.id,
        currentUser.id
      );
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    const { isRecruiter } = this.props.currentUser;
    if (isRecruiter) {
      await this.getConfirmationStatus();
    }
  }

  componentWillUnmount() {
    const { clearProjectRecruiterConfirmationStatus } = this.props;
    clearProjectRecruiterConfirmationStatus();
  }

  render() {
    const {
      classes,
      currentUser,
      projectDetails,
      projectRecruiterConfirmationStatus,
      t,
      notifications,
    } = this.props;
    const findCandidate_CO7 = filter(notifications, function (item) {
      return (
        item.type === 'project_candidate_co_7' &&
        item.data.project.id === projectDetails.id
      );
    });
    let findRecruiter = {};
    if (!isEmpty(findCandidate_CO7)) {
      findRecruiter = find(notifications, function (recruiter) {
        return (
          recruiter.type === 'project_recruiter_co_1' &&
          recruiter.data.project.id === projectDetails.id
        );
      });
    }

    const { confirmationStatus, owner = {} } =
      projectRecruiterConfirmationStatus;

    const { permissions = {} } = projectDetails;
    const { isOwner = false } = permissions;
    const { isRecruiter } = currentUser;
    const { isLoading } = this.state;
    if (
      permissions.isHiringManager ||
      permissions.isMentor ||
      permissions.isOwner
    ) {
      return null;
    }
    return !projectDetails.closedStatus && isRecruiter && isLoading ? (
      <Button
        disabled={true}
        className={classes.button}
        style={{ textAlign: 'center', background: '#f9f9f9', width: 250 }}
      >
        <CircularProgress
          style={{ width: 25, height: 25 }}
          className={classes.progress}
        />
      </Button>
    ) : isEmpty(findRecruiter) ? (
      <>
        <React.Fragment>
          <React.Fragment>
            {confirmationStatus === 2 && (
              <ActionButtonJobllegroRounded
                className={classes.buttonAccept}
                disabled={true}
              >
                {t('recruiter:form.recruiterInProject')}
              </ActionButtonJobllegroRounded>
            )}
            {confirmationStatus === 5 && (
              <ActionButtonJobllegroRounded
                className={classes.buttonAccept}
                disabled={true}
              >
                {t('recruiter:form.recruiterInProjectAccepted')}
              </ActionButtonJobllegroRounded>
            )}
            {confirmationStatus === 3 && (
              <ActionButtonJobllegroRounded
                className={classes.buttonCancel}
                disabled={true}
              >
                {t('recruiter:form.recruiterProjectRejected')}
              </ActionButtonJobllegroRounded>
            )}
            {confirmationStatus === 6 && (
              <ActionButtonJobllegroRounded
                className={classes.buttonCancel}
                disabled={true}
              >
                {t('recruiter:form.recruiterWasRejected')}
              </ActionButtonJobllegroRounded>
            )}
            {currentUser.isRecruiter &&
              !isOwner &&
              owner.id !== currentUser.id &&
              confirmationStatus === 1 && (
                <React.Fragment>
                  <ActionButtonJobllegroRounded
                    onClick={this.acceptInvitation}
                    className={classes.buttonAccept}
                    disabled={projectDetails.closedStatus === 1}
                  >
                    {t('recruiter:form.approveInvitation')}
                    <DoneIcon className={classes.iconClass} />
                  </ActionButtonJobllegroRounded>
                  <ActionButtonJobllegroRounded
                    onClick={this.rejectInvitation}
                    className={classes.buttonCancel}
                    disabled={projectDetails.closedStatus === 1}
                  >
                    {t('recruiter:form.rejectInvitation')}
                    <CloseIcon className={classes.iconClass} />
                  </ActionButtonJobllegroRounded>
                </React.Fragment>
              )}
          </React.Fragment>

          {currentUser.isRecruiter &&
            !isOwner &&
            owner.id === currentUser.id &&
            (confirmationStatus === 1 || confirmationStatus === 4) && (
              <React.Fragment>
                <ActionButtonJobllegroRounded
                  disabled={projectDetails.closedStatus === 1}
                >
                  {t('recruiter:form.recruiterWaitingForAcceptation') + '...'}
                  <WatchLaterIcon className={classes.iconClass} />
                </ActionButtonJobllegroRounded>
              </React.Fragment>
            )}

          {currentUser.isRecruiter &&
            !isOwner &&
            owner.id !== currentUser.id &&
            confirmationStatus === undefined && (
              <React.Fragment>
                <ActionButtonJobllegroRounded
                  onClick={this.addProjectRecruiter}
                  disabled={
                    (projectDetails.closedStatus === 1 ||
                      projectDetails.closedStatus === 2) &&
                    true
                  }
                >
                  {t('recruiter:form.addAsCooperatorToProject')}
                  <AddIcon className={classes.iconClass} />
                </ActionButtonJobllegroRounded>
              </React.Fragment>
            )}
        </React.Fragment>
      </>
    ) : (
      <Typography variant="h6" align="center" style={{ color: '#52a3fc' }}>
        {t`candidate:show.oneOf`}
        <br />
        {t`candidate:show.autoAssign`}{' '}
      </Typography>
    );
  }
}

const mapDispatchToProps = {
  addProjectRecruiter,
  getProjectRecruiterConfirmationStatus,
  clearProjectRecruiterConfirmationStatus,
  acceptInvitationProjectRecruiter,
  rejectInvitationProjectRecruiter,
  getProjectsPendingToRecruiter,
  clearProjectsPendingToRecruiter,
  getNotifications,
  setNotificationRead,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    projectRecruiterConfirmationStatus:
      store.projects.projectRecruiterConfirmationStatus,
    notifications: store.notifications.list,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(AssignRecruiterToProjectButton)));
