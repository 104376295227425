import { Grid, withStyles } from '@material-ui/core';
import { get, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import { ProgressBar, RadarChart, Translate } from 'shared/newComponents';
import { WorkStylePointsService } from 'shared/services';
import { getEnumItemNameFullObject } from 'shared/utils/assets';
import { getCompetenceHeaderInfo } from 'shared/utils/functions';
import { MODEL } from '../../../ProjectCreateWizardStep2/ProjectCreateWizardStep2.utils';
import { styles } from './ProjectReviewStep2.styles';

const ProjectReviewStep2 = ({ step2FormValues, t, classes }) => {
  const [workStylePoints, setWorkStylePoints] = useState({});

  const projectSkills = get(step2FormValues, MODEL.projectSkills) || [];
  const requirements = get(step2FormValues, MODEL.requirements);
  const workStyles = get(step2FormValues, MODEL.workStyles);

  useEffect(() => {
    const data = { workStyles: workStyles };
    WorkStylePointsService.setWorkStylePoints(data)
      .then(({ data }) => setWorkStylePoints(data))
      .catch(console.log);
  }, [workStyles]);

  const hideTitle =
    projectSkills.length === 0 && (requirements === undefined || !requirements);
  const hideWorkStyle = workStyles === undefined || workStyles.length === 0;

  return (
    <>
      {hideTitle ? null : (
        <Grid container className={classes.contentWrapper}>
          <Translate
            style={{ fontSize: 26 }}
            text="projectCreator:step2.title"
            bold
            variant="h6"
          />
          <Grid container style={{ paddingBottom: 16 }}>
            {map(projectSkills, (skill, index) => {
              if (!skill) {
                return null;
              }
              const experienceLevel = get(skill, MODEL.experienceLevel) + 1;
              const level =
                experienceLevel * 10 < 100 ? experienceLevel * 10 : 100;
              const headerInfo = getCompetenceHeaderInfo(
                step2FormValues,
                index,
                t
              );

              const secondHeaderInfo = getEnumItemNameFullObject(
                enums.EXPERIENCE_LEVEL,
                get(skill, MODEL.experienceLevel)
              );

              return (
                <ProgressBar
                  key={skill.id}
                  percentage={level}
                  title={headerInfo}
                  secondTitle={secondHeaderInfo}
                  customStyleSecondHeader={{
                    color: '#6CB8FF',
                  }}
                />
              );
            })}
          </Grid>
          <Grid container>
            <Translate
              style={{ fontSize: 20 }}
              text="projectCreator:reviewStep2.requirements"
              variant="h6"
              bold
            />
          </Grid>
          <Grid container>{ReactHtmlParser(requirements)}</Grid>
        </Grid>
      )}
      {hideWorkStyle ? null : (
        <Grid
          container
          className={classes.bluePrimaryBackground}
          justifyContent="center"
        >
          <Grid container>
            <Translate
              text="projectCreator:reviewStep2.workStylePoints"
              variant="h6"
              bold
            />
          </Grid>
          <RadarChart data={workStylePoints} />
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(withTranslation()(ProjectReviewStep2));
