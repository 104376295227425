import { Grid, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { Translate } from 'shared/newComponents';
import { getAssetUrl } from 'shared/services';
import { styles } from './CandidateReviewStep1.styles';

const CandidateReviewStep1 = ({ step1FormValues, classes, t }) => {
  const position = step1FormValues.position;
  const industryCategory = step1FormValues.industryCategory;
  const industryCategoryName = get(industryCategory, 'name');
  const industryCategoryAssets = get(industryCategory, 'assets');
  const industryCategoryLogo = industryCategoryAssets
    ? industryCategoryAssets.find(item => item.type === 'industryCategoryLogo')
    : null;

  const industryCategoryIcon = industryCategoryLogo
    ? getAssetUrl(industryCategoryLogo.id)
    : null;

  return (
    <>
      <Grid container className={classes.contentWrapper}>
        <Grid container style={{ paddingBottom: 12 }}>
          {industryCategoryName && (
            <Grid
              container
              alignItems="center"
              style={{
                display: 'flex',
                padding: '4px 0',
                flexDirection: 'column',
                alignItems: 'baseline',
              }}
            >
              <img
                alt="industryCategoryIcon"
                src={industryCategoryIcon}
                style={{ height: 60, paddingRight: 8 }}
              />
              <Translate
                style={{ fontSize: 24, color: '#D8DCE0' }}
                text={industryCategoryName}
                bold
                variant="body1"
              />
            </Grid>
          )}

          {position && (
            <Grid container>
              <Translate
                style={{ color: '#148EFF', fontSize: 42, lineHeight: 1.2 }}
                text={position}
                disableTranslate={true}
                bold
                variant="h6"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CandidateReviewStep1);
