export const styles = () => ({
  bgBlue: { background: '#E5F0FF' },
  container: { padding: 25 },
  editBtn: { cursor: 'pointer' },
  hidden: { visibility: 'hidden' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  dates: { paddingBottom: 25 },
  date: { opacity: '70%', fontWeight: 600 },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  comebackBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#148EFF',
    borderRadius: 30,
    '&:hover': {
      background: '#148EFF',
      opacity: 0.8,
    },
  },
  breakStartDate: { position: 'relative' },
  error: { color: '#f41010', position: 'absolute', top: '25px' },
});
