import PropTypes from 'prop-types';
import React from 'react';
import { GoogleLogin as GoogleLoginAPI } from 'react-google-login';
import { withTranslation } from 'react-i18next';
import { CONST_CONFIG } from 'shared/constants';
import SocialLoginButton from '../SocialLoginButton';

const GoogleLogin = props => (
  <GoogleLoginAPI
    clientId={CONST_CONFIG.GMAIL.GOOGLE_CLIENT_ID}
    onSuccess={response => {
      const responseData = {
        accessToken: response.accessToken,
        type: CONST_CONFIG.GMAIL.LOGIN_GOOGLE_API,
      };
      props.onSuccess(responseData);
    }}
    render={({ onClick, disabled }) => (
      <SocialLoginButton
        brand="google"
        onClick={onClick}
        disabled={disabled}
        label={props.text ? props.text : props.t`core:gmail`}
      />
    )}
    onFailure={error => {
      if (error.error !== 'popup_closed_by_user') {
        console.log(error);
        // alert('Błędna konfiguracja klucza'); //TODO: Task already in backlog
      }
    }}
  />
);

GoogleLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  text: PropTypes.string,
};

GoogleLogin.displayName = 'GoogleLogin';

export default withTranslation()(GoogleLogin);
