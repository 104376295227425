import {
  Typography,
  withStyles,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import EditIcon from 'shared/assets/img/icons/edit.png';
import { enums } from 'shared/enums';
import Checkbox from 'shared/newComponents/Checkbox';
import Select from 'shared/newComponents/Select';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import { dateAdd } from 'shared/utils/date';
import {
  getAccommodationDataSelect,
  getSelectedAccommodation,
  getTargetDate,
} from 'shared/utils/functions';
import ProjectOrderLocalization from '../../../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalization';
import AccommodationData from '../AwaitingEmployment/components/AccommodationData/AccommodationData';
import AwaitingEmploymentLocationForm from '../AwaitingEmployment/components/AwaitingEmploymentLocationForm/AwaitingEmploymentLocationForm';
import { styles } from './Quarantine.styles';

const Quarantine = ({
  classes,
  t,
  selectedFullObject,
  shouldLoadAccommodations,
  accommodations,
  dataAdded,
  project,
  showSaveBtn,
}) => {
  const [isQuarantineEdited, setIsQuarantineEdited] = useState(false);
  const [locationForm, setLocationForm] = useState(false);
  const [selectedAccommodation, setSelectedAccommodation] = useState(null);

  const formData = {
    isQuarantineRequired: selectedFullObject
      ? selectedFullObject.isQuarantineRequired
      : '',
    quarantineStartDate:
      selectedFullObject && selectedFullObject.quarantineStartDate
        ? selectedFullObject.quarantineStartDate
        : selectedFullObject.corsBorderDate,
    quarantineEndDate:
      selectedFullObject && selectedFullObject.quarantineEndDate
        ? selectedFullObject.quarantineEndDate
        : dateAdd(
            selectedFullObject.corsBorderDate
              ? selectedFullObject.corsBorderDate
              : moment(),
            10
          ).format('YYYY-MM-DD'),
    quarantineAccommodation: selectedFullObject.quarantineAccommodation
      ? selectedFullObject.quarantineAccommodation.id
      : null,
  };

  const { targetEndDate } = getTargetDate(selectedFullObject.candidate);

  const minDate = selectedFullObject.corsBorderDate
    ? selectedFullObject.corsBorderDate
    : moment().format('YYYY-MM-DD');

  const validationSchema = yup.object({
    quarantineStartDate: yup
      .date()
      .min(
        minDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minDate}`
      )
      .max(
        targetEndDate,
        `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${targetEndDate}`
      )
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
    quarantineEndDate: yup
      .date()
      .min(
        minDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minDate}`
      )
      .max(
        targetEndDate,
        `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${targetEndDate}`
      )
      .required(t`ats2ats3:validation.isRequired`),
    quarantineAccommodation: yup
      .number()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({
      quarantineStartDate,
      quarantineEndDate,
      quarantineAccommodation,
    }) => {
      if (
        moment(quarantineStartDate).isAfter(moment(quarantineEndDate), 'day')
      ) {
        formik.setFieldError(
          'quarantineStartDate',
          t`ats2ats3:validation.date`
        );
        return;
      }
      const body = {
        quarantineStartDate,
        quarantineEndDate,
        quarantineAccommodation,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };
      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_QUARANTINE
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => console.log(error));
    },
  });

  useEffect(() => {
    setSelectedAccommodation(
      getSelectedAccommodation(
        formik.values.quarantineAccommodation,
        accommodations
      )
    );
  }, [formik.values.quarantineAccommodation]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.bgGreen}>
          <div className={classes.container}>
            <Checkbox
              disabled
              formik={formik}
              name="isQuarantineRequired"
              label={t`employmentManagementDialog:dialog3.quarantineIsRequired`}
            />
            <div className={classes.padding}>
              <div className={classes.quarantinePeriod}>
                <Typography variant="body1">{t`employmentManagementDialog:dialog3.quarantinePeriod`}</Typography>
                <img
                  src={EditIcon}
                  alt="edit"
                  className={
                    isQuarantineEdited ? classes.hidden : classes.editBtn
                  }
                  onClick={() => setIsQuarantineEdited(true)}
                />
              </div>
              <div className={classes.datesContainer}>
                <div className={classes.dates}>
                  <div className={classes.date}>
                    <PowerSettingsNew className={classes.powerOnBtn} />
                    {isQuarantineEdited ? (
                      <TextField
                        type="date"
                        formik={formik}
                        name="quarantineStartDate"
                        variant="transparent"
                      />
                    ) : (
                      <div className={classes.quarantineStartDate}>
                        <Typography variant="h5">
                          {formik.values.quarantineStartDate
                            ? formik.values.quarantineStartDate
                            : t`assign:noData`}
                        </Typography>
                        {!formik.values.quarantineStartDate && (
                          <FormHelperText
                            className={classes.error}
                          >{t`employmentManagementDialog:dialog2.validation.resignationEndDate`}</FormHelperText>
                        )}
                        {formik.errors.quarantineStartDate && (
                          <FormHelperText className={classes.error}>
                            {formik.errors.quarantineStartDate}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  </div>
                  <div className={classes.date}>
                    <PowerSettingsNew className={classes.powerOffBtn} />
                    {isQuarantineEdited ? (
                      <TextField
                        type="date"
                        formik={formik}
                        name="quarantineEndDate"
                        variant="transparent"
                      />
                    ) : (
                      <div className={classes.quarantineStartDate}>
                        <Typography variant="h5">
                          {formik.values.quarantineEndDate
                            ? formik.values.quarantineEndDate
                            : t`assign:noData`}
                        </Typography>
                        {!formik.values.quarantineEndDate && (
                          <FormHelperText
                            className={classes.error}
                          >{t`employmentManagementDialog:dialog2.validation.resignationEndDate`}</FormHelperText>
                        )}
                        {formik.errors.quarantineEndDate && (
                          <FormHelperText className={classes.error}>
                            {formik.errors.quarantineEndDate}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!formik.values.quarantineAccommodation || isQuarantineEdited ? (
                <>
                  <Typography variant="body1">{t`employmentManagementDialog:dialog2.quarantineAccommodation`}</Typography>
                  {showSaveBtn && (
                    <ProjectOrderLocalization
                      handleOpenAddLocalization={() => setLocationForm(true)}
                    />
                  )}
                  <div className={classes.selectLoc}>
                    <Select
                      formik={formik}
                      name="quarantineAccommodation"
                      label={t`employmentManagementDialog:dialog2.chooseLocation`}
                      variant="transparent"
                      items={getAccommodationDataSelect(accommodations)}
                      fontBold={true}
                    />
                  </div>
                </>
              ) : (
                <AccommodationData
                  selectedAccommodation={selectedAccommodation}
                />
              )}
            </div>
          </div>
        </div>
        {showSaveBtn && (
          <div className={classes.saveBtnDiv}>
            <Button type="submit" className={classes.saveBtn}>
              {t`employmentManagementDialog:saveBtn`}
            </Button>
          </div>
        )}
      </form>
      {locationForm && (
        <AwaitingEmploymentLocationForm
          project={project}
          isOpen={locationForm}
          onClose={() => {
            shouldLoadAccommodations();
            setLocationForm(false);
          }}
        />
      )}
    </>
  );
};

export default withStyles(styles)(withTranslation()(Quarantine));
