import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PinIcon from 'shared/assets/img/icons/project-show/pin.svg';
import Button from 'shared/newComponents/Button';
import { styles } from './ProjectOrderLocalization.styles';

const ProjectOrderLocalization = ({
  handleOpenAddLocalization,
  helpModal = false,
  classes,
  accommodation,
  t,
}) => {
  return (
    <Grid
      container
      className={classes.gridMarginWrapper}
      justifyContent={helpModal ? 'center' : ''}
    >
      {helpModal ? null : (
        <Grid item xs={2} sm={2} md={2}>
          <Grid container justifyContent={'center'}>
            <img src={PinIcon} alt={'city'} className={classes.image} />
          </Grid>
        </Grid>
      )}

      <Grid item xs={10} sm={8} md={5}>
        <Button
          label={`+ ${t`action.add`} ${
            accommodation
              ? t`modifyOrderDialog:accommodation`
              : t`modifyOrderDialog:location`
          }`}
          onClick={handleOpenAddLocalization}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(ProjectOrderLocalization));
