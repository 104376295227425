import { Grid, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Translate } from 'shared/newComponents';
import Button from 'shared/newComponents/Button';
import Checkbox from 'shared/newComponents/Checkbox';
import PostCode from 'shared/newComponents/PostCode';
import TextField from 'shared/newComponents/TextField';
import { LocalizationService } from 'shared/services';
import { styles } from './ProjectOrderLocalizationForm.styles';

const ProjectOrderForm = ({ project, localizationAdded, classes, t }) => {
  const projectOrder = project.projectOrders[0];
  const projectClient = project.client;

  const projectOrderCompany = projectOrder.company;

  const formData = {
    name: projectClient,
    address: '',
    postCode: '',
    city: '',
    isRemoteWork: false,
    isHybridWork: false,
  };

  const MODEL = {
    name: 'name',
    address: 'address',
    postCode: 'postCode',
    city: 'city',
    isRemoteWork: 'isRemoteWork',
    isHybridWork: 'isHybridWork',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    address: Yup.string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    postCode: Yup.string()
      .length(
        6,
        t`employmentManagementDialog:dialog2.validation.postalCode.length`
      )
      .matches(
        /\d{2}-\d{3}/,
        t`employmentManagementDialog:dialog2.validation.postalCode.badFormat`
      )
      .required(t`ats2ats3:validation.isRequired`),
    city: Yup.string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    isRemoteWork: Yup.bool(),
    isHybridWork: Yup.bool(),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: values => {
      const body = {
        company: { id: projectOrderCompany.id },
        project: { id: project.id },
        address: values.address,
        postCode: values.postCode,
        city: values.city,
        isRemoteWork: values.isRemoteWork,
        isHybridWork: values.isHybridWork,
      };
      LocalizationService.addLocalization(body)
        .then(() => {
          localizationAdded();
        })
        .catch(error => {
          console.log(error);
          alert(t`addOrganizationForm:error`);
        });
    },
  });
  return (
    <Grid container>
      <Grid item>
        <Translate
          text={t`projectOrdersTable:workplace`}
          bold
          className={classes.bigFont}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            type={'text'}
            label={t`modifyOrderDialog:name`}
            disabled
            name={MODEL.name}
            formik={formik}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            type={'text'}
            label={t`modifyOrderDialog:paper.address`}
            name={MODEL.address}
            formik={formik}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <PostCode
            label={t('organization:field.invoicePostCode')}
            name={MODEL.postCode}
            formik={formik}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            type={'text'}
            label={t`modifyOrderDialog:city`}
            name={MODEL.city}
            formik={formik}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Checkbox
            label={t`modifyOrderDialog:openToRemote`}
            name={MODEL.isRemoteWork}
            formik={formik}
            type="switch"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Checkbox
            label={t`modifyOrderDialog:openToHybrid`}
            name={MODEL.isHybridWork}
            formik={formik}
            type="switch"
          />
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <Button
              label={t`adminCitySeo:save`}
              type="submit"
              onClick={formik.handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(ProjectOrderForm));
