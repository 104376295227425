import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getNotifications } from 'shared/services/notificationService';
import {
  getOrganization,
  deleteOrganizationRecruiter,
  getOrganizationRecruitersFilteredForAddingAsyncList,
} from 'shared/services/organizationService';
import {
  editRecruiter,
  clearCurrentRecruitersList,
  editRecruiterOrganization,
} from 'shared/services/recruiterService';
import { getRecruiters } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import OrganizationRecruiters from '../../components/OrganizationRecruiters';
import OrganizationRightList from '../../components/OrganizationRightList';
import PageHeader from '../../components/PageHeader';
import RecruiterOrganizationPanelTopCard from '../../components/RecruiterOrganizationPanelTopCard';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 8,
  },
});

class RecruiterOrganizationRecruitersShowView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmationOpened: false,
      filter: '',
      tabPage: 0,
      options: { limit: 12, offset: 0, filters: null, orderBy: null },
    };
  }

  addOrganizationRecruiter = async recruiterId => {
    try {
      const { t } = this.props;
      const { id: organizationId } = this.props.currentUser.organization;
      const { status } = await this.props.editRecruiterOrganization({
        id: recruiterId,
        organizationId: organizationId,
      });
      if (status === 200) {
        this.loadData(organizationId);
        this.props.showSnackbarMessage(
          t('organization:recruitersList.recruitedAddedToOrganization')
        );
      } else {
        this.props.showSnackbarMessage(
          t('organization:recruitersList.recruitedAddingToOrganizationError')
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  deleteOrganizationRecruiter = async recruiterId => {
    try {
      const { t, getNotifications } = this.props;
      const { id: organizationId } = this.props.currentUser.organization;
      const { status } = await this.props.editRecruiterOrganization({
        id: recruiterId,
        organizationId: null,
      });
      if (status === 200) {
        await this.loadData(organizationId);
        getNotifications();
        this.props.showSnackbarMessage(
          t('organization:recruitersList.recruitedRemovedFromOrganization')
        );
      } else {
        this.props.showSnackbarMessage(
          t(
            'organization:recruitersList.recruitedRemovingFromOrganizationError'
          )
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  loadData = async (id, options = false) => {
    const { limit, offset, filters, orderBy } = options || this.state.options;
    const { tabPage } = this.state;
    this.setState({
      isLoading: true,
    });

    let recruitersParams = {};
    if (tabPage === 1) {
      recruitersParams = { inOrganization: true };
    }
    let params = {
      type: 'recruiter',
      filters,
      orderBy,
      ...recruitersParams,
    };
    this.props.getOrganization(id);
    await this.props.getOrganizationRecruitersFilteredForAddingAsyncList(
      id,
      limit,
      offset,
      params
    );
    this.setState({
      isLoading: false,
    });
  };

  handleReloadElements = async (options = false) => {
    const { id } = this.props.currentUser.organization;
    if (options) {
      this.setState({
        options,
        isLoading: false,
      });
    }
    if (!this.state.isLoading) {
      return this.loadData(id, options || this.state.options);
    } else {
      return null;
    }
  };

  async componentDidMount() {
    const { id } = this.props.currentUser.organization;
    this.loadData(id);
  }

  async componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentUser.organization.id !==
      this.props.currentUser.organization.id
    ) {
      const { id } = nextProps.currentUser.organization;
      this.loadData(id);
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      t,
      currentUser,
      organization: { owner = {} },
    } = this.props;

    const { isLoading } = this.state;

    const isOwner = owner.id === currentUser.id;
    return (
      <React.Fragment>
        <PageHeader
          title={t('organization:recruitersList.organizationMembers')}
        />
        <Grid container spacing={0} className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/*<Paper className={classes.rootPaper} elevation={0}>*/}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ padding: '0px' }}
            >
              <RecruiterOrganizationPanelTopCard />
            </Grid>
            <Grid container alignItems="flex-start" spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={isOwner ? 6 : 12}
                lg={isOwner ? 6 : 12}
              >
                <OrganizationRecruiters
                  isLoading={isLoading}
                  onDeleteHandle={this.deleteOrganizationRecruiter}
                  handleReloadElements={this.handleReloadElements}
                />
              </Grid>
              {isOwner && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <OrganizationRightList
                    // storageKey={this.props.match.url}
                    handleReloadElements={this.handleReloadElements}
                    isLoading={isLoading}
                    userType="recruiter"
                    onAddRecruiterHandle={this.addOrganizationRecruiter}
                  />
                </Grid>
              )}
            </Grid>
            {/*</Paper>*/}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    organization: store.organizations.currentOrganization,
  };
};

const mapDispatchToProps = {
  getNotifications,
  getOrganization,
  getRecruiters,
  editRecruiter,
  editRecruiterOrganization,
  deleteOrganizationRecruiter,
  clearCurrentRecruitersList,
  getOrganizationRecruitersFilteredForAddingAsyncList,
  showSnackbarMessage,
};

RecruiterOrganizationRecruitersShowView.propTypes = {
  classes: PropTypes.object.isRequired,
  getOrganization: PropTypes.func.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  editRecruiter: PropTypes.func.isRequired,
  deleteOrganizationRecruiter: PropTypes.func.isRequired,
  clearCurrentRecruitersList: PropTypes.func.isRequired,
  getOrganizationRecruitersFilteredForAddingAsyncList:
    PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(withTranslation()(RecruiterOrganizationRecruitersShowView))
);
