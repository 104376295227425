import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { Translate } from 'shared/newComponents';
import { styles } from './PersonalData.styles';
import PersonalDataForm from './PersonalDataForm/PersonalDataForm';

const PersonalData = ({ recruiterData, recruiterEdited, classes, t }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const openFormModal = () => {
    setIsOpen(true);
  };

  const firstName =
    recruiterData && recruiterData.firstName
      ? recruiterData.firstName
      : t('core:general.noInformation');
  const lastName =
    recruiterData && recruiterData.lastName
      ? recruiterData.lastName
      : t('core:general.noInformation');
  const email =
    recruiterData && recruiterData.email
      ? recruiterData.email
      : t('core:general.noInformation');
  const phonePrimary =
    recruiterData && recruiterData.phonePrimary
      ? recruiterData.phonePrimary
      : t('core:general.noInformation');

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.topPadding}>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.gridWrapper}>
          <Grid container className={classes.gridPadding}>
            <Grid item xs={10} sm={10} md={10}>
              <Translate
                text={'candidateDetails:personalDetailsContent.personalData'}
                bold
                className={classes.bigFont}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              className={classes.imageWrapper}
              onClick={openFormModal}
            >
              <Grid container>
                <img alt="editIcon" src={EditIcon} height={50} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.firstName')}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={firstName}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
          <hr className={classes.separator} />
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.lastName')}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={lastName}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
          <hr className={classes.separator} />
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.email')}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={email}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
          <hr className={classes.separator} />
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={`${t('candidateCreator:step1.phone')}:`}
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Translate
                text={phonePrimary}
                bold
                disableTranslate={true}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <PersonalDataForm
        recruiterData={recruiterData}
        isOpen={isOpen}
        onClose={onClose}
        recruiterEdited={recruiterEdited}
      />
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withTranslation()(PersonalData)));
