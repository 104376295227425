import { Grid, LinearProgress } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { Snackbar } from 'shared/newComponents';
import { ProjectV2Service } from 'shared/services';
import { ProjectShowViewContent } from './components';

const ProjectShowView = ({ match, t, history }) => {
  const [projectDetails, setProjectDetails] = useState({});
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);

  const id = get(match, 'params.id');

  const redirectToDashboard = () => {
    history.push(PATHS.DASHBOARD.INDEX);
  };

  const fetchProject = () => {
    ProjectV2Service.getProjectV2(id)
      .then(({ data }) => setProjectDetails(data))
      .catch(error => {
        const { status } = error.response;
        if (status === 403) {
          setIsSnackbarErrorOpen(true);
          setTimeout(redirectToDashboard, 2000);
        }
      });
  };

  useEffect(() => {
    if (!!id) {
      ProjectV2Service.getProjectV2(id)
        .then(({ data }) => setProjectDetails(data))
        .catch(error => {
          const { status } = error.response;
          if (status === 403) {
            setIsSnackbarErrorOpen(true);
            setTimeout(redirectToDashboard, 2000);
          }
        });
    }
  }, [id]);

  return isEmpty(projectDetails) ? (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
      <Snackbar
        type={'error'}
        open={isSnackbarErrorOpen}
        onClose={() => setIsSnackbarErrorOpen(false)}
        label={t('projectCreator:core.projectRejected')}
      />
    </Grid>
  ) : (
    <ProjectShowViewContent
      fetchProject={fetchProject}
      projectDetails={projectDetails}
    />
  );
};

export default withRouter(withTranslation()(ProjectShowView));
