import {
  Button,
  Dialog,
  DialogActions,
  withStyles,
  Slider,
} from '@material-ui/core';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { withTranslation } from 'react-i18next';
import { styles } from './ImageCropper.styles';
import { getCroppedImg } from './ImageCropper.utils';

const ImageCropper = ({
  image,
  setOneFile,
  index,
  files,
  classes,
  isOpen,
  handleClose,
  cropShape = 'rect',
  aspect = 4 / 3,
  imageName,
  t,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = crop => {
    setCrop(crop);
  };

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = zoom => {
    setZoom(zoom);
  };

  const handleSaveImage = async () => {
    try {
      const modifiedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        imageName
      );
      const filedToEdit = [...files];
      filedToEdit[index] = modifiedImage;
      await setOneFile({ files: [...filedToEdit], cropperOpen: false });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.mainWindow}>
        <div className={classes.mainWindow}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            cropShape={cropShape}
            aspect={aspect}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            restrictPosition={false}
          />
        </div>
      </div>
      <DialogActions>
        <div className={classes.controls}>
          <Slider
            value={zoom}
            min={0.1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => onZoomChange(zoom)}
            classes={{ container: 'slider' }}
          />
        </div>
      </DialogActions>
      <DialogActions>
        <div>
          <Button onClick={handleClose}>{t`fileInput:close`}</Button>
          <Button onClick={handleSaveImage} variant="contained">
            {t`fileInput:choose`}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(withTranslation()(ImageCropper));
