import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { Translate } from 'shared/newComponents';
import NonContent from '../../../NonContent/NonContent';
import IndustriesExperienceItem from './IndustriesExperienceItem';
import { styles } from './IndustriesExperiences.styles';
import IndustriesExperiencesForm from './IndustriesExperiencesForm';

const IndustriesExperiences = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  isSelfEdit,
  industryCategories,
  t,
  ...props
}) => {
  const userIndustryCategoryExperiences =
    recruiterData.userIndustryCategoryExperiences;

  const [openForm, setOpenForm] = useState(false);

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const getYearOfExperiences = experienceLevel => {
    return ` > ${experienceLevel} ${t`recruiter:yrs`}`;
  };

  if (!isSelfEdit && !userIndustryCategoryExperiences.length) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Grid item xs={12} sm={12} md={12} className={classes.itemWrapper}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.containerWrapper}>
            <Grid container className={classes.headerWrapper}>
              <Grid container>
                <Grid item xs={10} sm={10} md={10}>
                  <Translate
                    text={t`recruiter:industries`}
                    bold
                    className={classes.fontStyleBig}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} className={classes.editWrapper}>
                  <div className={classes.imgWrapper}>
                    {isSelfEdit ? (
                      <img
                        alt="editIcon"
                        onClick={handleOpenForm}
                        src={EditIcon}
                        height={50}
                      />
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              {userIndustryCategoryExperiences.length ? (
                <Grid container>
                  {userIndustryCategoryExperiences.map(item => (
                    <IndustriesExperienceItem
                      key={item.id}
                      industryCategory={item.industryCategory}
                      yearOfExperiences={getYearOfExperiences(
                        item.experienceLevel
                      )}
                    />
                  ))}
                </Grid>
              ) : (
                <NonContent showContent={isSelfEdit} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {openForm ? (
        <IndustriesExperiencesForm
          recruiterData={recruiterData}
          isOpen={openForm}
          onClose={handleCloseForm}
          recruiterEdited={recruiterEdited}
          industryCategories={industryCategories}
        />
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(IndustriesExperiences))
);
