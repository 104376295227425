import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { CandidateV2Service, CompetenceService } from 'shared/services';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { checkCandidateEditPermissions } from 'shared/utils/functions';
import { CandidateCreateForm } from './components';
import {
  getLanguageSkill,
  getPrimaryPhoneCode,
  prepareLanguage,
} from './components/CandidateCreateWizard/CandidateCreateWizard.utils';

const CandidateEditFromView = ({ match, history, t, ...props }) => {
  const [candidate, setCandidate] = useState({});
  const [competences, setCompetences] = useState([]);
  const [languageCompetence, setLanguageCompetence] = useState([]);

  const [fetchDone, setFetchDone] = useState(false);

  const getCandidateById = id => {
    CandidateV2Service.getCandidateV2(id)
      .then(({ data }) => {
        if (id.toString() !== props.currentUser.id.toString()) {
          if (checkCandidateEditPermissions(data, history)) {
            props.showSnackbarMessage(t`candidate:noPermission`);
            history.push(PATHS.DASHBOARD.INDEX);
          }
        }
        setCandidate({
          ...data,
          ...getLanguageSkill(data.userSkills),
          ...getPrimaryPhoneCode(data),
        });
        getCompetences(id);
      })
      .catch(() => {
        //TODO: redirect to 404
        alert(t`addOrganizationForm:error`);
      });
  };

  const getCompetences = id => {
    const userId = id ? id : 0;
    CompetenceService.getCompetences({ project: 0, user: userId })
      .then(({ data }) => {
        setCompetences(data);
        setLanguageCompetence(prepareLanguage(data));
        setFetchDone(true);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    shouldUpdateCandidate();
  }, []);

  const shouldUpdateCandidate = () => {
    const { id } = match.params;
    if (!id) {
      setFetchDone(true);
    } else {
      getCandidateById(id);
    }
  };

  return fetchDone ? (
    <CandidateCreateForm
      candidate={candidate}
      competences={competences}
      languageCompetence={languageCompetence}
      shouldUpdateCandidate={shouldUpdateCandidate}
      currentUser={props.currentUser}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapDispatchToProps = {
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CandidateEditFromView)));
