import { TextField, withStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  flex: {
    flex: 1,
    alignItems: 'flex-end',
  },
  inputUnderline: {
    '&:after': {
      backgroundColor: '#fff',
    },
  },
  textFieldRoot: {
    maxWidth: 300,
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
  textFieldInput: {
    color: theme.palette.jobllegroDarkGrey,
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    padding: '10px 12px 10px 42px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
  textFieldFormLabel: {
    fontSize: 18,
    paddingLeft: 20,
  },
  searchIcon: {
    color: '#cccccc',
    position: 'absolute',
    top: 12,
    left: 0,
    borderRight: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    width: 17,
    height: 17,
    paddingRight: '10px',
  },
});

export const DefaultSearchField = props => {
  const { classes, placeholder } = props;

  const onChange = e => {
    props.onChangeHandler(e.target.value);
  };

  return (
    <TextField
      onChange={onChange}
      className={classes.flex}
      fullWidth
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <SearchIcon
            position="start"
            className={classes.searchIcon}
          ></SearchIcon>
        ),
        classes: {
          root: classes.textFieldRoot,
          input: classes.textFieldInput,
        },
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.textFieldFormLabel,
      }}
    />
  );
};

DefaultSearchField.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default withStyles(styles)(DefaultSearchField);
