import {
  Typography,
  withStyles,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import EditIcon from 'shared/assets/img/icons/edit.png';
import { enums } from 'shared/enums';
import Checkbox from 'shared/newComponents/Checkbox';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services';
import { dateAdd } from 'shared/utils/date';
import {
  getSelectedAccommodation,
  getTargetDate,
} from 'shared/utils/functions';
import Attachments from '../../components/Attachments';
import { styles } from './AwaitingEmployment.styles';
import Accommodation from './components/Accommodation';
import AwaitingEmploymentLocationForm from './components/AwaitingEmploymentLocationForm';
import HealthInsurance from './components/HealthInsurance';

const AwaitingEmployment = ({
  classes,
  t,
  shouldLoadAccommodations,
  accommodations,
  selectedFullObject,
  dataAdded,
  project,
  showSaveBtn,
}) => {
  const [isCrossingBorderDateEdited, setIsCrossingBorderDateEdited] =
    useState(false);
  const [isPeriodOfStayEdited, setIsPeriodOfStayEdited] = useState(false);
  const [locationForm, setLocationForm] = useState(false);
  const [errorDates, setErrorDates] = useState({
    corsBorderDate: '',
    periodStayStartDate: '',
    periodStayEndDate: '',
  });

  const [selectedAccommodation, setSelectedAccommodation] = useState(null);

  const getEmployeeInsuredFormikData = isEmployeeInsured => {
    if (isEmployeeInsured) {
      return { id: 1, name: t`employmentManagementDialog:dialog2.yes` };
    }
    return { id: 2, name: t`employmentManagementDialog:dialog2.no` };
  };
  const formData = {
    candidateAssets: selectedFullObject
      ? selectedFullObject.candidate.passportAsset
      : [],
    isEmployeeInsured: selectedFullObject
      ? getEmployeeInsuredFormikData(selectedFullObject.isEmployeeInsured)
      : null,
    corsBorderDate: selectedFullObject.corsBorderDate
      ? selectedFullObject.corsBorderDate
      : '',
    periodStayStartDate:
      selectedFullObject && selectedFullObject.periodStayStartDate
        ? selectedFullObject.periodStayStartDate
        : selectedFullObject.corsBorderDate
        ? dateAdd(selectedFullObject.corsBorderDate).format('YYYY-MM-DD')
        : '',
    periodStayEndDate:
      selectedFullObject && selectedFullObject.periodStayEndDate
        ? selectedFullObject.periodStayEndDate
        : selectedFullObject.corsBorderDate
        ? dateAdd(selectedFullObject.corsBorderDate, 10).format('YYYY-MM-DD')
        : '',
    accommodation: selectedFullObject.accommodation
      ? selectedFullObject.accommodation.id
      : null,
    isQuarantineRequired:
      selectedFullObject.accommodation !== null
        ? selectedFullObject.isQuarantineRequired
        : false,
  };

  const { targetStartDate, targetEndDate } = getTargetDate(
    selectedFullObject.candidate
  );

  const validationSchema = yup.object({
    isEmployeeInsured: yup
      .object()
      .nullable()
      .required(t('projectCreator:core.requiredField')),
    corsBorderDate: yup.date().required(t`ats2ats3:validation.isRequired`),
    periodStayStartDate: yup.date().required(t`ats2ats3:validation.isRequired`),
    periodStayEndDate: yup.date().required(t`ats2ats3:validation.isRequired`),
    accommodation: yup
      .number()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,

    onSubmit: ({
      isEmployeeInsured,
      corsBorderDate,
      periodStayStartDate,
      periodStayEndDate,
      isQuarantineRequired,
      accommodation,
    }) => {
      if (
        moment(periodStayStartDate).isAfter(moment(periodStayEndDate), 'day')
      ) {
        formik.setFieldError(
          'periodStayStartDate',
          t`ats2ats3:validation.date`
        );
        return;
      }

      const body = {
        isEmployeeInsured: isEmployeeInsured.id === 1,
        corsBorderDate,
        periodStayEndDate,
        periodStayStartDate,
        accommodation,
        isQuarantineRequired,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };
      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => console.log(error));
    },
  });

  useEffect(() => {
    setSelectedAccommodation(
      getSelectedAccommodation(formik.values.accommodation, accommodations)
    );
  }, [formik.values.accommodation]);

  useEffect(() => {
    if (!formik.values.periodStayStartDate) {
      formik.setFieldValue('periodStayStartDate', formik.values.corsBorderDate);
    }
    if (
      !formik.values.periodStayEndDate &&
      formik.values.corsBorderDate.length
    ) {
      formik.setFieldValue(
        'periodStayEndDate',
        dateAdd(formik.values.corsBorderDate, 10).format('YYYY-MM-DD')
      );
    }
  }, [formik.values.corsBorderDate]);

  useEffect(() => {
    if (
      moment(formik.values.corsBorderDate).isAfter(
        moment(targetStartDate),
        'day'
      )
    ) {
      setErrorDates({
        ...errorDates,
        corsBorderDate: `${t`Nie powinna być wcześniejsza niż`} ${targetStartDate}`,
      });
    }
    if (
      moment(formik.values.corsBorderDate).isBefore(
        moment(targetEndDate),
        'day'
      )
    ) {
      setErrorDates({
        ...errorDates,
        corsBorderDate: `${t`Data nie powinna być późniejsza niż`} ${targetEndDate}`,
      });
    }

    if (
      moment(formik.values.periodStayStartDate).isAfter(
        moment(formik.values.corsBorderDate),
        'day'
      )
    ) {
      setErrorDates({
        ...errorDates,
        corsBorderDate: `${t`Nie powinna być wcześniejsza niż`} ${
          formik.values.corsBorderDate
        }`,
      });
    }
    if (
      moment(formik.values.periodStayStartDate).isBefore(
        moment(targetEndDate),
        'day'
      )
    ) {
      setErrorDates({
        ...errorDates,
        corsBorderDate: `${t`Data nie powinna być późniejsza niż`} ${targetEndDate}`,
      });
    }

    if (
      moment(formik.values.periodStayStartDate).isAfter(
        moment(formik.values.corsBorderDate),
        'day'
      )
    ) {
      setErrorDates({
        ...errorDates,
        corsBorderDate: `${t`Nie powinna być wcześniejsza niż`} ${
          formik.values.corsBorderDate
        }`,
      });
    }
    if (
      moment(formik.values.periodStayStartDate).isBefore(
        moment(targetEndDate),
        'day'
      )
    ) {
      setErrorDates({
        ...errorDates,
        corsBorderDate: `${t`Data nie powinna być późniejsza niż`} ${targetEndDate}`,
      });
    }
  }, [
    formik.values.corsBorderDate,
    formik.values.periodStayStartDate,
    formik.values.periodStayEndDate,
  ]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.bgBlue}>
          <div className={classes.container}>
            <HealthInsurance formik={formik} />
            <div className={classes.crossingBorderDateDiv}>
              <div>
                <Typography variant="body1">{t`employmentManagementDialog:dialog2.crossingBorderDate`}</Typography>
              </div>
              <div className={classes.datePicker}>
                {!formik.values.corsBorderDate || isCrossingBorderDateEdited ? (
                  <TextField
                    formik={formik}
                    type="date"
                    variant="transparent"
                    name="corsBorderDate"
                  />
                ) : (
                  <div className={classes.crossingBorderDate}>
                    <div>
                      <Typography
                        variant="h5"
                        className={classes.crossingBorderDateFilled}
                      >
                        {formik.values.corsBorderDate}
                      </Typography>
                      {formik.errors.corsBorderDate && (
                        <FormHelperText className={classes.error}>
                          {formik.errors.corsBorderDate}
                        </FormHelperText>
                      )}
                      {!formik.errors.corsBorderDate &&
                        errorDates.corsBorderDate && (
                          <FormHelperText className={classes.error}>
                            {errorDates.corsBorderDate}
                          </FormHelperText>
                        )}
                    </div>
                    <img
                      src={EditIcon}
                      alt="edit"
                      className={
                        isCrossingBorderDateEdited
                          ? classes.hidden
                          : classes.editBtn
                      }
                      onClick={() => setIsCrossingBorderDateEdited(true)}
                    />
                  </div>
                )}
              </div>
            </div>
            <Attachments
              showSaveBtn={showSaveBtn}
              project={project}
              formik={formik}
              attachmentList={enums.ATTACHMENT_AWAITING_EMPLOYMENT}
              initialCandidateValues={selectedFullObject.candidate}
            />
          </div>
        </div>
        <Accommodation
          errorDate={errorDates}
          showSaveBtn={showSaveBtn}
          formik={formik}
          selectedAccommodation={selectedAccommodation}
          isPeriodOfStayEdited={isPeriodOfStayEdited}
          setIsPeriodOfStayEdited={setIsPeriodOfStayEdited}
          setLocationForm={setLocationForm}
          accommodations={accommodations}
        />
        <div className={classes.switch}>
          <Checkbox
            formik={formik}
            name="isQuarantineRequired"
            label={t`employmentManagementDialog:dialog2.isQuarantineRequired`}
            type="switch"
          />
        </div>
        {showSaveBtn && (
          <div className={classes.saveBtnDiv}>
            <Button type="submit" className={classes.saveBtn}>
              {t`employmentManagementDialog:saveBtn`}
            </Button>
          </div>
        )}
      </form>
      {locationForm && (
        <AwaitingEmploymentLocationForm
          accommodation={null}
          project={project}
          isOpen={locationForm}
          onClose={() => {
            shouldLoadAccommodations();
            setLocationForm(false);
          }}
        />
      )}
    </>
  );
};

export default withStyles(styles)(withTranslation()(AwaitingEmployment));
