import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { authUser } from 'shared/services/authService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import Login from './Login.style';

const mapStateToProps = store => {
  return {
    isFetching: store.auth.isFetching,
    isAuthorized: store.auth.isAuthorized,
  };
};

const mapDispatchToProps = {
  authUser,
  showSnackbarMessage,
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Login)));

LoginContainer.displayName = 'LoginContainer';

export default LoginContainer;
