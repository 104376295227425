import { Divider, Grid, LinearProgress, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ProjectDetailsBenefits from './ProjectDetailsBenefits';
import ProjectDetailsCompetences from './ProjectDetailsCompetences';
import ProjectDetailsCompetencesLanguages from './ProjectDetailsCompetencesLanguages';
import ProjectDetailsJobDescription from './ProjectDetailsJobDescription';
import ProjectDetailsJobInformation from './ProjectDetailsJobInformation';
import ProjectDetailsJobRequirements from './ProjectDetailsJobRequirements';
import ProjectDetailsLeftDescription from './ProjectDetailsLeftDescription';
import ProjectDetailsRecruiterPayments from './ProjectDetailsRecruiterPayments';
import ProjectDetailsRecruiterServices from './ProjectDetailsRecruiterServices';
import ProjectSlider from './ProjectSlider';

const styles = theme => ({
  rootSectionJob: {
    background: '#f2f2f2',
  },
  divider: {
    backgroundColor: theme.palette.jobllegroBorderGrey,
  },
});

export const ProjectDetails = props => {
  const { classes, projectDetails, isLoading, isRecruiter } = props;
  return isLoading || !projectDetails.permissions ? (
    <LinearProgress />
  ) : (
    <React.Fragment>
      <Grid container alignItems="flex-start" spacing={0}>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px' }}>
          <ProjectDetailsLeftDescription />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px' }}>
          <ProjectSlider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.rootSectionJob}
          style={{ margin: '0px' }}
        >
          <Grid container alignItems="flex-start" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ProjectDetailsJobInformation />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ProjectDetailsJobDescription />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '0px' }}>
          <Grid container alignItems="flex-start" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ProjectDetailsJobRequirements />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ProjectDetailsCompetences />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '0px' }}>
          <Grid container alignItems="flex-start" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ProjectDetailsBenefits />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ProjectDetailsCompetencesLanguages />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isRecruiter &&
        !projectDetails.permissions.isHiringManager &&
        !projectDetails.permissions.isHiringManagerToBe && (
          <React.Fragment>
            <Divider className={classes.divider} />
            <Grid container alignItems="flex-start" spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ProjectDetailsRecruiterServices />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ProjectDetailsRecruiterPayments />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = store => {
  return {
    isRecruiter: store.auth.currentUser.isRecruiter,
    projectDetails: store.projects.currentProject,
  };
};

ProjectDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  isRecruiter: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ProjectDetails));
