import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { Translate } from 'shared/newComponents';
import NonContent from '../../../NonContent/NonContent';
import { styles } from './PositionsLevel.styles';
import PositionsLevelForm from './PositionsLevelForm';
import PositionsLevelItem from './PositionsLevelItem';

const PositionsLevel = ({
  history,
  recruiterData,
  recruiterEdited,
  isSelfEdit,
  classes,
  t,
  ...props
}) => {
  const [openForm, setOpenForm] = useState(false);

  const userPositionExperiences = recruiterData.userPositionExperiences;

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const getYearOfExperiences = experienceLevel => {
    return ` > ${experienceLevel} lat`;
  };

  if (!isSelfEdit && !userPositionExperiences.length) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Grid item xs={12} sm={12} md={12} className={classes.itemWrapper}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.containerWrapper}>
            <Grid container className={classes.headerWrapper}>
              <Grid container>
                <Grid item xs={10} sm={10} md={10}>
                  <Translate
                    text={'recruiter:positionsLvl'}
                    bold
                    className={classes.fontStyleBig}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} className={classes.editWrapper}>
                  <div className={classes.imgWrapper}>
                    {isSelfEdit ? (
                      <img
                        alt="editIcon"
                        onClick={handleOpenForm}
                        src={EditIcon}
                        height={50}
                      />
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              {userPositionExperiences.length ? (
                <Grid container>
                  {userPositionExperiences.map(item => (
                    <PositionsLevelItem
                      key={item.id}
                      yearOfExperiencesText={getYearOfExperiences(
                        item.experienceLevel
                      )}
                      yearOfExperiences={item.experienceLevel}
                      position={item.position}
                    />
                  ))}
                </Grid>
              ) : (
                <NonContent showContent={isSelfEdit} />
              )}
            </Grid>
          </Grid>
        </Paper>
        {openForm ? (
          <PositionsLevelForm
            recruiterData={recruiterData}
            isOpen={openForm}
            onClose={handleCloseForm}
            recruiterEdited={recruiterEdited}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(PositionsLevel))
);
