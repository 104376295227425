import { Button, Grid, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ProjectOrderService } from 'shared/services';
import CancelOrderDialog from '../CancelOrderDialog';
import ModifyOrderDialog from '../ModifyOrderDialog';
import ProjectOrdersTable from '../ProjectOrdersTable';
import { styles } from './ProjectOrdersTableView.styles';

const ProjectOrdersTableView = ({
  project,
  projectOrders,
  loadProjectOrders,
  t,
  classes,
  projectAvailableToMove,
}) => {
  const [projectOrdersActive, setProjectOrdersActive] = useState([]);
  const [projectOrdersDisabled, setProjectOrdersDisabled] = useState([]);
  const [projectOrdersWaitForConfirm, setProjectOrdersWaitForConfirm] =
    useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheckAllWaiting, setIsCheckAllWaiting] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedWaitingOrders, setSelectedWaitingOrders] = useState([]);
  const [isOpenNewOrder, setIsOpenNewOrder] = useState(false);
  const [modifyDialog, setModifyDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);

  const [modifyWaitingDialog, setModifyWaitingDialog] = useState(false);
  const [cancelWaitingDialog, setCancelWaitingDialog] = useState(false);

  useEffect(() => {
    const projectOrdersActiveFilter = projectOrders.filter(
      projectOrder => projectOrder.isActive && projectOrder.isConfirmed
    );
    const projectOrdersWaitForConfirmFilter = projectOrders.filter(
      projectOrder => projectOrder.isActive && !projectOrder.isConfirmed
    );
    const projectOrdersDisabledFilter = projectOrders.filter(
      projectOrder => !projectOrder.isActive
    );

    setProjectOrdersWaitForConfirm(projectOrdersWaitForConfirmFilter);
    setProjectOrdersActive(projectOrdersActiveFilter);
    setProjectOrdersDisabled(projectOrdersDisabledFilter);
  }, [projectOrders]);

  const getSelectedProjectOrder = selectedOrders => {
    if (selectedOrders.length !== 1) {
      return null;
    }
    const selectedOrder = selectedOrders.shift();

    const projectOrder = projectOrders.find(
      projectOrder => projectOrder.id === selectedOrder
    );

    if (!projectOrder) {
      return null;
    }
    return projectOrder;
  };

  const getSelectedProjectOrders = selectedOrders => {
    return projectOrders.filter(projectOrder =>
      selectedOrders.includes(projectOrder.id)
    );
  };

  const projectOrderAdded = () => {
    setModifyDialog(false);
    setModifyWaitingDialog(false);
    loadProjectOrders(project);
  };

  const projectOrderRemoved = () => {
    setCancelDialog(false);
    setCancelWaitingDialog(false);
    loadProjectOrders(project);
  };

  const handleAcceptWaitingProjectOrder = () => {
    const promiseArray = [];
    const body = {
      confirmStatus: true,
    };
    selectedWaitingOrders.forEach(projectOrderId => {
      const acceptProjectOrder = ProjectOrderService.confirmProjectOrder(
        projectOrderId,
        body
      );
      promiseArray.push(acceptProjectOrder);
    });
    Promise.all(promiseArray)
      .then(() => {
        loadProjectOrders(project);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const { permissions, projectVersion, isDraft } = project;

  const showNewOrderBtn =
    (permissions.isOwner ||
      permissions.isMentorCoordinator ||
      permissions.isHiringManager ||
      permissions.isSuperUser) &&
    !isDraft &&
    projectVersion === 3;

  return (
    <>
      <Grid container>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.header}>
              <Typography component="p" className={classes.atsSubtext}>
                {t`projectOrdersTable:waitingForConfirmation`} (
                {projectOrdersWaitForConfirm.length})
              </Typography>
              {permissions.isOwner && (
                <div>
                  <Button
                    onClick={handleAcceptWaitingProjectOrder}
                    variant="outlined"
                    className={classes.newOrderBtn}
                  >{t`projectOrdersTable:confirmOrderBtn`}</Button>
                  <Button
                    onClick={() => setModifyWaitingDialog(true)}
                    variant="outlined"
                    className={`${
                      selectedWaitingOrders.length === 1 &&
                      classes.modifyBtnActive
                    } ${classes.modifyBtn}`}
                  >
                    {t`projectOrdersTable:modifyOrderBtn`}
                  </Button>
                  <Button
                    onClick={() => setCancelWaitingDialog(true)}
                    variant="outlined"
                    className={`${
                      selectedWaitingOrders.length && classes.cancelBtnActive
                    } ${classes.cancelBtn}`}
                  >
                    {selectedWaitingOrders.length > 1
                      ? t`projectOrdersTable:cancelOrdersBtn`
                      : t`projectOrdersTable:cancelOrderBtn`}
                  </Button>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.margin}>
            <ProjectOrdersTable
              selectedOrders={selectedWaitingOrders}
              setSelectedOrders={setSelectedWaitingOrders}
              variant="active"
              project={project}
              projectOrders={projectOrdersWaitForConfirm}
              isCheckAll={isCheckAllWaiting}
              setIsCheckAll={setIsCheckAllWaiting}
              confirmationTable={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.header}>
              <Typography component="p" className={classes.atsSubtext}>
                {t`projectOrdersTable:activeOrdersList`} (
                {projectOrdersActive.length})
              </Typography>
              <div>
                {showNewOrderBtn && (
                  <div>
                    <Button
                      onClick={() => setIsOpenNewOrder(true)}
                      variant="outlined"
                      className={classes.newOrderBtn}
                    >{t`projectOrdersTable:newOrderBtn`}</Button>
                    <Button
                      onClick={() => setModifyDialog(true)}
                      variant="outlined"
                      className={`${
                        selectedOrders.length === 1 && classes.modifyBtnActive
                      } ${classes.modifyBtn}`}
                    >
                      {t`projectOrdersTable:modifyOrderBtn`}
                    </Button>
                    <Button
                      onClick={() => setCancelDialog(true)}
                      variant="outlined"
                      className={`${
                        selectedOrders.length && classes.cancelBtnActive
                      } ${classes.cancelBtn}`}
                    >
                      {selectedOrders.length > 1
                        ? t`projectOrdersTable:cancelOrdersBtn`
                        : t`projectOrdersTable:cancelOrderBtn`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.margin}>
            <ProjectOrdersTable
              selectedOrders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
              variant="active"
              project={project}
              projectOrders={projectOrdersActive}
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="p" className={classes.atsSubtext}>
              {t`projectOrdersTable:closedOrdersList`} (
              {projectOrdersDisabled.length})
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ProjectOrdersTable
              variant="disabled"
              project={project}
              projectOrders={projectOrdersDisabled}
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
            />
          </Grid>
        </Grid>
      </Grid>

      {modifyWaitingDialog && selectedWaitingOrders.length === 1 && (
        <ModifyOrderDialog
          project={project}
          isOpen={modifyWaitingDialog}
          onClose={() => setModifyWaitingDialog(false)}
          projectOrderAdded={projectOrderAdded}
          projectOrder={getSelectedProjectOrder(selectedWaitingOrders)}
        />
      )}
      {cancelWaitingDialog && (
        <CancelOrderDialog
          projectAvailableToMove={projectAvailableToMove}
          isOpen={cancelWaitingDialog}
          onClose={() => setCancelWaitingDialog(false)}
          selectedOrders={getSelectedProjectOrders(selectedWaitingOrders)}
          projectOrderRemoved={projectOrderRemoved}
        />
      )}

      {isOpenNewOrder && (
        <ModifyOrderDialog
          project={project}
          isOpen={isOpenNewOrder}
          onClose={() => setIsOpenNewOrder(false)}
          projectOrderAdded={projectOrderAdded}
          projectOrder={project.projectOrders[0]}
          isEdit={false}
        />
      )}
      {modifyDialog && selectedOrders.length === 1 && (
        <ModifyOrderDialog
          project={project}
          isOpen={modifyDialog}
          onClose={() => setModifyDialog(false)}
          projectOrderAdded={projectOrderAdded}
          projectOrder={getSelectedProjectOrder(selectedOrders)}
        />
      )}
      {cancelDialog && (
        <CancelOrderDialog
          projectAvailableToMove={projectAvailableToMove}
          isOpen={cancelDialog}
          onClose={() => setCancelDialog(false)}
          selectedOrders={getSelectedProjectOrders(selectedOrders)}
          projectOrderRemoved={projectOrderRemoved}
        />
      )}
    </>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(ProjectOrdersTableView))
);
