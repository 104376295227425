import classNames from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'config';
import { getAllowRoutes } from 'shared/routes';
import Footer from '../../components/LayoutFooter';
import LeftDrawer from '../../components/LayoutLeftDrawer/';
import TopAppBar from '../../components/TopAppBar';
import DashboardCandidateIndexView from '../dashboard/DashboardCandidateIndexView';
import DashboardIndexView from '../dashboard/DashboardIndexView';
import DashboardRightDrawer from '../dashboard/components/DashboardRightDrawer';
import DashboardRightDrawerCandidate from '../dashboard/components/DashboardRightDrawerCandidate';

export const Main = ({ classes, currentUser }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const allowedRoutes = getAllowRoutes(currentUser);

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <TopAppBar
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          isOpened={open}
        />
        <LeftDrawer
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          isOpened={open}
        />
        <main className={classNames(classes.content)}>
          <Switch>
            {currentUser.isRecruiter ? (
              <Route
                exact
                path={PATHS.DASHBOARD.INDEX}
                component={DashboardIndexView}
              />
            ) : (
              <Route
                exact
                path={PATHS.DASHBOARD.INDEX}
                component={DashboardCandidateIndexView}
              />
            )}
            {allowedRoutes.map(({ path, component, exact }, index) => {
              return (
                <Route
                  key={index}
                  exact={exact}
                  path={path}
                  component={component}
                />
              );
            })}
            <Redirect to={PATHS.DASHBOARD.INDEX} />
          </Switch>
        </main>
        <Switch>
          {currentUser.isRecruiter ? (
            <Route
              exact
              path={PATHS.DASHBOARD.INDEX}
              component={DashboardRightDrawer}
            />
          ) : (
            <Route
              exact
              path={PATHS.DASHBOARD.INDEX}
              component={DashboardRightDrawerCandidate}
            />
          )}
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(mapStateToProps)(Main);
