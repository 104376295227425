import { Typography, Grid, withStyles } from '@material-ui/core';
import { find, get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EmployersIcon from 'shared/assets/img/icons/project-show/employers.svg';
import PinIcon from 'shared/assets/img/icons/project-show/pin.svg';
import { enums } from 'shared/enums';
import ProgressBar from 'shared/newComponents/ProgressBar';
import Translate from 'shared/newComponents/Translate';
import { getAssetUrl } from 'shared/services';
import { getEnumItemName, getProjectAssetSrcByType } from 'shared/utils/assets';

const styles = theme => ({
  leftSectionProject: {
    boxSizing: 'border-box',
    display: 'flex',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    height: 'auto',
    flexDirection: 'column',
    placeContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  rightSectionProject: {
    boxSizing: 'border-box',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  companyLogotype: {
    width: '100%',
    height: 'auto',
  },
  editProjectButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    width: 30,
    height: 30,
    background: '#fff',
    padding: 0,
    [theme.breakpoints.only('xs')]: {
      left: 10,
      position: 'fixed',
      zIndex: '9999',
      width: 50,
      height: 50,
      background: theme.palette.jobllegroOrange,
      color: '#ffffff',
      top: '80vh',
    },
  },
  sectionBottom: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  projectPosition: {
    color: theme.palette.jobllegroBlue,
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  projectClient: {
    color: theme.palette.jobllegroGrey,
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  projectWww: {
    color: theme.palette.jobllegroBlue,
    '& > a': {
      color: theme.palette.jobllegroBlue,
      fontSize: 13,
      textDecoration: 'none',
    },
  },
  chip: {
    border: '0px',
    color: theme.palette.jobllegroLightGrey,
  },
  projectInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2.5),
  },
  chipIcon: {
    width: 14,
    color: theme.palette.jobllegroLightGrey,
  },
  avatarChip: {
    background: 'none',
    width: 'auto',
    height: 'auto',
  },
  containerLeft: {
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      position: 'static',
    },
  },
  imagesWrapper: {
    position: 'relative',
  },
  header: {
    maxHeight: 270,
    width: '100%',
    borderRadius: 10,
  },
  logoWrapper: {
    position: 'absolute',
    height: '100%',
    paddingLeft: 50,
  },
  logo: {
    borderRadius: '50%',
  },
});

export const ProjectDetailsViewLeftDescription = ({ classes, project, t }) => {
  const logoId = get(
    find(get(project, 'assets'), o => o.type === 'logo'),
    'id'
  );
  const projectLogo = getAssetUrl(logoId);
  const projectBanner = getProjectAssetSrcByType(project, 'header');

  const projectStartDate = get(project, 'projectStartDate');
  const projectEndDate = get(project, 'projectEndDate');
  const isRemoteWork = get(project, 'isRemoteWork');
  const city = get(project, 'city');
  const industryCategory = get(project, 'industryCategory');
  const industryCategoryName = get(industryCategory, 'name');
  const industryCategoryAssets = get(industryCategory, 'assets');
  const industryCategoryLogo = industryCategoryAssets
    ? industryCategoryAssets.find(item => item.type === 'industryCategoryLogo')
    : null;
  const industryCategoryIcon = getAssetUrl(
    industryCategoryLogo ? industryCategoryLogo.id : null
  );

  const companySizeIndex = get(project, 'companySize');
  const companySize = getEnumItemName(enums.COMPANY_SIZE, companySizeIndex);

  const getTimeLeft = (projectStartDate, projectEndDate) => {
    if (projectStartDate && projectEndDate) {
      const now = moment(new Date());
      const endDateProps = moment(projectEndDate).endOf('day');
      if (endDateProps.isBefore(now, 'day')) {
        return `${now.endOf('day').to(endDateProps)}`;
      }
      return endDateProps.endOf('day').toNow(true);
    }
    return null;
  };

  const getPercentageLeft = (projectStartDate, projectEndDate) => {
    if (projectStartDate && projectEndDate) {
      const now = moment(new Date());

      const startDateProps = moment(projectStartDate).startOf('day');
      const endDateProps = moment(projectEndDate).endOf('day');

      const secondsFromStart = now.diff(startDateProps);
      const secondsToEnd = -now.diff(endDateProps);
      const result =
        (secondsFromStart / (secondsFromStart + secondsToEnd)) * 100;
      return result > 100 ? 100 : result;
    }
    return 0;
  };
  const projectTimeLeft = getTimeLeft(projectStartDate, projectEndDate);
  const projectPercentageLeft = getPercentageLeft(
    projectStartDate,
    projectEndDate
  );

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{ padding: '20px 20px' }}
      >
        <Grid container className={classes.imagesWrapper}>
          <img
            src={projectBanner}
            alt={'imageHeader'}
            className={classes.header}
          />
          <Grid container alignItems="center" className={classes.logoWrapper}>
            {projectLogo ? (
              <img
                src={projectLogo}
                alt={'imageLogo'}
                className={classes.logo}
                style={{ maxWidth: '15%' }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className={classes.rightSectionProject}>
          <Typography
            variant="h5"
            component="h1"
            className={classes.projectPosition}
          >
            {project.position}
          </Typography>
          <Typography
            variant="h5"
            component="h4"
            className={classes.projectClient}
          >
            {project.client}
          </Typography>
          <Typography component="div" className={classes.projectWww}>
            <a href={project.clientUrl}>{project.clientUrl}</a>
          </Typography>
          <Typography component="div" className={classes.projectInfo}>
            <Grid container alignItems="center" justifyContent={'space-around'}>
              {city && (
                <Grid item style={{ padding: '4px 0' }}>
                  <Grid container>
                    <img
                      src={PinIcon}
                      alt={'city'}
                      style={{ paddingRight: 8 }}
                    />
                    <Translate
                      text={city}
                      variant="body1"
                      disableTranslate={true}
                    />
                  </Grid>
                  {isRemoteWork && (
                    <>
                      <Translate text="&nbsp;" variant="body1" />
                      <Translate
                        text={t`projectDetails:mainContent.isRemoteWork`}
                        variant="body1"
                      />
                    </>
                  )}
                </Grid>
              )}

              {industryCategoryName && (
                <Grid item style={{ padding: '4px 0' }}>
                  <Grid container>
                    <img
                      src={industryCategoryIcon}
                      alt={'imageIndustry'}
                      style={{ width: 30, height: 30, paddingRight: 8 }}
                    />
                    <Translate text={industryCategoryName} variant="body1" />
                  </Grid>
                </Grid>
              )}
              {companySize && (
                <Grid item style={{ padding: '4px 0' }}>
                  <Grid container>
                    <img
                      src={EmployersIcon}
                      alt={'companySize'}
                      style={{ width: 30, height: 30, paddingRight: 8 }}
                    />
                    <Translate text={companySize} variant="body1" />
                    <Translate text="&nbsp;os." variant="body1" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Typography>
        </div>
      </Grid>

      {projectStartDate && projectEndDate ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.rightSectionProject}>
              <ProgressBar
                percentage={projectPercentageLeft}
                title={''}
                secondTitle={''}
                customStyleSecondHeader={{
                  color: '#6CB8FF',
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Translate
                  text={projectStartDate}
                  style={{ textAlign: 'center', color: '#01D38B' }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Translate
                  text={projectTimeLeft}
                  style={{ textAlign: 'center', color: '#2D333B' }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Translate
                  text={projectEndDate}
                  style={{ textAlign: 'center', color: '#C65447' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

ProjectDetailsViewLeftDescription.propTypes = {
  project: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectDetailsViewLeftDescription)));
