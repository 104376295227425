import { Typography, withStyles, Grid } from '@material-ui/core';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { WizardStep, Button } from '../../../../newComponents';
import ListWithTicks from '../ListWithTicks';
import { styles } from './Job.styles';

const recruiterInfoList = [
  {
    title: 'help:help.recruiterHeader1',
    description: 'help:help.recruiterDescription1',
  },
  {
    title: 'help:help.recruiterHeader2',
    description: 'help:help.recruiterDescription2',
  },
  {
    title: 'help:help.recruiterHeader3',
    boldTitle: true,
  },
];

const candidateInfoList = [
  {
    title: 'help:help.candidateHeader1',
    description: 'help:help.candidateDescription1',
  },
  {
    title: 'help:help.candidateHeader2',
    description: 'help:help.candidateDescription2',
  },
  {
    title: 'help:help.candidateHeader3',
    description: 'help:help.candidateDescription2',
  },
];

const OfferJob = ({ classes, t, link, needHelp }) => {
  const [isOfferJobOpen, setIsOfferJobOpen] = useState(true);

  return (
    <>
      <WizardStep
        title={t('help:help.job')}
        isOpen={isOfferJobOpen}
        onCloseSelf={() => {
          setIsOfferJobOpen(false);
        }}
        onOpen={() => {
          setIsOfferJobOpen(true);
        }}
        withSaveButton={false}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t(
                needHelp
                  ? 'help:help.jobllegroForCandidate'
                  : 'help:help.jobllegroForRecruiter'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.divider} />
          </Grid>
          <ListWithTicks
            items={needHelp ? candidateInfoList : recruiterInfoList}
          />
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={4}>
              <Link to={link ? link : ''} className={classes.link}>
                <Button
                  full
                  label={t(
                    needHelp
                      ? 'help:help.candidateBtn'
                      : 'help:help.recruiterBtn'
                  )}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </WizardStep>
    </>
  );
};

export default withStyles(styles)(withTranslation()(OfferJob));
