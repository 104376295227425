//auth
export const AUTH = 'AUTH';
export const IS_FETCHING = 'IS_FETCHING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CONFIG = 'SET_CURRENT_CONFIG';
export const SET_CONFIG_LIST = 'SET_CONFIG_LIST';
export const SET_LAST_PAGE = 'SET_LAST_PAGE';
export const LOGOUT = 'LOGOUT';

//candidates
export const SET_CURRENT_CANDIDATE = 'SET_CURRENT_CANDIDATE';
export const CLEAR_CURRENT_CANDIDATE = 'CLEAR_CURRENT_CANDIDATE';
export const SET_CURRENT_CANDIDATES_LIST = 'SET_CURRENT_CANDIDATES_LIST';
export const SET_CURRENT_CANDIDATES_LIST_COUNT =
  'SET_CURRENT_CANDIDATES_LIST_COUNT';
export const SET_CANDIDATES_BELONGS_TO_RECRUITER_LIST =
  'SET_CANDIDATES_BELONGS_TO_RECRUITER_LIST';
export const SET_CANDIDATES_PENDING_TO_RECRUITER_LIST =
  'SET_CANDIDATES_PENDING_TO_RECRUITER_LIST';
export const CLEAR_CURRENT_CANDIDATES_LIST = 'CLEAR_CURRENT_CANDIDATES_LIST';
export const SET_CANDIDATES_IN_PROJECT = 'SET_CANDIDATES_IN_PROJECT';
export const CLEAR_CANDIDATES_IN_PROJECT = 'CLEAR_CANDIDATES_IN_PROJECT';
export const SET_MY_CANDIDATES = 'SET_MY_CANDIDATES';
export const CLEAR_MY_CANDIDATES = 'CLEAR_MY_CANDIDATES';
export const SET_CURRENT_CANDIDATES_LIMITED_LIST =
  'SET_CURRENT_CANDIDATES_LIMITED_LIST';
export const CLEAR_CURRENT_CANDIDATES_LIMITED_LIST =
  'CLEAR_CURRENT_CANDIDATES_LIMITED_LIST';
export const SET_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER =
  'SET_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER';
export const CLEAR_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER =
  'CLEAR_CURRENT_CANDIDATES_LIMITED_LIST_COUNTER';

//users
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';
export const SET_SELECTED_USERS_LIST = 'SET_SELECTED_USERS_LIST';
export const SET_SELECTED_USERS_LIST_COUNT = 'SET_SELECTED_USERS_LIST_COUNT';
export const CLEAR_SELECTED_USERS_LIST = 'CLEAR_SELECTED_USERS_LIST';
export const SET_CURRENT_USERS_LIMITED_LIST = 'SET_CURRENT_USERS_LIMITED_LIST';
export const CLEAR_CURRENT_USERS_LIMITED_LIST =
  'CLEAR_CURRENT_USERS_LIMITED_LIST';
export const SET_CURRENT_USERS_LIMITED_LIST_COUNTER =
  'SET_CURRENT_USERS_LIMITED_LIST_COUNTER';
export const CLEAR_CURRENT_USERS_LIMITED_LIST_COUNTER =
  'CLEAR_CURRENT_USERS_LIMITED_LIST_COUNTER';

//messages
export const SHOW_SNACKBAR_MESSAGE = 'SHOW_SNACKBAR_MESSAGE';
export const HIDE_SNACKBAR_MESSAGE = 'HIDE_SNACKBAR_MESSAGE';
export const SHOW_SNACKBAR_INFORMATION_MESSAGE =
  'SHOW_SNACKBAR_INFORMATION_MESSAGE';
export const HIDE_SNACKBAR_INFORMATION_MESSAGE =
  'HIDE_SNACKBAR_INFORMATION_MESSAGE';

//projects
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT';
export const SET_CURRENT_PROJECTS_LIST = 'SET_CURRENT_PROJECTS_LIST';
export const SET_CURRENT_PROJECTS_LIMITED_LIST =
  'SET_CURRENT_PROJECTS_LIMITED_LIST';
export const CLEAR_CURRENT_PROJECTS_LIMITED_LIST =
  'CLEAR_CURRENT_PROJECTS_LIMITED_LIST';
export const SET_CURRENT_PROJECTS_LIMITED_LIST_COUNTER =
  'SET_CURRENT_PROJECTS_LIMITED_LIST_COUNTER';
export const CLEAR_CURRENT_PROJECTS_LIMITED_LIST_COUNTER =
  'CLEAR_CURRENT_PROJECTS_LIMITED_LIST_COUNTER';
export const CLEAR_CURRENT_PROJECTS_LIST = 'CLEAR_CURRENT_PROJECTS_LIST';
export const SET_PROJECT_RECRUITER_CONFIRMATION_STATUS =
  'SET_PROJECT_RECRUITER_CONFIRMATION_STATUS';
export const SET_PROJECTS_PENDING_TO_RECRUITER_LIST =
  'SET_PROJECTS_PENDING_TO_RECRUITER_LIST';
export const SET_PROJECTS_PENDING_TO_CANDIDATE_LIST =
  'SET_PROJECTS_PENDING_TO_CANDIDATE_LIST';
export const CLEAR_PROJECTS_PENDING_TO_RECRUITER_LIST =
  'CLEAR_PROJECTS_PENDING_TO_RECRUITER_LIST';
export const SET_PROJECT_CANDIDATE_CONFIRMATION_STATUS =
  'SET_PROJECT_CANDIDATE_CONFIRMATION_STATUS';
export const CLEAR_PROJECT_RECRUITER_CONFIRMATION_STATUS =
  'CLEAR_PROJECT_RECRUITER_CONFIRMATION_STATUS';
export const CLEAR_PROJECT_CANDIDATE_CONFIRMATION_STATUS =
  'CLEAR_PROJECT_CANDIDATE_CONFIRMATION_STATUS';

//recruiters
export const SET_CURRENT_RECRUITER = 'SET_CURRENT_RECRUITER';
export const CLEAR_CURRENT_RECRUITER = 'CLEAR_CURRENT_RECRUITER';
export const SET_CURRENT_RECRUITERS_LIST = 'SET_CURRENT_RECRUITERS_LIST';
export const CLEAR_CURRENT_RECRUITERS_LIST = 'CLEAR_CURRENT_RECRUITERS_LIST';
export const SET_RECRUITERS_IN_PROJECT = 'SET_RECRUITERS_IN_PROJECT';
export const CLEAR_RECRUITERS_IN_PROJECT = 'CLEAR_RECRUITERS_IN_PROJECT';
export const SET_RECRUITERS_IN_ORGANIZATION = 'SET_RECRUITERS_IN_ORGANIZATION';
export const CLEAR_RECRUITERS_IN_ORGANIZATION =
  'CLEAR_RECRUITERS_IN_ORGANIZATION';
export const SET_MY_RECRUITERS = 'SET_MY_RECRUITERS';
export const CLEAR_MY_RECRUITERS = 'CLEAR_MY_RECRUITERS';
export const SET_RECRUITERS_PENDING_TO_ORGANIZATION_LIST =
  'SET_RECRUITERS_PENDING_TO_ORGANIZATION_LIST';
export const SET_CURRENT_RECRUITERS_LIMITED_LIST =
  'SET_CURRENT_RECRUITERS_LIMITED_LIST';
export const SET_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER =
  'SET_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER';
export const CLEAR_CURRENT_RECRUITERS_LIMITED_LIST =
  'CLEAR_CURRENT_RECRUITERS_LIMITED_LIST';
export const CLEAR_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER =
  'CLEAR_CURRENT_RECRUITERS_LIMITED_LIST_COUNTER';
//organizations
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const CLEAR_CURRENT_ORGANIZATION = 'CLEAR_CURRENT_ORGANIZATION';
export const SET_CURRENT_ORGANIZATIONS_LIST = 'SET_CURRENT_ORGANIZATIONS_LIST';
export const CLEAR_CURRENT_ORGANIZATIONS_LIST =
  'CLEAR_CURRENT_ORGANIZATIONS_LIST';

//stages
export const SET_STAGES_LIST = 'SET_STAGES_LIST';

//summary
export const SET_SUMMARY_LIST = 'SET_SUMMARY_LIST';
export const CLEAR_SUMMARY_LIST = 'CLEAR_SUMMARY_LIST';
export const SET_SUMMARY_LIST_CLOSED = 'SET_SUMMARY_LIST_CLOSED';

//notifications
export const SET_NOTIFICATIONS_LIST = 'SET_NOTIFICATIONS_LIST';

//city
export const GET_DICTIONARIES_CITIES = 'GET_DICTIONARIES_CITIES';

//competence Type
export const GET_DICTIONARIES_COMPETENCE_TYPES =
  'GET_DICTIONARIES_COMPETENCE_TYPES';

//competence Category
export const GET_DICTIONARIES_COMPETENCE_CATEGORIES =
  'GET_DICTIONARIES_COMPETENCE_CATEGORIES';

//competence Temp
export const GET_DICTIONARIES_COMPETENCE_TEMP =
  'GET_DICTIONARIES_COMPETENCE_TEMP';

//competence Kind
export const GET_DICTIONARIES_COMPETENCE_KINDS =
  'GET_DICTIONARIES_COMPETENCE_KINDS';

//industry
export const GET_DICTIONARIES_INDUSTRIES = 'GET_DICTIONARIES_INDUSTRIES';

//industry
export const GET_DICTIONARIES_INDUSTRY_CATEGORIES =
  'GET_DICTIONARIES_INDUSTRY_CATEGORIES';

//nationality
export const GET_DICTIONARIES_NATIONALITIES = 'GET_DICTIONARIES_NATIONALITIES';

//language
export const GET_DICTIONARIES_LANGUAGES = 'GET_DICTIONARIES_LANGUAGES';

//skill
export const GET_DICTIONARIES_SKILLS = 'GET_DICTIONARIES_SKILLS';

//right drawer
export const SHOW_RIGHT_DRAWER = 'SHOW_RIGHT_DRAWER';
export const HIDE_RIGHT_DRAWER = 'HIDE_RIGHT_DRAWER';
