import { withStyles } from '@material-ui/core';
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';
import { times } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import 'shared/assets/css/ProjectStepper.css';

const styles = () => ({
  activeCircle: {
    color: '#24d1d7',
    width: 16,
  },
  circle: {
    color: '#cccccc',
    width: 16,
  },
  root: {
    maxWidth: 'auto',
    textAlign: 'center',
    margin: '0 auto',
  },
  rootLinear: {
    maxWidth: 'auto',
    textAlign: 'center',
    margin: '10px 0 0 0',
  },
});

export const LevelDotsRating = props => {
  const { classes, dotsActive, variant = '', points } = props;
  const countPercentage = ((dotsActive / points) * 100).toFixed(2);

  return (
    <div className={variant === 'linear' ? classes.rootLinear : classes.root}>
      {variant === 'linear' && (
        <div className="meter" align="left">
          <div
            className="middleLine"
            style={{ width: countPercentage + '%' }}
          />
        </div>
      )}
      {variant === '' &&
        times(points, index => {
          return (
            <React.Fragment key={index}>
              {dotsActive >= index + 1 ? (
                <FiberManualRecordIcon className={classes.activeCircle} />
              ) : (
                <FiberManualRecordIcon className={classes.circle} />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

LevelDotsRating.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LevelDotsRating);
