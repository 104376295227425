import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '100%',
  },
});

const LayoutExpansionComponent = ({
  classes,
  title,
  textOnTop,
  bgPink,
  children,
  isExpanded,
}) => {
  const [expandedStatus, setExpandedStatus] = useState(false);

  return (
    <div className={classes.root}>
      <Accordion
        expanded={isExpanded}
        elevation={0}
        onChange={() => setExpandedStatus(!expandedStatus)}
        style={bgPink ? { background: '#FFF3E9' } : {}}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.column}>
            <Typography
              style={bgPink ? { fontWeight: 600, color: '#2D333B' } : {}}
              className={classes.heading}
            >
              {title}
            </Typography>
          </div>
          <div className={classes.column}>
            {!expandedStatus && (
              <Typography className={classes.secondaryHeading}>
                {textOnTop}
              </Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.column}>{children}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

LayoutExpansionComponent.propTypes = {
  getContent: PropTypes.func,
};

export default withStyles(styles)(LayoutExpansionComponent);
