import {
  Dialog,
  DialogTitle,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import Select from 'shared/newComponents/Select';
import { ProjectV2Service } from 'shared/services';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import {
  getAccommodations,
  getOptionsDisabled,
  isPermissionToSaveEmploymentManagements,
} from 'shared/utils/functions';
import { styles } from './EmploymentManagementDialog.styles';
import AwaitingEmployment from './components/AwaitingEmployment';
import BreakAtWork from './components/BreakAtWork';
import ConfirmationOfEmployment from './components/ConfirmationOfEmployment';
import Deregistering from './components/Deregistering';
import Employed from './components/Employed';
import EndOfEmployment from './components/EndOfEmployment';
import Illness from './components/Illness';
import Quarantine from './components/Quarantine';
import Resignation from './components/Resignation';
import WaitingForArrival from './components/WaitingForArrival';

const EmploymentManagementDialog = ({
  t,
  classes,
  isOpen,
  onClose,
  selectedFullObjects,
  loadProjectCandidatesEmploymentManagement,
  accommodations,
  shouldLoadAccommodations,
  clearSelected,
  projectAvailableToMove,
  project,
}) => {
  const selectedFullObject = selectedFullObjects[0];
  const isResignationConfirm = selectedFullObject.isResignationConfirm;
  const [projectOrderLocalizations, setProjectOrderLocalizations] = useState(
    []
  );
  const formik = useFormik({
    initialValues: selectedFullObject,
  });

  useEffect(() => {
    getProjectOrderLocations();
  }, [selectedFullObjects]);

  const getProjectOrderLocations = () => {
    ProjectV2Service.getProjectLocalizations(project.id)
      .then(({ data }) => {
        setProjectOrderLocalizations(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const localizationAdded = () => {
    getProjectOrderLocations();
  };

  const dataAdded = () => {
    loadProjectCandidatesEmploymentManagement();
    onClose();
    clearSelected();
  };

  const getQuarantineAccommodations = () => {
    return accommodations.filter(
      accommodation =>
        accommodation.type ===
        enums.ACCOMMODATION_TYPES.QUARANTINE_ACCOMMODATION
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component="div"
          variant="h6"
        >{t`employmentManagementDialog:header`}</Typography>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.select}>
          <Select
            disabled={isResignationConfirm}
            optionsDisabled={getOptionsDisabled(formik.values.employmentStatus)}
            fontBold={true}
            name="employmentStatus"
            label={t`employmentManagementDialog:chooseStatus`}
            formik={formik}
            items={getSelectItemsTranslate(enums.EMPLOYMENT_STATUS, t)}
          />
        </div>
      </form>
      {formik.values.employmentStatus === '' && (
        <>
          <div className={classes.bgBlue}>
            <div className={classes.container}>
              <Typography
                variant="body1"
                className={classes.info}
              >{t`employmentManagementDialog:info`}</Typography>
            </div>
          </div>
          <div className={classes.saveBtnDiv} />
        </>
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_WAIT_FOR_ARRIVAL && (
        <WaitingForArrival
          selectedFullObject={selectedFullObject}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_WAIT_FOR_EMPLOY && (
        <AwaitingEmployment
          project={project}
          shouldLoadAccommodations={shouldLoadAccommodations}
          accommodations={getAccommodations(accommodations)}
          selectedFullObject={selectedFullObject}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_QUARANTINE && (
        <Quarantine
          project={project}
          selectedFullObject={selectedFullObject}
          shouldLoadAccommodations={shouldLoadAccommodations}
          accommodations={getQuarantineAccommodations(accommodations)}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_CONFIRM_EMPLOY && (
        <ConfirmationOfEmployment
          project={project}
          projectOrderLocalizations={projectOrderLocalizations}
          selectedFullObject={selectedFullObject}
          shouldLoadAccommodations={shouldLoadAccommodations}
          accommodations={getAccommodations(accommodations)}
          dataAdded={dataAdded}
          localizationAdded={localizationAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_EMPLOY && (
        <Employed
          project={project}
          selectedFullObject={selectedFullObject}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_BREAK && (
        <BreakAtWork
          selectedFullObject={selectedFullObject}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_RESIGNATION && (
        <Resignation
          selectedFullObject={selectedFullObject}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_ILLNESS && (
        <Illness
          selectedFullObject={selectedFullObject}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_EMPLOY_END && (
        <EndOfEmployment
          projectAvailableToMove={projectAvailableToMove}
          selectedFullObject={selectedFullObject}
          project={project}
          dataAdded={dataAdded}
          showSaveBtn={isPermissionToSaveEmploymentManagements(
            formik.values.employmentStatus,
            project
          )}
        />
      )}
      {formik.values.employmentStatus ===
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_DEREGISTERING && (
        <Deregistering
          projectAvailableToMove={projectAvailableToMove}
          selectedFullObject={selectedFullObject}
        />
      )}
    </Dialog>
  );
};

export default withStyles(styles)(
  withTranslation()(EmploymentManagementDialog)
);
