import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form, reduxForm, reset, change } from 'redux-form';
import ProjectFormFooter from './ProjectFormFooter';
import ProjectFormStepFour from './ProjectFormStepFour';
import ProjectFormStepOne from './ProjectFormStepOne';
import ProjectFormStepThree from './ProjectFormStepThree';
import ProjectFormStepTwo from './ProjectFormStepTwo';
import validate from './validateProject';
import 'shared/assets/css/layoutItems.css';

const styles = theme => ({
  root: {
    width: '100%',
  },
  formItem: {
    margin: '0px auto',
    maxWidth: '800px',
  },
  stepRoot: {
    background: 'transparent',
    fontSize: 30,
  },
  labelText: {
    color: theme.palette.jobllegroBlue,
    fontSize: 18,
  },
  stepRootActive: {
    color: '#000 !important',
    fontWeight: '700 !important',
    fontSize: 18,
  },
});

export class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      isEdited: false,
      activeStep: 0,
      completed: {},
      validationSectionShow: null,
      options: { limit: 12, offset: 0, filters: null, orderBy: null },
    };
  }

  steps = [
    'aboutCompany',
    'aboutOffer',
    'offerRequirements',
    'informationsForCO',
  ];

  isStepOptional = step => {
    return step === 3;
  };

  changeSectionValidationVisibility = async (index, value) => {
    await this.setState({
      validationSectionShow: value,
    });
    if (value !== 3) {
      this.props.change('mentorFee', null);
    }
  };

  getStepContent = step => {
    const { isPublic, isEdited, validationSectionShow } = this.state;
    let { valid, formValues, isLastStepIsBlockedByRecruiters, t } = this.props;
    switch (step) {
      case 0:
        return (
          <ProjectFormStepOne
            handleChangeIsPublic={this.handleChangeIsPublic}
            isPublic={isPublic}
            isEdited={isEdited}
            valid={valid}
          />
        );
      case 1:
        return <ProjectFormStepTwo isEdited={isEdited} valid={valid} />;
      case 2:
        return <ProjectFormStepThree isEdited={isEdited} valid={valid} />;
      case 3:
        return (
          <ProjectFormStepFour
            isEdited={isEdited}
            formValues={formValues}
            changeSectionValidationVisibility={
              this.changeSectionValidationVisibility
            }
            validationSectionShow={validationSectionShow}
            isLastStepIsBlockedByRecruiters={isLastStepIsBlockedByRecruiters}
          />
        );
      default:
        return t`projectForm:lack`;
    }
  };

  totalSteps = () => {
    const {
      initialValues: { permissions = {} },
    } = this.props;
    return permissions.isMentor ? this.steps.length - 1 : this.steps.length;
  };
  getLastStep = () => this.totalSteps() - 1;

  handleNext = () => {
    const { activeStep } = this.state;
    this.setStep(activeStep + 1);
  };

  handleChangeIsPublic = value => {
    this.setState({
      isPublic: value,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setStep(activeStep - 1);
  };

  handleStep = step => {
    this.setStep(step);
  };

  setStep(step) {
    const { valid, change, submit } = this.props;
    change('_validationHack', Date.now());
    if (!valid) {
      submit();
    }
    if (valid && step >= 0 && step <= this.getLastStep()) {
      this.setState({
        activeStep: step,
      });
    }
  }

  async componentDidMount() {
    const { isEdited, initialValues } = this.props;
    this.setState({
      isEdited: isEdited,
      validationSectionShow: isEdited ? initialValues.scope : null,
    });
  }

  render() {
    let {
      classes,
      valid,
      pristine,
      isEdited,
      handleSubmit,
      handleSubmitTrigger,
      t,
      initialValues: { permissions = {} },
      isLastStepIsBlockedByRecruiters,
    } = this.props;

    const { activeStep } = this.state;
    const slicedSteps = permissions.isMentor
      ? this.steps.slice(0, 3)
      : this.steps;
    const steps = slicedSteps.map((label, index) => {
      const labelIcon = (
        <div onClick={() => this.handleStep(index)} className="outer-circle">
          <div className="inner-circle" />
        </div>
      );

      const labelProps = {};
      if (this.isStepOptional(index) && isLastStepIsBlockedByRecruiters) {
        labelProps.optional = (
          <Typography variant="caption">{t`projectform:editionDisabled`}</Typography>
        );
      }

      const labelClasses = {
        label: classes.labelText,
        active: classes.stepRootActive,
      };

      return (
        <Step key={label}>
          <StepLabel {...labelProps} icon={labelIcon} classes={labelClasses}>
            {t('project:form.stepper.' + label)}
          </StepLabel>
        </Step>
      );
    });

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            classes={{
              root: classes.stepRoot,
            }}
          >
            {steps}
          </Stepper>
          <div>
            <Form
              onSubmit={handleSubmit(values => {
                this.setState({
                  isSubmit: true,
                });
                handleSubmitTrigger(values);
              })}
            >
              <Grid
                container
                alignItems="center"
                spacing={3}
                className={classes.formItem}
              >
                {this.getStepContent(activeStep)}
                <ProjectFormFooter
                  valid={valid}
                  pristine={pristine}
                  submitting={this.state.isSubmit}
                  activeStep={activeStep}
                  handleBack={this.handleBack}
                  handleNext={this.handleNext}
                  isEdited={isEdited}
                  isMentorEdited={permissions.isMentor}
                />
              </Grid>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const afterSubmit = (result, dispatch) => {
  if (result && result.status === 200) {
    dispatch(reset('projectCreatorForm'));
  }
};

ProjectForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ProjectCreator = reduxForm({
  form: 'projectCreatorForm',
  validate,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ProjectForm);

const mapStateToProps = (state, props) => {
  const forceScope = props.isLastStepIsBlockedByRecruiters
    ? state.projects.currentProject.scope
    : 2;
  return {
    initialValues: props.isEdited
      ? { ...state.projects.currentProject, scope: forceScope }
      : props.copiedProject,
    formValues: state.form['projectCreatorForm']
      ? state.form['projectCreatorForm'].values
      : {},
  };
};

const mapDispatchToProps = dispatch => ({
  change: (field, value) =>
    dispatch(change('projectCreatorForm', field, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectCreator)));
