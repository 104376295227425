import {
  Typography,
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ManIcon from 'shared/assets/img/icons/project-show/man_black.png';
import PairIcon from 'shared/assets/img/icons/project-show/pair.png';
import WomanIcon from 'shared/assets/img/icons/project-show/woman_black.png';
import { enums } from 'shared/enums';
import { Checkbox, TextField, Translate } from 'shared/newComponents';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import Select from 'shared/newComponents/Select';
import { ProjectV2Service, ProjectOrderService } from 'shared/services';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import { castLocalizationObjectForSelect } from 'shared/utils/functions';
import CustomDialog from '../../../../../components/CustomDialog';
import CompanyData from '../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/CompanyData/CompanyData';
import ProjectOrderLocalization from '../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalization';
import ProjectOrderLocalizationForm from '../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalizationForm/ProjectOrderLocalizationForm';
import { styles } from './ModifyOrderDialog.styles';

const MODEL = {
  numberOfManCandidate: 'numberOfManCandidate',
  numberOfPairCandidate: 'numberOfPairCandidate',
  numberOfWomanCandidate: 'numberOfWomanCandidate',
  employmentStartDate: 'employmentStartDate',
  projectOderLocalization: 'projectOderLocalization',
  sumOfCandidates: 'sumOfCandidates',
  address: 'address',
  postCode: 'postCode',
  city: 'city',
  isRemoteWork: 'isRemoteWork',
  isHybridWork: 'isHybridWork',
  typeOfOrder: 'typeOfOrder',
};

const ModifyOrderDialog = ({
  classes,
  t,
  isOpen,
  onClose,
  projectOrder,
  project,
  projectOrderAdded,
  isEdit = true,
}) => {
  const formData = {
    typeOfOrder: projectOrder && isEdit ? projectOrder.typeOfOrder : '',
    numberOfManCandidate:
      projectOrder && isEdit ? projectOrder.numberOfManCandidate : 0,
    numberOfPairCandidate:
      projectOrder && isEdit ? projectOrder.numberOfPairCandidate : 0,
    numberOfWomanCandidate:
      projectOrder && isEdit ? projectOrder.numberOfWomanCandidate : 0,
    sumOfCandidates: projectOrder && isEdit ? projectOrder.sumOfCandidates : 0,
    employmentStartDate:
      projectOrder && isEdit ? projectOrder.employmentStartDate : '',
    projectOderLocalization:
      projectOrder && isEdit ? projectOrder.localization : '',
    address:
      projectOrder && isEdit && projectOrder.localization
        ? projectOrder.localization.address
        : '',
    postCode:
      projectOrder && isEdit && projectOrder.localization
        ? projectOrder.localization.postCode
        : '',
    city:
      projectOrder && isEdit && projectOrder.localization
        ? projectOrder.localization.city
        : '',
    isRemoteWork:
      projectOrder && isEdit && projectOrder.localization
        ? projectOrder.localization.isRemoteWork
        : false,
    isHybridWork:
      projectOrder && isEdit && projectOrder.localization
        ? projectOrder.localization.isHybridWork
        : false,
  };

  const [isOpenProjectOrderLocalization, setIsOpenProjectOrderLocalization] =
    useState(false);

  const [projectOrderLocalizations, setProjectOrderLocalizations] = useState([
    [],
  ]);

  const getProjectOrderLocations = () => {
    ProjectV2Service.getProjectLocalizations(project.id)
      .then(({ data }) => {
        setProjectOrderLocalizations(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object().shape(
    {
      projectOderLocalization: Yup.object().shape({
        id: Yup.number()
          .required()
          .required(t('projectCreator:core.requiredField')),
      }),

      sumOfCandidates: Yup.number().when('typeOfOrder', {
        is: typeOfOrder =>
          typeOfOrder === Number(Object.keys(enums.TYPE_OF_ORDER)[0]),
        then: Yup.number()
          .min(1, t('projectCreator:core.minValue', { value: 1 }))
          .required(t('projectCreator:core.requiredField')),
        otherwise: Yup.number()
          .max(-1, t('projectCreator:core.minValue', { value: -1 }))
          .required(t('projectCreator:core.requiredField')),
      }),
      numberOfManCandidate: Yup.number().when('typeOfOrder', {
        is: typeOfOrder =>
          typeOfOrder === Number(Object.keys(enums.TYPE_OF_ORDER)[0]),
        then: Yup.number()
          .min(0, t('projectCreator:core.minValue', { value: 0 }))
          .required(t('projectCreator:core.requiredField')),
        otherwise: Yup.number()
          .max(0, t('projectCreator:core.minValue', { value: 0 }))
          .required(t('projectCreator:core.requiredField')),
      }),
      numberOfPairCandidate: Yup.number().when('typeOfOrder', {
        is: typeOfOrder =>
          typeOfOrder === Number(Object.keys(enums.TYPE_OF_ORDER)[0]),
        then: Yup.number()
          .min(0, t('projectCreator:core.minValue', { value: 0 }))
          .required(t('projectCreator:core.requiredField')),
        otherwise: Yup.number()
          .max(0, t('projectCreator:core.minValue', { value: 0 }))
          .required(t('projectCreator:core.requiredField')),
      }),
      numberOfWomanCandidate: Yup.number().when('typeOfOrder', {
        is: typeOfOrder =>
          typeOfOrder === Number(Object.keys(enums.TYPE_OF_ORDER)[0]),
        then: Yup.number()
          .min(0, t('projectCreator:core.minValue', { value: 0 }))
          .required(t('projectCreator:core.requiredField')),
        otherwise: Yup.number()
          .max(0, t('projectCreator:core.minValue', { value: 0 }))
          .required(t('projectCreator:core.requiredField')),
      }),
      employmentStartDate: Yup.date().required(
        t('projectCreator:core.requiredField')
      ),
      typeOfOrder: Yup.number().required(t`projectCreator:core.requiredField`),
    },
    ['typeOfOrder', 'sumOfCandidates']
  );

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: (values, actions) => {
      const body = {
        localization: values.projectOderLocalization,
        typeOfOrder: values.typeOfOrder,
        company: { id: projectOrder.company.id },
        sumOfCandidates: parseInt(values.sumOfCandidates),
        numberOfManCandidate: parseInt(values.numberOfManCandidate),
        numberOfWomanCandidate: parseInt(values.numberOfWomanCandidate),
        numberOfPairCandidate: parseInt(values.numberOfPairCandidate),
        employmentStartDate: values.employmentStartDate.trim(),
        project: { id: project.id },
      };

      const sumObjects = createSumObject(values);

      if (
        !isAllNumbersSetToZero(values) &&
        parseInt(values.sumOfCandidates) !==
          validateAllNumberOfCandidates(sumObjects)
      ) {
        actions.setFieldError(
          'numberOfManCandidate',
          t`modifyOrderDialog:sumOfCandidateError`
        );
        return;
      }
      if (isEdit) {
        ProjectOrderService.editProjectOrder(projectOrder.id, body)
          .then(() => {
            projectOrderAdded();
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        ProjectOrderService.addProjectOrder(body)
          .then(() => {
            window.location.reload();
          })
          .catch(error => {
            console.log(error);
            alert(t`addOrganizationForm:error`);
          });
      }
    },
  });

  const validateAllNumberOfCandidates = sumObjects => {
    const sum =
      parseInt(sumObjects[MODEL.numberOfPairCandidate]) * 2 +
      parseInt(sumObjects[MODEL.numberOfManCandidate]) +
      parseInt(sumObjects[MODEL.numberOfWomanCandidate]);
    if (isNaN(sum)) {
      return 0;
    }
    return sum;
  };

  const handleChangeSumOfCandidates = (field, event) => {
    formik.setFieldValue(field, event.target.value);
    setDefaultValueForCandidateTypes();
  };

  const handleChangeTypeOfOrder = (field, event) => {
    formik.setFieldValue(field, event.target.value);
    formik.setFieldValue(MODEL.sumOfCandidates, 0);
    setDefaultValueForCandidateTypes();
  };

  const setDefaultValueForCandidateTypes = () => {
    formik.setFieldValue(MODEL.numberOfPairCandidate, 0);
    formik.setFieldValue(MODEL.numberOfManCandidate, 0);
    formik.setFieldValue(MODEL.numberOfWomanCandidate, 0);
  };

  const isAllNumbersSetToZero = values =>
    parseInt(values.numberOfPairCandidate) === 0 &&
    parseInt(values.numberOfManCandidate) === 0 &&
    parseInt(values.numberOfWomanCandidate) === 0;

  const createSumObject = values => {
    return {
      numberOfPairCandidate: values[MODEL.numberOfPairCandidate],
      numberOfManCandidate: values[MODEL.numberOfManCandidate],
      numberOfWomanCandidate: values[MODEL.numberOfWomanCandidate],
    };
  };
  const handleChangeNumberOfCandidates = (field, event) => {
    const values = formik.values;

    const sumObjects = createSumObject(formik.values);

    sumObjects[field] = event.target.value;
    const validateAllNumberOfCandidate =
      validateAllNumberOfCandidates(sumObjects);
    if (
      event.target.value >= 0 &&
      formik.values.typeOfOrder === Number(Object.keys(enums.TYPE_OF_ORDER)[0])
    ) {
      if (
        validateAllNumberOfCandidate >= 0 &&
        validateAllNumberOfCandidate <= parseInt(values[MODEL.sumOfCandidates])
      ) {
        formik.setFieldValue(field, event.target.value);
      }
    } else if (event.target.value <= 0) {
      if (
        validateAllNumberOfCandidate <= 0 &&
        validateAllNumberOfCandidate >= parseInt(values[MODEL.sumOfCandidates])
      ) {
        formik.setFieldValue(field, event.target.value);
      }
    }
  };

  const resetLocationForm = () => {
    formik.setFieldValue(MODEL.address, '');
    formik.setFieldValue(MODEL.postCode, '');
    formik.setFieldValue(MODEL.city, '');
    formik.setFieldValue(MODEL.isRemoteWork, false);
    formik.setFieldValue(MODEL.isHybridWork, false);
  };

  useEffect(() => {
    const currentSelectValue = formik.values[MODEL.projectOderLocalization];
    const localization = projectOrderLocalizations.find(
      item => item.id === currentSelectValue.id
    );

    resetLocationForm();
    if (localization) {
      formik.setFieldValue(MODEL.address, localization.address);
      formik.setFieldValue(MODEL.postCode, localization.postCode);
      formik.setFieldValue(MODEL.city, localization.city);
      formik.setFieldValue(MODEL.isRemoteWork, !!localization.isRemoteWork);
      formik.setFieldValue(MODEL.isHybridWork, !!localization.isHybridWork);
    }
  }, [formik.values[MODEL.projectOderLocalization], projectOrderLocalizations]);

  useEffect(() => {
    getProjectOrderLocations();
  }, [projectOrder]);

  const handleOpenAddLocalization = () => {
    setIsOpenProjectOrderLocalization(true);
  };

  const handleCloseAddLocalization = () => {
    setIsOpenProjectOrderLocalization(false);
  };

  const locationAdded = () => {
    getProjectOrderLocations();
    handleCloseAddLocalization();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component="div"
          variant="h6"
        >{t`modifyOrderDialog:header`}</Typography>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <CompanyData project={project} />
          <Translate
            text={project.position}
            className={classes.employee}
            disableTranslate={true}
            bold
          />
          <ProjectOrderLocalization
            project={project}
            handleOpenAddLocalization={handleOpenAddLocalization}
          />
          <Typography
            component="div"
            variant="h6"
            className={classes.location}
          >{t`modifyOrderDialog:employmentLocation`}</Typography>
          <MultiSelect
            formik={formik}
            name={MODEL.projectOderLocalization}
            label={t`modifyOrderDialog:workplace`}
            options={castLocalizationObjectForSelect(projectOrderLocalizations)}
          />
          <div className={classes.txtFields}>
            <div className={classes.grow}>
              <TextField
                disabled={true}
                formik={formik}
                label={t`modifyOrderDialog:street`}
                name={MODEL.address}
              />
            </div>
          </div>
          <div className={classes.txtFields}>
            <div className={classes.homeNumber}>
              <TextField
                disabled={true}
                formik={formik}
                name={MODEL.postCode}
                label={t`modifyOrderDialog:postalCode`}
              />
            </div>
            <div className={classes.grow}>
              <TextField
                disabled={true}
                formik={formik}
                name={MODEL.city}
                label={t`modifyOrderDialog:city`}
              />
            </div>
          </div>
          <div className={classes.checkboxes}>
            <Checkbox
              formik={formik}
              disabled={true}
              name={MODEL.isRemoteWork}
              label={t`modifyOrderDialog:openToRemote`}
            />
            <Checkbox
              formik={formik}
              disabled={true}
              name={MODEL.isHybridWork}
              label={t`modifyOrderDialog:openToHybrid`}
            />
          </div>
        </DialogContent>
        <div className={classes.blueBg}>
          <Typography variant="body1">{t`modifyOrderDialog:typeOfOrder`}</Typography>
          <Select
            formik={formik}
            name={MODEL.typeOfOrder}
            variant="transparent"
            onChange={event =>
              handleChangeTypeOfOrder(MODEL.typeOfOrder, event)
            }
            items={getSelectItemsTranslate(enums.TYPE_OF_ORDER, t).map(i =>
              i.id === 1 ? { ...i, color: 'red' } : i
            )}
            label={t`modifyOrderDialog:typeOfOrder`}
            fontBold={true}
          />
          {formik.values.typeOfOrder !== '' && (
            <>
              <div className={classes.candidatesNumber}>
                <div>
                  {formik.values.typeOfOrder ===
                  Number(Object.keys(enums.TYPE_OF_ORDER)[0]) ? (
                    <Typography variant="body1">{t`modifyOrderDialog:amountOfCandidates`}</Typography>
                  ) : (
                    <Typography variant="body1">{t`modifyOrderDialog:candidateAmount`}</Typography>
                  )}
                </div>
                <div className={classes.datePicker}>
                  <TextField
                    onChange={event =>
                      handleChangeSumOfCandidates(MODEL.sumOfCandidates, event)
                    }
                    type="number"
                    name={'sumOfCandidates'}
                    formik={formik}
                    variant="transparent"
                  />
                </div>
              </div>
              <Typography variant="body1">{t`modifyOrderDialog:including`}</Typography>
              <div className={classes.including}>
                <div className={classes.includingCat}>
                  <img src={ManIcon} alt="ManIcon" height="30" />
                  <TextField
                    onChange={event =>
                      handleChangeNumberOfCandidates(
                        MODEL.numberOfManCandidate,
                        event
                      )
                    }
                    type="number"
                    formik={formik}
                    variant="transparent"
                    name="numberOfManCandidate"
                  />
                </div>
                <div className={classes.includingCat}>
                  <img src={WomanIcon} alt="WomanIcon" height="30" />
                  <TextField
                    onChange={event =>
                      handleChangeNumberOfCandidates(
                        MODEL.numberOfWomanCandidate,
                        event
                      )
                    }
                    type="number"
                    formik={formik}
                    variant="transparent"
                    name="numberOfWomanCandidate"
                  />
                </div>
                <div className={classes.includingCat}>
                  <img src={PairIcon} alt="PairIcon" height="30" />
                  <TextField
                    onChange={event =>
                      handleChangeNumberOfCandidates(
                        MODEL.numberOfPairCandidate,
                        event
                      )
                    }
                    type="number"
                    formik={formik}
                    variant="transparent"
                    name="numberOfPairCandidate"
                  />
                </div>
              </div>
              <div className={classes.dateOfEmployment}>
                <div>
                  <Typography variant="body1">{t`modifyOrderDialog:dateOfEmployment`}</Typography>
                </div>
                <div className={classes.datePicker}>
                  <TextField
                    type="date"
                    formik={formik}
                    variant="transparent"
                    name="employmentStartDate"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <Button type="submit" className={classes.saveBtn}>
          {t`modifyOrderDialog:saveBtn`}
        </Button>
      </form>
      <CustomDialog
        maxWidth={'md'}
        title={''}
        onCloseDialog={handleCloseAddLocalization}
        body={
          <ProjectOrderLocalizationForm
            project={project}
            localizationAdded={locationAdded}
          />
        }
        isOpen={isOpenProjectOrderLocalization}
      />
    </Dialog>
  );
};

export default withStyles(styles)(withTranslation()(ModifyOrderDialog));
