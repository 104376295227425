import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadAssets } from 'shared/services/assetService';
import {
  getOrganization,
  editOrganization,
  OrganizationService,
} from 'shared/services/organizationService';
import AssetsUpload from './asset/AssetsUpload';

export class OrganizationAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
    };
  }

  uploadAssets = async (files, assetType) => {
    const { organization, editOrganization, uploadAssets } = this.props;
    let { data } = await uploadAssets(files, assetType);
    let organizationAssets = [];
    Array.isArray(data) &&
      data.forEach(file => {
        organizationAssets.push(file.id);
      });
    await OrganizationService.editOrganizationAsset(organization.id, {
      assets: organizationAssets,
    });
    window.location.reload();
  };

  getAssets = async () => {
    const { organization, getOrganization, assetType } = this.props;
    if (this.state.reload) {
      await getOrganization(organization.id);
      return _.filter(organization.assets, { type: assetType });
    } else {
      this.setState({
        reload: true,
      });
      return _.filter(organization.assets, { type: assetType });
    }
  };

  render() {
    const {
      currentUser = {},
      assetType,
      filesLimit = 4,
      acceptedFiles,
      organization,
    } = this.props;
    const { isRecruiter = false } = currentUser;

    return organization.id && isRecruiter ? (
      <AssetsUpload
        uploadAssetsHandler={this.uploadAssets}
        getAssetsHandler={this.getAssets}
        assets={organization.assets}
        filesLimit={filesLimit}
        assetType={assetType}
        acceptedFiles={acceptedFiles}
      >
        {this.props.children}
      </AssetsUpload>
    ) : null;
  }
}

const mapDispatchToProps = {
  uploadAssets,
  editOrganization,
  getOrganization,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

OrganizationAssets.propTypes = {
  currentUser: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired,
  uploadAssets: PropTypes.func.isRequired,
  editOrganization: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAssets);
