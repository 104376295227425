import _ from 'lodash';
import AvatarIcon from 'shared/assets/img/icons/candidateCreator/avatar.svg';
import projectEmptyImage from 'shared/assets/img/jobllegro-320.png';
import userEmptyImage from 'shared/assets/img/user.jpg';
import { API_URL_RESOURCE } from '../constants';
import { enums } from '../enums';
import { getAssetUrl } from '../services';

export const getUserAvatarSrc = (
  user,
  newDefaultIcon = false,
  key = 'assets'
) => {
  const defaultAvatar = newDefaultIcon ? AvatarIcon : userEmptyImage;
  if (user && user[key]) {
    const userAvatarAsset = _.find(user[key], { type: 'avatar' });
    const userImage = userAvatarAsset && API_URL_RESOURCE + userAvatarAsset.id;
    return userImage || defaultAvatar;
  } else {
    return defaultAvatar;
  }
};

export const getProjectAssetsSrcByType = (
  project,
  type,
  defaultImage = projectEmptyImage
) => {
  if (
    project &&
    project.assets &&
    _.filter(project.assets, { type: type }).length > 0
  ) {
    const projectAssets = _.filter(project.assets, { type: type }) || [];
    const projectImages = projectAssets.map(projectAsset => {
      return API_URL_RESOURCE + projectAsset.id;
    });
    return projectImages;
  }
};

export const getAllCandidateAssetsDocuments = candidate => {
  if (candidate && candidate.candidateAssets) {
    return candidate.candidateAssets.filter(item => {
      if (enums.ATTACHMENT_TYPE_ARRAY.includes(item.type)) {
        return item;
      }
    });
  }
  return [];
};

export const getCandidateAssetsSrcByType = (candidate, type) => {
  if (
    candidate &&
    candidate.candidateAssets &&
    _.filter(candidate.candidateAssets, { type: type }).length > 0
  ) {
    const candidateAssets =
      _.filter(candidate.candidateAssets, { type: type }) || [];
    const candidateAssetsUrls = candidateAssets.map(candidateAsset => {
      return API_URL_RESOURCE + candidateAsset.id;
    });
    return candidateAssetsUrls;
  } else {
    return [];
  }
};

export const getCandidateAssetsDataByType = (candidate, type) => {
  if (
    candidate &&
    candidate.candidateAssets &&
    _.filter(candidate.candidateAssets, { type: type }).length > 0
  ) {
    const candidateAssets =
      _.filter(candidate.candidateAssets, { type: type }) || [];
    let getData = {};
    const candidateAssetsUrls = candidateAssets.map(candidateAsset => {
      return Object.assign(
        {
          assetId: candidateAsset.id,
          link: API_URL_RESOURCE + candidateAsset.id,
          filename: candidateAsset.filename,
        },
        getData
      );
    });
    return candidateAssetsUrls;
  } else {
    return [];
  }
};

export const getProjectAssetSrcByType = (
  project,
  type,
  defaultImage = projectEmptyImage
) => {
  if (project && project.assets) {
    const projectAsset = _.find(project.assets, { type: type });
    const projectImage = projectAsset && API_URL_RESOURCE + projectAsset.id;
    if (projectImage) {
      return projectImage;
    }
    if (!project.industryCategory) {
      return type === 'header' ? 'emptyCardHeader' : defaultImage;
    }
    const assets = project.industryCategory.assets;
    const headerUrl = assets.find(
      item => item.type === 'industryCategoryBackground'
    );
    const imageUrl = headerUrl ? getAssetUrl(headerUrl.id) : null;
    if (imageUrl) {
      return imageUrl;
    }
    return type === 'header' ? 'emptyCardHeader' : defaultImage;
  } else {
    return type === 'header' ? 'emptyCardHeader' : defaultImage;
  }
};

export const getImageFromSource = (image, defaultImage = projectEmptyImage) => {
  if (image) {
    const Image = API_URL_RESOURCE + image;
    return Image || defaultImage;
  } else {
    return defaultImage;
  }
};

export const getDocumentFromSource = document => {
  if (document) {
    return API_URL_RESOURCE + document;
  } else {
    return null;
  }
};

export const getSelectItems = enumItems => {
  const items = [];
  Object.values(enumItems).forEach((key, index) =>
    items.push({ id: index, name: key })
  );
  return items;
};

export const getSelectItemsFullObject = (enumItems, keyParse = true) => {
  const items = [];
  for (const [key, value] of Object.entries(enumItems)) {
    items.push({ id: keyParse ? parseInt(key) : key, name: value });
  }
  return items;
};

export const getSelectItemsFullObjectTranslate = (
  enumItems,
  t,
  parseKeyToInt = true
) => {
  const items = [];
  for (const [key, value] of Object.entries(enumItems)) {
    items.push({ id: parseKeyToInt ? parseInt(key) : key, name: t(value) });
  }
  return items;
};

export const sortItemsBy = (items, key) => {
  return _.sortBy(items, key);
};

export const getSelectItemsTranslate = (enumItems, t) => {
  const items = [];
  Object.values(enumItems).forEach((key, index) =>
    items.push({ id: index, name: t(key) })
  );
  return items;
};

export const getEnumItemName = (enumItems, index) => {
  const selectItems = getSelectItems(enumItems);
  const isArray = Array.isArray(index);
  return isArray
    ? index.map(i => _.get(selectItems[i], 'name'))
    : _.get(
        _.find(selectItems, o => o.id === parseInt(index)),
        'name'
      );
};

export const getEnumItemNameFullObject = (enumItems, index) => {
  if (index === undefined) {
    return;
  }
  const selectItems = getSelectItemsFullObject(enumItems);
  const selectedItem = selectItems.find(item => item.id === index);
  return selectedItem ? selectedItem.name : '';
};

export const getEnumItemNameFullObjectByName = (enumItems, name) => {
  if (name === undefined) {
    return;
  }
  const selectItems = getSelectItemsFullObject(enumItems, false);
  const selectedItem = selectItems.find(item => item.id === name);

  return selectedItem ? selectedItem.name : '';
};
