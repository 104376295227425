import { Grid } from '@material-ui/core';
import { get, filter, isEmpty } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Translate } from 'shared/newComponents';
import { MODEL } from '../../../ProjectCreateWizardStep3/ProjectCreateWizardStep3.utils';

const ProjectReviewStep3 = ({ step3FormValues, t }) => {
  const isRequireAttachmentCv = get(
    step3FormValues,
    MODEL.isRequireAttachmentCv
  )
    ? 'C.V.'
    : null;
  const isRequireAttachmentRecruiterApproved = get(
    step3FormValues,
    MODEL.isRequireAttachmentRecruiterApproved
  )
    ? t('projectCreator:step3.isRequireAttachmentRecruiterApproved')
    : null;
  const isRequireAttachmentPassportStampScan = get(
    step3FormValues,
    MODEL.isRequireAttachmentPassportStampScan
  )
    ? t('projectCreator:step3.isRequireAttachmentPassportStampScan')
    : null;
  const isRequireAttachmentPassportVisaScan = get(
    step3FormValues,
    MODEL.isRequireAttachmentPassportVisaScan
  )
    ? t('projectCreator:step3.isRequireAttachmentPassportVisaScan')
    : null;
  const isRequireAttachmentPassportPersonalDataScan = get(
    step3FormValues,
    MODEL.isRequireAttachmentPassportPersonalDataScan
  )
    ? t('projectCreator:step3.isRequireAttachmentPassportPersonalDataScan')
    : null;
  const isRequireAttachmentWorkPermitScan = get(
    step3FormValues,
    MODEL.isRequireAttachmentWorkPermitScan
  )
    ? t('projectCreator:step3.isRequireAttachmentWorkPermitScan')
    : null;
  const isRequireAttachmentWorkQualificationScan = get(
    step3FormValues,
    MODEL.isRequireAttachmentWorkQualificationScan
  )
    ? t('projectCreator:step3.isRequireAttachmentWorkQualificationScan')
    : null;
  const isRequireAttachmentCertificateAndDiplomaScan = get(
    step3FormValues,
    MODEL.isRequireAttachmentCertificateAndDiplomaScan
  )
    ? t('projectCreator:step3.isRequireAttachmentCertificateAndDiplomaScan')
    : null;
  const isRequireAttachmentOtherScan = get(
    step3FormValues,
    MODEL.isRequireAttachmentOtherScan
  );
  const isRequireAttachmentOtherDesc =
    isRequireAttachmentOtherScan &&
    get(step3FormValues, MODEL.isRequireAttachmentOtherDesc)
      ? get(step3FormValues, MODEL.isRequireAttachmentOtherDesc)
      : null;

  const requiredAttachment = filter(
    [
      isRequireAttachmentCv,
      isRequireAttachmentRecruiterApproved,
      isRequireAttachmentPassportStampScan,
      isRequireAttachmentPassportVisaScan,
      isRequireAttachmentPassportPersonalDataScan,
      isRequireAttachmentWorkPermitScan,
      isRequireAttachmentWorkQualificationScan,
      isRequireAttachmentCertificateAndDiplomaScan,
      isRequireAttachmentOtherDesc,
    ],
    o => !!o
  );

  return isEmpty(requiredAttachment) ? null : (
    <Grid container style={{ padding: '0px 25px', paddingBottom: 25 }}>
      <Grid container style={{ paddingBottom: 8 }}>
        <Translate
          style={{ fontSize: 26 }}
          text="projectCreator:step3.title"
          bold
          variant="h6"
        />
      </Grid>
      <Grid container>
        <ul>
          {requiredAttachment.map((item, index) => (
            <React.Fragment key={index}>
              <li>
                <p
                  style={{
                    fontFamily: 'Barlow Semi Condensed, sans-serif',
                  }}
                >
                  {item}
                </p>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ProjectReviewStep3);
