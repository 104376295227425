import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
// import { TextField, Checkbox } from 'redux-form-material-ui';
import { intNormalization, normalizeMax200 } from 'shared/utils/normalizers';
import FieldWysiwyg from '../FieldWysiwyg';
import AutosuggestComponent from '../inputs/AutoSuggest';

const styles = () => ({
  formControl: {
    minWidth: '50%',
  },
  employeeDatePicker: {
    minWidth: '300px',
  },
});

export const ProjectFormStepTwo = props => {
  let { valid, classes, initialValues, DictionariesCities, t } = props;

  initialValues = initialValues || {};

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Field
          label={t('project:field.position') + ' *'}
          name="position"
          inputProps={{ maxLength: 255 }}
          component={TextField}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.candidateCount') + ' *'}
          name="candidateCount"
          component={TextField}
          fullWidth
          inputProps={{ min: 0 }}
          type="number"
          normalize={intNormalization}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <AutosuggestComponent
            suggestionsList={DictionariesCities}
            maxLength={255}
            fieldName="city"
            fieldLabel={t('project:field.city') + ' *'}
            currentFieldValue={initialValues.city || ''}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink={true}>
            {t('project:field.minSalaryLong')}
          </InputLabel>
          <br />
          <Field
            label={t('project:field.minSalary') + ' *'}
            name="minSalary"
            component={TextField}
            inputProps={{ min: 0, max: 999999999 }}
            type="number"
            normalize={intNormalization}
          />
          <Field
            label={t('project:field.maxSalary')}
            name="maxSalary"
            component={TextField}
            inputProps={{ min: 0, max: 999999999 }}
            type="number"
            normalize={intNormalization}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Field name="isSalaryPublic" component={Checkbox} />}
          label={t('project:field.isSalaryPublic')}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.employmentStartDate') + ' *'}
          type="date"
          name="employmentStartDate"
          defaultValue="1990-01-01"
          component={TextField}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.employeeDatePicker}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.contractType') + ' *'}
          name="contractType"
          inputProps={{ maxLength: 255 }}
          component={TextField}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWysiwyg
          valid={valid}
          label={t('project:field.positionDescription') + ' *'}
          name="positionDescription"
          value={initialValues.positionDescription || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.profits')}
          name="profits"
          component={TextField}
          multiline={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('project:field.benefits') + ' *'}
          name="benefits"
          inputProps={{ maxLength: 200 }}
          component={TextField}
          multiline={true}
          fullWidth
          normalize={normalizeMax200}
        />
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.isEdited ? state.projects.currentProject : null,
    DictionariesCities: state.dictionaries.cities.list,
  };
};

ProjectFormStepTwo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(ProjectFormStepTwo)));
