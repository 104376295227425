export const styles = () => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#2D333B',
  },
  dialogTitle: {
    '& h6': { fontWeight: '500' },
  },
  question: {
    margin: 20,
  },
  info: { padding: 20 },
  closeOrderBtn: {
    color: '#fff',
    display: 'flex',
    margin: '40px auto',
    width: 330,
    height: 60,
    background: '#EF2917',
    borderRadius: 30,
    '&:hover': {
      background: '#EF2917',
      opacity: 0.8,
    },
  },
});
