import { get, isNil } from 'lodash';
import { object, array, string, number } from 'yup';

export const allCompetenceIsSet = (values, index) => {
  const competenceCategoryName = `${MODEL.projectSkills}[${index}].${MODEL.competenceCategory}`;
  const competenceTypeName = `${MODEL.projectSkills}[${index}].${MODEL.competenceType}`;
  const competenceKindName = `${MODEL.projectSkills}[${index}].${MODEL.competenceKind}`;
  const skillName = `${MODEL.projectSkills}[${index}].${MODEL.skill}`;

  const competenceCategoryValue = get(values, competenceCategoryName);
  const competenceTypeValue = get(values, competenceTypeName);
  const competenceKindValue = get(values, competenceKindName);
  const skillValue = get(values, skillName);

  return (
    !isNil(competenceCategoryValue) &&
    !isNil(competenceTypeValue) &&
    !isNil(competenceKindValue) &&
    !isNil(skillValue)
  );
};
export const MODEL = {
  projectSkills: 'projectSkills',
  competenceCategory: 'competence.competenceCategory',
  competenceType: 'competence.competenceType',
  competenceKind: 'competence.competenceKind',
  skill: 'skill',
  skillName: 'skill.name',
  experienceLevel: 'experienceLevel',
  requirements: 'requirements',
  workStyles: 'workStyles',
};

export const validationSchema = t =>
  object().shape({
    projectSkills: array()
      .min(3, t('projectCreator:core.requiredField'))
      .of(
        object().shape({
          experienceLevel: number().required(
            t('projectCreator:core.requiredField')
          ),
          competence: object().shape({
            competenceType: object().shape({
              name: string().required(t('projectCreator:core.requiredField')),
            }),
          }),
          skill: object().shape({
            name: string().required(t('projectCreator:core.requiredField')),
          }),
        })
      )
      .required(t('projectCreator:core.requiredField')),
    requirements: string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),

    workStyles: array()
      .min(1, t('projectCreator:core.requiredField'))
      .required(t('projectCreator:core.requiredField')),
  });
