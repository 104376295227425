import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { deleteUser, logoutUser } from 'shared/services';
import CustomDialog from '../../../../../../../components/CustomDialog';
import OrganizationForm from '../../../../../../../components/OrganizationForm';
import { styles } from './OrganizationData.styles';

const OrganizationData = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const userOrganization =
    recruiterData.organization &&
    [2, 5, 9].includes(recruiterData.organizationConfirmation)
      ? recruiterData.organization
      : null;

  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  const organizationName = userOrganization && userOrganization.name;

  const settlementDataType = userOrganization
    ? userOrganization.organizationType
    : null;

  const settlementDataTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t`addOrganizationForm:company`
        : t`addOrganizationForm:person`
      : '';

  const settlementDataInvoiceTypeTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t`project:field.companyName`
        : t`dashboard:rightDrawer.firstAndLastName`
      : t`project:field.companyName`;

  const settlementDataInvoiceNumberTypeTitle =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? t`project:field.nipAndVat`
        : t`organization:field.invoicePersonalNumber`
      : t`project:field.nipAndVat`;
  const isOrganizationOwner = recruiterData.isOrganizationOwner;

  const companyName = userOrganization && userOrganization.invoiceCompanyName;
  const invoiceAddress = userOrganization && userOrganization.invoiceAddress;
  const invoicePostCode = userOrganization && userOrganization.invoicePostCode;
  const invoiceCity = userOrganization && userOrganization.invoiceCity;
  const invoicePersonalNumber =
    userOrganization && userOrganization.invoicePersonalNumber;
  const accountNumber = userOrganization && userOrganization.accountNumber;
  const invoiceIdentificationNumber =
    userOrganization && userOrganization.invoiceIdentificationNumber;

  const settlementDataNumber =
    settlementDataType !== null
      ? settlementDataType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
        ? invoiceIdentificationNumber
        : invoicePersonalNumber
      : invoiceIdentificationNumber;

  let concatAddress = t('core:general.noInformation');
  if (
    invoiceAddress !== null ||
    invoicePostCode !== null ||
    invoiceCity !== null
  ) {
    concatAddress = `${invoiceAddress !== null ? invoiceAddress : ''} ${
      invoicePostCode !== null ? invoicePostCode : ''
    } ${invoiceCity !== null ? invoiceCity : ''}`;
  }

  if (!userOrganization) {
    return null;
  }

  return (
    <Grid container>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.gridWrapper}>
          <Grid container className={classes.gridPadding} alignItems={'center'}>
            <Grid container justifyContent={'space-between'}>
              <Grid item xs={6} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Translate
                      text={t`organization:orgData`}
                      bold
                      className={classes.bigFont}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Translate
                      text={settlementDataTitle}
                      bold
                      className={classes.settlementDataTitleFont}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isOrganizationOwner && (
                <Grid item xs={2} sm={2} md={2} className={classes.editWrapper}>
                  <div className={classes.imgWrapper} onClick={onOpen}>
                    <img alt="editIcon" src={EditIcon} height={50} />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`${t`organization:field.nameLong`}:`}
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={organizationName}
                  bold
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={settlementDataInvoiceTypeTitle}
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={companyName}
                  bold
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`${t`organization:field.invoiceAddress`}:`}
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={concatAddress}
                  bold
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={settlementDataInvoiceNumberTypeTitle}
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={settlementDataNumber}
                  bold
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`recruiter:field.accountNumber`}
                  className={classes.mediumFont}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={accountNumber}
                  bold
                  disableTranslate={true}
                  className={classes.mediumFont}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <CustomDialog
        maxWidth={'md'}
        title={''}
        isOpen={isOpen}
        onCloseDialog={onClose}
        body={
          <Grid container>
            <OrganizationForm organization={userOrganization} />
          </Grid>
        }
      />
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  deleteUser,
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(OrganizationData))));
