import { createReducer } from '@reduxjs/toolkit';
import { getAllSkillsAction } from './actions';

const initialState = {
  list: [],
};

export default createReducer(initialState, builder =>
  builder.addCase(getAllSkillsAction, (state, action) => {
    state.list = action.payload;
  })
);
