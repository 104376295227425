import { API } from '../constants';
import { ApiService } from './api.service';

export const ProjectOrderService = {
  addProjectOrder(data) {
    return ApiService.post(API.POST.PROJECT_ORDER.POST, data);
  },
  getProjectOrderFromProject(projectId) {
    return ApiService.get(
      API.GET.PROJECT_ORDER.PROJECT_ORDER_FROM_PROJECT.replace(
        ':projectId',
        projectId
      )
    );
  },
  getProjectOrder(projectOrderId) {
    return ApiService.get(
      API.GET.PROJECT_ORDER.PROJECT_ORDER.replace(
        ':projectOrderId',
        projectOrderId
      )
    );
  },
  editProjectOrder(projectOrderId, data) {
    return ApiService.put(
      API.PUT.PROJECT_ORDER.EDIT.replace(':orderId', projectOrderId),
      data
    );
  },
  closeProjectOrder(data) {
    return ApiService.put(API.PUT.PROJECT_ORDER.CLOSE, data);
  },
  confirmProjectOrder(projectOrderId, data) {
    return ApiService.put(
      API.PUT.PROJECT_ORDER.CONFIRM_PROJECT_ORDER.replace(
        ':orderId',
        projectOrderId
      ),
      data
    );
  },
};

export default ProjectOrderService;
