import {
  Avatar,
  Badge,
  CardHeader,
  FormControlLabel,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { getUserAvatarSrc } from 'shared/utils/assets';

const styles = theme => ({
  atsIcons: {
    transform: 'scale(1.3)',
    padding: 0,
  },
  cardHeader: {
    paddingTop: 16,
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down('md')]: {
      width: '40px',
      margin: '0px auto',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  names: {
    color: '#000',
    fontSize: 17,
    lineHeight: '1.3em',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

export class AtsCardHeader extends Component {
  state = {
    checkboxStatus: false,
  };

  componentDidMount() {
    this.setState({
      checkboxStatus: this.props.obj.isChecked,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.obj.isChecked != null &&
      prevProps.obj.isChecked !== this.props.obj.isChecked
    ) {
      this.setState({
        checkboxStatus: this.props.obj.isChecked,
      });
    }
  }

  render() {
    const {
      classes,
      candidate,
      handleOpenCandidateInProject,
      obj: { owner, stageNumber, isRejected },
      handleChangeCheckbox,
      variant,
      project,
      t,
    } = this.props;
    const { checkboxStatus } = this.state;
    const avatar = getUserAvatarSrc(candidate);
    const avatarOwnerInProject = getUserAvatarSrc(owner);

    const isHiringManager = project.permissions.isHiringManager;

    const isMentorCoordinator = project.permissions.isMentorCoordinator;

    const getActionCheckbox = stageNumber => {
      if (variant === 'employmentProcess') {
        return [1, 3, 5].includes(stageNumber) && !isRejected ? (
          <FormControlLabel
            className={classes.atsIcons}
            control={
              <input
                type="checkbox"
                checked={checkboxStatus}
                onChange={() => handleChangeCheckbox(candidate.id, stageNumber)}
              />
            }
          />
        ) : null;
      }
      return stageNumber !== 1 && !isRejected ? (
        <FormControlLabel
          style={{ marginRight: '0px' }}
          control={
            <Checkbox
              checked={checkboxStatus}
              onChange={() => handleChangeCheckbox(candidate.id, stageNumber)}
            />
          }
        />
      ) : null;
    };
    const actionCheckbox = getActionCheckbox(stageNumber);

    return (
      <CardHeader
        className={classes.cardHeader}
        avatar={
          owner ? (
            <Badge
              badgeContent={
                <Tooltip
                  title={owner.firstName + ' ' + owner.lastName}
                  placement="top"
                >
                  <Avatar style={{ width: 22, height: 22, top: '29px' }}>
                    <img
                      src={avatarOwnerInProject}
                      draggable={false}
                      style={{ width: 35 }}
                      alt="Owner"
                      aria-label="Owner"
                    />
                  </Avatar>
                </Tooltip>
              }
            >
              <Avatar>
                <img
                  src={avatar}
                  style={{ width: 65, cursor: 'pointer' }}
                  draggable={false}
                  onClick={() =>
                    handleOpenCandidateInProject(candidate, owner, stageNumber)
                  }
                  alt={candidate.lastName}
                  aria-label="Candidate"
                />
              </Avatar>
            </Badge>
          ) : (
            <Avatar>
              <img
                src={avatar}
                style={{ width: 40 }}
                draggable={false}
                alt="Candidate"
                aria-label="Candidate"
              />
            </Avatar>
          )
        }
        action={actionCheckbox}
        title={
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              handleOpenCandidateInProject(candidate, owner, stageNumber)
            }
          >
            {candidate.firstName
              ? (isHiringManager || isMentorCoordinator) && stageNumber < 5
                ? `${t('candidate:core.candidate')} #${candidate.id}`
                : candidate.firstName
              : t`atsSubMenu:awaitingApproval`}
          </span>
        }
        subheader={
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              handleOpenCandidateInProject(candidate, owner, stageNumber)
            }
          >
            {(isHiringManager || isMentorCoordinator) && stageNumber < 5
              ? candidate.firstName
              : candidate.lastName}
          </span>
        }
        classes={{
          title: classes.names,
          subheader: classes.names,
        }}
      />
    );
  }
}

AtsCardHeader.propTypes = {
  candidate: PropTypes.object.isRequired,
  obj: PropTypes.object.isRequired,
  handleOpenCandidateInProject: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(AtsCardHeader));
