import { createReducer } from '@reduxjs/toolkit';
import {
  setCurrentProjectAction,
  clearCurrentProjectAction,
  setCurrentProjectsListAction,
  setCurrentProjectsLimitedListAction,
  clearCurrentProjectsLimitedListAction,
  setCurrentProjectsLimitedListCounterAction,
  clearCurrentProjectsLimitedListCounterAction,
  clearCurrentProjectsListAction,
  setProjectRecruiterConfirmationStatusAction,
  setProjectCandidateConfirmationStatusAction,
  clearProjectRecruiterConfirmationStatusAction,
  clearProjectCandidateConfirmationStatusAction,
  setProjectsPendingToRecruiterListAction,
  clearProjectsPendingToRecruiterListAction,
  setProjectsPendingToCandidateListAction,
} from './actions';

const initialState = {
  currentProject: {},
  currentProjectsList: [],
  currentProjectsLimitedList: [],
  currentProjectsLimitedListCounter: 0,
  projectRecruiterConfirmationStatus: {},
  projectCandidateConfirmationStatus: {},
  projectsPendingToRecruiterList: {},
  projectsPendingToCandidateList: {},
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setCurrentProjectAction, (state, action) => {
      state.currentProject = action.payload;
    })
    .addCase(clearCurrentProjectAction, (state, action) => {
      state.currentProject = initialState.currentProject;
    })
    .addCase(setCurrentProjectsListAction, (state, action) => {
      state.currentProjectsList = action.payload;
    })
    .addCase(setCurrentProjectsLimitedListAction, (state, action) => {
      state.currentProjectsLimitedList = action.payload;
    })
    .addCase(clearCurrentProjectsLimitedListAction, (state, action) => {
      state.currentProjectsLimitedList =
        initialState.currentProjectsLimitedList;
    })
    .addCase(setCurrentProjectsLimitedListCounterAction, (state, action) => {
      state.currentProjectsLimitedListCounter = action.payload;
    })
    .addCase(clearCurrentProjectsLimitedListCounterAction, (state, action) => {
      state.currentProjectsLimitedListCounter =
        initialState.currentProjectsLimitedListCounter;
    })
    .addCase(clearCurrentProjectsListAction, (state, action) => {
      state.currentProjectsList = initialState.currentProjectsList;
    })
    .addCase(setProjectRecruiterConfirmationStatusAction, (state, action) => {
      state.projectRecruiterConfirmationStatus = action.payload;
    })
    .addCase(setProjectCandidateConfirmationStatusAction, (state, action) => {
      state.projectCandidateConfirmationStatus = action.payload;
    })
    .addCase(clearProjectRecruiterConfirmationStatusAction, (state, action) => {
      state.projectRecruiterConfirmationStatus =
        initialState.projectRecruiterConfirmationStatus;
    })
    .addCase(clearProjectCandidateConfirmationStatusAction, (state, action) => {
      state.projectCandidateConfirmationStatus =
        initialState.projectCandidateConfirmationStatus;
    })
    .addCase(setProjectsPendingToRecruiterListAction, (state, action) => {
      state.projectsPendingToRecruiterList = action.payload;
    })
    .addCase(clearProjectsPendingToRecruiterListAction, (state, action) => {
      state.projectsPendingToRecruiterList =
        initialState.projectsPendingToRecruiterList;
    })
    .addCase(setProjectsPendingToCandidateListAction, (state, action) => {
      state.projectsPendingToCandidateList = action.payload;
    })
);
