export const styles = ({ palette }) => ({
  baseSnackbarDefault: {
    '& > div': {
      maxWidth: '100%',
      backgroundColor: palette.success.main,
    },
  },
  baseSnackbarError: {
    '& > div': {
      maxWidth: '100%',
      backgroundColor: palette.error.main,
    },
  },
});
