import { TablePagination } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { filterFunction } from 'shared/utils';
import {
  getTableRowPerPageFromStorage,
  setLocalStorage,
} from 'shared/utils/localStorage';
import Filtering from './Filtering';
import Sorting from './Sorting';

export class ElementsList extends Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      page: 0,
      orderBy: props.defaultSorting,
      filters: props.defaultFilters,
      rowsPerPage: props.rowsPerPage,
      elements: [],
    };
  }

  getRowsPerPage = () =>
    getTableRowPerPageFromStorage(this.props.storageKey) ||
    this.state.rowsPerPage;

  handleChangePage = (event, page) => {
    this.setState({ page });
    if (this.props.scrollUp) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  handleChangeRowsPerPage = event => {
    if (this.props.storageKey) {
      setLocalStorage(this.props.storageKey, event.target.value);
    }
    this.setState({ rowsPerPage: event.target.value });
    if (this.props.scrollUp) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  getPagination = elements => {
    const { t } = this.props;
    return (
      <TablePagination
        component={'div'}
        count={elements.length}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.page}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
        rowsPerPageOptions={[6, 12, 24, 48, 96]}
        labelRowsPerPage={t('pagination.elementsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          t('pagination.pagesFromTo', { from, to, count })
        }
      />
    );
  };

  getSorting = () => {
    return (
      <Sorting
        orderByHandler={this.orderBy}
        orderBy={this.state.orderBy}
        sortingOptions={this.props.sortingOptions}
      />
    );
  };

  getFiltering = () => {
    return (
      <Filtering
        filterByHandler={this.filterBy}
        filters={this.state.filters}
        filteringOptions={this.props.filteringOptions}
      />
    );
  };

  orderBy = (name, orderAsc) => {
    const { orderBy } = this.state;

    if (orderBy[name] === 'asc') {
      orderBy[name] = 'desc';
    } else if (orderBy[name] === 'desc') {
      delete orderBy[name];
    } else {
      orderBy[name] = 'asc';
    }

    this.setState({
      orderBy,
    });
  };

  filterBy = filters => {
    _.each(filters, (value, key) => {
      (value === '' || value === null) && delete filters[key];
    });
    this.setState({
      filters,
    });
  };

  componentDidMount() {
    this.updateElements();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps() {
    this.updateElements();
  }

  updateElements = async () => {
    let { sort, filter } = this.state;
    let elements = await this.props.getElements({ filter, sort });
    if (!elements) {
      elements = this.props.elements;
    }
    if (this._isMounted) {
      this.setState({
        elements,
      });
    }
  };

  render() {
    const { sortingOptions } = this.props;
    const { page, elements, orderBy, filters } = this.state;
    const rowsPerPage = this.getRowsPerPage();
    //remove empty values
    let filteredElements = _.without(elements, [null, undefined]);

    //filtering
    filteredElements = filters
      ? _.filter(filteredElements, element =>
          filterFunction({
            element,
            filters: this.state.filters,
            filteringOptions: this.props.filteringOptions,
          })
        )
      : filteredElements;

    //sorting
    if (Object.keys(orderBy).length > 0) {
      let sortingBy = [],
        sortingOrder = [];
      _.forOwn(orderBy, (value, key) => {
        sortingBy.push(key);
        const reversed = _.find(sortingOptions, { name: key }).reversed;
        let order = reversed ? (value === 'asc' ? 'desc' : 'asc') : value;
        sortingOrder.push(order);
      });
      filteredElements = _.orderBy(filteredElements, sortingBy, sortingOrder);
    }

    //pagination
    const content = filteredElements
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(element => {
        return this.props.getContent(element);
      });

    const pagination = this.getPagination(filteredElements);
    const filtering = this.getFiltering();
    const sorting = this.getSorting();

    return this.props.getWrapper({ content, pagination, sorting, filtering });
  }
}

ElementsList.defaultProps = {
  getElements: () => false,
  sortingOptions: [],
  filteringOptions: [],
  defaultSorting: {},
  defaultFilters: {},
  rowsPerPage: 12,
  scrollUp: false,
};

ElementsList.propTypes = {
  getContent: PropTypes.func,
  getWrapper: PropTypes.func,
  getElements: PropTypes.func,
  sortingOptions: PropTypes.array,
  filteringOptions: PropTypes.array,
  defaultSorting: PropTypes.object,
  defaultFilters: PropTypes.object,
  rowsPerPage: PropTypes.number,
};

export default withTranslation()(ElementsList);
