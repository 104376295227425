import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { find } from 'lodash';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import {
  editProject,
  ProjectV2Service,
  getRecruitersWithLimit,
  getNotifications,
  setNotificationRead,
} from '../../services';
import { showSnackbarMessage } from '../../store/messages/actions';
import { getUserAvatarSrc } from '../../utils/assets';
import {
  getHiringManagerInProject,
  getHiringManagerStatusInProject,
} from '../../utils/functions';
import { getTableRowPerPageFromStorage } from '../../utils/localStorage';
import ButtonAddMentorrOrHiriginManager from '../ButtonAddMentorOrHiringManager/ButtonAddMentorrOrHiriginManager';
import { styles } from './AssignHiringManagerToProjectButton.styles';

const AssignHiringManagerToProjectButton = ({ projectDetails, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [choiceButtonStatus, setChoiceButtonStatus] = useState(false);

  const loadRecruiters = async (
    show,
    limit = 12,
    offset = 0,
    filters = null
  ) => {
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(props.match.path) || 12
      : limit;
    let params = {
      filters,
      orderBy: {
        createdAt: 'desc',
      },
    };
    await props.getRecruitersWithLimit(limitToSearch, offset, params);
  };

  const handleClickOpen = async () => {
    setIsLoading(true);
    await loadRecruiters();
    setIsOpen(true);
    setIsLoading(false);
  };

  const handleSelect = async recruiterId => {
    await setChoiceButtonStatus(true);

    const body = {
      hiringManagerId: recruiterId,
      hiringManagerStatus: 1,
    };
    const { t } = props;

    ProjectV2Service.editProjectV2HiringManager(projectDetails.id, body)
      .then(() => {
        setChoiceButtonStatus(false);
        setIsOpen(false);

        props.showSnackbarMessage(
          t('project:form.addHiringManagerInfoSuccess')
        );
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        props.showSnackbarMessage(t`candidate:show.unableToAssign`);
      });
  };

  const handleRemoveHiringManager = async hiringManagerId => {
    const { t } = props;

    const body = {
      hiringManagerId: hiringManagerId,
      hiringManagerStatus: 0,
    };
    ProjectV2Service.editProjectV2HiringManager(projectDetails.id, body)
      .then(() => {
        setIsOpen(false);
        props.showSnackbarMessage(
          t('project:form.removeHiringManagerFromProject')
        );
        window.location.reload();
      })
      .catch(error => {
        props.showSnackbarMessage(t`candidate:show.unableToAssign`);
        console.log(error);
      });
  };

  const handleChooseOption = (idOption, hiringManager) => async () => {
    const { t, setNotificationRead, getNotifications, notifications } = props;

    const body = {
      hiringManagerId: hiringManager.id,
      hiringManagerStatus: idOption,
    };

    const projectDetailsId = projectDetails.id;
    const findNotification = find(notifications, function (n) {
      const { project = {} } = n.data;
      return project.id === projectDetailsId;
    });
    await setNotificationRead(findNotification.id);
    await ProjectV2Service.editProjectV2HiringManager(projectDetails.id, body);

    await getNotifications();
    props.showSnackbarMessage(
      t(idOption === 2 ? 'project:form.acceptByHm' : 'project:form.rejectByHm')
    );
    window.location.reload();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const getContent = recruiter => {
    const {
      t,
      currentUser: { id },
    } = props;

    const avatar = getUserAvatarSrc(recruiter);
    const {
      permissions: { isOwner },
      projectHiringManagers,
    } = projectDetails;

    const hiringManager = getHiringManagerInProject(
      projectHiringManagers,
      recruiter
    );
    let secondaryText = {};
    if (hiringManager) {
      secondaryText = t(
        getHiringManagerStatusInProject(hiringManager.hiringManagerStatus)
      );
    } else {
      secondaryText =
        recruiter &&
        recruiter.industry &&
        t('project:field.industry') + ': ' + recruiter.industry;
    }

    return (
      recruiter &&
      recruiter.id !== id && (
        <ListItem key={recruiter.id}>
          <Avatar
            src={avatar}
            aria-label={recruiter.firstName + ' ' + recruiter.lastName}
          ></Avatar>
          <ListItemText
            primary={recruiter.firstName + ' ' + recruiter.lastName}
            secondary={secondaryText}
          />
          {hiringManager ? null : (
            <Button
              disabled={choiceButtonStatus}
              onClick={handleSelect.bind(this, recruiter.id)}
              size="small"
              variant="contained"
              color="primary"
            >
              {t('action.choose')}
            </Button>
          )}
          {hiringManager !== null &&
            recruiter.id === hiringManager.id &&
            isOwner && (
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => handleRemoveHiringManager(hiringManager.id)}
                  aria-label="Remove Hiring Manager"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
        </ListItem>
      )
    );
  };

  const getRecruiterContent = recruiter => {
    return getHiringManagerInProject(
      projectDetails.projectHiringManagers,
      recruiter
    )
      ? null
      : getContent(recruiter);
  };

  const getWrapper = options => {
    let { content, pagination, filtering } = options;
    const { t } = props;
    let hiringManagers = projectDetails.projectHiringManagers.map(
      hiringManager => getContent(hiringManager.recruiter)
    );

    return (
      <React.Fragment>
        <ExpansionComponent
          title={t('filters.name')}
          textOnTop={t('filters.description')}
        >
          {filtering}
        </ExpansionComponent>
        <div className={props.classes.hiringManagerSelected}>
          {hiringManagers !== null &&
            hiringManagers.map(item => {
              <List key={item.id} component={'ul'}>
                {item}
              </List>;
            })}
        </div>
        <Divider />
        <DialogContent>
          <List component={'nav'}>{content}</List>
        </DialogContent>
        {pagination}
      </React.Fragment>
    );
  };

  const handleClickRedirect = (recruiterID, projectID, position) => {
    props.history.push({
      pathname: PATHS.RECRUITER.SHOW.replace(':id', recruiterID),
      state: {
        subject: `${t`candidate:show.project`}: ${projectID} ${position}`,
      },
    });
  };

  const getHmActionButton1 = (
    hMStatus,
    ishMAssignedToProject,
    hiringManager
  ) => {
    const { classes, t, currentUser } = props;
    const {
      position,
      permissions: { isHiringManager, isOwner, isMentor, isCooperator },
      id,
    } = projectDetails;
    let showStatus = 0;
    let avatar = null;
    let showButton = true;
    if (
      ((isCooperator && ishMAssignedToProject) || isHiringManager) &&
      !isOwner
    ) {
      showButton = false;
    }
    if (
      hMStatus === 1 &&
      ishMAssignedToProject &&
      hiringManager.id === currentUser.id
    ) {
      showStatus = 1;
    } else if (hMStatus === 1 && ishMAssignedToProject) {
      showStatus = 3;
      avatar = getUserAvatarSrc(hiringManager);
    } else if (hMStatus === 2 && ishMAssignedToProject) {
      showStatus = 2;
      avatar = getUserAvatarSrc(hiringManager);
    }

    return (
      <React.Fragment>
        {(showStatus === 2 || showStatus === 3) && (
          <React.Fragment>
            <Typography className={classes.cardTopSectionTitle}>
              {t('project:field.hiringManager')}
            </Typography>
            {showButton && (
              <Typography
                onClick={!isHiringManager ? handleClickOpen : undefined}
                className={classes.cardTopChange}
              >
                {t`candidate:show.change`}
              </Typography>
            )}
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  title: classes.titleCardHeader,
                  subheader: classes.titleCardSubHeader,
                  avatar: classes.avatarTitleCardHeader,
                }}
                onClick={() =>
                  handleClickRedirect(hiringManager.id, id, position)
                }
                avatar={
                  <Avatar className={classes.cardHeaderAvatar} src={avatar} />
                }
                title={hiringManager.firstName + ' ' + hiringManager.lastName}
                subheader={t(getHiringManagerStatusInProject(hMStatus))}
              />
            </Card>
          </React.Fragment>
        )}

        {showStatus === 1 && (
          <React.Fragment>
            <Typography
              className={classes.infoText}
              variant="subtitle1"
              gutterBottom
            >
              {t`candidate:show.hmInvitation`}
              <br />
              {t`candidate:show.hmQuestion`} {position} ?
            </Typography>
            <div className={classes.toggleContainer}>
              <ToggleButtonGroup exclusive>
                <ToggleButton
                  className={classes.buttonOptionsAccept}
                  value={2}
                  onClick={handleChooseOption(2, hiringManager)}
                >
                  {t('core:action.yesImApproving')}
                </ToggleButton>
                <ToggleButton
                  className={classes.buttonOptionsReject}
                  value={0}
                  onClick={handleChooseOption(0, hiringManager)}
                >
                  {t('core:action.noImRejecting')}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </React.Fragment>
        )}

        {showStatus === 0 && (isOwner || isMentor) && (
          <ButtonAddMentorrOrHiriginManager handleClickOpen={handleClickOpen} />
        )}
      </React.Fragment>
    );
  };

  const handleReloadElements = ({ limit, offset, filters, orderBy }) => {
    return loadRecruiters(undefined, limit, offset, filters, orderBy);
  };

  const {
    currentUser: { id },
    t,
    currentRecruitersLimitedList,
    currentRecruitersLimitedListCounter,
  } = props;

  const hiringManager = getHiringManagerInProject(
    projectDetails.projectHiringManagers,
    props.currentUser
  );

  const hiringManagerStatus = hiringManager
    ? hiringManager.hiringManagerStatus
    : null;

  const {
    isOwner = false,
    isMentor = false,
    isCooperator = false,
    isSuperUser = false,
  } = projectDetails.permissions;

  let isHmAssignedToProject = false;
  if (
    hiringManager &&
    (id === hiringManager.id ||
      isOwner ||
      isMentor ||
      isCooperator ||
      isSuperUser)
  ) {
    isHmAssignedToProject = true;
  }

  const getHmActionButton = getHmActionButton1(
    hiringManagerStatus,
    isHmAssignedToProject,
    hiringManager
  );

  const sortingOptions = [
    {
      name: 'id',
      label: t('project:field.id'),
    },
  ];

  const defaultSorting = {};

  const filteringOptions = [
    {
      key: 'firstName',
      type: 'text',
      name: t('recruiter:field.firstName'),
    },
    {
      key: 'lastName',
      type: 'text',
      name: t('recruiter:field.lastName'),
    },
  ];
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    !projectDetails.closedStatus && (
      <React.Fragment>
        {getHmActionButton}
        <Dialog open={isOpen} onClose={handleClose} aria-labelledby="hm-dialog">
          <DialogTitle id="hm-dialog">
            {t('project:show.selectHiringManager')}
          </DialogTitle>
          {isLoading ? (
            <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
          ) : (
            <ElementsListAsync
              storageKey={props.match.path}
              rowsPerPage={getTableRowPerPageFromStorage(props.match.path)}
              handleReloadElements={handleReloadElements}
              load={isLoading}
              getContent={getRecruiterContent}
              getWrapper={getWrapper}
              sortingOptions={sortingOptions}
              defaultSorting={defaultSorting}
              filteringOptions={filteringOptions}
              elements={currentRecruitersLimitedList}
              elementsCount={currentRecruitersLimitedListCounter}
              scrollUp={false}
            />
          )}
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {t('action.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    notifications: store.notifications.list,
    currentRecruitersLimitedList: store.recruiters.currentRecruitersLimitedList,
    currentRecruitersLimitedListCounter:
      store.recruiters.currentRecruitersLimitedListCounter,
  };
};

const mapDispatchToProps = {
  getNotifications,
  setNotificationRead,
  editProject,
  getRecruitersWithLimit,
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withStyles(styles)(withTranslation()(AssignHiringManagerToProjectButton))
  )
);
