import {
  CardHeader,
  Avatar,
  Card,
  Grid,
  Typography,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { getProjects } from 'shared/services/projectService';
import ChartsProject from './ChartsProject';
import DataItemProject from './DataItemProject';

const styles = theme => ({
  cardSkeleton: {
    width: 'auto',
    margin: 20,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  },
  cardHeader: {
    paddingTop: '0px',
  },
  avatar: {
    width: 70,
    height: 70,
  },
  cardContent: {
    paddingBottom: '0px !important',
  },
  addIcon: {
    color: theme.palette.jobllegroBlue,
    fontSize: 30,
  },
  avatarEmpty: {
    width: 70,
    height: 70,
    background: '#eee',
    transition: 'all .4s',
    '&:hover': {
      background: '#f2f2f2',
    },
  },
  emptyTableContent: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  chartsWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapperSection: {
    borderBottom: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: '40px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& > div > p': {
      paddingLeft: '75px',
      fontSize: 12,
      color: theme.palette.jobllegroLightGrey,
      textAlign: 'center',
    },
  },
  rootBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 290,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: 100,
    },
  },
  circle: {
    color: theme.palette.jobllegroLightGrey,
  },
  circleRoot: {
    position: 'absolute',
    top: '45%',
  },
  projectContainer: {
    width: '100%',
    overflow: 'auto',
  },
  chartsContainer: {
    width: '100%',
  },
});

export class ProjectsTable extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      isLoading: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
    }
    const { currentUser, getProjects } = this.props;
    const filter = currentUser.isRecruiter
      ? { coAndMentorOnly: true }
      : { coOnly: true };

    let requests = [];
    const po = getProjects({
      limit: 3,
      poOnly: true,
      orderBy: { projectEndDate: 'asc' },
    });
    const hm = getProjects({
      limit: 3,
      hmOnly: true,
      orderBy: { projectEndDate: 'asc' },
    });
    const co = getProjects({
      limit: 3,
      confirmationStatus: '2,5',
      orderBy: { projectEndDate: 'asc' },
      ...filter,
    });
    if (currentUser.organization) {
      const organization = getProjects({
        limit: 3,
        organizationOnly: true,
        orderBy: { projectEndDate: 'asc' },
      });
      requests = [po, co, organization, hm];
    } else {
      requests = [po, co, hm];
    }
    const data = await Promise.all(requests);
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        projects: _.flatten(
          data.map(response => response.data.map(element => element))
        ),
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      classes,
      t,
      currentUser: { isRecruiter },
    } = this.props;
    const { projects, isLoading } = this.state;
    const items = _.chain(projects)
      .uniqBy('id')
      .slice(0, 3)
      .value()
      .map(project => {
        return (
          <DataItemProject key={project.id} data={project} type="projects" />
        );
      });
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rootBox}>
          {isLoading ? (
            <CircularProgress
              classes={{
                root: classes.circleRoot,
                circle: classes.circle,
              }}
            />
          ) : items.length > 0 ? (
            <React.Fragment>
              <Grid container spacing={0} className={classes.wrapperSection}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography component="p">
                    {t('dashboard:projectsTable.positionName')} /{' '}
                    {t('dashboard:projectsTable.clientName')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography component="p">
                    {t('dashboard:projectsTable.leftTime')}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.projectContainer}>{items}</div>
              <div className={classes.chartsContainer}>
                <ChartsProject />
              </div>
            </React.Fragment>
          ) : (
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.emptyTableContent}
              >
                <Typography
                  align="center"
                  style={{ fontSize: 23, fontWeight: 400 }}
                >
                  {t('dashboard:projectsTable.noActiveProjectsFound')}
                </Typography>
                <Card className={classes.cardSkeleton}>
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <Avatar
                        className={classes.avatarEmpty}
                        component={Link}
                        to={
                          isRecruiter
                            ? PATHS.PROJECT.CREATE
                            : PATHS.PROJECT.INDEX
                        }
                      >
                        <AddIcon className={classes.addIcon} />
                      </Avatar>
                    }
                    title={
                      isRecruiter
                        ? t('dashboard:projectsTable.emptyProjectsTable')
                        : t(
                            'dashboard:projectsTable.emptyProjectsTableCandidate'
                          )
                    }
                  />
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => {
  return {
    projects: store.projects.currentProjectsList,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getProjects,
};

ProjectsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  getProjects: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectsTable)));
