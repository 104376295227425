import { Button, LinearProgress, withStyles } from '@material-ui/core';
import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { enums } from 'shared/enums';
import {
  getNotifications,
  setNotificationRead,
} from 'shared/services/notificationService';
import {
  acceptInvitationProjectRecruiter,
  rejectInvitationProjectRecruiter,
} from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';

const styles = () => ({
  button: {
    minWidth: 'auto',
    padding: '0 10px 0 10px',
    fontSize: 12,
    '&:hover': {
      background: 'none',
    },
  },
});

export class ProjectRecruiterAcceptRejectButton extends Component {
  acceptInvitation = async () => {
    try {
      const {
        handleReloadElements,
        showSnackbarMessage,
        currentUser: { id: currentUserId },
        currentProject: { id: projectId, permissions = {}, owner = {} },
        recruiter: { id: recruiterId },
        t,
      } = this.props;
      await this.props.acceptInvitationProjectRecruiter(
        projectId,
        recruiterId,
        permissions.isOwner && owner.id === currentUserId ? 5 : 2
      );
      this.clearNotifications(recruiterId);
      handleReloadElements();
      showSnackbarMessage(t('recruiter:form.recruiterAcceptanceIsApproved'));
    } catch (err) {
      console.error(err);
    }
  };

  rejectInvitation = async () => {
    try {
      const {
        handleReloadElements,
        showSnackbarMessage,
        currentUser: { id: currentUserId },
        currentProject: { id: projectId, permissions = {}, owner = {} },
        recruiter: { id: recruiterId },
        t,
      } = this.props;
      await this.props.rejectInvitationProjectRecruiter(
        projectId,
        recruiterId,
        permissions.isOwner && owner.id === currentUserId ? 6 : 3
      );
      this.clearNotifications(recruiterId);
      handleReloadElements();
      showSnackbarMessage(t('recruiter:form.recruiterAcceptanceIsRejected'));
    } catch (err) {
      console.error(err);
    }
  };

  clearNotifications = async recruiterId => {
    const { getNotifications, setNotificationRead, notifications } = this.props;
    const notification = await find(notifications, function (n) {
      const { recruiter = {} } = n.data;
      return recruiter.id === recruiterId;
    });
    if (notification) {
      await setNotificationRead(notification.id);
      getNotifications();
    }
  };

  render() {
    const {
      classes,
      currentUser,
      currentProject,
      confirmationStatus,
      isLoading,
      t,
    } = this.props;
    const { owner: projectOwner = {} } = currentProject;
    let statusConfirmed = '';

    if (
      confirmationStatus ===
        enums.RECRUITERS.CONFIRMATION_STATUS.INVITED_TO_PROJECT_BY_SELF ||
      confirmationStatus ===
        enums.RECRUITERS.CONFIRMATION_STATUS.INVITED_TO_PROJECT_BY_MEMBER
    ) {
      statusConfirmed = t('project:list.recruiterStatusConfirmed_1_4');
    } else if (confirmationStatus === 2 || confirmationStatus === 5) {
      statusConfirmed = t('project:list.recruiterStatusConfirmed_2_5');
    } else if (confirmationStatus === 3 || confirmationStatus === 6) {
      statusConfirmed = t('project:list.recruiterStatusConfirmed_3_6');
    }

    const getShowButtons = () => {
      if (currentProject.closedStatus) {
        return false;
      }
      if (
        ![
          enums.RECRUITERS.CONFIRMATION_STATUS.INVITED_TO_PROJECT_BY_SELF,
          enums.RECRUITERS.CONFIRMATION_STATUS.INVITED_TO_PROJECT_BY_MEMBER,
        ].includes(confirmationStatus)
      ) {
        return false;
      }
      if (currentUser.id === projectOwner.id) {
        if (
          confirmationStatus ===
          enums.RECRUITERS.CONFIRMATION_STATUS.INVITED_TO_PROJECT_BY_SELF
        ) {
          return true;
        }
      }
      return false;
    };

    const showStatusButton = getShowButtons();

    return isLoading ? (
      <LinearProgress />
    ) : (
      <React.Fragment>
        {statusConfirmed}
        {showStatusButton && (
          <React.Fragment>
            <Button
              className={classes.button}
              color="secondary"
              onClick={this.rejectInvitation}
            >
              <CloseIcon />
              {t('action.reject')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={this.acceptInvitation}
            >
              <DoneIcon />
              {t('action.acceptance')}
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  acceptInvitationProjectRecruiter,
  rejectInvitationProjectRecruiter,
  getNotifications,
  setNotificationRead,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    currentProject: store.projects.currentProject,
    notifications: store.notifications.list,
  };
};

ProjectRecruiterAcceptRejectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  confirmationStatus: PropTypes.number.isRequired,
  handleReloadElements: PropTypes.func.isRequired,
  acceptInvitationProjectRecruiter: PropTypes.func.isRequired,
  rejectInvitationProjectRecruiter: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectRecruiterAcceptRejectButton)));
