import { Grid, Paper, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CustomIconButton from '../../../components/CustomIconButton';
import PageHeader from '../../../components/PageHeader';
import RecruiterMenu from '../../../modules/recruiter/recruiterMenu/RecruiterMenu';
import { Snackbar } from '../../newComponents';
import { HelpModal } from '../HelpModal';
import { styles } from './HelpRecruiterContent.styles';

const HelpRecruiterContentContent = ({
  recruiterDetails,
  offeringHelpInfo,
  t,
  classes,
  updateTable,
  currentUser,
}) => {
  const [isSnackbarSendSuccessOpen, setIsSnackbarSendSuccessOpen] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOfferingTransportInfo, setSelectedOfferingTransportInfo] =
    useState(null);
  const [
    selectedOfferingAccommodationInfo,
    setSelectedOfferingAccommodationInfo,
  ] = useState(null);

  const handleOpenEditTransport = transferInfo => {
    setSelectedOfferingTransportInfo(transferInfo);
    setIsModalOpen(true);
  };

  const handleOpenEditAccommodation = accommodationInfo => {
    setSelectedOfferingAccommodationInfo(accommodationInfo);
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (isModalOpen === false) {
      setSelectedOfferingTransportInfo(null);
      setSelectedOfferingAccommodationInfo(null);
    }
  }, [isModalOpen]);

  return (
    <Grid container className={classes.wrapper}>
      <RecruiterMenu
        itemSelected="help"
        title={'candidateDetails:anonymousRecruiterDetailsCard.menuTitle'}
        subtitle={'candidateDetails:anonymousRecruiterDetailsCard.subTitle'}
        currentRecruiter={recruiterDetails}
        currentUser={currentUser}
        setIsModalOpen={setIsModalOpen}
      />
      <HelpModal
        updateTable={updateTable}
        offeringHelpInfo={offeringHelpInfo}
        selectedOfferingTransportInfo={selectedOfferingTransportInfo}
        selectedOfferingAccommodationInfo={selectedOfferingAccommodationInfo}
        setIsModalOpen={setIsModalOpen}
        setIsSnackbarSendSuccessOpen={setIsSnackbarSendSuccessOpen}
        currentRecruiter={recruiterDetails}
        isModalOpen={isModalOpen}
      />

      <Grid
        container
        alignContent={'center'}
        className={classes.tableWrapper}
      />

      <Grid container>
        <Paper style={{ width: '100%' }}>
          <PageHeader title={t('help:help.transport')} />
          <Table stickyheader="true" className={classes.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell>{t('help:help.pickupPlace')}</TableCell>
                <TableCell>{t('help:help.destinationPlace')}</TableCell>
                <TableCell>{t('help:help.date')}</TableCell>
                <TableCell>{t('help:help.action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offeringHelpInfo && offeringHelpInfo.offeringTransportsInfo
                ? offeringHelpInfo.offeringTransportsInfo.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell align={'center'}>
                          {item.pickUpPlace}
                        </TableCell>
                        <TableCell align={'center'}>
                          {item.destinationPlace}
                        </TableCell>
                        <TableCell align={'center'}>
                          {item.periodPickUpDate}
                        </TableCell>
                        <TableCell
                          align={'right'}
                          className={classes.buttonsTableCell}
                        >
                          <CustomIconButton
                            onClick={() => handleOpenEditTransport(item)}
                            type={'edit'}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </Paper>
      </Grid>

      <Grid container style={{ marginTop: 10 }}>
        <Paper style={{ width: '100%' }}>
          <PageHeader
            title={t(
              'employmentManagementDialog:dialog2.accommodationTypeStandard'
            )}
          />
          <Table stickyheader="true" className={classes.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell>{t('modifyOrderDialog:city')}</TableCell>
                <TableCell>{t('modifyOrderDialog:paper.address')}</TableCell>
                <TableCell>{t('help:help.date')}</TableCell>
                <TableCell>{t('help:help.action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offeringHelpInfo && offeringHelpInfo.offeringAccommodationsInfo
                ? offeringHelpInfo.offeringAccommodationsInfo.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell align={'center'}>{item.city}</TableCell>
                        <TableCell align={'center'}>{item.address}</TableCell>
                        <TableCell align={'center'}>
                          {item.periodStayStartDate}
                        </TableCell>
                        <TableCell
                          align={'right'}
                          className={classes.buttonsTableCell}
                        >
                          <CustomIconButton
                            onClick={() => handleOpenEditAccommodation(item)}
                            type={'edit'}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </Paper>
      </Grid>

      <Snackbar
        open={isSnackbarSendSuccessOpen}
        onClose={() => setIsSnackbarSendSuccessOpen(false)}
        label={t`core:helpedRequestWasSend`}
      />
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(HelpRecruiterContentContent))
);
