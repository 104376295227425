import {
  Typography,
  withStyles,
  Grid,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import EditIcon from 'shared/assets/img/icons/edit.png';
import { enums } from 'shared/enums';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import { styles } from './BreakAtWork.styles';
import { getConfirmComeBackShowBtnStatus } from './utils';

const BreakAtWork = ({
  classes,
  t,
  selectedFullObject,
  dataAdded,
  showSaveBtn,
}) => {
  const [isEmploymentPeriodEdited, setIsEmploymentPeriodEdited] =
    useState(false);

  const formData = {
    breakStartDate: selectedFullObject.breakStartDate
      ? selectedFullObject.breakStartDate
      : '',
    breakEndDate: selectedFullObject.breakEndDate
      ? selectedFullObject.breakEndDate
      : '',
  };

  const minBreakDate = selectedFullObject.employmentStartDate
    ? selectedFullObject.employmentStartDate
    : moment().format('YYYY-MM-DD');

  const maxBreakDate = selectedFullObject.employmentEndDate
    ? selectedFullObject.employmentEndDate
    : moment().format('YYYY-MM-DD');

  const validationSchema = yup.object({
    breakStartDate: yup
      .date()
      .min(
        minBreakDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minBreakDate}`
      )
      .max(
        maxBreakDate,
        `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${maxBreakDate}`
      )
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
    breakEndDate: yup
      .date()
      .min(
        minBreakDate,
        `${t`employmentManagementDialog:dialog6.minBreakDate`} ${minBreakDate}`
      )
      .max(
        maxBreakDate,
        `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${maxBreakDate}`
      )
      .required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({ breakStartDate, breakEndDate }) => {
      if (moment(breakStartDate).isAfter(moment(breakEndDate), 'day')) {
        formik.setFieldError('breakStartDate', t`ats2ats3:validation.date`);
        return;
      }
      const body = {
        breakStartDate,
        breakEndDate,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };
      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_BREAK
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => console.log(error));
    },
  });

  const handleConfirmComeback = () => {
    const body = {
      employmentManagementHistories: [
        {
          candidate: selectedFullObject.candidate,
          projectCandidate: selectedFullObject.projectCandidate,
        },
      ],
      confirmStatus: true,
    };
    EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
      body,
      enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS_CONFIRM_COMEBACK.WORK_BREAK
    )
      .then(() => {
        dataAdded();
      })
      .catch(error => console.log(error));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.bgBlue}>
        <div className={classes.container}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="body1">{t`employmentManagementDialog:dialog6.dateInfo`}</Typography>
            </Grid>
            <Grid item>
              <img
                src={EditIcon}
                alt="edit"
                className={
                  isEmploymentPeriodEdited ? classes.hidden : classes.editBtn
                }
                onClick={() => setIsEmploymentPeriodEdited(true)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.dates}>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <PowerSettingsNew className={classes.powerOnBtn} />
                </Grid>
                <Grid item>
                  {isEmploymentPeriodEdited ? (
                    <TextField
                      type="date"
                      formik={formik}
                      name="breakStartDate"
                      variant="transparent"
                    />
                  ) : (
                    <div className={classes.breakStartDate}>
                      <Typography variant="h5" className={classes.date}>
                        {formik.values.breakStartDate || t`assign:noData`}
                      </Typography>
                      {!formik.isValid && (
                        <FormHelperText
                          className={classes.error}
                        >{t`employmentManagementDialog:dialog2.validation.corsBorderDate`}</FormHelperText>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                </Grid>
                <Grid item>
                  {isEmploymentPeriodEdited ? (
                    <TextField
                      type="date"
                      formik={formik}
                      name="breakEndDate"
                      variant="transparent"
                    />
                  ) : (
                    <Typography variant="h5" className={classes.date}>
                      {formik.values.breakEndDate || t`assign:noData`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {getConfirmComeBackShowBtnStatus(formik.values.breakEndDate) &&
              selectedFullObject.employmentStatus ===
                enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
                  .EMPLOYMENT_STATUS_BREAK && (
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent={'center'}
                  >
                    <div className={classes.saveBtnDiv}>
                      <Button
                        onClick={handleConfirmComeback}
                        className={classes.comebackBtn}
                      >
                        {t`employmentManagementDialog:dialog6:confirmComeback`}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </div>
      </div>
      {showSaveBtn && (
        <div className={classes.saveBtnDiv}>
          <Button type="submit" className={classes.saveBtn}>
            {t`employmentManagementDialog:saveBtn`}
          </Button>
        </div>
      )}
    </form>
  );
};

export default withStyles(styles)(withTranslation()(BreakAtWork));
