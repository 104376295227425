import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { enums } from 'shared/enums';
import { Select } from 'shared/newComponents';
import Button from 'shared/newComponents/Button/Button';
import TextField from 'shared/newComponents/TextField';
import CitySeoService from 'shared/services/citySeo.service';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import CustomDialog from '../CustomDialog';

function AddCitySeoForm({ citySeoData, isOpen, onClose, citSeoAdded, t }) {
  const formData = {
    name: citySeoData ? citySeoData.name : '',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string()
      .strict(false)
      .trim()
      .required(t`adminCitySeo:provideName`),
    urlType: Yup.number().required(t`adminCitySeo:property`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationShema,
    onSubmit: (values, actions) => {
      const data = {
        name: values.name,
        urlType: values.urlType,
      };
      if (citySeoData) {
        CitySeoService.replaceCitySeo(citySeoData.id, data)
          .then(() => {
            citSeoAdded();
          })
          .catch(error => {
            console.log(error);
            const response = error.response;
            const { status } = response;
            if (status === 409) {
              alert(t`adminCitySeo:taken`);
            } else {
              alert(t`adminCitySeo:error`);
            }
          });
      } else {
        CitySeoService.addCitySeo(data)
          .then(() => {
            citSeoAdded();
          })
          .catch(error => {
            console.log(error);
            const response = error.response;
            const { status } = response;
            if (status === 409) {
              alert(t`adminCitySeo:taken`);
            } else {
              alert(t`adminCitySeo:error`);
            }
          });
      }
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`adminCitySeo:city`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid container>
                <TextField
                  label={t`adminCitySeo:name`}
                  name={'name'}
                  formik={formik}
                />
              </Grid>
              <Grid container>
                <Select
                  formik={formik}
                  name={'urlType'}
                  label={t`adminCitySeo:propertyForPage`}
                  items={getSelectItemsTranslate(enums.URL_SEO_TYPES, t)}
                />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t`adminCitySeo:save`} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
}

export default connect()(withTranslation()(AddCitySeoForm));
