export const styles = () => ({
  container: { padding: '0 40px' },
  arrivalDate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '35px 0',
  },
  arrivalTxt: { color: '#000' },
  dateDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  date: { opacity: '70%', fontWeight: 600 },
  hidden: { visibility: 'hidden' },
  editIcon: { marginLeft: 30, cursor: 'pointer' },
  btns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 102,
  },
  quitBtn: {
    color: '#F41313',
    borderRadius: 30,
    borderColor: '#F41313',
    height: 50,
    width: 220,
    '&:hover': {
      background: '#F41313',
      color: '#fff',
    },
  },
  arrivedBtn: {
    width: 220,
    height: 50,
    color: '#fff',
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  newArrivalDate: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 95,
  },
  datePicker: {
    marginLeft: 20,
  },
  bgBlue: { background: '#E5F0FF' },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
});
