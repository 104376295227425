import { createReducer } from '@reduxjs/toolkit';
import {
  authAction,
  isFetchingAction,
  setCurrentUserAction,
  logoutAction,
  setConfigAction,
  setConfigListAction,
  setLastPageAction,
} from './actions';

const initialState = {
  token: null,
  isFetching: false,
  isAuthorized: false,
  currentUser: {},
  config: {},
  configList: {},
  lastPage: null,
};

export default createReducer(initialState, builder =>
  builder
    .addCase(authAction, (state, action) => {
      state.token = action.payload;
      state.isAuthorized = true;
    })
    .addCase(isFetchingAction, (state, action) => {
      state.isFetching = action.payload;
    })
    .addCase(setCurrentUserAction, (state, action) => {
      state.currentUser = action.payload;
      state.isAuthorized = true;
    })
    .addCase(logoutAction, (state, action) => {
      state.token = null;
      state.isAuthorized = false;
      state.currentUser = {};
    })
    .addCase(setConfigAction, (state, action) => {
      state.config = action.payload;
    })
    .addCase(setConfigListAction, (state, action) => {
      state.configList = action.payload;
    })
    .addCase(setLastPageAction, (state, action) => {
      state.lastPage = action.payload;
    })
);
