export const validate = (values, props) => {
  const { t } = props;
  const errors = {};

  if (!values.email) {
    errors.email = t('form.error.canNotBeEmpty');
  }

  if (!values.password) {
    errors.password = t('form.error.canNotBeEmpty');
  }

  return errors;
};
