import {
  Hidden,
  IconButton,
  CardContent,
  Divider,
  Typography,
  Tooltip,
  Grid,
  withStyles,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  ArrowUpward as ArrowIcon,
  AccessTime as AccessTimeicon,
  AccountCircle as AccountCircleIcon,
  Edit as EditIcon,
  Home as HomeIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { enums } from 'shared/enums';
import { editProjectStageCandidates } from 'shared/services';
import ProjectEmploymentProcessService from 'shared/services/projectCandidateEmploymentProcess.service';
import { getEnumItemName } from 'shared/utils/assets';
import { getClockDate, isShorForm } from 'shared/utils/functions';
import { getAvailabilityFontStyle } from './utils/utils';

const styles = theme => ({
  cardContent: {
    paddingTop: 8,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: '8px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: '12px !important',
    },
  },
  textBottom: {
    color: '#808080',
    fontSize: 15,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    textAlign: 'left',
  },

  textBottomBlue: {
    color: '#148EFF',
    fontSize: 15,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    textAlign: 'left',
  },
  textMiddle: {
    color: '#52a3fc',
    fontSize: 15,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  textMiddleLocked: {
    color: '#808080',
  },
  acceptButton: {
    width: '20px',
    height: '20px',
    padding: 0,
  },
  bottomButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    height: 28,
  },
});

export const AtsCardContent = props => {
  const {
    classes,
    project = {},
    handleOpenCandidateInProject,
    parseCandidatesList,
    currentUser: { isRecruiter },
    obj,
    handleOpenAtsModal,
    t,
  } = props;

  const rejectCandidate = async obj => {
    ProjectEmploymentProcessService.rejectProjectEmploymentProcess(obj.id);

    await parseCandidatesList();
  };

  const restoreCandidate = async obj => {
    ProjectEmploymentProcessService.restoreProjectEmploymentProcess(obj.id);
    await parseCandidatesList();
  };

  const { stage = {}, candidate = {}, owner = {} } = obj;

  const switchUser = obj.isRejected ? (
    <ArrowIcon style={{ fontSize: 18 }} onClick={() => restoreCandidate(obj)} />
  ) : (
    <CloseIcon style={{ fontSize: 18 }} onClick={() => rejectCandidate(obj)} />
  );
  const candidateDateStage = obj.isRejected
    ? obj[`stage${obj.stageNumber}DateReject`]
    : obj[`stage${obj.stageNumber}Date`];

  const getStageInformation = obj => {
    if (!obj.candidate.userEmploymentProcess) {
      return t`general.noInformation`;
    }
    return t([
      getEnumItemName(
        enums.EMPLOYMENT_TYPE_REQUEST_SHORT,
        obj.candidate.userEmploymentProcess.employmentTypeRequest
      ),
    ]);
  };

  const stageInformation = getStageInformation(obj);

  const redirectToRecruiterPage = recruiterId => {
    props.history.push(PATHS.RECRUITER.SHOW.replace(':id', recruiterId));
  };

  const btnActionPermission = () => {
    const { permissions = {} } = project;
    const { isMentorCoordinator = false } = permissions;

    return !isMentorCoordinator;
  };
  const handleShowBtn = obj => {
    if (!btnActionPermission()) {
      return false;
    }

    if (obj.isRejected) {
      return false;
    }
    if (obj.stageNumber === 2) {
      return true;
    }
    return (
      obj.candidate.userEmploymentProcess &&
      !isShorForm(obj.candidate.userEmploymentProcess.employmentTypeRequest)
    );
  };
  const showEditBtn = handleShowBtn(obj);

  const clockIconDate = getClockDate(obj.candidate, obj.stageNumber);

  return (
    <Hidden only="xs">
      <CardContent className={classes.cardContent}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Typography
              component="p"
              className={classes.textBottom}
              style={getAvailabilityFontStyle(candidateDateStage)}
            >
              {candidateDateStage}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Typography component="p" className={classes.textBottomBlue}>
              {stageInformation}
            </Typography>
          </Grid>
        </Grid>
        {!stage.locked && isRecruiter && (
          <React.Fragment>
            <Divider />
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.bottomButtons}
              >
                {candidate.isForeignerInEmploymentArea && (
                  <Tooltip
                    id="tooltip-top"
                    title={t`atsSubMenu:candidateIn`}
                    placement="top"
                  >
                    <IconButton
                      style={{ width: '20px', height: '20px', padding: 0 }}
                    >
                      <HomeIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                <div>
                  <Typography component="p">{obj.owner.lastName}</Typography>
                </div>
                <Tooltip
                  id="tooltip-top"
                  title={t`atsSubMenu:recruiterInProj`}
                  placement="top"
                >
                  <IconButton
                    onClick={() => redirectToRecruiterPage(obj.owner.id)}
                    style={{ width: '20px', height: '20px', padding: 0 }}
                  >
                    <AccountCircleIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip id="tooltip-top" title={clockIconDate} placement="top">
                  <IconButton
                    onClick={() =>
                      handleOpenCandidateInProject(
                        candidate,
                        owner,
                        obj.stageNumber
                      )
                    }
                    style={{ width: '20px', height: '20px', padding: 0 }}
                  >
                    <AccessTimeicon style={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>

                {!project.closedStatus &&
                  obj.confirmationStatus !== 1 &&
                  (candidate.permissions.isOwner ||
                    project.permissions.isOwner ||
                    project.permissions.isProjectOrganizationOwner ||
                    project.permissions.isSuperUser ||
                    (project.permissions.isMentor &&
                      !project.permissions.isMentorCoordinator &&
                      obj.stageNumber <=
                        enums.ATS_EMPLOYMENT_PROCESS_STAGES
                          .STAGE_CANDIDATE_GET_DOCUMENTS) ||
                    (project.permissions.isMentor &&
                      project.permissions.isMentorLegalization)) && (
                    <Tooltip
                      title={
                        obj.isRejected
                          ? t`candidate:show.bringBack`
                          : t`candidate:show.rejectCandidate`
                      }
                      placement="top"
                    >
                      <IconButton
                        style={{ padding: 0 }}
                        className={classes.acceptButton}
                      >
                        {switchUser}
                      </IconButton>
                    </Tooltip>
                  )}
                {!project.closedStatus &&
                  obj.stageNumber > 1 &&
                  showEditBtn &&
                  (candidate.permissions.isOwner ||
                    project.permissions.isOwner ||
                    project.permissions.isProjectOrganizationOwner ||
                    project.permissions.isSuperUser ||
                    (project.permissions.isMentor &&
                      project.permissions.isMentorCoordinator &&
                      obj.stageNumber <=
                        enums.ATS_EMPLOYMENT_PROCESS_STAGES
                          .STAGE_CANDIDATE_GET_DOCUMENTS) ||
                    (project.permissions.isMentor &&
                      project.permissions.isMentorLegalization)) && (
                    <Tooltip
                      title={t`candidate:form.editCandidate`}
                      placement="top"
                    >
                      <IconButton
                        onClick={() =>
                          handleOpenAtsModal(obj, obj.stageNumber, true)
                        }
                        style={{ width: '20px', height: '20px', padding: 0 }}
                      >
                        <EditIcon style={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </CardContent>
    </Hidden>
  );
};

AtsCardContent.propTypes = {
  project: PropTypes.object.isRequired,
  obj: PropTypes.object.isRequired,
  handleOpenCandidateInProject: PropTypes.func.isRequired,
  parseCandidatesList: PropTypes.func.isRequired,
  editProjectStageCandidates: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  editProjectStageCandidates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(withRouter(AtsCardContent))));
