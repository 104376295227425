import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckBox as EnabledIcon,
  Refresh as RefreshIcon,
  CheckBoxOutlineBlank as DisabledIcon,
  Done as DoneIcon,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
  ArrowRight as RightIcon,
} from '@material-ui/icons';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { PATHS } from 'config';
import { enums } from 'shared/enums';
import Select from 'shared/newComponents/Select';
import { AuthService, RecruiterV2Service } from 'shared/services';
import { refreshToken } from 'shared/services/authService';
import { editRecruiterMentorStatus } from 'shared/services/recruiterService';
import {
  getUsersWithLimit,
  clearSelectedUsersList,
  editUser,
  deleteUser,
} from 'shared/services/userService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getSelectItemsTranslate } from 'shared/utils/assets';
import {
  getTableRowPerPageFromStorage,
  setLocalStorage,
} from 'shared/utils/localStorage';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog/';
import PageHeader from '../../../components/PageHeader';
import CsvUpload from '../../../components/asset/CsvUpload';
import ElementsListAsync from '../../../components/list/ElementsListAsync';
import AdminUserHead from './AdminUserHead';

const AdminUserView = ({
  storageKey,
  elements,
  elementsCount,
  t,
  history,
  editUser,
  showSnackbarMessage,
  deleteUser,
  refreshToken,
  editRecruiterMentorStatus,
  match,
  getUsersWithLimit,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [deleteConfirmationOpened, setDeleteConfirmationOpened] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [options, setOptions] = useState({ limit: 12, offset: 0 });
  const [recruiterRating, setRecruiterRating] = useState(false);

  const handleSorting = async (e, property) => {
    let orderDirection = 'asc';

    if (!property) {
      return;
    }

    if (orderBy === property && order === 'asc') {
      orderDirection = 'desc';
    }

    await setOrder(orderDirection);
    await setOrderBy(property);
    handleReloadElements();
  };

  const openRemoveDialog = user => {
    setSelectedUser(user);
    setDeleteConfirmationOpened(true);
  };

  const updateUser = async (user, index) => {
    const filtredRecruiterRating = recruiterRating.filter(
      item => item.index === index
    );
    if (
      filtredRecruiterRating.length &&
      !isNaN(filtredRecruiterRating[0].value)
    ) {
      try {
        const body = user.isRecruiter
          ? {
              ...user,
              recruiterRating: filtredRecruiterRating[0].value,
            }
          : {
              id: user.id,
              recruiterRating: filtredRecruiterRating[0].value,
            };

        await editUser(body);
        const removeUpdatedRating = recruiterRating.filter(
          item => item.index !== index
        );
        const recruiterRatingArray = [...removeUpdatedRating];
        setRecruiterRating(recruiterRatingArray);
        showSnackbarMessage('Zapisano zmiany');
        handleReloadElements();
      } catch (error) {
        showSnackbarMessage('Błąd podczas zapisu informacji');
        console.log(error);
      }
    }
  };

  const handleRemove = async isOk => {
    if (isOk) {
      await deleteUser(selectedUser.id);
      handleReloadElements();
    }
    setDeleteConfirmationOpened(false);
  };

  const handleRefreshToken = async user => {
    await refreshToken(user.id);
    handleReloadElements();
  };

  const handleToggle = async user => {
    const body = user.isRecruiter
      ? {
          ...user,
          enabled: !user.enabled,
        }
      : {
          id: user.id,
          enabled: !user.enabled,
        };
    await editUser(body);
    handleReloadElements();
  };

  const handleToggleNotification = async user => {
    const body = user.isRecruiter
      ? {
          ...user,
          isNotificationNewProjectEnabled:
            !user.isNotificationNewProjectEnabled,
        }
      : {
          id: user.id,
          isNotificationNewProjectEnabled:
            !user.isNotificationNewProjectEnabled,
        };

    await editUser(body);
    handleReloadElements();
  };

  const handleToggleRandomMentorFormProject = async user => {
    const body = user.isRecruiter
      ? {
          ...user,
          isRandomMentorFormProject: !user.isRandomMentorFormProject,
        }
      : {
          id: user.id,
          isRandomMentorFormProject: !user.isRandomMentorFormProject,
        };

    await editUser(body);
    handleReloadElements();
  };

  const handleSetMentorStatus = async (user, idStatus) => {
    await editRecruiterMentorStatus({
      id: user.id,
      mentorStatus: idStatus,
    });
    handleReloadElements();
  };

  const loadData = async (options = {}) => {
    const { limit, offset, filters } = options || options;
    const limitToSearch = !limit
      ? getTableRowPerPageFromStorage(match.url) || 12
      : limit;

    const params = {
      filters,
      orderBy: {
        [orderBy]: order,
      },
    };
    await getUsersWithLimit(limitToSearch, offset, params);
  };

  const handleReloadElements = (opts = false) => {
    if (opts) {
      setOptions(opts);
    }
    return loadData(opts || options);
  };

  const useComponentWillMount = fn => {
    const willMount = useRef(true);
    if (willMount.current) fn();
    willMount.current = false;
  };

  useComponentWillMount(() => {
    handleReloadElements();
  });

  const getWrapper = ({ content, filtering, pagination }) => {
    return (
      <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
        <PageHeader title="Lista użytkowników" />
        <Paper style={{ padding: 20 }} elevation={0}>
          {filtering}
        </Paper>
        <CsvUpload
          filesLimit={1}
          reloadData={() => handleReloadElements()}
          acceptedFiles={['.csv']}
          maxSize={1000000}
        >
          <Button>Ustaw koleność rekrutera przez CSV</Button>
        </CsvUpload>
        <Table>
          <TableHead>
            <AdminUserHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleSorting}
            />
          </TableHead>
          <TableBody>{content}</TableBody>
          <TableFooter>
            <AdminUserHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleSorting}
            />
          </TableFooter>
        </Table>
        {pagination}
        <ConfirmationDialog
          open={deleteConfirmationOpened}
          titleText="Usuń użytkownika"
          onClose={handleRemove}
          okButtonText="Usuń"
          cancelButtonText="Anuluj"
        >
          Czy na pewno usunąć?
        </ConfirmationDialog>
      </Paper>
    );
  };

  const handleChange = (event, index) => {
    const filtredRecruiterRating = recruiterRating.filter(
      item => item.index !== index
    );
    const recruiterRatingArray = [
      ...filtredRecruiterRating,
      { value: event.target.value, index: index },
    ];
    setRecruiterRating(recruiterRatingArray);
  };

  const changeUser = user => {
    AuthService.getUserToken(user.id)
      .then(({ data }) => {
        const token = data.token;
        AuthService.logout().then(() => {
          setLocalStorage('token', token);
          history.push({
            pathname: PATHS.DASHBOARD.INDEX,
          });
          window.location.reload();
        });
      })
      .catch(error => {
        alert('Błąd generowania tokena');
      });
  };

  useEffect(() => {
    if (elements.length) {
      formik.setFieldValue(
        'mentorType',
        elements.map(item => item.mentorType)
      );
    }
  }, [elements]);

  const formData = {
    mentorType: [],
  };

  const formik = useFormik({
    initialValues: formData,
  });

  const getContent = (user, index = null) => {
    const name = `user[${index}]recruiterRating`;
    const nameFormik = `mentorType[${index}]`;

    return (
      <TableRow hover key={user.id}>
        <TableCell align="right" size="small" style={{ textAlign: 'center' }}>
          {user.id}
        </TableCell>
        <TableCell size="small">{user.email}</TableCell>
        <TableCell size="small">{user.firstName}</TableCell>
        <TableCell size="small">{user.lastName}</TableCell>
        <TableCell size="small">{user.createdAt}</TableCell>
        <TableCell size="small" style={{ textAlign: 'right' }}>
          {user.isRecruiter ? (
            <form
              key={user.id + 'form'}
              onChange={event => handleChange(event, index)}
              style={{
                width: '3em',
                float: 'right',
                marginRight: '6em',
              }}
            >
              {/*name={`${index}.recruiterRating`}*/}
              <Field
                name={name}
                component={TextField}
                type="number"
                fullWidth
              />
            </form>
          ) : null}
        </TableCell>
        <TableCell size="small">
          {user.isRecruiter ? 'true' : 'false'}
        </TableCell>
        <TableCell size="small">
          {user.employerStatus === 1 || user.employerStatus === 2
            ? 'true'
            : 'false'}
        </TableCell>
        <TableCell size="small" style={{ textAlign: 'center' }}>
          {user.isRecruiter ? (
            user.isNotificationNewProjectEnabled ? (
              <IconButton
                style={{ padding: 0 }}
                onClick={() => handleToggleNotification(user)}
              >
                <EnabledIcon />
              </IconButton>
            ) : (
              <IconButton
                style={{ padding: 0 }}
                onClick={() => handleToggleNotification(user)}
              >
                <DisabledIcon />
              </IconButton>
            )
          ) : null}
        </TableCell>
        <TableCell size="small">
          {user.mentorStatus === 2 && (
            <Select
              onChange={event => {
                formik.setFieldValue(nameFormik, event.target.value);
                const mentorId = user.id;
                const body = { mentorType: event.target.value };
                RecruiterV2Service.updateMentorType(mentorId, body).catch(
                  error => {
                    console.log(error);
                  }
                );
              }}
              variant="transparent"
              label={t`userList:mentorType`}
              formik={formik}
              name={nameFormik}
              items={getSelectItemsTranslate(enums.MENTOR_TYPE, t)}
            />
          )}
        </TableCell>
        <TableCell size="small" style={{ textAlign: 'center' }}>
          {user.isRecruiter && user.mentorStatus === 2 ? (
            user.isRandomMentorFormProject ? (
              <IconButton
                style={{ padding: 0 }}
                onClick={() => handleToggleRandomMentorFormProject(user)}
              >
                <EnabledIcon />
              </IconButton>
            ) : (
              <IconButton
                style={{ padding: 0 }}
                onClick={() => handleToggleRandomMentorFormProject(user)}
              >
                <DisabledIcon />
              </IconButton>
            )
          ) : null}
        </TableCell>
        <TableCell size="small">
          {user.isSuperUser ? 'true' : 'false'}
        </TableCell>
        <TableCell size="small">
          {user.mentorStatus === 1 && (
            <React.Fragment>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => handleSetMentorStatus(user, 0)}
              >
                <ThumbDownIcon style={{ color: 'red' }} />
              </IconButton>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => handleSetMentorStatus(user, 2)}
              >
                <ThumbUpIcon style={{ color: 'green' }} />
              </IconButton>
            </React.Fragment>
          )}
          {user.mentorStatus === 2 && (
            <DoneIcon style={{ color: 'green', fontSize: 30 }} />
          )}
        </TableCell>
        <TableCell size="small">
          {user.confirmationToken && (
            <a
              href={
                '#' +
                PATHS.AUTH.RESET_PASSWORD.replace(
                  ':token',
                  user.confirmationToken
                )
              }
            >
              LINK
            </a>
          )}
        </TableCell>
        <TableCell size="small" style={{ textAlign: 'left' }}>
          {user.enabled ? (
            <IconButton
              style={{ padding: 0 }}
              onClick={() => handleToggle(user)}
            >
              <EnabledIcon />
            </IconButton>
          ) : (
            <IconButton
              style={{ padding: 0 }}
              onClick={() => handleToggle(user)}
            >
              <DisabledIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell size="small">
          <IconButton
            style={{ padding: 0 }}
            onClick={() => updateUser(user, index)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => openRemoveDialog(user)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => handleRefreshToken(user)}
          >
            <RefreshIcon />
          </IconButton>
          <IconButton style={{ padding: 0 }} onClick={() => changeUser(user)}>
            <RightIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const filteringOptions = [
    {
      key: 'firstName',
      type: 'text',
      name: 'Imię',
    },
    {
      key: 'lastName',
      type: 'text',
      name: 'Nazwisko',
    },
    {
      key: 'email',
      type: 'text',
      name: 'Email',
    },
    {
      key: 'isRecruiter',
      type: 'checkbox',
      name: 'Tylko rekruterzy',
      value: '1',
      default: false,
    },
    {
      key: 'isCandidate',
      type: 'checkbox',
      name: 'Tylko kandydaci',
      value: '1',
      default: false,
    },
    {
      key: 'isMentor-from',
      type: 'checkbox',
      name: 'Tylko mentorzy',
      value: '2',
      default: false,
    },
  ];

  return (
    <ElementsListAsync
      storageKey={match.url}
      rowsPerPage={getTableRowPerPageFromStorage(storageKey)}
      handleReloadElements={handleReloadElements}
      getContent={getContent}
      getWrapper={getWrapper}
      filteringOptions={filteringOptions}
      elements={elements || []}
      elementsCount={elementsCount || 0}
      scrollUp={true}
    />
  );
};

const mapStateToProps = store => {
  return {
    elements: store.users.currentUsersLimitedList,
    elementsCount: store.users.currentUsersLimitedListCounter,
    initialValues: { user: store.users.currentUsersLimitedList },
  };
};

const mapDispatchToProps = {
  getUsersWithLimit,
  clearSelectedUsersList,
  editRecruiterMentorStatus,
  editUser,
  deleteUser,
  refreshToken,
  showSnackbarMessage,
};

const AdminUserViewFormAction = reduxForm({
  form: 'adminuserviewformaction',
  enableReinitialize: true,
})(AdminUserView);

AdminUserView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  getUsersWithLimit: PropTypes.func.isRequired,
  clearSelectedUsersList: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(AdminUserViewFormAction)));
