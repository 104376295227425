import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteUser, logoutUser } from 'shared/services';
import MyOrganization from '../MyOrganization';
import RecruiterInOrganization from '../RecruiterInOrganization';
import YourOrganizations from '../YourOrganizations';
import { styles } from './LeftSide.styles';

const LeftSide = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  return (
    <Grid container>
      <MyOrganization recruiterData={recruiterData} />
      <RecruiterInOrganization recruiterData={recruiterData} />
      <YourOrganizations recruiterData={recruiterData} />
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  deleteUser,
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(LeftSide))));
