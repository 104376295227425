export const styles = () => ({
  container: { padding: 25 },
  datesContainer: { marginBottom: 35 },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  isEmploymentStartDateEdited: {
    display: 'flex',
    justifyContent: 'space-even',
  },
  date: { display: 'flex', alignItems: 'center' },
  editBtn: { cursor: 'pointer' },
  hidden: { visibility: 'hidden' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
    marginRight: 10,
  },
  containerMargin: {
    marginTop: 10,
  },
  powerOffBtn: {
    background: '#F41313',
    borderRadius: '50%',
    color: '#fff',
    marginRight: 10,
    marginLeft: 30,
  },
  verifyBtn: {
    color: '#fff',
    background: '#F41313',
    borderRadius: 30,
    '&:hover': { background: '#F41313', opacity: 0.8 },
  },
  workplace: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  accommodationDiv: { position: 'relative' },
  accommodation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  mailDiv: { display: 'flex', alignItems: 'center', marginBottom: 10 },
  mail: { marginRight: 10 },
  mobileDiv: { display: 'flex', alignItems: 'center' },
  mobile: { marginRight: 10 },
  blue: { color: '#148EFF', fontWeight: 600 },
  bold: { fontWeight: 600 },
  employmentPeriod: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  bgBlue: { background: '#E5F0FF' },
  selectLoc: { margin: '20px 0' },
  employmentStartDate: { position: 'relative' },
  error: { position: 'absolute', top: '30px', color: '#f41010' },
  accommodationError: { position: 'absolute', top: '40px', color: '#f41010' },
  header: { margin: '20px 0' },
});
