import { Grid, Paper, withStyles } from '@material-ui/core';
import { map, find, remove, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CheckboxGroup } from 'shared/newComponents';
import { WorkStyleService } from 'shared/services';
import { MODEL } from '../../ProjectCreateWizardStep2.utils';
import { styles } from './WorkStyleComponent.styles';

const WorkStyleComponent = ({ formik, t, classes }) => {
  const [workStyles, setWorkStyles] = useState([]);

  useEffect(() => {
    WorkStyleService.getWorkStyles()
      .then(({ data }) => setWorkStyles(data))
      .catch(error => console.log(error));
  }, []);

  const selectedWorkStyles = formik.values.workStyles;
  const workStyleDescriptions = map(
    remove(
      map(workStyles, item => {
        const isSelected = find(selectedWorkStyles, o => o.id === item.id);
        if (isSelected) {
          return item;
        }
      }),
      o => o !== undefined
    ),
    p => t(`workStylesDescriptions:${p.name}`)
  );

  const translateWorkStyles = workStyles.map(item => {
    return { ...item, name: `workStyles:${item.name}` };
  });

  return (
    <Grid container>
      <Grid item xs={2} style={{ minWidth: 125 }}>
        <Grid container>
          <CheckboxGroup
            formik={formik}
            items={translateWorkStyles}
            name={MODEL.workStyles}
            label={'projectCreator:step2.workStyles'}
            variant="checkbox"
            direction="column"
            iterateFrom={1}
          />
        </Grid>
      </Grid>
      <Grid item xs={9}>
        {!isEmpty(workStyleDescriptions) && (
          <Grid container>
            <Paper className={classes.workStyleDescriptionPaper}>
              <Grid container>
                {map(workStyleDescriptions, item => (
                  <Grid container key={item}>
                    <p
                      style={{
                        textAlign: 'justify',
                      }}
                    >
                      {item}
                    </p>
                    <br />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(WorkStyleComponent));
