import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'config/i18n';
import { NEW_LAYOUT_PATHS, PATHS } from 'config';
import { CONST_CONFIG } from 'shared/constants';
import SnackBar from '../components/LayoutSnackBar';
import IsAuthorized from '../components/auth/IsAuthorized';
import MainIndexView from '../modules/main';
import 'shared/assets/css/App.css';
import {
  createTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import { themeOptions } from 'config';
import Layout from 'shared/components/Layout/Layout';
import {
  authRoutes,
  getRoutesFromObject,
  manageProjectDataInLocalStorage,
} from 'shared/routes';
import { generateClassName } from 'shared/utils/config';

export const theme = createTheme(themeOptions);

const App = ({ location }) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: CONST_CONFIG.GTM.GTM_ID,
    };

    if ('https://app.jobllegro.com' === window.location.origin) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);
  useEffect(() => {
    manageProjectDataInLocalStorage(location);
  }, [location]);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Switch>
          {authRoutes.map((route, i) => (
            <Route key={`route-${i}`} {...route} />
          ))}
          <IsAuthorized>
            <Switch>
              <Route
                path={getRoutesFromObject(NEW_LAYOUT_PATHS)}
                component={Layout}
              />
              <Route path={PATHS.MAIN} component={MainIndexView} />
            </Switch>
          </IsAuthorized>
        </Switch>
        <SnackBar />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default withRouter(App);
