export const styles = () => ({
  grid: { margin: '30px 0' },
  fontError: {
    color: 'red',
  },
  bold: { fontWeight: 600 },
  asset: { fontWeight: 600, wordBreak: 'break-word' },
  editIcon: { cursor: 'pointer' },
  link: { textDecoration: 'none' },
});
