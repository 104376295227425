export const styles = () => ({
  control: {
    minHeight: 58,
    borderRadius: 30,
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    fontSize: '1rem',
    fontFamily: 'Barlow Semi Condensed, sans-serif',
    lineHeight: '1.1875em',
    alignItems: 'center',
  },
  root: { padding: 10 },
  rootLabel: { backgroundColor: '#fff', top: -2 },
  input: { borderRadius: 30 },
  baseSelect: { '&:focus': { borderRadius: 30 } },
  dropdownIcon: { paddingRight: 10 },
});
