import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'shared/assets/css/ProjectStepper.css';
import moment from 'moment';
import 'moment/locale/pl';

moment.locale('pl');

const styles = theme => ({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    marginBottom: 40,
  },
  rootMiniSecond: {
    alignItems: 'center',
    display: 'flex',
  },
  rootMini: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  titleprimary: {
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
  },
  titleseccondary: {
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'center',
  },
  number: {
    fontSize: 14,
    color: '#52a3fc',
    fontWeight: '600',
    textAlign: 'center',
  },
  numberAlert: {
    color: theme.palette.jobllegroRedAlert,
  },
  numberMini: {
    fontSize: 12,
    color: '#52a3fc',
    fontWeight: '200',
    textAlign: 'center',
    width: '100%',
  },
  numberMiniAlert: {
    color: theme.palette.jobllegroRedAlert,
  },
  rootWide: {
    justifyContent: 'space-around',
    display: 'flex',
    padding: '0px',
    flexDirection: 'row',
    color: theme.palette.jobllegroLightGrey,
    '& > span': {
      color: theme.palette.jobllegroLightGrey,
    },
  },
});

export class ProjectStepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      timeLeft: null,
      alert: false,
    };
  }

  countDifferenceBetweenDates() {
    const now = moment(new Date());
    const startDate = moment(this.props.startDate).startOf('day');
    const endDate = moment(this.props.endDate).endOf('day');

    const secondsFromStart = now.diff(startDate);
    const secondsToEnd = -now.diff(endDate);

    let timeLeft = null;
    let alertTrigger = false;
    let resultPercentage = 0;

    if (this.props.startDate === null || this.props.endDate === null) {
      return this.setState({
        percentage: resultPercentage,
        timeLeft: timeLeft,
        alert: alertTrigger,
      });
    }

    if (startDate.isBefore(now) && endDate.isAfter(now, 'day')) {
      resultPercentage =
        (secondsFromStart / (secondsFromStart + secondsToEnd)) * 100;
      timeLeft = endDate.endOf('day').toNow(true);
    } else if (startDate.isBefore(now, 'day')) {
      timeLeft = endDate.endOf('day').toNow(true);
      alertTrigger = true;
    }

    this.setState({
      percentage: resultPercentage,
      timeLeft: timeLeft,
      alert: alertTrigger,
    });
  }

  componentDidMount() {
    this.countDifferenceBetweenDates();
  }

  render() {
    const {
      classes,
      variantMini = false,
      startDate,
      t,
      type = '',
    } = this.props;
    const { percentage, timeLeft, alert } = this.state;
    return variantMini ? (
      <Grid container spacing={0} className={classes.rootMiniSecond}>
        <Grid item xs={12} sm={4} md={4} lg={4} align="center">
          {timeLeft && (
            <Typography
              component="p"
              gutterBottom
              className={classes.titleprimary}
            >
              {alert ? t('stepper.deadline') : t('stepper.remaining')}
            </Typography>
          )}
          <div className="meter" align="left">
            <div className={alert ? 'circleLeft circleAlert' : 'circleLeft'} />
            <div
              className={alert ? 'middleLine alert' : 'middleLine'}
              style={{ width: alert ? '100%' : percentage + '%' }}
            />
            <div
              className={alert ? 'circleRight circleAlert' : 'circleRight'}
            />
          </div>
          {timeLeft && (
            <Typography
              component="p"
              gutterBottom
              className={classNames(
                classes.number,
                alert && classes.numberAlert
              )}
            >
              {alert ? `-${timeLeft}` : timeLeft}
            </Typography>
          )}
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} sm={4} md={3} lg={4} align="center">
          <List>
            <ListItem style={{ padding: 0 }}>
              <ListItemText
                style={{ padding: 0 }}
                primary={t('stepper.beginning')}
                secondary={this.props.startDate}
                classes={{
                  root: type === 'wide' && classes.rootWide,
                  primary: classes.titleprimary,
                  secondary: classes.titleseccondary,
                }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} align="center">
          {timeLeft && (
            <Typography
              component="p"
              gutterBottom
              className={classes.titleprimary}
            >
              {alert ? t('stepper.deadline') : t('stepper.remaining')}
            </Typography>
          )}
          <div className="meter" align="left">
            <div className={alert ? 'circleLeft circleAlert' : 'circleLeft'} />
            <div
              className={alert ? 'middleLine alert' : 'middleLine'}
              style={{ width: alert ? '100%' : percentage + '%' }}
            />
            <div
              className={alert ? 'circleRight circleAlert' : 'circleRight'}
            />
          </div>
          {timeLeft && (
            <Typography
              component="p"
              gutterBottom
              className={classNames(
                classes.number,
                alert && classes.numberAlert
              )}
            >
              {alert ? `-${timeLeft}` : timeLeft}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={4} align="center">
          <List>
            <ListItem style={{ padding: 0 }}>
              <ListItemText
                style={{ padding: 0 }}
                primary={t('stepper.ending')}
                secondary={this.props.endDate}
                classes={{
                  root: type === 'wide' && classes.rootWide,
                  primary: classes.titleprimary,
                  secondary: classes.titleseccondary,
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  }
}

ProjectStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default withStyles(styles)(withTranslation()(ProjectStepper));
