import { Grid, LinearProgress } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import Snackbar from 'shared/newComponents/Snackbar';
import { ProjectOrderService, ProjectV2Service } from 'shared/services';
import { getLocalizationData } from 'shared/utils/functions';
import ProjectOrderViewContent from './components/ProjectOrderViewContent';

const ProjectOrderView = ({ match, t, history }) => {
  const [projectDetails, setProjectDetails] = useState({});
  const [projectOrders, setProjectOrders] = useState([]);
  const [projectAvailableToMove, setProjectAvailableToMove] = useState([]);

  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);

  const id = get(match, 'params.id');

  const redirectToDashboard = () => {
    history.push(PATHS.DASHBOARD.INDEX);
  };

  useEffect(() => {
    if (!!id) {
      ProjectV2Service.getProjectV2(id)
        .then(({ data }) => {
          setProjectDetails(data);
          loadProjectOrders(data);
          loadProjectAvailableToMove();
        })
        .catch(error => {
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              setIsSnackbarErrorOpen(true);
              setTimeout(redirectToDashboard, 2000);
            }
          }
          console.log(error);
        });
    }
  }, [id]);

  const loadProjectOrders = project => {
    return ProjectOrderService.getProjectOrderFromProject(project.id)
      .then(({ data }) => {
        setProjectOrders(prepareRows(data, project));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const loadProjectAvailableToMove = () => {
    ProjectV2Service.getProjectAvailableToMove()
      .then(({ data }) => {
        setProjectAvailableToMove(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const prepareRows = (projectOrders, project) =>
    projectOrders.map(projectOrder => {
      return {
        id: projectOrder.id,
        createdAt: projectOrder.createdAt,
        employmentStartDate: projectOrder.employmentStartDate,
        localizationString: getLocalizationData(projectOrder.localization),
        localization: projectOrder.localization,
        company: projectOrder.company,
        position: project.position,
        numberOfManCandidate: projectOrder.numberOfManCandidate,
        numberOfWomanCandidate: projectOrder.numberOfWomanCandidate,
        numberOfPairCandidate: projectOrder.numberOfPairCandidate,
        sumOfCandidates: projectOrder.sumOfCandidates,
        orderNumber: projectOrder.orderNumber,
        allCandidateInProcess: projectOrder.allCandidateInProcess,
        isActive: projectOrder.isActive,
        isConfirmed: projectOrder.isConfirmed,
        typeOfOrder: projectOrder.typeOfOrder,
      };
    });

  return isEmpty(projectDetails) ? (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
      <Snackbar
        type={'error'}
        open={isSnackbarErrorOpen}
        onClose={() => setIsSnackbarErrorOpen(false)}
        label={t('projectCreator:core.projectRejected')}
      />
    </Grid>
  ) : (
    <ProjectOrderViewContent
      projectAvailableToMove={projectAvailableToMove}
      project={projectDetails}
      projectOrders={projectOrders}
      loadProjectOrders={loadProjectOrders}
    />
  );
};

export default withRouter(withTranslation()(ProjectOrderView));
