import { Grid } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import MentorDetails from './components/MentorDetails';
import PersonalData from './components/PersonalData';
import SettlementData from './components/SettlementData';

const RecruiterPresonalDetailsCenter = ({ recruiterData, recruiterEdited }) => {
  return (
    <Grid container>
      <PersonalData
        recruiterData={recruiterData}
        recruiterEdited={recruiterEdited}
      />
      <SettlementData
        recruiterData={recruiterData}
        recruiterEdited={recruiterEdited}
      />
      <MentorDetails
        recruiterData={recruiterData}
        recruiterEdited={recruiterEdited}
      />
    </Grid>
  );
};

export default withRouter(RecruiterPresonalDetailsCenter);
