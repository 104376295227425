import { Grid, Paper, withStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { CandidateV2Service, PositionService } from 'shared/services';
import { uploadAssets } from 'shared/services/assetService';
import { setLastPage } from 'shared/services/authService';
import { addCandidate, editCandidate } from 'shared/services/candidateService';
import { CountryService } from 'shared/services/country.service';
import DepartmentService from 'shared/services/department.service';
import { IndustryCategoryService } from 'shared/services/industryCategory.service';
import {
  addProjectCandidate,
  getProjectCvHashes,
} from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import PageHeader from '../../components/PageHeader';
import AssetsUpload from '../../components/asset/AssetsUpload';
import { CandidateCreateWizardStep1 } from '../candidate/NewCandidateCreateView/components';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  }),
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
  },
  icon: {
    color: theme.palette.jobllegroBlue,
    fontSize: 16,
    cursor: 'pointer',
  },
  avatarDone: {
    backgroundColor: theme.palette.jobllegroSuccess,
  },
});

const ProjectImportCandidatesView = props => {
  const [savedCandidateIds, setSavedCandidateIds] = useState([]);
  const [backPage, setBackPage] = useState('');
  const [projectId, setProjectId] = useState(null);
  const [assets, setAssets] = useState([]);
  const [openIndex, setOpenIndex] = useState(0);

  const [countries, setCountries] = useState([]);
  const [industryCategories, setIndustryCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);

  const getCountries = () => {
    CountryService.getCountries().then(({ data }) => {
      setCountries(data);
    });
  };

  const getIndustryCategories = () => {
    IndustryCategoryService.getIndustryCategories().then(({ data }) =>
      setIndustryCategories(data)
    );
  };

  const getDepartments = () => {
    DepartmentService.getDepartments().then(({ data }) => setDepartments(data));
  };

  const getPositions = () => {
    PositionService.getPositions().then(({ data }) => {
      setPositions(data.map(position => position.position));
    });
  };

  useEffect(() => {
    const { projectId } = props.match.params;
    setProjectId(parseInt(projectId));
    setBackPage(PATHS.PROJECT.CANDIDATES.replace(':id', projectId));
    getCountries();
    getPositions();
    getIndustryCategories();
    getDepartments();
  }, []);

  const uploadAssets = async (files, assetType) => {
    const { uploadAssets } = props;
    const filesPromises = [];
    files.forEach(file => {
      filesPromises.push(uploadAssets([file], assetType));
    });
    Promise.all(filesPromises)
      .then(responses => {
        const assets = responses.map(response => {
          return response.data.shift();
        });
        setAssets(assets);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const backAction = () => {
    props.history.push(backPage);
  };

  const { classes, t } = props;

  const formStepTouched = formStep => !isEmpty(formStep.touched);
  const formStepError = formStep => !isEmpty(formStep.errors);
  const formStepDirty = formStep => formStep.dirty;

  const isFormStepSaveButtonDisabled = formStep =>
    (formStepTouched(formStep) && formStepError(formStep)) ||
    !formStepDirty(formStep);

  const uploadCsForCandidate = (asset, candidate) => {
    CandidateV2Service.editCandidate(
      candidate.id,
      { assets: [{ fileName: asset.id }] },
      { stage: 'assets' }
    )
      .then(() => {
        setOpenIndex(null);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const generateCandidateForms = (asset, index) => {
    return (
      <CandidateCreateWizardStep1
        title={`${props.t('projectCreator:step1.title')} - ${asset.filename}`}
        project={{ id: projectId }}
        countries={countries}
        industryCategories={industryCategories}
        departments={departments}
        initialCandidateValues={{}}
        isStep1Open={index === openIndex}
        positions={positions}
        setIsStep1Open={() => {
          setOpenIndex(index);
        }}
        isFormStepSaveButtonDisabled={isFormStepSaveButtonDisabled}
        closeAll={() => {}}
        t={t}
        history={props.history}
        isFormStepError={false}
        isCandidateSave={false}
        step2FormValues={null}
        setIsStep2Open={() => {}}
        setStep1FormValues={() => {}}
        showSaveButton={!savedCandidateIds.includes(index)}
        alternativeSavedHeader={savedCandidateIds.includes(index)}
        callBackSave={candidate => {
          const updatedSavedCandidates = [...savedCandidateIds, index];
          setSavedCandidateIds(updatedSavedCandidates);
          uploadCsForCandidate(asset, candidate);
        }}
      />
    );
  };
  return (
    <React.Fragment>
      <Grid
        container
        alignItems="flex-start"
        spacing={2}
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} xl={8} lg={8}>
          <PageHeader
            title={t('candidate:form.candidateImport')}
            showBackButton={true}
            backButtonTarget={backPage}
          />
          <Paper className={classes.rootPaper} elevation={4}>
            <AssetsUpload
              uploadAssetsHandler={uploadAssets}
              closeHandler={backAction}
              acceptedFiles={['.pdf']}
              filesLimit={10}
              assetType={'cv'}
              opened={true}
            />
            {assets.map((asset, index) => {
              return generateCandidateForms(asset, index);
            })}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  uploadAssets,
  addCandidate,
  editCandidate,
  addProjectCandidate,
  getProjectCvHashes,
  showSnackbarMessage,
  setLastPage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(withStyles(styles)(withTranslation()(ProjectImportCandidatesView)))
);
