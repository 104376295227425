import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import Translate from '../../Translate';
import { styles } from './PersonalData.style';

const CareerTextContent = ({ projectCandidate, t, classes }) => {
  if (!projectCandidate) {
    return null;
  }

  return (
    <Grid container>
      {projectCandidate.userCareerProfessionalDescription ? (
        <Grid
          container
          style={{
            padding: '30px 30px 30px 30px',
            gridArea: 'left',
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={'newAts:core.careerDesc'}
              bold
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={ReactHtmlParser(
                projectCandidate.userCareerProfessionalDescription
              )}
              disableTranslate={true}
              style={{ fontSize: 16 }}
            />
          </Grid>
        </Grid>
      ) : null}
      {projectCandidate.userCareerProfessionalAspirations ? (
        <Grid
          container
          style={{
            padding: '30px 30px 30px 30px',
            gridArea: 'left',
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={'newAts:core.userCareerProfessionalAspirations'}
              bold
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={ReactHtmlParser(
                projectCandidate.userCareerProfessionalAspirations
              )}
              disableTranslate={true}
              style={{ fontSize: 16 }}
            />
          </Grid>
        </Grid>
      ) : null}
      {projectCandidate.userCareerMotivateToChange ? (
        <Grid
          container
          style={{
            padding: '30px 30px 30px 30px',
            gridArea: 'left',
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={'newAts:core.userCareerMotivateToChange'}
              bold
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={ReactHtmlParser(
                projectCandidate.userCareerMotivateToChange
              )}
              disableTranslate={true}
              style={{ fontSize: 16 }}
            />
          </Grid>
        </Grid>
      ) : null}
      {projectCandidate.isCandidateAppliedLastSixMonth ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            padding: '30px 30px 30px 30px',
            gridArea: 'left',
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Translate
              text={'newAts:core.isCandidateAppliedLastSixMonth'}
              bold
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Translate
            text={
              projectCandidate.isCandidateAppliedLastSixMonth
                ? 'newAts:core.yes'
                : 'newAts:core.no'
            }
            bold
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(CareerTextContent));
