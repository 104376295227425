import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import CandidateMenu from '../../../candidateMenu/CandidateMenu';
import CandidateAnonymousCard from '../CandidateAnonymousCard';
import CandidateDetailsHeaderCard from '../CandidateDetailsHeaderCard';
import { styles } from './CandidateAnonymousDetailsContent.styles';

const CandidateAnonymousDetailsContent = ({
  candidateDetails,
  classes,
  currentUser,
}) => {
  const isSelfEdit = currentUser.id === candidateDetails.id;

  return (
    <Grid container className={classes.wrapper}>
      <CandidateMenu
        itemSelected="show"
        title={'candidateDetails:anonymousDetailsCard.menuTitle'}
        subtitle={'candidateDetails:anonymousDetailsCard.subTitle'}
        currentCandidate={candidateDetails}
        currentUser={currentUser}
      />
      <CandidateDetailsHeaderCard
        itemSelected="show"
        title={
          isSelfEdit
            ? 'candidateDetails:anonymousDetailsCard.headerMenuTitleSelfEdit'
            : 'candidateDetails:anonymousDetailsCard.headerMenuTitle'
        }
        candidate={candidateDetails}
        currentUser={currentUser}
      />
      <CandidateAnonymousCard candidate={candidateDetails} />
    </Grid>
  );
};

export default withStyles(styles)(withRouter(CandidateAnonymousDetailsContent));
