import { createReducer } from '@reduxjs/toolkit';
import {
  setCurrentOrganizationAction,
  clearCurrentOrganizationAction,
  setCurrentOrganizationsListAction,
  clearCurrentOrganizationsListAction,
} from './actions';

const initialState = {
  currentOrganization: {},
  currentOrganizationsList: [],
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setCurrentOrganizationAction, (state, action) => {
      state.currentOrganization = action.payload;
    })
    .addCase(clearCurrentOrganizationAction, (state, action) => {
      state.currentOrganization = {};
    })
    .addCase(setCurrentOrganizationsListAction, (state, action) => {
      state.currentOrganizationsList = action.payload;
    })
    .addCase(clearCurrentOrganizationsListAction, (state, action) => {
      state.currentOrganizationsList = [];
    })
);
