import breakpoints from './breakpoints';
import overrides from './overrides';
import palette from './palette';
import spacing from './spacing';
import typography from './typography';
import zIndex from './zIndex';

const themeOptions = {
  overrides,
  palette,
  spacing,
  breakpoints,
  typography,
  zIndex,
};

export default themeOptions;
