import {
  Avatar,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import classNames from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import flagDe from 'shared/assets/img/flags/de.png';
import flagEn from 'shared/assets/img/flags/en.png';
import flagPl from 'shared/assets/img/flags/pl.png';
import flagUk from 'shared/assets/img/flags/uk.png';

const ITEM_HEIGHT = 48;

const languagesListLanding = ['pl-PL', 'uk-UK', 'en-EN', 'de-DE'];

export class LanguagesChange extends Component {
  state = {
    anchorEl: null,
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index, option) => {
    console.log(option);
    i18n.changeLanguage(option);
    this.setState({ anchorEl: null });
  };

  flags = {
    'pl-PL': flagPl,
    'uk-UK': flagUk,
    'en-EN': flagEn,
    'de-DE': flagDe,
    pl: flagPl,
    uk: flagUk,
    en: flagEn,
    de: flagDe,
  };

  render() {
    const { classes, version = '', t } = this.props;
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        {version === 'dots' ? (
          <div>
            {languagesListLanding.map((value, index) => (
              <Avatar
                src={this.flags[value]}
                key={value}
                className={classNames(
                  classes.circleLanguage,
                  value === i18n.language && classes.circleActive
                )}
                onClick={event => this.handleMenuItemClick(event, index, value)}
              >
                {value}
              </Avatar>
            ))}
          </div>
        ) : (
          <React.Fragment>
            <List onClick={this.handleClickListItem} className={classes.list}>
              <ListItem className={classes.listItem}>
                <Avatar
                  className={classes.icon}
                  src={this.flags[i18n.language]}
                />
                <ListItemText
                  primary={
                    t('languages.yourLanguage') +
                    ': ' +
                    t('languages.language') +
                    ' ' +
                    t('languages.' + i18n.language)
                  }
                />
              </ListItem>
            </List>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200,
                },
              }}
            >
              {languagesListLanding.map((value, index) => {
                return (
                  <MenuItem
                    key={value}
                    selected={value === i18n.language}
                    onClick={event =>
                      this.handleMenuItemClick(event, index, value)
                    }
                  >
                    {t('languages.' + value.substr(0, 2))}
                  </MenuItem>
                );
              })}
            </Menu>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

LanguagesChange.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LanguagesChange;
