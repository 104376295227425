import { Grid, withStyles, Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import { styles } from './Timeline.styles';

const Timeline = ({ classes, t, candidateEmploymentHistories }) => {
  const styleByStatus = {
    8: `${classes.endOfEmployment}`,
    4: `${classes.employment}`,
    5: `${classes.breakAtWork}`,
  };

  const isExtraViewDate = employmentStatus =>
    [
      enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_BREAK,
      enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_ILLNESS,
    ].includes(employmentStatus);

  return candidateEmploymentHistories.map(
    ({ id, tableStartDate, tableEndDate, employmentStatus }) => {
      const status = t(
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_NAME_STATUS_FOR_TIMELINE[
          employmentStatus
        ]
      );

      return (
        <Grid container className={classes.timeline} key={id}>
          <Grid
            item
            className={
              isExtraViewDate(employmentStatus)
                ? classes.timelineComponentBreakAtWork
                : classes.timelineComponent
            }
          >
            <div
              className={`${classes.timelineDate} ${classes.timelineDateRight}`}
            >
              <Typography variant="h5" className={classes.date}>
                {employmentStatus !==
                  enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
                    .EMPLOYMENT_STATUS_EMPLOY_END &&
                  !isExtraViewDate(employmentStatus) &&
                  tableStartDate}
                {isExtraViewDate(employmentStatus) &&
                  `${tableStartDate} - ${tableEndDate}`}
                {employmentStatus ===
                  enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
                    .EMPLOYMENT_STATUS_EMPLOY_END && tableEndDate}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            className={`${
              isExtraViewDate(employmentStatus) && classes.timelineMiddleTop
            } ${employmentStatus === 100 && classes.timelineMiddleBottom} ${
              classes.timelineMiddle
            }`}
          >
            <div
              className={`${classes.timelinePoint} ${
                isExtraViewDate(employmentStatus) && classes.timelinePointLong
              }`}
            />
          </Grid>
          <Grid item className={classes.timelineComponent}>
            <Typography
              variant="h6"
              className={styleByStatus[employmentStatus] || ''}
            >
              {status}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  );
};

export default withStyles(styles)(withTranslation()(Timeline));
