import { Avatar, Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Translate } from 'shared/newComponents';
import {
  clearCurrentOrganization,
  deleteOrganization,
  getCurrentUser,
} from 'shared/services';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getProjectAssetSrcByType } from 'shared/utils/assets';
import CustomDialog from '../../../../../../../components/CustomDialog';
import ConfirmationDialog from '../../../../../../../components/LayoutConfirmationDialog/LayoutConfirmationDialog';
import OrganizationAssets from '../../../../../../../components/OrganizationAssets';
import OrganizationForm from '../../../../../../../components/OrganizationForm';
import { styles } from './MyOrganization.styles';

const MyOrganization = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmationOpened, setConfirmationOpened] = useState(false);

  const userOrganization = recruiterData.organization;

  const userOrganizationDescription = userOrganization
    ? userOrganization.description
    : t('organization:nonDescription');

  const organizationConfirmation = recruiterData.organizationConfirmation;

  const isOrganizationOwner = recruiterData.isOrganizationOwner;

  const avatar = getProjectAssetSrcByType(userOrganization, 'logo');

  const organizationName = userOrganization && userOrganization.name;

  const percent = userOrganization && userOrganization.percent;

  const publicStatus =
    userOrganization && userOrganization.isPublic
      ? t`recruiter:orgPublic`
      : t`recruiter:orgPrivate`;
  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  const deleteOrganization = () => {
    props
      .deleteOrganization(recruiterData.organization.id)
      .then(() => {
        props.clearCurrentOrganization();
        props.getCurrentUser();
        props.showSnackbarMessage(
          t('organization:form.organizationClosedInfo')
        );
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleConfirmationClose = isOk => {
    if (isOk) {
      deleteOrganization();
    }
    setConfirmationOpened(false);
  };

  const handleConfirmationOpen = () => {
    setConfirmationOpened(true);
  };

  return (
    <Grid container>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.organizationWrapper}>
          {userOrganization ? (
            <Grid
              container
              className={classes.gridPadding}
              alignItems={'center'}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={`${t`organization:myOrg`} ${
                      [1, 4].includes(organizationConfirmation)
                        ? t`organization:myOrg2`
                        : ''
                    }`}
                    bold
                    className={classes.organizationStatusText}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.sectionMarginTop}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid
                    container
                    className={classes.avatarWrapper}
                    alignItems={'center'}
                  >
                    <Grid item xs={3} sm={3} md={3}>
                      {isOrganizationOwner ? (
                        <OrganizationAssets
                          filesLimit={1}
                          assetType="logo"
                          acceptedFiles={['image/*']}
                          currentUser={props.currentUser}
                          organization={userOrganization}
                        >
                          <Avatar
                            src={avatar}
                            aria-label="Jobllegro"
                            className={classes.avatar}
                          />
                        </OrganizationAssets>
                      ) : (
                        <Avatar
                          src={avatar}
                          aria-label="Jobllegro"
                          className={classes.avatar}
                        />
                      )}
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Translate
                            className={classes.organizationStatusText}
                            text={organizationName}
                            bold
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Translate
                            className={classes.publicStatusText}
                            text={publicStatus}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {isOrganizationOwner && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.sectionMarginTop}
                  >
                    <Button
                      onClick={handleConfirmationOpen}
                      style={{
                        border: '2px solid red',
                      }}
                      fontColor="red"
                      label={t`organization:delOrg`}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid container className={classes.sectionBigMarginTop}>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    className={classes.organizationFirstText}
                    text={userOrganizationDescription}
                    disableTranslate={true}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    className={classes.organizationSecondText}
                    text={`${percent}% - ${t`organization:provision`}`}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              className={classes.gridPadding}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={'organization:myOrg'}
                  bold
                  className={classes.organizationStatusText}
                />
                <Translate
                  text={'organization:createCommunity'}
                  className={classes.publicStatusText}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button label={t`organization:createOrg`} onClick={onOpen} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
      <CustomDialog
        maxWidth={'md'}
        title={''}
        isOpen={isOpen}
        onCloseDialog={onClose}
        body={
          <Grid container>
            <OrganizationForm organization={userOrganization} />
          </Grid>
        }
      />
      <ConfirmationDialog
        open={confirmationOpened}
        titleText={t('organization:form.closeOrganization')}
        onClose={handleConfirmationClose}
        okButtonText="Usuń"
        cancelButtonText="Anuluj"
      >
        {t('organization:form.closeOrganizationConfirmation')}
      </ConfirmationDialog>
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  deleteOrganization,
  clearCurrentOrganization,
  getCurrentUser,
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(MyOrganization))));
