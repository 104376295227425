import { Tabs, Tab, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const styles = theme => ({
  button: {
    minWidth: 150,
    margin: 0,
    background: '#e6e6e6',
    color: '#b3b3b3',
    marginRight: '3px',
    textTransform: 'capitalize',
    fontSize: '0.940rem',
    justifyContent: 'left',
    borderRadius: '3px 3px 0px 0px',
    minHeight: 40,
    '&:hover': {
      background: '#fff',
      color: '#53a3fc',
    },
  },
  buttonInActive: {
    minWidth: 150,
    margin: 0,
    background: '#f2f2f2',
    color: '#d3d3d3',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
  },
  active: {
    background: '#fff',
    color: '#53a3fc',
  },
  tabsRoot: {
    minHeight: 40,
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  scrollButtons: {
    color: theme.palette.jobllegroGrey,
    flex: '0 0 20px',
  },
});

export const TopMenu = props => {
  const {
    classes,
    counters,
    menuElements,
    itemSelected,
    hideEmpty = false,
    currentUser: { organization },
  } = props;
  const {
    currentUser: { isRecruiter = false },
  } = props;

  const subMenu = menuElements.map((element, key) => {
    if (
      (element.hideEmpty || hideEmpty) &&
      (!counters || !counters[element.route_url])
    ) {
      return null;
    }

    let getAccess = true;
    let setActive = false;

    if (itemSelected === element.route_url) {
      setActive = true;
    }

    if (!isRecruiter) {
      if (!element.accessCandidate) {
        getAccess = false;
      }
    }

    if (element.accessOrganization && !organization) {
      getAccess = false;
    }

    const counterLabel =
      counters && counters[element.route_url]
        ? counters[element.route_url]
        : null;
    return (
      getAccess && (
        <Tab
          key={key}
          component={Link}
          to={element.url}
          className={setActive ? classes.active : ''}
          label={`${element.name} ${
            counterLabel ? '( ' + counterLabel + ' ) ' : ''
          }`}
          classes={{
            root: classes.button,
          }}
        />
      )
    );
  });

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="on"
      value={0}
      classes={{
        root: classes.tabsRoot,
        indicator: classes.indicator,
        scrollButtons: classes.scrollButtons,
      }}
    >
      {subMenu}
    </Tabs>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(TopMenu));
