import { createAction } from '@reduxjs/toolkit';
import {
  SET_SUMMARY_LIST,
  CLEAR_SUMMARY_LIST,
  SET_SUMMARY_LIST_CLOSED,
} from '../actionConstants';

export const setSummaryListAction = createAction(SET_SUMMARY_LIST);
export const clearSummaryListAction = createAction(CLEAR_SUMMARY_LIST);
export const setSummaryListClosedAction = createAction(SET_SUMMARY_LIST_CLOSED);
