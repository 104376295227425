import { Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react';

export class Status404View extends Component {
  render() {
    return (
      <Paper>
        <Typography variant="h4" gutterBottom>
          ERROR 404
        </Typography>
      </Paper>
    );
  }
}

export default Status404View;
