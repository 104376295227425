export const styles = () => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#2D333B',
  },
  dialogTitle: {
    '& h6': { fontWeight: 500 },
  },
  select: { margin: '20px 25px 37px' },
  bgBlue: { background: '#E5F0FF' },
  container: { height: 280 },
  info: { margin: '0 100px', textAlign: 'center', paddingTop: 63 },
  saveBtnDiv: { height: 140 },
  pinIcon: {
    paddingRight: 8,
    height: 50,
  },
  mailDiv: { display: 'flex', alignItems: 'center', marginBottom: 10 },
  mail: { marginRight: 10 },
  mobileDiv: { display: 'flex', alignItems: 'center' },
  mobile: { marginRight: 10 },
});
