import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationService } from 'shared/services';
import { filterFunction } from 'shared/utils';

export const useAdminOrganizationFilters = () => {
  const { t } = useTranslation('adminOrganization');
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [filters, setFilters] = useState({});

  const getOrganizations = () => {
    OrganizationService.getOrganizationAll()
      .then(({ data }) => {
        setOrganizations(data);
      })
      .catch(error => {
        alert(t('dataError'));
        console.log(error);
      });
  };

  const filteringOptions = [
    {
      key: 'name',
      type: 'text',
      name: t('filters.name'),
    },
    {
      key: 'description',
      type: 'text',
      name: t('filters.description'),
    },
  ];

  const filterBy = filters => {
    _.each(filters, (value, key) => {
      (value === '' || value === null) && delete filters[key];
    });
    setFilters(filters);
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  useEffect(() => {
    const filteredOrganizations = organizations.filter(organization => {
      return filterFunction({
        element: organization,
        filters,
        filteringOptions,
      });
    });

    setFilteredOrganizations(filteredOrganizations);
  }, [organizations, filters]);

  return {
    filterBy,
    filters,
    filteringOptions,
    filteredOrganizations,
    getOrganizations,
  };
};
