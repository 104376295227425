import moment from 'moment';
import momentBusiness from 'moment-business-days';
import 'moment/locale/pl';
import { enums } from '../enums';

moment.locale('pl');

export const getTimeLeft = (project, t) => {
  const now = moment(new Date());
  const projectEnd = moment(project.projectEndDate);

  if (project.projectEndDate === null || project.projectStartDate === null) {
    return '';
  }
  if (projectEnd.startOf('day').isBefore(now.startOf('day'), 'day')) {
    return t('stepper.ending');
  } else {
    return t(enums.CAST_TRANSLATE[projectEnd.endOf('day').toNow(true)]);
  }
};

export const getOrganizationSubscriptionType = organizationSubscriptionType => {
  switch (organizationSubscriptionType) {
    case 1:
      return 'Bronze';
    case 2:
      return 'Silver';
    case 3:
      return 'Gold';
    default:
      return 'Brak informacji';
  }
};

export const getSettlementCurrencyType = settlementCurrency => {
  switch (settlementCurrency) {
    case 0:
      return 'PLN';
    case 1:
      return 'EUR';
    case 2:
      return 'HUA';
    default:
      return 'Brak informacji';
  }
};

export const dateSubtract = (date, numberOfDays) =>
  moment(date).subtract(numberOfDays, 'days');

export const dateAdd = (date, numberOfDays) =>
  moment(date).add(numberOfDays, 'days');

export const dateAddBusiness = (date, numberOfDays) =>
  momentBusiness(date).businessAdd(numberOfDays);
