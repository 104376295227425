import { Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, TextField, WizardStep } from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { isProjectClosed } from 'shared/utils/functions';
import {
  stage4NotSetFields,
  useFormStep3,
} from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { MODEL } from './ProjectCreateWizardStep3.utils';

const ProjectCreateWizardStep3 = ({
  setStep3FormValues,
  classes,
  t,
  initialProjectValues,
  isStep3Open,
  isFormStepError,
  setIsStep3Open,
  setIsStep4Open,
  isFormStepSaveButtonDisabled,
  closeAll,
  isProjectSave,
  setCurrentStage,
  step4FormValues,
  setIsSnackbarErrorOpen,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const formik = useFormStep3({ t, initialProjectValues, setCurrentStage });

  const isOtherScanSelected = formik.values.isRequireAttachmentOtherScan;

  const isSecondStageSaved = setCurrentStage < 2;

  const myRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep3FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  useEffect(() => {
    if (isStep3Open) {
      executeScroll(myRef);
    }
  }, [isStep3Open]);

  const handleChangeOtherScan = event => {
    if (!event.target.checked) {
      formik.setFieldValue(MODEL.isRequireAttachmentOtherDesc, null);
    }
    formik.setFieldValue(
      MODEL.isRequireAttachmentOtherScan,
      event.target.checked
    );
  };
  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />
      <WizardStep
        withSaveButton={!isProjectClosed(initialProjectValues)}
        title="projectCreator:step3.title"
        required
        isOpen={isStep3Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep3Open(false);
            const openSecondStep = stage4NotSetFields(
              initialProjectValues,
              step4FormValues
            );
            setIsStep4Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep3Open(true);
        }}
        disableArrowButton={!isProjectSave || !!isSecondStageSaved}
        onClickArrow={() => {
          if (!isProjectSave || !!isSecondStageSaved) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => {}}
      >
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentCv}
                label={t`candidateCreator:step4.cv`}
              />
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentRecruiterApproved}
                label={t(
                  'projectCreator:step3.isRequireAttachmentRecruiterApproved'
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentPassportStampScan}
                label={t(
                  'projectCreator:step3.isRequireAttachmentPassportStampScan'
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentPassportVisaScan}
                label={t(
                  'projectCreator:step3.isRequireAttachmentPassportVisaScan'
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentPassportPersonalDataScan}
                label={t(
                  'projectCreator:step3.isRequireAttachmentPassportPersonalDataScan'
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentWorkPermitScan}
                label={t(
                  'projectCreator:step3.isRequireAttachmentWorkPermitScan'
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentWorkQualificationScan}
                label={t(
                  'projectCreator:step3.isRequireAttachmentWorkQualificationScan'
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                formik={formik}
                name={MODEL.isRequireAttachmentCertificateAndDiplomaScan}
                label={t(
                  'projectCreator:step3.isRequireAttachmentCertificateAndDiplomaScan'
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item>
                  <Checkbox
                    onChange={event => handleChangeOtherScan(event)}
                    formik={formik}
                    name={MODEL.isRequireAttachmentOtherScan}
                    label={t(
                      'projectCreator:step3.isRequireAttachmentOtherScan'
                    )}
                  />
                </Grid>
                {isOtherScanSelected && (
                  <TextField
                    name={MODEL.isRequireAttachmentOtherDesc}
                    label=""
                    formik={formik}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default ProjectCreateWizardStep3;
