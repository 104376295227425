import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import OrganizationAssignRecruiterButton from './OrganizationAssignRecruiterButton';
import RecruiterOrganizationPanelTopCard from './RecruiterOrganizationPanelTopCard';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  rootPaperDescription: {
    paddingTop: 32,
    paddingBottom: 64,
    paddingLeft: 64,
    paddingRight: 64,
    marginTop: 0,
    background: 'transparent',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  root: {
    marginTop: 8,
  },
  textDescription: {
    fontSize: '16px',
    color: '#000',
    paddingBottom: 32,
  },
  textHeading: {
    paddingTop: 0,
    paddingBottom: 8,
    color: '#53a3fc',
    fontSize: '24px',
  },
  textSubHeading: {
    fontSize: 12,
  },
});

export const OrganizationDetails = props => {
  const { organization = {}, classes, t } = props;

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: '0px' }}>
        <RecruiterOrganizationPanelTopCard organization={organization} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: '0px' }}>
        <Paper className={classes.rootPaperDescription} elevation={0}>
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            className={classes.textHeading}
          >
            {t('organization:field.descriptionLong')}
          </Typography>
          <Typography component="p" className={classes.textDescription}>
            {organization.description}
          </Typography>
          <Typography
            variant="h5"
            component="h3"
            className={classes.textHeading}
          >
            {t('organization:field.provisionForProjects')}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className={classes.textSubHeading}
          >
            {t('organization:field.provisionForProjectsLong')}
          </Typography>
          <Typography component="p" className={classes.textDescription}>
            {organization.percent + ' %'}
          </Typography>
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            className={classes.textHeading}
          >
            {/*<OrganizationSwitchVisibilityButton organization={organization}/>*/}
            <OrganizationAssignRecruiterButton organization={organization} />
          </Typography>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(withTranslation()(OrganizationDetails));
