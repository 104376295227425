import { Grid, withStyles } from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { array, number, object } from 'yup';
import Button from 'shared/newComponents/Button/Button';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import TextField from 'shared/newComponents/TextField';
import { RecruiterV2Service } from 'shared/services';
import { castObjectForSelect } from 'shared/utils/functions';
import CustomDialog from '../../../../../../../../../../components/CustomDialog';
import { styles } from './DepartmentsExperiencesForm.styles';

function PersonalDataForm({
  recruiterData,
  isOpen,
  onClose,
  recruiterEdited,
  departments,
  classes,
  t,
}) {
  const [experienceDepartmentSelectCard, setExperienceDepartmentSelectCard] =
    useState([]);

  const defaultExperienceDepartmentCard = {
    department: '',
    experienceLevel: '',
  };

  const MODEL = {
    userDepartmentExperiences: 'userDepartmentExperiences',
  };

  useEffect(() => {
    if (
      recruiterData.userDepartmentExperiences &&
      recruiterData.userDepartmentExperiences.length !== 0
    ) {
      onAddExperienceDepartmentExist(recruiterData.userDepartmentExperiences);
    } else {
      onAddExperienceDepartment();
    }
  }, [recruiterData]);

  const onAddExperienceDepartmentExist = userExperienceDepartment => {
    setExperienceDepartmentSelectCard(userExperienceDepartment);
  };

  const onAddExperienceDepartment = () => {
    setExperienceDepartmentSelectCard([
      ...experienceDepartmentSelectCard,
      defaultExperienceDepartmentCard,
    ]);
    formik.setFieldValue(MODEL.userDepartmentExperiences, [
      ...formik.values[MODEL.userDepartmentExperiences],
      defaultExperienceDepartmentCard,
    ]);
  };

  const onRemove = indexToRemove => {
    const filterDepartmentTable = experienceDepartmentSelectCard.filter(
      (item, index) => index !== indexToRemove
    );
    const filterDepartmentTableFromFormik = formik.values[
      MODEL.userDepartmentExperiences
    ].filter((item, index) => index !== indexToRemove);

    setExperienceDepartmentSelectCard(filterDepartmentTable);
    formik.setFieldValue(
      `${MODEL.userDepartmentExperiences}`,
      filterDepartmentTableFromFormik
    );
  };

  const isLastIndex = index => {
    return experienceDepartmentSelectCard.length === index + 1;
  };

  const formData = {
    userDepartmentExperiences: recruiterData
      ? recruiterData.userDepartmentExperiences
      : null,
  };

  const validationSchema = Yup.object().shape({
    userDepartmentExperiences: array().of(
      object().shape({
        experienceLevel: number()
          .positive()
          .min(1, `${t`recruiter:reqVal`} 0`)
          .required(t('projectCreator:core.requiredField')),
        department: object().shape({
          id: number().required(t('projectCreator:core.requiredField')),
        }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: values => {
      const body = {
        userDepartmentExperiences: values.userDepartmentExperiences,
      };

      RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
        stage: 'department_experiences_data',
      })
        .then(() => {
          recruiterEdited();
          onClose();
        })
        .catch(error => {
          console.log(error);
          alert(t`recruiter:saveError`);
        });
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`recruiter:departments`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <FormikProvider value={formik}>
            <FieldArray
              name={MODEL.userDepartmentExperiences}
              render={() => {
                return experienceDepartmentSelectCard.map((item, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12} sm={12} md={6}>
                        <MultiSelect
                          options={castObjectForSelect(departments)}
                          label={t`recruiter:department`}
                          name={`${MODEL.userDepartmentExperiences}[${index}].department`}
                          formik={formik}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          type={'number'}
                          label={t('candidateCreator:step2.experienceLevel')}
                          name={`${MODEL.userDepartmentExperiences}[${index}].experienceLevel`}
                          formik={formik}
                        />
                      </Grid>

                      <Grid
                        container
                        className={classes.wrapLastIndex}
                        spacing={2}
                        alignItems="center"
                      >
                        {isLastIndex(index) ? (
                          <Grid item xs={12} sm={12} md={12}>
                            {index !== 0 ? (
                              <Grid item xs={12} sm={12} md={12}>
                                <Button
                                  className={classes.wrapLastIndexBtn}
                                  fontColor={'red'}
                                  label={t(
                                    'candidateCreator:step2.removeExperience'
                                  )}
                                  onClick={() => onRemove(index)}
                                />
                              </Grid>
                            ) : null}
                            <Grid item xs={12} sm={12} md={12}>
                              <Button
                                label={t(
                                  'candidateCreator:step2.addExperience'
                                )}
                                onClick={onAddExperienceDepartment}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={12} md={12}>
                            <Button
                              className={classes.wrapRemoveBtn}
                              fontColor={'red'}
                              label={t(
                                'candidateCreator:step2.removeExperience'
                              )}
                              onClick={() => onRemove(index)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                });
              }}
            />
            <Grid
              container
              justifyContent="center"
              onClick={formik.handleSubmit}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Button label={t`recruiter:save`} type="submit" />
              </Grid>
            </Grid>
          </FormikProvider>
        </Grid>
      }
    />
  );
}

export default connect()(
  withStyles(styles)(withTranslation()(PersonalDataForm))
);
