import { object } from 'yup';

export const MODEL = {
  dataProcessingAgreement: 'dataProcessingAgreement',
  clauseGDPR: 'clauseGDPR',
  dataProcessingAgreementUrl: 'dataProcessingAgreementUrl',
  clauseGDPRUrl: 'clauseGDPRUrl',
};

export const validationSchema = t => object().shape({});
