import moment from 'moment';

export const validateProject = (values, { t }) => {
  let errors = {};
  const ignoreElement = ['maxSalary'];
  [
    'visibility',
    'client',
    'clientUrl',
    'city',
    'scope',
    'companySize',
    'industry',
    'position',
    'positionDescription',
    'companyDescription',
    'minSalary',
    'employmentStartDate',
    'contractType',
    'benefits',
    'talentManagement',
    'projectStartDate',
    'projectEndDate',
    'candidateCount',
    'revenue',
    'revenueSubmitted',
    'revenueWinner',
    'requirements',
    'projectStartDate',
    'projectEndDate',
  ].forEach(element => {
    let value = values[element];
    if (
      (value === undefined ||
        value === null ||
        (typeof value === 'string' &&
          (value.trim() === '' || value.trim() === '<p></p>'))) &&
      !ignoreElement.includes(element)
    ) {
      errors[element] = t`candidate:form.validation.nonEmpty`;
    }
    if (
      (element === 'minSalary' ||
        element === 'maxSalary' ||
        element === 'revenue' ||
        element === 'revenueSubmitted' ||
        element === 'revenueWinner') &&
      value < 0
    ) {
      errors[element] = t`projectForm:fieldMin`;
    }
    if (element === 'maxSalary' && value < values['minSalary']) {
      errors[element] = t`projectForm:fieldSalary`;
    }
    if (
      element === 'minSalary' &&
      value > values['maxSalary'] &&
      values['maxSalary'] !== undefined &&
      values['maxSalary'] !== null
    ) {
      errors[element] = t`projectForm:fieldSalaryMax`;
    }
    if (element === 'candidateCount' && value < 0) {
      errors[element] = t`projectForm:fieldMin`;
    }
    if (element === 'candidateCount' && value < 0) {
      errors[element] = t`projectForm:fieldMin`;
    }
    if (element === 'employmentStartDate' && !values['isEdited']) {
      if (moment(value).diff(moment(), 'days') < 0) {
        errors[element] = t`projectForm:dateError`;
      }
    }
    if (element === 'projectEndDate' || element === 'projectStartDate') {
      if (element === 'projectEndDate') {
        if (
          values['projectStartDate'] &&
          new Date(value).getTime() <
            new Date(values['projectStartDate']).getTime()
        ) {
          errors[element] = t`projectForm:dateError2`;
        }
      } else {
        if (
          values['projectEndDate'] &&
          new Date(value).getTime() >
            new Date(values['projectEndDate']).getTime()
        ) {
          errors[element] = t`projectForm:dateError2`;
        }
      }
      if (!values['isEdited']) {
        if (moment(value).diff(moment(), 'days') < 0) {
          errors[element] = t`projectForm:dateError3`;
        }
      }
    }
  });

  return errors;
};

export default validateProject;
