import { Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ProgressBar, Translate } from '../../index';

const EmployerReview = ({ projectCandidate, t }) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Translate
        bold
        style={{
          fontSize: 20,
        }}
        text={'newAts:feedback.employerGrade'}
      />
      <Grid
        container
        style={{
          marginTop: 20,
        }}
      >
        <Grid container style={{ paddingBottom: 16 }}>
          <ProgressBar
            percentage={projectCandidate.feedbackReviewStage1 * 10}
            title={t('newAts:feedbackView.feedbackReviewStage1')}
            secondTitle={''}
            customStyleSecondHeader={{
              color: '#6CB8FF',
            }}
          />
          <ProgressBar
            percentage={projectCandidate.feedbackReviewStage2 * 10}
            title={t('newAts:feedbackView.feedbackReviewStage2')}
            secondTitle={''}
            customStyleSecondHeader={{
              color: '#6CB8FF',
            }}
          />
          <ProgressBar
            percentage={projectCandidate.feedbackReviewStage3 * 10}
            title={t('newAts:feedbackView.feedbackReviewStage3')}
            secondTitle={''}
            customStyleSecondHeader={{
              color: '#6CB8FF',
            }}
          />
          <ProgressBar
            percentage={projectCandidate.feedbackReviewStage4 * 10}
            title={t('newAts:feedbackView.feedbackReviewStage4')}
            secondTitle={''}
            customStyleSecondHeader={{
              color: '#6CB8FF',
            }}
          />
          <ProgressBar
            percentage={projectCandidate.feedbackReviewStage5 * 10}
            title={t('newAts:feedbackView.feedbackReviewStage5')}
            secondTitle={''}
            customStyleSecondHeader={{
              color: '#6CB8FF',
            }}
          />
          <ProgressBar
            percentage={projectCandidate.feedbackReviewStage6 * 10}
            title={t('newAts:feedbackView.feedbackReviewStage6')}
            secondTitle={''}
            customStyleSecondHeader={{
              color: '#6CB8FF',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(EmployerReview);
