import { Button as ButtonBase, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Translate } from '..';
import { styles } from './Button.style';

const Button = ({
  label,
  classes,
  disabled,
  variant = 'outlined',
  size = 'large',
  fullWidth = true,
  full = false,
  fontColor = null,
  change = false,
  ...props
}) => (
  <ButtonBase
    variant={variant}
    className={classNames(
      disabled
        ? classes.rootDisabledButton
        : change
        ? classes.rootButtonChange
        : classes.rootButton,
      variant === 'contained' ? classes.rootContainedButton : '',
      full && !disabled ? classes.rootContainedButton : '',
      size === 'small' ? classes.small : ''
    )}
    disabled={disabled}
    fullWidth={fullWidth}
    {...props}
  >
    <Translate
      text={label}
      className={classNames(
        disabled
          ? classes.disabledText
          : fontColor
          ? classes.textRed
          : classes.text,
        variant === 'contained' ? classes.containedText : '',
        full ? classes.fullText : '',
        fontColor ? { color: fontColor } : ''
      )}
      style={{ color: fontColor }}
      bold
      variant="body1"
    />
  </ButtonBase>
);

export default withStyles(styles)(Button);
