import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { deleteUser, logoutUser } from 'shared/services';
import { getEnumItemName } from 'shared/utils/assets';
import { styles } from './OrganizationFinicialPlan.styles';

const OrganizationFinicialPlan = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  const userOrganization =
    recruiterData.organization &&
    [2, 5, 9].includes(recruiterData.organizationConfirmation)
      ? recruiterData.organization
      : null;
  const individualFinicalPlan =
    userOrganization && userOrganization.individualFinicalPlan;
  const currentSubscriptionType =
    userOrganization && userOrganization.currentSubscriptionType;
  const publicationValue =
    userOrganization && userOrganization.publicationValue;
  const meetingsValue = userOrganization && userOrganization.meetingsValue;
  const hiringProc = userOrganization && userOrganization.hiringProc;
  const mentorValue = userOrganization && userOrganization.mentorValue;
  const warrantyValue = userOrganization && userOrganization.warrantyValue;
  const dateStart = userOrganization && userOrganization.dateStart;
  const dateStop = userOrganization && userOrganization.dateStop;

  if (!userOrganization) {
    return null;
  }
  return (
    <Grid container className={classes.gridMarginTop}>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.gridWrapper}>
          <Grid container className={classes.gridPadding} alignItems={'center'}>
            <Grid container justifyContent={'space-between'}>
              <Grid item xs={6} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Translate
                      text={'organization:finPlan'}
                      bold
                      className={classes.bigTextFont}
                    />
                  </Grid>
                  {individualFinicalPlan && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        text={'organization:specialOffer'}
                        bold
                        className={classes.smallTextFontSpecial}
                      />
                    </Grid>
                  )}
                  {dateStart && dateStop && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        text={`${t('organization:validUntil')} - ${dateStop}`}
                        className={classes.smallTextFont}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.gridMarginTop}>
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`organization:sub`}
                  className={classes.mediumTextFont}
                />
              </Grid>
              {currentSubscriptionType ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={getEnumItemName(
                      enums.SUBSCRIPT_TYPE,
                      currentSubscriptionType
                    )}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={'candidate:form.lack'}
                    bold
                    className={classes.mediumTextFont}
                  />
                </Grid>
              )}
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`organization:publication`}
                  className={classes.mediumTextFont}
                />
              </Grid>
              {publicationValue ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:specialPrice`} ${publicationValue.toFixed(
                      2
                    )} PLN`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:standardPrice`} 199,00 PLN`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              )}
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`organization:verifyMeeting`}
                  className={classes.mediumTextFont}
                />
              </Grid>

              {meetingsValue ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:specialPrice`} ${meetingsValue.toFixed(
                      2
                    )} PLN`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:standardPrice`} 199,00 PLN`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              )}
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`organization:employment`}
                  className={classes.mediumTextFont}
                />
              </Grid>
              {hiringProc ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:specialPrice`} ${hiringProc.toFixed(
                      1
                    )}%`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:standardPrice`} 90,0%`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              )}
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`organization:field.mentorValue`}
                  className={classes.mediumTextFont}
                />
              </Grid>
              {mentorValue ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:specialPrice`} ${mentorValue.toFixed(
                      2
                    )} PLN`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`candidate:form.lack`}
                    bold
                    className={classes.mediumTextFont}
                  />
                </Grid>
              )}
            </Grid>
            <hr className={classes.separator} />
            <Grid container className={classes.gridPadding}>
              <Grid item xs={12} sm={12} md={6}>
                <Translate
                  text={`organization:warranty`}
                  className={classes.mediumTextFont}
                />
              </Grid>
              {warrantyValue ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`${t`organization:specialPrice`} ${warrantyValue.toFixed(
                      2
                    )} PLN`}
                    bold
                    disableTranslate={true}
                    className={classes.mediumTextFont}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6}>
                  <Translate
                    text={`candidate:form.lack`}
                    bold
                    className={classes.mediumTextFont}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  deleteUser,
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withTranslation()(OrganizationFinicialPlan))));
