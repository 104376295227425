import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Google, FacebookBox, LinkedinBox } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';

const SocialLoginButton = props => {
  const { brand, classes, label, ...otherProps } = props;
  const Icon =
    brand === 'google'
      ? Google
      : brand === 'facebook'
      ? FacebookBox
      : LinkedinBox;

  return (
    <Button
      classes={{
        root: classNames(classes.root, classes[brand]),
      }}
      {...otherProps}
    >
      <Icon className={props.classes.leftIcon} />
      {label}
    </Button>
  );
};

SocialLoginButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  brand: PropTypes.oneOf(['google', 'facebook', 'linkedIn']).isRequired,
};

SocialLoginButton.displayName = 'SocialLoginButton';

export default SocialLoginButton;
