import {
  Button,
  IconButton,
  withStyles,
  Hidden,
  Menu,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';

const ITEM_HEIGHT = 30;

const styles = theme => ({
  button: {
    minWidth: 150,
    margin: 0,
    background: '#e6e6e6',
    color: '#b3b3b3',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
    '&:hover': {
      background: '#fff',
      color: '#53a3fc',
    },
    [theme.breakpoints.only('xs', 'sm')]: {
      background: theme.palette.jobllegroBlue,
      color: '#fff',
      borderRadius: '0px',
      minWidth: 200,
      borderBottom: '1px solid #84bfff',
      '&:nth-last-child(1)': {
        borderBottom: '0px',
      },
    },
  },
  buttonInActive: {
    minWidth: 150,
    margin: 0,
    background: '#f2f2f2',
    color: '#d3d3d3',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
  },
  active: {
    background: '#fff',
    color: '#53a3fc',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '0.940rem',
    justifyContent: 'left',
  },
  menuButton: {
    right: 10,
    top: 87,
    position: 'fixed',
    zIndex: '1000',
    width: 50,
    height: 50,
    background: '#52a3fc',
    color: '#ffffff',
    [theme.breakpoints.only('xs')]: {
      top: 78,
    },
  },
  paperMenu: {
    width: 150,
    [theme.breakpoints.down('md')]: {
      background: theme.palette.jobllegroBlue,
      width: 200,
    },
  },
});

export class OrganizationMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      organization,
      classes,
      organizationConfirmationStatus,
      currentUser,
      t,
    } = this.props;

    const { owner = {} } = organization;
    const isOwner = currentUser.id === owner.id;
    const accessRecruiter = organizationConfirmationStatus === 2;

    const menuOrganizationElements = [
      {
        name: t('project:menu.details.info'),
        url: PATHS.ORGANIZATION.SHOW.replace(':id', organization.id),
        accessCandidate: false,
        accessRecruiter: true,
        route_url: 'information',
      },
      {
        name: t('project:menu.details.recruiters'),
        url: PATHS.ORGANIZATION.RECRUITERS.replace(':id', organization.id),
        accessCandidate: false,
        accessRecruiter: isOwner || accessRecruiter,
        route_url: 'recruiters',
      },
    ];

    const subMenu = menuOrganizationElements.map((element, key) => {
      let getAccess = true;
      const { currentUser = {}, itemSelected } = this.props;
      const { isRecruiter } = currentUser;
      if (!isRecruiter) {
        if (!element.accessCandidate) {
          getAccess = false;
        }
      }

      let disabled = false;
      if (organization.id === undefined) {
        disabled = true;
      }

      let setActive = false;

      if (itemSelected === element.route_url) {
        setActive = true;
      }

      const { accessRecruiter } = element;
      let controlButtonDisabled = false;
      !accessRecruiter && !disabled
        ? (controlButtonDisabled = true)
        : (controlButtonDisabled = false);

      return (
        getAccess && (
          <Button
            key={key}
            variant="text"
            component={Link}
            to={element.url}
            disabled={controlButtonDisabled}
            className={setActive ? classes.active : ''}
            classes={{
              root: !accessRecruiter ? classes.buttonInActive : classes.button,
              label: classes.label,
            }}
          >
            {element.name}
          </Button>
        )
      );
    });

    return (
      <React.Fragment>
        <Hidden only={['xs', 'sm']}>{subMenu}</Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.menuButton}
            aria-label="Menu"
            aria-owns={anchorEl ? 'long-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            classes={{
              paper: classes.paperMenu,
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 9,
              },
            }}
          >
            {subMenu}
          </Menu>
        </Hidden>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    organizationConfirmationStatus:
      store.auth.currentUser.organizationConfirmation,
    organization: store.organizations.currentOrganization,
  };
};

OrganizationMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  organizationConfirmationStatus: PropTypes.number.isRequired,
  organization: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withStyles(styles)(withTranslation()(OrganizationMenu))));
