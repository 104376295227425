import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralInformations } from '..';
import { allCompetenceIsSet } from '../../../NewProjectCreateView/components/ProjectCreateWizardStep2/ProjectCreateWizardStep2.utils';
import ProjectMenu from '../../../projectMenu/ProjectMenu';
import { styles } from './ProjectShowViewContent.styles';

const ProjectShowViewContent = ({ fetchProject, projectDetails, classes }) => {
  //To Clear uncolpete old competences
  if (projectDetails && projectDetails.projectSkills) {
    projectDetails.projectSkills = projectDetails.projectSkills.filter(
      (item, index) => {
        if (allCompetenceIsSet(projectDetails, index)) {
          return item;
        }
      }
    );
  }
  return (
    <Grid container className={classes.wrapper}>
      <ProjectMenu
        title={'projectDetails:content.title'}
        subtitle={projectDetails.position}
        projectDetailsOutStore={projectDetails}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: '8px 0 16px 0' }}
      />
      <Grid container>
        <GeneralInformations
          fetchProject={fetchProject}
          projectDetails={projectDetails}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(ProjectShowViewContent));
