import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getConfigs, editConfig } from 'shared/services/configService';
import PageHeader from '../../components/PageHeader';
import AdminConfigViewHead, { columnData } from './AdminConfigViewHead';

export class AdminConfigView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'desc',
      orderBy: 'name',
      page: 0,
      rowsPerPage: 25,
      editedConfig: {},
      editModalOpen: false,
      editModalFormValue: '',
    };
  }

  componentWillMount() {
    this.props.getConfigs();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, page: 0 });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleOpenEditForm = config => {
    this.setState({
      editedConfig: config,
      editModalOpen: true,
      editModalFormValue: config.value,
    });
  };

  handleChangeValue = event => {
    this.setState({
      editModalFormValue: event.target.value,
    });
  };

  handleCloseEditForm = async isOk => {
    const { editedConfig, editModalFormValue } = this.state;
    if (isOk) {
      await this.props.editConfig(editedConfig.id, {
        value: editModalFormValue,
      });
      this.props.getConfigs();
    }
    this.setState({
      editedConfig: {},
      editModalOpen: false,
      editModalFormValue: '',
    });
  };

  render() {
    const { configList, t } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    let data = configList ? _.sortBy(configList, orderBy) : [];
    //działa odwrotnie bo material-ui/Table ma przy asc strzałkę w górę
    data = order === 'asc' ? _.reverse(data) : data;

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper style={{ overflow: 'scroll', marginRight: 80 }}>
        <PageHeader title="Konfiguracja" />
        <Dialog
          open={this.state.editModalOpen}
          onClose={this.handleCloseEditForm.bind(this, false)}
          fullWidth
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              disabled
              defaultValue={this.state.editedConfig.name}
              fullWidth
            />
            <TextField
              name={this.state.editedConfig.name + '_field'}
              autoFocus
              margin="dense"
              onChange={this.handleChangeValue}
              defaultValue={this.state.editedConfig.value}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseEditForm.bind(this, false)}
              color="primary"
            >
              Anuluj
            </Button>
            <Button
              onClick={this.handleCloseEditForm.bind(this, true)}
              color="primary"
            >
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
        <Table>
          <AdminConfigViewHead
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(n => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell align="right">{n.id}</TableCell>
                    <TableCell padding="none">{n.name}</TableCell>
                    <TableCell padding="none">{n.value}</TableCell>
                    <TableCell>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={this.handleOpenEditForm.bind(this, n)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columnData.length + 1} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t('pagination.elementsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  t('pagination.pagesFromTo', { from, to, count })
                }
                colSpan={columnData.length + 1}
                count={data.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = store => {
  return {
    configList: store.auth.configList,
  };
};

const mapDispatchToProps = {
  getConfigs,
  editConfig,
};

AdminConfigView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  getConfigs: PropTypes.func.isRequired,
  editConfig: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdminConfigView));
