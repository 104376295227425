import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { getEnumItemNameFullObject } from 'shared/utils/assets';
import NonContent from '../../../NonContent';
import { styles } from './OtherExperiences.styles';
import OtherExperiencesForm from './OtherExperiencesForm/OtherExperiencesForm';
import OtherExperiencesItem from './OtherExperiencesItem';

const OtherExperiences = ({
  history,
  recruiterData,
  recruiterEdited,
  countries,
  languageCompetence,
  isSelfEdit,
  classes,
  t,
  ...props
}) => {
  const userRecruitmentAreas = recruiterData.userRecruitmentAreas;
  const userRecruitmentCountries = recruiterData.userRecruitmentCountries;
  const userLanguageSkills = recruiterData.userLanguageSkills;

  const [openForm, setOpenForm] = useState(false);

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const getLanguageText = item => {
    const languageSkill = getEnumItemNameFullObject(
      enums.EXPERIENCE_LEVEL,
      item.level ? item.level : item.experience
    );
    return `${item.skill.name} ${t(languageSkill)}`;
  };

  if (
    !isSelfEdit &&
    !userRecruitmentAreas.length &&
    !userRecruitmentCountries.length &&
    !userLanguageSkills.length
  ) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Grid item xs={12} sm={12} md={12} className={classes.itemWrapper}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.containerWrapper}>
            <Grid container className={classes.headerWrapper}>
              <Grid container>
                <Grid item xs={10} sm={10} md={10}>
                  <Translate
                    text={'candidateCreator:general'}
                    bold
                    className={classes.fontStyleBig}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} className={classes.editWrapper}>
                  <div className={classes.imgWrapper}>
                    {isSelfEdit ? (
                      <img
                        alt="editIcon"
                        onClick={handleOpenForm}
                        src={EditIcon}
                        height={50}
                      />
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              {userRecruitmentAreas.length ||
              userRecruitmentCountries.length ||
              userLanguageSkills.length ? (
                <Grid container>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        text={'candidateCreator:area'}
                        bold
                        className={classes.fontStyleSmall}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container>
                        {userRecruitmentAreas.map(item => (
                          <Grid key={item.id} item xs={4} sm={4} md={4}>
                            <OtherExperiencesItem text={item.country.name} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        text={'candidateCreator:langs'}
                        bold
                        className={classes.fontStyleSmall}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container>
                        {userLanguageSkills.map(item => (
                          <Grid key={item.id} item xs={12} sm={12} md={12}>
                            <OtherExperiencesItem
                              text={getLanguageText(item)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        text={'candidateCreator:country'}
                        bold
                        className={classes.fontStyleSmall}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container>
                        {userRecruitmentCountries.map(item => (
                          <Grid key={item.id} item xs={4} sm={4} md={4}>
                            <OtherExperiencesItem text={item.country.name} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <NonContent showContent={isSelfEdit} />
              )}
            </Grid>
          </Grid>
        </Paper>
        {openForm ? (
          <OtherExperiencesForm
            recruiterData={recruiterData}
            isOpen={openForm}
            onClose={handleCloseForm}
            recruiterEdited={recruiterEdited}
            countries={countries}
            languageCompetence={languageCompetence}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(OtherExperiences))
);
