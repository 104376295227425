import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CandidateAbilityDate from '../../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateAbilityDate';
import CandidateExpectedSalary from '../../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateExpectedSalary';
import CandidateExperiences from '../../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateExperiences';
import CandidateHomeCountry from '../../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateHomeCountry';
import CandidateLanguages from '../../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidateLanguages';
import CandidatePreferenceWorkArea from '../../../../modules/candidate/NewCandidateDetails/components/CandidateAnonymousCard/CandidatePreferenceWorkArea';
import Translate from '../../Translate';
import { styles } from './PersonalData.style';

const PersonalData = ({ candidate, showCandidateData, t, classes }) => {
  const firstName = candidate.firstName
    ? candidate.firstName
    : t('candidateDetails:personalDetailsContent.noInfo');

  const lastName =
    showCandidateData && candidate.lastName
      ? candidate.lastName
      : t('candidateDetails:personalDetailsContent.noInfo');
  const homeCountry = candidate.homeCountry
    ? candidate.homeCountry.name
    : t('candidateDetails:personalDetailsContent.noInfo');

  return (
    <Grid container style={{ padding: '15px 0px' }}>
      <Grid item xs={10} sm={10} md={10}>
        <Translate
          text={'candidateDetails:personalDetailsContent.personalData'}
          bold
          style={{ fontSize: 26 }}
        />
      </Grid>
      <Grid container style={{ padding: '15px 0px' }}>
        <Grid item xs={12} sm={12} md={6}>
          <Translate
            text={`${t('candidateCreator:step1.firstName')}:`}
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Translate
            text={firstName}
            bold
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <hr className={classes.separator} />
      </Grid>
      <Grid container style={{ padding: '15px 0px', minWidth: 220 }}>
        <Grid item xs={12} sm={12} md={6}>
          <Translate
            text={`${t('candidateCreator:step1.lastName')}:`}
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Translate
            text={lastName}
            bold
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <hr className={classes.separator} />
      </Grid>
      <Grid container style={{ padding: '15px 0px' }}>
        <Grid item xs={12} sm={12} md={6}>
          <Translate
            text={`${t('candidateCreator:reviewStep2.homeCountry')}:`}
            disableTranslate={true}
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Translate
            text={homeCountry}
            style={
              candidate.homeCountry
                ? { fontSize: 20, fontWeight: 'bold' }
                : { fontSize: 20 }
            }
            disableTranslate={true}
          />
        </Grid>
      </Grid>
      <Grid container>
        <hr className={classes.separator} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CandidateExperiences candidate={candidate} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CandidateLanguages candidate={candidate} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CandidatePreferenceWorkArea candidate={candidate} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CandidateHomeCountry candidate={candidate} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CandidateExpectedSalary candidate={candidate} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CandidateAbilityDate candidate={candidate} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(PersonalData));
