import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { ProjectV2Service } from 'shared/services';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { checkProjectEditPermissions } from 'shared/utils/functions';
import { ProjectCreateForm } from './components';

const ProjectEditView = ({ history, match, t, ...props }) => {
  const [project, setProject] = useState({});
  const [fetchDone, setFetchDone] = useState(false);

  const getProjectById = id => {
    ProjectV2Service.getProjectV2(id)
      .then(({ data }) => {
        if (checkProjectEditPermissions(data)) {
          props.showSnackbarMessage(t`projectForm:noPermission`);
          history.push(PATHS.DASHBOARD.INDEX);
        }
        setProject(data);
        setFetchDone(true);
      })
      .catch(() => {
        //TODO: redirect to 404
        alert(t`addOrginzationForm:error`);
      });
  };

  useEffect(() => {
    shouldUpdateProject();
  }, []);

  const shouldUpdateProject = () => {
    const { id } = match.params;
    if (!id) {
      setFetchDone(true);
    } else {
      getProjectById(id);
    }
  };

  return fetchDone ? (
    <ProjectCreateForm
      project={project}
      shouldUpdateProject={shouldUpdateProject}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapDispatchToProps = {
  showSnackbarMessage,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectEditView)));
