import axios from 'axios';
import _ from 'lodash';
import { push } from 'react-router-redux';
import { PATHS } from 'config';
import { API, API_URL } from '../constants';
import {
  setCurrentOrganizationAction,
  setCurrentOrganizationsListAction,
  clearCurrentOrganizationAction,
  clearCurrentOrganizationsListAction,
} from '../store/organizations/actions';
import {
  setMyRecruitersAction,
  setRecruitersInOrganizationAction,
} from '../store/recruiters/actions';
import { getLocalStorageByKey } from '../utils/localStorage';
import { ApiService } from './api.service';
import { getRecruiters, getRecruitersWithLimit } from './recruiterService';

export const clearCurrentOrganization = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentOrganizationAction());
  };
};

export const clearCurrentOrganizationsList = () => {
  return async (dispatch, getState) => {
    dispatch(clearCurrentOrganizationsListAction());
  };
};

export const getOrganization = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ORGANIZATION.GET + '/' + id,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentOrganizationAction(data));
        return { data, status };
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response;
          if (status === 404) {
            dispatch(push(PATHS.STATUS.S404));
          }
          return { data: {}, status };
        }
        console.log(error);
        return { data: {}, status: {} };
      });
  };
};

export const getOrganizationRecruiters = id => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ORGANIZATION.RECRUITER.GET.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentOrganizationAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const getOrganizations = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.ORGANIZATION.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentOrganizationsListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

/*Delete recruiter from organization*/
export const deleteOrganizationRecruiter = (organizationId, recruiterId) => {
  return async dispatch => {
    return await axios({
      method: 'DELETE',
      url:
        API_URL +
        API.DELETE.ORGANIZATION.RECRUITER.DELETE.replace(
          ':id',
          organizationId
        ).replace(':recruiterID', recruiterId),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getOrganizationRecruitersFilteredForAdding = organizationId => {
  return async dispatch => {
    let allRecruiters = [];
    let myRecruiters = [];
    let organizationRecruiters = [];

    const getOrganizationRecruiters = async () => {
      organizationRecruiters = await axios({
        method: 'GET',
        url:
          API_URL +
          API.GET.ORGANIZATION.RECRUITER.GET.replace(':id', organizationId),
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    const getAllRecruiters = async () => {
      allRecruiters = await axios({
        method: 'GET',
        url: API_URL + API.GET.USER.GET,
        headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
        params: { type: 'recruiter' },
      })
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(error => {
          return {};
        });
    };
    await Promise.all([getAllRecruiters(), getOrganizationRecruiters()]);

    myRecruiters = _.differenceBy(allRecruiters, organizationRecruiters, 'id');

    dispatch(setMyRecruitersAction(myRecruiters));
    dispatch(setRecruitersInOrganizationAction(organizationRecruiters));
    return [myRecruiters, organizationRecruiters];
  };
};

export const getOrganizationRecruitersFilteredForAddingAsyncList = (
  organizationId,
  limit,
  offset,
  params
) => {
  return async (dispatch, getState) => {
    const myRecruiters = getRecruitersWithLimit(limit, offset, {
      withPublic: true,
      ...params,
      filters: { notInOrganization: organizationId, ...params.filters },
    })(dispatch, getState).then(data => data);
    const organizationRecruiters = getRecruiters({
      withPublic: true,
      filters: { inOrganization: organizationId },
    })(dispatch, getState).then(data => data);

    const recruitersLists = await Promise.all([
      myRecruiters,
      organizationRecruiters,
    ]);

    dispatch(setMyRecruitersAction(recruitersLists[0]));
    dispatch(setRecruitersInOrganizationAction(recruitersLists[1]));

    return {
      myRecruiters: recruitersLists[0],
      organizationRecruiters: recruitersLists[1],
    };
  };
};

export const createOrganization = values => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.ORGANIZATION.ADD,
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentOrganizationAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const editOrganization = values => {
  return async dispatch => {
    return await axios({
      method: 'PUT',
      url: API_URL + API.PUT.ORGANIZATION.EDIT.replace(':id', values.id),
      data: values,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setCurrentOrganizationAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 404) {
          dispatch(push(PATHS.STATUS.S404));
        }
        return { data: {}, status };
      });
  };
};

export const deleteOrganization = id => {
  return async (dispatch, getState) => {
    return await axios({
      method: 'DELETE',
      url: API_URL + API.DELETE.ORGANIZATION.DELETE.replace(':id', id),
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const OrganizationService = {
  getOrganizationAll: (params = {}) => {
    return ApiService.get(API.GET.ORGANIZATION.GET_ALL, params);
  },
  getOrganizationRecruiters: organizationId => {
    return ApiService.get(
      API.GET.ORGANIZATION.RECRUITER.GET.replace(':id', organizationId)
    );
  },
  addOrganization: (data, params = {}) => {
    return ApiService.post(API.POST.ORGANIZATION.ADD, data, params);
  },
  editOrganization: (id, data, params = {}) => {
    return ApiService.put(
      API.PUT.ORGANIZATION.EDIT.replace(':id', id),
      data,
      params
    );
  },
  editOrganizationAsset(id, body) {
    return ApiService.put(
      API.PUT.ORGANIZATION.EDIT_ASSETS.replace(':id', id),
      body
    );
  },
};
