import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';
import AutosuggestComponent from '../inputs/AutoSuggest';
import validate from '../validate/validateHelpDesk';

class AddSkillForm extends React.Component {
  render() {
    const {
      handleSubmit,
      handleUpdateSkill,
      pristine,
      isSubmitting,
      dictionariesSkills,
      withApproved,
      multiEdit,
      t,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(handleUpdateSkill)}>
        <AutosuggestComponent
          required={true}
          suggestionsList={dictionariesSkills}
          maxLength={255}
          fieldName={`name`}
          fieldLabel={t`addIndustryCategoryForm:name`}
        />
        <AutosuggestComponent
          required={true}
          suggestionsList={dictionariesSkills}
          maxLength={255}
          fieldName={`enName`}
          fieldLabel={t`addIndustryCategoryForm:nameEn`}
        />
        <AutosuggestComponent
          required={true}
          suggestionsList={dictionariesSkills}
          maxLength={255}
          fieldName={`deName`}
          fieldLabel={t`addIndustryCategoryForm:nameDe`}
        />
        <AutosuggestComponent
          required={true}
          suggestionsList={dictionariesSkills}
          maxLength={255}
          fieldName={`uaName`}
          fieldLabel={t`addIndustryCategoryForm:nameUa`}
        />
        {withApproved && (
          <Field
            name="isApproved"
            component={({ input }) => (
              <Select
                fullWidth
                {...input}
                onChange={event => input.onChange(event.target.value)}
              >
                <MenuItem
                  value={false}
                >{t`addCompetenceForm:pendingApproval`}</MenuItem>
                <MenuItem
                  value={true}
                >{t`addCompetenceForm:approved`}</MenuItem>
              </Select>
            )}
          ></Field>
        )}

        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
        >
          <ButtonJobllegrov1
            type="submit"
            color="primary"
            disabled={pristine || isSubmitting}
          >
            {multiEdit
              ? t`addCompetenceForm:accept&save`
              : t`addCompetenceForm:save`}
          </ButtonJobllegrov1>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      name: props.skill ? props.skill.name : '',
      enName: props.skill ? props.skill.enName : '',
      deName: props.skill ? props.skill.deName : '',
      uaName: props.skill ? props.skill.uaName : '',
      isApproved: props.skill ? props.skill.isApproved : true,
    },
  };
};

const AddSkillFormAction = reduxForm({
  form: 'addSkillForm',
  validate,
  enableReinitialize: true,
})(AddSkillForm);

export default connect(
  mapStateToProps,
  null
)(withTranslation()(AddSkillFormAction));
