import { createReducer } from '@reduxjs/toolkit';
import {
  setSummaryListAction,
  clearSummaryListAction,
  setSummaryListClosedAction,
} from './actions';

//reducer
const initialState = {
  list: [],
  listClosed: [],
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setSummaryListAction, (state, action) => {
      state.list = action.payload;
    })
    .addCase(clearSummaryListAction, (state, action) => {
      state.list = initialState.list;
    })
    .addCase(setSummaryListClosedAction, (state, action) => {
      state.listClosed = action.payload;
    })
);
