import { Grid, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  TextEditor,
  TextField,
  Translate,
  WizardStep,
} from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { isProjectClosed } from 'shared/utils/functions';
import {
  stage7NotSetFields,
  useFormStep6,
} from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { styles } from './ProjectCreateWizardStep6.styles';
import { MODEL } from './ProjectCreateWizardStep6.utils';

const ProjectCreateWizardStep6 = ({
  t,
  classes,
  initialProjectValues,
  setStep6FormValues,
  isStep6Open,
  isFormStepError,
  setIsStep6Open,
  setIsStep7Open,
  closeAll,
  isProjectSave,
  isThreeStageSaved,
  step7FormValues,
  setIsSnackbarErrorOpen,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const formik = useFormStep6({ t, initialProjectValues });

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep6FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  const [dataProcessingAgreementText, setDataProcessingAgreementText] =
    useState('');

  const [clauseGDPRText, setClauseGDPRText] = useState('');

  const myRef = useRef(null);

  const exampleProcessingAgreementText =
    `<p style="text-align:center;"><strong>ZGODA NA UDZIAŁ W PROCESIE REKRUTACJI</strong></p>\n` +
    '<p style="text-align:center;"><strong>TAK      NIE</strong>  Wyrażam zgodę na przetwarzanie danych osobowych zawartych mojej ofercie pracy w aktualnie prowadzonym procesie rekrutacji przez  <strong><em>[należy podać nazwę potencjalnego pracodawcy rekrutującego]</em></strong></p>\n' +
    '<p><strong>ZGODA NA UDZIAŁ W PROCESIE PRZYSZŁYCH REKRUTACJI</strong></p>\n' +
    '<p><strong>TAK      NIE</strong>  Wyrażam zgodę na przetwarzanie danych osobowych zawartych w mojej ofercie pracy <br> w przyszłych procesach rekrutacyjnych prowadzonych przez <strong><em>[należy podać nazwę potencjalnego pracodawcy rekrutującego] </em></strong>w okresie 12 miesięcy od czasu przekazania danych.</p>\n' +
    '<p>Dzięki tej zgodzie, będziemy mogli skontaktować się z Panią/Panem w czasie przyszłych rekrutacji, co zwiększy szanse na zatrudnienie.</p>\n' +
    '<p>Zgody są dobrowolne i mogą być w każdej chwili odwołane. Wystarczy wysłać taką informację na adres e-mail: <strong><em>[należy podać adres e-mail]</em></strong> lub bezpośrednio złożyć wniosek w siedzibie Administratora.</p>\n';

  const exampleClauseGDPRText =
    ' <p style="text-align:center;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">INFORMACJA DLA KANDYDATA DO PRACY</span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">przekazywana w wykonaniu obowiązków przewidzianych w Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako RODO).</span><br></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Kto jest administratorem danych?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Administratorem danych osobowych kandydata do pracy jest </span><span style="color: rgb(255,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">[należy podać nazwę potencjalnego pracodawcy rekrutującego]</span> <span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">z siedzibą w </span><span style="color: rgb(255,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">[należy podać adres]</span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"> (dalej jako ADO). </span><br></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Jak skontaktować się z administratorem danych?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Administrator umożliwia kontakt za pomocą telefonu: </span><span style="color: rgb(255,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">[należy podać nr telefonu]</span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">, e-maila: </span> <span style="color: rgb(255,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">[należy podać adres e-mail]</span><span style="color: rgb(51,51,51);background-color: rgb(255,255,255);font-size: 10pt;font-family: Calibri, sans-serif;">,</span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"> drogą pocztową: </span><span style="color: rgb(255,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">[należy podać adres]</span> <span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"> bądź bezpośrednio siedzibie ADO.</span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Jakie jest źródło danych?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Dane kandydata do pracy zostały pozyskane bezpośrednio od osoby, której dane dotyczą bądź od opiekuna kandydata w celu przeprowadzenia procesu rekrutacyjnego.</span><br></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">W jakim celu administrator przetwarza dane osobowe i na jakiej podstawie prawnej?</span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Administrator przetwarza dane osobowe podane w dokumentach aplikacyjnych w celu przeprowadzenia rekrutacji. Podstawą prawną przetwarzania danych kandydata – w zależności od formy zatrudnienia, której dotyczy rekrutacja - będzie:</span></p>\n' +
    '<ul>\n' +
    '<li><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">art. 6 ust. 1 lit. a RODO – zgoda na przetwarzanie danych - w przypadku przekazania dodatkowych danych          w CV niewymienionych w przepisach prawa,</span></li>\n' +
    '<li><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">art. 6 ust. 1 lit. b RODO – przetwarzanie jest niezbędne do podjęcia działań na żądnie osoby, której dane dotyczą, przed zawarciem umowy,</span></li>\n' +
    '<li><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">art. 6 ust. 1 lit. c RODO – </span><span style="color: rgb(0,0,0);background-color: rgb(0,0,0);font-size: 10pt;font-family: Calibri, sans-serif;">przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze w związku </span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">z art. 22</span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 0.6em;font-family: Calibri, sans-serif;">1 </span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">§1 kodeksu pracy i/lub (w przypadku dostarczenia danych wrażliwych art. 9 ust. 2 lit. b RODO) wykonania szczególnych praw kandydata zgodnie z ustawą  o rehabilitacji zawodowej i społecznej oraz zatrudnianiu osób niepełnosprawnych. Podanie danych wrażliwych następuje z inicjatywy kandydata i jest dobrowolne, </span><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 10pt;font-family: Calibri, sans-serif;">ale również konieczne do przyznania osobie z niepełnosprawnością ulg i świadczeń z tego tytułu</span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">.</span></li>\n' +
    '<li><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">art. 6 ust. 1 lit. f RODO - p</span><span style="color: rgb(18,20,22);background-color: rgb(0,0,0);font-size: 10pt;font-family: Calibri, sans-serif;">rzetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora, tj. </span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">ustalenia, dochodzenia lub obrony przed roszczeniami, jeżeli roszczenia dotyczą prowadzonej rekrutacji.</span><br></li>\n' +
    '</ul>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Jak długo administrator będzie przetwarzać dane osobowe?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Dane osobowe będą przetwarzane przez ADO do czasu zakończenia rekrutacji, tj. do 6 miesięcy od przekazania danych.</span><br></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Z kim administrator dzieli się danymi?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Administrator nie udostępni nikomu danych osobowych bez wyraźnej zgody kandydata do pracy, za wyjątkiem podmiotów, o których mowa niżej, tj.;</span></p>\n' +
    '<ul>\n' +
    '<li><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">osobom upoważnionym, działającym wewnątrz struktury Administratora, tj. pracownikom, współpracownikom, wolontariuszom, którzy muszą mieć dostęp do danych osobowych, aby wykonywać swoje obowiązki,</span></li>\n' +
    '<li><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">podmiotom, którym dane zostały powierzone, np. dostawcom systemów informatycznych i usług IT,</span></li>\n' +
    '<li><span style="color: rgb(0,0,0);background-color: rgb(0,0,0);font-size: 10pt;font-family: Calibri, sans-serif;">innym odbiorcom danych </span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">w zakresie i na zasadach określonych przepisami prawa,</span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 10pt;font-family: Calibri, sans-serif;"> np. uprawnionym organom</span><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">. </span></li>\n' +
    '</ul>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Czy administrator przekazuje dane do państw trzecich (poza Europejski Obszar Gospodarczy)?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Dane osobowe nie będą przekazywane do państwa trzeciego/organizacji międzynarodowej.</span><br></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Czy administrator podejmuje decyzje w sposób automatyczny w oparciu o profilowanie?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Administrator nie podejmuje decyzji opartych o zautomatyzowane przetwarzanie danych osobowych, w tym profilowaniu, wywołującej skutki prawne dla osoby, której dane są przetwarzane.</span><br></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;"><strong>Jakie są uprawnienia kandydata do pracy?</strong></span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Kandydatowi do pracy przysługuje prawo żądania dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, prawo do wycofania zgody. </span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">W przypadkach określonych przepisami prawa kandydatowi do pracy przysługuje prawo żądania usunięcia danych osobowych, żądania ograniczenia przetwarzania danych osobowych, prawo do przeniesienia danych osobowych, prawo wniesienie sprzeciwu wobec przetwarzania danych osobowych.</span></p>\n' +
    '<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 10pt;font-family: Calibri, sans-serif;">Kandydatowi do pracy przysługuje również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, gdy uzna, że ADO naruszył przepisy dotyczące ochrony danych osobowych.</span><br></p>\n';

  useEffect(() => {
    if (isStep6Open) {
      executeScroll(myRef);
    }
  }, [isStep6Open]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ScrollPoint refForward={myRef} />

      <WizardStep
        withSaveButton={!isProjectClosed(initialProjectValues)}
        title="projectCreator:step6.title"
        isOpen={isStep6Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep6Open(false);
            const openSecondStep = stage7NotSetFields(
              initialProjectValues,
              step7FormValues
            );
            setIsStep7Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        onOpen={() => {
          closeAll();
          setIsStep6Open(true);
        }}
        disableArrowButton={
          !(isProjectSave === true && isThreeStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isProjectSave === true && isThreeStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => setIsStep6Open(false)}
      >
        <Grid container>
          <Grid container style={{ padding: '0 10px' }}>
            <Grid container>
              <Translate
                text="projectCreator:step6.dataProcessingAgreement"
                variant="h6"
                bold
              />
            </Grid>
            <Grid container>
              <Translate
                text="projectCreator:step6.dataProcessingAgreementSubLabel"
                variant="body1"
              />
            </Grid>

            <Grid container style={{ paddingBottom: 16 }}>
              <Translate
                text="projectCreator:step6.dataProcessingAgreementBodyLabel"
                variant="body1"
              />
            </Grid>
            <Grid container style={{ padding: '10px 10px 0 10px' }}>
              <Grid item xs={12}>
                <Translate
                  text="projectCreator:step6.dataProcessingAgreementUrl"
                  variant="body1"
                />
              </Grid>
            </Grid>
            <Grid container>
              <TextField
                disabled={!!get(formik.values, MODEL.dataProcessingAgreement)}
                formik={formik}
                name={MODEL.dataProcessingAgreementUrl}
                label={t('projectCreator:step6.dataProcessingAgreementUrl')}
              />
            </Grid>
            <Grid
              container
              style={{ paddingBottom: 16, justifyContent: 'center' }}
            >
              <Translate text="projectCreator:step6.or" variant="body1" />
            </Grid>
            {!!!get(formik.values, MODEL.dataProcessingAgreementUrl) ? (
              <Grid container>
                <Translate
                  text="projectCreator:step6.example"
                  variant="body1"
                  uppercase
                  className={classes.link}
                  onMouseDown={() =>
                    setDataProcessingAgreementText(
                      exampleProcessingAgreementText
                    )
                  }
                  onMouseUp={() => setDataProcessingAgreementText('')}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container style={{ padding: '10px 10px 0 10px' }}>
            <Grid item xs={12}>
              <Translate
                text="projectCreator:step6.dataProcessingAgreementText"
                variant="body1"
              />
            </Grid>
          </Grid>
          <Grid container style={{ paddingBottom: 16 }}>
            <TextEditor
              disabled={!!get(formik.values, MODEL.dataProcessingAgreementUrl)}
              formik={formik}
              name={MODEL.dataProcessingAgreement}
              exampleText={dataProcessingAgreementText}
            />
          </Grid>
          <Grid container style={{ padding: '0 10px' }}>
            <Grid container>
              <Translate
                text="projectCreator:step6.clauseGDPR"
                variant="h6"
                bold
              />
            </Grid>
            <Grid container>
              <Translate
                text="projectCreator:step6.clauseGDPRSubLabel"
                variant="body1"
              />
            </Grid>
            <Grid container style={{ paddingBottom: 16 }}>
              <Translate
                text="projectCreator:step6.dataProcessingAgreementBodyLabel"
                variant="body1"
              />
            </Grid>
            <Grid container style={{ padding: '10px 10px 0 10px' }}>
              <Grid item xs={12}>
                <Translate
                  text="projectCreator:step6.dataProcessingAgreementUrl"
                  variant="body1"
                />
              </Grid>
            </Grid>
            <Grid container>
              <TextField
                disabled={!!get(formik.values, MODEL.clauseGDPR)}
                formik={formik}
                name={MODEL.clauseGDPRUrl}
                label={t('projectCreator:step6.clauseGDPRUrl')}
              />
            </Grid>
            <Grid
              container
              style={{ paddingBottom: 16, justifyContent: 'center' }}
            >
              <Translate text="projectCreator:step6.or" variant="body1" />
            </Grid>
            {!!!get(formik.values, MODEL.clauseGDPRUrl) ? (
              <Grid container>
                <Translate
                  text="projectCreator:step6.example"
                  variant="body1"
                  uppercase
                  className={classes.link}
                  onMouseDown={() => setClauseGDPRText(exampleClauseGDPRText)}
                  onMouseUp={() => setClauseGDPRText('')}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container style={{ padding: '10px 10px 0 10px' }}>
            <Grid item xs={12}>
              <Translate
                text="projectCreator:step6.dataProcessingAgreementText"
                variant="body1"
              />
            </Grid>
          </Grid>
          <Grid container>
            <TextEditor
              disabled={!!get(formik.values, MODEL.clauseGDPRUrl)}
              formik={formik}
              name={MODEL.clauseGDPR}
              exampleText={clauseGDPRText}
            />
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default withStyles(styles)(ProjectCreateWizardStep6);
