import { withStyles } from '@material-ui/core/styles';
import Main from './Main';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    background: '#f5f5f5',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: '#f5f5f5',
    marginLeft: theme.spacing(5),
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 'calc(100% - 90px)',
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 78,
      marginLeft: 0,
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      marginTop: 70,
      marginLeft: 0,
    },
  },
});

export default withStyles(styles, { withTheme: true })(Main);
