import { Grid, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ProgressBar, Translate } from 'shared/newComponents';
import { getDocumentFromSource } from 'shared/utils/assets';
import { getLevelProc } from 'shared/utils/functions';
import { styles } from './CandidateAllProjectsCard.styles.js';

const CandidateAllProjectsCard = ({ classes, candidateProjects, t }) => {
  return (
    <Grid
      container
      className={classes.wrapper}
      style={{ width: '100%', overflow: 'auto' }}
    >
      <Table stickyheader="true" className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('candidateDetails:allProjects.tablePosition')}
            </TableCell>
            <TableCell>
              {t('candidateDetails:allProjects.tableRecruitmentData')}
            </TableCell>
            <TableCell>
              {t('candidateDetails:allProjects.tableRecruitmentCurrentStage')}
            </TableCell>
            <TableCell>
              {t('candidateDetails:allProjects.tableRecruitmentBrief')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {candidateProjects.map(item => {
            const position = item.position;
            const client = item.client;
            const city = item.city;
            const isRemoteWork = item.isRemoteWork;
            const cityOrRemoteWork = isRemoteWork
              ? t('candidateDetails:allProjects.remoteWork')
              : city;
            const logoUrl = item.logo ? getDocumentFromSource(item.logo) : null;
            const dateUpdate = item.updatedAt;
            const feedback = item.feedback;
            const brief = item.brief;
            const { levelProc, desc } = getLevelProc(item, t);
            return (
              <TableRow key={item.id}>
                <TableCell align={'center'}>
                  <Grid container>
                    <Grid item xs={3} sm={3} md={3} style={{ minWidth: 50 }}>
                      <div style={{ display: 'flex', width: '10%' }}>
                        <img alt="logoUrl" src={logoUrl} height={50} />
                      </div>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Translate
                            text={position}
                            bold
                            disableTranslate={true}
                            style={{ fontSize: 26 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              style={{ minWidth: 50 }}
                            >
                              <Translate
                                text={client}
                                bold
                                disableTranslate={true}
                                style={{ fontSize: 16, color: '#868889' }}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Translate
                                text={cityOrRemoteWork}
                                bold
                                disableTranslate={true}
                                style={{ fontSize: 16, color: '#148EFF' }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={'center'}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        text={dateUpdate}
                        disableTranslate={true}
                        style={{ fontSize: 16, color: '#868889' }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={'center'}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <ProgressBar
                        percentage={levelProc}
                        title={desc}
                        customStyle={{ fontSize: 16, color: '#868889' }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={'center'}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Translate
                        text={ReactHtmlParser(brief)}
                        disableTranslate={true}
                        style={{ fontSize: 16, color: '#868889' }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(CandidateAllProjectsCard))
);
