export const styles = ({ palette, breakpoints }) => ({
  fontHeader: {
    fontSize: 26,
    color: '#2D333B',
  },
  fontBody: {
    fontSize: 24,
    color: '#2D333B',
  },
});
