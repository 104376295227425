import {
  AppBar,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import {
  getProject,
  addProjectRecruiter,
  deleteProjectRecruiter,
  getProjectRecruitersFilteredForAddingAsyncList,
} from 'shared/services/projectService';
import {
  clearMyRecruiters,
  clearRecruitersInProject,
  clearCurrentRecruitersList,
  clearCurrentRecruitersLimitedListCounter,
  clearCurrentRecruitersLimitedList,
} from 'shared/services/recruiterService';
import { getRecruiters } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import ConfirmationDialog from '../../components/LayoutConfirmationDialog/';
import ProjectDetailsViewLeftDescription from '../../components/ProjectDetailsViewLeftDescription';
import ProjectNextPrevButtons from '../../components/ProjectNextPrevButtons';
import ProjectRecruiters from './ProjectDetails/ProjectRecruiters';
import ProjectRightListRecruiters from './ProjectDetails/ProjectRightListRecruiters';
import ProjectMenu from './projectMenu/ProjectMenu';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    padding: '16px 150px',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
    marginTop: 8,
  },
  rightList: {
    marginTop: 25,
  },
  dialog: {
    width: '100%',
  },
  divider: {
    backgroundColor: theme.palette.jobllegroBorderGrey,
  },
});

export class ProjectRecruitersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmationOpened: false,
      filter: '',
      tabPage: 0,
      options: { limit: 12, offset: 0, filters: null, orderBy: null },
    };
  }

  addProjectRecruiter = async recruiterId => {
    this.setState({
      isLoading: true,
    });
    const { t } = this.props;
    try {
      const { id } = this.props.match.params;
      const { status } = await this.props.addProjectRecruiter(id, recruiterId);
      this.loadData(id);
      if (status !== 201) {
        this.props.showSnackbarMessage(t`assign:handleSelect`);
        return;
      }
      this.props.showSnackbarMessage(
        t('recruiter:form.recruiterWasAddedToProject')
      );
    } catch (err) {
      console.error(err);
    }
  };

  deleteProjectRecruiter = async recruiterId => {
    try {
      const { t } = this.props;
      const { id } = this.props.match.params;
      this.setState({
        isLoading: true,
      });
      await this.props.deleteProjectRecruiter(id, recruiterId);
      this.loadData(id);
      this.props.showSnackbarMessage(
        t('recruiter:form.recruiterWasDeletedToProject')
      );
    } catch (err) {
      console.error(err);
    }
  };

  loadData = async (id, options = false) => {
    const { limit, offset, filters, orderBy } = options || this.state.options;
    const { tabPage } = this.state;
    this.setState({
      isLoading: true,
    });

    let recruitersParams = {};
    if (tabPage === 1) {
      recruitersParams = { inOrganization: true };
    }
    let params = {
      type: 'recruiter',
      filters,
      orderBy,
      ...recruitersParams,
    };
    await this.props.getProjectRecruitersFilteredForAddingAsyncList(
      this.props.match.params.id,
      limit,
      offset,
      params
    );
    this.setState({
      isLoading: false,
    });
  };

  handleChangeTab = async (event, tabPage) => {
    await this.setState({
      tabPage,
    });
    const { id } = this.props.match.params;
    this.loadData(id);
  };

  handleReloadElements = (options = false) => {
    const { id } = this.props.match.params;
    if (options) {
      this.setState({
        options,
        isLoading: false,
      });
    }
    if (!this.state.isLoading) {
      return this.loadData(id, options || this.state.options);
    } else {
      return null;
    }
  };

  handleConfirmationClose = isOk => {
    if (isOk) {
      const { history, project } = this.props;
      history.push(PATHS.PROJECT.EDIT.replace(':id', project.id));
    }
    this.setState({
      confirmationOpened: false,
    });
  };

  handlePermissionView = () => {
    const { permissions = {} } = this.props.project;

    const {
      isOwner = false,
      isMentor = false,
      isSuperUser = false,
      isHiringManager = false,
    } = permissions;
    const { history } = this.props;
    const { id } = this.props.match.params;
    if (!(isOwner || isMentor || isHiringManager || isSuperUser)) {
      history.push(PATHS.PROJECT.SHOW.replace(':id', id));
    }
  };
  async componentDidMount() {
    const { id } = this.props.match.params;
    this.props.clearCurrentRecruitersList();
    this.props.getProject(id);
  }

  componentWillUnmount() {
    this.props.clearRecruitersInProject();
    this.props.clearMyRecruiters();
    this.props.clearCurrentRecruitersList();
    this.props.clearCurrentRecruitersLimitedList();
    this.props.clearCurrentRecruitersLimitedListCounter();
  }

  render() {
    const { classes, project, currentUser, t } = this.props;

    const { isLoading, tabPage } = this.state;

    const { permissions = {} } = project;

    if (_.isEmpty(permissions)) {
      return null;
    }

    this.handlePermissionView();
    return (
      <React.Fragment>
        {/*<PageHeader*/}
        {/*    title={t('recruiter:core.recruiters')}*/}
        {/*    showBackButton={true}*/}
        {/*    backButtonTarget={lastPage}*/}
        {/*/>*/}
        <Grid
          container
          alignItems="flex-start"
          spacing={0}
          className={classes.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ProjectMenu title={t('recruiter:core.recruiters')} />
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ProjectDetailsViewLeftDescription
                    title={t('project:show.candidatesInProject')}
                    project={project}
                  />
                </Grid>
                {isLoading ? (
                  <Grid
                    container
                    style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
                  >
                    <LinearProgress style={{ width: '100%' }} />
                  </Grid>
                ) : null}
              </Grid>
              <Divider className={classes.divider} />
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ProjectNextPrevButtons />
                  <ProjectRecruiters
                    isLoading={isLoading}
                    onShowListHandle={this.loadRecruiters}
                    onDeleteHandle={this.deleteProjectRecruiter}
                    handleReloadElements={this.handleReloadElements}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.rightList}
                >
                  {currentUser.organization ? (
                    <AppBar position="static" color="default">
                      <Tabs onChange={this.handleChangeTab} value={tabPage}>
                        <Tab label={t('project:show.allRecruiters')} />
                        <Tab label={t('project:show.organizationRecruiters')} />
                      </Tabs>
                    </AppBar>
                  ) : null}
                  <ProjectRightListRecruiters
                    storageKey={this.props.match.path}
                    handleReloadElements={this.handleReloadElements}
                    isLoading={isLoading}
                    userType="recruiter"
                    onAddRecruiterHandle={this.addProjectRecruiter}
                  />
                </Grid>
              </Grid>
            </Paper>
            <ConfirmationDialog
              classes={{
                paper: classes.dialog,
              }}
              open={this.state.confirmationOpened}
              onClose={this.handleConfirmationClose}
              titleText={t('project:show.cooperationTerms')}
              okButtonText={t('project:show.editProject')}
              cancelButtonText={t('action.close')}
            >
              {t('project:show.informationAreNotEstablished')}
              {permissions.isMentor &&
                t('project:show.informationAreNotEstablishedForMentor')}
            </ConfirmationDialog>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    project: store.projects.currentProject,
    lastPage: store.auth.lastPage,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getProject,
  getRecruiters,
  addProjectRecruiter,
  deleteProjectRecruiter,
  clearMyRecruiters,
  clearRecruitersInProject,
  clearCurrentRecruitersList,
  clearCurrentRecruitersLimitedListCounter,
  clearCurrentRecruitersLimitedList,
  getProjectRecruitersFilteredForAddingAsyncList,
  showSnackbarMessage,
};

ProjectRecruitersView.propTypes = {
  classes: PropTypes.object.isRequired,
  getProject: PropTypes.func.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  addProjectRecruiter: PropTypes.func.isRequired,
  deleteProjectRecruiter: PropTypes.func.isRequired,
  clearMyRecruiters: PropTypes.func.isRequired,
  clearRecruitersInProject: PropTypes.func.isRequired,
  clearCurrentRecruitersList: PropTypes.func.isRequired,
  clearCurrentRecruitersLimitedListCounter: PropTypes.func.isRequired,
  clearCurrentRecruitersLimitedList: PropTypes.func.isRequired,
  getProjectRecruitersFilteredForAddingAsyncList: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectRecruitersView)));
