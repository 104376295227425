import { withStyles } from '@material-ui/core/styles';
import LanguagesChange from './LanguagesChange';

const styles = () => ({
  list: {
    cursor: 'pointer',
  },
  icon: {
    width: 20,
    height: 20,
    border: '1px solid #eee',
  },
  listItem: {
    paddingLeft: 10,
  },
  circleLanguage: {
    fontSize: 10,
    float: 'left',
    width: 20,
    height: 20,
    cursor: 'pointer',
    margin: '0 2px 0 2px',
    transition: 'all .4s',
    textTransform: 'uppercase',
    opacity: 0.5,
    '&:hover': {
      margin: '5px 2px 0 2px',
      opacity: 1,
    },
  },
  circleActive: {
    margin: '5px 2px 0 2px',
    color: '#fff',
    opacity: 1,
  },
});

export default withStyles(styles)(LanguagesChange);
