import { Grid, Button, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import TextField from 'shared/newComponents/TextField';
import { styles } from './FilterOrders.styles';

const FilterOrders = ({ classes, setFilters, t, employmentManagement }) => {
  const validationSchema = yup.object({
    startDate: yup.string(),
    endDate: yup.string(),
    location: yup.string(),
    orderNumber: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      location: '',
      orderNumber: '',
    },
    validationSchema,
    onSubmit: ({ startDate, endDate, location, orderNumber }) => {
      const body = {
        createdAtStart: startDate !== '' ? startDate : undefined,
        createdAtEnd: endDate !== '' ? endDate : undefined,
        localizationString: location !== '' ? location : undefined,
        orderNumber: orderNumber !== '' ? orderNumber : undefined,
      };
      setFilters(body);
    },
  });

  return (
    <Grid container justifyContent="space-around">
      <Grid item xs={12} sm={12} md={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container>
                <TextField
                  label={t`projectOrderViewContent:orderId`}
                  name="orderNumber"
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container>
                <TextField
                  label={t`projectOrderViewContent:location`}
                  name="location"
                  formik={formik}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Grid container>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t`projectOrderViewContent:startDate`}
                  name="startDate"
                  type="date"
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Grid container>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t`projectOrderViewContent:endDate`}
                  name="endDate"
                  type="date"
                  formik={formik}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <Grid container>
                <TextField
                  label={t`projectOrderViewContent:orderId`}
                  name="orderId"
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Grid container>
                <TextField
                  label={
                    employmentManagement
                      ? t`employmentStatusTable:accommodation`
                      : t`projectOrderViewContent:recruitersName`
                  }
                  name={
                    employmentManagement ? 'recruitersSurname' : 'accommodation'
                  }
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Grid container>
                <Button
                  className={classes.filterSubmitBtn}
                  type="submit"
                  onClick={formik.handleSubmit}
                  fullWidth={true}
                >{t`projectOrderViewContent:showResultsBtn`}</Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FilterOrders);
