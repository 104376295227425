import { DialogContent, Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import MultiSelect from 'shared/newComponents/MultiSelect/MultiSelect';
import PostCode from 'shared/newComponents/PostCode';
import TextField from 'shared/newComponents/TextField';
import { countriesPhoneCode } from 'shared/utils/countriesPhoneCodes';
import { castObjectPhoneCodesForSelect } from 'shared/utils/functions';
import { styles } from './Location.styles';

const Location = ({ t, formik }) => {
  return (
    <DialogContent>
      <Grid container>
        <Grid item xs={12} sm={12} xl={12}>
          <TextField
            formik={formik}
            name="name"
            label={t`employmentManagementDialog:dialog2.nameOfLocationOwner`}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <TextField
            formik={formik}
            name="address"
            label={t`employmentManagementDialog:dialog2.street`}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <Grid container>
            <Grid item xs={12} sm={5} xl={5}>
              <PostCode
                formik={formik}
                name="postCode"
                label={t`employmentManagementDialog:dialog2.postalCode`}
              />
            </Grid>
            <Grid item xs={12} sm={7} xl={7}>
              <TextField
                formik={formik}
                name="city"
                label={t`employmentManagementDialog:dialog2.city`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <TextField
            formik={formik}
            name="email"
            label={t`employmentManagementDialog:dialog2.email`}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <Grid container>
            <Grid item xs={12} sm={5} xl={5}>
              <MultiSelect
                label={t('candidateCreator:step1.phoneCode')}
                name={'prefixPhone'}
                formik={formik}
                options={castObjectPhoneCodesForSelect(countriesPhoneCode)}
              />
            </Grid>
            <Grid item xs={12} sm={7} xl={7}>
              <TextField
                formik={formik}
                name="phone"
                label={t`employmentManagementDialog:dialog2.phone`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default withStyles(styles)(withTranslation()(Location));
