import { Grid, Paper, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { get, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { enums } from 'shared/enums';
import {
  Autocomplete,
  Checkbox,
  TextField,
  Translate,
  Button as NewButton,
  WizardStep,
  Snackbar,
} from 'shared/newComponents';
import PostCode from 'shared/newComponents/PostCode';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import { executeScroll } from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { OrganisationService } from 'shared/services';
import { isProjectClosed } from 'shared/utils/functions';
import CustomDialog from '../../../../../components/CustomDialog/index';
import RecruiterOrganizationTable from '../../../../../components/RecruiterOrganizationTable/index';
import { useFormStep8 } from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { styles } from './ProjectCreateWizardStep8.styles';
import { MODEL } from './ProjectCreateWizardStep8.utils';
import { FinicalPlanContainer } from './components/index';

const ProjectCreateWizardStep8 = ({
  initialProjectValues,
  t,
  isStep8Open,
  closeAll,
  setIsStep8Open,
  isProjectSave,
  classes,
  match,
  isThreeStageSaved,
  setStep8FormValues,
  setIsSnackbarErrorOpen,
  ...props
}) => {
  const getOrganizationToBe = initialProjectValues => {
    if (initialProjectValues.organizationToBe) {
      return initialProjectValues.organizationToBe;
    } else if (initialProjectValues.organization) {
      return initialProjectValues.organization;
    }
    return null;
  };
  const setDefaultValueOfOrganizationType = !(
    ((!!get(initialProjectValues, 'invoiceTypeBusiness') || false) &&
      !!get(initialProjectValues, 'invoiceTypeNaturalPerson')) ||
    false
  );

  const [finicalOrderPlanBody, setFinicalOrderPlanBody] = useState(null);

  const [
    disableEditOrganizationInvoiceDetails,
    setDisableEditOrganizationInvoiceDetails,
  ] = useState(false);

  const [dateSaved, setDateSaved] = useState(false);

  const [selectedOrganizationMentor, setSelectedOrganizationMentor] =
    useState(null);

  const formik = useFormStep8({
    t,
    initialProjectValues: {
      ...initialProjectValues,
      invoiceTypeBusiness:
        !!get(initialProjectValues, 'invoiceTypeBusiness') ||
        setDefaultValueOfOrganizationType,
      invoiceTypeNaturalPerson:
        !!get(initialProjectValues, 'invoiceTypeNaturalPerson') || false,
      organizationToBe: getOrganizationToBe(initialProjectValues),
    },
    finicalOrderPlanBody,
    setDateSaved,
    selectedOrganizationMentor,
  });

  const [organizations, setOrganizations] = useState([]);

  const [organizationsForAutoComplete, setOrganizationsForAutoComplete] =
    useState([]);

  const [finicalPlan, setFinicalPlan] = useState({});
  const [selectedOrganization, setSelectedOrganization] = useState(
    get(formik.values, `${MODEL.organizationToBe}`)
  );
  const [finicalPlanFetchStatus, setFinicalPlanFetchStatus] = useState(false);

  const [openWarrantyOrderDialog, setOpenWarrantyOrderDialog] = useState(false);
  const [openMentorOrderDialog, setOpenMentorOrderDialog] = useState(false);
  const [
    openChangeOrganizationMentorDialog,
    setOpenChangeOrganizationMentorDialog,
  ] = useState(false);

  const [organizationAcceptStatus, setOrganizationAcceptStatus] = useState(
    get(formik.values, 'organizationAcceptStatus')
  );

  const handleCloseWarrantyActionDialog = () => {
    setOpenWarrantyOrderDialog(false);
  };

  const handleCloseMentorActionDialog = () => {
    setOpenMentorOrderDialog(false);
  };

  const handleCloseChangeOrganizationMentorActionDialog = () => {
    setOpenChangeOrganizationMentorDialog(false);
  };

  const handleOpenChangeOrganizationMentorActionDialog = () => {
    setOpenChangeOrganizationMentorDialog(true);
  };

  const warrantyOrderValue = !!get(finicalPlan, 'warrantyOrderValue') || false;
  const mentorOrderValue = !!get(finicalPlan, 'mentorOrderValue') || false;

  const [selectWarrantyOrderValue, setSelectWarrantyOrderValue] =
    useState(warrantyOrderValue);
  const [selectMentorOrderValue, setSelectMentorOrderValue] =
    useState(mentorOrderValue);

  const mentorValue =
    get(finicalPlan, 'mentorValue') !== null &&
    get(finicalPlan, 'mentorValue') !== undefined
      ? get(finicalPlan, 'mentorValue').toFixed(2)
      : '';
  const warrantyValue =
    get(finicalPlan, 'warrantyValue') !== null &&
    get(finicalPlan, 'warrantyValue') !== undefined
      ? get(finicalPlan, 'warrantyValue').toFixed(2)
      : '';

  const permissions = get(initialProjectValues, 'permissions') || {};
  const [timeoutId, setTimeoutId] = useState(null);

  const isInvoiceTypeBusiness = !!get(formik.values, MODEL.invoiceTypeBusiness);

  const organization = get(formik.values, MODEL.organizationToBe);

  const isDraft = get(formik.values, 'isDraft');

  const organizationInformationSendStatus = get(
    initialProjectValues,
    'organizationInformationSendStatus'
  );

  const warrantyDisabledToBuy = props.currentUser.isSuperUser
    ? false
    : isNil(warrantyValue) ||
      warrantyValue === '' ||
      finicalPlanFetchStatus ||
      !isDraft;

  const mentorOrderDisabledToBuy = props.currentUser.isSuperUser
    ? false
    : isNil(mentorValue) ||
      mentorValue === '' ||
      finicalPlanFetchStatus ||
      !isDraft;

  const handleOrderWarrantyAction = () => {
    if (warrantyDisabledToBuy) {
      return;
    }
    editFinicalOrderPlan({
      mentorOrderValue: selectMentorOrderValue,
      warrantyOrderValue: !selectWarrantyOrderValue,
    });
    handleCloseWarrantyActionDialog();
  };

  const handleOrderMentorAction = () => {
    if (mentorOrderDisabledToBuy) {
      return;
    }

    editFinicalOrderPlan({
      mentorOrderValue: !selectMentorOrderValue,
      warrantyOrderValue: selectWarrantyOrderValue,
    });

    handleCloseMentorActionDialog();
  };

  const myRef = useRef(null);

  const editFinicalOrderPlan = body => {
    setFinicalOrderPlanBody(body);
    setFinicalPlanFetchStatus(true);

    setSelectWarrantyOrderValue(body.warrantyOrderValue);
    setSelectMentorOrderValue(body.mentorOrderValue);
    setFinicalPlanFetchStatus(false);
  };

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep8FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  useEffect(() => {
    if (get(initialProjectValues, `${MODEL.organizationMentor}`)) {
      setSelectedOrganizationMentor(
        get(initialProjectValues, `${MODEL.organizationMentor}`)
      );
    } else if (get(formik.values, `${MODEL.organizationToBe}.owner`)) {
      setSelectedOrganizationMentor(
        get(formik.values, `${MODEL.organizationToBe}.owner`)
      );
    }
  }, [initialProjectValues]);

  useEffect(() => {
    const id = match.params.id;

    if (!!id) {
      if (selectedOrganization && selectedOrganization.id) {
        if (
          initialProjectValues.organizationToBe &&
          initialProjectValues.organizationToBe.id === selectedOrganization.id
        ) {
          setFinicalPlanFormRequestData(initialProjectValues);
        } else {
          if (
            initialProjectValues.organizationToBe &&
            initialProjectValues.organizationToBe.id ===
              selectedOrganization.id &&
            initialProjectValues.organizationMentor
          ) {
            setSelectedOrganizationMentor(
              initialProjectValues.organizationMentor
            );
          } else {
            setSelectedOrganizationMentor(selectedOrganization.owner);
          }

          OrganisationService.getFinicalPlanOrganization(
            id,
            selectedOrganization.id
          )
            .then(({ data }) => {
              setFinicalPlanFormRequestData(data);
            })
            .catch(error => console.log(error));
        }
      } else {
        setSelectedOrganizationMentor(null);
        OrganisationService.getFinicalPlan(id)
          .then(({ data }) => {
            setFinicalPlanFormRequestData(data);
          })
          .catch(error => console.log(error));
      }
      setMentorAndWarrantyFields(null);
    }
  }, [selectedOrganization]);

  const setOrganizationField = organizationOnList => {
    if (organizationOnList) {
      setSelectedOrganization(organizationOnList);
      formik.setFieldValue(
        MODEL.invoiceAddress,
        organizationOnList.invoiceAddress
      );
      formik.setFieldValue(
        MODEL.invoicePostCode,
        organizationOnList.invoicePostCode
      );
      formik.setFieldValue(MODEL.invoiceCity, organizationOnList.invoiceCity);

      formik.setFieldValue(
        MODEL.accountNumber,
        organizationOnList.accountNumber
      );
      formik.setFieldValue(
        MODEL.accountNumber,
        organizationOnList.accountNumber
      );
      if (
        organizationOnList.organizationType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
      ) {
        formik.setFieldValue(
          MODEL.invoiceIdentificationNumber,
          organizationOnList.invoiceIdentificationNumber
        );
      } else {
        formik.setFieldValue(
          MODEL.invoicePersonalNumber,
          organizationOnList.invoicePersonalNumber
        );
      }
      setDisableEditOrganizationInvoiceDetails(true);
    }
  };
  useEffect(() => {
    setDisableEditOrganizationInvoiceDetails(false);
    setSelectedOrganization(null);
    if (formik.values.organizationToBe && formik.values.organizationToBe.id) {
      const organizationOnList = organizations.find(
        item => item.id === formik.values.organizationToBe.id
      );

      setOrganizationField(
        organizationOnList ? organizationOnList : formik.values.organizationToBe
      );
    }
  }, [formik.values]);

  useEffect(() => {
    OrganisationService.getOrganisations()
      .then(({ data }) => setOrganizations(data))
      .catch(error => console.log(error));
  }, []);

  const setFinicalPlanFormRequestData = data => {
    setFinicalPlan(data);
  };

  const setMentorAndWarrantyFields = data => {
    const warrantyOrderValue = !!get(data, 'warrantyOrderValue') || false;
    const mentorOrderValue = !!get(data, 'mentorOrderValue') || false;
    setSelectWarrantyOrderValue(warrantyOrderValue);
    setSelectMentorOrderValue(mentorOrderValue);
  };

  useEffect(() => {
    if (isStep8Open) {
      executeScroll(myRef);
    }
  }, [isStep8Open]);

  useEffect(() => {
    setMentorAndWarrantyFields(initialProjectValues);
  }, []);

  useEffect(() => {
    if (initialProjectValues.organizationToBe) {
      if (
        initialProjectValues.organizationToBe.organizationType ===
        enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
      ) {
        formik.setFieldValue(MODEL.invoiceTypeBusiness, true);
        formik.setFieldValue(MODEL.invoiceTypeNaturalPerson, false);
      } else {
        formik.setFieldValue(MODEL.invoiceTypeBusiness, false);
        formik.setFieldValue(MODEL.invoiceTypeNaturalPerson, true);
      }
    }
  }, [initialProjectValues.organizationToBe]);

  useEffect(() => {
    const currentOrganizationType = formik.values.invoiceTypeBusiness
      ? enums.ORGANIZATION_TYPES.ORGANIZATION_BUSINESS_TYPE
      : enums.ORGANIZATION_TYPES.ORGANIZATION_PERSONAL_TYPE;
    const filterOrganizationByType = organizations.filter(
      item => item.organizationType === currentOrganizationType
    );
    setOrganizationsForAutoComplete(filterOrganizationByType);
  }, [organizations, formik.values.invoiceTypeBusiness]);

  const clearOrganizationForm = () => {
    formik.setFieldValue(MODEL.organizationToBe, null);
    formik.setFieldValue(MODEL.invoiceIdentificationNumber, '');
    formik.setFieldValue(MODEL.invoiceAddress, '');
    formik.setFieldValue(MODEL.invoicePostCode, '');
    formik.setFieldValue(MODEL.accountNumber, '');
    formik.setFieldValue(MODEL.invoicePersonalNumber, '');
    formik.setFieldValue(MODEL.invoiceCity, '');
    setFinicalOrderPlanBody({
      mentorOrderValue: false,
      warrantyOrderValue: false,
    });
  };

  const disableEditOrganization =
    !(permissions.isOwner && isDraft) || organizationInformationSendStatus;

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />

      <WizardStep
        title="projectCreator:step8.title"
        isOpen={isStep8Open}
        required
        onOpen={() => {
          closeAll();
          setIsStep8Open(true);
        }}
        disableArrowButton={
          !(isProjectSave === true && isThreeStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isProjectSave === true && isThreeStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        withSaveButton={!isProjectClosed(initialProjectValues)}
        onCloseSelf={() => setIsStep8Open(false)}
      >
        <Grid container>
          <Grid container>
            <Translate text="projectCreator:step8.subTitle" />
          </Grid>
          <Grid container>
            <Grid
              container
              justifyContent={'space-around'}
              alignItems={'center'}
            >
              <Grid item xs={6} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={3}>
                    <Checkbox
                      onChange={event => {
                        if (!event.target.checked || disableEditOrganization) {
                          return;
                        }
                        if (
                          event.target.checked !== formik.invoiceTypeBusiness
                        ) {
                          clearOrganizationForm();
                        }
                        formik.setFieldValue(MODEL.invoiceTypeBusiness, true);
                        formik.setFieldValue(
                          MODEL.invoiceTypeNaturalPerson,
                          false
                        );
                      }}
                      label={t('projectCreator:step8.organizationTypeBusiness')}
                      name={MODEL.invoiceTypeBusiness}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={5}>
                    <Checkbox
                      onChange={event => {
                        if (!event.target.checked || disableEditOrganization) {
                          return;
                        }
                        if (
                          event.target.checked !==
                          formik.invoiceTypeNaturalPerson
                        ) {
                          clearOrganizationForm();
                        }
                        formik.setFieldValue(MODEL.invoiceTypeBusiness, false);
                        formik.setFieldValue(
                          MODEL.invoiceTypeNaturalPerson,
                          true
                        );
                      }}
                      label={t(
                        'projectCreator:step8.organizationTypeNaturalPerson'
                      )}
                      name={MODEL.invoiceTypeNaturalPerson}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  {organizationAcceptStatus === 0 && (
                    <Translate
                      style={{ color: 'orange' }}
                      text={'projectCreator:step8.waitToApprove'}
                    />
                  )}
                  {organizationAcceptStatus === 1 && (
                    <Translate
                      style={{ color: 'green' }}
                      text={'projectCreator:step8.approved'}
                    />
                  )}
                  {organizationAcceptStatus === 2 && (
                    <Translate
                      style={{ color: 'red' }}
                      text={'projectCreator:step8.reject'}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Autocomplete
                    disabled={disableEditOrganization}
                    label={
                      isInvoiceTypeBusiness
                        ? t('projectCreator:step8.organizationName')
                        : t('projectCreator:step8.organizationNamePersonal')
                    }
                    name={MODEL.organizationToBe}
                    items={organizationsForAutoComplete}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    disabled={disableEditOrganizationInvoiceDetails}
                    label={t('projectCreator:step8.organizationAddress')}
                    name={MODEL.invoiceAddress}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <PostCode
                    formik={formik}
                    name={MODEL.invoicePostCode}
                    disabled={disableEditOrganizationInvoiceDetails}
                    label={t('projectCreator:step8.organizationPostCode')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    disabled={disableEditOrganizationInvoiceDetails}
                    label={t('projectCreator:step8.organizationCity')}
                    name={MODEL.invoiceCity}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    disabled={disableEditOrganizationInvoiceDetails}
                    label={t('recruiter:field.accountNumber')}
                    name={MODEL.accountNumber}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  {isInvoiceTypeBusiness ? (
                    <TextField
                      disabled={disableEditOrganizationInvoiceDetails}
                      label={t(
                        'projectCreator:step8.invoiceIdentificationNumber'
                      )}
                      name={MODEL.invoiceIdentificationNumber}
                      formik={formik}
                    />
                  ) : (
                    <TextField
                      disabled={disableEditOrganizationInvoiceDetails}
                      label={t('projectCreator:step8.invoicePersonalNumber')}
                      name={MODEL.invoicePersonalNumber}
                      formik={formik}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {selectedOrganization && selectedOrganization.owner ? (
            <Grid container>
              <Grid container>
                <Grid item>
                  <Translate
                    text={t`projectCreator:step8.orgOwner`}
                    bold
                    variant="h6"
                  />
                </Grid>
              </Grid>
              <Paper className={classNames(classes.paper)}>
                <Grid
                  container
                  justifyContent={'space-between'}
                  className={classes.paperWrapper}
                >
                  {selectedOrganizationMentor ? (
                    <Grid item>
                      <Grid item>
                        <Translate
                          style={{ fontSize: 16 }}
                          disableTranslate={true}
                          text={`${selectedOrganizationMentor.firstName} ${selectedOrganizationMentor.lastName}`}
                          bold
                        />
                      </Grid>
                      <Grid item>
                        <Translate
                          style={{ fontSize: 16 }}
                          disableTranslate={true}
                          text={`${selectedOrganizationMentor.email}`}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {disableEditOrganization ? null : (
                    <Grid item xs={12} sm={12} md={4}>
                      <NewButton
                        onClick={handleOpenChangeOrganizationMentorActionDialog}
                        full={true}
                        label={t`assign:change`}
                      />
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <Grid container style={{ padding: 16 }}>
                <Grid item>
                  <Translate
                    style={{ fontSize: 16 }}
                    disableTranslate={true}
                    text={
                      ` ${t`projectCreator:step8.invoiceInfo1`}\n` +
                      `                  ${t`projectCreator:step8.invoiceInfo1`}`
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {!isNil(finicalPlan) && (
            <FinicalPlanContainer
              finicalPlan={finicalPlan}
              selectWarrantyOrderValue={selectWarrantyOrderValue}
              finicalPlanFetchStatus={finicalPlanFetchStatus}
              isDraft={isDraft}
              setOpenWarrantyOrderDialog={setOpenWarrantyOrderDialog}
              selectMentorOrderValue={selectMentorOrderValue}
              setOpenMentorOrderDialog={setOpenMentorOrderDialog}
              organization={organization}
            />
          )}
        </Grid>
        <CustomDialog
          title={t('projectCreator:step8.orderDialogHeader')}
          onCloseDialog={handleCloseWarrantyActionDialog}
          body={<Translate text="projectCreator:step8.orderWarrantyDialog" />}
          isOpen={openWarrantyOrderDialog && !warrantyDisabledToBuy}
          dialogActions={
            <>
              <Button color={'primary'} onClick={handleOrderWarrantyAction}>
                <Translate text="core:general.yes" />
              </Button>
              <Button
                color={'primary'}
                autoFocus
                onClick={handleCloseWarrantyActionDialog}
              >
                <Translate text="core:general.no" />{' '}
              </Button>
            </>
          }
        />
        <CustomDialog
          title={t('projectCreator:step8.orderDialogHeader')}
          onCloseDialog={handleCloseMentorActionDialog}
          body={<Translate text="projectCreator:step8.orderMentorDialog" />}
          isOpen={openMentorOrderDialog && !mentorOrderDisabledToBuy}
          dialogActions={
            <>
              <Button color={'primary'} onClick={handleOrderMentorAction}>
                <Translate text="core:general.yes" />{' '}
              </Button>
              <Button
                color={'primary'}
                autoFocus
                onClick={handleCloseMentorActionDialog}
              >
                <Translate text="core:general.no" />{' '}
              </Button>
            </>
          }
        />
        <CustomDialog
          maxWidth={'lg'}
          title={t`projectCreator:step8.choosePatron`}
          onCloseDialog={handleCloseChangeOrganizationMentorActionDialog}
          body={<Translate text="" />}
          isOpen={openChangeOrganizationMentorDialog}
          dialogActions={
            <RecruiterOrganizationTable
              project={initialProjectValues}
              organization={selectedOrganization}
              setSelectedOrganizationMentor={setSelectedOrganizationMentor}
              onClose={handleCloseChangeOrganizationMentorActionDialog}
            />
          }
        />
      </WizardStep>
      <Snackbar
        open={dateSaved}
        onClose={() => setDateSaved(false)}
        label={t('projectCreator:core.sectionHasSaved')}
      />
    </form>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(ProjectCreateWizardStep8))
);
