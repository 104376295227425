import axios from 'axios';
import { API, API_URL } from '../constants';
import { setStagesListAction } from '../store/stages/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getStages = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.STAGE.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(setStagesListAction(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
