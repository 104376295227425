import { object, array, string, number } from 'yup';

export const MODEL = {
  userExperience: 'userExperiences',
  position: 'position',
  industryCategory: 'industryCategory',
  companySize: 'companySize',
  companyName: 'companyName',
  experienceLevel: 'experienceLevel',
  startWorkYear: 'startWorkYear',
  experience: 'experience',
  userSkills: 'userSkills',
  userLanguageSkills: 'userLanguageSkills',
  competence: 'competence',
  competenceCategory: 'competence.competenceCategory',
  competenceType: 'competence.competenceType',
  competenceKind: 'competence.competenceKind',
  skill: 'skill',
  skillName: 'skill.name',
};

export const initialValues = {
  userExperiences: [],
  userSkills: [],
  userLanguageSkills: [],
};

export const validationSchema = t =>
  object().shape({
    userExperiences: array()
      .min(0, t('projectCreator:core.requiredField'))
      .of(
        object().shape({
          position: string().strict(false).trim().nullable(),
          experienceLevel: number()
            .min(0, t('projectCreator:core.minValue', { value: 0 }))
            .nullable(),
          startWorkYear: number().nullable(),
          companySize: number().nullable(),
          industryCategory: object()
            .shape({
              id: number(),
            })
            .nullable(),
        })
      )
      .nullable(),

    userSkills: array()
      .min(0, t('projectCreator:core.requiredField'))
      .of(
        object().shape({
          competence: object().shape({
            competenceType: object().shape({
              name: string()
                .strict(false)
                .trim()
                .required(t('projectCreator:core.requiredField')),
            }),
          }),
          experience: number().nullable(),
          skill: object().shape({
            name: string().strict(false).trim().nullable(),
          }),
        })
      )
      .nullable(),
    userLanguageSkills: array()
      .min(0, t('projectCreator:core.requiredField'))
      .of(
        object().shape({
          skill: object().shape({
            id: number().nullable(),
          }),
          experience: number()
            .nullable()
            .when('skill', {
              is: skill => Object.keys(skill).length > 0,
              then: number().required(t('projectCreator:core.requiredField')),
            }),
        })
      )
      .nullable(),
  });
