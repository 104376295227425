import createPalette, {
  PaletteOptions,
} from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    primary: string;
    secondary: string;
  }

  interface Palette {
    changeRecruiter: Palette['primary'];
    jobllegroBlue: string;
    jobllegroRedAlert: string;
    jobllegroLightGrey: string;
    jobllegroSuperLightGrey: string;
    jobllegroSuccess: string;
    jobllegroSuccessActive: string;
    jobllegroGrey: string;
    jobllegroDarkGrey: string;
    jobllegroBorderGrey: string;
    jobllegroUltraLightGrey: string;
    jobllegroOrange: string;
  }

  interface PaletteOptions {
    changeRecruiter: PaletteOptions['primary'];
    jobllegroBlue: string;
    jobllegroRedAlert: string;
    jobllegroLightGrey: string;
    jobllegroSuperLightGrey: string;
    jobllegroSuccess: string;
    jobllegroSuccessActive: string;
    jobllegroGrey: string;
    jobllegroDarkGrey: string;
    jobllegroBorderGrey: string;
    jobllegroUltraLightGrey: string;
    jobllegroOrange: string;
  }
}

const palette: PaletteOptions = {
  type: 'light',
  primary: {
    main: '#148eff',
    contrastText: '#fff',
  },
  secondary: {
    main: '#01d38b',
  },
  success: {
    main: '#01d38b',
  },
  changeRecruiter: {
    main: '#FA5742',
  },
  error: {
    main: '#f41010',
  },
  warning: {
    main: '#feeeb3',
  },
  text: {
    secondary: '#96999d',
  },
  jobllegroBlue: '#52a3fc',
  jobllegroRedAlert: '#e86958',
  jobllegroLightGrey: '#b0b0b0',
  jobllegroSuperLightGrey: '#f3f3f3',
  jobllegroSuccess: '#00D298',
  jobllegroSuccessActive: '#02dd9f',
  jobllegroGrey: '#575757',
  jobllegroDarkGrey: '#424242',
  jobllegroBorderGrey: '#ebebef',
  jobllegroUltraLightGrey: '#f6f6fa',
  jobllegroOrange: '#fcc93c',
};

export default createPalette(palette);
