import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import { t } from 'i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
// import { Select, TextField } from 'redux-form-material-ui';
import { changeFieldValue } from 'shared/services/formService';
import { int0to99Normalization } from 'shared/utils/normalizers';
import {
  ButtonJobllegrov1,
  ButtonJobllegrov4,
} from 'shared/utils/styledComponents';
import { required } from 'shared/utils/validationRegexTest';
import AutosuggestComponent from './AutoSuggest';

const styles = () => ({
  card: {
    width: '100%',
  },
  selectField: {
    paddingTop: 16,
    width: 'calc(100% - 10px)',
    marginLeft: '10px',
  },
  inputField: {
    width: 'calc(100% - 10px)',
    marginLeft: '10px',
  },
});

export class SkillsInputField extends Component {
  state = {
    arrayOfConfigProjectSkills: [],
    dictionariesCompetenceCategories: [],
    dictionariesIndustries: [],
    isLoaded: false,
  };

  removeItemOfConfigProjectSkill = index => {
    const newConfigProjectSkill = this.state.arrayOfConfigProjectSkills.filter(
      item => item.index !== index
    );
    newConfigProjectSkill.forEach((item, index) => {
      item.index = index;
    });
    const newState = [...newConfigProjectSkill];
    this.setState({
      arrayOfConfigProjectSkills: [...newState],
    });
  };

  createNewItemOfConfigProjectSkills = async () => {
    let lastIndex = 0;
    if (this.state.arrayOfConfigProjectSkills.length) {
      const stateIndex =
        this.state.arrayOfConfigProjectSkills[
          this.state.arrayOfConfigProjectSkills.length - 1
        ];
      lastIndex = stateIndex.index + 1;
    }

    const newItem = {
      index: lastIndex,
      competenceTypeDisabled: true,
      competenceKindDisabled: true,
      skillsDisabled: true,
      industryDisabled: true,
      dictionariesSkills: [],
      dictionariesCompetenceTypes: [],
      dictionariesCompetenceKinds: [],
      selectedCompetenceCategory: null,
      selectedCompetenceType: null,
      selectedCompetenceKind: null,
    };
    const newState = [...this.state.arrayOfConfigProjectSkills, newItem];

    await this.setState({
      arrayOfConfigProjectSkills: [...newState],
    });
  };
  async componentDidMount() {
    const newCompetenceCategories = [];
    this.props.DictionariesCompetenceTemp.forEach(item => {
      if (
        !newCompetenceCategories.find(
          newCompetenceCategory =>
            newCompetenceCategory.id === item.competenceCategory.id
        )
      ) {
        newCompetenceCategories.push(item.competenceCategory);
      }
    });
    this.setState({
      dictionariesIndustries: this.props.DictionariesIndustries,
      dictionariesCompetenceCategories: newCompetenceCategories,
    });
    const { fields } = this.props;

    await this.prepareFields(fields);
  }

  setCompetenceAndSkill = async (item, isFirstLoad, index) => {
    if (item.competence === null) {
      if (index === this.props.formValues[this.props.fieldName].length - 1) {
        await this.setState({
          isLoaded: true,
        });
      }
      return;
    }
    if (item.competence.competenceCategory) {
      await this.onChangeHandle(
        this.props.formName,
        `${this.props.fieldName}[${index}].competence.competenceCategory.name`,
        item.competence.competenceCategory.name,
        index,
        isFirstLoad
      );
    }
    if (item.competence.competenceType) {
      await this.onChangeHandle(
        this.props.formName,
        `${this.props.fieldName}[${index}].competence.competenceType.name`,
        item.competence.competenceType.name,
        index,
        isFirstLoad
      );
    }
    if (item.competence.competenceKind) {
      await this.onChangeHandle(
        this.props.formName,
        `${this.props.fieldName}[${index}].competence.competenceKind.name`,
        item.competence.competenceKind.name,
        index,
        isFirstLoad
      );
    }
    if (item.skill) {
      await this.onChangeHandle(
        this.props.formName,
        `${this.props.fieldName}[${index}].skill.name`,
        item.skill.name,
        index,
        isFirstLoad
      );
    }

    if (index === this.props.formValues[this.props.fieldName].length - 1) {
      await this.setState({
        isLoaded: true,
      });
    }
  };

  prepareFields = async (isFirstLoad = true) => {
    if (
      !this.props.formValues[this.props.fieldName] ||
      this.props.formValues[this.props.fieldName].length === 0
    ) {
      await this.setState({
        isLoaded: true,
      });
      return;
    }

    for (const item of this.props.formValues[this.props.fieldName]) {
      await this.createNewItemOfConfigProjectSkills();
    }

    let index = 0;
    for (const item of this.props.formValues[this.props.fieldName]) {
      if (Object.keys(item).length !== 0) {
        await this.setCompetenceAndSkill(item, isFirstLoad, index);
      }
      index++;
    }

    await this.setState({
      isLoaded: true,
    });
  };

  getConfigProjectSkillsIndex = index =>
    this.state.arrayOfConfigProjectSkills.findIndex(
      item => item.index === index
    );

  onChangeHandle = async (
    formName,
    fieldName,
    newValue,
    index,
    firstLoad = false
  ) => {
    const configProjectSkillsIndex = this.getConfigProjectSkillsIndex(index);
    const configProjectSkills = {
      ...this.state.arrayOfConfigProjectSkills[configProjectSkillsIndex],
    };

    const notUsedState = this.state.arrayOfConfigProjectSkills.filter(
      item => item.index !== index
    );
    if (
      fieldName ===
      `${this.props.fieldName}[${index}].competence.competenceCategory.name`
    ) {
      if (newValue.trim().length !== 0) {
        configProjectSkills.competenceTypeDisabled = false;
        configProjectSkills.competenceKindDisabled = true;
        configProjectSkills.skillsDisabled = true;
        configProjectSkills.industryDisabled = true;

        const competenceCategoryId =
          this.props.DictionariesCompetenceCategories.find(
            competenceCategory => competenceCategory.name === newValue
          );
        if (typeof competenceCategoryId === 'undefined') {
          configProjectSkills.dictionariesCompetenceTypes = [];
          configProjectSkills.selectedCompetenceCategory = null;
          const newState = [...notUsedState, configProjectSkills];
          await this.setState({
            arrayOfConfigProjectSkills: [...newState],
          });
          return false;
        }
        configProjectSkills.selectedCompetenceCategory = competenceCategoryId;

        const newDictionariesCompetenceTypes = [];

        this.props.DictionariesCompetenceTemp.forEach(competenceTemp => {
          if (
            competenceTemp.competenceCategory.id === competenceCategoryId.id
          ) {
            if (
              !newDictionariesCompetenceTypes.find(
                item => item.id === competenceTemp.competenceType.id
              )
            ) {
              newDictionariesCompetenceTypes.push(
                competenceTemp.competenceType
              );
            }
          }
        });
        configProjectSkills.dictionariesCompetenceTypes =
          newDictionariesCompetenceTypes;
        const newState = [...notUsedState, configProjectSkills];
        await this.setState({
          arrayOfConfigProjectSkills: [...newState],
        });
      } else {
        configProjectSkills.competenceTypeDisabled = true;
        configProjectSkills.competenceKindDisabled = true;
        configProjectSkills.skillsDisabled = true;
        configProjectSkills.industryDisabled = true;

        const newState = [...notUsedState, configProjectSkills];

        await this.setState({
          arrayOfConfigProjectSkills: [...newState],
        });
      }
      if (firstLoad) {
        return;
      }
      this.props.changeFieldValue(
        formName,
        `${this.props.fieldName}[${index}].competence.competenceType.name`,
        ''
      );
      this.props.changeFieldValue(
        formName,
        `${this.props.fieldName}[${index}].competence.competenceKind.name`,
        ''
      );
      this.props.changeFieldValue(
        formName,
        `${this.props.fieldName}[${index}].skill.name`,
        ''
      );
    }
    if (
      fieldName ===
      `${this.props.fieldName}[${index}].competence.competenceType.name`
    ) {
      if (newValue.trim().length !== 0) {
        configProjectSkills.competenceTypeDisabled = false;
        configProjectSkills.competenceKindDisabled = false;
        configProjectSkills.skillsDisabled = true;
        configProjectSkills.industryDisabled = true;

        const newDictionariesCompetenceKinds = [];

        const competenceTypeId = this.props.DictionariesCompetenceTypes.find(
          competenceType => competenceType.name === newValue
        );
        if (typeof competenceTypeId === 'undefined') {
          configProjectSkills.dictionariesCompetenceKinds = [];
          configProjectSkills.selectedCompetenceType = null;
          const newState = [...notUsedState, configProjectSkills];
          await this.setState({
            arrayOfConfigProjectSkills: [...newState],
          });
          return false;
        }
        configProjectSkills.selectedCompetenceType = competenceTypeId;
        this.props.DictionariesCompetenceTemp.forEach(competenceTemp => {
          if (
            competenceTemp.competenceCategory &&
            configProjectSkills.selectedCompetenceCategory &&
            competenceTemp.competenceType &&
            competenceTypeId &&
            competenceTemp.competenceCategory.id ===
              configProjectSkills.selectedCompetenceCategory.id &&
            competenceTemp.competenceType.id === competenceTypeId.id
          ) {
            if (
              !newDictionariesCompetenceKinds.find(
                item => item.id === competenceTemp.competenceKind.id
              )
            ) {
              newDictionariesCompetenceKinds.push(
                competenceTemp.competenceKind
              );
            }
          }
        });
        configProjectSkills.dictionariesCompetenceKinds =
          newDictionariesCompetenceKinds;

        const newState = [...notUsedState, configProjectSkills];
        await this.setState({
          arrayOfConfigProjectSkills: [...newState],
        });
      } else {
        configProjectSkills.competenceTypeDisabled = false;
        configProjectSkills.competenceKindDisabled = true;
        configProjectSkills.skillsDisabled = true;
        configProjectSkills.industryDisabled = true;
        const newState = [...notUsedState, configProjectSkills];

        await this.setState({
          arrayOfConfigProjectSkills: [...newState],
        });
      }
      if (firstLoad) {
        return;
      }
      this.props.changeFieldValue(
        formName,
        `${this.props.fieldName}[${index}].competence.competenceKind.name`,
        ''
      );
      this.props.changeFieldValue(
        formName,
        `${this.props.fieldName}[${index}].skill.name`,
        ''
      );
    }
    if (
      fieldName ===
      `${this.props.fieldName}[${index}].competence.competenceKind.name`
    ) {
      if (newValue.trim().length !== 0) {
        configProjectSkills.competenceTypeDisabled = false;
        configProjectSkills.competenceKindDisabled = false;
        configProjectSkills.skillsDisabled = false;
        configProjectSkills.industryDisabled = false;

        const newDictionariesCompetenceSkills = [];

        const competenceKindId = this.props.DictionariesCompetenceKinds.find(
          competenceType => competenceType.name === newValue
        );

        if (typeof competenceKindId === 'undefined') {
          configProjectSkills.dictionariesSkills = [];
          configProjectSkills.selectedCompetenceKind = null;

          const newState = [...notUsedState, configProjectSkills];
          await this.setState({
            arrayOfConfigProjectSkills: [...newState],
          });

          return false;
        }
        configProjectSkills.selectedCompetenceKind = competenceKindId;

        this.props.DictionariesCompetenceTemp.forEach(competenceTemp => {
          if (
            competenceTemp.competenceCategory &&
            configProjectSkills.selectedCompetenceCategory &&
            configProjectSkills.selectedCompetenceType &&
            competenceTemp.competenceKind &&
            competenceKindId &&
            competenceTemp.competenceCategory.id ===
              configProjectSkills.selectedCompetenceCategory.id &&
            competenceTemp.competenceType.id ===
              configProjectSkills.selectedCompetenceType.id &&
            competenceTemp.competenceKind.id === competenceKindId.id
          ) {
            if (
              !newDictionariesCompetenceSkills.find(
                item => item.id === competenceTemp.skill.id
              )
            ) {
              newDictionariesCompetenceSkills.push(competenceTemp.skill);
            }
          }
        });
        configProjectSkills.dictionariesSkills =
          newDictionariesCompetenceSkills;
        const newState = [...notUsedState, configProjectSkills];
        await this.setState({
          arrayOfConfigProjectSkills: [...newState],
        });
      } else {
        configProjectSkills.competenceTypeDisabled = false;
        configProjectSkills.competenceKindDisabled = false;
        configProjectSkills.skillsDisabled = true;
        configProjectSkills.industryDisabled = true;

        const newState = [...notUsedState, configProjectSkills];
        await this.setState({
          arrayOfConfigProjectSkills: [...newState],
        });
      }
      if (firstLoad) {
        return;
      }
      this.props.changeFieldValue(
        formName,
        `${this.props.fieldName}[${index}].skill.name`,
        ''
      );
    }
  };

  getFieldsContent = ({ fields, meta: { touched, error, submitFailed } }) => {
    return (
      <React.Fragment>
        {(touched || submitFailed) && error && <span>{error}</span>}
        {fields.map((fieldName, index) => {
          return (
            <Grid container key={index}>
              {/*Kategoria kopetencji*/}
              <Grid item xs={12} sm={4} lg={4}>
                <AutosuggestComponent
                  required={true}
                  index={index}
                  onChange={this.onChangeHandle}
                  showListOnClick={true}
                  suggestionsList={this.state.dictionariesCompetenceCategories}
                  maxLength={255}
                  fieldName={`${fieldName}.competence.competenceCategory.name`}
                  fieldLabel={t`skillsInputField.skillCat`}
                />
              </Grid>
              {/*Typ kopetencji*/}
              <Grid item xs={12} sm={4} lg={4}>
                <AutosuggestComponent
                  required={true}
                  index={index}
                  onChange={this.onChangeHandle}
                  showListOnClick={true}
                  disabled={
                    this.state.arrayOfConfigProjectSkills[
                      this.getConfigProjectSkillsIndex(index)
                    ].competenceTypeDisabled
                  }
                  suggestionsList={
                    this.state.arrayOfConfigProjectSkills[
                      this.getConfigProjectSkillsIndex(index)
                    ].dictionariesCompetenceTypes
                  }
                  maxLength={255}
                  fieldName={`${fieldName}.competence.competenceType.name`}
                  fieldLabel={t`skillsInputField.skillType`}
                />
              </Grid>
              {/*Rodzaj kopetencji*/}
              <Grid item xs={12} sm={4} lg={4}>
                <AutosuggestComponent
                  required={true}
                  index={index}
                  onChange={this.onChangeHandle}
                  showListOnClick={true}
                  disabled={
                    this.state.arrayOfConfigProjectSkills[
                      this.getConfigProjectSkillsIndex(index)
                    ].competenceKindDisabled
                  }
                  suggestionsList={
                    this.state.arrayOfConfigProjectSkills[
                      this.getConfigProjectSkillsIndex(index)
                    ].dictionariesCompetenceKinds
                  }
                  maxLength={255}
                  fieldName={`${fieldName}.competence.competenceKind.name`}
                  fieldLabel={t`skillsInputField.skillType2`}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <AutosuggestComponent
                  required={true}
                  index={index}
                  onChange={this.onChangeHandle}
                  showListOnClick={true}
                  disabled={
                    this.state.arrayOfConfigProjectSkills[
                      this.getConfigProjectSkillsIndex(index)
                    ].skillsDisabled
                  }
                  suggestionsList={
                    this.state.arrayOfConfigProjectSkills[
                      this.getConfigProjectSkillsIndex(index)
                    ].dictionariesSkills
                  }
                  maxLength={255}
                  fieldName={`${fieldName}.skill.name`}
                  fieldLabel={this.props.fieldLabel}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="industryCategory">
                    {t('candidate:field.industry')}
                  </InputLabel>
                  <Field
                    name={`${fieldName}.industryCategory.id`}
                    component={Select}
                    validate={[required]}
                    type="number"
                  >
                    <MenuItem value={null}>Brak</MenuItem>
                    {this.props.DictionariesIndustryCategories.map(
                      industryCategory => (
                        <MenuItem
                          value={industryCategory.id}
                          key={industryCategory.id}
                        >
                          {industryCategory.name}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </FormControl>
              </Grid>
              {/*<Grid item xs={12} sm={4} lg={4} style={{ display: "flex" }}>*/}
              {/*  <AutosuggestComponent*/}
              {/*    required={true}*/}
              {/*    index={index}*/}
              {/*    onChange={this.onChangeHandle}*/}
              {/*    showListOnClick={false}*/}
              {/*    disabled={this.state.industryDisabled}*/}
              {/*    suggestionsList={this.state.dictionariesIndustries}*/}
              {/*    maxLength={255}*/}
              {/*    fieldName={`${fieldName}.industry`}*/}
              {/*    fieldLabel={t("project:field.industry")}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={12} sm={4} lg={4} style={{ display: 'flex' }}>
                <Field
                  validate={[required]}
                  name={`${fieldName}.level`}
                  component={Select}
                  type="number"
                  className={this.props.classes.selectField}
                >
                  <option value="" disabled>
                    {t('project:field.projectSkillsHardExpertise')}
                  </option>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={12} sm={4} lg={4} style={{ display: 'flex' }}>
                <Field
                  validate={[required]}
                  name={`${fieldName}.experience`}
                  component={TextField}
                  type="number"
                  label={t('project:field.projectSkillsHardYears')}
                  normalize={int0to99Normalization}
                  inputProps={{ min: 0 }}
                  className={this.props.classes.inputField}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                lg={1}
                style={{
                  display: 'flex',
                  alignContent: 'end',
                  justifyContent: 'center',
                }}
              >
                <ButtonJobllegrov4
                  style={{ paddingTop: 16 }}
                  onClick={() => {
                    this.removeItemOfConfigProjectSkill(index);
                    fields.remove(index);
                  }}
                >
                  {t('action.remove')}
                </ButtonJobllegrov4>
              </Grid>
            </Grid>
          );
        })}
        <ButtonJobllegrov1
          style={{ marginTop: 10 }}
          variant="contained"
          onClick={() => {
            this.createNewItemOfConfigProjectSkills();
            fields.push({});
          }}
        >
          {fields.length
            ? t('project:form.addAnotherSkillHard')
            : t('project:form.addNewSkillHard')}
        </ButtonJobllegrov1>
      </React.Fragment>
    );
  };

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    return (
      <FieldArray
        name={this.props.fieldName}
        component={this.getFieldsContent}
        labe={this.props.fieldLabel}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formValues: state.form[props.form].values,
    formName: _.findKey(
      state.form,
      (obj, key) =>
        obj.registeredFields && obj.registeredFields[props.fieldName]
    ),
    DictionariesSkills: state.dictionaries.skills.list,
    DictionariesIndustries: state.dictionaries.industries.list,
    DictionariesCompetenceTemp: state.dictionaries.competenceTemp.list,
    DictionariesCompetenceTypes: state.dictionaries.competenceTypes.list,
    DictionariesCompetenceKinds: state.dictionaries.competenceKinds.list,
    DictionariesCompetenceCategories:
      state.dictionaries.competenceCategories.list,
    DictionariesIndustryCategories: state.dictionaries.industryCategories.list,
  };
};

SkillsInputField.propTypes = {
  classes: PropTypes.object.isRequired,
  DictionariesSkills: PropTypes.array.isRequired,
  DictionariesIndustries: PropTypes.array.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  currentFieldValue: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
  changeFieldValue,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(SkillsInputField)));
