export const styles = () => ({
  filterSubmitBtn: {
    height: 60,
    margin: 10,
    color: '#fff',
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
});
