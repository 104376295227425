import {
  Collapse,
  Grid,
  IconButton,
  Paper,
  withStyles,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  filter,
  get,
  sortBy,
  uniqBy,
  isNil,
  map,
  remove,
  includes,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Autocomplete,
  Button,
  CheckboxGroup,
  Select,
  Translate,
} from 'shared/newComponents';
import { getCompetenceHeaderInfo } from 'shared/utils/functions';
import { MODEL } from '../../ProjectCreateWizardStep2.utils';
import { styles } from './CompetenceSelectCard.styles';

const CompetenceSelectCard = ({
  competences,
  fieldName = MODEL.projectSkills,
  experienceFieldName = MODEL.experienceLevel,
  classes,
  formik,
  index,
  onSave,
  isOpen,
  isSave,
  onEdit,
  onDelete,
  isEdit,
  t,
}) => {
  const values = formik.values;
  const competenceTypeName = `${fieldName}[${index}].${MODEL.competenceType}`;
  const skillName = `${fieldName}[${index}].${MODEL.skill}`;

  const competenceTypeValue = get(values, competenceTypeName);
  const skillValue = get(values, skillName);
  const isExperienceLevel = !isNil(
    get(values, `${fieldName}[${index}].${experienceFieldName}`)
  );

  const [competenceTypeValueState, setCompetenceTypeValueState] =
    useState(competenceTypeValue);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (competenceTypeValue !== competenceTypeValueState) {
      formik.setFieldValue(skillName, undefined);
      setCompetenceTypeValueState(competenceTypeValue);
    }
  }, [competenceTypeValue, skillValue]);

  const approvedCompetences = competences;

  const filteredCompetenceTypes = approvedCompetences;

  const selectedCompetenceTypeId = get(values, `${competenceTypeName}.id`);

  const filteredSkills = selectedCompetenceTypeId
    ? filter(
        approvedCompetences,
        o => o.competenceType.id === selectedCompetenceTypeId
      )
    : approvedCompetences;

  const filteredCompetenceType = [];
  filteredCompetenceTypes.forEach(competence => {
    filteredCompetenceType.push(competence.competenceType);
  });

  const selectedSkills = remove(
    map(values[fieldName], competences => get(competences, 'skill.name')),
    o => !isNil(o)
  );

  const filteredSkill = [];
  filteredSkills.forEach(competence => {
    if (!includes(selectedSkills, competence.skill.name)) {
      filteredSkill.push(competence.skill);
    }
  });

  const competenceName = get(
    formik.values,
    `${fieldName}[${index}].${MODEL.skill}.name`
  );

  const headerInfo = getCompetenceHeaderInfo(
    formik.values,
    index,
    t,
    fieldName,
    experienceFieldName
  );

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.paper}>
        {competenceName && (
          <Grid
            container
            style={{ padding: 10 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Translate text={headerInfo} variant="h6" bold />
            </Grid>
            {isSave && !isOpen && !isEdit && (
              <Grid item>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <IconButton onClick={onEdit}>
                      <Edit />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={onDelete}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        <Collapse in={isOpen}>
          <Grid container>
            <CheckboxGroup
              formik={formik}
              items={sortBy(uniqBy(filteredCompetenceType, 'id'), o => o.name)}
              label={t('projectCreator:step2.competenceType')}
              name={`${fieldName}[${index}].${MODEL.competenceType}`}
              variant="radio"
              errorPath={`${fieldName}[${index}].${MODEL.competenceType}.name`}
              touched={true}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <Autocomplete
                formik={formik}
                items={sortBy(uniqBy(filteredSkill, 'id'), o => o.name)}
                label={t('projectCreator:step2.skillName')}
                name={`${fieldName}[${index}].${MODEL.skill}`}
                touched={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Select
                formik={formik}
                items={[
                  {
                    id: 0,
                    name: t('projectCreator:competenceExperienceLevel.none'),
                  },
                  {
                    id: 2,
                    name: t('projectCreator:competenceExperienceLevel.junior'),
                  },
                  {
                    id: 5,
                    name: t('projectCreator:competenceExperienceLevel.regular'),
                  },
                  {
                    id: 10,
                    name: t('projectCreator:competenceExperienceLevel.senior'),
                  },
                ]}
                name={`${fieldName}[${index}].${experienceFieldName}`}
                label={t('projectCreator:step2.experienceLevel')}
                touched={true}
                projectWizard={true}
              />
            </Grid>
          </Grid>
          {competenceName && isExperienceLevel && (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={6}
                xl={4}
                style={{ padding: 10 }}
              >
                <Button
                  label={t('projectCreator:step2.save')}
                  onClick={onSave}
                  variant="contained"
                />
              </Grid>
            </Grid>
          )}
        </Collapse>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(CompetenceSelectCard));
