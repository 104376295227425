import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getOrganizationSubscriptionType } from 'shared/utils/date';
import AddOrganizationForm from '../AddOrganizationForm/AddOrganizationForm';
import CustomIconButton from '../CustomIconButton';

export function OrganizationsTable({
  organizations,
  shouldUpdateOrganizations,
  classes,
  t,
}) {
  const [organizationData, setOrganizationData] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleOpenEdit = id => {
    const organization = getOrganizationById(id);
    if (organization) {
      setOrganizationData(organization);
      setOpenAddDialog(true);
    }
  };

  const getOrganizationById = id =>
    organizations.find(organization => organization.id === id);

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setOrganizationData(null);
  };

  const handleAdded = () => {
    handleCloseAddDialog();
    shouldUpdateOrganizations();
  };
  return (
    <div>
      <Table stickyheader="true" className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>{t`organizationsTable:owner`}</TableCell>
            <TableCell>{t`organizationsTable:name`}</TableCell>
            <TableCell>{t`organizationsTable:desc`}</TableCell>
            <TableCell>{t`organizationsTable:isPublic`}</TableCell>
            <TableCell>{t`organizationsTable:percent`}</TableCell>
            <TableCell>{t`organizationsTable:invioceAddress`}</TableCell>
            <TableCell>{t`organizationsTable:companyNameInvoice`}</TableCell>
            <TableCell>{t`organizationsTable:nip`}</TableCell>
            <TableCell>{t`organizationsTable:sub`}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map(organization => (
            <TableRow key={organization.id}>
              <TableCell align={'center'}>{organization.id}</TableCell>
              <TableCell
                align={'center'}
              >{`${organization.owner.lastName} ${organization.owner.firstName}}`}</TableCell>
              <TableCell align={'center'}>{organization.name}</TableCell>
              <TableCell align={'center'}>{organization.description}</TableCell>
              <TableCell align={'center'}>
                {organization.isPublic
                  ? t`organizationsTable:yes`
                  : t`organizationsTable:no`}
              </TableCell>
              <TableCell align={'center'}>{organization.percent}</TableCell>
              <TableCell align={'center'}>
                {organization.invoiceAddress}
              </TableCell>
              <TableCell align={'center'}>
                {organization.invoiceCompanyName}
              </TableCell>
              <TableCell align={'center'}>
                {organization.invoiceIdentificationNumber}
              </TableCell>
              <TableCell align={'center'}>
                {getOrganizationSubscriptionType(
                  organization.currentSubscriptionType
                )}
              </TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell}>
                <CustomIconButton
                  onClick={() => handleOpenEdit(organization.id)}
                  type={'edit'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {openAddDialog && (
        <AddOrganizationForm
          isOpen={openAddDialog}
          organizationData={organizationData}
          onClose={handleCloseAddDialog}
          organizationAdded={handleAdded}
        />
      )}
    </div>
  );
}

export default withTranslation()(OrganizationsTable);
