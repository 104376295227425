export const styles = theme => ({
  buttonOptionsAccept: {
    color: '#fff',
    background: theme.palette.jobllegroSuccess,
    opacity: 0.7,
    height: 'auto',
    '&:hover': {
      background: theme.palette.jobllegroSuccess,
      opacity: 1,
    },
  },
  buttonOptionsReject: {
    color: '#fff',
    background: theme.palette.jobllegroRedAlert,
    opacity: 0.7,
    height: 'auto',
    '&:hover': {
      background: theme.palette.jobllegroRedAlert,
      opacity: 1,
    },
  },
  infoText: {
    color: theme.palette.jobllegroSuccess,
    fontSize: 12,
  },
  toggleContainer: {
    height: 'auto',
    padding: `${theme.spacing()}px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing()}px 0`,
    background: theme.palette.background.default,
  },
  cardTopSectionTitle: {
    marginTop: 10,
    fontSize: 11,
    background: theme.palette.jobllegroOrange,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
  },
  card: {
    maxWidth: 320,
    boxShadow: 'none',
  },
  cardHeader: {
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeaderAvatar: {
    width: 20,
    height: 20,
  },
  titleCardHeader: {
    fontSize: 12,
  },
  titleCardSubHeader: {
    fontSize: 12,
  },
  avatarTitleCardHeader: {
    marginRight: 8,
    cursor: 'pointer',
  },
  cardTopChange: {
    marginLeft: 10,
    marginTop: 10,
    fontSize: 11,
    background: theme.palette.jobllegroBlue,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.jobllegroSuccessActive,
    },
  },
  hiringManagerSelected: {
    maxHeight: 150,
    minHeight: 100,
    overflow: 'scroll',
  },
});
