import {
  Typography,
  withStyles,
  Button,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import EditIcon from 'shared/assets/img/icons/edit.png';
import { enums } from 'shared/enums';
import Select from 'shared/newComponents/Select/Select';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import {
  getAccommodationDataSelect,
  getSelectedAccommodation,
  getTargetDate,
} from 'shared/utils/functions';
import CustomDialog from '../../../../../../../components/CustomDialog';
import ProjectOrderLocalization from '../../../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalization/ProjectOrderLocalization';
import ProjectOrderLocalizationForm from '../../../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalizationForm/ProjectOrderLocalizationForm';
import AccommodationData from '../AwaitingEmployment/components/AccommodationData/AccommodationData';
import AwaitingEmploymentLocationForm from '../AwaitingEmployment/components/AwaitingEmploymentLocationForm/AwaitingEmploymentLocationForm';
import LocalizationData from '../AwaitingEmployment/components/LocalizationData/LocalizationData';
import { styles } from './ConfirmationOfEmployment.styles';

const ConfirmationOfEmployment = ({
  classes,
  t,
  selectedFullObject,
  shouldLoadAccommodations,
  accommodations,
  projectOrderLocalizations,
  project,
  dataAdded,
  localizationAdded,
  showSaveBtn,
}) => {
  const [isEmploymentStartDateEdited, setIsEmploymentStartDateEdited] =
    useState(false);
  const [isWorkplaceEdited, setIsWorkplaceEdited] = useState(false);
  const [isAccommodationEdited, setIsAccommodationEdited] = useState(false);
  const [locationForm, setLocationForm] = useState(false);
  const [accommodationForm, setAccommodationForm] = useState(false);

  const [selectedAccommodation, setSelectedAccommodation] = useState(null);
  const [selectedLocalization, setSelectedLocalization] = useState(null);

  const formData = {
    employmentStartDate: selectedFullObject.employmentStartDate
      ? selectedFullObject.employmentStartDate
      : selectedFullObject.corsBorderDate
      ? selectedFullObject.corsBorderDate
      : '',
    employmentEndDate: selectedFullObject.employmentEndDate
      ? selectedFullObject.employmentEndDate
      : '',
    localization: selectedFullObject.localization
      ? selectedFullObject.localization.id
      : null,
    accommodation: selectedFullObject.accommodation
      ? selectedFullObject.accommodation.id
      : null,
  };

  const { targetStartDate, targetEndDate } = getTargetDate(
    selectedFullObject.candidate
  );

  const validationSchema = yup.object({
    employmentStartDate: yup
      .date()
      // .min(
      //   targetStartDate,
      //   `${t`employmentManagementDialog:dialog6.minBreakDate`} ${targetStartDate}`
      // )
      // .max(targetEndDate, `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${targetEndDate}`)
      .required(t`ats2ats3:validation.isRequired`),

    employmentEndDate: yup
      .date()
      // .min(
      //   targetStartDate,
      //   `${t`employmentManagementDialog:dialog6.minBreakDate`} ${targetStartDate}`
      // )
      // .max(targetEndDate, `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${targetEndDate}`)
      .required(t`ats2ats3:validation.isRequired`),
    localization: yup
      .number()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
    accommodation: yup
      .number()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({
      employmentStartDate,
      localization,
      accommodation,
      employmentEndDate,
    }) => {
      if (
        moment(employmentStartDate).isAfter(moment(employmentEndDate), 'day')
      ) {
        formik.setFieldError('employmentEndDate', t`ats2ats3:validation.date`);
        return;
      }
      const body = {
        employmentStartDate,
        localization,
        accommodation,
        employmentEndDate,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };
      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_CONFIRM_EMPLOY
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => console.log(error));
    },
  });

  useEffect(() => {
    setSelectedAccommodation(
      getSelectedAccommodation(formik.values.accommodation, accommodations)
    );
  }, [formik.values.accommodation]);

  useEffect(() => {
    setSelectedLocalization(
      getSelectedAccommodation(
        formik.values.localization,
        projectOrderLocalizations
      )
    );
  }, [formik.values.localization, projectOrderLocalizations]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.bgBlue}>
        <div className={classes.container}>
          <div className={classes.employmentPeriod}>
            <Typography variant="body1" className={classes.periodOfStay}>
              {t`employmentManagementDialog:dialog4.periodOfEmployment`}
            </Typography>
            {!isEmploymentStartDateEdited && (
              <img
                alt="editIcon"
                src={EditIcon}
                onClick={() => setIsEmploymentStartDateEdited(true)}
                className={classes.editBtn}
              />
            )}
          </div>
          <div className={classes.datesContainer}>
            <div
              className={
                isEmploymentStartDateEdited
                  ? classes.isEmploymentStartDateEdited
                  : classes.dates
              }
            >
              <div className={classes.date}>
                <PowerSettingsNew className={classes.powerOnBtn} />
                <div className={classes.employmentStartDate}>
                  {isEmploymentStartDateEdited ? (
                    <TextField
                      type="date"
                      formik={formik}
                      name="employmentStartDate"
                      variant="transparent"
                    />
                  ) : (
                    <div className={classes.employmentStartDate}>
                      <Typography
                        variant="h5"
                        onClick={() => setIsEmploymentStartDateEdited(true)}
                      >
                        {formik.values.employmentStartDate
                          ? formik.values.employmentStartDate
                          : t`assign:noData`}
                      </Typography>
                      {!formik.values.employmentStartDate && (
                        <FormHelperText
                          className={classes.error}
                        >{t`employmentManagementDialog:dialog2.validation.resignationEndDate`}</FormHelperText>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.dates}>
                <div className={classes.date}>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                  {isEmploymentStartDateEdited ? (
                    <TextField
                      type="date"
                      formik={formik}
                      name="employmentEndDate"
                      variant="transparent"
                    />
                  ) : (
                    <div className={classes.employmentStartDate}>
                      <Typography
                        variant="h5"
                        onClick={() => setIsEmploymentStartDateEdited(true)}
                      >
                        {formik.values.employmentEndDate
                          ? formik.values.employmentEndDate
                          : t`assign:noData`}
                      </Typography>
                      {!formik.values.employmentEndDate && (
                        <FormHelperText
                          className={classes.error}
                        >{t`employmentManagementDialog:dialog2.validation.resignationEndDate`}</FormHelperText>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!isEmploymentStartDateEdited && (
                <div>
                  {/*<Button*/}
                  {/*  className={classes.verifyBtn}*/}
                  {/*>{t`employmentManagementDialog:dialog4.confirmEmployment`}</Button>*/}
                </div>
              )}
            </div>
          </div>
          {isWorkplaceEdited ? (
            <Grid container className={classes.containerMargin}>
              <Grid item xs={12}>
                <Typography variant="body1">{t`employmentManagementDialog:dialog2.location`}</Typography>
                {showSaveBtn && (
                  <ProjectOrderLocalization
                    handleOpenAddLocalization={() => setLocationForm(true)}
                  />
                )}

                <div className={classes.selectLoc}>
                  <Select
                    formik={formik}
                    name="localization"
                    label={t`employmentManagementDialog:dialog4.workplaceLabel`}
                    variant="transparent"
                    items={getAccommodationDataSelect(
                      projectOrderLocalizations
                    )}
                    fontBold={true}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.header}
                  >{t`employmentManagementDialog:dialog4.workplace`}</Typography>
                </Grid>
                <Grid item>
                  <img
                    alt="editIcon"
                    src={EditIcon}
                    onClick={() => setIsWorkplaceEdited(true)}
                    className={classes.editBtn}
                  />
                </Grid>
              </Grid>
              <LocalizationData selectedLocalization={selectedLocalization} />
            </>
          )}
          {isAccommodationEdited ? (
            <Grid container className={classes.containerMargin}>
              <Grid item xs={12}>
                <Typography variant="body1">{t`employmentManagementDialog:dialog2.accommodation`}</Typography>
                {showSaveBtn && (
                  <ProjectOrderLocalization
                    handleOpenAddLocalization={() => setAccommodationForm(true)}
                  />
                )}

                <div className={classes.selectLoc}>
                  <Select
                    formik={formik}
                    name="accommodation"
                    label={t`employmentManagementDialog:dialog2.chooseLocation`}
                    variant="transparent"
                    items={getAccommodationDataSelect(accommodations)}
                    fontBold={true}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.employmentStartDate}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.header}
                  >{t`employmentManagementDialog:dialog2.accommodation`}</Typography>
                </Grid>
                <Grid item>
                  <img
                    alt="editIcon"
                    src={EditIcon}
                    onClick={() => setIsAccommodationEdited(true)}
                    className={classes.editBtn}
                  />
                </Grid>
              </Grid>
              {!formik.values.accommodation && (
                <FormHelperText
                  className={classes.accommodationError}
                >{t`employmentManagementDialog:dialog4.validation.accommodation`}</FormHelperText>
              )}
              <AccommodationData
                selectedAccommodation={selectedAccommodation}
              />
            </div>
          )}
        </div>
      </div>
      {accommodationForm && (
        <AwaitingEmploymentLocationForm
          project={project}
          isOpen={accommodationForm}
          onClose={() => {
            shouldLoadAccommodations();
            setAccommodationForm(false);
          }}
        />
      )}
      {locationForm && (
        <CustomDialog
          maxWidth={'md'}
          title={''}
          onCloseDialog={() => {
            setLocationForm(false);
          }}
          body={
            <ProjectOrderLocalizationForm
              project={project}
              localizationAdded={() => {
                localizationAdded();
                setLocationForm(false);
              }}
            />
          }
          isOpen={locationForm}
        />
      )}
      {showSaveBtn && (
        <div className={classes.saveBtnDiv}>
          <Button type="submit" className={classes.saveBtn}>
            {t`employmentManagementDialog:saveBtn`}
          </Button>
        </div>
      )}
    </form>
  );
};

export default withStyles(styles)(withTranslation()(ConfirmationOfEmployment));
