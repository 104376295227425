import {
  // @TODO
  //Button,
  Paper,
  Grid,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

// @TODO
// import {
//     KeyboardArrowRight as ArrowForwardIcon,
//     KeyboardArrowLeft as ArrowBackIcon,
// } from '@material-ui/icons';

const styles = theme => ({
  rootPaper: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 23,
    paddingLeft: 64,
    paddingRight: 32,
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 16,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  leftButton: {
    float: 'left',
    color: '#52a3fc',
    fontSize: 13,
    padding: '0px',
    '&:hover': {
      background: 'transparent',
    },
    textTransform: 'capitalize',
    minHeight: 12,
  },
  rightButton: {
    float: 'right',
    color: '#52a3fc',
    fontSize: 13,
    padding: '0px',
    '&:hover': {
      background: 'transparent',
    },
    textTransform: 'capitalize',
    minHeight: 12,
  },
});

export const ProjectNextPrevButtons = props => {
  const { classes } = props;

  return (
    <Paper className={classes.rootPaper} elevation={0}>
      <Grid container alignItems="flex-start" spacing={0}>
        {/*@TO DO*/}
        {/*<Grid item xs={6} sm={6} md={6} lg={6}>*/}
        {/*<Button className={classes.leftButton}>*/}
        {/*<ArrowBackIcon style={{width: 15}}/>*/}
        {/*Poprzedni projekt*/}
        {/*</Button>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={6} sm={6} md={6} lg={6}>*/}
        {/*<Button className={classes.rightButton}>*/}
        {/*Następny projekt*/}
        {/*<ArrowForwardIcon style={{width: 15}}/>*/}
        {/*</Button>*/}
        {/*</Grid>*/}
      </Grid>
    </Paper>
  );
};

ProjectNextPrevButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectNextPrevButtons);
