import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import DisabledIcon from 'shared/assets/img/icons/candidate-show/disable.svg';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { Translate } from 'shared/newComponents';
import CandidateAbilityDate from './CandidateAbilityDate';
import { styles } from './CandidateAnonymousCard.styles';
import CandidateExpectedSalary from './CandidateExpectedSalary';
import CandidateExperiences from './CandidateExperiences';
import CandidateHomeCountry from './CandidateHomeCountry';
import CandidateKeyCompetences from './CandidateKeyCompetences';
import CandidateLanguages from './CandidateLanguages';
import CandidatePreferenceWorkArea from './CandidatePreferenceWorkArea';
import CandidateSelectOwner from './CandidateSelectOwner';
import CandidateVerifyBy from './CandidateVerifyBy';

const CandidateAnonymousCard = ({
  candidate,
  classes,
  t,
  history,
  ...props
}) => {
  const position = candidate.position;

  const isSecondStageInfoPublic = candidate.isSecondStageInfoPublic;

  const candidateRatingFromRecruiterDescription =
    candidate && candidate.candidateRatingFromRecruiterDescription
      ? candidate.candidateRatingFromRecruiterDescription
      : candidate.projectCandidates &&
        candidate.projectCandidates[candidate.projectCandidates.length - 1]
      ? candidate.projectCandidates[candidate.projectCandidates.length - 1]
          .brief
      : null;

  const candidateHasOwner = !!(candidate.owner && candidate.owner.id);

  const candidateHasOwnerToBe = !!(
    candidate.ownerToBe && candidate.ownerToBe.id
  );

  const redirectToEditPage = () => {
    history.push(PATHS.CANDIDATE.NEW_EDIT.replace(':id', candidate.id));
    window.location.reload();
  };

  const enableToEdit =
    (candidate.owner && props.currentUser.id === candidate.owner.id) ||
    props.currentUser.isSuperUser ||
    props.currentUser.id === candidate.id;

  return (
    <Grid container className={classes.wrapper} style={{ width: '100%' }}>
      <Paper style={{ width: '100%', borderRadius: 20 }}>
        <Grid container>
          <Grid
            item
            xs={8}
            sm={8}
            md={8}
            style={{
              padding: '30px 30px 30px 30px',
              gridArea: 'left',
            }}
          >
            <Translate
              text={position}
              bold
              disableTranslate={true}
              style={{ fontSize: 26 }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid container className={classes.headerSections}>
              {isSecondStageInfoPublic ? (
                <Grid item xs={3} sm={3} md={3}>
                  <img alt="DisabledIcon" src={DisabledIcon} height={50} />
                </Grid>
              ) : null}
              {enableToEdit ? (
                <Grid item xs={3} sm={3} md={3} onClick={redirectToEditPage}>
                  <img alt="EditIcon" src={EditIcon} height={50} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {candidate.email ? <div style={{ display: 'flex' }}></div> : null}
          </Grid>
          <Grid container>
            <hr className={classes.separator} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={classes.bluePrimaryBackground}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                style={{
                  padding: '30px 30px 30px 30px',
                  height: '100%',
                }}
              >
                <CandidateKeyCompetences candidate={candidate} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} style={{ padding: '0px 25px' }}>
            <Grid
              container
              style={{
                padding: '30px 30px 30px 30px',
              }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <CandidateExperiences candidate={candidate} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CandidateLanguages candidate={candidate} />
              </Grid>
            </Grid>
            <Grid container>
              <hr className={classes.separator} />
            </Grid>
            <Grid
              container
              style={{
                padding: '30px 30px 30px 30px',
              }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <CandidateExpectedSalary candidate={candidate} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CandidatePreferenceWorkArea candidate={candidate} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CandidateAbilityDate candidate={candidate} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CandidateHomeCountry candidate={candidate} />
              </Grid>
            </Grid>
            {candidateRatingFromRecruiterDescription ? (
              <Grid container>
                <hr className={classes.separator} />
              </Grid>
            ) : null}
            {candidateRatingFromRecruiterDescription ? (
              <Grid
                container
                style={{
                  padding: '30px 30px 30px 30px',
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    style={{
                      fontSize: 20,
                    }}
                    bold
                    text={
                      'candidateCreator:reviewStep2.recruiterRatingDescription'
                    }
                  />
                  <Grid item>
                    <Translate
                      style={{
                        fontSize: 16,
                      }}
                      disableTranslate={true}
                      text={ReactHtmlParser(
                        candidateRatingFromRecruiterDescription
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {candidateHasOwner && !candidateHasOwnerToBe && (
              <div>
                <Grid container>
                  <hr className={classes.separator} />
                </Grid>
                <CandidateVerifyBy
                  candidate={candidate}
                  variant={'owner'}
                  enableToEdit={enableToEdit}
                />
              </div>
            )}
            {candidateHasOwnerToBe && !candidateHasOwner && (
              <div>
                <Grid container>
                  <hr className={classes.separator} />
                </Grid>
                <CandidateVerifyBy
                  candidate={candidate}
                  variant={'ownerToBe'}
                  enableToEdit={enableToEdit}
                />
              </div>
            )}
            {!candidateHasOwner && !candidateHasOwnerToBe && enableToEdit && (
              <div>
                <Grid container>
                  <hr className={classes.separator} />
                </Grid>
                <CandidateSelectOwner candidate={candidate} />
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateAnonymousCard))));
