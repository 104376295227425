export const styles = ({ breakpoints }) => ({
  wrapper: {
    position: 'relative',
    padding: '16px 150px',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  tableWrapper: {
    overflow: 'scroll',
  },
  imageWrapper: {
    maxWidth: 75,
  },
  positionText: {
    fontSize: 26,
    cursor: 'pointer',
  },
  clientWrapper: {
    minWidth: 50,
  },
  clientText: {
    fontSize: 16,
    color: '#148EFF',
  },
  grayText: {
    fontSize: 16,
    color: '#868889',
  },
});
