import { Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { styles } from './Translate.style';

const Translate = ({
  classes,
  t,
  text,
  bold,
  uppercase,
  underline,
  tReady,
  className,
  disableTranslate = false,
  ...props
}) => (
  <Typography
    className={classNames(
      bold && classes.bold,
      uppercase && classes.uppercase,
      underline && classes.underline,
      className
    )}
    {...props}
  >
    {disableTranslate ? text : t(text)}
  </Typography>
);

export default withStyles(styles)(withTranslation()(Translate));
