import { Typography } from '@material-ui/core';
import { withWidth } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart as Chart,
} from 'recharts';
import compose from 'recompose/compose';

export class RadarChart extends Component {
  fakeData = {
    personalityCurious: 0,
    personalityOrganized: 0,
    personalityExtraversion: 0,
    personalityCompassionate: 0,
    personalitySecure: 0,
  };

  parseData = project => {
    const { t } = this.props;
    return [
      {
        name: t('chart.personalityCurious'),
        value: project.personalityCurious,
        fullMark: 100,
      },
      {
        name: t('chart.personalityOrganized'),
        value: project.personalityOrganized,
        fullMark: 100,
      },
      {
        name: t('chart.personalityExtraversion'),
        value: project.personalityExtraversion,
        fullMark: 100,
      },
      {
        name: t('chart.personalityCompassionate'),
        value: project.personalityCompassionate,
        fullMark: 100,
      },
      {
        name: t('chart.personalitySecure'),
        value: project.personalitySecure,
        fullMark: 100,
      },
    ];
  };

  render() {
    const { project, width } = this.props;
    const data = this.parseData(project || this.fakeData);

    return (
      <Typography component="div">
        <Chart
          outerRadius={90}
          width={width === 'xs' ? 300 : 550}
          height={250}
          data={data}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis angle={90} domain={[0, 100]} />
          <Radar
            name="Project"
            dataKey="value"
            stroke="#52a3fc"
            fill="#20d4d5"
            fillOpacity={0.6}
          />
        </Chart>
      </Typography>
    );
  }
}

RadarChart.propTypes = {
  project: PropTypes.object.isRequired,
};

export default compose(withWidth())(withTranslation()(RadarChart));
