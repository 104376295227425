import axios from 'axios';
import { API, API_URL } from '../constants';
import { getAllCitiesAction } from '../store/cities/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getCities = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.CITY.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllCitiesAction(data));
        return { data, status };
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response;
          return { data: {}, status };
        }
        return { data: {}, status: 500 };
      });
  };
};

export const addCity = name => {
  return async dispatch => {
    return await axios({
      method: 'POST',
      url: API_URL + API.POST.CITY.ADD,
      data: { name },
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
