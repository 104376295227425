import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { OrganizationService } from 'shared/services';
import CustomIconButton from '../CustomIconButton';

export const RecruiterOrganizationTable = ({
  classes,
  project,
  setSelectedOrganizationMentor,
  organization,
  onClose,
  t,
}) => {
  const [organizationRecruiters, setOrganizationRecruiters] = useState([]);

  const organizationAcceptStatus = [9, 2, 5];

  const getOnlyAcceptOrganizationRecruiters = recruiters => {
    return recruiters.filter(recruiter =>
      organizationAcceptStatus.includes(recruiter.organizationConfirmation)
    );
  };

  useEffect(() => {
    if (!organization) {
      return;
    }
    OrganizationService.getOrganizationRecruiters(organization.id)
      .then(({ data }) => {
        setOrganizationRecruiters(getOnlyAcceptOrganizationRecruiters(data));
      })
      .catch(error => {
        alert(t`recruiterOrganizationTable:downloadError`);
      });
  }, organization);

  const handleChangeOrganizationMentor = recruiter => {
    setSelectedOrganizationMentor(recruiter);
    onClose();
  };

  return (
    <div>
      <Table stickyheader="true" className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>{t`recruiterOrganizationTable:name`}</TableCell>
            <TableCell>{t`recruiterOrganizationTable:lastName`}</TableCell>
            <TableCell>{t`recruiterOrganizationTable:email`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizationRecruiters.map(recruiter => (
            <TableRow key={recruiter.id}>
              <TableCell align={'center'}>{recruiter.id}</TableCell>
              <TableCell align={'center'}>{recruiter.firstName}</TableCell>
              <TableCell align={'center'}>{recruiter.lastName}</TableCell>
              <TableCell align={'center'}>{recruiter.email}</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell}>
                <CustomIconButton
                  onClick={() => handleChangeOrganizationMentor(recruiter)}
                  type={'edit'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withTranslation()(RecruiterOrganizationTable);
