import { Button, withStyles } from '@material-ui/core';
import {
  Done as DoneIcon,
  Close as CloseIcon,
  ArrowForward as ArrowForwardIcon,
} from '@material-ui/icons';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getNotifications,
  setNotificationRead,
} from 'shared/services/notificationService';
import { getOrganizationRecruitersFilteredForAdding } from 'shared/services/organizationService';
import {
  editRecruiter,
  editRecruiterOrganization,
} from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';

const styles = theme => ({
  buttonAccept: {
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.jobllegroSuccess,
    color: '#fff',
    borderRadius: '0 0 0px 10px',
    width: '50%',
    '&:hover': {
      background: theme.palette.jobllegroSuccessActive,
    },
  },
  buttonReject: {
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.jobllegroRedAlert,
    color: '#fff',
    borderRadius: '0 0 10px 0px',
    width: '50%',
    '&:hover': {
      background: theme.palette.jobllegroLightGrey,
    },
  },
  buttonShow: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#F5F5F5',
    color: '#52a3fc',
    borderRadius: '0 0 10px 10px',
    width: '100%',
  },
});

export class OrganizationRecruiterAcceptRejectButton extends Component {
  acceptInvitation = async () => {
    try {
      const {
        organization,
        recruiter,
        notifications,
        setNotificationRead,
        getNotifications,
        t,
      } = this.props;
      const { status } = await this.props.editRecruiterOrganization({
        id: recruiter.id,
        organizationConfirmation: 5,
      });
      const findNotification = find(notifications, function (n) {
        return (
          n.data.organization.id === organization.id &&
          n.data.recruiter.id === recruiter.id
        );
      });
      await setNotificationRead(findNotification.id);
      await getNotifications();
      if (status === 200) {
        this.props.getOrganizationRecruitersFilteredForAdding(organization.id);
        this.props.showSnackbarMessage(
          t('notification:organization.recruiterWasInvitedToOrganization')
        );
      } else {
        this.props.showSnackbarMessage(t('notification:organization.error'));
      }
    } catch (err) {
      console.error(err);
    }
  };

  rejectInvitation = async () => {
    try {
      const {
        organization,
        recruiter,
        notifications,
        setNotificationRead,
        getNotifications,
        t,
      } = this.props;
      const { status } = await this.props.editRecruiterOrganization({
        id: recruiter.id,
        organizationConfirmation: 6,
      });
      const findNotification = find(notifications, function (n) {
        return (
          n.data.organization.id === organization.id &&
          n.data.recruiter.id === recruiter.id
        );
      });
      await setNotificationRead(findNotification.id);
      await getNotifications();
      if (status === 200) {
        this.props.getOrganizationRecruitersFilteredForAdding(organization.id);
        this.props.showSnackbarMessage(
          t('notification:organization.rejectedRecruiterInvitation')
        );
      } else {
        this.props.showSnackbarMessage(t('notification:organization.error'));
      }
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const {
      classes,
      currentUser,
      organization = {},
      confirmationStatus,
      recruiter,
      t,
    } = this.props;
    const showStatusButton =
      organization.owner &&
      currentUser.id === organization.owner.id &&
      confirmationStatus === 4;

    return (
      <React.Fragment>
        {showStatusButton ? (
          <React.Fragment>
            <Button
              className={classes.buttonAccept}
              color="primary"
              onClick={this.acceptInvitation.bind(this)}
            >
              <DoneIcon />
              {t('core:action.acceptance')}
            </Button>
            <Button
              className={classes.buttonReject}
              color="secondary"
              onClick={this.rejectInvitation.bind(this)}
            >
              {t('core:action.reject')}
              <CloseIcon />
            </Button>
          </React.Fragment>
        ) : (
          <Button
            component={Link}
            to={PATHS.RECRUITER.SHOW.replace(':id', recruiter.id)}
            color="primary"
            className={classes.buttonShow}
          >
            {t('general.details')}
            <ArrowForwardIcon />
          </Button>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getOrganizationRecruitersFilteredForAdding,
  editRecruiter,
  editRecruiterOrganization,
  getNotifications,
  setNotificationRead,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    currentOrganization: store.organizations.currentOrganization,
    notifications: store.notifications.list,
  };
};

OrganizationRecruiterAcceptRejectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  recruiter: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  confirmationStatus: PropTypes.number.isRequired,
  editRecruiter: PropTypes.func.isRequired,
  getOrganizationRecruitersFilteredForAdding: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(withTranslation()(OrganizationRecruiterAcceptRejectButton))
);
