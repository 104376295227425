import {
  TableSortLabel,
  TableCell,
  withStyles,
  withWidth,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';

const styles = theme => ({
  TableCell: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
  },
  TableCellClient: {
    textAlign: 'left !important',
    paddingLeft: '75px',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '15px',
      textAlign: 'left !important',
    },
  },
  tableHead: {
    fontSize: 12,
    color: theme.palette.jobllegroLightGrey,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left !important',
    },
  },
  hiddenColumn: {
    display: 'none',
  },
});

export const SummaryCellComponent = props => {
  const { classes, column, orderBy, order, onClickTrigger, width } = props;
  let hiddenColumn = false;
  if (column.hiddenSm === 'true' && width === 'sm') {
    hiddenColumn = true;
  } else if (column.hiddenXs === 'true' && width === 'xs') {
    hiddenColumn = true;
  }
  let clientColumn = null;
  if (column.id === 'lastName') {
    clientColumn = classes.TableCellClient;
  }

  return (
    <TableCell
      padding="none"
      key={column.id}
      align={column.numeric ? 'right' : 'left'}
      sortDirection={orderBy === column.id ? order : false}
      className={classNames(
        hiddenColumn ? classes.hiddenColumn : classes.tableHead,
        clientColumn
      )}
    >
      <TableSortLabel
        padding="none"
        style={{ textAlign: 'center' }}
        active={orderBy === column.id}
        direction={order}
        onClick={onClickTrigger.bind(this, column.id)}
      >
        {column.label}
      </TableSortLabel>
    </TableCell>
  );
};

SummaryCellComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  onClickTrigger: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withWidth())(SummaryCellComponent);
