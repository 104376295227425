import {
  Button,
  Hidden,
  IconButton,
  Menu,
  withStyles,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';

const styles = theme => ({
  button: {
    minWidth: 150,
    margin: 0,
    background: '#e6e6e6',
    color: '#b3b3b3',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
    '&:hover': {
      background: '#fff',
      color: '#53a3fc',
    },
    [theme.breakpoints.only('xs', 'sm')]: {
      background: theme.palette.jobllegroBlue,
      color: '#fff',
      borderRadius: '0px',
      minWidth: 200,
      borderBottom: '1px solid #84bfff',
      '&:nth-last-child(1)': {
        borderBottom: '0px',
      },
    },
  },
  active: {
    background: '#fff',
    color: '#53a3fc',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '0.940rem',
    justifyContent: 'left',
  },
  menuButton: {
    right: 10,
    top: 87,
    position: 'fixed',
    zIndex: '1000',
    width: 50,
    height: 50,
    background: '#52a3fc',
    color: '#ffffff',
  },
  paperMenu: {
    width: 150,
    [theme.breakpoints.down('md')]: {
      background: theme.palette.jobllegroBlue,
      width: 200,
    },
  },
});

export const RecruitersMenu = props => {
  const ITEM_HEIGHT = 30;
  const { classes, t, currentUser, itemSelected, anchorEl } = props;
  let { organization } = currentUser;

  let organization_access = false;
  let organization_access_index = false;
  if (
    !organization ||
    currentUser.organizationConfirmation === 0 ||
    currentUser.organizationConfirmation === 3 ||
    currentUser.organizationConfirmation === 6
  ) {
    organization_access_index = true;
  }
  if (
    organization &&
    (currentUser.organizationConfirmation === 2 ||
      currentUser.organizationConfirmation === 5 ||
      currentUser.organizationConfirmation === 9)
  ) {
    organization_access = true;
  }

  const menuRecruiterElements = [
    {
      name: t('recruiter:menu.editProfile'),
      url: PATHS.USER.INDEX,
      organization_access: true,
      route_url: 'user',
    },
    {
      name: t('recruiter:menu.organization'),
      url: PATHS.RECRUITER.ORGANIZATION.INDEX,
      organization_access:
        organization_access_index || organization_access ? true : false,
      route_url: 'organization',
    },
    {
      name: t('recruiter:menu.organizationMembers'),
      url: PATHS.RECRUITER.ORGANIZATION.RECRUITERS,
      organization_access: organization_access,
      route_url: 'organization_members',
    },
  ];

  const subMenu = menuRecruiterElements.map((element, key) => {
    let setActive = false;

    if (itemSelected === element.route_url) {
      setActive = true;
    }

    return (
      element.organization_access && (
        <Button
          key={key}
          variant="text"
          component={Link}
          to={element.url}
          className={setActive ? classes.active : ''}
          classes={{
            root: classes.button,
            label: classes.label,
          }}
        >
          {element.name}
        </Button>
      )
    );
  });

  return (
    <React.Fragment>
      <Hidden only={['xs', 'sm']}>{subMenu}</Hidden>
      <Hidden mdUp>
        <IconButton
          className={classes.menuButton}
          aria-label="Menu"
          aria-owns={anchorEl ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={props.handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={props.handleClose}
          classes={{
            paper: classes.paperMenu,
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 9,
            },
          }}
        >
          {subMenu}
        </Menu>
      </Hidden>
    </React.Fragment>
  );
};

RecruitersMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(RecruitersMenu)));
