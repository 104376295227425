import { Tab, Tabs, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';

const styles = theme => ({
  button: {
    minWidth: 150,
    margin: 0,
    background: '#e6e6e6',
    color: '#b3b3b3',
    textTransform: 'capitalize',
    fontSize: '0.940rem',
    justifyContent: 'left',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
    minHeight: 40,
    '&:hover': {
      background: '#fff',
      color: '#53a3fc',
    },
  },
  buttonInActive: {
    minWidth: 150,
    margin: 0,
    background: '#f2f2f2',
    color: '#d3d3d3',
    marginRight: '3px',
    borderRadius: '3px 3px 0px 0px',
  },
  active: {
    background: '#fff',
    color: '#53a3fc',
  },
  tabsRoot: {
    minHeight: 40,
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  scrollButtons: {
    color: theme.palette.jobllegroGrey,
    flex: '0 0 20px',
  },
});

export const SummaryIndexMenu = props => {
  const { classes, t, itemSelected } = props;

  const menuElements = [
    {
      name: t('summary:menu.candidateAll'),
      route_url: 'candidateAll',
      url: PATHS.SUMMARY_FOR_CANDIDATE.INDEX.replace(':filter', 'candidateAll'),
    },
  ];

  const subMenu = menuElements.map((element, key) => {
    let setActive = false;

    if (itemSelected === element.route_url) {
      setActive = true;
    }

    return (
      <Tab
        key={key}
        component={Link}
        to={element.url}
        className={setActive ? classes.active : ''}
        label={element.name}
        classes={{
          root: classes.button,
        }}
      />
    );
  });

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="on"
      value={0}
      classes={{
        root: classes.tabsRoot,
        indicator: classes.indicator,
        scrollButtons: classes.scrollButtons,
      }}
    >
      {subMenu}
    </Tabs>
  );
};

export default withStyles(styles)(withTranslation()(SummaryIndexMenu));
