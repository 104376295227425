import { Grid } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { TextEditor, WizardStep } from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import { isProjectClosed } from 'shared/utils/functions';
import {
  stage5NotSetFields,
  useFormStep4,
} from '../ProjectCreateWizard/ProjectCreateWizard.utils';
import { MODEL } from './ProjectCreateWizardStep4.utils';

const ProjectCreateWizardStep4 = ({
  classes,
  isStep4Open,
  setIsStep4Open,
  setIsStep5Open,
  isFormStepError,
  closeAll,
  isProjectSave,
  initialProjectValues,
  setStep4FormValues,
  t,
  isThreeStageSaved,
  step5FormValues,
  setIsSnackbarErrorOpen,
}) => {
  const formik = useFormStep4({ t, initialProjectValues });

  const [timeoutId, setTimeoutId] = useState(null);

  const myRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep4FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  useEffect(() => {
    if (isStep4Open) {
      executeScroll(myRef);
    }
  }, [isStep4Open]);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />

      <WizardStep
        title="projectCreator:step4.title"
        withSaveButton={!isProjectClosed(initialProjectValues)}
        isOpen={isStep4Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep4Open(false);
            const openSecondStep = stage5NotSetFields(
              initialProjectValues,
              step5FormValues
            );
            setIsStep5Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        onOpen={() => {
          closeAll();
          setIsStep4Open(true);
        }}
        disableArrowButton={
          !(isProjectSave === true && isThreeStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isProjectSave === true && isThreeStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => setIsStep4Open(false)}
      >
        <Grid container>
          <Grid container>
            <TextEditor
              formik={formik}
              name={MODEL.positionDescription}
              label={t('projectCreator:step4.positionDescription')}
              subLabel={t('projectCreator:step4.positionDescriptionSubLabel')}
            />
          </Grid>
          <Grid container>
            <TextEditor
              formik={formik}
              name={MODEL.benefits}
              label={t('projectCreator:step4.benefits')}
              subLabel={t('projectCreator:step4.benefitsSubLabel')}
            />
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default ProjectCreateWizardStep4;
