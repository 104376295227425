import { array, number, object, ref, string } from 'yup';

export const MODEL = {
  employmentTypes: 'employmentTypes',
  workHourType: 'workHourType',
  expectedWorkTime: 'expectedWorkTime',
  minMonthlyWadges: 'minMonthlyWadges',
  maxMonthlyWadges: 'maxMonthlyWadges',
  currency: 'currency',
  salaryType: 'salaryType',
  isWeekendsWorkPossible: 'isWeekendsWorkPossible',
  isShiftWorkPossible: 'isShiftWorkPossible',
};

export const initialValues = {};

export const validationSchema = t =>
  object().shape({
    employmentTypes: array()
      .min(0, t('projectCreator:core.requiredField'))
      .required(t('projectCreator:core.requiredField')),
    workHourType: number().nullable(),

    expectedWorkTime: number()
      .min(0, t('projectCreator:core.requiredFieldMinNumber'))
      .nullable(),
    minMonthlyWadges: number()
      .min(0, t('projectCreator:core.requiredFieldMinNumber'))
      .nullable(),
    maxMonthlyWadges: number()
      .nullable()
      .min(
        ref('minMonthlyWadges'),
        t('projectCreator:core.requiredFieldHigherThan')
      ),
    currency: number().nullable(),
    salaryType: string().nullable(),
  });
