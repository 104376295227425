import { Grid, CardContent, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import { setLastPage } from 'shared/services/authService';
import { getCandidatesCount } from 'shared/services/candidateService';
import Box from '../../components/BoxContainer';
import DashboardAts from '../../components/DashboardAts';
import CandidatesTable from './components/CandidatesTable';
import ProjectsTable from './components/ProjectsTable';

const styles = () => ({
  content: {
    padding: '0px !important',
    margin: 0,
  },
  mock: {
    background: '#F5F5F5',
    zIndex: '1',
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: '0.8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 10px 10px',
  },
});

export class DashboardIndexView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countAllCandidates: 0,
      countMyCandidates: 0,
      countForAcceptanceCandidates: 0,
      countRecommendCandidates: 0,
    };
  }

  getProjectBoxContent = () => {
    const { classes } = this.props;
    return (
      <CardContent className={classes.content}>
        <ProjectsTable />
      </CardContent>
    );
  };

  getCandidatesBoxContent = () => {
    const { classes } = this.props;
    const {
      countMyCandidates,
      countAllCandidates,
      countForAcceptanceCandidates,
      countRecommendCandidates,
    } = this.state;
    return (
      <CardContent className={classes.content}>
        <CandidatesTable
          countMyCandidates={countMyCandidates}
          countAllCandidates={countAllCandidates}
          countForAcceptanceCandidates={countForAcceptanceCandidates}
          countRecommendCandidates={countRecommendCandidates}
        />
      </CardContent>
    );
  };

  getFilterParameters(filterName) {
    const { currentUser } = this.props;
    if (filterName === 'cooperation') {
      return { owner: currentUser.id, withPublic: false };
    } else if (filterName === 'invitation') {
      return { ownerToBe: currentUser.id };
    } else if (filterName === 'organization') {
      return { inOrganization: true };
    } else if (filterName === 'recommend') {
      return { recommend: true };
    } else {
      return { withPublic: true };
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    this.props.setLastPage(PATHS.DASHBOARD.INDEX);
    await this.getMenuCoutners();
  }

  getCounter = async route => {
    const params = this.getFilterParameters(route);
    return await this.props.getCandidatesCount(params);
  };

  async getMenuCoutners() {
    const { count: countMyCandidates = 0 } = await this.getCounter(
      'cooperation'
    );
    const { count: countAllCandidates = 0 } = await this.getCounter();
    const { count: countForAcceptanceCandidates = 0 } = await this.getCounter(
      'invitation'
    );
    const { count: countRecommendCandidates = 0 } = await this.getCounter(
      'recommend'
    );
    if (this._isMounted) {
      this.setState({
        countMyCandidates,
        countAllCandidates,
        countForAcceptanceCandidates,
        countRecommendCandidates,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const projectBoxContent = this.getProjectBoxContent();
    const candidatesBoxContent = this.getCandidatesBoxContent();
    const {
      t,
      currentUser: { isOrganizationOwner },
    } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box
            children
            title={t('dashboard:projectsTable.projectsTitle')}
            link={PATHS.PROJECT.INDEX}
            icons="true"
            elevation={6}
            secondTitle={t('project:form.projectCreator')}
            secondLink={PATHS.PROJECT.CREATE}
          >
            {projectBoxContent}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box
            title={t('dashboard:candidatesTable.candidatesTitle')}
            link={PATHS.CANDIDATE.INDEX}
            icons="true"
            elevation={6}
            secondTitle={t('candidate:form.candidateCreator')}
            secondLink={PATHS.CANDIDATE.CREATE}
          >
            {candidatesBoxContent}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            title={
              isOrganizationOwner
                ? t`dashboard:boxTitle`
                : t('dashboard:ats.recruiter')
            }
            link={PATHS.SUMMARY.ORGANIZATION_ALL}
            icons="true"
            elevation={6}
          >
            <DashboardAts params="poAndCoAndMentorAndHm" />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

DashboardIndexView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  setLastPage,
  getCandidatesCount,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(DashboardIndexView)));
