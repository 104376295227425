import { API } from '../constants';
import { ApiService } from './api.service';

export const HelpService = {
  getLookingHelpInfoId: candidateId => {
    return ApiService.get(
      API.GET.HELP.GET_LOOKING_HELP.replace(':candidateId', candidateId)
    );
  },

  getOfferingHelpInfo: recruiterId => {
    return ApiService.get(
      API.GET.HELP.GET_OFFERING_HELP.replace(':recruiterId', recruiterId)
    );
  },
  updateLookingHelpInfoId: (lookingHelpInfoId, body, stage) => {
    return ApiService.put(
      API.PUT.HELP.PUT_LOOKING_HELP_INFO.replace(
        ':lookingHelpInfoId',
        lookingHelpInfoId
      ),
      body,
      { stage }
    );
  },

  postOfferingAccommodationInfo: (offeringHelpInfoId, body) => {
    return ApiService.post(
      API.POST.HELP.OFFERING_ACCOMMODATION_INFO.replace(
        ':offeringHelpInfoId',
        offeringHelpInfoId
      ),
      body
    );
  },

  postOfferingTransportInfo: (offeringHelpInfoId, body) => {
    return ApiService.post(
      API.POST.HELP.OFFERING_TRANSPORT_INFO.replace(
        ':offeringHelpInfoId',
        offeringHelpInfoId
      ),
      body
    );
  },
  putOfferingAccommodationInfo: (offeringAccommodationId, body) => {
    return ApiService.put(
      API.PUT.HELP.PUT_OFFERING_ACCOMMODATION_INFO.replace(
        ':offeringAccommodationInfoId',
        offeringAccommodationId
      ),
      body
    );
  },

  putOfferingTransportInfo: (offeringTransportId, body) => {
    return ApiService.put(
      API.PUT.HELP.PUT_OFFERING_TRANSPORT_INFO.replace(
        ':offeringTransportInfoId',
        offeringTransportId
      ),
      body
    );
  },
  getTransport: lookingHelpId => {
    return ApiService.put(
      API.GET.HELP.GET_TRANSPORT.replace(':id', lookingHelpId)
    );
  },
  getAccommodation: lookingHelpId => {
    return ApiService.put(
      API.GET.HELP.GET_ACCOMMODATION.replace(':id', lookingHelpId)
    );
  },
};
