import { withStyles, DialogContent, Typography, Grid } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import HouseIcon from 'shared/assets/img/icons/project-show/house.png';
import { enums } from 'shared/enums';
import Select from 'shared/newComponents/Select';
import TextField from 'shared/newComponents/TextField';
import { getSelectItems } from 'shared/utils/assets';
import { styles } from './ContractData.styles';

const ContractData = ({ classes, t, formik }) => {
  return (
    <div className={classes.bgBlue}>
      <DialogContent>
        <Typography
          variant="body1"
          className={classes.contractData}
        >{t`employmentManagementDialog:dialog2.contractData`}</Typography>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={1} xl={1}>
            <PowerSettingsNew className={classes.powerOnBtn} />
          </Grid>
          <Grid item xs={12} sm={5} xl={5}>
            <TextField
              formik={formik}
              type="date"
              name="contractStartDate"
              variant="transparent"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={1} xl={1}>
            <PowerSettingsNew className={classes.powerOffBtn} />
          </Grid>
          <Grid item xs={12} sm={5} xl={5}>
            <TextField
              formik={formik}
              type="date"
              variant="transparent"
              name="contractEndDate"
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={6}>
            <Select
              formik={formik}
              variant="transparent"
              name="terminationType"
              label={t`employmentManagementDialog:dialog2.momentOfTermination`}
              items={getSelectItems(enums.TERMINATION_TYPE)}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2} sm={1} xl={1}>
            <img src={HouseIcon} alt="house" height="30" />
          </Grid>
          <Grid item xs={12} sm={3} xl={3}>
            <TextField
              formik={formik}
              type="number"
              variant="transparent"
              name="price"
              label={t`employmentManagementDialog:dialog2.price`}
            />
          </Grid>
          <Grid item xs={12} sm={3} xl={3}>
            <Select
              formik={formik}
              variant="transparent"
              name="currency"
              label={t`employmentManagementDialog:dialog2.currency`}
              items={getSelectItems(enums.CURRENCY)}
              fontBold
            />
          </Grid>
          <Grid item xs={12} sm={5} xl={5}>
            <TextField
              formik={formik}
              type="number"
              variant="transparent"
              name="terminationDayNumber"
              label={t`employmentManagementDialog:dialog2.period`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            xl={12}
            className={classes.accommodationType}
          >
            <Select
              formik={formik}
              variant="transparent"
              name="type"
              label={t`employmentManagementDialog:dialog2.accommodationType`}
              items={getSelectItems(enums.ACCOMMODATION_TYPE)}
              fontBold
            />
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  );
};

export default withStyles(styles)(withTranslation()(ContractData));
