import { withStyles } from '@material-ui/core';
import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  addProjectCandidate,
  getProjectCandidateConfirmationStatus,
  clearProjectCandidateConfirmationStatus,
  acceptInvitationProjectCandidate,
  rejectInvitationProjectCandidate,
  getProjectsPendingToCandidate,
  ProjectV2Service,
  getNotifications,
  setNotificationRead,
} from '../../services';
import { showSnackbarMessage } from '../../store/messages/actions';
import { ActionButtonJobllegroRounded } from '../../utils/styledComponents';

const styles = theme => ({
  button: {
    float: 'right',
    background: '#52a3fc',
    color: '#fff',
    '&:hover': {
      background: '#70b3ff',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonAccept: {
    background: '#3bbf13',
    color: '#fff !important',
    '&:hover': {
      background: '#3dd60e',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonCancel: {
    background: '#f7544d',
    color: '#fff !important',
    '&:hover': {
      background: '#ed635e',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  iconClass: {
    float: 'right',
    marginRight: 10,
  },
});

export class AssignOrganizationToProjectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  acceptInvitation = async () => {
    const {
      notificationToAccept,
      showSnackbarMessage,
      setNotificationRead,
      projectDetails,
      t,
    } = this.props;
    const body = { organizationAcceptStatus: 1 };

    await ProjectV2Service.editProjectV2OrganizationStatus(
      projectDetails.id,
      body
    );

    await setNotificationRead(notificationToAccept.id);

    showSnackbarMessage(
      t('projectDetails:assignCandidateToProjectButton.actionSaved')
    );
    window.location.reload();
  };

  rejectInvitation = async () => {
    const {
      notificationToAccept,
      showSnackbarMessage,
      setNotificationRead,
      projectDetails,
      t,
    } = this.props;

    const body = { organizationAcceptStatus: 2 };

    await ProjectV2Service.editProjectV2OrganizationStatus(
      projectDetails.id,
      body
    );

    await setNotificationRead(notificationToAccept.id);

    showSnackbarMessage(
      t('projectDetails:assignCandidateToProjectButton.actionSaved')
    );
    window.location.reload();
  };

  render() {
    const { classes, projectDetails, t } = this.props;
    return (
      <React.Fragment>
        <ActionButtonJobllegroRounded
          onClick={this.acceptInvitation}
          className={classes.buttonAccept}
          disabled={projectDetails.closedStatus}
        >
          {t('projectDetails:assignCandidateToProjectButton.acceptRequest')}

          <DoneIcon className={classes.iconClass} />
        </ActionButtonJobllegroRounded>
        <ActionButtonJobllegroRounded
          onClick={this.rejectInvitation}
          className={classes.buttonCancel}
          disabled={projectDetails.closedStatus}
        >
          {t('projectDetails:assignCandidateToProjectButton.rejectRequest')}

          <CloseIcon className={classes.iconClass} />
        </ActionButtonJobllegroRounded>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  addProjectCandidate,
  getProjectCandidateConfirmationStatus,
  clearProjectCandidateConfirmationStatus,
  acceptInvitationProjectCandidate,
  rejectInvitationProjectCandidate,
  getProjectsPendingToCandidate,
  getNotifications,
  setNotificationRead,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(AssignOrganizationToProjectButton)));
