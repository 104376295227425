import {
  Grid,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import RecruiterCard from 'shared/newComponents/RecruiterCard';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import Box from '../../../components/BoxContainer';
import ExpansionComponent from '../../../components/LayoutExpansionComponent';
import ElementsListAsync from '../../../components/list/ElementsListAsync';

const styles = theme => ({
  container: {
    marginTop: 16,
  },
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 0,
  },
});

export class RecruiterList extends Component {
  getContentRecruiter = recruiter => {
    return <RecruiterCard recruiter={recruiter} />;
  };

  getContent = recruiter => {
    const content = this.getContentRecruiter(recruiter);
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={recruiter.id}>
        <Box>{content}</Box>
      </Grid>
    );
  };

  getWrapper = ({ content, pagination, sorting, filtering }) => {
    const { t, classes } = this.props;
    return (
      <div>
        <ExpansionComponent
          title={t('candidate:list.filteringTitle')}
          textOnTop={t('candidate:list.filteringDescription')}
        >
          {filtering}
        </ExpansionComponent>
        {sorting}
        {this.props.load ? (
          <LinearProgress />
        ) : (
          <Grid container className={classes.rootPaper}>
            {content.length !== 0 ? (
              content
            ) : (
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h1"
                  align="center"
                  style={{ paddingTop: '15%', height: '50vh' }}
                >
                  {t('recruiter:list.noRecruitersFound')}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {pagination}
      </div>
    );
  };

  render() {
    const { t } = this.props;

    let sortingOptions = [
      {
        name: 'id',
        label: t('candidate:field.id'),
      },
      {
        name: 'firstName',
        label: t('candidate:field.firstName'),
      },
      {
        name: 'lastName',
        label: t('candidate:field.lastName'),
      },
      {
        name: 'email',
        label: t('candidate:field.email'),
      },
      {
        name: 'industry',
        label: t('candidate:field.industry'),
      },
      {
        name: 'createdAt',
        label: t('candidate:field.createdAt'),
      },
      {
        name: 'updatedAt',
        label: t('candidate:field.updatedAt'),
      },
    ];
    const defaultSorting = {
      //id: 'asc',
    };
    const filteringOptions = [
      {
        key: 'firstName',
        type: 'text',
        name: t('recruiter:field.firstName'),
      },
      {
        key: 'lastName',
        type: 'text',
        name: t('recruiter:field.lastName'),
      },
      {
        key: 'email',
        type: 'text',
        name: t('recruiter:field.email'),
      },
      {
        key: 'industry',
        type: 'text',
        name: t('recruiter:field.industry'),
      },
      {
        key: 'nationality',
        type: 'text',
        name: t('recruiter:field.nationality'),
      },
    ];

    return (
      <ElementsListAsync
        storageKey={this.props.storageKey}
        rowsPerPage={getTableRowPerPageFromStorage(this.props.storageKey)}
        handleReloadElements={this.props.handleReloadElements}
        load={this.props.load}
        getContent={this.getContent}
        getWrapper={this.getWrapper}
        sortingOptions={sortingOptions}
        defaultSorting={defaultSorting}
        filteringOptions={filteringOptions}
        elements={this.props.recruiters}
        elementsCount={this.props.recruitersCount}
        scrollUp={true}
      />
    );
  }
}

RecruiterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    recruiters: store.recruiters.currentRecruitersLimitedList,
    recruitersCount: store.recruiters.currentRecruitersLimitedListCounter,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(RecruiterList)));
