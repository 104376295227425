import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CrossIcon from 'shared/assets/img/icons/project-show/cross.png';
import ConfirmationDialog from '../../../components/LayoutConfirmationDialog';
import { ActionIconButton } from '../../../modules/project/NewProjectShowView/components/GeneralInformations/components/SideView/components';
import { editProject, getProject } from '../../services';
import { showSnackbarMessage } from '../../store/messages/actions';

const ProjectClose = ({
  projectDetails,
  t,
  editProject,
  getProject,
  showSnackbarMessage,
}) => {
  const [confirmationOpened, setConfirmationOpened] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationClosedStatus, setConfirmationClosedStatus] =
    useState(null);
  const closeProject = async confirmationClosedStatus => {
    try {
      const closedStatus = projectDetails.closedStatus;

      let snackBarText = '';

      if (confirmationClosedStatus === 0) {
        snackBarText = t('project:form.yourProjectWasRejectedFromClosed');
      } else if (
        confirmationClosedStatus === 1 ||
        confirmationClosedStatus === 2
      ) {
        snackBarText = t('project:form.yourProjectWasClosed');
      }
      if (closedStatus === 0 || closedStatus === 1) {
        const closeProjectData = {
          id: projectDetails.id,
          closedStatus: confirmationClosedStatus,
        };
        await editProject(closeProjectData);
        await getProject(projectDetails.id);
        showSnackbarMessage(snackBarText);
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmationOpen = id => {
    let confirmationText = '';
    if (id === 0) {
      confirmationText = t('project:form.confirmToRejectProjectByHm');
    } else if (id === 1) {
      confirmationText = t('project:form.confirmToCloseProject');
    } else if (id === 2) {
      confirmationText = t('project:form.confirmToCloseProjectByHm');
    }
    setConfirmationOpened(true);
    setConfirmationClosedStatus(id);
    setConfirmationMessage(confirmationText);
  };

  const handleConfirmationClose = isOk => {
    if (isOk) {
      closeProject(confirmationClosedStatus);
    }
    setConfirmationOpened(false);
  };

  const { permissions = {} } = projectDetails;
  const { isOwner = {}, isHiringManager = {}, isSuperUser = {} } = permissions;

  let showButton = false;
  if (!projectDetails.closedStatus && (isOwner || isSuperUser)) {
    showButton = true;
  }
  return (
    (isOwner || isHiringManager || isSuperUser) && (
      <React.Fragment>
        <ConfirmationDialog
          open={confirmationOpened}
          titleText={t('project:form.closeProject')}
          onClose={handleConfirmationClose}
          okButtonText={
            confirmationClosedStatus === 0
              ? t('action.rejectClose')
              : t('action.close')
          }
          cancelButtonText={t('action.cancel')}
        >
          {confirmationMessage}
        </ConfirmationDialog>

        {(isOwner || isSuperUser) &&
          (projectDetails.closedStatus === 0 ||
            projectDetails.closedStatus === 2) && (
            <ActionIconButton
              name={t('projectDetails:content.closeProject')}
              onClick={() => {
                handleConfirmationOpen(1);
              }}
              icon={CrossIcon}
              disabled={
                !showButton ||
                projectDetails.closedStatus ||
                projectDetails.isDraft
              }
            />
          )}
      </React.Fragment>
    )
  );
};

const mapDispatchToProps = {
  editProject,
  getProject,
  showSnackbarMessage,
};

ProjectClose.propTypes = {
  projectDetails: PropTypes.object.isRequired,
  editProject: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(ProjectClose));
