import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { editProject } from 'shared/services/projectService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';

export class ProjectMakePublicButton extends Component {
  makePublic = async () => {
    try {
      const { project, t } = this.props;
      const { status } = await this.props.editProject({
        id: project.id,
        isDraft: false,
      });
      if (status === 200) {
        this.props.showSnackbarMessage(t('project:show.projectPublished'));
        window.location.reload();
      } else {
        this.props.showSnackbarMessage(t('form.error.error'));
      }
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const {
      project: { isDraft, permissions = {} },
      t,
    } = this.props;

    return permissions.isOwner && isDraft ? (
      <React.Fragment>
        <ActionButtonJobllegroRounded onClick={this.makePublic}>
          {t('project:show.makeProjectPublic')}
        </ActionButtonJobllegroRounded>
      </React.Fragment>
    ) : null;
  }
}

const mapDispatchToProps = {
  editProject,
  showSnackbarMessage,
};

const mapStateToProps = store => {
  return {
    project: store.projects.currentProject,
  };
};

ProjectMakePublicButton.propTypes = {
  project: PropTypes.object.isRequired,
  editProject: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProjectMakePublicButton));
