import { withStyles } from '@material-ui/core/styles';
import ResetPasswordForm from './ResetPasswordForm';

const styles = theme => ({
  card: {
    maxWidth: 600,
    minWidth: 500,
    marginTop: '10%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  root: {
    zIndex: '1',
  },
  field: {
    marginTop: 10,
    padding: 0,
    display: 'flex',
  },
});

export default withStyles(styles)(ResetPasswordForm);
