export const styles = theme => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#2D333B',
  },
  dialogTitle: {
    '& h6': { fontWeight: '500' },
  },
  location: { '& h6': { fontWeight: '500' }, margin: '20px 0' },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paperHeader: { marginBottom: 20 },
  span: { marginLeft: 40, fontWeight: 'bold' },
  info: { margin: '20px 0' },
  bold: { fontWeight: 'bold' },
  employee: {
    fontSize: 26,
    color: '#148EFF',
    marginTop: 20,
    marginBottom: 40,
    textAlign: 'center',
  },
  saveBtn: {
    color: '#fff',
    display: 'flex',
    margin: '40px auto',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  blueBg: { background: '#E5F0FF', padding: '20px 34px' },
  txtFields: { display: 'flex', alignItems: 'center' },
  homeNumber: { width: 185 },
  checkboxes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  grow: { flexGrow: 1 },
});
