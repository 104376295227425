import { Grid, Paper, LinearProgress, withStyles } from '@material-ui/core';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { addCity, getCities } from 'shared/services/cityService';
import { getCompetenceCategories } from 'shared/services/competenceCategoryService';
import { getCompetenceKinds } from 'shared/services/competenceKindService';
import { getCompetenceTemp } from 'shared/services/competenceTempService';
import { getCompetenceTypes } from 'shared/services/competenceTypeService';
import { getIndustryCategories } from 'shared/services/industryCategoryService';
import { addIndustry, getIndustries } from 'shared/services/industryService';
import { addLanguage, getLanguages } from 'shared/services/languageService';
import {
  getProject,
  editProject,
  editProjectLanguages,
  editProjectSkills,
  getProjectRecruiters,
} from 'shared/services/projectService';
import { clearCurrentRecruitersList } from 'shared/services/recruiterService';
import { addSkill, getSkills } from 'shared/services/skillService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { checkIfRecruitersCoAreInProject } from 'shared/utils/functions';
import { checkIfExsistInObject } from 'shared/utils/normalizers';
import PageHeaderTitleComponent from '../../components/PageHeaderTitleComponent';
import ProjectForm from '../../components/projectForm/ProjectForm';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  }),
  bottomNavigation: {
    marginTop: 20,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export class ProjectEditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingProject: true,
      isLastStepIsBlockedByRecruiters: false,
      componentMountFully: false,
    };
  }

  handleSubmitForm = async values => {
    const { t } = this.props;
    values['industry'] = null;

    const projectLanguages = values['projectLanguages'] || [];
    delete values['projectLanguages'];
    // TODO na projectSkill
    const projectSkills = values['projectSkills'] || [];
    delete values['projectSkills'];
    // TODO na projectSkill
    delete values['tempProjectSkills'];

    delete values['organization'];
    delete values['hiringManager'];
    delete values['isEdited'];
    try {
      let result = await this.props.editProject(values);
      if (result && result.status === 200) {
        if (
          checkIfExsistInObject(
            this.props.DictionariesCities,
            values.city,
            'name'
          ) === false
        ) {
          await this.props.addCity(values.city);
        }
        if (
          checkIfExsistInObject(
            this.props.DictionariesIndustries,
            values.industry,
            'name'
          ) === false
        ) {
          await this.props.addIndustry(values.industry);
        }
        //Languages
        await Promise.all(
          projectLanguages.map(async language => {
            if (
              checkIfExsistInObject(
                this.props.DictionariesLanguages,
                language.language.name,
                'name'
              ) === false
            ) {
              await this.props.addLanguage(language.language.name);
            }
          })
        );
        const languages = projectLanguages.map(language => {
          const dictLanguage =
            find(this.props.DictionariesLanguages, [
              'name',
              language.language.name,
            ]) || {};
          return {
            languageId: dictLanguage.id,
            level: language.level,
          };
        });
        await this.props.editProjectLanguages(values.id, languages);

        const skills = projectSkills.map(skill => {
          return {
            skill: {
              name: skill.skill.name,
              level: skill.level,
              industry: skill.industry,
              industryCategory: skill.industryCategory.id,
              experience: parseInt(skill.experience, 10),
            },
            competence: {
              competenceCategory: skill.competence.competenceCategory.name,
              competenceKind: skill.competence.competenceKind.name,
              competenceType: skill.competence.competenceType.name,
            },
          };
        });
        await editProjectSkills(values.id, skills);

        this.props.showSnackbarMessage(t`projectCreator:projectEdited`);

        await this.loadData(values.id);

        this.props.history.push(PATHS.PROJECT.SHOW.replace(':id', values.id));
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  loadData = async id => {
    this.setState({ isLoadingProject: true });
    await this.props.getProject(id);
    await this.props.getProjectRecruiters(id);
    const {
      currentProject: {
        permissions: { isHiringManager },
      },
    } = await this.props;
    isHiringManager &&
      this.props.history.push(PATHS.PROJECT.SHOW.replace(':id', id));
    this.setState({ isLoadingProject: false });
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    await this.props.getCities();
    await this.props.getCompetenceCategories();
    await this.props.getCompetenceKinds();
    await this.props.getCompetenceTypes();
    await this.props.getCompetenceTemp(id);
    await this.props.getIndustries();
    await this.props.getIndustryCategories();
    await this.props.getLanguages();
    await this.props.getSkills();

    await this.loadData(id);
    const checkRecruiters = checkIfRecruitersCoAreInProject(
      this.props.currentRecruitersList
    );
    await this.setState({
      isLastStepIsBlockedByRecruiters: checkRecruiters.length > 0 && true,
      componentMountFully: true,
    });
  }

  componentWillUnmount() {
    this.props.clearCurrentRecruitersList();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { id } = nextProps.match.params;
      await this.loadData(id);
    }
  }

  render() {
    const { classes, t } = this.props;
    const {
      isLoadingProject,
      isLastStepIsBlockedByRecruiters,
      componentMountFully,
    } = this.state;

    return (
      <Grid
        container
        alignItems="flex-start"
        spacing={2}
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} xl={8} lg={8}>
          <PageHeaderTitleComponent title={t('project:menu.editProject')} />
          <Paper className={classes.rootPaper} elevation={4}>
            {isLoadingProject || !componentMountFully ? (
              <LinearProgress />
            ) : (
              <ProjectForm
                isEdited={true}
                handleSubmitTrigger={this.handleSubmitForm}
                isLoading={isLoadingProject}
                isLastStepIsBlockedByRecruiters={
                  isLastStepIsBlockedByRecruiters
                }
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.auth.currentUser,
    DictionariesCities: state.dictionaries.cities.list,
    DictionariesIndustries: state.dictionaries.industries.list,
    DictionariesLanguages: state.dictionaries.languages.list,
    DictionariesSkills: state.dictionaries.skills.list,
    currentProject: state.projects.currentProject,
    currentRecruitersList: state.recruiters.currentRecruitersList,
  };
};

const mapDispatchToProps = {
  getProject,
  editProject,
  editProjectLanguages,
  addCity,
  getCities,
  getCompetenceCategories,
  getCompetenceKinds,
  getCompetenceTemp,
  getCompetenceTypes,
  addIndustry,
  getIndustryCategories,
  getIndustries,
  addLanguage,
  getLanguages,
  addSkill,
  getSkills,
  getProjectRecruiters,
  clearCurrentRecruitersList,
  showSnackbarMessage,
};

ProjectEditView.propTypes = {
  classes: PropTypes.object.isRequired,
  getProject: PropTypes.func.isRequired,
  editProjectLanguages: PropTypes.func.isRequired,
  editProjectSkills: PropTypes.func.isRequired,
  addCity: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  addIndustry: PropTypes.func.isRequired,
  getIndustries: PropTypes.func.isRequired,
  addLanguage: PropTypes.func.isRequired,
  getLanguages: PropTypes.func.isRequired,
  addSkill: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  getProjectRecruiters: PropTypes.func.isRequired,
  clearCurrentRecruitersList: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(ProjectEditView))));
