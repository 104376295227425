export const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    padding: '16px 150px',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
    marginTop: 8,
  },
  rightList: {
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  rightSectionProjectDetails: {
    boxSizing: 'border-box',
    display: 'flex',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-end',
    placeContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 16,
      height: 'auto',
      alignItems: 'center',
    },
  },
  buttonRedirect: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
    },
  },
  buttonTab: {
    minWidth: 150,
    margin: 0,
    color: '#b3b3b3',
    '&:hover': {
      background: '#fff',
      color: '#53a3fc',
    },
    '&:active': {
      background: 'transparent !important',
    },
  },
  selected: {
    background: 'transparent',
    color: theme.palette.jobllegroBlue,
  },
  indicator: {
    backgroundColor: theme.palette.jobllegroBlue,
  },
  divider: {
    backgroundColor: theme.palette.jobllegroBorderGrey,
  },
});
