import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { forgotPassword, logoutUser } from 'shared/services/authService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import ForgotPassword from './ForgotPassword.style';

const mapDispatchToProps = {
  forgotPassword,
  logoutUser,
  showSnackbarMessage,
};

const ForgotPasswordContainer = connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(ForgotPassword)));

ForgotPasswordContainer.displayName = 'ForgotPasswordContainer';

export default ForgotPasswordContainer;
