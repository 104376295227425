import { withStyles } from '@material-ui/core/styles';
import { CustomDialog } from './CustomDialog';

const styles = () => ({
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default withStyles(styles)(CustomDialog);
