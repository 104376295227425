import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import LayoutLeftMenu from './LayoutLeftMenu.style';

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    config: store.auth.config,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(LayoutLeftMenu));
