import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  uploadAssets,
  deleteAsset,
  getAssets,
} from 'shared/services/assetService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import FileInput from '../../components/asset/fileinput/FileInput';

export class AssetsUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploadIsOpened: props.opened || false,
    };
  }

  openUploadModal = () => {
    this.setState({
      uploadIsOpened: true,
    });
  };

  closeUploadModal = () => {
    this.props.closeHandler && this.props.closeHandler();
    this.setState({
      uploadIsOpened: false,
    });
  };

  saveAssets = async files => {
    const { t } = this.props;
    let assetsOnServer = false;
    if (this.props.uploadAssetsHandler) {
      assetsOnServer = await this.props.uploadAssetsHandler(
        files,
        this.props.assetType
      );
    } else {
      await this.props.uploadAssets(files, this.props.assetType);
    }
    this.setState({
      uploadIsOpened: false,
    });
    await this.loadAssets();
    if (assetsOnServer) {
      this.props.showSnackbarMessage(t('core:form:info.filesUploadAgain'));
    } else {
      this.props.showSnackbarMessage(t('core:form:info.filesUploadSuccess'));
    }
  };

  deleteAsset = async id => {
    this.props.deleteAsset(id);
  };

  loadAssets = async newAssets => {
    let files = [];
    let assets;

    this.props.assetType &&
      (assets = filter(newAssets || this.props.assets, {
        type: this.props.assetType,
      }));

    if (assets) {
      assets.forEach(file => {
        let fileObject = new File([], file.filename, { type: file.filetype });
        fileObject.preview = '/api/resource/' + file.id;
        fileObject.id = file.id;
        fileObject.assetType = this.props.assetType || null;
        files.push(fileObject);
      });
      this.setState({
        files: files,
      });
    }
  };

  async componentDidMount() {
    this.loadAssets();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.assets !== this.props.assets) {
      this.loadAssets(nextProps.assets);
    }
  }

  render() {
    const {
      children,
      filesLimit = 4,
      maxSize = 5000000,
      acceptedFiles,
      cropShape,
      aspect,
      removeIcon = true,
      disabled = false,
    } = this.props;
    const { uploadIsOpened, files } = this.state;

    return (
      <React.Fragment>
        <FileInput
          disabled={disabled}
          removeIcon={removeIcon}
          cropShape={cropShape}
          aspect={aspect}
          open={uploadIsOpened}
          files={files}
          closeDialog={this.closeUploadModal}
          saveFiles={this.saveAssets}
          deleteFile={this.deleteAsset}
          filesLimit={filesLimit}
          maxSize={maxSize}
          acceptedFiles={acceptedFiles}
        />
        <div onClick={disabled ? () => {} : this.openUploadModal}>
          {children}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  uploadAssets,
  deleteAsset,
  getAssets,
  showSnackbarMessage,
};

AssetsUpload.propTypes = {
  assetType: PropTypes.string,
  filesLimit: PropTypes.number,
  maxSize: PropTypes.number,
  uploadAssetsHandler: PropTypes.func.isRequired,
  getAssetsHandler: PropTypes.func,
  uploadAssets: PropTypes.func.isRequired,
  deleteAsset: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(AssetsUpload));
