import { Grid, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { find, get } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { ProgressBar, Translate } from 'shared/newComponents';
import { getAssetUrl } from 'shared/services';
import { getLevelProc } from 'shared/utils/functions';
import RecruiterMenu from '../../../recruiterMenu/RecruiterMenu';
import { styles } from './RecruiterCandidateProjectsDetailsContent.styles';

const RecruiterCandidateProjectsDetailsContent = ({
  recruiterDetails,
  recruiterProjectCandidate,
  history,
  t,
  classes,
  currentUser,
}) => {
  const handleRedirectToProject = projectId => {
    history.push(PATHS.PROJECT.NEW_SHOW.replace(':id', projectId));
  };
  return (
    <Grid container className={classes.wrapper}>
      <RecruiterMenu
        itemSelected="all-projects"
        title={'candidateDetails:anonymousRecruiterDetailsCard.menuTitle'}
        subtitle={'candidateDetails:anonymousRecruiterDetailsCard.subTitle'}
        currentRecruiter={recruiterDetails}
        currentUser={currentUser}
      />
      <Grid container alignContent={'center'} className={classes.tableWrapper}>
        <Table stickyheader="true" className={classes.tableContainer}>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('candidateDetails:allProjects.tablePosition')}
              </TableCell>
              <TableCell>{t`recruiter:publicationDate`}</TableCell>
              <TableCell>{t('recruiter:recruitmentStage')}</TableCell>
              <TableCell>{t('recruiter:amountCand')}</TableCell>
              <TableCell>{t('recruiter:income')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recruiterProjectCandidate.map(item => {
              const position = item.project.position;
              const projectId = item.project.id;
              const client = item.project.client;
              const finicalStageResult = item.finicalStageResult;
              const candidateInStage = item.candidateInStage;
              const recruiterIsMentorOrOwnerInProject =
                item.recruiterIsMentorOrOwnerInProject;

              const logoId = get(
                find(get(item.project, 'assets'), o => o.type === 'logo'),
                'id'
              );
              const logo = getAssetUrl(logoId);

              const dateUpdate = item.project.updatedAt;

              const { levelProc, desc } = getLevelProc(item, t);
              return (
                <TableRow key={item.id}>
                  <TableCell align={'center'}>
                    <Grid container alignItems={'center'}>
                      <Grid
                        item
                        xs={5}
                        sm={5}
                        md={5}
                        className={classes.imageWrapper}
                      >
                        <Grid container justifyContent={'center'}>
                          <img alt="logo" src={logo} height={50} />
                        </Grid>
                      </Grid>
                      <Grid item xs={7} sm={7} md={7}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            <Translate
                              text={position}
                              bold
                              disableTranslate={true}
                              className={classes.positionText}
                              onClick={() => handleRedirectToProject(projectId)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                className={classes.clientWrapper}
                              >
                                <Translate
                                  text={client}
                                  bold
                                  disableTranslate={true}
                                  className={classes.clientText}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Translate
                          text={dateUpdate}
                          disableTranslate={true}
                          className={classes.grayText}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <ProgressBar
                          percentage={levelProc}
                          title={desc}
                          className={classes.grayText}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Translate
                          text={candidateInStage}
                          disableTranslate={true}
                          className={classes.grayText}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Translate
                          text={`${
                            recruiterIsMentorOrOwnerInProject
                              ? '-'
                              : finicalStageResult.toFixed(2) + 'PLN'
                          } `}
                          disableTranslate={true}
                          className={classes.grayText}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(RecruiterCandidateProjectsDetailsContent))
);
