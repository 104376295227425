import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ProjectDetailsViewLeftDescription from '../../../../../components/ProjectDetailsViewLeftDescription';
import ProjectStepper from '../../../../../components/ProjectStepper/ProjectStepper';
import { styles } from './ProjectDetails.styles';

const ProjectAtsEmploymentProcessViewContent = ({ project, t }) => {
  return (
    <Grid container alignItems="flex-start" spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ProjectDetailsViewLeftDescription
          title={t('project:show.candidatesInProject')}
          project={project}
          stepperComponent={
            <ProjectStepper
              startDate={project.projectStartDate}
              endDate={project.projectEndDate}
              marginTop={120}
              type="wide"
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(ProjectAtsEmploymentProcessViewContent))
);
