import {
  Avatar,
  Button,
  Card,
  CardHeader,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getNotifications,
  setNotificationRead,
} from 'shared/services/notificationService';
import { editProject } from 'shared/services/projectService';
import { getRecruiters } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getUserAvatarSrc } from 'shared/utils/assets';
import { getMentorStatusInProject } from 'shared/utils/functions';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';
import MentorFeeDialog from './MentorFeeDialog';
import ElementList from './list/ElementsList';

const styles = theme => ({
  buttonOptionsAccept: {
    color: '#fff',
    background: theme.palette.jobllegroSuccess,
    opacity: 0.7,
    height: 'auto',
    '&:hover': {
      background: theme.palette.jobllegroSuccess,
      opacity: 1,
    },
  },
  buttonOptionsReject: {
    color: '#fff',
    background: theme.palette.jobllegroRedAlert,
    opacity: 0.7,
    height: 'auto',
    '&:hover': {
      background: theme.palette.jobllegroRedAlert,
      opacity: 1,
    },
  },
  infoText: {
    color: theme.palette.jobllegroSuccess,
    fontSize: 12,
  },
  toggleContainer: {
    height: 'auto',
    padding: `${theme.spacing()}px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing()}px 0`,
    background: theme.palette.background.default,
  },
  cardTopSectionTitle: {
    marginTop: 10,
    fontSize: 11,
    background: theme.palette.jobllegroOrange,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
  },
  card: {
    maxWidth: 320,
    boxShadow: 'none',
  },
  cardHeader: {
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeaderAvatar: {
    width: 20,
    height: 20,
  },
  titleCardHeader: {
    fontSize: 12,
  },
  titleCardSubHeader: {
    fontSize: 12,
  },
  avatarTitleCardHeader: {
    marginRight: 8,
    cursor: 'pointer',
  },
  cardTopChange: {
    marginLeft: 10,
    marginTop: 10,
    fontSize: 11,
    background: theme.palette.jobllegroBlue,
    display: 'inline-block',
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '20px',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.jobllegroSuccessActive,
    },
  },
});

export class AssignMentorToProjectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mentorsList: [],
      isLoading: false,
      choiceButtonStatus: false,
      openMentorFee: false,
    };
  }

  loadData = async () => {
    const { data } = await this.props.getRecruiters({
      filters: { mentorStatus: 2 },
    });
    this.setState({
      mentorsList: data,
    });
  };

  handleClickOpen = async () => {
    this.setState({
      isLoading: true,
    });
    await this.loadData();
    this.setState({
      open: true,
      isLoading: false,
    });
  };

  handleSelect = async recruiterId => {
    await this.setState({ choiceButtonStatus: true });
    const { t } = this.props;
    const mentorData = {
      id: this.props.projectDetails.id,
      mentorId: recruiterId,
    };
    const { status } = await this.props.editProject(mentorData);
    if (status !== 200) {
      this.props.showSnackbarMessage('Brak możliwości przypisania');
      return;
    }
    await this.setState({ open: false });
    await this.setState({ choiceButtonStatus: false });
    this.props.showSnackbarMessage(t('project:form.addMentorInfoSuccess'));
  };

  handleRemoveMentor = async () => {
    const { t } = this.props;
    const mentorData = {
      id: this.props.projectDetails.id,
      mentorId: null,
    };
    await this.props.editProject(mentorData);
    this.setState({ open: false });
    this.props.showSnackbarMessage(t('project:form.removeMentorFromProject'));
  };

  handleChooseOption = idOption => async () => {
    const { t, setNotificationRead, getNotifications, notifications } =
      this.props;
    const mentorData = {
      id: this.props.projectDetails.id,
      mentorStatus: idOption,
    };
    await this.props.editProject(mentorData);
    const projectDetailsId = this.props.projectDetails.id;
    const findNotification = find(notifications, function (n) {
      const { project = {} } = n.data;
      return project.id === projectDetailsId;
    });
    await setNotificationRead(findNotification.id);
    await getNotifications();
    this.props.showSnackbarMessage(
      t(
        idOption === 2
          ? 'project:form.acceptByMentor'
          : 'project:form.rejectByMentor'
      )
    );
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  isMentor = recruiter =>
    this.props.projectDetails.mentor &&
    recruiter.id === this.props.projectDetails.mentor.id;

  getContent(recruiter) {
    const {
      t,
      currentUser: { id },
    } = this.props;
    const { choiceButtonStatus } = this.state;
    const avatar = getUserAvatarSrc(recruiter);
    const {
      mentor = {},
      mentorStatus,
      permissions: { isOwner },
      mentorFee,
    } = this.props.projectDetails;

    let secondaryText = {};
    if (!mentorFee) {
      this.setState({
        openMentorFee: true,
      });
    }
    if (mentor && recruiter.id === mentor.id) {
      secondaryText = t(getMentorStatusInProject(mentorStatus));
    } else {
      secondaryText =
        recruiter &&
        recruiter.industry &&
        t('project:field.industry') + ': ' + recruiter.industry;
    }

    return (
      recruiter &&
      recruiter.id !== id && (
        <ListItem key={recruiter.id}>
          <Avatar
            src={avatar}
            aria-label={recruiter.firstName + ' ' + recruiter.lastName}
          ></Avatar>
          <ListItemText
            primary={recruiter.firstName + ' ' + recruiter.lastName}
            secondary={secondaryText}
          />
          {this.isMentor(recruiter) ? null : (
            <Button
              disabled={choiceButtonStatus}
              onClick={this.handleSelect.bind(this, recruiter.id)}
              size="small"
              variant="contained"
              color="primary"
            >
              {t('action.choose')}
            </Button>
          )}
          {mentor !== null && recruiter.id === mentor.id && isOwner && (
            <ListItemSecondaryAction>
              <IconButton
                onClick={this.handleRemoveMentor}
                aria-label="Remove mentor"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      )
    );
  }

  getRecruiterContent = recruiter => {
    return this.isMentor(recruiter) ? null : this.getContent(recruiter);
  };

  getWrapper = options => {
    let { content, pagination } = options;
    let mentor = this.getContent(this.props.projectDetails.mentor);
    return (
      <React.Fragment>
        {mentor !== null && <List component={'ul'}>{mentor}</List>}
        <Divider />
        <DialogContent>
          <List component={'ul'}>{content}</List>
        </DialogContent>
        {pagination}
      </React.Fragment>
    );
  };

  handleClickRedirect = (mentorID, projectID, position) => {
    this.props.history.push({
      pathname: PATHS.RECRUITER.SHOW.replace(':id', mentorID),
      state: { subject: `Projekt: ${projectID} ${position}` },
    });
  };

  getMentorActionButton = (mentorStatus, isMentorAssignedToProject, scope) => {
    const { classes, t, currentUser } = this.props;
    const {
      position,
      mentor = {},
      id,
      permissions: { isCooperator, isHiringManager, isOwner },
    } = this.props.projectDetails;
    let showStatus = 0;
    let avatar = null;
    let showButton = true;
    if (
      (isCooperator && isMentorAssignedToProject) ||
      (mentor && mentor.id === currentUser.id)
    ) {
      showButton = false;
    }
    if (
      mentorStatus === 1 &&
      isMentorAssignedToProject &&
      mentor.id === currentUser.id
    ) {
      showStatus = 1;
    } else if (mentorStatus === 1 && isMentorAssignedToProject) {
      showStatus = 3;
      avatar = getUserAvatarSrc(mentor);
    } else if (mentorStatus === 2 && isMentorAssignedToProject) {
      showStatus = 2;
      avatar = getUserAvatarSrc(mentor);
    }
    return (
      <React.Fragment>
        {(showStatus === 2 || showStatus === 3) && (
          <React.Fragment>
            <Typography className={classes.cardTopSectionTitle}>
              Mentor
            </Typography>
            {showButton && (
              <Typography
                onClick={!isHiringManager ? this.handleClickOpen : undefined}
                className={classes.cardTopChange}
              >
                {t`assign:change`}
              </Typography>
            )}
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  title: classes.titleCardHeader,
                  subheader: classes.titleCardSubHeader,
                  avatar: classes.avatarTitleCardHeader,
                }}
                onClick={() =>
                  this.handleClickRedirect(mentor.id, id, position)
                }
                avatar={
                  <Avatar className={classes.cardHeaderAvatar} src={avatar} />
                }
                title={mentor.firstName + ' ' + mentor.lastName}
                subheader={t(getMentorStatusInProject(mentorStatus))}
              />
            </Card>
          </React.Fragment>
        )}

        {showStatus === 1 && (
          <React.Fragment>
            <Typography
              className={classes.infoText}
              variant="subtitle1"
              gutterBottom
            >
              {t`assign:askToBeMentor`}
              <br />
              {t`assign:mentorQuestion`} {position} ?
            </Typography>
            <div className={classes.toggleContainer}>
              <ToggleButtonGroup exclusive>
                <ToggleButton
                  className={classes.buttonOptionsAccept}
                  value={2}
                  onClick={this.handleChooseOption(2)}
                >
                  {t('core:action.yesImApproving')}
                </ToggleButton>
                <ToggleButton
                  className={classes.buttonOptionsReject}
                  value={0}
                  onClick={this.handleChooseOption(0)}
                >
                  {t('core:action.noImRejecting')}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </React.Fragment>
        )}

        {showStatus === 0 && isOwner && (
          <ActionButtonJobllegroRounded
            onClick={this.handleClickOpen}
            // disabled={scope !== 2 && true}
          >
            {t('project:form.addMentor')}
          </ActionButtonJobllegroRounded>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { mentor = {}, mentorStatus } = this.props.projectDetails;
    const {
      currentUser: { id },
      projectDetails: { permissions = {}, closedStatus = false, scope },
      t,
    } = this.props;
    const { mentorsList, isLoading, openMentorFee } = this.state;
    const { isOwner = false, isCooperator = false } = permissions;
    let isMentorAssignedToProject = false;
    if (mentor && (id === mentor.id || isOwner || isCooperator)) {
      isMentorAssignedToProject = true;
    }
    const getMentorActionButton = this.getMentorActionButton(
      mentorStatus,
      isMentorAssignedToProject,
      scope
    );

    return (
      (isOwner || isMentorAssignedToProject) &&
      !closedStatus && (
        <React.Fragment>
          {getMentorActionButton}
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="mentor-dialog"
          >
            <DialogTitle id="mentor-dialog">
              {t('project:show.selectMentor')}
            </DialogTitle>
            {isLoading ? (
              <LinearProgress style={{ marginTop: 50, marginBottom: 50 }} />
            ) : openMentorFee ? (
              <MentorFeeDialog />
            ) : !isEmpty(mentorsList) ? (
              <ElementList
                elements={mentorsList}
                getContent={this.getRecruiterContent}
                getWrapper={this.getWrapper}
              />
            ) : (
              <DialogContent>
                <Typography variant="subtitle1">
                  {t('project:field.thereIsNoMentorInSystem')}
                </Typography>
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                {t('action.cancel')}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    projectDetails: store.projects.currentProject,
    notifications: store.notifications.list,
  };
};

const mapDispatchToProps = {
  getNotifications,
  setNotificationRead,
  editProject,
  getRecruiters,
  showSnackbarMessage,
};

AssignMentorToProjectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  editProject: PropTypes.func.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(withStyles(styles)(withTranslation()(AssignMentorToProjectButton)))
);
