import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { CandidateV2Service } from '../services';
import { getCurrentUser } from '../services/authService';
import HelpCandidateContent from './HelpCandidateContent';

const HelpRecruiter = ({ match, history, t, ...props }) => {
  const [candidate, setCandidate] = useState({});
  const [fetchDone, setFetchDone] = useState(false);

  const getCandidateById = id => {
    CandidateV2Service.getCandidateV2(id)
      .then(({ data }) => {
        setCandidate(data);
        if (!data.email) {
          history.push(PATHS.CANDIDATE.SHOW.replace(':id', data.id));
        }
        setFetchDone(true);
      })
      .catch(() => {
        //TODO: redirect to 404
        alert(t`candidate:dataError`);
      });
  };

  useEffect(() => {
    shouldUpdateRecruiter();
  }, []);

  const shouldUpdateRecruiter = () => {
    props.getCurrentUser();
    const { id } = match.params;

    if (!id) {
      getCandidateById(props.currentUser.id);
    } else {
      getCandidateById(id);
    }
  };
  const isSelfEdit =
    (candidate && props.currentUser.id === candidate.id) ||
    props.currentUser.isSuperUser ||
    (candidate.permissions && candidate.permissions.isOwner);

  if (!isSelfEdit && fetchDone) {
    history.push(PATHS.DASHBOARD.INDEX);
  }
  return fetchDone ? (
    <HelpCandidateContent
      isSelfEdit={isSelfEdit}
      currentCandidate={candidate}
    />
  ) : (
    <Grid
      container
      style={{ height: 'calc(100vh - 100px)', padding: '50px 0' }}
    >
      <LinearProgress style={{ width: '100%' }} />
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getCurrentUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(HelpRecruiter)));
