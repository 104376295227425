import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Button from 'shared/newComponents/Button/Button';
import TextField from 'shared/newComponents/TextField';
import { RecruiterV2Service } from 'shared/services';
import CustomDialog from '../../../../../../../../../../components/CustomDialog';

function PersonalDataForm({
  recruiterData,
  isOpen,
  onClose,
  recruiterEdited,
  t,
}) {
  const formData = {
    firstName: recruiterData ? recruiterData.firstName : '',
    lastName: recruiterData ? recruiterData.lastName : '',
    email: recruiterData ? recruiterData.email : '',
    phonePrimary:
      recruiterData && recruiterData.phonePrimary
        ? recruiterData.phonePrimary
        : '',
  };

  const phoneRegex = RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );

  const validationShema = Yup.object().shape({
    firstName: Yup.string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    lastName: Yup.string()
      .strict(false)
      .trim()
      .required(t('projectCreator:core.requiredField')),
    email: Yup.string()
      .email(t('candidateCreator:step1.invalidEmail'))
      .required(t('projectCreator:core.requiredField')),
    phonePrimary: Yup.string()
      .matches(phoneRegex, t('candidateCreator:step1.invalidPhoneNumber'))
      .trim()
      .min(9, t('candidateCreator:step1.invalidPhoneNumber'))
      .max(10, t('candidateCreator:step1.invalidPhoneNumber'))
      .required(t('projectCreator:core.requiredField')),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationShema,
    onSubmit: (values, actions) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phonePrimary: values.phonePrimary,
      };

      RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
        stage: 'personal_data',
      })
        .then(() => {
          recruiterEdited();
          onClose();
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 409) {
              return actions.setFieldError(
                'email',
                t`candidateCreator:emailTaken`
              );
            } else if (error.response.status === 418) {
              return actions.setFieldError(
                'phonePrimary',
                t`candidateCreator:phoneTaken`
              );
            }
          } else {
            console.log(error);
            alert(t`recruiter:saveError`);
          }
        });
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`candidateCreator:step1.personalData`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid container>
                <TextField
                  label={t`candidateCreator:step1.firstName`}
                  name={'firstName'}
                  formik={formik}
                />
              </Grid>
              <Grid container>
                <TextField
                  label={t`candidateCreator:step1.lastName`}
                  name={'lastName'}
                  formik={formik}
                />
              </Grid>{' '}
              <Grid container>
                <TextField
                  label={t`candidateCreator:step1.email`}
                  name={'email'}
                  formik={formik}
                />
              </Grid>{' '}
              <Grid container>
                <TextField
                  label={t`candidateCreator:step1.phone`}
                  name={'phonePrimary'}
                  formik={formik}
                />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Button label={t`adminCitySeo:save`} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
    />
  );
}

export default connect()(withTranslation()(PersonalDataForm));
