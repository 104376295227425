import _ from 'lodash';

export const filterFunction = ({ element, filters, filteringOptions }) => {
  let filter = true;
  _.forOwn(filters, (value, key) => {
    let parsedKey = _.endsWith(key, '-from') ? key.slice(0, -5) : key;
    parsedKey = _.endsWith(key, '-to') ? key.slice(0, -3) : parsedKey;
    if (element[parsedKey] !== null) {
      let { type } =
        filteringOptions[_.findKey(filteringOptions, { key: parsedKey })];
      if (type === 'text') {
        filter =
          filter &&
          _.includes(element[parsedKey].toLowerCase(), value.toLowerCase());
      } else if (type === 'select') {
        filter = filter && element[parsedKey] === value;
      } else if (type === 'boolean') {
        filter =
          filter &&
          (element[parsedKey] === Boolean(parseInt(value, 10)) ||
            (element[parsedKey.split('.')[0]] &&
              element[parsedKey.split('.')[0]][parsedKey.split('.')[1]] ===
                Boolean(parseInt(value, 10))));
      } else if (type === 'number') {
        filter =
          key === parsedKey + '-from'
            ? filter &&
              element[parsedKey] !== '' &&
              parseInt(element[parsedKey], 10) >= value
            : filter;
        filter =
          key === parsedKey + '-to'
            ? filter &&
              element[parsedKey] !== '' &&
              parseInt(element[parsedKey], 10) <= value
            : filter;
      }
    } else {
      filter = false;
    }
  });
  return filter;
};
