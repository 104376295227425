import { Grid, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getOrganization,
  deleteOrganizationRecruiter,
  getOrganizationRecruitersFilteredForAddingAsyncList,
} from 'shared/services/organizationService';
import {
  editRecruiter,
  clearCurrentRecruitersList,
  editRecruiterOrganization,
} from 'shared/services/recruiterService';
import { getRecruiters } from 'shared/services/recruiterService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { getTableRowPerPageFromStorage } from 'shared/utils/localStorage';
import OrganizationMenu from '../../components/OrganizationMenu';
import OrganizationRecruiters from '../../components/OrganizationRecruiters';
import OrganizationRightList from '../../components/OrganizationRightList';
import PageHeader from '../../components/PageHeader';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 8,
  },
  rightList: {
    marginTop: 25,
  },
});

export class OrganizationRecruitersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmationOpened: false,
      filter: '',
      tabPage: 0,
      options: { limit: 12, offset: 0, filters: null, orderBy: null },
    };
  }

  addOrganizationRecruiter = async recruiterId => {
    const { t } = this.props;
    try {
      const { id: organizationId } = this.props.match.params;
      const { status } = await this.props.editRecruiterOrganization({
        id: recruiterId,
        organizationId: organizationId,
      });
      if (status === 200) {
        this.loadData(organizationId);
        this.props.showSnackbarMessage(t`organization:recruiterAdded`);
      } else {
        this.props.showSnackbarMessage(t`organization:addError`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  deleteOrganizationRecruiter = async recruiterId => {
    const { t } = this.props;
    try {
      const { id: organizationId } = this.props.match.params;
      const { status } = await this.props.editRecruiterOrganization({
        id: recruiterId,
        organizationId: null,
      });
      if (status === 200) {
        this.loadData(organizationId);
        this.props.showSnackbarMessage(t`organization:recruiterDel`);
      } else {
        this.props.showSnackbarMessage(t`organization:delError`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  loadData = async (id, options = false) => {
    const limitToSearch = !options.limit
      ? getTableRowPerPageFromStorage(this.props.match.path) || 12
      : options.limit;
    const { offset, filters, orderBy } = options || this.state.options;
    const { tabPage } = this.state;
    this.setState({
      isLoading: true,
    });

    let recruitersParams = {};
    if (tabPage === 1) {
      recruitersParams = { inOrganization: true };
    }
    let params = {
      type: 'recruiter',
      filters,
      orderBy,
      ...recruitersParams,
    };
    this.props.getOrganization(id);
    await this.props.getOrganizationRecruitersFilteredForAddingAsyncList(
      id,
      limitToSearch,
      offset,
      params
    );
    this.setState({
      isLoading: false,
    });
  };

  handleReloadElements = async (options = false) => {
    const { id } = this.props.match.params;
    if (options) {
      this.setState({
        options,
        isLoading: false,
      });
    }
    if (!this.state.isLoading) {
      return this.loadData(id, options || this.state.options);
    } else {
      return null;
    }
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    this.loadData(id);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { id } = nextProps.match.params;
      this.loadData(id);
    }
  }

  render() {
    const {
      classes,
      organization: { owner = {} },
      currentUser,
      t,
    } = this.props;

    const { isLoading } = this.state;

    const isOwner = owner.id === currentUser.id;
    return (
      <React.Fragment>
        <Grid container alignItems="flex-start" spacing={0}>
          <PageHeader title={t`project:details.recruiters`} />
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
            <OrganizationMenu itemSelected="recruiters" />
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={isOwner ? 6 : 12}
                  lg={isOwner ? 6 : 12}
                >
                  <OrganizationRecruiters
                    isLoading={isLoading}
                    onDeleteHandle={this.deleteOrganizationRecruiter}
                    handleReloadElements={this.handleReloadElements}
                  />
                </Grid>
                {isOwner && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    className={classes.rightList}
                  >
                    <OrganizationRightList
                      storageKey={this.props.match.path}
                      handleReloadElements={this.handleReloadElements}
                      isLoading={isLoading}
                      userType="recruiter"
                      onAddRecruiterHandle={this.addOrganizationRecruiter}
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    organization: store.organizations.currentOrganization,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getOrganization,
  getRecruiters,
  editRecruiterOrganization,
  editRecruiter,
  deleteOrganizationRecruiter,
  clearCurrentRecruitersList,
  getOrganizationRecruitersFilteredForAddingAsyncList,
  showSnackbarMessage,
};

OrganizationRecruitersView.propTypes = {
  classes: PropTypes.object.isRequired,
  getOrganization: PropTypes.func.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  editRecruiter: PropTypes.func.isRequired,
  deleteOrganizationRecruiter: PropTypes.func.isRequired,
  clearCurrentRecruitersList: PropTypes.func.isRequired,
  getOrganizationRecruitersFilteredForAddingAsyncList:
    PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(OrganizationRecruitersView)));
