import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from 'shared/newComponents';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateExperiences = ({ candidate }) => {
  const userSkills = candidate.userSkills;
  const userExperiences = candidate.userExperiences;

  const isNewCreatorCandidate = candidate && candidate.currentStage > 1;

  const industriesCategoriesIds = [];

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Translate
        style={{
          fontSize: 20,
        }}
        bold
        text={'candidateCreator:reviewStep2.experienceInIndustries'}
      />
      {isNewCreatorCandidate && !userExperiences.length ? (
        <Translate
          style={{
            fontSize: 16,
          }}
          text={'core:general.noInformation'}
          disableTranslate={false}
        />
      ) : !isNewCreatorCandidate && !userSkills.length ? (
        <Translate
          style={{
            fontSize: 16,
          }}
          text={'core:general.noInformation'}
          disableTranslate={false}
        />
      ) : null}
      {isNewCreatorCandidate && userExperiences
        ? userExperiences.map(item => {
            if (!item.industryCategory) {
              return null;
            }
            return (
              <Grid item key={`experience_${item.id}`}>
                <Translate
                  style={{
                    fontSize: 16,
                  }}
                  disableTranslate={true}
                  text={`${item.industryCategory.name}: ${item.experienceLevel}`}
                />
              </Grid>
            );
          })
        : userSkills.map(item => {
            if (!item.industryCategory) {
              return null;
            }
            if (!industriesCategoriesIds.includes(item.industryCategory.id)) {
              industriesCategoriesIds.push(item.industryCategory.id);
            } else {
              return null;
            }
            return (
              <Grid item key={`experience_${item.industryCategory.id}`}>
                <Translate
                  style={{
                    fontSize: 16,
                  }}
                  disableTranslate={true}
                  text={`${item.industryCategory.name}: ${item.experience}`}
                />
              </Grid>
            );
          })}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateExperiences))));
