import { createReducer } from '@reduxjs/toolkit';
import {
  setSelectedUserAction,
  clearSelectedUserAction,
  setSelectedUsersListAction,
  setSelectedUsersListCountAction,
  clearSelectedUsersListAction,
  setCurrentUsersLimitedListAction,
  clearCurrentUsersLimitedListAction,
  setCurrentUsersLimitedListCounterAction,
  clearCurrentUsersLimitedListCounterAction,
} from './actions';

const initialState = {
  selectedUser: {},
  selectedUsersList: [],
  selectedUsersListCount: 0,
  currentUsersLimitedList: [],
  currentUsersLimitedListCounter: 0,
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setSelectedUserAction, (state, action) => {
      state.selectedUser = action.payload;
    })
    .addCase(clearSelectedUserAction, (state, action) => {
      state.selectedUser = initialState.selectedUser;
    })
    .addCase(setSelectedUsersListAction, (state, action) => {
      state.selectedUsersList = action.payload;
    })
    .addCase(setSelectedUsersListCountAction, (state, action) => {
      state.selectedUsersListCount = action.payload;
    })
    .addCase(clearSelectedUsersListAction, (state, action) => {
      state.selectedUsersList = initialState.selectedUsersList;
    })
    .addCase(setCurrentUsersLimitedListAction, (state, action) => {
      state.currentUsersLimitedList = action.payload;
    })
    .addCase(clearCurrentUsersLimitedListAction, (state, action) => {
      state.currentUsersLimitedList = initialState.currentUsersLimitedList;
    })
    .addCase(setCurrentUsersLimitedListCounterAction, (state, action) => {
      state.currentUsersLimitedListCounter = action.payload;
    })
    .addCase(clearCurrentUsersLimitedListCounterAction, (state, action) => {
      state.currentUsersLimitedListCounter =
        initialState.currentUsersLimitedListCounter;
    })
);
