import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { enums } from 'shared/enums';
import { Translate, Button } from 'shared/newComponents';
import { RecruiterV2Service } from 'shared/services';
import { styles } from './MentorDetails.styles';

const MentorDetails = ({ classes, t, recruiterEdited, recruiterData }) => {
  const currentMentorStatus = recruiterData.mentorStatus;

  const sendMentorRequest = mentorStatus => {
    const body = {
      mentorStatus,
    };
    RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
      stage: 'mentor_status',
    })
      .then(() => recruiterEdited())
      .catch(error => {
        console.log(error);
        alert(t`candidate:dataError`);
      });
  };

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.topPadding}>
      <Paper className={classes.paperWrapper}>
        <Grid container className={classes.gridWrapper}>
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={'candidate:additionalFuncs'}
                bold
                className={classes.bigFont}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={12} className={classes.gridPadding}>
              {currentMentorStatus === enums.MENTOR_STATUS.ACCEPTED ? (
                <Button
                  style={{
                    border: '2px solid red',
                  }}
                  fontColor="red"
                  label={`- ${t`candidate:mentorResign`}`}
                  onClick={() => sendMentorRequest(enums.MENTOR_STATUS.NOT_SET)}
                />
              ) : currentMentorStatus === enums.MENTOR_STATUS.SEND_REQUEST ? (
                <Button
                  disabled={true}
                  style={{
                    border: '2px solid #148EFF',
                  }}
                  fontColor="#148EFF"
                  label={t`candidate:awaitingAcc`}
                />
              ) : (
                <Button
                  label={`+ ${t`candidate:beMentor`}`}
                  onClick={() =>
                    sendMentorRequest(enums.MENTOR_STATUS.SEND_REQUEST)
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.gridPadding}>
              <Translate
                text={'candidate:mentorInfo'}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.gridPadding}>
              <Translate
                text={'candidate:mentorInfo2'}
                className={classes.mediumFont}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.gridPadding}>
              <Translate
                text={'candidate:sendYourReq'}
                className={classes.mediumFont}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withTranslation()(MentorDetails)));
