import { Grid, Typography, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Translate } from 'shared/newComponents';
import { MODEL } from '../../../ProjectCreateWizardStep6/ProjectCreateWizardStep6.utils';
import { styles } from './ProjectReviewStep6.style';

const ProjectReviewStep6 = ({ step6FormValues, classes }) => {
  const dataProcessingAgreement = get(
    step6FormValues,
    MODEL.dataProcessingAgreement
  );
  const clauseGDPR = get(step6FormValues, MODEL.clauseGDPR);

  const clauseGDPRUrl = get(step6FormValues, MODEL.clauseGDPRUrl);

  const dataProcessingAgreementUrl = get(
    step6FormValues,
    MODEL.dataProcessingAgreementUrl
  );
  return (
    (!!dataProcessingAgreement ||
      !!clauseGDPR ||
      !!clauseGDPRUrl ||
      !!dataProcessingAgreementUrl) && (
      <Grid container className={classes.contentWrapper}>
        <Grid container style={{ paddingBottom: 16 }}>
          <Translate
            text="projectCreator:step6.title"
            style={{ fontSize: 26 }}
            bold
            variant="h6"
          />
        </Grid>
        <Grid container>
          <Translate
            text="projectCreator:step6.dataProcessingAgreement"
            bold
            variant="body1"
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid container>
          {dataProcessingAgreement ? (
            ReactHtmlParser(dataProcessingAgreement)
          ) : (
            <a
              href={`https://${dataProcessingAgreementUrl}`}
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="body1" color="primary">
                {dataProcessingAgreementUrl}
              </Typography>
            </a>
          )}
        </Grid>
        <Grid container>
          <Translate
            text="projectCreator:step6.clauseGDPR"
            bold
            variant="body1"
            style={{ fontSize: 20 }}
          />
        </Grid>
        <Grid container>
          {clauseGDPR ? (
            ReactHtmlParser(clauseGDPR)
          ) : (
            <a
              href={`https://${clauseGDPRUrl}`}
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="body1" color="primary">
                {clauseGDPRUrl}
              </Typography>
            </a>
          )}
        </Grid>
      </Grid>
    )
  );
};

export default withStyles(styles)(ProjectReviewStep6);
