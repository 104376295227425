import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import CandidateMenu from '../../../candidateMenu/CandidateMenu';
import CandidateAllProjectsCard from '../CandidateAllProjectsCard';
import CandidateDetailsHeaderCard from '../CandidateDetailsHeaderCard';
import { styles } from './CandidateAllProjectsDetailsContent.styles';

const CandidateAllProjectsDetailsContent = ({
  candidateDetails,
  classes,
  candidateProjects,
  currentUser,
}) => {
  const isSelfEdit = currentUser.id === candidateDetails.id;

  return (
    <Grid container className={classes.wrapper}>
      <CandidateMenu
        itemSelected="all-projects"
        title={'candidateDetails:anonymousDetailsCard.menuTitle'}
        subtitle={'candidateDetails:anonymousDetailsCard.subTitle'}
        currentCandidate={candidateDetails}
        currentUser={currentUser}
      />
      <CandidateDetailsHeaderCard
        itemSelected="all-projects"
        title={
          isSelfEdit
            ? 'candidateDetails:allProjects.headerMenuTitleSelfEdit'
            : 'candidateDetails:allProjects.headerMenuTitle'
        }
        candidate={candidateDetails}
        currentUser={currentUser}
      />
      <CandidateAllProjectsCard
        candidateProjects={candidateProjects}
        candidate={candidateDetails}
      />
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(CandidateAllProjectsDetailsContent)
);
