import { API } from '../constants';
import { ApiService } from './api.service';

export const AuthService = {
  getUserToken: userId => {
    return ApiService.get(
      API.GET.AUTHORIZE.GET_TOKEN.replace(':userId', userId)
    );
  },
  logout: () => {
    return ApiService.get(API.GET.AUTHORIZE.LOGOUT);
  },
};
