import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import FileIcon from 'shared/assets/img/icons/project-show/file.png';
import { ActionIconButton } from '../../../modules/project/NewProjectShowView/components/GeneralInformations/components/SideView/components';
import { ProjectV2Service } from '../../services';

const MakeCopyProjectButton = ({ projectDetails, t, history }) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const handleOnClick = () => {
    setBtnDisabled(true);
    ProjectV2Service.copyProjectV2(projectDetails.id)
      .then(({ data }) => {
        history.push(PATHS.PROJECT.NEW_CREATE_FROM.replace(':id', data.id));
        window.location.reload();
      })
      .catch(error => {
        setBtnDisabled(false);
        console.log(t`project:error`, error);
        alert(t`project:fillStages`);
      });
  };
  return projectDetails.permissions.isOwner ? (
    <ActionIconButton
      disabled={btnDisabled}
      name={t('projectDetails:content.copyProject')}
      onClick={handleOnClick}
      icon={FileIcon}
    />
  ) : null;
};

export default withRouter(withTranslation()(MakeCopyProjectButton));
