import { Grid } from '@material-ui/core';
import { Translate } from 'shared/newComponents';

export function CandidateDetailsGetMore({ classes, itemSelected }) {
  const variantOne = (
    <Grid container>
      <Grid item className={classes.titleDialogPart}>
        <Translate
          text={'candidateDetails:candidateMenu.getMoreBodyShowPart1'}
          variant="body1"
        />
      </Grid>
      <Grid item className={classes.titleDialogPart}>
        <Translate
          text={'candidateDetails:candidateMenu.getMoreBodyShowPart2'}
          variant="body1"
        />
      </Grid>
      <Grid item className={classes.titleDialogPart}>
        <Translate
          text={'candidateDetails:candidateMenu.getMoreBodyShowPart3'}
          variant="body1"
        />
      </Grid>
      <Grid item className={classes.titleDialogPart}>
        <Translate
          text={'candidateDetails:candidateMenu.getMoreBodyShowPart4'}
          variant="body1"
        />
      </Grid>
    </Grid>
  );

  const variantTwo = (
    <Grid container>
      <Grid item className={classes.titleDialogPart}>
        <Translate
          text={'candidateDetails:candidateMenu.getMoreBodyPersonalDataPart1'}
          variant="body1"
        />
      </Grid>
      <Grid item className={classes.titleDialogPart}>
        <Translate
          text={'candidateDetails:candidateMenu.getMoreBodyPersonalDataPart2'}
          variant="body1"
        />
      </Grid>
    </Grid>
  );

  const getBodyVariant = selected => {
    switch (selected) {
      case 'show':
        return variantOne;
      case 'personal-data':
        return variantTwo;
      default:
        return null;
    }
  };

  return getBodyVariant(itemSelected);
}

export default CandidateDetailsGetMore;
