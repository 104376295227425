export const styles = () => ({
  bgBlue: { background: '#E5F0FF' },
  bold: { fontWeight: 600 },
  location: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 0',
  },
  locationSelect: { width: 400 },
});
