import { Grid, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'shared/newComponents';
import { RecruiterV2Service } from 'shared/services';
import { styles } from './PublicSwitch.styles';

const PublicSwitch = ({ recruiterData, recruiterEdited, classes, t }) => {
  const formData = {
    recruiterVisibility: recruiterData
      ? recruiterData.recruiterVisibility === 0
      : false,
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: null,
    onSubmit: values => {
      const body = {
        recruiterVisibility: values.recruiterVisibility === true ? 0 : 1,
      };
      RecruiterV2Service.editRecruiterV2(recruiterData.id, body, {
        stage: 'recruiter_visibility_data',
      })
        .then(() => {
          recruiterEdited();
        })
        .catch(error => {
          console.log(error);
          alert(t('recruiter:saveError'));
        });
    },
  });

  return (
    <Grid item xs={12}>
      <Checkbox
        onChange={event => {
          formik.setFieldValue('recruiterVisibility', event.target.checked);
          formik.submitForm();
        }}
        label={t('recruiter:showCard')}
        name={'recruiterVisibility'}
        type="switch"
        formik={formik}
        hintContent={null}
      />
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withTranslation()(PublicSwitch)));
