import { Typography, withStyles, Grid } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import ManIcon from 'shared/assets/img/icons/project-show/man_black.png';
import PairIcon from 'shared/assets/img/icons/project-show/pair.png';
import WomanIcon from 'shared/assets/img/icons/project-show/woman_black.png';
import { WizardStep } from '../../../../newComponents';
import TextField from '../../../../newComponents/TextField';
import { HelpService } from '../../../../services';
import { styles } from './NeedAccommodation.styles';

const MODEL = {
  numberOfMen: 'numberOfMen',
  numberOfPair: 'numberOfPair',
  numberOfWomen: 'numberOfWomen',
  numberOfPeople: 'numberOfPeople',
};

const NeedAccommodation = ({ classes, t, lookingHelpData, sectionSaved }) => {
  const [isAccommodationOpen, setIsAccommodationOpen] = useState(true);
  const [saved, setIsSaved] = useState(false);

  const formData = {
    periodStayStartDate:
      lookingHelpData && lookingHelpData.periodStayStartDate
        ? lookingHelpData.periodStayStartDate
        : '',
    periodStayEndDate:
      lookingHelpData && lookingHelpData.periodStayEndDate
        ? lookingHelpData.periodStayEndDate
        : '',
    preferCityToStay:
      lookingHelpData && lookingHelpData.preferCityToStay
        ? lookingHelpData.preferCityToStay
        : '',
    numberOfPair:
      lookingHelpData && lookingHelpData.numberOfPair
        ? lookingHelpData.numberOfPair
        : 0,
    numberOfMen:
      lookingHelpData && lookingHelpData.numberOfMen
        ? lookingHelpData.numberOfMen
        : 0,
    numberOfWomen:
      lookingHelpData && lookingHelpData.numberOfWomen
        ? lookingHelpData.numberOfWomen
        : 0,
    numberOfPeople:
      lookingHelpData && lookingHelpData.numberOfPeople
        ? lookingHelpData.numberOfPeople
        : 0,
  };
  const lookingHelpInfoAdded = () => {
    setIsSaved(true);
    sectionSaved();
  };

  const validationSchema = yup.object({
    periodStayStartDate: yup
      .date()
      .nullable()
      .required(t`ats2ats3:validation.isRequired`),
    periodStayEndDate: yup.date().required(t`ats2ats3:validation.isRequired`),
    preferCityToStay: yup.string().required(t`ats2ats3:validation.isRequired`),
    numberOfPeople: yup
      .number()
      .min(1, t('projectCreator:core.minValue', { value: 1 }))
      .required(t('projectCreator:core.requiredField')),
    numberOfMen: yup
      .number()
      .min(0, t('projectCreator:core.minValue', { value: 0 }))
      .required(t('projectCreator:core.requiredField')),
    numberOfPair: yup
      .number()
      .min(0, t('projectCreator:core.minValue', { value: 0 }))
      .required(t('projectCreator:core.requiredField')),

    numberOfWomen: yup
      .number()
      .min(0, t('projectCreator:core.minValue', { value: 0 }))
      .required(t('projectCreator:core.requiredField')),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: (values, actions) => {
      if (
        moment(values.periodStayStartDate).isAfter(
          moment(values.periodStayEndDate),
          'day'
        )
      ) {
        formik.setFieldError(
          'periodStayStartDate',
          t`ats2ats3:validation.date`
        );
        return;
      }

      const body = {
        periodStayStartDate: values.periodStayStartDate,
        periodStayEndDate: values.periodStayEndDate,
        preferCityToStay: values.preferCityToStay,
        numberOfPeople: parseInt(values.numberOfPeople),
        numberOfMen: parseInt(values.numberOfMen),
        numberOfPair: parseInt(values.numberOfPair),
        numberOfWomen: parseInt(values.numberOfWomen),
      };

      HelpService.updateLookingHelpInfoId(lookingHelpData.id, body, 1)
        .then(() => {
          lookingHelpInfoAdded();
        })
        .catch(error => {
          console.log(error);
        });
    },
  });

  const handleChangeNumberOfCandidates = (field, event) => {
    formik.setFieldValue(field, event.target.value);
  };

  const handleChangeSumOfCandidates = (field, event) => {
    formik.setFieldValue(field, event.target.value);
    formik.setFieldValue(MODEL.numberOfPair, 0);
    formik.setFieldValue(MODEL.numberOfMen, 0);
    formik.setFieldValue(MODEL.numberOfWomen, 0);
  };

  return (
    <>
      <WizardStep
        subInfo={
          (lookingHelpData && lookingHelpData.periodStayEndDate) || saved
            ? 'help:help.sectionSaved'
            : null
        }
        withSaveButton
        title={t('help:help.accommodationTitle')}
        isOpen={isAccommodationOpen}
        onCloseSelf={() => {
          setIsAccommodationOpen(false);
        }}
        onOpen={() => {
          setIsAccommodationOpen(true);
        }}
        onClose={() => {
          formik.submitForm();
        }}
      >
        <div className={classes.container}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container style={{ width: '100%' }}>
              <div className={classes.bg}>
                <div className={classes.padding}>
                  <div className={classes.quarantinePeriod}>
                    <Typography variant="body1">
                      {t('help:help.accommodationDateRange')}
                    </Typography>
                  </div>

                  <Grid container className={classes.employmentDate}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Grid item xs={1} sm={1} md={1}>
                              <PowerSettingsNew
                                className={classes.powerOnBtn}
                              />
                            </Grid>
                            <Grid item xs={9} sm={9} md={9}>
                              <TextField
                                type="date"
                                formik={formik}
                                name="periodStayStartDate"
                                variant="transparent"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Grid item xs={1} sm={1} md={1}>
                              <PowerSettingsNew
                                className={classes.powerOffBtn}
                              />
                            </Grid>
                            <Grid item xs={9} sm={9} md={9}>
                              <TextField
                                type="date"
                                formik={formik}
                                name="periodStayEndDate"
                                variant="transparent"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <div className={classes.locationHeader}>
                    <Typography variant="body1">
                      {t('help:help.enterLocalization')}
                    </Typography>
                  </div>
                  <TextField
                    formik={formik}
                    placeholder={t('help:help.enterPreferredPlace')}
                    name="preferCityToStay"
                    variant="transparent"
                  />
                </div>
              </div>

              <Grid container justifyContent={'center'}>
                <div className={classes.personCount}>
                  <div className={classes.candidatesNumber}>
                    <div>
                      <Typography variant="body1">{t`help:help.amountOfPeople`}</Typography>
                    </div>
                    <div className={classes.datePicker}>
                      <TextField
                        onChange={event =>
                          handleChangeSumOfCandidates(
                            MODEL.numberOfPeople,
                            event
                          )
                        }
                        type="number"
                        name={'numberOfPeople'}
                        formik={formik}
                        variant="transparent"
                        placeholder={t('help:help.personCount')}
                      />
                    </div>
                  </div>
                  <Typography variant="body1">{t`modifyOrderDialog:including`}</Typography>
                  <div className={classes.including}>
                    <div className={classes.includingCat}>
                      <img src={ManIcon} alt="ManIcon" height="30" />
                      <TextField
                        onChange={event =>
                          handleChangeNumberOfCandidates(
                            MODEL.numberOfMen,
                            event
                          )
                        }
                        type="number"
                        formik={formik}
                        variant="transparent"
                        name="numberOfMen"
                        placeholder={t('help:help.man')}
                      />
                    </div>
                    <div className={classes.includingCat}>
                      <img src={WomanIcon} alt="WomanIcon" height="30" />
                      <TextField
                        onChange={event =>
                          handleChangeNumberOfCandidates(
                            MODEL.numberOfWomen,
                            event
                          )
                        }
                        type="number"
                        formik={formik}
                        variant="transparent"
                        name="numberOfWomen"
                        placeholder={t('help:help.woman')}
                      />
                    </div>
                    <div className={classes.includingCat}>
                      <img
                        src={PairIcon}
                        alt="PairIcon"
                        height="20"
                        style={{ margin: 3 }}
                      />
                      <TextField
                        onChange={event =>
                          handleChangeNumberOfCandidates(
                            MODEL.numberOfPair,
                            event
                          )
                        }
                        type="number"
                        formik={formik}
                        variant="transparent"
                        name="numberOfPair"
                        placeholder={t('help:help.pair')}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </WizardStep>
    </>
  );
};

export default withStyles(styles)(withTranslation()(NeedAccommodation));
