export const styles = () => ({
  paperWrapper: {
    width: '100%',
    borderRadius: 20,
    height: '100%',
  },
  containerWrapper: {
    alignItems: 'center',
    padding: '20px 20px 20px 20px',
    wordBreak: 'break-word',
  },
  headerWrapper: {
    padding: '15px 0px',
  },
  marginGridWrapper: {
    marginTop: 15,
  },
  bigFont: {
    fontSize: 18,
  },
  mediumFont: {
    fontSize: 16,
  },
});
