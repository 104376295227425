import { Grid, LinearProgress, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import {
  getOrganization,
  clearCurrentOrganization,
} from 'shared/services/organizationService';
import OrganizationDetails from '../../components/OrganizationDetails';
import OrganizationMenu from '../../components/OrganizationMenu';
import PageHeader from '../../components/PageHeader';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
  }),
  root: {
    marginTop: 0,
  },
});

export class OrganizationShowView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingOrganization: false,
    };
  }

  loadOrganization = async id => {
    try {
      this.setState({ isLoadingOrganization: true });
      await this.props.getOrganization(id);
      this.setState({
        isLoadingOrganization: false,
      });
      if (
        this.props.organization.owner &&
        this.props.currentUser.id === this.props.organization.owner.id
      ) {
        this.props.history.push(PATHS.RECRUITER.ORGANIZATION.INDEX);
      }
    } catch (err) {
      this.setState({ isLoadingOrganization: false });
      console.error(err);
    }
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    this.props.clearCurrentOrganization();
    this.loadOrganization(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.props.clearCurrentOrganization();
      const { id } = nextProps.match.params;
      this.loadOrganization(id);
    }
  }

  render() {
    const { classes, t, organization = {} } = this.props;
    const { isLoadingOrganization } = this.state;

    return isLoadingOrganization ? (
      <LinearProgress />
    ) : (
      <React.Fragment>
        <PageHeader title={t('organization:show.organizationDetails')} />
        <Grid
          container
          alignItems="flex-start"
          spacing={2}
          className={classes.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <OrganizationMenu itemSelected="information" />
            <Paper className={classes.rootPaper} elevation={0}>
              <OrganizationDetails organization={organization} />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    organization: store.organizations.currentOrganization,
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getOrganization,
  clearCurrentOrganization,
};

OrganizationShowView.propTypes = {
  classes: PropTypes.object.isRequired,
  getOrganization: PropTypes.func.isRequired,
  clearCurrentOrganization: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(withRouter(OrganizationShowView))));
