import { withStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CheckboxGroup } from 'shared/newComponents';
import { styles } from './HealthInsurance.styles';

const HealthInsurance = ({ classes, t, formik }) => {
  const [gotInsurance] = useState([
    { id: 1, name: t`employmentManagementDialog:dialog2.yes` },
    { id: 2, name: t`employmentManagementDialog:dialog2.no` },
  ]);

  return (
    <div className={classes.insurance}>
      <div>
        <Typography
          align="right"
          variant="body1"
        >{t`employmentManagementDialog:dialog2.insurance`}</Typography>
      </div>
      <div>
        <CheckboxGroup
          formik={formik}
          items={gotInsurance}
          name="isEmployeeInsured"
          variant="radio"
          iterateFrom="1"
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(withTranslation()(HealthInsurance));
