import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Button,
  DialogActions,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { PATHS } from 'config';
import RedAlert from 'shared/assets/img/icons/project-show/redAlert.png';
import { LANDING_PAGE_URL } from 'shared/constants';
import AssetsUpload from 'shared/newComponents/AssetsUpload';
import { styles } from './ProjectCandidatesCsvTable.styles';

const ProjectCandidatesCsvTable = ({
  isOpen,
  onClose,
  saveCsvTable,
  classes,
  t,
  handleFileUpload,
  checkedCandidates,
  verifiedCandidates,
  lockSendCandidateBtn,
}) => {
  const getErrorMsg = error =>
    ({
      'Phone is used': t`candidateCreator:phoneTaken`,
      'Model is not complete': t`candidateCreator:missingData`,
      'Email is used': t`candidateCreator:emailTaken`,
      'Email is not valid': t`candidate:form.validation.badEmail`,
      'Phone is not valid': t`candidate:form.validation.badPhone`,
    }[error]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component="div"
          variant="h6"
        >{t`candidateCreator:step1.personalData`}</Typography>
        <Typography
          component="div"
          variant="h6"
        >{t`register:form.validRecordsMaximumAmount`}</Typography>
        {checkedCandidates && verifiedCandidates && (
          <Typography variant="body1">
            {t`register:form.validRecordsAmount`}: {verifiedCandidates.length}/
            {checkedCandidates.length}
          </Typography>
        )}
        <div className={classes.info}>
          <Typography variant="body1">
            <a
              href={`${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.STATIC_FILES.EXAMPLE_CSV}`}
              download="exampleCsv.csv"
            >{t`project:csvInfo`}</a>
          </Typography>
          <div className={classes.chooseFileDiv}>
            <AssetsUpload
              uploadAssetsHandler={handleFileUpload}
              filesLimit={1}
              acceptedFiles={['.csv']}
              assetType="candidatesCsv"
            >
              <Button
                className={classes.chooseFileBtn}
              >{t`project:upFile`}</Button>
            </AssetsUpload>
          </div>
        </div>
      </DialogTitle>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t`register:form.name`}</TableCell>
              <TableCell>{t`register:form.surname`}</TableCell>
              <TableCell>{t`register:form.email`}</TableCell>
              <TableCell>{t`candidateCreator:step1.phone`}</TableCell>
              <TableCell>{t`addOrganizationForm:error`}</TableCell>
              <TableCell>{t`addOrganizationForm:typeOfError`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkedCandidates.map(
              (
                {
                  firstName,
                  lastName,
                  email,
                  phonePrimaryCode,
                  phonePrimary,
                  isAccept,
                  errorMessage,
                },
                index
              ) => (
                <TableRow key={index}>
                  <TableCell>{firstName}</TableCell>
                  <TableCell>{lastName}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>
                    {phonePrimaryCode} {phonePrimary}
                  </TableCell>
                  <TableCell>
                    {!isAccept && (
                      <img
                        alt="error"
                        src={RedAlert}
                        height="20"
                        className={classes.alert}
                      />
                    )}
                  </TableCell>
                  <TableCell>{getErrorMsg(errorMessage)}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          onClick={onClose}
        >{t`action.cancel`}</Button>
        <Button
          className={classes.saveBtn}
          onClick={saveCsvTable}
          disabled={
            lockSendCandidateBtn ||
            (verifiedCandidates && !verifiedCandidates.length)
          }
        >{t`adminCitySeo:save`}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(withTranslation()(ProjectCandidatesCsvTable));
