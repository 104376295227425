import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getMLTaskList,
  fetchMLData,
  checkMLTask,
} from 'shared/services/mlService';
import {
  getTableRowPerPageFromStorage,
  setLocalStorage,
} from 'shared/utils/localStorage';

const columnData = [
  { id: 'id', numeric: true, label: 'lp.' },
  { id: 'createdAt', numeric: false, label: 'Data zadania' },
  { id: 'email', numeric: false, label: 'E-mail' },
  { id: 'mlTaskId', numeric: false, label: 'Number zadania' },
  { id: 'mlTaskStatus', numeric: false, label: 'Status' },
];

export class AdminMLStatusViewHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

AdminMLStatusViewHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export class AdminMLStatusView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetch: false,
      order: 'asc',
      orderBy: 'id',
      page: 0,
      open: false,
      rowsPerPage: getTableRowPerPageFromStorage(this.props.match.url) || 25,
      data: [],
      snackbarMessage: '',
    };
  }

  loadData = async () => {
    this.setState({
      fetch: true,
    });
    const { data } = await this.props.getMLTaskList();
    this.setState({
      data: _.map(data, (log, index) => {
        return { id: parseInt(index, 10), data: log };
      }),
      order: 'asc',
      orderBy: 'id',
      page: 0,
    });
    this.setState({
      fetch: false,
    });
  };

  reloadData = async () => {
    this.setState({
      fetch: true,
    });
    await this.props.checkMLTask();
    await this.loadData();
    this.setState({
      fetch: false,
    });
  };

  setSnackbar = statusCode => {
    if (statusCode === 0) {
      this.setState({
        open: true,
        snackbarMessage: 'Brak połączenia z ML',
      });
    } else if (statusCode === 500) {
      this.setState({
        open: true,
        snackbarMessage: 'Błąd podczas wykonywania ML',
      });
    }
  };
  fetchData = async () => {
    this.setState({
      fetch: true,
    });
    const response = await this.props.fetchMLData();
    this.setSnackbar(response.data.statusCode);
    await this.loadData();
    this.setState({
      fetch: false,
    });
  };

  componentWillMount() {
    this.loadData();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (!orderBy) {
      return;
    }

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, page: 0 });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    setLocalStorage(this.props.match.url, event.target.value);
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = state => () => {
    this.setState({ open: true, ...state });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { order, orderBy, rowsPerPage, page } = this.state;
    const { t } = this.props;

    let data = this.state.data ? _.sortBy(this.state.data, orderBy) : [];
    //działa odwrotnie bo material-ui/Table ma przy asc strzałkę w górę
    data = order === 'asc' ? _.reverse(data) : data;

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper>
        <Button
          onClick={this.reloadData}
          variant="contained"
          color="primary"
          disabled={this.state.fetch}
        >
          Odśwież dane
        </Button>
        <Button
          onClick={this.fetchData}
          variant="contained"
          color="primary"
          disabled={this.state.fetch}
        >
          Wykonaj przeliczenie
        </Button>
        {this.state.fetch ? (
          <LinearProgress />
        ) : (
          <Table>
            <AdminMLStatusViewHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow hover key={n.id}>
                      <TableCell align="right">{n.id}</TableCell>
                      <TableCell padding="none">{n.data.createdAt}</TableCell>
                      <TableCell padding="none">{n.data.user.email}</TableCell>
                      <TableCell padding="none">{n.data.mlTaskId}</TableCell>
                      <TableCell padding="none">
                        {n.data.mlTaskStatus}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={columnData.length + 1} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage={t('pagination.elementsPerPage')}
                  labelDisplayedRows={({ from, to, count }) =>
                    t('pagination.pagesFromTo', { from, to, count })
                  }
                  colSpan={columnData.length + 1}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
        <Snackbar
          open={this.state.open}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
        />
      </Paper>
    );
  }
}

const mapDispatchToProps = {
  getMLTaskList,
  checkMLTask,
  fetchMLData,
};

AdminMLStatusView.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  getMLTaskList: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(AdminMLStatusView));
