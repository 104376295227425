import { Typography, Paper, withStyles } from '@material-ui/core';
import { MoodBad as MoodBadIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  emptyAtsContainer: {
    height: '15vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    '& > div': {
      color: theme.palette.jobllegroLightGrey,
    },
  },
  icon: {
    color: theme.palette.jobllegroLightGrey,
    fontSize: 32,
  },
});

export const AtsEmptyContainer = props => {
  const { classes, text } = props;
  return (
    <Paper className={classes.emptyAtsContainer} elevation={0}>
      <Typography component="div" align="center">
        {text}
      </Typography>
      <MoodBadIcon className={classes.icon} />
    </Paper>
  );
};

AtsEmptyContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AtsEmptyContainer);
