export const styles = ({ palette, breakpoints }) => ({
  wrapLastIndex: {
    padding: 10,
  },
  wrapLastIndexBtn: {
    borderColor: 'red',
    marginBottom: 4,
    height: 60,
    borderRadius: 30,
  },
  wrapRemoveBtn: {
    borderColor: 'red',
    height: 60,
    borderRadius: 30,
  },
});
