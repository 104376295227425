import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { styles } from './TimelineSection.styles';
import DocsInfo from './components/DocsInfo';
import Timeline from './components/Timeline';
import Workwear from './components/Workwear';

const TimelineSection = ({
  classes,
  candidateDetails,
  candidateEmploymentHistories,
}) => {
  return (
    <Grid container className={classes.wrapper}>
      <Grid item md={6}>
        <Timeline candidateEmploymentHistories={candidateEmploymentHistories} />
      </Grid>
      <Grid item md={6}>
        <DocsInfo
          candidateDetails={candidateDetails}
          candidateEmploymentHistories={candidateEmploymentHistories}
        />
        <Workwear candidateEmploymentHistories={candidateEmploymentHistories} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TimelineSection);
