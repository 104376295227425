import { createAction } from '@reduxjs/toolkit';
import {
  SET_CURRENT_PROJECT,
  CLEAR_CURRENT_PROJECT,
  CLEAR_CURRENT_PROJECTS_LIST,
  SET_CURRENT_PROJECTS_LIST,
  SET_CURRENT_PROJECTS_LIMITED_LIST,
  CLEAR_CURRENT_PROJECTS_LIMITED_LIST,
  SET_CURRENT_PROJECTS_LIMITED_LIST_COUNTER,
  CLEAR_CURRENT_PROJECTS_LIMITED_LIST_COUNTER,
  SET_PROJECT_RECRUITER_CONFIRMATION_STATUS,
  SET_PROJECT_CANDIDATE_CONFIRMATION_STATUS,
  CLEAR_PROJECT_RECRUITER_CONFIRMATION_STATUS,
  CLEAR_PROJECT_CANDIDATE_CONFIRMATION_STATUS,
  SET_PROJECTS_PENDING_TO_RECRUITER_LIST,
  CLEAR_PROJECTS_PENDING_TO_RECRUITER_LIST,
  SET_PROJECTS_PENDING_TO_CANDIDATE_LIST,
} from '../actionConstants';

export const setCurrentProjectAction = createAction(SET_CURRENT_PROJECT);
export const clearCurrentProjectAction = createAction(CLEAR_CURRENT_PROJECT);
export const setCurrentProjectsListAction = createAction(
  SET_CURRENT_PROJECTS_LIST
);
export const setCurrentProjectsLimitedListAction = createAction(
  SET_CURRENT_PROJECTS_LIMITED_LIST
);
export const clearCurrentProjectsLimitedListAction = createAction(
  CLEAR_CURRENT_PROJECTS_LIMITED_LIST
);
export const setCurrentProjectsLimitedListCounterAction = createAction(
  SET_CURRENT_PROJECTS_LIMITED_LIST_COUNTER
);
export const clearCurrentProjectsLimitedListCounterAction = createAction(
  CLEAR_CURRENT_PROJECTS_LIMITED_LIST_COUNTER
);
export const clearCurrentProjectsListAction = createAction(
  CLEAR_CURRENT_PROJECTS_LIST
);
export const setProjectRecruiterConfirmationStatusAction = createAction(
  SET_PROJECT_RECRUITER_CONFIRMATION_STATUS
);
export const setProjectCandidateConfirmationStatusAction = createAction(
  SET_PROJECT_CANDIDATE_CONFIRMATION_STATUS
);
export const clearProjectRecruiterConfirmationStatusAction = createAction(
  CLEAR_PROJECT_RECRUITER_CONFIRMATION_STATUS
);
export const clearProjectCandidateConfirmationStatusAction = createAction(
  CLEAR_PROJECT_CANDIDATE_CONFIRMATION_STATUS
);
export const setProjectsPendingToRecruiterListAction = createAction(
  SET_PROJECTS_PENDING_TO_RECRUITER_LIST
);
export const clearProjectsPendingToRecruiterListAction = createAction(
  CLEAR_PROJECTS_PENDING_TO_RECRUITER_LIST
);
export const setProjectsPendingToCandidateListAction = createAction(
  SET_PROJECTS_PENDING_TO_CANDIDATE_LIST
);
