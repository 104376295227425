import { withStyles, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from 'shared/newComponents/Button';
import MultiSelect from 'shared/newComponents/MultiSelect';
import CustomDialog from '../../../../components/CustomDialog';
import { styles } from './ProjectOrdersDialog.styles';

export const ProjectOrdersDialog = ({
  t,
  openProjectOrdersDialog,
  onCloseProjectOrdersDialog,
  projectOrders,
  setProjectOrder,
  projectOrdersAdded,
  classes,
}) => {
  const MODEL = {
    projectOrder: 'projectOrder',
  };
  const formData = {
    projectOrder: {},
  };

  const validationSchema = Yup.object().shape({
    projectOrder: Yup.object().shape({
      id: Yup.number().required(t('projectCreator:core.requiredField')),
    }),
  });

  const filterProjectOrders = projectOrders.filter(
    projectOrder => projectOrder.isActive
  );

  const projectOrdersArray = filterProjectOrders.map(projectOrder => {
    return {
      id: projectOrder.id,
      value: projectOrder.id,
      label: `#${projectOrder.id} ${projectOrder.createdAt} ${projectOrder.owner.firstName} ${projectOrder.owner.lastName}`,
    };
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: values => {
      setProjectOrder(values.projectOrder);
      projectOrdersAdded();
      formik.resetForm();
      onCloseProjectOrdersDialog();
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      title={t`modifyOrderDialog:assign`}
      isOpen={openProjectOrdersDialog}
      onCloseDialog={onCloseProjectOrdersDialog}
      body={
        <Grid container className={classes.wrapperHeight}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <MultiSelect
                  options={projectOrdersArray}
                  label={t`modifyOrderDialog:order`}
                  name={MODEL.projectOrder}
                  formik={formik}
                />
                <Grid
                  container
                  justifyContent="center"
                  onClick={formik.handleSubmit}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      label={t`modifyOrderDialog:save&close`}
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      }
    />
  );
};

export default withStyles(styles)(withTranslation()(ProjectOrdersDialog));
