import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import RecruiterMenu from '../../../recruiterMenu/RecruiterMenu';
import { styles } from './RecruiterOrganizationDetailsContent.styles';
import LeftSide from './components/LeftSide';
import RightSide from './components/RightSide';

const RecruiterOrganizationDetailsContent = ({
  recruiterDetails,
  classes,
  currentUser,
}) => {
  return (
    <Grid container className={classes.wrapper}>
      <RecruiterMenu
        recruiterId={recruiterDetails.id}
        itemSelected="organizations"
        title={'candidateDetails:anonymousRecruiterDetailsCard:menuTitle'}
        subtitle={'candidateDetails:anonymousRecruiterDetailsCard:subTitle'}
        currentRecruiter={recruiterDetails}
        currentUser={currentUser}
      />

      <Grid container>
        <Grid item xs={12} sm={12} md={6} className={classes.sideWrapper}>
          <LeftSide recruiterData={recruiterDetails} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.sideWrapper}>
          <RightSide recruiterData={recruiterDetails} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(RecruiterOrganizationDetailsContent))
);
