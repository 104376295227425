import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import EmployersIcon from 'shared/assets/img/icons//project-show/employers.svg';
import TimeIcon from 'shared/assets/img/icons/time.svg';
import { enums } from '../../../enums';
import { getEnumItemName } from '../../../utils/assets';
import Translate from '../../Translate';
import { styles } from './PersonalData.style';

const CareerItemPanel = ({ userExperience, projectCandidate, t }) => {
  const { project, stageNumber } = projectCandidate;

  const getCompanyName = project => {
    if (project.permissions && project.permissions.isHiringManager) {
      if (stageNumber < 5) {
        return 'XXXXXX';
      }
    }
    return userExperience.companyName;
  };

  const companyName = getCompanyName(project);

  return (
    <Paper style={{ width: '100%', borderRadius: 20 }}>
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Translate
            text={userExperience.position}
            disableTranslate={true}
            bold
            style={{ fontSize: 20, color: '#348EFF' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Translate
            text={companyName}
            disableTranslate={true}
            style={{ fontSize: 16, color: '#575C62' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 15 }}>
          <Translate
            text={userExperience.industryCategory.name}
            disableTranslate={true}
            style={{ fontSize: 16, color: '#575C62' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
          <Grid container alignItems={'center'}>
            <img
              src={EmployersIcon}
              alt={'companySize'}
              style={{ width: 30, height: 30, paddingRight: 8 }}
            />
            <Translate
              text={getEnumItemName(
                enums.COMPANY_SIZE,
                userExperience.companySize
              )}
              disableTranslate={true}
              style={{ fontSize: 16, color: '#575C62' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
          <Grid container alignItems={'center'}>
            <img
              src={TimeIcon}
              alt={'companySize'}
              style={{ width: 30, height: 30, paddingRight: 8 }}
            />
            <Translate
              text={`${t('newAts:careerPanelView.years')} ${
                userExperience.experienceLevel
              }`}
              disableTranslate={true}
              style={{ fontSize: 16, color: '#575C62' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(withTranslation()(CareerItemPanel));
