import { withStyles } from '@material-ui/core/styles';
import ProjectStepper from './ProjectStepper';

const styles = theme => ({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    marginBottom: 40,
  },
  rootMiniSecond: {
    alignItems: 'center',
    display: 'flex',
  },
  rootMini: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  titleprimary: {
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
  },
  titleseccondary: {
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'center',
  },
  number: {
    fontSize: 14,
    color: '#52a3fc',
    fontWeight: '600',
    textAlign: 'center',
  },
  numberAlert: {
    color: theme.palette.jobllegroRedAlert,
  },
  numberMini: {
    fontSize: 12,
    color: '#52a3fc',
    fontWeight: '200',
    textAlign: 'center',
    width: '100%',
  },
  numberMiniAlert: {
    color: theme.palette.jobllegroRedAlert,
  },
  rootWide: {
    justifyContent: 'space-around',
    display: 'flex',
    padding: '0px',
    flexDirection: 'row',
    color: theme.palette.jobllegroLightGrey,
    '& > span': {
      color: theme.palette.jobllegroLightGrey,
    },
  },
});
export default withStyles(styles)(ProjectStepper);
