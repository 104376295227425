import { Grid, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AvatarIcon from 'shared/assets/img/icons/candidateCreator/avatar.svg';
import { Translate } from 'shared/newComponents';
import AssignCandidateToRecruiterButton from 'shared/newComponents/AssignCandidateToRecruiterButton/AssignCandidateToRecruiterButton';
import { styles } from './CandidateAnonymousCard.styles';

const CandidateSelectOwner = ({ candidate, classes, t, ...props }) => {
  const [openSetRecruiter, setOpenSetRecruiter] = useState(false);

  const handleOpenSetCandidateRecruiter = () => {
    setOpenSetRecruiter(true);
  };

  const onClose = () => {
    setOpenSetRecruiter(false);
  };

  return (
    <Grid
      container
      style={{
        padding: '30px 30px 30px 30px',
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Translate
                text={t`candidate:chooseYourRecruiter`}
                bold
                style={{
                  fontSize: 20,
                  color: '#F41313',
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} style={{ marginTop: 16 }}>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              justifyContent={'center'}
              style={{ minWidth: 60 }}
            >
              <img
                alt="AvatarIcon"
                style={{ cursor: 'pointer' }}
                onClick={handleOpenSetCandidateRecruiter}
                src={AvatarIcon}
                height={60}
              />
              <AssignCandidateToRecruiterButton
                candidate={candidate}
                isOpen={openSetRecruiter}
                onClose={onClose}
              />
            </Grid>
            <Grid item xs={9} sm={10} md={10}>
              {' '}
              <Translate
                text={t`candidate:chooseFromList`}
                style={{
                  fontSize: 16,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withRouter(withTranslation()(CandidateSelectOwner))));
