import { configureStore } from '@reduxjs/toolkit';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './rootReducer';

const reduxRouterMiddleware = routerMiddleware(createHashHistory());

const feedStore = () => {
  return {
    auth: {
      token: localStorage.token,
      isAuthorized: false,
      currentUser: {},
      isFetching: false,
      config: {},
      configList: {},
      lastPage: null,
    },
  };
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: feedStore(),
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: true,
      serializableCheck: true,
    }).concat(reduxRouterMiddleware),
});

export default store;
