import {
  BottomNavigation,
  BottomNavigationAction,
  withStyles,
} from '@material-ui/core';
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Assignment as AssigmentIcon,
  AssignmentInd as AssigmentIndIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';

const styles = () => ({
  bottomIcon: {
    color: '#52a3fc',
  },
  bgGrey: {
    background: '#fcfcfc',
  },
});

export const NotificationsBottomNavigation = props => {
  const {
    classes,
    handleClickCloseToggleMenu,
    userLogged: { isRecruiter },
    t,
  } = props;

  return (
    <BottomNavigation className={classes.bgGrey} showLabels>
      <BottomNavigationAction
        component={Link}
        to={PATHS.PROJECT.INDEX_SHOW.replace(':show', 'invitation')}
        className={classes.bottomIcon}
        label={t('project:menu.main.acceptance')}
        icon={<AssigmentIcon />}
        onClick={handleClickCloseToggleMenu}
      />
      {isRecruiter ? (
        <BottomNavigationAction
          component={Link}
          to={PATHS.PROJECT.INDEX_SHOW.replace(':show', 'my-projects')}
          className={classes.bottomIcon}
          label={t('project:menu.main.myprojects')}
          icon={<AssigmentIndIcon />}
          onClick={handleClickCloseToggleMenu}
        />
      ) : (
        <BottomNavigationAction
          component={Link}
          to={PATHS.PROJECT.INDEX}
          className={classes.bottomIcon}
          label={t('project:menu.main.all')}
          icon={<AssigmentIndIcon />}
          onClick={handleClickCloseToggleMenu}
        />
      )}
      <BottomNavigationAction
        component={Link}
        to={PATHS.PROJECT.INDEX_SHOW.replace(':show', 'cooperation')}
        className={classes.bottomIcon}
        label={t('project:menu.main.involvement')}
        icon={<AssignmentTurnedInIcon />}
        onClick={handleClickCloseToggleMenu}
      />
    </BottomNavigation>
  );
};

NotificationsBottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  userLogged: PropTypes.object.isRequired,
  handleClickCloseToggleMenu: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    userLogged: store.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation()(NotificationsBottomNavigation)));
