import { TextField } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { addCmsConfigs, updateCmsConfigs } from 'shared/services/cmsService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';
import CustomDialog from '../CustomDialog';
import validate from '../validate/validateCmsConfig';

class AddCmsConfig extends React.Component {
  render() {
    const {
      handleSubmit,
      isOpen,
      onClose,
      pristine,
      cmsConfig,
      isSubmitting,
      cmsConfigAdded,
      showSnackbarMessage,
      t,
    } = this.props;

    return (
      <CustomDialog
        title={cmsConfig ? t`addCmsForm:editCms` : t`addCmsForm:addCms`}
        isOpen={isOpen}
        onCloseDialog={onClose}
        body={
          <form
            onSubmit={handleSubmit((values, dispatch) => {
              const cmsConfigData = {
                fieldName: values.fieldName,
                value: values.value,
              };
              if (cmsConfig && cmsConfig.id) {
                updateCmsConfigs(cmsConfig.id, cmsConfigData)
                  .then(() => {
                    dispatch(reset('addCmsConfigForm'));
                    cmsConfigAdded();
                  })
                  .catch(() => {
                    showSnackbarMessage(t``);
                  });
              } else {
                addCmsConfigs(cmsConfigData)
                  .then(() => {
                    dispatch(reset('addCmsConfigForm'));
                    cmsConfigAdded();
                  })
                  .catch(() => {
                    showSnackbarMessage(t`addCmsForm:error`);
                  });
              }
            })}
          >
            <div>
              <Field
                label={t`addCmsForm:fieldName`}
                name={'fieldName'}
                type="text"
                component={TextField}
                fullWidth
              />
              <Field
                label={t`addCmsForm:value`}
                name={'value'}
                type="text"
                component={TextField}
                fullWidth
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <ButtonJobllegrov1
                type="submit"
                color="primary"
                disabled={pristine || isSubmitting}
              >
                {t`addCmsForm:save`}
              </ButtonJobllegrov1>
            </div>
          </form>
        }
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      fieldName: props.cmsConfig ? props.cmsConfig.fieldName : '',
      value: props.cmsConfig ? props.cmsConfig.value : '',
    },
  };
};

const AddCmsConfigForm = reduxForm({
  form: 'addCmsConfigForm',
  validate,
  enableReinitialize: true,
})(AddCmsConfig);

const mapDispatchToProps = {
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddCmsConfigForm));
