import { Button, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from 'config';
import logotypeEdge from 'shared/assets/img/LOGO-EDGE.jpg';
import logotypeEdulab from 'shared/assets/img/LOGO-EDULAB.jpg';
import logotypeJobllegroBottom from 'shared/assets/img/LOGO-JOBLLEGRO-BOTTOM.jpg';
import { LANDING_PAGE_URL } from 'shared/constants';

export const Footer = props => {
  const { classes, t } = props;

  return (
    <div className={classes.sectionRootContainer}>
      <Grid
        container
        justifyContent="flex-start"
        className={classes.sectionRoot}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <a href="https://jobllegro.com/">
            <img
              alt="Jobllegro"
              className={classes.buttonLogotype}
              src={logotypeJobllegroBottom}
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
              <Typography variant="h6">
                {t('footer:general.labelMenu')}
              </Typography>
              <div className={classes.linkSectionContainer}>
                <Button
                  className={classes.button}
                  href={`${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.STATIC_FILES.RULES}`}
                >
                  {t('footer:general.rules')}
                </Button>
                <Button
                  className={classes.button}
                  href={`${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.STATIC_FILES.PRIVACY_POLICY}`}
                >
                  {t('footer:general.privacy')}
                </Button>
                <Button
                  className={classes.button}
                  href={`${LANDING_PAGE_URL}${PATHS.LANDING_PAGE.STATIC_FILES.UNIVERSITIES_COOPERATION}`}
                >
                  {t('footer:general.cooperationWithUniversities')}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <Typography variant="h6">
                {t('footer:general.labelContact')}
              </Typography>
              <div
                className={classNames(
                  classes.linkSectionContainer,
                  classes.linkSectionColumn
                )}
              >
                <Button className={classes.button} href="tel:+48502284287">
                  +48 535 888 588
                </Button>
                <Button
                  className={classes.button}
                  href="mailto:kontakt@jobllegro.com"
                >
                  kontakt@jobllegro.com
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <div className={classes.linkContainer}></div>
        </Grid>
        <Grid item xs={12} className={classes.footerBottom}>
          <Typography component="p" className={classes.footerBottomContainer}>
            {t('footer:general.rightReserved') + '.'}
          </Typography>
          <Typography component="p">
            <a href="https://www.edge1s.com/">
              <img alt="Jobllegro" src={logotypeEdge} />
            </a>
            <a href="https://edulab.io/">
              <img alt="Jobllegro" src={logotypeEdulab} />
            </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Footer;
