import { Typography, withStyles, FormHelperText } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import EditIcon from 'shared/assets/img/icons/edit.png';
import Select from 'shared/newComponents/Select';
import TextField from 'shared/newComponents/TextField';
import { getAccommodationDataSelect } from 'shared/utils/functions';
import ExpansionComponent from '../../../../../../../../../components/LayoutExpansionComponent';
import ProjectOrderLocalization from '../../../../../../../NewProjectShowView/components/GeneralInformations/components/SideView/components/ProjectOrder/components/ProjectOrderLocalization';
import AccommodationData from '../AccommodationData';
import { styles } from './Accommodation.styles';

const Accommodation = ({
  classes,
  t,
  formik,
  isPeriodOfStayEdited,
  setIsPeriodOfStayEdited,
  setLocationForm,
  accommodations,
  showSaveBtn = true,
  selectedAccommodation,
  errorDates,
}) => {
  return (
    <ExpansionComponent
      title={t`employmentManagementDialog:dialog2.accommodation`}
      bgPink={true}
      isExpanded={true}
    >
      <Typography
        variant="body1"
        className={classes.periodOfStay}
      >{t`employmentManagementDialog:dialog2.periodOfStay`}</Typography>
      <div className={classes.datesContainer}>
        <div className={classes.dates}>
          <div className={classes.date}>
            <PowerSettingsNew className={classes.powerOnBtn} />
            {isPeriodOfStayEdited ? (
              <TextField
                type="date"
                formik={formik}
                name="periodStayStartDate"
                variant="transparent"
              />
            ) : (
              <div className={classes.periodStayStartDate}>
                <Typography variant="h5">
                  {formik.values.periodStayStartDate
                    ? formik.values.periodStayStartDate
                    : t`assign:noData`}
                </Typography>
                {formik.errors.corsBorderDate && (
                  <FormHelperText className={classes.error}>
                    {formik.errors.corsBorderDate}
                  </FormHelperText>
                )}
                {!formik.errors &&
                  formik.errors.corsBorderDate &&
                  errorDates.periodStayStartDate && (
                    <FormHelperText className={classes.error}>
                      {errorDates.periodStayStartDate}
                    </FormHelperText>
                  )}
              </div>
            )}
          </div>
          <div className={classes.date}>
            <PowerSettingsNew className={classes.powerOffBtn} />
            {isPeriodOfStayEdited ? (
              <TextField
                type="date"
                formik={formik}
                name="periodStayEndDate"
                variant="transparent"
              />
            ) : (
              <div className={classes.periodStayStartDate}>
                <Typography variant="h5">
                  {formik.values.periodStayEndDate
                    ? formik.values.periodStayEndDate
                    : t`assign:noData`}
                </Typography>
                {formik.errors.periodStayEndDate && (
                  <FormHelperText className={classes.error}>
                    {formik.errors.periodStayEndDate}
                  </FormHelperText>
                )}
                {!formik.errors &&
                  !formik.errors.periodStayEndDate &&
                  errorDates.periodStayEndDate && (
                    <FormHelperText className={classes.error}>
                      {errorDates.periodStayEndDate}
                    </FormHelperText>
                  )}
              </div>
            )}
          </div>
        </div>
        <img
          src={EditIcon}
          alt="edit"
          className={isPeriodOfStayEdited ? classes.hidden : classes.editBtn}
          onClick={() => setIsPeriodOfStayEdited(true)}
        />
      </div>
      {!selectedAccommodation || isPeriodOfStayEdited ? (
        <>
          <Typography variant="body1">{t`employmentManagementDialog:dialog2.accommodation`}</Typography>
          {showSaveBtn && (
            <ProjectOrderLocalization
              handleOpenAddLocalization={() => setLocationForm(true)}
              accommodation={true}
            />
          )}

          <div className={classes.selectLoc}>
            <Select
              formik={formik}
              name="accommodation"
              label={t`employmentManagementDialog:dialog2.chooseAccommodation`}
              variant="transparent"
              items={getAccommodationDataSelect(accommodations)}
              fontBold={true}
            />
          </div>
        </>
      ) : (
        <AccommodationData selectedAccommodation={selectedAccommodation} />
      )}
    </ExpansionComponent>
  );
};

export default withStyles(styles)(withTranslation()(Accommodation));
