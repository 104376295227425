import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import openIcon from 'shared/assets/img/icons/project-show/open.svg';
import organizationIcon from 'shared/assets/img/icons/project-show/organization.svg';
import publicIcon from 'shared/assets/img/icons/project-show/public.svg';
import unPublicIcon from 'shared/assets/img/icons/project-show/unPublic.svg';
import UserLogo from 'shared/assets/img/icons/project-show/user.png';
import { Button, Translate } from 'shared/newComponents';
import AssignHiringManagerToProjectButton from 'shared/newComponents/AssignHiringManagerToProjectButton/AssignHiringManagerToProjectButton';
import AssignMentorToProjectButton from 'shared/newComponents/AssignMentorToProjectButton/AssignMentorToProjectButton';
import GetProjectPublicUrlButton from 'shared/newComponents/GetProjectPublicUrlButton/GetProjectPublicUrlButton';
import MakeCopyProjectButton from 'shared/newComponents/MakeCopyProjectButton/MakeCopyProjectButton';
import MakeProjectClose from 'shared/newComponents/MakeProjectCloseButton/MakeProjectCloseButton';
import MakePublicButton from 'shared/newComponents/MakePublicButton/MakePublicButton';
import ModifyOrderDialog from '../../../../../ProjectOrders/components/ModifyOrderDialog/ModifyOrderDialog';
import { styles } from './SideView.style';
import List from './components/List';

const SideView = ({
  fetchProject,
  projectDetails,
  currentUser,
  classes,
  t,
  ...props
}) => {
  const [isOpenNewOrder, setIsOpenNewOrder] = useState(false);

  const getOwnerData = () => {
    if (projectDetails.owner) {
      return `${projectDetails.owner.firstName} ${projectDetails.owner.lastName}`;
    }
    return t`assign:noData`;
  };
  const handleClickEdit = () => {
    props.history.push(
      PATHS.PROJECT.NEW_EDIT.replace(':id', projectDetails.id)
    );
  };
  const handleClickProjectOrder = () => {
    setIsOpenNewOrder(true);
  };

  const handleCloseProjectOrder = () => {
    setIsOpenNewOrder(false);
  };
  const showEdit =
    projectDetails.permissions.isOwner ||
    projectDetails.permissions.isMentor ||
    projectDetails.permissions.isSuperUser;

  const showNewOrderBtn =
    (projectDetails.permissions.isOwner ||
      projectDetails.permissions.isMentorCoordinator ||
      projectDetails.permissions.isHiringManager ||
      projectDetails.permissions.isSuperUser) &&
    !projectDetails.isDraft &&
    projectDetails.projectVersion === 3;

  const redirectToOwner = () => {
    props.history.push(
      PATHS.RECRUITER.SHOW.replace(':id', projectDetails.owner.id)
    );
  };

  return (
    <Grid container alignContent="center" justifyContent="space-between">
      <Grid container style={{ padding: 30, justifyContent: 'space-between' }}>
        <Grid item>
          <Translate
            style={{ fontSize: 26 }}
            text={'projectDetails:content.projectManagement'}
            bold
            variant="h6"
          />
          <Grid
            container
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <img alt="openIcon" src={openIcon} />
            <Translate
              text={
                projectDetails.closedStatus === 0
                  ? 'projectDetails:content.open'
                  : 'projectDetails:content.close'
              }
              variant="body1"
            />
            <img
              alt="icon"
              src={
                projectDetails.visibility === 3
                  ? publicIcon
                  : projectDetails.visibility === 2
                  ? organizationIcon
                  : unPublicIcon
              }
              style={{ margin: '10px 10px' }}
            />
            <Translate
              text={
                projectDetails.visibility === 3
                  ? 'projectDetails:content.public'
                  : projectDetails.visibility === 2
                  ? 'projectDetails:content.isOrganizationProject'
                  : 'projectDetails:content.isNotPublic'
              }
              variant="body1"
            />
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            alignSelf: 'center',
          }}
        >
          <Grid
            container
            style={{
              padding: 10,
            }}
          >
            {showEdit ? (
              <Button
                full={true}
                label="projectDetails:content.editProject"
                onClick={handleClickEdit}
                size="small"
                fullWidth={true}
                style={{ marginLeft: 10 }}
              />
            ) : null}
          </Grid>
          <Grid
            container
            style={{
              padding: 10,
            }}
          >
            {showNewOrderBtn ? (
              <Button
                full={true}
                label={'menu:newOrder'}
                onClick={handleClickProjectOrder}
                size="small"
                fullWidth={true}
                style={{ marginLeft: 10, background: '#148EFF' }}
              />
            ) : null}
          </Grid>
        </Grid>
        <hr className={classes.separator} />
      </Grid>
      <Grid
        container
        style={{
          padding: 16,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          marginLeft: '10%',
        }}
      >
        <Grid item xs={12} sm={4}>
          <Grid container style={{ paddingBottom: 4 }}>
            <Translate
              style={{ fontSize: 18, color: '#6C7076' }}
              bold
              text="projectDetails:content.projectOwner"
              variant="body1"
            />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={redirectToOwner}
          >
            <img alt="UserLogo" src={UserLogo} style={{ paddingRight: 8 }} />
            <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
              {getOwnerData()}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container style={{ paddingBottom: 4 }}>
            <Translate
              style={{ fontSize: 18, color: '#6C7076' }}
              bold
              text="projectDetails:content.projectMentor"
              variant="body1"
            />
          </Grid>
          {(projectDetails.permissions.isOwner ||
            projectDetails.permissions.isHiringManager ||
            projectDetails.permissions.isSuperUser) &&
            projectDetails.projectMentors.length > 0 && (
              <List projectDetails={projectDetails} />
            )}
          <AssignMentorToProjectButton projectDetails={projectDetails} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container style={{ paddingBottom: 4 }}>
            <Translate
              style={{ fontSize: 18, color: '#6C7076' }}
              bold
              text="projectDetails:content.projectHiringManager"
              variant="body1"
            />
          </Grid>
          {(projectDetails.permissions.isOwner ||
            projectDetails.permissions.isMentor ||
            projectDetails.permissions.isSuperUser) &&
            projectDetails.projectHiringManagers.length > 0 && (
              <List projectDetails={projectDetails} hm={true} />
            )}
          <AssignHiringManagerToProjectButton projectDetails={projectDetails} />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.blueBackground}
        style={{
          padding: 16,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <Grid item>
          <MakePublicButton projectDetails={projectDetails} />
        </Grid>
        <Grid item>
          <MakeCopyProjectButton projectDetails={projectDetails} />
        </Grid>
        <Grid item>
          <MakeProjectClose projectDetails={projectDetails} />
        </Grid>
        <Grid item>
          <GetProjectPublicUrlButton
            projectDetails={projectDetails}
            currentUser={currentUser}
          />
        </Grid>
      </Grid>
      {isOpenNewOrder && (
        <ModifyOrderDialog
          project={projectDetails}
          isOpen={isOpenNewOrder}
          onClose={handleCloseProjectOrder}
          projectOrderAdded={fetchProject}
          projectOrder={projectDetails.projectOrders[0]}
          isEdit={false}
        />
      )}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(withTranslation()(SideView)));
