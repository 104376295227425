import { withStyles, Paper, Typography, Button, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { AssetService } from 'shared/services';
import { getAssetUrl } from 'shared/services';
import CandidateAssets from '../../../../../../../components/CandidateAssets';
import { MODEL } from '../../../../../../candidate/NewCandidateCreateView/components/CandidateCreateWizardStep4/CandidateCreateWizardStep4.utils';
import { styles } from './Attachments.styles';

const Attachments = ({
  classes,
  t,
  initialCandidateValues,
  formik,
  project,
  attachmentList,
  employmentProcessAsset,
  showSaveBtn = true,
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (
      formik.values[MODEL.candidateAssets] &&
      formik.values[MODEL.candidateAssets].length
    ) {
      setFiles(formik.values[MODEL.candidateAssets]);
    } else {
      setFiles([]);
    }
  }, [formik.values[MODEL.candidateAssets]]);

  useEffect(() => {
    if (employmentProcessAsset && employmentProcessAsset.length) {
      setFiles(employmentProcessAsset);
    }
  }, [initialCandidateValues]);

  const callbackUpload = assets => {
    formik.setFieldValue(MODEL.candidateAssets, [...files, assets[0]]);
    setFiles([...files, assets[0]]);
  };

  const removeFile = file => {
    AssetService.deleteAsset(file.id)
      .then(() => {
        const filterFiles = files.filter(
          item => item.id && item.id !== file.id
        );
        formik.setFieldValue(MODEL.candidateAssets, filterFiles);
        setFiles(filterFiles.length ? filterFiles : []);
      })
      .catch(error => {
        console.log(error);
        alert(t`adminCitySeo:error`);
      });
  };

  const boxDesc = {
    'access-type-oopp': `ats1ats2:employmentTypeRequest.access-type-oopp`,
    'access-type-a': `ats1ats2:employmentTypeRequestShort.access-type-a`,
    'access-type-residenceCardApplicationSubmitted': `ats1ats2:employmentTypeRequestShort.access-type-residenceCardApplicationSubmitted`,
    'access-type-c': `ats1ats2:employmentTypeRequestShort.access-type-c`,
    'access-type-d': `ats1ats2:employmentTypeRequestShort.access-type-d`,
    'access-type-e': `ats1ats2:employmentTypeRequestShort.access-type-e`,
    'access-type-residenceCardNoJobMarket': `ats1ats2:employmentTypeRequestShort.access-type-residenceCardNoJobMarket`,
    visa: `ats4ats5:visa`,
    passportStampScan: `employmentManagementDialog:dialog2.crossingBorderPageAdded`,
    passportVisaScan: `employmentManagementDialog:dialog2.personalDataVisaAdded`,
    passportPersonalDataScan: `employmentManagementDialog:dialog2.personalDataAdded`,
    assignContractScan: `employmentManagementDialog:dialog5.employmentContract`,
    accountNumberScan: `employmentManagementDialog:dialog5.bankAccount`,
    personNumberScan: `employmentManagementDialog:dialog5.registrationNumber`,
  };

  const boxes = Object.entries(attachmentList).map(
    ([attachmentType, label]) => {
      return {
        name: t([label]),
        file: files.find(file => file.type === attachmentType),
        type: attachmentType,
        desc: boxDesc[attachmentType] ? t(boxDesc[attachmentType]) : '',
      };
    }
  );

  return (
    <div className={classes.fileSection}>
      {boxes.map(({ name, file, type, desc }, index) => (
        <Paper
          className={`${classes.paper} ${
            index === boxes.length - 1 && classes.noGap
          }`}
          key={index}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item md={9}>
              {!file ? (
                <Typography variant="body1" className={classes.bold}>
                  {name}
                </Typography>
              ) : (
                <>
                  <Typography variant="body1" className={classes.bold}>
                    {desc}
                  </Typography>
                  <Typography variant="body1" className={classes.fileName}>
                    <a href={getAssetUrl(file.id)} className={classes.link}>
                      {file.filename}
                    </a>
                  </Typography>
                </>
              )}
            </Grid>
            {showSaveBtn && (
              <Grid item md={3}>
                {!file ? (
                  <CandidateAssets
                    projectId={project ? project.id : null}
                    newCandidateUpload={true}
                    onClose={() => {}}
                    clearLoad={true}
                    callback={callbackUpload}
                    removeIcon={false}
                    filesLimit={1}
                    assetType={type}
                    acceptedFiles={['.pdf', '.docx', '.doc', '.odt']}
                    candidate={initialCandidateValues}
                  >
                    <Button
                      variant="outlined"
                      className={classes.chooseFileBtn}
                      onClick={() => {}}
                    >{t`employmentManagementDialog:dialog2.chooseFile`}</Button>
                  </CandidateAssets>
                ) : (
                  <Button
                    onClick={() => removeFile(file)}
                    variant="outlined"
                    className={classes.delBtn}
                  >{t`employmentManagementDialog:dialog2.deleteFile`}</Button>
                )}
              </Grid>
            )}
          </Grid>
        </Paper>
      ))}
    </div>
  );
};

export default withStyles(styles)(withTranslation()(Attachments));
