import { Grid, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { enums } from 'shared/enums';
import {
  Checkbox,
  CheckboxGroup,
  Select,
  TextField,
  Translate,
  WizardStep,
} from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import {
  getSelectItems,
  getSelectItemsFullObjectTranslate,
} from 'shared/utils/assets';
import {
  useFormStep3,
  stage4NotSetFields,
} from '../CandidateCreateWizard/CandidateCreateWizard.utils';
import { styles } from './CandidateCreateWizardStep3.styles';
import { MODEL } from './CandidateCreateWizardStep3.utils';

const CandidateCreateWizardStep3 = ({
  t,
  initialCandidateValues,
  employmentTypes,
  isFormStepError,
  setIsStep3Open,
  isFormStepSaveButtonDisabled,
  closeAll,
  isCandidateSave,
  history,
  setCurrentStage,
  setIsSnackbarErrorOpen,
  isStep3Open,
  step4FormValues,
  setIsStep4Open,
  setStep3FormValues,
  classes,
  showSaveButton,
}) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const myRef = useRef(null);

  const formik = useFormStep3({
    t,
    initialCandidateValues,
    history,
    setCurrentStage,
  });

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeout = setTimeout(() => {
      setStep3FormValues(formik.values);
    }, 2000);
    setTimeoutId(newTimeout);
  }, [formik.values]);

  useEffect(() => {
    if (isStep3Open) {
      executeScroll(myRef);
    }
  }, [isStep3Open]);

  const translateEmploymentTypes = employmentTypes.map(item => {
    return { ...item, name: item.name };
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />
      <WizardStep
        withSaveButton={showSaveButton}
        title="candidateCreator:step3.title"
        myRef={myRef}
        isOpen={isStep3Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep3Open(false);
            const openSecondStep = stage4NotSetFields(
              initialCandidateValues,
              step4FormValues
            );
            setIsStep4Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep3Open(true);
          executeScroll();
        }}
        disableArrowButton={!isCandidateSave}
        onClickArrow={() => {
          if (!isCandidateSave) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => {
          setIsStep3Open(false);
        }}
      >
        <Grid container>
          <Grid container>
            <CheckboxGroup
              items={translateEmploymentTypes}
              name={MODEL.employmentTypes}
              label={t('candidateCreator:step3.employmentType')}
              formik={formik}
              variant="button"
              iterateFrom={1}
            />
          </Grid>
          <Grid container style={{ padding: '42px 16px 0 16px' }}>
            <Translate
              text="candidateCreator:step3.expectedWorkTime"
              bold
              variant="h6"
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                items={[
                  {
                    id: 0,
                    name: t('projectCreator:workHoursSelect.monthly'),
                  },
                ]}
                onChange={e => {
                  formik.setFieldValue(MODEL.workHourType, e.target.value);
                  if (e.target.value > 2) {
                    formik.setFieldValue(MODEL.expectedWorkTime, '');
                  }
                }}
                name={MODEL.workHourType}
                label={t`candidateCreator:step3.expectedWorkTypeFiled`}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                type={'number'}
                disabled={formik.values.workHourType > 2}
                name={MODEL.expectedWorkTime}
                formik={formik}
                label={t('projectCreator:step1.expectedWorkTime')}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <Checkbox
                label={t('candidateCreator:step3.isShiftWorkPossible')}
                name={MODEL.isShiftWorkPossible}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Checkbox
                label={t('candidateCreator:step3.isWeekendsWorkPossible')}
                name={MODEL.isWeekendsWorkPossible}
                formik={formik}
              />
            </Grid>
          </Grid>
          <Grid container style={{ padding: '32px 16px 0 16px' }}>
            <Translate text="projectCreator:step1.salary" bold variant="h6" />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item style={{ width: '100%' }}>
                  <TextField
                    label={t('projectCreator:step1.minSalary')}
                    name={MODEL.minMonthlyWadges}
                    formik={formik}
                    type="number"
                  />
                </Grid>
                <Grid item>
                  <Translate text="-" variant="h4" color="textSecondary" />
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <TextField
                    name={MODEL.maxMonthlyWadges}
                    formik={formik}
                    label={t('projectCreator:step1.maxSalary')}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Select
                label={t('projectCreator:step1.currency')}
                name={MODEL.currency}
                formik={formik}
                items={getSelectItems(enums.CURRENCY)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <Select
                items={getSelectItemsFullObjectTranslate(
                  enums.WORKING_HOURS,
                  t
                )}
                name={MODEL.salaryType}
                label={t('projectCreator:step1.salaryType')}
                formik={formik}
              />
            </Grid>
          </Grid>
        </Grid>
      </WizardStep>
    </form>
  );
};

export default withStyles(styles)(
  withTranslation()(CandidateCreateWizardStep3)
);
