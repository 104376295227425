import { API } from '../constants';
import { ApiService } from './api.service';

export const ProjectEmploymentProcessService = {
  addProjectEmploymentProcess(data) {
    return ApiService.post(
      API.POST.PROJECT_CANDIDATE_EMPLOYMENT_PROCESS.POST,
      data
    );
  },

  addProjectEmploymentManagement(data) {
    return ApiService.post(
      API.POST.PROJECT_CANDIDATE_EMPLOYMENT_MANAGEMENT_HISTORY.POST,
      data
    );
  },
  updateProjectEmploymentProcess(data, stage) {
    return ApiService.put(
      API.PUT.PROJECT_CANDIDATE_EMPLOYMENT_PROCESS.PUT_STAGE,
      data,
      { stage }
    );
  },
  updateProjectEmploymentProcessStageOnly(data, stage) {
    return ApiService.put(
      API.PUT.PROJECT_CANDIDATE_EMPLOYMENT_PROCESS.PUT_STAGE_ONLY,
      data,
      {
        stage,
      }
    );
  },
  rejectProjectEmploymentProcess(projectEmploymentId) {
    return ApiService.put(
      API.PUT.PROJECT_CANDIDATE_EMPLOYMENT_PROCESS.REJECT.replace(
        ':id',
        projectEmploymentId
      )
    );
  },
  restoreProjectEmploymentProcess(projectEmploymentId) {
    return ApiService.put(
      API.PUT.PROJECT_CANDIDATE_EMPLOYMENT_PROCESS.RESTORE.replace(
        ':id',
        projectEmploymentId
      )
    );
  },
};

export default ProjectEmploymentProcessService;
