export const styles = () => ({
  employmentDate: { marginTop: 10 },
  container: { padding: '40px 25px' },
  datesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 35,
  },
  containerMargin: {
    marginTop: 10,
  },
  dates: { display: 'flex', alignItems: 'center' },
  date: { display: 'flex', alignItems: 'center' },
  editBtn: { cursor: 'pointer' },
  hidden: { visibility: 'hidden' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  periodOfStay: { marginBottom: 20 },
  quarantinePeriod: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectLoc: { margin: '20px 0' },
  pinIcon: {
    paddingRight: 8,
    height: 50,
  },
  mailDiv: { display: 'flex', alignItems: 'center', marginBottom: 10 },
  mail: { marginRight: 10 },
  mobileDiv: { display: 'flex', alignItems: 'center' },
  mobile: { marginRight: 10 },
  padding: { paddingLeft: 10 },
  bg: {
    background: '#FFF3E9',
    margin: '0 -15px 40px',
    width: 'calc(100% + 30px)',
  },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  addLocationWrapper: { marginLeft: '-25px' },
  error: { color: '#f41010', position: 'absolute', top: '30px' },
  accommodationWrapper: { paddingBottom: '25px', cursor: 'pointer' },
});
