import { Typography, withStyles, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import EditIcon from 'shared/assets/img/icons/edit.png';
import { enums } from 'shared/enums';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services';
import { styles } from './WaitingForArrival.styles';

const WaitingForArrival = ({
  classes,
  t,
  selectedFullObject,
  dataAdded,
  showSaveBtn,
}) => {
  const [isArrivalDateEdited, setIsArrivalDateEdited] = useState(false);

  const formData = {
    arrivalDate: selectedFullObject.arrivalEstimatedDate,
    isCandidateArrival: !!selectedFullObject.arrivalConfirmedDate,
  };

  const validationSchema = yup.object({
    arrivalDate:
      isArrivalDateEdited &&
      yup.date().required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({ arrivalDate, isCandidateArrival }) => {
      const body = {
        arrivalEstimatedDate: arrivalDate,
        isCandidateArrival: isCandidateArrival,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };
      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS
          .EMPLOYMENT_STATUS_WAIT_FOR_ARRIVAL
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => {
          console.log(error);
        });
    },
  });

  const handleCandidateArrived = () => {
    formik.setFieldValue('isCandidateArrival', true);
    formik.submitForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.bgBlue}>
        <div className={classes.container}>
          <div className={classes.arrivalDate}>
            <Typography
              variant="h6"
              className={classes.arrivalTxt}
            >{t`employmentManagementDialog:dialog1.arrivalDate`}</Typography>
            <div className={classes.dateDiv}>
              <Typography variant="h5" className={classes.date}>
                {formik.values.arrivalDate}
              </Typography>
              <img
                src={EditIcon}
                alt="edit"
                className={
                  isArrivalDateEdited ? classes.hidden : classes.editIcon
                }
                onClick={() => setIsArrivalDateEdited(true)}
              />
            </div>
          </div>
          {isArrivalDateEdited ? (
            <div className={classes.newArrivalDate}>
              <div>
                <Typography variant="body1">{t`employmentManagementDialog:dialog1.newArrivalDate`}</Typography>
              </div>
              <div className={classes.datePicker}>
                <TextField
                  type="date"
                  name="arrivalDate"
                  variant="transparent"
                  formik={formik}
                />
              </div>
            </div>
          ) : (
            <div className={classes.btns}>
              {/*<Button*/}
              {/*  variant="outlined"*/}
              {/*  className={classes.quitBtn}*/}
              {/*>{t`employmentManagementDialog:dialog1.candidateQuit`}</Button>*/}
              {/*<Button*/}
              {/*  onClick={handleCandidateArrived}*/}
              {/*  className={classes.arrivedBtn}*/}
              {/*>{t`employmentManagementDialog:dialog1.candidateArrived`}</Button>*/}
            </div>
          )}
        </div>
      </div>
      {showSaveBtn && (
        <div className={classes.saveBtnDiv}>
          <Button type="submit" className={classes.saveBtn}>
            {t`employmentManagementDialog:saveBtn`}
          </Button>
        </div>
      )}
    </form>
  );
};

export default withStyles(styles)(withTranslation()(WaitingForArrival));
