import { Grid, Paper } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import IK1 from 'shared/assets/img/icons/IK1.png';
import IK2 from 'shared/assets/img/icons/IK2.png';
import IK3 from 'shared/assets/img/icons/IK3.png';
import IK4 from 'shared/assets/img/icons/IK4.png';
import IK5 from 'shared/assets/img/icons/IK5.png';
import IK6 from 'shared/assets/img/icons/IK6.png';
import SectionFooter from '../../modules/landing/components/Footer/';
import MenuBar from '../../modules/landing/components/MenuBar/';
import 'shared/assets/css/mainPage.css';

const LandingLayout = props => {
  const { classes, children } = props;

  return (
    <Grid className={classes.landingWrapper} container justifyContent="center">
      <img
        className={classNames(classes.icon, classes.icon6)}
        alt="Jobllegro"
        src={IK6}
      />
      <img
        className={classNames(classes.icon, classes.icon5)}
        alt="Jobllegro"
        src={IK5}
      />
      <img
        className={classNames(classes.icon, classes.icon1)}
        alt="Jobllegro"
        src={IK1}
      />
      <img
        className={classNames(classes.icon, classes.icon2)}
        alt="Jobllegro"
        src={IK2}
      />
      <img
        className={classNames(classes.icon, classes.icon3)}
        alt="Jobllegro"
        src={IK3}
      />
      <img
        className={classNames(classes.icon, classes.icon4)}
        alt="Jobllegro"
        src={IK4}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <MenuBar />
        <Paper style={{ background: 'transparent' }} elevation={0}>
          <Grid
            container
            justifyContent="center"
            className={classes.landingInnerWrapper}
          >
            {children}
          </Grid>
        </Paper>
      </Grid>
      <SectionFooter />
    </Grid>
  );
};

LandingLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LandingLayout;
