export const styles = ({ palette }) => ({
  containerRoot: {
    padding: 16,
  },
  paperRoot: {
    width: '100%',
  },
  icon: {
    height: 50,
    paddingBottom: 8,
  },
  price: {
    fontWeight: 'bold',
    color: palette.primary.main,
  },
});
