import { Avatar, Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { enums } from 'shared/enums';
import { Translate } from 'shared/newComponents';
import { deleteUser, logoutUser } from 'shared/services';
import { getUserAvatarSrc } from 'shared/utils/assets';
import { ActionButtonJobllegroRounded } from 'shared/utils/styledComponents';
import ConfirmationDialog from '../../../../../../../components/LayoutConfirmationDialog/LayoutConfirmationDialog';
import UserAvatar from '../../../../../../../components/UserAvatar';
import { styles } from './RecruiterPresonalDetailsTop.styles';

const RecruiterPresonalDetailsTop = ({
  history,
  recruiterData,
  recruiterEdited,
  classes,
  t,
  ...props
}) => {
  const [deleteConfirmationOpened, setDeleteConfirmationOpened] =
    useState(false);
  const avatar = getUserAvatarSrc(recruiterData);

  const handleClickChangePassword = () => {
    history.push(PATHS.AUTH.FORGOT_PASSWORD);
  };

  const handleClickRemoveButton = () => {
    setDeleteConfirmationOpened(true);
  };

  const handleRemove = isOk => {
    if (isOk) {
      props
        .deleteUser(props.currentUser.id)
        .then(() => {
          props.logoutUser().then(() => {
            history.push(PATHS.AUTH.LOGIN);
          });
        })
        .catch(error => {
          console.log(error);
          alert(t`addOrganizationForm:error`);
        });
    }
    setDeleteConfirmationOpened(false);
  };
  const currentMentorStatus = recruiterData.mentorStatus;

  const isMentor = currentMentorStatus === enums.MENTOR_STATUS.ACCEPTED;

  return (
    <Grid container alignContent={'center'}>
      {/* //Zdjecie i akcje z kontem */}
      <Grid item xs={12} sm={12} md={6} className={classes.topPadding}>
        <Paper className={classes.paperWrapper}>
          <Grid container className={classes.gridWrapper}>
            <Grid item xs={3} sm={3} md={3}>
              <Avatar
                src={avatar}
                aria-label="Jobllegro"
                className={classes.avatar}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={'recruiter:addPhoto'}
                    bold
                    className={classes.bigFont}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={
                      isMentor
                        ? 'organization:field.mentorValue'
                        : 'dashboard:rightDrawer.recruiter'
                    }
                    className={classes.bigFont}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} className={classes.imageWrapper}>
              <Grid>
                <UserAvatar
                  user={recruiterData}
                  updateUser={recruiterEdited}
                  updateRecruiterV2={true}
                >
                  <img alt="userAvatar" src={EditIcon} height={50} />
                </UserAvatar>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.topPadding}>
        <Grid container className={classes.gridBtnWrapper}>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            className={classes.btnChangePasswordWidth}
          >
            <ActionButtonJobllegroRounded
              style={{ background: '#27D39B' }}
              onClick={handleClickChangePassword}
            >
              {t('recruiter:form.changePassword')}
            </ActionButtonJobllegroRounded>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            className={classes.btnChangePasswordWidth}
          >
            <ActionButtonJobllegroRounded
              style={{ background: '#F41913' }}
              onClick={handleClickRemoveButton}
            >
              {t('recruiter:form.removeProfileRecruiter')}
            </ActionButtonJobllegroRounded>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={deleteConfirmationOpened}
        titleText={t('recruiter:form.removeProfileRecruiterText')}
        onClose={handleRemove}
        okButtonText={t('action.remove')}
        cancelButtonText={t('action.cancel')}
      >
        {t('form.info.removeAccount')}
      </ConfirmationDialog>
    </Grid>
  );
};

const mapStateToProps = state => {
  return { currentUser: state.auth.currentUser };
};
const mapDispatchToProps = {
  deleteUser,
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(withRouter(withTranslation()(RecruiterPresonalDetailsTop)))
);
