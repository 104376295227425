import { createAction } from '@reduxjs/toolkit';
import {
  SHOW_SNACKBAR_MESSAGE,
  HIDE_SNACKBAR_MESSAGE,
  SHOW_SNACKBAR_INFORMATION_MESSAGE,
  HIDE_SNACKBAR_INFORMATION_MESSAGE,
} from '../actionConstants';

export const showSnackbarMessage = createAction(SHOW_SNACKBAR_MESSAGE);
export const hideSnackbarMessage = createAction(HIDE_SNACKBAR_MESSAGE);
export const showSnackbarInformationMessage = createAction(
  SHOW_SNACKBAR_INFORMATION_MESSAGE
);
export const hideSnackbarInformationMessage = createAction(
  HIDE_SNACKBAR_INFORMATION_MESSAGE
);
