import { Menu, MenuItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import { AvatarIcon, LogoJobllegro } from 'shared/assets/icons';
import {
  BottomBar,
  ButtonText,
  Content,
  GridContainer,
  Icon,
  Logo,
  StyledButton,
  Wrapper,
} from './Layout.style';

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //TODO: translations

  return (
    <>
      <GridContainer container>
        <Wrapper item xs={10}>
          <Content>
            <Link to={PATHS.DASHBOARD.INDEX}>
              <Logo alt="Jobllegro" src={LogoJobllegro} />
            </Link>
            <Icon alt="Icon" src={AvatarIcon} onClick={handleClick} />
          </Content>
          <Divider />
        </Wrapper>
        {children}
        <BottomBar item xs={12}>
          <StyledButton>
            <ButtonText>ZAPISZ SEKCJĘ</ButtonText>
            <ArrowForwardIosIcon fontSize="small" />
          </StyledButton>
        </BottomBar>
      </GridContainer>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={PATHS.USER.INDEX}>
          Profil użytkownika
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to={PATHS.AUTH.LOGOUT}>
          Wyloguj
        </MenuItem>
      </Menu>
    </>
  );
};

export default Layout;
