import {
  TextField as BaseTextField,
  withStyles,
  Grid,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { has, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { styles } from './TextField.style';

const TextField = ({
  label,
  name,
  formik,
  classes,
  variant = 'default',
  ...props
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (
      props.type === 'number' &&
      get(formik.values, name) !== undefined &&
      get(formik.values, name) !== null
    ) {
      setValue(get(formik.values, name).toString() || '');
    } else {
      setValue(get(formik.values, name) || '');
    }
  }, [get(formik.values, name)]);

  const handleTextFieldChange = e => {
    setValue(e.target.value);
    formik.handleChange(e);
  };

  const error =
    has(formik, `touched.${name}`) && Boolean(get(formik.errors, name));

  return (
    <Grid container className={classes.root}>
      <FormControl
        fullWidth
        error={error}
        variant="outlined"
        className={
          props.type === 'date' || props.type === 'number'
            ? classes.formControl
            : ''
        }
      >
        <BaseTextField
          label={label}
          name={name}
          value={value}
          onChange={handleTextFieldChange}
          error={error}
          fullWidth
          classes={{
            root: classes.input,
          }}
          variant="outlined"
          {...props}
          style={
            variant === 'transparent'
              ? { backgroundColor: 'white', borderRadius: 30 }
              : {}
          }
        />
        {error && (
          <FormHelperText
            className={
              props.type === 'date' || props.type === 'number'
                ? classes.formHelperText
                : ''
            }
          >
            {has(formik, `touched.${name}`) && get(formik.errors, name)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(TextField);
