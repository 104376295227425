import _ from 'lodash';
import moment from 'moment';

const filterAts = (filters, candidates) => {
  let candidateFilter = [...candidates];
  if (filters.candidateLastName) {
    const candidateLastName = filters.candidateLastName;
    candidateFilter = _.filter(candidateFilter, obj => {
      const lastName = obj.candidate.lastName;
      return _.startsWith(
        lastName ? lastName.toLowerCase() : lastName,
        candidateLastName.toLowerCase()
      );
    });
  }
  if (filters.projectOwnerRecruiterLastName) {
    const projectOwnerRecruiterLastName = filters.projectOwnerRecruiterLastName;
    candidateFilter = _.filter(candidateFilter, obj => {
      const lastName = obj.owner.lastName;
      return _.startsWith(
        lastName ? lastName.toLowerCase() : lastName,
        projectOwnerRecruiterLastName.toLowerCase()
      );
    });
  }
  if (filters.stage1DateStart) {
    const filterDate = moment(filters.stage1DateStart);
    candidateFilter = _.filter(candidateFilter, obj => {
      const stage1DateMoment = moment(obj.stage1Date);
      return filterDate.isSameOrBefore(stage1DateMoment, 'day');
    });
  }
  if (filters.stage1DateStop) {
    const filterDate = moment(filters.stage1DateStop);
    candidateFilter = _.filter(candidateFilter, obj => {
      const stage1DateMoment = moment(obj.stage1Date);
      return filterDate.isSameOrAfter(stage1DateMoment, 'day');
    });
  }

  if (filters.projectOrderNumbers && filters.projectOrderNumbers.length) {
    const projectOrderNumbers = filters.projectOrderNumbers.map(
      item => item.id
    );

    candidateFilter = _.filter(candidateFilter, obj => {
      const projectOrder = obj.projectOrder;
      if (!projectOrder) {
        return true;
      }
      return projectOrderNumbers.includes(projectOrder.id);
    });
  }

  return candidateFilter;
};

const filterManagementFilter = (
  filters,
  projectCandidatesEmploymentManagement
) => {
  let projectCandidatesEmploymentManagementFilter = [
    ...projectCandidatesEmploymentManagement,
  ];

  if (filters.candidateLastName) {
    const candidateLastName = filters.candidateLastName;
    projectCandidatesEmploymentManagementFilter = _.filter(
      projectCandidatesEmploymentManagementFilter,
      obj => {
        const lastName = obj.candidate.lastName;
        return _.startsWith(
          lastName ? lastName.toLowerCase() : lastName,
          candidateLastName.toLowerCase()
        );
      }
    );
  }

  if (filters.tableStartDate) {
    const filterDate = moment(filters.tableStartDate);
    projectCandidatesEmploymentManagementFilter = _.filter(
      projectCandidatesEmploymentManagementFilter,
      obj => {
        const tableStartDate = moment(obj.tableStartDate);
        return filterDate.isSameOrBefore(tableStartDate, 'day');
      }
    );
  }
  if (filters.tableEndDate) {
    const filterDate = moment(filters.tableEndDate);
    projectCandidatesEmploymentManagementFilter = _.filter(
      projectCandidatesEmploymentManagementFilter,
      obj => {
        const tableEndDate = moment(obj.tableEndDate);
        return filterDate.isSameOrAfter(tableEndDate, 'day');
      }
    );
  }

  if (filters.projectOrderNumbers && filters.projectOrderNumbers.length) {
    const projectOrderNumbers = filters.projectOrderNumbers.map(
      item => item.id
    );

    projectCandidatesEmploymentManagementFilter = _.filter(
      projectCandidatesEmploymentManagementFilter,
      obj => {
        const projectOrder = obj.projectCandidate.projectOrder;
        if (!projectOrder) {
          return true;
        }
        return projectOrderNumbers.includes(projectOrder.id);
      }
    );
  }

  if (filters.localizationNumbers && filters.localizationNumbers.length) {
    const localizationNumbers = filters.localizationNumbers.map(
      item => item.id
    );

    projectCandidatesEmploymentManagementFilter = _.filter(
      projectCandidatesEmploymentManagementFilter,
      obj => {
        const localization = obj.localization;
        if (!localization) {
          return true;
        }
        return localizationNumbers.includes(localization.id);
      }
    );
  }

  if (filters.accommodationNumbers && filters.accommodationNumbers.length) {
    const accommodationNumbers = filters.accommodationNumbers.map(
      item => item.id
    );

    projectCandidatesEmploymentManagementFilter = _.filter(
      projectCandidatesEmploymentManagementFilter,
      obj => {
        const accommodation = obj.accommodation;
        if (!accommodation) {
          return true;
        }
        return accommodationNumbers.includes(accommodation.id);
      }
    );
  }
  if (
    filters.employmentStatusNumbers &&
    filters.employmentStatusNumbers.length
  ) {
    const employmentStatusNumbers = filters.employmentStatusNumbers.map(
      item => item.id
    );

    projectCandidatesEmploymentManagementFilter = _.filter(
      projectCandidatesEmploymentManagementFilter,
      obj => {
        const employmentStatus = obj.employmentStatus;
        if (!employmentStatus) {
          return true;
        }
        return employmentStatusNumbers.includes(employmentStatus);
      }
    );
  }

  return projectCandidatesEmploymentManagementFilter;
};

export const handleEmploymentManagementFilter = (
  filters,
  projectCandidatesEmploymentManagement
) => {
  return filterManagementFilter(filters, projectCandidatesEmploymentManagement);
};

export const handleAtsEmploymentProcessFilter = (filters, candidates) => {
  return filterAts(filters, candidates);
};

export const handleAtsFilter = (filters, candidates) => {
  let candidateFilter = filterAts(filters, candidates);
  candidateFilter = _.filter(
    candidateFilter,
    obj => obj.projectCandidateEmploymentProcess === null
  );

  return candidateFilter;
};

export const getAvailabilityDiffDay = candidateDateStage => {
  const momentDate = moment(candidateDateStage);
  return moment().diff(momentDate, 'days');
};

export const getAvailabilityDiffDayToDay = candidateDateStage => {
  return moment(candidateDateStage).diff(moment(), 'days');
};

export const diffBetweenDate = (dateStart, dateEnd) => {
  const momentDateStart = moment(dateStart);
  const momentDateEnd = moment(dateEnd);
  return momentDateEnd.diff(momentDateStart, 'days');
};

export const getAvailabilityFontStyle = candidateDateStage => {
  const availabilityDiffDay = getAvailabilityDiffDay(candidateDateStage);
  if (availabilityDiffDay < 7) {
    return null;
  }
  if (availabilityDiffDay >= 7 && availabilityDiffDay < 14) {
    return {
      color: 'red',
    };
  }

  return {
    color: 'red',
    fontWeight: 600,
  };
};
