import { Avatar, Badge, Grid, withStyles } from '@material-ui/core';
import { get, take } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { Button } from '..';
import { getAssetUrl } from '../../services';
import { getUserAvatarSrc } from '../../utils/assets';
import { ProgressBar, Translate } from '../index';
import { styles } from './RecruiterCard.styles';

const RecruiterCard = ({
  recruiter,
  showBtn = true,
  t,
  action = null,
  history,
  classes,
}) => {
  const avatar = getUserAvatarSrc(recruiter, false, 'avatarAsset');

  const firstName = recruiter.firstName;
  const email = recruiter.email;
  const lastName = recruiter.lastName;
  const updatedAt = recruiter.updatedAt;

  const userDepartmentExperience = recruiter.userDepartmentExperiences || [];

  const { mentorStatus } = recruiter;

  const recruiterMlSimilarities =
    recruiter.similarities && recruiter.similarities > 0
      ? recruiter.similarities
      : null;

  const userIndustryCategoryExperiences =
    recruiter.userIndustryCategoryExperiences || [];

  const userPositionExperiences = recruiter.userPositionExperiences || [];

  const getRecruiterExperiencesInYears = () => {
    return userPositionExperiences.reduce((previousValue, currentValue) => {
      return parseInt(previousValue) > parseInt(currentValue.experienceLevel)
        ? parseInt(previousValue)
        : parseInt(currentValue.experienceLevel);
    }, 0);
  };

  const handleClickReturnToProject = () => {
    history.push(PATHS.RECRUITER.SHOW.replace(':id', recruiter.id));
  };
  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid className={classes.recruiterCardWrapper}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container alignItems={'center'}>
              <Grid item xs={12} sm={5} md={4}>
                <Badge
                  badgeContent={t`recruiter:mentor`}
                  classes={{
                    badge:
                      mentorStatus === 2
                        ? classes.userStatus
                        : classes.userStatusDisabled,
                  }}
                >
                  <Avatar
                    src={avatar}
                    component={Link}
                    to={PATHS.RECRUITER.SHOW.replace(':id', recruiter.id)}
                    className={classes.avatar}
                  />
                </Badge>
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    onClick={handleClickReturnToProject}
                    className={classes.personalDataWrapper}
                  >
                    <Translate
                      text={`${firstName} ${lastName}`}
                      bold
                      className={classes.fontStylesBig}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {action}
              </Grid>
            </Grid>
            <Grid container className={classes.wrapperTextExp}>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  bold
                  text={`${email}`}
                  className={classes.fontStylesSmall}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={`${t`recruiter:mentor`} ${
                    getRecruiterExperiencesInYears() === 0
                      ? t`recruiter:noInfo`
                      : '> ' + getRecruiterExperiencesInYears()
                  }`}
                  className={classes.fontStylesSmall}
                />
              </Grid>
              {recruiterMlSimilarities ? (
                <Grid item xs={12} sm={12} md={12}>
                  <Translate
                    text={`${t`recruiter:fulfills`} ${recruiterMlSimilarities}%`}
                    className={classes.fontStylesSmall}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={12} md={12}>
                <Translate
                  text={`${t`recruiter:latestActivity`} ${updatedAt}`}
                  className={classes.fontStylesSmall}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.wrapperDepartments}>
              <Grid item xs={12} sm={12} md={12}>
                {take(userDepartmentExperience, 2).map(item => {
                  return (
                    <ProgressBar
                      key={item.id}
                      percentage={
                        item.experienceLevel * 10 > 100
                          ? 100
                          : item.experienceLevel * 10
                      }
                      title={item.department.name}
                      secondTitle={''}
                      customStyleSecondHeader={{
                        color: '#6CB8FF',
                      }}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <Grid container className={classes.wrapperLogos}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container justifyContent={'space-around'}>
                  {take(userIndustryCategoryExperiences, 3).map(item => {
                    const industryCategory = get(item, 'industryCategory');
                    const industryCategoryAssets = get(
                      industryCategory,
                      'assets'
                    );

                    const industryCategoryLogo = industryCategoryAssets
                      ? industryCategoryAssets.find(
                          item => item.type === 'industryCategoryLogo'
                        )
                      : null;
                    const industryCategoryIcon = industryCategoryLogo
                      ? getAssetUrl(industryCategoryLogo.id)
                      : null;
                    return (
                      <img
                        key={item.id}
                        alt="industryCategoryIcon"
                        src={industryCategoryIcon}
                        height={40}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            {showBtn ? (
              <Grid
                container
                justifyContent="center"
                className={classes.saveButton}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    label={t`recruiter:see`}
                    onClick={handleClickReturnToProject}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(withRouter(RecruiterCard)));
