import { Grid, withStyles } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Translate, WizardStep } from 'shared/newComponents';
import ScrollPoint from 'shared/newComponents/ScrollPoint';
import {
  executeScroll,
  executeScrollToTop,
} from 'shared/newComponents/ScrollPoint/Scroll.utils';
import {
  useFormStep3,
  stage5NotSetFields,
} from '../CandidateCreateWizard/CandidateCreateWizard.utils';
import { styles } from './CandidateCreateWizardStep4.styles';
import { MODEL } from './CandidateCreateWizardStep4.utils';
import FileChooser from './components/FileChoiser';

const CandidateCreateWizardStep4 = ({
  t,
  initialCandidateValues,
  isFormStepError,
  isFormStepSaveButtonDisabled,
  closeAll,
  isCandidateSave,
  history,
  setCurrentStage,
  setIsSnackbarErrorOpen,
  isStep4Open,
  setIsStep4Open,
  step5FormValues,
  setIsStep5Open,
  isOneStageSaved,
  classes,
  showSaveButton,
}) => {
  const [files, setFiles] = useState([]);
  const myRef = useRef(null);

  const formik = useFormStep3({
    t,
    initialCandidateValues,
    history,
    setCurrentStage,
  });

  useEffect(() => {
    if (
      formik.values[MODEL.candidateAssets] &&
      formik.values[MODEL.candidateAssets].length
    ) {
      setFiles(formik.values[MODEL.candidateAssets]);
    } else {
      setFiles([1]);
    }
  }, [formik.values[MODEL.candidateAssets]]);

  useEffect(() => {
    if (isStep4Open) {
      executeScroll(myRef);
    }
  }, [isStep4Open]);

  if (!files) {
    return null;
  }

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <ScrollPoint refForward={myRef} />
      <WizardStep
        withSaveButton={false}
        title="candidateCreator:step4.title"
        myRef={myRef}
        isOpen={isStep4Open}
        onClose={() => {
          if (!isFormStepError(formik)) {
            setIsStep4Open(false);
            const openSecondStep = stage5NotSetFields(
              initialCandidateValues,
              step5FormValues
            );
            setIsStep5Open(openSecondStep);
            if (!openSecondStep) {
              executeScrollToTop();
            }
          }
        }}
        isError={isFormStepSaveButtonDisabled(formik)}
        onOpen={() => {
          closeAll();
          setIsStep4Open(true);
          executeScroll();
        }}
        disableArrowButton={
          !(isCandidateSave === true && isOneStageSaved === true)
        }
        onClickArrow={() => {
          if (!(isCandidateSave === true && isOneStageSaved === true)) {
            setIsSnackbarErrorOpen(true);
          }
        }}
        onCloseSelf={() => {
          setIsStep4Open(false);
        }}
      >
        <Grid container>
          <Grid container>
            <Grid item>
              <Translate
                style={{ fontSize: '1rem', color: '#233862' }}
                text={'candidateCreator:step4.titleLabel'}
              />
            </Grid>
          </Grid>
          <FileChooser
            formik={formik}
            initialCandidateValues={initialCandidateValues}
            showSaveButton={showSaveButton}
          />
        </Grid>
      </WizardStep>
    </form>
  );
};

export default withStyles(styles)(
  withTranslation()(CandidateCreateWizardStep4)
);
