import { Tabs, Tab, withStyles, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import { getProjectRecruiterConfirmationStatus } from 'shared/services';
import { styles } from './AtsSubMenu.styles';

export const AtsSubMenu = ({
  match,
  getProjectRecruiterConfirmationStatus,
  currentUser = {},
  projectDetails,
  projectDetailsOutStore,
  classes,
  t,
  history,
}) => {
  const [index, setIndex] = useState(0);

  const getProjectRecruiterConfirmationStatusFunc = async () => {
    try {
      if (match.params.id !== undefined) {
        await getProjectRecruiterConfirmationStatus(
          match.params.id,
          currentUser.id
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getIndexMenu = () => {
    switch (match.path) {
      case PATHS.PROJECT.ATS:
        return 0;
      case PATHS.PROJECT.ATS_EMPLOYMENT_PROCESS:
        return 1;
      case PATHS.PROJECT.ATS_EMPLOYMENT_MANAGEMENT:
        return 2;
    }
  };

  useEffect(() => {
    const index = getIndexMenu();
    setIndex(index);
    getProjectRecruiterConfirmationStatusFunc();
  }, []);
  const projectSource = projectDetailsOutStore
    ? projectDetailsOutStore
    : projectDetails;
  const { permissions = {} } = projectSource;
  const {
    isOwner = false,
    isCooperator = false,
    isMentor = false,
    isSuperUser = false,
    isHiringManager = false,
    isProjectOrganizationOwner = false,
    statusInProject = 0,
  } = permissions;
  const projectId = match.params.id;
  const isConnectedWithProject =
    isOwner ||
    (isCooperator && (statusInProject === 5 || statusInProject === 2)) ||
    isMentor ||
    isSuperUser ||
    isHiringManager ||
    isProjectOrganizationOwner;

  const isConnectedWithProjectExtendAts =
    isOwner ||
    isMentor ||
    isSuperUser ||
    isHiringManager ||
    isProjectOrganizationOwner;

  const menuProjectElements = [
    {
      name: t`atsSubMenu:recruitment`,
      url: PATHS.PROJECT.ATS.replace(':id', projectId),
      accessCandidate: isCooperator,
      accessRecruiter: isConnectedWithProject,
      show: true,
    },
    {
      name: t`atsSubMenu:employment`,
      url: PATHS.PROJECT.ATS_EMPLOYMENT_PROCESS.replace(':id', projectId),
      accessRecruiter: isConnectedWithProjectExtendAts,
      show: projectDetails.projectVersion === 3,
    },
    {
      name: t`atsSubMenu:management`,
      url: PATHS.PROJECT.ATS_EMPLOYMENT_MANAGEMENT.replace(':id', projectId),
      accessRecruiter: isConnectedWithProjectExtendAts,
      show: projectDetails.projectVersion === 3,
    },
  ];

  const subMenu = menuProjectElements.map((element, key) => {
    const projectSource = projectDetailsOutStore
      ? projectDetailsOutStore
      : projectDetails;

    /*Candidate access*/
    let getAccess = true;
    const { isRecruiter } = currentUser;
    if (!isRecruiter) {
      if (!element.accessCandidate) {
        getAccess = false;
      }
    }

    if (!element.show) {
      getAccess = false;
    }

    let disabled = false;
    if (projectSource.id === undefined) {
      disabled = true;
    }

    const { accessRecruiter } = element;
    let controlButtonDisabled = false;
    !accessRecruiter && !disabled
      ? (controlButtonDisabled = true)
      : (controlButtonDisabled = false);

    return (
      getAccess && (
        <Tab
          key={key}
          component={Link}
          to={element.url}
          disabled={controlButtonDisabled}
          style={controlButtonDisabled ? { display: 'none' } : null}
          label={element.name}
          classes={{
            root: !accessRecruiter ? classes.buttonInActive : classes.button,
            label: classes.label,
          }}
        />
      )
    );
  });

  const handleTabChange = (_, index) => {
    const projectSource = projectDetailsOutStore
      ? projectDetailsOutStore
      : projectDetails;

    switch (index) {
      case 0: {
        history.push(PATHS.PROJECT.ATS.replace(':id', projectSource.id));
        break;
      }
      case 1: {
        history.push(
          PATHS.PROJECT.ATS_EMPLOYMENT_PROCESS.replace(':id', projectSource.id)
        );
        break;
      }
      case 2: {
        history.push(
          PATHS.PROJECT.ATS_EMPLOYMENT_MANAGEMENT.replace(
            ':id',
            projectSource.id
          )
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Grid container className={classes.wrapper}>
      <Grid container>
        <Grid container className={classes.tabsWrapper}>
          <Tabs
            value={index}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator,
              scrollButtons: classes.scrollButtons,
              flexContainer: classes.scroll,
            }}
          >
            {subMenu}
          </Tabs>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
    projectDetails: store.projects.currentProject,
  };
};

const mapDispatchToProps = {
  getProjectRecruiterConfirmationStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withTranslation()(AtsSubMenu))));
