import {
  Typography,
  withStyles,
  Grid,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import EditIcon from 'shared/assets/img/icons/edit.png';
import { enums } from 'shared/enums';
import TextField from 'shared/newComponents/TextField';
import { EmploymentManagementHistory } from 'shared/services/employmentManagementHistory.service';
import { dateAdd } from 'shared/utils/date';
import { getTargetDate } from 'shared/utils/functions';
import Attachments from '../Attachments';
import { styles } from './Employed.styles';

const Employed = ({
  classes,
  t,
  selectedFullObject,
  dataAdded,
  project,
  showSaveBtn,
}) => {
  const [isEmploymentPeriodEdited, setIsEmploymentPeriodEdited] =
    useState(false);

  const formData = {
    candidateAssets: selectedFullObject
      ? selectedFullObject.candidate.employmentAsset
      : [],
    employmentStartDate: selectedFullObject.employmentStartDate
      ? selectedFullObject.employmentStartDate
      : '',
    employmentEndDate: selectedFullObject.employmentEndDate
      ? selectedFullObject.employmentEndDate
      : selectedFullObject.quarantineEndDate
      ? dateAdd(selectedFullObject.quarantineEndDate, 2).format('YYYY-MM-DD')
      : '',
  };

  const { targetStartDate, targetEndDate } = getTargetDate(
    selectedFullObject.candidate
  );

  const validationSchema = yup.object({
    employmentStartDate: yup
      .date()
      // .min(
      //   targetStartDate,
      //   `${t`employmentManagementDialog:dialog6.minBreakDate`} ${targetStartDate}`
      // )
      // .max(targetEndDate, `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${targetEndDate}`)
      .required(t`ats2ats3:validation.isRequired`),
    employmentEndDate: yup
      .date()
      // .min(
      //   targetStartDate,
      //   `${t`employmentManagementDialog:dialog6.minBreakDate`} ${targetStartDate}`
      // )
      // .max(targetEndDate, `${t`employmentManagementDialog:dialog6.maxBreakDate`} ${targetEndDate}`)
      .required(t`ats2ats3:validation.isRequired`),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: ({ employmentStartDate, employmentEndDate }) => {
      if (
        moment(employmentStartDate).isAfter(moment(employmentEndDate), 'day')
      ) {
        formik.setFieldError(
          'employmentStartDate',
          t`ats2ats3:validation.date`
        );
        return;
      }
      const body = {
        employmentStartDate,
        employmentEndDate,
        employmentManagementHistories: [
          {
            candidate: selectedFullObject.candidate,
            projectCandidate: selectedFullObject.projectCandidate,
          },
        ],
      };
      EmploymentManagementHistory.updateEmploymentManagementHistoryStage(
        body,
        enums.EMPLOYMENT_MANAGEMENT_HISTORY_STATUS.EMPLOYMENT_STATUS_EMPLOY
      )
        .then(() => {
          dataAdded();
        })
        .catch(error => console.log(error));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.bgBlue}>
        <div className={classes.container}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="body1">{t`employmentManagementDialog:dialog4.periodOfEmployment`}</Typography>
            </Grid>
            <Grid item>
              <img
                src={EditIcon}
                alt="edit"
                className={
                  isEmploymentPeriodEdited ? classes.hidden : classes.editBtn
                }
                onClick={() => setIsEmploymentPeriodEdited(true)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <PowerSettingsNew className={classes.powerOnBtn} />
                </Grid>
                <Grid item md={9}>
                  {isEmploymentPeriodEdited ? (
                    <TextField
                      type="date"
                      formik={formik}
                      name="employmentStartDate"
                      variant="transparent"
                    />
                  ) : (
                    <div className={classes.employmentStartDate}>
                      <Typography variant="h5" className={classes.date}>
                        {formik.values.employmentStartDate
                          ? formik.values.employmentStartDate
                          : t`assign:noData`}
                      </Typography>
                      {!formik.values.employmentStartDate && (
                        <FormHelperText
                          className={classes.error}
                        >{t`employmentManagementDialog:dialog2.validation.resignationEndDate`}</FormHelperText>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item className={classes.icon}>
                  <PowerSettingsNew className={classes.powerOffBtn} />
                </Grid>
                <Grid item md={9}>
                  {isEmploymentPeriodEdited ? (
                    <TextField
                      type="date"
                      formik={formik}
                      name="employmentEndDate"
                      variant="transparent"
                    />
                  ) : (
                    <Typography variant="h5" className={classes.date}>
                      {formik.values.employmentEndDate
                        ? formik.values.employmentEndDate
                        : t`assign:noData`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.extendBtnDiv}>
            {/*<Button*/}
            {/*  className={classes.extendBtn}*/}
            {/*>{t`employmentManagementDialog:dialog5.extendEmployment`}</Button>*/}
          </div>
        </div>
      </div>
      <div className={classes.docsContainer}>
        <Typography
          variant="body1"
          className={classes.attachments}
        >{t`employmentManagementDialog:dialog5.attachments`}</Typography>
        <Attachments
          showSaveBtn={showSaveBtn}
          formik={formik}
          project={project}
          attachmentList={enums.ATTACHMENT_EMPLOY_TYPE}
          initialCandidateValues={selectedFullObject.candidate}
        />
      </div>
      {showSaveBtn && (
        <div className={classes.saveBtnDiv}>
          <Button type="submit" className={classes.saveBtn}>
            {t`employmentManagementDialog:saveBtn`}
          </Button>
        </div>
      )}
    </form>
  );
};

export default withStyles(styles)(withTranslation()(Employed));
