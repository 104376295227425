import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import DataItemListItemProject from '../../../components/DataItemListItemProject';
import ProjectStepper from '../../../components/ProjectStepper';

const styles = theme => ({
  gridWrapper: {
    borderBottom: `1px solid ${theme.palette.jobllegroBorderGrey}`,
    textDecoration: 'none',
    transition: 'all .4s',
    '&:hover': {
      background: theme.palette.jobllegroUltraLightGrey,
    },
  },
  stepperWrapper: {
    display: 'flex',
    height: '100%',
    paddingRight: 24,
  },
});

export const DataItemProject = props => {
  const { data, classes, onlyOneColumn } = props;
  const screenRelation = onlyOneColumn ? 12 : 6;
  return (
    <Grid container spacing={0} className={classes.gridWrapper}>
      <Grid
        item
        xs={12}
        sm={screenRelation}
        md={screenRelation}
        lg={screenRelation}
      >
        <DataItemListItemProject data={data} />
      </Grid>
      {!onlyOneColumn && (
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className={classes.stepperWrapper}>
            <ProjectStepper
              startDate={data.projectStartDate}
              endDate={data.projectEndDate}
              variantMini={true}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

DataItemProject.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(DataItemProject);
