import { Grid, LinearProgress, withStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import { styles } from '../../../modules/candidate/NewCandidateDetails/components/CandidatePersonalCard/CandidatePersonalCard.styles';
import { Button, Snackbar } from '../../newComponents';
import { HelpService } from '../../services';
import NeedJob from './components/Job';
import NeedAccommodation from './components/NeedAccommodation';
import NeedTransport from './components/NeedTransport';

const NeedHelpModal = ({ t, classes, id }) => {
  const [isSnackbarSuccessOpen, setIsSnackbarSuccessOpen] = useState(false);
  const [isSnackbarSendSuccessOpen, setIsSnackbarSendSuccessOpen] =
    useState(false);

  const [lookingHelp, setLookingHelp] = useState(null);

  useEffect(() => {
    HelpService.getLookingHelpInfoId(id)
      .then(({ data }) => {
        setLookingHelp(data);
      })
      .catch(() => {});
  }, []);

  const sectionSaved = () => {
    setIsSnackbarSuccessOpen(true);
  };

  return (
    <>
      <Grid container className={classes.wrapper} style={{ width: '100%' }}>
        {lookingHelp ? (
          <NeedAccommodation
            lookingHelpData={lookingHelp}
            sectionSaved={sectionSaved}
          />
        ) : (
          <Grid container style={{ padding: '50px 0' }}>
            <LinearProgress style={{ width: '100%' }} />
          </Grid>
        )}
        {lookingHelp ? (
          <NeedTransport
            lookingHelpData={lookingHelp}
            sectionSaved={sectionSaved}
          />
        ) : (
          <Grid container style={{ padding: '50px 0' }}>
            <LinearProgress style={{ width: '100%' }} />
          </Grid>
        )}
        <NeedJob needHelp link={PATHS.CANDIDATE.NEW_EDIT.replace(':id', id)} />
        <Grid container justifyContent={'center'} style={{ marginTop: 80 }}>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              full={true}
              label={t`help:help.sendHelpInfo`}
              onClick={() => {
                setIsSnackbarSendSuccessOpen(true);
              }}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={isSnackbarSendSuccessOpen}
          onClose={() => setIsSnackbarSendSuccessOpen(false)}
          label={t`core:helpedRequestWasSend`}
        />
        <Snackbar
          open={isSnackbarSuccessOpen}
          onClose={() => setIsSnackbarSuccessOpen(false)}
          label={t`core:isSaved`}
        />
      </Grid>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = store => {
  return {};
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NeedHelpModal))
);
