export const styles = theme => ({
  imagesWrapper: {
    position: 'relative',
  },
  header: {
    maxHeight: 270,
    width: '100%',
    borderRadius: 10,
  },
  logoWrapper: {
    position: 'absolute',
    height: '100%',
    paddingLeft: 50,
  },
  logo: {
    borderRadius: '50%',
  },
  blueBackground: { backgroundColor: '#f1f7ff' },
  separator: {
    borderColor: 'rgba(213, 214, 216, 0.5)',
    margin: '16px 0',
    width: '100%',
  },
  divBg: theme.mixins.gutters({
    width: '100% !important',
    height: 500,
    margin: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  }),

  imgContainer: {
    display: 'flex',
    width: '100%',
  },
  selectedImageDialog: {
    width: '100%',
  },
  picture: {
    cursor: 'pointer',
    borderRadius: 20,
    padding: 9,
    top: 0,
    left: 0,
    height: 156,
    width: 221,
  },
  iframeVideoDialog: {
    borderWidth: 0,
  },
});
