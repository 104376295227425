import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import RecruiterMenu from './recruiterMenu/RecruiterMenu';

const styles = theme => ({
  rootPaper: theme.mixins.gutters({
    paddingTop: 0,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: 16,
    marginTop: 0,
    minHeight: 400,
  }),
  root: {
    marginTop: 16,
  },
  mock: {
    background: '#fff',
    zIndex: '1',
    height: 400,
    width: '100%',
    opacity: '0.8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 10px 10px',
  },
});

export class RecruiterProjectClosedView extends Component {
  render() {
    const { classes, t } = this.props;

    return (
      <Grid container alignItems="flex-start" spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
          <RecruiterMenu recruiterId={this.props.match.params.id} />
          <Paper className={classes.rootPaper} elevation={0}>
            <div className={classes.mock}>
              <Typography
                align="center"
                component="span"
                style={{
                  fontSize: 35,
                  fontWeight: '600',
                  transform: 'rotate(25deg)',
                }}
              >
                {t`candidate:closedProjects`}
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

RecruiterProjectClosedView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.node }).isRequired,
  }).isRequired,
};

export default withStyles(styles)(
  withTranslation()(RecruiterProjectClosedView)
);
