import { API } from '../constants';
import { ApiService } from './api.service';

export const CitySeoService = {
  getCitiesSeo() {
    return ApiService.get(API.GET.CITY_SEO.GET);
  },
  addCitySeo(data) {
    return ApiService.post(API.POST.CITY_SEO.POST, data);
  },
  replaceCitySeo(id, data) {
    return ApiService.put(API.PUT.CITY_SEO.PUT.replace(':id', id), data);
  },
  deleteCitySeo(id) {
    return ApiService.delete(API.DELETE.CITY_SEO.DELETE.replace(':id', id));
  },
};

export default CitySeoService;
