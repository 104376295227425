export const styles = () => ({
  container: { padding: '10px 0', width: '100%' },
  locationWrapper: {
    display: 'flex',
    width: '100%',
    gap: '30px',
    paddingBottom: '20px',
  },
  location: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& h6': {
      marginLeft: '10px',
      fontWeight: 600,
      fontSize: '1.25rem',
    },
  },
  numberField: {
    width: '100px',
  },

  '& form': {
    width: '100%',
  },
  datesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 35,
  },
  dates: { display: 'flex', alignItems: 'center' },
  date: { display: 'flex', alignItems: 'center' },
  editBtn: { cursor: 'pointer' },
  hidden: { visibility: 'hidden' },
  powerOnBtn: {
    background: '#01D38B',
    borderRadius: '50%',
    color: '#fff',
  },
  powerOffBtn: { background: '#F41313', borderRadius: '50%', color: '#fff' },
  periodOfStay: { marginBottom: 20 },
  quarantinePeriod: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectLoc: { margin: '20px 0' },
  pinIcon: {
    paddingRight: 8,
    height: 50,
  },
  mailDiv: { display: 'flex', alignItems: 'center', marginBottom: 10 },
  mail: { marginRight: 10 },
  mobileDiv: { display: 'flex', alignItems: 'center' },
  mobile: { marginRight: 10 },
  padding: { paddingLeft: 10 },
  bg: {
    background: '#FFF3E9',
    margin: '0 -15px 40px',
    width: '100%',
    padding: '20px 15px',
  },
  saveBtnDiv: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveBtn: {
    color: '#fff',
    width: 330,
    height: 60,
    background: '#01D38B',
    borderRadius: 30,
    '&:hover': {
      background: '#01D38B',
      opacity: 0.8,
    },
  },
  addLocationWrapper: { marginLeft: '-25px' },
  error: { color: '#f41010', position: 'absolute', top: '30px' },
  accommodationWrapper: { paddingBottom: '25px', cursor: 'pointer' },
  candidatesNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  including: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0',
  },
  includingCat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  personCount: {
    padding: '10px 15px',
  },
  locationHeader: {
    padding: '0 15px 10px',
  },
});
