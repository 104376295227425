import { Divider, Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ExpansionComponent from '../../../../../components/LayoutExpansionComponent';
import AtsSubMenu from '../../../ProjectAts/components/AtsSubMenu/AtsSubMenu';
import { handleEmploymentManagementFilter } from '../../../ProjectAts/utils/utils';
import ProjectOrderInfo from '../../../ProjectOrders/components/ProjectOrderInfo';
import ProjectMenu from '../../../projectMenu/ProjectMenu';
import EmploymentManagementTableFilter from '../EmploymentManagementTableFilter';
import EmploymentManagementTableView from '../EmploymentManagementTableView';
import { styles } from './EmploymentManagementViewContent.styles';

const EmploymentManagementViewContent = ({
  project,
  t,
  classes,
  loadProjectCandidatesEmploymentManagement,
  projectCandidatesEmploymentManagement,
  shouldLoadAccommodations,
  accommodations,
  projectAvailableToMove,
  isLoading,
}) => {
  const [
    filteredProjectCandidatesEmploymentManagement,
    setFilteredProjectCandidatesEmploymentManagement,
  ] = React.useState([]);

  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [selectedOrders, setSelectedOrders] = React.useState([]);

  const clearSelected = () => {
    setSelectedOrders([]);
    setIsCheckAll(false);
  };

  const [filters, setFilters] = React.useState({});

  const filterProjectOrders = () => {
    setFilteredProjectCandidatesEmploymentManagement(
      handleEmploymentManagementFilter(
        filters,
        projectCandidatesEmploymentManagement
      )
    );
  };

  React.useEffect(() => {
    filterProjectOrders();
    clearSelected();
  }, [filters, projectCandidatesEmploymentManagement]);

  return (
    <Grid container>
      <Grid
        container
        alignItems="flex-start"
        spacing={0}
        className={classes.wrapperForPadding}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
          <ProjectMenu title={t`projectOrderViewContent:header`} />
          <AtsSubMenu />
          <ProjectOrderInfo project={project} />
          <Paper className={classes.rootPaper} elevation={0}>
            <ExpansionComponent
              title={t`core:filters.name`}
              textOnTop={t`core:filters.description`}
            >
              <EmploymentManagementTableFilter
                projectCandidatesEmploymentManagement={
                  projectCandidatesEmploymentManagement
                }
                t={t}
                setFilters={setFilters}
                employmentManagement={true}
              />
            </ExpansionComponent>
            <Divider />
            <EmploymentManagementTableView
              isLoading={isLoading}
              projectAvailableToMove={projectAvailableToMove}
              selectedOrders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
              clearSelected={clearSelected}
              shouldLoadAccommodations={shouldLoadAccommodations}
              accommodations={accommodations}
              loadProjectCandidatesEmploymentManagement={
                loadProjectCandidatesEmploymentManagement
              }
              project={project}
              projectCandidatesEmploymentManagement={
                filteredProjectCandidatesEmploymentManagement
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation()(EmploymentManagementViewContent))
);
