import { API } from '../constants';
import { ApiService } from './api.service';

export const CandidateV2Service = {
  postCandidate: data => {
    return ApiService.post(API.POST.CANDIDATE_V2.CREATE, data);
  },
  editCandidate: (candidateId, data, params) => {
    return ApiService.put(
      API.PUT.CANDIDATE_V2.PUT.replace(':id', candidateId),
      data,
      params
    );
  },
  getCandidateV2: candidateId => {
    return ApiService.get(API.GET.CANDIDATE_V2.GET.replace(':id', candidateId));
  },
  getCandidateEmploymentHistoriesV2: candidateId => {
    return ApiService.get(
      API.GET.CANDIDATE_V2.GET_EMPLOYMENT_HISTORIES.replace(':id', candidateId)
    );
  },

  getCandidateV2Stash: () => {
    return ApiService.get(API.GET.CANDIDATE_V2.GET_CANDIDATE_STASH);
  },

  getCandidateAllProjects: candidateId => {
    return ApiService.get(API.GET.SUMMARY.GET, { candidateId: candidateId });
  },
  getMostPopularCities() {
    return ApiService.get(API.GET.USER.MOST_POPULAR_CITIES);
  },
};
