import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { ActionButtonJobllegroRoundedBig } from 'shared/utils/styledComponents';

const ForgotPasswordForm = props => {
  const { classes, t, onSubmit, handleSubmit, valid } = props;

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className={classes.card} elevation={10}>
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.boxTitle}
              gutterBottom
              component="h2"
            >
              {t('login:form.titlePassword')}
            </Typography>
            <Typography className={classes.boxText} component="p">
              {t('login:form.emailToResetPassword')}:
            </Typography>
            <Field
              className={classes.field}
              label={t('recruiter:field.emailLong')}
              name="email"
              type="email"
              component={TextField}
            />
          </CardContent>
          <CardActions className={classes.cardActions}>
            <ActionButtonJobllegroRoundedBig
              type="submit"
              name="login"
              disabled={!valid}
            >
              {t('login:form.emailToResetPasswordSubmit')}
            </ActionButtonJobllegroRoundedBig>
          </CardActions>
        </Card>
      </form>
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default ForgotPasswordForm;
