import { TablePagination } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  getTableRowPerPageFromStorage,
  setLocalStorage,
} from 'shared/utils/localStorage';
import Filtering from './Filtering';
import Sorting from './Sorting';

export class ElementsListAsync extends Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      timer: null,
      page: 0,
      orderBy: props.defaultSorting,
      filters: props.defaultFilters,
      rowsPerPage: props.rowsPerPage,
    };
  }
  getRowsPerPage = () =>
    getTableRowPerPageFromStorage(this.props.storageKey) ||
    this.state.rowsPerPage;

  handleChangePage = async (event, page) => {
    await this.setState({ page });
    await this.updateElements();
    if (this.props.scrollUp) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  handleChangeRowsPerPage = async event => {
    if (!this.props.storageKey) {
      await this.setState({ rowsPerPage: event.target.value });
    } else {
      setLocalStorage(this.props.storageKey, event.target.value);
    }
    await this.updateElements();
    if (this.props.scrollUp) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  getPagination = elements => {
    const { t } = this.props;
    return (
      <TablePagination
        component={'div'}
        count={this.props.elementsCount}
        rowsPerPage={this.getRowsPerPage()}
        page={this.state.page}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
        rowsPerPageOptions={[12, 24, 48, 96]}
        labelRowsPerPage={t('pagination.elementsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          t('pagination.pagesFromTo', { from, to, count })
        }
      />
    );
  };

  getSorting = () => {
    return (
      <Sorting
        orderByHandler={this.orderBy}
        orderBy={this.state.orderBy}
        sortingOptions={this.props.sortingOptions}
      />
    );
  };

  getFiltering = () => {
    return (
      <Filtering
        filterByHandler={this.filterBy}
        filters={this.state.filters}
        filteringOptions={this.props.filteringOptions}
      />
    );
  };

  orderBy = async (name, orderAsc) => {
    const { orderBy } = this.state;

    if (orderBy[name] === 'asc') {
      orderBy[name] = 'desc';
    } else if (orderBy[name] === 'desc') {
      delete orderBy[name];
    } else {
      orderBy[name] = 'asc';
    }
    if (this._isMounted) {
      await this.setState({
        orderBy,
      });
      this.updateElements();
    }
  };

  filterBy = filters => {
    _.each(filters, (value, key) => {
      (value === '' || value === null) && delete filters[key];
    });
    this.setState({
      filters,
      page: 0,
    });
    clearTimeout(this.state.timer);
    const timer = setTimeout(() => {
      this.updateElements();
    }, 500);
    this.setState({
      timer,
    });
  };

  updateElements = async () => {
    let { filters, orderBy, page } = this.state;
    const rowsPerPage = this.getRowsPerPage();
    if (this._isMounted) {
      await this.setState({ isLoading: true });
      await this.props.handleReloadElements({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        filters,
        orderBy,
        page,
        rowsPerPage,
      });
      await this.setState({ isLoading: false });
    }
  };

  async componentDidUpdate(prevProps) {
    if (prevProps && prevProps.storageKey !== this.props.storageKey) {
      this.setState({
        rowsPerPage: this.props.rowsPerPage,
      });
    }
    let { page } = this.state;
    let { elementsCount } = this.props;
    const rowsPerPage = this.getRowsPerPage();
    if (this._isMounted) {
      if (elementsCount > 0 && page * rowsPerPage >= elementsCount) {
        await this.setState({
          page: page - 1,
        });
        this.updateElements();
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const { load } = this.props;

    if (load !== false && this._isMounted) {
      this.updateElements();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { elements, load } = this.props;

    if (load && !this.state.isLoading) {
      this.updateElements();
    }

    const content = elements.map((element, index) => {
      return this.props.getContent(element, index);
    });

    const pagination = this.getPagination(elements);
    const filtering = this.getFiltering();
    const sorting = this.getSorting();

    return this.props.getWrapper({ content, pagination, sorting, filtering });
  }
}

ElementsListAsync.defaultProps = {
  sortingOptions: [],
  filteringOptions: [],
  defaultSorting: {},
  defaultFilters: {},
  rowsPerPage: 12,
  elementsCount: 0,
  scrollUp: false,
};

ElementsListAsync.propTypes = {
  getWrapper: PropTypes.func,
  handleReloadElements: PropTypes.func.isRequired,
  sortingOptions: PropTypes.array,
  filteringOptions: PropTypes.array,
  defaultSorting: PropTypes.object,
  defaultFilters: PropTypes.object,
  rowsPerPage: PropTypes.number,
};

export default withTranslation()(ElementsListAsync);
