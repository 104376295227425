import {
  IconButton,
  TextField as MuiTextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { PermPhoneMsg as PermPhoneMsgIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, reset } from 'redux-form';
// import { TextField } from 'redux-form-material-ui';
import { sendHelpdeskMessages } from 'shared/services/helpdeskService';
import { showSnackbarMessage } from 'shared/store/messages/actions';
import { ButtonJobllegrov1 } from 'shared/utils/styledComponents';
import ConfirmationDialog from './LayoutConfirmationDialog/';
import validate from './validate/validateHelpDesk';

const styles = theme => ({
  avatar: {
    position: 'fixed',
    left: 10,
    bottom: 10,
    width: 50,
    height: 50,
    color: '#fff',
    transition: 'all .4s',
    background: theme.palette.jobllegroBlue,
    zIndex: 2000,
    '&:hover': {
      background: theme.palette.jobllegroSuccess,
      transform: 'scale(1.5) translateY(-10px) translateX(10px)',
    },
  },
  icon: {
    fontSize: 22,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
  },
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <MuiTextField
    label={label}
    error={touched && Boolean(error)}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export class HelpDesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationOpened: false,
    };
  }

  handleConfirmationClose = () => {
    this.setState({
      confirmationOpened: false,
    });
  };

  handleConfirmationOpen = () => {
    this.setState({
      confirmationOpened: true,
    });
  };

  handleSubmitForm = async values => {
    console.log(this.props);
    const { t } = this.props;
    try {
      this.props.sendHelpdeskMessages(values);
      this.props.reset();
      this.handleConfirmationClose();
      this.props.showSnackbarMessage(t`core:msgSent`);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { confirmationOpened } = this.state;

    let { classes, valid, pristine, submitting, handleSubmit, currentUser, t } =
      this.props;

    return currentUser.id ? (
      <React.Fragment>
        {!confirmationOpened && (
          <IconButton
            onClick={this.handleConfirmationOpen}
            className={classes.avatar}
          >
            <PermPhoneMsgIcon className={classes.icon} />
          </IconButton>
        )}
        <ConfirmationDialog
          open={confirmationOpened}
          onClose={this.handleConfirmationClose}
          titleText={t`helpDesk:contactForm`}
          cancelButtonText={t`helpDesk:cancel`}
        >
          <form onSubmit={handleSubmit(this.handleSubmitForm)}>
            <Typography
              variant="body1"
              align="left"
              style={{ marginBottom: 10 }}
            >
              {t`helpDesk:gotQuestions`}
            </Typography>
            <Field
              label={t`helpDesk:enterMsgTitle`}
              name="title"
              inputProps={{ maxLength: 255 }}
              component={renderTextField}
              fullWidth
            />
            <Field
              label={t`helpDesk:enterMsg`}
              name="message"
              component={renderTextField}
              fullWidth
              multiline={true}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <ButtonJobllegrov1
                type="submit"
                color="primary"
                disabled={pristine || submitting || !valid}
              >
                {t`helpDesk:send`}
              </ButtonJobllegrov1>
            </div>
          </form>
        </ConfirmationDialog>
      </React.Fragment>
    ) : null;
  }
}

const afterSubmit = (result, dispatch) => {
  if (result && result.status === 200) {
    dispatch(reset('helpDeskForm'));
  }
};

const HelpDeskForm = reduxForm({
  form: 'helpDeskForm',
  validate,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(HelpDesk);

HelpDesk.propTypes = {
  classes: PropTypes.object.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    currentUser: store.auth.currentUser,
  };
};

const mapDispatchToProps = {
  sendHelpdeskMessages,
  showSnackbarMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(HelpDeskForm)));
