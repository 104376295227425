import { withStyles } from '@material-ui/core/styles';
import LayoutSnackBar from './LayoutSnackBar';

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.jobllegroSuperLightGrey,
    color: theme.palette.jobllegroGrey,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(),
    fontSize: 20,
    color: theme.palette.jobllegroBlue,
  },
});

export default withStyles(styles)(LayoutSnackBar);
