import axios from 'axios';
import { API, API_URL } from '../constants';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getTempSkillsWithLimit = (limit, offset, params) => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET_SEARCH,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      limit: limit,
      offset: offset,
      ...params,
    },
  });
};

export const getTempSkills = () => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const deleteTempSkill = id => {
  return axios({
    method: 'DELETE',
    url: API_URL + API.DELETE.TEMP_SKILL.DELETE_TEMP.replace(':id', id),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const editTempSkill = (skillId, values) => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.TEMP_SKILL.EDIT_TEMP.replace(':id', skillId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const editTempSkills = values => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.TEMP_SKILL.CONCAT_AND_APPROVE,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const approveTempSkill = values => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.TEMP_SKILL.APPROVE_TEMP_SKILL,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const concatTempSkill = (skillId, values) => {
  return axios({
    method: 'PUT',
    url: API_URL + API.PUT.TEMP_SKILL.CONCAT_SKILL.replace(':id', skillId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const getTempProjectSkill = skillId => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET_PROJECT_SKILL.replace(':id', skillId),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const getCompetenceTemp = skillId => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET_COMPETENCE.replace(':id', skillId),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const getTempUserSkill = skillId => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET_USER_SKILL.replace(':id', skillId),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const getTempProjectsSkills = params => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET_PROJECTS_SKILLS,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      ...params,
    },
  });
};

export const getTempUsersSkills = params => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET_USERS_SKILLS,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      ...params,
    },
  });
};

export const getTempSkillsIds = params => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.TEMP_SKILL.GET,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      ...params,
    },
  });
};
