import { Drawer } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import 'shared/assets/css/animationsComponents.css';
import LanguagesChange from '../LanguagesChange/';
import LeftMenu from '../LayoutLeftMenu/';

const LayoutLeftDrawer = props => {
  const { classes, isOpened, handleDrawerClose } = props;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !isOpened && classes.drawerPaperClose
        ),
      }}
      open={isOpened}
    >
      <div>
        <div className={classes.toolbar}>
          <LeftMenu handleDrawerClose={handleDrawerClose} isOpened={isOpened} />
        </div>
        <div className={classes.LanguagesChangeWrapper}>
          <LanguagesChange />
        </div>
      </div>
    </Drawer>
  );
};

LayoutLeftDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default LayoutLeftDrawer;
