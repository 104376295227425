import {
  Grid,
  Tooltip,
  Typography,
  FormControlLabel,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  TextField,
  Select,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
// import { Checkbox, Select, TextField } from 'redux-form-material-ui';
import {
  showSnackbarInformationMessage,
  hideSnackbarInformationMessage,
} from 'shared/store/messages/actions';
import { intNormalization } from 'shared/utils/normalizers';
import ProjectFormCalculator from './ProjectFormCalculator';

const styles = theme => ({
  textHeading: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#53a3fc',
    fontSize: '24px',
  },
  textSubHeading: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#53a3fc',
    fontSize: '18px',
  },
  inputField: {
    width: 'calc(100% - 10px)',
  },
  toolTip: {
    background: theme.palette.jobllegroBlue,
    color: '#fff',
  },
  toolTipSpan: {
    fontSize: 14,
  },
  formControl: {
    minWidth: '100%',
  },
  information: {
    color: theme.palette.jobllegroBlue,
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
  },
});

export const ProjectFormStepFour = props => {
  let {
    classes,
    t,
    changeSectionValidationVisibility,
    validationSectionShow,
    formValues,
    isLastStepIsBlockedByRecruiters,
  } = props;

  // const showMe = (text) => {
  //     props.showSnackbarInformationMessage(text);
  // };
  //
  // const hideMe = () => {
  //     props.hideSnackbarInformationMessage();
  // };

  const getScopeSelect = () => {
    if (isLastStepIsBlockedByRecruiters) {
      return (
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormRecrutationServices'
          )}
        >
          <InputLabel htmlFor="visibility">
            {t('project:field.chooseRecrutationServices') + ' *'}
          </InputLabel>
          <Field
            name="scope"
            component={Select}
            type="number"
            disabled={true}
            onChange={(event, index, value) =>
              changeSectionValidationVisibility(event, index, value)
            }
          >
            <MenuItem value={1}>
              {t('project:field.recrutationServicesSearchAndSelection')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.recrutationServicesValidation')}
            </MenuItem>
          </Field>
        </FormControl>
      );
    } else {
      return (
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormRecrutationServices'
          )}
        >
          <InputLabel htmlFor="visibility">
            {t('project:field.chooseRecrutationServices') + ' *'}
          </InputLabel>
          <Field
            name="scope"
            component={Select}
            type="number"
            onChange={(event, index, value) =>
              changeSectionValidationVisibility(event, index, value)
            }
          >
            <MenuItem value={2}>
              {t('project:field.recrutationServicesValidation')}
            </MenuItem>
          </Field>
        </FormControl>
      );
    }
  };

  const getTooltipSpan = text => {
    return <span className={classes.toolTipSpan}> {text} </span>;
  };

  return (
    <React.Fragment>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          {t('project:field.projectStartDateLong')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} xl={6} align="center">
        <Field
          className={classes.inputField}
          label={t('project:field.projectStartDate') + ' *'}
          type="date"
          name="projectStartDate"
          defaultValue="1990-01-01"
          component={TextField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isLastStepIsBlockedByRecruiters}
        />
      </Grid>
      <Grid item xs={12} sm={6} xl={6} align="center">
        <Field
          className={classes.inputField}
          label={t('project:field.projectEndDate') + ' *'}
          type="date"
          name="projectEndDate"
          defaultValue="1990-01-01"
          component={TextField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isLastStepIsBlockedByRecruiters}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Field
          label={t('project:field.revenue') + ' *'}
          name="revenue"
          inputProps={{ min: 0, max: 999999999 }}
          disabled={isLastStepIsBlockedByRecruiters}
          component={TextField}
          fullWidth
          type="number"
          normalize={intNormalization}
        />
        <FormControlLabel
          control={
            <Field
              name="isRevenuePublic"
              component={Checkbox}
              disabled={isLastStepIsBlockedByRecruiters}
            />
          }
          label={t('project:field.isRevenuePublic')}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          {t('project:field.recrutationServices')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {getScopeSelect()}
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          {t('project:field.calculator')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCalcCandidateExperience'
          )}
        >
          <InputLabel htmlFor="calcCandidateExperience">
            {t('project:field.calcCandidateExperience') + ' *'}
            <Tooltip
              title={getTooltipSpan(
                t('project:show.calcCandidateExperienceInfo')
              )}
            >
              <span className={classes.information}>(?)</span>
            </Tooltip>
          </InputLabel>
          <Field
            name="calcCandidateExperience"
            component={Select}
            type="number"
            disabled={isLastStepIsBlockedByRecruiters}
          >
            <MenuItem value={1}>
              {t('project:field.calcCandidateExperienceOpt1')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.calcCandidateExperienceOpt2')}
            </MenuItem>
            <MenuItem value={3}>
              {t('project:field.calcCandidateExperienceOpt3')}
            </MenuItem>
            <MenuItem value={4}>
              {t('project:field.calcCandidateExperienceOpt4')}
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCalcCandidateWarranty'
          )}
        >
          <InputLabel htmlFor="calcCandidateWarranty">
            {t('project:field.calcCandidateWarranty') + ' *'}
            <Tooltip
              title={getTooltipSpan(
                t('project:show.calcCandidateWarrantyInfo')
              )}
            >
              <span className={classes.information}>(?)</span>
            </Tooltip>
          </InputLabel>
          <Field
            name="calcCandidateWarranty"
            component={Select}
            type="number"
            disabled={isLastStepIsBlockedByRecruiters}
          >
            <MenuItem value={1}>
              {t('project:field.calcCandidateWarrantyOpt1')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.calcCandidateWarrantyOpt2')}
            </MenuItem>
            <MenuItem value={3}>
              {t('project:field.calcCandidateWarrantyOpt3')}
            </MenuItem>
            <MenuItem value={4}>
              {t('project:field.calcCandidateWarrantyOpt4')}
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCalcNumberOfRecommendations'
          )}
        >
          <InputLabel htmlFor="calcNumberOfRecommendations">
            {t('project:field.calcNumberOfRecommendations') + ' *'}
            <Tooltip
              title={getTooltipSpan(
                t('project:show.calcNumberOfRecommendationsInfo')
              )}
            >
              <span className={classes.information}>(?)</span>
            </Tooltip>
          </InputLabel>
          <Field
            name="calcNumberOfRecomendations"
            component={Select}
            type="number"
            disabled={isLastStepIsBlockedByRecruiters}
          >
            <MenuItem value={1}>
              {t('project:field.calcNumberOfRecommendationsOpt1')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.calcNumberOfRecommendationsOpt2')}
            </MenuItem>
            <MenuItem value={3}>
              {t('project:field.calcNumberOfRecommendationsOpt3')}
            </MenuItem>
            <MenuItem value={4}>
              {t('project:field.calcNumberOfRecommendationsOpt4')}
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCalcPaymentDeadline'
          )}
        >
          <InputLabel htmlFor="calcPaymentDeadline">
            {t('project:field.calcPaymentDeadline') + ' *'}
            <Tooltip
              title={getTooltipSpan(t('project:show.calcPaymentDeadlineInfo'))}
            >
              <span className={classes.information}>(?)</span>
            </Tooltip>
          </InputLabel>
          <Field
            name="calcPaymentDeadline"
            component={Select}
            type="number"
            disabled={isLastStepIsBlockedByRecruiters}
          >
            <MenuItem value={1}>
              {t('project:field.calcPaymentDeadlineOpt1')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.calcPaymentDeadlineOpt2')}
            </MenuItem>
            <MenuItem value={3}>
              {t('project:field.calcPaymentDeadlineOpt3')}
            </MenuItem>
            <MenuItem value={4}>
              {t('project:field.calcPaymentDeadlineOpt4')}
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCalcSearchTime'
          )}
        >
          <InputLabel htmlFor="calcSearchTime">
            {t('project:field.calcSearchTime') + ' *'}
            <Tooltip
              title={getTooltipSpan(t('project:show.calcSearchTimeInfo'))}
            >
              <span className={classes.information}>(?)</span>
            </Tooltip>
          </InputLabel>
          <Field
            name="calcSearchTime"
            component={Select}
            type="number"
            disabled={isLastStepIsBlockedByRecruiters}
          >
            <MenuItem value={1}>
              {t('project:field.calcSearchTimeOpt1')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.calcSearchTimeOpt2')}
            </MenuItem>
            <MenuItem value={3}>
              {t('project:field.calcSearchTimeOpt3')}
            </MenuItem>
            <MenuItem value={4}>
              {t('project:field.calcSearchTimeOpt4')}
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCalcServiceCompletionDate'
          )}
        >
          <InputLabel htmlFor="calcServiceCompletionDate">
            {t('project:field.calcServiceCompletionDate') + ' *'}
            <Tooltip
              title={getTooltipSpan(
                t('project:show.calcServiceCompletionDateInfo')
              )}
            >
              <span className={classes.information}>(?)</span>
            </Tooltip>
          </InputLabel>
          <Field
            name="calcServiceCompletionDate"
            component={Select}
            type="number"
            disabled={isLastStepIsBlockedByRecruiters}
          >
            <MenuItem value={1}>
              {t('project:field.calcServiceCompletionDateOpt1')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.calcServiceCompletionDateOpt2')}
            </MenuItem>
            <MenuItem value={3}>
              {t('project:field.calcServiceCompletionDateOpt3')}
            </MenuItem>
            <MenuItem value={4}>
              {t('project:field.calcServiceCompletionDateOpt4')}
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classNames(
            classes.formControl,
            'newProjectFormCalcUpfrontPayment'
          )}
        >
          <InputLabel htmlFor="calcUpfrontPayment">
            {t('project:field.calcUpfrontPayment') + ' *'}
            <Tooltip
              title={getTooltipSpan(t('project:show.calcUpfrontPaymentInfo'))}
            >
              <span className={classes.information}>(?)</span>
            </Tooltip>
          </InputLabel>
          <Field
            name="calcUpfrontPayment"
            component={Select}
            type="number"
            disabled={isLastStepIsBlockedByRecruiters}
          >
            <MenuItem value={1}>
              {t('project:field.calcUpfrontPaymentOpt1')}
            </MenuItem>
            <MenuItem value={2}>
              {t('project:field.calcUpfrontPaymentOpt2')}
            </MenuItem>
            <MenuItem value={3}>
              {t('project:field.calcUpfrontPaymentOpt3')}
            </MenuItem>
            <MenuItem value={4}>
              {t('project:field.calcUpfrontPaymentOpt4')}
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          <Trans i18nKey={'project:field.recruitersSalary'}>
            x<span style={{ fontSize: 12 }}>x</span>
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12} md={6} align="left">
            <Tooltip
              classes={{
                tooltip: classes.toolTip,
              }}
              id="submitted_payment"
              title={t('project:field.revenueSubmittedTooltip')}
              placement="bottom"
            >
              <div>
                <Field
                  label={t('project:field.revenueSubmitted')}
                  name="revenueSubmitted"
                  className={classes.inputField}
                  component={TextField}
                  inputProps={{
                    min: isLastStepIsBlockedByRecruiters
                      ? props.initialValues
                        ? props.initialValues.revenueSubmitted
                        : 0
                      : 0,
                    max: 999999999,
                  }}
                  type="number"
                  fullWidth
                  normalize={intNormalization}
                />
              </div>
            </Tooltip>
            <ProjectFormCalculator
              formValues={formValues}
              label={t('project:field.suggestedPaymentCandidateSubmitted')}
              multiplier={0.2}
              singleCandidate={true}
            />
            <FormControlLabel
              control={
                <Field
                  name="isRevenueSubmittedPublic"
                  component={Checkbox}
                  disabled={isLastStepIsBlockedByRecruiters}
                />
              }
              label={t('project:field.isRevenueSubmittedPublic')}
            />
          </Grid>
          <Grid item xs={12} md={6} align="left">
            <Tooltip
              classes={{
                tooltip: classes.toolTip,
              }}
              id="winner_payment"
              title={t('project:field.revenueWinnerTooltip')}
              placement="bottom"
            >
              <div>
                <Field
                  label={t('project:field.revenueWinner')}
                  name="revenueWinner"
                  className={classes.inputField}
                  component={TextField}
                  inputProps={{
                    min: isLastStepIsBlockedByRecruiters
                      ? props.initialValues
                        ? props.initialValues.revenueWinner
                        : 0
                      : 0,
                    max: 999999999,
                  }}
                  type="number"
                  fullWidth
                  normalize={intNormalization}
                />
              </div>
            </Tooltip>
            <ProjectFormCalculator
              formValues={formValues}
              label={t('project:field.suggestedPaymentCandidateWinner')}
              multiplier={1}
              singleCandidate={true}
            />
            <FormControlLabel
              control={
                <Field
                  name="isRevenueWinnerPublic"
                  component={Checkbox}
                  disabled={isLastStepIsBlockedByRecruiters}
                />
              }
              label={t('project:field.isRevenueWinnerPublic')}
            />
          </Grid>
        </Grid>
      </Grid>
      {validationSectionShow === 2 && (
        <React.Fragment>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              className={classes.textHeading}
            >
              <Trans i18nKey={'project:field.mentorsSalary'}>
                x<span style={{ fontSize: 12 }}>x</span>
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
            <Field
              label={t('project:field.mentorFee')}
              name="mentorFee"
              component={TextField}
              inputProps={{
                min: isLastStepIsBlockedByRecruiters
                  ? props.initialValues
                    ? props.initialValues.mentorFee
                    : 0
                  : 0,
                max: 999999999,
              }}
              type="number"
              fullWidth
              normalize={intNormalization}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              className={classes.textSubHeading}
            >
              {t('project:field.suggestedPaymentForRecrutationProject')}
            </Typography>
            <ProjectFormCalculator
              formValues={formValues}
              label={t('project:field.suggestedMentorValue')}
              multiplier={0.15}
            />
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          className={classes.textHeading}
        >
          <Trans i18nKey={'project:field.projectValue'}>
            x<span style={{ fontSize: 12 }}>x</span>
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
        <ProjectFormCalculator
          formValues={formValues}
          label={t('project:field.predictedProjectValue')}
          value={
            (validationSectionShow === 2 ? formValues['mentorFee'] : 0) +
            formValues['candidateCount'] * formValues['revenueWinner'] +
            formValues['candidateCount'] *
              formValues['calcNumberOfRecomendations'] *
              formValues['revenueSubmitted']
          }
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
        <ProjectFormCalculator
          formValues={formValues}
          label={t('project:field.predictedOnSugestedProjectValue')}
          multiplier={
            1 +
            formValues['calcNumberOfRecomendations'] * 0.2 +
            (validationSectionShow === 2 ? 0.15 : 0)
          }
        />
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.isEdited ? state.projects.currentProject : null,
  };
};

const mapDispatchToProps = {
  showSnackbarInformationMessage,
  hideSnackbarInformationMessage,
};

ProjectFormStepFour.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProjectFormStepFour)));
