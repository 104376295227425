import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  radioGroup: {
    flexDirection: 'row',
  },
  formControl: {
    flexDirection: 'row',
    marginRight: 15,
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
    },
  },
  formRoot: {
    width: '100%',
    marginRight: 15,
    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
    },
  },
  formRootSelect: {
    minWidth: '285px',
    [theme.breakpoints.only('xs')]: {
      minWidth: 300,
    },
  },
  searchIcon: {
    display: 'flex',
    'flex-direction': 'column-reverse',
  },
});

export class Sorting extends Component {
  static defaultProps = {
    filterByHandler: () => false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  handlePressKey = async event => {
    if (event.key === 'Enter' && this.props.searchIcon) {
      this.props.onSearchHandler();
    }
  };

  handleChange = async event => {
    if (event.target.type === 'checkbox' && !event.target.checked) {
      await this.setState({ [event.target.name]: null });
    } else {
      await this.setState({ [event.target.name]: event.target.value });
    }
    this.props.filterByHandler(this.state);
  };

  getContentSelect = filter => {
    const { classes } = this.props;
    let values = filter.values.map(value => (
      <MenuItem value={value.key} key={value.key}>
        {value.name}
      </MenuItem>
    ));
    return (
      <Grid xs={12} sm={6} md={4} lg={3} xl={3} item key={filter.key}>
        <FormControl className={classes.formControl}>
          <InputLabel>{filter.name}</InputLabel>
          <Select
            value={this.state[filter.key] || ''}
            name={filter.key}
            onChange={this.handleChange}
            classes={{
              root: classes.formRootSelect,
            }}
          >
            <MenuItem key="defaultFilterValue" value="">
              {' '}
              ---{' '}
            </MenuItem>
            {values}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  getContentMultiselect = filter => {
    const { classes } = this.props;
    let values = filter.values.map(value => (
      <MenuItem value={value.key} key={value.key}>
        {value.name}
      </MenuItem>
    ));
    return (
      <Grid xs={12} sm={6} md={4} lg={3} xl={3} item key={filter.key}>
        <FormControl className={classes.formControl}>
          <InputLabel>{filter.name}</InputLabel>
          <Select
            multiple
            value={this.state[filter.key] || []}
            name={filter.key}
            onChange={this.handleChange}
            classes={{
              root: classes.formRootSelect,
            }}
          >
            <MenuItem key="defaultFilterValue" value="">
              {' '}
              ---{' '}
            </MenuItem>
            {values}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  getContentText = filter => {
    const { classes } = this.props;
    return (
      <Grid xs={12} sm={6} md={4} lg={3} xl={2} item key={filter.key}>
        <FormControl className={classes.formControl}>
          <TextField
            value={this.state[filter.key] || ''}
            label={filter.name}
            name={filter.key}
            placeholder={filter.placeholder || null}
            onChange={this.handleChange}
            classes={{
              root: classes.formRoot,
            }}
            onKeyPress={this.handlePressKey}
          />
        </FormControl>
      </Grid>
    );
  };
  searchIcon = () => {
    const { classes, onSearchHandler } = this.props;
    return (
      <Grid
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={2}
        item
        className={classes.searchIcon}
      >
        <SearchIcon onClick={onSearchHandler} />
      </Grid>
    );
  };
  getContentBool = filter => {
    const { classes, t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={filter.key}>
        <FormLabel component="legend">{filter.name}</FormLabel>
        <RadioGroup
          className={classes.radioGroup}
          name={filter.key}
          value={this.state[filter.key] || filter.default || undefined}
          onChange={this.handleChange}
        >
          <FormControlLabel control={<Radio />} label={t('general.all')} />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={t('general.yes')}
          />
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={t('general.no')}
          />
        </RadioGroup>
      </Grid>
    );
  };

  getContentCheckbox = filter => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={filter.key}>
        <FormControlLabel
          control={
            <Checkbox
              value={filter.value}
              defaultChecked={filter.default || false}
              name={filter.key}
              onChange={this.handleChange}
            />
          }
          label={filter.name}
        />
      </Grid>
    );
  };

  getContentNumber = filter => {
    const { classes, t } = this.props;

    return (
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={filter.key}>
        <FormControl className={classes.formControl}>
          <TextField
            value={this.state[filter.key + '-from'] || ''}
            label={filter.name + ` ${t('project:field.minSalary')}`}
            name={filter.key + '-from'}
            type={'number'}
            onChange={this.handleChange}
            classes={{
              root: classes.formRoot,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            value={this.state[filter.key + '-to'] || ''}
            label={filter.name + ` ${t('project:field.maxSalary')}`}
            name={filter.key + '-to'}
            type={'number'}
            onChange={this.handleChange}
            classes={{
              root: classes.formRoot,
            }}
          />
        </FormControl>
      </Grid>
    );
  };

  getContent = (filter, type) => {
    if (type === filter.type) {
      switch (filter.type) {
        case 'number':
          return this.getContentNumber(filter);
        case 'select':
          return this.getContentSelect(filter);
        case 'multiselect':
          return this.getContentMultiselect(filter);
        case 'boolean':
          return this.getContentBool(filter);
        case 'text':
          return this.getContentText(filter);
        case 'checkbox':
          return this.getContentCheckbox(filter);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  componentDidMount() {
    let filters = [];
    this.props.filteringOptions.forEach(filter => {
      return filter.key
        ? filter.type === 'number'
          ? ((filters[filter.key + '-from'] = null),
            (filters[filter.key + '-to'] = null))
          : (filters[filter.key] = null)
        : null;
    });
    filters = _.merge(filters, this.props.filters);
    this.setState({ ...filters });
  }

  render() {
    const { filteringOptions, searchIcon } = this.props;

    const contentNumber = filteringOptions.map(filter =>
      this.getContent(filter, 'number')
    );
    const contentText = filteringOptions.map(filter =>
      this.getContent(filter, 'text')
    );
    const contentRadio = filteringOptions.map(filter =>
      this.getContent(filter, 'boolean')
    );
    const contentCheckbox = filteringOptions.map(filter =>
      this.getContent(filter, 'checkbox')
    );
    const contentSelect = filteringOptions.map(filter =>
      this.getContent(filter, 'select')
    );
    const contentMultiselect = filteringOptions.map(filter =>
      this.getContent(filter, 'multiselect')
    );

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            {contentRadio}
            {contentCheckbox}
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            {contentText}
            {searchIcon ? this.searchIcon() : null}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>{contentSelect}</Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>{contentMultiselect}</Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>{contentNumber}</Grid>
        </Grid>
      </Grid>
    );
  }
}

Sorting.propTypes = {
  filterByHandler: PropTypes.func,
  filteringOptions: PropTypes.array,
  filters: PropTypes.object,
};

export default withStyles(styles)(withTranslation()(Sorting));
