import axios from 'axios';
import { API, API_URL } from '../constants';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getSearchData = query => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.SEARCH.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
      params: { q: query },
    })
      .then(response => {
        const { data, status } = response;
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};
