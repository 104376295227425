import React from 'react';

const ScrollPoint = ({ refForward }) => (
  <div
    ref={refForward}
    key={'projectCreator:step2.title'}
    style={{
      paddingTop: 64,
      marginTop: -64,
    }}
  />
);

export default ScrollPoint;
