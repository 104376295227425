import axios from 'axios';
import { API, API_URL } from '../constants';
import { getAllCompetenceKinds } from '../store/competenceKinds/actions';
import { getLocalStorageByKey } from '../utils/localStorage';

export const getCompetenceKindsWithLimit = (limit, offset, params) => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.COMPETENCE_KIND.GET_SEARCH,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    params: {
      limit: limit,
      offset: offset,
      ...params,
    },
  });
};

export const getCompetenceKinds = () => {
  return async dispatch => {
    return await axios({
      method: 'GET',
      url: API_URL + API.GET.COMPETENCE_KIND.GET,
      headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
    })
      .then(response => {
        const { data, status } = response;
        dispatch(getAllCompetenceKinds(data));
        return { data, status };
      })
      .catch(error => {
        const { status } = error.response;
        return { data: {}, status };
      });
  };
};

export const getCompetenceKindsWithoutDispatch = () => {
  return axios({
    method: 'GET',
    url: API_URL + API.GET.COMPETENCE_KIND.GET,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const deleteCompetenceKind = id => {
  return axios({
    method: 'DELETE',
    url: API_URL + API.DELETE.COMPETENCE_KIND.DELETE.replace(':id', id),
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const editCompetenceKind = (competenceKindId, values) => {
  return axios({
    method: 'PUT',
    url:
      API_URL + API.PUT.COMPETENCE_KIND.EDIT.replace(':id', competenceKindId),
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};

export const addCompetenceKind = values => {
  return axios({
    method: 'POST',
    url: API_URL + API.POST.COMPETENCE_KIND.PUT,
    data: values,
    headers: { Authorization: 'Bearer ' + getLocalStorageByKey('token') },
  });
};
