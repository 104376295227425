import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'config';
import EditIcon from 'shared/assets/img/icons/candidate-show/edit.svg';
import { enums } from '../../../enums';
import { getAssetUrl } from '../../../services';
import { getEnumItemName } from '../../../utils/assets';
import { setLocalStorage } from '../../../utils/localStorage';
import { styles } from './InfoAboutCandidate.styles';

const InfoAboutCandidate = ({ project, candidates, history, classes, t }) => {
  const redirectToEditPage = candidate => {
    setLocalStorage('projectIdEmploymentProcess', project.id.toString());
    history.push(PATHS.CANDIDATE.NEW_EDIT.replace(':id', candidate.id));
    window.location.reload();
  };

  const content = candidates.map(({ candidate }) => {
    const {
      firstName,
      lastName,
      passportNumber,
      arrivalDateEmploymentCountry,
      isForeignerInEmploymentArea,
      residenceTitle,
      passportAsset,
      id,
    } = candidate;

    const isSetAllDataForEmploymentProcess =
      passportNumber !== null && passportAsset.length;

    return (
      <Grid key={id}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              alignItems={'center'}
              justifyContent={'space-around'}
            >
              <Grid item xs={10} sm={10} md={10}>
                <Typography
                  className={classes.bold}
                >{`${firstName} ${lastName}`}</Typography>
                <Typography className={classes.fontError}>
                  {!isSetAllDataForEmploymentProcess
                    ? t`ats1ats2:setMissingData`
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  onClick={() => redirectToEditPage(candidate)}
                >
                  <img
                    src={EditIcon}
                    height={50}
                    alt={'edit'}
                    className={classes.editIcon}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <Typography>{t`ats1ats2:passportNumber`}</Typography>
          </Grid>
          <Grid item>
            <Typography
              className={passportNumber ? classes.bold : classes.fontError}
            >
              {passportNumber ? passportNumber : t`ats1ats2:notReported`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <Typography>{t`ats1ats2:passportScan`}</Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            {passportAsset.length > 0 ? (
              passportAsset.map(asset => (
                <a
                  href={getAssetUrl(asset.id)}
                  download={asset.filename}
                  key={asset.id}
                  className={classes.link}
                >
                  <Typography className={classes.asset}>
                    {asset.filename}
                  </Typography>
                </a>
              ))
            ) : (
              <Typography
                className={classes.fontError}
              >{t`ats1ats2:notReported`}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <Typography>{t`ats1ats2:residenceTitle`}</Typography>
          </Grid>
          <Grid item>
            <Typography
              className={residenceTitle ? classes.bold : classes.fontError}
            >
              {residenceTitle
                ? t([getEnumItemName(enums.RESIDENCE_TITLE, residenceTitle)])
                : t`ats1ats2:notReported`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <Typography>{t`ats1ats2:arrivalDateEmploymentCountry`}</Typography>
          </Grid>
          <Grid item>
            {arrivalDateEmploymentCountry ? (
              <Typography className={classes.bold}>
                {arrivalDateEmploymentCountry}
              </Typography>
            ) : (
              <Typography
                className={classes.fontError}
              >{t`ats1ats2:notReported`}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <Typography>{t`ats1ats2:isForeignerInEmploymentArea`}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.bold}>
              {isForeignerInEmploymentArea ? t`ats1ats2:yes` : t`ats1ats2:no`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return <Grid>{content}</Grid>;
};

export default withStyles(styles)(
  withRouter(withTranslation()(InfoAboutCandidate))
);
