export const styles = ({ palette }) => ({
  fileSection: { padding: '25px 25px 0' },
  paper: {
    padding: 20,
    borderRadius: 10,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    marginBottom: 10,
  },
  chooseFileBtn: {
    float: 'right',
    width: 100,
    fontWeight: 'bold',
    whiteSpace: 'noWrap',
    borderRadius: 30,
    color: '#01D38B',
    border: '2px solid #01D38B',
    '&:hover': {
      background: '#01D38B',
      color: '#fff',
    },
  },
  delBtn: {
    float: 'right',
    width: 100,
    border: '2px solid #B7BEC8',
    borderRadius: 30,
    fontWeight: 'bold',
    color: '#B7BEC8',
    whiteSpace: 'noWrap',
    '&:hover': { borderColor: '#F41313', color: '#F41313', background: '#fff' },
  },
  warningMessage: {
    backgroundColor: palette.warning.main,
    padding: 16,
  },
  bold: { fontWeight: 'bold' },
  addIcon: { cursor: 'pointer' },
  link: {
    textDecoration: 'none',
  },
  fileDesc: { fontSize: '1rem', wordBreak: 'break-all' },
});
