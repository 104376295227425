import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import ResetPasswordForm from './ResetPasswordForm.style';
import validate from './validate';

const resetPasswordForm = reduxForm({
  form: 'resetPassword',
  validate,
})(ResetPasswordForm);

export default withTranslation()(resetPasswordForm);
