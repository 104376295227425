import { Grid, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { Translate } from 'shared/newComponents';
import AssignCandidateToProjectButton from 'shared/newComponents/AssingCandidateToProjectButton/AssignCandidateToProjectButton';
import { styles } from './CandidateContent.style';

const CandidateContent = ({ projectDetails, ...props }) => {
  const position = get(projectDetails, 'position');

  return (
    <>
      <Grid container style={{ padding: 16 }}>
        <Grid
          container
          style={{
            justifyContent: 'center',
          }}
        >
          <Translate variant="h6" text={position} bold />
        </Grid>
        <Grid
          container
          style={{
            justifyContent: 'center',
          }}
        >
          <AssignCandidateToProjectButton projectDetails={projectDetails} />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CandidateContent);
