import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import { Button, Snackbar } from '../../newComponents';
import OfferJob from './components/Job';
import OfferAccommodation from './components/OfferAccommodation';
import Transport from './components/Transport';

const HelpModal = ({
  t,
  isModalOpen,
  setIsModalOpen,
  currentRecruiter,
  offeringHelpInfo,
  setIsSnackbarSendSuccessOpen,
  selectedOfferingTransportInfo,
  selectedOfferingAccommodationInfo,
  updateTable,
}) => {
  const [anyInfoWasSend, setAnyInfoWasSend] = useState(false);
  const [isSnackbarSuccessOpen, setIsSnackbarSuccessOpen] = useState(false);

  const sectionSaved = () => {
    setAnyInfoWasSend(true);
    setIsSnackbarSuccessOpen(true);
  };

  const onClose = () => {
    updateTable(currentRecruiter.id);
    setIsModalOpen(false);
    if (anyInfoWasSend) {
      setIsSnackbarSendSuccessOpen(true);
    }
  };
  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '1000px',
            maxWidth: '80vw',
            background: '#EEEFF0',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {t('help:help.modalTitle')}
        </DialogTitle>
        <IconButton
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            color: '#2D333B',
          }}
          onClick={onClose}
        >
          <Close fontSize="small" />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" />
          <div>
            <OfferAccommodation
              selectedOfferingAccommodationInfo={
                selectedOfferingAccommodationInfo
              }
              sectionSaved={sectionSaved}
              offeringHelpInfo={offeringHelpInfo}
            />
            <Transport
              selectedOfferingTransportInfo={selectedOfferingTransportInfo}
              sectionSaved={sectionSaved}
              offeringHelpInfo={offeringHelpInfo}
            />
            <OfferJob link={PATHS.PROJECT.CREATE} />
            <Grid container justifyContent={'center'} style={{ marginTop: 80 }}>
              <Grid item xs={12} sm={12} md={4}>
                <Button
                  full={true}
                  label={t`help:help.sendHelpInfo`}
                  onClick={onClose}
                />
              </Grid>
            </Grid>
          </div>
          <Snackbar
            open={isSnackbarSuccessOpen}
            onClose={() => setIsSnackbarSuccessOpen(false)}
            label={t`core:isSaved`}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = store => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HelpModal));
